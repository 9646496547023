export const SettingsRightArrowIcon = () => {
	return (
		<svg
			id="chevron_right_black_24dp"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path id="Path_186932" data-name="Path 186932" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_186933"
				data-name="Path 186933"
				d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z"
				fill="#1b5984"
			/>
		</svg>
	);
};

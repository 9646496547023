import { useDispatch, useSelector } from "react-redux"
import Button from "../../../../components/common-components/button/Button"
import { BUTTON_VARIANTS } from "../../../../components/common-components/button/Constants"
import Label from "../../../../components/common-components/label/Label"
import EncounterSummaryCard from "./EncounterSummaryCard"
import { componentKey as PatientEncounterComponentKey, setIsEncounterSummaryOpen, setIsPrintEncounterModalOpen, setIsSignatureModalOpen, setIsSuccessPopUpModalOpen } from "../../EncounterSlice"
import SignModal from "../../../Billing/Payments/SignModal"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { getEncounterByIdDetails } from "../../EncounterSaga"
import { useNavigate, useParams } from "react-router-dom"
import ConfirmationModal from "../../../../components/common-components/confirmationModal/ConfirmationModal"
import SucessPopUp from "../../../../components/common-components/popup-components/SucessPopUp"
import Icons from "../../../../components/icons/Icons"
import BillingViewSummaryModal from "../../../Billing/ReadyForBilling/viewSummary/BillingViewSummaryModal"
import AlertPopUp from "../../../../components/common-components/popup-components/AlertPopUp"
import General from "../../../../libs/utility/General"
import { DEVICE_UUID_TEXT } from "../../FormBuilder/constants"


const EncounterSummary = () => {
    const dispatch = useDispatch()
    const { formData, encounterDataById, isSignatureModalOpen, isSuccessPopUpModalOpen, isPrintEncounterModalOpen } = useSelector(state => state[PatientEncounterComponentKey])
    const { encounterId } = useParams()
    const [isEndEncounterModalOpen, setIsEndEncounterModalOpen] = useState()
    const navigate = useNavigate()

    const handleSave = () => {
        dispatch(setIsSignatureModalOpen(true))
    }

    useEffect(() => {
        dispatch(getEncounterByIdDetails({ encounterId }))
    }, [encounterId, dispatch])

    return <div className="bg-white bg-no-repeat p-4">
        {encounterDataById?.encounterForms?.data && Object.keys(encounterDataById?.encounterForms?.data).map((key,index)=>{
            if(typeof encounterDataById?.encounterForms?.data[key] === 'string' && key !== DEVICE_UUID_TEXT){
                return <EncounterSummaryCard key={`encounter-data-${index}`} title={General.stringToUpperCaseWithSpaces(key)} description={parse(encounterDataById?.encounterForms?.data[key] || '-')} customLabelClass='text-met-primary' fontSize='lg'></EncounterSummaryCard>
            }else {
                return <React.Fragment key={`encounter-data-${index}`}></React.Fragment>
            }
        })}
        {/* <div>
            <EncounterSummaryCard title='CHIEF COMPLAINT (CC)' description={encounterDataById?.encounterForms?.data?.chiefComplaintCc || '-'} customLabelClass='text-met-primary' fontSize='lg'></EncounterSummaryCard>
        </div>
        <div className="my-4">
            <EncounterSummaryCard title='SUBJECTIVE' description={parse(encounterDataById?.encounterForms?.data?.subjective || '-')} customLabelClass='text-met-primary'></EncounterSummaryCard>
        </div>
        <div>
            <EncounterSummaryCard title='History of Present Illness(HPI)' description={parse(encounterDataById?.encounterForms?.data?.historyOfPresentIllnessHpi || '-')} customLabelClass='text-met-primary'></EncounterSummaryCard>
        </div> */}
        {/* <div>
            <EncounterSummaryCard title='Review Of System (ROS)' description="Today's Visit : This is where the default comes into play."></EncounterSummaryCard>
        </div> */}
        {/* <div>
            <EncounterSummaryCard title='OBJECTIVE' description={parse(encounterDataById?.encounterForms?.data?.objective || '-')} customLabelClass='text-met-primary'></EncounterSummaryCard>
        </div> */}
        {/* <div>
            <EncounterSummaryCard title='Vitals' description="Today's Visit : This is where the default comes into play."></EncounterSummaryCard>
        </div> */}
        {/* <div>
            <EncounterSummaryCard title='Physical Exam (PE)' description="Today's Visit : This is where the default comes into play."></EncounterSummaryCard>
        </div> */}
        {/* <div>
            <EncounterSummaryCard title='ASSESSMENT' description={parse(encounterDataById?.encounterForms?.data?.assessment || '-')} customLabelClass='text-met-primary' fontSize='lg'></EncounterSummaryCard>
        </div> */}
        {/* <div>
            <EncounterSummaryCard title='ICD 10 Code' description="Today's Visit : This is where the default comes into play."></EncounterSummaryCard>
        </div> */}
        {/* <div>
            <EncounterSummaryCard title='PLAN' description={parse(encounterDataById?.encounterForms?.data?.plan || '-')} customLabelClass='text-met-primary' fontSize='lg'></EncounterSummaryCard>
        </div>
        <div>
            <EncounterSummaryCard title='FOLLOW UP & INSTRUCTION NOTE' description={parse(encounterDataById?.encounterForms?.data?.followUpInstructionNote || '-')} customLabelClass='text-met-primary' fontSize='lg'></EncounterSummaryCard>
        </div>
        <div>
            <EncounterSummaryCard title='ADDITIONAL NOTE' description={parse(encounterDataById?.encounterForms?.data?.additionalNote || '-')} customLabelClass='text-met-primary' fontSize='lg'></EncounterSummaryCard>
        </div> */}
        <div className="flex mt-4 col-gap-5 float-right">
            <div className="mx-2">
                <Button variant={BUTTON_VARIANTS.OUTLINED} onClickCb={() => setIsEndEncounterModalOpen(true)} customBtnClass="text-met-primary" type='submit'>Cancel</Button>
            </div>
            <div className="mx-2">
                <Button variant={BUTTON_VARIANTS.OUTLINED} onClickCb={() => { dispatch(setIsPrintEncounterModalOpen(true)) }} customBtnClass="text-met-primary" type='submit'>Print</Button>
            </div>
            <div className="mx-2">
                <Button variant={BUTTON_VARIANTS.OUTLINED} onClickCb={() => { dispatch(setIsEncounterSummaryOpen(false)) }} customBtnClass="text-met-primary" type='submit'>Edit Encounter</Button>
            </div>
            <div className="ml-2">
                <Button variant={BUTTON_VARIANTS.CONTAINED} onClickCb={() => { handleSave() }} customBtnClass="text-met-primary" type='submit'>Sign & Save</Button>
            </div>
        </div>
        {isSignatureModalOpen ? <SignModal open={isSignatureModalOpen} close={() => { dispatch(setIsSignatureModalOpen(false)) }}></SignModal> : null}
        {isEndEncounterModalOpen ? <AlertPopUp
            title="Are you sure you want to end this encounter?"
            open={isEndEncounterModalOpen}
            cancelBtnProps={{
                onCancelCb: () => setIsEndEncounterModalOpen(false)
            }}
            confirmationButtonProps={{
                onConfirmCb: () => {
                    navigate('/scheduling/list')
                }
            }}
        /> : null}

        {isSuccessPopUpModalOpen ? <SucessPopUp successMesaage="Encounter Successfully Completed" isOpen={isSuccessPopUpModalOpen} onClickOk={() => {
            dispatch(setIsSuccessPopUpModalOpen(false))
            navigate('/unsigned-visit')
        }}>
            <Icons iconName='successSvg'></Icons>
        </SucessPopUp> : null}
        {isPrintEncounterModalOpen ? <BillingViewSummaryModal isOpen={isPrintEncounterModalOpen} close={() => dispatch(setIsPrintEncounterModalOpen(false))}></BillingViewSummaryModal> : null}
    </div>
}

export default EncounterSummary
import moment from 'moment'
import store from '../../store/store'
export const componentKey = 'DASHBOARD_SECTION'

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setActiveDashboardTab: (state, action) => {
            state.activeDashboardTab = action.payload
        },
        setProviderAdminPaginationState: (state, action) => {
            state.providerAdminPaginationState = { ...state.providerAdminPaginationState, ...action.payload }
        },
        setProvidersListState: (state, action) => {
            state.providersListState = action.payload
        },
        setProviderAdminMetaDataState: (state, action) => {
            state.provideAdminMetaDataState = action.payload
        },
        setIsOpenCalenderRange: (state, action) => {
            state.isOpenCalenderRange = action.payload
        },
        setUpcommingAppointmentData: (state, action) => {
            state.upcommingAppointmentData = action.payload
        },
        setIsOpenCustomDatePickerModal: (state, action) => {
            state.isOpenCustomDatePickerModal = action.payload
        },
        setProviderInformation: (state, action) => {
            state.providerInformation = action.payload
        },
        setBirthdays: (state, action) => {
            state.birthDays = action.payload
        },
        setCalenderviewUpcommingData: (state, action) => {
            state.calenderviewUpcommingData = action.payload
        },
        setDashboardCounts: (state, action) => {
            state.dashboardCounts = action.payload
        },
        setIsOpenBilingGraphModal: (state, action) => {
            state.isOpenBilingGraphModal = action.payload
        },
        setProviderIdBilingCount: (state, action) => {
            state.providerIdBilingCount = action.payload
        },
        setBilingCountGraphDates: (state, action) => {
            state.bilingCountGraphDates = { ...state.bilingCountGraphDates, ...action.payload }
        },
        setBilingCountStored: (state, action) => {
            state.bilingCountStored = action.payload
        },
        setApplyTrue: (state, action) => {
            state.applyTrue = action.payload
        },
        resetCustomDate: (state, action) => {
            const today = moment().format('YYYY-MM-DD');
            state.bilingCountGraphDates = {
                graphDateFrom: moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD'),
                graphDateTo: today
            };
        },
        setIsOpenDeviceTestCount: (state, action) => {
            state.isOpenDeviceTestCount = action.payload
        },
        setSelectedDevice: (state, action) => {
            state.selectedDevice = action.payload
        },
        setSelectedTestName: (state, action) => {
            state.selectedTestName = action.payload
        },
        setVitalTestsCountGraphDates: (state, action) => {
            state.vitalTestsCountGraphDates = { ...state.vitalTestsCountGraphDates, ...action.payload }
        },
        setApplyBtnTrue: (state, action) => {
            state.applyBtnTrue = action.payload
        },
        resetVitalTestsCustomDate: (state, action) => {
            const today = moment().format('YYYY-MM-DD');
            state.vitalTestsCountGraphDates = {
                testsGraphDateFrom: today,
                testsGraphDateTo: today
            };
        },
        setVitalTestCount: (state, action) => {
            state.vitalTestCount = action.payload
        },
        resetVitalTestCount: (state, action) => {
            state.vitalTestCount = []
        },
        setNoDataMessage: (state, action) => {
            state.noDataMessage = action.payload
        },
        setNoDataMessageForBilling: (state, action) => {
            state.noDataMessageForBilling = action.payload
        }
    },
    initialReducerState: {
        activeDashboardTab: "/provider",
        providerAdminPaginationState: { pageNumber: 1, limit: 10, total: 0, orderBy: "desc", sortBy: "", dateFrom: undefined, dateTo: undefined },
        providersListState: [],
        provideAdminMetaDataState: [],
        isOpenCalenderRange: false,
        upcommingAppointmentData: {},
        isOpenCustomDatePickerModal: false,
        providerInformation: {},
        birthDays: [],
        calenderviewUpcommingData: {},
        dashboardCounts: {},
        isOpenBilingGraphModal: false,
        providerIdBilingCount: "",
        bilingCountGraphDates: {
            graphDateFrom: moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD'),
            graphDateTo: moment().format('YYYY-MM-DD')
        },
        bilingCountStored: [],
        applyTrue: false,
        isOpenDeviceTestCount: false,
        selectedDevice: "",
        selectedTestName: "",
        vitalTestsCountGraphDates: {
            testsGraphDateFrom: moment().format('YYYY-MM-DD'),
            testsGraphDateTo: moment().format('YYYY-MM-DD')
        },
        applyBtnTrue: false,
        vitalTestCount: [],
        noDataMessage: false,
        noDataMessageForBilling: false

    }
})

export const { setActiveDashboardTab, setProviderAdminPaginationState, setProvidersListState, setProviderAdminMetaDataState, setUpcommingAppointmentData, setIsOpenCalenderRange, setIsOpenCustomDatePickerModal, setProviderInformation, setBirthdays, setCalenderviewUpcommingData, setDashboardCounts, setIsOpenBilingGraphModal, setProviderIdBilingCount, setBilingCountGraphDates, setBilingCountStored, setApplyTrue, resetCustomDate, setIsOpenDeviceTestCount, setSelectedDevice, setVitalTestsCountGraphDates, setApplyBtnTrue, setSelectedTestName, resetVitalTestsCustomDate, setVitalTestCount, resetVitalTestCount, setNoDataMessage, setNoDataMessageForBilling } = actions

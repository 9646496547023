import React, { useCallback, useEffect } from 'react'

import styles from "../../ZoomIntegration.style"

import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setActivePageIndex, setAudioStarted, setCloseVideoCallModal, setInCallMessages, setIsMuted, setIsVideoMuted, setLoadingState, setShowViewJoinAttendance, setVideoStarted } from '../../ZoomIntegrationSlice';
import { PAGE_STATE, SELF_VIDEO_ID } from '../../constants';
import { ConnectionState, ReconnectReason, ShareStatus } from '@zoom/videosdk';
import classNames from 'classnames';
import { postSendReminder } from '../../ZoomIntegrationSaga';
import VideoTimer from '../../helper/videoTimer';
import { toast } from 'react-toastify'
import { VEC_ICON_NAME } from '../icon/constants';
import Icons from '../icon/Icon';


const VideoFooter = ({ selfShareCanvas, className, userType, appointmentDetails }) => {

    const dispatch = useDispatch()

    const { client, videoStarted, mediaStream, audioStarted, isMuted, isVideoMuted, exitFullScreen, showViewJoinAttendance, inCallMessages, meetingParticipants } = useSelector(state => state[componentKey])

    const onMicrophoneClick = useCallback(async () => {
        if (audioStarted) {
            if (isMuted) {
                await mediaStream?.startAudio();
                await mediaStream?.unmuteAudio();
                dispatch(setIsMuted(false))
            } else {
                await mediaStream?.muteAudio();
                dispatch(setIsMuted(true))
            }
        } else {
            try {
                await mediaStream?.startAudio();
                await mediaStream?.unmuteAudio();
                dispatch(setAudioStarted(true))
                dispatch(setIsMuted(false))
            } catch (e) {
                console.log(e);
            }
        }
    }, [mediaStream, audioStarted, isMuted]);

    const onCameraClick = useCallback(async () => {
        try {
            if (videoStarted) {
                if (!isVideoMuted) {
                    await mediaStream?.stopVideo();
                    dispatch(setIsVideoMuted(true));
                } else {
                    const startVideoOptions = {
                        hd: true,
                        fullHd: true,
                        ptz: mediaStream?.isBrowserSupportPTZ(),
                        originalRatio: true
                    };

                    if (mediaStream?.isSupportVirtualBackground()) {
                        Object.assign(startVideoOptions, { virtualBackground: { imageUrl: 'blur' } });
                    }

                    await mediaStream?.startVideo();

                    if (!mediaStream?.isSupportMultipleVideos()) {
                        const canvasElement = document.querySelector(`#${SELF_VIDEO_ID}`)
                        mediaStream?.renderVideo(canvasElement, client.getSessionInfo().userId, canvasElement.width, canvasElement.height, 0, 0, 3);
                    }
                    dispatch(setIsVideoMuted(false))
                }
            } else {
                const startVideoOptions = {
                    hd: true,
                    fullHd: true,
                    ptz: mediaStream?.isBrowserSupportPTZ(),
                    originalRatio: true
                };

                if (mediaStream?.isSupportVirtualBackground()) {
                    Object.assign(startVideoOptions, { virtualBackground: { imageUrl: 'blur' } });
                }

                await mediaStream?.startVideo();

                if (!mediaStream?.isSupportMultipleVideos()) {
                    const canvasElement = document.querySelector(`#${SELF_VIDEO_ID}`)
                    mediaStream?.renderVideo(canvasElement, client.getSessionInfo().userId, canvasElement.width, canvasElement.height, 0, 0, 3);
                }
                dispatch(setVideoStarted(true));
                dispatch(setIsVideoMuted(false));
            }
        } catch (error) {
            console.log(error)
        }
    }, [mediaStream, videoStarted, isVideoMuted]);

    const onMeetingLeaveHandler = useCallback(async () => {
        dispatch(setCloseVideoCallModal(true))
    }, []);

    const onScreenShareClick = useCallback(async () => {
        try {
            if (mediaStream?.getShareStatus() === ShareStatus.End && selfShareCanvas) {
                await mediaStream?.startShareScreen(selfShareCanvas, { requestReadReceipt: true });
            }
        } catch (error) {
            console.log(error)
        }
    }, [mediaStream, selfShareCanvas]);

    const handleTriggerInvitation = () => {
        dispatch(postSendReminder({ scheduleId: appointmentDetails.uuid }))
    }

    const onConnectionChange = (payload) => {
        try {
            if (payload.state === ConnectionState.Reconnecting) {
                dispatch(setLoadingState({ state: PAGE_STATE.LOADING, message: "Session Disconnected, Trying to reconnect..." }));
                const { reason } = payload;
                if (reason === ReconnectReason.Failover) {
                    dispatch(setLoadingState({ state: PAGE_STATE.LOADING, message: "Session Disconnected, Try to reconnect" }));
                }
            } else if (payload.state === ConnectionState.Closed) {
                if (payload.reason === 'ended by host') {
                    toast.success("Host has ended the meeting.")
                    dispatch(setActivePageIndex(0))
                }
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        client.on('connection-change', onConnectionChange);
        return () => {
            client.off('connection-change', onConnectionChange);
        };
    }, [client, onConnectionChange]);

    return (
        <React.Fragment>
            <div style={exitFullScreen ? { justifyContent: "center" } : {}} className={classNames(`video-footer`, className)}>
                {!exitFullScreen &&
                    <div className='d-flex'>
                        <VideoTimer />
                        {/* <Icons onClickCb={onMicrophoneClick} iconName={VEC_ICON_NAME.VOLUME_UP_ICON} style={styles.videoButtonsIcon} /> : */}
                    </div>
                }
                <div className='video-setting-icons'>
                    {(meetingParticipants.length <= 1 && userType == "Provider") && <Icons onClickCb={handleTriggerInvitation} iconName={VEC_ICON_NAME.TRIGGER_PATIENT_ICON} style={styles.videoButtonsIcon} />}
                    <div>
                        {!isMuted ?
                            <Icons onClickCb={onMicrophoneClick} iconName={VEC_ICON_NAME.MUTE_MIC_ICON} style={styles.videoButtonsIcon} /> :
                            <Icons onClickCb={onMicrophoneClick} iconName={VEC_ICON_NAME.UNMUTE_MIC_ICON} style={styles.videoButtonsIcon} />
                        }
                    </div>
                    <div>
                        {!isVideoMuted ?
                            <Icons onClickCb={onCameraClick} iconName={VEC_ICON_NAME.ON_CAMERA} style={styles.videoButtonsIcon} /> :
                            <Icons onClickCb={onCameraClick} iconName={VEC_ICON_NAME.OFF_CAMERA} style={styles.videoButtonsIcon} />
                        }
                    </div>
                    {meetingParticipants.length > 1 && <Icons onClickCb={onScreenShareClick} iconName={VEC_ICON_NAME.SCREEN_SHARE_ICON} style={styles.videoButtonsIcon} />}
                    {userType == "Provider" && <Icons onClickCb={() => { }} iconName={VEC_ICON_NAME.CALL_RECORDING_ICON} style={styles.videoButtonsIcon} />}

                    <Icons onClickCb={onMeetingLeaveHandler} iconName={VEC_ICON_NAME.LEAVE_MEETING_ICON} style={styles.videoButtonsIcon} />
                </div>
                {!exitFullScreen &&
                    <div style={{ display: "flex" }}>
                        <Icons onClickCb={() => dispatch(setShowViewJoinAttendance(!showViewJoinAttendance))} iconName={VEC_ICON_NAME.ADD_GUEST_ICON} style={styles.videoButtonsIcon} />
                        <Icons onClickCb={() => dispatch(setInCallMessages(!inCallMessages))} iconName={VEC_ICON_NAME.ZOOM_CHAT_ICON} style={styles.videoButtonsIcon} />
                        {/* <Icons onClickCb={onMicrophoneClick} iconName={VEC_ICON_NAME.ZOOM_SETTING_ICON} style={styles.videoButtonsIcon} /> */}
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default VideoFooter

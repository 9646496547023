import React, { useEffect, useState } from 'react'
import SelectDropdown from '../../../../components/common-components/selectDropdown';
import { getAllPatientVitalsForFilter } from './PatientVitalsSaga';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { componentKey, setFilteredArray } from './PatientVitalsSlice';
import { VitalsLineChart } from './VitalsLineChart';
import moment from 'moment';
import General from '../../../../libs/utility/General';


export const vitalsOption = [
    { label: 'Weight', value: 'Weight' },
    { label: 'Respiratory Rate', value: 'Respiratory Rate' },
    { label: 'Oxygen Saturation', value: 'Oxygen Saturation' },
    { label: 'Heart Rate', value: 'Heart Rate' },
    { label: 'Blood Pressure', value: 'Blood Pressure' },
    { label: 'Body Mass Index', value: 'Body Mass Index' },
    { label: 'Temperature', value: 'Temperature' },
    { label: 'Height', value: 'Height' },
];

const VitalsGraph = () => {
    const [selectedVital, setSelectedVital] = useState({});
    const [filteredArray, setFilteredArray] = useState([])

    const { contentToFilterArray } = useSelector(state => state[componentKey])

    const dispatch = useDispatch()
    const { patientId } = useParams();


    useEffect(() => {
        dispatch(getAllPatientVitalsForFilter({ patientId }))
    }, [])

    const handleFilterVitalDropdown = (val) => {
        setSelectedVital(val)
        let filteredData = contentToFilterArray.filter(item => item?.name === val?.value);
       setFilteredArray(filteredData)
    }

    const xValues = filteredArray?.map((item) => {
        const dateTime = moment(`${item?.recordedDate} ${item?.recordedTime}`, 'YYYY-MM-DD HH:mm:ss');
        return dateTime.format('DD-MM-YY hh:mm A');
    });

    const yAxisValues = filteredArray?.map((item) => {
        return `${item?.value1}`
    })
    const yAxisValues2 = filteredArray?.map((item) => {
        return `${item?.value2}`
    })

    const isBPVital = selectedVital?.value === "Blood Pressure" || selectedVital?.value === "BP Test"

    return (
        <>
            <div className='p-5 w-2/5'>
                <div className='pt-6 '>
                    <SelectDropdown placeholder='Select'
                        label='Select Type'
                        isRequired={true}
                        value={selectedVital}
                        onChangeCb={handleFilterVitalDropdown}
                        options={vitalsOption}
                    />
                </div>
            </div>
            {filteredArray?.length === 0 || Object.keys(selectedVital).length === 0 ? (
                <div className='flex justify-center text-gray-500 font-bold mt-[60px]'>
                    No Data Found!
                </div>) :
                <div className="flex flex-wrap justify-between">
                    <VitalsLineChart selectedVital={selectedVital} isBPVital={isBPVital} xValues={xValues} yAxisValues2={yAxisValues2.map(item => General.extractDigits(item))} yAxisValues={isBPVital ? yAxisValues.map(item => General.extractDigits(item)) : yAxisValues} />
                </div>
            }
        </>

    )
}

export default VitalsGraph
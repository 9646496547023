import React, { useState } from 'react';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import Icons from '../../../components/icons/Icons';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import TextArea from '../../../components/common-components/textArea/TextArea';
import { componentKey } from '../ShedulingSlice';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../components/common-components/button/Button';
import { patchScheduledAppointment } from '../SchedulingSaga';
import { Form, Formik } from 'formik';
import { FORM_FIELDS_FOR_CANECEL_APPOINTMENTS } from '../constant';
import { getValidationSchema } from '../../../libs/formsUtils';
const CancellingApppointment = ({ close, appointmentDetails }) => {
	const dispatch = useDispatch();
	const { isCancelledAppointmentModalOpen, statusOfAppointment, schedulingAppointmentsPaginationState } = useSelector(
		(state) => state[componentKey]
	);
	const fields = [{ fieldName: FORM_FIELDS_FOR_CANECEL_APPOINTMENTS.REASON, isRequired: true }];
	const validationSchema = getValidationSchema(fields);
	return (
		<Formik
			initialValues={{
				[FORM_FIELDS_FOR_CANECEL_APPOINTMENTS.REASON]: "",
				
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				const payload = {
					
					status: statusOfAppointment,
					cancellationReason: values.reason,
				};
				dispatch(
					patchScheduledAppointment({
						scheduleId: appointmentDetails?.uuid,
						editScheduledData: payload,
						schedulingAppointmentsPaginationState,
					})
				);
				
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={isCancelledAppointmentModalOpen}
							title={'Cancel Appointment'}
							close={() => {
								close();
							}}
							icon={<Icons iconName="closeIcon" />}
							footerButton={
								<Button
									type="submit"
									variant={BUTTON_VARIANTS.CONTAINED}
									customBtnClass="text-met-primary"
									onClickCb={() => {
									}}
								>
									Confirm
								</Button>
							}
							customClasses="w-[30%] overflow-visible"
							enableReinitialize
						>
							<div className="">
								<div className="flex justify-center">
									<Icons iconName="calenderWithWatchIcon" />
								</div>
								<TextArea
									isRequired={true}
									label="Enter the reason for cancelling the appointment"
									placeholder="Type here..."
									customClasses="mt-2"
									name={FORM_FIELDS_FOR_CANECEL_APPOINTMENTS.REASON}
									value={values[FORM_FIELDS_FOR_CANECEL_APPOINTMENTS.REASON]}
									onChangeCb={(e) => {
										setFieldValue(FORM_FIELDS_FOR_CANECEL_APPOINTMENTS.REASON, e.target.value);
									}}
								/>
							</div>
						</ModalComponent>
					</Form>
				);
			}}
		</Formik>
	);
};
export default CancellingApppointment;

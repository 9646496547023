export const SettingsWatchIcon = () => {
	return (
		<svg
			id="schedule_black_24dp_8_"
			data-name="schedule_black_24dp (8)"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path id="Path_206790" data-name="Path 206790" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_206791"
				data-name="Path 206791"
				d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm.5-13H11v6l5.25,3.15L17,14.92l-4.5-2.67Z"
				fill="rgba(26,26,26,0.5)"
			/>
		</svg>
	);
};

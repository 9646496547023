import React from 'react'

function SuccesStepIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <g id="Group_91673" data-name="Group 91673" transform="translate(-44 -159)">
                <circle id="Ellipse_1296" data-name="Ellipse 1296" cx="16" cy="16" r="16" transform="translate(44 159)" fill="rgba(0,185,23,0.15)" />
                <g id="done_black_24dp_8_" data-name="done_black_24dp (8)" transform="translate(48 163)">
                    <path id="Path_206074" data-name="Path 206074" d="M0,0H24V24H0Z" fill="none" />
                    <path id="Path_206075" data-name="Path 206075" d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z" fill="#00b917" />
                </g>
            </g>
        </svg>
    )
}

export default SuccesStepIcon
import React, { useEffect, useMemo, useState } from 'react'
import CardContainer from '../../../../components/common-components/Cards/Container/CardContainer'
import { BillingFieldsArray, genrateSuperBillFieldNames, serviceDetailsFieldNames, serviceDetailsLabelNames, vitalsTestFieldNames } from './Constants';
import SelectDropdown from '../../../../components/common-components/selectDropdown';
import DatePicker from '../../../../components/common-components/datePicker';
import { Form, Formik } from 'formik';
import DiagnosisCodeTable from './DiagnosisCodeTable';
import ProceduralCodeTable from './ProceduralCodeTable';
import SummaryChargesTable from './SummaryChargesTable';
import CollectPaymentTable from './CollectPaymentTable';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import BillingPrintModal from './BillingPrintModal';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDepartments, getAllLocation, getAllVitalsTest, getReadyForBillingById, postSuperBillDetails, updateSuperBill } from './GenrateSuperBillSaga';
import { componentKey, setIsopenCollectPaymentModal } from './GenrateSuperBillSlice';
import { componentKey as dataLoaderKey } from '../../../../containers/Wrappers/Dataloader/DataloaderSlice';
import * as Yup from 'yup';
import { VALIDATION_REGEX } from '../../../../libs/constant';
import TextArea from '../../../../components/common-components/textArea/TextArea';
import General from '../../../../libs/utility/General';
import ModalPortal from '../../../../components/common-components/modal/ModalPortal';
import CollectPaymentModal from './CollectPaymentModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getValidationSchema } from '../../../../libs/formsUtils';
import DeviceTestTable from './DeviceTestTable';
import Checkbox from '../../../../components/common-components/checkbox/Checkbox';
import GSTCalculator from './GSTCalculator';
import ShowForAllowedPermissionsOnly from '../../../../containers/Wrappers/ShowForAllowedPermissionsOnly';
import { USER_PERMISSIONS } from '../../../../libs/UserPermissionsConstants';
import { USER_ROLE_NAMES } from '../../../../containers/Wrappers/Constants';
import IsAccessAllowed from '../../../../containers/Wrappers/IsAccessAllowed';
import { componentKey as routerComponentKey } from '../../../../routes/RoutesSlice'


const fields = [
    { fieldName: serviceDetailsFieldNames.SERVICE_LOCATION, isDropdown: true },
    { fieldName: serviceDetailsFieldNames.PLACE_OF_SERVICE, isDropdown: true },
    { fieldName: serviceDetailsFieldNames.DOS, isRequired: true },
    { fieldName: serviceDetailsFieldNames.SERVICE_STATE, isDropdown: true },
    { fieldName: serviceDetailsFieldNames.RENDERING_PROVIDER, isDropdown: true },
    { fieldName: serviceDetailsFieldNames.DEPARTMENT_NAME, isDropdown: true },
    { fieldName: vitalsTestFieldNames.GST_TYPE, isRequired: true, isDropdown: true },
];



function BillingServiceDetails({ billId, uuid }) {
    const { allLocation, allDepartmentsData, isOpenCollectPaymentModal, collectPaymentData, patientDetails, billData, paymentData, deviceNameList, patientVitalTests, readyForBillingByIdData, isReadyBilingIds } = useSelector(state => state[componentKey])

    const { indianStates, providersList } = useSelector(state => state[dataLoaderKey])
    const { loggedInUser, loggedInProviderUser } = useSelector(state => state[routerComponentKey])

    const dispatch = useDispatch()
    const [isOpenBillingPrintModal, setIsOpenBillingPrintModal] = useState(false)
    const navigate = useNavigate()

    const [params, setParams] = useSearchParams()

    const EncounterID = params.get("encounterid")

    const [isEditSuperBill, setIsEditSuperBill] = useState(false)
    const [isAdminUser, setIsAdminUser] = useState(false)
    const [selectedProvider, setSelectedProvider] = useState({})
    const role = localStorage.getItem('role')

    useEffect(() => {
        const allowedRoles = [
            USER_ROLE_NAMES.ADMIN_PROVIDER,
        ]

        const currentRole = loggedInProviderUser?.roleNames || []
        setIsAdminUser(IsAccessAllowed(allowedRoles, currentRole))
    }, [loggedInProviderUser])

    useEffect(() => {
        if (!isAdminUser) {
            setSelectedProvider({ label: loggedInUser?.name, value: loggedInUser?.uuid })
        }
    }, [isAdminUser, loggedInUser])

    useEffect(() => {
        if (EncounterID) {
            dispatch(getReadyForBillingById({ EncounterID }))
        }
    }, [dispatch, EncounterID])

    useEffect(() => {
        dispatch(getAllLocation())
        dispatch(getAllDepartments())
        if (uuid) {
            const params = {
                patientId: uuid,
                status: 'Completed',
                isTestIsReadyForbilling: true
            }
            dispatch(getAllVitalsTest({ params }))
        }

    }, [dispatch, uuid])

    useEffect(() => {
        if (Object.keys(billData)?.length > 0) {
            setIsEditSuperBill(true)
        }
    }, [billData])

    const validationSchema = Yup.object().shape({
        ...getValidationSchema(fields).fields,
        billingProceduralCodes: Yup.array().of(
            Yup.object().shape({
                charges: Yup.string().matches(VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX, `charges is invalid`),
                quantity: Yup.string().matches(VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX, `quantity is invalid`),
                discountInPercentage: Yup.string().matches(VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX, `discountInPercentage is invalid`),
                discountInAmount: Yup.string().matches(VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX, `discountInAmount is invalid`),
            })
        ),
        discountInPercentage: Yup.string().matches(VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX, `discountInPercentage is invalid`),
        insuranceAmount: Yup.string().matches(VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX, `insuranceAmount is invalid`),
        coPayAmount: Yup.string().matches(VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX, `coPayAmount is invalid`),
        [vitalsTestFieldNames.IS_GST_APPLICABLE]: Yup.boolean(),
        [vitalsTestFieldNames.GST_NUMBER]: Yup.string().when(vitalsTestFieldNames.IS_GST_APPLICABLE, {
            is: (val) => val === true,
            then: () => Yup.string().required(`GST Number is required`).matches(VALIDATION_REGEX.GST_NUMBER_REGEX, `GST Number is invalid`),
            otherwise: schema => schema.optional(),
        }),
        [vitalsTestFieldNames.GST_IN_PERCENTAGE]: Yup.string().when(vitalsTestFieldNames.IS_GST_APPLICABLE, {
            is: (val) => val === true,
            then: () => Yup.string().required(`GST Rate is required`).matches(VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX, `${(vitalsTestFieldNames.GST_NUMBER)} is invalid`),
            otherwise: schema => schema.optional(),
        }),
        [vitalsTestFieldNames.GST_TYPE]: Yup.object()
            .when(vitalsTestFieldNames.IS_GST_APPLICABLE, {
                is: (val) => val === true,
                then: () => Yup.object().shape({
                    label: Yup.string().required(`GST Type is required`),
                    value: Yup.string().required(`GST Type  is required`)
                }),
                otherwise: schema => schema.optional(),
            }),


    });

    const loactionID = readyForBillingByIdData?.schedules?.Locations?.uuid
    const placeOfService = readyForBillingByIdData?.schedules?.Locations?.placeOfService

    const alltest = []
    for (const tests of patientVitalTests) {
        for (const tst of tests.test) {
            const apiTest = { ...tst, isDelete: false };
            alltest.push(apiTest)
        }
    }

    const initialValues = useMemo(() => {
        return {
            diagnosisCodes: isEditSuperBill ? billData?.diagnosisCodes : [],
            billingProceduralCodes: isEditSuperBill ? billData?.billing_procedural_codes : [],
            tests: isEditSuperBill ? billData.tests : alltest,
            [genrateSuperBillFieldNames.NOTE]: isEditSuperBill ? billData?.note : "",
            [genrateSuperBillFieldNames.TOTAL_BILL]: isEditSuperBill ? 0 : undefined,
            [genrateSuperBillFieldNames.DISCOUNT_IN_PERCENTAGE]: isEditSuperBill ? billData?.discountInPercentage : 0,
            [genrateSuperBillFieldNames.INSURANCE_AMOUNT]: isEditSuperBill ? billData?.insuranceAmount : 0,
            [genrateSuperBillFieldNames.CO_PAY_AMOUNT]: isEditSuperBill ? billData?.coPayAmount : 0,
            [genrateSuperBillFieldNames.TOTAL_BALANCE]: isEditSuperBill ? billData?.totalBalance : 0,
            [genrateSuperBillFieldNames.NET_CHARGES]: isEditSuperBill ? billData?.netCharges : undefined,
            [serviceDetailsFieldNames.SERVICE_LOCATION]: isEditSuperBill ? General.getLabelValue(billData?.serviceLocationId) : General.getLabelValue(loactionID),
            [serviceDetailsFieldNames.PLACE_OF_SERVICE]: isEditSuperBill ? General.getLabelValue(billData.pos) : { label: placeOfService, value: placeOfService },
            [serviceDetailsFieldNames.DOS]: isEditSuperBill ? billData.dos : "",
            [serviceDetailsFieldNames.SERVICE_STATE]: isEditSuperBill ? General.getLabelValue(billData.serviceState) : { label: '', value: '' },
            [serviceDetailsFieldNames.RENDERING_PROVIDER]: isEditSuperBill ? General?.getLabelValue(billData?.renderingProviderId) : isAdminUser  ?  General?.getLabelValue(readyForBillingByIdData.providerId) : selectedProvider,
            [serviceDetailsFieldNames.DEPARTMENT_NAME]: isEditSuperBill ? General.getLabelValue(billData.departmentName) : { label: '', value: '' },
            [vitalsTestFieldNames.GST_TYPE]: isEditSuperBill ? { label: billData?.gstType || "", value: billData?.gstType || "" } : { label: "", value: "" },
            [vitalsTestFieldNames.GST_IN_PERCENTAGE]: isEditSuperBill ? billData.gstPercentage : 18,
            [vitalsTestFieldNames.GST_NUMBER]: isEditSuperBill ? billData.gstNumber : "",
            [vitalsTestFieldNames.GST_AMOUNT]: undefined,
            [vitalsTestFieldNames.GST_TOTAL_AMOUNT]: undefined,
            [vitalsTestFieldNames.IS_GST_APPLICABLE]: isEditSuperBill ? billData.isGstApply === true ? true : false : false
        }
    }, [isEditSuperBill, billData?.diagnosisCodes, billData?.billing_procedural_codes, billData.tests, billData?.note, billData?.discountInPercentage, billData?.insuranceAmount, billData?.coPayAmount, billData?.totalBalance, billData?.netCharges, billData?.serviceLocationId, billData.pos, billData.dos, billData.serviceState, billData?.renderingProviderId, billData.departmentName, billData?.gstType, billData.gstPercentage, billData.gstNumber, billData.isGstApply, patientVitalTests])

    const addDIagnosisCodes = (values, setFieldValue, selectVal) => {
        selectVal = { ...selectVal, index: values.diagnosisCodes.length + 1 }
        setFieldValue('diagnosisCodes', [
            ...values.diagnosisCodes,
            selectVal
        ]);
    };

    const handleRemove = (uuid, values, setFieldValue) => {
        const updatedDiagnosisCodes = values.diagnosisCodes?.filter(code => code.uuid !== uuid) || []
        setFieldValue('diagnosisCodes', updatedDiagnosisCodes);
    };

    const addProceduralCodes = (values, setFieldValue, selectVal) => {
        const updatedProceduralCode = {
            uuid: selectVal.uuid,
            proceduralCode: selectVal?.proceduralCode || "",
            proceduralCodeDescription: selectVal?.proceduralCodeDescription || "",
            charges: 0,
            quantity: 1,
            discountInPercentage: 0,
            discountInAmount: 0,
            netAmount: 0
        };
        setFieldValue('billingProceduralCodes', [
            ...values.billingProceduralCodes,
            updatedProceduralCode
        ]);
    };

    const addDeviceTests = (values, setFieldValue, selectVal) => {
        const updatedDeviceTests = {
            uuid: selectVal.uuid,
            deviceName: selectVal.deviceName,
            testName: selectVal.testName,
            charge: selectVal.charge,
            status: selectVal.status

        }
        setFieldValue('tests', [
            ...values.tests,
            updatedDeviceTests
        ]);
    }

    const handleRemoveProceduralCode = (uuid, values, setFieldValue) => {
        const updatedProceduralCodeList = values?.billingProceduralCodes.filter(item => item.uuid !== uuid)
        setFieldValue('billingProceduralCodes', updatedProceduralCodeList)
    }

    const handleRemoveDeviceTests = (uuid, values, setFieldValue) => {
        const updatedDeviceTestList = values?.tests?.filter(item => item.uuid !== uuid)
        setFieldValue('tests', updatedDeviceTestList)
    }

    const handleFormSubmit = (values, status, shouldNavigateBack = true, isBillUnpaid) => {
        //const testuuid = values?.map((item) => item.uuid)
        const { gstAmount, isGstApplicable, gstTotalAmount, ...extractedValues } = values

        const val = {
            ...extractedValues,
            departmentName: values.departmentName.value,
            serviceState: values.serviceState.value,
            serviceLocationId: values.serviceLocationId.value,
            renderingProviderId: values.renderingProviderId.value || selectedProvider?.value,
            pos: values.pos.value,
            patientPayments: collectPaymentData?.uuid ? [collectPaymentData?.uuid] : [],
            patientId: patientDetails.uuid,
            status: status,
            totalAmountPaid: isGstApplicable ? values?.gstTotalAmount : values?.totalBalance,
            totalBill: isGstApplicable ? values?.gstTotalAmount : values?.totalBalance,
            encounterId: EncounterID,
            gstType: values?.gstType?.value ? values?.gstType?.value : undefined,
            isTestIsReadyForbilling: isReadyBilingIds
        };

        if (billId) {
            const { dos, ...updatedVal } = val || {};
            dispatch(updateSuperBill({ data: updatedVal, uuid: billData.uuid, navigate: navigate, shouldNavigateBack: shouldNavigateBack, isBillUnpaid: isBillUnpaid }));
        } else {
            dispatch(postSuperBillDetails({ data: val, navigate: navigate, shouldNavigateBack: shouldNavigateBack }));
        }
    };


    const { mapping_billing_payment } = billData || {}

    const dropdownOptions = [
        {
            name: 'pos',
            options: [
                { label: "11- Office", value: "11-Office" },
                { label: "12 - Home", value: "12-Home" },
                { label: "13- Assisted Living Facility", value: "13-AssistedLivingFacility" },
            ]
        },
        {
            name: 'serviceLocationId',
            options: allLocation
        },
        {
            name: 'serviceState',
            options: indianStates
        },
        {
            name: 'renderingProviderId',
            options: providersList
        },
        {
            name: 'departmentName',
            options: allDepartmentsData
        }
    ]

    const getFeildOption = (fieldName) => {
        const currentField = dropdownOptions?.find(opt => opt.name === fieldName)
        return currentField?.options || []
    }

    const getserviceDetailsElements = (values, setFieldValue) => {
        return BillingFieldsArray?.map((field, index) => {
            const opts = getFeildOption(field.fieldName)
            return (
                <div key={field.fieldName + index} className='mt-6 w-1/4 pl-4'>
                    {field.type === 'dropdown' ? (
                        <SelectDropdown
                            placeholder={field.placeholder}
                            label={role === 'Biller' && field.fieldLabel === serviceDetailsLabelNames.RENDERING_PROVIDER ? serviceDetailsLabelNames.BILLER_NAME : field.fieldLabel}
                            isRequired={field.isRequired}
                            name={field.fieldName}
                            value={isAdminUser ? values[field.fieldName] : selectedProvider}
                            onChangeCb={(val) => {
                                setFieldValue(field.fieldName, val);
                            }}
                            options={isAdminUser || field?.fieldName !== 'renderingProviderId' ? opts : [selectedProvider]}
                            disabled={mapping_billing_payment?.length ? true : (!isAdminUser && field?.fieldName === 'renderingProviderId')}
                        />
                    ) : field.type === 'date' ? (
                        <DatePicker
                            maxDate={new Date()}
                            isRequired={true}
                            label={field.fieldLabel}
                            placeholder={field.placeholder}
                            name={field.fieldName}
                            value={(values[field.fieldName])}
                            onChangeCb={(date) => {
                                const formattedDate = moment.utc(date).format('YYYY-MM-DDTHH:mm:ssZ');
                                setFieldValue(field.fieldName, formattedDate);
                            }}
                            disabled={mapping_billing_payment?.length ? true : false}

                        />
                    ) : null}
                </div>
            );
        });
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    const status = collectPaymentData?.status ? 'Paid' : 'Unpaid';
                    handleFormSubmit(values, status)
                }}
            >
                {({
                    submitForm,
                    isSubmitting,
                    handleReset,
                    values,
                    handleSubmit,
                    setFieldValue,
                    handleBlur,
                    ...props
                }) => {

                    return (
                        <Form onSubmit={handleSubmit}>
                            <CardContainer customCssClass="">
                                <div className='h-[750px] overflow-y-scroll met-scrollbar'>

                                    <div className='fs-18 font-bold'>Service Location</div>
                                    <div className="flex flex-wrap">{getserviceDetailsElements(values, setFieldValue)}</div>
                                    <div className='mt-5'>
                                        <DiagnosisCodeTable
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            addDIagnosisCodes={(selectVal) => addDIagnosisCodes(values, setFieldValue, selectVal)}
                                            handleRemoveCb={(uuid) => handleRemove(uuid, values, setFieldValue)}
                                            handleBlur={handleBlur}
                                        />
                                    </div>
                                    <div className='mt-5'>
                                        <ProceduralCodeTable
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            addProceduralCodes={(selectVal) => addProceduralCodes(values, setFieldValue, selectVal)}
                                            handleBlur={handleBlur}
                                            handleRemoveCb={(uuid) => handleRemoveProceduralCode(uuid, values, setFieldValue)}
                                        />
                                    </div>
                                    <div className='mt-5'>
                                        <DeviceTestTable
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            addDeviceTests={(selectVal) => addDeviceTests(values, setFieldValue, selectVal)}
                                            handleBlur={handleBlur}
                                            handleRemoveCb={(uuid) => handleRemoveDeviceTests(uuid, values, setFieldValue)}
                                        />
                                    </div>
                                    <div className='mt-4 font-bold'>
                                        Note
                                    </div>
                                    <div className='mt-4 pl-1 pr-1'>
                                        <TextArea
                                            name={genrateSuperBillFieldNames.NOTE}
                                            value={values[genrateSuperBillFieldNames.NOTE]}
                                            onChangeCb={(e) => {
                                                setFieldValue(genrateSuperBillFieldNames.NOTE, e.target.value)
                                            }}

                                            disabled={mapping_billing_payment?.length ? true : false}
                                        />
                                    </div>
                                    <div>
                                        <SummaryChargesTable
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                    <div className='mt-4 pl-1 pr-1'>
                                        <div className='flex column-gap-20px'>
                                            <div>
                                                <Checkbox
                                                    onChangeCb={(e) => {
                                                        setFieldValue(vitalsTestFieldNames.IS_GST_APPLICABLE, e)
                                                    }}
                                                    checked={values[vitalsTestFieldNames.IS_GST_APPLICABLE]}
                                                    disabled={mapping_billing_payment?.length ? true : false}
                                                />
                                            </div>

                                            <div className='font-bold'>
                                                Apply GST
                                            </div>
                                        </div>
                                        {values[vitalsTestFieldNames.IS_GST_APPLICABLE] || billData?.gstType ?
                                            <GSTCalculator
                                                values={values}
                                                setFieldValue={setFieldValue}
                                            />
                                            : null}
                                    </div>

                                    <div className='flex justify-between mt-[20px]'>
                                        <div className='text-gray-500   font-bold'>Total Charges</div>
                                        <div className='mr-[100px] text-gray-500 font-bold'>
                                            {values[vitalsTestFieldNames.IS_GST_APPLICABLE] ? values.gstTotalAmount?.toFixed(2) : values?.totalBalance}
                                        </div>
                                    </div>
                                    <div className='font-bold mt-6 flex justify-end mr-2'>
                                        <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.COLLECT_PAYMENTS}>
                                            {
                                                mapping_billing_payment?.length ? null : <Button variant={BUTTON_VARIANTS.OUTLINED}
                                                    onClickCb={() => {
                                                        const status = "Draft"
                                                        const isBillUnpaid = true;
                                                        const { isGstApplicable, gstTotalAmount, gstAmount, ...extractedValues } = values
                                                        handleFormSubmit(values, status, false, isBillUnpaid)
                                                        dispatch(setIsopenCollectPaymentModal(true))
                                                    }}
                                                    disabled={values.totalBalance === 0 || !values.departmentName?.departmentName?.length }
                                                >Collect Payment</Button>
                                            }
                                        </ShowForAllowedPermissionsOnly >


                                    </div >
                                    <div>
                                        <CollectPaymentTable />
                                    </div>
                                    <div className='flex bg-white  justify-end mt-5 sticky bottom-0 mr-2'>
                                        <div className='mr-4'>
                                            {mapping_billing_payment?.length ? null : <Button type='submit' onClickCb={() => {
                                                const status = "Draft"
                                                const isBillUnpaid = false;
                                                const { isGstApplicable, gstTotalAmount, gstAmount, ...extractedValues } = values
                                                handleFormSubmit(extractedValues, status, isBillUnpaid)
                                            }}
                                                variant={BUTTON_VARIANTS.OUTLINED}>Save As Draft</Button>}

                                        </div>
                                        <div className='mr-4'>
                                            {mapping_billing_payment?.length > 0 ?
                                                <Button onClickCb={() => { setIsOpenBillingPrintModal(true) }} variant={BUTTON_VARIANTS.OUTLINED}>Print</Button> : null}

                                        </div>
                                        <div>
                                            {mapping_billing_payment?.length ? null : <Button type='submit' variant={BUTTON_VARIANTS}>{isEditSuperBill ? "Update" : "Save"} Bill</Button>}
                                        </div>
                                    </div>
                                </div >
                            </CardContainer >
                            {
                                isOpenCollectPaymentModal ?

                                    <ModalPortal>
                                        < CollectPaymentModal
                                            open={isOpenCollectPaymentModal}
                                            close={() => { dispatch(setIsopenCollectPaymentModal(false)) }
                                            }
                                            totalBalance={values[vitalsTestFieldNames.IS_GST_APPLICABLE] === true ? values?.gstTotalAmount?.toFixed(2) : values?.totalBalance?.toFixed(2)}
                                            values={values}
                                        />
                                    </ModalPortal > : null
                            }
                            {isOpenBillingPrintModal ? <BillingPrintModal values={values} open={isOpenBillingPrintModal} close={() => { setIsOpenBillingPrintModal(false) }} /> : null}
                        </Form >
                    );
                }}
            </Formik >

        </>

    )
}

export default BillingServiceDetails
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import Icons from '../../../../../components/icons/Icons';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import Input from '../../../../../components/common-components/input/Input';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import Table from '../../../../Table/Index';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setAllRolesAndResp, setIsEditedRoleData } from './RolesAndRespomsibilitiiesSlice';
import {
	getAllRoles,
	getAllRolesAndResponsibilities,
	patchUpdatRole,
	postCreateNewRole,
} from './RolesAndResponsibilitiesSaga';
import Label from '../../../../../components/common-components/label/Label';
import ToggleSwitch from '../../../../../components/common-components/toggleSwitch/ToggleSwitch';
import { getValidationSchema } from '../../../../../libs/formsUtils';
import { FORM_FIELDS_NAMES } from './constant';
import General from '../../../../../libs/utility/General';
const AddNewRole = ({ close }) => {
	const dispatch = useDispatch();
	const { isNewRoleAdd, isNewAddRoleModalOpen, allRolesAndResp, editedRoleData, roleDuplicate } = useSelector(
		(state) => state[componentKey]
	);
	const [groupedData, setGroupedData] = useState({});

	const fields = [];
	const validationSchema = getValidationSchema(fields);

	const columns = [
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Permission</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row?.permissionName}
						</Heading>
					</>
				);
			},
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Description</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row?.description}
						</Heading>
					</>
				);
			},
		},

		{
			field: 'pcp',
			label: <Label fontWeight="bold">Status</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							<div className="w-[50%] p-1 bg-[#1A1A1A0F] rounded-[50px] flex">
								<ToggleSwitch
									label={'action'}
									checked={row.permissionEnabled}
									onChangeCb={(status) => handleToggleClick(row, 'permissionEnabled', status)}
								/>
								<span
									className={`${
										row.permissionEnabled === true
											? 'text-met-success border-b-[1px] border-b-met-success'
											: 'text-met-red border-b-[1px] border-b-met-red'
									}`}
								>
									{row.permissionEnabled === true ? 'Enable' : 'Disable'}
								</span>
							</div>
						</Heading>
					</>
				);
			},
		},
	];

	useEffect(() => {
		if (editedRoleData?.rolesPermissionsMapping?.length) return; //on edit we are getting all the fields and permission from this prop. so no need to call api
		dispatch(getAllRolesAndResponsibilities());
		dispatch(getAllRoles());
	}, []);

	useEffect(() => {
		if (editedRoleData?.rolesPermissionsMapping?.length) {
			const editedRoleAndResp = editedRoleData?.rolesPermissionsMapping.map((item) => ({
				...item.permissions,
				permissionEnabled: item.enable,
			}));
			dispatch(setAllRolesAndResp(editedRoleAndResp));
		}
	}, [editedRoleData]);

	useEffect(() => {
		const groupedData =
			allRolesAndResp.reduce((acc, item) => {
				if (!acc[item.module]) {
					acc[item.module] = [];
				}
				acc[item.module].push({ ...item });
				return acc;
			}, {}) || {};

		setGroupedData(groupedData);
	}, [allRolesAndResp]);

	const handleToggleClick = (row, key, status) => {
		const allRolesRespClone = [...allRolesAndResp];
		const updatedData = {
			...row,
			[key]: status,
		};

		const updatedRolesData = General.updateObjectInArray(allRolesRespClone, row, 'uuid', updatedData);
		dispatch(setAllRolesAndResp(updatedRolesData));
	};

	return (
		<Formik
			initialValues={{
				[FORM_FIELDS_NAMES.ROLE]: General.getLabelValue(editedRoleData?.name),
				[FORM_FIELDS_NAMES.DESCRIPTION]: editedRoleData?.description,
				[FORM_FIELDS_NAMES.PERMISSION]: editedRoleData?.permission,
				// ...editedRoleData,
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				const permissions = allRolesAndResp.map((item, index) => {
					return {
						permissionId: item.uuid,
						enable: item?.permissionEnabled === true ? true : false,
					};
				});

				const payload = {
					...values,
					role: values.role.value,
					permission: permissions,
				};
				try {
					if (isNewRoleAdd || roleDuplicate) {
						dispatch(postCreateNewRole({ roleData: payload }));
					} else {
						dispatch(patchUpdatRole({ uuid: editedRoleData?.uuid, editedRoleData: payload }));
					}
				} catch (err) {
					console.log(err);
				}
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={isNewAddRoleModalOpen}
							validationSchema={validationSchema}
							title={isNewRoleAdd ? 'Add New Role' : 'Edit role'}
							close={() => {
								close();
								dispatch(setIsEditedRoleData({}));
							}}
							icon={<Icons iconName="closeIcon" />}
							footerButton={
								<Button
									type="submit"
									variant={BUTTON_VARIANTS.CONTAINED}
									customBtnClass="text-met-primary"
								>
									Save
								</Button>
							}
							customClasses="w-[80%]"
							enableReinitialize
						>
							<div className="flex flex-col">
								<div className="flex justify-between">
									<div className="w-[40%]">
										<SelectDropdown
											placeholder="Select Role Name"
											label="Role Name"
											isRequired={true}
											isMultiSelect={false}
											name={FORM_FIELDS_NAMES.ROLE}
											value={values[FORM_FIELDS_NAMES.ROLE]}
											onChangeCb={(val) => {
												setFieldValue(FORM_FIELDS_NAMES.ROLE, val);
											}}
											options={[
												{ label: 'Provider', value: 'Provider' },
												{ label: 'Provider Admin', value: 'Provider Admin' },
												{ label: 'Biller', value: 'Biller' },
												{ label: 'Patient', value: 'Patient' },
												{ label: 'Front Desk', value: 'Front Desk' },
											]}
										/>
									</div>
									<div className="w-[55%]">
										<Input
											label="Description"
											isRequired={false}
											placeholder="Enter  Description"
											name={FORM_FIELDS_NAMES.DESCRIPTION}
											value={values[FORM_FIELDS_NAMES.DESCRIPTION]}
											onChangeCb={(e) => {
												setFieldValue(FORM_FIELDS_NAMES.DESCRIPTION, e.target.value);
											}}
										/>
									</div>
								</div>
								<div className="mt-4">
									{Object.keys(groupedData || {}).map((key, index) => {
										return (
											<div key={index} className="mt-5">
												<Heading
													type={HEADING.H1}
													color="met-primary"
													fontWeight="bold"
													fontSize={'lg'}
												>
													{key}
												</Heading>
												<div className="mt-4">
													<Table coloumns={columns} rows={groupedData[key] || []} />
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</ModalComponent>
					</Form>
				);
			}}
		</Formik>
	);
};
export default AddNewRole;

import React, { useEffect, useState } from 'react'
import ModalComponent from '../../../components/common-components/modal/ModalComponent'
import Button from '../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants'
import RadioButton from '../../../components/common-components/radioButton/RadioButton'
import DatePicker from '../../../components/common-components/datePicker'
import { setProviderAdminPaginationState } from '../DashboardSlice'
import { useDispatch } from 'react-redux'
import moment from 'moment'

function CalenderRangeModal({ open, close }) {
    const [selectedLabel, setSelectedLabel] = useState("");
    const dispatch = useDispatch()

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const FILTER_LABELS = {
        MOST_RECENT: "Most Recent Date (21 Sep 2022)",
        LAST_7_DAYS: "Last 7 Days",
        LAST_28_DAYS: "Last 28 Days",
        LAST_3_MONTHS: "Last 3 Months",
        LAST_6_MONTHS: "Last 6 Months",
        LAST_12_MONTHS: "Last 12 Months",
        LAST_16_MONTHS: "Last 16 Months",
        CUSTOM: "Custom",
    }
    const labels = [
        `Most Recent Date (${moment().format("DD MMM YYYY")})`,
        "Last 7 Days",
        "Last 28 Days",
        "Last 3 Months",
        "Last 6 Months",
        "Last 12 Months",
        "Last 16 Months",
        "Custom"
    ];

    useEffect(() => {
        if (selectedLabel === FILTER_LABELS.CUSTOM) {
            setEndDate(new Date());
        }
    }, [selectedLabel]);

    const handleChange = (label) => {
        const endDate = new Date();
        switch (label) {
            case FILTER_LABELS.MOST_RECENT:
                setStartDate(new Date())
                setEndDate(new Date())
                break;

            case FILTER_LABELS.LAST_7_DAYS:
                setStartDate(moment(endDate).subtract(1, 'week').toDate())
                setEndDate(endDate)
                break;

            case FILTER_LABELS.LAST_28_DAYS:
                setStartDate(moment(endDate).subtract(28, 'days').toDate())
                setEndDate(endDate)
                break;

            case FILTER_LABELS.LAST_3_MONTHS:
                setStartDate(moment(endDate).subtract(3, 'months').toDate())
                setEndDate(endDate)
                break;

            case FILTER_LABELS.LAST_6_MONTHS:
                setStartDate(moment(endDate).subtract(6, 'months').toDate())
                setEndDate(endDate)
                break;

            case FILTER_LABELS.LAST_12_MONTHS:
                setStartDate(moment(endDate).subtract(12, 'months').toDate())
                setEndDate(endDate)
                break;

            case FILTER_LABELS.LAST_16_MONTHS:
                setStartDate(moment(endDate).subtract(16, 'months').toDate())
                setEndDate(endDate)
                break;

            default:
                break;
        }
        setSelectedLabel(label);
    };

    const handleApplyClick = () => {
        dispatch(setProviderAdminPaginationState({ dateFrom: new Date(startDate), dateTo: new Date(endDate) }))
    }

    return (
        <>
            <ModalComponent customClasses="min-w-[590px]" open={open} close={close} title="Calendar Range" footerButton={<Button customBtnClass='mt-6' onClickCb={handleApplyClick} variant={BUTTON_VARIANTS.CONTAINED}>Apply</Button>}>
                {labels.map((label, index) => (
                    <div key={index} className='mb-3'>
                        <RadioButton
                            label={label}
                            customLabelClass="font-bold text-gray-500"
                            checked={selectedLabel === label}
                            onChangeCb={() => handleChange(label)}
                        />
                    </div>
                ))}
                <div className='flex column-gap-20px  ml-8'>
                    {selectedLabel === "Custom" ? (
                        <>
                            <DatePicker label='Start Date' placeholder='Choose Date' value={startDate} maxDate={new Date()} onChangeCb={(date) => setStartDate(date)} />
                            <DatePicker label='End Date' placeholder='Choose Date' value={endDate} minDate={startDate} onChangeCb={(date) => setEndDate(date)} />
                        </>
                    ) : null}
                </div>
            </ModalComponent>
        </>
    )
}

export default CalenderRangeModal
import React, { useEffect } from 'react'
import { getStateList, getProvidersList, rolesAndPermissionOfUser, getAllProviderGroupLocations } from './DataloaderSaga'
import { useDispatch, useSelector } from 'react-redux'
import { setIsAdminUser } from "../../../routes/RoutesSlice"
import IsAccessAllowed from '../IsAccessAllowed'
import { USER_ROLE_NAMES } from '../Constants'
import { setSelectedProvider } from '../../SettingsTab/AppointmentCard/Tabs/Availability/AvailabilitySlice'

const Dataloader = ({ children }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getStateList())
    }, [dispatch])

    useEffect(() => {
        dispatch(getProvidersList());
    }, [dispatch]);

    useEffect(() => {
        const role = localStorage?.getItem('role');
        dispatch(rolesAndPermissionOfUser(role));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllProviderGroupLocations())
    }, [dispatch])

    useEffect(() => {
        let user = localStorage.getItem("user")
        const providerUser = localStorage.getItem("providerUser")
        const allowedRoles = [USER_ROLE_NAMES.ADMIN_PROVIDER]
        const currentRole = JSON.parse(providerUser)?.roleNames || []
        dispatch(setIsAdminUser(IsAccessAllowed(allowedRoles, currentRole)))
        user = JSON.parse(user)
        dispatch(setSelectedProvider({ label: user?.name, value: user?.uuid }))
    }, [dispatch])

    return (
        <>{children}</>
    )
}

export default Dataloader;

import AppDataService from './AppDataService'

const COMMON_BASE = 'vitals-test'

export default class VitalsTestDataService {

    static async getAllVitalsTestDetails(params) {
        return await AppDataService.get(`${COMMON_BASE}/details`, { params })
    }

    static async postVitalsTest(patientId, data) {
        return await AppDataService.post(`${COMMON_BASE}/create/${patientId}`, data)
    }

    static async patchVitalsTest({ body }) {
        return await AppDataService.patch(`${COMMON_BASE}/device/requested`, body)
    }

    static async getAllPatientDeviceVitalsResult(url, params) {
        return await AppDataService.get(`${COMMON_BASE}/${url}`, { params })
    }

    static async updatePendingVitalTest(vitalTestId, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${vitalTestId}`, data)
    }

}
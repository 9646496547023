import store from '../../../store/store';

export const componentKey = 'DATALOADER';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setIndianStates: (state, action) => {
			state.indianStates = action.payload;
		},
		setProvidersList: (state, action) => {
			state.providersList = action.payload;
		},
		setUsersRolesAndResp: (state, action) => {
			state.usersRolesAndResp = action.payload;
		},
		setAllProviderGroupLocation: (state, action) => {
			state.allProviderGroupLocation = action.payload;
		},
	},
	initialReducerState: {
		indianStates: [],
		providersList: [],
		usersRolesAndResp: [],
		allProviderGroupLocation: [],
	},
});

export const { setIndianStates, setProvidersList, setUsersRolesAndResp,setAllProviderGroupLocation } = actions;

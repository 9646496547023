import React from 'react';
import Icons from '../../icons/Icons';
import { BUTTON_VARIANTS } from './Constants';

function Button({
	variant,
	startIcon = {
		icon: '',
		iconStyle: {},
		color: '',
	},
	endIcon,
	customBtnClass = '',
	children,
	onClickCb = () => { },
	color = '',
	style = {},
	onMouseOverCb = () => { },
	onMouseLeaveCb = () => { },
	type = "button",
	disabled = false,
	title
}) {
	const getVariantClass = () => {
		let variantClass;
		switch (variant) {
			case BUTTON_VARIANTS.CONTAINED:
				variantClass = `bg-met-primary text-white border bg-${color || 'met-primary'}`;
				break;
			case BUTTON_VARIANTS.OUTLINED:
				variantClass = `text-${color || 'met-primary'} bg-${color || 'met-secondary'
					} border text-met-primary border-${color || 'met-primary'}`;
				break;
			case BUTTON_VARIANTS.TEXT:
				variantClass = 'text-button';
				break;
			case BUTTON_VARIANTS.OUTLINED_GRAY:
				variantClass = 'border border-gray-300 py-1 px-2';
				break;
			case BUTTON_VARIANTS.CONTAINED_GRAY:
				variantClass = `bg-opacity-0 text-sm h-10 text-opacity-80 text-gray-800 bg-gray-200 border-gray-300 border`;
				break;
			default:
				variantClass = `bg-met-primary text-white border bg-${color || 'met-primary'}`;
		}
		return variantClass;
	};

	return (
		<button
			onClick={onClickCb}
			className={`p-2 px-3 rounded text-md flex items-center text-start column-gap-10px ${getVariantClass()} ${customBtnClass} ${disabled && "cursor-not-allowed" }`}
			style={{ ...style }}
			onMouseOver={onMouseOverCb}
			onMouseLeave={onMouseLeaveCb}
			type={type}
			disabled={disabled}
			title={title}
		>
			<div className='flex column-gap-10px items-center'>
				{startIcon.icon && (
					<div className='mr-[4px]'>
						{typeof startIcon.icon === 'string' ? (
							<Icons iconName={startIcon.icon} color={startIcon.color} />
						) : (
							startIcon.icon
						)}
					</div>
				)}
				{children}
			</div>
			{endIcon && (
				<div className='mr-[4px]'>
					{typeof endIcon === 'string' ? <Icons iconName={endIcon} /> : endIcon}
				</div>
			)}
		</button>
	);
}

export default Button;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ApprovedState, RemoteControlAppStatus, RemoteControlSessionStatus } from '@zoom/videosdk';

export function useRemoteControl(zmClient, mediaStream, selfShareView, shareView) {
    const [isControllingUser, setIsControllingUser] = useState(mediaStream?.isControllingUserRemotely());
    const [controllingUser, setControllingUser] = useState(null);
    const isDownloadAppRef = useRef(false);
    const launchModalRef = useRef(null);

    const onInControllingChange = useCallback(payload => {
        const { isControlling } = payload;
        setIsControllingUser(isControlling);
    }, []);

    const onControlApproveChange = useCallback(payload => {
        const { state } = payload;
        if (state === ApprovedState.Approved) {
            if (shareView) {
                const viewport = shareView.parentElement;
                if (viewport) {
                    mediaStream?.startRemoteControl(viewport);
                }
            }
        } else {
            console.log('Your remote control request is rejected');
        }
    }, [mediaStream, shareView]);

    const onReceiveRemoteControlRequest = useCallback(payload => {
        const { userId, displayName, isSharingEntireScreen } = payload;
        if (isSharingEntireScreen) {
            setControllingUser({ userId, displayName });
        }
        // Implement your own modal or dialog for confirmation
        console.log(`${displayName} is requesting remote control of your screen`);
    }, []);

    const onRemoteControlAppStatusChange = useCallback(payload => {
        if (payload === RemoteControlAppStatus.Uninstalled || payload === RemoteControlAppStatus.Unknown) {
            const { displayName, userId } = controllingUser || {};
            // Implement your own modal or dialog for downloading the app
            console.log(`${displayName} is requesting remote control of your screen`);
        } else if (payload === RemoteControlAppStatus.Unlaunched) {
            const { userId } = controllingUser || {};
            // Implement your own modal or dialog for re-approving
            console.log('Remote control app launch timeout');
        }
    }, [controllingUser]);

    const onRemoteControlSessionChange = useCallback(payload => {
        if (payload === RemoteControlSessionStatus.Ended) {
            setControllingUser(null);
        } else if (payload === RemoteControlSessionStatus.Started) {
            launchModalRef.current?.destroy();
        }
    }, []);

    useEffect(() => {
        zmClient.on('remote-control-in-control-change', onInControllingChange);
        zmClient.on('remote-control-approved-change', onControlApproveChange);
        zmClient.on('remote-control-request-change', onReceiveRemoteControlRequest);
        zmClient.on('remote-control-app-status-change', onRemoteControlAppStatusChange);
        zmClient.on('remote-control-controlled-status-change', onRemoteControlSessionChange);

        return () => {
            zmClient.off('remote-control-in-control-change', onInControllingChange);
            zmClient.off('remote-control-approved-change', onControlApproveChange);
            zmClient.off('remote-control-request-change', onReceiveRemoteControlRequest);
            zmClient.off('remote-control-app-status-change', onRemoteControlAppStatusChange);
            zmClient.off('remote-control-controlled-status-change', onRemoteControlSessionChange);
        };
    }, [
        zmClient,
        onInControllingChange,
        onControlApproveChange,
        onReceiveRemoteControlRequest,
        onRemoteControlAppStatusChange,
        onRemoteControlSessionChange
    ]);

    return { isControllingUser, controllingUser };
}

import React from 'react';
import moment from 'moment';
import { componentKey, setIsEditSingleDayAvailabilityModalOpen, setIsViewAllEventsModalOpen, setViewAllModalSelectedSlotData } from './AvailabilitySlice';
import { useDispatch, useSelector } from 'react-redux';
import EditAvailabilityModal from './EditAvailability';

const ViewAllEventModal = ({ events = [], handleEventClick }) => {
	const dispatch = useDispatch()
	const { isEditSingleDayAvailabilityModalOpen } = useSelector(state => state[componentKey])
	return (
		<>
			{
				events.map((item, index) => {
					return <div onClick={() => {
						dispatch(setViewAllModalSelectedSlotData(item))
						dispatch(setIsViewAllEventsModalOpen(false))
						dispatch(setIsEditSingleDayAvailabilityModalOpen(true))
					}} key={`${item.title} + ${index}`} className='flex justify-between m-1 cursor-pointer' style={item.style}>
						<div>{item.title}</div>
						<div>
							{`${moment(item.start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('hh:mm A')} - ${moment(item.end, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('hh:mm A')}`}
						</div>
						{isEditSingleDayAvailabilityModalOpen && <EditAvailabilityModal open={isEditSingleDayAvailabilityModalOpen} customClasses="h-[80%] w-[45%]" close={() => dispatch(setIsEditSingleDayAvailabilityModalOpen(false))} customBodyClasses="h-[88%]" />}

					</div>
				})
			}
		</>
	);
};

export default ViewAllEventModal;

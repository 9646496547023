import React from 'react'
import BgBlurCard from '../bgBlurCard/BgBlurCard'
import Label from '../label/Label'

const ProviderInfoCard = ({ image, quote, name, organization, position }) => {
    return (
        <div className='h-[100vh] flex-1 relative'>
            <img className='h-[100%] w-[100%]' src={image} alt='provider' />
            <BgBlurCard>
                <div className='flex flex-col justify-between h-full'>
                    <Label fontSize="2xl" fontWeight="bold" color="white" customClass="p-4 m-3">
                        {`"${quote}"`}
                    </Label>
                    <div className='flex flex-col p-4 m-2'>
                        <Label fontSize="lg" fontWeight="bold" color="white">
                            {name}
                        </Label>
                        <Label fontSize="md" fontWeight="bold" color="white">
                            {organization}
                        </Label>
                        <Label fontSize="md" fontWeight="normal" color="white">
                            {position}
                        </Label>
                    </div>
                </div>
            </BgBlurCard>
        </div>
    )
}

export default ProviderInfoCard
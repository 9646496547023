export default {
    overlay: {
        visibility: "hidden",
        opacity: 0,
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: "rgba(0, 0, 0, 0.7)",
        transition: "opacity 500ms",
        zIndex: 999,
    },
    popup: {
        background: "#ffffff",
        borderRadius: "8px",
        minWidth: "472px",
        maxWidth: "511px",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        transition: "all 2s ease-in-out",
    },
    popupHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px 24px",
        borderRadius: "8px 8px 0px 0px",
    },
    popupHeaderTitle: {
        fontSize: "24px",
        marginBottom: "0px",
        fontWeight: "600",
        lineHeight: "29px",
        color: "#262626"
    },

    content: {
        maxHeight: "550px",
        padding: "0 24px",
        // overflowY: "auto",
    },

    closeIcon: {
        width: "24px",
        height: "24px"
    },
    icon: {
        cursor: "pointer"
    }
}

import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import Label from '../../../../../components/common-components/label/Label';
import Upload from '../../../../../components/common-components/upload/Upload';
import { MET_COLORS, VALIDATION_REGEX } from '../../../../../libs/constant';
import { componentKey as locationComponentKey, setEditLocationData } from './LocationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getValidationSchema } from '../../../../../libs/formsUtils';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import Input from '../../../../../components/common-components/input/Input';
// import Icons from '../../../../../assets/svgs';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import Icons from '../../../../../components/icons/Icons';
import Checkbox from '../../../../../components/common-components/checkbox/Checkbox';
import ToggleSwitch from '../../../../../components/common-components/toggleSwitch/ToggleSwitch';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import { FORM_FIELDS_NAMES } from './constant';
import { getProviderGroupDetails, postLocationData, updateLocations } from './LocationSaga';
import General from '../../../../../libs/utility/General';
import { getProviderSepcialities } from '../../../MyAccountCard/Tabs/MyProfile/ProviderProfileSaga';
import { componentKey } from '../../../../Wrappers/Dataloader/DataloaderSlice';
import DatePicker from '../../../../../components/common-components/datePicker';

const LocationModal = ({ close, hospitalName }) => {
	useEffect(() => {
		dispatch(getProviderSepcialities());
	}, []);

	const dispatch = useDispatch();
	const [isSameAsPhysicalAddress, setISSameAsPhysicialAddress] = useState(false);

	const fields = [
		{ fieldName: FORM_FIELDS_NAMES.NAME, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.EMAIL, isRequired: true, isEmail: true },
		{
			fieldName: FORM_FIELDS_NAMES.CONTACT_NUMBER,
			isRequired: true,
			regexPattern: VALIDATION_REGEX.MOBILE_NUM_REGEX,
		},
		{ fieldName: FORM_FIELDS_NAMES.CONTACT_PERSON, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.PLACEOFSERVICE, isRequired: true, isDropdown: true },
		{ fieldName: FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_1, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_CITY, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_COUNTRY ,regexPattern: VALIDATION_REGEX.NAME_REGEX},
		{ fieldName: FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_STATE, isRequired: true, isDropdown: true },
		{
			fieldName: FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_ZIP,
			isRequired: true,
			regexPattern: VALIDATION_REGEX.ZIP_CODE_REGEX,
		},
		{ fieldName: FORM_FIELDS_NAMES.BILLING_ADDRESS_1, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY, isRequired: true,regexPattern: VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY, regexPattern: VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE, isRequired: true, isDropdown: true },
		{
			fieldName: FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP,
			isRequired: true,
			regexPattern: VALIDATION_REGEX.ZIP_CODE_REGEX,
		},
	];
	const { isLocationModalOpen, isNewLocationAdd, editLocationData, providerSepcialities } = useSelector(
		(state) => state[locationComponentKey]
	);

	const { indianStates, usersRolesAndResp } = useSelector((state) => state[componentKey]);
	const validationSchema = getValidationSchema(fields);

	const formatOfficeHours = (officeHours) => {
		
		return officeHours.map(item => ({
		...item,
		from: item.from ? item.from : (item.openingTime || undefined),
		to: item.to ? item.to :(item.closingTime || undefined),
		openingTime: item.from ? item.from : (item.openingTime || undefined),
		closingTime:item.to ? item.to :(item.closingTime || undefined),
		}));
		}; 
			
	const OFFICE_HOURS = Object.keys(editLocationData?.officeHours || {}).map((key, index) => {
		const {from,to,openingTime,closingTime} = editLocationData.officeHours[key]
		return {
			day: key,
			dayOfWeek : General.stringToUpperCaseWithSpaces(key),
			from: from ? from : openingTime,
			to: to ? to : closingTime,
			...editLocationData.officeHours[key],
		};
	});
	const editedLocationFieldsData = {
		...editLocationData,
		[FORM_FIELDS_NAMES.OFFICE_HOURS]: OFFICE_HOURS,
	};


	const defaultOfficeHours = [
		{
			day: 'Monday',
			dayOfWeek: 'MONDAY',
			active: false,
			from: '',
			to: '',
		},
		{
			day: 'Tuesday',
			dayOfWeek: 'TUESDAY',
			active: false,
			from: '',
			to: '',
		},
		{
			day: 'Wednesday',
			dayOfWeek: 'WEDNESDAY',
			active: false,
			from: '',
			to: '',
		},
		{
			day: 'Thursday',
			dayOfWeek: 'THURSDAY',
			active: false,
			from: '',
			to: '',
		},
		{
			day: 'Friday',
			dayOfWeek: 'FRIDAY',
			active: false,
			from: '',
			to: '',
		},
		{
			day: 'Saturday',
			dayOfWeek: 'SATURDAY',
			active: false,
			from: '',
			to: '',
		},
		{
			day: 'Sunday',
			dayOfWeek: 'SUNDAY',
			active: false,
			from: '',
			to: '',
		},
	];
	const specialityData = editedLocationFieldsData?.provider_locations_speciality_intermediate?.map(
		(item, idx) => item?.specialityId
	);

	return (
		<Formik
			initialValues={{
				[FORM_FIELDS_NAMES.NAME]: isNewLocationAdd ? "" : editedLocationFieldsData?.name || '',
				[FORM_FIELDS_NAMES.LOCATION_ID]: isNewLocationAdd ? "" : editedLocationFieldsData?.locationId,
				[FORM_FIELDS_NAMES.PLACEOFSERVICE]: isNewLocationAdd ? { label: "", value: "" } : editedLocationFieldsData?.placeOfService ? General.getLabelValue(editedLocationFieldsData?.placeOfService) : { label: "", value: "" },
				[FORM_FIELDS_NAMES.SPECIALITY_TYPE]: isNewLocationAdd ? "" : editedLocationFieldsData?.specialityType,
				[FORM_FIELDS_NAMES.EMAIL]: isNewLocationAdd ? "" : editedLocationFieldsData?.email,
				[FORM_FIELDS_NAMES.GROUP_NPI_NUMBER]: isNewLocationAdd ? undefined : editedLocationFieldsData?.groupNpiNumber,
				[FORM_FIELDS_NAMES.FAX]: isNewLocationAdd ? "" : editedLocationFieldsData?.fax,
				[FORM_FIELDS_NAMES.INFORMATION]: isNewLocationAdd ? "" : editedLocationFieldsData?.information,
				// [FORM_FIELDS_NAMES.TIMEZONE]: General.getLabelValue(editedLocationFieldsData?.timezone),
				[FORM_FIELDS_NAMES.CONTACT_NUMBER]: isNewLocationAdd ? undefined : editedLocationFieldsData?.contactNumber,
				[FORM_FIELDS_NAMES.CONTACT_PERSON]: isNewLocationAdd ? "" : editedLocationFieldsData?.contactPerson,

				[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_1]: isNewLocationAdd ? "" : editedLocationFieldsData?.physicalAddress1,
				[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_2]: isNewLocationAdd ? "" : editedLocationFieldsData?.physicalAddress2,
				[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_CITY]: isNewLocationAdd ? "" : editedLocationFieldsData?.physicalAddressCity,

				[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_STATE]: isNewLocationAdd ? { label: "", value: "" } : editedLocationFieldsData?.physicalAddressState ? General.getLabelValue(editedLocationFieldsData?.physicalAddressState) : { label: "", value: "" },
				[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_COUNTRY]: isNewLocationAdd ? "" : editedLocationFieldsData?.physicalAddressCountry,
				[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_ZIP]: isNewLocationAdd ? "" : editedLocationFieldsData?.physicalAddressZip,
				[FORM_FIELDS_NAMES.BILLING_ADDRESS_1]: isNewLocationAdd ? "" : editedLocationFieldsData?.billingAddress1,
				[FORM_FIELDS_NAMES.BILLING_ADDRESS_2]: isNewLocationAdd ? "" : editedLocationFieldsData?.billingAddress2,
				[FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY]: isNewLocationAdd ? "" : editedLocationFieldsData?.billingAddressCity,
				[FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE]: isNewLocationAdd ? { label: "", value: "" } : editedLocationFieldsData?.billingAddressState ? General?.getLabelValue(editedLocationFieldsData?.billingAddressState) : { label: "", value: "" },
				[FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY]: isNewLocationAdd ? "" : editedLocationFieldsData?.billingAddressCountry,
				[FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP]: isNewLocationAdd ? "" : editedLocationFieldsData?.billingAddressZip,
				[FORM_FIELDS_NAMES.NUMBER]: isNewLocationAdd ? "" : editedLocationFieldsData?.number,
				[FORM_FIELDS_NAMES.SPECIALITY]: isNewLocationAdd ? [] : specialityData || [],
				[FORM_FIELDS_NAMES.LOCATION_LOGO]: isNewLocationAdd ? undefined : editedLocationFieldsData?.image,
				[FORM_FIELDS_NAMES.LOCATION_PATH]: isNewLocationAdd ? undefined : '',
				[FORM_FIELDS_NAMES.OFFICE_HOURS]: isNewLocationAdd ? defaultOfficeHours : editedLocationFieldsData[FORM_FIELDS_NAMES.OFFICE_HOURS]?.length
					? editedLocationFieldsData[FORM_FIELDS_NAMES.OFFICE_HOURS] : []

			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				const payload = {
					...values,
					[FORM_FIELDS_NAMES.OFFICE_HOURS]: formatOfficeHours(values[FORM_FIELDS_NAMES.OFFICE_HOURS]),
				};
				try {
					if (isNewLocationAdd) {
						dispatch(postLocationData({ allLocationData: payload }));
					} else {
						dispatch(updateLocations({ uuid: editLocationData.uuid, allLocationData: payload }));
					}
				} catch (error) {
					console.log(error);
				}
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={isLocationModalOpen}
							validationSchema={validationSchema}
							title={isNewLocationAdd ? `Add New Location for ${hospitalName}` : 'Edit Location'}
							close={() => {
								close();
								dispatch(setEditLocationData({}));
							}}
							icon={<Icons iconName="closeIcon" />}
							footerButton={
								<Button
									type="submit"
									variant={BUTTON_VARIANTS.CONTAINED}
									customBtnClass="text-met-primary"
								>
									Save
								</Button>
							}
							customClasses="w-[80%]"
							enableReinitialize
						>
							<div className="flex-column">
								<div className="flex justify-between flex-wrap">
									<div className="w-[10%]">
										<div className="mb-3">
											<Label fontWeight="bold">Location Logo</Label>
										</div>
										<Upload
											url={editedLocationFieldsData?.imagePath}
											subTitle="Upload"
											customSubtitleClass="text-met-primary"
											customClass="w-[157px] h-[157px]"
											icon={<Icons iconName="fileUpload" color={MET_COLORS.DARK_GRAY} />}
											name={FORM_FIELDS_NAMES.LOCATION_LOGO}
											onChangeCb={async (file) => {
												const mimeType = file?.type || '';
												const base64String = await General.fileToBase64(file);
												const base64 = `data:${mimeType};base64,${base64String}`;
												setFieldValue(FORM_FIELDS_NAMES.LOCATION_LOGO, base64);
											}}
										/>
									</div>
									<div className="w-[85%]">
										<div
											className=" flex column-gap-10px flex-wrap border-b-[1px] border-b-[#00000029] p-4"
											style={{ rowGap: '10px' }}
										>
											<div className="w-full">
												<Input
													label="Location Name"
													isRequired={true}
													placeholder="Enter Name"
													name={FORM_FIELDS_NAMES.NAME}
													value={values[FORM_FIELDS_NAMES.NAME]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.NAME, e.target.value);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Location ID"
													isRequired={false}
													placeholder="Enter"
													name={FORM_FIELDS_NAMES.LOCATION_ID}
													value={values[FORM_FIELDS_NAMES.LOCATION_ID]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.LOCATION_ID, e.target.value);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Contact Number"
													isRequired={true}
													placeholder="Enter Number"
													name={FORM_FIELDS_NAMES.CONTACT_NUMBER}
													value={values[FORM_FIELDS_NAMES.CONTACT_NUMBER]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.CONTACT_NUMBER,
															Number(e.target.value)
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Contact Person"
													isRequired={true}
													placeholder="Enter"
													name={FORM_FIELDS_NAMES.CONTACT_PERSON}
													value={values[FORM_FIELDS_NAMES.CONTACT_PERSON]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.CONTACT_PERSON, e.target.value);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Place of Service"
													isRequired={true}
													name={FORM_FIELDS_NAMES.PLACEOFSERVICE}
													value={values[FORM_FIELDS_NAMES.PLACEOFSERVICE]}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_NAMES.PLACEOFSERVICE, val);
													}}
													options={[
														{ label: '11-Office', value: 'Office' },
														{ label: '12-Home', value: 'Home' },
														{
															label: '13-Assisted Lining Facility',
															value: 'assisteLiningFacility',
														},
													]}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Specility Type"
													isRequired={false}
													isMultiSelect={true}
													name={FORM_FIELDS_NAMES.SPECIALITY}
													value={values[FORM_FIELDS_NAMES.SPECIALITY] || []}
													onChangeCb={(val) => {
														const values = val.map((item) => item?.value);
														setFieldValue(FORM_FIELDS_NAMES.SPECIALITY, values);
													}}
													options={providerSepcialities}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Email"
													isRequired={true}
													placeholder="Enter Email"
													name={FORM_FIELDS_NAMES.EMAIL}
													value={values[FORM_FIELDS_NAMES.EMAIL]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.EMAIL, e.target.value);
													}}
												/>
											</div>
											{/* <div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Time Zone"
													isRequired={true}
													name={FORM_FIELDS_NAMES.TIMEZONE}
													value={values[FORM_FIELDS_NAMES.TIMEZONE]}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_NAMES.TIMEZONE, val);
													}}
													options={[
														{ label: 'Eastern Standard Time(EST)', value: 'est' },
														{ label: 'Indian Standard Time(IST)', value: 'ist' },
														{ label: 'Central Standard Time(CST)', value: 'cst' },
														{ label: 'Pacific Standard Time(PST)', value: 'pst' },
														{ label: 'Coordinated Standard Time(UST)', value: 'ust' },
													]}
												/>
											</div> */}
											<div className="w-[32.5%]">
												<Input
													label="MLC"
													isRequired={false}
													placeholder="MLC"
													name={FORM_FIELDS_NAMES.GROUP_NPI_NUMBER}
													value={values[FORM_FIELDS_NAMES.GROUP_NPI_NUMBER]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.GROUP_NPI_NUMBER,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Fax ID"
													isRequired={false}
													placeholder="Enter Fax"
													name={FORM_FIELDS_NAMES.FAX}
													value={values[FORM_FIELDS_NAMES.FAX]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.FAX, e.target.value);
													}}
												/>
											</div>
											<div className="w-full">
												<Input
													label="Information"
													isRequired={false}
													placeholder="Enter information"
													name={FORM_FIELDS_NAMES.INFORMATION}
													value={values[FORM_FIELDS_NAMES.INFORMATION]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.INFORMATION, e.target.value);
													}}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="">
									<Heading type={HEADING.H1} color="met-primary" fontSize={'md'} fontWeight="bold">
										Address
									</Heading>
									<div className="flex flex-col w-full border-[1px] p-6 mt-2 rounded-[5px] shadow-[0px_0px_6px_#00000029]">
										<Heading type={HEADING.H1} fontSize={'md'} fontWeight="bold">
											Physical Address
										</Heading>
										<div
											className="w-full flex column-gap-10px flex-wrap mt-2"
											style={{ rowGap: '10px' }}
										>
											<div className="w-[32.5%]">
												<Input
													label="Address 1"
													isRequired={true}
													placeholder="Address 1"
													name={FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_1}
													value={values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_1]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_1,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Address 2"
													isRequired={false}
													placeholder="Address 2"
													name={FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_2}
													value={values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_2]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_2,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="City"
													isRequired={true}
													placeholder="City"
													name={FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_CITY}
													value={values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_CITY]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_CITY,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="State"
													isRequired={true}
													name={FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_STATE}
													value={values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_STATE]}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_STATE, val);
													}}
													options={indianStates}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Country"
													isRequired={false}
													placeholder="Country"
													name={FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_COUNTRY}
													value={values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_COUNTRY]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_COUNTRY,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Zip Code"
													isRequired={true}
													placeholder="Zip Code"
													name={FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_ZIP}
													value={values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_ZIP]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_ZIP,
															e.target.value
														);
													}}
												/>
											</div>
										</div>
									</div>
									<div className="flex flex-col w-full border-[1px] p-6 mt-4 rounded-[5px] shadow-[0px_0px_6px_#00000029]">
										<div className="flex justify-between w-1/4">
											<Heading type={HEADING.H1} fontSize={'md'} fontWeight="bold">
												Billing Address
											</Heading>
											{/* <Checkbox
												label={'same as physical address'}
												style={{ color: 'gray-500' }}
												checked={isSameAsPhysicalAddress === true ? true : false}
												onChangeCb={(e) => {
													setISSameAsPhysicialAddress(!isSameAsPhysicalAddress);
													if (e) {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_1,
															values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_1]
														);
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_2,
															values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_2]
														);
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY,
															values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_CITY]
														);
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE,
															values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_STATE]
														);
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY,
															values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_COUNTRY]
														);
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP,
															values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_ZIP]
														);
													} else {
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_1, '');
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_2, '');
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY, '');
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE, '');
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY, '');
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP, '');
													}
												}}
											/> */}
										</div>
										<div
											className="w-full flex column-gap-10px flex-wrap mt-2"
											style={{ rowGap: '10px' }}
										>
											<div className="w-[32.5%]">
												<Input
													label="Address 1"
													isRequired={true}
													placeholder="Address 1"
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_1}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_1]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_1,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Address 2"
													isRequired={false}
													placeholder="Address 2"
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_2}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_2]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_2,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="City"
													isRequired={true}
													placeholder="City"
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="State"
													isRequired={true}
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE]}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE, val);
													}}
													options={indianStates}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Country"
													isRequired={false}
													placeholder="Country"
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Zip Code"
													isRequired={true}
													placeholder="Zip Code"
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP,
															e.target.value
														);
													}}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="mt-4">
									<Heading type={HEADING.H1} color="met-primary" fontSize={'md'} fontWeight="bold">
										Provider Group Office Hours
									</Heading>

									<div className="w-full flex-wrap border-[1px] p-4 mt-2 rounded-[5px] shadow-[0px_0px_6px_#00000029] ">
										{values[FORM_FIELDS_NAMES.OFFICE_HOURS]?.map((item, index) => (
											<div
												className="w-[45%]  flex column-gap-10px p-[10px]"
												key={index}
												style={{ rowGap: '10px' }}
											>
												<div className="mt-3 w-[12%]">
													<ToggleSwitch
														name={`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].active`}
														checked={((item.from && item.to) || item['active'] === true) ? true : false}
														onChangeCb={(val) => {															
															setFieldValue(
																`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].active`,
																val
															);

															if (val === false) {
																setFieldValue(
																	`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].from`,
																	undefined
																);
																setFieldValue(
																	`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].to`,
																	undefined
																);
																setFieldValue(
																	`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].openingTime`,
																	undefined
																);
																setFieldValue(
																	`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].closingTime`,
																	undefined
																);
															}
								
														}}
													/>
												</div>
												<div className="mt-3 w-[20%]">
													<span>{General.camelToPascalWithSpaces(item?.day)}</span>
												</div>
												<div className="w-[30%]">
													<DatePicker
														placeholder="Select Time"
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={30}
														timeCaption="Time"
														dateFormat="h:mm aa"
														name={`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].from`}
														value={item['from']}
														onChangeCb={(time) => {
															setFieldValue(
																`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].from`,
																time
															);
														}}
														iconName="scheduleIcon"
													/>
												</div>
												<div className="w-[30%]">
													<DatePicker
														placeholder="Select Time"
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={30}
														timeCaption="Time"
														dateFormat="h:mm aa"
														name={`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].to`}
														value={item['to']}
														onChangeCb={(time) => {
															setFieldValue(
																`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].to`,
																time
															);
														}}
														iconName="scheduleIcon"
													/>
												</div>
												{/* <div className="w-[30%]">
													<Input
														// label="Zip Code"
														// isRequired={true}
														placeholder="from"
														endIcon={values[item?.from]}
														name={`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].from`}
														value={item['from']}
														onChangeCb={(e) => {
															setFieldValue(
																`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].from`,
																e.target.value
															);
														}}
													/>
												</div>
												<div className="w-[30%]">
													<Input
														// label="Zip Code"
														// isRequired={true}
														placeholder="to"
														endIcon={values[item?.to]}
														name={`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].to`}
														value={item['to']}
														onChangeCb={(e) => {
															setFieldValue(
																`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].to`,
																e.target.value
															);
														}}
													/>
												</div> */}
											</div>
										))}
									</div>
								</div>
							</div>
						</ModalComponent>
					</Form>
				);
			}}
		</Formik>
	);
};
export default LocationModal;

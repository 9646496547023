import React, { useEffect, useState } from 'react';
import './styles.scss';
import Button from '../button/Button';
import { BUTTON_VARIANTS } from '../button/Constants';

function ToggleButton({
	options = [],
	activeLabel = '',
	inactiveLabel = '',
	onChangeCb = () => {},
	value = '',
	label = '',
}) {
	return (
		<div className="mt-2">
			<div className="font-bold pb-1">{label}</div>
			<div className="flex gap-3">
				{options.map((option, index) => {
					return (
						<Button
							key={index + 'label'}
							onClickCb={() => onChangeCb(option)}
							variant={value === option.value ? BUTTON_VARIANTS.CONTAINED : BUTTON_VARIANTS.OUTLINED_GRAY}
							customBtnClass="justify-center px-4"
						>
							{option.label}
						</Button>
					);
				})}
			</div>
		</div>
	);
}

export default ToggleButton;

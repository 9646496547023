import React, { useEffect, useState } from 'react'
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import Icons from '../../../../../assets/svgs';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import General from '../../../../../libs/utility/General';
import { componentKey as RouterComponentKey } from '../../../../../routes/RoutesSlice'
import { componentKey as dataLoaderComponentKey } from '../../../../Wrappers/Dataloader/DataloaderSlice'
import { useDispatch, useSelector } from 'react-redux';
import { componentKey as AvailabilityComponentKey, setSelectedLocations, setSelectedProvider } from './AvailabilitySlice'
import { getAvailability } from './AvailabilitySaga';
import DatePicker from '../../../../../components/common-components/datePicker';
const CustomHeader = ({ date = new Date(), onNavigate = () => { } }) => {
	const dispatch = useDispatch()

	const { isAdminUser, loggedInUser, loggedInProviderUser } = useSelector(state => state[RouterComponentKey])
	const { providersList } = useSelector(state => state[dataLoaderComponentKey])
	const { selectedProvider, providerLocationsData, selectedLocations } = useSelector(state => state[AvailabilityComponentKey])

	const [selectedMonth, setSelectedMonth] = useState(date)

	useEffect(() => {
		setSelectedMonth(date)
	}, [date])

	const handleTodayClick = () => {
		const today = new Date();
		onNavigate('TODAY', today);
	};

	const handlePrev = () => {
		onNavigate('PREV');
	};

	const handleNext = () => {
		onNavigate('NEXT');
	};

	const handleMonth = (date) => {
		setSelectedMonth(date)
		onNavigate('DATE', date)
	};

	const selecteProviderHandler = (provider) => {
		dispatch(setSelectedProvider(provider))
		if (provider?.value)
			dispatch(getAvailability({ providerId: provider.value, locationIds: {} }))
	}

	// const formattedLabel = `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;

	return (
		<div className="flex mb-1 justify-between py-5 availability-custom-header">
			<div className="flex items-center space-x-1 date-navigation-container">
				<Button variant={BUTTON_VARIANTS.OUTLINED} onClickCb={handleTodayClick} customBtnClass="bg-white">
					Today
				</Button>
				<Button variant={BUTTON_VARIANTS.TEXT} onClickCb={handlePrev}>
					<Icons iconName="settingsLeftArrowIcon" />
				</Button>
				<Button variant={BUTTON_VARIANTS.TEXT} onClickCb={handleNext}>
					<Icons iconName="settingsRightArrowIcon" />
				</Button>
				<DatePicker
					showYearDropdown={false}
					showMonthYearPicker={true}
					value={selectedMonth}
					onChangeCb={(date) => handleMonth(date)}
				></DatePicker>
			</div>

			<div className="flex justify-between button-group-container">
				<SelectDropdown
					placeholder="Select Provider"
					toggleIcon={<Icons iconName="settingsProviderIcon" />}
					value={selectedProvider}
					onChangeCb={(val) => {
						selecteProviderHandler(val)
						dispatch(setSelectedLocations([]))
					}}
					options={isAdminUser ? General.addLableValuePair(providersList, 'firstName', 'uuid', 'lastName') : [selectedProvider]}
					disabled={!isAdminUser}
				/>

				<SelectDropdown
					placeholder="Select Location"
					toggleIcon={<Icons iconName="settingsLocationIcon" />}
					customClasses='pl-5'
					isMultiSelect={true}
					value={selectedLocations || []}
					onChangeCb={(val) => {
						const values = val.map((item) => item?.value);
						dispatch(setSelectedLocations(values))
						let locationIds = values && values.length ? { locationIds: values } : {}
						dispatch(getAvailability({ providerId: selectedProvider.value, locationIds }))
					}}
					options={providerLocationsData?.map(location => ({ 'label': location.provider_locations?.name, 'value': location.provider_locations?.uuid }))}
				/>
			</div>
			{/* <CommonDialog
				title="Schedule New Appointment"
				onClose={() => {
					setNewAppointmentModalState(false);
				}}
				open={newAppointmentModalState}
			>
				<NewAppointmentComponent />
			</CommonDialog>
			<CommonDialog
				title="Schedule Instant Appointment"
				onClose={() => {
					setInstantAppointmentModalState(false);
				}}
				open={instantAppointmentModalState}
			>
				<InstantAppointmentComponent />
			</CommonDialog> */}
		</div>
	);
};

export default CustomHeader;

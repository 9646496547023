import { DialoutState } from "@zoom/videosdk"
export const SELF_VIDEO_ID = 'ZOOM_WEB_SDK_SELF_VIDEO'

const statusDescription = {
    [DialoutState.Calling]: { text: 'Calling', type: 'pending' },
    [DialoutState.Ringing]: { text: 'Ringing', type: 'pending' },
    [DialoutState.Accepted]: { text: 'Accepted', type: 'pending' },
    [DialoutState.Canceling]: { text: 'Cencelling', type: 'pending' },
    [DialoutState.Busy]: { text: 'Busy', type: 'fail' },
    [DialoutState.Fail]: { text: 'Fail', type: 'fail' },
    [DialoutState.CancelFailed]: { text: 'Cancel failed', type: 'fail' },
    [DialoutState.NotAvailable]: { text: 'NotAvailable', type: 'fail' },
    [DialoutState.Success]: { text: 'Success', type: 'success' },
    [DialoutState.Canceled]: { text: 'Canceled', type: 'success' }
};

export const getPhoneCallStatusDescription = (status) => {
    if (status !== undefined) {
        return statusDescription[status];
    }
    return undefined;
};

export const SHARE_CANVAS_ID = 'ZOOM_WEB_SDK_SHARER_CANVAS';

export const ShareViewType = {
    FitWindow: 'fit',
    OriginalSize: 'original'
};

export const PAGE_STATE = {
    PRE_LOADING: 'preloading',
    LOADING: 'loading',
    PAGE_READY: 'ready',
}

export const BUTTON_TYPE = {
    PRIMARY: "primary",
    PRIMARY_LIGHT_WITH_BORDER: "primary-light-with-border",
    LIGHT_WITH_NO_BORDER: "light-with-no-border",
    DANGER: "danger",
    BUTTON_WITH_LIGHT_COLOR: "button-with-light-color",
    BUTTON_WITH_LIGHT_COLOR_NO_ICON: "button-with-light-color-no-icon",
    BUTTON_WITH_GRAY_COLOR: "button-with-gray-color",
    BUTTON_WITH_LIGHT_COLOR_BG: "button-with-light-color-bg",
    BUTTON_WITH_RED_COLOR_BG: "button-with-red-color-bg",
    BUTTON_WITH_LIGHT_COLOR_TOOGLE: "primary-light-with-border-btn-toogle",
    BUTTON_WITHOUT_BORDER: "light-without-border-btn",
    BUTTON_WITH_BOX_SHADOW: "button-with-box-shadow",
    BUTTON_WITHOUT_BG_COLOR_TOGGLE: "button-without-bg-color-toggle",
    BUTTON_WITHOUT_ANY_COLOR_TOGGLE: "button-without-any-color-toggle"
}

export const HEADING = {
    H1: 'H1',
    H2: 'H2',
    H3: 'H3'
}
import React, { useEffect, useState } from 'react';
import AddNewAvailabilityModal from './AddNewAvailabilityModal';
import { FORM_FIELDS_NAMES, editAvailabilityScheduleAppData } from '../constants';
import Icons from '../../../../../assets/svgs';
import moment from 'moment';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey } from './AvailabilitySlice';
import { componentKey as AvailabilityComponentKey } from '../../Tabs/Availability/AvailabilitySlice'
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import DatePicker from '../../../../../components/common-components/datePicker';
import { postAvailabilityForADay } from './AvailabilitySaga';
import { getValidationSchema } from '../../../../../libs/formsUtils';
import { customAvailabilityMessage } from '../../../utils';

const fields = [
	{ fieldName: FORM_FIELDS_NAMES.AVAILABILITY_LOCATION_ID, isDropdown: true },
	{ fieldName: FORM_FIELDS_NAMES.START_TIME, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.END_TIME, isRequired: true }
];

const initialValues = {
	date: '',
	startTime: '',
	endTime: '',
	availabilityLocationId: '',
	day: '',
	providerId: ''
};

const EditAvailabilityModal = ({ open, customClasses, close, customBodyClasses }) => {
	const { availiabilityData, selectedProvider, viewAllModalSelectedSlotData } = useSelector(state => state[componentKey])
	const { providerLocationsData } = useSelector(state => state[AvailabilityComponentKey])
	const [eventsForSelectedDate, setEventsForSelectedDate] = useState([])

	const validationSchema = getValidationSchema(fields)
	const dispatch = useDispatch();

	useEffect(() => {
		const selectedDateEvents = availiabilityData[0]?.provider_availability_slots?.filter(availability => availability.date === moment(viewAllModalSelectedSlotData?.start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('DD-MM-YYYY'))
		selectedDateEvents?.sort((a, b) => {
			const startTimeA = a.startTime;
			const startTimeB = b.startTime;
			return startTimeA - startTimeB;
		});
		const filteredEventsData = selectedDateEvents?.filter(item => {
			const startTime = item.startTime;
			return startTime >= moment().format('hh:mm A');
		});
		setEventsForSelectedDate(filteredEventsData)

	}, [viewAllModalSelectedSlotData])

	return <Formik
		initialValues={initialValues}
		validationSchema={validationSchema}
		enableReinitialize
		onSubmit={(formvals) => {
			const data = {
				...formvals,
				availabilityLocationId: formvals.availabilityLocationId?.value,
				startTime: formvals.startTime ? moment(formvals.startTime).format('hh:mm A') : '',
				endTime: formvals.endTime ? moment(formvals.endTime).format('hh:mm A') : '',
				providerId: selectedProvider.value,
				date: moment(viewAllModalSelectedSlotData?.start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
				day: moment(viewAllModalSelectedSlotData?.start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('dddd')
			}
			dispatch(postAvailabilityForADay({ availabilityData: data }))
		}}
	>
		{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {

			return <Form onSubmit={handleSubmit} >
				<ModalComponent
					open={open}
					title={viewAllModalSelectedSlotData && viewAllModalSelectedSlotData.start ? customAvailabilityMessage(viewAllModalSelectedSlotData?.start, true) : ''}
					customClasses={customClasses}
					close={() => close()}
					customBodyClasses={customBodyClasses}
					footerButton={<Button type='submit' variant={BUTTON_VARIANTS.CONTAINED}>Save</Button>}
				>
					<div className="max-h-[100%] overflow-y-auto scrollbar-h-2 px-2 pb-[10px] text-rgba-1a1a1acc">
						<div className="mt-6">
							<p className="text-rgba-00B917 text-[20px] font-medium">Current Availability</p>
						</div>
						<div className="mt-6">
							<p className="text-rgba-1a1a1acc font-medium text-[18px] flex">
								{`${moment(viewAllModalSelectedSlotData?.start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('hh:mm A')} - ${moment(viewAllModalSelectedSlotData?.end, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('hh:mm A')}`} <span className="text-met-dimmed-charcoal ml-2">{viewAllModalSelectedSlotData?.title}</span>
							</p>
						</div>
						<div className="mt-6">
							<p className="text-rgba-FFAA00 text-[20px] font-medium">Scheduled Appointment</p>
						</div>
						<div className="">
							{eventsForSelectedDate?.length ? eventsForSelectedDate?.map((item) => (
								<div key={item.startTime} className="text-rgba-1a1a1acc font-medium text-[18px] flex mt-4">
									<p>{item.startTime}</p>
									<span className="ml-10">{item.virtual ? <Icons iconName="settingsVideoIcon" /> : null}</span>
									<p className="ml-2">{item.pName}</p>
								</div>
							)) : <div className="text-rgba-1a1a1acc font-medium text-[18px] flex mt-4">No Upcoming appointments</div>}
						</div>
						<div className="mt-6">
							<div
								className={"max-h-[100%] overflow-y-auto scrollbar-h-2 px-2 pb-[10px] text-rgba-1a1a1acc"}
							>
								<div className="mt-6">
									<SelectDropdown
										placeholder="Select Location"
										label="Location"
										toggleIcon={<Icons iconName="settingsLocationIcon" />}
										isRequired={true}
										name={`availabilityLocationId`}
										value={values.availabilityLocationId}
										onChangeCb={(val) => { setFieldValue(`availabilityLocationId`, val) }}
										options={providerLocationsData?.map(location => ({ 'label': location.provider_locations.name, 'value': location.provider_locations.uuid }))}
									/>
								</div>
								<div className="flex justify-between mt-6">
									<div className="flex-1 mr-5">
										<DatePicker
											name={`startTime`}
											placeholder='Start Time'
											label='Start Time'
											showTimeSelect
											showTimeSelectOnly
											isRequired={true}
											timeIntervals={30}
											timeCaption="Time"
											dateFormat="h:mm aa"
											value={values.startTime}
											onChangeCb={(time) => {
												setFieldValue(`startTime`, time)
												if (time && values.endTime && new Date(time).toISOString() > new Date(values.endTime).toISOString()) {
													setFieldValue(`endTime`, '')
												}
											}}
											iconName='scheduleIcon'
										/>
									</div>
									<div className="flex-1">
										<DatePicker
											name={`endTime`}
											placeholder='End Time'
											label='End Time'
											showTimeSelect
											showTimeSelectOnly
											isRequired={true}
											timeIntervals={30}
											minTime={values.startTime ? new Date(values.startTime).setMinutes(new Date(values.startTime).getMinutes() + 30) : new Date().setHours(0, 0, 0, 0)}
											maxTime={new Date().setHours(23, 59)}
											timeCaption="Time"
											dateFormat="h:mm aa"
											value={values.endTime}
											onChangeCb={(time) => {
												setFieldValue(`endTime`, time)
											}}
											iconName='scheduleIcon'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ModalComponent>
			</Form>
		}}
	</Formik >
};

export default EditAvailabilityModal;

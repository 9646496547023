import { useDispatch, useSelector } from "react-redux";
import { HEADING } from "../../../../components/common-components/heading/Constants";
import Heading from "../../../../components/common-components/heading/Heading";
import Label from "../../../../components/common-components/label/Label";
import Table from "../../../../containers/Table/Index";
import { componentKey,  setPatientBillingPaginationState } from "./PatientBillingslice";
import { useEffect } from "react";
import { getAllPatientPayment } from "./PatientBillingSaga";
import moment from "moment";
import Avatar from "../../../../components/common-components/avatar";
import ButtonDropdown from "../../../../components/common-components/buttonDropdown";
import { useParams } from "react-router-dom";
import PatientReceiptModal from "../../../Billing/Payments/PatientReceiptModal";
import { setIsReciptModalOpen, setPatientReceiptData } from "../../../Billing/Payments/PatientPaymentSlice";
import { componentKey as PatientPaymentComponentKey} from "../../../Billing/Payments/PatientPaymentSlice"

const PatientBillingTable = () => {
    const { patientBillingList, patientBillingPaginationState} = useSelector((state) => state[componentKey]);
    const { isReceiptModalOpen } = useSelector(((state) => state[PatientPaymentComponentKey]))

    const dispatch = useDispatch()
	const { patientId } = useParams();


    useEffect(() => {
        const params = {
            pageNumber: patientBillingPaginationState.pageNumber,
            limit: patientBillingPaginationState.limit,
            name: (patientBillingPaginationState?.name || "").toLowerCase(),
            receipt: patientBillingPaginationState.receipt,
            date: patientBillingPaginationState.date,
        }
        dispatch(getAllPatientPayment({patientId,params}));
    }, [
        dispatch,
        patientBillingPaginationState.limit,
        patientBillingPaginationState.pageNumber,
        patientBillingPaginationState.name,
        patientBillingPaginationState.receipt,
        patientBillingPaginationState.date,
    ]);
       

    const handleReceiptView = (row) => {
        dispatch(setIsReciptModalOpen(true))
        dispatch(setPatientReceiptData(row))
    }


    const coloumns = [
        {
            field: 'id',
            label: <Label fontWeight="font-bold">Payment ID</Label>,
            renderLogic: (row) => {
                const uuid = row?.uuid;
                const paymentId = uuid.substring(0, 6);
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {paymentId}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'date',
            label: <Label fontWeight="font-bold">Payment Date</Label>,
            renderLogic: (row) => {
                const paymentDate = row.paymentDate ? moment(row?.paymentDate).format('DD-MM-YYYY') : '-';
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color='met-primary'
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {paymentDate}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'receipt',
            label: <Label fontWeight="font-bold">Receipt #</Label>,
            renderLogic: (row) => {
                const uuid = row?.uuid;
                const receipt = `RE${uuid.substring(uuid.length - 4)}`;
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="met-primary"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                   // onClickCb={() => handleReceiptView(row)}
                                >
                                    {receipt}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'name',
            label: <Label fontWeight="font-bold">Patient Name</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <Avatar
                                customClass="w-[40px] h-[40px] rounded-full"
                                url={row?.profilePicture}
                                name={row.patient?.firstName + ' ' + row.patient?.lastName}
                            />
                            <div className="pl-4">
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.patient?.firstName + ' ' + row.patient?.lastName}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'provider',
            label: <Label fontWeight="font-bold">Provider Name</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.provider?.firstName + ' ' + row.provider?.lastName}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'method',
            label: <Label fontWeight="font-bold">Payment Method</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.method}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'card',
            label: <div>
                <Label fontWeight="font-bold">Cheque No/Card Last 4 Digit</Label>
            </div>,
            renderLogic: (row) => {
                const checkNo = row?.checkNo;
                const cheque = checkNo ? checkNo.substring(checkNo.length - 4) : '-';
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {cheque}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'paid',
            label: <Label fontWeight="font-bold">Paid Amount</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.amount}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'action',
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.action}
                                </Heading>
                            </div>
                            <ButtonDropdown
                                customBtnClass="p-0"
                                endIcon="moreVertIcon"
                                options={[
                                    { label: 'View Details', value: 'viewDetails', onChangeCb: () => handleReceiptView(row) },

                                    // {label: 'Edit & Apply Payment', value: null, onChangeCb: () => handleEditClick(row) },
                                ]}
                                selectCb={(option) => {
                                    option?.onChangeCb();
                                }}
                            />
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="p-4">
                <Table
                    isPagination={true}
                    coloumns={coloumns}
                    rows={patientBillingList?.payments}
                    paginationProps={{
                        isPagination: true,
                        total: patientBillingPaginationState.totalRecords,
                        limit: patientBillingPaginationState.limit,
                        onPageChange: (page) => {
                            dispatch(setPatientBillingPaginationState({ pageNumber: page }));
                        },
                    }}
                    
                />
            </div>
            {isReceiptModalOpen ? (
                <PatientReceiptModal open={isReceiptModalOpen} close={() => { dispatch(setIsReciptModalOpen(false)) }} />
            ) : null}

        </>
    )

}

export default PatientBillingTable;
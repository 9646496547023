import store from "../../../../store/store";

export const componentKey = 'PORTAL_ACCESS';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setOpenSuccessModal: (state, action) => {
            state.openSuccessModal = action.payload;
        },
    },
    initialReducerState: {
        openSuccessModal: false
    },
});

export const { setOpenSuccessModal } = actions;
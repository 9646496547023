import React, { useState, useRef } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import classNames from 'classnames';
import Draggable from 'react-draggable';
import { Modal } from 'reactstrap';
import './share-indication.scss';
import { getAntdItem } from '../../helper/chat-utils';

const ShareIndicationBar = (props) => {
    const { sharUserList, activeSharingId, isControllingUser, viewType, setViewType, mediaStream } = props;
    const draggableRef = useRef(null);
    const [mutedShareAudioList, setMutedShareAudioList] = useState([]);
    const activeUser = (sharUserList ?? []).find((user) => user.userId === activeSharingId);

    const menuItems = [
        getAntdItem(
            'View',
            'view',
            undefined,
            [
                getAntdItem('Fit to Window', 'view|fit', 'view|fit'.endsWith(viewType) && "vg"),
                getAntdItem('Original Size', 'view|original', 'view|original'.endsWith(viewType) && "Vh")
            ],
            'group'
        )
    ];

    if (mediaStream?.isRemoteControlEnabled() && mediaStream?.isTargetShareSupportRemoteControl(activeSharingId)) {
        menuItems.push(getAntdItem('', 'd1', undefined, undefined, 'divider'));
        menuItems.push(
            getAntdItem(isControllingUser ? 'Give up Remote Control' : 'Request Remote Control', 'remote control')
        );
    }

    if (activeUser?.bShareAudioOn) {
        menuItems.push(getAntdItem('', 'd2', undefined, undefined, 'divider'));
        menuItems.push(
            getAntdItem(
                `${mediaStream?.isOthersShareAudioMutedLocally(activeSharingId) ? 'Unmute' : 'Mute'} ${activeUser.displayName
                }'s Computer Audio`,
                'share audio'
            )
        );
    }

    if ((sharUserList ?? []).length > 1) {
        menuItems.push(getAntdItem('', 'd3', undefined, undefined, 'divider'));
        menuItems.push(
            getAntdItem(
                'Shared Screens',
                'share users',
                undefined,
                (sharUserList ?? []).map((user) =>
                    getAntdItem(user.displayName, `share|${user.userId}`, activeSharingId === user.userId && "VB")
                ),
                'group'
            )
        );
    }

    const onMenuClick = (payload) => {
        const { key } = payload;
        if (key.startsWith('view|')) {
            const [, type] = key.split('|');
            setViewType(type);
        } else if (key.startsWith('share|')) {
            const [, shareUserId] = key.split('|');
            if (Number(shareUserId) !== activeSharingId) {
                mediaStream?.switchShareView(Number(shareUserId));
            }
        } else if (key === 'share audio') {
            if (mediaStream?.isOthersShareAudioMutedLocally(activeSharingId)) {
                mediaStream.unmuteShareAudio(activeSharingId);
                setMutedShareAudioList(mutedShareAudioList.filter((u) => u !== activeSharingId));
            } else {
                mediaStream?.muteShareAudio(activeSharingId);
                setMutedShareAudioList([...mutedShareAudioList, activeSharingId]);
            }
        } else if (key === 'remote control') {
            if (isControllingUser) {
                mediaStream?.giveUpRemoteControl();
            } else {
                Modal.confirm({
                    title: 'Remote Control',
                    content: `You are about to request remote control of ${activeUser?.displayName}'s shared content`,
                    okText: 'Request Remote Control',
                    cancelText: 'Cancel',
                    onOk: () => {
                        mediaStream?.requestRemoteControl();
                    }
                });
            }
        }
    };

    return (
        <Draggable nodeRef={draggableRef} axis="x" bounds="parent">
            <div className="share-indication-bar" ref={draggableRef}>
                <p className={classNames('share-indication-tip m-0', { 'share-indication-in-control': isControllingUser })}>
                    {activeUser?.bShareAudioOn && (
                        mutedShareAudioList.includes(activeSharingId) ? 'icon-audio-off' : 'icon-audio-on'
                    )}
                    {`You are ${isControllingUser ? 'controlling' : 'viewing'} ${activeUser?.displayName}'s screen`}
                </p>
                {/* <Dropdown isOpen={false} toggle={() => { }} direction="down">
                    <DropdownToggle size="sm" className={classNames('share-bar-btn', 'share-bar-more')} color="secondary">
                        View Options
                    </DropdownToggle>
                    <DropdownMenu>
                        {menuItems.map(item => (
                            <DropdownItem key={item.key} onClick={() => onMenuClick(item)}>
                                {item.text}
                                {item.icon}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown> */}
            </div>
        </Draggable>
    );
};

export default ShareIndicationBar;

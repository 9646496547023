export const SettingsAppointmentIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
			<g id="Group_96322" data-name="Group 96322" transform="translate(-48.4 -132)">
				<rect
					id="Rectangle_36519"
					data-name="Rectangle 36519"
					width="70"
					height="70"
					rx="5"
					transform="translate(48.4 132)"
					fill="rgba(204,236,255,0.5)"
				/>
				<g id="appointment_1_" data-name="appointment(1)" transform="translate(61.955 145.555)">
					<path
						id="Path_205949"
						data-name="Path 205949"
						d="M41.635,2.681H37.781V1.257a1.257,1.257,0,1,0-2.513,0V2.681H30.242V1.257a1.257,1.257,0,1,0-2.513,0V2.681H22.7V1.257a1.257,1.257,0,1,0-2.513,0V2.681H15.163V1.257a1.257,1.257,0,0,0-2.513,0V2.681H7.623V1.257a1.257,1.257,0,1,0-2.513,0V2.681H1.257A1.257,1.257,0,0,0,0,3.937v37.7a1.257,1.257,0,0,0,1.257,1.257H41.635a1.257,1.257,0,0,0,1.257-1.257V3.937A1.257,1.257,0,0,0,41.635,2.681ZM5.11,5.194V6.45a1.257,1.257,0,1,0,2.513,0V5.194H12.65V6.45a1.257,1.257,0,0,0,2.513,0V5.194h5.026V6.45a1.257,1.257,0,1,0,2.513,0V5.194h5.026V6.45a1.257,1.257,0,1,0,2.513,0V5.194h5.026V6.45a1.257,1.257,0,1,0,2.513,0V5.194h2.6V10.22H2.513V5.194Zm-2.6,35.184V12.733H40.378V40.378Z"
						fill="#1b5984"
					/>
					<path
						id="Path_205950"
						data-name="Path 205950"
						d="M97.283,212H92.257A1.257,1.257,0,0,0,91,213.257v5.026a1.257,1.257,0,0,0,1.257,1.257h5.026a1.257,1.257,0,0,0,1.257-1.257v-5.026A1.257,1.257,0,0,0,97.283,212Zm-1.257,5.026H93.513v-2.513h2.513Z"
						transform="translate(-83.377 -194.24)"
						fill="#1b5984"
					/>
					<path
						id="Path_205951"
						data-name="Path 205951"
						d="M337.283,212h-5.026A1.257,1.257,0,0,0,331,213.257v5.026a1.257,1.257,0,0,0,1.257,1.257h5.026a1.257,1.257,0,0,0,1.257-1.257v-5.026A1.257,1.257,0,0,0,337.283,212Zm-1.257,5.026h-2.513v-2.513h2.513Z"
						transform="translate(-303.272 -194.24)"
						fill="#1b5984"
					/>
					<path
						id="Path_205952"
						data-name="Path 205952"
						d="M217.283,331h-5.026A1.257,1.257,0,0,0,211,332.257v5.026a1.257,1.257,0,0,0,1.257,1.257h5.026a1.257,1.257,0,0,0,1.257-1.257v-5.026A1.257,1.257,0,0,0,217.283,331Zm-1.257,5.026h-2.513v-2.513h2.513Z"
						transform="translate(-193.324 -303.272)"
						fill="#1b5984"
					/>
					<path
						id="Path_205953"
						data-name="Path 205953"
						d="M217.283,212h-5.026A1.257,1.257,0,0,0,211,213.257v5.026a1.257,1.257,0,0,0,1.257,1.257h5.026a1.257,1.257,0,0,0,1.257-1.257v-5.026A1.257,1.257,0,0,0,217.283,212Zm-1.257,5.026h-2.513v-2.513h2.513Z"
						transform="translate(-193.324 -194.24)"
						fill="#1b5984"
					/>
					<path
						id="Path_205954"
						data-name="Path 205954"
						d="M337.283,331h-5.026A1.257,1.257,0,0,0,331,332.257v5.026a1.257,1.257,0,0,0,1.257,1.257h5.026a1.257,1.257,0,0,0,1.257-1.257v-5.026A1.257,1.257,0,0,0,337.283,331Zm-1.257,5.026h-2.513v-2.513h2.513Z"
						transform="translate(-303.272 -303.272)"
						fill="#1b5984"
					/>
					<path
						id="Path_205955"
						data-name="Path 205955"
						d="M98.075,342.081a1.257,1.257,0,0,0-1.768.182l-2.555,3.143-.7-.574a1.257,1.257,0,1,0-1.593,1.943l1.675,1.374a1.257,1.257,0,0,0,1.772-.179l3.351-4.122A1.257,1.257,0,0,0,98.075,342.081Z"
						transform="translate(-83.376 -313.167)"
						fill="#1b5984"
					/>
				</g>
			</g>
		</svg>
	);
};

export const FORM_FIELDS_NAMES = {
    DATE: 'date',
    TIME: 'time',
    SYSTOLIC: 'systolic',
    DAISTOLIC: 'daistolic',
    HEART_RATE: 'heartRate',
    RESPIRATORY_RATE: 'respiratoryRate',
    OXYGEN_SATURATION: 'oxygenSaturation',
    BODY_MASS_INDEX: 'BMI',
    FEET: 'feet',
    IN: 'in',
    TEMPERATURE: 'temperature',
    WEIGHT: 'weight',
    NOTE: 'note'
}
import React from 'react'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import RadioButton from '../../../../components/common-components/radioButton/RadioButton'
import Button from '../../../../components/common-components/button/Button'
import Input from '../../../../components/common-components/input/Input'
import TextArea from '../../../../components/common-components/textArea/TextArea'
import Icons from '../../../../components/icons/Icons'
import { COLLECT_PAYMENT_FIELDS_NAMES } from './Constants'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey } from './GenrateSuperBillSlice'
import { getValidationSchema } from '../../../../libs/formsUtils'
import { Form, Formik } from 'formik'
import { postCollectPaymentDetails } from './GenrateSuperBillSaga'
import DatePicker from '../../../../components/common-components/datePicker'
import General from '../../../../libs/utility/General'
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom'

const PAYMENT_METHODS = {
    CASH_PAYMENT: "Cash",
    BANK_PAYMENT: 'Online',
    CARD_PAYMENT: 'Card'
}

const fields = [
    { fieldName: COLLECT_PAYMENT_FIELDS_NAMES.PAYMENT_DATE, isRequired: true },
    { fieldName: COLLECT_PAYMENT_FIELDS_NAMES.METHOD, isRequired: true },
    { fieldName: COLLECT_PAYMENT_FIELDS_NAMES.AMOUNT },
    { fieldName: COLLECT_PAYMENT_FIELDS_NAMES.CHECK_NO },
    { fieldName: COLLECT_PAYMENT_FIELDS_NAMES.TRANSECTIONID },
    { fieldName: COLLECT_PAYMENT_FIELDS_NAMES.NOTE },
    { fieldName: COLLECT_PAYMENT_FIELDS_NAMES.CARD_HOLDER_NAME }
];


const validationSchema = Yup.object().shape({
    ...getValidationSchema(fields).fields,
    [COLLECT_PAYMENT_FIELDS_NAMES.MOBILE_NUMBER]: Yup.string().required().when(COLLECT_PAYMENT_FIELDS_NAMES.METHOD, {
        is: (val) => { return [PAYMENT_METHODS.BANK_PAYMENT, PAYMENT_METHODS.CARD_PAYMENT].includes(val) },
        then: () => { return Yup.string().required(`Contact number is required`) },
        otherwise: (schema) => { return schema.notRequired() },
    }),
    [COLLECT_PAYMENT_FIELDS_NAMES.CARD_HOLDER_NAME]: Yup.string().required().when(COLLECT_PAYMENT_FIELDS_NAMES.METHOD, {
        is: (val) => { return [PAYMENT_METHODS.CARD_PAYMENT].includes(val) },
        then: () => { return Yup.string().required(`Card Holder Name is required`) },
        otherwise: (schema) => { return schema.notRequired() },

    }),
    [COLLECT_PAYMENT_FIELDS_NAMES.TRANSECTIONID]: Yup.string().required().when(COLLECT_PAYMENT_FIELDS_NAMES.METHOD, {
        is: (val) => { return [PAYMENT_METHODS.CARD_PAYMENT].includes(val) },
        then: () => { return Yup.string().required(`TransectionID is required`) },
        otherwise: (schema) => { return schema.notRequired() },

    })

});

function CollectPaymentModal({ open, close, totalBalance, values: FormValues }) {

    const { patientDetails } = useSelector(state => state[componentKey])
    const dispatch = useDispatch()

    const [params, setParams] = useSearchParams()

    const billID = params.get("billid")

    const initialValues = {
        [COLLECT_PAYMENT_FIELDS_NAMES.METHOD]: PAYMENT_METHODS.CASH_PAYMENT,
        [COLLECT_PAYMENT_FIELDS_NAMES.AMOUNT]: totalBalance,
        [COLLECT_PAYMENT_FIELDS_NAMES.MOBILE_NUMBER]: undefined,
        [COLLECT_PAYMENT_FIELDS_NAMES.CARD_HOLDER_NAME]: "",
        [COLLECT_PAYMENT_FIELDS_NAMES.TRANSECTIONID]: "",
        [COLLECT_PAYMENT_FIELDS_NAMES.NOTE]: "",
        [COLLECT_PAYMENT_FIELDS_NAMES.PAYMENT_DATE]: ""
    }

    return (
        <>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    const val = {
                        ...values,
                        providerId: FormValues?.renderingProviderId.value,
                        patientId: patientDetails?.uuid,
                        billid: billID,
                        amount: Number(values?.amount)
                    }

                    dispatch(postCollectPaymentDetails(val))
                }}
            >
                {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                    return <Form onSubmit={handleSubmit} >
                        <ModalComponent open={open} close={close}
                            title="Payment Method"
                            customClasses='w-[38%]'
                            footerButton={<Button type='submit'>Collect Payment</Button>}
                        >
                            <div className='flex justify-center'>
                                <div className='w-[240px] h-[240px] flex justify-center items-center'>
                                    <Icons iconName="paymentIcon" />
                                </div>
                            </div>
                            <div className='font-bold'>
                                Payment Method
                            </div>
                            <div className=''>
                                <div className='mt-3'>
                                    <RadioButton
                                        name={PAYMENT_METHODS.CASH_PAYMENT}
                                        checked={values[COLLECT_PAYMENT_FIELDS_NAMES.METHOD] === PAYMENT_METHODS.CASH_PAYMENT}
                                        label="Cash Payment"
                                        customLabelClass="text-gray-500 font-bold"
                                        onChangeCb={() => { setFieldValue(COLLECT_PAYMENT_FIELDS_NAMES.METHOD, PAYMENT_METHODS.CASH_PAYMENT) }}
                                    />
                                </div>
                                {values[COLLECT_PAYMENT_FIELDS_NAMES.METHOD] === PAYMENT_METHODS.CASH_PAYMENT ?
                                    <div className='w-full'>
                                        <div className='mt-3 flex justify-between column-gap-20px'>
                                            <div className='flex-1'>
                                                <Input
                                                    label='Amount'
                                                    placeholder='Amount'
                                                    name={COLLECT_PAYMENT_FIELDS_NAMES.AMOUNT}
                                                    value={values[COLLECT_PAYMENT_FIELDS_NAMES.AMOUNT]}
                                                    disabled={true}
                                                    onChangeCb={(e) => {
                                                        setFieldValue(`${COLLECT_PAYMENT_FIELDS_NAMES.AMOUNT}`, General.sanitizeNumbers(e.target.value))
                                                    }}
                                                />
                                            </div>
                                            <div className='flex-1'>
                                                <DatePicker label="Date" placeholder='Choose Date'
                                                    name={COLLECT_PAYMENT_FIELDS_NAMES.PAYMENT_DATE}
                                                    isRequired={true}
                                                    maxDate={new Date()}
                                                    value={values[COLLECT_PAYMENT_FIELDS_NAMES.PAYMENT_DATE]}
                                                    onChangeCb={(date) => {
                                                        setFieldValue(COLLECT_PAYMENT_FIELDS_NAMES.PAYMENT_DATE, date)
                                                    }} />
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <div className='font-bold fs-14'>
                                                Notes
                                            </div>
                                            <div className='mt-2'>
                                                <TextArea
                                                    placeholder='Notes'
                                                    name={COLLECT_PAYMENT_FIELDS_NAMES.NOTE}
                                                    value={values[COLLECT_PAYMENT_FIELDS_NAMES.NOTE]}
                                                    onChangeCb={(e) => {
                                                        setFieldValue(`${COLLECT_PAYMENT_FIELDS_NAMES.NOTE}`, e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                                <div className='mt-3'>
                                    <RadioButton
                                        label="Online Payment"
                                        customLabelClass="text-gray-500 font-bold"
                                        checked={values[COLLECT_PAYMENT_FIELDS_NAMES.METHOD] === PAYMENT_METHODS.BANK_PAYMENT}
                                        onChangeCb={() => { setFieldValue(COLLECT_PAYMENT_FIELDS_NAMES.METHOD, PAYMENT_METHODS.BANK_PAYMENT) }}
                                    />
                                </div>
                                {values[COLLECT_PAYMENT_FIELDS_NAMES.METHOD] === PAYMENT_METHODS.BANK_PAYMENT ? <div className='flex flex-wrap mt-3'>
                                    <div className='flex mt-3 column-gap-16 w-full'>
                                        <div className='flex-1' >
                                            <Input
                                                label='Contact Number'
                                                placeholder='Enter Number'
                                                isRequired={true}
                                                name={COLLECT_PAYMENT_FIELDS_NAMES.MOBILE_NUMBER}
                                                value={values[COLLECT_PAYMENT_FIELDS_NAMES.MOBILE_NUMBER]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(COLLECT_PAYMENT_FIELDS_NAMES.MOBILE_NUMBER, General.sanitizeNumbers(e.target.value))
                                                }}
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <Input
                                                label='Amount'
                                                placeholder='Amount'
                                                disabled={true}
                                                name={COLLECT_PAYMENT_FIELDS_NAMES.AMOUNT}
                                                value={values[COLLECT_PAYMENT_FIELDS_NAMES.AMOUNT]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(`${COLLECT_PAYMENT_FIELDS_NAMES.AMOUNT}`, General.sanitizeNumbers(e.target.value))
                                                }}
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <DatePicker label="Date" placeholder='Choose Date'
                                                name={COLLECT_PAYMENT_FIELDS_NAMES.PAYMENT_DATE}
                                                isRequired={true}
                                                maxDate={new Date()}
                                                value={values[COLLECT_PAYMENT_FIELDS_NAMES.PAYMENT_DATE]}
                                                onChangeCb={(date) => {
                                                    setFieldValue(COLLECT_PAYMENT_FIELDS_NAMES.PAYMENT_DATE, date)
                                                }} />
                                        </div>
                                    </div>
                                    <div className='mt-6 w-full'>
                                        <TextArea
                                            placeholder='Notes'
                                            name={COLLECT_PAYMENT_FIELDS_NAMES.NOTE}
                                            value={values[COLLECT_PAYMENT_FIELDS_NAMES.NOTE]}
                                            onChangeCb={(e) => {
                                                setFieldValue(`${COLLECT_PAYMENT_FIELDS_NAMES.NOTE}`, e.target.value)
                                            }}
                                        />
                                    </div>
                                </div> : null}

                                <div className='mt-3'>
                                    <RadioButton
                                        label="Card Payment"
                                        customLabelClass="text-gray-500 font-bold"
                                        checked={values[COLLECT_PAYMENT_FIELDS_NAMES.METHOD] === PAYMENT_METHODS.CARD_PAYMENT}
                                        onChangeCb={() => { setFieldValue(COLLECT_PAYMENT_FIELDS_NAMES.METHOD, PAYMENT_METHODS.CARD_PAYMENT) }}
                                    />
                                </div>
                                {values[COLLECT_PAYMENT_FIELDS_NAMES.METHOD] === PAYMENT_METHODS.CARD_PAYMENT ? <div className='flex flex-wrap mt-3'>
                                    <div className='flex mt-3 column-gap-16 w-full'>
                                        <div className='flex-1'>
                                            <Input
                                                placeholder='Card Holder Name'
                                                label='Card Holder Name'
                                                isRequired={true}
                                                name={COLLECT_PAYMENT_FIELDS_NAMES.CARD_HOLDER_NAME}
                                                value={values[COLLECT_PAYMENT_FIELDS_NAMES.CARD_HOLDER_NAME]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(`${COLLECT_PAYMENT_FIELDS_NAMES.CARD_HOLDER_NAME}`, e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <Input
                                                label='Transaction ID'
                                                placeholder='Transaction ID'
                                                isRequired={true}
                                                name={COLLECT_PAYMENT_FIELDS_NAMES.TRANSECTIONID}
                                                value={values[COLLECT_PAYMENT_FIELDS_NAMES.TRANSECTIONID]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(`${COLLECT_PAYMENT_FIELDS_NAMES.TRANSECTIONID}`, e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex mt-3 column-gap-16 w-full'>
                                        <div className='flex-1' >
                                            <Input
                                                label='Contact Number'
                                                placeholder='Enter Number'
                                                isRequired={true}
                                                name={COLLECT_PAYMENT_FIELDS_NAMES.MOBILE_NUMBER}
                                                value={values[COLLECT_PAYMENT_FIELDS_NAMES.MOBILE_NUMBER]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(COLLECT_PAYMENT_FIELDS_NAMES.MOBILE_NUMBER, General.sanitizeNumbers(e.target.value))
                                                }}
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <Input
                                                label='Amount'
                                                placeholder='Amount'
                                                disabled={true}
                                                name={COLLECT_PAYMENT_FIELDS_NAMES.AMOUNT}
                                                value={values[COLLECT_PAYMENT_FIELDS_NAMES.AMOUNT]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(COLLECT_PAYMENT_FIELDS_NAMES.AMOUNT, General.sanitizeNumbers(e.target.value))
                                                }}
                                            />
                                        </div>

                                        <div className=''>
                                            <DatePicker label="Date" placeholder='Choose Date'
                                                name={COLLECT_PAYMENT_FIELDS_NAMES.PAYMENT_DATE}
                                                maxDate={new Date()}
                                                isRequired={true}
                                                value={values[COLLECT_PAYMENT_FIELDS_NAMES.PAYMENT_DATE]}
                                                onChangeCb={(date) => {
                                                    setFieldValue(COLLECT_PAYMENT_FIELDS_NAMES.PAYMENT_DATE, date)
                                                }} />
                                        </div>

                                    </div>
                                    <div className='mt-6 w-full'>
                                        <TextArea
                                            placeholder='Notes'
                                            name={COLLECT_PAYMENT_FIELDS_NAMES.NOTE}
                                            value={values[COLLECT_PAYMENT_FIELDS_NAMES.NOTE]}
                                            onChangeCb={(e) => {
                                                setFieldValue(`${COLLECT_PAYMENT_FIELDS_NAMES.NOTE}`, e.target.value)
                                            }}
                                        />
                                    </div>
                                </div> : null}
                            </div>

                        </ModalComponent>
                    </Form>
                }}
            </Formik>
        </>
    )

}

export default CollectPaymentModal
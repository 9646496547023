import React, { useMemo } from 'react';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { componentKey, setIsCheckedInModalOpen } from '../ShedulingSlice';
import { useSelector, useDispatch } from 'react-redux';
import Icons from '../../../components/icons/Icons';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import moment from 'moment';
import { postEncounterDetails } from '../../Encounter/EncounterSaga';
import { useNavigate } from 'react-router-dom';
import Label from '../../../components/common-components/label/Label';
import General from '../../../libs/utility/General';
import { getEncounterIdByScheduleId } from '../SchedulingSaga';
const CheckInModal = ({ close }) => {
	const { checkedInData, isCheckedInModalOpen } = useSelector((state) => state[componentKey]);

	const dispatch = useDispatch()

	const navigate = useNavigate()
	const chekedInDetails = useMemo(() => {
		const { appointmentType, complaints, date, start } = checkedInData;
		const { Provider } = checkedInData;
		const { Locations } = checkedInData;
		return {
			'Appointment Type': appointmentType,
			'Rendering Provider': `${Provider?.firstName} ${Provider?.lastName}`,
			'Chief Complaint': complaints,
			'Appointment Date & Time': `${moment(date).format('DD-MMM-YYYY')} & ${start}`,
			Location: Locations?.name,
			// "Outstanding Balance": "$20"
		};
	}, [checkedInData]);

	const handleCheckIn = () => {
		if (checkedInData?.status === "Checked_In" || checkedInData?.status === "In_Exam") {
			dispatch(getEncounterIdByScheduleId({
				data: {
					patinetId: checkedInData?.patientId,
					scheduleId: checkedInData?.uuid,
				},
				navigate,
			}))
		} else {

			dispatch(postEncounterDetails({
				encounterData: {
					patinetId: checkedInData?.patientId,
					providerId: checkedInData?.providerId,
					scheduleId: checkedInData?.uuid,
					data: {},
					isCreateEncounter: true,
				},
				navigate,
				mode: checkedInData?.mode
			}))
		}
	}

	return (
		<ModalComponent
			close={() => close()}
			open={isCheckedInModalOpen}
			title={'Check In'
			}
			icon={<div className='flex column-gap-10px'>
				<div className='flex items-center'>
					<div className='mr-1 fs-20'><Icons iconName="chartIcon" /></div>
					<Label customClass='fs-20 text-met-primary' onChangeCb={() => navigate(`/patients/${checkedInData?.Patient?.uuid}/dashboard`)}>Chart</Label>
				</div>
				<Icons iconName="closeIcon" />
			</div>}
			footerButton={
				<div className="flex justify-between column-gap-20px">
					<Button
						type="submit"
						variant={BUTTON_VARIANTS.OUTLINED}
						customBtnClass="text-met-primary"
						onClickCb={() => {
							const navigationParams = {
								redirectFrom: "Demographics",
								scheduleId: checkedInData?.uuid,
							}
							dispatch(setIsCheckedInModalOpen(false));
							const queryParams = General.convertObjToQueryString(navigationParams)
							navigate(`/patients/add/${checkedInData?.Patient?.uuid}/provider?${queryParams}`)
						}}
					>
						Edit Demographics
					</Button>
					{/* <Button
						type="submit"
						variant={BUTTON_VARIANTS.OUTLINED}
						customBtnClass="text-met-primary"
						onClickCb={() => { }}
					>
						Collect Payment
					</Button> */}
					<Button
						type="submit"
						variant={BUTTON_VARIANTS.CONTAINED}
						customBtnClass="text-met-primary"
						onClickCb={() => { handleCheckIn() }}
					>
						Complete Check In
					</Button>
				</div>
			}
			enableReinitialize
			customClasses="w-[55%] overflow-visible"
		>
			{/* <div>
				{Object.keys(chekedInDetails).map((key) => (
					// {
					// })}
					<div className="flex justify-between mt-5" key={key}>
						<div className=" text-met-light-grey font-bold" fontWeight="bold">
							{key}
						</div>
						<div className="text-rgba-1a1a1acc font-bold">{chekedInDetails[key]}</div>
					</div>
				))}
			</div> */}
			<div className="flex justify-between">
				<div className='w-[50%]'>
					{Object.keys(chekedInDetails).map((key) => {
						if (key === 'Appointment Type' || key === 'Rendering Provider' || key === 'Chief Complaint') {
							return (
								<div className="flex justify-between mt-5 " key={key}>
									<div className="text-met-light-grey font-bold w-[60%]">{key}</div>
									<div className="text-rgba-1a1a1acc font-bold w-[60%]">{chekedInDetails[key]}</div>
								</div>
							);
						}
						return null;
					})}
				</div>
				<div className='w-[50%]'>
					{Object.keys(chekedInDetails).map((key) => {
						if (key === 'Appointment Date & Time' || key === 'Location') {
							return (
								<div className="flex justify-between mt-5" key={key}>
									<div className="text-met-light-grey font-bold w-[60%]">{key}</div>
									<div className="text-rgba-1a1a1acc font-bold w-[60%]">{chekedInDetails[key]}</div>
								</div>
							);
						}
						return null;
					})}
				</div>
			</div>
		</ModalComponent>
	);
};

export default CheckInModal;

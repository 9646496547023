import RoundFab from "../../../components/common-components/Fab/RoundFab"
import Label from "../../../components/common-components/label/Label"
import Icons from "../../../components/icons/Icons"
import useGetNameInitials from "../../../libs/customHooks/useGetNameInitials"

const CreateSuperBill = () => {
    const getNameInitials = useGetNameInitials()
    return <>
        <div className="w-full">
            <div>
                <Icons iconName='arrowBackIcon'></Icons>
            </div>
            <div>
                <Label>Create Super Bill</Label>
            </div>
            <div>
                <RoundFab customClasses="w-[30px] h-[30px]" color="gray-400">
                    <h1 className="fs-12">{getNameInitials(`${'S'} ${'S'}`)}</h1>
                </RoundFab>
                <Label fontSize="lg" customClass="w-1/3">{`Heena West (A1430) (21 Oct 1970)`}</Label>
            </div>
        </div>
    </>
}

export default CreateSuperBill
import store from "../../../../store/store";
export const componentKey = 'PATIENT_BILLING';

const initialState= {
    patientBillingList: [],
    patientBillingPaginationState: {pageNumber: 2, limit: 10, total: 0 }
    
}
const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setPatientBillingList : (state, action) => {
            state.patientBillingList = action.payload
        },
        setPatientBillingPaginationState : (state,action) => {
            state.patientBillingPaginationState = {...state.patientBillingPaginationState, ...action.payload }
        },
    },
    initialReducerState: initialState
});
    


export const {setPatientBillingList, setPatientBillingPaginationState} = actions;
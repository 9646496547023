import { Form, Formik } from "formik";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import { FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER } from "./constant";
import Icons from "../../../../../components/icons/Icons";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import Upload from "../../../../../components/common-components/upload/Upload";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import Input from "../../../../../components/common-components/input/Input";
import DatePicker from "../../../../../components/common-components/datePicker";
import General from "../../../../../libs/utility/General";
import { MET_COLORS, VALIDATION_REGEX } from "../../../../../libs/constant";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import { useDispatch, useSelector } from "react-redux";
import { componentKey as usersComponentKey } from "./UsersSlice";
import { componentKey as locationComponentKey } from "../Locations/LocationSlice";
import { componentKey } from "../../../../Wrappers/Dataloader/DataloaderSlice";
import { patchAddUser, postAddUser } from "./UsersSaga";
import * as Yup from 'yup';

const AddUserStaffModal = ({ close, providerUserData }) => {

	const dispatch = useDispatch();
	const fields = [
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ROLE, isDropdown: true },
		{
			fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.FIRSTNAME,
			isRequired: true,
			regexPattern: VALIDATION_REGEX.NAME_REGEX,
		},
		{
			fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.LASTNAME,
			isRequired: true,
			regexPattern: VALIDATION_REGEX.NAME_REGEX,
		},
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.GENDER, isDropdown: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.EMAIL, isRequired: true, isEmail: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.DOB, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ADDRESS_LINE_1, isRequired: true },
		{
			fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PHONE_NUMBER,
			isRequired: true,
			regexPattern: VALIDATION_REGEX.MOBILE_NUM_REGEX,
		},
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.CITY, isRequired: true, regexPattern:VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.COUNTTRY, isRequired: false, regexPattern:VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.STATE, isDropdown: true, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.WORK_LOCATION, isMultiSelect: true, isRequired: true },

		{
			fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ZIP,
			isRequired: true,
			regexPattern: VALIDATION_REGEX.ZIP_CODE_REGEX,
		},
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PROVIDER_ID, isDropdown: true, isRequired: false }
	];
	const {
		allProvidersWorkLocations,
		isNewProviderUserAdd,
		isAddStaffUserModalOpen,
		currentProviderUserUUID,
		isNewUserAdd
	} = useSelector((state) => state[usersComponentKey]);
	

	const { indianStates, providersList } = useSelector((state) => state[componentKey]);

	const { providerSepcialities } = useSelector((state) => state[locationComponentKey]);
	// const { allDepartments } = useSelector((state) => state[departmentkey]);

	const validationSchema = Yup.object().shape({
		...getValidationSchema(fields).fields,
		[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PROVIDER_ID]: Yup.object().when(FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ROLE, {
			is: (val) =>
				val.value === "Front Desk",
			then: () => Yup.object().shape({
				label: Yup.string().required('Provider is required'),
				value: Yup.string().required('Provider is required')
			}),
			otherwise: (schema) => schema.optional(),
		}),

	})


	const { Address } = providerUserData;
	const { addressLine1, addressLine2, city, state, country, zip } = Address?.[0] || {};

	const work_locations = providerUserData?.provider_location_intermediate?.map(
		(item, index) => item?.providerLocationId
	);

	return (
		<Formik
			initialValues={{
				// ...providerUsersData,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.FIRSTNAME]: providerUserData?.firstName,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.LASTNAME]: providerUserData?.lastName,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.EMAIL]: providerUserData?.email,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ROLE]: General.getLabelValue(providerUserData?.role),
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.GENDER]: General.getLabelValue(providerUserData?.gender),
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.DOB]: providerUserData?.dob,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PHONE_NUMBER]: providerUserData?.phoneNumber,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ADDRESS_LINE_1]: providerUserData?.addressLine1 || addressLine1,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ADDRESS_LINE_2]: providerUserData?.addressLine2 || addressLine2,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.CITY]: providerUserData?.city || city,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.STATE]: General.getLabelValue(providerUserData?.state || state),
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.COUNTTRY]: providerUserData?.country || country,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ZIP]: providerUserData?.zip || zip,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.WORK_LOCATION]: work_locations || undefined,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PROVIDER_PROFILE_PIC]: providerUserData?.image,
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PROVIDER_PROFILE_PATH]: "",
				[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PROVIDER_ID] : providerUserData?.assigneeProviders?.uuid ?  General?.getLabelValue(providerUserData?.assigneeProviders?.uuid) : {label:"", value:""} 
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				const { providerId, ...extractedValues } = values
				const commonPayload = {
					state: values.state.value,
					role: values.role.value,
					gender: values.gender.value,
				};
				let payload
				if (values?.role?.value === "Front Desk") {
					payload = {
						...values,
						...commonPayload,
						providerId: values?.providerId.value

					};
				} else {
					payload = {
						...extractedValues,
						...commonPayload		
					};
				}
				try {
					if (isNewUserAdd) {
						dispatch(postAddUser({ newUsersData: payload }));
					} else {
						dispatch(
							patchAddUser({ id: currentProviderUserUUID, newUsersData: payload })
						);
					}
				} catch (error) {
					console.log(error);
				}
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={isAddStaffUserModalOpen}
							validationSchema={validationSchema}
							title={isNewUserAdd ? 'Add Staff User' : 'Edit Staff User'}
							close={close}
							icon={<Icons iconName="closeIcon" />}
							footerButton={
								<Button
									type="submit"
									variant={BUTTON_VARIANTS.CONTAINED}
									customBtnClass="text-met-primary"
								>
									Save
								</Button>
							}
							customClasses="w-[80%]"
							enableReinitialize
						>
							<div className="flex-column">
								<div className="flex justify-between flex-wrap">
									<div className="w-[10%]">
										<Upload
											subTitle="Upload"
											url={providerUserData?.profilePicture}
											customClass="w-[157px] h-[157px]"
											customSubtitleClass="font-bold text-met-primary"
											icon={<Icons iconName="fileUpload" color={MET_COLORS.DARK_GRAY} />}
											name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PROVIDER_PROFILE_PIC}
											onChangeCb={async (file) => {
												const mimeType = file?.type || '';
												const base64String = await General.fileToBase64(file);
												const base64 = `data:${mimeType};base64,${base64String}`;
												setFieldValue(
													FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PROVIDER_PROFILE_PIC,
													base64
												);
											}}
										/>
									</div>
									<div className="w-[85%]">
										<div
											className=" flex column-gap-10px flex-wrap  p-4"
											style={{ rowGap: '10px' }}
										>


											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Role"
													isRequired={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ROLE}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ROLE]}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ROLE, val);
													}}
													options={[
														{ label: 'Biller', value: 'Biller' },
														{ label: 'Front Desk', value: 'Front Desk' }

													]}
													disabled={isNewUserAdd ? false : true}
												/>
											</div>
											{values.role.value === "Front Desk" ? <div className="w-[32.5%]">
												<SelectDropdown
													// isRequired={true}
													placeholder="Select Provider"
													label="Provider"
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PROVIDER_ID}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PROVIDER_ID]}
													options={providersList}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PROVIDER_ID, val); 
													}}
													disabled={isNewUserAdd ? false : true}
												/>
											</div> : null}

											<div className="w-[32.5%]">
												<Input
													label="First Name"
													isRequired={true}
													placeholder="Enter first name"
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.FIRSTNAME}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.FIRSTNAME]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.FIRSTNAME,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Last Name"
													isRequired={true}
													placeholder="Enter Last Name"
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.LASTNAME}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.LASTNAME]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.LASTNAME,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Gender"
													isRequired={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.GENDER}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.GENDER]}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.GENDER, val);
													}}
													options={[
														{ label: 'Male', value: 'male' },
														{ label: 'Female', value: 'female' },
														{ label: 'Other', value: 'other' },
													]}
												/>
											</div>
											<div className="w-[32.5%]">
												<DatePicker
													label="Date of Birth"
													placeholder="Select"
													isRequired={true}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.DOB]}
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.DOB}
													onChangeCb={(date) => {
														setFieldValue(FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.DOB, date);
													}}
													maxDate={new Date()}
												/>
											</div>

											<div className="w-[32.5%]">
												<Input
													label="Email"
													isRequired={true}
													placeholder="Enter email"
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.EMAIL}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.EMAIL]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.EMAIL,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Contact Number"
													isRequired={true}
													placeholder="Enter number"
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PHONE_NUMBER}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PHONE_NUMBER]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.PHONE_NUMBER,
															General.sanitizeNumbers(e.target.value)
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Work Locations"
													isRequired={true}
													isMultiSelect={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.WORK_LOCATION}
													value={
														values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.WORK_LOCATION] || []
													}
													onChangeCb={(val) => {
														const values = val.map((item) => item?.value);
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.WORK_LOCATION,
															values?.length ? values : undefined
														);
													}}
													options={allProvidersWorkLocations}
												/>
											</div>

											<div className="w-[32.5%]">
												<Input
													label="Address 1"
													isRequired={true}
													placeholder="Address 1"
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ADDRESS_LINE_1}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ADDRESS_LINE_1]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ADDRESS_LINE_1,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Address 2"
													isRequired={false}
													placeholder="Address 2"
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ADDRESS_LINE_2}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ADDRESS_LINE_2]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ADDRESS_LINE_2,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="City"
													isRequired={true}
													placeholder="City"
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.CITY}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.CITY]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.CITY,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="State"
													isRequired={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.STATE}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.STATE]}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.STATE, val);
													}}
													options={indianStates}
												/>
											</div>{' '}
											<div className="w-[32.5%]">
												<Input
													label="Country"
													isRequired={false}
													placeholder="Country"
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.COUNTTRY}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.COUNTTRY]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.COUNTTRY,
															e.target.value
														);
													}}	
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Zip Code"
													isRequired={true}
													placeholder="Zip Code"
													name={FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ZIP}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ZIP]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER.ZIP,
															e.target.value
														);
													}}
												/>
											</div>

										</div>
									</div>

								</div>
							</div>
						</ModalComponent>
					</Form>
				);
			}}
		</Formik>
	);
};
export default AddUserStaffModal;

import CardComponent from "../../../components/common-components/SettingsCard/Card";
import { masterData } from "../constant";

function index(){
    return(
        <CardComponent
            cardName="Master"
            cardLogoName="settingMasterIcon"
            cardoptions={masterData}
            extraClass='ml-[20px]'
        />
    )
}   
export default index;
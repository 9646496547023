import React from 'react'
import CardContainer from '../../components/common-components/Cards/Container/CardContainer'
import SchedulingFilters from './SchedulingFilters'
import SchedulingNavbar from './SchedulingNavbar'
import { SCHEDULING_ROUTES } from '../../libs/constant'
import { Navigate, Route, Routes } from 'react-router-dom'
import SchedulingGridLists from './SchedulingGridLists'
import SchedulingCalendar from './SchedulingCalendar'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey, setIsAppointmentDetailsModalOpen, setIsCheckedInModalOpen, setShowSheduleFilters } from './ShedulingSlice'
import AppointmentDetailsModal from './ScheduleAppointmentModals/AppointmentDetailsModal'
import CheckInModal from './ScheduleAppointmentModals/CheckInModal'

const Scheduling = () => {
    const { showSheduleFilters ,isAppointmentDetailsModalOpen, isCheckedInModalOpen} = useSelector(state => state[componentKey])
    const dispatch = useDispatch()
    const SCHEDULING_SECTION_ROUTES = [
        { path: SCHEDULING_ROUTES.LIST, component: <SchedulingGridLists /> },
        { path: SCHEDULING_ROUTES.CALENDAR, component: <SchedulingCalendar /> },
        { path: "/", component: <Navigate to={"list"} /> },
    ]
    return (
        <CardContainer>
            <div className='flex column-gap-10px overflow-auto relative met-scrollbar h-[100%]'>
                {showSheduleFilters && <div className='border-r p-1 px-2'>
                    <SchedulingFilters />
                </div>}
                <div className='h-full flex-2'>
                    <SchedulingNavbar />

                    <Routes>
                        {SCHEDULING_SECTION_ROUTES.map((route, index) => (
                            <Route key={'scheduling-section-routes' + index} path={route.path} element={route.component} />
                        ))}
                    </Routes>
                </div>
            </div>
            {isAppointmentDetailsModalOpen && (
				<AppointmentDetailsModal close={() => dispatch(setIsAppointmentDetailsModalOpen(false))} />
			)}
			{isCheckedInModalOpen && <CheckInModal close={() => dispatch(setIsCheckedInModalOpen(false))} />}
        </CardContainer>
    )
}

export default Scheduling
import store from "../../../store/store";
export const componentKey = 'PATIENT_PAYMENT';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setIsPaymentModalOpen: (state, action) => {
            state.isPaymentModalOpen = action.payload;
        },
        setIsReciptModalOpen: (state, action) => {
            state.isReceiptModalOpen = action.payload;
        },
        setIsPatientPaymentFilterOpen: (state, action) => {
            state.isPatientPaymentFilterOpen = action.payload;
        },
        setPatientList: (state, action) => {
            state.patientList = [...state.patientList, ...action.payload];
        },
        setPatientPayments: (state, action) => {
            state.patientPayments = action.payload
        },
        setPaymentPaginationState: (state, action) => {
            state.paymentpaginationState = { ...state.paymentpaginationState, ...action.payload }
        },
        setUnpaidSuperBill: (state, action) => {
            state.unpaidSuperBill = action.payload
        },
        setEditSelectedPayment: (state, action) => {
            state.editSelectedPayment = action.payload
        },
        setPatientReceiptRow: (state, action) => {
            state.patientReceiptRow = action.payload
        },
        setPatientListPaginationState: (state, action) => {
            state.patientListPaginationState = { ...state.patientListPaginationState, ...action.payload }
        },
        setPatientReceiptData: (state, action) => {
            state.patientReceiptData = action.payload
        },
        setEmptyPaymentpaginationState: (state, action) => {
            state.paymentpaginationState = { 
                pageNumber: 1, 
                limit: 10, 
                total: 0, 
                name: "", 
                status: "", 
                date: "" 
            }
        }
    },
    initialReducerState: {
        isPaymentModalOpen: false,
        isReceiptModalOpen: false,
        isPatientPaymentFilterOpen: false,
        patientList: [],
        paymentpaginationState: { pageNumber: 1, limit: 10, total: 0, name: "", receipt: "", date: "", orderBy: "desc", sortBy: "" },
        patientListPaginationState: { pageNumber: 1, limit: 20, total: 10, },
        patientPayments: [],
        unpaidSuperBill: [],
        editSelectedPayment: {},
        patientReceiptRow: [],
        patientReceiptData: [],
    },
});

export const { setIsPaymentModalOpen, setIsReciptModalOpen, setIsPatientPaymentFilterOpen, setPatientList, setPaymentPaginationState, setPatientPayments, setUnpaidSuperBill, setEditSelectedPayment, setPatientReceiptRow, setPatientListPaginationState, setPatientReceiptData, setEmptyPaymentpaginationState } = actions;

import React from 'react'
import NavigationBorderedTabs from '../../components/common-components/navigationTabBorderVariant'
import { BILLING_ROUTES } from '../../libs/constant'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import ReadyForBilling from './ReadyForBilling/ReadyForBilling'
import SuperBill from './SuperBill/SuperBill'
import Payments from './Payments/Payments'
import CardContainer from '../../components/common-components/Cards/Container/CardContainer'

const BillingWrapper = () => {
    const role = localStorage.getItem('role')
    const navigate = useNavigate()
    const routes = [
        { path: "/billing-ready", component: <ReadyForBilling /> },
        { path: "/superbill/*", component: <SuperBill /> },
        { path: "/payments", component: <Payments /> },
        { path: "/*", component: role === 'Front Desk' ?  <Navigate to={"payments"} /> : <Navigate to={"billing-ready"} /> },

    ]
    return (
        <CardContainer>
            <NavigationBorderedTabs tabs={[
                role !== 'Front Desk' && { label: "Ready For Billing", onClickCb: () => navigate('billing-ready'), path: BILLING_ROUTES.BILLING_READY },
                role !== 'Front Desk' && { label: "Superbill", onClickCb: () => navigate('superbill'), path: BILLING_ROUTES.SUPERBILL },
                { label: "Patient Payment", onClickCb: () => navigate('payments'), path: BILLING_ROUTES.PAYMENTS }
            ]} customClasses="absolute z-10" />
            
            <div className='overflow-auto relative met-scrollbar h-[100%]'>
                <Routes>
                    {routes.map((route, index) => {
                        return <Route key={"patient-history-route" + index} path={route.path} element={route.component} />
                    })}
                </Routes>
            </div>
        </CardContainer >
    )
}

export default BillingWrapper
import React, { forwardRef, useEffect } from 'react'
import './styles.scss'
import { Form, Formik } from 'formik'
import PreferencesDetails from './PreferencesDetails';
import { getPatientDetails, getPreferences, savePatientPreferences } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setCreatePatientData, setEditDemographicsData } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import { useParams } from 'react-router-dom';
import store from '../../../store/store';

const Preferences = forwardRef(function Preferences({ isUpdate = false, onUpdateCb = null }, formRef) {

    const clinicalCenterNames = {
        pharmacyIds: "pharmacy",
        labIds: "lab",
        radiologyIds: "radiology"
    }

    const dispatch = useDispatch()
    const { patientId } = useParams();

    const { pharmacies, labs, radiologies, newlyAddedPatientId, fetchedPatientDetails, createPatientData } = useSelector(state => state[componentKey])

    const { PatientPharmacyIntermediate, PatientLabIntermediate, PatientRadiologyIntermediate } = fetchedPatientDetails

    const initialValues = {
        pharmacyIds: createPatientData?.preferences?.pharmacyIds?.length ? createPatientData?.preferences?.pharmacyIds : PatientPharmacyIntermediate || [],
        labIds: createPatientData?.preferences?.labIds?.length ? createPatientData?.preferences?.labIds : PatientLabIntermediate || [],
        radiologyIds: createPatientData?.preferences?.radiologyIds?.length ? createPatientData?.preferences?.radiologyIds : PatientRadiologyIntermediate || [],
    };

    useEffect(() => {
        return () => {
            if (isUpdate) {
                store.reducerManager.remove(componentKey);
            }
        }
    }, [])

    useEffect(() => {
        if (patientId && patientId !== ":id" && isUpdate) {
            dispatch(getPatientDetails({ patientId }))
        }
    }, [dispatch, patientId, isUpdate])

    useEffect(() => {
        dispatch(getPreferences({ name: "pharmacy" }));
        dispatch(getPreferences({ name: "lab" }));
        dispatch(getPreferences({ name: "radiology" }));
    }, [dispatch]);

    const addClinicalCenterContact = (values, setFieldValue, storeLabel, selectVal, title) => {
        setFieldValue(storeLabel, [
            ...values[storeLabel],
            { [(title).toLowerCase()]: selectVal }
        ]);
    };

    const handleRemove = (index, values, setFieldValue, storeLabel) => {
        const clinicalCenterClone = [...values[storeLabel]];
        clinicalCenterClone.splice(index, 1);
        setFieldValue(storeLabel, clinicalCenterClone);
    };

    const data = [
        {
            title: 'Pharmacy',
            storeLabel: "pharmacyIds",
            placeholder: 'Search & Select Pharmacy',
            options: pharmacies
        },
        {
            title: 'Lab',
            storeLabel: "labIds",
            placeholder: 'Search & Select Lab',
            options: labs
        },
        {
            title: 'Radiology',
            storeLabel: "radiologyIds",
            placeholder: 'Search & Select Radiology',
            options: radiologies
        },
    ];

    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                enableReinitialize
                onSubmit={(values) => {
                    if (onUpdateCb) {
                        return onUpdateCb(values, patientId)
                    }
                    const payload = {
                        patientId: newlyAddedPatientId,
                    }
                    for (let key in values) {
                        payload[key] = values[key].map(item => {
                            return item[clinicalCenterNames[key]].uuid;
                        });
                    }
                    dispatch(setCreatePatientData({ preferences: values }))
                    dispatch(savePatientPreferences({ patientId: newlyAddedPatientId, payload }))
                    dispatch(setEditDemographicsData({ patientsPreferences: payload }));

                }}
            >
                {({ handleSubmit, values, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className='custom-patient-details bg-opacity-3 rounded flex p-5 justify-between'>
                            <div className='preferences-wrapper justify-center flex column-gap-20px row-gap-15'>
                                {data?.map((pref, index) => (
                                    <PreferencesDetails
                                        values={values}
                                        addClinicalCenterContact={(storeLabel, selectVal, title) => addClinicalCenterContact(values, setFieldValue, storeLabel, selectVal, title)}
                                        key={index}
                                        title={pref.title}
                                        placeholder={pref.placeholder}
                                        dropdownOptions={pref.options}
                                        storeLabel={pref.storeLabel}
                                        handleRemoveCb={(index, values, storeLabel) => handleRemove(index, values, setFieldValue, storeLabel)}
                                    />
                                ))}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
});

export default Preferences;
import store from '../../../../store/store';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { componentKey, setAllVitalsData, setContentToFilterArray, setDeviceFiltersArray, setDeviceNamesOptionList, setIsVitalsModalOpen, setPatientDeviceVitals, setVitalTestsOptions, setVitalTestsOptionsWithoutType } from './PatientVitalsSlice';
import PatientDashboardDataService from '../../../../services/PatientDashboardDataService';
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/MetToaster';
import General from '../../../../libs/utility/General';
import DeviceTestDataService from '../../../../services/DeviceTestDataService';
import VitalsTestDataService from '../../../../services/VitalsTestDataService';

export const {
    getAllPatientVitalsDetails,
    postPatientVitals,
    getAllPatientVitalsForFilter,
    getAllPatientDeviceVitalsResult,
    getDeviceNameList,
    getPatientVitalDeviceTest,
    getPatientVitalDeviceTestWithoutType
} = {
    getAllPatientVitalsDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/VITALS',
            payload,
        };
    },
    postPatientVitals: (payload) => {
        return {
            type: 'POST/PATIENTDASHBOARD/VITALS',
            payload,
        };
    },
    getAllPatientVitalsForFilter: (payload) => {
        return {
            type: 'GET_PATIENTDASHBOARD/VITALS/FILTER',
            payload
        }
    },
    getAllPatientDeviceVitalsResult: (payload) => {
        return {
            type: 'GET_PATIENTDASHBOARD/DEVICE_VITALS/FILTER',
            payload
        }
    },
    getDeviceNameList: (payload) => {
        return {
            type: 'GET_PATIENTDASHBOARD/GET_DEVICE_LIST',
            payload
        }
    },
    getPatientVitalDeviceTest: (payload) => {
        return {
            type: 'GET_PATIENT_VITAL_DEVICE_TEST',
            payload
        }
    },
    getPatientVitalDeviceTestWithoutType: (payload) => {
        return {
            type: 'GET_PATIENT_VITAL_DEVICE_TEST_WITHOUT_TYPE',
            payload
        }
    }
};

function* getAllPatientVitalsDetailsAsync(action) {
    try {
        const { patientId = '', type = 'current', page = '1', limit = '10' } = action.payload;
        const params = {
            page: page,
            limit: limit,
        };

        const response = yield PatientDashboardDataService.getAllPatientVitals(
            'vitals/patient',
            patientId,
            params
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            const vitals = data?.vitals.map((vital, index) => {
                return {
                    ...vital, index
                }
            })
            yield put(setAllVitalsData(vitals));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* postPatientVitalsAsync(action) {
    try {
        const { patientId, vitalsData } = action.payload;
        const response = yield PatientDashboardDataService.postPatientVitals('vitals', patientId, vitalsData);
        const { message, status, data } = response?.data || {};
        if (status) {
            yield put(setIsVitalsModalOpen(false));
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAllPatientVitalsDetailsAsync,
                getAllPatientVitalsDetails({ patientId }));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* getAllPatientVitalsForFilterAsync(action) {
    try {
        const { patientId = '', page = '1', limit = '10' } = action.payload;
        const params = {
            page: page,
            limit: limit,
        };

        const response = yield PatientDashboardDataService.getAllPatientVitalsForFilter(
            'vitals/patient/mobile',
            patientId,
            params
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setContentToFilterArray(data?.content))
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* getAllPatientDeviceVitalsResultAsync(action) {
    try {
        const { patientId = '', page = '1', limit = '10' } = action.payload;
        const params = {
            patientId: patientId,
            status: 'Completed',
            type: "graph"
        };

        const response = yield VitalsTestDataService.getAllPatientDeviceVitalsResult(
            'details',
            params
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setPatientDeviceVitals(data?.vitalTest))
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* getDeviceNameListAsync(action) {
    try {
        const response = yield DeviceTestDataService.getDeviceNameList(action.payload)
        const { device, status } = response.data
        if (status) {
            const deviceList = General.addLableValuePair(device, 'deviceName', 'deviceName');
            yield put(setDeviceNamesOptionList(deviceList))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* getPatientVitalDeviceTestAsync(action) {
    try {
        const { selectedDevice } = action.payload;
        const params = {
            deviceName: selectedDevice,
            type : "graph"
        };

        const response = yield DeviceTestDataService.getPatientVitalDeviceTest(params);

        const { message, status, data } = response?.data || {};

        if (status) {
            const options = General.addLableValuePairWithoutSpace(data, "testName", "testName")
            yield put(setVitalTestsOptions(options))
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* getPatientVitalDeviceTestWithoutTypeAsync(action) {
    try {
        const { selectedDevice } = action.payload;
        const params = {
            deviceName: selectedDevice,
        };

        const response = yield DeviceTestDataService.getPatientVitalDeviceTest(params);

        const { message, status, data } = response?.data || {};

        if (status) {
            const options = General.addLableValuePairWithoutSpace(data, "testName", "testName")
            yield put(setVitalTestsOptionsWithoutType(options))
        }
    } catch (error) {
        console.log('err: ', error);
    }
}


function* rootSaga() {
    yield all([
        takeLatest(getAllPatientVitalsDetails().type, getAllPatientVitalsDetailsAsync),
        takeLatest(postPatientVitals().type, postPatientVitalsAsync),
        takeLatest(getAllPatientVitalsForFilter().type, getAllPatientVitalsForFilterAsync),
        takeLatest(getAllPatientDeviceVitalsResult().type, getAllPatientDeviceVitalsResultAsync),
        takeLatest(getDeviceNameList().type, getDeviceNameListAsync),
        takeLatest(getPatientVitalDeviceTest().type, getPatientVitalDeviceTestAsync),
        takeLatest(getPatientVitalDeviceTestWithoutType().type, getPatientVitalDeviceTestWithoutTypeAsync)

    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import Label from '../../../../../components/common-components/label/Label';
import Upload from '../../../../../components/common-components/upload/Upload';
import { MET_COLORS, VALIDATION_REGEX } from '../../../../../libs/constant';
import { componentKey as usersComponentKey } from './UsersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getValidationSchema } from '../../../../../libs/formsUtils';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import Input from '../../../../../components/common-components/input/Input';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import Icons from '../../../../../components/icons/Icons';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import { componentKey } from '../../../../Wrappers/Dataloader/DataloaderSlice';
import { FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER } from './constant';
import TextArea from '../../../../../components/common-components/textArea/TextArea';
import DatePicker from '../../../../../components/common-components/datePicker';
import { patchAddProviderUser, postAddProviderUser } from './UsersSaga';
import General from '../../../../../libs/utility/General';
import { getProviderSepcialities } from '../Locations/LocationSaga';
import { componentKey as locationComponentKey } from '../Locations/LocationSlice';
import { componentKey as departmentkey } from '../Departments/DepartmentsSlice';
import { getAllDepartments } from '../Departments/DepartmentsSaga';
const AddProviderUserModal = ({ close, providerUserData }) => {
	const dispatch = useDispatch();
	const fields = [
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.FIRSTNAME, isRequired: true, regexPattern: VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LASTNAME, isRequired: true, regexPattern: VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EMAIL, isRequired: true, isEmail: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ROLE, isDropdown: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DOB, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.GENDER, isDropdown: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_1, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PHONE_NUMBER, isRequired: true, regexPattern: VALIDATION_REGEX.MOBILE_NUM_REGEX },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.CITY, isRequired: true, regexPattern:VALIDATION_REGEX.NAME_REGEX },
		// { fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.COUNTTRY, isRequired: true, regexPattern:VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.STATE, isDropdown: true, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.WORK_LOCATION, isMultiSelect: true, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENSED_STATES, isMultiSelect: true, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LANGUAGES_SPOKEN, isMultiSelect: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.SPECIALITY, isMultiSelect: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ZIP, isRequired: true, regexPattern: VALIDATION_REGEX.ZIP_CODE_REGEX, },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.NMC_ID, isRequired: true }
	];
	const {
		isProviderUserModalOpen,
		providerUsersData,
		allProvidersWorkLocations,
		isNewProviderUserAdd,
		currentProviderUserUUID,
	} = useSelector((state) => state[usersComponentKey]);

	const { indianStates } = useSelector((state) => state[componentKey]);
	const { providerSepcialities } = useSelector((state) => state[locationComponentKey]);
	const { allDepartments } = useSelector((state) => state[departmentkey]);
	const validationSchema = getValidationSchema(fields);

	useEffect(() => {
		dispatch(getProviderSepcialities());
		dispatch(getAllDepartments());
	}, []);
	const departments = General.addLableValuePair(allDepartments, 'departmentName', 'uuid');
	const specialityData = providerUserData?.provider_speciality_intermediate?.map((item, idx) => item?.specialityId);

	const dept = providerUserData?.provider_department_intermediate?.map((item) => item?.departmentId);
	const { Address } = providerUserData
	const {
		addressLine1,
		addressLine2,
		city,
		state,
		country,
		zip,
	} = Address?.[0] || {};

	const work_locations = providerUserData?.provider_location_intermediate?.map((item, index) => item?.providerLocationId)

	return (
		<Formik
			initialValues={{
				// ...providerUsersData,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.FIRSTNAME]: providerUserData?.firstName,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LASTNAME]: providerUserData?.lastName,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EMAIL]: providerUserData?.email,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ROLE]: General.getLabelValue(providerUserData?.role),
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_TYPE]: General.getLabelValue(
					providerUserData?.providerType
				),
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.SPECIALITY]: specialityData || [],
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.GENDER]: General.getLabelValue(providerUserData?.gender),
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DOB]: providerUserData?.dob,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.NPI_NUMBER]: providerUserData?.npiNumber,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PHONE_NUMBER]: providerUserData?.phoneNumber,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.OFFICE_FAX_NUMBER]: providerUserData?.officeFaxNumber,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LANGUAGES_SPOKEN]: providerUserData?.languagesSpoken || [],
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.YEARS_OF_EXPERIENCE]: providerUserData?.yearsOfExperience || 0,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DEPARTMENT]: dept || [],
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENSED_STATES]: providerUserData?.licensedStates?.length ? providerUserData?.licensedStates : undefined,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_NUMBER]: providerUserData?.licenceNumber,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_EXPIRE_DATE]: providerUserData?.licenceExpireDate,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.BIO]: providerUserData?.bio,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EXPERTIES_IN]: providerUserData?.expertiesIn,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EDUCATION_WORK_EXPERIENCE]:
					providerUserData?.educationWorkExperience,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.NMC_ID]: providerUserData?.nmcId,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_1]: providerUserData?.addressLine1 || addressLine1,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_2]: providerUserData?.addressLine2 || addressLine2,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.CITY]: providerUserData?.city || city,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.STATE]: General.getLabelValue(providerUserData?.state || state),
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.COUNTTRY]: providerUserData?.country || country,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ZIP]: providerUserData?.zip || zip,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.WORK_LOCATION]: work_locations || undefined,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_PROFILE_PIC]: providerUserData?.image,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_PROFILE_PATH]: "",
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				const payload = {
					...values,
					providerType: values.providerType.value,
					state: values.state.value,
					role: values.role.value,
					gender: values.gender.value,
					// nmcID: values.nmcId?.value.toString()
					// languagesSpoken: values.languagesSpoken.value,
					// departmentIds: values.departmentIds.uuid
				};
				try {
					if (isNewProviderUserAdd) {
						dispatch(postAddProviderUser({ newProviderUsersData: payload }));
					} else {
						dispatch(
							patchAddProviderUser({ uuid: currentProviderUserUUID, newProviderUsersData: payload })
						);
					}
				} catch (error) {
					console.log(error);
				}
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={isProviderUserModalOpen}
							validationSchema={validationSchema}
							title={isNewProviderUserAdd ? 'Add User' : 'Edit  User'}
							close={close}
							icon={<Icons iconName="closeIcon" />}
							footerButton={
								<Button
									type="submit"
									variant={BUTTON_VARIANTS.CONTAINED}
									customBtnClass="text-met-primary"
								>
									Save
								</Button>
							}
							customClasses="w-[80%]"
							enableReinitialize
						>
							<div className="flex-column">
								<div className="flex justify-between flex-wrap">
									<div className="w-[10%]">
										<Upload
											subTitle="Upload"
											url={providerUserData?.profilePicture}

											customClass="w-[157px] h-[157px]"
											customSubtitleClass="font-bold text-met-primary"
											icon={<Icons iconName="fileUpload" color={MET_COLORS.DARK_GRAY} />}
											name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_PROFILE_PIC}

											onChangeCb={async (file) => {
												const mimeType = file?.type || '';
												const base64String = await General.fileToBase64(file);
												const base64 = `data:${mimeType};base64,${base64String}`;
												setFieldValue(
													FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_PROFILE_PIC,
													base64
												);
											}}
										/>
									</div>
									<div className="w-[85%]">
										<div
											className=" flex column-gap-10px flex-wrap  p-4"
											style={{ rowGap: '10px' }}
										>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Provider Type"
													isRequired={false}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_TYPE}
													value={
														values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_TYPE]
													}
													onChangeCb={(val) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_TYPE,
															val
														);
													}}
													options={[
														{ label: 'MD', value: 'md' },
														{ label: 'PA', value: 'pa' },
														{ label: 'PSYD', value: 'psyd' },
														{ label: 'LCSW', value: 'lcsw' },
														{ label: 'NP', value: 'np' },
													]}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Select Specility"
													isRequired={false}
													isMultiSelect={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.SPECIALITY}
													value={
														values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.SPECIALITY] || []
													}
													onChangeCb={(val) => {
														const values = val?.length && val?.map((item) => item.value);
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.SPECIALITY,
															values
														);
													}}
													options={providerSepcialities}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Role"
													isRequired={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ROLE}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ROLE]}
													onChangeCb={(val) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ROLE,
															val
														);
													}}
													options={[
														{ label: 'Provider Admin', value: 'Provider Admin' },
														{
															label: 'Provider',
															value: 'Provider',
														},
													]}
													disabled={isNewProviderUserAdd ? false : true}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="First Name"
													isRequired={true}
													placeholder="Enter first name"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.FIRSTNAME}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.FIRSTNAME]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.FIRSTNAME,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Last Name"
													isRequired={true}
													placeholder="Enter Last Name"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LASTNAME}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LASTNAME]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LASTNAME,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Gender"
													isRequired={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.GENDER}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.GENDER]}
													onChangeCb={(val) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.GENDER,
															val
														);
													}}
													options={[
														{ label: 'Male', value: 'male' },
														{ label: 'Female', value: 'female' },
														{ label: 'Other', value: 'other' },
													]}
												/>
											</div>
											<div className="w-[32.5%]">
												<DatePicker
													label="Date of Birth"
													placeholder="Select"
													isRequired={true}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DOB]}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DOB}
													onChangeCb={(date) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DOB,
															date
														);
													}}
													maxDate={new Date()}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="NMC ID"
													isRequired={true}
													placeholder="Enter Number"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.NMC_ID}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.NMC_ID]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.NMC_ID, e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Provider Phone Number"
													isRequired={true}
													placeholder="Enter Phone Number"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PHONE_NUMBER}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PHONE_NUMBER]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PHONE_NUMBER,
															General.sanitizeNumbers(e.target.value)
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Office Fax Number"
													isRequired={false}
													placeholder="Enter Fax Number"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.OFFICE_FAX_NUMBER}
													value={
														values[
														FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.OFFICE_FAX_NUMBER
														]
													}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.OFFICE_FAX_NUMBER,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Email"
													isRequired={true}
													placeholder="Enter email"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EMAIL}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EMAIL]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EMAIL,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Language Spoken"
													isRequired={false}
													isMultiSelect={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LANGUAGES_SPOKEN}
													value={
														values[
														FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LANGUAGES_SPOKEN
														] || []
													}
													onChangeCb={(val) => {
														const values = val.map((item) => item?.value);

														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LANGUAGES_SPOKEN,
															values
														);
													}}
													options={[
														{ label: 'Hindi', value: 'Hindi' },
														{ label: 'Bengali', value: 'Bengali' },
														{ label: 'English', value: 'English' },
														{ label: 'Telugu', value: 'Telugu' },
														{ label: 'Marathi', value: 'Marathi' },
														{ label: 'Tamil', value: 'Tamil' },
														{ label: 'Urdu', value: 'Urdu' },
														{ label: 'Gujarati', value: 'Gujarati' },
														{ label: 'Kannada', value: 'Kannada' },
														{ label: 'Odia', value: 'Odia' },
														{ label: 'Punjabi', value: 'Punjabi' },
														{ label: 'Malayalam', value: 'Malayalam' },
													]}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Year of Experience"
													isRequired={false}
													placeholder="Enter "
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.YEARS_OF_EXPERIENCE}
													value={
														values[
														FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.YEARS_OF_EXPERIENCE
														]
													}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.YEARS_OF_EXPERIENCE,
															General.sanitizeNumbers(e.target.value)
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Work Locations"
													isRequired={true}
													isMultiSelect={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.WORK_LOCATION}
													value={
														values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.WORK_LOCATION] ||
														[]
													}
													onChangeCb={(val) => {
														const values = val.map((item) => item?.value);
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.WORK_LOCATION,
															values?.length ? values : undefined);
													}}
													options={allProvidersWorkLocations}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Department"
													isRequired={false}
													isMultiSelect={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DEPARTMENT}
													value={
														values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DEPARTMENT] || []
													}
													onChangeCb={(val) => {
														const values = val.map((item) => item.uuid);
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DEPARTMENT,
															values
														);
													}}
													options={departments}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Address 1"
													isRequired={true}
													placeholder="Address 1"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_1}
													value={
														values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_1]
													}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_1,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Address 2"
													isRequired={false}
													placeholder="Address 2"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_2}
													value={
														values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_2]
													}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_2,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="City"
													isRequired={true}
													placeholder="City"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.CITY}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.CITY]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.CITY,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="State"
													isRequired={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.STATE}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.STATE]}
													onChangeCb={(val) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.STATE,
															val
														);
													}}
													options={indianStates}
												/>
											</div>{' '}
											<div className="w-[32.5%]">
												<Input
													label="Country"
													isRequired={false}
													placeholder="Country"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.COUNTTRY}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.COUNTTRY]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.COUNTTRY,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Zip Code"
													isRequired={true}
													placeholder="Zip Code"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ZIP}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ZIP]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ZIP,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Licensed State(s)"
													isRequired={true}
													isMultiSelect={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENSED_STATES}
													value={
														values[
														FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENSED_STATES
														] || []
													}
													onChangeCb={(val) => {
														const values = val.map((item) => item?.value);
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENSED_STATES,
															values?.length ? values : undefined);
													}}
													options={indianStates}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Licence Number"
													isRequired={false}
													placeholder="Enter  Number"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_NUMBER}
													value={
														values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_NUMBER]
													}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_NUMBER,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<DatePicker
													label="Licence Expiry Date"
													placeholder="Select"
													isRequired={false}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_EXPIRE_DATE}
													value={
														values[
														FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_EXPIRE_DATE
														]
													}
													onChangeCb={(date) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_EXPIRE_DATE,
															date
														);
													}}
													minDate={new Date()}
												/>
											</div>
										</div>
									</div>
									<div className="w-full">
										<Heading
											type={HEADING.H1}
											color="met-primary"
											fontSize={'md'}
											fontWeight="bold"
										>
											Basic Account Profile Data
										</Heading>
										<div className="flex flex-col w-full p-6 mt-2 ">
											<div>
												<TextArea
													label="Provider Bio"
													placeholder="Enter Bio..."
													customClasses="mt-2"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.BIO}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.BIO]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.BIO,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="mt-6">
												<TextArea
													label="Expertise In"
													placeholder="Enter Expertise"
													customClasses="mt-2"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EXPERTIES_IN}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EXPERTIES_IN]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EXPERTIES_IN,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="mt-6">
												<TextArea
													label="Education, Work Experience"
													placeholder="Enter Education, Work Experience"
													customClasses="mt-2"
													name={
														FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EDUCATION_WORK_EXPERIENCE
													}
													value={
														values[
														FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER
															.EDUCATION_WORK_EXPERIENCE
														]
													}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EDUCATION_WORK_EXPERIENCE,
															e.target.value
														);
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ModalComponent>
					</Form>
				);
			}}
		</Formik>
	);
};
export default AddProviderUserModal;

import store from '../../../../../store/store';

export const componentKey = 'PROVIDERS_GROUP_DEPARTMENTS';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setAllDepartments: (state, action) => {
			state.allDepartments = action.payload;
		},
		setEditDepartmentData: (state, action) => {
			state.editDepartmentData = action.payload;
		},
		setIsDepartmentModalOpen: (state, action) => {
			state.isDepartmentModalOpen = action.payload;
		},
		setIsNewDepartmentAdd: (state, action) => {
			state.isNewDepartmentAdd = action.payload;
		},

	},
	initialReducerState: {
		allDepartments: [],
		editDepartmentData: {},
        isDepartmentModalOpen:false,
        isNewDepartmentAdd: false,
	},
});

export const { setAllDepartments, setEditDepartmentData, setIsDepartmentModalOpen,setIsNewDepartmentAdd,  } = actions;

import React from 'react';
import { componentKey } from '../../../../Wrappers/Dataloader/DataloaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_FIELDS_NAMES } from './constant';
import { componentKey as DepartmentKey, setEditDepartmentData } from './DepartmentsSlice';
import { Form, Formik } from 'formik';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import { getValidationSchema } from '../../../../../libs/formsUtils';
import Input from '../../../../../components/common-components/input/Input';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import Icons from '../../../../../components/icons/Icons';
import General from '../../../../../libs/utility/General';
import { patchDepartment, postDepartment } from './DepartmentsSaga';
import { VALIDATION_REGEX } from '../../../../../libs/constant';
const DepartmentsModal = ({ close, editDepartmentData }) => {
	const { providersList } = useSelector((state) => state[componentKey]);
	const { isNewDepartmentAdd, isDepartmentModalOpen } = useSelector((state) => state[DepartmentKey]);
	const dispatch = useDispatch();
	const fields = [
		{ fieldName: FORM_FIELDS_NAMES.DEPARTMENT_NAME, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.DEPARTMENT_ADMIN, isDropdown: true, isRequired: true },
		{
			fieldName: FORM_FIELDS_NAMES.DEPARTMENT_CONTACT_NUMBER,
			isRequired: true,
			regexPattern: VALIDATION_REGEX.MOBILE_NUM_REGEX,
		},
		{ fieldName: FORM_FIELDS_NAMES.DEPARTMENT_ID, isRequired: true },
	];
	const validationSchema = getValidationSchema(fields);

	const admin = General.addLableValuePair(providersList, 'firstName', 'uuid', 'lastName');

	return (
		<Formik
			initialValues={{
				// ...editDepartmentData,
				[FORM_FIELDS_NAMES.DEPARTMENT_NAME]: editDepartmentData?.departmentName,
				[FORM_FIELDS_NAMES.DEPARTMENT_ADMIN]: General.getLabelValue(editDepartmentData?.departmentAdmin),
				[FORM_FIELDS_NAMES.DEPARTMENT_CONTACT_NUMBER]: editDepartmentData?.contactNumber,
				[FORM_FIELDS_NAMES.DEPARTMENT_ID]: editDepartmentData?.departmentId,
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				const payload = {
					...values,
					departmentAdmin: values.departmentAdmin.uuid,
				};
				try {
					if (isNewDepartmentAdd) {
						dispatch(postDepartment({ editDepartmentData: payload }));
					} else {
						dispatch(
							patchDepartment({ departmentId: editDepartmentData?.uuid, editDepartmentData: payload })
						);
					}
				} catch (err) {
					console.log(err);
				}
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={isDepartmentModalOpen}
							validationSchema={validationSchema}
							title={isNewDepartmentAdd ? 'Add New Department' : 'Edit Department'}
							close={() => {
								close();
								dispatch(setEditDepartmentData({}));
							}}
							icon={<Icons iconName="closeIcon" />}
							footerButton={
								<Button
									type="submit"
									variant={BUTTON_VARIANTS.CONTAINED}
									customBtnClass="text-met-primary"
								>
									Save
								</Button>
							}
							customClasses="w-[60%] overflow-visible"
							enableReinitialize
							hideOverflow={true}
						>
							<div className="flex flex-col">
								<div className="w-full flex justify-between">
									<div className="w-[45%]">
										<Input
											label="Department ID"
											isRequired={true}
											placeholder="Enter Department ID"
											name={FORM_FIELDS_NAMES.DEPARTMENT_ID}
											value={values[FORM_FIELDS_NAMES.DEPARTMENT_ID]}
											onChangeCb={(e) => {
												setFieldValue(FORM_FIELDS_NAMES.DEPARTMENT_ID, e.target.value);
											}}
										/>
									</div>
									<div className="w-[45%]">
										<Input
											label="Department Name"
											isRequired={true}
											placeholder="Enter Department Name"
											name={FORM_FIELDS_NAMES.DEPARTMENT_NAME}
											value={values[FORM_FIELDS_NAMES.DEPARTMENT_NAME]}
											onChangeCb={(e) => {
												setFieldValue(FORM_FIELDS_NAMES.DEPARTMENT_NAME, e.target.value);
											}}
										/>
									</div>
								</div>
								<div className="w-full flex justify-between mt-[20px]">
									<div className="w-[45%]">
										
										<SelectDropdown
											placeholder="Select Department Admin"
											label="Department Admin"
											isRequired={true}
											name={FORM_FIELDS_NAMES.DEPARTMENT_ADMIN}
											value={values[FORM_FIELDS_NAMES.DEPARTMENT_ADMIN] || []}
											onChangeCb={(val) => {
												setFieldValue(FORM_FIELDS_NAMES.DEPARTMENT_ADMIN, val);
											}}
											options={admin}
										/>
									</div>
									<div className="w-[45%]">
										<Input
											label="Contact Number"
											isRequired={true}
											placeholder="Enter Contact Number"
											name={FORM_FIELDS_NAMES.DEPARTMENT_CONTACT_NUMBER}
											value={values[FORM_FIELDS_NAMES.DEPARTMENT_CONTACT_NUMBER]}
											onChangeCb={(e) => {
												setFieldValue(
													FORM_FIELDS_NAMES.DEPARTMENT_CONTACT_NUMBER,
													General.sanitizeNumbers(e.target.value)
												);
											}}
										/>
									</div>
								</div>
							</div>
						</ModalComponent>
					</Form>
				);
			}}
		</Formik>
	);
};
export default DepartmentsModal;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Table from '../../../../containers/Table/Index'
import ButtonDropdown from '../../../../components/common-components/buttonDropdown'
import NoteHistoryModal from './NoteHistoryModal'
import { deletePatientNotes, getAllPatientNotesDetails } from './PatientNotesSaga'
import { componentKey as PatientDashboardComponentKey } from "../PatientDashboardSlice"
import { componentKey as PatientNotesComponentKey, setIsNotesHistoryModalOpen, setIsOpenDeleteStickyNotePopup, setNotesHistoryData } from "./PatientNotesSlice"
import moment from 'moment'
import DeletePopup from '../../../../components/common-components/deletePopup/DeletePopup'

function StickyNotes() {
    const dispatch = useDispatch();

    const { currentPatientId } = useSelector((state => state[PatientDashboardComponentKey]))
    const { allNotesData, isNotesHistoryModalOpen, isOpenDeleteStickyNotePopup } = useSelector(state => state[PatientNotesComponentKey])

    const [rowData, setRowData] = useState(null)

    const handleHistoryClick = (notesHistoryData) => {
        dispatch(setIsNotesHistoryModalOpen(true));
        dispatch(setNotesHistoryData(notesHistoryData))
    };

    const handleDeleteClick = (note) => {
        dispatch(deletePatientNotes({ patientId: currentPatientId, noteId: note.uuid }))
    }

    useEffect(() => {
        if (!currentPatientId) return;
        dispatch(getAllPatientNotesDetails({ patientId: currentPatientId }))
    }, [currentPatientId])

    const coloumns = [
        {
            field: "noteName",
            label: <Label fontWeight="font-bold">Note Name</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-00' fontSize={"md"} customClasses="cursor-pointer">{`${row?.notes_history[0]?.provider?.firstName ?? ''} ${row?.notes_history[0]?.provider?.lastName ?? ''} | ${row?.notes_history[0]?.updatedAt ? moment(row?.notes_history[0]?.updatedAt).format("DD-MM-YYYY") : ''} | ${row?.notes_history[0]?.updatedAt ? moment(row?.notes_history[0]?.updatedAt).format("hh:mm A") : ''}`}</Heading>
                            <Label color='gray-500'>{row.note ?? ''}</Label>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "action",
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer" onClickCb={() => setRowData(row)}>{row.action}</Heading>
                        </div>
                        <ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[{ label: "History", value: null, onClickCb: () => handleHistoryClick(row.notes_history) }, {
                            label: "Delete", value: null, onClickCb: () => {
                                dispatch(setIsOpenDeleteStickyNotePopup(true))
                                setRowData(row)
                            }
                        }]} />
                    </div>
                </>
            },
        },
    ]

    return (
        <>
            <div className='p-4'>
                <div className='pt-6'>
                    <Table isPagination={false} coloumns={coloumns} rows={allNotesData} sorting={
                        {
                            isSortable: false,
                            onSortChangeCb: () => { }
                        }} />
                </div>
            </div>
            {isNotesHistoryModalOpen ? <NoteHistoryModal open={isNotesHistoryModalOpen} close={() => dispatch(setIsNotesHistoryModalOpen(false))} /> : null}
            {isOpenDeleteStickyNotePopup ?
                <DeletePopup
                    open={isOpenDeleteStickyNotePopup}
                    onClickOk={() => {
                        handleDeleteClick(rowData)
                    }}
                    onClickCancel={() => {
                        dispatch(setIsOpenDeleteStickyNotePopup(false))
                    }}
                    okButton='Delete'
                    cancelButton='Cancel'
                /> : null}
        </>
    )
}

export default StickyNotes




import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import CustomEvent from './CustomEvent';
import CustomHeader from './CustomHeader';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import AddNewAvailabilityModal from './AddNewAvailabilityModal';
import { customAvailabilityMessage } from '../../../utils';
import EditAvailabilityModal from './EditAvailability';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailability } from './AvailabilitySaga';
import { componentKey, setAvailiabilityResponseData, setIsAddSingleDayAvailabilityModalOpen, setIsEditSingleDayAvailabilityModalOpen, setIsViewAllEventsModalOpen, setViewAllModalSelectedSlotData } from './AvailabilitySlice';
import { componentKey as RouterComponentKey } from '../../../../../routes/RoutesSlice'
import ViewAllEventModal from './ViewAllEvent';
import Icons from '../../../../../assets/svgs';
import { addNotifications } from '../../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../../components/common-components/toaster/MetToaster';
import { useShowForAllowedPermissionsOnly } from '../../../../../libs/customHooks/useShowForAllowedPermissionsOnly';
import { USER_PERMISSIONS } from '../../../../../libs/UserPermissionsConstants';


const SettingsAvailability = () => {

	const showForAllowPermission = useShowForAllowedPermissionsOnly()

	const localizer = momentLocalizer(moment);
	const [isAddNewAvailability, setIsAddNewAvailability] = useState(false);
	const [addNewAvailabilityTitle, setAddNewAvailabilityTitle] = useState('');
	const [isEditAvailability, setIsEditAvailability] = useState(false);
	const [editAvailabilityTitle, setEditAvailabilityTitle] = useState('');
	const [provider, setProvider] = useState({})
	const [events, setEvents] = useState([])
	const [selectedEvent, setSelectedEvent] = useState([])
	const [selectedSlotDate, setSelectedSlotDate] = useState()

	const { availiabilityData, selectedProvider, isEditSingleDayAvailabilityModalOpen, isAddSingleDayAvailabilityModalOpen, isViewAllEventsModalOpen } = useSelector(state => state[componentKey])
	const { isAdminUser, loggedInUser, loggedInProviderUser } = useSelector(state => state[RouterComponentKey])

	const dispatch = useDispatch()

	const addNewAvailabilityStateHandler = (message = '') => {
		setAddNewAvailabilityTitle(message);
		setIsAddNewAvailability(!isAddNewAvailability);
	};

	const editAvailabilityStateHandler = (message = '') => {
		setEditAvailabilityTitle(message);
		setIsEditAvailability(!isEditAvailability);
	};

	const onSlotClick = (slotInfo) => {
		if (moment(slotInfo.start).format("MM-DD-YYYY") >= moment().format("MM-DD-YYYY")) {
			addNewAvailabilityStateHandler(customAvailabilityMessage(slotInfo.start));
			setSelectedSlotDate(slotInfo.start)
			if (isAddSingleDayAvailabilityModalOpen !== undefined) {
				dispatch(setIsAddSingleDayAvailabilityModalOpen(true))
			}

		} else {
			dispatch(addNotifications({ message: `You cannot add availability for past dates`, variant: TOASTER_VARIANT.ERROR }))
		}

	};

	const onEventClick = (event) => {
		if (!showForAllowPermission(USER_PERMISSIONS.UPDATE_AVAILABILITY)) {
			return dispatch(addNotifications({ message: "You do not have right to edit.", variant: TOASTER_VARIANT.ERROR }));
		}
		dispatch(setIsEditSingleDayAvailabilityModalOpen(true))
		dispatch(setViewAllModalSelectedSlotData(event))
	};


	useEffect(() => {
		if (!isAdminUser) {
			setProvider({ label: loggedInUser.name, value: loggedInUser?.uuid })
		}
	}, [isAdminUser, loggedInUser])

	useEffect(() => {
		const eventData = []
		if (availiabilityData[0]?.provider_availability_slots?.length) {
		const blockDays = availiabilityData[0]?.availability_block_days; 
		blockDays.forEach(blockDay => {
		const blockDate = blockDay.date?.split('T')[0];
		if (!blockDay.fullDayBlock) {
			const startTimeString = `${blockDate} ${blockDay.startTime}`;
			const endTimeString = `${blockDate} ${blockDay.endTime}`;
			const startTime = moment(startTimeString, 'YYYY-MM-DD h:mm A');
			const endTime = moment(endTimeString, 'YYYY-MM-DD h:mm A');
			
			eventData.push({
				title: 'Blocked',
				start: new Date(startTime),
				end: new Date(endTime),
				style: {
					backgroundColor: '#FF00001A',
					color: '#FF0000',
					padding: '10px',
					borderRadius: '5px',
					border: '1px solid #FF0000',
					marginTop: '6px',
				},
			});
		}
	});
			availiabilityData[0].provider_availability_slots.forEach(((availability, index) => {
				const date = availability.date?.split('T')[0];
				let includeSlots = true;
	
				blockDays.forEach(blockDay => {
					const blockDate = blockDay.date?.split('T')[0];
	
					if (blockDate === date && blockDay.fullDayBlock) {
						includeSlots = false; 
					}
				});
				if (includeSlots) {
				const startTimeString = `${date} ${availability.startTime}`;
				const startTime = moment(startTimeString, 'YYYY-MM-DD h:mm A');
				const startDateTime = new Date(startTime);
	
				const endTimeString = `${availability.date} ${availability.endTime}`;
				const endTime = moment(endTimeString, 'YYYY-MM-DD h:mm A');
				const endDateTime = new Date(endTime);
			
				eventData.push({
					title: availability.virtual ? <div className='flex items-center'><Icons iconName="settingsVideoIcon" /><p className='ml-2'>Virtual</p></div> : availability.availabilityLocation?.name,
					start: startDateTime,
					end: endDateTime,
					style: availability.virtual ? {
						backgroundColor: '#EAC6001A',
						color: '#1A1A1ACC',
						padding: '10px',
						borderRadius: '5px',
						border: '1px solid #EAC600',
						marginTop: '6px',
					} : {
						backgroundColor: '#3434FF1A',
						color: '#1A1A1ACC',
						padding: '10px',
						borderRadius: '5px',
						border: '1px solid #3434FF',
					},
				})
			}
			}))
		}
		setEvents(eventData)
	}, [availiabilityData])	

	// const events = [
	// 	{
	// 		title: 'Meeting 1',
	// 		start: new Date(2024, 2, 12, 10, 0),
	// 		end: new Date(2024, 2, 12, 12, 0),
	// 		status: 'Scheduled',
	// 		label: 'New Appointment',
	// 		style: {
	// 			backgroundColor: '#3434FF1A',
	// 			color: '#1A1A1ACC',
	// 			padding: '10px',
	// 			borderRadius: '5px',
	// 			border: '1px solid #3434FF',
	// 		},
	// 	},
	// 	{
	// 		title: 'Meeting 2',
	// 		start: new Date(2024, 2, 12, 13, 0),
	// 		end: new Date(2024, 2, 12, 15, 0),
	// 		status: 'Scheduled',
	// 		label: 'Follow up Appointment',

	// 		style: {
	// 			backgroundColor: '#EAC6001A',
	// 			color: '#1A1A1ACC',
	// 			padding: '10px',
	// 			borderRadius: '5px',
	// 			border: '1px solid #EAC600',
	// 			marginTop: '6px',
	// 		},
	// 	},
	// 	{
	// 		title: 'Meeting 1',
	// 		start: new Date(2024, 1, 29, 15, 0),
	// 		end: new Date(2024, 1, 29, 16, 0),
	// 		status: 'Scheduled',
	// 		label: 'New Appointment',
	// 		style: {
	// 			backgroundColor: '#3434FF1A',
	// 			color: '#1A1A1ACC',
	// 			padding: '10px',
	// 			borderRadius: '5px',
	// 			border: '1px solid #3434FF',
	// 		},
	// 	},
	// 	{
	// 		title: 'Meeting 2',
	// 		start: new Date(2024, 1, 29, 17, 0),
	// 		end: new Date(2024, 1, 29, 18, 0),
	// 		status: 'Cancelled',
	// 		label: 'Follow up Appointment',

	// 		style: {
	// 			backgroundColor: '#EAC6001A',
	// 			color: '#1A1A1ACC',
	// 			padding: '10px',
	// 			borderRadius: '5px',
	// 			border: '1px solid #EAC600',
	// 			marginTop: '6px',
	// 		},
	// 	},
	// 	{
	// 		title: 'Meeting 3',
	// 		start: new Date(2024, 2, 30, 18, 0),
	// 		end: new Date(2024, 2, 30, 20, 0),
	// 		status: 'Checked In',
	// 		label: 'New Appointment',
	// 		style: { backgroundColor: '#F3F4F6', color: '#1A1A1ACC' },
	// 	},
	// 	{
	// 		title: 'Meeting 3',
	// 		start: new Date(2024, 2, 30, 18, 0),
	// 		end: new Date(2024, 2, 30, 20, 0),
	// 		status: 'Rescheduled',
	// 		label: 'New Appointment',
	// 		style: { backgroundColor: '#F3F4F6', color: '#1A1A1ACC' },
	// 	},
	// ];
	useEffect(() => {
		const responseArray = []
		availiabilityData.forEach(availiability => {
			const location = availiability.availabilityLocation

			availiability.provider_availability_slots.forEach(slot => {
				responseArray.push({
					...slot,
					location
				})
			})
		})
		dispatch(setAvailiabilityResponseData(responseArray));

	}, [dispatch, availiabilityData])

	const handleEventClick = (event) => {
		// console.log('handleEventClick--', event);
		// setStatusModal({ status: event.status, open: true });
		// Handle opening modals based on event status
	};

	useEffect(() => {
		if (selectedProvider?.value)
			dispatch(getAvailability({ providerId: selectedProvider.value, locationIds: {} }))
	}, [dispatch])

	return (
		<>
			<Calendar
				selectable={true}
				onNavigate={() => { }}
				messages={{
					showMore: function showMore(total) {
						return 'View All';
					},
				}}
				onShowMore={(event) => {
					dispatch(setIsViewAllEventsModalOpen(true))
					setSelectedEvent(event)
				}}
				onSelectEvent={onEventClick}
				onSelectSlot={onSlotClick}
				localizer={localizer}
				events={events}
				startAccessor="start"
				endAccessor="end"
				style={{ height: '100%', width: '100%' }}
				eventPropGetter={(event) => ({
					style: event.style,
				})}
				components={{
					toolbar: (props) => (
						<CustomHeader
							{...props}
							onViewChange={(view) => console.log('View', view)}
							isAdminUser={isAdminUser}

						// toggleMenu={toggleMenu}
						// onNavigate={handleNavigate}
						/>
					),
					// event: CustomEvent,
					event: (props) => <CustomEvent {...props} onEventClick={handleEventClick} />,
				}}
				view="month"
				onView={() => { }}
				min={new Date()}
			/>
			{isAddSingleDayAvailabilityModalOpen ? <AddNewAvailabilityModal open={isAddSingleDayAvailabilityModalOpen} selectedSlotDate={selectedSlotDate} title={addNewAvailabilityTitle} customClasses="h-[45%] w-[45%]" close={() => dispatch(setIsAddSingleDayAvailabilityModalOpen(false))} customBodyClasses="h-[70%]" /> : null}
			{isEditSingleDayAvailabilityModalOpen ? <EditAvailabilityModal open={isEditSingleDayAvailabilityModalOpen} title={editAvailabilityTitle} customClasses="h-[80%] w-[45%]" close={() => dispatch(setIsEditSingleDayAvailabilityModalOpen(false))} customBodyClasses="h-[88%]" /> : null}
			<ModalComponent
				open={isViewAllEventsModalOpen}
				title={`All Availability Slots for ${moment(selectedEvent[0]?.start, 'ddd MMM DD YYYY HH:mm:ss').format('Do MMMM')}`}
				customClasses="h-[35%] w-[40%]"
				close={() => dispatch(setIsViewAllEventsModalOpen(false))}
				customBodyClasses="h-[88%] flex justify-center items-center"
			>
				<div className='w-full'>
					<ViewAllEventModal events={selectedEvent} handleEventClick={handleEventClick} />
				</div>
			</ModalComponent>
		</>
	);
};

export default SettingsAvailability;

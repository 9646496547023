import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setApplyBtnTrue, setSelectedDevice, setSelectedTestName, setVitalTestsCountGraphDates } from '../DashboardSlice';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import Button from '../../../components/common-components/button/Button';
import moment from 'moment';
import DatePicker from '../../../components/common-components/datePicker';
import { Chart } from 'chart.js';
import Icons from '../../../components/icons/Icons';
import { componentKey as VitalDeviceKey } from '../../Patients/PatientDashboard/Vitals/PatientVitalsSlice';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import { getDeviceNameList, getPatientVitalDeviceTestWithoutType } from '../../Patients/PatientDashboard/Vitals/PatientVitalsSaga';
import { vitalTestResultCount } from '../DashboardSaga';


const ViewDeviceTestsCountModal = ({ open, close, onClickCb }) => {

    const dispatch = useDispatch();
    const { selectedDevice, selectedTestName, vitalTestsCountGraphDates, applyBtnTrue, vitalTestCount, noDataMessage } = useSelector(
        (state) => state[componentKey]
    );
    const { deviceNamesOptionList, vitalTestsOptionsWithoutType } = useSelector(
        (state) => state[VitalDeviceKey]
    );

    useEffect(() => {
        dispatch(getDeviceNameList());
    }, [dispatch]);

    useEffect(() => {
        if (selectedDevice.value) {
            dispatch(getPatientVitalDeviceTestWithoutType({ selectedDevice: selectedDevice.value }));
        }
    }, [selectedDevice, dispatch]);

    useEffect(() => {
        if (!selectedTestName || !applyBtnTrue) return;

        const params = {
            testName: selectedTestName,
            dateFrom: vitalTestsCountGraphDates?.testsGraphDateFrom,
            dateTo: vitalTestsCountGraphDates?.testsGraphDateTo,
        };
        dispatch(vitalTestResultCount({ params }));
    }, [selectedTestName, applyBtnTrue, vitalTestsCountGraphDates, dispatch]);

    useEffect(() => {
        if (!vitalTestCount?.length) return;

        let labelsArray = vitalTestCount.map(month => month?.monthName);
        let dataArray = vitalTestCount.map(month => month?.total);

        const canvas = document.getElementById("count-modal");
        if (!canvas) return;
        const ctx = canvas.getContext("2d");

        const existingChart = Chart.getChart(ctx);
        if (existingChart) {
            existingChart.destroy();
        }

        const newChart = new Chart(ctx, {
            type: "line",
            data: {
                labels: labelsArray,
                datasets: [
                    {
                        label: "Count",
                        data: dataArray
                    },

                ],
            },
            options: {
                cutout: "75%",
                animation: {
                    animateScale: true,
                },
                plugins: {
                    title: {
                        display: true,
                    },
                    legend: {
                        display: false,
                        position: "right",
                    },
                },
            },
            grid: {
                drawOnChartArea: false,
            },
        });
        return () => {
            newChart.destroy();
        };
    }, [vitalTestCount, noDataMessage]);

    return (
        <div>
            <ModalComponent
                open={open}
                title={'Vital Device Tests Count Graph'}
                icon={<Icons iconName="closeIcon" />}
                close={close}
                customClasses="w-[1000px] h-[650px]"
            >
                <div className="flex space-x-5">
                    <SelectDropdown
                        placeholder='Select'
                        label='Select Device'
                        isRequired={true}
                        value={selectedDevice}
                        onChangeCb={(val) => {
                            dispatch(setSelectedDevice(val));
                        }}
                        options={deviceNamesOptionList}
                    />
                    {selectedDevice?.value && (
                        <div>
                            <SelectDropdown
                                placeholder='Select'
                                label='Select Test'
                                isRequired={true}
                                value={selectedTestName}
                                onChangeCb={(val) => {
                                    dispatch(setSelectedTestName(val?.label));
                                }}
                                options={vitalTestsOptionsWithoutType}
                            />
                        </div>
                    )}

                    <DatePicker
                        label="DateFrom"
                        placeholder="Choose Date"
                        value={vitalTestsCountGraphDates?.testsGraphDateFrom}
                        onChangeCb={(date) => {
                            const formattedDate = moment(date).format('YYYY-MM-DD');
                            dispatch(setVitalTestsCountGraphDates({ testsGraphDateFrom: formattedDate }));
                        }}
                    />
                    <DatePicker
                        label="DateTo"
                        placeholder="Choose Date"
                        value={vitalTestsCountGraphDates?.testsGraphDateTo}
                        onChangeCb={(date) => {
                            const formattedDate = moment(date).format('YYYY-MM-DD');
                            dispatch(setVitalTestsCountGraphDates({ testsGraphDateTo: formattedDate }));
                        }}
                    />
                    <Button
                        onClickCb={() => dispatch(setApplyBtnTrue(!applyBtnTrue))}
                        customBtnClass="flex-justify-end px-2 h-10 mt-7 ml-5"
                    >
                        Apply
                    </Button>
                </div>
                <div className="item-center flex-wrap flex justify-center w-full">
                    {noDataMessage ? (
                        <p className='mt-24'>No Data Found</p>
                    ) : (
                        <canvas id="count-modal" className="max-h-[50vh]"></canvas>
                    )}
                </div>


            </ModalComponent>
        </div>
    );
};

export default ViewDeviceTestsCountModal;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setApplyTrue, setBilingCountGraphDates } from '../DashboardSlice';
import Button from '../../../components/common-components/button/Button';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import Icons from '../../../components/icons/Icons';
import DatePicker from '../../../components/common-components/datePicker';
import { Chart } from 'chart.js';
import moment from 'moment';

const ViewBillingGraphModal = ({ open, close, bilingCountStored, onClickCb }) => {
    const dispatch = useDispatch();
    const { isOpenBilingGraphModal, bilingCountGraphDates, applyTrue, noDataMessageForBilling } = useSelector(
        (state) => state[componentKey]
    );

    useEffect(() => {

        let labelsArray = [];
        bilingCountStored?.forEach((month) => {
            labelsArray.push(month?.monthName);
        });

        let dataArray = [];
        bilingCountStored?.forEach((month) => {
            dataArray.push(month?.totalCollection);
        });

        const canvas = document.getElementById("myChart");
        if (!canvas) return; 
        const ctx = canvas.getContext("2d");

        const existingChart = Chart.getChart(ctx);
        if (existingChart) {
            existingChart.destroy();
        }

        const newChart = new Chart(ctx, {
            type: "line",
            data: {
                labels: labelsArray,
                datasets: [
                    {
                        label: "Count",
                        data: dataArray
                    },

                ],
            },
            options: {
                cutout: "75%",
                animation: {
                    animateScale: true,
                },
                plugins: {
                    title: {
                        display: true,
                    },
                    legend: {
                        display: false,
                        position: "right",
                    },
                },
            },
            grid: {
                drawOnChartArea: false,
            },
        });
        return () => {
            newChart.destroy();
        };
    }, [bilingCountStored, applyTrue, noDataMessageForBilling]);

    return (
        <div>
            <ModalComponent
                open={isOpenBilingGraphModal}
                title={'Billing Count Graph'}
                icon={<Icons iconName="closeIcon" />}
                close={close}
                customClasses="w-[1000px] h-[650px]"
            >
                <div className="flex space-x-5">
                    <DatePicker
                        label="DateFrom"
                        placeholder="Choose Date"
                        value={bilingCountGraphDates?.graphDateFrom}
                        onChangeCb={(date) => {
                            const formattedDate = moment(date).format('YYYY-MM-DD');
                            dispatch(setBilingCountGraphDates({ graphDateFrom: formattedDate }));
                        }}
                    />
                    <DatePicker
                        label="DateTo"
                        placeholder="Choose Date"
                        value={bilingCountGraphDates?.graphDateTo}
                        onChangeCb={(date) => {
                            const formattedDate = moment(date).format('YYYY-MM-DD');
                            dispatch(setBilingCountGraphDates({ graphDateTo: formattedDate }));
                        }}
                    />
                    <Button
                        onClickCb={() => dispatch(setApplyTrue(!applyTrue))}
                        customBtnClass="flex-justify-end px-2 h-10 mt-7 ml-5"
                    >
                        Apply
                    </Button>
                </div>

                <div className="item-center flex-wrap flex justify-center w-full">
                    {noDataMessageForBilling ? (
                        <p className='mt-24'>No Data Found</p>
                    ) : (
                       <canvas id="myChart" className="max-h-[50vh]"></canvas>
                    )}
                </div>
            </ModalComponent>
        </div>
    );
};

export default ViewBillingGraphModal;

import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Icons from '../../../../components/icons/Icons'
import RadioButton from '../../../../components/common-components/radioButton/RadioButton'
import VaccineHistoricalModal from './VaccineHistoricalModal'
import VaccineAdministeredModal from './VaccineAdministeredModal'
import { getValidationSchema } from '../../../../libs/formsUtils';
import { Form, Formik } from 'formik'
import { FORM_FIELDS_NAMES } from '../Allergies/Constants'
import { ADMINISTERED_FORM_FIELDS_NAMES, HISTORICAL_FORM_FIELDS_NAMES, VACCINE_FORM_FIELDS_NAMES } from './Constants'
import { componentKey as PatientVaccinesComponentKey, setActiveTab } from '../Vaccines/PatientVaccinesSlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import { componentKey as RouteComponentKey } from '../../../../routes/RoutesSlice'
import { postPatientVaccines, updatePatientVaccines } from './PatientVaccinesSaga'
import { VALIDATION_REGEX } from '../../../../libs/constant';

const TABS = {
    ADMINISTERED: "ADMINISTERED",
    HISTORICAL: "HISTORICAL"
}

const administeredFields = [
    { fieldName: ADMINISTERED_FORM_FIELDS_NAMES.VACCINE_NAME, isRequired: true },
    { fieldName: ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_DATE, isRequired: true },
    { fieldName: ADMINISTERED_FORM_FIELDS_NAMES.OREDERD_BY, isRequired: true },
    { fieldName: ADMINISTERED_FORM_FIELDS_NAMES.ROUTE, isDropdown: true },
    { fieldName: ADMINISTERED_FORM_FIELDS_NAMES.BODY_SITE, isDropdown: true },
    { fieldName: ADMINISTERED_FORM_FIELDS_NAMES.EXPIRY_DATE, isRequired: true },
    { fieldName: ADMINISTERED_FORM_FIELDS_NAMES.REACTION, isDropdown: true },
    { fieldName: ADMINISTERED_FORM_FIELDS_NAMES.UNITS, isDropdown: true },
    { fieldName: ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_BY, isRequired: true },
    { fieldName: ADMINISTERED_FORM_FIELDS_NAMES.AGE, regexPattern: VALIDATION_REGEX.AGE_REGEX },
];

const historicalFields = [
    { fieldName: HISTORICAL_FORM_FIELDS_NAMES.VACCINE_NAME, isRequired: true },
    { fieldName: HISTORICAL_FORM_FIELDS_NAMES.ADMINISTERED_DATE, isRequired: true },
    { fieldName: HISTORICAL_FORM_FIELDS_NAMES.OREDERD_BY, isRequired: true },
    { fieldName: HISTORICAL_FORM_FIELDS_NAMES.ROUTE, isDropdown: true },
    { fieldName: HISTORICAL_FORM_FIELDS_NAMES.BODY_SITE, isDropdown: true },
    { fieldName: HISTORICAL_FORM_FIELDS_NAMES.EXPIRY_DATE, isRequired: true },
    { fieldName: HISTORICAL_FORM_FIELDS_NAMES.REACTION, isDropdown: true },
    { fieldName: HISTORICAL_FORM_FIELDS_NAMES.SOURCE_FOR_INFORMATION, isDropdown: true },
    { fieldName: HISTORICAL_FORM_FIELDS_NAMES.ADMINISTERED_BY, isRequired: true },
    { fieldName: HISTORICAL_FORM_FIELDS_NAMES.UNITS, isDropdown: true },
    { fieldName: HISTORICAL_FORM_FIELDS_NAMES.AGE, regexPattern: VALIDATION_REGEX.AGE_REGEX },
]

const administeredValidationSchema = getValidationSchema(administeredFields);
const historicalValidationSchema = getValidationSchema(historicalFields);


function VaccineModal({ open, close }) {
    const dispatch = useDispatch();
    const { isVaccinesModalOpen, isNewVaccineAdd, vaccineData, currentVaccineId, activeTab = TABS.ADMINISTERED } = useSelector(
        (state) => state[PatientVaccinesComponentKey]
    );

    const { loggedInUser } = useSelector(
        (state) => state[RouteComponentKey]
    );

    const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);


    return (
        <Formik
            initialValues={activeTab === 'ADMINISTERED' ? {
                [ADMINISTERED_FORM_FIELDS_NAMES.VACCINE_TYPE]: "Administered",
                [ADMINISTERED_FORM_FIELDS_NAMES.VACCINE_NAME]: vaccineData?.name,
                [ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_DATE]: vaccineData?.administerDate,
                [ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_TIME]: vaccineData?.administerTime,
                [ADMINISTERED_FORM_FIELDS_NAMES.OREDERD_BY]: vaccineData?.orderedBy,
                [ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_BY]: vaccineData?.administeredBy,
                [ADMINISTERED_FORM_FIELDS_NAMES.MANUFACTURER]: vaccineData?.manufacturer,
                [ADMINISTERED_FORM_FIELDS_NAMES.NDC_CODE]: vaccineData?.ndcCode,
                [ADMINISTERED_FORM_FIELDS_NAMES.LOT]: vaccineData?.lot,
                [ADMINISTERED_FORM_FIELDS_NAMES.DOSE]: vaccineData?.dose,
                [ADMINISTERED_FORM_FIELDS_NAMES.UNITS]: vaccineData?.units ? { label: vaccineData?.units || "", value: vaccineData?.units || "", } : "",
                [ADMINISTERED_FORM_FIELDS_NAMES.ROUTE]: vaccineData?.route ? { label: vaccineData?.route || "", value: vaccineData?.route || "", } : "",
                [ADMINISTERED_FORM_FIELDS_NAMES.BODY_SITE]: vaccineData?.site ? { label: vaccineData?.site || "", value: vaccineData?.site || "", } : '',
                [ADMINISTERED_FORM_FIELDS_NAMES.EXPIRY_DATE]: vaccineData?.expiryDate,
                [ADMINISTERED_FORM_FIELDS_NAMES.AGE]: vaccineData?.age,
                [ADMINISTERED_FORM_FIELDS_NAMES.REACTION]: vaccineData?.reaction ? { label: vaccineData?.reaction, value: vaccineData?.reaction, } : '',
                [ADMINISTERED_FORM_FIELDS_NAMES.REASON]: vaccineData?.reason,
                [ADMINISTERED_FORM_FIELDS_NAMES.NOTE]: vaccineData?.note,
            } :
                {
                    [HISTORICAL_FORM_FIELDS_NAMES.VACCINE_TYPE]: "Historical",
                    [HISTORICAL_FORM_FIELDS_NAMES.VACCINE_NAME]: vaccineData?.name,
                    [HISTORICAL_FORM_FIELDS_NAMES.ADMINISTERED_DATE]: vaccineData?.administerDate,
                    [HISTORICAL_FORM_FIELDS_NAMES.ADMINISTERED_TIME]: vaccineData?.administerTime,
                    [HISTORICAL_FORM_FIELDS_NAMES.SOURCE_FOR_INFORMATION]: vaccineData?.sourceOfInfo ? { label: vaccineData?.sourceOfInfo, value: vaccineData?.sourceOfInfo } : '',
                    [HISTORICAL_FORM_FIELDS_NAMES.OREDERD_BY]: vaccineData?.orderedBy,
                    [HISTORICAL_FORM_FIELDS_NAMES.ADMINISTERED_BY]: vaccineData?.administeredBy,
                    [HISTORICAL_FORM_FIELDS_NAMES.MANUFACTURER]: vaccineData?.manufacturer,
                    [HISTORICAL_FORM_FIELDS_NAMES.NDC_CODE]: vaccineData?.ndcCode,
                    [HISTORICAL_FORM_FIELDS_NAMES.LOT]: vaccineData?.lot,
                    [HISTORICAL_FORM_FIELDS_NAMES.DOSE]: vaccineData?.dose,
                    [HISTORICAL_FORM_FIELDS_NAMES.UNITS]: vaccineData?.units ? { label: vaccineData?.units || "", value: vaccineData?.units || "", } : "",
                    [HISTORICAL_FORM_FIELDS_NAMES.ROUTE]: vaccineData?.route ? { label: vaccineData?.route || "", value: vaccineData?.route || "", } : "",
                    [HISTORICAL_FORM_FIELDS_NAMES.BODY_SITE]: vaccineData?.site ? { label: vaccineData?.site || "", value: vaccineData?.site || "", } : '',
                    [HISTORICAL_FORM_FIELDS_NAMES.EXPIRY_DATE]: vaccineData?.expiryDate,
                    [HISTORICAL_FORM_FIELDS_NAMES.AGE]: vaccineData?.age,
                    [HISTORICAL_FORM_FIELDS_NAMES.REACTION]: vaccineData?.reaction ? { label: vaccineData?.reaction, value: vaccineData?.reaction, } : '',
                    [HISTORICAL_FORM_FIELDS_NAMES.REASON]: vaccineData?.reason,
                    [HISTORICAL_FORM_FIELDS_NAMES.NOTE]: vaccineData?.note,
                }
            }
            validationSchema={activeTab === 'ADMINISTERED' ? administeredValidationSchema : historicalValidationSchema}
            enableReinitialize
            onSubmit={(formVals) => {
                try {
                    const data = {
                        ...formVals,
                        providerId: loggedInUser?.uuid,
                        units: formVals.units?.value,
                        route: formVals.route?.value,
                        site: formVals.site?.value,
                        reaction: formVals.reaction?.value,
                        ...(formVals.sourceOfInfo ? { sourceOfInfo: formVals.sourceOfInfo?.value } : {})
                    }
                    if (isNewVaccineAdd) {
                        dispatch(postPatientVaccines({ patientId: currentPatientId, vaccinesData: data }));
                    } else {
                        dispatch(
                            updatePatientVaccines({
                                patientId: currentPatientId,
                                vaccineId: currentVaccineId,
                                vaccinesData: data,
                            })
                        );
                    }
                } catch (error) {
                    console.log(error);
                }
            }}
        >
            {({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, handleBlur }) => {
                return <Form onSubmit={handleSubmit} >
                    <div>
                        <ModalComponent
                            open={isVaccinesModalOpen}
                            title={isNewVaccineAdd ? 'Add Vaccine' : 'Update Vaccine'}
                            footerButton={<Button type='submit' variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary">Save</Button>}
                            icon={<Icons iconName="closeIcon" />}
                            close={close}
                        >
                            <div className='p-2'>
                                <div className='flex mt-2'>
                                    <div className='flex'>
                                        <div>
                                            <RadioButton
                                                label='Administered'
                                                name={VACCINE_FORM_FIELDS_NAMES.VACCINE_TYPE}
                                                checked={activeTab === TABS.ADMINISTERED || values[VACCINE_FORM_FIELDS_NAMES.VACCINE_TYPE] === VACCINE_FORM_FIELDS_NAMES.ADMINISTERED}
                                                value={values[ADMINISTERED_FORM_FIELDS_NAMES.VACCINE_TYPE]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(VACCINE_FORM_FIELDS_NAMES.VACCINE_TYPE, e.target.value)
                                                    dispatch(setActiveTab(TABS.ADMINISTERED))
                                                }} />
                                        </div>
                                    </div>
                                    <div className='flex ml-3'>
                                        <div>
                                            <RadioButton
                                                label='Historical'
                                                name={VACCINE_FORM_FIELDS_NAMES.VACCINE_TYPE}
                                                checked={activeTab === TABS.HISTORICAL || values[VACCINE_FORM_FIELDS_NAMES.VACCINE_TYPE] === VACCINE_FORM_FIELDS_NAMES.HISTORICAL}
                                                value={values[HISTORICAL_FORM_FIELDS_NAMES.VACCINE_TYPE]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(VACCINE_FORM_FIELDS_NAMES.VACCINE_TYPE, e.target.value)
                                                    dispatch(setActiveTab(TABS.HISTORICAL))
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                {activeTab === TABS.ADMINISTERED ? <VaccineAdministeredModal values={values} setFieldValue={setFieldValue} handleBlurCb={handleBlur} /> : <VaccineHistoricalModal values={values} setFieldValue={setFieldValue} handleBlurCb={handleBlur} />}
                            </div>
                        </ModalComponent >
                    </div >
                </Form>
            }}
        </Formik>
    )
}

export default VaccineModal
import React from 'react';

const CalenderIcon = ({ color = "#1b5984", style }) => {
	return (
		<svg
			style={style}
			xmlns="http://www.w3.org/2000/svg"
			id="calendar_month_black_24dp_1_"
			data-name="calendar_month_black_24dp (1)"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g id="Group_88208" data-name="Group 88208">
				<rect id="Rectangle_36143" data-name="Rectangle 36143" width="24" height="24" fill="none" />
			</g>
			<g id="Group_88209" data-name="Group 88209">
				<path
					id="Path_205412"
					data-name="Path 205412"
					d="M19,4H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V10H19ZM19,8H5V6H19ZM9,14H7V12H9Zm4,0H11V12h2Zm4,0H15V12h2ZM9,18H7V16H9Zm4,0H11V16h2Zm4,0H15V16h2Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default CalenderIcon;

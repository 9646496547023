import React, { useEffect, useState } from 'react'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Icons from '../../../../components/icons/Icons'
import Table from '../../../../containers/Table/Index'
import SelectDropdown from '../../../../components/common-components/selectDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { getAllICDcodes } from './GenrateSuperBillSaga'
import { componentKey } from './GenrateSuperBillSlice'
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice'
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/MetToaster'


const DiagnosisCodeTable = ({ addDIagnosisCodes, values, handleBlur, handleRemoveCb }) => {

    const { icdCodesData, billData } = useSelector(state => state[componentKey])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllICDcodes())
    }, [dispatch])

    const coloumns = [
        {
            field: "srNo",
            label: <Label fontWeight='md'>Sr No</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.index}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "icdCode",
            label: <Label fontWeight='md'>ICD Code</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='' fontSize={"md"} customClasses="cursor-pointer">{`${row.icdCode}  ${row.icdCodeDescription}`}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "action",
            label: <Label fontWeight='md'>Action</Label>,
            renderLogic: (row) => {
                const isDisabled = mapping_billing_payment?.length > 0;
                return <>
                    <div className='flex items-center justify-between'>
                        <div className={isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}
                            onClick={!isDisabled ? () => handleRemoveCb(row.uuid) : null}> <Icons iconName={'trashIcon'}></Icons> </div>
                    </div>
                </>
            },
        },

    ]

    const { mapping_billing_payment } = billData || {}

    const message = "Already Exists";
    return (
        <>
            <div className='w-full p-2'>

                <div className='h-[45px] items-center pl-4 bg-met-secondary flex   font-semibold'>
                    Diagnosis Code
                </div>
                <>
                    <div className='mt-[20px] w-[50%]'>
                        <SelectDropdown placeholder="Search ICD Code"
                            options={icdCodesData}
                            onChangeCb={(selectedOpt) => {
                                if (selectedOpt && selectedOpt !== null && Object.keys(selectedOpt).length > 0) {
                                    const similarOpt = values?.diagnosisCodes?.find(item => item.uuid === selectedOpt.uuid)
                                    if (similarOpt) {
                                        dispatch(addNotifications({ message: `${selectedOpt.icdCodeDescription} ${message}`, variant: TOASTER_VARIANT.ERROR }))
                                    }
                                    else {
                                        addDIagnosisCodes(selectedOpt)
                                    }
                                }
                            }}
                            onBlurCb={handleBlur}
                            disabled={mapping_billing_payment?.length ? true : false}
                        />
                    </div>
                </>
                <div className='pt-[20px]'>
                    <Table isPagination={false} coloumns={coloumns} rows={values?.diagnosisCodes} />
                </div>
            </div >
        </>
    )
}

export default DiagnosisCodeTable
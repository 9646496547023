export const ABHA_FIELDS_NAMES = {
    SELECT_OPTION: "getOption",
    ABHA_NUMBER: "abhaNumber",
    ABHA_ADDRESS: "abhaAddress",
    AADHAR_NUMBER: "aadharNumber",
};

export const ABHA_OPTIONS = {
    GET_OPTIONS: [
        { label: "Aadhar Number", value: "Aadhar Number" },
        { label: "ABHA Number", value: "ABHA Number" },
        { label: "ABHA Address", value: "ABHA Address" },
        { label: "Mobile Number", value: "Mobile Number"}
    ],

};

export const GENDER_OPTIONS = [
    {label: "M", value: "Male"},
    {label: "F", value: "Female"},
]

export const ABHA_VERIFICATION_OPTIONS = [
    {label: "Aadhar Link Number", value: "aadharLink"},
    {label: "ABHA Link Number", value: "mobileLink"}
]

export const FORM_FIELDS_NAMES = {
    PROFILE_PICTURE: "profilePicture",
    FIRSTNAME: 'firstName',
    MIDDLENAME: 'middleName',
    LASTNAME: "lastName",
    DOB: "dob",
    GENDER: "gender",
    MARITAL_STATUS: "maritalStatus",
    AADHAR_NUMBER: "aadhar",
    TIMEZONE: "timeZone",
    LANGUAGE: "language",
    IS_PATIENT_AADHAR: "isPatientsAadhar",
    MOBILE_NUMBER:"mobileNumber",
    OTP:"otp"
}

export const GARDIAN_FIELDS_NAMES = {
    GUARDIAN_NAME: "guardianName",
    RELATIONSHIP: "relationWithPatient",
    GUARDIAN_AADHAR_NUMBER: "guardianAadhar",
    PATIENT_DOB: "patientsBirthTimestamp",
}

export const LANGUAGE_OPTIONS = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Other", value: "Other" }
]

export const TIMEZONE_OPTIONS = [
    { label: 'IST', value: 'IST' },
    { label: 'PST', value: 'PST' },
    { label: 'EST', value: 'EST' },
    { label: 'CST', value: 'CST' },
    { label: 'MST', value: 'MST' },
    { label: 'AST', value: 'AST' },
    { label: 'HST', value: 'HST' },
];
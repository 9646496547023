export const SettingMasterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
            <g id="Group_96326" data-name="Group 96326" transform="translate(-48 -132)">
                <rect id="Rectangle_36519" data-name="Rectangle 36519" width="70" height="70" rx="5" transform="translate(48 132)" fill="rgba(204,236,255,0.5)" />
                <g id="file_1_" data-name="file(1)" transform="translate(47 131.481)">
                    <g id="Group_90483" data-name="Group 90483" transform="translate(44.589 21.55)">
                        <path id="Path_205814" data-name="Path 205814" d="M42.916,27.423a3.493,3.493,0,0,1-3.644-3.563c0-4.68,7.289-4.678,7.289,0a3.492,3.492,0,0,1-3.644,3.563Zm0-4.775a1.215,1.215,0,1,0,1.294,1.212A1.192,1.192,0,0,0,42.916,22.647Z" transform="translate(-39.272 -20.35)" fill="#1b5984" />
                    </g>
                    <g id="Group_90484" data-name="Group 90484" transform="translate(28.444 21.993)">
                        <path id="Path_205815" data-name="Path 205815" d="M29.185,27.8a3.493,3.493,0,0,1-3.646-3.563c0-4.678,7.289-4.68,7.289,0A3.492,3.492,0,0,1,29.185,27.8Zm0-4.775a1.215,1.215,0,1,0,1.293,1.212A1.193,1.193,0,0,0,29.185,23.024Z" transform="translate(-25.539 -20.727)" fill="#1b5984" />
                    </g>
                    <g id="Group_90485" data-name="Group 90485" transform="translate(13.835 21.993)">
                        <path id="Path_205816" data-name="Path 205816" d="M16.759,27.8a3.493,3.493,0,0,1-3.646-3.563c0-4.678,7.289-4.68,7.289,0A3.492,3.492,0,0,1,16.759,27.8Zm0-4.775a1.215,1.215,0,1,0,0,2.424,1.215,1.215,0,1,0,0-2.424Z" transform="translate(-13.113 -20.727)" fill="#1b5984" />
                    </g>
                    <g id="Group_90486" data-name="Group 90486" transform="translate(49.607 42.727)">
                        <path id="Path_205817" data-name="Path 205817" d="M44.716,41.678a1.176,1.176,0,0,1-.27-2.32l4.062-.963a1.175,1.175,0,0,1,.542,2.287l-4.062.963a1.136,1.136,0,0,1-.272.033Z" transform="translate(-43.541 -38.363)" fill="#1b5984" />
                    </g>
                    <g id="Group_90487" data-name="Group 90487" transform="translate(50.811 47.803)">
                        <path id="Path_205818" data-name="Path 205818" d="M45.74,46a1.176,1.176,0,0,1-.27-2.32l4.061-.963A1.175,1.175,0,1,1,50.072,45l-4.061.963A1.122,1.122,0,0,1,45.74,46Z" transform="translate(-44.565 -42.681)" fill="#1b5984" />
                    </g>
                    <g id="Group_90488" data-name="Group 90488" transform="translate(38.273 18.493)">
                        <path id="Path_205819" data-name="Path 205819" d="M43.258,54.624a1.176,1.176,0,0,1-1.143-.905L33.932,19.2a1.175,1.175,0,1,1,2.287-.542L44.4,53.177a1.174,1.174,0,0,1-.872,1.414A1.122,1.122,0,0,1,43.258,54.624Z" transform="translate(-33.9 -17.75)" fill="#1b5984" />
                    </g>
                    <g id="Group_90489" data-name="Group 90489" transform="translate(38.215 15.98)">
                        <path id="Path_205820" data-name="Path 205820" d="M35.083,20.478a1.177,1.177,0,0,1-1.143-.9,3.261,3.261,0,0,1,2.422-3.928,1.175,1.175,0,0,1,.542,2.287.914.914,0,0,0-.676,1.1,1.174,1.174,0,0,1-.871,1.415,1.155,1.155,0,0,1-.274.033Z" transform="translate(-33.85 -15.613)" fill="#1b5984" />
                    </g>
                    <g id="Group_90490" data-name="Group 90490" transform="translate(39.823 13.574)">
                        <path id="Path_205821" data-name="Path 205821" d="M36.393,18.325a1.176,1.176,0,0,1-.27-2.32L46.276,13.6a1.175,1.175,0,0,1,.542,2.287L36.664,18.292a1.136,1.136,0,0,1-.272.033Z" transform="translate(-35.218 -13.566)" fill="#1b5984" />
                    </g>
                    <g id="Group_90491" data-name="Group 90491" transform="translate(49.975 13.519)">
                        <path id="Path_205822" data-name="Path 205822" d="M47.541,17.476a1.178,1.178,0,0,1-1.144-.905.9.9,0,0,0-.408-.564A.918.918,0,0,0,45.3,15.9a1.175,1.175,0,1,1-.544-2.287,3.261,3.261,0,0,1,3.929,2.422,1.177,1.177,0,0,1-.874,1.415,1.265,1.265,0,0,1-.272.031Z" transform="translate(-43.854 -13.519)" fill="#1b5984" />
                    </g>
                    <g id="Group_90492" data-name="Group 90492" transform="translate(52.488 15.123)">
                        <path id="Path_205823" data-name="Path 205823" d="M55.349,51.758a1.176,1.176,0,0,1-1.143-.905L46.023,16.331a1.175,1.175,0,0,1,2.287-.542l8.184,34.522a1.174,1.174,0,0,1-.872,1.414,1.136,1.136,0,0,1-.272.033Z" transform="translate(-45.991 -14.884)" fill="#1b5984" />
                    </g>
                    <g id="Group_90493" data-name="Group 90493" transform="translate(46.457 53.016)">
                        <path id="Path_205824" data-name="Path 205824" d="M44.061,51.072a3.263,3.263,0,0,1-3.168-2.511,1.176,1.176,0,0,1,2.289-.542.9.9,0,0,0,.408.564.918.918,0,0,0,.688.112,1.175,1.175,0,1,1,.544,2.287,3.233,3.233,0,0,1-.761.091Z" transform="translate(-40.861 -47.115)" fill="#1b5984" />
                    </g>
                    <g id="Group_90494" data-name="Group 90494" transform="translate(48.969 52.158)">
                        <path id="Path_205825" data-name="Path 205825" d="M44.173,51.144a1.176,1.176,0,0,1-.27-2.32l10.154-2.407A1.175,1.175,0,1,1,54.6,48.7L44.444,51.111A1.136,1.136,0,0,1,44.173,51.144Z" transform="translate(-42.998 -46.385)" fill="#1b5984" />
                    </g>
                    <g id="Group_90495" data-name="Group 90495" transform="translate(59.123 49.646)">
                        <path id="Path_205826" data-name="Path 205826" d="M52.81,49.113a1.176,1.176,0,0,1-.27-2.32.914.914,0,0,0,.676-1.1,1.175,1.175,0,1,1,2.287-.544A3.261,3.261,0,0,1,53.08,49.08a1.119,1.119,0,0,1-.27.033Z" transform="translate(-51.635 -44.248)" fill="#1b5984" />
                    </g>
                    <g id="Group_90496" data-name="Group 90496" transform="translate(14.216 44.187)">
                        <path id="Path_205827" data-name="Path 205827" d="M18.787,41.956H14.613a1.176,1.176,0,1,1,0-2.351h4.175a1.176,1.176,0,1,1,0,2.351Z" transform="translate(-13.437 -39.605)" fill="#1b5984" />
                    </g>
                    <g id="Group_90497" data-name="Group 90497" transform="translate(14.216 49.404)">
                        <path id="Path_205828" data-name="Path 205828" d="M18.787,46.394H14.613a1.176,1.176,0,1,1,0-2.351h4.175a1.176,1.176,0,0,1,0,2.351Z" transform="translate(-13.437 -44.043)" fill="#1b5984" />
                    </g>
                    <g id="Group_90498" data-name="Group 90498" transform="translate(28.826 44.187)">
                        <path id="Path_205829" data-name="Path 205829" d="M31.213,41.956H27.04a1.176,1.176,0,1,1,0-2.351h4.174a1.176,1.176,0,1,1,0,2.351Z" transform="translate(-25.864 -39.605)" fill="#1b5984" />
                    </g>
                    <g id="Group_90499" data-name="Group 90499" transform="translate(28.826 49.404)">
                        <path id="Path_205830" data-name="Path 205830" d="M31.213,46.394H27.04a1.176,1.176,0,0,1,0-2.351h4.174a1.176,1.176,0,1,1,0,2.351Z" transform="translate(-25.864 -44.043)" fill="#1b5984" />
                    </g>
                    <g id="Group_90500" data-name="Group 90500" transform="translate(21.523 14.97)">
                        <path id="Path_205831" data-name="Path 205831" d="M22.913,19.19a1.175,1.175,0,0,1-1.176-1.176.911.911,0,0,0-.91-.91,1.176,1.176,0,0,1,0-2.351,3.264,3.264,0,0,1,3.261,3.261A1.175,1.175,0,0,1,22.913,19.19Z" transform="translate(-19.652 -14.753)" fill="#1b5984" />
                    </g>
                    <g id="Group_90501" data-name="Group 90501" transform="translate(11.087 14.97)">
                        <path id="Path_205832" data-name="Path 205832" d="M22.386,17.1H11.951a1.176,1.176,0,1,1,0-2.351H22.386a1.176,1.176,0,0,1,0,2.351Z" transform="translate(-10.775 -14.753)" fill="#1b5984" />
                    </g>
                    <g id="Group_90502" data-name="Group 90502" transform="translate(23.609 17.055)">
                        <path id="Path_205833" data-name="Path 205833" d="M22.6,54.358a1.175,1.175,0,0,1-1.176-1.176V17.7a1.176,1.176,0,1,1,2.351,0v35.48A1.175,1.175,0,0,1,22.6,54.358Z" transform="translate(-21.426 -16.527)" fill="#1b5984" />
                    </g>
                    <g id="Group_90503" data-name="Group 90503" transform="translate(23.609 14.97)">
                        <path id="Path_205834" data-name="Path 205834" d="M22.6,19.19a1.175,1.175,0,0,1-1.176-1.176,3.265,3.265,0,0,1,3.262-3.261,1.176,1.176,0,0,1,0,2.351.912.912,0,0,0-.911.91A1.175,1.175,0,0,1,22.6,19.19Z" transform="translate(-21.426 -14.753)" fill="#1b5984" />
                    </g>
                    <g id="Group_90504" data-name="Group 90504" transform="translate(25.695 14.97)">
                        <path id="Path_205835" data-name="Path 205835" d="M34.812,17.1H24.377a1.176,1.176,0,0,1,0-2.351H34.812a1.176,1.176,0,1,1,0,2.351Z" transform="translate(-23.201 -14.753)" fill="#1b5984" />
                    </g>
                    <g id="Group_90505" data-name="Group 90505" transform="translate(36.131 14.97)">
                        <path id="Path_205836" data-name="Path 205836" d="M35.34,19.19a1.175,1.175,0,0,1-1.176-1.176.912.912,0,0,0-.911-.91,1.176,1.176,0,1,1,0-2.351,3.265,3.265,0,0,1,3.262,3.261A1.175,1.175,0,0,1,35.34,19.19Z" transform="translate(-32.078 -14.753)" fill="#1b5984" />
                    </g>
                    <g id="Group_90506" data-name="Group 90506" transform="translate(38.218 17.055)">
                        <path id="Path_205837" data-name="Path 205837" d="M35.029,54.358a1.175,1.175,0,0,1-1.176-1.176V17.7a1.176,1.176,0,1,1,2.351,0v35.48A1.175,1.175,0,0,1,35.029,54.358Z" transform="translate(-33.853 -16.527)" fill="#1b5984" />
                    </g>
                    <g id="Group_90507" data-name="Group 90507" transform="translate(9 14.97)">
                        <path id="Path_205838" data-name="Path 205838" d="M10.176,19.19A1.175,1.175,0,0,1,9,18.014a3.265,3.265,0,0,1,3.262-3.261,1.176,1.176,0,0,1,0,2.351.912.912,0,0,0-.911.91A1.175,1.175,0,0,1,10.176,19.19Z" transform="translate(-9 -14.753)" fill="#1b5984" />
                    </g>
                    <g id="Group_90508" data-name="Group 90508" transform="translate(9 17.055)">
                        <path id="Path_205839" data-name="Path 205839" d="M10.176,54.358A1.175,1.175,0,0,1,9,53.182V17.7a1.176,1.176,0,0,1,2.351,0v35.48A1.175,1.175,0,0,1,10.176,54.358Z" transform="translate(-9 -16.527)" fill="#1b5984" />
                    </g>
                    <g id="Group_90509" data-name="Group 90509" transform="translate(21.522 52.535)">
                        <path id="Path_205840" data-name="Path 205840" d="M20.827,51.143a1.176,1.176,0,1,1,0-2.351.911.911,0,0,0,.91-.91,1.176,1.176,0,0,1,2.351,0A3.263,3.263,0,0,1,20.827,51.143Z" transform="translate(-19.651 -46.706)" fill="#1b5984" />
                    </g>
                    <g id="Group_90510" data-name="Group 90510" transform="translate(11.087 54.621)">
                        <path id="Path_205841" data-name="Path 205841" d="M22.386,50.831H11.951a1.176,1.176,0,1,1,0-2.351H22.386a1.176,1.176,0,1,1,0,2.351Z" transform="translate(-10.775 -48.48)" fill="#1b5984" />
                    </g>
                    <g id="Group_90511" data-name="Group 90511" transform="translate(9 52.535)">
                        <path id="Path_205842" data-name="Path 205842" d="M12.262,51.143A3.265,3.265,0,0,1,9,47.882a1.176,1.176,0,0,1,2.351,0,.912.912,0,0,0,.911.91,1.176,1.176,0,0,1,0,2.351Z" transform="translate(-9 -46.706)" fill="#1b5984" />
                    </g>
                    <g id="Group_90512" data-name="Group 90512" transform="translate(23.609 52.535)">
                        <path id="Path_205843" data-name="Path 205843" d="M24.688,51.143a3.265,3.265,0,0,1-3.262-3.261,1.176,1.176,0,0,1,2.351,0,.912.912,0,0,0,.911.91,1.176,1.176,0,0,1,0,2.351Z" transform="translate(-21.426 -46.706)" fill="#1b5984" />
                    </g>
                    <g id="Group_90513" data-name="Group 90513" transform="translate(25.695 54.621)">
                        <path id="Path_205844" data-name="Path 205844" d="M34.812,50.831H24.377a1.176,1.176,0,1,1,0-2.351H34.812a1.176,1.176,0,1,1,0,2.351Z" transform="translate(-23.201 -48.48)" fill="#1b5984" />
                    </g>
                    <g id="Group_90514" data-name="Group 90514" transform="translate(36.13 52.535)">
                        <path id="Path_205845" data-name="Path 205845" d="M33.253,51.143a1.176,1.176,0,0,1,0-2.351.912.912,0,0,0,.911-.91,1.176,1.176,0,1,1,2.351,0A3.265,3.265,0,0,1,33.253,51.143Z" transform="translate(-32.077 -46.706)" fill="#1b5984" />
                    </g>
                </g>
            </g>
        </svg>
    )
}
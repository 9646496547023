import React, { useEffect, useState } from 'react';
import Label from '../../../../components/common-components/label/Label';
import Icons from '../../../../components/icons/Icons';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import PatientInfoCards from './PatientInfoCards';
import BadgeWithCloseIcon from '../Dashboard/Badge';
import RoundFab from '../../../../components/common-components/Fab/RoundFab';
import FlagsModal from './FlagsModal';
import useGetNameInitials from '../../../../libs/customHooks/useGetNameInitials';
import { useSelector } from 'react-redux';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice'
import { getFormattedPatientAgeDob } from '../../../../libs/patientUtils';
import useBase64Image from '../../../../containers/SettingsTab/MyAccountCard/Tabs/PrintConfiguration/useS3Image';
import MetPopOver from '../../../../components/common-components/popoverComponent/MetPopOver';
import { useNavigate } from 'react-router-dom';

function PatientInfo() {
	const getNameInitials = useGetNameInitials()
	const [isFlagesOpen, setIsFlagesOpen] = useState(false);
	const navigate = useNavigate()
	const [base64Data, setBase64Data] = useState("")

	const { patientData } = useSelector((state) => state[PatientDashboardComponentKey]);

	const handleFlagesOpen = () => {
		setIsFlagesOpen(true);
	};

	const getBase64 = useBase64Image(patientData?.profilePicture);

	useEffect(() => {
		const getBase64Data = async () => {
			const base64Data = await getBase64(patientData?.profilePicture)
			setBase64Data(base64Data)
		}
		getBase64Data()
	}, [patientData?.profilePicture])

	return (
		<>
			<div className="flex flex-row justify-between p-4 column-gap-10px row-gap-10 flex-wrap">
				<div className="flex column-gap-10px justify-between flex-1 flex-wrap min-w-[600px]">
					<RoundFab customClasses="w-[100px] h-[100px]" color="gray-400">
						{patientData?.profilePicture ? <img className="h-full w-full overflow-hidden object-cover rounded-full" src={base64Data} alt='profile-pic'></img> : <h1 className="fs-18">{getNameInitials(`${patientData?.firstName} ${patientData?.lastName}`)}</h1>}
					</RoundFab>
					<div className="flex flex-wrap flex-1">
						<Label fontWeight='bold' fontSize="xl" customClass="w-1/3">{`${patientData?.firstName || ''} ${patientData?.lastName || ''} (${patientData?.uuid ? patientData.uuid.substr(-4) : ''})`}</Label>
						<span className='w-1/3'>
							{(patientData.patientPortalAccessStatus === "Disabled" || patientData.patientPortalAccessStatus === null) && (
								<Button
									variant={BUTTON_VARIANTS.CONTAINED_GRAY}
									startIcon={{ icon: <Icons iconName="plusVectorIcon" height={18} width={18} /> }}
									customBtnClass="h-[28px]"
									onClickCb={() => navigate("portal-access")}
								>
									Invite Portal
								</Button>
							)}
							{patientData.patientPortalAccessStatus === "Pending" && (
								<Button
									variant={BUTTON_VARIANTS.OUTLINED}
									startIcon={{
										icon: <Icons iconName="clockWithPendingStatusIcon" />,
									}}
									customBtnClass="text-yellow-500  bg-yellow-50 bg-no-repeat bg-0 bg-0 bg-padding-box  text-opacity-100 border-yellow-500  h-[28px] "
								>
									Pending Invitaion
								</Button>
							)}
							{patientData.patientPortalAccessStatus === "Enrolled" && (
								<Button
									variant={BUTTON_VARIANTS.OUTLINED}
									startIcon={{
										icon: <Icons iconName="checkCircleIcon" />,
									}}
									customBtnClass="text-met-success border-green-500 bg-yellow-50 bg-opacity-20 h-[28px]"
								>
									Enrolled Patient
								</Button>
							)}
						</span>
						<span className='w-1/3 flex column-gap-10px'>
							{/* NOT IMPLEMENTED IN BACKEND */}
							{/* <Button
								variant={BUTTON_VARIANTS.TEXT}
								startIcon={{ icon: 'addWithCircleIcon' }}
								customBtnClass="text-met-primary fs-14 h-[28px] p-0"
								onClickCb={handleFlagesOpen}
							>
								Flag
							</Button> */}
							{/* <BadgeWithCloseIcon /> */}
						</span>
						<div className='flex w-1/3 column-gap-10px items-center'>
							<Icons iconName="emailIcon" />
							<Label color="met-dimmed-charcoal" fontSize="sm">{patientData?.email || '-'}</Label>
						</div>
						<div className="flex w-1/4 column-gap-10px items-center">
							<Icons iconName="languageGlobIcon" />
							<Label color="met-dimmed-charcoal" fontSize="sm">{patientData?.timeZone || '-'}</Label>
						</div>
						<div className="flex w-1/4 column-gap-10px items-center">
							<Icons iconName="phoneIcon" />
							<Label color="met-dimmed-charcoal" fontSize="sm">{patientData?.mobileNumber || '-'}</Label>
						</div>
						<div className="flex w-1/3 column-gap-10px items-center">
							<Icons iconName="calenderIcon" />
							<Label color="met-dimmed-charcoal" fontSize="sm">{getFormattedPatientAgeDob(patientData?.dob)} {patientData?.gender ? `(${patientData.gender.substring(0, 1).toUpperCase()})` : ''}</Label>
						</div>
						<div className="flex w-1/4 column-gap-10px items-center">
							<Icons iconName="translateIcon" />
							<Label color="met-dimmed-charcoal" fontSize="sm">{patientData?.language || '-'}</Label>
						</div>
						<div className="flex w-1/4 column-gap-10px items-center">
							<div>
								<Icons iconName="locationIcon" width={24} height={24} />
							</div>
							{/* <Label color="met-dimmed-charcoal" fontSize="sm">8642 Yule Street, Arvada CO 80007</Label> */}
							{/* //TODO: update this once BE up */}
							<Label color="met-dimmed-charcoal" fontSize="sm"><MetPopOver maxChar="50">{patientData?.Address && patientData.Address[0] ? `${patientData?.Address[0]?.addressLine1 ?? ''}, ${patientData?.Address[0]?.addressLine2 ?? ''} ${patientData?.Address[0]?.city ?? ''} ${patientData?.Address[0]?.state ?? ''} ${patientData?.Address[0]?.country ?? ''} ${patientData?.Address[0]?.zip ?? ''}` : '-'}</MetPopOver></Label>
						</div>
					</div>
				</div>
				<PatientInfoCards />
			</div>
			{isFlagesOpen ? <FlagsModal isOpen={isFlagesOpen} close={() => { setIsFlagesOpen(false) }} /> : null}
		</>
	);
}

export default PatientInfo;

import AppDataService from './AppDataService'

const COMMON_BASE = 'patients'

export default class PatientDataService {

    static async getPatientGridList(params) {
        return await AppDataService.get(`${COMMON_BASE}`, params)
    }

    static async createNewPatient(data) {
        return await AppDataService.post(`${COMMON_BASE}`, data)
    }

    static async savePatientPreferences(uuid, data) {
        return await AppDataService.post(`${COMMON_BASE}/${uuid}/preferences`, data)
    }

    static async updatePatientDetails(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}`, data)
    }

    static async getPatientDetails(uuid, params) {
        return await AppDataService.get(`${COMMON_BASE}/${uuid}`, { params })
    }

    static async invitePortalAccess(uuid) {
        return await AppDataService.post(`${COMMON_BASE}/${uuid}/invite`, {})
    }

    static async resendPortalAccess(uuid) {
        return await AppDataService.post(`${COMMON_BASE}/${uuid}/invite/resend`, {})
    }

    static async revokedPortalAccess(uuid) {
        return await AppDataService.post(`${COMMON_BASE}/${uuid}/invite/disable`, {})
    }
    static async upcomingbirthdays() {
        return await AppDataService.get(`${COMMON_BASE}/upcomingbirth`,)
    }
    static async validateAadhaar(data) {
        return await AppDataService.post(`${COMMON_BASE}/validate/aadhar`,data)
    }
    static async getExistingABHADetails(data) {
        const queryString = new URLSearchParams(data).toString();
        return await AppDataService.get(`abha?${queryString}`);
    }
    static async abhaIntAadhaarValidation(data) {
        return await AppDataService.post(`abha/send/otp`,data)
    }
    static async otpVerification(data) {
        return await AppDataService.post(`abha/verify/otp`,data)
    }
    static async abhaAddressSuggetions(data){
        return await AppDataService.post(`abha/suggest/address`,data)
    }
    static async abhaAddressUpdate(data){
        return await AppDataService.post(`abha/change/address`,data)
    }
    static async downloadCard(data){
        return await AppDataService.post(`abha/card`,data)
    }
}
import React from 'react'

const SortingIcon = ({ color = "rgba(76,76,76,0.8)" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12.687" height="14" viewBox="0 0 12.687 14">
            <path id="Path_18254" data-name="Path 18254" d="M116.388,25l0,0h0a.446.446,0,0,1-.03.037c-.008.01-.015.02-.024.029l-.012.01-.024.02c-.01.008-.02.018-.031.025h0c-.01.006-.02.011-.03.017l-.017.009-.02.01-.021.008h0l-.045.015-.013,0-.019,0h0a.437.437,0,0,1-.078.008h0a.436.436,0,0,1-.078-.008l-.019,0-.013,0h0l-.045-.015-.021-.008-.02-.01-.017-.009c-.01-.005-.02-.01-.03-.017h0c-.011-.008-.021-.017-.031-.025l-.024-.02-.012-.01c-.009-.009-.016-.019-.024-.029h0a.444.444,0,0,1-.03-.037h0l-2.625-3.937a.437.437,0,0,1,.728-.485l1.823,2.735V11.635a.438.438,0,1,1,.875,0v11.68l1.823-2.735a.437.437,0,0,1,.728.485Zm-6.562-13.6,0,0h0a.445.445,0,0,0-.03-.037c-.008-.01-.015-.02-.024-.029h0c-.008-.008-.018-.015-.027-.022h0a.414.414,0,0,0-.038-.031h0c-.011-.008-.024-.013-.036-.02h0l-.038-.02h0c-.01,0-.021-.006-.032-.01l-.012,0c-.012,0-.023-.008-.035-.011h0l-.043,0c-.013,0-.027,0-.04,0h0c-.014,0-.027,0-.041,0h0l-.043,0h0l-.027.008-.024.007-.027.008h0c-.011,0-.021.011-.031.017a.452.452,0,0,0-.044.024c-.011.007-.02.017-.031.025a.428.428,0,0,0-.035.029h0c-.009.009-.016.019-.024.029a.429.429,0,0,0-.03.037h0l-2.625,3.937a.437.437,0,0,0,.728.485l1.823-2.735V24.76a.438.438,0,0,0,.875,0V13.08l1.823,2.735a.437.437,0,0,0,.728-.485Z" transform="translate(-106.397 -11.198)" fill={color} />
        </svg>
    )
}

export default SortingIcon
import AppDataService from './AppDataService'

const COMMON_BASE = 'providers'

export default class ProvidersDataService {

    static async getProvidersDetails(uuid) {
        return await AppDataService.get(`${COMMON_BASE}/${uuid}`)
    }

    static async getProvidersList(params) {
        return await AppDataService.get(`${COMMON_BASE}`, params)
    }

    static async saveProvidersDetails(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}`, data)
    }
    static async getAllProviderLocation() {
        return await AppDataService.get(`${COMMON_BASE}-locations`)
    }
    static async getProviderGroupLocation(providerId) {
        return await AppDataService.get(`${COMMON_BASE}-locations/assigned/${providerId}`)
    }
    static async postLocationData(data) {
        return await AppDataService.post(`${COMMON_BASE}-locations`, data)
    }
    static async updateLocations(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}-locations/${uuid}`, data)

    }
    static async updateProfileData(uuid, data) {
        return await AppDataService.patch(`providergroup/${uuid}`, data)
    }
    static async getAvailability(providerId, data) {
        return await AppDataService.post(`${COMMON_BASE}/get/availability/${providerId}`, data)
    }

    static async getProviderLocations(providerId) {
        return await AppDataService.get(`${COMMON_BASE}/${providerId}`)
    }

    static async postAvailability(data) {
        return await AppDataService.post(`${COMMON_BASE}/availability`, data)
    }

    static async updateAvailability(data, availabilityId) {
        return await AppDataService.patch(`${COMMON_BASE}/availability/${availabilityId}`, data)
    }

    static async postAvailabilityForADay(data) {
        return await AppDataService.post(`${COMMON_BASE}/availability/foraday`, data)
    }

    static async postAddProviderUser(data) {
        return await AppDataService.post(`${COMMON_BASE}`, data)
    }
    static async getAllProviders(params) {
        return await AppDataService.get(`${COMMON_BASE}`, { params })
    }
    //  static async getAllProviders(name, status,locationId,departmentId,role,type) {
    //     return await AppDataService.get(`${COMMON_BASE}?name=${name || ""}&status=${status || ""}&locationId=${locationId || ""}&departmentId=${departmentId || ""}&type=${type || ""}&role=${role || ""}`)
    // }
    static async getAllProvidersWorkLocations() {
        return await AppDataService.get(`${COMMON_BASE}-locations`)
    }
    static async patchAddProviderUser(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}`, data)
    }
    static async postCreateInviteLink(uuid) {
        return await AppDataService.post(`${COMMON_BASE}/${uuid}/resend-invite`, {})
    }
    static async updateProvidersStatus(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}/status`, data)
    }
    static async getAdminProvidersList(params) {
        return await AppDataService.get(`${COMMON_BASE}/admin/providers`, params)
    }
    static async getProviderAdminMetaData(params) {
        return await AppDataService.get(`${COMMON_BASE}/admin/dash-board`, { params }); 
    }
    static async postAddUser(data){
        return await AppDataService.post(`users`,data)
    }
    static async patchAddUser(id,data){
        return await AppDataService.patch(`users/${id}`,data)
    }
    static async getCountToDisplayDashboard(){
        return await AppDataService.get(`metsl/counts`)
    }
    static async bilingCount(params) {
        return await AppDataService.get(`billing/result/count`, params)
    }
    static async vitalTestResultCount(params) {
        return await AppDataService.get(`vitals-test/result/count`, params)
    }
    static async getProviderLocationsByProviderId(providerId) {
        return await AppDataService.get(`providers-locations/assigned/${providerId}`)
    }
}

import React from 'react';
import { useState } from 'react';
import Icons from '../../../../components/icons/Icons';

function BadgeWithCloseIcon() {
	const [hovered, setHovered] = useState(false);

	const closeBadge = () => {
		setHovered(false);
	};

	return (
		<div style={{ height: '28px' }} className="flex bg-yellow-100 rounded-lg flex-row items-center">
			<div
				className="text-met-goldenrod px-2 py-0 cursor-pointer relative"
				onMouseEnter={() => setHovered(true)}
			>
				Diabetic
			</div>
			{hovered && (
				<div className=" px-2 py-2  cursor-pointer " onClick={closeBadge}>
					<Icons iconName="plusVectorIcon" color="#D5A400" rotateDeg={45} />
				</div>
			)}
		</div>
	);
}

export default BadgeWithCloseIcon;

import React from 'react'
import Label from '../../components/common-components/label/Label'
import Input from '../../components/common-components/input/Input'
import Button from '../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../components/common-components/button/Constants'
import { Form, Formik } from 'formik'
import { FORM_FIELDS_NAMES } from './Constants'
import { getValidationSchema } from '../../libs/formsUtils'
import { VALIDATION_REGEX } from '../../libs/constant'
import { useDispatch, useSelector } from 'react-redux'
import { resetUserPassword } from './LoginSaga'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup';
import Loadable from '../../components/common-components/zoom/components/loadable/Loadable'
import { componentKey } from './LoginSlice'

const fields = [
    { fieldName: FORM_FIELDS_NAMES.ENTER_NEW_PASSWORD, isRequired: true, isPassword: true, regexPattern: VALIDATION_REGEX.PASSWORD_REGEX },
    { fieldName: FORM_FIELDS_NAMES.CONFIRM_NEW_PASSWORD, isRequired: true, isPassword: true, regexPattern: VALIDATION_REGEX.PASSWORD_REGEX },
];

const validationSchema = getValidationSchema(fields)
validationSchema.fields[FORM_FIELDS_NAMES.CONFIRM_NEW_PASSWORD] = Yup.string()
    .oneOf([Yup.ref(FORM_FIELDS_NAMES.ENTER_NEW_PASSWORD), null], 'Passwords must match')
    .required('Confirm Password is required')


function ResetPassword() {
    const dispatch = useDispatch()
    const [params, setParams] = useSearchParams()
    const magicLinkToken = params.get("t")
    const navigate = useNavigate()

    const { loginLoadingState } = useSelector(state => state[componentKey])
    return (
        <div className='flex flex-1 items-center justify-center'>
            < Formik
                initialValues={{
                    [FORM_FIELDS_NAMES.ENTER_NEW_PASSWORD]: "",
                    [FORM_FIELDS_NAMES.CONFIRM_NEW_PASSWORD]: "",
                }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    const payload = {
                        ...values,
                        magicLinkToken,
                    }
                    dispatch(resetUserPassword({ payload, navigate: navigate }))
                }
                }
            >
                {({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, handleBlur }) => {
                    return <Form onSubmit={handleSubmit} >
                        <div className='flex flex-1 items-center justify-center'>
                            <div className='w-[500px] h-[300px] flex flex-col justify-evenly'>
                                <div className='text-met-primary'>
                                    <Label fontSize={"xl"} fontWeight='bold'>Reset Password</Label>
                                </div>
                                <div>
                                    <Input placeholder={"Enter new password"}
                                        type="password"
                                        name={FORM_FIELDS_NAMES.ENTER_NEW_PASSWORD}
                                        value={values[FORM_FIELDS_NAMES.ENTER_NEW_PASSWORD]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.ENTER_NEW_PASSWORD, e.target.value) }}
                                        onBlurCb={handleBlur} />
                                </div>
                                <div>
                                    <Input placeholder={"Confirm new password"}
                                        type="password"
                                        name={FORM_FIELDS_NAMES.CONFIRM_NEW_PASSWORD}
                                        value={values[FORM_FIELDS_NAMES.CONFIRM_NEW_PASSWORD]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.CONFIRM_NEW_PASSWORD, e.target.value) }}
                                        onBlurCb={handleBlur} />
                                </div>
                                <Loadable loadingStates={loginLoadingState.state} loadingMessage={loginLoadingState.message}>
                                    <div>
                                        <Button type='submit' variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary w-full justify-center">Update Password</Button>
                                    </div>
                                </Loadable>
                            </div>
                        </div>
                    </Form>
                }}
            </Formik>
        </div >
    )
}

export default ResetPassword
import React, { useEffect, useState } from 'react';
import Label from '../../../../../components/common-components/label/Label';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import Table from '../../../../Table/Index';
import ButtonDropdown from '../../../../../components/common-components/buttonDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUUID, setDeleteVitalsTestModalOpen, setDeviceName, setIsCreateNewVitalsTests,setVitalsTestsData, setIsVitalsTestsModalOpen, componentKey as vitalsTestsKey } from './VitalsTestsSlice';
import { getDeviceName, getDeviceTestList } from './VitalsTestsSaga';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import { VITALS_TESTS_FIELDS } from './Constants';
import { Form, Formik } from 'formik';
import DeleteVitalsTest from './DeleteVitalsTest';

function VitalsTests() {
	const [selectedDevice, setselectedDevice] = useState({});
	const { deviceNamesList, deviceTestList,deleteVitalsTestModalOpen, deviceName } = useSelector((state) => state[vitalsTestsKey]);
	const dispatch = useDispatch();

    const role = localStorage.getItem('role')

	useEffect(() => {
		dispatch(getDeviceName());
	}, [dispatch]);

	useEffect(() => {
		if (selectedDevice.value) {
			dispatch(getDeviceTestList({ deviceName: (selectedDevice?.label || '').trim() }));
		}
	}, [selectedDevice, dispatch]);

	const initialValues = {
		tests: [],
		[VITALS_TESTS_FIELDS.DEVICE_NAME]: selectedDevice,
		[VITALS_TESTS_FIELDS.TEST_NAME]: '',
	};

	const columns = [
		{
			field: 'name',
			label: <Label fontWeight="font-bold">Name</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div>
							<Heading fontWeight="bold" type={HEADING.H1} color="gray-500" fontSize={'md'}>
								{row.testName}
							</Heading>
						</div>
					</>
				);
			},
		},
		{
			field: 'charges',
			label: <Label fontWeight="font-bold">Charges</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div>
							<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
								{row.charge}
							</Heading>
						</div>
					</>
				);
			},
		},
		{
			field: 'action',
			label: role !== 'Front Desk' && <Label fontWeight="font-bold">Action</Label>,
			renderLogic: (row) => {
				const handleEdit = (row) => {
					dispatch(setCurrentUUID(row?.uuid));
					dispatch(setIsVitalsTestsModalOpen(true));
					dispatch(setIsCreateNewVitalsTests(false))
					dispatch(setVitalsTestsData(row))
				};

				const handleDelete = (row) => {
					dispatch(setCurrentUUID(row?.uuid));
					dispatch(setDeleteVitalsTestModalOpen(true));
				};
				
				return (
					<>
						{role !== 'Front Desk' && <div className="flex items-center">
							<div>
								<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>{row.action}</Heading>
							</div>
							<ButtonDropdown
								customBtnClass="p-0"
								endIcon="moreVertIcon"
								options={[
									{ label: 'Edit', value: 'Edit' },
									{ label: 'Delete', value: 'Delete' },
								]}
								selectCb={(option) => {
									switch (option.value) {
										case 'Edit':
											handleEdit(row);
											break;
										case 'Delete':
											handleDelete(row);
											break;
										default:
											break;
									}
								}}
							/>
						</div>}
					</>
				);
			},
		},
	];
	return (
		<div>
			<Formik initialValues={initialValues} enableReinitialize>
				{({ values, setFieldValue }) => (
					<Form>
						<div className="mt-4 mb-4 bg-opacity-3 rounded-md">
							<div className="flex column-gap-10px">
								<div className="w-1/4 min-w-410 max-w-450">
									<SelectDropdown
										label="Select Device"
										placeholder="Select Device"
										name={VITALS_TESTS_FIELDS.DEVICE_NAME}
										value={values[VITALS_TESTS_FIELDS.DEVICE_NAME]}
										onChangeCb={(val) => {
											setFieldValue(VITALS_TESTS_FIELDS.DEVICE_NAME, val);
											setselectedDevice(val);
											dispatch(setDeviceName(val));

										}}
										options={deviceNamesList}
									/>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>

			<div className='pb-[30px]'>
				<Table isPagination={false} coloumns={columns} rows={deviceTestList} />
			</div>
			{deleteVitalsTestModalOpen && (
				<DeleteVitalsTest open = {deleteVitalsTestModalOpen} deviceName={deviceName.deviceName} close={()=>dispatch(setDeleteVitalsTestModalOpen(false))}/>	
			)}
		</div>
	);
}

export default VitalsTests;

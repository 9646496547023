import React from 'react'

function DocumentsIcon({ color = "#7f7f7f" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <path id="path1053" d="M0-682.665H18.249v18.249H0Z" transform="translate(0 682.665)" fill="rgba(26,26,26,0.5)" />
        </clipPath>
      </defs>
      <g id="Group_88422" data-name="Group 88422" transform="translate(-16634 7599)">
        <g id="Group_88400" data-name="Group 88400" transform="translate(-76 173)">
          <g id="Group_88394" data-name="Group 88394" transform="translate(-12 213)">
            <rect id="Rectangle_36164" data-name="Rectangle 36164" width="24" height="24" transform="translate(16722 -7985)" fill="rgba(255,255,255,0.5)" opacity="0" />
            <g id="folder" transform="translate(16724.875 -7982.126)">
              <g id="g1047" transform="translate(0 0.002)">
                <g id="g1049">
                  <g id="g1051" clipPath="url(#clip-path)">
                    <g id="g1057" transform="translate(0.534 2.584)">
                      <path id="path1059" d="M-512.365-476.266h-13.449a.6.6,0,0,1-.623-.581v-11.945a.576.576,0,0,1,.6-.555h4.323a.608.608,0,0,1,.5.247l1.191,1.666a.555.555,0,0,0,.452.226h6.645a.576.576,0,0,1,.6.555v2.989" transform="translate(526.437 489.347)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="1" />
                    </g>
                    <g id="g1061" transform="translate(0.621 8.302)">
                      <path id="path1063" d="M-509.209-268.067l3.014-6.053a.927.927,0,0,0-.9-1.31h-12.1a.993.993,0,0,0-.906.553l-3.1,6.624" transform="translate(523.195 275.43)" fill="none" stroke={color} strokeWidth="1" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default DocumentsIcon
import React from 'react'

function HistoryIcon({ color = "rgba(26,26,26,0.5)" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <path id="path2043" d="M0-682.665H17.077v17.077H0Z" transform="translate(0 682.665)" fill={color} />
        </clipPath>
      </defs>
      <g id="Group_88421" data-name="Group 88421" transform="translate(-16634 7599)">
        <g id="Group_88400" data-name="Group 88400" transform="translate(-76 173)">
          <g id="Group_88394" data-name="Group 88394" transform="translate(-12 213)">
            <rect id="Rectangle_36164" data-name="Rectangle 36164" width="24" height="24" transform="translate(16722 -7985)" fill="rgba(255,255,255,0.5)" opacity="0" />
            <g id="g2037" transform="translate(16725 -7299.335)">
              <g id="g2039" transform="translate(0 -682.665)">
                <g id="g2041" clipPath="url(#clip-path)">
                  <g id="g2047" transform="translate(0.534 8.572)">
                    <path id="path2049" d="M-311.995-156a4,4,0,0,1-4,4,4,4,0,0,1-4-4,4,4,0,0,1,4-4A4,4,0,0,1-311.995-156Z" transform="translate(319.999 160)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                  <g id="g2051" transform="translate(4.536 0.5)">
                    <path id="path2053" d="M2.881-626.589h8.126a1,1,0,0,0,1-1v-11.74l-3.335-3.335H1a1,1,0,0,0-1,1v6.061" transform="translate(0 642.665)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                  <g id="g2055" transform="translate(13.208 -9.5)">
                    <path id="path2057" d="M0,0V3.335H3.335Z" transform="translate(0 10)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                  <g id="g2059" transform="translate(8.537 9.572)">
                    <path id="path2061" d="M0,0H5.671" transform="translate(0 0)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                  <g id="g2063" transform="translate(6.871 6.57)">
                    <path id="path2065" d="M0,0H7.338" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                  <g id="g2067" transform="translate(9.554 12.574)">
                    <path id="path2069" d="M0,0H4.654" transform="translate(0 0)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                  <g id="g2071" transform="translate(4.536 10.573)">
                    <path id="path2073" d="M0-78v-2" transform="translate(0 80)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                  <g id="g2075" transform="translate(4.536 12.574)">
                    <path id="path2077" d="M0,0H1.334" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default HistoryIcon
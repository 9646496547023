import React, { useEffect, useState } from 'react'

import { HEADING } from '../../components/common-components/heading/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey as UnsignedVisitKey, setResetFilterUnsignedVisitData, setUnsignedVisitPaginationState, unsignedVisitState } from './UnsignedVisitSlice';
import { componentKey as EncounterKey, setIsPrintEncounterModalOpen } from '../Encounter/EncounterSlice';
import { BUTTON_VARIANTS } from '../../components/common-components/button/Constants';
import { useNavigate } from 'react-router-dom';
import { getUnsignedVisitData } from './UnsignedVisitSaga';
import Heading from '../../components/common-components/heading/Heading';

import SelectDropdown from '../../components/common-components/selectDropdown';
import Table from '../../containers/Table/Index';
import Label from '../../components/common-components/label/Label';
import moment from 'moment';
import CardContainer from '../../components/common-components/Cards/Container/CardContainer';
import DatePicker from '../../components/common-components/datePicker';
import Button from '../../components/common-components/button/Button';
import Icons from '../../components/icons/Icons';
import BillingViewSummaryModal from '../Billing/ReadyForBilling/viewSummary/BillingViewSummaryModal';
import Avatar from '../../components/common-components/avatar';
import { componentKey as routerComponentKey } from '../../routes/RoutesSlice';


const UnsignedVisit = () => {

    const { unsignedVisitListState, unsignedVisitPaginationState } = useSelector((state) => state[UnsignedVisitKey]);
    const { loggedInUser } = useSelector(state => state[routerComponentKey])
    const { isPrintEncounterModalOpen } = useSelector((state) => state[EncounterKey]);
    const [activeEncounterId, setActiveEncounterId] = useState(false)
    const [scheduleId, setScheduleId] = useState(false)
    const [patientId, setPatientId] = useState(false)
    const role = localStorage.getItem('role');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (unsignedVisitPaginationState.start != undefined && unsignedVisitPaginationState.end == undefined)
            return;
        dispatch(getUnsignedVisitData({ ...unsignedVisitPaginationState, providerId: loggedInUser?.uuid }));
    }, [
        dispatch,
        unsignedVisitPaginationState.limit,
        unsignedVisitPaginationState.pageNumber,
        unsignedVisitPaginationState.start,
        unsignedVisitPaginationState.end,
        unsignedVisitPaginationState.status,
        loggedInUser?.uuid
    ]);



    const coloumns = [
        {
            field: 'PatientName',
            label: <Label fontWeight="bold"> Rendering Provider </Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-start column-gap-10px">
                            <div>
                                <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                                    {`${row?.provider?.firstName} ${row?.provider?.lastName}`}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'dob',
            label: <Label fontWeight="bold"> Patient Name </Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-start column-gap-10px">
                            <Avatar
                                customClass="w-[40px] h-[40px] rounded-full"
                                url={row?.patient?.profilePicture}
                                name={`${row?.patient?.firstName} ${row?.patient?.lastName}`}
                            />
                            <div>
                                <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                                    {`${row?.patient?.firstName} ${row?.patient?.lastName}`}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
            sort: 'dob',
        },
        {
            field: 'contactDetails',
            label: <Label fontWeight="bold"> Appointment Type </Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                            {`${row?.schedules?.Appointment?.mode?.split("_").join(" ")}`}
                        </Heading>
                    </>
                );
            },
            sort: 'mobileNumber',
        },
        {
            field: 'pcp',
            label: <Label fontWeight="bold"> Date Of Service </Label>,
            renderLogic: (row) => {
                const formattedDate = row?.createdAt ? moment(row?.createdAt).format('DD-MM-YYYY') : '-';

                return (
                    <>
                        <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                            {formattedDate}

                        </Heading>
                    </>
                );
            },
            sort: 'firstName',
        },
        {
            field: 'lastVisit',
            label: <Label fontWeight="bold"> Visit Type</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                            {`${row?.schedules?.Appointment?.appointmentType?.split("_").join(" ")}`}
                        </Heading>
                    </>
                );
            },
            sort: 'lastVisit',
        },
        {
            field: 'lastVisit',
            label: <Label fontWeight="bold"> Chief Complaint </Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                            {row?.schedules?.complaints || "-"}
                        </Heading>
                    </>
                );
            },
            sort: 'lastVisit',
        }, {
            field: 'lastVisit',
            label: <Label fontWeight="bold"> Status </Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                            {row?.status || "-"}
                        </Heading>
                    </>
                );
            },
            sort: 'lastVisit',
        },
        {
            field: 'lastVisit',
            label: <Label fontWeight="bold"> Action </Label>,
            renderLogic: (row) => {
                return (
                    <>
                        {row?.status === "Signed" ? (
                            <Button
                                type="submit"
                                variant={BUTTON_VARIANTS.OUTLINED}
                                customBtnClass="text-met-primary px-1 py-0"
                                startIcon={{ icon: <Icons iconName="visibilityIcon" /> }}
                                onClickCb={() => {
                                    setScheduleId(row.scheduleId);
                                    setPatientId(row.patientId);
                                    setActiveEncounterId(row?.uuid);
                                    dispatch(setIsPrintEncounterModalOpen(true));
                                }}
                            >
                                View Summary
                            </Button>
                        ) : (
                            role !== 'Biller' && role !== 'Front Desk' && (
                                <Button
                                    type="submit"
                                    variant={BUTTON_VARIANTS.OUTLINED}
                                    customBtnClass="text-met-primary px-1 py-0"
                                    startIcon={{ icon: <Icons iconName="redirectionIcon" /> }}
                                    onClickCb={() => navigate(`/encounter/${row?.patientId}/${row?.uuid}/clinical-data`)}
                                >
                                    Open Encounter
                                </Button>
                            )
                        )}
                    </>
                );
            },
            sort: 'lastVisit',
        },
    ];

    return (
        <>
            <CardContainer customCssClass='flex flex-col row-gap-10'>
                <div className="flex justify-between column-gap-10px items-center">
                    <Heading fontSize="2xl" fontWeight="bold" type={HEADING.H1}>
                        Unsigned Visit
                    </Heading>

                    <div className='flex column-gap-10px items-center'>
                        <div className='flex column-gap-20px'>
                            <div>
                                <DatePicker label='Date From' placeholder='Choose Date'
                                    value={unsignedVisitPaginationState?.start}
                                    maxDate={new Date()}
                                    onChangeCb={
                                        (date) => {
                                            dispatch(setUnsignedVisitPaginationState({ start: date }))
                                        }
                                    }
                                />
                            </div>
                            <div> <DatePicker
                                label='Date End'
                                placeholder='Choose Date'
                                maxDate={new Date()}
                                value={unsignedVisitPaginationState?.end}
                                onChangeCb={(date) => {
                                    dispatch(setUnsignedVisitPaginationState({ end: date }))
                                }}
                                minDate={unsignedVisitPaginationState?.start || new Date()}
                            /></div>

                            <div>
                                <SelectDropdown
                                    label="Status"
                                    onChangeCb={(val) => {
                                        dispatch(setUnsignedVisitPaginationState({ pageNumber: 1 }))
                                        dispatch(setUnsignedVisitPaginationState({ status: val }));
                                    }}
                                    placeholder="Search by Status"
                                    value={unsignedVisitPaginationState.status}
                                    options={[
                                        { label: 'All', value: 'All' },
                                        { label: 'Signed', value: 'Signed' },
                                        { label: 'Unsigned', value: 'Unsigned' },
                                    ]}
                                />

                            </div>
                            <div className='pt-5'>
                                <Button
                                    onClickCb={() => {
                                        dispatch(setResetFilterUnsignedVisitData(unsignedVisitState));
                                    }}
                                    variant={BUTTON_VARIANTS.OUTLINED}
                                    customBtnClass="text-met-primary p-2"
                                >
                                    Clear Filters
                                </Button>
                            </div>
                        </div>

                    </div>

                </div>
                <Table
                    selectProps={{
                        onSelectAll: () => { },
                        onSelectRowsCb: () => { },
                        selectIdentifier: 'name',
                    }}
                    coloumns={coloumns}
                    rows={unsignedVisitListState}
                    paginationProps={{
                        isPagination: true,
                        totalCount: unsignedVisitPaginationState.total,
                        limit: unsignedVisitPaginationState.limit,
                        page: unsignedVisitPaginationState.pageNumber,
                        onPageChange: (page) => {
                            dispatch(setUnsignedVisitPaginationState({ pageNumber: page }));
                        },
                    }}

                />
                {isPrintEncounterModalOpen ? <BillingViewSummaryModal patientId={patientId} encounterId={activeEncounterId} scheduleId={scheduleId} isOpen={isPrintEncounterModalOpen} close={() => dispatch(setIsPrintEncounterModalOpen(false))}></BillingViewSummaryModal> : null}

            </CardContainer>
        </>
    )
}

export default UnsignedVisit
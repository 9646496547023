import React from 'react';
import './style.scss';
import Label from '../label/Label';
import { ErrorMessage } from 'formik';

function Input({
	name = "",
	id = null,
	placeholder = "",
	label = "",
	labelColor = "",
	labelFontSize = "",
	labelfontWeight = "bold",
	isRequired = false,
	asteriskPosition = "end",
	startIcon = "",
	endIcon = "",
	value = "",
	onChangeCb = () => { },
	onFocusCb = () => { },
	onBlurCb = () => { },
	bgInputColor = "",
	customClasses = "",
	errorContainerClasses = "",
	type = "text",
	disabled = false,
	autoComplete = ""
}) {
	return (
		<div className="block w-full justify-start relative">
			<div className="">
				<Label
					color={labelColor}
					fontSize={labelFontSize}
					fontWeight={labelfontWeight}
					isRequired={isRequired}
					asteriskPosition={asteriskPosition}
				>{label}</Label>
			</div>
			<div className="relative">
				{startIcon && <span className="absolute inset-y-0 left-0 flex items-center pl-3">{startIcon}</span>}
				<input
					autoComplete={autoComplete}
					type={type}
					name={name}
					id={id}
					placeholder={placeholder}
					className={`input-component block w-full p-2 focus:outline-none placeholder-gray-500 rounded-md opacity-100 bg-${bgInputColor || 'white'} bg-no-repeat bg-0 bg-0/0 padding-box  ${startIcon ? 'pl-[80px]' : ''} ${customClasses}`}
					value={value}
					onChange={onChangeCb}
					onFocus={onFocusCb}
					onBlur={onBlurCb}
					disabled={disabled}
				/>
				{endIcon && <span className="absolute inset-y-0 right-0 flex items-center pr-3">{endIcon}</span>}
			</div>
			{name && <div className={`mt-1 ${errorContainerClasses}`}>
				<ErrorMessage name={name}>{msg => <div className="error-text">{typeof msg === 'string' ? msg : msg.label}</div>}</ErrorMessage>
			</div>}
		</div>
	);
}

export default Input;

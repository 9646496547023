import React from 'react'

function InsuranceIcon({ color = "rgba(26,26,26,0.5)" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_88426" data-name="Group 88426" transform="translate(-16638 7248)">
        <rect id="Rectangle_36165" data-name="Rectangle 36165" width="24" height="24" transform="translate(16638 -7248)" fill="rgba(255,255,255,0.5)" opacity="0" />
        <path id="insurance" d="M4.059,17.824,3.782,17.7A16.645,16.645,0,0,1-2.44,12.807a4.173,4.173,0,0,1-.892-2.578V0l.969.416a7.665,7.665,0,0,0,3.01.743A6.774,6.774,0,0,0,3.7.221L4.059,0l.363.211a7.272,7.272,0,0,0,3.106.913A7.839,7.839,0,0,0,10.475.413l.962-.4V10.327a4.145,4.145,0,0,1-.788,2.44,14.6,14.6,0,0,1-6.311,4.939Zm-6-15.753v8.158a2.777,2.777,0,0,0,.593,1.716A15.274,15.274,0,0,0,4.081,16.3a13.172,13.172,0,0,0,5.441-4.35,2.763,2.763,0,0,0,.525-1.626V2.062a7.939,7.939,0,0,1-2.52.453A8.264,8.264,0,0,1,4.08,1.6a7.825,7.825,0,0,1-3.433.946A8.056,8.056,0,0,1-1.942,2.071ZM8.367,6.411l-.983-.983L3.3,9.516,1.034,7.253l-.983.983L3.3,11.481Zm0,0" transform="translate(16645.947 -7244.912)" fill={color} />
      </g>
    </svg>
  )
}

export default InsuranceIcon
import React from 'react'
import Input from '../input/Input'
function InputWithSuffix({ placeholder, name, value, onChangeCb = () => { } }) {
    return (
        <div className='flex'>
            <div>
                <Input
                    customClasses="block min-w-[45px] max-w-[45px] p-2 focus:outline-none  bg-met-secondary rounded-tr-none rounded-br-none"
                    bgInputColor="bg-secondary"
                    placeholder={placeholder}
                    name={name}
                    errorContainerClasses="absolute"
                />
            </div>
            <div>
                <Input
                    customClasses="block min-w-[96px] max-w-[93px] rounded-tl-none rounded-bl-none"
                    value={value}
                    onChangeCb={onChangeCb}
                />
            </div>
        </div>
    )
}

export default InputWithSuffix
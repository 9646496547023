import { all, call, put, takeLatest } from 'redux-saga/effects';
import store from '../../../../../store/store';
import {
	componentKey,
	setAllRoles,
	setAllRolesAndResp,
	setDeleteRoleModalOpen,
	setGetRoleById,
	setIsNewAddRoleModalOpen,
	setPaginationState,
} from './RolesAndRespomsibilitiiesSlice';
import RolesAndResponsibilitiesDataService from '../../../../../services/RolesAndResponsibilitiesService';
import { addNotifications } from '../../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../../components/common-components/toaster/MetToaster';

export const {
	getAllRolesAndResponsibilities,
	getAllRoles,
	postCreateNewRole,
	patchUpdatRole,
	deleteRole,
	getRoleByID,
} = {
	getAllRolesAndResponsibilities: (payload) => {
		return {
			type: 'ROLES_AND_RESP/GET_ALL_ROLES_AND_RESP',
			payload,
		};
	},
	getAllRoles: (payload) => {
		return {
			type: 'ROLES_AND_RESP/GET_ALL_ROLES',
			payload,
		};
	},
	postCreateNewRole: (payload) => {
		return {
			type: 'ROLES_AND_RESP/POST_CREATE_ROLE',
			payload,
		};
	},
	patchUpdatRole: (payload) => {
		return {
			type: 'ROLES_AND_RESP/PATCH_UPDATE_ROLE',
			payload,
		};
	},
	deleteRole: (payload) => {
		return {
			type: 'ROLES_AND_RESP/DELETE_ROLE',
			payload,
		};
	},
	getRoleByID: (payload) => {
		return {
			type: 'ROLES_AND_RESP/ROLE_BY_ID',
			payload,
		};
	},
};

function* getAllRolesAndResponsibilitiesAsync(action) {
	try {
		const response = yield RolesAndResponsibilitiesDataService.getAllRolesAndResponsibilities();
		if (response.status === 200) {
			yield put(setAllRolesAndResp(response?.data?.data));
		}
	} catch (error) {
		console.log('err:', error);
	}
}

function* getAllRolesAsync(action) {
	const { pageNumber, limit } = action;

	try {
		const response = yield RolesAndResponsibilitiesDataService.getAllRoles(pageNumber, limit);
		const { totalRecords, roles } = response.data.data
		if (response.status === 200) {
			yield put(setAllRoles(roles));
			yield put(setPaginationState({ totalRecords: totalRecords }));
		}
	} catch (error) {
		console.log('err:', error);
	}
}

function* postCreateNewRoleAsync(action) {
	const { roleData } = action.payload;
	try {
		const response = yield RolesAndResponsibilitiesDataService.postCreateNewRole(roleData);
		const { message } = response.data
		if (response.status === 201) {
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			yield put(setIsNewAddRoleModalOpen(false))
			yield call(getAllRolesAsync, getAllRoles())
		}
	} catch (error) {
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
		console.log('err:', error);
	}
}
function* patchUpdateRoleAsync(action) {
	const { uuid, editedRoleData } = action.payload;
	try {
		const response = yield RolesAndResponsibilitiesDataService.patchUpdatRole(uuid, editedRoleData);
		const { message } = response.data
		if (response.status === 200) {
			yield put(setIsNewAddRoleModalOpen(false))
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			yield call(getAllRolesAsync, getAllRoles())
		}
	} catch (error) {
		console.log('err:', error);
	}
}
function* deleteRoleAsync(action) {
	const { uuid } = action.payload;
	try {
		const response = yield RolesAndResponsibilitiesDataService.deleteRole(uuid);
		const { message } = response.data
		if (response.status === 200) {
			yield put(setDeleteRoleModalOpen(false));
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			yield call(getAllRolesAsync, getAllRoles())
		}
	} catch (error) {
		console.log('err:', error);
	}
}

function* getRoleByIDAsync(action) {
	const { uuid } = action.payload
	try {
		const response = yield RolesAndResponsibilitiesDataService.getRoleByID(uuid);
		if (response.status === 200) {
			yield put(setGetRoleById(response?.data?.data));
		}
	} catch (error) {
		console.log(error);
	}
}
function* rootSaga() {
	yield all([
		takeLatest(getAllRolesAndResponsibilities().type, getAllRolesAndResponsibilitiesAsync),
		takeLatest(getAllRoles().type, getAllRolesAsync),
		takeLatest(postCreateNewRole().type, postCreateNewRoleAsync),
		takeLatest(patchUpdatRole().type, patchUpdateRoleAsync),
		takeLatest(deleteRole().type, deleteRoleAsync),
		takeLatest(getRoleByID().type, getRoleByIDAsync),
	]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

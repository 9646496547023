import React, { useEffect } from 'react';
import Table from '../../../../../Table/Index';
import Label from '../../../../../../components/common-components/label/Label';
import Avatar from '../../../../../../components/common-components/avatar';
import Heading from '../../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../../components/common-components/heading/Constants';
import MetPopOver from '../../../../../../components/common-components/popoverComponent/MetPopOver';
import { useNavigate } from 'react-router-dom';
import { getFormattedPatientAgeDob } from '../../../../../../libs/patientUtils';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientsByLocationId } from '../LocationSaga';
import { componentKey as locationComponentKey } from '../LocationSlice';

const Patients = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { editLocationData, patientsByLocationId } = useSelector((state) => state[locationComponentKey]);

	const columns = [
		{
			field: 'PatientName',
			label: <Label fontWeight="bold">Patient Name</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-start column-gap-10px">
							<Avatar
								customClass="w-[40px] h-[40px] rounded-full"
								url={row?.profilePicture}
								name={`${row.firstName} ${row.lastName}`}
							/>
							<div>
								<Heading
									onClickCb={() => {
										navigate(`/patients/${row.uuid}`);
									}}
									fontWeight="bold"
									type={HEADING.H1}
									color="met-primary"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{`${row.firstName} ${row.lastName}`}
								</Heading>
								{/* <Label color="gray-500">{row.subLabel}</Label> */}
							</div>
						</div>
					</>
				);
			},
			sort: 'firstName',
		},
		{
			field: 'dob',
			label: <Label fontWeight="bold">Date of Birth</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{getFormattedPatientAgeDob(row.dob)}
						</Heading>
						<Label color="gray-500">{row.gender}</Label>
					</>
				);
			},
			sort: 'dob',
		},
		{
			field: 'contactDetails',
			label: <Label fontWeight="bold">Contact Details</Label>,
			renderLogic: (row) => {
				return (
					<>
						{row.Address.map((address, index) => (
							<div key={index}>
								<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
									<MetPopOver>
										{`${address?.addressLine1 ? address?.addressLine1 + ', ' : ''}${address?.addressLine2 ? address?.addressLine2 + ', ' : ''
											}${address?.city ? address?.city + ', ' : ''}${address?.state ? address?.state + ', ' : ''
											} ${address?.country ? address?.country + ', ' : ''} ${address.zip}`}
									</MetPopOver>
								</Heading>
								<Label color="gray-500">{row.mobileNumber}</Label>
								<div>
									<Label color="met-primary">{row.email}</Label>
								</div>
							</div>
						))}
					</>
				);
			},
			sort: 'mobileNumber',
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Primary Care Provider (PCP)</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row.provider?.firstName + ' ' + row.provider?.lastName}
						</Heading>
					</>
				);
			},
			sort: 'firstName',
		},
		{
			field: 'lastVisit',
			label: <Label fontWeight="bold">Last Visit</Label>,
			renderLogic: (row) => {
				const formattedDate = row.lastVisit ? moment(row.lastVisit).format('ddd MMM DD YYYY') : '-';
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{formattedDate}
						</Heading>
					</>
				);
			},
			sort: 'lastVisit',
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Status</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{/* <ToggleSwitch label={'action'} checked={row.status === true ? true : false} /> */}
							<span
								className={`${row.status === true
									? 'text-met-success border-b-[1px] border-b-met-success'
									: 'text-met-red border-b-[1px] border-b-met-red'
									}`}
							>
								{row.status === true ? 'Active' : 'Inactive'}
							</span>
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
	];
	useEffect(() => {
		dispatch(getPatientsByLocationId({ locationId: editLocationData?.uuid }))
	}, [])
	return (
		<div className=' h-[75vh] met-scrollbar overflow-auto'>
			<Table rows={patientsByLocationId} coloumns={columns} />
		</div>
	);
};
export default Patients;

import React from 'react';

const CustomEvent = ({ event, onEventClick }) => {
	const timeOptions = {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	};
	const timeFormatter = new Intl.DateTimeFormat('en-US', timeOptions);

	const handleClick = () => {
		onEventClick(event);
	};

	return (
		<div onClick={handleClick} className="cursor-pointer">
			<div className="flex">
				<div style={{ width: '-webkit-fill-available' }} className="flex text-xs justify-between items-center">
					<p className="font-bold">{event.title}</p>
					<p>
						{timeFormatter.format(new Date(event.start)) +
							' - ' +
							timeFormatter.format(new Date(event.end))}
					</p>
				</div>
			</div>
		</div>
	);
};

export default CustomEvent;

import React, { useEffect } from "react";
import { Chart } from "chart.js/auto";

const DashBoardPieChart = ({ xValues = [], yValues = [], barColors = [], totalcount = 0 }) => {
    useEffect(() => {
        const total = totalcount;
        const percentages = yValues.map((value) => (value / total) * 100);
        const ctx = document.getElementById("myChart").getContext("2d");
        const existingChart = Chart.getChart(ctx);
        if (existingChart) {
            existingChart.destroy();
        }

        const newChart = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: xValues,
                datasets: [
                    {
                        backgroundColor: barColors,
                        data: percentages,
                    },
                ],
            },
            options: {
                cutout: "75%",
                animation: {
                    animateScale: true,
                },
                plugins: {
                    title: {
                        display: true,
                    },
                    legend: {
                        display: false,
                        position: "right",
                    },
                },
            },
        });
        return () => {
            newChart.destroy();
        };
    }, [yValues, totalcount, barColors]);

    return (
        <div className="item-center flex-wrap flex justify-center">
            <div className="flex-1">
                <canvas id="myChart" width="180" height="180"></canvas>
            </div>

            <div className="item-center justify-center flex-1 flex flex-col">
                {xValues?.map((lable, index) => {
                    return (
                        <div key={index} className="text-gray-500 flex items-center gap-2 my-2">
                            <div className="inline-block w-4 h-4 rounded-full" style={{ background: barColors[index] }}></div>
                            {lable} <div className="text-gray-500" style={{ color: "#1A1A1ACC", marginLeft: "10px" }}>{yValues[index]}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DashBoardPieChart;

import React, { useState } from 'react'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Icons from '../../../../components/icons/Icons'
import SelectDropdown from '../../../../components/common-components/selectDropdown'
import Label from '../../../../components/common-components/label/Label'
import DatePicker from '../../../../components/common-components/datePicker'
import NavigationCapsuleTabs from '../../../../components/common-components/roundedtoggles/NavigationCapsuleTabs'
import Checkbox from '../../../../components/common-components/checkbox/Checkbox'
import Input from '../../../../components/common-components/input/Input'
import { Form, Formik } from 'formik'
import { LAB_ORDER_FORM_FIELDS_NAMES } from './Constants'
import { getValidationSchema } from '../../../../libs/formsUtils'


const fields = [{ fieldName: LAB_ORDER_FORM_FIELDS_NAMES.LAB_TYPE, isRequired: true },
{ fieldName: LAB_ORDER_FORM_FIELDS_NAMES.INSURANCE, isRequired: true },
{ fieldName: LAB_ORDER_FORM_FIELDS_NAMES.LAB_CENTER, isRequired: true },
{ fieldName: LAB_ORDER_FORM_FIELDS_NAMES.TESTS },
{ fieldName: LAB_ORDER_FORM_FIELDS_NAMES.ICD_10_CODE, isRequired: true },
{ fieldName: LAB_ORDER_FORM_FIELDS_NAMES.PATIENT_INSTRUCTIONS },
{ fieldName: LAB_ORDER_FORM_FIELDS_NAMES.LAB_INSTRUCTIONS_TO_TEST_CENTER },
{ fieldName: LAB_ORDER_FORM_FIELDS_NAMES.COLLECTION_DATE_TIME },
{ fieldName: LAB_ORDER_FORM_FIELDS_NAMES.PRIMARY_PROVIDER, isRequired: true },
{ fieldName: LAB_ORDER_FORM_FIELDS_NAMES.ORDERING_PROVIDER },
{ fieldName: LAB_ORDER_FORM_FIELDS_NAMES.CHART_NOTE },
{ fieldName: LAB_ORDER_FORM_FIELDS_NAMES.BILL_TYPE }]


const validationSchema = getValidationSchema(fields)

const initialValues = {
    [LAB_ORDER_FORM_FIELDS_NAMES.LAB_TYPE]: '',
    [LAB_ORDER_FORM_FIELDS_NAMES.INSURANCE]: '',
    [LAB_ORDER_FORM_FIELDS_NAMES.LAB_CENTER]: '',
    [LAB_ORDER_FORM_FIELDS_NAMES.TESTS]: '',
    [LAB_ORDER_FORM_FIELDS_NAMES.ICD_10_CODE]: '',
    [LAB_ORDER_FORM_FIELDS_NAMES.PATIENT_INSTRUCTIONS]: '',
    [LAB_ORDER_FORM_FIELDS_NAMES.LAB_INSTRUCTIONS_TO_TEST_CENTER]: '',
    [LAB_ORDER_FORM_FIELDS_NAMES.COLLECTION_DATE_TIME]: '',
    [LAB_ORDER_FORM_FIELDS_NAMES.CHOOSE_TIME]: '',
    [LAB_ORDER_FORM_FIELDS_NAMES.PRIMARY_PROVIDER]: '',
    [LAB_ORDER_FORM_FIELDS_NAMES.ORDERING_PROVIDER]: '',
    [LAB_ORDER_FORM_FIELDS_NAMES.CHART_NOTE]: '',
    [LAB_ORDER_FORM_FIELDS_NAMES.BILL_TYPE]: ''
}


function LabOrderModal({ open, close, onClickCb }) {
    const [isChecked, setIsChecked] = useState(false)

    const [showInsuranceDropdown, setShowInsuranceDropdown] = useState(false);

    const capsuleTabs = [{ label: 'Patient' }, { label: 'Provider' }, { label: 'Insurance' }];

    return (

        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={() => { }}
        >
            {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                return <Form onSubmit={handleSubmit} >

                    <ModalComponent
                        open={open}
                        title={'Lab Requisition Form'}
                        footerButton={<div className='flex column-gap-10px'>
                            <div> <Button variant={BUTTON_VARIANTS.CONTAINED_GRAY} customBtnClass="h-[42px]" type='submit'>Save as Order set</Button>
                            </div>
                            <div> <Button variant={BUTTON_VARIANTS.OUTLINED} type='submit' >Save & Draft</Button></div>

                            <div>
                                <Button variant={BUTTON_VARIANTS.CONTAINED} onClickCb={onClickCb} type='submit'>Save & Print</Button></div>
                        </div>}
                        icon={<Icons iconName="closeIcon" />}
                        close={close}
                        customClasses="w-[869px]">
                        <div className='p-2'>
                            <div className='flex pt-6'>
                                <div className='mr-2'>
                                    <Checkbox onChangeCb={() => setIsChecked(prev => !prev)} checked={isChecked} />
                                </div>
                                <div>
                                    <Label fontWeight='bold'>STAT</Label>
                                </div>
                            </div>
                            <div className='flex pt-6 column-gap-10px'>
                                <div className='flex-1' >
                                    <SelectDropdown
                                        placeholder='Search And Select'
                                        label='Lab Type'
                                        name={LAB_ORDER_FORM_FIELDS_NAMES.LAB_TYPE}
                                        value={values[LAB_ORDER_FORM_FIELDS_NAMES.LAB_TYPE]}
                                        onChangeCb={(val) => { setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.LAB_TYPE, val.value) }}
                                        options={[{ label: "Quest", value: "Quest" }]}
                                    />
                                </div>
                                <div className='flex-1'>
                                    <SelectDropdown
                                        placeholder='Search And Select'
                                        label='Lab Center'
                                        name={LAB_ORDER_FORM_FIELDS_NAMES.LAB_CENTER}
                                        value={values[LAB_ORDER_FORM_FIELDS_NAMES.LAB_CENTER]}
                                        onChangeCb={(val) => { setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.LAB_CENTER, val.value) }}
                                        options={[{ label: "Randox Laboratories", value: "Randox Laboratories" }]}
                                    />
                                </div>
                            </div>
                            <div className='flex pt-6 column-gap-10px'>
                                <div className='flex-1'>
                                    <div className='max-width-max-content'>
                                        <div className='pt-1 '>
                                            <NavigationCapsuleTabs
                                                label='Bill Type'
                                                tabs={capsuleTabs}
                                                name={LAB_ORDER_FORM_FIELDS_NAMES.BILL_TYPE}
                                                value={values[LAB_ORDER_FORM_FIELDS_NAMES.BILL_TYPE]}
                                                onChangeCb={(label) => {
                                                    setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.BILL_TYPE, label)
                                                    if (label === "Insurance") {
                                                        setShowInsuranceDropdown(true)
                                                    } else {
                                                        setShowInsuranceDropdown(false)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    {showInsuranceDropdown && (
                                        <SelectDropdown
                                            placeholder='Select'
                                            label='Insurance'
                                            name={LAB_ORDER_FORM_FIELDS_NAMES.INSURANCE}
                                            value={values[LAB_ORDER_FORM_FIELDS_NAMES.INSURANCE]}
                                            onChangeCb={(val) => { setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.INSURANCE, val.value) }}
                                            options={[{ label: "Randox Laboratories", value: "Randox Laboratories" }]}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className='pt-6'>
                                <SelectDropdown
                                    toggleIcon={<Icons iconName={"searchIcon"}></Icons>}
                                    placeholder='Search and Select Test'
                                    label='Tests'
                                    name={LAB_ORDER_FORM_FIELDS_NAMES.TESTS}
                                    value={values[LAB_ORDER_FORM_FIELDS_NAMES.TESTS]}
                                    onChangeCb={(val) => { setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.TESTS, val.value) }}
                                    options={[{ label: "Randox Laboratories", value: "Randox Laboratories" }]}
                                />
                            </div>
                            <div className='pt-4'>
                                <div className='relative group'>
                                    <div className='flex items-center p-2 hover:border rounded border-transparent hover:border-met-gray-border hover:bg-met-light-yellow'>
                                        <div >
                                            <Icons iconName={"arrowRightIcon"} color="red"></Icons>
                                        </div>
                                        <Label className='flex-grow'>Methadone Blood with Confirmation (CLH 2437) (METHSP)</Label>
                                        <div className='hidden absolute group-hover:block right-0 p-2 '>
                                            <Icons iconName={"closeIcon"} color="red"></Icons>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-6'>
                                <SelectDropdown
                                    toggleIcon={<Icons iconName={"searchIcon"}></Icons>}
                                    placeholder='Search and Select Test'
                                    label='ICD 10 Code'
                                    name={LAB_ORDER_FORM_FIELDS_NAMES.ICD_10_CODE}
                                    value={values[LAB_ORDER_FORM_FIELDS_NAMES.ICD_10_CODE]}
                                    onChangeCb={(val) => { setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.ICD_10_CODE, val.value) }}
                                    options={[{ label: "Randox Laboratories", value: "Randox Laboratories" }]} />
                            </div>
                            <div className='pt-4'>
                                <div className='relative group'>
                                    <div className='flex items-center p-2 hover:border rounded border-transparent hover:border-met-gray-border hover:bg-met-light-yellow'>
                                        <div >
                                            <Icons iconName={"arrowRightIcon"} color="red"></Icons>
                                        </div>
                                        <Label className='flex-grow'>F32.0 - Bipolar disord, crnt epsd depress, mild or mod severt, unsp (296.50)</Label>
                                        <div className='hidden absolute group-hover:block right-0 p-2 '>
                                            <Icons iconName={"closeIcon"} color="red"></Icons>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-6'>
                                <SelectDropdown
                                    placeholder='Fasting or non-fasting does not matter (Random)'
                                    label='Patient Instructions'
                                    name={LAB_ORDER_FORM_FIELDS_NAMES.PATIENT_INSTRUCTIONS}
                                    value={values[LAB_ORDER_FORM_FIELDS_NAMES.PATIENT_INSTRUCTIONS]}
                                    onChangeCb={(val) => { setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.PATIENT_INSTRUCTIONS, val.value) }}
                                    options={[{ label: "Randox Laboratories", value: "Randox Laboratories" }]} />
                            </div>
                            <div className='pt-6'>
                                <Input
                                    label='Lab Instructions To Test Center'
                                    placeholder={"Lab Instructions To Test Center"}
                                    name={LAB_ORDER_FORM_FIELDS_NAMES.LAB_INSTRUCTIONS_TO_TEST_CENTER}
                                    value={values[LAB_ORDER_FORM_FIELDS_NAMES.LAB_INSTRUCTIONS_TO_TEST_CENTER]}
                                    onChangeCb={(e) => { setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.LAB_INSTRUCTIONS_TO_TEST_CENTER, e.target.value) }}
                                    onBlurCb={handleBlur}

                                />
                            </div>
                            <div className='flex column-gap-10px pt-2'>
                                <div>
                                    <DatePicker
                                        label='Collection Date & Time'
                                        placeholder='Date'
                                        name={LAB_ORDER_FORM_FIELDS_NAMES.COLLECTION_DATE_TIME}
                                        isRequired={true}
                                        value={values[LAB_ORDER_FORM_FIELDS_NAMES.COLLECTION_DATE_TIME]}
                                        onChangeCb={(date) => {
                                            setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.COLLECTION_DATE_TIME, date)
                                        }}
                                    />
                                </div>
                                <div className='pt-6'>
                                    <SelectDropdown
                                        toggleIcon={<Icons iconName="scheduleIcon"></Icons>}
                                        placeholder='Choose Time'
                                        name={LAB_ORDER_FORM_FIELDS_NAMES.CHOOSE_TIME}
                                        value={values[LAB_ORDER_FORM_FIELDS_NAMES.CHOOSE_TIME]}
                                        onChangeCb={(val) => { setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.CHOOSE_TIME, val.value) }}
                                        options={[{ label: "12", value: "11" }]}

                                    />
                                </div>
                            </div>
                            <div className='flex pt-6 column-gap-10px'>
                                <div className='flex-1' >
                                    <SelectDropdown
                                        placeholder='Select Provider'
                                        label='Primary Provider'
                                        name={LAB_ORDER_FORM_FIELDS_NAMES.PRIMARY_PROVIDER}
                                        value={values[LAB_ORDER_FORM_FIELDS_NAMES.PRIMARY_PROVIDER]}
                                        onChangeCb={(val) => { setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.PRIMARY_PROVIDER, val.value) }}
                                        options={[{ label: "12", value: "11" }]}
                                    />
                                </div>
                                <div className='flex-1'>
                                    <SelectDropdown
                                        placeholder='Select Provider'
                                        label='Ordering Provider'
                                        name={LAB_ORDER_FORM_FIELDS_NAMES.ORDERING_PROVIDER}
                                        value={values[LAB_ORDER_FORM_FIELDS_NAMES.ORDERING_PROVIDER]}
                                        onChangeCb={(val) => { setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.ORDERING_PROVIDER, val.value) }}
                                        options={[{ label: "12", value: "11" }]}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='pt-6 width-50 ' >
                                    <SelectDropdown
                                        placeholder='Select a Chart Note'
                                        label='Chart Note'
                                        name={LAB_ORDER_FORM_FIELDS_NAMES.CHART_NOTE}
                                        value={values[LAB_ORDER_FORM_FIELDS_NAMES.CHART_NOTE]}
                                        onChangeCb={(val) => { setFieldValue(LAB_ORDER_FORM_FIELDS_NAMES.CHART_NOTE, val.value) }}
                                        options={[{ label: "12", value: "11" }]}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalComponent >
                </Form>
            }}
        </Formik>

    )
}

export default LabOrderModal
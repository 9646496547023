import React, { useEffect, useState } from 'react';

import {
	componentKey,
	setClearUserList,
	setGuestEmailState,
	setPatientListPaginationStates,
	setSelectedUser,
	setSendInviteType,
} from '../../ZoomIntegrationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientList, getProviderList, postSendInviteToGuest } from '../../ZoomIntegrationSaga';
import { toast } from 'react-toastify';

import Button from '../../components/button/Button';
import Heading from '../../components/heading/Heading';
import RadioInput from '../../components/radioinput/RadioInput';
import Popup from '../../components/popup/Popup';
import { BUTTON_TYPE } from '../../constants';
import TextInput from '../../components/input/textinput/TextInput';
import SelectDropdown from '../../../selectDropdown';

const AddGuest = ({ isOpen = false, title = 'Are you sure', toggle = () => {}, appointmentDetails }) => {
	const dispatch = useDispatch();
	const token = localStorage.getItem('token');
	const providerUser = localStorage.getItem('providerUser');

	const [linkCopied, setLinkCopied] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const {
		sendInviteType,
		patientListPaginationStates,
		patientListData,
		scheduleAppointmentDetails,
		selectedUser,
		guestEmailState,
	} = useSelector((state) => state[componentKey]);

	const handleRadioChange = (event) => {
		dispatch(setSendInviteType(event.target.name));
		dispatch(setClearUserList());
		dispatch(setPatientListPaginationStates({ PageNumber: 1 }));
		dispatch(setSelectedUser({ label: '', value: '' }));
		dispatch(setGuestEmailState({ guestEmail: { value: '', errors: {} } }));
	};

	const handleSendInvite = () => {

        if(sendInviteType == 'Guest' && guestEmailState.guestEmail.value.length == 0){
			toast.error('Please select user or enter email');
            return 
        }

		if (Object.keys(selectedUser).length !== 0 || guestEmailState.guestEmail.value.length !== 0) {
			dispatch(
				postSendInviteToGuest({
					body: {
						email: sendInviteType == 'Guest' ? guestEmailState.guestEmail.value : selectedUser.email,
						id: appointmentDetails.uuid,
						type: sendInviteType,
					},
					token,
				})
			);
		} else {
			toast.error('Please select user or enter email');
		}
	};

	const onChangeHandler = (event, rules) => {
		const { name, value } = event.target;
		dispatch(setGuestEmailState({ [name]: { value } }));
	};

	const handleCopyInviteLink = async () => {
		try {
			let link = `${process.env.REACT_APP_FE_URL}/video-encounter/${appointmentDetails.uuid}/Guest/${
				JSON.parse(providerUser).customAttributes.providerGroupTenent
			}`;
			await navigator.clipboard.writeText(link);
			setLinkCopied(!linkCopied);
		} catch (err) {
			setLinkCopied(false);
		}
	};

	return (
		<Popup
			title={title}
			onCloseCb={() => {
				toggle();
				dispatch(setSelectedUser({ label: '', value: '' }));
			}}
			isPopupOpen={isOpen}
			customPopupStyles={{ minWidth: '40%' }}
		>
			<div style={{ minHeight: '300px' }}>
				<Heading customStyles={{ fontWeight: '600', color: '#1A1A1A', fontSize: '15px', marginBottom: '10px' }}>
					Please select type
				</Heading>
				<div>
					<RadioInput
						label={{ suffixLabel: 'Patient' }}
						name="Patient"
						checked={sendInviteType == 'Patient'}
						onChangeCb={handleRadioChange}
						customStyle={{ fontWeight: '600', color: '#1A1A1A' }}
					/>
					<RadioInput
						label={{ suffixLabel: 'Provider' }}
						name="Provider"
						checked={sendInviteType == 'Provider'}
						onChangeCb={handleRadioChange}
						customStyle={{ fontWeight: '600', color: '#1A1A1A' }}
					/>
					<RadioInput
						label={{ suffixLabel: 'Guest' }}
						name="Guest"
						checked={sendInviteType == 'Guest'}
						onChangeCb={handleRadioChange}
						customStyle={{ fontWeight: '600', color: '#1A1A1A' }}
					/>
				</div>
				<div className="mt-4">
					{sendInviteType == 'Guest' ? (
						<TextInput
							label="Enter Email"
							name="guestEmail"
							placeHolder="Please enter guest email"
							value={guestEmailState.guestEmail.value}
							errors={guestEmailState.guestEmail.errors}
							rules={guestEmailState.guestEmail.rules}
							onChangeCb={onChangeHandler}
						/>
					) : null}
					{sendInviteType == 'Provider' ? (
						<SelectDropdown
							value={selectedUser}
							onChangeCb={(e) => {
                                dispatch(setSelectedUser(e));
							}}
							// toggleIcon={<Icons iconName={'searchIcon'}></Icons>}
							placeholder="Search provider"
							label="Provider Name"
							isAsync={true}
							url={'providers'}
							extractKeyFromResponse={''}
							labelKey={'firstName'}
							valueKey={'uuid'}
							labelKey2={'lastName'}
						/>
					) : null}
					{sendInviteType == 'Patient' ? (
						<SelectDropdown
							value={selectedUser}
							onChangeCb={(e) => {
                                dispatch(setSelectedUser(e));
							}}
							// toggleIcon={<Icons iconName={'searchIcon'}></Icons>}
							placeholder="Search Patient"
							label="Patient Name"
							isAsync={true}
							url={'patients'}
							extractKeyFromResponse={'patients'}
							labelKey={'firstName'}
							valueKey={'uuid'}
							labelKey2={'lastName'}
						/>
					) : null}
				</div>
				<div
					style={{
						marginTop: "100px",
						display: 'flex',
						marginBottom: '10px',
						justifyContent: 'space-between',
					}}
				>
					<Button
						className="button-text-name"
						onClickCb={handleCopyInviteLink}
						variant={BUTTON_TYPE.LIGHT_WITH_NO_BORDER}
					>
						{linkCopied ? 'Copied to clipboard.' : 'Copy Invite Link'}
					</Button>
					<Button styles={{ width: '120px' }} onClickCb={handleSendInvite}>
						Send Invite
					</Button>
				</div>
			</div>
		</Popup>
	);
};

export default AddGuest;

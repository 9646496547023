import store from '../../../../store/store';

export const componentKey = 'PATIENTNOTES_SECTION';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setNotesId: (state, action) => {
            state.currentNotesId = action.payload;
        },
        setAllNotesData: (state, action) => {
            state.allNotesData = action.payload;
        },
        setNotesData: (state, action) => {
            state.notesData = action.payload;
        },
        setIsNotesModalOpen: (state, action) => {
            state.isNotesModalOpen = action.payload;
        },
        setIsNewNotesAdd: (state, action) => {
            state.isNewNotesAdd = action.payload;
        },
        setIsNotesHistoryModalOpen: (state, action) => {
            state.isNotesHistoryModalOpen = action.payload;
        },
        setNotesHistoryData: (state, action) => {
            state.notesHistoryData = action.payload;
        },
        setIsOpenDeleteStickyNotePopup: (state, action) => {
            state.isOpenDeleteStickyNotePopup = action.payload
        }

    },
    initialReducerState: {
        currentNotesId: '',
        allNotesData: [],
        notesData: {},
        isNotesModalOpen: false,
        isNewNotesAdd: false,
        isNotesHistoryModalOpen: false,
        notesHistoryData: [],
        isOpenDeleteStickyNotePopup: false
    },
});

export const { setNotesId, setAllNotesData, setNotesData, setIsNotesModalOpen, setIsNewNotesAdd, setIsNotesHistoryModalOpen, setNotesHistoryData, setIsOpenDeleteStickyNotePopup } =
    actions;

import React from 'react'

const BellIcon = () => {
    return (
        <svg id="notifications_black_24dp_1_" data-name="notifications_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_85152" data-name="Group 85152" transform="translate(-40)">
                <path id="Path_204226" data-name="Path 204226" d="M0,0H24V24H0Z" transform="translate(40)" fill="none" />
                <path id="Path_204227" data-name="Path 204227" d="M12,22a2.006,2.006,0,0,0,2-2H10A2.006,2.006,0,0,0,12,22Zm6-6V11c0-3.07-1.63-5.64-4.5-6.32V4a1.5,1.5,0,0,0-3,0v.68C7.64,5.36,6,7.92,6,11v5L4,18v1H20V18Zm-2,1H8V11c0-2.48,1.51-4.5,4-4.5s4,2.02,4,4.5Z" transform="translate(40)" fill="#fff" />
            </g>
        </svg>
    )
}

export default BellIcon
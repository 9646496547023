import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from '../containers/Navbar';
import { MAIN_ROUTES } from '../routes/Routes';
import DataService from '../services/utils/dataservice/DataService';
import { componentKey as routerComponentKey, setLoggedInProviderUser, setLoggedInUser } from '../routes/RoutesSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Loader from '../components/common-components/loader/Index';
import { componentKey, setLoadingState } from './MainSlice';
import Dataloader from '../containers/Wrappers/Dataloader/Dataloader';
import { socket } from '../socket/socket';
import General from '../libs/utility/General';

const Main = () => {
	const dispatch = useDispatch()
	const { loggedInUser } = useSelector(state => state[routerComponentKey])
	const { loadingState } = useSelector(state => state[componentKey])
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		if (loggedInUser?.uuid) {
			socket.connect();
			socket.emit("addProviders", { uuid: loggedInUser?.uuid })
		}

		return () => {
			socket.disconnect();
		};
	}, [loggedInUser]);

	useEffect(() => {
		// axios request interceptor
		axios.interceptors.request.use(function (response) {
			if (General.isWhitelisted(response.url)) {
				return response;
			}

			dispatch(setLoadingState(true))
			return response;
		}, function (error) {
			dispatch(setLoadingState(false));
			return Promise.reject(error);
		});

		// axios response interceptor
		axios.interceptors.response.use(function (response) {
			dispatch(setLoadingState(false))
			return response;
		}, function (error) {
			dispatch(setLoadingState(false));
			if (error.response && error.response.status === 401) {
				localStorage.removeItem('token')
				localStorage.removeItem('user')
				dispatch(setLoggedInUser({}))
				dispatch(setLoggedInProviderUser({}))
				navigate("/login")
			}
			return Promise.reject(error);
		});
	}, [dispatch, navigate])

	useEffect(() => {
		const isAuthenticated = DataService.getToken()
		setIsAuthenticated(isAuthenticated)

		if (!loggedInUser?.userId) {
			const user = localStorage.getItem("user")
			const providerUser = localStorage.getItem("providerUser")

			dispatch(setLoggedInUser(JSON.parse(user)))
			dispatch(setLoggedInProviderUser(JSON.parse(providerUser)))
		}
	}, [dispatch, loggedInUser?.userId])



	if (!isAuthenticated) {
		return <div className='text-center'>loading</div>
	}

	return (
		<Dataloader>
			<Navbar />
			{loadingState ? <Loader /> : null}
			<div className="bg-met-primary-bg flex-1">
				<Routes>
					{MAIN_ROUTES.map((route, index) => (
						<Route key={'main-route-' + index} path={route.path} element={route.component} />
					))}
				</Routes>
			</div>
		</Dataloader>
	);
};

export default Main;

import React from 'react'
import Button from '../button/Button'
import { BUTTON_VARIANTS } from '../button/Constants'
import useGetCurrenPathHook from '../../../libs/customHooks/useGetCurrenPathHook'

const NavigationBorderedTabs = ({ tabs, customClasses = "" }) => {
    const currentPath = useGetCurrenPathHook()

    return (
        <div className={`flex px-4 py-2 focus:outline-none items-start column-gap-20px min-h-[50px] ${customClasses}`}>
            {tabs.map((tab, index) => {
                return <div key={"navigation-bordered-tab" + index}>
                    <Button
                        onClickCb={() => { tab?.onClickCb ? tab?.onClickCb() : null }}
                        variant={BUTTON_VARIANTS.TEXT}
                        customBtnClass={`font-semibold p-0 min-w-1/12 flex justify-center ${currentPath === tab.path ? "font-bold text-met-primary " : "text-gray-500"}`}
                    >
                        {tab.label}
                    </Button>
                    {currentPath === tab.path && (
                        <div className="bottom-0 w-full h-[3px] rounded-t-md bg-met-primary"></div>
                    )}
                </div>
            })}
        </div>
    )
}

export default NavigationBorderedTabs
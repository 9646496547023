
import { all, put, takeLatest } from 'redux-saga/effects'
import store from '../../store/store'
import { componentKey, setPatientPayments, setPatientReportsState, setReportsPaginationState, setSuperBillData } from './ReportsSlice'
import PatientDataService from '../../services/PatientDataServices'
import BillingDataService from '../../services/BillingDataService'
import PaymentDataService from '../../services/PaymentDataService'

export const { getPatientReports,getAllSuperBill,getPatientsPaymentDetails } = {
    getPatientReports: (payload) => {
        return {
            type: 'REPORTS/PATIENT_REPORTS',
            payload
        }
    },
    getAllSuperBill : (payload) => {
        return {
            type: 'REPORTS/GET_ALL_SUPER_BILL',
            payload
        }
    },
    getPatientsPaymentDetails: (payload) => {
        return {
          type: 'REPORTS/GET_PAYMENT_DETAILS',
          payload
        }
      },
}

function* getPatientReportsAsync(action) {
    try {
        const { paginationState } = action.payload
        const params = {
            status: paginationState.status,
            dateFrom: paginationState.dateFrom,
            dateTo: paginationState.dateTo,
        }
        const response = yield PatientDataService.getPatientGridList({ params })
        const { status, data } = response.data
        if (status) {
            const { patients } = data || {}
            yield put(setPatientReportsState(patients))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}
function* getAllSuperBillAsync(action) {
    try {
        const response = yield BillingDataService.getAllSuperBill(action.payload)
        const { data, status, message } = response.data
       
        if (status) {
            yield put(setSuperBillData(data?.superBills))
        }


    } catch (error) {
        console.log('err: ', error)
    }
}

function* getPatientsPaymentDetailsAsync(action) {
    try {
      const params = action.payload
      const response = yield PaymentDataService.getPatientsPaymentDetails({ params })
      const { data, status } = response.data
  
      if (status) {
        const { payments } = data || {}
        yield put(setPatientPayments(payments))
      }
    } catch (error) {
      console.log('err: ', error)
    }
  }
function* rootSaga() {
    yield all([
        takeLatest(getPatientReports().type, getPatientReportsAsync),
        takeLatest(getAllSuperBill().type, getAllSuperBillAsync),
        takeLatest(getPatientsPaymentDetails().type, getPatientsPaymentDetailsAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
export const VACCINE_FORM_FIELDS_NAMES = {
    VACCINE_TYPE: 'type',
    ADMINISTERED: 'administered',
    HISTORICAL: 'historical',
}

export const ADMINISTERED_FORM_FIELDS_NAMES = {
    VACCINE_TYPE: 'type',
    VACCINE_NAME: 'name',
    ADMINISTERED_DATE: 'administerDate',
    ADMINISTERED_TIME: 'administerTime',
    OREDERD_BY: 'orderedBy',
    ADMINISTERED_BY: 'administeredBy',
    MANUFACTURER: 'manufacturer',
    NDC_CODE: 'ndcCode',
    LOT: 'lot',
    DOSE: 'dose',
    UNITS: 'units',
    ROUTE: 'route',
    BODY_SITE: 'site',
    EXPIRY_DATE: 'expiryDate',
    AGE: 'age',
    REACTION: 'reaction',
    REASON: 'reason',
    NOTE: 'note',
}

export const HISTORICAL_FORM_FIELDS_NAMES = {
    VACCINE_TYPE: 'type',
    VACCINE_NAME: 'name',
    ADMINISTERED_DATE: 'administerDate',
    ADMINISTERED_TIME: 'administerTime',
    SOURCE_FOR_INFORMATION: 'sourceOfInfo',
    OREDERD_BY: 'orderedBy',
    ADMINISTERED_BY: 'administeredBy',
    MANUFACTURER: 'manufacturer',
    NDC_CODE: 'ndcCode',
    LOT: 'lot',
    DOSE: 'dose',
    UNITS: 'units',
    ROUTE: 'route',
    BODY_SITE: 'site',
    EXPIRY_DATE: 'expiryDate',
    AGE: 'age',
    REACTION: 'reaction',
    REASON: 'reason',
    NOTE: 'note',
}
export class EncodeDecodeUtils {

    static encode = (value) => {
        return window.btoa(value)
    }
    
    static decode = (value) => {
        return window.atob(value)
    }

    static encodeURIString = (str = "") => {
        if (!str && typeof str !== 'string') {
            return null
        }
        let encodedStr = encodeURIComponent(str)

        return encodedStr
    }

    static decodeURIString = (str = "") => {
        if (!str && typeof str !== 'string') {
            return null
        }
        let decodedStr = decodeURIComponent(str)

        return decodedStr
    }

}
export default EncodeDecodeUtils

import React from 'react'
import Checkbox from '../../../components/common-components/checkbox/Checkbox'
import General from '../../../libs/utility/General'

const TableBody = ({ coloumns, rows, selectProps, setSelectedRows, selectedRows, getVarient }) => {
	const onToggleCb = (object) => {
		const updatedArray = General.toggleObjectInArray(selectedRows, object, selectProps.selectIdentifier)
		setSelectedRows(updatedArray)
	}

	return <tbody>
		{rows.map((row, index) => {
			return <tr className="bg-white border-b border-met-dark-grey" key={'table-row' + index}>
				{selectProps.isSelectable ? (
					<td className={`${getVarient()}`}>
						<Checkbox checked={selectedRows.some(selectedRw => selectedRw[selectProps.selectIdentifier] === row[selectProps.selectIdentifier])} onChangeCb={() => onToggleCb(row)} />
					</td>) : null}
				{coloumns.map((col, index) => {
					return <td key={'table-row' + index} className={`max-w-[300px] ${getVarient()} ${col?.color || ''}`}>{col.renderLogic(row)}</td>
				})}
			</tr>
		})}
	</tbody>
}

export default TableBody;

// CardComponent.js

import React from 'react';
import Icons from '../../../assets/svgs';
import { useNavigate } from 'react-router-dom';
import ShowForAllowedPermissionsOnly from '../../../containers/Wrappers/ShowForAllowedPermissionsOnly';
// bg-white rounded-xl overflow-hidden shadow-md  border-opacity-10 rounded-5 opacity-100
const CardComponent = ({ cardName = '', cardLogoName = '', cardoptions = [], extraClass = '' }) => {
    const navigate = useNavigate();
    const role = localStorage.getItem('role')

    return (
        <div
            style={{ backgroundPosition: '0% 0%', backgroundClip: 'padding-box' }}
            className={`w-[358px] min-w-[250px] h-[553px] min-h-[350px] border border-solid border-gray-500 bg-white bg-no-repeat rounded-lg opacity-100 border-opacity-10 ${extraClass}`}
        >
            <div className="p-4 flex items-center justify-start mt-[6px]">
                <Icons iconName={cardLogoName} />
                <div className="flex items-center space-x-2 pl-[16px] self-start">
                    <span style={{ color: '#1B5984' }} className="text-[22px]">
                        {cardName}
                    </span>
                </div>
            </div>
            {cardoptions?.map((item) => (
                <React.Fragment key={item?.name}>
                    <ShowForAllowedPermissionsOnly name={item.accessName}>
                        {!(role === 'Biller' || role === 'Front Desk') ||
                            !(item?.id === '/notification' || item?.id === '/print-configuration') ? (
                            <div
                                className="hover:bg-gray-200 hover:cursor-pointer px-[16px] py-[8px] flex flex-col sm:flex-row items-center justify-between"
                                onClick={() => {
                                    navigate(item?.redirectTo);
                                }}
                            >
                                <div className="mb-2 sm:mb-0 sm:mr-2">
                                    <span style={{ color: '#1A1A1A99' }} className="text-lg">
                                        {item?.name}
                                    </span>
                                </div>
                                <div className="flex items-center space-x-2" style={{ color: '#1A1A1A99' }}>
                                    <Icons iconName="rightArrowIcon" />
                                    {/* <img src="arrow-logo.png" alt="Arrow Logo" className="h-4 w-4 object-cover" /> */}
                                </div>
                            </div>
                        ) : null}
                    </ShowForAllowedPermissionsOnly>
                </React.Fragment>
            ))}
        </div>
    );
};

export default CardComponent;

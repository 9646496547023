export const FORM_FIELDS_NAMES = {
    MOBILE_NUMBER: "mobileNumber",
    HOME_NUMBER: "homeNumber",
    EMAIL: "email",
    FAX_NUMBER: "faxNumber",
    ADDRESS_LINE_1: "addressLine1",
    ADDRESS_LINE_2: "addressLine2",
    CITY: "city",
    STATE: "state",
    COUNTRY: "country",
    ZIPCODE: "zip",
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import 'react-big-calendar/lib/css/react-big-calendar.css';


const globalStyles = {
	fontFamily: 'Roboto',
	height: "100vh",
	width: "100vw",
	display:'flex',
	flexDirection: 'column',
	backgroundColor: '#F1F1F1'
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<div style={globalStyles}>
				<App />
			</div>
		</BrowserRouter>
	</Provider>
);

import AppDataService from './AppDataService'

export default class CommmonDataService {

    static async getStateList() {
        return await AppDataService.get(`states`)
    }

    static async saveEmergencyContact(data) {
        return await AppDataService.post(`emergency-contacts`, data)
    }

    static async getPreferences(name = "") {
        return await AppDataService.get(name)
    }

    static async saveClinicalCenter(name = "", data = {}) {
        return await AppDataService.post(name, data)
    }
    static async getOptions(endpoint = "", params = {}) {
        return await AppDataService.get(endpoint, { params })
    }
}
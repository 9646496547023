import React from 'react';
import Label from '../../../components/common-components/label/Label';
import moment from 'moment/moment';

const CustomEvent = ({ event, onEventClick }) => {
    const getStatusColor = (status) => {
        switch (status) {
            case 'Scheduled':
                return '#1B5984';
            case 'ReScheduled':
                return '#FFC107';
            case 'Cancelled':
                return '#FF7E7E';
            case 'Checked_In':
                return '#E69EFF';
            case 'In_Exam':
                return '#0961DD';
            case 'Signed_Off':
                return '#7FFF00';
            default:
                return '#757575';
        }
    };

    const handleClick = () => {
        // Pass the event status to the onEventClick handler
        onEventClick(event);
    };

    return (
        <div onClick={handleClick} className="cursor-pointer">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        minWidth: 2,
                        height: 40,
                        marginRight: 8,
                        backgroundColor: getStatusColor(event.status),
                    }}
                />
                <div>
                    <Label fontWeight="bold" style={{ color: getStatusColor(event.status) }}>
                        {event.title}
                    </Label>

                    <div className="flex justify-between">
                        <Label fontSize="small" variant="body2" color="textSecondary">
                            <p style={{ color: getStatusColor(event.status), padding: "0" }} className='text-[14px]'>{event.status}</p>
                        </Label>
                        {/* <Label fontSize="small" variant="inherit" className="flex ">
							<span className='text-[14px]'>{event.label}</span>
						</Label> */}
                        <span className="text-[12px]">{`(${moment(event.start).format('h:mm A')} - ${moment(
                            event.end
                        ).format('h:mm A')})`}</span>
                        <Label fontWeight="bold" style={{ color: getStatusColor(event.status) }}>
                            {event.providerName}
                        </Label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomEvent;

import store from '../../store/store';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { componentKey, setEncounterDataById, setEncounterFormDataById, setIsEditAppointmentModalOpen, setIsEncounterSummaryOpen, setIsSuccessPopUpModalOpen, setPatientEncounterData, setPostEncounterData, setProviderLocations } from './EncounterSlice';
import EncounterDataService from '../../services/EncounterDataService';
import SchedulingService from '../../services/SchedulingService';
import { addNotifications } from '../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../components/common-components/toaster/MetToaster';
import { setStartZoomMeeting, setZoomEncounterId } from '../../components/common-components/zoom/ZoomIntegrationSlice';
import ProvidersDataService from '../../services/ProvidersDataService';
import { getScheduleById } from '../Scheduling/SchedulingSaga';

export const {
    getPatientEncounterDetails,
    getEncounterByIdDetails,
    postEncounterDetails,
    patchAppointmentDetails,
    getProviderLocations,
    getEncounterIdByUUID
} = {
    getPatientEncounterDetails: (payload) => {
        return {
            type: 'PATIENT/ENCOUNTER',
            payload,
        };
    },
    getEncounterByIdDetails: (payload) => {
        return {
            type: 'PATIENT/ENCOUNTER_BY_ID',
            payload,
        };
    },
    getProviderLocations: (payload) => {
        return {
            type: 'PATIENT/ENCOUNTER_PROVIDER_LOCATIONS',
            payload,
        };
    },
    postEncounterDetails: (payload) => {
        return {
            type: 'POST_PATIENT/ENCOUNTER',
            payload,
        };
    },
    patchAppointmentDetails: (payload) => {
        return {
            type: 'PATCHT_PATIENT/APPOINTMENT',
            payload,
        };
    },
    getEncounterIdByUUID: (payload) => {
        return {
            type: 'PATIENT/ENCOUNTER_BY_UUID',
            payload,
        };
    },
};

function* getPatientEncounterDetailsAsync(action) {
    try {
        const { patientId = '', startDate } = action.payload;
        let params = {
            ...(startDate ? { startDate } : {}),
            status : "Signed"
        }

        const response = yield EncounterDataService.getPatientEncounter(patientId, params);

        const { message, status, data } = response?.data || {};
        if (status) {
            yield put(setPatientEncounterData(data?.encounters));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* getEncounterByIdDetailsAsync(action) {
    try {
        const { encounterId = '' } = action.payload;

        const response = yield EncounterDataService.getEncounterById(encounterId);

        const { message, status, data } = response?.data || {};
        if (response?.data?.uuid) {
            yield put(setEncounterDataById(response.data));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* getProviderLocationsAsync(action) {
    try {
        const { providerId = '' } = action.payload;

        const response = yield ProvidersDataService.getProviderLocations(providerId);

        const { message, success, data } = response?.data || {};
        if (success) {
            yield put(setProviderLocations(data?.provider_location_intermediate));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* postEncounterDetailsAsync(action) {
    try {
        const { encounterData, navigate, signAndSave = false, mode = '', encounterId } = action.payload;
        const response = yield EncounterDataService.postPatientEncounter(encounterData);
        const { status, message } = response?.data || {};
        if (status) {
            yield put(setPostEncounterData(response?.data))
            if (encounterId) {
                // yield put(getEncounterByIdDetails({ encounterId }))
                yield put(setIsEncounterSummaryOpen(true))
            }
            yield
            if (message) {
                yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            }
            if (signAndSave) {
                yield put(setIsSuccessPopUpModalOpen(true))
            }
            else if (mode === "Video_Call") {
                yield put(setStartZoomMeeting(true))
                yield put(setZoomEncounterId(response?.data?.encounter?.uuid))
            }
            else if (navigate) {
                navigate(`/encounter/${encounterData.patinetId}/${response?.data?.encounter?.uuid}`)
            }
        }
    } catch (error) {
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.WARNING }))
        console.log('err: ', error);
    }
}

function* patchAppointmentDetailsAsync(action) {
    try {
        const { encounterData, scheduleId } = action.payload;
        const response = yield SchedulingService.patchScheduledAppointment(scheduleId, encounterData);
        const { status, data } = response?.data || {};
        if (status) {
            yield put(setIsEditAppointmentModalOpen(false))
            yield put(getScheduleById({ scheduleId }))
        }
    } catch (error) {
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.WARNING }))
        console.log('err: ', error);
    }
}


function* getEncounterByUUIDAsync(action) {
    try {
        const { encounterId = '' } = action.payload;

        const response = yield EncounterDataService.getEncounterIdByUUID(encounterId);

        const { message, status, data } = response || {};
        yield put(setEncounterFormDataById(data?.encounterForms?.data));

    } catch (error) {
        console.log('err: ', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getPatientEncounterDetails().type, getPatientEncounterDetailsAsync),
        takeLatest(getEncounterByIdDetails().type, getEncounterByIdDetailsAsync),
        takeLatest(postEncounterDetails().type, postEncounterDetailsAsync),
        takeLatest(patchAppointmentDetails().type, patchAppointmentDetailsAsync),
        takeLatest(getProviderLocations().type, getProviderLocationsAsync),
        takeLatest(getEncounterIdByUUID().type, getEncounterByUUIDAsync) 
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

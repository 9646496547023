import React, { useState } from 'react'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../components/common-components/button/Button'
import Icons from '../../../../components/icons/Icons'
import Label from '../../../../components/common-components/label/Label'
import Input from '../../../../components/common-components/input/Input'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import TextArea from '../../../../components/common-components/textArea/TextArea'
import SelectDropdown from '../../../../components/common-components/selectDropdown'
import ToggleButton from '../../../../components/common-components/toggleButton/ToggleButton'
import DatePicker from '../../../../components/common-components/datePicker'
import { Form, Formik } from 'formik'
import { getValidationSchema } from '../../../../libs/formsUtils'
import { FAMILY_HISTORY_FORM_FIELDS_NAMES } from './Constants'

const fields = [
    { fieldName: FAMILY_HISTORY_FORM_FIELDS_NAMES.PROBLEM_NAME, isRequired: true },
    { fieldName: FAMILY_HISTORY_FORM_FIELDS_NAMES.RELATIVE, isRequired: true },
    { fieldName: FAMILY_HISTORY_FORM_FIELDS_NAMES.ONSET_AGE, isRequired: true },
    { fieldName: FAMILY_HISTORY_FORM_FIELDS_NAMES.DATE, isRequired: true },]

const validationSchema = getValidationSchema(fields)

const initialValues = {
    [FAMILY_HISTORY_FORM_FIELDS_NAMES.PROBLEM_NAME]: '',
    [FAMILY_HISTORY_FORM_FIELDS_NAMES.RELATIVE]: '',
    [FAMILY_HISTORY_FORM_FIELDS_NAMES.DATE]: '',
    [FAMILY_HISTORY_FORM_FIELDS_NAMES.ONSET_AGE]: '',
    [FAMILY_HISTORY_FORM_FIELDS_NAMES.NOTE]: '',
    [FAMILY_HISTORY_FORM_FIELDS_NAMES.DIED]: ''
}

function FamilyHistoryModal({ open, close }) {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={() => { }}
        >
            {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                return <Form onSubmit={handleSubmit} >

                    <ModalComponent
                        open={open}
                        title={'Add Family History'}
                        footerButton={<Button variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary" type='submit'>Save</Button>}
                        icon={<Icons iconName="closeIcon" />}
                        close={close}
                        customClasses="w-[594px]">
                        <div className='p-2'>
                            <div>
                                <div className='pt-6'>
                                    <SelectDropdown
                                        label='Problem Name'
                                        placeholder='Select Or Search Problem'
                                        name={FAMILY_HISTORY_FORM_FIELDS_NAMES.PROBLEM_NAME}
                                        value={values[FAMILY_HISTORY_FORM_FIELDS_NAMES.PROBLEM_NAME]}
                                        onChangeCb={(val) => { setFieldValue(FAMILY_HISTORY_FORM_FIELDS_NAMES.PROBLEM_NAME, val.value) }}
                                        options={[{ label: "Difficulty Breathing", value: "Difficulty Breathing" }]}
                                    />
                                </div>
                            </div>
                            <div className='flex column-gap-10px pt-6'>
                                <div className='flex-1'>
                                    <SelectDropdown
                                        label='Relative'
                                        placeholder='Select Or Search Problem'
                                        name={FAMILY_HISTORY_FORM_FIELDS_NAMES.RELATIVE}
                                        value={values[FAMILY_HISTORY_FORM_FIELDS_NAMES.RELATIVE]}
                                        onChangeCb={(val) => { setFieldValue(FAMILY_HISTORY_FORM_FIELDS_NAMES.RELATIVE, val.value) }}
                                        options={[{ label: "Father", value: "Father" }]}
                                    />
                                </div>
                                <div className='flex-1'>
                                    <div>
                                        <Label fontWeight='bold'>Onset Age</Label>
                                    </div>
                                    <div>
                                        <Input
                                            name={FAMILY_HISTORY_FORM_FIELDS_NAMES.ONSET_AGE}
                                            value={values[FAMILY_HISTORY_FORM_FIELDS_NAMES.ONSET_AGE]}
                                            onChangeCb={(e) => { setFieldValue(FAMILY_HISTORY_FORM_FIELDS_NAMES.ONSET_AGE, e.target.value) }}
                                            onBlurCb={handleBlur}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='pt-6 flex justify-between'>
                                <div>
                                    <ToggleButton
                                        label='Died'
                                        activeLabel="Yes"
                                        inactiveLabel="No"
                                        value={values[FAMILY_HISTORY_FORM_FIELDS_NAMES.DIED]}
                                        onChangeCb={(status) => {
                                            if (status === "Active") {
                                                setFieldValue(FAMILY_HISTORY_FORM_FIELDS_NAMES.DIED, true)
                                            } else {
                                                setFieldValue(FAMILY_HISTORY_FORM_FIELDS_NAMES.DIED, false)
                                            }
                                        }}
                                    />
                                </div>
                                {values[FAMILY_HISTORY_FORM_FIELDS_NAMES.DIED] === true && (
                                    <div>
                                        <DatePicker
                                            label='Date'
                                            name={FAMILY_HISTORY_FORM_FIELDS_NAMES.DATE}
                                            isRequired={true}
                                            value={values[FAMILY_HISTORY_FORM_FIELDS_NAMES.DATE]}
                                            onChangeCb={(date) => {
                                                setFieldValue(FAMILY_HISTORY_FORM_FIELDS_NAMES.DATE, date)
                                            }}
                                        />
                                    </div>
                                )}


                            </div>
                            <div >
                                <div className='min-w-[280px] max-w-[280px] pt-6'>
                                    <Label fontWeight='bold'>Note</Label>
                                </div>
                                <div className='mt-2'>
                                    <TextArea
                                        name={FAMILY_HISTORY_FORM_FIELDS_NAMES.NOTE}
                                        value={values[FAMILY_HISTORY_FORM_FIELDS_NAMES.NOTE]}
                                        onChangeCb={(e) => { setFieldValue(FAMILY_HISTORY_FORM_FIELDS_NAMES.NOTE, e.target.value) }} />
                                </div>
                            </div>
                        </div>
                    </ModalComponent>
                </Form>
            }}
        </Formik>

    )
}

export default FamilyHistoryModal
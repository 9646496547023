import React from 'react';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';

function UpdateABHAAddressPopup({ open, close, onConfirm }) {
    if (!open) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-60">
            <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md">

                <div className="flex justify-between items-center border-b pb-4">
                    <h2 className="text-lg font-semibold">Update ABHA Address</h2>
                    <button onClick={close} className="text-gray-600 hover:text-black text-xl">
                        ✕
                    </button>
                </div>

                <div className="mt-6 text-center">
                    <div className="mt-2 text-md     text-gray-600">
                        Are you sure you want to update the ABHA Address?
                    </div>
                </div>


                <div className="mt-6 flex justify-end gap-4">
                    <Button
                        variant={BUTTON_VARIANTS.CONTAINED}
                        onClickCb={close}
                        className="bg-gray-500 hover:bg-gray-600 text-white"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={BUTTON_VARIANTS.CONTAINED}
                        onClickCb={onConfirm}
                        className="bg-red-500 hover:bg-red-600 text-white"
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default UpdateABHAAddressPopup;

import Draggable from 'react-draggable';

import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setExitFullScreen, setIsFullScreen, setMeetingParticipants } from '../ZoomIntegrationSlice';
import { useCanvasDimension } from '../hooks/useCanvasDimension';
import { useActiveVideo } from '../hooks/useActiveVideo';
import { useNetworkQuality } from '../hooks/useNetworkQuality';
import { usePagination } from '../hooks/usePagination';
import { useGalleryLayout } from '../hooks/useGalleryLayout';
import { useParticipantsChange } from '../hooks/useParticipantsChange';
import { SELF_VIDEO_ID } from '../constants';

import Avatar from '../components/avatar/Avatar';
import VideoFooter from '../components/videoFooter/VideoFooter';
import Icons from '../components/icon/Icon';
import { VEC_ICON_NAME } from '../components/icon/constants';

const DraggableVideoContainer = () => {

    const { client, videoStarted, mediaStream, audioStarted, isShareScreen, isMuted, isVideoMuted, exitFullScreen } = useSelector(state => state[componentKey])

    const dispatch = useDispatch()
    const videoRef = useRef();

    const canvasDimension = useCanvasDimension(mediaStream, videoRef);

    const activeVideo = useActiveVideo(client);
    const networkQuality = useNetworkQuality(client);

    const { page, pageSize, totalPage, totalSize, setPage } = usePagination(client, canvasDimension)

    let paginationObj = { page, pageSize, totalPage, totalSize }

    const { visibleParticipants, layout: videoLayout } = useGalleryLayout(client, mediaStream, true, videoRef, canvasDimension, paginationObj);

    const currentUserIndex = visibleParticipants.findIndex(
        (user) => user.userId === client.getCurrentUserInfo()?.userId
    );

    let selfVideoLayout = null;
    if (currentUserIndex > -1) {
        const item = videoLayout[currentUserIndex];
        if (item && canvasDimension) {
            selfVideoLayout = { ...item, y: canvasDimension.height - item.y - item.height };
        }
    }

    useParticipantsChange(client, () => {
        const participants = client.getAllUser()
        dispatch(setMeetingParticipants(participants))
    }, [])

    const handleExitHalfScreen = () => {
        dispatch(setExitFullScreen(false))
        dispatch(setIsFullScreen(true))
    }

    return (
        <>
            {exitFullScreen ?
                <Draggable bounds="parent" >
                    <div className='half-screen-viewport'>
                        <div className='minimize-screen-video cursor' onClick={handleExitHalfScreen}>
                            <Icons iconName={VEC_ICON_NAME.EXIT_FULL_SCREEN} onClickCb={() => console.log("clickkked")} />
                        </div>
                        <canvas className="video-canvas" id="video-canvas" ref={videoRef} />
                        {selfVideoLayout && mediaStream?.isRenderSelfViewWithVideoElement() && (
                            <video
                                id={SELF_VIDEO_ID}
                                className="video-canvas"
                                playsInline
                                muted
                                autoPlay
                                style={{
                                    position: "absolute",
                                    display: 'block',
                                    width: `${selfVideoLayout.width}px`,
                                    height: `${selfVideoLayout.height}px`,
                                    top: `${selfVideoLayout.y}px`,
                                    left: `${selfVideoLayout.x}px`,
                                    pointerEvents: 'none'
                                }}
                            />
                        )}

                        <ul className="avatar-list">
                            {visibleParticipants.map((user, index) => {
                                if (index > videoLayout.length - 1) {
                                    return null;
                                }
                                const dimension = videoLayout[index];
                                const { width, height, x, y } = dimension;
                                const { height: canvasHeight } = canvasDimension;
                                return (
                                    <Avatar
                                        participant={user}
                                        key={user.userId}
                                        isActive={activeVideo === user.userId}
                                        networkQuality={networkQuality[`${user.userId}`]}
                                        style={{
                                            width: `${width}px`,
                                            height: `${height}px`,
                                            top: `${canvasHeight - y - height}px`,
                                            left: `${x}px`
                                        }}
                                    />
                                );
                            })}
                        </ul>
                        <VideoFooter />
                    </div>
                </Draggable> : null
            }
        </>
    )
}

export default DraggableVideoContainer

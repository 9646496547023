import store from '../../../../store/store';
import { all, put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import { componentKey, setAllMedicalHistoryData, setAllSurgicalHistoryData, setIsMedicalHistoryModalOpen, setIsPastSurgicalHistoryDeletePopUp, setIsSurgicalHistoryModalOpen } from './PatientHistorySlice';
import PatientDashboardDataService from '../../../../services/PatientDashboardDataService';
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from "../../../../components/common-components/toaster/Constants"
export const {
    getAllPatientHistoryDetails,
    postPatientHistory,
    updatePatientHistory,
    deletePatientHistory,
} = {
    getAllPatientHistoryDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/HISTORY',
            payload,
        };
    },
    postPatientHistory: (payload) => {
        return {
            type: 'POST/PATIENTDASHBOARD/HISTORY',
            payload,
        };
    },
    updatePatientHistory: (payload) => {
        return {
            type: 'UPDATE/PATIENTDASHBOARD/HISTORY',
            payload,
        };
    },
    deletePatientHistory: (payload) => {
        return {
            type: 'DELETE/PATIENTDASHBOARD/HISTORY',
            payload,
        };
    },
};

function* getAllPatientHistoryDetailsAsync(action) {
    try {
        const { patientId = '', historyType = 'pmh', page = '1', limit = '10' } = action.payload;
        const params = {
            page: page,
            limit: limit,
        };

        const response = yield PatientDashboardDataService.getAllPatientHistory(
            `history/${historyType}/patient`,
            patientId,
            params
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            const historyData = data?.[historyType]?.map((result, index) => {
                return {
                    ...result, index
                }
            })
            if (historyType === 'pmh') {
                yield put(setAllMedicalHistoryData(historyData));
            }
            else {
                yield put(setAllSurgicalHistoryData(historyData));
            }
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* postPatientHistoryAsync(action) {
    try {
        const { patientId, historyData, historyType } = action.payload;
        const response = yield PatientDashboardDataService.postPatientHistory(`history/${historyType}`, patientId, historyData);
        const { message, status, data } = response?.data || {};
        if (status) {
            if (historyType === 'pmh') {
                yield put(setIsMedicalHistoryModalOpen(false));
            }
            else {
                yield put(setIsSurgicalHistoryModalOpen(false));
            }
            yield call(getAllPatientHistoryDetailsAsync,
                getAllPatientHistoryDetails({ patientId, historyType }));
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* updatePatientHistoryDetailsAsync(action) {
    try {
        const { patientId = '', historyId = '', historyType = '', historyData = {} } = action.payload;

        const response = yield PatientDashboardDataService.updatePatientHistory(
            `history/${historyType}`,
            historyId,
            patientId,
            historyData
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            if (historyType === 'pmh') {
                yield put(setIsMedicalHistoryModalOpen(false));
            }
            else {
                yield put(setIsSurgicalHistoryModalOpen(false));
            }
            yield call(getAllPatientHistoryDetailsAsync,
                getAllPatientHistoryDetails({ patientId, historyType }));
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* deletePatientHistoryDetailsAsync(action) {
    try {
        const { patientId = '', historyType = '', historyId = '' } = action.payload;

        const response = yield PatientDashboardDataService.deletePatientHistory(`history/${historyType}`, historyId, patientId);

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setIsPastSurgicalHistoryDeletePopUp(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAllPatientHistoryDetailsAsync,
                getAllPatientHistoryDetails({ patientId, historyType }));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}


function* rootSaga() {
    yield all([
        takeEvery(getAllPatientHistoryDetails().type, getAllPatientHistoryDetailsAsync),
        takeLatest(postPatientHistory().type, postPatientHistoryAsync),
        takeLatest(updatePatientHistory().type, updatePatientHistoryDetailsAsync),
        takeLatest(deletePatientHistory().type, deletePatientHistoryDetailsAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

import React, { useEffect } from 'react'
import { useState } from 'react';
import { SCREEN_SIZES } from '../constant';

const BREAKPOINTS = {
    sm: 768, md: 1366, lg: 1920
}
const useGetCurrentScreenSize = () => {
    const [screenSize, setScreenSize] = useState('md');

    const handleResize = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < BREAKPOINTS.sm) {
            setScreenSize(SCREEN_SIZES.XS);
        } else if (screenWidth < BREAKPOINTS.md) {
            setScreenSize(SCREEN_SIZES.SM);
        } else if (screenWidth < BREAKPOINTS.lg) {
            setScreenSize(SCREEN_SIZES.MD);
        } else {
            setScreenSize(SCREEN_SIZES.LG);
        }
    };

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return screenSize;
}

export default useGetCurrentScreenSize
import React from 'react';
import CardComponent from '../../../components/common-components/SettingsCard/Card';
import { appointmentCardsData } from '../constant';

function index() {
	return (
		<CardComponent
			cardName="Appointment"
			cardLogoName="settingsAppointmentIcon"
			cardoptions={appointmentCardsData}
		/>
	);
}

export default index;

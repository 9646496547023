import AppDataService from './AppDataService'

const COMMON_BASE = 'billing'

export default class BillingDataService {

    static async postSuperBill(data) {
        return await AppDataService.post(`${COMMON_BASE}`, data)
    }
    static async getAllICDcodes() {
        return await AppDataService.get(`${COMMON_BASE}/icd-codes`)
    }
    static async getAllProceduralcodes() {
        return await AppDataService.get(`${COMMON_BASE}/procedural-codes`)
    }
    static async getAllSuperBill(params) {
        return await AppDataService.get(`${COMMON_BASE}`, { params })
    }
    static async getSuperbill(uuid) {
        return await AppDataService.get(`${COMMON_BASE}/${uuid}`)

    }
    static async getUnpaidSuperBill(patientId) {
        return await AppDataService.get(`${COMMON_BASE}/patient/${patientId}`)
    }
    static async updateSuperBill(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}`, data)
    }
}


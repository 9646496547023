export const FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER = {
	EMAIL: 'email',
	FIRSTNAME: 'firstName',
	LASTNAME: 'lastName',
	ROLE: 'role',
	PROVIDER_TYPE: 'providerType',
	SPECIALITY: 'speciality',
	GENDER: 'gender',
	DOB: 'dob',
	NPI_NUMBER: 'npiNumber',
	PHONE_NUMBER: 'phoneNumber',
	OFFICE_FAX_NUMBER: 'officeFaxNumber',
	LANGUAGES_SPOKEN: 'languagesSpoken',
	YEARS_OF_EXPERIENCE: 'yearsOfExperience',
	DEPARTMENT: 'departmentIds',
	LICENSED_STATES: 'licensedStates',
	LICENCE_NUMBER: 'licenceNumber',
	LICENCE_EXPIRE_DATE: 'licenceExpireDate',
	BIO: 'bio',
	EXPERTIES_IN: 'expertiesIn',
	EDUCATION_WORK_EXPERIENCE: 'educationWorkExperience',
	NMC_ID: 'nmcId',
	ADDRESS_LINE_1: 'addressLine1',
	ADDRESS_LINE_2: 'addressLine2',
	CITY: 'city',
	STATE: 'state',
	COUNTTRY: 'country',
	ZIP: 'zip',
	WORK_LOCATION: 'locationIds',
	PROVIDER_PROFILE_PIC: 'image',
	PROVIDER_PROFILE_PATH: 'profilePicture'
};

export const FORM_FIELDS_NAMES_FOR_FILTERS = {
	NAME: 'name',
	ROLE: 'role',
	DEPARTMENT: 'department',
	STATUS: 'status',
};

export const FORM_FIELDS_NAMES_FOR_ADD_STAFF_USER = {
	ROLE: 'role',
	FIRSTNAME: 'firstName',
	LASTNAME: 'lastName',
	GENDER: 'gender',
	PHONE_NUMBER: 'phoneNumber',
	DOB: 'dob',
	EMAIL: 'email',
	WORK_LOCATION: 'locationIds',
	ADDRESS_LINE_1: 'addressLine1',
	ADDRESS_LINE_2: 'addressLine2',
	CITY: 'city',
	STATE: 'state',
	COUNTTRY: 'country',
	ZIP: 'zip',
	PROVIDER_PROFILE_PIC: 'image',
	PROVIDER_PROFILE_PATH: 'profilePicture',
	PROVIDER_ID: 'providerId'
}
export const FILTERS = {
	'name': "",
	'role': "",
	'department': "",
	'status': "",
};


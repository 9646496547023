import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Icons from '../../../../components/icons/Icons'
import Label from '../../../../components/common-components/label/Label'
import Input from '../../../../components/common-components/input/Input'
import DatePicker from '../../../../components/common-components/datePicker'
import TextArea from '../../../../components/common-components/textArea/TextArea'
import { FORM_FIELDS_NAMES } from "./constants"
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { getValidationSchema } from '../../../../libs/formsUtils'
import { componentKey as PatientVitalsComponentKey } from '../Vitals/PatientVitalsSlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import {
    postPatientVitals,
} from '../Vitals/PatientVitalsSaga';
import * as Yup from 'yup';
import moment from 'moment';
import { VALIDATION_REGEX } from '../../../../libs/constant';

const fields = [
    { fieldName: FORM_FIELDS_NAMES.DATE, isRequired: true },
    { fieldName: FORM_FIELDS_NAMES.TIME, isRequired: true },
    { fieldName: FORM_FIELDS_NAMES.SYSTOLIC, regexPattern: VALIDATION_REGEX.NUMBER_REGEX },
    { fieldName: FORM_FIELDS_NAMES.DAISTOLIC, regexPattern: VALIDATION_REGEX.NUMBER_REGEX },
    { fieldName: FORM_FIELDS_NAMES.HEART_RATE, regexPattern: VALIDATION_REGEX.NUMBER_REGEX },
    { fieldName: FORM_FIELDS_NAMES.RESPIRATORY_RATE, regexPattern: VALIDATION_REGEX.NUMBER_REGEX },
    { fieldName: FORM_FIELDS_NAMES.OXYGEN_SATURATION, regexPattern: VALIDATION_REGEX.NUMBER_REGEX },
    { fieldName: FORM_FIELDS_NAMES.BODY_MASS_INDEX, regexPattern: VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX },
    { fieldName: FORM_FIELDS_NAMES.FEET, regexPattern: VALIDATION_REGEX.NUMBER_REGEX },
    { fieldName: FORM_FIELDS_NAMES.IN, regexPattern: VALIDATION_REGEX.NUMBER_REGEX },
    { fieldName: FORM_FIELDS_NAMES.TEMPERATURE, regexPattern: VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX },
    { fieldName: FORM_FIELDS_NAMES.WEIGHT, regexPattern: VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX },
];


const initialValues = {
    [FORM_FIELDS_NAMES.DATE]: "",
    [FORM_FIELDS_NAMES.TIME]: '',
    [FORM_FIELDS_NAMES.SYSTOLIC]: '',
    [FORM_FIELDS_NAMES.DAISTOLIC]: '',
    [FORM_FIELDS_NAMES.HEART_RATE]: '',
    [FORM_FIELDS_NAMES.RESPIRATORY_RATE]: '',
    [FORM_FIELDS_NAMES.OXYGEN_SATURATION]: '',
    [FORM_FIELDS_NAMES.BODY_MASS_INDEX]: '',
    [FORM_FIELDS_NAMES.FEET]: '',
    [FORM_FIELDS_NAMES.IN]: '',
    [FORM_FIELDS_NAMES.TEMPERATURE]: '',
    [FORM_FIELDS_NAMES.WEIGHT]: '',
    [FORM_FIELDS_NAMES.NOTE]: '',

}


const validationSchema = getValidationSchema(fields).test('at-least-one', null, (values) => {
    if (!values.BMI && !values.daistolic && !values.feet && !values.heartRate && !values.in && !values.oxygenSaturation && !values.respiratoryRate && !values.systolic && !values.temperature && !values.weight) {
        return new Yup.ValidationError('At least one vital is required', null, '_error');
    }
    return true;
});
function VitalsModal({ open, close }) {

    const dispatch = useDispatch();
    const { isVitalsModalOpen } = useSelector(
        (state) => state[PatientVitalsComponentKey]
    );

    const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(formVals, { resetForm }) => {
                try {
                    formVals.height = formVals.feet && `${formVals.feet}.${formVals.in}`
                    formVals.time = moment(formVals.time).format('HH:mm')
                    delete formVals.feet;
                    delete formVals.in;
                    dispatch(postPatientVitals({ patientId: currentPatientId, vitalsData: formVals }));
                    resetForm();
                } catch (error) {
                    console.log(error);
                }
            }}
        >
            {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur, errors }) => {
                return <Form onSubmit={handleSubmit} >
                    <ModalComponent
                        open={isVitalsModalOpen}
                        title={'Add Vitals'}
                        footerButton={<Button variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary" type='submit'>Save</Button>}
                        icon={<Icons iconName="closeIcon" />}
                        close={close}
                        customClasses="w-[772px]">
                        <div className='p-2'>
                            <div className='flex column-gap-10px items-center pt-6  '>
                                <div className='flex-1'>
                                    <DatePicker
                                        label="Date"
                                        name={FORM_FIELDS_NAMES.DATE}
                                        placeholder='Choose Date'
                                        maxDate={new Date()}
                                        isRequired={true}
                                        value={values[FORM_FIELDS_NAMES.DATE]}
                                        onChangeCb={(date) => {
                                            setFieldValue(FORM_FIELDS_NAMES.DATE, date)
                                        }} />
                                </div>
                                <div className='flex-1'>
                                    <DatePicker
                                        name={FORM_FIELDS_NAMES.TIME}
                                        placeholder='Choose Time'
                                        isRequired={true}
                                        label='Time'
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        value={values[FORM_FIELDS_NAMES.TIME]}
                                        onChangeCb={(time) => {
                                            setFieldValue(FORM_FIELDS_NAMES.TIME, time)
                                        }}
                                        iconName='scheduleIcon'
                                    />
                                </div>
                            </div>
                            <div>
                                <ErrorMessage name="vital_error" component="div" />
                            </div>
                            {errors._error && <div name='vital_error' className="error-text pt-6 text-center">{errors._error}</div>}
                            <div className='flex column-gap-10px items-center pt-6  '>
                                <div className='flex-1'>
                                    <div>
                                        <Label fontWeight='bold'>Blood Pressure(mmhg)</Label>
                                    </div>
                                    <div className="text-gray-500">
                                        <Label>Systolic</Label>
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={"Enter"}
                                            name={FORM_FIELDS_NAMES.SYSTOLIC}
                                            value={values[FORM_FIELDS_NAMES.SYSTOLIC]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.SYSTOLIC, e.target.value) }}
                                            onBlurCb={handleBlur} />
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <div className=' text-gray-500 pt-6'>
                                        <Label>Daistolic</Label>
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={"Enter"}
                                            name={FORM_FIELDS_NAMES.DAISTOLIC}
                                            value={values[FORM_FIELDS_NAMES.DAISTOLIC]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.DAISTOLIC, e.target.value) }}
                                            onBlurCb={handleBlur} />
                                    </div>
                                </div>
                            </div>
                            <div className='flex column-gap-10px items-center pt-6  '>
                                <div className='flex-1'>
                                    <div>
                                        <Label fontWeight='bold'>Heart Rate</Label>
                                    </div>
                                    <div className="text-gray-500">
                                        <Label>BPM</Label>
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={"Enter"}
                                            name={FORM_FIELDS_NAMES.HEART_RATE}
                                            value={values[FORM_FIELDS_NAMES.HEART_RATE]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.HEART_RATE, e.target.value) }}
                                            onBlurCb={handleBlur} />
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <div>
                                        <Label fontWeight='bold'>Respiratory Rate</Label>
                                    </div>
                                    <div className="text-gray-500">
                                        <Label >BPM</Label>
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={"Enter"}
                                            name={FORM_FIELDS_NAMES.RESPIRATORY_RATE}
                                            value={values[FORM_FIELDS_NAMES.RESPIRATORY_RATE]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.RESPIRATORY_RATE, e.target.value) }}
                                            onBlurCb={handleBlur} />
                                    </div>
                                </div>
                            </div>
                            <div className='flex column-gap-10px items-center pt-6  '>
                                <div className='flex-1'>
                                    <div>
                                        <Label fontWeight='bold'>Oxygen Saturation</Label>
                                    </div>
                                    <div className="text-gray-500">
                                        <Label>%</Label>
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={"Enter"}
                                            name={FORM_FIELDS_NAMES.OXYGEN_SATURATION}
                                            value={values[FORM_FIELDS_NAMES.OXYGEN_SATURATION]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.OXYGEN_SATURATION, e.target.value) }}
                                            onBlurCb={handleBlur} />
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <div className='pb-6'>
                                        <Label fontWeight='bold'>Body Mass Index</Label>
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={"Enter"}
                                            name={FORM_FIELDS_NAMES.BODY_MASS_INDEX}
                                            value={values[FORM_FIELDS_NAMES.BODY_MASS_INDEX]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.BODY_MASS_INDEX, e.target.value) }}
                                            onBlurCb={handleBlur} />
                                    </div>
                                </div>
                            </div>
                            <div className='flex column-gap-10px items-center pt-6  '>
                                <div className='flex-1'>
                                    <div>
                                        <Label fontWeight='bold'>Height</Label>
                                    </div>
                                    <div className="text-gray-500">
                                        <Label>Ft</Label>
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={"Enter"}
                                            name={FORM_FIELDS_NAMES.FEET}
                                            value={values[FORM_FIELDS_NAMES.FEET]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.FEET, e.target.value) }}
                                            onBlurCb={handleBlur} />
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <div className="text-gray-500 pt-6">
                                        <Label >in</Label>
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={"Enter"}
                                            name={FORM_FIELDS_NAMES.IN}
                                            value={values[FORM_FIELDS_NAMES.IN]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.IN, e.target.value) }}
                                            onBlurCb={handleBlur} />
                                    </div>
                                </div>
                            </div>
                            <div className='flex column-gap-10px items-center pt-6  '>
                                <div className='flex-1'>
                                    <div>
                                        <Label fontWeight='bold'>Temperature</Label>
                                    </div>
                                    <div className="text-gray-500">
                                        <Label >°F</Label>
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={"Enter"}
                                            name={FORM_FIELDS_NAMES.TEMPERATURE}
                                            value={values[FORM_FIELDS_NAMES.TEMPERATURE]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.TEMPERATURE, e.target.value) }}
                                            onBlurCb={handleBlur} />
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <div>
                                        <Label fontWeight='bold'>Weight</Label>
                                    </div>
                                    <div className="text-gray-500">
                                        <Label >Kg</Label>
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={"Enter"}
                                            name={FORM_FIELDS_NAMES.WEIGHT}
                                            value={values[FORM_FIELDS_NAMES.WEIGHT]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.WEIGHT, e.target.value) }}
                                            onBlurCb={handleBlur} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='mt-4'>
                                    <Label fontWeight='bold'>Note</Label>
                                </div>
                                <div className='mt-2'>
                                    <TextArea
                                        name={FORM_FIELDS_NAMES.NOTE}
                                        value={values[FORM_FIELDS_NAMES.NOTE]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.NOTE, e.target.value) }} />
                                </div>
                            </div>
                        </div>
                    </ModalComponent>
                </Form>
            }}
        </Formik>

    )
}

export default VitalsModal
export const PAST_MEDICAL_FORM_FIELDS_NAMES = {
    CONDITION_NAME: 'conditionName',
    ONNSET_DATE: 'onsetDate',
    NOTE: 'note'
}

export const SURGICAL_HISTORY_FORM_FIELDS_NAMES = {
    SURGERGY_NAME: 'surgeryName',
    SURGERY_DATE: 'surgeryDate',
    NOTE: 'note'
}

export const FAMILY_HISTORY_FORM_FIELDS_NAMES = {
    PROBLEM_NAME: 'problemName',
    RELATIVE: 'relative',
    ONSET_AGE: 'onsetAge',
    NOTE: 'note',
    DIED: 'died'
}

import store from "../../store/store"

export const componentKey = 'REPORTS'

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setPatientReportsState: (state, action) => {
            state.patientReportsState = action.payload
        },
        setSuperBillData: (state, action) => {
            state.superBillData = action.payload
        },
        setPatientPayments: (state, action) => {
            state.patientPayments = action.payload
        },
    },
    initialReducerState: {
        patientReportsState: [],
        superBillData: [],
        patientPayments:[]
    }
})

export const { setPatientReportsState,setSuperBillData ,setPatientPayments} = actions
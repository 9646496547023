import React from 'react'

function DiagnosesIcon({ color = "rgba(26,26,26,0.5)" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_88417" data-name="Group 88417" transform="translate(-16710 7772)">
        <g id="Group_88394" data-name="Group 88394" transform="translate(-12 213)">
          <rect id="Rectangle_36164" data-name="Rectangle 36164" width="24" height="24" transform="translate(16722 -7985)" fill={color} opacity="0" />
          <g id="diagnose" transform="translate(16720.508 -7986.492)">
            <path id="Path_205531" data-name="Path 205531" d="M11.458,4a7.458,7.458,0,1,0,7.458,7.458A7.462,7.462,0,0,0,11.458,4Zm0,1.356a6.1,6.1,0,1,1-6.1,6.1A6.105,6.105,0,0,1,11.458,5.356Z" fill={color} fillRule="evenodd" />
            <path id="Path_205532" data-name="Path 205532" d="M6.994,18.712l.959-2.337A.56.56,0,0,1,8.439,16a.547.547,0,0,1,.5.349l2.314,5.076,1.173-2.384a.545.545,0,0,1,.477-.329h2.782a.691.691,0,0,1,0,1.356H13.217L11.71,23.129a.543.543,0,0,1-.486.329.552.552,0,0,1-.477-.349l-2.27-4.978-.641,1.562a.556.556,0,0,1-.5.375H4.556a.691.691,0,0,1,0-1.356Z" transform="translate(1.32 -7.932)" fill={color} fillRule="evenodd" />
            <path id="Path_205533" data-name="Path 205533" d="M44.2,45.158l4.068,4.068a.678.678,0,0,0,.959-.959L45.158,44.2a.678.678,0,1,0-.959.959Z" transform="translate(-26.44 -26.44)" fill={color} fillRule="evenodd" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default DiagnosesIcon
import React from 'react';
import ProfileCardList from '../ProfileCardList';
import Icons from '../../../../../../components/icons/Icons';
import Preferences from '../../../../../../containers/AddPatient/Preferences/Preferences';
import { useDispatch } from 'react-redux';
import { updatePreference } from '../../ProfileSaga';

const clinicalCenterNames = {
    pharmacyIds: "pharmacy",
    labIds: "lab",
    radiologyIds: "radiology"
}

const PreferencesCard = ({ patientData }) => {
    const dispatch = useDispatch()

    const data = [
        {
            editbtnIcon: (<Icons iconName="editIcon" />),
        },
        { cardTitle: 'Preferences', feildName: 'Primary Care Provider', subFeild: `${patientData?.provider?.firstName?.length || patientData?.provider?.lastName?.length ? `${patientData?.provider.firstName || ""} ${patientData?.provider?.lastName || ""}` : "-"}`, description: ' ' },
        {
            feildName: 'Default Pharmacy',
            subFeild: `${patientData?.PatientPharmacyIntermediate?.map((item) => item?.pharmacy?.name).join("</br>") || "-"}`,
            description: `${patientData?.PatientPharmacyIntermediate?.map((item) => {
                return `${item?.pharmacy?.address ? item?.pharmacy?.address + "," : ""} ${item?.pharmacy?.city ? item?.pharmacy?.city + "," : ""}
                     ${item?.pharmacy?.state ? item?.pharmacy?.state + "," : ""} ${item?.pharmacy?.country ? item?.pharmacy?.country + "," : ""} ${item?.pharmacy?.zip ? item?.pharmacy?.zip + "," : ""}`
            }).join("</br>")}`,
        },
        {
            feildName: 'Preferred Lab',
            subFeild: `${patientData?.PatientLabIntermediate?.map((item) => item?.lab?.name).join("</br>") || "-"}`,
            description: `${patientData?.PatientLabIntermediate?.map((item) => {
                return `${item?.lab?.address ? item?.lab?.address + "," : ""} ${item?.lab?.city ? item?.lab?.city + "," : ""}
                     ${item?.lab?.state ? item?.lab?.state + "," : ""} ${item?.lab?.country ? item?.lab?.country + "," : ""} ${item?.lab?.zip ? item?.lab?.zip + "," : ""}`
            }).join("</br>")}`,
        },
        {
            feildName: 'Preferred Radiology:',
            subFeild: `${patientData?.PatientRadiologyIntermediate?.map((item) => item?.radiology?.name).join("</br>") || "-"}`,
            description: `${patientData?.PatientRadiologyIntermediate?.map((item) => {
                return `${item?.radiology?.address ? item?.radiology?.address + "," : ""} ${item?.radiology?.city ? item?.radiology?.city + "," : ""}
                     ${item?.radiology?.state ? item?.radiology?.state + "," : ""} ${item?.radiology?.country ? item?.radiology?.country + "," : ""} ${item?.radiology?.zip ? item?.radiology?.zip + "," : ""}`
            }).join("</br>")}`,
        },
    ];
    return <ProfileCardList
        patientUpdateCb={(values, handleSuccess, patientId) => {
            const payload = {
                patientId: patientId,
            }
            for (let key in values) {
                payload[key] = values[key]?.map(item => {
                    return item[clinicalCenterNames[key]].uuid;
                });
            }
            dispatch(updatePreference({ uuid: patientId, updatedData: payload, handleSuccess }))
        }}
        data={data}
        paddingTop="pt-0"
        Component={Preferences}
        modelTitle={data[1].cardTitle}
    />
};

export default PreferencesCard;

import React, { useEffect } from 'react';
import SelectDropdown from '../../../../components/common-components/selectDropdown';
import Input from '../../../../components/common-components/input/Input';
import { vitalsTestFieldNames } from './Constants';
import { useSelector } from 'react-redux';
import { componentKey } from './GenrateSuperBillSlice';

function GSTCalculator({ values, setFieldValue }) {
    const { billData } = useSelector(state => state[componentKey])
    const { mapping_billing_payment } = billData || {}

    useEffect(() => {
        if (values.totalBalance && values[vitalsTestFieldNames.GST_TYPE].value === "CGST & SGST") {
            const gstAmount = values.totalBalance * (values[vitalsTestFieldNames.GST_IN_PERCENTAGE] / 100)
            setFieldValue(vitalsTestFieldNames.GST_AMOUNT, gstAmount)
            setFieldValue(vitalsTestFieldNames.GST_TOTAL_AMOUNT, values.totalBalance + gstAmount)
        } else if (values.totalBalance && values[vitalsTestFieldNames.GST_TYPE].value === "IGST") {
            const gstAmount = values.totalBalance * (values[vitalsTestFieldNames.GST_IN_PERCENTAGE] / 100)
            setFieldValue(vitalsTestFieldNames.GST_AMOUNT, gstAmount);
            setFieldValue(vitalsTestFieldNames.GST_TOTAL_AMOUNT, values.totalBalance + gstAmount)
        }
    }, [values, setFieldValue])

    return (
        <>
            <div className='mt-4 flex items-center mr-[60px] justify-between'>
                <div className='flex column-gap-20px'>
                    <div className='w-1/3' >
                        <SelectDropdown
                            isRequired={true}
                            name={vitalsTestFieldNames.GST_TYPE}
                            label='GST Type'
                            placeholder='Select GST Type'
                            value={values[vitalsTestFieldNames.GST_TYPE]}
                            onChangeCb={(val) => setFieldValue(vitalsTestFieldNames.GST_TYPE, val)}
                            options={[
                                { label: "CGST & SGST", value: "CGST & SGST" },
                                { label: "IGST", value: "IGST" },
                            ]}
                            disabled={mapping_billing_payment?.length ? true : false}
                        />
                    </div>
                    <div className='w-1/4'  >
                        <Input
                            name={vitalsTestFieldNames.GST_IN_PERCENTAGE}
                            isRequired={true}
                            label='GST Rate'
                            placeholder='Enter GST Rate'
                            value={values[vitalsTestFieldNames.GST_IN_PERCENTAGE]}
                            onChangeCb={(e) => setFieldValue(vitalsTestFieldNames.GST_IN_PERCENTAGE, e.target.value)}
                            disabled={mapping_billing_payment?.length ? true : false}
                        />
                    </div>
                    <div className='w-1/3' >
                        <Input
                            name={vitalsTestFieldNames.GST_NUMBER}
                            isRequired={true}
                            label='GST Number'
                            placeholder='Enter GST Number'
                            value={values[vitalsTestFieldNames.GST_NUMBER]}
                            onChangeCb={(e) => setFieldValue(vitalsTestFieldNames.GST_NUMBER, e.target.value)}
                            disabled={mapping_billing_payment?.length ? true : false}
                        />
                    </div>
                </div>
                <div>
                    {values[vitalsTestFieldNames.GST_TYPE].value === "CGST & SGST" && (
                        <>
                            <div className='flex column-gap-20px'>
                                <div className='text-met-primary font-bold'>{`CGST(${values[vitalsTestFieldNames.GST_IN_PERCENTAGE] / 2}%)`}</div>
                                <div className='font-bold text-gray-500'>{values?.gstAmount?.toFixed(2) / 2}</div>
                            </div>
                            <div className='flex column-gap-20px'>
                                <div className='text-met-primary font-bold'>{`SGST(${values[vitalsTestFieldNames.GST_IN_PERCENTAGE] / 2}%)`}</div>
                                <div className='font-bold text-gray-500'>{values?.gstAmount?.toFixed(2) / 2}</div>
                            </div>
                        </>
                    )}
                    {values[vitalsTestFieldNames.GST_TYPE].value === "IGST" && (
                        <>
                            <div className='flex column-gap-20px'>
                                <div className='text-met-primary font-bold'>{`IGST(${values[vitalsTestFieldNames.GST_IN_PERCENTAGE]}%)`}</div>
                                <div className='font-bold text-gray-500'>{values?.gstAmount?.toFixed(2)}</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default GSTCalculator;

import { all, call, put, takeLatest } from 'redux-saga/effects'
import store from "../../../../../store/store"
import { componentKey, setNotificationData } from './NotificationsSlice'
import NotificationDataService from '../../../../../services/NotificationDataService'

export const { getNotifications, updateNotifications } = {
    getNotifications: (payload) => {
        return {
            type: 'USER_PROFILE/GET_NOTIFICATIONS',
            payload
        }
    },
    updateNotifications: (payload) => {
        return {
            type: 'USER_PROFILE/UPDATE_NOTIFICATIONS',
            payload
        }
    }
}


function* getNotificationsAsync(action) {
    try {
        const response = yield NotificationDataService.getNotificationType(action.payload)
        const { data, status } = response.data

        if (status) {
            yield put(setNotificationData(data))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* updatedNotificationsAsync(action) {
    try {
        const { payload } = action.payload
        const response = yield NotificationDataService.updateNotificationType(payload)

        const { status } = response.data

        if (status) {
            yield call(getNotificationsAsync, getNotifications());

        }
    }
    catch (error) {
        console.log('err: ', error)
    }
}


function* rootSaga() {
    yield all([
        takeLatest(getNotifications().type, getNotificationsAsync),
        takeLatest(updateNotifications().type, updatedNotificationsAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
import React from 'react'
import CardContainer from '../../components/common-components/Cards/Container/CardContainer'
import Heading from '../../components/common-components/zoom/components/heading/Heading'
import { HEADING } from '../../components/common-components/zoom/constants'
import CsvReports from './CsvReports';

const Reports = () => {
    return (
        <CardContainer>
            <Heading fontSize="2xl" fontWeight="bold" type={HEADING.H1}>
                Reports
            </Heading>
            <div className="flex mt-[16px] justify-between">
                <CsvReports />
            </div>
        </CardContainer>
    )
};

export default Reports
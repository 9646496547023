import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../components/common-components/button/Constants";
import ButtonDropdown from "../../../../components/common-components/buttonDropdown";
import { HEADING } from "../../../../components/common-components/heading/Constants";
import Heading from "../../../../components/common-components/heading/Heading";
import Label from "../../../../components/common-components/label/Label";
import Table from "../../../../containers/Table/Index";
import { MET_COLORS } from "../../../../libs/constant";
import AddVitalsTestModal from "./AddVitalsTestModal";
import { componentKey as patientVitalTestSlice, setPaginationState, setIsVitalsTestModalOpen, setEditTestsData, setIsEditVitalTest, setIsOpenViewReportModal, setViewTestsData, setIsreviewTestModalOpen, setIsAudioPlayModalOpen } from "./VitalsTestSlice";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchAllVitalsTest, patchVitalsTest } from "./VitalsTestSaga";
import MetPopOver from "../../../../components/common-components/popoverComponent/MetPopOver";
import ViewReportModal from "./ViewReportModal";
import ReviewReportModal from "./ReviewReportModal";
import AudioPlayModal from "./AudioPlayModal";


function VitalsTest() {
    const dispatch = useDispatch();
    const { patientId } = useParams()

    const { isVitalsTestModalOpen, vitalsTestDetails, paginationState, editTestsData, isOpenViewReportModal, viewTestsData, isreviewTestModalOpen, isAudioPlayModalOpen } = useSelector((state) => state[patientVitalTestSlice]);

    const [isStethoscopeTestUrl, setIsStethoscopeTestUrl] = useState(false);
    const role = localStorage.getItem('role');

    useEffect(() => {
        if (patientId) {
            dispatch(fetchAllVitalsTest({ patientId, paginationState }));
        }
    }, [patientId,
        dispatch,
        paginationState?.limit,
        paginationState?.pageNumber,
    ]);

    const handleAddVitalsTestModal = () => {
        dispatch(setIsVitalsTestModalOpen(true));
        dispatch(setIsEditVitalTest(false))
    };


    const handleRequestStatus = (uuid, status) => {
        dispatch(patchVitalsTest({ paginationState, patientId, body: { uuid: uuid, status: 'Requested' } }));
    };

    const coloumns = [
        {
            field: 'deviceName',
            label: <Label fontWeight="font-bold">Device Name</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {typeof row?.test[0]?.deviceName === "string" ? row?.test[0]?.deviceName : null}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'testName',
            label: <Label fontWeight="font-bold">Test Name</Label>,
            renderLogic: (row) => {
                if (row?.test?.length > 1) {
                    return (
                        <MetPopOver maxChar="1">
                            {row.test.map((item, index) => (
                                <div className="flex items-center" key={`test-data-${index}`}>
                                    <div>
                                        <Heading
                                            type={HEADING.H1}
                                            color="gray-500"
                                            fontSize={'md'}
                                            customClasses="cursor-pointer"
                                        >
                                            {item?.testName}
                                        </Heading>
                                    </div>
                                </div>
                            ))}
                        </MetPopOver>
                    );
                } else {
                    return row.test.map((item, index) => (
                        <div className="flex items-center" key={`test-data-${index}`}>
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {item?.testName}
                                </Heading>
                            </div>
                        </div>
                    ));
                }
            }
        },
        {
            field: 'status',
            label: <Label fontWeight="font-bold">Status</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row?.status}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'status',
            label: <Label fontWeight="font-bold">Feedback</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row?.review && row?.review?.length > 30 ?
                                        <MetPopOver maxChar="150" >
                                            {row?.review}
                                        </MetPopOver> :  row?.review}

                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'action',
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                let actionButton = null;
                switch (row?.status) {
                    case 'Pending':
                        actionButton = (
                            <>
                                <div className='flex items-center'>
                                    <ButtonDropdown
                                        customBtnClass="p-0" endIcon="moreVertIcon"
                                        options={[
                                            {
                                                label: "Edit", value: null, onChangeCb: () => {
                                                    dispatch(setIsVitalsTestModalOpen(true));
                                                    dispatch(setEditTestsData(row))
                                                    dispatch(setIsEditVitalTest(true))
                                                }
                                            },
                                            {
                                                label: "Request", value: null, onChangeCb: () => {
                                                    handleRequestStatus(row?.uuid, row?.status)
                                                }
                                            },
                                        ]}
                                        selectCb={(option) => {
                                            option?.onChangeCb();
                                        }} />
                                </div>
                            </>
                        );
                        break;
                    case 'Requested':
                        break;
                    case 'Completed':
                        actionButton = (
                            <>
                                <div className='flex items-center'>
                                    <ButtonDropdown
                                        customBtnClass="p-0" endIcon="moreVertIcon"
                                        options={[
                                            {
                                                label: "View", value: null, onChangeCb: () => {
                                                    dispatch(setIsOpenViewReportModal(true));
                                                    dispatch(setViewTestsData(row))
                                                }
                                            },
                                            {
                                                label: "Add Feedback", value: null, onChangeCb: () => {
                                                    dispatch(setIsreviewTestModalOpen(true));
                                                    dispatch(setViewTestsData(row))
                                                }
                                            }
                                        ]}
                                        selectCb={(option) => {
                                            option?.onChangeCb();
                                        }} />
                                </div>
                            </>
                        );


                    default:
                        break;
                }

                return (
                    <>
                        <div className="flex items-center">
                            {/* <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.action}
                                </Heading>
                            </div>
                            <ButtonDropdown
                                customBtnClass="p-0"
                                endIcon="moreVertIcon"
                                options={[
                                    { label: 'Test1', value: null },
                                    { label: 'Test2', value: null },
                                ]}
                           
                            /> */}
                            {actionButton}

                            {row.test.map((item, index) => (
                                <div className="flex items-center" key={`test-data-${index}`}>

                                    {row?.stethoscope?.length && item?.testName === 'StethoscopeTest' &&
                                        <Button
                                            variant={BUTTON_VARIANTS.OUTLINED}
                                            customBtnClass="text-met-success border-green-500 bg-yellow-50 bg-opacity-20 h-[28px] ml-14"
                                            onClickCb={() => {
                                                dispatch(setIsAudioPlayModalOpen(true))
                                                setIsStethoscopeTestUrl(row?.stethoscope)
                                            }}
                                        >
                                            Play
                                        </Button>
                                    }
                                </div>
                            ))}

                        </div >
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="flex items-center justify-between p-2 px-4">
                <Heading fontSize="xl" fontWeight="bold" type={HEADING.H3}>
                    Vitals Test
                </Heading>
                <div className='flex'>
                {role !=="Biller" && <Button onClickCb={handleAddVitalsTestModal}
                        variant={BUTTON_VARIANTS.OUTLINED}
                        startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} >
                        Add Vitals Test
                    </Button>
                }
                </div>
            </div>
            <div className='p-4'>
                <Table
                    coloumns={coloumns}
                    rows={vitalsTestDetails.vitalTest}
                    paginationProps={{
                        isPagination: true,
                        totalCount: paginationState?.total,
                        limit: paginationState?.limit,
                        onPageChange: (page) => {
                            dispatch(setPaginationState({ pageNumber: page }));
                        },
                    }}
                />
            </div >
            {isVitalsTestModalOpen ?
                <AddVitalsTestModal
                    editTestsData={editTestsData}
                    open={isVitalsTestModalOpen}
                    close={() => {
                        dispatch(setIsVitalsTestModalOpen(false))
                        dispatch(setEditTestsData({}))
                    }}
                /> : null}

            {isOpenViewReportModal ?
                <ViewReportModal
                    viewTestsData={viewTestsData}
                    open={isOpenViewReportModal}
                    close={() => {
                        dispatch(setIsOpenViewReportModal(false))
                    }}
                /> : null}
            {isreviewTestModalOpen ?
                <ReviewReportModal
                    viewTestsData={viewTestsData}
                    open={isreviewTestModalOpen}
                    close={() => {
                        dispatch(setIsreviewTestModalOpen(false))
                    }}
                /> : null}
            {isAudioPlayModalOpen ? <AudioPlayModal open={isAudioPlayModalOpen}
                linkurl={isStethoscopeTestUrl}
                close={() => {
                    dispatch(setIsAudioPlayModalOpen(false))
                }} /> : null}
        </>

    )
}

export default VitalsTest;
import React from 'react'
import Login from '../pages/Authentication/Login'
import Main from '../pages'
import { AuthProtected, FullPageRoute } from './authProtected'
import { COMMUNICATIONS_TAB_PATHS, MAIN_PATHS, SETTINGS_PATHS } from '../libs/constant'
import HomeSection from '../pages/HomeSection'
import Scheduling from '../pages/Scheduling'
import Patients from '../pages/Patients'
import Billing from '../pages/Billing'
import Settings from '../pages/Settings'
import { Navigate } from 'react-router-dom'
import AppointmentTabContainer from '../containers/SettingsTab/AppointmentCard/Tabs';
import MyAccountTabContainer from '../containers/SettingsTab/MyAccountCard/Tabs';
import ProviderGroupTabContainer from '../containers/SettingsTab/ProviderGroupCard/Tabs'
import PaymentSuccessPage from '../pages/PaymentSuccessPage/PaymentSuccessPage'
import PatientEncounterDetails from '../pages/Encounter'
import PatientVideoCall from '../containers/AddPatient/PatientVideoCall/PatientVideoCall'
import TemplatesTabContainer from '../containers/SettingsTab/TemplatesCard/Tabs'
import NewVisitNote from '../containers/SettingsTab/TemplatesCard/Tabs/VisitNotes/NewVisitNote'
import UnsignedVisit from '../pages/UnsignedVisit/UnsignedVisit'
import MasterTabContainer from '../containers/SettingsTab/MasterCard/Tabs'
import Reports from '../pages/Report'
import { USER_PERMISSIONS } from '../libs/UserPermissionsConstants'
import PasswordSuccessPage from '../pages/Authentication/PasswordSuccessPage'
// import Formbuilder from '../pages/Encounter/FormBuilder/FormBuilder'

const AppRoutes = [
    { path: '/login/*', component: <FullPageRoute><Login /></FullPageRoute> },
    { path: '/*', component: <AuthProtected><Main /></AuthProtected> },
    { path: '/payment/success', component: <PaymentSuccessPage /> },
    { path: '/resetpassword/success', component: <PasswordSuccessPage /> },
    { path: '/video-encounter/:appointmentDetailsId/:type/:tenetId', component: <PatientVideoCall /> },
]

export const MAIN_ROUTES = [
    { path: MAIN_PATHS.DASHBOARD, component: <HomeSection />, label: "Dashboard" },
    { path: MAIN_PATHS.SCHEDULING, component: <Scheduling />, label: "Scheduling" },
    { path: MAIN_PATHS.PATIENT_ENCOUNTER, component: <PatientEncounterDetails /> },
    // { path: '/form/build', component: <Formbuilder /> },
    { path: MAIN_PATHS.PATIENTS, component: <Patients />, label: "Patients" },
    // {
    //     label: "Communications",
    //     path: MAIN_PATHS.COMMUNICATIONS,
    //     children: [
    //         { path: "/communications/tasks", label: "Tasks", value: null },
    //         { path: "/communications/contact-directory", label: "Contact directory", value: null }
    //     ],
    //     component: <Communications />
    // },
    { path: MAIN_PATHS.UNSIGNED_VISIT, component: <UnsignedVisit />, label: "Unsigned Visit" },
    { path: MAIN_PATHS.BILLING, component: <Billing />, label: "Billing" },
    { path: MAIN_PATHS.REPORTS, component: <Reports />, label: "Reports" },
    { path: MAIN_PATHS.SETTINGS, component: <Settings />, label: "Settings" },
    { path: SETTINGS_PATHS.APPOINTMENT_ROOT, component: <AppointmentTabContainer /> },
    { path: SETTINGS_PATHS.MY_ACCOUNT_ROOT, component: <MyAccountTabContainer /> },
    { path: SETTINGS_PATHS.PROVIDER_GROUP_ROOT, component: <ProviderGroupTabContainer /> },
    { path: SETTINGS_PATHS.TEMPLATES_ROOT, component: <TemplatesTabContainer /> },
    { path: SETTINGS_PATHS.TEMPLATES_NEW_VISIT_NOTES, component: <NewVisitNote /> },
    { path: SETTINGS_PATHS.MASTER_ROOT, component: <MasterTabContainer /> },
    { path: MAIN_PATHS.ROOT, component: <Navigate to={MAIN_PATHS.REDIRECT_TO_DASHBOARD} /> },
]


export const COMMUNICATIONS_TAB_ROUTES = [
    { path: COMMUNICATIONS_TAB_PATHS.TASKS, component: <div>helo tasks</div> },
    { path: COMMUNICATIONS_TAB_PATHS.CONTACT_DIRECTORY, component: <div>helo contacts</div>, },
]

export const PATIENTS_ROUTE_PATHS = {
    PROVIDER_INFORMATION: "/provider",
    ABHA_INFORMATION: "/abha",
    PATIENT_DETAILS: "/patient",
    CONTACT_INFORMATION: "/contacts",
    EMERGENCY_CONTACT: "/emergency-contacts",
    INSURANCE: "/insurance",
    PREFERENCES: "/preferences",
    CONSENTS: "/consents",
    VITALS_TEST: "/vitals-test"
}

export default AppRoutes

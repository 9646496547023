import { all, call, put, takeLatest } from 'redux-saga/effects'
import PatientDataService from '../../../../services/PatientDataServices'
import { componentKey, setAllDepartments, setAllLocation, setBillData, setCollectPaymentData, setEncounterDetails, setIcdCodesData, setIsReadyBilingIds, setIsopenCollectPaymentModal, setPaymentData, setProceduralCodesData, setReadyForBillingById, setReadyForBillingByIdData, setReadyForBillingData, setReadyForBillingPaginationState, setSuperBillData, setSuperBillpaginationState, setdeviceNameList, setdeviceTestsData, setpatientDetails, setpatientVitalTests, setproviderGrouplist } from './GenrateSuperBillSlice'
import store from '../../../../store/store'
import BillingDataService from '../../../../services/BillingDataService'
import ProvidersDataService from '../../../../services/ProvidersDataService'
import General from '../../../../libs/utility/General'
import DepartmentDataService from '../../../../services/DepartmentDataService'
import PaymentDataService from '../../../../services/PaymentDataService'
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice'
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/MetToaster'
import ProviderGroupDataService from '../../../../services/ProviderGroupDataService'
import EncounterDataService from '../../../../services/EncounterDataService'
import DeviceTestDataService from '../../../../services/DeviceTestDataService'
import VitalsTestDataService from '../../../../services/VitalsTestDataService'
import { socket } from '../../../../socket/socket'

export const getPatientDetails = (payload) => {
    return {
        type: 'BILLING/GET_PATIENT_DETAILS',
        payload
    }
}
export const postSuperBillDetails = (payload) => {
    return {
        type: 'BILLING/POST_SUPER_BILL_DETAILS',
        payload
    }
}

export const getAllLocation = (payload) => {
    return {
        type: "BILLING/GET_ALL_LOCATION",
        payload

    }
}

export const getAllDepartments = (payload) => {
    return {
        type: 'BILLING/GET_ALL_DEPARTMENTS',
        payload
    }
}

export const getAllICDcodes = (payload) => {
    return {
        type: 'BILLING/GET_ALL_ICD_CODES',
        payload
    }
}

export const getAllProceduralodes = (payload) => {
    return {
        type: 'BILLING/GET_ALL_PROCEDURAL_CODES',
        payload
    }
}

export const getCollectPaymentDetails = (payload) => {
    return {
        type: 'BILLING/GET_COLLECT_PAYMENT_DETAILS',
        payload
    }
}


export const postCollectPaymentDetails = (payload) => {
    return {
        type: 'BILLING/POST_COLLECT_PAYMENT_DETAILS',
        payload
    }
}

export const getAllSuperBill = (payload) => {
    return {
        type: 'BILLING/GET_ALL_SUPER_BILL',
        payload
    }
}

export const getSuperBill = (payload) => {
    return {
        type: 'BILLING/GET_SUPER_BILL',
        payload
    }
}

export const getProviderGroupList = (payload) => {
    return {
        type: 'BILLING/GET_PROVIDER_GROUP_LIST',
        payload
    }
}

export const updateSuperBill = (payload) => {
    return {
        type: 'BILLING/EDIT_SUPER_BILL',
        payload
    }
}

export const getReadyForBillingList = (payload) => {
    return {
        type: 'BILLING/READY_FOR_SUPER_BILL',
        payload
    }
}

export const getDeviceTestDetails = (payload) => {
    return {
        type: 'BILLING/GET_DEVICE_TEST',
        payload
    }
}

export const getAllDeviceNamesList = (payload) => {
    return {
        type: 'BILLING/GET_DEVICE_NAME',
        payload
    }
}

export const getAllVitalsTest = (payload) => {
    return {
        type: 'BILLING/GET_ALL_VITAL_TESTS',
        payload
    }
}

export const getReadyForBillingById = (payload) => {
    return {
        type: 'BILLING/GET_READY_FOR_BILLING_BY_ID',
        payload
    }
}


function* getPatientDetailsAsync(action) {
    try {
        const { uuid } = action.payload
        const response = yield PatientDataService.getPatientDetails(uuid)
        const { data, status } = response.data
        if (status) {
            yield put(setpatientDetails(data))
        }

    } catch (error) {
        console.log('err: ', error)
    }
}

function* postSuperBillDetailsAsync(action) {
    try {
        const { data: payload, navigate, shouldNavigateBack } = action.payload
        const response = yield BillingDataService.postSuperBill(payload)
        const { status, message, data } = response.data
        const { patientId, uuid } = data.superBill || {}

        if (status) {
            // yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            const notificationObject = {
                uuid: patientId,
            }
            const providerNotificationObject = {
                uuid: data?.renderingProviderId,
            }
            socket.emit('sendNotificationToPatient', notificationObject)
            socket.emit('sendNotificationToProvider', providerNotificationObject)
            if (shouldNavigateBack) {
                navigate("/billing/superbill")
            }
            else {
                navigate(`/billing/genrate-super-bill/${patientId}?billid=${uuid}`)
            }

        }

    } catch (error) {
        console.log('err: ', error)
    }
}

function* getAllLocationAsync(action) {
    try {
        const response = yield ProvidersDataService.getAllProviderLocation(action.payload)
        const { data } = response.data
        const { locations } = data || []
        const allLocations = General.addLableValuePair(locations, "name", "uuid")
        yield put(setAllLocation(allLocations))
    } catch (error) {
        console.log('err: ', error)
    }
}

function* getAllDepartmentsAsync() {

    try {
        const response = yield DepartmentDataService.getAllDepartments()
        const { data, status } = response.data
        if (status) {
            const department = General.addLableValuePair(data, "departmentName", "uuid")
            yield put(setAllDepartments(department))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* getAllICDCodesAsync() {
    try {
        const response = yield BillingDataService.getAllICDcodes()
        const { data, status } = response.data
        const { icdCodes } = data
        const icdCodeData = General.addLableValuePair(icdCodes, "icdCode", "uuid", "icdCodeDescription")

        if (status) {
            yield put(setIcdCodesData(icdCodeData))
        }
    }
    catch (error) {
        console.log('err: ', error)
    }

}

function* getAllProceduralCodesAsync() {
    try {
        const response = yield BillingDataService.getAllProceduralcodes()
        const { data, status } = response.data
        const { proceduralCodes } = data
        const proceduralCodesData = General.addLableValuePair(proceduralCodes, "proceduralCode", "uuid", "proceduralCodeDescription")

        if (status) {
            yield put(setProceduralCodesData(proceduralCodesData))
        }
    }
    catch (error) {
        console.log('err: ', error)
    }

}

function* getCollectPaymentDetailsAsync(action) {

    try {
        const uuid = action.payload
        const response = yield PaymentDataService.getCollectPayment(uuid)
        const { data, status, message } = response.data
        if (status) {
            yield put(setPaymentData(data))
        }

    } catch (error) {
        console.log('err: ', error)
    }
}

function* postCollectPaymentDetailsAsync(action) {
    try {
        const payload = action.payload
        const response = yield PaymentDataService.createPatientPayment(payload)
        const { data, status, message } = response.data
        const paymentMethod = data?.method

        if (status) {
            const notificationObject = {
                uuid: data.patientId,
            }
            const providerNotificationObject = {
                uuid: data?.providerId,
            }
            socket.emit('sendNotificationToPatient', notificationObject)
            socket.emit('sendNotificationToProvider', providerNotificationObject)
            const uuid = payload?.billid
            yield put(setCollectPaymentData(data))
            yield call(updateSuperBillAsync, updateSuperBill({ data: { patientPayments: [data.uuid], status: paymentMethod !== "Online" ? "Paid" : "Unpaid" }, uuid: payload.billid }))
            yield call(getSuperBillAsync, getSuperBill(uuid))
            if (paymentMethod === "Online") {
                const { paymentlink } = data
                window.location.href = paymentlink.link_url;
            }
            yield put(setIsopenCollectPaymentModal(false))
        }

    } catch (error) {
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
        console.log('err: ', error)
    }
}


function* getAllSuperBillAsync(action) {
    try {
        const response = yield BillingDataService.getAllSuperBill(action.payload)
        const { data, status, message } = response.data

        if (status) {
            const { totalRecords } = data || {}
            yield put(setSuperBillData(data))
            yield put(setSuperBillpaginationState({ total: totalRecords }))
        }


    } catch (error) {
        console.log('err: ', error)
    }
}

function* getSuperBillAsync(action) {

    try {
        const uuid = action.payload
        const response = yield BillingDataService.getSuperbill(uuid)
        const { data, status, message } = response.data
        if (status) {
            yield put(setBillData(data))
        }

    } catch (error) {
        console.log('err: ', error)
    }
}


function* getProviderGroupListAsync(action) {
    try {
        const response = yield ProviderGroupDataService.getProviderGroupDetails(action.payload)
        const { data: [firstElement] } = response.data
        yield put(setproviderGrouplist(firstElement))
    } catch (error) {
        console.log('err: ', error)
    }
}

function* updateSuperBillAsync(action) {
    try {
        const { uuid, data, navigate, shouldNavigateBack, isBillUnpaid } = action.payload
        const response = yield BillingDataService.updateSuperBill(uuid, data)

        const { status, message } = response.data
        const resData = response?.data?.data || {}

        if (response.status === 200) {
            const notificationObject = {
                uuid: resData?.patientId,
            }
            const providerNotificationObject = {
                uuid: resData?.renderingProviderId,
            }
            socket.emit('sendNotificationToPatient', notificationObject)
            socket.emit('sendNotificationToProvider', providerNotificationObject)
            if (shouldNavigateBack) {
                navigate("/billing/superbill")
            }

            if (!isBillUnpaid) {
                yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            }

        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* getRedayForBillingListAsync(action) {

    try {
        const readyForBillingPaginationState = action.payload
        const params = {
            pageNumber: readyForBillingPaginationState?.pageNumber,
            limit: readyForBillingPaginationState?.limit,
            name: readyForBillingPaginationState?.name
        }
        const response = yield EncounterDataService.getReadyForBilling(params)
        const { data, status } = response.data

        if (status) {
            const { totalRecords } = data || {}
            yield put(setReadyForBillingData(data))
            yield put(setReadyForBillingPaginationState({ total: totalRecords }))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}


function* getDeviceTestDetailsAsync(action) {
    try {
        const response = yield DeviceTestDataService.getDeviceTest(action.payload)
        const { data, status } = response.data
        if (status) {
            const deviceTestList = General.addLableValuePair(data, "testName", "uuid")
            yield put(setdeviceTestsData(deviceTestList))
        }

    } catch (error) {
        console.log('err: ', error)
    }
}

function* getAllDeviceNamesListAsync(action) {
    try {
        const response = yield DeviceTestDataService.getDeviceNameList(action.payload)
        const { device, status } = response.data
        if (status) {
            const deviceList = General.addLableValuePair(device, 'deviceName', 'uuid');
            yield put(setdeviceNameList(deviceList))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}


function* getAllVitalsTestAsync(action) {
    try {
        const { params } = action.payload
        const response = yield VitalsTestDataService.getAllVitalsTestDetails(params);
        const { data, status } = response.data;
        const { vitalTest, isTestIsReadyForbilling } = data
        if (status) {
            yield put(setpatientVitalTests(vitalTest));
            yield put(setIsReadyBilingIds(isTestIsReadyForbilling))
        }
    } catch (error) {
        console.log('err: ', error);
    }
}


function* getReadyForBillingByIdAsync(action) {

    try {
        const { EncounterID = '' } = action.payload;
        const response = yield EncounterDataService.fetchReadyForBillingByID(EncounterID);
        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setReadyForBillingByIdData(data));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}


function* rootSaga() {
    yield all([
        takeLatest(getPatientDetails().type, getPatientDetailsAsync),
        takeLatest(postSuperBillDetails().type, postSuperBillDetailsAsync),
        takeLatest(getAllLocation().type, getAllLocationAsync),
        takeLatest(getAllDepartments().type, getAllDepartmentsAsync),
        takeLatest(getAllICDcodes().type, getAllICDCodesAsync),
        takeLatest(getAllProceduralodes().type, getAllProceduralCodesAsync),
        takeLatest(postCollectPaymentDetails().type, postCollectPaymentDetailsAsync),
        takeLatest(getCollectPaymentDetails().type, getCollectPaymentDetailsAsync),
        takeLatest(getAllSuperBill().type, getAllSuperBillAsync),
        takeLatest(getSuperBill().type, getSuperBillAsync),
        takeLatest(getProviderGroupList().type, getProviderGroupListAsync),
        takeLatest(updateSuperBill().type, updateSuperBillAsync),
        takeLatest(getReadyForBillingList().type, getRedayForBillingListAsync),
        takeLatest(getDeviceTestDetails().type, getDeviceTestDetailsAsync),
        takeLatest(getAllDeviceNamesList().type, getAllDeviceNamesListAsync),
        takeLatest(getAllVitalsTest().type, getAllVitalsTestAsync),
        takeLatest(getReadyForBillingById().type, getReadyForBillingByIdAsync)
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
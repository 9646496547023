import React from 'react';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';

function LimitReachedPopup({ open, close }) {
	return open ? (
		<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-65">
			<div
				style={{
					minHeight: '300px',
					minWidth: '400px',
				}}
				className="bg-white shadow-md rounded-lg p-6 flex items-center justify-center flex-col"
			>
				<div
					style={{
						height: 50,
						width: 50,
					}}
					className="rounded-full bg-red-100 flex items-center justify-center"
				>
					<span role="img" aria-label="limit reached" className="text-red-500 text-6xl">
						-
					</span>
				</div>
				<div className="mt-6 text-lg font-semibold text-red-600">
					Maximum Limit Reached
				</div>
				<div className="m-4 text-sm text-gray-600 text-center">
					You have exhausted the maximum number of resend attempts. Please try again later.
				</div>
				<div className="mt-6">
					<Button variant={BUTTON_VARIANTS.CONTAINED} onClickCb={close}>
						Close
					</Button>
				</div>
			</div>
		</div>
	) : null;
}

export default LimitReachedPopup;

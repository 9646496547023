import React, { useEffect, useState } from 'react'
import CardContainer from '../../../components/common-components/Cards/Container/CardContainer'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import CustomEvent from '../../Scheduling/SchedulingCalendar/CustomEvents';
import Icons from '../../../components/icons/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { getUpcommingAppointments } from '../DashboardSaga';
import { componentKey } from '../DashboardSlice';
import { componentKey as routerComponentKey } from '../../../routes/RoutesSlice';

function UpcommingAppointment() {
    const localizer = momentLocalizer(moment);

    const { calenderviewUpcommingData } = useSelector(state => state[componentKey])
    const { loggedInUser } = useSelector(state => state[routerComponentKey])
    const dispatch = useDispatch()

    const { schedule } = calenderviewUpcommingData || {}

    useEffect(() => {
        const currentDate = moment().format('YYYY-MM-DD');
        const dateTo = currentDate
        const dateFrom = currentDate
        const params = {
            dateFrom: dateFrom,
            dateTo: dateTo,
            providerId: loggedInUser?.uuid,
            responseType: 'calender',

        };
        dispatch(getUpcommingAppointments({ params }))
    }, [dispatch, loggedInUser])

    const events = schedule?.map(item => ({
        providerName:`${item?.Provider?.firstName} ${item?.Provider?.lastName}`,
        title: `${item.Patient.firstName}  ${item.Patient.lastName} (${item.Patient.gender}) `,
        date: item?.date,
        start: new Date(moment(item?.date).format('ddd MMM DD YYYY') + ' ' + moment(item?.start, 'hh:mm a').format('HH:mm:ss [GMT]ZZ')),
        end: new Date(moment(item?.date).format('ddd MMM DD YYYY') + ' ' + moment(item?.end, 'hh:mm a').format('HH:mm:ss [GMT]ZZ')),
        status: item?.status,
        style: {
            backgroundColor: '#F3F4F6',
            color: '#4873B9',
            border: 'none',
            columnGap: '20px',
        },
    }));

    const handleEventClick = (event) => {
        console.log('handleEventClick--', event);
    };

    const DashBoardToolBar = ({ date }) => {
        const formattedDate = moment(date).format('DD  MMMM   YYYY');
        return (
            <div className='mb-2'>
                <span className='ml-6 text-gray-500 font-bold'>IST</span>
                <span className='ml-[50px] text-gray-500 font-bold'>{formattedDate}</span>
            </div>
        );
    };

    return (

        <CardContainer customCssClass=''>
            <div className='flex justify-between items-center'>
                <div className='flex items-center column-gap-10px'>
                    <div className='font-bold text-gray-800  fs-18'>Upcoming Appointments</div>
                    <span className='rounded-full bg-met-primary text-white font-semibold p-1 w-8 h-8 flex items-center justify-center'>{schedule?.length}</span>
                </div>
                <div><Icons iconName="calenderIcon" /></div>
            </div>
            <div className='pt-2 met-scrollbar max-h-[575px] overflow-y-auto'>
                <Calendar
                    date={moment()}
                    onNavigate={() => { }}
                    timeslots={1}
                    step={15}
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    eventPropGetter={(event) => ({
                        style: event.style,
                    })}
                    components={{
                        toolbar: (props) => <DashBoardToolBar {...props} localizer={localizer} />,
                        event: (props) => <CustomEvent {...props} onEventClick={handleEventClick} />,
                    }}
                    view={"week"}
                    onView={() => { }}
                />
            </div>


        </CardContainer>

    )
}

export default UpcommingAppointment
import React from 'react';
const CalenderWithWatchIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="197.895" height="179.828" viewBox="0 0 197.895 179.828">
			<g id="appointment_3_" data-name="appointment(3)" transform="translate(0 -23.371)">
				<g id="Group_96518" data-name="Group 96518" transform="translate(109.412 106.385)">
					<g id="Group_96517" data-name="Group 96517">
						<path
							id="Path_207145"
							data-name="Path 207145"
							d="M327.646,238.147v13.5l.342.048a35.1,35.1,0,1,1-26.682,62.312l-.109-.086H283.076a48.509,48.509,0,1,0,44.57-75.773Z"
							transform="translate(-283.076 -238.147)"
							fill="rgba(20,121,255,0.5)"
						/>
					</g>
				</g>
				<g id="Group_96586" data-name="Group 96586" transform="translate(0 23.371)">
					<g id="Group_96523" data-name="Group 96523" transform="translate(0 38.237)">
						<g id="Group_96522" data-name="Group 96522">
							<path
								id="Path_207147"
								data-name="Path 207147"
								d="M114.278,215.373a35.1,35.1,0,0,1,40.1-34.741V122.3H0V237.275a5.973,5.973,0,0,0,5.973,5.973h122.1a35.063,35.063,0,0,1-13.8-27.875Z"
								transform="translate(0 -122.299)"
								fill="#f9f3f1"
							/>
						</g>
					</g>
					<g id="Group_96526" data-name="Group 96526" transform="translate(137.856 38.237)">
						<g id="Group_96525" data-name="Group 96525">
							<g id="Group_96524" data-name="Group 96524">
								<path
									id="Path_207148"
									data-name="Path 207148"
									d="M356.666,122.3v59.919a35.1,35.1,0,0,1,16.524-1.586V122.3Z"
									transform="translate(-356.666 -122.299)"
									fill="#f3eae6"
								/>
							</g>
						</g>
					</g>
					<g id="Group_96541" data-name="Group 96541" transform="translate(19.774 55.788)">
						<g id="Group_96528" data-name="Group 96528">
							<g id="Group_96527" data-name="Group 96527">
								<path
									id="Path_207149"
									data-name="Path 207149"
									d="M69.024,186.768H52.354a1.2,1.2,0,0,1-1.195-1.195V168.9a1.2,1.2,0,0,1,1.195-1.195h16.67a1.2,1.2,0,0,1,1.195,1.195v16.67A1.2,1.2,0,0,1,69.024,186.768Z"
									transform="translate(-51.159 -167.708)"
									fill="rgba(20,121,255,0.5)"
								/>
							</g>
						</g>
						<g id="Group_96530" data-name="Group 96530" transform="translate(31.924)">
							<g id="Group_96529" data-name="Group 96529">
								<path
									id="Path_207150"
									data-name="Path 207150"
									d="M151.62,186.768H134.95a1.2,1.2,0,0,1-1.195-1.195V168.9a1.2,1.2,0,0,1,1.195-1.195h16.67a1.2,1.2,0,0,1,1.195,1.195v16.67A1.2,1.2,0,0,1,151.62,186.768Z"
									transform="translate(-133.755 -167.708)"
									fill="rgba(20,121,255,0.5)"
								/>
							</g>
						</g>
						<g id="Group_96532" data-name="Group 96532" transform="translate(41.692 0)">
							<g id="Group_96531" data-name="Group 96531">
								<path
									id="Path_207151"
									data-name="Path 207151"
									d="M167.123,167.709h-8.1a1.2,1.2,0,0,1,1.195,1.195v16.67a1.2,1.2,0,0,1-1.195,1.195h8.1a1.2,1.2,0,0,0,1.195-1.195V168.9A1.2,1.2,0,0,0,167.123,167.709Z"
									transform="translate(-159.025 -167.709)"
									fill="rgba(19,120,253,0.85)"
								/>
							</g>
						</g>
						<g id="Group_96534" data-name="Group 96534" transform="translate(63.849)">
							<g id="Group_96533" data-name="Group 96533">
								<path
									id="Path_207152"
									data-name="Path 207152"
									d="M234.216,186.768h-16.67a1.2,1.2,0,0,1-1.195-1.195V168.9a1.2,1.2,0,0,1,1.195-1.195h16.67a1.2,1.2,0,0,1,1.195,1.195v16.67A1.2,1.2,0,0,1,234.216,186.768Z"
									transform="translate(-216.351 -167.708)"
									fill="rgba(20,121,255,0.5)"
								/>
							</g>
						</g>
						<g id="Group_96536" data-name="Group 96536" transform="translate(73.546 0)">
							<g id="Group_96535" data-name="Group 96535">
								<path
									id="Path_207153"
									data-name="Path 207153"
									d="M249.608,167.709H241.44a1.2,1.2,0,0,1,1.195,1.195v16.67a1.2,1.2,0,0,1-1.195,1.195h8.168a1.2,1.2,0,0,0,1.195-1.195V168.9A1.2,1.2,0,0,0,249.608,167.709Z"
									transform="translate(-241.44 -167.709)"
									fill="rgba(19,120,253,0.85)"
								/>
							</g>
						</g>
						<g id="Group_96538" data-name="Group 96538" transform="translate(95.773)">
							<g id="Group_96537" data-name="Group 96537">
								<path
									id="Path_207154"
									data-name="Path 207154"
									d="M316.812,186.768h-16.67a1.2,1.2,0,0,1-1.195-1.195V168.9a1.2,1.2,0,0,1,1.195-1.195h16.67a1.2,1.2,0,0,1,1.195,1.195v16.67A1.2,1.2,0,0,1,316.812,186.768Z"
									transform="translate(-298.947 -167.708)"
									fill="rgba(27,89,132,0.5)"
								/>
							</g>
						</g>
						<g id="Group_96540" data-name="Group 96540" transform="translate(105.4 0)">
							<g id="Group_96539" data-name="Group 96539">
								<path
									id="Path_207155"
									data-name="Path 207155"
									d="M332.092,167.709h-8.238a1.2,1.2,0,0,1,1.195,1.195v16.67a1.2,1.2,0,0,1-1.195,1.195h8.238a1.2,1.2,0,0,0,1.195-1.195V168.9A1.2,1.2,0,0,0,332.092,167.709Z"
									transform="translate(-323.854 -167.709)"
									fill="#1b5984"
								/>
							</g>
						</g>
					</g>
					<g id="Group_96543" data-name="Group 96543" transform="translate(19.774 88.665)">
						<g id="Group_96542" data-name="Group 96542">
							<path
								id="Path_207156"
								data-name="Path 207156"
								d="M69.024,271.829H52.354a1.2,1.2,0,0,1-1.195-1.195v-16.67a1.2,1.2,0,0,1,1.195-1.195h16.67a1.2,1.2,0,0,1,1.195,1.195v16.67A1.2,1.2,0,0,1,69.024,271.829Z"
								transform="translate(-51.159 -252.769)"
								fill="rgba(20,121,255,0.5)"
							/>
						</g>
					</g>
					<g id="Group_96545" data-name="Group 96545" transform="translate(51.698 88.665)">
						<g id="Group_96544" data-name="Group 96544">
							<path
								id="Path_207157"
								data-name="Path 207157"
								d="M151.62,271.829H134.95a1.2,1.2,0,0,1-1.195-1.195v-16.67a1.2,1.2,0,0,1,1.195-1.195h16.67a1.2,1.2,0,0,1,1.195,1.195v16.67A1.2,1.2,0,0,1,151.62,271.829Z"
								transform="translate(-133.755 -252.769)"
								fill="rgba(20,121,255,0.5)"
							/>
						</g>
					</g>
					<g id="Group_96547" data-name="Group 96547" transform="translate(61.465 88.666)">
						<g id="Group_96546" data-name="Group 96546">
							<path
								id="Path_207158"
								data-name="Path 207158"
								d="M167.123,252.77h-8.1a1.2,1.2,0,0,1,1.195,1.195v16.67a1.2,1.2,0,0,1-1.195,1.195h8.1a1.2,1.2,0,0,0,1.195-1.195v-16.67a1.2,1.2,0,0,0-1.195-1.195Z"
								transform="translate(-159.025 -252.77)"
								fill="rgba(19,120,253,0.85)"
							/>
						</g>
					</g>
					<g id="Group_96549" data-name="Group 96549" transform="translate(29.541 55.788)">
						<g id="Group_96548" data-name="Group 96548">
							<path
								id="Path_207159"
								data-name="Path 207159"
								d="M84.527,167.709h-8.1a1.2,1.2,0,0,1,1.195,1.195v16.67a1.2,1.2,0,0,1-1.195,1.195h8.1a1.2,1.2,0,0,0,1.195-1.195V168.9A1.2,1.2,0,0,0,84.527,167.709Z"
								transform="translate(-76.429 -167.709)"
								fill="rgba(19,120,253,0.85)"
							/>
						</g>
					</g>
					<g id="Group_96551" data-name="Group 96551" transform="translate(29.541 88.666)">
						<g id="Group_96550" data-name="Group 96550">
							<path
								id="Path_207160"
								data-name="Path 207160"
								d="M84.527,252.77h-8.1a1.2,1.2,0,0,1,1.195,1.195v16.67a1.2,1.2,0,0,1-1.195,1.195h8.1a1.2,1.2,0,0,0,1.195-1.195v-16.67a1.2,1.2,0,0,0-1.195-1.195Z"
								transform="translate(-76.429 -252.77)"
								fill="rgba(19,120,253,0.85)"
							/>
						</g>
					</g>
					<g id="Group_96553" data-name="Group 96553" transform="translate(83.622 88.665)">
						<g id="Group_96552" data-name="Group 96552">
							<path
								id="Path_207161"
								data-name="Path 207161"
								d="M234.216,271.829h-16.67a1.2,1.2,0,0,1-1.195-1.195v-16.67a1.2,1.2,0,0,1,1.195-1.195h16.67a1.2,1.2,0,0,1,1.195,1.195v16.67A1.2,1.2,0,0,1,234.216,271.829Z"
								transform="translate(-216.351 -252.769)"
								fill="rgba(20,121,255,0.5)"
							/>
						</g>
					</g>
					<g id="Group_96555" data-name="Group 96555" transform="translate(93.32 88.666)">
						<g id="Group_96554" data-name="Group 96554">
							<path
								id="Path_207162"
								data-name="Path 207162"
								d="M249.608,252.77H241.44a1.2,1.2,0,0,1,1.195,1.195v16.67a1.2,1.2,0,0,1-1.195,1.195h8.168a1.2,1.2,0,0,0,1.195-1.195v-16.67a1.2,1.2,0,0,0-1.195-1.195Z"
								transform="translate(-241.44 -252.77)"
								fill="rgba(19,120,253,0.85)"
							/>
						</g>
					</g>
					<g id="Group_96557" data-name="Group 96557" transform="translate(115.546 88.666)">
						<g id="Group_96556" data-name="Group 96556">
							<path
								id="Path_207163"
								data-name="Path 207163"
								d="M300.141,271.829H306.8a35.285,35.285,0,0,1,11.2-8.25v-9.615a1.2,1.2,0,0,0-1.195-1.195h-16.67a1.2,1.2,0,0,0-1.195,1.195v16.67A1.2,1.2,0,0,0,300.141,271.829Z"
								transform="translate(-298.946 -252.77)"
								fill="rgba(27,89,132,0.5)"
							/>
						</g>
					</g>
					<g id="Group_96560" data-name="Group 96560" transform="translate(125.174 88.666)">
						<g id="Group_96559" data-name="Group 96559">
							<g id="Group_96558" data-name="Group 96558">
								<path
									id="Path_207164"
									data-name="Path 207164"
									d="M332.092,252.77h-8.238a1.2,1.2,0,0,1,1.195,1.195v14.979a35.212,35.212,0,0,1,8.238-5.364v-9.615A1.2,1.2,0,0,0,332.092,252.77Z"
									transform="translate(-323.854 -252.77)"
									fill="#1b5984"
								/>
							</g>
						</g>
					</g>
					<g id="Group_96569" data-name="Group 96569" transform="translate(51.698 121.542)">
						<g id="Group_96562" data-name="Group 96562">
							<g id="Group_96561" data-name="Group 96561">
								<path
									id="Path_207165"
									data-name="Path 207165"
									d="M151.62,356.89H134.95a1.2,1.2,0,0,1-1.195-1.195v-16.67a1.2,1.2,0,0,1,1.195-1.195h16.67a1.2,1.2,0,0,1,1.195,1.195V355.7A1.2,1.2,0,0,1,151.62,356.89Z"
									transform="translate(-133.755 -337.83)"
									fill="rgba(20,121,255,0.5)"
								/>
							</g>
						</g>
						<g id="Group_96564" data-name="Group 96564" transform="translate(9.767 0)">
							<g id="Group_96563" data-name="Group 96563">
								<path
									id="Path_207166"
									data-name="Path 207166"
									d="M167.123,337.831h-8.1a1.2,1.2,0,0,1,1.195,1.195V355.7a1.2,1.2,0,0,1-1.195,1.195h8.1a1.2,1.2,0,0,0,1.195-1.195v-16.67A1.2,1.2,0,0,0,167.123,337.831Z"
									transform="translate(-159.025 -337.831)"
									fill="rgba(19,120,253,0.85)"
								/>
							</g>
						</g>
						<g id="Group_96566" data-name="Group 96566" transform="translate(31.924)">
							<g id="Group_96565" data-name="Group 96565">
								<path
									id="Path_207167"
									data-name="Path 207167"
									d="M234.216,356.89h-16.67a1.2,1.2,0,0,1-1.195-1.195v-16.67a1.2,1.2,0,0,1,1.195-1.195h16.67a1.2,1.2,0,0,1,1.195,1.195V355.7A1.2,1.2,0,0,1,234.216,356.89Z"
									transform="translate(-216.351 -337.83)"
									fill="rgba(20,121,255,0.5)"
								/>
							</g>
						</g>
						<g id="Group_96568" data-name="Group 96568" transform="translate(41.622 0)">
							<g id="Group_96567" data-name="Group 96567">
								<path
									id="Path_207168"
									data-name="Path 207168"
									d="M249.608,337.831H241.44a1.2,1.2,0,0,1,1.195,1.195V355.7a1.2,1.2,0,0,1-1.195,1.195h8.168A1.2,1.2,0,0,0,250.8,355.7v-16.67A1.2,1.2,0,0,0,249.608,337.831Z"
									transform="translate(-241.44 -337.831)"
									fill="rgba(19,120,253,0.85)"
								/>
							</g>
						</g>
					</g>
					<g id="Group_96576" data-name="Group 96576" transform="translate(113.879 95.813)">
						<g id="Group_96571" data-name="Group 96571">
							<g id="Group_96570" data-name="Group 96570">
								<circle
									id="Ellipse_1387"
									data-name="Ellipse 1387"
									cx="35.498"
									cy="35.498"
									r="35.498"
									fill="#fff"
								/>
							</g>
						</g>
						<g id="Group_96573" data-name="Group 96573" transform="translate(29.227 0)">
							<g id="Group_96572" data-name="Group 96572">
								<path
									id="Path_207169"
									data-name="Path 207169"
									d="M376.52,271.262a35.709,35.709,0,0,0-6.272.552,35.5,35.5,0,0,1,0,69.891,35.5,35.5,0,1,0,6.272-70.443Z"
									transform="translate(-370.248 -271.262)"
									fill="#f9f3f1"
								/>
							</g>
						</g>
						<g id="Group_96575" data-name="Group 96575" transform="translate(32.6 10.919)">
							<g id="Group_96574" data-name="Group 96574">
								<path
									id="Path_207170"
									data-name="Path 207170"
									d="M398.312,326.99H381.874a2.9,2.9,0,0,1-2.9-2.9v-21.68a2.9,2.9,0,1,1,5.8,0v18.781h13.54a2.9,2.9,0,1,1,0,5.8Z"
									transform="translate(-378.975 -299.512)"
									fill="#685e68"
								/>
							</g>
						</g>
					</g>
					<g id="Group_96578" data-name="Group 96578" transform="translate(0 10.046)">
						<g id="Group_96577" data-name="Group 96577">
							<path
								id="Path_207171"
								data-name="Path 207171"
								d="M154.38,77.951H0V55.335a5.973,5.973,0,0,1,5.973-5.973H148.408a5.973,5.973,0,0,1,5.973,5.973V77.951Z"
								transform="translate(0 -49.362)"
								fill="#1b5984"
							/>
						</g>
					</g>
					<path
						id="Path_207172"
						data-name="Path 207172"
						d="M366.661,49.363h-10.9v3.971a5.952,5.952,0,0,1,.351,2V77.952h16.524V55.335A5.972,5.972,0,0,0,366.661,49.363Z"
						transform="translate(-218.253 -39.317)"
						fill="#123a55"
					/>
					<g id="Group_96583" data-name="Group 96583" transform="translate(16.875)">
						<g id="Group_96580" data-name="Group 96580">
							<g id="Group_96579" data-name="Group 96579">
								<path
									id="Path_207173"
									data-name="Path 207173"
									d="M46.559,45.846a2.9,2.9,0,0,1-2.9-2.9V26.27a2.9,2.9,0,0,1,5.8,0V42.947A2.9,2.9,0,0,1,46.559,45.846Z"
									transform="translate(-43.66 -23.371)"
									fill="#6bd9e7"
								/>
							</g>
						</g>
						<g id="Group_96582" data-name="Group 96582" transform="translate(114.832)">
							<g id="Group_96581" data-name="Group 96581">
								<path
									id="Path_207174"
									data-name="Path 207174"
									d="M343.657,45.846a2.9,2.9,0,0,1-2.9-2.9V26.27a2.9,2.9,0,0,1,5.8,0V42.947A2.9,2.9,0,0,1,343.657,45.846Z"
									transform="translate(-340.758 -23.371)"
									fill="#6bd9e7"
								/>
							</g>
						</g>
					</g>
					<g id="Group_96585" data-name="Group 96585" transform="translate(16.875 119.154)">
						<g id="Group_96584" data-name="Group 96584">
							<path
								id="Path_207175"
								data-name="Path 207175"
								d="M55.146,356a2.9,2.9,0,0,1-2.239-1.058L44.319,344.49a2.9,2.9,0,0,1,4.479-3.681l5.959,7.25,8.853-14.984a2.9,2.9,0,1,1,4.991,2.949L57.642,354.573a2.9,2.9,0,0,1-2.272,1.416C55.3,355.995,55.221,356,55.146,356Z"
								transform="translate(-43.66 -331.65)"
								fill="#0ed290"
							/>
						</g>
					</g>
				</g>
				<g id="Group_96588" data-name="Group 96588" transform="translate(110.981 116.285)">
					<g id="Group_96587" data-name="Group 96587">
						<path
							id="Path_207176"
							data-name="Path 207176"
							d="M325.53,340.556a38.4,38.4,0,1,1,38.4-38.4A38.44,38.44,0,0,1,325.53,340.556Zm0-71a32.6,32.6,0,1,0,32.6,32.6A32.636,32.636,0,0,0,325.53,269.56Z"
							transform="translate(-287.133 -263.762)"
							fill="#1b5984"
						/>
					</g>
				</g>
				<g id="Group_96589" data-name="Group 96589" transform="translate(34.473 45.38)">
					<path
						id="Path_207177"
						data-name="Path 207177"
						d="M171.726,86.112H92.09a2.9,2.9,0,1,1,0-5.8h79.636a2.9,2.9,0,0,1,0,5.8Z"
						transform="translate(-89.191 -80.314)"
						fill="#fff"
					/>
				</g>
			</g>
		</svg>
	);
};
export default CalenderWithWatchIcon;

import { all, put, takeLatest, call } from 'redux-saga/effects'
import store from "../../../../../store/store"
import { componentKey, setIsOpenDeletePopup, setIsOpenPrintConfigModal, setPrintConfigDetails, setUpdatePrintConfigDetails } from './PrintConfigSlice'
import PrintConfigurationsDataService from '../../../../../services/PrintConfigurationsDataService'
import { addNotifications } from '../../../../../components/common-components/toaster/ToasterSlice'
import { TOASTER_VARIANT } from '../../../../../components/common-components/toaster/Constants'


export const { postPrintConfig, updatePrintConfig, deletePrintConfig, getPrintConfig } = {
    postPrintConfig: (payload) => {
        return {
            type: 'USER_PROFILE/SAVE_PRINT_CONFIG',
            payload
        }
    },
    updatePrintConfig: (payload) => {
        return {
            type: 'USER_PROFILE/UPDATE_PRINT_CONFIG',
            payload
        }
    },
    deletePrintConfig: (payload) => {
        return {
            type: 'USER_PROFILE/DELETE_PRINT_CONFIG',
            payload
        }
    },
    getPrintConfig: (payload) => {
        return {
            type: 'USER_PROFILE/GET_PRINT_CONFIG',
            payload
        }
    }
}

function* postPrintConfigAsync(action) {
    try {
        const { data: payload } = action.payload
        const response = yield PrintConfigurationsDataService.postPrintConfigDetails(payload)
        const { status, message } = response.data

        if (status) {
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
            yield put(setIsOpenPrintConfigModal(false))
            yield call(getPrintConfigDetailsAsync, getPrintConfig());
        }

    } catch (error) {
        console.log('err: ', error)
    }
}

function* updatePrintConfigDetailsAsync(action) {
    try {
        const { uuid, values } = action.payload;
        const response = yield PrintConfigurationsDataService.updatePrintConfigDetails(uuid, values)
        const { status, message } = response.data
        if (status) {
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
            yield put(setUpdatePrintConfigDetails(response));
            yield put(setIsOpenPrintConfigModal(false))
            yield call(getPrintConfigDetailsAsync, getPrintConfig());
        }

    } catch (error) {
        console.log('err: ', error)
    }
}
function* deletePrintConfigDetailsAsync(action) {
    try {
        const { uuid } = action.payload;
        const responce = yield PrintConfigurationsDataService.deletePrintConfigDetails(uuid);
        const { status, message } = responce.data
        if (status) {
            yield put(setIsOpenDeletePopup(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
            yield call(getPrintConfigDetailsAsync, getPrintConfig())
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* getPrintConfigDetailsAsync(action) {
    try {
        yield put(setPrintConfigDetails([]));
        const response = yield PrintConfigurationsDataService.getPrintConfigDetails(action.payload)
        const { data, status } = response.data
        if (status) {
            yield put(setPrintConfigDetails(data))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* rootSaga() {
    yield all([
        takeLatest(postPrintConfig().type, postPrintConfigAsync),
        takeLatest(updatePrintConfig().type, updatePrintConfigDetailsAsync),
        takeLatest(deletePrintConfig().type, deletePrintConfigDetailsAsync),
        takeLatest(getPrintConfig().type, getPrintConfigDetailsAsync),

    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
import AppDataService from './AppDataService'

const COMMON_BASE = 'insurance'

export default class InsuranceDataService {

    static async saveInsuranceDetails(data) {
        return await AppDataService.post(`${COMMON_BASE}`, data)
    }
    static async updateInsuranceDetails(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}`, data);
    }
    static async getInsuranceDetails(uuid) {
        return await AppDataService.get(`${COMMON_BASE}/${uuid}`)
    }

    static async toggleInsuranceActivation(insuranceId) {
        return await AppDataService.patch(`${COMMON_BASE}/${insuranceId}/active`,{});
    }

}
import store from "../../../../../store/store";

export const componentKey = 'PRINT_CONFIGURATION'

const initialState = {
    printConfigDetails: [],
    isOpenPrintConfigModal: false,
    updatePrintConfigDetails: false,
    printConfig: {},
    isOpenDeletePopup: false

};

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setPrintConfigDetails: (state, action) => {
            state.printConfigDetails = action.payload;
        },
        setIsOpenPrintConfigModal: (state, action) => {
            state.isOpenPrintConfigModal = action.payload;
        },
        setUpdatePrintConfigDetails: (state, action) => {
            state.updatePrintConfigDetails = action.payload;
        },
        setPrintConfig: (state, action) => {
            state.printConfig = action.payload;
        },
        setIsOpenDeletePopup: (state, action) => {
            state.isOpenDeletePopup = action.payload
        }

    },
    initialReducerState: initialState

})

export const { setPrintConfigDetails, setIsOpenPrintConfigModal, setUpdatePrintConfigDetails, setPrintConfig, setIsOpenDeletePopup } = actions
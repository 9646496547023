import React from 'react'

function LabIcon({ color = "gray" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_88420" data-name="Group 88420" transform="translate(-16634 7599)">
        <g id="Group_88400" data-name="Group 88400" transform="translate(-76 173)">
          <g id="Group_88394" data-name="Group 88394" transform="translate(-12 213)">
            <rect id="Rectangle_36164" data-name="Rectangle 36164" width="24" height="24" transform="translate(16722 -7985)" fill="rgba(255,255,255,0.5)" opacity="0" />
            <g id="noun-lab-4023310" transform="translate(16680 -8006.904)">
              <path id="Path_205706" data-name="Path 205706" d="M60.559,39.951H58.285a12.521,12.521,0,0,0,.794-1.054,3.616,3.616,0,0,0-.689-4.748l-1.48-1.257L59.8,30.011a.451.451,0,0,0,0-.639l-.194-.194.784-.782.361.361a.217.217,0,0,0,.307-.306l-.515-.514h0L59.1,26.487h0l-.513-.514a.217.217,0,1,0-.307.306l.36.361-.784.782-.192-.2a.451.451,0,0,0-.32-.133h0a.448.448,0,0,0-.321.133l-3.131,3.124a1.154,1.154,0,0,0-1.581,1.577l-2.849,2.842a.451.451,0,0,0,0,.64l.12.12-.747.745a.217.217,0,0,0,0,.307l1.592,1.6a.216.216,0,0,0,.153.064h0a.217.217,0,0,0,.153-.064l.746-.747.12.12a.448.448,0,0,0,.32.133h0a.449.449,0,0,0,.32-.132l3.032-3.025,1.626,1.38a1.307,1.307,0,0,1,.257,1.714,9.45,9.45,0,0,1-2.181,2.328H47.334a.654.654,0,0,0-.654.653v.616a.655.655,0,0,0,.652.655H60.559a.655.655,0,0,0,.654-.654V40.6A.654.654,0,0,0,60.559,39.951Zm-9.983-2.234-1.284-1.288.592-.593,1.284,1.288Zm8.367-10.769,1.139,1.141-.784.782L58.16,27.729Zm-1.619.588a.02.02,0,0,1,.026,0l1.723,1.727.069.069h0l.347.347a.018.018,0,0,1,0,.026L56.577,32.61l-2.344-1.99Zm-5.4,9.712a.019.019,0,0,1-.026,0L49.765,35.1a.018.018,0,0,1,0-.026l2.827-2.82,2.345,1.99Zm5.584.616a1.741,1.741,0,0,0-.338-2.284L52.839,31.9a.7.7,0,0,1-.08-.081l0-.006a.72.72,0,0,1,.493-1.18.712.712,0,0,1,.526.166l4.335,3.681a3.183,3.183,0,0,1,.608,4.179,12.838,12.838,0,0,1-1,1.293H55.7A11.632,11.632,0,0,0,57.513,37.862Zm3.266,3.358a.22.22,0,0,1-.22.22H47.332a.22.22,0,0,1-.22-.22V40.6a.22.22,0,0,1,.22-.219H60.559a.22.22,0,0,1,.22.219Z" transform="translate(0 0)" fill={color} stroke={color} strokeWidth="0.3" />
              <path id="Path_205707" data-name="Path 205707" d="M123.107,51.963h1.979l2.1,2.1a.217.217,0,0,0,.307-.307l-2.166-2.166a.218.218,0,0,0-.153-.064h-2.069a.217.217,0,0,0,0,.434Z" transform="translate(-73.565 -24.736)" fill={color} stroke={color} strokeWidth="0.3" />
              <path id="Path_205708" data-name="Path 205708" d="M123.107,83.174h1.438a.217.217,0,1,0,0-.434h-1.438a.217.217,0,0,0,0,.434Z" transform="translate(-73.565 -54.863)" fill={color} stroke={color} strokeWidth="0.3" />
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}

export default LabIcon
import React, { useEffect, useState } from 'react';
import Table from '../../../../Table/Index';
import Label from '../../../../../components/common-components/label/Label';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import Icons from '../../../../../components/icons/Icons';
import { useDispatch, useSelector } from 'react-redux';
import {
	componentKey as RolesAndRespKey,
	setCurrentUUID,
	setDeleteRoleModalOpen,
	setIsEditedRoleData,
	setIsNewAddRoleModalOpen,
	setIsNewRoleAdd,
	setIsRoleDuplicate,
	setIsViewRoleModalOpen,
	setPaginationState,
} from './RolesAndRespomsibilitiiesSlice';
import ButtonDropdown from '../../../../../components/common-components/buttonDropdown';
import { getAllRoles, getAllRolesAndResponsibilities } from './RolesAndResponsibilitiesSaga';
import DeleteRoleModal from './DeleteRole';
import ViewRoleAndRespModal from './ViewRoleAndRespModal';
const RolesAndResponsibilities = () => {
	const [showExportCSVSelection, setShowExportCSVSelection] = useState(false);
	const dispatch = useDispatch();
	const { paginationState, allRolesAndResp, roles, deleteRoleModalOpen, isViewRoleModalOpen } = useSelector(
		(state) => state[RolesAndRespKey]
	);

	const coloumns = [
		{
			field: 'pcp',
			label: <Label fontWeight='bold'>Role</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading
							onClickCb={() => {
								// navigate(row.uuid);
							}}
							fontWeight="bold"
							type={HEADING.H1}
							color="met-primary"
							fontSize={'sm'}
							customClasses="cursor-pointer"
						>
							{row?.name}
						</Heading>
					</>
				);
			},
		},
		{
			field: 'pcp',
			label: <Label fontWeight='bold'>Description</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row?.description}
						</Heading>
					</>
				);
			},
		},

		{
			field: 'action',
			label: <Label fontWeight='bold'>Action</Label>,
			renderLogic: (row) => {
				const handleView = (row) => {
					dispatch(setCurrentUUID(row?.uuid));
					dispatch(setIsViewRoleModalOpen(true));
				};

				const handleDuplicate = (row) => {
					dispatch(setCurrentUUID(row?.uuid));
					dispatch(setIsNewRoleAdd(false));
					dispatch(setIsNewAddRoleModalOpen(true));
					dispatch(setIsRoleDuplicate(true))
					dispatch(setIsEditedRoleData(row));
				};

				const handleEdit = (row) => {
					dispatch(setCurrentUUID(row?.uuid));
					dispatch(setIsNewAddRoleModalOpen(true));
					dispatch(setIsNewRoleAdd(false));
					dispatch(setIsEditedRoleData(row));
				};

				const handleDelete = (row) => {
					dispatch(setCurrentUUID(row?.uuid));
					dispatch(setDeleteRoleModalOpen(true));
				};
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.action}
								</Heading>
							</div>
							<ButtonDropdown
								customBtnClass="p-0"
								endIcon="moreVertIcon"
								options={[
									{ label: 'View', value: 'view' },
									...(row?.name == 'Provider' || row?.name == 'Provider Admin' ? [{ label: 'Duplicate', value: 'duplicate' } ]: []),
									...(row?.name == 'Provider' || row?.name == 'Provider Admin' ? [{ label: 'Edit', value: 'Edit' }] : []),
									{ label: 'Delete', value: 'Delete' },
								]}
								selectCb={(option) => {
									switch (option.value) {
										case 'view':
											handleView(row);
											break;
										case 'duplicate':
											handleDuplicate(row);
											break;
										case 'Edit':
											handleEdit(row);
											break;
										case 'Delete':
											handleDelete(row);
											break;
										default:
											break;
									}
								}}
							/>
						</div>
					</>
				);
			},
		},
	];
	useEffect(() => {
		dispatch(getAllRolesAndResponsibilities());
		dispatch(getAllRoles());
	}, [dispatch]);
	return (
		<div>
			<div className="mt-[10px] overflow-y-scroll met-scrollbar" style={{ height: '35vh' }}>
				<Table
					selectProps={{
						isSelectAll: showExportCSVSelection,
						onSelectAll: () => { },
						isSelectable: showExportCSVSelection,
						onSelectRowsCb: () => { },
						selectIdentifier: 'name',
					}}
					coloumns={coloumns}
					rows={roles}
					paginationProps={{
						isPagination: true,
						totalCount: paginationState.totalRecords,
						limit: paginationState.limit,
						onPageChange: (page) => {
							dispatch(setPaginationState({ pageNumber: page }));
							dispatch(getAllRoles());
						},
					}}
					sorting={{
						isSortable: true,
						onSortChangeCb: (sortKey) => {
							dispatch(
								setPaginationState({ orderBy: paginationState.orderBy === 'asc' ? 'desc' : 'asc' })
							);
							dispatch(setPaginationState({ sortBy: sortKey }));
						},
					}}
				/>
			</div>

			{deleteRoleModalOpen && (
				<DeleteRoleModal open={deleteRoleModalOpen} close={() => dispatch(setDeleteRoleModalOpen(false))} />
			)}
			{isViewRoleModalOpen && (
				<ViewRoleAndRespModal
					open={isViewRoleModalOpen}
					close={() => dispatch(setIsViewRoleModalOpen(false))}
				/>
			)}
		</div>
	);
};
export default RolesAndResponsibilities;

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Card from './Card';
import Icons from '../../../../components/icons/Icons';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import PopupWtBgColor from '../../../../components/common-components/popup-components/PopupWtBgColor';
import TextArea from '../../../../components/common-components/textArea/TextArea';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice'
import { componentKey as PatientNotesComponentKey, setIsNewNotesAdd, setIsNotesModalOpen } from "../Documents/PatientNotesSlice"
import { postPatientNotes } from '../Documents/PatientNotesSaga';

function PatientInfoCards() {
	const [isOpenNote, setIsOpenNote] = useState(false);
	const [isEmptyNoteOpen, setIsEmptyNoteOpen] = useState(false);
	const [notesData, setNotesData] = useState('')
	const dispatch = useDispatch();

	const { currentPatientId, patientData } = useSelector((state) => state[PatientDashboardComponentKey]);
	const { isNotesModalOpen, allNotesData, isNewNotesAdd } = useSelector(
		(state) => state[PatientNotesComponentKey]
	);

	const handleNotesData = () => {
		dispatch(setIsNewNotesAdd(false))
		dispatch(postPatientNotes({ notesData: { note: notesData, patientId: currentPatientId } }));
		setNotesData('')
	}

	return (
		<>
			<div className="flex column-gap-10px row-gap-10 flex-1 min-w-[600px]">
				<div className='w-1/3'>
					<Card title="PRIMARY PROVIDER">
						<div>
							<div className="text-xs text-met-dimmed-charcoal">{`${patientData?.provider?.firstName ?? ''} ${patientData?.provider?.lastName ?? ''}`}</div>
							<div className="fs-12 font-semibold pt-1">LAST VISIT DATE & TIME</div>
							<div className="text-xs pt-2 text-met-dimmed-charcoal">{patientData?.lastVisit ? `${moment(patientData?.lastVisit).format('D MMMM YYYY')},` : ''} {patientData?.lastVisit ? moment(patientData?.lastVisit).format('h:mm A') : '-'}</div>
						</div>
					</Card>
				</div>
				<div className='w-1/3 relative'>
					<Card
						title="NOTE"
						bgColor="met-orange-300"
						icon={<Icons iconName="plusVectorIcon" height={18} width={18} />}
						onClickCb={() => dispatch(setIsNewNotesAdd(true))}
					>
						<p className="fs-12 text-met-dimmed-charcoal">
							<span className='truncate'>
								{allNotesData[0] ? `${allNotesData[0]?.note.slice(0, 35)}...` : ''}
							</span>

							<Button
								variant={BUTTON_VARIANTS.TEXT}
								customBtnClass='text-met-primary p-0'
								onClickCb={() => dispatch(setIsNotesModalOpen(true))}
							>
								More
							</Button>
						</p>
						<div className="flex  justify-end">
							<div className="fs-10  text-met-dimmed-charcoal"> {`${allNotesData[0]?.notes_history[0]?.provider?.firstName ?? ''} ${allNotesData[0]?.notes_history[0]?.provider?.lastName ?? ''}`},
								{allNotesData[0]?.updatedAt ? `${moment(allNotesData[0]?.updatedAt).format('DD-MM-YYYY')},` : ''} {allNotesData[0]?.updatedAt ? `${moment(allNotesData[0]?.updatedAt).format('h:mm A')}` : ''}
							</div>
						</div>
					</Card>
					<PopupWtBgColor
						isOpen={isNotesModalOpen}
						heading="Note"
						closeProps={{ icon: <Icons iconName="plusVectorIcon" rotateDeg={45} height={18} width={18} />, onCloseCb: () => { dispatch(setIsNotesModalOpen(false)) } }}
					>
						<div className="fs-12">
							{allNotesData[0] ? allNotesData[0].note : ''}
						</div>
					</PopupWtBgColor>

					<PopupWtBgColor
						isOpen={isNewNotesAdd}
						heading="Note"
						closeProps={{ icon: <Icons iconName="plusVectorIcon" rotateDeg={45} height={18} width={18} />, onCloseCb: () => { dispatch(setIsNewNotesAdd(false)) } }}
						customClass="min-h-[200px] min-w-[377px]"
					>
						<TextArea rows={5} customClasses="bg-transparent" shadow={false} value={notesData}
							onChangeCb={(e) => {
								setNotesData(e.target.value);
							}} />
						<div className='flex justify-end'>
							<Button
								variant={BUTTON_VARIANTS.CONTAINED}
								customBtnClass="text-met-primary px-2 py-1 fs-14"
								type="submit"
								onClickCb={handleNotesData}
							>
								Save
							</Button>
						</div>
					</PopupWtBgColor>
				</div>
				{/* <div className='w-1/4'>
					<Card title="BALANCE" bgColor="yellow-50">
						<div className="flex flex-row">
							<div className="fs-12 font-semibold">Patient</div>
							<div className="fs-12 ml-4 text-met-dimmed-charcoal">$59</div>
						</div>
						<div style={{ marginTop: '12px' }} className="flex flex-row">
							<div className="fs-12 font-semibold">Insuance</div>
							<div className="fs-12 ml-4 text-met-dimmed-charcoal">$107</div>
						</div>
						<div className="flex  justify-end">
							<div className="fs-12  text-met-dimmed-charcoal">BlueLink TPA </div>
						</div>
					</Card>
				</div> */}
				{/* Not in this sprint */}
				{/* <div className='w-1/3'>
					<Card title="PATIENT PORTAL LAST LOG)">
						<div>
							<div className="text-xs text-met-dimmed-charcoal">24 March 2019, 12:35 PM</div>
						</div>
					</Card>
				</div> */}
			</div >
		</>
	);
}

export default PatientInfoCards;

import { useRef } from "react";
import Icons from "../../../../components/icons/Icons";
import Button from "../../../../components/common-components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { componentKey } from "./VitalsTestSlice";
import ModalComponent from "../../../../components/common-components/modal/ModalComponent";
import { REVIEW_VITAL_TEST_FORM_FIELDS_NAMES } from "./constant";
import { getValidationSchema } from "../../../../libs/formsUtils";
import { Form, Formik } from "formik";
import TextArea from "../../../../components/common-components/textArea/TextArea";
import { BUTTON_VARIANTS } from "../../../../components/common-components/button/Constants";
import { patchVitalsTest } from "./VitalsTestSaga";
import { useParams } from "react-router-dom";



const fields = [
    { fieldName: REVIEW_VITAL_TEST_FORM_FIELDS_NAMES.ADD_FEEDACK, isRequired: true },
];

const validationSchema = getValidationSchema(fields)

function ReviewReportModal({ open, close, viewTestsData }) {
    const dispatch = useDispatch();
    const { patientId } = useParams()

    const { paginationState } = useSelector((state) => state[componentKey])
    return (

        <Formik
            initialValues={{
                [REVIEW_VITAL_TEST_FORM_FIELDS_NAMES.ADD_FEEDACK]: viewTestsData?.review,
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(formVals) => {
                const body = {
                    review: formVals?.addFeedback,
                    uuid: viewTestsData?.uuid
                }
                try {
                    dispatch(
                        patchVitalsTest({
                            body,
                            patientId,
                            paginationState
                        })
                    );
                } catch (error) {
                    console.log(error);
                }
            }}
        >
            {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                return <Form onSubmit={handleSubmit} >

                    <ModalComponent
                        open={open}
                        title={"Add your feedback here"}
                        footerButton={<Button variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary" type='submit'>Save</Button>}
                        icon={<Icons iconName="closeIcon" />}
                        close={close}
                        customClasses="w-[594px]">
                        <div className='p-2'>
                            <div >
                                <div className='mt-2'>
                                    <TextArea
                                        name={REVIEW_VITAL_TEST_FORM_FIELDS_NAMES.ADD_FEEDACK}
                                        value={values[REVIEW_VITAL_TEST_FORM_FIELDS_NAMES.ADD_FEEDACK]}
                                        onChangeCb={(e) => { setFieldValue(REVIEW_VITAL_TEST_FORM_FIELDS_NAMES.ADD_FEEDACK, e.target.value) }} />
                                </div>
                            </div>
                        </div>
                    </ModalComponent>
                </Form>
            }}
        </Formik>

    )
}

export default ReviewReportModal;
export const encounterData = [{ feildName: 'Patient Name -', feildData: 'Henna West (73 yo,F) ID# 4430' },
{ feildName: 'Appointment Date & Time -', feildData: 'Mon, Jan 23 2023 12:05PM' },
{ feildName: 'DOB -', feildData: 'May 10, 1951' },
{ feildName: 'Service Department -', feildData: 'ThinkEMR' },
{ feildName: 'Provider Name -', feildData: 'Dr. Albert Melbourne' },
{ feildName: 'Insurane Name -', feildData: 'Blue Shield Association' }]

export const subjectiveData = ["How often do you smoke cigarettes? some days, but not every day ",
    "How many cigarettes a day do you smoke? 5 or less",
    " How soon after you wake up do you smoke your first cigarette? within 5 minutes",
    "Are you interested in quitting ? Thinking about quitting"

]

export const HPIdata = [
    {
        title: 'Past Medical History',
        subData: [{
            feildName: 'Posttraumatic stress Disorder',
            feildData: "- May 2022"
        }]

    },
    {
        title: 'Past Surgical History',
        subData: [
            {
                feildName: '1. Right Knee Arthroscopy ',
                feildData: "- July 10, 2015"

            },

        ]
    },
    {
        title: 'Family History',
        subData: [
            {
                feildName: '1. Mother',
                feildData: ' Diabetes'
            },
            {
                feildName: '2. Father',
                feildData: 'High Blood Pressure'
            }
        ]
    },
    {
        title: 'Social History',
        subData: [
            {
                feildName: '1. Financial Strain',
                feildData: '- Very Hard'
            }
        ]
    }

]

export const historyData = [
    {
        historyTitle: '2. Exposure to Violence-',
        historySubData: ["A. Within the last year, have you been humiliated or emotionally abused in other ways by your partner or ex-partner? Yes",
            "B. Within the last year, have you been afraid of your partner or ex-partner? - Yes",
            "C. Within the last year, have you been raped or forced to have any kind of sexual activity by your partner or ex-partner? - Yes",
            "D. Within the last year, have you been kicked, hit, slapped, or otherwise physically hurt by your partner or ex-partner? No",
        ]
    },
    {
        historyTitle: '3. Social Connection-',
        historySubData: ["A. Are you now married, widowed, divorced, separated, never married, or living with a partner? Married", "B. In a typical week, how many times do you talk on the telephone with family, friends, or neighbors? How often do you get together with friends or relatives? (Combine total interactions) Less than 3 interactions per week",
            "C. How often do you attend church or religious services? 4 or more times per year",
            "D. Do you belong to any clubs or organizations such as church groups, unions, fraternal or athletic groups, or school group? No"]
    },
    {
        historyTitle: '1. Alcohol use',
        historySubData: ["A. How often do you have a drink containing alcohol? Monthly or less",
            "B. How many standard drinks containing alcohol do you have on a typical day?- 1 or 2-",
            "How often do you have 6 or more drinks on 1 occasion? - Monthly"]
    }
];


export const medicationData = [
    {
        medicationDatatitle: 'Medications',
        medicationSubData: [{
            feildName: '1. Abelcet (Amphotericin Injection) -',
            feildData: "- 21 Jan 2022"
        },
        {
            feildName: '2. Abacavir Sulfate (Ziagen)',
            feildData: '- 10 Dec 2021'
        }]

    },
    {
        medicationDatatitle: 'Allergies',
        medicationSubData: [
            {
                feildName: '1. Asthma: Environment: Chest Pain: Mild Onset Date:',
                feildData: ' 07-11-2007'
            },
            {
                feildName: '2. Dust Allergy: Environment: sneezing, runny nose: Mild Onset Date: ',
                feildData: ' 16-04-2005'
            }
        ]
    }
]


export const reviewsystemData = [{
    feildName: 'GENERAL',
    feildData: 'Denies fever, chills weight loss or sleep abnormalities'
},
{ feildName: 'EYES', feildData: 'Denies visual abnormalities' },
{ feildName: 'Pa', feildData: 'Denies nasal discharge, sore throat, ear pain or headache' },
{ feildName: "HENT", feildData: 'No shortness of breath of wheezing', },
{ feildName: 'RESP', feildData: "Denies chest pain, palpitations, irregular heart beat or leg" }, { feildName: 'CVS', feildData: "Denies chest pain, palpitations, irregular heart beat or leg" }, { feildName: 'GI', feildData: "Denies chest pain, palpitations, irregular heart beat or leg" }, { feildName: 'GU', feildData: "Denies nausea, vomiting, diarrhea, abdominal pain" }, { feildName: 'MSS', feildData: "Denies dysuria, hesitancy or hematuria" }, { feildName: 'Cand Detai', feildData: "Denies weakness, nerve pain or numbness" }, { feildName: 'NS', feildData: "Denies weakness, nerve pain or numbness" }, { feildName: 'Profe', feildData: "Denies fever, chills weight loss or sleep abnormalities" },]


export const objectiveData = ["How often do you smoke cigarettes? some days, but not every day",
    "How many cigarettes a day do you smoke? 5 or less",
    "How soon after you wake up do you smoke your first cigarette? within 5 minutes",
    "Are you interested in quitting? Thinking about quitting"]
export const vitalsData = [{ feildName: '1. Blood Pressure-', feildData: " 120/80" }, { feildName: '2. Heart Rate-', feildData: "92" }, { feildName: '3. Oxygen Saturation-', feildData: "120/80" }, { feildName: '4. Weight - ', feildData: "180" }, { feildName: '5. Height - ', feildData: "6.2" }, { feildName: '6. Body Mass Index -', feildData: " 22.4" }, { feildName: '7. Temperature -', feildData: " 98.4" }]
export const assessmentData = ["Acute pharyngitis, likely viral etiology.", "Hypertension, controlled.", " Possible upper respiratory tract infection (URTI) with associated cough and fatigue"]

export const icdCodeData = [{ feildName: " Major depressive disorder, single episode, unspecified ", feildData: 'F32.9' },
{ feildName: 'Depression ', feildData: 'F32.0' }]

export const SymptomaticData = ["Rest, fluids, over-the-counter pain relievers (acetaminophen or ibuprofen) as needed.", " Throat lozenges or warm salt water gargles for throat pain."]

export const planData = [
    {
        planTitle: 'CPT CODE',
        planSubData: [
            { fieldName: 'Psychotherapy, 45 minutes (extended individual therapy) ', fieldData: '90834' },
            { fieldName: '. Psychological testing by a computer ', fieldData: '96103' }
        ]
    },
    {
        planTitle: 'LAB TEST',
        planSubData: [
            { fieldName: 'Cholesterol in HDL (mass/volume) in Serum or Plasma', fieldData: '2085-9' },
            { fieldName: 'Hemoglobin A1c/Hemoglobin.total in Blood', fieldData: '4548-4' }
        ]
    },
    {
        planTitle: 'IMAGING',
        planSubData: [
            { fieldName: 'CT Scan', fieldData: 'Brain' },
            { fieldName: 'X-ray', fieldData: 'Both Hip Ap & Lateral View' }
        ]
    },
    {
        planTitle: 'MEDICATIONS',
        planSubData: [
            { fieldName: 'Amoxicillin 500 mg', fieldData: 'Tab 1 tablet orally every 12 hours for 10 days #30 amp RFx15' },
            { fieldName: 'Ascorbic Acid Tab 100mg', fieldData: 'Tab 1 tablet orally every 24 hours for 15 days #25 amp RFx20' }
        ]


    },
    {
        planTitle: 'FOLLOW UP & INSTRUCTION NOTE',
        planSubData: [
            { fieldData: ' Return if symptoms worsen or do not improve within 5-7 days.' },
            { fieldData: 'Consider COVID-19 testing if symptoms persist or if there is concern for exposure' }
        ]


    }
    ,
    {
        planTitle: 'ADDITIONAL NOTE',
        planSubData: [
            { fieldData: ' Patient educated on URTI management, self-care measures, and signs of potential complications' },
            { fieldData: ' Importance of hand hygiene and cough etiquette emphasized.' }
        ]


    }



];

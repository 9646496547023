import React from 'react';
import Icons from '../../../components/icons/Icons';
import Checkbox from '../../../components/common-components/checkbox/Checkbox';

const TableHeader = ({ tableHeaderCustomclasses = '', coloumns, sorting, selectProps = { isSelectAll: false }, allChecked, selectAllCb = () => { }, getVarient }) => {
	const { isSortable = false, onSortChangeCb } = sorting || {};

	return (
		<thead className={`text-x bg-met-dark-grey ${tableHeaderCustomclasses}`}>
			<tr>
				{selectProps.isSelectAll ? (
					<th scope="col" className={`text-left px-6 py-[11px]`}>
						<Checkbox checked={allChecked} onChangeCb={selectAllCb} />
					</th>
				) : null}
				{
					coloumns.map((col, index) => {
						return <React.Fragment key={"table-column-" + index} >
							{isSortable ? (
								<th scope="col" className={`text-left px-6 py-[11px]`}>
									{col.label}
									{col.sort ? (
										<span
											className='inline-block ml-2 cursor-pointer'
											onClick={() => onSortChangeCb(col.sort)}>
											<Icons iconName="sortingIcon" />
										</span>
									) : null}
								</th>) : (
								<th scope="col" className={`text-left px-6 py-[11px]`}>
									{col.label}
								</th>
							)}
						</React.Fragment>
					})
				}
			</tr>
		</thead>
	)
}

export default TableHeader;

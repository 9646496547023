import React from 'react';

const Card = ({ bgColor = "", title, icon, children, onClickCb }) => {
	return (
		<div className={`bg-${bgColor} border border-solid border-1B598426 rounded-5 opacity-100 p-1 w-full h-full`}>
			<div className="flex justify-between">
				<div className="fs-12 font-semibold">{title}</div>
				<div className="cursor-pointer" onClick={onClickCb}>
					{icon}
				</div>
			</div>
			<div className="mt-1">{children}</div>
		</div>
	);
};

export default Card;

import React from 'react';
import CardComponent from '../../../components/common-components/SettingsCard/Card';
import { myAccountCardsData } from '../constant';

function index() {
	return (
		<CardComponent
			cardName="My Account"
			cardLogoName="settingsMyAccountIcon"
			cardoptions={myAccountCardsData}
			extraClass='ml-[20px]'
		/>
	);
}

export default index;

import { useRef } from 'react';
import classNames from 'classnames';
import './avatar.scss';

const networkQualityIcons = ['bad', 'bad', 'normal', 'good', 'good', 'good'];

const Avatar = (props) => {
    const { participant, style, isActive, className, networkQuality } = props;
    const { displayName, audio, muted, bVideoOn, userId, isInFailover } = participant;
    const avatarRef = useRef(null);

    return (
        <div
            className={classNames('avatar-img', { 'avatar-active': (isActive && !muted) }, className)}
            style={{ ...style, background: bVideoOn ? 'transparent' : 'rgb(26,26,26)' }}
            ref={avatarRef}
        >
            {(bVideoOn || (audio === 'computer' && muted) || isInFailover) && (
                <div className="corner-name">
                    {bVideoOn && networkQuality !== undefined && (
                        <i className={`icon-network-${networkQualityIcons[Math.min(networkQuality?.downlink ?? Number.MAX_VALUE, networkQuality?.uplink ?? Number.MAX_VALUE)]}`} />
                    )}
                    {isInFailover && (
                        <i className="icon-reconnect" style={{ color: '#FF9209', animation: 'loading 3s linear infinite' }} />
                    )}
                    {bVideoOn && <span>{displayName}</span>}
                </div>
            )}
            {!bVideoOn && <p className="center-name">{displayName}</p>}
        </div>
    );
};

export default Avatar;

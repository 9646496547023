import React from 'react'
import NavigationBorderedTabs from '../../../../components/common-components/navigationTabBorderVariant'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import ImagingOrder from './ImagingOrder'
import ImagingReport from './ImagingReport'

const PatientImaging = () => {
    const navigate = useNavigate()
    const routes = [
        // { path: "/order", component: <ImagingOrder /> },
        { path: "/report", component: <ImagingReport /> },
        { path: "/", component: <Navigate to={"report"} /> },
    ]
    return (
        <>
            <NavigationBorderedTabs tabs={[
                // { label: "Imaging Order", onClickCb: () => navigate('order'), path: "/order" },
                { label: "Imaging Report", onClickCb: () => navigate('report'), path: "/report" }
            ]} customClasses="absolute z-10" />
            <div className='overflow-auto relative met-scrollbar h-[90%]'>
                <Routes>
                    {routes.map((route, index) => {
                        return <Route key={"patient-imaging-route" + index} path={route.path} element={route.component} />
                    })}
                </Routes>
            </div>
        </>
    )
}

export default PatientImaging
import React, { useEffect } from 'react'
import ImageAndInfoCard from '../../../components/common-components/ImageAndInfoCard/ImageAndInfoCard'
import { useDispatch, useSelector } from 'react-redux';
import { fetchProviderAdminMetaData } from '../DashboardSaga';
import { componentKey, resetVitalTestCount, resetVitalTestsCustomDate, setApplyBtnTrue, setIsOpenDeviceTestCount, setNoDataMessage } from '../DashboardSlice';
import ViewDeviceTestsCountModal from './ViewDeviceTestsCountModal';
import { useNavigate } from 'react-router-dom';

function ProviderAdminCards() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { vitalTestsCountGraphDates } = useSelector(
        (state) => state[componentKey]
    );
    const { provideAdminMetaDataState, isOpenDeviceTestCount, providerAdminPaginationState } = useSelector((state) => state[componentKey]);
    useEffect(() => {
        const params = {
            dateFrom: providerAdminPaginationState.dateFrom,
            dateTo: providerAdminPaginationState.dateTo,
        }
        dispatch(fetchProviderAdminMetaData( { params }));
    }, [dispatch, providerAdminPaginationState.dateFrom, providerAdminPaginationState.dateTo]);

    const cardData = [
        {
            iconName: "providersIcon",
            trendIconName: provideAdminMetaDataState.provider?.isGrowing ? "trendingUpIcon" : "trendingDownIcon",
            counts: provideAdminMetaDataState.provider?.count.toString(),
            cardLabel: "Providers",
            subLabel: provideAdminMetaDataState.provider?.message,
            handleClick: () => {
            }
        },
        {
            iconName: "patientsIcon",
            trendIconName: provideAdminMetaDataState.patient?.isGrowing ? "trendingUpIcon" : "trendingDownIcon",
            counts: provideAdminMetaDataState.patient?.count.toString(),
            cardLabel: "Patients",
            subLabel: provideAdminMetaDataState.patient?.message,
            handleClick: () => {
                navigate("/patients");
            }
        },
        {
            iconName: "dashboardCalenderIcon",
            trendIconName: provideAdminMetaDataState.billing?.isGrowing ? "trendingUpIcon" : "trendingDownIcon",
            counts: provideAdminMetaDataState.billing?.count.toString(),
            cardLabel: "Total Billing",
            subLabel: provideAdminMetaDataState.billing?.message,
            handleClick: () => {
                navigate("/billing/billing-ready");

            }
        },
        {
            iconName: "encounterIcon",
            trendIconName: provideAdminMetaDataState.test?.isGrowing ? "trendingUpIcon" : "trendingDownIcon",
            counts: provideAdminMetaDataState.test?.count.toString(),
            cardLabel: "Test Statistics",
            subLabel: provideAdminMetaDataState.test?.message,
            handleClick: () => {
                dispatch(setIsOpenDeviceTestCount(true));
            }
        }
    ];

    return (
        //TODO: use map while integration
        <>
            <div className='flex column-gap-20px'>
                {cardData.map((item, index) => (
                    <div key={index} className='flex-1'>
                        <ImageAndInfoCard
                            iconName={item.iconName}
                            trendIconName={item.trendIconName}
                            counts={item.counts}
                            cardLabel={item.cardLabel}
                            subLabel={item.subLabel}
                            handleClick={item.handleClick}
                        />
                    </div>
                ))}
            </div>
            {
                isOpenDeviceTestCount && (
                    <ViewDeviceTestsCountModal
                        open={isOpenDeviceTestCount}
                        close={() => {
                            dispatch(setIsOpenDeviceTestCount(false));
                            dispatch(resetVitalTestsCustomDate(vitalTestsCountGraphDates))
                            dispatch(setApplyBtnTrue(false))
                            dispatch(resetVitalTestCount())
                            dispatch(setNoDataMessage(false))
                        }}
                    />)
            }
        </>
    )
}

export default ProviderAdminCards
import React, { useState } from 'react';
import Label from '../../../../../components/common-components/label/Label';
import PolicyHolder from './PolicyHolder';
import Avatar from '../../../../../components/common-components/avatar';
import General from '../../../../../libs/utility/General';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import Icons from '../../../../../components/icons/Icons';
import Checkbox from '../../../../../components/common-components/checkbox/Checkbox';
import { useDispatch } from 'react-redux';
import { toggleInsuranceActivation } from '../../../../../containers/AddPatient/Insurance/InsuranceSaga';

const InsuranceDetails = ({ insurance = [], handleEditInsuranceModal = () => { }, insuranceCategory }) => {

	const dispatch = useDispatch();

	const [isChecked, setIsChecked] = useState(false);

	const handleCheckboxChange = async () => {
		setIsChecked(!isChecked);
		dispatch(toggleInsuranceActivation(insurance?.uuid));
	};

	return (
		<>
			<div>
				<div className='flex justify-between'>
					<div className="flex w-full flex-wrap justify-between row-gap-10 p-4">
						<div className="w-1/3 flex items-center column-gap-10px">
							<Label fontWeight="bold">Insurance Type</Label>
							<div>:</div>
							<Label color="gray-500" fontWeight="bold">
								{insurance.type || ''}
							</Label>
						</div>
						<div className="w-1/3 flex items-center column-gap-10px">
							<Label fontWeight="bold">Insurance Name</Label>
							<div>:</div>
							<Label color="gray-500" fontWeight="bold">
								{insurance.name || ''}
							</Label>
						</div>
						<div className="w-1/3 flex items-center column-gap-10px">
							<Label fontWeight="bold">Plan Name</Label>
							<div>:</div>
							<Label color="gray-500" fontWeight="bold">
								{insurance.planName || ''}
							</Label>
						</div>
						<div className="w-1/3 flex items-center column-gap-10px">
							<Label fontWeight="bold">Company Name</Label>
							<div>:</div>
							<Label color="gray-500" fontWeight="bold">
								{insurance.companyName || ''}
							</Label>
						</div>

						<div className="w-1/3 flex items-center column-gap-10px">
							<Label fontWeight="bold">Effective Start & End Date</Label>
							<div>:</div>
							<Label color="gray-500" fontWeight="bold">
								{`${General.getformattedDate(insurance.startDate)} - ${General.getformattedDate(insurance.endDate)}` || ""}
							</Label>
						</div>
						<div className="w-1/3 flex items-center column-gap-10px">
							<Label fontWeight="bold">Insurance Holder</Label>
							<div>:</div>
							<Label color="gray-500" fontWeight="bold">
								{/* {`${insurance.firstName !== null ? insurance.firstName : "-"} ${insurance.lastName !== null ? insurance.lastName : ""}` || ""} */} {"-"}
							</Label>
						</div>
					</div>
					<div className='flex'>
						<Button variant={BUTTON_VARIANTS.TEXT}>
							{['Secondary', 'Other'].includes(insurance.type) && insuranceCategory !== 'inactiveInsurance' && (
								<Checkbox
									label="Make Primary"
									onChangeCb={() => handleCheckboxChange()}
									checked={isChecked}
								/>
							)}
						</Button>
						<Button
							onClickCb={() => handleEditInsuranceModal(true, insurance)}
							variant={BUTTON_VARIANTS.TEXT}
							customBtnClass="text-met-primary"
							startIcon={{ icon: <Icons iconName="editIcon" width={16} height={16} /> }}
						>
							Edit
						</Button>
					</div>

				</div>

			</div>
			<div className="flex column-gap-20px p-4">
				<Avatar url={insurance?.backImageUrl} customClass='h-[245px] w-[390px]'></Avatar>
				<Avatar url={insurance?.frontImageUrl
				} customClass='h-[245px] w-[390px]'></Avatar>
			</div>
			{insurance.patientRelationshipToInsured !== "Self" ? (
				<>
					<hr className="m-4" />
					<PolicyHolder insurance={insurance} />
				</>
			) : null}
		</>
	);
};

export default InsuranceDetails;

import React, { useState } from 'react';

const MetPopOver = ({ children, maxChar = "150" }) => {
    const [showPopup, setShowPopup] = useState(false);

    const truncatedNote = children.slice(0, maxChar)

    return (
        <div className="relative">
            <div
                className="w-full text-gray-500 overflow-hidden"
                onMouseEnter={() => setShowPopup(true)}
                onMouseLeave={() => setShowPopup(false)}
            >
                <span className="cursor-pointer">{truncatedNote}...</span>
            </div>
            {showPopup && (
                <div onMouseEnter={() => setShowPopup(true)}
                    onMouseLeave={() => setShowPopup(false)}
                    className="absolute z-10 bg-white shadow-lg p-4 rounded-lg border border-gray-200 max-w-sm max-h-60 overflow-y-auto met-scrollbar left-0 top-0"
                >
                    <p className='text-gray-500'>{children}</p>
                </div>
            )}
        </div>
    );
};

export default MetPopOver;

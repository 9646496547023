import React from "react";

const AvatarComponent = ({ alt, size = "xs", rounded = true, style = {}, row = {} }) => {
    const classes = `avatar avatar-initials avatar-${size} ${rounded ? "rounded-full" : ""}`;

    return (
        <div className={`${classes} bg-gray-500 flex items-center justify-center`} style={{ ...style }}>
            <span
                className="text-white text-sm text-center text-uppercase font-bold"
                style={{ fontSize: size, alignItems: "center", display: "flex", justifyContent: "center" }}
            >
                {row?.firstName?.charAt(0)}
                {row?.lastName?.charAt(0)}
            </span>
        </div>
    );
};

export default AvatarComponent;

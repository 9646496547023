import React from 'react'
import CardComponent from '../../../components/common-components/SettingsCard/Card';
import { templatesData } from '../constant';

function index() {
  return (
    <CardComponent
      cardName="templates"
      cardLogoName="settingTemplatesIcon"
      cardoptions={templatesData}
      extraClass='ml-[20px]'
    />
  );
}

export default index
import React from 'react'

function VitalsIcon({ color = "rgba(26,26,26,0.5)" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_88420" data-name="Group 88420" transform="translate(-16634 7599)">
        <g id="Group_88400" data-name="Group 88400" transform="translate(-76 173)">
          <g id="Group_88394" data-name="Group 88394" transform="translate(-12 213)">
            <rect id="Rectangle_36164" data-name="Rectangle 36164" width="24" height="24" transform="translate(16722 -7985)" fill="rgba(255,255,255,0.5)" opacity="0" />
            <g id="vital-signs" transform="translate(16723.313 -7987.124)">
              <path id="Path_205515" data-name="Path 205515" d="M2.562,19.749H6v.625H3.812A1.564,1.564,0,0,0,2.25,21.936a.312.312,0,0,0,.312.312H18.811a.312.312,0,0,0,.312-.312,1.564,1.564,0,0,0-1.562-1.562H15.374v-.625h3.437a1.564,1.564,0,0,0,1.562-1.562V7.562A1.564,1.564,0,0,0,18.811,6H2.562A1.564,1.564,0,0,0,1,7.562V18.186a1.564,1.564,0,0,0,1.562,1.562Zm4.062,0h.625v.625H6.624Zm1.25.625v-.625H13.5v.625Zm10.57,1.25H2.928A.939.939,0,0,1,3.812,21H17.561A.939.939,0,0,1,18.445,21.623Zm-3.7-1.25h-.625v-.625h.625ZM1.625,7.562a.937.937,0,0,1,.937-.937H18.811a.937.937,0,0,1,.937.937V18.186a.937.937,0,0,1-.937.937H2.562a.937.937,0,0,1-.937-.937Z" transform="translate(0 0)" fill={color} />
              <path id="Path_205516" data-name="Path 205516" d="M5.312,21.249H21.561a.312.312,0,0,0,.312-.312V10.312A.312.312,0,0,0,21.561,10H5.312A.312.312,0,0,0,5,10.312V20.936A.312.312,0,0,0,5.312,21.249Zm.312-4.375H10v3.75H9.375v-.312a1.557,1.557,0,0,0-.787-1.348.937.937,0,1,0-1.55,0,1.557,1.557,0,0,0-.787,1.348v.312H5.625Zm10,0v3.75h-5v-3.75Zm0-.625h-10v-2.5H7.187a.312.312,0,0,0,.29-.2L7.7,13l.431,2.369a.312.312,0,0,0,.285.256h.022a.312.312,0,0,0,.3-.214l.852-2.556.106.634A.312.312,0,0,0,10,13.75h1.337l.554,1.661a.312.312,0,0,0,.287.214.305.305,0,0,0,.3-.2l.891-2.228.078.312a.312.312,0,0,0,.3.237h1.875ZM7.5,18.437a.312.312,0,1,1,.312.312A.312.312,0,0,1,7.5,18.437Zm.312.937a.937.937,0,0,1,.937.937v.312H6.875v-.312a.937.937,0,0,1,.937-.937Zm13.436,1.25h-5v-10h5Zm-5.624-10v2.5H13.993l-.253-1.013a.312.312,0,0,0-.594-.04L12.213,14.4l-.355-1.066a.312.312,0,0,0-.3-.214h-1.3L10,11.511a.312.312,0,0,0-.284-.261.309.309,0,0,0-.321.213l-.864,2.593L8.12,11.819a.312.312,0,0,0-.6-.06l-.547,1.366H5.625v-2.5Z" transform="translate(-2.75 -2.75)" fill={color} />
              <path id="Path_205517" data-name="Path 205517" d="M32.312,36.5h1.25a.312.312,0,0,0,.312-.312V34.312A.312.312,0,0,0,33.562,34h-1.25a.312.312,0,0,0-.312.312v1.875A.312.312,0,0,0,32.312,36.5Zm.312-1.875h.625v1.25h-.625Z" transform="translate(-21.313 -19.251)" fill={color} />
              <path id="Path_205518" data-name="Path 205518" d="M24.312,36.5h1.25a.312.312,0,0,0,.312-.312V34.312A.312.312,0,0,0,25.562,34h-1.25a.312.312,0,0,0-.312.312v1.875A.312.312,0,0,0,24.312,36.5Zm.312-1.875h.625v1.25h-.625Z" transform="translate(-15.813 -19.251)" fill={color} />
              <path id="Path_205519" data-name="Path 205519" d="M43.312,17.125h1.25a.312.312,0,0,0,.312-.312v-2.5A.312.312,0,0,0,44.562,14h-1.25a.312.312,0,0,0-.312.312v2.5A.312.312,0,0,0,43.312,17.125Zm.312-2.5h.625V16.5h-.625Z" transform="translate(-28.876 -5.5)" fill={color} />
              <path id="Path_205520" data-name="Path 205520" d="M51,14h.937v.625H51Z" transform="translate(-34.377 -5.5)" fill={color} />
              <path id="Path_205521" data-name="Path 205521" d="M51,18h1.25v.625H51Z" transform="translate(-34.377 -8.25)" fill={color} />
              <path id="Path_205522" data-name="Path 205522" d="M43,26h3.125v.625H43Z" transform="translate(-28.876 -13.751)" fill={color} />
              <path id="Path_205523" data-name="Path 205523" d="M43,30h3.75v.625H43Z" transform="translate(-28.876 -16.501)" fill={color} />
              <path id="Path_205524" data-name="Path 205524" d="M46.25,34a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,46.25,34Zm0,1.875a.625.625,0,1,1,.625-.625A.625.625,0,0,1,46.25,35.875Z" transform="translate(-30.251 -19.251)" fill={color} />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default VitalsIcon
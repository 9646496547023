import React, { useEffect, useMemo } from 'react';
import Label from '../../../../../components/common-components/label/Label';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProviderGroupLocations, getProviderGroupDetails } from './ProviderGroupSaga';
import { componentKey } from './ProviderGroupSlice';
import Table from '../../../../Table/Index';
import Avatar from '../../../../../components/common-components/avatar';
import General from '../../../../../libs/utility/General';
import moment from 'moment';

const ProviderGroup = () => {
	const { providerGroupDetails } = useSelector((state) => state[componentKey]);
	const { providerGroupSpecialities } = providerGroupDetails || {};
	const { allProviderGroupLocation } = useSelector((state) => state[componentKey]);

	const specialitiesNames = useMemo(() => {
		if (!providerGroupSpecialities) {
			return [];
		}
		return providerGroupSpecialities.map((item) => item.speciality.name);
	}, [providerGroupSpecialities]);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getProviderGroupDetails());
		dispatch(getAllProviderGroupLocations());
	}, [dispatch]);

	const PROFILE_INFO_LABELS = useMemo(() => {
		const { email, phone, mlc, website } = providerGroupDetails;
		const { physicalAddress } = providerGroupDetails;
		const { address_line_1, address_line_2, city, state, country, zip } = physicalAddress || {};
		const physicalAddressArray = [address_line_1, address_line_2, city, zip, state, country]
			.filter(Boolean)
			.join(', ');
		return {
			MLC: mlc,
			Website: website || '',
			'Contact Number': phone,
			Email: email,
			'Physical Address': physicalAddressArray ,
		};
	}, [providerGroupDetails]);

	const BASIC_INFORMATION_LABELS = useMemo(() => {
		const { fax, description, billingAddress, workingHours } = providerGroupDetails || {};
		const { address_line_1, address_line_2, city, state, country, zip } = billingAddress || {};
		const billingAddressArray = [address_line_1, address_line_2, city, zip, state, country]
			.filter(Boolean)
			.join(', ');
		return {
			'Fax Number': fax || '',
			'Billing Address': billingAddressArray,
			'Provider Group Office Hours': workingHours?.length ? workingHours : [],
			Information: description || '',
		};
	}, [providerGroupDetails]);

	const columns = [
		{
			field: 'locationId',
			label: <Label fontWeight="bold">Location ID</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} customClasses="text-met-primary" fontSize={'md'}>
							{row.locationId}
						</Heading>
					</>
				);
			},
		},
		{
			field: 'name',
			label: <Label fontWeight="bold">Location Name</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{`${General.camelToPascalWithSpaces(row.name)}`}
						</Heading>
					</>
				);
			},
		},
		{
			field: 'speciality',
			label: <Label fontWeight="bold">Specialties</Label>,
			renderLogic: (row) => {
				const [speciality] = row?.provider_locations_speciality_intermediate || [];
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{speciality?.speciality?.name || ''}
						</Heading>
					</>
				);
			},
		},
		{
			field: 'placeOfService',
			label: <Label fontWeight="bold">POS</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row.placeOfService}
						</Heading>
					</>
				);
			},
		},
		{
			field: 'address',
			label: <Label fontWeight="bold">Address</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{`${row.physicalAddress1},${row.physicalAddress2},${row.physicalAddressCity} , ${row.physicalAddressState} , ${row.physicalAddressCountry} , ${row.physicalAddressZip}`}
						</Heading>
					</>
				);
			},
		},
		{
			field: 'contactNumber',
			label: <Label fontWeight="bold">Contact Number</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row.contactNumber}
						</Heading>
					</>
				);
			},
		},
	];

	const getExtraInfo = (key) => {
		if (typeof BASIC_INFORMATION_LABELS[key] === 'object') {
			return (
				<div className="text-rgba-1a1a1acc">
					{BASIC_INFORMATION_LABELS[key].map((item, index) => (
						<ol className="mt-5 flex" key={'basic-information-working-hour-' + index}>
							<div className="w-1/3">{General.capitalizeFirstLetter(item?.dayOfWeek)}</div>
							<div className=" text-met-light-grey">{moment(item?.openingTime, "HH:mm").format("hh:mm A")}</div>
							{item?.openingTime && <div className="ml-2  text-met-light-grey">-</div>}
							<div className="ml-2 text-met-light-grey"> {moment(item?.closingTime,"HH:mm").format("hh:mm A")}</div>
						</ol>
					))}
				</div>
			);
		} else {
			return BASIC_INFORMATION_LABELS[key];
		}
	};

	return (
		<div className="profile-card-height">
			<div className="flex justify-between">
				<div style={{ borderRadius: '10px', border: '1px solid #1A1A1A33' }} className="bg-white w-1/3 p-6">
					<div className="flex flex-col">
						<div>
							<Avatar
								textCenter="middle"
								fontSize={3}
								customClass="w-[130px] h-[130px] overflow-hidden object-cover rounded-full"
								url={providerGroupDetails?.path || ''}
								name={`${providerGroupDetails.groupName}`}
							/>
						</div>
						<p className="mt-4 text-2xl text-rgba-1A1A1A font-bold">{providerGroupDetails.groupName}</p>
						<div className="pt-4 flex column-gap-16 flex-wrap row-gap-10px">
							{specialitiesNames.map((name, index) => (
								<p
									key={'provider-specialities-index' + index}
									style={{ borderRadius: '15px' }}
									className="inline-block bg-rgba-CCECFF4D  text-met-primary"
								>
									{name}
								</p>
							))}
						</div>
					</div>
					<div className="flex flex-col">
						{Object.keys(PROFILE_INFO_LABELS).map((key) => (
							<div key={key} className="flex mt-7">
								<div className="w-1/3 text-met-light-grey">{key}</div>
								<div className="w-2/3 text-rgba-1a1a1acc fs-14 font-semibold">
									{PROFILE_INFO_LABELS[key]}
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="flex-1 w-2/3 ml-3">
					<div
						style={{ borderRadius: '10px', border: '1px solid #1A1A1A33' }}
						className="bg-white p-6 h-full"
					>
						<p className=" text-[20px] font-semibold text-met-primary">Basic Information</p>
						<div className="flex">
							<div className="">
								{Object.keys(BASIC_INFORMATION_LABELS).map((key) => (
									<div key={key} className="flex mt-7">
										<div className="w-1/3 text-met-light-grey">{key}</div>
										<div className="w-2/3 fs-14 font-semibold text-rgba-1a1a1acc">
											{getExtraInfo(key)}
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ borderRadius: '10px', border: '1px solid #1A1A1A33' }} className="bg-white p-6 mt-5">
				<p className="text-[20px] font-semibold text-met-primary">Work Locations</p>
				<div className="mt-4">
					<Table coloumns={columns} rows={allProviderGroupLocation} isPagination={false} />
				</div>
			</div>
		</div>
	);
};

export default ProviderGroup;

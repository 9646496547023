import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetCurrenPathHook from '../../../libs/customHooks/useGetCurrenPathHook';
import ShowForAllowedPermissionsOnly from '../../../containers/Wrappers/ShowForAllowedPermissionsOnly';

const Tabs = ({ tabs, customDivClass = '', customChildClass = '', pathLevel = 2 }) => {
    const activeTab = useGetCurrenPathHook(true, pathLevel);
    const navigate = useNavigate();

    return (
        <div className={customDivClass}>
            <div className="flex">
                {tabs.map((tab, index) => (
                    <React.Fragment key={'navigation-tab-id-' + index}>
                        <ShowForAllowedPermissionsOnly name={tab?.accessName}>
                            <button
                                style={activeTab === tab.id ? { color: '#1B5984' } : { color: '#1A1A1A80' }}
                                className={`relative px-4 py-2 mr-4 focus:outline-none ${activeTab === tab.id ? 'font-bold' : 'font-semibold'
                                    }`}
                                onClick={() => navigate(tab.redirectTo)}
                            >
                                {tab.name}
                                {activeTab === tab.id && (
                                    <span className="absolute bottom-0 left-0 w-full h-[3px] rounded-t-md bg-met-primary"></span>
                                )}
                            </button>
                        </ShowForAllowedPermissionsOnly>
                    </React.Fragment>
                ))}
            </div>
            <div className={`p-4 ${customChildClass}`}>{tabs.find((tab) => tab.id === activeTab)?.component}</div>
        </div>
    );
};

export default Tabs;

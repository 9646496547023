import React, { useEffect, useState } from 'react'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Table from '../../../../containers/Table/Index'
import DocumentsTypeModal from './DocumentsTypeModal'
import DocumentsModal from './DocumentsModal'
import { MET_COLORS } from '../../../../libs/constant'
import ButtonDropdown from '../../../../components/common-components/buttonDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { deletePatientDocuments, getAllPatientDocumentsDetails, getAllPatientDocumentsTypeDetails } from './PatientDocumentsSaga'
import { componentKey as PatientDashboardComponentKey } from "../PatientDashboardSlice"
import { componentKey as PatientDocumentsComponentKey, setIsDocumentsModalOpen, setIsDocumentsTypeModalOpen, setIsOpenDeletePopUp } from "./PatientDocumentsSlice"
import General from '../../../../libs/utility/General'
import useBase64Image from '../../../../containers/SettingsTab/MyAccountCard/Tabs/PrintConfiguration/useS3Image'
import DeletePopup from '../../../../components/common-components/deletePopup/DeletePopup'
import { useShowForAllowedPermissionsOnly } from '../../../../libs/customHooks/useShowForAllowedPermissionsOnly'
import { USER_PERMISSIONS } from '../../../../libs/UserPermissionsConstants'
import ShowForAllowedPermissionsOnly from '../../../../containers/Wrappers/ShowForAllowedPermissionsOnly'

function Documents() {
    const dispatch = useDispatch();
    const { currentPatientId } = useSelector((state => state[PatientDashboardComponentKey]))
    const { allDocumentsData, isDocumentsTypeModalOpen, isDocumentsModalOpen, isOpenDeletePopUp } = useSelector(state => state[PatientDocumentsComponentKey])
    const [rowData, setRowData] = useState(null);
    const role = localStorage.getItem('role');

    const showForAllowPermission = useShowForAllowedPermissionsOnly()

    const getBase64 = useBase64Image();

    const handleDocumentsModal = () => {
        dispatch(setIsDocumentsModalOpen(true))
    }

    const handleManageDocumentClick = () => {
        dispatch(getAllPatientDocumentsTypeDetails())
        dispatch(setIsDocumentsTypeModalOpen(true));
    }

    const handleDeleteClick = (documentId) => {
        dispatch(deletePatientDocuments({ patientId: currentPatientId, documentId }));
    };

    const handleViewClick = async (document) => {
        const base64 = await getBase64(document.file)
        if (base64) {
            General.openBase64FileInNewTab(base64);
        }
    }

    useEffect(() => {
        if (!currentPatientId) return;
        dispatch(getAllPatientDocumentsDetails({ patientId: currentPatientId }))
    }, [currentPatientId])

    const coloumns = [
        {
            field: "docName",
            label: <Label fontWeight="font-bold">Document Name</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.documentName}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "docType",
            label: <Label fontWeight="font-bold">Document Type</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.documentType?.typeName}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "docDate",
            label: <Label fontWeight="font-bold">Document Date</Label>,
            renderLogic: (row) => {
                const formattedDocDate = General.getformattedDate(row.createdAt)
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{formattedDocDate}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "uploadBy",
            label: <Label fontWeight="font-bold">Uploaded By</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{`${row.provider?.firstName} ${row.provider?.lastName}`}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "action",
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer" onClickCb={() => setRowData(row)} >{row.action}</Heading>
                        </div>
                        <ButtonDropdown
                            customBtnClass="p-0" endIcon="moreVertIcon"
                            options={
                                [
                                    ...(showForAllowPermission(USER_PERMISSIONS.VIEW_PATIENTS_DOCUMENTS) ? [{ label: "View", value: null, onChangeCb: () => handleViewClick(row) }] : []),
                                    {
                                        label: "Delete", value: null, onChangeCb: () => {
                                            dispatch(setIsOpenDeletePopUp(true))
                                            setRowData(row)
                                        }
                                    }]}
                            selectCb={(option) => {
                                option?.onChangeCb();
                            }} />
                    </div>
                </>
            },
        },
    ]

    return (
        <>
            <div className='flex items-center justify-end p-2 px-4'>
                <div className='flex'>
                    <Button onClickCb={handleManageDocumentClick} variant={BUTTON_VARIANTS.TEXT} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} customBtnClass='text-met-primary'>Manage Document types</Button>
                    <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.UPLOAD_PATIENTS_DOCUMENTS}>
                    {role !=="Biller" && <Button onClickCb={handleDocumentsModal} variant={BUTTON_VARIANTS.OUTLINED} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} >Add Document</Button>}
                    </ShowForAllowedPermissionsOnly>
                </div>
            </div>
            <div className='p-4'>
                <Table isPagination={false} coloumns={coloumns} rows={allDocumentsData} sorting={
                    {
                        isSortable: false,
                        onSortChangeCb: () => { }
                    }} />
            </div>
            {isDocumentsTypeModalOpen ? <DocumentsTypeModal open={isDocumentsTypeModalOpen} close={() => dispatch(setIsDocumentsTypeModalOpen(false))} /> : null}
            {isDocumentsModalOpen ? <DocumentsModal open={isDocumentsModalOpen} close={() => dispatch(setIsDocumentsModalOpen(false))} /> : null}
            {isOpenDeletePopUp ? <DeletePopup open={isOpenDeletePopUp}
                okButton='Delete'
                cancelButton='Cancel'
                onClickCancel={() => {
                    dispatch(setIsOpenDeletePopUp(false))
                }}
                onClickOk={() => {
                    handleDeleteClick(rowData.uuid)
                }}
            /> : null}
        </>
    )
}

export default Documents
import Label from "../../../components/common-components/label/Label"
import Icons from "../../../components/icons/Icons"
import HeaderAndDescriptionCard from "./HeaderAndDescriptionCard"
import ReviewOfSystemCard from "./ReviewOfSystemCard"
import VitalsCard from "./VitalsCard"
import parse from "html-react-parser"

const EncounterDetails = ({ encounterDetails, handleHideAllEncounterDetails }) => {
    return <div>
        <div onClick={handleHideAllEncounterDetails} className="flex items-center cursor-pointer mb-4">
            <Icons iconName='arrowBackIcon'></Icons>
            <Label fontWeight="bold" customClass='ml-2'>{parse(encounterDetails?.encounterForms?.data?.chiefComplaintCc || '-')}</Label>
        </div>
        <div className="flex flex-col row-gap-10">
            <div>
                {
                    typeof encounterDetails === 'object' && encounterDetails !== null && !Array.isArray(encounterDetails) ? (
                        Object.keys(encounterDetails)
                            .filter(keyName => keyName !== 'deviceUuid') 
                            .map(keyName => (
                                <HeaderAndDescriptionCard
                                    key={keyName}
                                    id={keyName}
                                    keyName={keyName}
                                    encounterDetails={encounterDetails}
                                />
                            ))
                    ) : null
                }

            </div>

        </div>
    </div>
}

export default EncounterDetails
import React from 'react'
import NavigationBorderedTabs from '../../../../components/common-components/navigationTabBorderVariant'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import LabOrder from './LabOrder'
import LabResult from './LabResult'

const PatientLabDetails = () => {
    const navigate = useNavigate()
    const routes = [
        // { path: "/order", component: <LabOrder /> },
        { path: "/result", component: <LabResult /> },
        { path: "/", component: <Navigate to={"result"} /> },
    ]
    return (
        <>
            <NavigationBorderedTabs tabs={[
                // { label: "Lab Order", onClickCb: () => navigate('order'), path: "/order" },
                { label: "Lab Result", onClickCb: () => navigate('result'), path: "/result" }
            ]} customClasses="absolute z-10" />
            <div className='overflow-auto relative met-scrollbar h-[90%]'>
                <Routes>
                    {routes.map((route, index) => {
                        return <Route key={"patient-labdetails-route" + index} path={route.path} element={route.component} />
                    })}
                </Routes>
            </div>
        </>
    )
}

export default PatientLabDetails
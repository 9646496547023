import React from 'react';
import Label from '../label/Label';
import ToggleSwitch from './ToggleSwitch';

function ToggleSwitchWtLabel({ label }) {
    function handleCheckboxChange(event) {
        console.log("Checkbox changed:", event.target.checked);
    }
    return (
        <div className='flex items-center w-44 justify-between align-center'>
            <Label>{label}</Label>
            <ToggleSwitch onChange={handleCheckboxChange} />
        </div>
    );
}

export default ToggleSwitchWtLabel;

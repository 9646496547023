import React from 'react';
const AddPersonIcon = () => {
	return (
		<svg id="person_add_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path id="Path_186962" data-name="Path 186962" d="M0,0H24V24H0Z" fill="#CCECFF80" stroke='ffffff'/>
			<path
				id="Path_186963"
				data-name="Path 186963"
				d="M15,12a4,4,0,1,0-4-4A4,4,0,0,0,15,12Zm0-6a2,2,0,1,1-2,2A2.006,2.006,0,0,1,15,6Zm0,8c-2.67,0-8,1.34-8,4v2H23V18C23,15.34,17.67,14,15,14ZM9,18c.22-.72,3.31-2,6-2s5.8,1.29,6,2ZM6,15V12H9V10H6V7H4v3H1v2H4v3Z"
				fill="#1B5984"
			/>
		</svg>
	);
};
export default AddPersonIcon;

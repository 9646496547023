import React from 'react'

const PopupWtBgColor = ({ isOpen, heading = "", closeProps = { icon: "", onCloseCb: () => { } }, children, customClass = '' }) => {
    return (
        <>
            {isOpen && (
                <div className="flex justify-center items-center absolute z-10 mt-1 w-full rounded-md right-0 min-w-[350px]">
                    <div
                        className={`bg-met-orange-300 shadow-md border-2 border-met-orange-900 p-2 rounded-md ${customClass}`}
                    >
                        <div className="flex flex-row justify-between py-1">
                            {heading ? <div>{heading}</div> : null}
                            {closeProps.icon ? <div onClick={closeProps?.onCloseCb || undefined}>{closeProps.icon}</div> : null}
                        </div>
                        {children}
                    </div>
                </div>
            )}
        </>
    )
}

export default PopupWtBgColor
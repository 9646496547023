import AppDataService from './AppDataService'

const COMMON_BASE = 'role'

export default class RolesAndResponsibilitiesDataService {

    static async getAllRolesAndResponsibilities() {
        return await AppDataService.get(`${COMMON_BASE}/permission`)
    }
    static async getAllRoles(page, limit) {
        return await AppDataService.get(`${COMMON_BASE}?page=${page || 1}&limit=${limit || 10}`)
    }
    static async postCreateNewRole(data) {
        return await AppDataService.post(`${COMMON_BASE}`, data)
    }
    static async patchUpdatRole(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}`, data)
    }
    static async deleteRole(uuid) {
        return await AppDataService.delete(`${COMMON_BASE}/${uuid}`)
    }
    static async getRoleByID(uuid) {
        return await AppDataService.get(`${COMMON_BASE}/${uuid}`)
    }
    static async rolesAndPermissionOfUser(role) {
        return await AppDataService.get(`${COMMON_BASE}/name?role=${role}`)
    }
    
}
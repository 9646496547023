import { useState, useCallback, useEffect } from 'react';
import { ChatFileDownloadStatus } from '@zoom/videosdk';
import { useParticipantsChange } from './useParticipantsChange';
import { isImageFile } from '../helper/chat-utils';
import { useSelector } from 'react-redux';
import { componentKey } from '../ZoomIntegrationSlice';

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function useChat(zmClient, chatClient) {
    const [records, setRecords] = useState([]);
    const [receivers, setReceivers] = useState([]);
    const [privilege, setPrivilege] = useState(chatClient.getPrivilege());
    const [chatUser, setChatUser] = useState({});
    const [isHost, setIsHost] = useState(false);
    const [isManager, setIsManager] = useState(false);

    const { videoStarted, audioStarted } = useSelector(state => state[componentKey])

    const onChatMessage = useCallback((payload) => {
        setRecords(records => {
            const { length } = records;
            if (length > 0) {

                //we can remove last records timestamp
                // const lastRecord = records[length - 1];

                const currentUserId = zmClient.getSessionInfo().userId;
                if (payload.file && payload.sender.userId === currentUserId) {
                    const record = records.find(
                        (item) =>
                            item.sender.userId === currentUserId &&
                            item.file?.name === payload.file?.name &&
                            item.file?.size === payload.file?.size &&
                            item.file?.type === payload.file?.type &&
                            item.receiver.userId === payload.receiver.userId &&
                            !item.id
                    );
                    if (record) {
                        const objectUrl = record.file?.originalFileObjectUrl;
                        let nPayload = payload;
                        if (objectUrl && payload.file) {
                            const { file, ...restProps } = payload;
                            nPayload = {
                                ...restProps,
                                file: {
                                    ...file,
                                    originalFileObjectUrl: objectUrl,
                                },
                            };
                        }
                        Object.assign(record, { ...nPayload });
                    }
                } else {
                    records.push(payload);
                }
            } else {
                records.push(payload);
            }
            return [...records];
        });
    }, [zmClient]);

    const onPrivilegeChange = useCallback((payload) => {
        setPrivilege(payload.chatPrivilege);
        if (chatClient) {
            setReceivers(chatClient.getReceivers());
        }
    }, [chatClient]);

    const onFileUploadProgressChange = useCallback((payload) => {
        const { fileName, fileSize, receiverId, progress, status, retryToken } = payload;
        const currentUserId = zmClient.getSessionInfo().userId;
        setRecords(records => {
            const record = records
                .slice(0)
                .reverse()
                .find(
                    (item) =>
                        !item.id &&
                        item.file?.name === fileName &&
                        item.file?.size === fileSize &&
                        item.sender.userId === currentUserId &&
                        item.receiver.userId === receiverId
                );
            if (record?.file?.upload) {
                Object.assign(record.file.upload, { progress, status, retryToken });
            }
            return [...records];
        });
    }, [zmClient]);

    const onFileDownloadProgressChange = useCallback((payload) => {
        const { id, fileName, fileSize, fileUrl, fileBlob, senderId, progress, status } = payload;
        setRecords(records => {
            const updatedRecords = records.map((item) => {
                if (
                    item.id === id &&
                    item.file?.name === fileName &&
                    item.file?.size === fileSize &&
                    item.file?.fileUrl === fileUrl &&
                    item.sender.userId === senderId
                ) {
                    const updatedRecord = { ...item };
                    if (updatedRecord.file?.download) {
                        updatedRecord.file.download = { progress, status };
                    }
                    if (updatedRecord.file && fileBlob) {
                        updatedRecord.file.originalFileObjectUrl = window.URL.createObjectURL(fileBlob);
                    }
                    return updatedRecord;
                }
                return item;
            });
            return updatedRecords;
        });
    }, []);

    const sendMessage = useCallback((text) => {
        if (chatUser) {
            chatClient.send(text, chatUser.userId);
        }
    }, [chatUser, chatClient]);

    const sendFile = useCallback(async (file) => {
        if (chatUser) {
            const cancelFunc = await chatClient.sendFile(file, chatUser.userId);
            setRecords(records => {
                records.push({
                    file: {
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        uuid: generateUUID(),
                        originalFileObjectUrl: file && isImageFile(file.name) ? window.URL.createObjectURL(file) : undefined,
                        upload: {
                            cancelFunc: cancelFunc,
                            status: 0,
                            progress: 0,
                        },
                    },
                    sender: {
                        name: '',
                        userId: zmClient.getSessionInfo().userId,
                    },
                    receiver: {
                        name: chatUser.displayName,
                        userId: chatUser.userId,
                    },
                    timestamp: new Date().getTime(),
                });
                return [...records];
            });
        }
    }, [chatUser, chatClient, zmClient]);

    const resendFile = useCallback(async (retryToken, fileUuid) => {
        if (chatUser) {
            const cancelFunc = await chatClient.sendFile(retryToken, chatUser.userId);
            setRecords(records => {
                const updatedRecords = records.map((item) => {
                    if (item.file?.uuid === fileUuid) {
                        const updatedItem = { ...item };
                        if (updatedItem.file?.upload) {
                            updatedItem.file.upload.cancelFunc = cancelFunc;
                        }
                        return updatedItem;
                    }
                    return item;
                });
                return updatedRecords;
            });
        }
    }, [chatUser, chatClient]);

    const downloadFile = useCallback(async (id, blob) => {
        const record = records.find((item) => item.id === id);
        if (record?.file?.fileUrl) {
            const cancelFunc = await chatClient.downloadFile(id, record.file.fileUrl, blob);
            setRecords(records => {
                const updatedRecords = records.map((item) => {
                    if (item.id === id && item.file?.download) {
                        item.file.download.cancelFunc = cancelFunc;
                        item.file.download.status = ChatFileDownloadStatus.InProgress;
                        item.file.download.progress = 0;
                    }
                    return item;
                });
                return updatedRecords;
            });
        }
    }, [chatClient, records]);

    const setChatUserId = useCallback((userId) => {
        const user = receivers.find((user) => user.userId === userId);
        if (user) {
            setChatUser(user);
        }
    }, [receivers]);

    useEffect(() => {
        if (chatClient) {
            setReceivers(chatClient.getReceivers());
        }
        setIsHost(zmClient.isHost());
        setIsManager(zmClient.isManager());
    }, [videoStarted, audioStarted])

    useParticipantsChange(zmClient, () => {
        if (chatClient) {
            setReceivers(chatClient.getReceivers());
        }
        setIsHost(zmClient.isHost());
        setIsManager(zmClient.isManager());
    });

    useEffect(() => {
        zmClient.on('chat-on-message', onChatMessage);
        zmClient.on('chat-file-upload-progress', onFileUploadProgressChange);
        zmClient.on('chat-file-download-progress', onFileDownloadProgressChange);
        return () => {
            zmClient.off('chat-on-message', onChatMessage);
            zmClient.off('chat-file-upload-progress', onFileUploadProgressChange);
            zmClient.off('chat-file-download-progress', onFileDownloadProgressChange);
        };
    }, [zmClient, onChatMessage, onFileUploadProgressChange, onFileDownloadProgressChange]);

    useEffect(() => {
        zmClient.on('chat-privilege-change', onPrivilegeChange);
        return () => {
            zmClient.off('chat-privilege-change', onPrivilegeChange);
        };
    }, [zmClient, onPrivilegeChange]);

    useEffect(() => {
        if (chatUser) {
            const index = receivers.findIndex((user) => user.userId === chatUser.userId);
            if (index === -1) {
                setChatUser(receivers[0]);
            }
        } else {
            if (receivers.length > 0) {
                setChatUser(receivers[0]);
            }
        }
    }, [receivers, chatUser]);

    return { records, receivers, privilege, chatUser, isHostOrManager: isHost || isManager, sendMessage, sendFile, resendFile, downloadFile, setChatUserId, };
}

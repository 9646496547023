import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HEADING } from '../../../../components/common-components/heading/Constants';
import Heading from '../../../../components/common-components/heading/Heading';
import Label from '../../../../components/common-components/label/Label';
import Table from '../../../../containers/Table/Index';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import AllergiesModal from './AllergiesModal';
import { MET_COLORS } from '../../../../libs/constant';
import SelectDropdown from '../../../../components/common-components/selectDropdown';
import ButtonDropdown from '../../../../components/common-components/buttonDropdown';
import {
	componentKey as PatientAllergiesComponentKey,
	setAllergyData,
	setAllergyId,
	setIsAllergyModalOpen,
	setIsNewAllergyAdd,
	setAllergyStatus,
	setIsOpenAllergiesDeletePopup,
} from '../Allergies/PatientAllergiesSlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import { deletePatientAllergies, getAllPatientAllergiesDetails } from '../Allergies/PatientAllergiesSaga';
import General from '../../../../libs/utility/General';
import DeletePopup from '../../../../components/common-components/deletePopup/DeletePopup';

function Allergies() {
	const dispatch = useDispatch();
	const { isAllergyModalOpen, allergiesData, allergyStatus, isOpenAllergiesDeletePopup, currentAllergyId } = useSelector((state) => state[PatientAllergiesComponentKey]);

	const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);
    const role = localStorage.getItem('role');

	const handleAllergiesModal = () => {
		dispatch(setIsNewAllergyAdd(true));
		dispatch(setAllergyId(''));
		dispatch(setAllergyData({}));
		dispatch(setIsAllergyModalOpen(true));
	};

	const handleEditClick = (allergy) => {
		dispatch(setIsNewAllergyAdd(false));
		dispatch(setIsAllergyModalOpen(true));
		dispatch(setAllergyId(allergy.uuid));
		dispatch(setAllergyData(allergy))
	};

	const handleDeleteClick = (allergyId) => {
		dispatch(deletePatientAllergies({ patientId: currentPatientId, allergyId }));
	};

	useEffect(() => {
		if (!currentPatientId) return;
		dispatch(getAllPatientAllergiesDetails({ patientId: currentPatientId, page: 1, limit: 10 }));
	}, [currentPatientId, dispatch]);

	const coloumns = [
		{
			field: 'number',
			label: <Label fontWeight="font-bold">No</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row?.index + 1}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'type',
			label: <Label fontWeight="font-bold">Type</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.allergyType ? General.camelToPascalWithSpaces(row.allergyType) : '-'}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'allergy',
			label: <Label fontWeight="font-bold">Allergy</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.name}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'reaction',
			label: <Label fontWeight="font-bold">Reaction</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.reaction}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'severity',
			label: <Label fontWeight="font-bold">Severity</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.severity ? General.camelToPascalWithSpaces(row.severity) : '-'}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'onsetDate',
			label: <Label fontWeight="font-bold">Onset Date</Label>,
			renderLogic: (row) => {
				const formattedonsetDate = General.getformattedDate(row.onsetDate)
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{formattedonsetDate}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'recordedDate',
			label: <Label fontWeight="font-bold">Recorded date</Label>,
			renderLogic: (row) => {
				const formattedcreatedAtDate = General.getformattedDate(row.createdAt)
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{formattedcreatedAtDate}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'recordedBy',
			label: <Label fontWeight="font-bold">Recorded By</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.providers?.firstName ? `${row.providers?.firstName} ${row.providers?.lastName}` : '-'}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'status',
			label: <Label fontWeight="font-bold">Status</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color={row.status === 'Active' ? 'green-500' : 'red-500'}
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.status}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'action',
			label: <Label fontWeight="font-bold">Action</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.action}
								</Heading>
							</div>
							<ButtonDropdown
								customBtnClass="p-0"
								endIcon="moreVertIcon"
								options={[
									{ label: 'Edit', value: null, onChangeCb: () => handleEditClick(row) },
									{
										label: 'Delete', value: null, onChangeCb: () => {
											dispatch(setIsOpenAllergiesDeletePopup(true))
											dispatch(setAllergyId(row.uuid));
										}
									},
								]}
								selectCb={(option) => {
									option?.onChangeCb();
								}}
							/>
						</div>
					</>
				);
			},
		},
	];

	return (
		<>
			<div className="flex items-center justify-between p-2 px-4">
				<Heading fontSize="xl" fontWeight="bold" type={HEADING.H3}>
					Allergies
				</Heading>
				<div className="flex column-gap-10px">
					<SelectDropdown
						placeholder="Select Status"
						value={allergyStatus}
						onChangeCb={(val) => {
							dispatch(setAllergyStatus(val));
							dispatch(getAllPatientAllergiesDetails({ patientId: currentPatientId, allergyStatus: val?.value }))
						}}
						options={[
							{ label: 'All', value: 'All' },
							{ label: 'Active', value: 'Active' },
							{ label: 'Inactive', value: 'Inactive' },
						]}
					/>
					{role !== 'Biller' && <Button
						onClickCb={handleAllergiesModal}
						variant={BUTTON_VARIANTS.OUTLINED}
						startIcon={{ icon: 'plusVectorIcon', color: MET_COLORS.PRIMARY }}
					>
						Add Allergies
					</Button>}
				</div>
			</div>
			<div className="p-4">
				<Table
					isPagination={false}
					coloumns={coloumns}
					rows={allergiesData}
				/>
			</div>
			{isAllergyModalOpen ? (
				<AllergiesModal open={isAllergyModalOpen} close={() => dispatch(setIsAllergyModalOpen(false))} />
			) : null}
			{isOpenAllergiesDeletePopup ? <DeletePopup
				open={isOpenAllergiesDeletePopup}
				okButton="Delete"
				cancelButton='Cancel'
				confirmationMessage="Delete"
				onClickCancel={() => { dispatch(setIsOpenAllergiesDeletePopup(false)) }}
				onClickOk={() => handleDeleteClick(currentAllergyId)}
			/> : null}
		</>
	);
}

export default Allergies;

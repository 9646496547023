import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setOpenValidateOtpModal } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import { Form, Formik } from 'formik';
import Input from '../../../components/common-components/input/Input';
import Icons from '../../../components/icons/Icons';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { FORM_FIELDS_NAMES } from './Constants';
import Button from '../../../components/common-components/button/Button';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { VALIDATION_REGEX } from '../../../libs/constant';
import { getValidationSchema } from '../../../libs/formsUtils';
import { otpVerification } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import { checkExistingAadhaar, getExistingABHADetails } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import LimitReachedPopup from './LimitReachedPopup';
import PatientDetailsReviewModal from './PatientDetailsReviewModal';
import { setStartTimer } from '../../../pages/Patients/AddPatient/AddPatientSlice';


const fields = [
	// {
	// 	fieldName: FORM_FIELDS_NAMES.MOBILE_NUMBER,
	// 	isRequired: true,
	// 	regexPattern: VALIDATION_REGEX.NUMBER_REGEX,
	// },
	{
		fieldName: FORM_FIELDS_NAMES.OTP,
		isRequired: true,
		regexPattern: VALIDATION_REGEX.NUMBER_REGEX,
	}
];
const validationSchema = getValidationSchema(fields);

const ValidateOTPModal = ({ open, close, getVia, otpOption, searchValue, modalType }) => {
	const dispatch = useDispatch();
	const { transactionIdAndAadhaar, createPatientData, startTimer } = useSelector((state) => state[componentKey]);
	// const createPatientFromAadhaar = useSelector(
	// 	(state) => state[componentKey].createPatientFromAadhaar,
	// 	(prev, next) => prev === next
	// );
	const [ timer, setTimer ] = useState(60);
	const [resendDisabled, setResendDisabled] = useState(true);
	//const [timerVisible, setTimerVisible] = useState(true);
	const [resendLimit, setResendLimit] = useState(2);
	const [showResendLimitPopup, setShowResendLimitPopup] = useState(false);

	useEffect(() => {
		let interval;
		if (open) {
			setTimer(60)
			interval = setInterval(() => {
				setTimer((prev) => {
					if (prev <= 1) {
						clearInterval(interval);
						setResendDisabled(false);
						dispatch(setStartTimer(false))
						return 0;
					}
					return prev - 1;
				});
			}, 1000);
		}
		return () => clearInterval(interval);
	}, [open, setResendDisabled, startTimer]);

	const formatTime = (time) => {
		const minutes = Math.floor(time / 60).toString().padStart(2, '0');
		const seconds = (time % 60).toString().padStart(2, '0');
		return `${minutes}:${seconds}`;
	};

	

	const handleResendOTP = async () => {
		if(resendLimit > 0){
			setResendLimit(prevState => prevState - 1);
			setResendDisabled(true)
			try {
				if(modalType === "getABHADetails"){
                    if (!getVia || !searchValue) {
                        console.error("Invalid input or selection");
                        return;
                    }
                    const payload = {
						key: getVia,
						[getVia]: searchValue,
						...(otpOption === "mobileLink" || otpOption === "aadharLink"
							? { sentTo: otpOption }
							: {})
					};
					await dispatch(getExistingABHADetails({getExistingABHADetailsData:payload}));
				}else {
					await dispatch(checkExistingAadhaar(Number(transactionIdAndAadhaar.aadhar)));
				}
				
			} catch (error) {
				console.error("Failed to resend OTP:", error);
			}
			setTimer(60);
		}else{
			setShowResendLimitPopup(true);
		}
		
	}
	let payloadData = modalType === "getABHADetails" ? {
		txnId:transactionIdAndAadhaar.transactionId,
		key: getVia,
		providerId: createPatientData.providerId,
		providerLocationId : createPatientData.providerLocationId,
		registrationDate: createPatientData.registrationDate,
		// ...((getVia === "abhaAddress" || getVia === "abhaNumber") ? {sendTo:otpOption} : {}),
	} : {
		txnId:transactionIdAndAadhaar.transactionId,
		aadhar : Number(transactionIdAndAadhaar.aadhar)
	}

	return (
		<>
			<Formik
				initialValues={
					{
						[FORM_FIELDS_NAMES.MOBILE_NUMBER]: '',
						//[FORM_FIELDS_NAMES.OTP]: '',
					}
				}
				validationSchema={validationSchema}
				enableReinitialize
				onSubmit={(values) => {
					let payload = {
						...values,
						...payloadData

					}
					dispatch(otpVerification({otpVerificationData:payload}));
					
				}}
			>
				{({ submitForm, isSubmitting, handleReset, values, handleSubmit, setFieldValue, handleBlur, ...props }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<div>
								<ModalComponent
									open={open}
									close={close}
									title={'Validate OTP'}
									footerButton={
										<div className='flex gap-2.5'>
											<Button
												type="button"
												variant={BUTTON_VARIANTS.CONTAINED}
												customBtnClass={`text-met-primary ${resendDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
												onClickCb = {() => handleResendOTP()}
												disabled={resendDisabled}
											>
												Resend OTP
											</Button>
											<Button
												type="submit"
												variant={BUTTON_VARIANTS.CONTAINED}
												customBtnClass="text-met-primary"
											>
												Submit
											</Button>
										</div>	
									}
									customClasses="w-[800px]"
								>
									<div className="p-2">
										<div className="flex column-gap-10px items-center pt-6">
											<div className="flex-1">
												<div>
													<Input
														label="OTP"
														placeholder="Enter 6 digit OTP"
														isRequired={true}
														name={FORM_FIELDS_NAMES.OTP}
														value={values[FORM_FIELDS_NAMES.OTP]}
														onChangeCb={(e) => {
															const numericValue = e.target.value.replace(/[^0-9]/g, '');
															if (numericValue.length <= 6) {
																setFieldValue(
																	FORM_FIELDS_NAMES.OTP,
																	numericValue
																);
															}
															
														}}
														onBlurCb={handleBlur}
													/>
												</div>
												{modalType !== "getABHADetails" && 
												<div>
													<Input
														label="Mobile Number"
														placeholder="Enter 10 digit Mobile Number"
														isRequired={true}
														name={FORM_FIELDS_NAMES.MOBILE_NUMBER}
														value={values[FORM_FIELDS_NAMES.MOBILE_NUMBER]}
														onChangeCb={(e) => {
															const numericValue = e.target.value.replace(/[^0-9]/g, '');
															if (numericValue.length <= 10) {
																setFieldValue(
																	FORM_FIELDS_NAMES.MOBILE_NUMBER,
																	numericValue
																);
															}
														}}
														onBlurCb={handleBlur}
														maxLength={10}
													/>
												</div> }
												{startTimer && (
													<div className="text-left pt-2 text-sm">
														Resend in {formatTime(timer)}
													</div>
												)}
											</div>
										</div>
									</div>
								</ModalComponent>
							</div>
						</Form>
					);
				}}
			</Formik>
			{showResendLimitPopup ? 
				<LimitReachedPopup
					open={showResendLimitPopup}
					close={() =>  {
						setShowResendLimitPopup(false);
						close()
					}}
				/> : null
			}
		</>
	);
};

export default ValidateOTPModal;

import React from 'react';

const AddWithCircleIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="add_circle_outline_black_24dp_1_"
			data-name="add_circle_outline_black_24dp (1)"
			width="18"
			height="18"
			viewBox="0 0 18 18"
		>
			<path id="Path_204922" data-name="Path 204922" d="M0,0H18V18H0Z" fill="none" />
			<path
				id="Path_204923"
				data-name="Path 204923"
				d="M10.158,5.708H8.675V8.675H5.708v1.483H8.675v2.967h1.483V10.158h2.967V8.675H10.158ZM9.417,2a7.417,7.417,0,1,0,7.417,7.417A7.419,7.419,0,0,0,9.417,2Zm0,13.35A5.933,5.933,0,1,1,15.35,9.417,5.941,5.941,0,0,1,9.417,15.35Z"
				transform="translate(-0.417 -0.417)"
				fill="#1b5984"
			/>
		</svg>
	);
};

export default AddWithCircleIcon;

import React, { useEffect, useState } from 'react'
import SuperBillHeader from './SuperBillHeader'
import BillingServiceDetails from './BillingServiceDetails'
import BillDetails from './BillDetails'
import { useParams, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getSuperBill } from './GenrateSuperBillSaga'
import useGarbageCollector from '../../../../libs/garbagecollector/garbageCollectorHooks'
import { componentKey } from './GenrateSuperBillSlice'

const SuperBillWrapper = () => {
    useGarbageCollector(componentKey)
    const { uuid } = useParams();
    const dispatch = useDispatch()
    const [isEditingSuperBill, setIsEditionSuperBill] = useState(false)
    const [billId, setBillId] = useState(null);

    const [params, setParams] = useSearchParams()

    useEffect(() => {
        const billid = params.get("billid")
        if (billid) {
            dispatch(getSuperBill(billid))
            setIsEditionSuperBill(true)
            setBillId(billid)
        }
        else {
            setIsEditionSuperBill(false)
        }
    }, [dispatch, params])

    return (
        <>
            <div className='flex w-full h-full'>
                <div className='w-full'>
                    <div>
                        <SuperBillHeader isEditingSuperBill={isEditingSuperBill} />
                    </div>
                    <div className='h-[91%]'>
                        <BillingServiceDetails billId={billId} uuid={uuid} />
                    </div>
                </div>
                <div className='w-1/3 h-[98%]'>
                    <BillDetails uuid={uuid} />
                </div>
            </div>
        </>

    )
}

export default SuperBillWrapper
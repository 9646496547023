import React, { useEffect } from 'react';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import Icons from '../../../../components/icons/Icons';
import SucessPopUp from '../../../../components/common-components/popup-components/SucessPopUp';
import { useParams } from 'react-router-dom';
import { getPatientDetails } from '../../AddPatient/AddPatientSaga';
import { componentKey } from '../../AddPatient/AddPatientSlice';
import { componentKey as portalAccessComponentKey, setOpenSuccessModal } from './PortalAccessSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { invitePatient, resendInvitePortalAccess, revokePortalAccess } from './PortalAccessSaga';


const PortalAccess = () => {
	const { patientId } = useParams();
	const dispatch = useDispatch()

	const { fetchedPatientDetails } = useSelector(state => state[componentKey])
	const { openSuccessModal } = useSelector(state => state[portalAccessComponentKey])

	useEffect(() => {
		dispatch(getPatientDetails({ patientId }))
	}, [dispatch])

	const data = [
		{
			fieldName: 'Date Of Birthday -',
			fieldDetails: moment(fetchedPatientDetails.dob).format('DD-MM-YYYY') || '-',
		},
		{
			fieldName: 'Phone Number - ',
			fieldDetails: fetchedPatientDetails.mobileNumber,
		},
		{
			fieldName: 'Email ID - ',
			fieldDetails: fetchedPatientDetails.email,
		},
	];

	return (
		<>
			<div className="flex justify-between items-center p-2 px-4">
				<div className="text-xl font-black">Patient Portal Access</div>
				<div>
					{fetchedPatientDetails.patientPortalAccessStatus === "Disabled" ||
						fetchedPatientDetails.patientPortalAccessStatus === null ||
						fetchedPatientDetails.patientPortalAccessStatus === "" ? (
						<Button
							variant={BUTTON_VARIANTS.OUTLINED}
							startIcon={{ icon: <Icons iconName="lockOpenIcon" /> }}
							onClickCb={() => dispatch(invitePatient({ patientId }))}
						>
							Enable Patient Access
						</Button>
					) : fetchedPatientDetails.patientPortalAccessStatus === "Enrolled" ? (
						<Button
							variant={BUTTON_VARIANTS.OUTLINED}
							customBtnClass="bg-red-50 text-red-500 border-red-500"
							startIcon={{ icon: <Icons iconName="lockCloseIcon" /> }}
							onClickCb={() => dispatch(revokePortalAccess({ patientId }))}
						>
							Revoke Patient Access
						</Button>
					) : null}
				</div>
			</div>
			{(fetchedPatientDetails.patientPortalAccessStatus === "Disabled" || fetchedPatientDetails.patientPortalAccessStatus === null) && (
				<div className="h-12 bg-gray-200 bg-opacity-60 flex justify-start items-center rounded-md">
					<div className="text-gray-500 text-lg font-black p-3">
						The Following Information is Required to Connect With METSL
					</div>
				</div>
			)}

			<div className="pt-4 pl-4">
				<ul>
					{data.map((item, index) => (
						<React.Fragment key={index}>
							<li>
								<div className="flex column-gap-20px gap-x-20">
									<div className="fs-18 text-gray-400">{item.fieldName}</div>

									<div className="fs-18">{item.fieldDetails}</div>
								</div>
							</li>
						</React.Fragment>
					))}
					{fetchedPatientDetails.patientPortalAccessStatus === "Pending" && (
						<div className="pl-20 pt-2">
							<div className="flex flex-wrap">
								<div className="fs-18 text-yellow-500">Pending - </div>
								<div
									onClick={() => dispatch(resendInvitePortalAccess({ patientId }))}
									className="text-met-primary fs-18 pl-2 underline"
								>
									Resend link
								</div>
								<div
									onClick={() => dispatch(revokePortalAccess({ patientId }))}
									className="cursor-pointer text-met-primary fs-18 underline pl-6"
								>
									Cancel Invite
								</div>
							</div>
						</div>
					)}
					{fetchedPatientDetails.patientPortalAccessStatus === "Enrolled" && (
						<div className="text-green-400 fs-18 pl-20 pt-2">Enrolled</div>
					)}

					{fetchedPatientDetails.patientPortalAccessStatus === "Enrolled" || fetchedPatientDetails.patientPortalAccessStatus === "Pending" && (
						<div className="fs-18 pt-2 pl-20 text-gray-400">
							Since {moment(fetchedPatientDetails.invitedDate).format('DD-MM-YYYY [At] hh:mm A')}
						</div>
					)}
					{fetchedPatientDetails.patientPortalAccessStatus === "Enrolled" && (
						<div className="flex items-center gap-x-10 pt-2">
							<div className="fs-18 text-gray-400">Last Logged In - </div>
							<div className="fs-18">{fetchedPatientDetails.lastLogin}</div>
						</div>
					)}
				</ul>
			</div>
			{
				<SucessPopUp
					successMesaage="Patient Will Receive Access Information Via Email"
					isOpen={openSuccessModal}
					onClickOk={() => {
						dispatch(setOpenSuccessModal(false));
					}}
				> <Icons iconName="successSvg" />
				</SucessPopUp>
			}
		</>
	);
};

export default PortalAccess;

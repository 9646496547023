import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDefaultPrintConfig } from './MetPrintModalSaga'
import { componentKey } from './MetPrintModalSlice'
import ReactToPrint from 'react-to-print'
import ModalComponent from '../../components/common-components/modal/ModalComponent'
import PrintConfigCardItem from '../SettingsTab/MyAccountCard/Tabs/PrintConfiguration/PrintConfigCard'

const MetPrintModal = ({ children, close, open, title }) => {
    const dispatch = useDispatch()
    const { defaultPrintConfig } = useSelector(state => state[componentKey])

    const printingRef = useRef()

    useEffect(() => {
        dispatch(getDefaultPrintConfig())
    }, [dispatch])


    return (
        <ModalComponent title={title} close={close} open={open} customClasses='w-[65%]' footerButton={
            <ReactToPrint
                trigger={() => <button className="text-met-primary bg-met-secondary border border-met-primary p-2 px-4 rounded-md">Print</button>}
                content={() => printingRef.current}
            />
        }>
            <div ref={printingRef}>
                <PrintConfigCardItem isHeader={false} item={defaultPrintConfig} />
                {children}
            </div>
        </ModalComponent>
    )
}

export default MetPrintModal
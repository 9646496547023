import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { componentKey } from './Dataloader/DataloaderSlice';
import { useNavigate } from 'react-router-dom';

const ShowForAllowedPermissionsOnly = ({ children, name, navigateUrl = null }) => {
    const { usersRolesAndResp } = useSelector((state) => state[componentKey]);

    const navigate = useNavigate()

    if ((name in usersRolesAndResp === true && usersRolesAndResp[name]) || name in usersRolesAndResp === false) {
        return children;
    } else if (navigateUrl) {
        return navigate(navigateUrl)
    } else {
        return null;
    }
};
export default ShowForAllowedPermissionsOnly;

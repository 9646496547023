import React from 'react'
import Route from './routes'
import Toaster from './components/common-components/toaster/Index'
import ZoomIntegration from './components/common-components/zoom/ZoomIntegration'

import { componentKey } from './components/common-components/zoom/ZoomIntegrationSlice'
import { useSelector } from "react-redux"
import { componentKey as schedulingComponentKey } from "./pages/Scheduling/ShedulingSlice"

function App() {

    const { startZoomMeeting } = useSelector(state => state[componentKey])
    const { checkedInData } = useSelector(state => state[schedulingComponentKey])
   
    return (
        <React.Fragment>
            {startZoomMeeting &&
                <ZoomIntegration
                    onEndVideoCb={() => { }}
                    userType="Provider"
                    appointmentDetails={checkedInData}
                />
            }
            <Route />
            <Toaster />
            <div id="modal-portal"></div>
        </React.Fragment>
    )
}

export default App

import { Form, Formik } from 'formik';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../../components/common-components/datePicker';
import Input from '../../../components/common-components/input/Input';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { FORM_FIELDS_NAMES } from './Constants';
import { componentKey, setSuperBillpaginationState } from '../ReadyForBilling/genrateSuperBill/GenrateSuperBillSlice';
import { getValidationSchema } from '../../../libs/formsUtils';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import moment from 'moment'

function Filters() {
    const dispatch = useDispatch()
    const { isSuperBillFilterOpen } = useSelector((state) => state[componentKey]);

    const fields = [];
    const validationSchema = getValidationSchema(fields);
    return (
        <Formik
            initialValues={{
                [FORM_FIELDS_NAMES.SEARCH_BY_DATE]: '',
                [FORM_FIELDS_NAMES.SEARCH_BY_PATIENT]: '',
                [FORM_FIELDS_NAMES.SEARCH_BY_STATUS]: '',
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => {
                dispatch(setSuperBillpaginationState({
                    date: values[FORM_FIELDS_NAMES.SEARCH_BY_DATE],
                    name: values[FORM_FIELDS_NAMES.SEARCH_BY_PATIENT],
                    status: values[FORM_FIELDS_NAMES.SEARCH_BY_STATUS]?.value,
                }));
            }}
        >
            {({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, handleBlur }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <div className='p-4'>
                            {isSuperBillFilterOpen && (
                                <div className="flex justify-between p-[20px] items-end border-[1px] border-[#1A1A1A33] rounded-[10px] border-opacity-25 ">
                                    <div className='flex column-gap-20px '>
                                        <div className='flex-1 min-w-410 max-w-440 '>
                                            <DatePicker
                                                label="Search By Date"
                                                placeholder="Choose Date"
                                                value={values[FORM_FIELDS_NAMES.SEARCH_BY_DATE]}
                                                name={FORM_FIELDS_NAMES.SEARCH_BY_DATE}
                                                onChangeCb={(date) => {
                                                    setFieldValue(FORM_FIELDS_NAMES.SEARCH_BY_DATE, moment(date).toISOString())
                                                }}
                                            />
                                        </div>
                                        <div className='flex-1 min-w-410 max-w-440'>
                                            <Input
                                                // endIcon={<Icons iconName={'searchIcon'} />}
                                                placeholder="Patient"
                                                label="Search Patient"
                                                name={FORM_FIELDS_NAMES.SEARCH_BY_PATIENT}
                                                value={values[FORM_FIELDS_NAMES.SEARCH_BY_PATIENT]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(FORM_FIELDS_NAMES.SEARCH_BY_PATIENT, e.target.value);
                                                }}
                                                onBlurCb={handleBlur}
                                            />
                                        </div>
                                        <div className='flex-1 min-w-410 max-w-440'>
                                            <SelectDropdown
                                                label="Search By Status"
                                                name={FORM_FIELDS_NAMES.SEARCH_BY_STATUS}
                                                placeholder="Select Status"
                                                value={values[FORM_FIELDS_NAMES.SEARCH_BY_STATUS]}
                                                options={[
                                                    { label: 'All', value: 'All' },
                                                    { label: 'Paid', value: "Paid" },
                                                    { label: 'Unpaid', value: "Unpaid" },
                                                    { label: 'Draft', value: "Draft" },
                                                ]}
                                                onChangeCb={(val) => {
                                                    setFieldValue(FORM_FIELDS_NAMES.SEARCH_BY_STATUS, val);
                                                }}
                                                onBlurCb={handleBlur}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            type='submit'
                                            variant={BUTTON_VARIANTS.OUTLINED}
                                            customBtnClass="text-met-primary "
                                        >
                                            Apply Filter
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    )
}

export default Filters
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { componentKey } from '../../socket/socketSlice';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../components/icons/Icons';
import Button from '../../components/common-components/button/Button';
import { patchClearNotifications, patchMarkNotificationAsRead } from '../../socket/socketSaga';
import { componentKey as routerComponentKey } from '../../routes/RoutesSlice'
import { BUTTON_VARIANTS } from '../../components/common-components/button/Constants';

const NotificationDropdown = () => {
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const { notifications } = useSelector(state => state[componentKey])
    const { loggedInUser } = useSelector(state => state[routerComponentKey])

    const dispatch = useDispatch()

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsNotificationDropdown(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    const handleNotificationMarkAsRead = (notification) => {
        if (!notification.isRead) {
            dispatch(patchMarkNotificationAsRead({ notificationId: notification.uuid, providerId: loggedInUser.uuid }))
        }
    }

    const handleClearAllNotification = () => {
        dispatch(patchClearNotifications({ providerId: loggedInUser.uuid }))
    }

    return (
        <div ref={dropdownRef} className="relative">
            <button onClick={toggleNotificationDropdown} className="relative z-10">
                <div><Icons iconName="bellIcon" /></div>
                {(notifications.length !== 0 && notifications.filter(item => !item.isRead)?.length !== 0 ) &&
                    <span className="absolute top-2 left-2 px-2 py-1 text-xs font-semibold leading-none text-white bg-red-500 rounded-full">{notifications.filter(item => !item.isRead)?.length}</span>
                }
            </button>
            {isNotificationDropdown &&
                <div className="absolute right-0 mt-2 w-72 bg-white shadow-lg rounded-lg" style={{ zIndex: "1099", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                    <div className="py-2 px-4 bg-gray-100 border-b border-gray-200">
                        <h6 className="text-lg font-semibold">Notifications</h6>
                    </div>
                    <div className="overflow-y-auto max-h-80">
                        {notifications.length ? notifications.map((item, index) => (
                            <div key={index} className="p-4 border-b border-gray-200" style={item.isRead ? {} : { background: "aliceblue" }} onClick={() => handleNotificationMarkAsRead(item)}>
                                <div className="flex items-center">
                                    <div className="flex-1">
                                        <h6 className="text-base font-semibold">{item.label}</h6>
                                        <p className="text-sm text-gray-600">{item.description}</p>
                                        <p className="text-xs text-gray-400">{moment(item.date).format("LL LT")}</p>
                                    </div>
                                </div>
                            </div>
                        )) : <div className='flex justify-center py-4'>No new notifications at the moment</div>}
                        <div className='py-2 flex justify-center'>
                            {notifications.length > 0 &&
                                <Button style={{ width: "200px", alignItems: "center", justifyContent: "center" }} variant={BUTTON_VARIANTS.CONTAINED_GRAY} onClickCb={handleClearAllNotification}>Clear All</Button>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default NotificationDropdown;

export const customAvailabilityMessage = (date, isEdit = false) => {
	const currentDate = new Date(date);

	const day = currentDate.getDate();
	const month = currentDate.toLocaleString('default', { month: 'long' });

	const dynamicMessage = `${isEdit ? 'Edit ' : ''}Availability for ${day}${getOrdinalSuffix(day)} ${month}`;

	function getOrdinalSuffix(day) {
		if (day === 1 || day === 21 || day === 31) return 'st';
		if (day === 2 || day === 22) return 'nd';
		if (day === 3 || day === 23) return 'rd';
		return 'th';
	}

	return dynamicMessage;
};

import React, { useEffect, useState } from 'react'
import { SETTINGS_PATHS } from '../../../../../libs/constant'
import CardContainer from '../../../../../components/common-components/Cards/Container/CardContainer'
import { useNavigate } from 'react-router-dom';
import Icons from '../../../../../components/icons/Icons';
import { componentKey, setIsVisitNoteModalOpen, setVisitNoteFormState } from './VisitNotesSlice';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import VisitNoteModal from './VisitNoteModal';

function NewVisitNote() {
    const navigate = useNavigate();
    const [srcDoc, setSrcDoc] = useState(null)
    const { isVisitNoteModalOpen } = useSelector(((state) => state[componentKey]))
    const dispatch = useDispatch()

    const handleSave = () => {
        dispatch(setIsVisitNoteModalOpen(true))
    };

    useEffect(() => {
        const onloadFunction = `
        window.onload = function () {
            const iframeWindow = window.parent;
                iframeWindow.postMessage({ type: 'iframeLoaded' }, '*');
            Formio.builder(document.getElementById('builder'), "", { noDefaultSubmitButton: true}).then(function (builder) {
                console.log("builder object", builder)
                builder.on('change', (e) => {
                    iframeWindow.postMessage({ type: 'change', data: e }, '*');
                });
            });
        }
    `;


        const dynamicContent = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <style> body { overflow-x: hidden; } </style> 
                <title>Dynamic HTML inside Iframe</title>
                <script type='text/javascript'>
                ${onloadFunction}
            </script >
            </head >
    <body>
        <div>
        <link rel='stylesheet' crossorigin="anonymous"  href='https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css' />
        <link rel='stylesheet' crossorigin="anonymous"  href='https://cdn.form.io/formiojs/formio.full.min.css' />
        <script crossorigin="anonymous"  src='https://cdn.form.io/formiojs/formio.full.min.js'></script>

        <div id='builder'></div>
    </div>
    </body>
            </html >
    `;

        setSrcDoc(dynamicContent);
    }, []);

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'change') {
                dispatch(setVisitNoteFormState(event.data.data))
            }
        };

        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <>
            <CardContainer >
                <div className="justify-between mb-4 flex font-medium text-rgba-1a1a1acc">
                    <div className="flex text-2xl">
                        <span onClick={() => navigate(SETTINGS_PATHS.TEMPLATES_VISIT_NOTES)} className="hover:cursor-pointer pt-[4px]">
                            <Icons iconName="arrowBackIcon" />
                        </span>
                        <span className="pl-[10px] font-bold">Visit Note</span>
                    </div>
                </div>
                <iframe id="dynamicIframe" className='w-full h-[75vh]' title='unique title' srcDoc={srcDoc} width="400" height="200"></iframe>
                <div className='flex justify-end pt-2'>
                    <Button
                        type="submit"
                        variant={BUTTON_VARIANTS.CONTAINED}
                        customBtnClass="text-met-primary"
                        onClickCb={() => handleSave()}
                    >
                        Save
                    </Button>
                </div>
            </CardContainer>
            {isVisitNoteModalOpen ? <VisitNoteModal open={isVisitNoteModalOpen} close={() => dispatch(setIsVisitNoteModalOpen(false))} /> : null}
        </>
    )
}

export default NewVisitNote

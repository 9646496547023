import { FieldArray } from 'formik'
import React from 'react'
import InsuranceCard from './InsuranceCard'
import Button from '../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants'
import { MET_COLORS } from '../../../libs/constant'

const InsuranceFields = ({ values = {}, setFieldValue = () => { }, handleBlur = () => { }, addInsurance = () => { }, handleRemove = () => { }, isEdit }) => {

    return (
        <FieldArray name="addPatientInsurance">
            {() => {
                return (
                    <>
                        {
                            values.addPatientInsurance?.length && values.addPatientInsurance.map((insurance, index) => (
                                <InsuranceCard index={index} key={"add-patient-insurance-" + index} insurance={insurance} setFieldValue={setFieldValue} handleBlur={handleBlur} enableDelete={values.addPatientInsurance?.length > 1} handleRemoveCb={() => handleRemove(index, values, setFieldValue)} />
                            ))
                        }
                        {!isEdit && values.addPatientInsurance?.length < 2 ? <Button
                            onClickCb={() => addInsurance(values, setFieldValue)}
                            variant={BUTTON_VARIANTS.TEXT}
                            startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }}
                            customBtnClass='text-met-primary'>
                            Add Secondary Insurance
                        </Button> : null}
                    </>
                )
            }}
        </FieldArray>
    )
}

export default InsuranceFields
import React, { useEffect, useMemo, useState } from "react";
import DashBoardPieChart from "./DashBoardPieChart";
import { useSelector } from "react-redux";
import { componentKey } from "../DashboardSlice";


function ProgressView() {
    const { upcommingAppointmentData } = useSelector(state => state[componentKey])
    const { schedule } = upcommingAppointmentData?.data || {}


    const { data } = upcommingAppointmentData || {}

    const [counts, setCounts] = useState({
        Scheduled: 0,
        ReScheduled: 0,
        Cancelled: 0,
        'No Show': 0
    })


    useEffect(() => {
        let counts = {
            Scheduled: 0,
            ReScheduled: 0,
            Cancelled: 0,
            'No Show': 0
        }

        schedule?.forEach(item => {
            if (item.status === 'Scheduled') {
                counts.Scheduled++
            } else if (item.status === 'ReScheduled') {
                counts.ReScheduled++
            } else if (item.status === 'Cancelled') {
                counts.Cancelled++
            } else if (item.status === 'No_Show') {
                counts['No Show']++
            }

        })

        setCounts(prev => ({ ...prev, ...counts }))
    }, [schedule])


    const xValues = ["Scheduled", "Rescheduled", "Cancelled", "No Show"];
    const yValues = useMemo(() => {
        return [counts.Scheduled, counts.ReScheduled, counts.Cancelled, counts["No Show"]];
    }, [counts])

    const barColors = ["#4873B9", "#7EDCFF", "#FF7E7E", "#8C8C8C"];
    const totalcount = 240
    return (
        <div className="">
            <div>
                <div className="flex justify-between">
                    <div className="font-bold fs-18 text-gray-800">Appointments</div>
                    {/* <span className="text-met-primary font-bold">View Report</span> */}
                </div>
                <div className="font-bold fs-20 mt-4">{data?.totalRecords}</div>
                {data?.totalRecords <= 0 ? (
                    <div className='flex justify-center text-gray-500 font-bold mt-[60px]'>
                        No Data Found!
                    </div>) :
                    <div className="flex flex-wrap justify-between">
                        <DashBoardPieChart xValues={xValues} yValues={yValues} barColors={barColors} totalcount={totalcount} />
                    </div>}
            </div>
        </div>
    );
}

export default ProgressView;


import { Route, Routes, useNavigate } from "react-router-dom";
import RoundFab from "../../../components/common-components/Fab/RoundFab";
import ButtonDropdown from "../../../components/common-components/buttonDropdown";
import { HEADING } from "../../../components/common-components/heading/Constants";
import Heading from "../../../components/common-components/heading/Heading";
import Label from "../../../components/common-components/label/Label";
import Table from "../../../containers/Table/Index";
import CreateSuperBill from "./CreateSuperBill";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllSuperBill } from "../ReadyForBilling/genrateSuperBill/GenrateSuperBillSaga";
import General from "../../../libs/utility/General";
import Avatar from "../../../components/common-components/avatar";
import Button from "../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../components/common-components/button/Constants";
import { componentKey, setEmptySuperBillpaginationState, setIsCreateSuperbillModalOpen, setIsEditSuperBill, setIsSuperBillFilterOpen, setSuperBillpaginationState, setbillingPrintData } from "../ReadyForBilling/genrateSuperBill/GenrateSuperBillSlice";
import CreateSuperbillModal from "./CreateSuperbillModal";
import PatientPaymentModal from "../Payments/PatientPaymentModal";
import { componentKey as patientPaymentsKey, setIsPaymentModalOpen } from "../Payments/PatientPaymentSlice";
import BillingPrintModal from "../ReadyForBilling/genrateSuperBill/BillingPrintModal";
import ShowForAllowedPermissionsOnly from "../../../containers/Wrappers/ShowForAllowedPermissionsOnly";
import { USER_PERMISSIONS } from "../../../libs/UserPermissionsConstants";
import Filters from "./Filters";

const SuperBill = ({isDontShowOnTab = false}) => {
    const navigate = useNavigate();
    const { superBillData, SuperBillpaginationState, isCreateSuperbillModalOpen, isSuperBillFilterOpen } = useSelector(state => state[componentKey])
    const { isPaymentModalOpen } = useSelector(state => state[patientPaymentsKey])
    const [rowData, setRowData] = useState(null)
    const [isOpenPrintModal, setIsOpenPrintModal] = useState(false)

    const billData = superBillData.superBills
    const dispatch = useDispatch()

    useEffect(() => {
        const params = {
            pageNumber: SuperBillpaginationState.pageNumber,
            limit: SuperBillpaginationState.limit,
            name: (SuperBillpaginationState?.name || "").toLowerCase(),
            status: SuperBillpaginationState.status,
            date: SuperBillpaginationState.date,
        }
        dispatch(getAllSuperBill(params));
    }, [
        dispatch,
        SuperBillpaginationState.pageNumber,
        SuperBillpaginationState.limit,
        SuperBillpaginationState.name,
        SuperBillpaginationState.status,
        SuperBillpaginationState.date,
    ]);

    const routes = [
        { path: "/create", component: <CreateSuperBill /> },
    ]

    const handleCreateSuperbillModal = () => {
        dispatch(setIsCreateSuperbillModalOpen(true));
    }

    const coloumns = [
        {
            field: 'createdAt',
            label: <Label fontWeight="md">Bill Date</Label>,
            renderLogic: (row) => {
                const formattedBillDate = General.getformattedDate(row.createdAt)
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {formattedBillDate}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'billId',
            label: <Label fontWeight="md">Bill ID</Label>,
            renderLogic: (row) => {
                const formattedUUID = row?.uuid?.substring(0, 5)
                const UUID = General.camelToPascalWithSpaces(formattedUUID)
                return (
                    <>
                        <div className="flex items-center" onClick={() => {
                            navigate(`/billing/genrate-super-bill/${row.patientId}?billid=${row.uuid}`)
                        }}>
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color='met-primary'
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {UUID}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'type',
            label: <Label fontWeight="md">Date Of Service</Label>,
            renderLogic: (row) => {
                const formattedDate = General.getformattedDate(row.dos)
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {formattedDate}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'allergy',
            label: <Label fontWeight="md">Patient Name</Label>,
            renderLogic: (row) => {
                const patient = {
                    firstName: row?.patient?.firstName,
                    lastName: row?.patient?.lastName,
                    profilePicture: row?.patient?.profilePicture

                };
                return (
                    <>
                        <div className="flex items-center">
                            <Avatar
                                customClass="w-[30px] h-[30px] rounded-full"
                                url={patient?.profilePicture}
                                name={`${patient.firstName} ${patient.lastName}`}
                            />

                            <div className="pl-4">
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {`${patient.firstName} ${patient.lastName}`}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'recordedDate',
            label: <Label fontWeight="md">Rendering Provider</Label>,
            renderLogic: (row) => {

                const renderingProvider = {
                    renderingProviderFirstName: row?.renderingProvider?.firstName,
                    renderingProviderLastName: row?.renderingProvider?.lastName,
                }

                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {`${renderingProvider.renderingProviderFirstName} ${renderingProvider.renderingProviderLastName}`}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'reaction',
            label: <Label fontWeight="md">Total Charges</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.netCharges}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'insuranceAmount',
            label: <Label fontWeight="md">Insurance Balance</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.insuranceAmount}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'recordedBy',
            label: <Label fontWeight="md" >Patient Paid</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.totalAmountPaid}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'recordedBy',
            label: <Label fontWeight="md">Status</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color={row.status === "Paid" ? 'green-500' : (row.status === "Draft" ? "met-primary" : 'red-500')}
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.status}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'action',
            label: <Label fontWeight="md">Action</Label>,
            renderLogic: (row) => {
                const showEditAndAddPayment = row?.status === "Paid";
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.action}
                                </Heading>
                            </div>
                            <ButtonDropdown
                                customBtnClass="p-0"
                                endIcon="moreVertIcon"
                                options={[
                                    showEditAndAddPayment
                                        ? {
                                            label: <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.VIEW_SUPER_BILL}> View Bill </ShowForAllowedPermissionsOnly>,
                                            value: null,
                                            onChangeCb: () => {
                                                navigate(`/billing/genrate-super-bill/${row.patientId}?billid=${row.uuid}`);
                                            }
                                        }
                                        : {
                                            label: <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.UPDATE_SUPER_BILL}> Edit Bill </ShowForAllowedPermissionsOnly>,
                                            value: null,
                                            onChangeCb: () => {
                                                navigate(`/billing/genrate-super-bill/${row.patientId}?billid=${row.uuid}`);
                                            }
                                        },
                                    showEditAndAddPayment
                                        ? {
                                            label: 'Print Bill',
                                            value: null,
                                            onChangeCb: () => {
                                                dispatch(setbillingPrintData(row));
                                                setIsOpenPrintModal(true);
                                                setRowData(row);
                                            }
                                        }
                                        : {
                                            label: 'Add Payment',
                                            value: null,
                                            onChangeCb: () => {
                                                dispatch(setIsPaymentModalOpen(true));
                                                setRowData(row);
                                            }
                                        }
                                ]}
                                selectCb={(option) => {
                                    option?.onChangeCb();
                                }}
                            />
                        </div>
                    </>
                );
            },
        },
    ];


    return (
        <>
            {!isDontShowOnTab && <div className='flex items-center justify-end p-2 px-4 column-gap-10px'>

                <div className='flex'>
                    {!isSuperBillFilterOpen && (
                        <Button
                            variant={BUTTON_VARIANTS.OUTLINED}
                            onClickCb={() => {
                                dispatch(setIsSuperBillFilterOpen(!isSuperBillFilterOpen));
                            }}
                        >
                            Show Filters
                        </Button>
                    )}
                    {isSuperBillFilterOpen && (
                        <Button
                            variant={BUTTON_VARIANTS.OUTLINED}
                            onClickCb={() => {
                                dispatch(setIsSuperBillFilterOpen(!isSuperBillFilterOpen));
                                dispatch(getAllSuperBill())
                                dispatch(setEmptySuperBillpaginationState())
                            }}
                        >
                            Clear Filters
                        </Button>
                    )}
                </div>
                <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.CREATE_SUPER_BILL}>
                    <Button onClickCb={handleCreateSuperbillModal} variant={BUTTON_VARIANTS.CONTAINED} startIcon={{ icon: "plusVectorIcon", color: "white" }} >Create Superbill</Button>
                </ShowForAllowedPermissionsOnly>
            </div>}
            {isSuperBillFilterOpen && <Filters />}
            <div className="p-4">
                <Table
                    isPagination={true}
                    coloumns={coloumns}
                    rows={billData}
                    paginationProps={{
                        isPagination: true,
                        totalCount: SuperBillpaginationState.total,
                        limit: SuperBillpaginationState.limit,
                        onPageChange: (page) => {
                            dispatch(setSuperBillpaginationState({ pageNumber: page }))
                        }
                    }}
                />
                <div className='overflow-auto relative met-scrollbar h-[90%]'>
                    <Routes>
                        {routes.map((route, index) => {
                            return <Route key={"billing-route" + index} path={route.path} element={route.component} />
                        })}
                    </Routes>
                </div>
            </div>
            {isCreateSuperbillModalOpen ? (
                <CreateSuperbillModal open={isCreateSuperbillModalOpen} close={() => { dispatch(setIsCreateSuperbillModalOpen(false)); }} />
            ) : null}
            {isPaymentModalOpen ? <PatientPaymentModal close={() => { dispatch(setIsPaymentModalOpen(false)) }} rowData={rowData} /> : null}
            {isOpenPrintModal ? <BillingPrintModal open={isOpenPrintModal} close={() => { setIsOpenPrintModal(false) }} rowData={rowData} /> : null}
        </>
    );
}

export default SuperBill;
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../../../components/common-components/label/Label'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Icons from '../../../../components/icons/Icons'
import Input from '../../../../components/common-components/input/Input'
import TextArea from '../../../../components/common-components/textArea/TextArea'
import DatePicker from '../../../../components/common-components/datePicker'
import { Form, Formik } from 'formik'
import { getValidationSchema } from '../../../../libs/formsUtils'
import { FORM_FIELDS_NAMES } from "./constants"
import { componentKey as PatientMedicationsComponentKey } from '../Medications/PatientMedicationsSlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import {
    postPatientMedications, updatePatientMedications,
} from '../Medications/PatientMedicationsSaga';
import moment from 'moment';

const fields = [
    { fieldName: FORM_FIELDS_NAMES.MEDICINE_NAME, isRequired: true },
    { fieldName: FORM_FIELDS_NAMES.START_DATE, isRequired: true },
    { fieldName: FORM_FIELDS_NAMES.END_DATE, isRequired: true },
];

const validationSchema = getValidationSchema(fields)

function MedicationModal({ open, close }) {

    const dispatch = useDispatch();
    const { isMedicationModalOpen, medicationData, currentMedicationId, isNewMedicationAdd } = useSelector(
        (state) => state[PatientMedicationsComponentKey]
    );

    const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);

    return (
        <Formik
            initialValues={{
                [FORM_FIELDS_NAMES.MEDICINE_NAME]: medicationData?.medicineName,
                [FORM_FIELDS_NAMES.SIG]: medicationData?.sig || '',
                [FORM_FIELDS_NAMES.DURATION]: medicationData?.duration || '',
                [FORM_FIELDS_NAMES.START_DATE]: medicationData?.startDate,
                [FORM_FIELDS_NAMES.END_DATE]: medicationData?.endDate,
                [FORM_FIELDS_NAMES.NOTE]: medicationData?.note || '',
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(formVals) => {
                try {
                    if (isNewMedicationAdd) {
                        dispatch(postPatientMedications({ patientId: currentPatientId, medicationData: formVals }));
                    } else {
                        dispatch(
                            updatePatientMedications({
                                patientId: currentPatientId,
                                medicationId: currentMedicationId,
                                medicationData: formVals,
                            })
                        );
                    }
                } catch (error) {
                    console.log(error);
                }
            }}
        >
            {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                return <Form onSubmit={handleSubmit} >
                    <div>
                        <ModalComponent
                            open={isMedicationModalOpen}
                            title={isNewMedicationAdd ? 'Add Medication' : 'Update Medication'}
                            footerButton={<Button variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary" type='submit' >Save</Button>}
                            icon={<Icons iconName="closeIcon" />}
                            close={close}
                            customClasses="w-[869px]">
                            <div className='p-2'>
                                <div className='pt-6'>
                                    <Input
                                        label="Medicine Name"
                                        isRequired={true}
                                        placeholder="Enter Medicine Name"
                                        name={FORM_FIELDS_NAMES.MEDICINE_NAME}
                                        value={values[FORM_FIELDS_NAMES.MEDICINE_NAME]}
                                        onChangeCb={(e) => {
                                            setFieldValue(FORM_FIELDS_NAMES.MEDICINE_NAME, e.target.value);
                                        }}
                                    />
                                </div>
                                <div className='mt-4'>
                                    <div>
                                        <Input
                                            label="Sig"
                                            placeholder="Enter"
                                            name={FORM_FIELDS_NAMES.SIG}
                                            value={values[FORM_FIELDS_NAMES.SIG]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.SIG, e.target.value) }}
                                            onBlurCb={handleBlur} />
                                    </div>
                                </div>
                                <div className='flex column-gap-10px items-center pt-6  '>
                                    <div className='flex-1'>
                                        <div>
                                            <Input
                                                label="Duration"
                                                placeholder="Enter"
                                                name={FORM_FIELDS_NAMES.DURATION}
                                                value={values[FORM_FIELDS_NAMES.DURATION]}
                                                onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.DURATION, e.target.value) }}
                                                onBlurCb={handleBlur} />
                                        </div>
                                    </div>
                                    <div className='flex-1'>
                                        <DatePicker
                                            label="Start Date"
                                            placeholder='Choose Date'
                                            isRequired={true}
                                            maxDate={new Date()}
                                            value={values[FORM_FIELDS_NAMES.START_DATE]}
                                            name={FORM_FIELDS_NAMES.START_DATE}
                                            onChangeCb={(date) => {
                                                setFieldValue(FORM_FIELDS_NAMES.START_DATE, date)
                                            }} />
                                    </div>
                                    <div className='flex-1'>
                                        <DatePicker
                                            label="End Date"
                                            placeholder='Choose Date'
                                            isRequired={true}
                                            minDate={values[FORM_FIELDS_NAMES.START_DATE] ? moment(values[FORM_FIELDS_NAMES.START_DATE]).format('DD/MM/YYYY') < moment(new Date()).format('DD/MM/YYYY') ? new Date() : new Date(values[FORM_FIELDS_NAMES.START_DATE]).setDate(new Date(values[FORM_FIELDS_NAMES.START_DATE]).getDate()) : ''}
                                            value={values[FORM_FIELDS_NAMES.END_DATE]}
                                            name={FORM_FIELDS_NAMES.END_DATE}
                                            onChangeCb={(date) => {
                                                setFieldValue(FORM_FIELDS_NAMES.END_DATE, date)
                                            }} />
                                    </div>
                                </div>
                                <div>
                                    <div className='mt-4'>
                                        <Label fontWeight="bold">Note</Label>
                                    </div>
                                    <div className='mt-2'>
                                        <TextArea
                                            name={FORM_FIELDS_NAMES.NOTE}
                                            value={values[FORM_FIELDS_NAMES.NOTE]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.NOTE, e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                        </ModalComponent>
                    </div>
                </Form>
            }}
        </Formik>
    )
}

export default MedicationModal
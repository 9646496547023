import AppDataService from "./AppDataService"

const COMMON_BASE = 'providergroup'

export default class ProviderGroupDataService {
    static async getProviderSepcialities() {
        return await AppDataService.get(`${COMMON_BASE}/specialities`)
    }
    static async getProviderGroupDetails() {
        return await AppDataService.get(`${COMMON_BASE}`)
    }
}

import store from '../../../../store/store';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { componentKey, setAllVaccinesData, setIsOpenVaccinDeletePopup, setIsVaccinesModalOpen } from './PatientVaccinesSlice';
import PatientDashboardDataService from '../../../../services/PatientDashboardDataService';
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/Constants';

export const {
    getAllPatientVaccinesDetails,
    postPatientVaccines,
    updatePatientVaccines,
    deletePatientVaccines,
} = {
    getAllPatientVaccinesDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/VACCINES',
            payload,
        };
    },
    postPatientVaccines: (payload) => {
        return {
            type: 'POST/PATIENTDASHBOARD/VACCINES',
            payload,
        };
    },
    updatePatientVaccines: (payload) => {
        return {
            type: 'UPDATE/PATIENTDASHBOARD/VACCINES',
            payload,
        };
    },
    deletePatientVaccines: (payload) => {
        return {
            type: 'DELETE/PATIENTDASHBOARD/VACCINES',
            payload,
        };
    },
};

function* getAllPatientVaccinesDetailsAsync(action) {
    try {
        const { patientId = '', type = 'current', page = '1', limit = '10' } = action.payload;
        const params = {
            page: page,
            limit: limit,
        };

        const response = yield PatientDashboardDataService.getAllPatientVaccines(
            'vaccines/patient',
            patientId,
            params
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            const vaccines = data?.vaccines.map((vaccine, index) => {
                return {
                    ...vaccine, index
                }
            })
            yield put(setAllVaccinesData(vaccines));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* postPatientVaccinesAsync(action) {
    try {
        const { patientId, vaccinesData } = action.payload;
        const response = yield PatientDashboardDataService.postPatientVaccines('vaccines', patientId, vaccinesData);
        const { message, status, data } = response?.data || {};
        if (status) {
            yield put(setIsVaccinesModalOpen(false));
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAllPatientVaccinesDetailsAsync,
                getAllPatientVaccinesDetails({ patientId }));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* updatePatientVaccinesDetailsAsync(action) {

    try {
        const { patientId = '', vaccineId = '', vaccinesData = {} } = action.payload;

        const response = yield PatientDashboardDataService.updatePatientVaccines(
            'vaccines',
            vaccineId,
            patientId,
            vaccinesData
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setIsVaccinesModalOpen(false));
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAllPatientVaccinesDetailsAsync,
                getAllPatientVaccinesDetails({ patientId })
            );
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* deletePatientVaccinesDetailsAsync(action) {
    try {
        const { patientId = '', vaccineId = '' } = action.payload;

        const response = yield PatientDashboardDataService.deletePatientVaccines('vaccines', vaccineId, patientId);

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setIsOpenVaccinDeletePopup(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAllPatientVaccinesDetailsAsync,
                getAllPatientVaccinesDetails({ patientId }));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getAllPatientVaccinesDetails().type, getAllPatientVaccinesDetailsAsync),
        takeLatest(postPatientVaccines().type, postPatientVaccinesAsync),
        takeLatest(updatePatientVaccines().type, updatePatientVaccinesDetailsAsync),
        takeLatest(deletePatientVaccines().type, deletePatientVaccinesDetailsAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);
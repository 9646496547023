// import { forwardRef, useEffect, useState } from 'react';
// import Button from '../../../components/common-components/button/Button';
// import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
// import InsuranceCard from './InsuranceCard';
// import ModalComponent from '../../../components/common-components/modal/ModalComponent';
// import { FORM_FIELDS_NAMES } from './Constants';
// import { FieldArray, Form, Formik } from 'formik';
// import * as Yup from 'yup';
// import { useDispatch, useSelector } from 'react-redux';
// import { saveInsurance } from '../../../pages/Patients/AddPatient/AddPatientSaga';
// import { getValidationSchema } from '../../../libs/formsUtils';
// import { componentKey, setCreatePatientData } from '../../../pages/Patients/AddPatient/AddPatientSlice';
// import General from '../../../libs/utility/General';
// import { MET_COLORS, VALIDATION_REGEX } from '../../../libs/constant';
// import { updateInsuranceDetails } from './InsuranceSaga';

// const relativeInitialValues = {
// 	[FORM_FIELDS_NAMES.FIRST_NAME]: "",
// 	[FORM_FIELDS_NAMES.LAST_NAME]: "",
// 	[FORM_FIELDS_NAMES.DATE_OF_BIRTH]: "",
// 	[FORM_FIELDS_NAMES.GENDER]: "",
// 	[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: "",
// 	[FORM_FIELDS_NAMES.ADDRESS_LINE_2]: "",
// 	[FORM_FIELDS_NAMES.CITY]: "",
// 	[FORM_FIELDS_NAMES.STATE]: "",
// 	[FORM_FIELDS_NAMES.COUNTRY]: "",
// 	[FORM_FIELDS_NAMES.ZIP_CODE]: "",
// }

// const relativesFields = [
// 	{ fieldName: FORM_FIELDS_NAMES.FIRST_NAME, isRequired: true },
// 	{ fieldName: FORM_FIELDS_NAMES.LAST_NAME, isRequired: true },
// 	{ fieldName: FORM_FIELDS_NAMES.DATE_OF_BIRTH, isRequired: true },
// 	{ fieldName: FORM_FIELDS_NAMES.GENDER, isRequired: true },
// 	{ fieldName: FORM_FIELDS_NAMES.ADDRESS_LINE_1, isRequired: true },
// 	{ fieldName: FORM_FIELDS_NAMES.ADDRESS_LINE_2, isRequired: false },
// 	{ fieldName: FORM_FIELDS_NAMES.CITY, isRequired: true },
// 	{ fieldName: FORM_FIELDS_NAMES.STATE, isRequired: true },
// 	{ fieldName: FORM_FIELDS_NAMES.COUNTRY, isRequired: false },
// 	{ fieldName: FORM_FIELDS_NAMES.ZIP_CODE, isRequired: true, regexPattern: VALIDATION_REGEX.ZIP_CODE_REGEX },
// ]

// const AddPatientInsurance = forwardRef(function AddPatientInsurance({ patientId = "", editInsuranceData, isEdit = false }, formRef) {
// 	// const AddPatientInsurance = ({ isPopup = false, isOpen = false, onCloseCb = () => { } }) => { //DO NOT CHANGE THIS

// 	const { newlyAddedPatientId, fetchedPatientDetails, createPatientData } = useSelector(state => state[componentKey])

// 	const { Insurance } = fetchedPatientDetails

// 	const isPopup = false
// 	const [isOpen, setIsOpen] = useState(false)

// 	const initialValues = {
// 		addPatientInsurance: Insurance?.length || createPatientData?.addPatientInsurance?.length || editInsuranceData?.length ? (editInsuranceData?.length ? editInsuranceData : Insurance?.length ? Insurance : createPatientData.addPatientInsurance)?.map((insurance) => {
// 			const { addressLine1, addressLine2, city, companyName, country, dob, endDate, firstName, gender, insuranceCardBackImage, insuranceCardFrontImage, lastName, name, patientRelationshipToInsured, planName, policyNumber, startDate, state, type, zip } = insurance
// 			return {
// 				[FORM_FIELDS_NAMES.INSURANCE_TYPE]: { label: type, value: type },
// 				[FORM_FIELDS_NAMES.INSURANCE_NAME]: { label: name, value: name },
// 				[FORM_FIELDS_NAMES.POLICY_NUMBER]: policyNumber,
// 				[FORM_FIELDS_NAMES.PLAN_NAME]: planName,
// 				[FORM_FIELDS_NAMES.COMPANY_NAME]: companyName,
// 				[FORM_FIELDS_NAMES.EFFECTIVE_START_DATE]: startDate,
// 				[FORM_FIELDS_NAMES.EFFECTIVE_END_DATE]: endDate,
// 				[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED]: patientRelationshipToInsured,
// 				[FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT]: insuranceCardFrontImage,
// 				[FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK]: insuranceCardBackImage,
// 				[FORM_FIELDS_NAMES.FIRST_NAME]: firstName,
// 				[FORM_FIELDS_NAMES.LAST_NAME]: lastName,
// 				[FORM_FIELDS_NAMES.DATE_OF_BIRTH]: dob,
// 				[FORM_FIELDS_NAMES.GENDER]: { label: gender, value: gender },
// 				[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: addressLine1,
// 				[FORM_FIELDS_NAMES.ADDRESS_LINE_2]: addressLine2,
// 				[FORM_FIELDS_NAMES.CITY]: city,
// 				[FORM_FIELDS_NAMES.STATE]: { label: state, value: state },
// 				[FORM_FIELDS_NAMES.COUNTRY]: country,
// 				[FORM_FIELDS_NAMES.ZIP_CODE]: zip,
// 				// ...relativeInitialValues
// 			}
// 		}) : [{
// 			[FORM_FIELDS_NAMES.INSURANCE_TYPE]: undefined,
// 			[FORM_FIELDS_NAMES.INSURANCE_NAME]: undefined,
// 			[FORM_FIELDS_NAMES.POLICY_NUMBER]: undefined,
// 			[FORM_FIELDS_NAMES.PLAN_NAME]: undefined,
// 			[FORM_FIELDS_NAMES.COMPANY_NAME]: undefined,
// 			[FORM_FIELDS_NAMES.EFFECTIVE_START_DATE]: undefined,
// 			[FORM_FIELDS_NAMES.EFFECTIVE_END_DATE]: undefined,
// 			[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED]: "Self",
// 			[FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT]: undefined,
// 			[FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK]: undefined,
// 			[FORM_FIELDS_NAMES.FIRST_NAME]: undefined,
// 			[FORM_FIELDS_NAMES.LAST_NAME]: undefined,
// 			[FORM_FIELDS_NAMES.DATE_OF_BIRTH]: undefined,
// 			[FORM_FIELDS_NAMES.GENDER]: undefined,
// 			[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: undefined,
// 			[FORM_FIELDS_NAMES.ADDRESS_LINE_2]: undefined,
// 			[FORM_FIELDS_NAMES.CITY]: undefined,
// 			[FORM_FIELDS_NAMES.STATE]: undefined,
// 			[FORM_FIELDS_NAMES.COUNTRY]: undefined,
// 			[FORM_FIELDS_NAMES.ZIP_CODE]: undefined,
// 		}]
// 	}

// 	const fields = [
// 		{ fieldName: FORM_FIELDS_NAMES.INSURANCE_TYPE, isDropdown: true },
// 		{ fieldName: FORM_FIELDS_NAMES.INSURANCE_NAME, isDropdown: true },
// 		{ fieldName: FORM_FIELDS_NAMES.POLICY_NUMBER, isRequired: true },
// 		{ fieldName: FORM_FIELDS_NAMES.PLAN_NAME, isRequired: false },
// 		{ fieldName: FORM_FIELDS_NAMES.COMPANY_NAME, isRequired: true },
// 		{ fieldName: FORM_FIELDS_NAMES.EFFECTIVE_START_DATE, isRequired: true },
// 		{ fieldName: FORM_FIELDS_NAMES.EFFECTIVE_END_DATE, isRequired: true },
// 		{ fieldName: FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT, isRequired: true },
// 		{ fieldName: FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK, isRequired: true },
// 	];

// 	// const validationSchema = Yup.object().shape({
// 	// 	addPatientInsurance: Yup.array().of(
// 	// 		...getValidationSchema(fields).fields,
// 	// 	)
// 	// });

// 	const validationSchema = Yup.object().shape({
// 		addPatientInsurance: Yup.array().of(
// 			Yup.object().shape({
// 				...getValidationSchema(fields).fields,
// 				[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED]: Yup.string(),
// 				[FORM_FIELDS_NAMES.FIRST_NAME]: Yup
// 					.string()
// 					.required()
// 					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
// 						is: (insuredRel) =>
// 							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
// 						then: () => Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.FIRST_NAME)} is required`).matches(VALIDATION_REGEX.NAME_REGEX, `${General.camelToPascalWithSpaces("firstName")} is invalid`),
// 						otherwise: (schema) => schema.notRequired(),
// 					}),
// 				[FORM_FIELDS_NAMES.LAST_NAME]: Yup
// 					.string()
// 					.required()
// 					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
// 						is: (insuredRel) =>
// 							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
// 						then: () => Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.LAST_NAME)} is required`).matches(VALIDATION_REGEX.NAME_REGEX, `${General.camelToPascalWithSpaces("lastName")} is invalid`),
// 						otherwise: (schema) => schema.notRequired(),
// 					}),

// 				[FORM_FIELDS_NAMES.GENDER]: Yup.object()
// 					.required()
// 					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
// 						is: (insuredRel) =>
// 							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
// 						then: () => Yup.object().shape({
// 							label: Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.GENDER)} is required`),
// 							value: Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.GENDER)} value is required`),
// 						}),
// 						otherwise: (schema) => schema.notRequired(),
// 					}),
// 				[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: Yup
// 					.string()
// 					.required()
// 					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
// 						is: (insuredRel) =>
// 							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
// 						then: () => Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.ADDRESS_LINE_1)} is required`),
// 						otherwise: (schema) => schema.notRequired(),
// 					}),
// 				[FORM_FIELDS_NAMES.CITY]: Yup
// 					.string()
// 					.required()
// 					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
// 						is: (insuredRel) =>
// 							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
// 						then: () => Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.CITY)} is required`),
// 						otherwise: (schema) => schema.notRequired(),
// 					}),
// 				[FORM_FIELDS_NAMES.STATE]: Yup.object()
// 					.required()
// 					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
// 						is: (insuredRel) =>
// 							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
// 						then: () => Yup.object().shape({
// 							label: Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.STATE)} is required`),
// 							value: Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.STATE)} value is required`),
// 						}),
// 						otherwise: (schema) => schema.notRequired(),
// 					}),
// 				[FORM_FIELDS_NAMES.ZIP_CODE]: Yup
// 					.string()
// 					.required()
// 					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
// 						is: (insuredRel) =>
// 							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
// 						then: () => Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.ZIP_CODE)} is required`).matches(VALIDATION_REGEX.ZIP_CODE_REGEX, `${General.camelToPascalWithSpaces("zip")} is invalid`),
// 						otherwise: (schema) => schema.notRequired(),
// 					}),
// 			}
// 			)
// 		),
// 	});

// 	/*
// 	[FORM_FIELDS_NAMES.FIRST_NAME]: Yup.string().when('isSelfChecked', {
// 					is: false,
// 					then: Yup.string().required('First Name is required'),
// 				}),
// 				[FORM_FIELDS_NAMES.LAST_NAME]: Yup.string().when('isSelfChecked', {
// 					is: false,
// 					then: Yup.string().required('First Name is required'),
// 				}),
// 				[FORM_FIELDS_NAMES.GENDER]: Yup.string().when('isSelfChecked', {
// 					is: false,
// 					then: Yup.string().required('First Name is required'),
// 				}),
// 				[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: Yup.string().when('isSelfChecked', {
// 					is: false,
// 					then: Yup.string().required('First Name is required'),
// 				}),
// 				[FORM_FIELDS_NAMES.CITY]: Yup.string().when('isSelfChecked', {
// 					is: false,
// 					then: Yup.string().required('First Name is required'),
// 				}),
// 				[FORM_FIELDS_NAMES.STATE]: Yup.string().when('isSelfChecked', {
// 					is: false,
// 					then: Yup.string().required('First Name is required'),
// 				}),
// 				[FORM_FIELDS_NAMES.ZIP_CODE]: Yup.string().when('isSelfChecked', {
// 					is: false,
// 					then: Yup.string().required('First Name is required'),
// 				}),
// 	*/

// 	const addInsurance = (values, setFieldValue) => {
// 		setFieldValue('addPatientInsurance', [
// 			...values.addPatientInsurance,
// 			{
// 				[FORM_FIELDS_NAMES.INSURANCE_TYPE]: "",
// 				[FORM_FIELDS_NAMES.INSURANCE_NAME]: "",
// 				[FORM_FIELDS_NAMES.POLICY_NUMBER]: "",
// 				[FORM_FIELDS_NAMES.PLAN_NAME]: "",
// 				[FORM_FIELDS_NAMES.COMPANY_NAME]: "",
// 				[FORM_FIELDS_NAMES.FIRST_NAME]: "",
// 				[FORM_FIELDS_NAMES.LAST_NAME]: "",
// 				[FORM_FIELDS_NAMES.EFFECTIVE_START_DATE]: "",
// 				[FORM_FIELDS_NAMES.EFFECTIVE_END_DATE]: "",
// 				[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED]: "Self",
// 				[FORM_FIELDS_NAMES.DATE_OF_BIRTH]: "",
// 				[FORM_FIELDS_NAMES.GENDER]: "",
// 				[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: "",
// 				[FORM_FIELDS_NAMES.ADDRESS_LINE_2]: "",
// 				[FORM_FIELDS_NAMES.CITY]: "",
// 				[FORM_FIELDS_NAMES.STATE]: "",
// 				[FORM_FIELDS_NAMES.COUNTRY]: "",
// 				[FORM_FIELDS_NAMES.ZIP_CODE]: "",
// 				[FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT]: "",
// 				[FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK]: "",
// 			}]);
// 	};

// 	const InsuranceFields = ({ values = {}, setFieldValue = () => { }, handleBlur = () => { } }) => {
// 		return (
// 			<FieldArray name="addPatientInsurance">
// 				{() => (
// 					<>
// 						{
// 							values.addPatientInsurance.map((insurance, index) => (
// 								<InsuranceCard index={index} key={"add-patient-insurance-" + index} insurance={insurance} setFieldValue={setFieldValue} handleBlur={handleBlur} enableDelete={values.addPatientInsurance?.length > 1} handleRemoveCb={() => handleRemove(index, values, setFieldValue)} />
// 							))
// 						}
// 						{values.addPatientInsurance?.length < 2 ? <Button
// 							onClickCb={() => addInsurance(values, setFieldValue)}
// 							variant={BUTTON_VARIANTS.TEXT}
// 							startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }}
// 							customBtnClass='text-met-primary'>
// 							Add Secondary Insurance
// 						</Button> : null}
// 					</>
// 				)}
// 			</FieldArray>
// 		)
// 	}

// 	const handleRemove = (index, values, setFieldValue) => {
// 		const updatedInsurance = [...values.addPatientInsurance];
// 		updatedInsurance.splice(index, 1);
// 		setFieldValue('addPatientInsurance', updatedInsurance);
// 	};

// 	const dispatch = useDispatch()
// 	return (
// 		<>
// 			<Formik
// 				innerRef={formRef}
// 				initialValues={initialValues}
// 				validationSchema={validationSchema}
// 				enableReinitialize
// 				onSubmit={(values) => {
// 					if (!isEdit) {
// 						const { addPatientInsurance } = values
// 						const insurance = addPatientInsurance.map((item, index) => {
// 							return { ...item, patientId: patientId ? patientId : newlyAddedPatientId, gender: item?.gender?.value || "", state: item?.state?.value || "", type: item?.type?.value || "", name: item?.name?.value || "", }
// 						})
// 						dispatch(saveInsurance(insurance))
// 						dispatch(setCreatePatientData({ addPatientInsurance: insurance }))
// 					} else {
// 						const { addPatientInsurance } = values
// 						const insurance = addPatientInsurance.map((item, index) => {
// 							return { ...item, patientId: patientId ? patientId : newlyAddedPatientId, gender: item?.gender?.value || undefined, state: item?.state?.value || undefined, type: item?.type?.value || undefined, name: item?.name?.value || undefined, }
// 						})
// 						dispatch(updateInsuranceDetails({ uuid: editInsuranceData.uuid, values: insurance }))
// 					}

// 				}}
// 			>
// 				{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, handleBlur, ...props }) => {
// 					return <Form onSubmit={handleSubmit} >
// 						{isPopup ? (
// 							<ModalComponent
// 								title={'Add Insurance Details'}
// 								customClasses="w-[90vw]"
// 								open={isOpen}
// 								footerButton={<Button variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary">Save</Button>}
// 								close={() => setIsOpen(false)}
// 							>

// 								<InsuranceFields values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} />
// 							</ModalComponent>
// 						)
// 							:
// 							<InsuranceFields values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} />
// 						}
// 					</Form>
// 				}}
// 			</Formik >
// 		</>
// 	);
// });

// export default AddPatientInsurance;

import { forwardRef, useEffect, useState } from 'react';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import InsuranceCard from './InsuranceCard';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { FORM_FIELDS_NAMES } from './Constants';
import { FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { saveInsurance } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import { getValidationSchema } from '../../../libs/formsUtils';
import { componentKey, setCreatePatientData } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import General from '../../../libs/utility/General';
import { MET_COLORS, VALIDATION_REGEX } from '../../../libs/constant';
import { updateInsuranceDetails } from './InsuranceSaga';
import InsuranceFields from './InsuranceFields';

const relativeInitialValues = {
	[FORM_FIELDS_NAMES.FIRST_NAME]: "",
	[FORM_FIELDS_NAMES.LAST_NAME]: "",
	[FORM_FIELDS_NAMES.DATE_OF_BIRTH]: "",
	[FORM_FIELDS_NAMES.GENDER]: "",
	[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: "",
	[FORM_FIELDS_NAMES.ADDRESS_LINE_2]: "",
	[FORM_FIELDS_NAMES.CITY]: "",
	[FORM_FIELDS_NAMES.STATE]: "",
	[FORM_FIELDS_NAMES.COUNTRY]: "",
	[FORM_FIELDS_NAMES.ZIP_CODE]: "",
}

const relativesFields = [
	{ fieldName: FORM_FIELDS_NAMES.FIRST_NAME, isRequired: true, },
	{ fieldName: FORM_FIELDS_NAMES.LAST_NAME, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.DATE_OF_BIRTH, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.GENDER, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.ADDRESS_LINE_1, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.ADDRESS_LINE_2, isRequired: false },
	{ fieldName: FORM_FIELDS_NAMES.CITY, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.STATE, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.COUNTRY, isRequired: false },
	{ fieldName: FORM_FIELDS_NAMES.ZIP_CODE, isRequired: true, regexPattern: VALIDATION_REGEX.ZIP_CODE_REGEX },
]

const AddPatientInsurance = forwardRef(function AddPatientInsurance({ patientId = "", isEdit = false, insuranceId = "", editInsuranceData = [], isAddInsurance = false }, formRef) {
	// const AddPatientInsurance = ({ isPopup = false, isOpen = false, onCloseCb = () => { } }) => { //DO NOT CHANGE THIS

	const { newlyAddedPatientId, fetchedPatientDetails, createPatientData } = useSelector(state => state[componentKey])

	const { Insurance } = fetchedPatientDetails

	const isPopup = false
	const [isOpen, setIsOpen] = useState(false)

	const initialValues = {
		addPatientInsurance: !isAddInsurance && (Insurance?.length || createPatientData?.addPatientInsurance?.length || editInsuranceData.length) ? (editInsuranceData.length ? editInsuranceData : Insurance?.length ? Insurance : createPatientData.addPatientInsurance)?.map((insurance) => {
			const { addressLine1, addressLine2, city, companyName, country, dob, endDate, firstName, gender, insuranceCardBackImage, insuranceCardFrontImage, lastName, name, patientRelationshipToInsured, planName, policyNumber, startDate, state, type, zip } = insurance
			return {
				[FORM_FIELDS_NAMES.INSURANCE_TYPE]: { label: type, value: type },
				[FORM_FIELDS_NAMES.INSURANCE_NAME]: { label: name, value: name },
				[FORM_FIELDS_NAMES.POLICY_NUMBER]: policyNumber,
				[FORM_FIELDS_NAMES.PLAN_NAME]: planName || undefined,
				[FORM_FIELDS_NAMES.COMPANY_NAME]: companyName,
				[FORM_FIELDS_NAMES.EFFECTIVE_START_DATE]: startDate,
				[FORM_FIELDS_NAMES.EFFECTIVE_END_DATE]: endDate,
				[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED]: patientRelationshipToInsured,
				[FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT]: insuranceCardFrontImage,
				[FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK]: insuranceCardBackImage,
				[FORM_FIELDS_NAMES.FIRST_NAME]: firstName,
				[FORM_FIELDS_NAMES.LAST_NAME]: lastName,
				[FORM_FIELDS_NAMES.DATE_OF_BIRTH]: dob,
				[FORM_FIELDS_NAMES.GENDER]: { label: gender, value: gender },
				[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: addressLine1,
				[FORM_FIELDS_NAMES.ADDRESS_LINE_2]: addressLine2,
				[FORM_FIELDS_NAMES.CITY]: city,
				[FORM_FIELDS_NAMES.STATE]: { label: state, value: state },
				[FORM_FIELDS_NAMES.COUNTRY]: country || undefined,
				[FORM_FIELDS_NAMES.ZIP_CODE]: zip,
				// ...relativeInitialValues
			}
		}) : [{
			[FORM_FIELDS_NAMES.INSURANCE_TYPE]: undefined,
			[FORM_FIELDS_NAMES.INSURANCE_NAME]: undefined,
			[FORM_FIELDS_NAMES.POLICY_NUMBER]: undefined,
			[FORM_FIELDS_NAMES.PLAN_NAME]: undefined,
			[FORM_FIELDS_NAMES.COMPANY_NAME]: undefined,
			[FORM_FIELDS_NAMES.EFFECTIVE_START_DATE]: undefined,
			[FORM_FIELDS_NAMES.EFFECTIVE_END_DATE]: undefined,
			[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED]: "Self",
			[FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT]: undefined,
			[FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK]: undefined,
			[FORM_FIELDS_NAMES.FIRST_NAME]: undefined,
			[FORM_FIELDS_NAMES.LAST_NAME]: undefined,
			[FORM_FIELDS_NAMES.DATE_OF_BIRTH]: undefined,
			[FORM_FIELDS_NAMES.GENDER]: undefined,
			[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: undefined,
			[FORM_FIELDS_NAMES.ADDRESS_LINE_2]: undefined,
			[FORM_FIELDS_NAMES.CITY]: undefined,
			[FORM_FIELDS_NAMES.STATE]: undefined,
			[FORM_FIELDS_NAMES.COUNTRY]: undefined,
			[FORM_FIELDS_NAMES.ZIP_CODE]: undefined,
		}]
	}

	const fields = [
		{ fieldName: FORM_FIELDS_NAMES.INSURANCE_TYPE, isDropdown: true },
		{ fieldName: FORM_FIELDS_NAMES.INSURANCE_NAME, isDropdown: true },
		{ fieldName: FORM_FIELDS_NAMES.POLICY_NUMBER, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.PLAN_NAME, isRequired: false },
		{ fieldName: FORM_FIELDS_NAMES.COMPANY_NAME, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.EFFECTIVE_START_DATE, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.EFFECTIVE_END_DATE, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK, isRequired: true },
	];

	// const validationSchema = Yup.object().shape({
	// 	addPatientInsurance: Yup.array().of(
	// 		...getValidationSchema(fields).fields,
	// 	)
	// });

	const validationSchema = Yup.object().shape({
		addPatientInsurance: Yup.array().of(
			Yup.object().shape({
				...getValidationSchema(fields).fields,
				[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED]: Yup.string(),
				[FORM_FIELDS_NAMES.FIRST_NAME]: Yup
					.string()
					
					.required()
					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
						is: (insuredRel) =>
							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
						then: () => Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.FIRST_NAME)} is required`).matches(VALIDATION_REGEX.NAME_REGEX, `${General.camelToPascalWithSpaces("firstName")} is invalid`).max(20, `${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.FIRST_NAME)} must be at most 20 characters`),
						otherwise: (schema) => schema.notRequired(),
					}),
				[FORM_FIELDS_NAMES.LAST_NAME]: Yup
					.string()
					.required()
					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
						is: (insuredRel) =>
							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
						then: () => Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.LAST_NAME)} is required`).matches(VALIDATION_REGEX.NAME_REGEX, `${General.camelToPascalWithSpaces("lastName")} is invalid`).max(20, `${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.LAST_NAME)} must be at most 20 characters`),
						otherwise: (schema) => schema.notRequired(),
					}),

				[FORM_FIELDS_NAMES.GENDER]: Yup.object()
					.required()
					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
						is: (insuredRel) =>
							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
						then: () => Yup.object().shape({
							label: Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.GENDER)} is required`),
							value: Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.GENDER)} value is required`),
						}),
						otherwise: (schema) => schema.notRequired(),
					}),
				[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: Yup
					.string()
					.required()
					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
						is: (insuredRel) =>
							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
						then: () => Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.ADDRESS_LINE_1)} is required`),
						otherwise: (schema) => schema.notRequired(),
					}),
				[FORM_FIELDS_NAMES.CITY]: Yup
					.string()
					.required()
					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
						is: (insuredRel) =>
							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
						then: () => Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.CITY)} is required`).matches(VALIDATION_REGEX.NAME_REGEX,  `${General.camelToPascalWithSpaces("city")} is invalid`),
						otherwise: (schema) => schema.notRequired(),
					}),
					[FORM_FIELDS_NAMES.COUNTRY]: Yup
					.string().matches(VALIDATION_REGEX.NAME_REGEX, `${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.COUNTRY)} is invalid`),
					// .when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
					// 	is: (insuredRel) =>
					// 		[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
					// 	then: () => Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.COUNTRY)} is required`).matches(VALIDATION_REGEX.NAME_REGEX,  `${General.camelToPascalWithSpaces("country")} is invalid`),
					// 	otherwise: (schema) => schema.notRequired(),
					// }),
				[FORM_FIELDS_NAMES.STATE]: Yup.object()
					.required()
					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
						is: (insuredRel) =>
							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
						then: () => Yup.object().shape({
							label: Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.STATE)} is required`),
							value: Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.STATE)} value is required`),
						}),
						otherwise: (schema) => schema.notRequired(),
					}),
				[FORM_FIELDS_NAMES.ZIP_CODE]: Yup
					.string()
					.required()
					.when(FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED, {
						is: (insuredRel) =>
							[FORM_FIELDS_NAMES.SPOUSE, FORM_FIELDS_NAMES.CHILD, FORM_FIELDS_NAMES.OTHER].includes(insuredRel),
						then: () => Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.ZIP_CODE)} is required`).matches(VALIDATION_REGEX.ZIP_CODE_REGEX, `${General.camelToPascalWithSpaces("zip")} is invalid`),
						otherwise: (schema) => schema.notRequired(),
					}),
			}
			)
		),
	});

	/*
	[FORM_FIELDS_NAMES.FIRST_NAME]: Yup.string().when('isSelfChecked', {
					is: false,
					then: Yup.string().required('First Name is required'),
				}),
				[FORM_FIELDS_NAMES.LAST_NAME]: Yup.string().when('isSelfChecked', {
					is: false,
					then: Yup.string().required('First Name is required'),
				}),
				[FORM_FIELDS_NAMES.GENDER]: Yup.string().when('isSelfChecked', {
					is: false,
					then: Yup.string().required('First Name is required'),
				}),
				[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: Yup.string().when('isSelfChecked', {
					is: false,
					then: Yup.string().required('First Name is required'),
				}),
				[FORM_FIELDS_NAMES.CITY]: Yup.string().when('isSelfChecked', {
					is: false,
					then: Yup.string().required('First Name is required'),
				}),
				[FORM_FIELDS_NAMES.STATE]: Yup.string().when('isSelfChecked', {
					is: false,
					then: Yup.string().required('First Name is required'),
				}),
				[FORM_FIELDS_NAMES.ZIP_CODE]: Yup.string().when('isSelfChecked', {
					is: false,
					then: Yup.string().required('First Name is required'),
				}),
	*/

	const addInsurance = (values, setFieldValue) => {
		setFieldValue('addPatientInsurance', [
			...values.addPatientInsurance,
			{
				[FORM_FIELDS_NAMES.INSURANCE_TYPE]: "",
				[FORM_FIELDS_NAMES.INSURANCE_NAME]: "",
				[FORM_FIELDS_NAMES.POLICY_NUMBER]: "",
				[FORM_FIELDS_NAMES.PLAN_NAME]: "",
				[FORM_FIELDS_NAMES.COMPANY_NAME]: "",
				[FORM_FIELDS_NAMES.FIRST_NAME]: "",
				[FORM_FIELDS_NAMES.LAST_NAME]: "",
				[FORM_FIELDS_NAMES.EFFECTIVE_START_DATE]: "",
				[FORM_FIELDS_NAMES.EFFECTIVE_END_DATE]: "",
				[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED]: "Self",
				[FORM_FIELDS_NAMES.DATE_OF_BIRTH]: "",
				[FORM_FIELDS_NAMES.GENDER]: "",
				[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: "",
				[FORM_FIELDS_NAMES.ADDRESS_LINE_2]: "",
				[FORM_FIELDS_NAMES.CITY]: "",
				[FORM_FIELDS_NAMES.STATE]: "",
				[FORM_FIELDS_NAMES.COUNTRY]: "",
				[FORM_FIELDS_NAMES.ZIP_CODE]: "",
				[FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT]: "",
				[FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK]: "",
			}]);
	};

	const handleRemove = (index, values, setFieldValue) => {
		const updatedInsurance = [...values.addPatientInsurance];
		updatedInsurance.splice(index, 1);
		setFieldValue('addPatientInsurance', updatedInsurance);
	};

	const dispatch = useDispatch()
	return (
		<>
			<Formik
				innerRef={formRef}
				initialValues={initialValues}
				validationSchema={validationSchema}
				enableReinitialize
				onSubmit={(values) => {
					if (!isEdit) {
						const { addPatientInsurance } = values
						const insurance = addPatientInsurance.map((item, index) => {
							return { ...item, patientId: patientId ? patientId : newlyAddedPatientId, gender: item?.gender?.value || "", state: item?.state?.value || "", type: item?.type?.value || "", name: item?.name?.value || "", }
						})
						dispatch(saveInsurance(insurance))
						dispatch(setCreatePatientData({ addPatientInsurance: insurance }))
					} else {
						const { addPatientInsurance } = values
						const insurance = addPatientInsurance.map((item, index) => {
							return { ...item, patientId: patientId ? patientId : newlyAddedPatientId, gender: item?.gender?.value || undefined, state: item?.state?.value || undefined, type: item?.type?.value || undefined, name: item?.name?.value || undefined, }
						})
						dispatch(updateInsuranceDetails({ uuid: editInsuranceData[0]?.uuid, values: insurance }))
					}

				}}
			>
				{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, handleBlur, ...props }) => {
					return <Form onSubmit={handleSubmit} >
						{isPopup ? (
							<ModalComponent
								title={'Add Insurance Details'}
								customClasses="w-[90vw]"
								open={isOpen}
								footerButton={<Button variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary">Save</Button>}
								close={() => setIsOpen(false)}
							>

								<InsuranceFields isEdit={isEdit} addInsurance={addInsurance} handleRemove={handleRemove} values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} />
							</ModalComponent>
						)
							:
							<InsuranceFields isEdit={isEdit} values={values} addInsurance={addInsurance} handleRemove={handleRemove} setFieldValue={setFieldValue} handleBlur={handleBlur} />
						}
					</Form>
				}}
			</Formik >
		</>
	);
});

export default AddPatientInsurance;

import React, { useEffect } from 'react'
import ModalComponent from '../../../components/common-components/modal/ModalComponent'
import Button from '../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants'
import Icons from '../../../components/icons/Icons'
import SelectDropdown from '../../../components/common-components/selectDropdown'
import Input from '../../../components/common-components/input/Input'
import { Form, Formik } from 'formik'
import { getValidationSchema } from '../../../libs/formsUtils'
import { FORM_FIELDS_NAMES } from './Constants'
import { saveClinicalCenter } from '../../../pages/Patients/AddPatient/AddPatientSaga'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey as dataloaderComponentKey } from '../../Wrappers/Dataloader/DataloaderSlice'
import { setIsOpenAddCenterModal } from '../../../pages/Patients/AddPatient/AddPatientSlice'
import { VALIDATION_REGEX } from '../../../libs/constant'
import General from '../../../libs/utility/General'


const fields = [
    { fieldName: FORM_FIELDS_NAMES.NAME, isRequired: true,isMaxLength:50 },
    { fieldName: FORM_FIELDS_NAMES.ADDRESS, isRequired: true },
    { fieldName: FORM_FIELDS_NAMES.CITY, isRequired: true, regexPattern:VALIDATION_REGEX.NAME_REGEX },
    { fieldName: FORM_FIELDS_NAMES.ZIP_CODE, isRequired: true, regexPattern: VALIDATION_REGEX.ZIP_CODE_REGEX },
    { fieldName: FORM_FIELDS_NAMES.STATE, isRequired: true, },
    { fieldName: FORM_FIELDS_NAMES.COUNTRY, regexPattern:VALIDATION_REGEX.NAME_REGEX },
    { fieldName: FORM_FIELDS_NAMES.CONTACT_NUMBER, isRequired: true, regexPattern: VALIDATION_REGEX.MOBILE_NUM_REGEX },
    { fieldName: FORM_FIELDS_NAMES.FAX_NUMBER }
];

const validationSchema = getValidationSchema(fields)

const initialValues = {
    [FORM_FIELDS_NAMES.NAME]: "",
    [FORM_FIELDS_NAMES.ADDRESS]: '',
    [FORM_FIELDS_NAMES.CITY]: '',
    [FORM_FIELDS_NAMES.ZIP_CODE]: '',
    [FORM_FIELDS_NAMES.STATE]: '',
    [FORM_FIELDS_NAMES.COUNTRY]: '',
    [FORM_FIELDS_NAMES.CONTACT_NUMBER]: '',
    [FORM_FIELDS_NAMES.FAX_NUMBER]: ''
}



function AddClinicalCenterModal({ open, close, label }) {
    const dispatch = useDispatch()
    const { indianStates } = useSelector(state => state[dataloaderComponentKey])

    useEffect(() => {
        dispatch(setIsOpenAddCenterModal(true))
    }, [dispatch])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => {
                dispatch(saveClinicalCenter({ name: label.toLowerCase(), center: values }))
            }}
        >
            {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                return <Form onSubmit={handleSubmit} >
                    <ModalComponent
                        open={open}
                        title={`Add New ${label}`}
                        footerButton={<Button variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary" type='submit'>Save {label}</Button>}
                        icon={<Icons iconName="closeIcon" />}
                        close={close}
                        customClasses="w-[870px]"
                    >
                        <div>
                            <div className='pt-6'>
                                <Input
                                    label={`${label} Name`}
                                    placeholder={`Enter ${label} Name`}
                                    isRequired={true}
                                    name={FORM_FIELDS_NAMES.NAME}
                                    value={values[FORM_FIELDS_NAMES.NAME]}
                                    onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.NAME, e.target.value) }}
                                    onBlurCb={handleBlur}
                                />

                            </div>
                            <div className='flex column-gap-10px items-center pt-6'>
                                <div className='flex-1'>
                                    <Input
                                        label='Address'
                                        isRequired={true}
                                        placeholder={"Enter Address"}
                                        name={FORM_FIELDS_NAMES.ADDRESS}
                                        value={values[FORM_FIELDS_NAMES.ADDRESS]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.ADDRESS, e.target.value) }}
                                        onBlurCb={handleBlur} />
                                </div>
                                <div className='flex-1'>
                                    <Input
                                        label='City'
                                        isRequired={true}
                                        placeholder={"Enter City"}
                                        name={FORM_FIELDS_NAMES.CITY}
                                        value={values[FORM_FIELDS_NAMES.CITY]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.CITY, e.target.value) }}
                                        onBlurCb={handleBlur}
                                    />
                                </div>
                            </div>
                            <div className='flex column-gap-10px items-center pt-6'>
                                <div className='flex-1'>
                                    <Input
                                        label='Zip Code'
                                        isRequired={true}
                                        placeholder={"Enter Zip code"}
                                        name={FORM_FIELDS_NAMES.ZIP_CODE}
                                        value={values[FORM_FIELDS_NAMES.ZIP_CODE]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.ZIP_CODE, e.target.value) }}
                                        onBlurCb={handleBlur}
                                    />
                                </div>
                                <div className='flex-1'>
                                    <div >
                                        <SelectDropdown
                                            placeholder='Select'
                                            label='State'
                                            isRequired={true}
                                            name={FORM_FIELDS_NAMES.STATE}
                                            value={values[FORM_FIELDS_NAMES.STATE]}
                                            onChangeCb={(val) => { setFieldValue(FORM_FIELDS_NAMES.STATE, val.value) }}
                                            options={indianStates}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex  column-gap-10px items-center pt-6'>
                                <div className='flex-1'>
                                    <div>
                                        <Input
                                            placeholder='Enter Country Name'
                                            label='Country'
                                            name={FORM_FIELDS_NAMES.COUNTRY}
                                            value={values[FORM_FIELDS_NAMES.COUNTRY]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.COUNTRY, e.target.value) }}
                                        />
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <Input
                                        label='Contact Number'
                                        placeholder={"Enter Contact Number"}
                                        name={FORM_FIELDS_NAMES.CONTACT_NUMBER}
                                        value={values[FORM_FIELDS_NAMES.CONTACT_NUMBER]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.CONTACT_NUMBER, General.sanitizeNumbers(e.target.value)) }}
                                        isRequired={true}
                                        onBlurCb={handleBlur}
                                    />
                                </div>
                            </div>
                            <div className=' width-50 column-gap-10px items-center pt-6'>
                                <div >
                                    <Input
                                        label='Fax Number'
                                        placeholder={"Enter Fax Number"}
                                        name={FORM_FIELDS_NAMES.FAX_NUMBER}
                                        value={values[FORM_FIELDS_NAMES.FAX_NUMBER]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.FAX_NUMBER, e.target.value) }}
                                        onBlurCb={handleBlur}

                                    />
                                </div>
                            </div>
                        </div>
                    </ModalComponent>
                </Form>
            }}
        </Formik>

    )
}

export default AddClinicalCenterModal
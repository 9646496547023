import React from 'react';
import { useSelector } from 'react-redux';
import { componentKey as PatientDiagnosesComponentKey } from "../../../Patients/PatientDashboard/Diagnoses/PatientDiagnosesSlice";
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import Button from '../../../../components/common-components/button/Button';
import FaceSheetCard from '../../../Patients/PatientDashboard/Dashboard/FaceSheet/FaceSheetCard';
import moment from 'moment';
import Icons from '../../../../components/icons/Icons';
import { MET_COLORS } from '../../../../libs/constant';

const DiagonosesCard = () => {
    const { allDiagnosesData } = useSelector((state) => state[PatientDiagnosesComponentKey]);

    const handleCopyClick = () => {
        let copiedText = '';
        allDiagnosesData?.slice(0, 5).map(diagnoses => {
            copiedText += `${diagnoses.diagnosesName} ${diagnoses.type} \nOnset Date: ${moment(diagnoses.onsetDate).format('DD-MM-YYYY')}\n`
        })
        navigator.clipboard.writeText(copiedText);
    }

    return (
        <FaceSheetCard
            title="DIAGNOSES"
            iconbtn={
                allDiagnosesData?.length ? <Button
                    variant={BUTTON_VARIANTS.TEXT}
                    customBtnClass="fs-14 font-bold text-met-primary"
                    style={{ height: '19px' }}
                    onClickCb={() => handleCopyClick()}
                >
                    <Icons iconName={'copyIcon'} color={MET_COLORS.PRIMARY}></Icons> Copy
                </Button> : ''
            }
            textColorClass={'text-met-primary'}
        >
            {allDiagnosesData && allDiagnosesData.length ? allDiagnosesData?.slice(0, 5).map((diagnoses, index) => {
                return <div key={"face-sheet-card-" + index}>
                    <div className="mb-4">
                        <div className="flex items-center mb-1">
                            <div className={`fs-14`}>{`${diagnoses.diagnosesName}`} <span className='text-gray-500 ml-2'>{`${diagnoses.type}`}</span></div>
                        </div>
                        <div className="text-gray-500 fs-14">{`Onset Date: ${moment(diagnoses.onsetDate).format('DD-MM-YYYY')}`}</div>
                    </div>
                </div>

            }) : <div className='fs-14 text-gray-500'>No data found</div>}
        </FaceSheetCard>
    );
};

export default DiagonosesCard;

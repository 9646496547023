import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Table from '../../../../containers/Table/Index'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import PastMedicalHistoryModal from './PastMedicalHistoryModal'
import { MET_COLORS } from '../../../../libs/constant'
import ButtonDropdown from '../../../../components/common-components/buttonDropdown'
import {
    componentKey as PatientHistoryComponentKey, setIsMedicalHistoryModalOpen, setIsNewMedicalHistoryAdd, setIsPastSurgicalHistoryDeletePopUp, setMedicalHistoryData, setMedicalHistoryId,
} from '../History/PatientHistorySlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import { deletePatientHistory, getAllPatientHistoryDetails } from '../History/PatientHistorySaga';
import General from '../../../../libs/utility/General';
import DeletePopup from '../../../../components/common-components/deletePopup/DeletePopup';

function PastMedicalHistory() {

    const dispatch = useDispatch();
    const { isMedicalHistoryModalOpen, allMedicalHistoryData, isPastSurgicalHistoryDeletePopUp, currentMedicalHistoryId } = useSelector((state) => state[PatientHistoryComponentKey]);

    const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);
    const role = localStorage.getItem('role');

    const handlePastMedicalHistoryModal = () => {
        dispatch(setIsNewMedicalHistoryAdd(true));
        dispatch(setMedicalHistoryId(''));
        dispatch(setMedicalHistoryData({}));
        dispatch(setIsMedicalHistoryModalOpen(true));
    }

    const handleEditClick = (history) => {
        dispatch(setIsNewMedicalHistoryAdd(false));
        dispatch(setMedicalHistoryId(history.uuid));
        dispatch(setIsMedicalHistoryModalOpen(true));
        dispatch(setMedicalHistoryData(history));
    };

    const handleDeleteClick = (historyId) => {
        dispatch(deletePatientHistory({ patientId: currentPatientId, historyId, historyType: 'pmh' }));
    };

    useEffect(() => {
        if (!currentPatientId) return;
        dispatch(getAllPatientHistoryDetails({ patientId: currentPatientId, historyType: 'pmh', page: 1, limit: 10 }));
    }, [currentPatientId]);

    const coloumns = [
        {
            field: "conName",
            label: <Label fontWeight="font-bold">Condition Name</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.conditionName}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "onsetDate",
            label: <Label fontWeight="font-bold">Onset Date</Label>,
            renderLogic: (row) => {
                const formattedOnsetDate = General.getformattedDate(row.onsetDate)
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{formattedOnsetDate}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "recordedDate",
            label: <Label fontWeight="font-bold">Recorded Date</Label>,
            renderLogic: (row) => {
                const formattedDate = General.getformattedDate(row.createdAt)
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{formattedDate}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "note",
            label: <Label fontWeight="font-bold">Note</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.note || '-'}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "action",
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.action}</Heading>
                        </div>
                        <ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[{ label: "Edit", value: null, onChangeCb: () => handleEditClick(row) }, {
                            label: "Delete", value: null, onChangeCb: () => {
                                dispatch(setIsPastSurgicalHistoryDeletePopUp(true))
                                dispatch(setMedicalHistoryId(row.uuid))
                            }
                        }]}
                            selectCb={(option) => {
                                option?.onChangeCb();
                            }} />
                    </div>
                </>
            },
        },
    ]

    return (
        <>
            <div className='flex items-center justify-end p-2 px-4'>
                <div className='flex'>
                {role !=="Biller" && <Button onClickCb={handlePastMedicalHistoryModal} variant={BUTTON_VARIANTS.OUTLINED} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} >Add PMH</Button>}
                </div>
            </div>
            <div className='p-4'>
                <Table isPagination={false} coloumns={coloumns} rows={allMedicalHistoryData} sorting={
                    {
                        isSortable: false,
                        onSortChangeCb: () => { }
                    }} />
            </div>
            {isMedicalHistoryModalOpen ? <PastMedicalHistoryModal open={isMedicalHistoryModalOpen} close={() => dispatch(setIsMedicalHistoryModalOpen(false))} /> : null}
            {isPastSurgicalHistoryDeletePopUp ?
                <DeletePopup
                    open={isPastSurgicalHistoryDeletePopUp}
                    okButton="Delete"
                    cancelButton='Cancel'
                    confirmationMessage="Delete"
                    onClickCancel={() => { dispatch(setIsPastSurgicalHistoryDeletePopUp(false)) }}
                    onClickOk={() => handleDeleteClick(currentMedicalHistoryId)}
                /> : null}
        </>
    )
}

export default PastMedicalHistory
import { all, put, takeLatest } from "redux-saga/effects";
import PaymentDataService from "../../../services/PaymentDataService";
import store from "../../../store/store";
import { componentKey, setIsPaymentModalOpen, setPatientList, setPatientListPaginationState, setPatientPayments, setPatientReceiptData, setPaymentPaginationState, setUnpaidSuperBill } from "./PatientPaymentSlice";
import PatientDataService from "../../../services/PatientDataServices";
import General from "../../../libs/utility/General";
import BillingDataService from "../../../services/BillingDataService";
import { addNotifications } from "../../../components/common-components/toaster/ToasterSlice";
import { TOASTER_VARIANT } from "../../../components/common-components/toaster/MetToaster";

export const { createNewPatientPayment, getAllPatients, getPatientsPaymentDetails, getPatientsUnpaidSuperBill, updateUnpaidPatientPayment, getPatientsReceiptDetails } = {
  createNewPatientPayment: (payload) => {
    return {
      type: 'PATIENT_PAYMENT/CREATE_NEW_PATIENT_PAYMENT',
      payload
    }
  },

  getAllPatients: (payload) => {
    return {
      type: 'PATIENT_PAYMENT/GET_PATIENT_NAMES',
      payload
    }
  },

  getPatientsPaymentDetails: (payload) => {
    return {
      type: 'PATIENT_PAYMENT/GET_PATIENT_PAYMENT_DETAILS',
      payload
    }
  },

  getPatientsUnpaidSuperBill: (payload) => {
    return {
      type: 'PATIENT_PAYMENT/GET_PATIENT_UNPAID_SUPERBILL',
      payload
    }
  },

  updateUnpaidPatientPayment: (payload) => {
    return {
      type: 'PATIENT_PAYMENT/UPDATE_PATIENT_PAYMENT',
      payload
    }
  },
  getPatientsReceiptDetails: (payload) => {
    return {
      type: 'PATIENT_PAYMENT/GET_PATIENT_RECEIPT_DETAILS',
      payload
    }
  },
}


function* createNewPatientPaymentAsync(action) {
  try {
    const { patientPayment, navigate, paymentpaginationState } = action.payload
    const response = yield PaymentDataService.createPatientPayment(patientPayment)
    const { data, status, message } = response.data
    const paymentMethod = data?.method

    if (status) {
      yield put(setIsPaymentModalOpen(false))
      yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
      navigate('/billing/payments')
      yield put(getPatientsPaymentDetails(paymentpaginationState))

      if (paymentMethod === "Online") {
        const { paymentlink } = data
        window.location.href = paymentlink.link_url;
      }
    }
  } catch (error) {
    yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
    console.log('err: ', error)
  }
}

function* getAllPatientsAsync(action) {
  try {
    const params = action.payload
    const response = yield PatientDataService.getPatientGridList({ params })
    const { data, status } = response.data

    if (status) {
      const { totalRecords } = data || {}
      const patientList = General.addLableValuePair(data?.patients || [], 'firstName', 'uuid', 'lastName');
      yield put(setPatientList(patientList));
      yield put(setPatientListPaginationState({ total: totalRecords }))
    }
  } catch (error) {
    console.log('err: ', error)
  }
}

function* getPatientsPaymentDetailsAsync(action) {
  try {
    const params = action.payload
    const response = yield PaymentDataService.getPatientsPaymentDetails({ params })
    const { data, status } = response.data

    if (status) {
      const { payments, totalPages, totalRecords } = data || {}
      yield put(setPatientPayments(payments))
      yield put(setPaymentPaginationState({ total: totalRecords }))
    }
  } catch (error) {
    console.log('err: ', error)
  }
}

function* getPatientsUnpaidSuperBillAsync(action) {
  try {
    const { patientId } = action.payload
    const response = yield BillingDataService.getUnpaidSuperBill(patientId)
    const { data, message, status } = response.data
    if (status) {
      yield put(setUnpaidSuperBill(data))
    }
  } catch (error) {
    console.log('err: ', error)
  }
}


function* updateUnpaidPatientPaymentsAsync(action) {
  try {
    const { uuid, ...values } = action.payload;
    const response = yield PaymentDataService.updatePatientPayment(uuid, values)
    const { status, message } = response.data

    if (status) {
      yield put(setIsPaymentModalOpen(false))
      yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
    }
  }
  catch (error) {
    yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
    console.log('err: ', error)
  }
}

function* getPatientsReceiptDetailsAsync(action) {
  try {
    const { paymentId } = action.payload
    const response = yield PaymentDataService.getCollectPayment(paymentId)
    const { data, status } = response.data
    if (status) {
      yield put(setPatientReceiptData(data))
    }

  } catch (error) {
    console.log('err: ', error)
  }
}

function* rootSaga() {
  yield all([
    takeLatest(createNewPatientPayment().type, createNewPatientPaymentAsync),
    takeLatest(getAllPatients().type, getAllPatientsAsync),
    takeLatest(getPatientsPaymentDetails().type, getPatientsPaymentDetailsAsync),
    takeLatest(getPatientsUnpaidSuperBill().type, getPatientsUnpaidSuperBillAsync),
    takeLatest(updateUnpaidPatientPayment().type, updateUnpaidPatientPaymentsAsync),
    takeLatest(getPatientsReceiptDetails().type, getPatientsReceiptDetailsAsync),
  ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
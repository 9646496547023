import React from 'react';
import Label from '../label/Label';
import { ErrorMessage } from 'formik';
function TextArea({
	label,
	labelfontWeight = 'bold',
	placeholder = 'Type here',
	rows = '4',
	customClasses = '',
	shadow = true,
	value = '',
	onChangeCb = () => { },
	disabled = false,
	isRequired = false,
	name = "",
	errorContainerClasses = "",
}) {
	return (
		<>
			<Label fontWeight={labelfontWeight} isRequired={isRequired}> {label} </Label>
			<textarea
				rows={rows}
				className={`focus:outline-none placeholder-gray-500 opacity-100 bg-no-repeat bg-white bg-0/0 p-2 w-full rounded-md fs-14 text-gray-600 ${customClasses} ${shadow ? 'box-shadow-1' : ''
					}`}
				value={value}
				placeholder={placeholder}
				onChange={onChangeCb}
				disabled={disabled}
				name={name}	
			></textarea>
			{name && <div className={`mt-1 ${errorContainerClasses}`}>
				<ErrorMessage name={name}>{msg => <div className="error-text">{typeof msg === 'string' ? msg : msg.label}</div>}</ErrorMessage>
			</div>}
		</>
	);
}

export default TextArea;

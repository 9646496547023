import { useState, useCallback, useRef, useEffect } from 'react';
import { useSizeCallback } from './useSizeCallback';

export function useCanvasDimension(mediaStream, videoRef, showViewJoinAttendance, inCallMessages, isReceiveSharing) {
    const [dimension, setDimension] = useState({ width: 0, height: 0 });

    const debounceRef = useRef(null);

    const debounce = useCallback((func, delay) => {
        clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(func, delay);
    }, []);

    const onCanvasResize = useCallback(({ width, height }) => {
        debounce(() => {
            setDimension({ width, height });
        }, 0);
    }, [debounce]);

    useSizeCallback(videoRef.current, onCanvasResize);

    useEffect(() => {
        const handleResize = () => {
            if (videoRef.current) {
                const { width, height } = videoRef.current.getBoundingClientRect();
                setDimension({ width, height });
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [videoRef, showViewJoinAttendance, inCallMessages, isReceiveSharing]);

    useEffect(() => {
        const { width, height } = dimension;
        try {
            if (videoRef.current) {
                videoRef.current.width = width;
                videoRef.current.height = height;
            }
        } catch (e) {
            mediaStream?.updateVideoCanvasDimension(videoRef.current, width, height);
        }
    }, [mediaStream, dimension, videoRef]);

    return dimension;
}

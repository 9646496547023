import React, { useEffect, useState } from "react";
import Button from "../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../components/common-components/button/Constants";
import Label from "../../../components/common-components/label/Label";
import SelectDropdown from "../../../components/common-components/selectDropdown";
import Icons from "../../../components/icons/Icons";
import { MET_COLORS } from "../../../libs/constant";
import AddClinicalCenterModal from "./AddClinicalCenterModal";
import ModalPortal from "../../../components/common-components/modal/ModalPortal";
import { useDispatch, useSelector } from "react-redux";
import { componentKey } from "../../../pages/Patients/AddPatient/AddPatientSlice";
import { addNotifications } from "../../../components/common-components/toaster/ToasterSlice";
import { TOASTER_VARIANT } from "../../../components/common-components/toaster/MetToaster";

const PreferencesDetails = ({ title, values, placeholder, dropdownOptions, addClinicalCenterContact, storeLabel, handleRemoveCb = () => { } }) => {
    const [openAddPreferenceModal, setAddPreferenceModal] = useState()
    const { isOpenAddCenterModal } = useSelector(state => state[componentKey])
    const [selectedOption, setSelectedOption] = useState({});

    useEffect(() => {
        if (!isOpenAddCenterModal) {
            setAddPreferenceModal(false)
        }
    }, [isOpenAddCenterModal])

    const dispatch = useDispatch()
    const message = "Already Exists";
    return (
        <>
            <div className='flex-1 w-1/4 min-w-410 max-w-450'>
                <div className='flex justify-between items-center'>
                    <Label fontWeight='bold'>{title}</Label>
                    <Button onClickCb={() => setAddPreferenceModal(true)} variant={BUTTON_VARIANTS.TEXT} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} customBtnClass='text-met-primary'>Add New</Button>
                </div>
                <div>
                    <SelectDropdown
                        value={selectedOption}
                        placeholder={placeholder}
                        onChangeCb={(selectedOpt) => {
                            if (!selectedOpt?.value) return
                            const similarOpt = values[storeLabel]?.find(item => item[(title).toLowerCase()].uuid === selectedOpt.uuid)
                            if (similarOpt) {
                                dispatch(addNotifications({ message: `${title} ${message}`, variant: TOASTER_VARIANT.ERROR }))
                                setSelectedOption({ label: "", value: Math.random() })
                            } else {
                                addClinicalCenterContact(storeLabel, selectedOpt, title)
                                setSelectedOption({ label: "", value: Math.random() })
                            }
                        }
                        } options={dropdownOptions} />
                </div>

                <div className="flex flex-col row-gap-10">
                    {values[storeLabel]?.map((pref, index) => {
                        const { name, address, city, state, country, zip, contactNumber, fax } = pref[(title).toLowerCase()]
                        return <React.Fragment key={"preferences-" + title + index}>
                            <div>
                                <div className='mt-2'>
                                    <Label fontWeight='bold'>{name}</Label>
                                </div>
                                <div className='mt-2 flex flex-col row-gap-15'>
                                    <div className='flex items-center column-gap-10px'>
                                        <Icons iconName={"locationIcon"}></Icons>
                                        <Label fontSize="md" color={"gray-500"}>{address}, {city}, {state}, {country} {zip}</Label>
                                    </div>
                                    <div className='flex  items-center  column-gap-10px'>
                                        <Icons iconName={"phoneIcon"}></Icons>
                                        <Label fontSize="md" color={"gray-500"}>{contactNumber}</Label>
                                    </div>
                                    <div className='flex  items-center  column-gap-10px'>
                                        <Icons iconName={"faxIcon"}></Icons>
                                        <Label fontSize="md" color={"gray-500"}>{fax}</Label>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-end'>
                                <Button variant={BUTTON_VARIANTS.OUTLINED}
                                    customBtnClass="bg-red-50 text-red-500 border-red-500"
                                    startIcon={{ icon: <Icons iconName="trashIcon" /> }} onClickCb={() => handleRemoveCb(index, values, storeLabel)}>Delete</Button>
                            </div>
                        </React.Fragment>
                    })}
                </div>
            </div>


            {openAddPreferenceModal ?
                <ModalPortal>
                    <AddClinicalCenterModal
                        open={openAddPreferenceModal}
                        close={() => setAddPreferenceModal(false)}
                        label={title}
                    />
                </ModalPortal> : null}
        </>
    );
};

export default PreferencesDetails;
import store from '../../../../store/store';

export const componentKey = 'PATIENTLAB_SECTION';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setLabResultId: (state, action) => {
            state.currentLabResultId = action.payload;
        },
        setAllLabResultData: (state, action) => {
            state.allLabResultData = action.payload;
        },
        setLabResultData: (state, action) => {
            state.labResultData = action.payload;
        },
        setIsLabResultModalOpen: (state, action) => {
            state.isLabResultModalOpen = action.payload;
        },
        setIsNewLabResultAdd: (state, action) => {
            state.isNewLabResultAdd = action.payload;
        },
        setAllLabsData: (state, action) => {
            state.allLabsData = action.payload;
        },
        setIsOpenDeleteLabResultPopup: (state, action) => {
            state.isOpenDeleteLabResultPopup = action.payload;
        },
        setSelectedRowData: (state, action) => {
            state.selectedRowData = action.payload
        }
    },
    initialReducerState: {
        currentLabResultId: '',
        allLabResultData: [],
        labResultData: {},
        isLabResultModalOpen: false,
        isNewLabResultAdd: false,
        allLabsData: [],
        isOpenDeleteLabResultPopup: false,
        selectedRowData: {}
    },
});

export const { setLabResultId, setAllLabResultData, setLabResultData, setIsLabResultModalOpen, setIsNewLabResultAdd, setAllLabsData, setIsOpenDeleteLabResultPopup, setSelectedRowData } =
    actions;

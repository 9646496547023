import React, { useEffect, useState } from 'react';
import Label from '../../../components/common-components/label/Label';
import RoundFab from '../../../components/common-components/Fab/RoundFab';
import Heading from '../../../components/common-components/heading/Heading';
import { HEADING } from '../../../components/common-components/heading/Constants';
import Button from '../../../components/common-components/button/Button';
import Table from '../../../containers/Table/Index';
import { useNavigate } from 'react-router-dom';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import SearchBar from '../../../components/common-components/searchInput/SearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setPaginationState, setSelectedPatientList } from './PatientGridListSlice';
import { getPatientGridList } from './PatientGridListSaga';
import useDebounced from '../../../libs/customHooks/useDebounced';
import { getFormattedPatientAgeDob } from '../../../libs/patientUtils';
import Avatar from '../../../components/common-components/avatar';
import moment from 'moment';
import MetPopOver from '../../../components/common-components/popoverComponent/MetPopOver';
import useGarbageCollector from '../../../libs/garbagecollector/garbageCollectorHooks';
import General from '../../../libs/utility/General';
import { CVS_HEADERS, bindData } from './Constant';
import { CSVLink } from "react-csv";
import { USER_PERMISSIONS } from '../../../libs/UserPermissionsConstants';
import ShowForAllowedPermissionsOnly from '../../../containers/Wrappers/ShowForAllowedPermissionsOnly';

const PatientsGridList = () => {
    useGarbageCollector(componentKey)
    const navigate = useNavigate();
    const debounced = useDebounced(1000);

    const [showExportCSVSelection, setShowExportCSVSelection] = useState(false);

    const { patientGridListState, paginationState, selectedPatientList } = useSelector((state) => state[componentKey]);
    const dispatch = useDispatch();
    const role = localStorage.getItem('role');

    useEffect(() => {
        dispatch(getPatientGridList(paginationState));
    }, [
        dispatch,
        paginationState.limit,
        paginationState.pageNumber,
        paginationState.search,
        paginationState.status,
        paginationState.sortBy,
        paginationState.orderBy,
    ]);

    const coloumns = [
        // {
        // 	field:"id",
        // 	label:<Label fontWeight='bold'>Patient Id</Label>,
        // 	renderLogic: (row) => {

        //         return (
        //             <>
        //                 <div className="flex items-center">
        //                     <div>
        //                         <Heading
        //                             type={HEADING.H1}
        //                             color="gray-500"
        //                             fontSize={'md'}
        //                             customClasses="cursor-pointer"
        //                         >
        //                             {UUIDWithUpperCase}
        //                         </Heading>
        //                     </div>
        //                 </div>
        //             </>
        //         );
        //     },
        // },
        {
            field: 'PatientName',
            label: <Label fontWeight="bold">Patient Name</Label>,
            renderLogic: (row) => {
                const formattedUUID = row?.uuid.substring(0, 5)
                const UUIDWithUpperCase = formattedUUID.charAt(0).toUpperCase() + formattedUUID.slice(1);
                return (
                    <>
                        <div className="flex items-start column-gap-10px">
                            <Avatar
                                customClass="w-[40px] h-[40px] rounded-full"
                                url={row?.profilePicture}
                                name={`${row.firstName} ${row.lastName}`}
                            />
                            <div>
                                <Heading
                                    onClickCb={() => {
                                        navigate(row.uuid);
                                    }}
                                    fontWeight="bold"
                                    type={HEADING.H1}
                                    color="met-primary"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {`${row.firstName} ${row.lastName}`}
                                </Heading>
                                <p className='text-gray-500'>{`#${UUIDWithUpperCase}`}</p>
                                {/* <Label color="gray-500">{row.subLabel}</Label> */}
                            </div>
                        </div>
                    </>
                );
            },
            sort: 'firstName',
        },
        {
            field: 'dob',
            label: <Label fontWeight="bold">Date of Birth</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                            {getFormattedPatientAgeDob(row.dob)}
                        </Heading>
                        <Label color="gray-500">{`${General.camelToPascalWithSpaces(row.gender)}`}</Label>
                    </>
                );
            },
            sort: 'dob',
        },
        {
            field: 'contactDetails',
            label: <Label fontWeight="bold">Contact Details</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        {row?.Address?.map((address, index) => (
                            <div key={index}>
                                <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                                    <MetPopOver>
                                        {`${address?.addressLine1 ? address?.addressLine1 + ', ' : ''}${address?.addressLine2 ? address?.addressLine2 + ', ' : ''
                                            }${address?.city ? address?.city + ', ' : ''}${address?.state ? address?.state + ', ' : ''
                                            } ${address?.country ? address?.country + ', ' : ''} ${address.zip}`}
                                    </MetPopOver>
                                </Heading>
                                <Label color="gray-500">{row.mobileNumber}</Label>
                                <div>
                                    <Label color="met-primary">{row.email}</Label>
                                </div>
                            </div>
                        ))}
                    </>
                );
            },
            sort: 'mobileNumber',
        },
        {
            field: 'pcp',
            label: <Label fontWeight="bold">Primary Care Provider (PCP)</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                            {row.provider?.firstName + ' ' + row.provider?.lastName}
                        </Heading>
                    </>
                );
            },
            sort: 'firstName',
        },
        {
            field: 'lastVisit',
            label: <Label fontWeight="bold">Last Visit</Label>,
            renderLogic: (row) => {
                const formattedDate = row.lastVisit ? moment(row.lastVisit).format('ddd MMM DD YYYY') : '-';
                return (
                    <>
                        <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                            {formattedDate}
                        </Heading>
                    </>
                );
            },
            sort: 'lastVisit',
        },
    ];
    const handleExportCsv = () => {
        setShowExportCSVSelection(true);
    };
    return (
        <>
            <div className="flex items-center justify-between">
                <Heading fontSize="2xl" fontWeight="bold" type={HEADING.H1}>
                    Patients
                </Heading>
                <div className="flex items-end column-gap-10px">
                    {showExportCSVSelection ? (
                        <>
						{selectedPatientList.length >0 && (
									<CSVLink data={bindData(selectedPatientList)} headers={CVS_HEADERS}>
										<Button
											customBtnClass="text-met-primary mt-0"
											onClickCb={() => setShowExportCSVSelection(false)}
										>
											Done
										</Button>
									</CSVLink>
							)}
                            <Button
                                customBtnClass="text-met-primary mt-0"
                                onClickCb={() => setShowExportCSVSelection(false)}
                            >
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <>
                            <SearchBar
                                onChangeCb={(e) => {
                                    debounced(() => {
                                        dispatch(setPaginationState({ search: e.target.value, pageNumber: 1 }));
                                    });
                                }}
                                placeholder="Search by patient name, DOB"
                            />

                            <SelectDropdown
                                onChangeCb={(val) => {
                                    dispatch(setPaginationState({ status: val.value }));
                                }}
                                placeholder="Select Status"
                                value={{ label: 'All', value: 'All' }}
                                options={[
                                    { label: 'All', value: 'All' },
                                    { label: 'Active', value: true },
                                    { label: 'Inactive', value: false },
                                ]}
                            />
                            <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.EXPORT_PATIENTS}>
                                <Button variant={BUTTON_VARIANTS.TEXT} startIcon={{ icon: "fileUpload" }} customBtnClass="text-met-primary mt-0" onClickCb={handleExportCsv}>
                                    Export CSV
                                </Button>
                            </ShowForAllowedPermissionsOnly>
                            <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.ADD_PATIENT}>
                            {role !=="Biller" &&  <Button
                                    onClickCb={() => {
                                        navigate('add/:id/abha');
                                    }}
                                    variant={BUTTON_VARIANTS.CONTAINED}
                                    startIcon={{ icon: 'plusVectorIcon', color: 'white' }}
                                    customBtnClass="text-met-primary"
                                >
                                    Add Patient
                                </Button>
}
                            </ShowForAllowedPermissionsOnly>
                        </>
                    )}
                </div>
            </div>
            <Table
                selectProps={{
                    isSelectAll: showExportCSVSelection,
                    isSelectable: showExportCSVSelection,
                    onSelectAll: (data) => dispatch(setSelectedPatientList(data)),
                    onSelectRowsCb: (data) => dispatch(setSelectedPatientList(data)),
                    selectIdentifier: 'dob',
                }}
                coloumns={coloumns}
                rows={patientGridListState}
                paginationProps={{
                    isPagination: true,
                    totalCount: paginationState.total,
                    limit: paginationState.limit,
                    onPageChange: (page) => {
                        dispatch(setPaginationState({ pageNumber: page }));
                    },
                }}
                sorting={{
                    isSortable: true,
                    onSortChangeCb: (sortKey) => {
                        dispatch(setPaginationState({ orderBy: paginationState.orderBy === 'asc' ? 'desc' : 'asc' }));
                        dispatch(setPaginationState({ sortBy: sortKey }));
                    },
                }}
            />
        </>
    );
};

export default PatientsGridList;

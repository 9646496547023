import React from 'react'

const FileUpload = ({ color = '#1b5984', width = '24px', height = '24px' }) => {
    return (
        <svg id="file_upload_black_24dp_1_" data-name="file_upload_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width={width}
            height={height} viewBox="0 0 24 24">
            <g id="Group_91948" data-name="Group 91948">
                <rect id="Rectangle_36787" data-name="Rectangle 36787" width="24" height="24" fill="none" />
            </g>
            <g id="Group_91949" data-name="Group 91949">
                <path id="Path_206114" data-name="Path 206114" d="M18,15v3H6V15H4v3a2.006,2.006,0,0,0,2,2H18a2.006,2.006,0,0,0,2-2V15ZM7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9,12,4Z" fill={color} />
            </g>
        </svg>
    )
}

export default FileUpload
import React, { forwardRef, useEffect } from 'react';
import Checkbox from '../../../components/common-components/checkbox/Checkbox';
import Label from '../../../components/common-components/label/Label';
import ToggleSwitch from '../../../components/common-components/toggleSwitch/ToggleSwitch';
import { Form, Formik } from 'formik';
import { PRIVACY_CONSENT_FILES } from './Constants';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientDetails, updatePatientDetails } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import { componentKey, setCreatePatientData, setEditDemographicsData, setIsFormSubmitted } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import { useParams } from 'react-router-dom';
import store from '../../../store/store';
import * as Yup from 'yup';

const PrivacyConsent = forwardRef(function PrivacyConsent({ isUpdate = false, onUpdateCb = null }, formRef) {
	const { patientId } = useParams();
	const dispatch = useDispatch()
	const { newlyAddedPatientId, fetchedPatientDetails, isFormSubmitted } = useSelector(state => state[componentKey])

	useEffect(() => {
		return () => {
			if (isUpdate) {
				store.reducerManager.remove(componentKey);
			}
		}
	}, [])

	useEffect(() => {
		if (patientId) {
			dispatch(getPatientDetails({ patientId }))
		}
	}, [dispatch, patientId])

	const schema = Yup.object().shape({
		[PRIVACY_CONSENT_FILES.EMAIL_CONSENT]: Yup.boolean(),
		[PRIVACY_CONSENT_FILES.MESSAGE_CONSENT]: Yup.boolean(),
		[PRIVACY_CONSENT_FILES.CALL_CONSENT]: Yup.boolean(),
	}).test('at-least-one-checked', null, (values) => {
		const { [PRIVACY_CONSENT_FILES.EMAIL_CONSENT]: emailConsent,
			[PRIVACY_CONSENT_FILES.MESSAGE_CONSENT]: messageConsent,
			[PRIVACY_CONSENT_FILES.CALL_CONSENT]: callConsent } = values;

		if (!(emailConsent || callConsent || messageConsent)) {
			return new Yup.ValidationError('At least one consent must be checked', null, '_error');
		}
		return true;
	});


	return (
		<Formik
			innerRef={formRef}
			initialValues={{
				[PRIVACY_CONSENT_FILES.EMAIL_CONSENT]: false,
				[PRIVACY_CONSENT_FILES.MESSAGE_CONSENT]: false,
				[PRIVACY_CONSENT_FILES.CALL_CONSENT]: true,
				[PRIVACY_CONSENT_FILES.PATIENT_STATUS]: true,
				...fetchedPatientDetails,
			}}
			validationSchema={schema}
			enableReinitialize
			onSubmit={(values) => {
				const payload = {
					consentToCall: values.consentToCall,
					consentToEmail: values.consentToEmail,
					consentToMessage: values.consentToMessage,
					status: values.status
				}
				if (onUpdateCb) {
					return onUpdateCb(payload)
				}
				dispatch(updatePatientDetails({ patientId: newlyAddedPatientId, payload: payload }))
				dispatch(setIsFormSubmitted({ privacyConsent: false }))
				dispatch(setCreatePatientData(payload))
				dispatch(setEditDemographicsData(payload))
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, errors, touched, ...props }) => {
				return <Form onSubmit={handleSubmit} >
					<div className="custom-provider-information mt-4 mb-4 bg-opacity-3 rounded-md">
						<div className="flex column-gap-20px row-gap-15  flex-wrap p-4">
							<div>
								<Checkbox
									label="Consent To Email"
									name={PRIVACY_CONSENT_FILES.EMAIL_CONSENT}
									checked={values[PRIVACY_CONSENT_FILES.EMAIL_CONSENT]}
									onChangeCb={(val) => setFieldValue(PRIVACY_CONSENT_FILES.EMAIL_CONSENT, val)}
									disabled={isFormSubmitted.privacyConsent}
								/>
							</div>
							<div>
								<Checkbox
									label="Consent To Call"
									name={PRIVACY_CONSENT_FILES.CALL_CONSENT}
									checked={values[PRIVACY_CONSENT_FILES.CALL_CONSENT]}
									onChangeCb={(val) => setFieldValue(PRIVACY_CONSENT_FILES.CALL_CONSENT, val)}
									disabled={isFormSubmitted.privacyConsent}
								/>
							</div>
							<div>
								<Checkbox
									label="Consent To Message"
									name={PRIVACY_CONSENT_FILES.MESSAGE_CONSENT}
									checked={values[PRIVACY_CONSENT_FILES.MESSAGE_CONSENT]}
									onChangeCb={(val) => setFieldValue(PRIVACY_CONSENT_FILES.MESSAGE_CONSENT, val)}
									disabled={isFormSubmitted.privacyConsent}
								/>
							</div>
						</div>
						{errors._error && <div name='vital_error' className="error-text p-2 px-3 text-center">{errors._error}</div>}
						<div className="flex column-gap-20px row-gap-15  flex-wrap pt-2 pb-4 pl-4 pr-4">
							<div>
								<Label fontWeight="bold" fontSize="sm">Patient Status</Label>
							</div>
							<div>
								<ToggleSwitch
									name={PRIVACY_CONSENT_FILES.PATIENT_STATUS}
									checked={values[PRIVACY_CONSENT_FILES.PATIENT_STATUS]}
									onChangeCb={(val) => setFieldValue(PRIVACY_CONSENT_FILES.PATIENT_STATUS, val)}
								/>
							</div>
						</div>
					</div>
				</Form>
			}}
		</Formik >
	);
})

export default PrivacyConsent;

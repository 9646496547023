import React from 'react'
import ModalComponent from '../../../components/common-components/modal/ModalComponent'
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants'
import Icons from '../../../components/icons/Icons'
import Button from '../../../components/common-components/button/Button'
import Input from '../../../components/common-components/input/Input'
import SignaturePadWrapper from '../../../components/common-components/SignaturePad/SignaturePadWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey as PatientEncounterComponentKey, setIsSignatureModalOpen } from "../../Encounter/EncounterSlice"
import { postEncounterDetails } from '../../Encounter/EncounterSaga'
import { componentKey as schedulingComponentKey } from '../../Scheduling/ShedulingSlice'
import moment from 'moment'

function SignModal({ close, open }) {

    const { encounterDataById } = useSelector(state => state[PatientEncounterComponentKey])
    const { checkedInData } = useSelector(state => state[schedulingComponentKey])

    const dispatch = useDispatch()

    const handleSignAndSave = () => {
        if (encounterDataById && encounterDataById.uuid) {
            const data = {
                patinetId: encounterDataById.patientId,
                providerId: encounterDataById.providerId,
                scheduleId: encounterDataById.scheduleId,
                data: encounterDataById.encounterForms.data,
                isCreateEncounter: false,
                encounterId: encounterDataById.uuid,
                encounterFormsId: encounterDataById.encounterFormsId,
                status: 'Signed',
            }
            dispatch(postEncounterDetails({ encounterData: data, signAndSave: true }))
            dispatch(setIsSignatureModalOpen(false))
        }
    }

    return (
        <div>
            <ModalComponent
                open={open}
                title={"Sign And Lock Diagnosis And Treatment Plan"}
                footerButton={
                    <Button
                        type="submit"
                        variant={BUTTON_VARIANTS.CONTAINED}
                        customBtnClass="text-met-primary"
                        onClickCb={() => { handleSignAndSave() }}
                    >
                        Sign & Lock
                    </Button>
                }
                icon={<Icons iconName="closeIcon" />}
                close={close}
                customClasses="w-[869px]"
            >
                <div className='p-2'>
                    <div className='flex column-gap-20px'>
                        <div className='w-[50%]'>
                            <div>
                                <Input
                                    placeholder={'Enter name'}
                                    label="Name"
                                    value={`${checkedInData?.Provider?.firstName}` + ` ${checkedInData?.Provider?.lastName}`}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='w-[50%] flex items-end bg-blue-100 border-2 border-blue-300 p-4'>
                            <div className='flex flex-col'>
                                <div className='fs-20 font-bold mb-2'>{`${checkedInData?.Provider?.firstName}` + ` ${checkedInData?.Provider?.lastName}`}</div>
                                <div className=''>{`Signed by ${checkedInData?.Provider?.firstName}` + ` ${checkedInData?.Provider?.lastName}`}</div>
                                <div className=''>{moment(new Date()).format('MMMM DD, YYYY hh:mm A')}</div>
                            </div>
                            {/* <SignaturePadWrapper /> */}
                        </div>
                    </div>
                </div>

            </ModalComponent>
        </div>
    )
}

export default SignModal
export const FORM_FIELDS_NAMES = {
    PRIMARY_PROVIDER: "providerId",
    PROVIDER_GROUP_LOCATION: "providerLocationId",
    REGISTRATION_DATE: "registrationDate",
}

export const FORM_FIELDS_FOR_CHECK_IN = {
	DATE: "date",
	LOCATION_ID: "locationId",
	APPOINTMENT_TYPE: "appointmentType",
	PROVIDER_ID: "providerId",
};
import React, { useEffect, useState } from 'react'
import CardContainer from '../../../../components/common-components/Cards/Container/CardContainer'
import Icons from '../../../../components/icons/Icons'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Heading from '../../../../components/common-components/heading/Heading'
import { useNavigate } from 'react-router-dom'
import Avatar from '../../../../components/common-components/avatar'
import { useSelector } from 'react-redux'
import { componentKey } from './GenrateSuperBillSlice'
import General from '../../../../libs/utility/General'


function SuperBillHeader() {
    const navigate = useNavigate()
    const [isEditSuperBill, setIsEditSuperBill] = useState(false)
    const { patientDetails, billData, paymentData } = useSelector((state) => state[componentKey]);
    const { dob, uuid } = patientDetails || {}
    const formattedDOB = General.getformattedDate(dob)
    const formattedPatientID = uuid?.substring(0, 5)
    const patientID = General.camelToPascalWithSpaces(formattedPatientID)

    useEffect(() => {
        if (Object.keys(billData)?.length > 0) {
            setIsEditSuperBill(true)
        }
    }, [billData])

    const { mapping_billing_payment } = billData || {}

    return (
        <CardContainer>
            <div className='flex items-center column-gap-40px'>
                <div>
                    <Heading fontSize="xl" onClickCb={() => {
                        navigate("/billing/superbill")
                    }} fontWeight="bold" type={HEADING.H1} customClasses="flex column-gap-10px items-center cursor-pointer">
                        <Icons iconName={"arrowBackIcon"} />  {isEditSuperBill && !mapping_billing_payment?.length ? 'Edit' : !mapping_billing_payment?.length ? 'Create' : "View"} Super Bill
                    </Heading>
                </div>
                <div className='flex column-gap-20px'>
                    <div>
                        <Avatar textCenter="middle"
                            customClass="w-[32px] h-[32px] overflow-hidden object-cover rounded-full"
                            fontSize={3} url={patientDetails.profilePicture}
                            name={`${patientDetails.firstName || ""} ${patientDetails.lastName || ""}`}
                        />
                    </div>
                    <div className='fs-20 font-bold text-met-primary'>
                        {`${patientDetails.firstName || ""} ${patientDetails.lastName || ""} (${patientID}) (${formattedDOB})`}
                    </div>

                </div>
            </div>

        </CardContainer >

    )
}

export default SuperBillHeader
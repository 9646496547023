import React from 'react';
import Icons from '../../icons/Icons';
import Label from '../label/Label';

function ImageAndInfoCard({ iconName = "", trendIconName = "", counts = "", cardLabel = "", subLabel = "", handleClick = () => { } }) {

    return (
        <div>
            <div className='flex h-[120px] box-shadow-1 rounded-lg items-center bg-white' onClick={handleClick}>
                <div className='flex column-gap-20px items-center'>
                    <div className="flex items-center justify-center pl-2">
                        {iconName && <Icons iconName={iconName} height={'40px'} width={"40px"} />}
                    </div>
                    <div className='row-gap-10'>
                        <div>
                            {counts &&
                                <div>
                                    <Label color="met-primary" fontSize="xl" fontWeight='bold'>{counts}</Label>
                                </div>
                            }
                            {cardLabel &&
                                <div>
                                    <Label customClass='cursor' fontSize="xl">{cardLabel}</Label>
                                </div>
                            }
                        </div>
                        <div className='flex items-center'>
                            {trendIconName &&
                                <div className='pr-2'>
                                    <Icons iconName={trendIconName} />
                                </div>}
                            {subLabel &&
                                <div>
                                    <Label color="gray-500">{subLabel}</Label>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageAndInfoCard;
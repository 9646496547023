import { put, takeLatest, all } from "redux-saga/effects";
import PatientDataService from "../../../../services/PatientDataServices";
import store from "../../../../store/store";
import { componentKey, setOpenSuccessModal } from "./PortalAccessSlice";
import { getPatientDetails } from "../../AddPatient/AddPatientSaga";
import { addNotifications } from "../../../../components/common-components/toaster/ToasterSlice";
import { TOASTER_VARIANT } from "../../../../components/common-components/toaster/MetToaster";

export const {
    invitePatient,
    resendInvitePortalAccess,
    revokePortalAccess
} = {
    invitePatient: (payload) => {
        return {
            type: 'PORTAL_ACCESS/INVITE_PORTAL_ACCESS',
            payload,
        };
    },
    resendInvitePortalAccess: (payload) => {
        return {
            type: 'PORTAL_ACCESS/RESEND_PORTAL_ACCESS',
            payload,
        };
    },
    revokePortalAccess: (payload) => {
        return {
            type: 'PORTAL_ACCESS/DISABLE_PORTAL_ACCESS',
            payload,
        };
    },
};

function* invitePatientAsync(action) {
    try {
        const { patientId } = action.payload
        const response = yield PatientDataService.invitePortalAccess(patientId)
        const { status, data, success, message } = response.data
        if (status) {
            yield put(setOpenSuccessModal(true))
            yield put(getPatientDetails({ patientId }))
        }
    } catch (error) {
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
        console.log('err: ', error)
    }
}

function* resendInvitePortalAccessAsync(action) {
    try {
        const { patientId } = action.payload
        const response = yield PatientDataService.resendPortalAccess(patientId)
        const { status, data, message } = response.data
        if (status) {
            yield put(setOpenSuccessModal(true))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}
function* revokePortalAccessAsync(action) {
    try {
        const { patientId } = action.payload
        const response = yield PatientDataService.revokedPortalAccess(patientId)
        const { status, data, success, message } = response.data
        if (status) {
            yield put(getPatientDetails({ patientId }))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* rootSaga() {
    yield all([
        takeLatest(invitePatient().type, invitePatientAsync),
        takeLatest(resendInvitePortalAccess().type, resendInvitePortalAccessAsync),
        takeLatest(revokePortalAccess().type, revokePortalAccessAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

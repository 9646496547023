import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import Icons from '../../../components/icons/Icons';
import Button from '../../../components/common-components/button/Button';
import Avatar from '../../../components/common-components/avatar';
import moment from 'moment';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import RadioButton from '../../../components/common-components/radioButton/RadioButton';
import {
	componentKey as SchedulerKey,
	setAvailableSlotsOfProvider,
	setIsSuccessPopUpModalOpen,
	setSelectedProvidersLocation,
} from '../ShedulingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { componentKey } from '../../../containers/Wrappers/Dataloader/DataloaderSlice';
import General from '../../../libs/utility/General';
import Label from '../../../components/common-components/label/Label';
import DatePicker from '../../../components/common-components/datePicker';
import { getProviderLocations, patchScheduledAppointment, postAppointmentsSlots } from '../SchedulingSaga';
import Input from '../../../components/common-components/input/Input';
import { FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT } from '../constant';
import SucessPopUp from '../../../components/common-components/popup-components/SucessPopUp';
const RescheduleAppointment = ({ close, appointmentDetails }) => {
	const [selectedTimeOfAppointment, setSelectedTimeOfAppointment] = useState(null);
	const [isDatePickerTouched, setIsDatePickerTouched] = useState(false);
	const {
		isRescheduleAppointmentModalOpen,
		statusOfAppointment,
		availableSlotsOfProvider,
		selectedProvidersLocation,
	} = useSelector((state) => state[SchedulerKey]);
	const { providersList } = useSelector((state) => state[componentKey]);
	const dispatch = useDispatch();

	const locations = selectedProvidersLocation?.map((item) => {
		return {
			...item.provider_locations,
			label: item.provider_locations.name,
			value: item.provider_locations.uuid,
		};
	});

	useEffect(() => {
		dispatch(getProviderLocations({ providerId: appointmentDetails?.Provider?.uuid }));
	}, []);

	const currentDate = moment();
	const ageOfPerson = currentDate.diff(appointmentDetails?.Patient?.dob, 'years');
	const gender = General.camelToPascalWithSpaces(appointmentDetails?.Patient?.gender);
	const formattedUUID = appointmentDetails?.uuid.substring(0, 5);
	const UUIDWithUpperCase = formattedUUID?.charAt(0)?.toUpperCase() + formattedUUID?.slice(1);
	return (
		<>
			<Formik
				initialValues={{
					[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.MODE]: appointmentDetails?.Appointment.mode,
					[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.APPOINTMENT_TYPE]: General.getLabelValue(
						appointmentDetails?.appointmentType
					),
					[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.LOCATION]: General?.getLabelValue(
						appointmentDetails?.Locations?.uuid
					),
					[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.CHIEF_COMPLAINT]: appointmentDetails?.complaints,
					[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.START]: appointmentDetails?.start,
					[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.START_DATE]: appointmentDetails?.Appointment?.startDate,
					[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.PROVIDER]: General.getLabelValue(
						appointmentDetails?.Provider?.uuid
					),
				}}
				// validationSchema={validationSchema}
				enableReinitialize
				onSubmit={(values) => {
					const payload = {
						...values,

						providerId: appointmentDetails?.Provider?.uuid,
						locationId: values?.locationId?.uuid,
						appointmentType: values?.appointmentType?.value,
						status: statusOfAppointment,
					};
					const selectedProvider = providersList.find(
						(item) => item.uuid == appointmentDetails?.Provider?.uuid
					);
					dispatch(
						patchScheduledAppointment({
							scheduleId: appointmentDetails?.uuid,
							editScheduledData: payload,
							selectedProvider,
							selectedPatientData: appointmentDetails?.Patient,
						})
					);
				}}
			>
				{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
					return (
						<>
							<Form onSubmit={handleSubmit}>
								<ModalComponent
									open={isRescheduleAppointmentModalOpen}
									// validationSchema={validationSchema}
									title={'Reschedule Appointment'}
									close={() => {
										close();
										dispatch(setAvailableSlotsOfProvider([]));
									}}
									icon={<Icons iconName="closeIcon" />}
									footerButton={
										<Button
											type="submit"
											variant={BUTTON_VARIANTS.CONTAINED}
											customBtnClass="text-met-primary"
										>
											Save
										</Button>
									}
									customClasses="w-[50%] overflow-visible"
									enableReinitialize
									// hideOverflow={true}
								>
									<div>
										{
											<div className="flex ">
												<div className="w-[10%]">
													<Avatar
														height={64}
														width={64}
														url={appointmentDetails?.Patient.profilePicture}
														name={`${appointmentDetails?.Patient?.firstName} ${appointmentDetails?.Patient?.lastName} `}
													/>
												</div>
												<div className="w-[70%]">
													<div>
														<p className="font-bold">{`${
															appointmentDetails?.Patient?.firstName
														} ${appointmentDetails?.Patient?.lastName} (${
															UUIDWithUpperCase || '-'
														})`}</p>
													</div>
													<div className="flex justify-between">
														<p className="w-[20%]">
															{moment(appointmentDetails?.Patient?.dob).format(
																'DD-MM-YYYY'
															)}
														</p>
														<p>{`${ageOfPerson}yrs`}</p>
														<p>{gender || '-'}</p>

														<p className="flex w-[20%]">
															<span>
																<Icons iconName="mobileIcon" />
															</span>
															{appointmentDetails?.Patient?.mobileNumber || '-'}
														</p>
														<p className="flex w-[20%]">
															<span>
																<Icons iconName="emailIcon" color={'#1A1A1A99'} />
															</span>
															{/* {selectedPatientData?.email}
															 */}
															{appointmentDetails?.Patient?.email}
														</p>
													</div>
												</div>
												{/* <div className="w-[20%]">
													<p className="" onClick={() => setIsSelectedPatient(false)}>
														Change Patient
													</p>
												</div> */}
											</div>
										}
										{/* {!isselectedPatient && (
											<div className="flex justify-between">
												<div className="w-[75%] mt-1">
													<SelectDropdown
														placeholder="Select Patient"
														label="Patient Name"
														isImageInOption={true}
														isRequired={true}
														// name={FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.PATIENT_NAME}
														value={
															[]
															// values[
															// FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.PATIENT_NAME
															// ]
														}
														onChangeCb={(val) => {
															setFieldValue(
																// FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.PATIENT_NAME,
																val
															);
														}}
														options={patientList}
														renderOption={defaultRenderOption}
													/>
												</div>
											</div>
										)} */}
									</div>
									<div className="flex justify-between">
										<div>
											<div className="flex column-gap-16 row-gap-16  flex-wrap mt-5">
												<div>
													<Label fontWeight="bold">Appointment Mode</Label>
												</div>
											</div>
											<div className="flex column-gap-16 row-gap-16  flex-wrap pr-4 pt-2 pb-2 pl-4">
												<div className="flex flex-row  gap-6">
													<div>
														<RadioButton
															name={FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.MODE}
															value={
																values[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.IN_PERSON]
															}
															// checked={radioButtons.isInPersonChecked}
															checked={
																values[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.MODE] ===
																FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.IN_PERSON
															}
															onChangeCb={(val) => {
																// handleRadioButtonChange('isInPersonChecked');
																setFieldValue(
																	FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.MODE,
																	FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.IN_PERSON
																);
															}}
															label={'In_Person'}
														/>
													</div>

													<div>
														<RadioButton
															name={FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.MODE}
															value={FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.VIDEO_CALL}
															// checked={radioButtons.isVideoCallChecked}
															checked={
																values[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.MODE] ===
																FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.VIDEO_CALL
															}
															onChangeCb={() => {
																// handleRadioButtonChange('isVideoCallChecked');
																setFieldValue(
																	FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.MODE,
																	FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.VIDEO_CALL
																);
															}}
															label={'Video Call'}
														/>
													</div>

													<div>
														<RadioButton
															name={FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.MODE}
															value={FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.VOICE_CALL}
															// checked={radioButtons.isVoiceCallChecked}
															checked={
																values[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.MODE] ===
																FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.VOICE_CALL
															}
															onChangeCb={() => {
																// handleRadioButtonChange('isVoiceCallChecked');
																setFieldValue(
																	FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.MODE,
																	FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.VOICE_CALL
																);
															}}
															label={'Voice Call'}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="mt-5 w-[45%]">
											<SelectDropdown
												placeholder="Select Type"
												label="Appointment Type"
												isRequired={true}
												name={FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.APPOINTMENT_TYPE}
												value={
													values[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.APPOINTMENT_TYPE] ||
													[]
												}
												onChangeCb={(val) => {
													setFieldValue(
														FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.APPOINTMENT_TYPE,
														val
													);
												}}
												options={[
													{ label: 'New Appointment', value: 'New_Appointment' },
													{ label: 'Follow Up', value: 'Follow_Up' },
												]}
											/>
										</div>
									</div>
									<div className="flex justify-between flex-wrap mt-4">
										<div className="w-[45%] mt-2">
											<SelectDropdown
												placeholder="Select Provider"
												label="Provider Name"
												isRequired={true}
												name={FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.PROVIDER}
												value={values[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.PROVIDER] || []}
												// onChangeCb={(val) => {
												// 	setFieldValue(FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.PROVIDER, val);
												// }}
												options={providersList}
												disabled={true}
											/>
										</div>
										{values[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.MODE] ===
											FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.IN_PERSON && (
											<div className="w-[45%] mt-2">
												<SelectDropdown
													placeholder="Select Location"
													label="Location"
													isRequired={true}
													name={FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.LOCATION}
													value={
														values[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.LOCATION] || []
													}
													onChangeCb={(val) => {
														setFieldValue(
															FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.LOCATION,
															val
														);
														dispatch(
															getProviderLocations({
																providerId: appointmentDetails?.Provider?.uuid,
															})
														);
													}}
													options={locations}
												/>
											</div>
										)}
										<div className="w-[45%] mt-2"></div>
									</div>
									{/* <div className="w-full mt-4">
										<DatePicker
											label="Date & Time"
											open={false}
											placeholder="Date"
											isRequired={true}
											onFocusCb={() => {
											}}
											name={FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.DATE_AND_TIME}
											// value={values[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.DATE_AND_TIME]}
											onChangeCb={(date) => {
												setIsDatePickerTouched(!isDatePickerTouched);
											}}
											/>
										</div> */}
									{/* {isDatePickerTouched && ( */}
									<div className="flex w-full justify-between mt-4">
										<div className="w-[35%]">
											<DatePicker
												label="Date & Time"
												isRequired={true}
												showMonthDropdown={false}
												placeholder="Choose Date"
												showYearDropdown={false}
												value={values[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.START_DATE]}
												onChangeCb={(date) => {
													setIsDatePickerTouched(!isDatePickerTouched);
													// dispatch(setActiveDate(date));
													setFieldValue(
														FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.START_DATE,
														date
													);
													console.log(values.mode);
													dispatch(
														postAppointmentsSlots({
															patientId: appointmentDetails?.Patient?.uuid,
															providerId: appointmentDetails?.Provider.uuid,
															locationId:
																values.mode === 'In_Person'
																	? appointmentDetails?.Locations?.uuid
																	: undefined,

															appointmentType:
																values?.appointmentType?.value ||
																appointmentDetails?.Appointment?.appointmentType,
															startDate: date,
															mode:
																values.mode === 'In_Person'
																	? values.mode
																	: appointmentDetails?.Appointment?.mode,
														})
													);
												}}
												customClass="w-full"
												minDate={new Date()}
											/>
										</div>
										<div className="w-[60%]">
											{Object.keys(availableSlotsOfProvider || {}).map((key, index) => {
												const slots = availableSlotsOfProvider[key] || [];
												if (
													slots.length === 0 &&
													(key === 'morning' || key === 'afternoon' || key === 'evening')
												) {
													return <div key={index}>"No slots available for {key}"</div>;
												}
												return (
													<div key={index} className="w-full p-1">
														<Label fontWeight="semi-bold">{key}</Label>
														<div className="flex flex-wrap">
															{slots.map((time, index) => (
																<div
																	key={index}
																	// className={`shadow-[0px_2px_8px_#00000029] rounded-5px p-2 text-[#00000029] m-2 cursor-pointer ${selectedTimeOfAppointment} ? "text-met-primary border-met-primary" : ""`}
																	className={`shadow-[0px_2px_8px_#00000029] rounded-5px p-2 text-[#00000029] m-2 cursor-pointer ${
																		selectedTimeOfAppointment === time
																			? 'text-[#0097F0] border-[1px] border-[#0097F0]'
																			: ''
																	}`}
																	onClick={() => {
																		setSelectedTimeOfAppointment(time);
																		setFieldValue(
																			FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.START,
																			time
																		);
																	}}
																>
																	<p>{time}</p>
																</div>
															))}
														</div>
													</div>
												);
											})}
										</div>
									</div>
									{/* )} */}

									<div className="w-full mt-4">
										<Input
											label="Chief Complaint"
											// isRequired={true}
											placeholder="Enter reason"
											name={FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.CHIEF_COMPLAINT}
											value={values[FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.CHIEF_COMPLAINT]}
											onChangeCb={(e) => {
												setFieldValue(
													FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT.CHIEF_COMPLAINT,
													e.target.value
												);
											}}
										/>
									</div>
								</ModalComponent>
							</Form>
						</>
					);
				}}
			</Formik>
		</>
	);
};
export default RescheduleAppointment;

import React from 'react';
import { useSelector } from 'react-redux';
import FaceSheetCard from './FaceSheetCard';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import { useNavigate } from 'react-router-dom';
import { componentKey as PatientAllergiesComponentKey } from "../../Allergies/PatientAllergiesSlice";
import General from '../../../../../libs/utility/General';

const AlleriesCard = () => {
	const navigate = useNavigate()
	const { allergiesData } = useSelector((state) => state[PatientAllergiesComponentKey])

	const alleriesData = allergiesData.slice(0, 2).map((allergy, index) => {
		return {
			id: index + 1,
			title: allergy.name,
			subtitle: `Onset Date: ${General.getformattedDate(allergy.onsetDate)}`
		}
	})

	return (
		<FaceSheetCard
			title="ALLERGIES"
			comingFrom ={true}
			iconbtn={
				<Button
					variant={BUTTON_VARIANTS.TEXT}
					customBtnClass="fs-14 font-bold text-met-primary"
					style={{ height: '19px' }}
					onClickCb={() => navigate("../allergies")}
				>
					View All
				</Button>
			}
			data={alleriesData}
			customClassName="min-w-515 h-full"
		/>
	);
};

export default AlleriesCard;

import React, { useState } from 'react';

const ColorSelector = ({ selectedColor = '', handleColorChange, selectedRow }) => {
    const [showColorPicker, setShowColorPicker] = useState(false);

    const basicColors = [
        "#FF7F7F",
        "#FFBF7F",
        "#FFFF7F",
        "#BFFF7F",
        "#7FFF7F",
        "#7FFFBF",
        "#7FFFFF",
        "#7FBFFF",
        "#BF7FFF",
        "#FF7FFF",
        "#FFBFBF",
        "#FFDFBF",
        "#FFFFBF",
        "#DFFFBF",
        "#BFFFBF",
        "#BFFFFF",
        "#BFBFFF",
        "#DFBFFF",
        "#FFBFFF",
        "#FFDFDF",
        "#FFEFDF",
        "#FFFFDF",
        "#EFFFDF",
        "#DFFFEF",
        "#DFFFFF",
        "#DFDFFF",
        "#EFFFFF",
        "#FFDFFF",
        "#FF0000",
        "#FF7F00",
        "#FFFF00",
        "#7FFF00",
        "#00FF00",
        "#1B5984",
        "#00FFFF",
        "#007FFF",
        "#7F00FF",
        "#0000FF",
        "#FF00FF",
        "#FF007F",
    ];

    return (
        <div>
            <div
                className={`h-10 w-16 ml-2  bg-${selectedColor} cursor-pointer`}
                onClick={() => setShowColorPicker(!showColorPicker)}
            >
                <div className='h-8 w-20 rounded-md' style={{ backgroundColor: showColorPicker ? selectedColor : selectedRow.colour }}></div>
            </div>
            {showColorPicker && (
                <>
                    <div className=' bg-gray-300 rounded-md pt-1 p-2'>
                        <div className='pl-2  pt-2 fs-18'>Basic colors</div>
                        <div className="relative top-0 left-0 grid grid-cols-8 gap-1 p-[10px] rounded-md">
                            {basicColors.map((color) => (
                                <div
                                    key={color}
                                    className={`h-10 w-10 cursor-pointer border border-gray-500 ${selectedColor === color ? 'ring-2 ring-gray-600' : ''}`}
                                    style={{ backgroundColor: color }}
                                    onClick={() => handleColorChange(color)}
                                ></div>
                            ))}
                        </div>
                    </div>

                </>
            )}
        </div>
    );
};

export default ColorSelector;

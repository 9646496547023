import React, { useEffect, useRef, useState } from 'react';
import DropdownModal from '../dropdownModal';
import Icons from '../../icons/Icons';
import Input from '../input/Input';
import { MET_COLORS } from '../../../libs/constant';
import { getSelectOptions } from './selectDropdownSaga';
import { useDispatch, useSelector } from 'react-redux';
import useDebounced from '../../../libs/customHooks/useDebounced';

const SelectDropdown = ({
	label = '',
	name = '',
	startIcon,
	onChangeCb = () => { },
	isRequired = false,
	value = { label: '', value: '' },
	placeholder = '',
	options = [],
	showToggle = true,
	toggleIcon = '',
	customClasses = '',
	isMultiSelect = false,
	disabled = false,
	renderOption = undefined,
	isAsync = false,
	openDirection = "bottom",
	extractKeyFromResponse = "",
	url = "",
	labelKey,
	valueKey,
	labelKey2,
	multiSelectAll = false
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [selectedOption, setSelectedOption] = useState();
	const [filteredOptions, setFilteredOptions] = useState([]);
	const [iconRotation, setIconRotation] = useState(0);
	const [dropdownOptions, setDropdownOptions] = useState([])
	const [paginationState, setPaginationState] = useState({
		pageNumber: 1,
		limit: 10,
		totalRecords: 0,
		search: "",
	})

	const dispatch = useDispatch()

	const debounce = useDebounced(1000)

	useEffect(() => {
		if (isAsync) return;
		if (isMultiSelect) return;
		const selectedOpt = options.find((opt) => opt.value === value.value);
		setSelectedOption(selectedOpt);
	}, [value.value, options.length, isAsync, isMultiSelect]);

	useEffect(() => {
		if (isAsync) return;
		if (!isMultiSelect) return;
		const selectedOpt = options?.filter((opt) => value?.includes(opt?.value));

		setSelectedOption(selectedOpt);
	}, [value.length, options.length, isMultiSelect, isAsync]);


	//scenario when api call and not multiselect
	useEffect(() => {
		if (!isAsync) return;
		if (isMultiSelect) return;
		const selectedOpt = dropdownOptions.find((opt) => opt.value === value.value);

		setSelectedOption(selectedOpt);
	}, [value, dropdownOptions.length, isMultiSelect, isAsync]);

	useEffect(() => {
		if (isAsync) return
		let filteredOptions = [];
		filteredOptions = options.filter((opt) => opt.label && opt.label.toLowerCase().startsWith(searchText.toLowerCase()));
		setFilteredOptions(filteredOptions);
	}, [searchText, options, isAsync]);

	useEffect(() => {
		if (!isAsync) return
		setFilteredOptions(dropdownOptions);
	}, [dropdownOptions.length, isAsync])

	useEffect(() => {
		if (!url) return
		const params = {
			pageNumber: paginationState.pageNumber,
			limit: paginationState.limit,
			search: paginationState.search,
			name: paginationState?.search
		}
		dispatch(getSelectOptions({ url, params, extractKeyFromResponse: extractKeyFromResponse, labelKey, valueKey, labelKey2, addOptions, handlePaginationChange }))
	}, [dispatch, url, paginationState.pageNumber, paginationState.limit, paginationState.search, extractKeyFromResponse, labelKey, valueKey, labelKey2])

	const onCloseCb = () => {
		setIsOpen(false);
	};

	const onDropdownTouchBottomCb = () => {
		setPaginationState(prev => ({
			...prev,
			pageNumber: prev.pageNumber + 1
		}))
	}


	const handlePaginationChange = (paginationObj) => {
		setPaginationState(prev => ({ ...prev, ...paginationObj }))
	}

	const handleInputChange = (e) => {
		if (e.target.value === "") {
			setSelectedOption({})
			const val =  isMultiSelect ? [] : {}
			onChangeCb(val)
		}
		debounce(() => {
			setDropdownOptions([])
			handlePaginationChange({
				pageNumber: 1,
				search: e.target.value
			})
		})
		setSearchText(e.target.value);
		if (!isOpen) {
			setIsOpen(true);
		}
	};
	const handleIconRotation = () => {
		if (disabled) return
		setIconRotation(iconRotation === 180 ? 0 : 180);
		setIsOpen(iconRotation === 180 ? false : true);
	};

	const handleSelectOption = (option) => {
		setSelectedOption(option);
		onChangeCb(option);
		setSearchText('');
		setIsOpen(false);
	};

	const addOptions = (options) => {
		setDropdownOptions(prev => [...prev, ...options])
	}

	return (
		<div className={`relative ${customClasses}`}>
			<Input
				startIcon={startIcon}
				label={label}
				placeholder={placeholder}
				value={
					searchText
						? searchText
						: Array.isArray(selectedOption)
							? selectedOption.map((val) => val.label).join(', ')
							: selectedOption?.label
				}
				onChangeCb={handleInputChange}
				endIcon={
					toggleIcon ? (
						toggleIcon
					) : showToggle && (
						<div onClick={handleIconRotation}>
							<Icons iconName={'expandIcon'} color={MET_COLORS.DARK_GRAY} rotateDeg={iconRotation} />
						</div>
					)}
				onFocusCb={() => setIsOpen(true)}
				isRequired={isRequired}
				name={name}
				disabled={disabled}
				autoComplete="off"
			/>
			{isOpen ? (
				<DropdownModal
					isOpen={isOpen}
					onCloseCb={onCloseCb}
					options={filteredOptions}
					isMultiSelect={isMultiSelect}
					multiSelectAll={multiSelectAll}
					selectCb={handleSelectOption}
					selectedOption={selectedOption}
					renderOption={renderOption}
					onDropdownTouchBottomCb={onDropdownTouchBottomCb}
					isAsync={isAsync && dropdownOptions.length < paginationState.totalRecords}
					disabled={disabled}
					openDirection={openDirection}
					dropdownOptions={dropdownOptions}
				/>
			) : null}
		</div>
	);
};

export default SelectDropdown;

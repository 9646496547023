import moment from "moment";
export const FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT = {
	PATIENT_NAME: 'patientId',
	IN_PERSON: 'In_Person',
	VIDEO_CALL: 'Video_Call',
	VOICE_CALL: 'Voice_Call',
	PROVIDER: 'providerId',
	LOCATION: 'locationId',
	APPOINTMENT_TYPE: 'appointmentType',
	IS_REPEAT: 'isRepeat',
	CHIEF_COMPLAINT: 'complaints',
	COUNT: 'appointmentCount',
	EVERY: 'every',
	START_DATE: 'startDate',
	END_DATE: 'endDate',
	DAYS: 'days',
	MODE: 'mode',
	START: 'start',
	REPEAT_TYPE: 'repeatType',
};

export const FORM_FIELDS_FOR_ADD_NEW_PATIENT = {
	FIRSTNAME: 'firstName',
	MIDDLENAME: 'middleName',
	LASTNAME: 'lastName',
	DOB: 'dob',
	GENDER: 'gender',
	EMAIL: 'email',
	CONTACT_NUMBER: 'mobileNumber',
	AADHAR_NUMBER: 'aadhar',
	IS_PATIENT_AADHAR: 'isPatientsAadhar',
	REGISTRATION_DATE: 'registrationDate',
};

export const FORM_FIELDS_FOR_BLOCK_APPOINTMENTS = {
	PROVIDER: 'providerId',
	LOCATION: 'locationId',
	START: 'startTime',
	END: 'endTime',
	START_DATE: 'date',
	END_DATE: 'endDate',
	IS_REPEAT: 'isRepeat',
	DAYS: 'days',
	EVERY: 'every',
	REPEAT_TYPE: 'repeatType',
	REASON: 'reason',
	COUNT: 'appointmentType',
};

export const FORM_FIELDS_FOR_RESCHEDULE_APPOINTMENT = {
	MODE: 'mode',
	APPOINTMENT_TYPE: 'appointmentType',
	LOCATION: 'locationId',
	CHIEF_COMPLAINT: 'complaints',
	START: 'start',
	// PATIENT_NAME: 'patientId',
	IN_PERSON: 'In_Person',
	VIDEO_CALL: 'Video_Call',
	VOICE_CALL: 'Voice_Call',
	PROVIDER: 'providerId',

	START_DATE: 'date',
};

export const FORM_FIELDS_FOR_FILTERS_OF_SCHEDULING = {
	DATE_TO: 'dateTo',
	DATE_FROM: 'dateFrom',
	LOCATION: 'location',
	PATIENT_ID: 'patientId',
	PROVIDER_ID: 'providerId',
	APPOINTMNET_TYPE: 'appointmentType',
	STATUS: 'status',
	colorConfigDetails: 'colorConfigDetails',
};

export const FILTERS = {
	dateTo: moment().format('YYYY-MM-DD') ,
	dateFrom: moment().format('YYYY-MM-DD') ,
	location: [],
	patientId: undefined,
	providerId: [],
	appointmentType: 'All',
	status: "",
	colorConfigDetails: [],
};

export const FORM_FIELDS_FOR_INSTANT_BOOKING = {
	PATIENT: "patientId",
	NOTE: "note",
	SEND_INVITE: "sendInvite"
};

export const FORM_FIELDS_FOR_CANECEL_APPOINTMENTS = {
	REASON: 'reason'
}

export const FORM_FIELDS_FOR_NO_SHOW_APPOINTMENTS = {
	REASON: 'reason'
}

export const FORM_FIELDS_FOR_SIGN_OFF_APPOINTMENTS = {
	REASON: 'reason'
}
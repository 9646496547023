import { all, takeEvery } from 'redux-saga/effects';
import store from '../../../store/store';
import { componentKey, setDropdownOptions, setPaginationState } from './SelectDropdownSlice';
import CommmonDataService from '../../../services/CommonDataService';
import General from '../../../libs/utility/General';

export const {
    getSelectOptions,
} = {
    getSelectOptions: (payload) => {
        return {
            type: 'SELECT_DROPDOWN/GET_ALL_ROLES_AND_RESP',
            payload,
        };
    },
};

function* getSelectOptionsAsync(action) {
    const { url, params, extractKeyFromResponse, labelKey, valueKey, labelKey2, addOptions, handlePaginationChange } = action.payload
    try {
        const { data, status } = yield CommmonDataService.getOptions(url, params)
        if (status) {
            const options = extractKeyFromResponse ? General.addLableValuePair(data.data[extractKeyFromResponse], labelKey, valueKey, labelKey2) : General.addLableValuePair(data.data, labelKey, valueKey, labelKey2)
            handlePaginationChange({ totalRecords: data?.data?.totalRecords })
            addOptions(options)
        }
    } catch (error) {
        console.log(error);
    }
}


function* rootSaga() {
    yield all([
        takeEvery(getSelectOptions().type, getSelectOptionsAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

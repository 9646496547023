import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import CustomEvent from './CustomEvents';
import { useDispatch, useSelector } from 'react-redux';
import {
	componentKey,
	setAppointmentDetails,
	setIsAppointmentDetailsModalOpen,
	setIsCheckedInData,
	setIsCheckedInModalOpen,
	setIsViewAllEventsModalOpen,
} from '../ShedulingSlice';
import { getAllScheduleAppointments } from '../SchedulingSaga';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import ViewAllEventModal from '../../../containers/SettingsTab/AppointmentCard/Tabs/Availability/ViewAllEvent';

const SchedulingCalendar = () => {
	const [countOfEvents, setCountOfEvents] = useState(null);
	const {
		calendarViewFormat,
		activeDate,
		allAppointmentsOfCalenderView,
		schedulingAppointmentsPaginationState,
		isViewAllEventsModalOpen,
	} = useSelector((state) => state[componentKey]);
	const dispatch = useDispatch();
	const [selectedEvent, setSelectedEvent] = useState([]);
	
	const localizer = momentLocalizer(moment);

	const handleEventClick = (event) => {
		if (event?.status === 'Scheduled') {
			dispatch(setAppointmentDetails(event));
			dispatch(setIsAppointmentDetailsModalOpen(true));
		}
		if (event.status !== 'Signed_Off' && event.status !== 'Unsigned') {
			dispatch(setIsCheckedInModalOpen(true));
			dispatch(setIsCheckedInData(event));
		}
	};

	useEffect(() => {
		let dateTo, dateFrom;

		if (calendarViewFormat === 'day') {
			dateTo = moment(activeDate).format('YYYY-MM-DD');
			dateFrom = moment(activeDate).format('YYYY-MM-DD');
		} else if (calendarViewFormat === 'month') {
			dateTo = moment().endOf('month').format('YYYY-MM-DD');
			dateFrom = moment().startOf('month').format('YYYY-MM-DD');
		} else if (calendarViewFormat === 'week') {
			dateTo = moment(activeDate).endOf('week').format('YYYY-MM-DD');
			dateFrom = moment(activeDate).startOf('week').format('YYYY-MM-DD');
		}
		const params = {
			dateTo: dateTo,
			dateFrom: dateFrom,
			location: schedulingAppointmentsPaginationState?.location.join(',') || undefined,
			patientId: schedulingAppointmentsPaginationState?.patientId || undefined,
			providerId: schedulingAppointmentsPaginationState?.providerId.join(',') || undefined,
			appointmentType: (schedulingAppointmentsPaginationState?.appointmentType) || undefined,
			status: (schedulingAppointmentsPaginationState?.status || '').trim() || undefined,
			responseType: 'calender',
		};
		dispatch(getAllScheduleAppointments(params));
	}, [schedulingAppointmentsPaginationState]);

	const events = allAppointmentsOfCalenderView?.map((item) => ({
		...item,
		title: `${item.Patient.firstName}  ${item.Patient.lastName} (${item.Patient.gender}) `,
		date: item.date,
		start: new Date(
			moment(item?.date).format('ddd MMM DD YYYY') +
				' ' +
				moment(item?.start, 'hh:mm a').format('HH:mm:ss [GMT]ZZ')
		),
		end: new Date(
			moment(item?.date).format('ddd MMM DD YYYY') + ' ' + moment(item?.end, 'hh:mm a').format('HH:mm:ss [GMT]ZZ')
		),
		status: item.status,
		style: {
			backgroundColor: '#F3F4F6',
			color: '#4873B9',
			border: 'none',
			columnGap: '20px',
		},
	}));

	const eventsByDay = allAppointmentsOfCalenderView?.reduce((acc, event) => {
		const date = moment(event.date).format('YYYY-MM-DD');
		if (!acc[date]) {
			acc[date] = 0;
		}
		acc[date]++;
		return acc;
	}, {});
	return (
		<>
			<Calendar
				selectable={true}
				messages={{
					showMore: function showMore(total) {
						setCountOfEvents(total);
						return 'View All';
					},
				}}
				onShowMore={(event) => {
					dispatch(setIsViewAllEventsModalOpen(true));
					setSelectedEvent(event);
				}}
				date={activeDate}
				onNavigate={() => {}}
				localizer={localizer}
				timeslots={1}
				events={events}
				startAccessor="start"
				endAccessor="end"
				eventPropGetter={(event) => ({
					style: event.style,
				})}
				components={{
					toolbar: (props) => <></>,
					event: (props) => (
						<CustomEvent {...props} onEventClick={handleEventClick} countOfEvents={countOfEvents} />
					),
				}}
				view={calendarViewFormat}
				onView={() => {}}
				style={{ height: '100%', width: '100%' }}
				step={15}
				showAllEvents
			/>
			<ModalComponent
				open={isViewAllEventsModalOpen}
				title={`All Availability Slots for ${moment(selectedEvent?.start, 'ddd MMM DD YYYY HH:mm:ss').format(
					'Do MMMM'
				)}`}
				customClasses="h-[35%] w-[40%]"
				close={() => dispatch(setIsViewAllEventsModalOpen(false))}
				customBodyClasses="h-[88%] flex justify-center items-center"
			>
				<div className="w-full">
					<ViewAllEventModal events={selectedEvent} handleEventClick={handleEventClick} />
				</div>
			</ModalComponent>
		</>
	);
};

export default SchedulingCalendar;

import React, { useEffect, useState } from 'react'
import ModalComponent from '../../../components/common-components/modal/ModalComponent'
import { componentKey as PatientPaymentComponentKey, componentKey, setUnpaidSuperBill } from "../Payments/PatientPaymentSlice"
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../components/icons/Icons';
import Input from '../../../components/common-components/input/Input';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import TextArea from '../../../components/common-components/textArea/TextArea';
import DatePicker from '../../../components/common-components/datePicker';
import PatientPaymentTable from './PatientPaymentTable';
import { Form, Formik } from 'formik';
import { getValidationSchema } from '../../../libs/formsUtils';
import { FORM_FIELDS_NAMES } from './Constants';
import { createNewPatientPayment, getAllPatients } from './PatientPaymentSaga';
import ProviderDropdown from '../../../components/common-components/providerDropdown/ProviderDropdown';
import General from '../../../libs/utility/General';
import { VALIDATION_REGEX } from '../../../libs/constant';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { addNotifications } from '../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../components/common-components/toaster/MetToaster';
import { componentKey as dataLoaderComponentKey } from '../../../containers/Wrappers/Dataloader/DataloaderSlice';
function PatientPaymentModal({ close, rowData }) {
    const { isPaymentModalOpen, patientList, editSelectedPayment, paymentpaginationState } = useSelector((state) => state[PatientPaymentComponentKey]);
    const { providersList } = useSelector((state) => state[dataLoaderComponentKey]);

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [patientListPageNum, setPatientListPageNum] = useState(1)

    const fields = [
        { fieldName: FORM_FIELDS_NAMES.PATIENT_NAME, isDropdown: true },
        { fieldName: FORM_FIELDS_NAMES.PAYMENT_DATE, isRequired: true },
        { fieldName: FORM_FIELDS_NAMES.PAYMENT_METHOD, isDropdown: true },
        { fieldName: FORM_FIELDS_NAMES.AMOUNT, isRequired: true },
        { fieldName: FORM_FIELDS_NAMES.PROVIDER_NAME, isDropdown: true },
    ];

    const validationSchema = Yup.object().shape({
        ...getValidationSchema(fields).fields,
        [FORM_FIELDS_NAMES.MOBILE]: Yup
            .string()
            .required()
            .when(FORM_FIELDS_NAMES.PAYMENT_METHOD, {
                is: (PaymentMode) => ["Online"].includes(PaymentMode.value),
                then: () => Yup.string().required(`${General.camelToPascalWithSpaces(FORM_FIELDS_NAMES.MOBILE)} is required`).matches(VALIDATION_REGEX.MOBILE_NUM_REGEX, `${General.camelToPascalWithSpaces("Mobile number")} is invalid`),
                otherwise: (schema) => schema.notRequired(),
            }),
    });

    const initialValues = {
        [FORM_FIELDS_NAMES.PATIENT_NAME]: General.getLabelValue(rowData?.patientId) || "",
        [FORM_FIELDS_NAMES.PAYMENT_DATE]: rowData?.dos || "",
        [FORM_FIELDS_NAMES.PAYMENT_METHOD]: "",
        [FORM_FIELDS_NAMES.AMOUNT]: rowData?.totalBalance || undefined,
        [FORM_FIELDS_NAMES.CHEQUE_NO_NFT_TRANS_ID]: "",
        [FORM_FIELDS_NAMES.TRANSACTION_ID]: "",
        [FORM_FIELDS_NAMES.PROVIDER_NAME]: General.getLabelValue(rowData?.renderingProviderId) || "",
        [FORM_FIELDS_NAMES.MOBILE]: rowData?.patient.mobileNumber || undefined,
        [FORM_FIELDS_NAMES.NOTE]: rowData?.note || "",
        [FORM_FIELDS_NAMES.SUPERBILL]: [],
    }

    useEffect(() => {
        const params = {
            pageNumber: patientListPageNum,
            limit: 20,
        }
        dispatch(getAllPatients(params));
    }, [dispatch, patientListPageNum,]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                if (values.amount === 0) {
                    dispatch(addNotifications({ message: "Amount cannot be zero!", variant: TOASTER_VARIANT.ERROR }));
                    return;
                }
                const payload = {
                    ...values,
                    providerId: values.providerId.value,
                    patientId: values.patientId.value,
                    method: values?.method?.value,
                }
                const paginationState = {
                    pageNumber: paymentpaginationState.pageNumber,
                    limit: paymentpaginationState.limit,
                    name: (paymentpaginationState?.name || "").toLowerCase(),
                    receipt: paymentpaginationState.receipt,
                    date: paymentpaginationState.date,
                }
                dispatch(createNewPatientPayment({ patientPayment: payload, navigate, paginationState }));
                // if (Object.keys(editSelectedPayment).length === 0) {
                //TODO: passing navigate is not good practice need to handle it using state.
                // } else {
                //     const updatedPayload = {
                //         ...payload,
                //         uuid: editSelectedPayment.uuid
                //     };
                //     dispatch(updateUnpaidPatientPayment(updatedPayload));
                // }
            }}
        >
            {({ submitForm, isSubmitting, handleSubmit, handleReset, values, setFieldValue, handleBlur }) => {
                return <Form onSubmit={handleSubmit} >
                    <div>
                        <ModalComponent
                            open={isPaymentModalOpen}
                            title={"Patient Payment Entry"}
                            footerButton={
                                <Button
                                    type="submit"
                                    variant={BUTTON_VARIANTS.CONTAINED}
                                    customBtnClass="text-met-primary"
                                >
                                    Save
                                </Button>
                            }
                            icon={<Icons iconName="closeIcon" />}
                            close={() => { close(); handleReset(); dispatch(setUnpaidSuperBill([])) }}
                            customClasses="w-[1297px]"
                        >
                            <div className='p-2'>
                                <div className='flex column-gap-10px items-center pt-6  '>
                                    <div className='flex-1'>
                                        <SelectDropdown
                                            name={FORM_FIELDS_NAMES.PATIENT_NAME}
                                            value={values[FORM_FIELDS_NAMES.PATIENT_NAME]}
                                            onChangeCb={(val) => { setFieldValue(FORM_FIELDS_NAMES.PATIENT_NAME, val) }}
                                            isRequired={true}
                                            toggleIcon={<Icons iconName={"searchIcon"}></Icons>}
                                            placeholder='Search Patient'
                                            label='Patient Name'
                                            onBlurCb={handleBlur}
                                            options={patientList}
                                            isAsync={true}
                                            url='patients'
                                            extractKeyFromResponse="patients"
                                            labelKey={"firstName"}
                                            valueKey={"uuid"}
                                            labelKey2={"lastName"}
                                        />
                                    </div>
                                    <div className='flex-1'>
                                        <DatePicker
                                            label="Payment Date"
                                            placeholder="Choose Date"
                                            value={values[FORM_FIELDS_NAMES.PAYMENT_DATE]}
                                            name={FORM_FIELDS_NAMES.PAYMENT_DATE}
                                            onChangeCb={(date) => {
                                                setFieldValue(FORM_FIELDS_NAMES.PAYMENT_DATE, date)
                                            }}
                                            isRequired={true}
                                            maxDate={new Date()}
                                        />
                                    </div>
                                    <div className='flex-1'>
                                        <SelectDropdown
                                            label='Payment Method'
                                            placeholder="Payment Method"
                                            name={FORM_FIELDS_NAMES.PAYMENT_METHOD}
                                            value={values[FORM_FIELDS_NAMES.PAYMENT_METHOD]}
                                            onChangeCb={(val) => {
                                                setFieldValue(FORM_FIELDS_NAMES.PAYMENT_METHOD, val)
                                            }}
                                            onBlurCb={handleBlur}
                                            options={[
                                                { label: "Cheque/NEFT", value: "Cheque/NEFT" },
                                                { label: "Cash", value: "Cash" },
                                                { label: "Online", value: "Online" }
                                            ]}
                                            isRequired={true}
                                        />
                                    </div>
                                </div>
                                <div className='flex column-gap-10px items-center pt-6  '>
                                    <div className='flex-1'>
                                        <Input
                                            placeholder={"Enter Amount"}
                                            label="Amount"
                                            name={FORM_FIELDS_NAMES.AMOUNT}
                                            value={values[FORM_FIELDS_NAMES.AMOUNT]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.AMOUNT, General.sanitizeNumbers(e.target.value)) }}
                                            onBlurCb={handleBlur}
                                            isRequired={true}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='flex-1'>
                                        <Input
                                            placeholder={"Cheque No/NFT/Trans.Id"}
                                            label="Cheque No/NFT/Trans.Id"
                                            name={FORM_FIELDS_NAMES.CHEQUE_NO_NFT_TRANS_ID}
                                            value={values[FORM_FIELDS_NAMES.CHEQUE_NO_NFT_TRANS_ID]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.CHEQUE_NO_NFT_TRANS_ID, e.target.value) }}
                                            onBlurCb={handleBlur}
                                        />
                                    </div>
                                    <div className='flex-1'>
                                        <Input
                                            placeholder={"Enter Transaction ID"}
                                            label="Transaction ID"
                                            name={FORM_FIELDS_NAMES.TRANSACTION_ID}
                                            value={values[FORM_FIELDS_NAMES.TRANSACTION_ID]}
                                            onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.TRANSACTION_ID, e.target.value) }}
                                            onBlurCb={handleBlur}
                                        />
                                    </div>
                                </div>
                                <div className='flex column-gap-10px items-center pt-6'>
                                    <div className='min-w-410 max-w-440'>
                                        <SelectDropdown
                                            label='Provider Name'
                                            placeholder="Select Provider Name"
                                            name={FORM_FIELDS_NAMES.PROVIDER_NAME}
                                            value={values[FORM_FIELDS_NAMES.PROVIDER_NAME]}
                                            options={providersList}
                                            onChangeCb={(val) => { setFieldValue(FORM_FIELDS_NAMES.PROVIDER_NAME, val) }}
                                            onBlurCb={handleBlur}
                                            isRequired={true}
                                        />
                                    </div>
                                    {
                                        values[FORM_FIELDS_NAMES.PAYMENT_METHOD].value === "Online" && (
                                            <div className="min-w-410 max-w-440">
                                                <Input
                                                    placeholder="Enter Mobile Number"
                                                    label="Mobile Number"
                                                    name={FORM_FIELDS_NAMES.MOBILE}
                                                    value={values[FORM_FIELDS_NAMES.MOBILE]}
                                                    onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.MOBILE, General.sanitizeNumbers(e.target.value)) }}
                                                    isRequired={true}
                                                    onBlurCb={handleBlur}
                                                />
                                            </div>
                                        )}
                                </div>
                                <div className='flex-1  pt-6'>
                                    <TextArea
                                        label="Notes"
                                        name={FORM_FIELDS_NAMES.NOTE}
                                        value={values[FORM_FIELDS_NAMES.NOTE]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.NOTE, e.target.value) }}
                                    />
                                </div>
                                <div>
                                    <PatientPaymentTable setFieldValue={setFieldValue} patientId={values[FORM_FIELDS_NAMES.PATIENT_NAME]?.value} />
                                </div>
                            </div>
                        </ModalComponent>
                    </div>
                </Form>
            }}
        </Formik>
    )
}

export default PatientPaymentModal
import React, { useEffect, useMemo } from 'react'
import Label from '../../../components/common-components/label/Label';
import Heading from '../../../components/common-components/heading/Heading';
import { HEADING } from '../../../components/common-components/heading/Constants';
import Table from '../../../containers/Table/Index';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientsUnpaidSuperBill } from './PatientPaymentSaga';
import { componentKey } from './PatientPaymentSlice';
import moment from 'moment';
import General from '../../../libs/utility/General';
import { FORM_FIELDS_NAMES } from './Constants';

function PatientPaymentTable({ patientId, setFieldValue }) {

    const dispatch = useDispatch()

    const { unpaidSuperBill } = useSelector(((state) => state[componentKey]))

    useEffect(() => {
        if (patientId) {
            dispatch(getPatientsUnpaidSuperBill({ patientId }));
        }
    }, [dispatch, patientId]);

    const totalBalance = useMemo(() => {
        return General.getAllSum(unpaidSuperBill, "totalBalance")
    }, [unpaidSuperBill])

    useEffect(() => {
        setFieldValue(FORM_FIELDS_NAMES.AMOUNT, Number(totalBalance))
    }, [totalBalance])

    useEffect(() => {
        const superBillUUIDs = unpaidSuperBill?.map(item => item?.uuid);
        setFieldValue(FORM_FIELDS_NAMES.SUPERBILL, superBillUUIDs)
    }, [unpaidSuperBill])

    useEffect(() => {
        if (unpaidSuperBill?.length) {
            setFieldValue(FORM_FIELDS_NAMES.PROVIDER_NAME, General.getLabelValue(unpaidSuperBill[0]?.renderingProviderId))
        } else {
            setFieldValue(FORM_FIELDS_NAMES.PROVIDER_NAME, General.getLabelValue(""))
        }
    }, [unpaidSuperBill?.length])

    const coloumns = [
        {
            field: 'superBillNo',
            label: <Label fontWeight="font-bold">Super Bill No</Label>,
            renderLogic: (row) => {
                const uuid = row?.uuid;
                const superBill = uuid.substring(0, 6);
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {superBill}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'dateOfService',
            label: <Label fontWeight="font-bold">Date Of Service</Label>,
            renderLogic: (row) => {
                const dateOfService = row.dos ? moment(row.dos).format('MMM DD YYYY') : '-';
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {dateOfService}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'totalCharges',
            label: <Label fontWeight="font-bold">Total Charges</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.totalBill}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'insuranceBalance',
            label: <Label fontWeight="font-bold">Insurance Balance</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.insuranceAmount}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'totalBalance',
            label: <Label fontWeight="font-bold">Total Balance</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color={"gray-500"}
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.totalBalance}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];
    return (
        <div className='pt-6'>
            <Table
                isPagination={false}
                coloumns={coloumns}
                rows={unpaidSuperBill}
            />
        </div>
    )
}

export default PatientPaymentTable
import store from "../../../../store/store";

export const componentKey = 'FAMILY_MEMBERS'

const initialState = {
    familyMembersData: [],
    isAddFamilyMemberModalOpen: false,
    isEditFamilyMemberOpen: false,
    isOpenFamilyMemberDeletePopup: false
};

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setfamilyMembersData: (state, action) => {
            state.familyMembersData = action.payload;
        },
        setIsAddFamilyMemberModalOpen: (state, action) => {
            state.isAddFamilyMemberModalOpen = action.payload;
        },
        setIsEditFamilyMemberOpen: (state, action) => {
            state.isEditFamilyMemberOpen = action.payload;
        },
        setIsOpenFamilyMemberDeletePopup: (state, action) => {
            state.isOpenFamilyMemberDeletePopup = action.payload;
        },

    },
    initialReducerState: initialState
});

export const { setfamilyMembersData, setIsAddFamilyMemberModalOpen, setIsEditFamilyMemberOpen, setIsOpenFamilyMemberDeletePopup } = actions
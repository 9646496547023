import React, { useEffect, useRef, useState } from 'react';

import Avatar from '../components/avatar/Avatar';
import VideoFooter from '../components/videoFooter/VideoFooter';
import ShareView from '../components/shareView/share-view';
import classNames from 'classnames';
import ShowParticipantList from './ParticipantList/ShowParticipantList';
import InCallMessages from './InCallMessages/InCallMessages';
import AddGuest from './AddGuest/AddGuest';
import Icons from '../components/icon/Icon';

import { componentKey, setAudioStarted, setExitFullScreen, setIsFullScreen, setIsMuted, setIsVideoMuted, setMeetingParticipants, setSendInviteModal } from '../ZoomIntegrationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParticipantsChange } from '../hooks/useParticipantsChange';
import { useCanvasDimension } from '../hooks/useCanvasDimension';
import { SELF_VIDEO_ID } from '../constants';
import { usePagination } from '../hooks/usePagination';
import { useGalleryLayout } from '../hooks/useGalleryLayout';
import { useActiveVideo } from '../hooks/useActiveVideo';
import { useNetworkQuality } from '../hooks/useNetworkQuality';
import { VEC_ICON_NAME } from '../components/icon/constants';

const VideoCall = ({ userType, appointmentDetails }) => {

    const [isReceiveSharing, setIsReceiveSharing] = useState(false);
    const dispatch = useDispatch()

    const videoRef = useRef();
    const shareViewRef = useRef()

    const { client, videoStarted, mediaStream, audioStarted, isFullScreen, showViewJoinAttendance, inCallMessages, sendInviteModal, meetingParticipants } = useSelector(state => state[componentKey])

    useEffect(() => {

        let startVideo = async () => {
            try {
                const startVideoOptions = {
                    hd: true,
                    fullHd: true,
                    ptz: mediaStream?.isBrowserSupportPTZ(),
                    originalRatio: true
                };

                if (mediaStream?.isSupportVirtualBackground()) {
                    Object.assign(startVideoOptions, { virtualBackground: { imageUrl: 'blur' } });
                }

                await mediaStream?.startVideo();

                if (!mediaStream?.isSupportMultipleVideos()) {
                    const canvasElement = document.querySelector(`#${SELF_VIDEO_ID}`)
                    await mediaStream?.renderVideo(canvasElement, client.getSessionInfo().userId, canvasElement.width, canvasElement.height, 0, 0, 3);
                }

                dispatch(setIsVideoMuted(false))
                dispatch(setIsFullScreen(false))
            } catch (error) {
                console.log("Error", error)
            }
        }

        let startAudio = async () => {
            if (audioStarted && !isFullScreen) {
                try {
                    await mediaStream.startAudio();
                    dispatch(setIsMuted(false));
                } catch (error) {
                    console.log(error)
                }
            }
            else {
                try {
                    await mediaStream.startAudio();
                    await mediaStream?.muteAudio();
                } catch (error) {
                    console.log(error)
                }
            }
        }

        if (videoStarted && !isFullScreen) startVideo()
        startAudio()

        // return async () => {
        //     await client.leave();
        //     ZoomVideo.destroyClient();
        //     console.log(">>>> Client destroyed .......")
        // };
    }, []);

    const canvasDimension = useCanvasDimension(mediaStream, videoRef, showViewJoinAttendance, inCallMessages, isReceiveSharing);

    const activeVideo = useActiveVideo(client);
    const networkQuality = useNetworkQuality(client);

    const { page, pageSize, totalPage, totalSize, setPage } = usePagination(client, canvasDimension)

    let paginationObj = { page, pageSize, totalPage, totalSize }

    const { visibleParticipants, layout: videoLayout } = useGalleryLayout(client, mediaStream, true, videoRef, canvasDimension, paginationObj);

    const currentUserIndex = visibleParticipants.findIndex(
        (user) => user.userId === client.getCurrentUserInfo()?.userId
    );

    let selfVideoLayout = null;
    if (currentUserIndex > -1) {
        const item = videoLayout[currentUserIndex];
        if (item && canvasDimension) {
            selfVideoLayout = { ...item, y: canvasDimension.height - item.y - item.height };
        }
    }

    useEffect(() => {
        const participants = client.getAllUser()
        dispatch(setMeetingParticipants(participants))
    }, [videoStarted, audioStarted])

    useParticipantsChange(client, () => {
        const participants = client.getAllUser()
        dispatch(setMeetingParticipants(participants))
    })

    const handleExitFullScreenClick = () => {
        dispatch(setExitFullScreen(true))
    }

    return (
        <React.Fragment>
            <div className='flex gap-2 h-100'>
                <div className='viewport'>
                    <ShareView zmClient={client} mediaStream={mediaStream} ref={shareViewRef} onReceiveSharingChange={setIsReceiveSharing} />
                    <div className={classNames('video-container', { 'video-container-in-sharing': isReceiveSharing })}>
                        <div className='minimize-screen cursor' onClick={handleExitFullScreenClick}>
                            {userType == "Provider" && <Icons iconName={VEC_ICON_NAME.EXIT_FULL_SCREEN} />}
                        </div>
                        <canvas className="video-canvas" id="video-canvas" ref={videoRef} />
                        {selfVideoLayout && mediaStream?.isRenderSelfViewWithVideoElement() && (
                            <video
                                id={SELF_VIDEO_ID}
                                className="video-canvas"
                                playsInline
                                muted
                                autoPlay
                                style={{
                                    position: "absolute",
                                    display: 'block',
                                    width: `${selfVideoLayout.width}px`,
                                    height: `${selfVideoLayout.height}px`,
                                    top: `${selfVideoLayout.y}px`,
                                    left: `${selfVideoLayout.x}px`,
                                    pointerEvents: 'none'
                                }}
                            />
                        )}

                        <ul className="avatar-list">
                            {visibleParticipants.map((user, index) => {
                                if (index > videoLayout.length - 1) {
                                    return null;
                                }
                                const dimension = videoLayout[index];
                                const { width, height, x, y } = dimension;
                                const { height: canvasHeight } = canvasDimension;
                                return (
                                    <Avatar
                                        participant={user}
                                        key={user.userId}
                                        isActive={activeVideo === user.userId}
                                        networkQuality={networkQuality[`${user.userId}`]}
                                        style={{
                                            width: `${width}px`,
                                            height: `${height}px`,
                                            top: `${canvasHeight - y - height}px`,
                                            left: `${x}px`
                                        }}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                    <VideoFooter className="video-operations" sharing selfShareCanvas={shareViewRef.current?.selfShareRef} userType={userType} appointmentDetails={appointmentDetails} />
                </div>
                <ShowParticipantList userType={userType} />
                <InCallMessages />
                <AddGuest
                    title='Invite People to join Meeting'
                    isOpen={sendInviteModal}
                    toggle={() => dispatch(setSendInviteModal(!sendInviteModal))}
                    appointmentDetails={appointmentDetails}
                />
            </div>
        </React.Fragment >
    );
};

export default VideoCall;

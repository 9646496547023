import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../../../components/common-components/button/Button';
import Icons from '../../../../../components/icons/Icons';
import InsuranceDetails from './InsuranceDetails';
import Checkbox from '../../../../../components/common-components/checkbox/Checkbox';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import AddPatientInsurance from '../../../../../containers/AddPatient/Insurance/AddPatientInsurance';
import { useDispatch, useSelector } from 'react-redux';
import {
	componentKey,
	setIsOpenEditInsuranceModal,
} from '../../../../../containers/AddPatient/Insurance/InsuranceSlice';
import moment from 'moment';
import { componentKey as dashboardComponentKey } from '../../PatientDashboardSlice';

export const InsuranceType = {
	PRIMARY_INSURANCE: 'Primary',
	SECONDARY_INSUREANCE: 'Secondary',
};

const PatientInsuranceDetails = ({ patientId }) => {
	const [isOpenInactiveInsurance, setIsOpenInactiveInsurance] = useState(true);
	const [isOpenPrimaryInsurance, setIsOpenPrimaryInsurance] = useState(true);
	const [isOpenOtherInsurance, setIsOpenOtherInsurance] = useState(true);
	const { isOpenEditInsuranceModal } = useSelector((state) => state[componentKey]);
	const dispatch = useDispatch();

	const { patientData } = useSelector((state) => state[dashboardComponentKey]);
	const { Insurance } = patientData;

	const [editInsuranceData, setEditInsuranceData] = useState('');

	const [insuranceData, setInsuranceData] = useState({
		primaryInsurance: [],
		secondaryInsurance: [],
		inactiveInsurance: [],
	});

	console.log('🚀 ~ PatientInsuranceDetails ~ insuranceData:primaryInsurance', insuranceData?.primaryInsurance);
	console.log('🚀 ~ PatientInsuranceDetails ~ insuranceData:secondaryInsurance', insuranceData?.secondaryInsurance);
	console.log('🚀 ~ PatientInsuranceDetails ~ insuranceData:inactiveInsurance', insuranceData?.inactiveInsurance);
	useEffect(() => {
		if (Insurance?.length) {
			console.log('🚀 ~ useEffect ~ Insurance:', Insurance);
			const formattedInsuranceData = Insurance?.reduce(
				(acc, item) => {
					const today = moment().format('YYYY-MM-DD');
					const itemEndDate = moment(item.endDate).format('YYYY-MM-DD');
					if (moment(itemEndDate).isBefore(today)) {
						acc.inactiveInsurance.push(item);
					} else {
						if (item.type === InsuranceType.PRIMARY_INSURANCE) {
							acc.primaryInsurance.push(item);
						} else if (item.type === InsuranceType.SECONDARY_INSUREANCE ) {
							console.log("in secondary");
							acc.secondaryInsurance.push(item);
						} else if(item.type === "Other"){
							console.log("in other");
							acc.secondaryInsurance.push(item)
						}
					}

					return acc;
				},
				{ primaryInsurance: [], secondaryInsurance: [], inactiveInsurance: [] }
			);

			setInsuranceData(formattedInsuranceData);
		}
	}, [Insurance]);

	const insuranceTabs = [
		{
			title: 'Primary Insurance',
			type: 'primaryInsurance',
			showExpandIcon: false,
			showEditButton: true,
			showCheckbox: false,
		},
		{
			title: 'Other Insurance',
			type: 'secondaryInsurance',
			showExpandIcon: true,
			showEditButton: true,
			showCheckbox: false,
		},
		{
			title: 'Inactive Insurance',
			type: 'inactiveInsurance',
			showExpandIcon: true,
			showEditButton: false,
			showCheckbox: false,
		},
	];

	const formRef = useRef();

	const handleEditInsuranceModal = (status, row) => {
		dispatch(setIsOpenEditInsuranceModal(status));
		setEditInsuranceData(row);
	};

	const handleTabClick = (type) => {
		if (type === 'secondaryInsurance') {
			setIsOpenOtherInsurance(!isOpenOtherInsurance);
			setIsOpenInactiveInsurance(false);
		} else if (type === 'inactiveInsurance') {
			setIsOpenInactiveInsurance(!isOpenInactiveInsurance);
			setIsOpenOtherInsurance(false);
		} else {
			setIsOpenOtherInsurance(false);
			setIsOpenInactiveInsurance(false);
		}
	};
	return (
		<div className="pt-6">
			<div className="shadow-sm border border-solid border-gray-200 rounded-md opacity-100">
				{insuranceTabs?.map((item, index) => (
					<React.Fragment key={'insurance-tab-' + index}>
						<div key={index}>
							{insuranceData[item?.type]?.length > 0 && (
								<div
									className={`bg-gray-200 font-bold flex items-center justify-between pl-4 pr-4 pt-2 pb-2 ${
										item.type === 'inactiveInsurance' || item.type === 'secondaryInsurance'
											? 'cursor-pointer'
											: 'cursor-default'
									}`}
									onClick={() => handleTabClick(item.type)}
								>
									<div className="flex column-gap-20px items-center">
										<div className="fs-18">{item?.title}</div>
										{item.showCheckbox && (
											<>
												<div>
													<Checkbox checked={false} />
												</div>
												<div className="text-met-primary">Make Primary</div>
											</>
										)}
									</div>
									<div>
										{item.showExpandIcon && (
											<Icons
												iconName="expandIcon"
												color="gray-500"
												rotateDeg={isOpenInactiveInsurance ? 180 : 0}
											/>
										)}
									</div>
								</div>
							)}
							{item.type === 'primaryInsurance' &&
								isOpenPrimaryInsurance &&
								insuranceData[item.type]?.map((insurance, index) => (
									<React.Fragment key={index}>
										<InsuranceDetails
											handleEditInsuranceModal={handleEditInsuranceModal}
											insurance={insurance}
											insuranceId={insurance.insuranceId}
											insuranceCategory={item.type}
										/>
										{index < insuranceData[item.type].length - 1 && (
											<hr className="m-4 border-gray-300" />
										)}
									</React.Fragment>
								))}
							{item.type === 'inactiveInsurance' &&
								isOpenInactiveInsurance &&
								insuranceData[item.type]?.map((insurance, index) => (
									<React.Fragment key={index}>
										<InsuranceDetails
											handleEditInsuranceModal={handleEditInsuranceModal}
											insurance={insurance}
											insuranceId={insurance.insuranceId}
											insuranceCategory={item.type}
										/>
										{index < insuranceData[item.type].length - 1 && (
											<hr className="m-4 border-gray-300" />
										)}
									</React.Fragment>
								))}
							{item.type === 'secondaryInsurance' &&
								isOpenOtherInsurance &&
								insuranceData[item.type]?.map((insurance, index) => (
									<React.Fragment key={index}>
										<InsuranceDetails
											handleEditInsuranceModal={handleEditInsuranceModal}
											insurance={insurance}
											insuranceId={insurance.insuranceId}
											insuranceCategory={item.type}
										/>
										{index < insuranceData[item.type].length - 1 && (
											<hr className="m-4 border-gray-300" />
										)}
									</React.Fragment>
								))}
								{item.type === 'Other' &&
								isOpenOtherInsurance &&
								insuranceData[item.type]?.map((insurance, index) => (
									<React.Fragment key={index}>
										<InsuranceDetails
											handleEditInsuranceModal={handleEditInsuranceModal}
											insurance={insurance}
											insuranceId={insurance.insuranceId}
											insuranceCategory={item.type}
										/>
										{index < insuranceData[item.type].length - 1 && (
											<hr className="m-4 border-gray-300" />
										)}
									</React.Fragment>
								))}
						</div>
					</React.Fragment>
				))}
			</div>

			{isOpenEditInsuranceModal ? (
				<ModalComponent
					title="Edit Insurance Details"
					customClasses="w-[80%]"
					open={isOpenEditInsuranceModal}
					close={() => {
						dispatch(setIsOpenEditInsuranceModal(false));
					}}
					footerButton={
						<Button
							onClickCb={() => {
								formRef.current.submitForm();
							}}
							type="submit"
						>
							Update
						</Button>
					}
				>
					<AddPatientInsurance
						editInsuranceData={[editInsuranceData]}
						isEdit={true}
						patientId={patientId}
						ref={formRef}
					/>
				</ModalComponent>
			) : null}
		</div>
	);
};

export default PatientInsuranceDetails;

import React, { useEffect, useState } from 'react'
import Label from '../../../components/common-components/label/Label';
import Avatar from '../../../components/common-components/avatar';
import Table from '../../../containers/Table/Index';
import Heading from '../../../components/common-components/heading/Heading';
import { HEADING } from '../../../components/common-components/heading/Constants';
import ToggleSwitch from '../../../components/common-components/toggleSwitch/ToggleSwitch';
import { componentKey, resetCustomDate, setApplyTrue, setIsOpenBilingGraphModal, setNoDataMessageForBilling, setProviderAdminPaginationState } from '../DashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { bilingCount, getProvidersList, updateProviderAdminStatus } from '../DashboardSaga';
import MetPopOver from '../../../components/common-components/popoverComponent/MetPopOver';
import ViewBillingGraphModal from './ViewBillingGraphModal';
import ButtonDropdown from '../../../components/common-components/buttonDropdown';

function ProviderAdminTable() {

    const { providerAdminPaginationState, providersListState, isOpenBilingGraphModal, bilingCountGraphDates, bilingCountStored, applyTrue } = useSelector((state) => state[componentKey]);
    const dispatch = useDispatch();
    const [selectedProviderId, SetselectedProviderId] = useState("")

    useEffect(() => {
        const params = {
            pageNumber: providerAdminPaginationState.pageNumber,
            limit: providerAdminPaginationState.limit,
            sortBy: providerAdminPaginationState.sortBy,
            orderBy: providerAdminPaginationState.orderBy,
            dateFrom: providerAdminPaginationState.dateFrom,
            dateTo: providerAdminPaginationState.dateTo,
        }
        dispatch(getProvidersList({ params }));
    }, [
        dispatch,
        providerAdminPaginationState.limit,
        providerAdminPaginationState.pageNumber,
        providerAdminPaginationState.sortBy,
        providerAdminPaginationState.orderBy,
        providerAdminPaginationState.dateFrom,
        providerAdminPaginationState.dateTo,
    ]);

    useEffect(() => {
        if (!selectedProviderId) return;

        const params = {
            providerId: selectedProviderId,
            dateFrom: bilingCountGraphDates?.graphDateFrom,
            dateTo: bilingCountGraphDates?.graphDateTo,
        }
        dispatch(bilingCount({ params }));

    }, [selectedProviderId, applyTrue])


    const coloumns = [
        {
            field: 'providerId',
            label: <Label fontWeight="bold">Provider Id</Label>,
            renderLogic: (row) => {
                const uuid = row?.uuid;
                const providerId = `#P${uuid.substring(uuid.length - 4)}`;
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {providerId}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'providerName',
            label: <Label fontWeight="bold">Provider Name</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-start column-gap-10px">
                            <Avatar
                                customClass="w-[40px] h-[40px] rounded-full"
                                url={row?.profilePicture}
                                name={`${row.firstName} ${row.lastName}`}
                            />
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {`${row.firstName} ${row.lastName}`}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
            sort: 'firstName',
        },
        {
            field: 'npiNumber',
            label: <Label fontWeight="bold">NMC Id</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.nmcId}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
            sort: 'npiNumber',
        },
        {
            field: 'contact',
            label: <Label fontWeight="bold">Contact</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.phoneNumber}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'speciality',
            label: <Label fontWeight="bold">Speciality</Label>,
            renderLogic: (row) => {
                const speciality = row?.provider_speciality_intermediate.map((item) => (item.speciality.name)).join(", ")
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <MetPopOver maxChar={row?.provider_speciality_intermediate[0]?.speciality?.name?.length}>
                                    {speciality}
                                </MetPopOver>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'yearsOfExperience',
            label: <Label fontWeight="bold">Years Of Experience</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.yearsOfExperience}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
            sort: 'yearsOfExperience',
        },
        {
            field: 'totalPatients',
            label: <Label fontWeight="bold">Total Patients</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row._count?.Patient}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'status',
            label: <Label fontWeight="bold">Status</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                 
                                <ToggleSwitch title={row?.status === true ? "Active" : "Inactive"} checked={row.status === true ? true : false} onChangeCb={() =>
                                    dispatch(updateProviderAdminStatus({ providerAdminPaginationState, uuid: row?.uuid, status: row?.status === false ? 'Active' : 'Inactive' }))
                                } />
                                {/* <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                    
                                >
                                    {row.status}
                                </Heading> */}
                            </div>
                        </div>
                    </>
                );
            },
            sort: 'status',
        },
        {
            field: 'viewGraph',
            label: <Label fontWeight="bold">Biling Count</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                {/* <Icons iconName="viewGraphIcon" style={{ cursor: "pointer" }} onClick={handleViewBilingModal(row?.uuid)} /> */}
                                <ButtonDropdown
                                    customBtnClass="p-0" endIcon="moreVertIcon"
                                    options={[

                                        {
                                            label: "View Graph", value: null, onChangeCb: () => {
                                                SetselectedProviderId(row?.uuid)
                                                dispatch(setIsOpenBilingGraphModal(true))
                                            }
                                        },
                                    ]}
                                    selectCb={(option) => {
                                        option?.onChangeCb();
                                    }} />

                            </div>
                        </div>
                    </>
                );
            },
        }

    ];

    return (
        <>
            <Table
                coloumns={coloumns}
                rows={providersListState}
                paginationProps={{
                    isPagination: true,
                    totalCount: providerAdminPaginationState.total,
                    limit: providerAdminPaginationState.limit,
                    onPageChange: (page) => {
                        dispatch(setProviderAdminPaginationState({ pageNumber: page }));
                    },
                }}
                sorting={{
                    isSortable: true,
                    onSortChangeCb: (sortKey) => {
                        dispatch(setProviderAdminPaginationState({ orderBy: providerAdminPaginationState.orderBy === 'asc' ? 'desc' : 'asc' }));
                        dispatch(setProviderAdminPaginationState({ sortBy: sortKey }));
                    },
                }}
            />
            {
                isOpenBilingGraphModal && (
                    <ViewBillingGraphModal bilingCountStored={bilingCountStored} open={isOpenBilingGraphModal} close={() => {
                        dispatch(setIsOpenBilingGraphModal(false))
                        dispatch(resetCustomDate(bilingCountGraphDates))
                        dispatch(setApplyTrue(false))
                        dispatch(setNoDataMessageForBilling(false))
                    }
                    } />
                )
            }
        </>
    )
}

export default ProviderAdminTable
import store from '../../../../store/store';

export const componentKey = 'PATIENTIMAGING_SECTION';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setImagingResultId: (state, action) => {
            state.currentImagingResultId = action.payload;
        },
        setAllImagingResultData: (state, action) => {
            state.allImagingResultData = action.payload;
        },
        setImagingResultData: (state, action) => {
            state.imagingResultData = action.payload;
        },
        setIsImagingResultModalOpen: (state, action) => {
            state.isImagingResultModalOpen = action.payload;
        },
        setIsNewImagingResultAdd: (state, action) => {
            state.isNewImagingResultAdd = action.payload;
        },
        setAllImagingCentresData: (state, action) => {
            state.allImagingCentreData = action.payload;
        },
        setIsOpenImagingDeleteModal: (state, action) => {
            state.isOpenImagingDeleteModal = action.payload;
        },
    },
    initialReducerState: {
        currentImagingResultId: '',
        allImagingResultData: [],
        imagingResultData: {},
        isImagingResultModalOpen: false,
        isNewImagingResultAdd: false,
        allImagingCentreData: [],
        isOpenImagingDeleteModal: false
    },
});

export const { setImagingResultId, setAllImagingResultData, setImagingResultData, setIsImagingResultModalOpen, setIsNewImagingResultAdd, setAllImagingCentresData, setIsOpenImagingDeleteModal } =
    actions;

export const SettingsLocationIcon = () => {
	return (
		<svg
			id="place_black_24dp_3_"
			data-name="place_black_24dp (3)"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path id="Path_204362" data-name="Path 204362" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_204363"
				data-name="Path 204363"
				d="M12,12a2,2,0,1,1,2-2A2.006,2.006,0,0,1,12,12Zm6-1.8a6,6,0,1,0-12,0c0,2.34,1.95,5.44,6,9.14C16.05,15.64,18,12.54,18,10.2ZM12,2a7.955,7.955,0,0,1,8,8.2q0,4.98-8,11.8Q4.005,15.175,4,10.2A7.955,7.955,0,0,1,12,2Z"
				fill="rgba(26,26,26,0.6)"
			/>
		</svg>
	);
};

import React from 'react';
const StartButtonIcon = () => {
	return (
		<svg
			id="task_black_24dp_2_"
			data-name="task_black_24dp (2)"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g id="Group_88771" data-name="Group 88771">
				<path id="Path_205548" data-name="Path 205548" d="M0,0H24V24H0Z" fill="none" />
			</g>
			<g id="Group_88772" data-name="Group 88772">
				<path
					id="Path_205549"
					data-name="Path 205549"
					d="M14,2H6A2,2,0,0,0,4.01,4L4,20a2,2,0,0,0,1.99,2H18a2.006,2.006,0,0,0,2-2V8Zm4,18H6V4h7V9h5Z"
					fill="#1b5984"
				/>
				<path
					id="Path_205550"
					data-name="Path 205550"
					d="M8,15.01l1.41,1.41L11,14.84V19h2V14.84l1.59,1.59L16,15.01,12.01,11Z"
					transform="translate(27 2) rotate(90)"
					fill="#1b5984"
				/>
			</g>
		</svg>
	);
};
export default StartButtonIcon;

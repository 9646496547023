import store from '../../../../../store/store';

export const componentKey = 'VITALS_TESTS';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setDeviceNamesList: (state, action) => {
			state.deviceNamesList = action.payload;
		},
		setDeviceTestList: (state, action) => {
			state.deviceTestList = action.payload;
		},
		setDeleteVitalsTestModalOpen: (state,action)=>{
			state.deleteVitalsTestModalOpen = action.payload;
		},
		setCurrentUUID:(state, action) => {
			state.currentUUID = action.payload
		},
		setIsCreateNewVitalsTests: (state, action) => {
            state.isCreateNewVitalsTests = action.payload
        },
		setIsVitalsTestsModalOpen: (state,action)=>{
			state.isVitalsTestsModalOpen = action.payload;
		},
		setVitalsTestsData: (state, action) => {
			state.vitalsTestsData = action.payload;
		},
		setDeviceName:(state,action)=>{
			state.deviceName = action.payload
		}

		
	},
	initialReducerState: {
		deviceNamesList: [],
		deviceTestList:[],
		currentUUID:"",
		deleteVitalsTestsModalOpen :false,
		setDeviceTestList:[],
		isCreateNewVitalsTests:false,
		isVitalsTestsModalOpen:false,
		vitalsTestsData :[],
		deviceName:{}

		
	},
});

export const { setDeviceNamesList, setDeviceTestList,setDeleteVitalsTestModalOpen, setCurrentUUID, setIsCreateNewVitalsTests, setIsVitalsTestsModalOpen,setVitalsTestsData,setDeviceName } = actions;

import React from 'react';
import { useSelector } from 'react-redux';
import FaceSheetCard from './FaceSheetCard';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import { componentKey as PatientDiagnosesComponentKey } from "../../Diagnoses/PatientDiagnosesSlice";
import { Navigate, useNavigate } from 'react-router-dom';
import General from '../../../../../libs/utility/General';

const DiagonosesCard = () => {
	const navigate = useNavigate()
	const { allDiagnosesData } = useSelector((state) => state[PatientDiagnosesComponentKey]);

	const data = allDiagnosesData.slice(0, 2).map((diagnoses, index) => {
		return {
			id: index + 1,
			title: diagnoses.diagnosesName,
			subtitle: `Onset Date: ${General.getformattedDate(diagnoses.onsetDate)}`
		}
	})

	return (
		<FaceSheetCard
			comingFrom ={true}
			title="DIAGNOSES"
			iconbtn={
				<Button
					variant={BUTTON_VARIANTS.TEXT}
					customBtnClass="fs-14 font-bold text-met-primary"
					style={{ height: '19px' }}
					onClickCb={() => navigate("../diagnoses")}
				>
					View All
				</Button>
			}
			data={data}
			customClassName="min-w-515"
		/>
	);
};

export default DiagonosesCard;

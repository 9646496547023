import store from "../../../../../store/store";

export const componentKey = 'SETTING_NOTIFICATIONS'

const initialState = {
    notificationData: [],
};

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setNotificationData: (state, action) => {
            state.notificationData = action.payload;
        },

    },
    initialReducerState: initialState

})

export const { setNotificationData } = actions
import AppDataService from './AppDataService'

const COMMON_BASE = 'templates'

export default class TemplatesDataService {
    static async getTemplateNotes(params) {
        return await AppDataService.get(`${COMMON_BASE}/notes`, { params })
    }
    static async getTemplateNoteById(noteId) {
        return await AppDataService.get(`${COMMON_BASE}/note/${noteId}`)
    }
    static async createTemplateVisitNotes(data) {
        return await AppDataService.post(`${COMMON_BASE}/notes`, data)
    }
    static async deleteTemplateVisitNotes(uuid) {
        return await AppDataService.delete(`${COMMON_BASE}/note/${uuid}`)
    }
}
import React, { useEffect, useState } from 'react';
import DatePicker from '../../../components/common-components/datePicker';
import Input from '../../../components/common-components/input/Input';
import Icons from '../../../components/icons/Icons';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey as DataloaderComponentKey } from '../../../containers/Wrappers/Dataloader/DataloaderSlice';
import {
	componentKey,
	setActiveDate,
	setResetFilters,
	setSchedulingAppointmentsPaginationState,
	setShowSheduleFilters,
} from '../ShedulingSlice';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import General from '../../../libs/utility/General';
import { getAllScheduleAppointments, getColorConfigDetails } from '../SchedulingSaga';
import moment from 'moment';
import { Form, Formik } from 'formik';
import { FILTERS, FORM_FIELDS_FOR_FILTERS_OF_SCHEDULING } from '../constant';

function SchedulingFilters() {
	const dispatch = useDispatch();
	const [color, setColor] = useState();
	const { schedulingAppointmentsPaginationState, colorConfigDetails, isCalenderView } = useSelector((state) => state[componentKey]);
	const { providersList, allProviderGroupLocation } = useSelector((state) => state[DataloaderComponentKey]);
	const locations = General.addLableValuePair(allProviderGroupLocation, 'name', 'uuid');

	useEffect(() => {
		dispatch(getColorConfigDetails());
	}, [dispatch]);

	useEffect(() => {
		if(!isCalenderView){
			const params = {
				dateTo: moment(schedulingAppointmentsPaginationState?.dateTo).format('YYYY-MM-DD') || undefined,
				dateFrom: moment(schedulingAppointmentsPaginationState?.dateFrom).format('YYYY-MM-DD') || undefined,
				location: schedulingAppointmentsPaginationState?.location.join(',') || undefined,
				patientId: schedulingAppointmentsPaginationState?.patientId || undefined,
				providerId: schedulingAppointmentsPaginationState?.providerId.join(',') || undefined,
				appointmentType: (schedulingAppointmentsPaginationState?.appointmentType) || undefined,
				status: (schedulingAppointmentsPaginationState?.status || '').trim() || undefined,
			};
			dispatch(getAllScheduleAppointments({ ...params, mode: 'location' }));
			dispatch(getAllScheduleAppointments({ ...params, mode: 'virtual' }));
		}
	}, [schedulingAppointmentsPaginationState]);

	return (
		<Formik
			initialValues={{
				[FORM_FIELDS_FOR_FILTERS_OF_SCHEDULING.DATE_TO]:
					moment(schedulingAppointmentsPaginationState?.dateTo).format('YYYY-MM-DD') || undefined,
				[FORM_FIELDS_FOR_FILTERS_OF_SCHEDULING.DATE_FROM]:
					moment(schedulingAppointmentsPaginationState?.dateFrom).format('YYYY-MM-DD') || undefined,
				[FORM_FIELDS_FOR_FILTERS_OF_SCHEDULING.LOCATION]:
					schedulingAppointmentsPaginationState?.location.join(',') || undefined,
				[FORM_FIELDS_FOR_FILTERS_OF_SCHEDULING.PATIENT_ID]:
					schedulingAppointmentsPaginationState?.patientId || undefined,
				[FORM_FIELDS_FOR_FILTERS_OF_SCHEDULING.PROVIDER_ID]:
					schedulingAppointmentsPaginationState?.providerId.join(',') || undefined,
				[FORM_FIELDS_FOR_FILTERS_OF_SCHEDULING.APPOINTMNET_TYPE]:
					(schedulingAppointmentsPaginationState?.appointmentType) || undefined,
				[FORM_FIELDS_FOR_FILTERS_OF_SCHEDULING.STATUS]: schedulingAppointmentsPaginationState?.status || undefined,
			}}
			enableReinitialize
			onSubmit={(values) => {
				if(!isCalenderView){
					const params = {
						dateTo: moment(schedulingAppointmentsPaginationState?.dateTo).format('YYYY-MM-DD') || undefined,
						dateFrom: moment(schedulingAppointmentsPaginationState?.dateFrom).format('YYYY-MM-DD') || undefined,
						location: schedulingAppointmentsPaginationState?.location.join(',') || undefined,
						patientId: schedulingAppointmentsPaginationState?.patientId || undefined,
						providerId: schedulingAppointmentsPaginationState?.providerId.join(',') || undefined,
						appointmentType:
						(schedulingAppointmentsPaginationState?.appointmentType) || undefined,
						// status: schedulingAppointmentsPaginationState?.status.join(',') || undefined,
						status: (schedulingAppointmentsPaginationState?.status || '').trim() || undefined,
					};
					dispatch(getAllScheduleAppointments({ ...values, mode: 'location' }));
					dispatch(getAllScheduleAppointments({ ...values, mode: 'virtual' }));
				}
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<div className="flex flex-col row-gap-20">
							<div className="flex justify-between">
								<Button
									onClickCb={() => {
										dispatch(setShowSheduleFilters(false));
									}}
									variant={BUTTON_VARIANTS.TEXT}
									startIcon={{ icon: 'filtersIcon', color: 'white' }}
									customBtnClass="text-met-primary p-0"
								>
									Filters
								</Button>
								
								<Button
									onClickCb={() => {
										dispatch(setResetFilters(FILTERS));
										setColor(undefined);
										// dispatch(getAllScheduleAppointments({ mode: 'location'}));
										// dispatch(getAllScheduleAppointments({ mode: 'virtual'}));
									}}
									variant={BUTTON_VARIANTS.OUTLINED}
									// startIcon={{ icon: 'filtersIcon', color: 'white' }}
									customBtnClass="text-met-primary p-2"
								>
									Clear Filters
								</Button>
							</div>
							<div id="scheduling-date-picker">
								<DatePicker
									showMonthDropdown={false}
									showYearDropdown={false}
									value={schedulingAppointmentsPaginationState?.dateTo}
									onChangeCb={(date) => {
										const formattedDate = moment.utc(date).startOf('day').format('YYYY-MM-DD');

										const data = { dateTo: formattedDate, dateFrom: formattedDate };
										dispatch(setSchedulingAppointmentsPaginationState(data));
										dispatch(setActiveDate(date))
									}}
									
									open={true}
								/>
							</div>
							<div>
								<SelectDropdown
									// name={FORM_FIELDS_NAMES.PATIENT_NAME}
									value={
										General.getLabelValue(schedulingAppointmentsPaginationState?.patientId) ||
										undefined
									}
									onChangeCb={(e) => {
										return dispatch(
											setSchedulingAppointmentsPaginationState({ patientId: e.value })
										);
									}}
									toggleIcon={<Icons iconName={'searchIcon'}></Icons>}
									placeholder="Search Patient"
									label="Patient Name"
									isAsync={true}
									url="patients"
									extractKeyFromResponse="patients"
									labelKey={'firstName'}
									valueKey={'uuid'}
									labelKey2={'lastName'}
								/>
							</div>
							<div>
								<SelectDropdown
									isMultiSelect={true}
									placeholder="Search & Select"
									label="Search By Provider"
									value={schedulingAppointmentsPaginationState?.providerId || []}
									onChangeCb={(val) => {
										const values = val?.map((item) => item?.value);
										dispatch(setSchedulingAppointmentsPaginationState({ providerId: values }));
									}}
									options={providersList}
								/>
							</div>
							<div>
								<SelectDropdown
									isMultiSelect={true}
									placeholder="Locations"
									label="Locations"
									value={schedulingAppointmentsPaginationState?.location}
									onChangeCb={(val) => {
										const values = val && val?.map((item) => item?.value);
										dispatch(setSchedulingAppointmentsPaginationState({ location: values }));
									}}
									options={locations}
								/>
							</div>
							<div>
								<div className="mt-6 flex">
									<SelectDropdown
										value={colorConfigDetails.find((item)=>item?.label === schedulingAppointmentsPaginationState?.status )}
										onChangeCb={(val) => {
											dispatch(setSchedulingAppointmentsPaginationState({ status: val.label }));
											setColor(val.colour);
										}}
										label="Status"
										startIcon={
											<div
												className={`min-w-[50px] h-[20px] rounded-md`}
												style={{ backgroundColor: color }}
											></div>
										}
										options={colorConfigDetails}
										// placeholder={'status'}
									/>
								</div>
							</div>
							<div>
								<SelectDropdown
									// isMultiSelect={true}
									placeholder="Appointment Type"
									label="Appointment Type"
									value={schedulingAppointmentsPaginationState.appointmentType}
									onChangeCb={(val) => {
										console.log("🚀 ~ SchedulingFilters ~ val:", val)
										// const values = val.map((item) => item?.value);
										dispatch(setSchedulingAppointmentsPaginationState({ appointmentType: val.value }));
									}}
									options={[
										{ label: 'New Appointment', value: 'New_Appointment' },
										{ label: 'Follow Up', value: 'Follow_Up' },
										{ label: 'All', value: 'All' },
									]}
								/>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
}

export default SchedulingFilters;

import store from "../../../../../store/store"

export const componentKey = 'PROVIDER_PROFILE'

const initialState = {
    providerProfile: {},
    allproviderLocations: [],
    providerSepcialities: [],
    allDepartmentsData: [],
    isOpenChangePassWordModal: false
};

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setProviderInformationData: (state, action) => {
            state.providerProfile = action.payload;
        },
        setAllProviderLocation: (state, action) => {
            state.allproviderLocations = action.payload
        },
        setProviderSepcialities: (state, action) => {
            state.providerSepcialities = action.payload
        },
        setAllDepartmentsData: (state, action) => {
            state.allDepartmentsData = action.payload
        },
        setIsOpenChangePassWordModal: (state, action) => {
            state.isOpenChangePassWordModal = action.payload
        }
    },
    initialReducerState: initialState
});

export const { setProviderInformationData, setAllProviderLocation, setProviderSepcialities, setAllDepartmentsData, setIsOpenChangePassWordModal } = actions
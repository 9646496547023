
import React from 'react'
import { Form, Formik } from 'formik'
import moment from 'moment';
import Input from '../../../components/common-components/input/Input';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants'
import { FORM_FIELDS_NAMES } from '../constants';
import Icons from '../../../components/icons/Icons';
import { componentKey as PatientEncounterComponentKey } from "../EncounterSlice"
import { componentKey as SchedulingComponentKey } from '../../Scheduling/ShedulingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { patchAppointmentDetails } from '../EncounterSaga';


function EditAppointmentModal({ open, close }) {
    const { isEditAppointmentModalOpen, providerLocations } = useSelector(state => state[PatientEncounterComponentKey])
    const { checkedInData } = useSelector((state) => state[SchedulingComponentKey]);
    const dispatch = useDispatch()

    return (
        <Formik
            initialValues={{
                [FORM_FIELDS_NAMES.APPOINTMENT_DATE_TIME]: checkedInData && checkedInData.date ? `${moment(checkedInData.date).format('ddd, MMMM D, YYYY')} ${checkedInData.start}` : '',
                [FORM_FIELDS_NAMES.APPOINTMENT_TYPE]: checkedInData && checkedInData.appointmentType ? checkedInData.appointmentType : '',
                [FORM_FIELDS_NAMES.RENDERING_PROVIDER]: checkedInData && checkedInData.Provider ? `${checkedInData.Provider.firstName} ${checkedInData.Provider.lastName}` : '',
                [FORM_FIELDS_NAMES.LOCATION]: checkedInData && checkedInData.Locations ? { label: checkedInData.Locations.name, value: checkedInData.Locations.uuid } : '',
                [FORM_FIELDS_NAMES.POS]: checkedInData && checkedInData.placeOfService ? { label: checkedInData.placeOfService, value: checkedInData.placeOfService } : '',
                [FORM_FIELDS_NAMES.AGE]: checkedInData ? checkedInData?.ageAtEncounter : '',
            }}
            enableReinitialize
            onSubmit={(formVals) => {
                const data = {
                    locationId: formVals.locationId.value,
                    placeOfService: formVals.placeOfService.value,
                    ageAtEncounter: Number(formVals.ageAtEncounter),
                    status: checkedInData?.status || 'Unsigned'
                }
                dispatch(patchAppointmentDetails({ encounterData: data, scheduleId: checkedInData?.uuid }))
            }}
        >
            {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                return <Form onSubmit={handleSubmit} >
                    <div>
                        <ModalComponent
                            open={isEditAppointmentModalOpen}
                            title={'Edit Appointment Details'}
                            footerButton={<Button variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary" type='submit' >Save</Button>}
                            icon={<Icons iconName="closeIcon" />}
                            close={close}
                            customClasses="w-[869px]">
                            <div className='flex p-2'>
                                <div className='w-[50%] p-2'>
                                    <div className='pt-6'>
                                        <Input
                                            label="Appointment Date & Time"
                                            isRequired={true}
                                            placeholder="Enter Medicine Name"
                                            name={FORM_FIELDS_NAMES.APPOINTMENT_DATE_TIME}
                                            value={values[FORM_FIELDS_NAMES.APPOINTMENT_DATE_TIME]}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='flex-1'>
                                        <div>
                                            <Input
                                                label="Rendering Provider"
                                                placeholder="Enter"
                                                name={FORM_FIELDS_NAMES.RENDERING_PROVIDER}
                                                value={values[FORM_FIELDS_NAMES.RENDERING_PROVIDER]}
                                                disabled={true} />
                                        </div>
                                    </div>
                                    <div className='flex-1'>
                                        <div>
                                            <SelectDropdown
                                                placeholder="Location"
                                                label="Location"
                                                name={FORM_FIELDS_NAMES.LOCATION}
                                                value={values[FORM_FIELDS_NAMES.LOCATION]}
                                                onChangeCb={(val) => { setFieldValue(FORM_FIELDS_NAMES.LOCATION, val) }}
                                                options={providerLocations?.map(location => ({ 'label': location.provider_locations.name, 'value': location.provider_locations.uuid }))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='column-gap-10px items-center p-2 w-[50%]'>
                                    <div className='pt-6'>
                                        <div>
                                            <Input
                                                label="Appointment Type"
                                                placeholder="Enter"
                                                name={FORM_FIELDS_NAMES.APPOINTMENT_TYPE}
                                                value={values[FORM_FIELDS_NAMES.APPOINTMENT_TYPE]}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex-1'>
                                        <div>
                                            <Input
                                                label="Age at Encounter"
                                                placeholder="Enter"
                                                name={FORM_FIELDS_NAMES.AGE}
                                                value={values[FORM_FIELDS_NAMES.AGE]}
                                                onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.AGE, e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex-1'>
                                        <div>
                                            <SelectDropdown
                                                placeholder="Place of Service"
                                                label="Place of Service"
                                                name={FORM_FIELDS_NAMES.POS}
                                                value={values[FORM_FIELDS_NAMES.POS]}
                                                onChangeCb={(val) => { setFieldValue(FORM_FIELDS_NAMES.POS, val) }}
                                                options={[
                                                    { label: "11- Office", value: "11-Office" },
                                                    { label: "12 - Home", value: "12-Home" },
                                                    { label: "13- Assisted Living Facility", value: "13-AssistedLivingFacility" },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalComponent>
                    </div>
                </Form>
            }}
        </Formik>
    )
}

export default EditAppointmentModal
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Label from '../label/Label';
import Icons from '../../icons/Icons';
import './styles.scss';
import { ErrorMessage } from 'formik';
import moment from 'moment';

const DatePicker = ({
	label = '',
	iconName = 'calenderIcon',
	name = '',
	isRequired = false,
	value = null,
	onChangeCb = () => {},
	placeholder = '',
	showTimeSelect = false,
	showTimeSelectOnly = false,
	timeIntervals = 1,
	disabled = false,
	customClass = '',
	minDate,
	maxDate,
	minTime,
	maxTime,
	showMonthDropdown = true,
	showYearDropdown = true,
	onFocusCb = () => {},
	onMonthChange = () => {},
	showMonthYearPicker = false,
	...additionalProps
}) => {
	return (
		<div className="flex flex-col">
			<div className="mb-1">
				<Label fontWeight="bold" isRequired={isRequired}>
					{label}
				</Label>
			</div>
			<ReactDatePicker
				{...additionalProps}
				showIcon
				showTimeSelect={showTimeSelect}
				showTimeSelectOnly={showTimeSelectOnly}
				timeFormat="hh:mm:ss aa"
				timeIntervals={timeIntervals}
				timeCaption="Time"
				id="datepicker"
				minDate={minDate}
				maxDate={maxDate}
				minTime={minTime}
				maxTime={maxTime}
				selected={value ? moment(value)?.toDate() : null}
				onMonthChange={onMonthChange}
				showMonthYearPicker={showMonthYearPicker}
				onChange={(selectedDate) => {
					if (!selectedDate) {
						return onChangeCb(selectedDate);
					}
					if (showTimeSelectOnly || showTimeSelect) {
						onChangeCb(selectedDate);
					} else {
						//TODO: if date picker is breaking somewhere try removing .utc
						const date = moment.utc(selectedDate).toDate();

						const isoString = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

						onChangeCb(isoString);
					}
				}}
				placeholderText={placeholder}
				dateFormat={showTimeSelectOnly ? 'h:mm aa' : showTimeSelect ? 'dd/MM/yyyy hh:mm:ss aa' : 'dd/MM/yyyy'}
				className={`met-scrollbar custom-datepicker block opacity-100 w-full p-7px text-base rounded-md outline-none pl-30px ${customClass}`}
				icon={
					<div className="flex justify-center items-center">
						<Icons style={{ position: 'absolute', margin: '5px' }} iconName={iconName} />
					</div>
				}
				name={name}
				showMonthDropdown={showMonthDropdown}
				showYearDropdown={showYearDropdown}
				dropdownMode="select"
				disabled={disabled}
				toggleCalendarOnIconClick
				onKeyDown={(e) => {
					e.preventDefault();
				}}
				autoComplete="off"
				onFocus={onFocusCb}
			/>
			{name && (
				<div className="mt-1">
					<ErrorMessage name={name} component="div" className="error-text" />
				</div>
			)}
		</div>
	);
};

export default DatePicker;

import React from "react";

function Drawer({ heading = "", isOpen, onCloseCb, direction = "left", children,width="64" , isHeading=false}) {

    const toggleDrawer = () => {
        onCloseCb(!isOpen);
        if (!isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    };

    return (
        <div>
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black opacity-50 z-50"
                    onClick={toggleDrawer}
                ></div>
            )}

            <div
                style={{ width: `${width}` }}
                className={`fixed inset-y-0  ${direction}-0  bg-white shadow-lg z-50 transition-transform transform ${isOpen ? "translate-x-0" : "translate-x-full"
                    }`}
            >
                <div className={`${isHeading} ? "h-[40px]" : ""`}>{heading || ""}</div>
                <div className="p-4">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Drawer;

import AppDataService from './AppDataService'

const COMMON_BASE = 'device-test'

export default class DeviceTestDataService {

    static async getDeviceNameList() {
        return await AppDataService.get(`${COMMON_BASE}/list`,)
    }
    static async getDeviceTest(params) {
        return await AppDataService.get(`${COMMON_BASE}`, { params })
    }

    static async addDeviceTest(data) {
        return await AppDataService.post(`${COMMON_BASE}`, data)
    }
    static async editDeviceTest(uuid,data){
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}`,data)
    }
    
    static async deleteDeviceTest(uuid){
        return await AppDataService.delete(`${COMMON_BASE}/${uuid}`)
    }

    static async getPatientVitalDeviceTest(params) {
        return await AppDataService.get(`${COMMON_BASE}`, { params })
    }
}
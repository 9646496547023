import React from 'react';
import PatientProfileCard from './PatientProfileCard';
import DemographicsCard from './DemographicsCard';
import PrivacyCard from './PrivacyCard';
import AddressCard from './AddressCard';
import EmergencycontactInfoCard from './EmergencycontactInfoCard';
import PreferencesCard from './PreferencesCard';
import { componentKey as PatientDashboard } from '../../../PatientDashboardSlice';
import { useSelector } from 'react-redux';


const ProfileWrapper = () => {
	const { patientData } = useSelector((state) => state[PatientDashboard]);

	return (
		<>
			<div className="flex column-gap-10px row-gap-10">
				<PatientProfileCard patientData={patientData} />
				<div className="flex flex-wrap row-gap-10 column-gap-10px">
					<DemographicsCard patientData={patientData} />
					<AddressCard patientData={patientData} />
					<PrivacyCard patientData={patientData} />
					<EmergencycontactInfoCard patientData={patientData} />
				</div>
			</div>
			<div className='mt-4'>
				<PreferencesCard patientData={patientData} />
			</div>
		</>
	);
};

export default ProfileWrapper;

import React from 'react';

const SubCard = ({ children, title = '', cardAction = null }) => {
	return (
		<div className="bg-white p-4">
			<div className='flex justify-between font-bold'>
				<h1>{title}</h1>
				<div>{cardAction}</div>
			</div>
			<div>{children}</div>
		</div>
	);
};

export default SubCard;

import React from 'react';
import Label from '../../../../../components/common-components/label/Label';
import General from '../../../../../libs/utility/General';

const PolicyHolder = ({ insurance }) => {
	return (
		<>
			<div className="flex p-4">
				<Label color="met-primary" fontWeight='bold'>
					Patient Relationship To Insured
				</Label>
			</div>
			<div className="flex flex-wrap justify-between row-gap-10 p-4">
				<div className="w-1/3 flex items-center column-gap-10px">
					<Label fontWeight='bold'>Name</Label>
					<div>:</div>
					<Label color="gray-500" fontWeight='bold'>
						{`${insurance.firstName} ${insurance.lastName}`}
					</Label>
				</div>
				<div className="w-1/3 flex items-center column-gap-10px">
					<Label fontWeight='bold'>Date Of Birth</Label>
					<div>:</div>
					<Label color="gray-500" fontWeight='bold'>
						{General.getformattedDate(insurance.dob)}
					</Label>
				</div>
				<div className="w-1/3 flex items-center column-gap-10px">
					<Label fontWeight='bold'>Gender</Label>
					<div>:</div>
					<Label color="gray-500" fontWeight='bold'>
						{insurance.gender}
					</Label>
				</div>

				<div className="w-1/3 flex items-center column-gap-10px">
					<Label fontWeight='bold'>Relationship</Label>
					<div>:</div>
					<Label color="gray-500" fontWeight='bold'>
						{insurance.patientRelationshipToInsured}
					</Label>
				</div>
				{/* <div className="w-1/3 flex items-center column-gap-10px">
					<Label fontWeight='bold'>Aadhar</Label>
					<div>:</div>
					<Label color="gray-500" fontWeight='bold'>
						{insurance.aadhar}
					</Label>
				</div> */}
				{/* <div className="w-1/3 flex items-center column-gap-10px">
					<Label fontWeight='bold'>contact Number</Label>
					<div>:</div>
					<Label color="gray-500" fontWeight='bold'>
						{insurance.contactNumber}
					</Label>
				</div> */}
				<div className="w-1/3 flex items-center column-gap-10px">
					<Label fontWeight='bold'>Addres line 1</Label>
					<div>:</div>
					<Label color="gray-500" fontWeight='bold'>
						{insurance.addressLine1}
					</Label>
				</div>

				<div className="w-1/3 flex items-center column-gap-10px">
					<Label fontWeight='bold'>Addres line 2</Label>
					<div>:</div>
					<Label color="gray-500" fontWeight='bold'>
						{insurance.addressLine2}
					</Label>
				</div>
				<div className="w-1/3 flex items-center column-gap-10px">
					<Label fontWeight='bold'>City</Label>
					<div>:</div>
					<Label color="gray-500" fontWeight='bold'>
						{insurance.city}
					</Label>
				</div>
				<div className="w-1/3 flex items-center column-gap-10px">
					<Label fontWeight='bold'>State</Label>
					<div>:</div>
					<Label color="gray-500" fontWeight='bold'>
						{insurance.state}
					</Label>
				</div>
				<div className="w-1/3 flex items-center column-gap-10px">
					<Label fontWeight='bold'>Country</Label>
					<div>:</div>
					<Label color="gray-500" fontWeight='bold'>
						{insurance.country}
					</Label>
				</div>
				<div className="w-1/3 flex items-center column-gap-10px">
					<Label fontWeight='bold'>Zip code</Label>
					<div>:</div>
					<Label color="gray-500" fontWeight='bold'>
						{insurance.zip}
					</Label>
				</div>
			</div>
		</>
	);
};

export default PolicyHolder;

import React, { useCallback, useEffect, useState } from 'react'
import SelectDropdown from '../../../../components/common-components/selectDropdown'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Table from '../../../../containers/Table/Index'
import { componentKey } from './GenrateSuperBillSlice'
import { useDispatch, useSelector } from 'react-redux'
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice'
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/MetToaster'
import { deviceTestsFieldNames } from './Constants'
import Icons from '../../../../components/icons/Icons'
import { getAllDeviceNamesList, getDeviceTestDetails } from './GenrateSuperBillSaga'


function DeviceTestTable({ addDeviceTests, values, setFieldValue, handleBlur, handleRemoveCb }) {

    const { deviceTestsData, deviceNameList, billData } = useSelector(state => state[componentKey])
    const { mapping_billing_payment } = billData || {}

    const dispatch = useDispatch()
    const [selectedDeviceName, setSelectedDeviceName] = useState(null)

    useEffect(() => {
        if (selectedDeviceName) {
            const params = {
                deviceName: selectedDeviceName?.deviceName,
            }
            dispatch(getDeviceTestDetails(params))
        }
        dispatch(getAllDeviceNamesList())
    }, [dispatch, selectedDeviceName])

    const columns = [
        {
            field: "srNo",
            label: <Label fontWeight='md'>Sr No</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.number}.</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "deviceName",
            label: <Label fontWeight='md'>Device Name</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='' fontSize={"md"} customClasses="cursor-pointer">{`${row.deviceName}`}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "testName",
            label: <Label fontWeight='md'>Test Name</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='' fontSize={"md"} customClasses="cursor-pointer">{`${row.testName}`}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "charge",
            label: <Label fontWeight='md'>Charges (₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='' fontSize={"md"} customClasses="cursor-pointer">{`${row?.charge?.toFixed(2)}`}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "action",
            label: <Label fontWeight='md'>Action</Label>,
            renderLogic: (row) => {
                const isDisabled = mapping_billing_payment?.length > 0;
                return <>
                    {row?.isDelete && <div className='flex items-center'>
                        <div className={isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}
                            onClick={!isDisabled ? () => handleRemoveCb(row.uuid) : null} > <Icons iconName="trashIcon" /></div>
                    </div>}
                </>
            },
        },
    ]


    const getRowData = useCallback(() => {
        if (!values?.tests?.length) return []
        return values?.tests?.map((item, index) => {
            return {
                isDelete: item?.isDelete === false ? false : true,
                number: index + 1,
                uuid: item.uuid,
                deviceName: item[deviceTestsFieldNames.DEVICE_NAME],
                testName: item[deviceTestsFieldNames.TEST_NAME],
                charge: item[deviceTestsFieldNames.CHARGE]
            }
        })
    }, [values?.tests])


    const message = "Already Exists";

    return (
        <>

            <div className='w-full'>

                <div className='h-[45px] items-center pl-4 bg-met-secondary flex font-semibold'>
                    Device Tests
                </div>
                <div className='mt-[20px] flex column-gap-20px justify-between'>
                    <div className='w-1/2'>
                        <SelectDropdown
                            placeholder="Search Device Name"
                            options={deviceNameList}
                            onChangeCb={(selectedOpt) => {
                                setSelectedDeviceName(selectedOpt)
                            }}
                            disabled={mapping_billing_payment?.length ? true : false}
                        />
                    </div>
                    {selectedDeviceName && <div className='w-1/2'>
                        <SelectDropdown
                            placeholder="Search Vitals Test"
                            options={deviceTestsData}
                            onChangeCb={(selectedOpt) => {
                                if (selectedOpt && selectedOpt !== null && Object.keys(selectedOpt).length > 0) {
                                    const similarOpt = values?.tests?.find(item => item.uuid === selectedOpt.uuid)
                                    if (similarOpt) {
                                        dispatch(addNotifications({ message: `${selectedOpt.testName} ${message}`, variant: TOASTER_VARIANT.ERROR }))
                                    }
                                    else {
                                        addDeviceTests(selectedOpt)
                                    }
                                }
                            }}
                        />
                    </div>}
                </div>
                <div className='pt-[20px]'>
                    <Table isPagination={false} coloumns={columns} rows={getRowData()} />
                </div>
            </div>
        </>
    )
}

export default DeviceTestTable
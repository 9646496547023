import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { getValidationSchema } from '../../../libs/formsUtils';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import Icons from '../../../components/icons/Icons';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import Input from '../../../components/common-components/input/Input';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import General from '../../../libs/utility/General';
import Label from '../../../components/common-components/label/Label';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT } from '../constant';
import RadioButton from '../../../components/common-components/radioButton/RadioButton';
import { MET_COLORS } from '../../../libs/constant';
import {
    componentKey as ScheduleryKey,
    setAvailableSlotsOfProvider,
    setIsAddNewPatientModalOpen,
    setIsNewAppointmentModal,
    setSelectedProviderId,
    setSelectedProvidersLocation,
} from '../ShedulingSlice';
import DatePicker from '../../../components/common-components/datePicker';
import Checkbox from '../../../components/common-components/checkbox/Checkbox';
import AddNewPatient from './AddNewPatient';
import Avatar from '../../../components/common-components/avatar';
import ModalPortal from '../../../components/common-components/modal/ModalPortal';
import { componentKey } from '../../../containers/Wrappers/Dataloader/DataloaderSlice';
import { getAllPatients, getProviderLocations, getProviderLocationsByProviderId, postAppointmentsSlots, postCreateNewAppointment } from '../SchedulingSaga';
import { componentKey as routerComponentKey } from '../../../routes/RoutesSlice'
import moment from 'moment';
import IsAccessAllowed from '../../../containers/Wrappers/IsAccessAllowed';
import { USER_ROLE_NAMES } from '../../../containers/Wrappers/Constants';

const DAYS = [
	{ label: "Sun", value: "Sunday" },
	{ label: "Mon", value: "Monday" },
	{ label: "Tue", value: "Tuesday" },
	{ label: "Wed", value: "Wednesday" },
	{ label: "Thur", value: "Thursday" },
	{ label: "Fri", value: "Friday" },
	{ label: "Sat", value: "Saturday" },
]
const NewAppointmentModal = ({ close }) => {

    const [selectedTimeOfAppointment, setSelectedTimeOfAppointment] = useState(null);
    const [selectedDays, setSelectedDays] = useState([]);
    const [isselectedPatient, setIsSelectedPatient] = useState(false);
    const [selectedPatientData, setSelectedPatientData] = useState({});
    const [isSelectedProvider, setIsSelectedProvider] = useState(false);

    const {
        isNewAppointModalOpen,
        isAddNewPatientModalOpen,
        isBlockApoointmentModalOpen,
        addNewPatientData,
        availableSlotsOfProvider,
        selectedProvidersLocation,
        schedulingAppointmentsPaginationState
    } = useSelector((state) => state[ScheduleryKey]);
    const { providersList, allProviderGroupLocation } = useSelector((state) => state[componentKey]);
    const { loggedInUser, loggedInProviderUser } = useSelector(state => state[routerComponentKey])

    const dispatch = useDispatch();

    const [isAdminUser, setIsAdminUser] = useState(false)
    const [isFrontDeskUser, setIsFrontDeskUser] = useState(false)
    const [selectedProvider, setSelectedProvider] = useState({})
    const [selectedProviderId, setSelectedProviderId] = useState({})

    useEffect(() => {
        const allowedRoles = [
            USER_ROLE_NAMES.ADMIN_PROVIDER,
        ]

        const currentRole = loggedInProviderUser?.roleNames || []
        setIsAdminUser(IsAccessAllowed(allowedRoles, currentRole))
        setIsFrontDeskUser(currentRole[0] === 'Front Desk' ? true : false)
    }, [loggedInProviderUser])

    useEffect(() => {
        if (!isAdminUser) {
            setSelectedProvider({ label: loggedInUser?.name, value: loggedInUser?.uuid })
            setSelectedProviderId({ label:loggedInUser?.providerId, value: loggedInUser?.providerId })
        }
    }, [isAdminUser, loggedInUser, isFrontDeskUser, loggedInProviderUser])
    useEffect(() => {
        if (!selectedProvider?.value) return;
        dispatch(getProviderLocationsByProviderId({ providerId: selectedProvider?.value }));
    }, [selectedProvider, isAdminUser, loggedInProviderUser, isFrontDeskUser]);

    useEffect(() => {
        if (!selectedProviderId?.value) return;
        dispatch(getProviderLocationsByProviderId({ providerId: selectedProviderId?.value }));
    }, [selectedProviderId, isFrontDeskUser]);


    const fields = [
        { fieldName: FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.PATIENT_NAME, isRequired: true },
        { fieldName: FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.PROVIDER, isDropdown: true, isRequired: true },
        { fieldName: FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.APPOINTMENT_TYPE, isDropdown: true, isRequired: true },
        { fieldName: FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.START_DATE, isRequired: true },

		// { fieldName: FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.COUNT, isRequired: true },
		// { fieldName: FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.EVERY, isRequired: true },
		// { fieldName: FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.DAYS, isRequired: true },
	];
	const validationSchema = getValidationSchema(fields);
	const days = selectedDays?.map((item, index) => item.value)
	const selectPatient = (option, index) => {
		setIsSelectedPatient(true);
		setSelectedPatientData(option);
	};
	const defaultRenderOption = (option, index) => {
		const formattedUUID = option?.uuid.substring(0, 5);
		const UUIDWithUpperCase = formattedUUID.charAt(0).toUpperCase() + formattedUUID.slice(1);
		const currentDate = moment()
		const ageOfPerson = currentDate.diff(option.dob, 'years')
		return (
			<div
				className={`flex items-center justify-between w-full p-2 ${isselectedPatient && isselectedPatient.value === option.value
					? 'bg-met-primary text-white'
					: 'hover:bg-gray-100'
					}`}
			>
				<div className="flex justify-between w-full" onClick={() => selectPatient(option, index)}>
					<div className="w-[10%] items-center flex">
						<Avatar
							url={option?.profilePicture}
							name={`${option?.firstName} ${option?.lastName}`}
							width={64}
							height={64}
							customClass="rounded-[5px]"
						/>
					</div>
					<div className="w-[85%] flex flex-col justify-between p-2">
						<div className="flex">
							<p>{option.label}</p>
							{option.dob && (
								<p>
									({`${General.getformattedDate(option?.dob)}, ${ageOfPerson}yrs, ${option?.gender}`})
								</p>
							)}
						</div>
						<div className="flex justify-between mt-4">
							<p className="flex w-[20%]">
								<Icons iconName="personWithBorder" />

								{UUIDWithUpperCase || '-'}
							</p>
							<p className="flex w-[30%]">
								<Icons iconName="mobileIcon" />
								{option?.mobileNumber || '-'}
							</p>
							<p className="flex w-[60%]">
								<Icons iconName="emailIcon" color={"#1A1A1A99"} />
								{option?.email || '-'}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const locations = selectedProvidersLocation?.map((item) => {

		return {
			...item.provider_locations,
			label: item.provider_locations.name,
			value: item.provider_locations.uuid,
		};
	});




	const toggleDay = (day) => {
		setSelectedDays((prevSelectedDays) => {
			if (prevSelectedDays.includes(day)) {
				return prevSelectedDays.filter((selectedDay) => selectedDay !== day);
			} else {
				return [...prevSelectedDays, day];
			}
		});
	};
	const gender = General.camelToPascalWithSpaces(selectedPatientData?.gender)
	const formattedUUID = selectedPatientData?.uuid?.substring(0, 5);
	const UUIDWithUpperCase = formattedUUID?.charAt(0)?.toUpperCase() + formattedUUID?.slice(1);
	const currentDate = moment();
	const ageOfPerson = currentDate.diff(selectedPatientData?.dob, 'years');
	const defaultRenderOptionForProvider = (option, onChangeCb = () => { }) => {
		const specialities = option.provider_speciality_intermediate.map((item) => {
			return item?.speciality.name;
		});
		return (
			<div
				className={`flex items-center justify-between w-full p-2 ${isSelectedProvider && isSelectedProvider.value === option.value
					? 'bg-met-primary text-white'
					: 'hover:bg-gray-100'
					}`}
			>
				<div
					className="flex justify-between w-full"
					onClick={() => {
						onChangeCb(option);
					}}
				>
					<div className="flex justify-between">
						<Avatar url={option?.profilePicture} name={`${option?.firstName} ${option?.lastName}`} />
						<div className='flex flex-col'>
							<p className="ml-[10px]">{option.label}</p>
							{/* <p className='ml-[10px]'>{specialities?.length > 1 ? "Multispecialist" : specialities}</p> */}
							<p className='ml-[10px]'>{specialities && specialities.length > 0 ? (specialities.length > 1 ? "Multispecialist" : specialities) : "-"}</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

    return (
        <Formik
            initialValues={{
                [FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.MODE]: 'In_Person',
                [FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.PATIENT_NAME]:
                    addNewPatientData?.patient || selectedPatientData?.uuid,
                [FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.PROVIDER]: isAdminUser ? addNewPatientData?.provider : isFrontDeskUser
                                                  ? selectedProviderId: selectedProvider,

				[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.APPOINTMENT_TYPE]: addNewPatientData?.appointmentType,
				[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.LOCATION]: addNewPatientData?.locationId,
				[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.START]: addNewPatientData?.start,
				[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.COUNT]: addNewPatientData?.appointmentCount,
				[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.EVERY]: addNewPatientData?.every || 0,
				[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.DAYS]: addNewPatientData?.days || [],
				[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.CHIEF_COMPLAINT]: addNewPatientData?.complaints,
				[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.IS_REPEAT]: addNewPatientData?.isRepeat,
				[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.REPEAT_TYPE]: addNewPatientData?.repeatType,
				[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.START_DATE]: addNewPatientData?.startDate,
				// [FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.]
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
            const selectedProvider = providersList.find(item => item.uuid == values?.providerId?.uuid)

                const payload = {
                    ...values,
                    providerId: values?.providerId?.value  ,
                    locationId: values?.locationId?.uuid || undefined,
                    appointmentType: values?.appointmentType?.value,
                    every: General.sanitizeNumbers(values?.every?.value) || 0,
                    repeatType: values?.repeatType?.value,
                    days: days,
                    // appointmentCount: (values?.appointmentCount),
                    type: 'New_Appointment',
                };

				dispatch(postCreateNewAppointment({ createNewAppointment: payload, schedulingAppointmentsPaginationState, selectedPatientData, selectedProvider }));
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<>
						<Form onSubmit={handleSubmit}>
							<ModalComponent
								open={isNewAppointModalOpen}
								validationSchema={validationSchema}
								title={'Schedule New Appointments'}
								close={() => {
									close();
									dispatch(setAvailableSlotsOfProvider({}))
								}}
								icon={<Icons iconName="closeIcon" />}
								footerButton={
									<Button
										type="submit"
										variant={BUTTON_VARIANTS.CONTAINED}
										customBtnClass="text-met-primary"
									>
										Save
									</Button>
								}
								customClasses="w-[50%] overflow-visible"
								enableReinitialize
							// hideOverflow={true}
							>
								<div>
									{isselectedPatient && (
										<div className="flex ">
											<div className="w-[10%]">
												<Avatar
													url={selectedPatientData?.profilePicture}
													name={`${selectedPatientData?.firstName} ${selectedPatientData?.lastName}`}
													height={64}
													width={64}
												/>
											</div>
											<div className="w-[70%]">
												<div>
													<p className='font-bold'>{`${selectedPatientData?.label} (${UUIDWithUpperCase || '-'})`}</p>
												</div>
												<div className="flex jusstify-between">
													<p className="w-[20%]">
														{moment(selectedPatientData?.dob).format('DD-MM-YYYY')}
													</p>
													<p className='w-[10%]'>{`${ageOfPerson}yrs`}</p>
													<p>{gender || "-"}</p>
												</div>
												<div className="flex">
													<p className="w-[20%] flex">
														<span><Icons iconName="mobileIcon" /></span>
														{selectedPatientData?.mobileNumber || '-'}
													</p>
													<p className="flex">
														<span><Icons iconName="emailIcon" color={'#1A1A1A99'} /></span>

														{selectedPatientData?.email || "-"}</p>
												</div>
											</div>
											<div className="w-[20%]">
												<p className="text-met-primary cursor-pointer" onClick={() => setIsSelectedPatient(false)}
													style={{ textDecoration: 'underline' }}

												>
													Change Patient
												</p>
											</div>
										</div>
									)}
									{!isselectedPatient && (
										<div className="flex justify-between">
											<div className="w-[75%] mt-1">
												<SelectDropdown
													placeholder="Select Patient"
													label="Patient Name"
													isImageInOption={true}
													isRequired={true}
													name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.PATIENT_NAME}
													value={
														values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.PATIENT_NAME]
													}
													onChangeCb={(val) => {
														setFieldValue(
															FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.PATIENT_NAME,
															val
														);
													}}
													renderOption={defaultRenderOption}
													isAsync={true}
													url='patients?key=appointment'
													extractKeyFromResponse="patients"
													labelKey={"firstName"}
													valueKey={"uuid"}
													labelKey2={"lastName"}
												/>
											</div>
											<div className="w-[20%] mt-[30px]">
												<Button
													onClickCb={() => {
														dispatch(
															setIsAddNewPatientModalOpen(!isAddNewPatientModalOpen)
														);

													}}
													variant={BUTTON_VARIANTS.OUTLINED}
													startIcon={{
														icon: (
															<Icons iconName="addPersonIcon" color={MET_COLORS.GRAY} />
														),
													}}
												>
													New Patient
												</Button>
											</div>
										</div>
									)}
								</div>
								<div>
									<div className="flex column-gap-16 row-gap-16  flex-wrap mt-5">
										<div>
											<Label fontWeight="bold">Appointment Mode</Label>
										</div>
									</div>
									<div className="flex column-gap-16 row-gap-16  flex-wrap pr-4 pt-2 pb-2 pl-4">
										<div className="flex flex-row  gap-6">
											<div>
												<RadioButton
													name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.MODE}
													value={values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.IN_PERSON]}
													// checked={radioButtons.isInPersonChecked}
													checked={
														values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.MODE] ===
														FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.IN_PERSON
													}
													onChangeCb={(val) => {
														// handleRadioButtonChange('isInPersonChecked');
														setFieldValue(
															FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.MODE,
															FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.IN_PERSON
														);
														dispatch(setAvailableSlotsOfProvider({}))

													}}
													label={'In_Person'}
												/>
											</div>

											<div>
												<RadioButton
													name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.MODE}
													value={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.VIDEO_CALL}
													// checked={radioButtons.isVideoCallChecked}
													checked={
														values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.MODE] ===
														FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.VIDEO_CALL
													}
													onChangeCb={() => {
														// handleRadioButtonChange('isVideoCallChecked');
														setFieldValue(
															FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.MODE,
															FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.VIDEO_CALL
														);
														dispatch(setAvailableSlotsOfProvider({}))
													}}
													label={'Video Call'}
												/>
											</div>

											<div>
												<RadioButton
													name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.MODE}
													value={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.VOICE_CALL}
													// checked={radioButtons.isVoiceCallChecked}
													checked={
														values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.MODE] ===
														FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.VOICE_CALL
													}
													onChangeCb={() => {
														// handleRadioButtonChange('isVoiceCallChecked');
														setFieldValue(
															FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.MODE,
															FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.VOICE_CALL
														);
													}}
													label={'Voice Call'}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="flex justify-between flex-wrap mt-4">
									<div className="w-[45%] mt-2">
										<SelectDropdown
											placeholder="Select Provider"
											label="Provider Name"
											isImageInOption={true}
											// isRequired={true}
											name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.PROVIDER}
                                            value={
                                                isAdminUser
                                                    ? values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.PROVIDER]
                                                    : isFrontDeskUser
                                                        ? selectedProviderId
                                                        : selectedProvider
                                            }		
                                            onChangeCb={(val) => {
												setFieldValue(FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.PROVIDER, val);
												dispatch(setSelectedProvidersLocation(val.provider_location_intermediate))

                                            }}
                                            disabled={isAdminUser ? false : true}
                                            renderOption={defaultRenderOptionForProvider}
                                            isAsync={true}
                                            url='providers'
                                            extractKeyFromResponse=""
                                            labelKey={"firstName"}
                                            valueKey={"uuid"}
                                            labelKey2={"lastName"}
                                        />
                                    </div>
                                    {values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.MODE] ===
                                        FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.IN_PERSON && (
                                            <div className="w-[45%] mt-2">
                                                <SelectDropdown
                                                    placeholder="Select Location"
                                                    label="Location"
                                                    // isRequired={true}
                                                    name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.LOCATION}
                                                    value={values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.LOCATION] || []}
                                                    onChangeCb={(val) => {
                                                        setFieldValue(
                                                            FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.LOCATION,
                                                            val
                                                        );
                                                    }}
                                                    options={locations}
                                                />
                                            </div>
                                        )}
                                    <div className="w-[45%] mt-2">
                                        <SelectDropdown
                                            placeholder="Select Type"
                                            label="Appointment Type"
                                            isRequired={true}
                                            name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.APPOINTMENT_TYPE}
                                            value={
                                                values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.APPOINTMENT_TYPE] || []
                                            }
                                            onChangeCb={(val) => {
                                                setFieldValue(
                                                    FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.APPOINTMENT_TYPE,
                                                    val
                                                );
                                            }}
                                            options={[
                                                { label: 'New Appointment', value: 'New_Appointment' },
                                                { label: 'Follow Up', value: 'Follow_Up' },
                                            ]}
                                        />
                                    </div>
                                </div>
                                {/* <div className="w-full mt-4">
									<DatePicker
										label="Date & Time"
										open={false}
										placeholder="Date"
										isRequired={true}
										onFocusCb={() => {
											setIsDatePickerTouched(!isDatePickerTouched);
										}}
									// name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.DATE_AND_TIME}
									// value={values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.DATE_AND_TIME]}
									// onChangeCb={(date) => {
									// 	setIsDatePickerTouched(!isDatePickerTouched);

									// }}
									/>
								</div> */}
                                {/* {isDatePickerTouched && ( */}
                                <div className="flex w-full justify-between mt-4">
                                    <div className="w-[35%]">
                                        <DatePicker
                                            isRequired={true}
                                            label='Date & Time'
                                            showMonthDropdown={false}
                                            placeholder='Please select start date'
                                            showYearDropdown={false}
                                            name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.START_DATE}
                                            value={values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.START_DATE]}
                                            onChangeCb={(date) => {
                                                // dispatch(setActiveDate(date));
                                                setFieldValue(
                                                    FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.START_DATE,
                                                    date
                                                );
                                                const providerId =
                                                isAdminUser
                                                    ? values?.providerId?.uuid
                                                    : isFrontDeskUser
                                                        ? selectedProviderId?.value
                                                        : selectedProvider?.value
                                                dispatch(
                                                    postAppointmentsSlots({
                                                        patientId: selectedPatientData?.uuid,
                                                        providerId: providerId,
                                                        locationId: values.mode === "In_Person" ? values?.locationId?.uuid : undefined,
                                                        appointmentType: values?.appointmentType?.value,
                                                        startDate: date,
                                                        mode: values?.mode,
                                                    })
                                                );
                                            }}
                                            minDate={new Date()}
                                            customClass="w-full"
                                        />
                                    </div>
                                    <div className="w-[60%]">
                                        {Object.keys(availableSlotsOfProvider || {}).map((key, index) => {
                                            const slots = availableSlotsOfProvider[key] || [];
                                            if (
                                                slots.length === 0 &&
                                                (key === 'morning' || key === 'afternoon' || key === 'evening')
                                            ) {
                                                return <div key={index}>"No slots available for {key}"</div>;
                                            }
                                            return (
                                                <div key={index} className="w-full p-1">
                                                    <Label fontWeight="semi-bold">{key}</Label>
                                                    <div className="flex flex-wrap">
                                                        {slots.map((time, index) => (
                                                            <div
                                                                key={index}
                                                                // className={`shadow-[0px_2px_8px_#00000029] rounded-5px p-2 text-[#00000029] m-2 cursor-pointer ${selectedTimeOfAppointment} ? "text-met-primary border-met-primary" : ""`}
                                                                className={`shadow-[0px_2px_8px_#00000029] rounded-5px p-2 text-[#00000029] m-2 cursor-pointer ${selectedTimeOfAppointment === time
                                                                    ? 'text-[#0097F0] border-[1px] border-[#0097F0]'
                                                                    : ''
                                                                    }`}
                                                                onClick={() => {
                                                                    setSelectedTimeOfAppointment(time);
                                                                    setFieldValue(
                                                                        FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.START,
                                                                        time
                                                                    );
                                                                }}
                                                            >
                                                                <p>{time}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                {/* )} */}
                                <div className="mt-4">
                                    <Checkbox
                                        label={'Repeat'}
                                        style={{ color: 'gray-500' }}
                                        checked={values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.IS_REPEAT]}
                                        onChangeCb={(e) => {
                                            // setIsRepeatCheckboxChecked(!isRepeatCheckboxChecked);
                                            setFieldValue(FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.IS_REPEAT, e);
                                            if (e === false) {
                                                setFieldValue(FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.EVERY, [])
                                                setFieldValue(
                                                    FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.REPEAT_TYPE,
                                                    []
                                                );

                                                setSelectedDays([])
                                                setFieldValue(
                                                    FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.END_DATE,
                                                    ""
                                                );
                                                setFieldValue(
                                                    FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.COUNT,
                                                    ""
                                                );
                                            }
                                        }}
                                    />
                                </div>
                                {values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.IS_REPEAT] && (
                                    <div
                                        className={`mt-4 ${isBlockApoointmentModalOpen === true ? 'w-full' : 'w-[80%]'
                                            }`}
                                    >
                                        <div className="flex  justify-between w-[80%]">
                                            <p className="mt-4">Every</p>
                                            <div className="">
                                                <SelectDropdown
                                                    placeholder="Select"
                                                    name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.EVERY}
                                                    value={values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.EVERY] || []}
                                                    onChangeCb={(val) => {
                                                        setFieldValue(
                                                            FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.EVERY,
                                                            val
                                                        );
                                                    }}
                                                    options={[
                                                        { label: '1', value: '1' },
                                                        { label: '2', value: '2' },
                                                        { label: '3', value: '3' },
                                                        { label: '4', value: '4' },
                                                        { label: '5', value: '5' },
                                                    ]}
                                                />
                                            </div>
                                            <div className="">
                                                <SelectDropdown
                                                    placeholder="Select"
                                                    name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.REPEAT_TYPE}
                                                    value={
                                                        values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.REPEAT_TYPE] ||
                                                        []
                                                    }
                                                    onChangeCb={(val) => {
                                                        setFieldValue(
                                                            FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.REPEAT_TYPE,
                                                            val
                                                        );
                                                        if (val.value === "Day" || val.value === "Month") {
                                                            setSelectedDays([])
                                                        }
                                                    }}
                                                    options={[
                                                        { label: 'Day', value: 'Day' },
                                                        { label: 'Week', value: 'Week' },
                                                        { label: 'Month', value: 'Month' },
                                                        // { label: 'Year', value: 'Year' },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        {values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.REPEAT_TYPE]?.value === "Week" && <div className="w-[30%] mt-5">
                                            <div className="flex">
                                                {DAYS.map(
                                                    (item, index) => (
                                                        <div
                                                            key={item}
                                                            className={`h-10 p-2 w-10 flex flex-wrap justify-center items-center cursor-pointer border-[1px] rounded-[5px] border-[#1A1A1A33] ${index !==
                                                                ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                                                                    .length -
                                                                1
                                                                ? 'mr-1'
                                                                : ''
                                                                } ${selectedDays.includes(item)
                                                                    ? 'bg-met-primary text-white'
                                                                    : 'bg-met-primary-bg text-rgba-1A1A1A66'
                                                                }`}
                                                            onClick={() => {
                                                                toggleDay(item);
                                                                setFieldValue(
                                                                    FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.DAYS,
                                                                    selectedDays
                                                                );
                                                            }}
                                                        >
                                                            {item?.label}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>}
                                        <div className="flex justify-between mt-5">
                                            <p className="mt-4">End On</p>
                                            <div>
                                                <DatePicker
                                                    // label="Date & Time"
                                                    // open={false}
                                                    isClearable={true}
                                                    placeholder="Choose Date"
                                                    // onFocusCb={() => setIsDatePickerTouched(!isDatePickerTouched)}
                                                    name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.END_DATE}
                                                    value={values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.END_DATE]}
                                                    onChangeCb={(date) => {
                                                        // setIsDatePickerTouched(!isDatePickerTouched);
                                                        setFieldValue(
                                                            FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.END_DATE,
                                                            date
                                                        );
                                                    }}
                                                    disabled={values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.COUNT] ? true : false}
                                                    minDate={values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.START_DATE] ? moment(values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.START_DATE]).format('DD/MM/YYYY') < moment(new Date()).format('DD/MM/YYYY') ? new Date() : new Date(values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.START_DATE]).setDate(new Date(values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.START_DATE]).getDate()) : ''}
                                                />
                                            </div>
                                            <div>
                                                <Input
                                                    onChangeCb={(e) => {
                                                        setFieldValue(
                                                            FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.COUNT,
                                                            General.sanitizeNumbers(e.target.value)
                                                        );
                                                    }}
                                                    placeholder="Enter"
                                                    name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.COUNT}
                                                    value={values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.COUNT]}
                                                    disabled={values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.END_DATE] ? true : false}
                                                />
                                            </div>
                                            <Label customClass="mt-4">Appointments</Label>
                                        </div>
                                    </div>
                                )}
                                <div className="w-full mt-4">
                                    <Input
                                        label="Chief Complaint"
                                        // isRequired={true}
                                        placeholder="Enter reason"
                                        name={FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.CHIEF_COMPLAINT}
                                        value={values[FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.CHIEF_COMPLAINT]}
                                        onChangeCb={(e) => {
                                            setFieldValue(
                                                FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.CHIEF_COMPLAINT,
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </ModalComponent>
                            {isAddNewPatientModalOpen && (
                                <ModalPortal>
                                    <AddNewPatient
                                        open={isAddNewPatientModalOpen}
                                        close={() => dispatch(setIsAddNewPatientModalOpen(false))}
                                    />
                                </ModalPortal>
                            )}
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
};
export default NewAppointmentModal;

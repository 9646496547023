import React from 'react';

function ClockWithPendingStatusIcon({ color = '' }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="schedule_black_24dp_9_"
			data-name="schedule_black_24dp (9)"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path id="Path_206961" data-name="Path 206961" d="M0,0H24V24H0Z" fill="none" />
			<g id="Group_94816" data-name="Group 94816" transform="translate(-1 -1)">
				<path
					id="Path_206962"
					data-name="Path 206962"
					d="M9.792,2A7.8,7.8,0,1,0,17.6,9.8,7.8,7.8,0,0,0,9.792,2ZM9.8,16.039a6.24,6.24,0,1,1,6.24-6.24A6.238,6.238,0,0,1,9.8,16.039Z"
					transform="translate(3 3)"
					fill="#fa0"
				/>
				<path
					id="Path_206970"
					data-name="Path 206970"
					d="M9.35,5.5H8.3V9.7l3.675,2.2.525-.861L9.35,9.175Z"
					transform="translate(3.4 4)"
					fill="#fa0"
					stroke="#fa0"
					strokeWidth="0.4"
				/>
			</g>
		</svg>
	);
}

export default ClockWithPendingStatusIcon;

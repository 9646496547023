import React, { useRef, useState, useEffect, useCallback, forwardRef } from 'react';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { ShareStatus } from '@zoom/videosdk';
import classNames from 'classnames';
import Draggable from 'react-draggable';
import { SHARE_CANVAS_ID } from '../../constants';
import './share-bar.scss';

const ShareBar = forwardRef((props, ref) => {
    const { controllingUser, mediaStream, zmClient } = props;

    const [status, setStatus] = useState(mediaStream?.getShareStatus());
    const [hideShareAudioTooltip, setHideShareAudioTooltip] = useState(false);
    const [shareAudioStatus, setShareAudioStatus] = useState(mediaStream?.getShareAudioStatus());
    const [isVideoShare, setIsVideoShare] = useState(mediaStream?.isOptimizeForSharedVideoEnabled());
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const draggableRef = useRef(null);

    const onShareAudioChange = useCallback(() => {
        setShareAudioStatus(mediaStream?.getShareAudioStatus());
    }, [mediaStream]);

    const onShareAudioClick = useCallback(() => {
        if (shareAudioStatus?.isShareAudioEnabled) {
            if (shareAudioStatus.isShareAudioMuted) {
                mediaStream?.unmuteShareAudio();
            } else {
                mediaStream?.muteShareAudio();
            }
        }
    }, [mediaStream, shareAudioStatus]);

    const onSharePauseClick = useCallback(() => {
        if (status === ShareStatus.Paused) {
            mediaStream?.resumeShareScreen();
        } else if (status === ShareStatus.Sharing) {
            mediaStream?.pauseShareScreen();
        }
    }, [mediaStream, status]);

    const onShareStatusChange = useCallback(() => {
        if (status !== mediaStream?.getShareStatus()) {
            setStatus(mediaStream?.getShareStatus());
        }
    }, [status, mediaStream]);

    useEffect(() => {
        zmClient.on('share-audio-change', onShareAudioChange);
        zmClient.on('user-updated', onShareStatusChange);
        return () => {
            zmClient.off('share-audio-change', onShareAudioChange);
            zmClient.off('user-updated', onShareStatusChange);
        };
    }, [zmClient, onShareAudioChange, onShareStatusChange]);

    const menuItems = [];

    if (mediaStream?.isSupportOptimizedForSharedVideo()) {
        menuItems.push({ text: 'Optimize for video clip', key: 'video share', icon: isVideoShare && "Check Icon" });
    }

    if (controllingUser) {
        menuItems.push({ text: 'Stop remote control', key: 'stop control' });
    }

    const onMenuClick = useCallback(
        (key) => {
            if (key === 'video share') {
                mediaStream?.enableOptimizeForSharedVideo(!isVideoShare);
                setIsVideoShare(!isVideoShare);
            } else if (key === 'stop control') {
                mediaStream?.stopRemoteControl();
            }
        },
        [mediaStream, isVideoShare]
    );

    return (
        <div className={classNames({ 'share-bar-hide': status === ShareStatus.End })}>
            (
            <Draggable handle=".share-bar-move" nodeRef={draggableRef}>
                <div className="screen-share-control-bar" ref={draggableRef}>
                    <Button className="share-bar-move" outline color="primary">
                        {/* <IconFont type="icon-move" /> */}
                    </Button>
                    <div className="share-bar-tip">
                        {status === ShareStatus.Sharing ? "You're sharing the screen" : 'Your screen sharing is paused'}
                    </div>

                    {mediaStream?.isStartShareScreenWithVideoElement() ? (
                        <video id={SHARE_CANVAS_ID} className="share-bar-canvas" ref={ref} />
                    ) : (
                        <canvas id={SHARE_CANVAS_ID} className="share-bar-canvas" ref={ref} />
                    )}

                    {shareAudioStatus?.isShareAudioEnabled && (
                        <Popover placement="bottom" isOpen={!hideShareAudioTooltip} target="Popover1">
                            <PopoverHeader>Your microphone is disabled when sharing computer audio.</PopoverHeader>
                            <PopoverBody>
                                When you pause or stop sharing audio, your microphone will be reactivated.
                                <Button color="link" onClick={() => setHideShareAudioTooltip(true)}>Got it</Button>
                            </PopoverBody>
                        </Popover>
                    )}

                    {/* <Button outline color="primary" className="share-bar-btn" onClick={onShareAudioClick}>
                        {shareAudioStatus.isShareAudioMuted ? 'icon-audio-off' : 'icon-audio-on'}
                        {/* <IconFont type={shareAudioStatus.isShareAudioMuted ? 'icon-audio-off' : 'icon-audio-on'} /> */}
                    {/* </Button> */}

                    {/* <Button outline color="primary" className="share-bar-btn" onClick={onSharePauseClick}>
                        {status === ShareStatus.Paused ? 'icon-resume' : 'icon-pause'}
                        {/* <IconFont type={status === ShareStatus.Paused ? 'icon-resume' : 'icon-pause'} /> */}
                    {/* </Button> */}
                    <Button color="danger" className="share-bar-btn" onClick={() => mediaStream?.stopShareScreen()}>
                        {/* <IconFont type="icon-stop" /> */}
                        Stop Share
                    </Button>
                </div>
            </Draggable>
            )
        </div>
    );
});

ShareBar.displayName = 'ShareBar';

export default ShareBar;

import React from 'react';
const CopyUrlIcon = () => {
	return (
		<svg
			id="link_black_24dp_2_"
			data-name="link_black_24dp (2)"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path id="Path_186809" data-name="Path 186809" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_186810"
				data-name="Path 186810"
				d="M17,7H13V9h4a3,3,0,0,1,0,6H13v2h4A5,5,0,0,0,17,7Zm-6,8H7A3,3,0,0,1,7,9h4V7H7A5,5,0,0,0,7,17h4ZM8,11h8v2H8Z"
				fill="#1b5984"
			/>
		</svg>
	);
};
export default CopyUrlIcon;

import React, { useEffect } from 'react'
import Carousel from '../../components/common-components/carousel'
import ProviderInfoCard from '../../components/common-components/providerInfoCard'
import LoginCard from './LoginCard'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Forgot from './Forgot'
import { componentKey, setLoggedInUser } from '../../routes/RoutesSlice'
import { useSelector } from 'react-redux'
import Logo from '../../components/common-components/logo/Logo'
import ResetPassword from './ResetPassword'


const providersInfo = [
    {
        image: '/metLogin_Image_01.png',
        quote: "Time and health are two precious assets that we don't recognize and appreciate until they have been depleted",
        name: "Marietta Kaufman",
        organization: "Proton HealthCare",
        position: "Obstetrics and Gynecology."
    },
    {
        image: '/metLogin_Image_02.png',
        quote: "Good health is not something we can buy. However, it can be an extremely valuable savings account.",
        name: "Jaymie Richmond",
        organization: "Proton HealthCare",
        position: "Internal Medicine."
    },
    {
        image: "/MetLogin_Image_03.png",
        quote: "A fit body, a calm mind, a house full of love. These things cannot be bought – they must be earned.",
        name: "Gonzalo Neuman",
        organization: "Proton HealthCare",
        position: "General Surgery."
    },
]
const Login = () => {
    const routes = [
        { path: "/", component: <LoginCard /> },
        { path: "/recovery", component: <Forgot /> },
        { path: "/recovery/reset", component: <ResetPassword /> },
    ]

    const navigate = useNavigate()
    const { loggedInUser } = useSelector(state => state[componentKey])


    useEffect(() => {
        if (loggedInUser?.uuid) {
            navigate("/");
        }
    }, [loggedInUser?.uuid, navigate]);


    const CarouselContent = providersInfo.map((provider, index) => {
        return <ProviderInfoCard
            key={"provider-info-card-" + index}
            image={provider.image}
            quote={provider.quote}
            name={provider.name}
            organization={provider.organization}
            position={provider.position}
        />
    })

    return (
        <>
            <div className='flex'>
                <div className='flex-1 bg-white h-full flex flex-col'>
                    <Routes>
                        {routes.map((route, index) => {
                            return <Route key={"login-route" + index} path={route.path} element={route.component} />
                        })}
                    </Routes>
                </div>
                <Carousel customClasses="flex-1 h-[100vh]" content={CarouselContent} />
            </div>
        </>
    )
}

export default Login

import React from 'react'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import FaceSheetCard from '../Dashboard/FaceSheet/FaceSheetCard'
import { HPIdata, SymptomaticData, assessmentData, encounterData, historyData, icdCodeData, medicationData, objectiveData, planData, reviewsystemData, subjectiveData, vitalsData } from './AppointmentData'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'

const PatientFormModal = ({ isOpen, close }) => {
    return (
        <ModalComponent customClasses='w-[70%]' close={close} open={isOpen} footerButton={<Button variant={BUTTON_VARIANTS.OUTLINED}>Print</Button>}>
            <div className='sticky top-0 bg-white'>
                <div className='flex flex-wrap flex-col items-center '>
                    <div className='text-2xl font-extrabold text-met-primary'>METSL</div>
                    <div className='pt-3'>METSL Test Account</div>
                    <div>70 Washington Square South, New York, NY 10012, United States</div>
                    <div>Office Number- 414-690-5082 / Fax Number - 1-408-999 8888 </div>
                </div>

                <div className='mt-3 border border-dashed border-gray-400'></div>
            </div>
            <div className='text-met-primary font-bold pt-3'>ENCOUNTER DETAILS</div>
            <div className='pt-3'> <FaceSheetCard paddingTopClass="p-0" customClassName="p-[12px]">
                <div className='flex flex-row column-gap-20px'>
                    <div>Encounter Date -</div>
                    <div className='text-gray-500'>Mon, Jan 23 2023</div>
                    <div className='text-gray-500'>(Last amended by Dinah Soria on Apr, 16 2022 at 02:34 PM)</div>
                </div>
                <div className='flex  flex-wrap justify-between'>
                    {encounterData.map((encounter, index) => (
                        <div className='flex w-1/2 flex-row column-gap-20px pt-2' key={"encounterData-appointment" + index}>
                            <div className=''>{encounter.feildName}</div>
                            <div className=' text-gray-500'>{encounter.feildData}</div>
                        </div>
                    ))}
                </div>
            </FaceSheetCard>
            </div>
            <div className='text-met-primary font-bold pt-3'>CHIEF COMPLAINT</div>
            <div className='pt-3'>
                <FaceSheetCard paddingTopClass="p-0" customClassName="p-[12px]">
                    <div className='text-gray-500'>Unspecified essential hypertension</div>
                </FaceSheetCard>
            </div>

            <div className='text-met-primary font-bold pt-3'>SUBJECTIVE</div>
            <div className='pt-3'>
                <FaceSheetCard paddingTopClass="p-0" customClassName="p-[12px]">
                    {subjectiveData.map((subjectData, index) => (
                        <div className='pt-1' key={"subjectiveData-appointment" + index}>
                            <div className='text-gray-500'>{subjectData}</div>
                        </div>

                    ))}
                    <div className='pt-3'>
                        <hr className='' />
                    </div>
                    <div className='pt-4 flex flex-row column-gap-5px items-center'>
                        <div className="h-4 w-4 bg-met-primary rounded-full mr-2"></div>
                        <div className='font-bold'>HISTORY OF PRESENT ILLNESS (HPL)</div>
                    </div>
                    {HPIdata.map(({ title, subData }) => (
                        <div className='pl-5 pt-2' key={title}>
                            <div className='fs-18 pt-1'>{title}</div>
                            {subData.map(({ feildName, feildData }) => (
                                <div className='pt-1' key={feildName}>
                                    <span className=''>{feildName}</span>
                                    <span className='text-gray-500 pl-2'>{feildData}</span>
                                </div>
                            ))}
                        </div>
                    ))}
                    {historyData.map((item, index) => (
                        <div key={index}>
                            <div className='pl-5 pt-1 '>{item.historyTitle}</div>
                            <div className='pl-8'>
                                {item?.historySubData?.map((subItem, subIndex) => (
                                    <div className='text-gray-500' key={subIndex}>{subItem}</div>
                                ))}
                            </div>
                        </div>
                    ))}

                    <div className='pt-1 pl-5'>
                        <span className=''>2.Smoking</span>
                        <span className='text-gray-500 pl-2'>- Current some day smoker</span>
                    </div>
                    {medicationData.map(({ medicationDatatitle, medicationSubData }) => (
                        <div className='pl-5 pt-2' key={medicationDatatitle}>
                            <div className='fs-18 pt-1'>{medicationDatatitle}</div>
                            {medicationSubData.map(({ feildName, feildData }) => (
                                <div className='pt-1' key={feildName}>
                                    <span className=''>{feildName}</span>
                                    <span className='text-gray-500 pl-2'>{feildData}</span>
                                </div>
                            ))}
                        </div>
                    ))}
                    <div className='pt-3'>
                        <hr className='' />
                    </div>
                    <div className='pt-4 flex flex-row column-gap-5px items-center'>
                        <div className="h-4 w-4 bg-met-primary rounded-full mr-2"></div>
                        <div className='font-bold'>REVIEW OF SYSTEM</div>
                    </div>
                    <div>
                        {reviewsystemData.map((item, index) => (
                            <div className='pt-2 pl-5 column-gap-20px ' key={index}>
                                <span className=''>{item.feildName}</span>
                                <span className='text-gray-500 pl-2'>{item.feildData}</span>
                            </div>
                        ))
                        }
                    </div>
                </FaceSheetCard>
                <div className='text-met-primary font-bold pt-3'>OBJECTIVE</div>
                <div className='pt-3'>
                    <FaceSheetCard paddingTopClass="p-0" customClassName="p-[12px]">
                        {objectiveData.map((objectData, index) => (
                            <div className='pt-1' key={"subjectiveData-appointment" + index}>
                                <div className='text-gray-500'>{objectData}</div>
                            </div>

                        ))}

                        <div className='pt-3'>
                            <hr className='' />
                        </div>
                        <div className='pt-4 flex flex-row column-gap-5px items-center'>
                            <div className="h-4 w-4 bg-met-primary rounded-full mr-2"></div>
                            <div className='fs-18'>VITALS</div>
                        </div>
                        <div className='flex pl-5 flex-wrap justify-between'>
                            {vitalsData.map((vitaldata, index) => (
                                <div className='flex w-1/3 flex-row column-gap-20px pt-2' key={"vitaldata-appointment" + index}>
                                    <div className=''>{vitaldata.feildName}</div>
                                    <div className=' text-gray-500'>{vitaldata.feildData}</div>
                                </div>
                            ))}
                        </div>
                        <div className='pt-3'>
                            <hr className='' />
                        </div>
                        <div className='pt-4 flex flex-row column-gap-5px items-center'>
                            <div className="h-4 w-4 bg-met-primary rounded-full mr-2"></div>
                            <div className='fs-18'>UPLOAD RESULTS</div>
                        </div>
                        <div className='flex flex-wrap
                        '>
                            <div className='pt-3'>
                                <img alt='' src='https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/b0cd9d30569263.5628dced0486c.png' className="w-[50%] h-[60%]" />
                            </div>
                            <div className='pt-6'>
                                <img alt='' src='https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/b0cd9d30569263.5628dced0486c.png' className="w-[50%] h-[60%]" />
                            </div>
                        </div>

                        <div className='pt-4'>
                            <hr className='' />
                        </div>
                        <div className='pt-4 flex flex-row column-gap-5px items-center'>
                            <div className="h-4 w-4 bg-met-primary rounded-full mr-2"></div>
                            <div className='fs-18'>PHYSICAL EXAM</div>
                        </div>
                        <div>
                            {reviewsystemData.map((item, index) => (
                                <div className='pt-2 pl-5 column-gap-20px ' key={index}>
                                    <span className=''>{item.feildName}</span>
                                    <span className='text-gray-500 pl-2'>{item.feildData}</span>
                                </div>
                            ))
                            }
                        </div>
                    </FaceSheetCard>
                    <div className='text-met-primary font-bold pt-3'>ASSESSMENT</div>
                    <div className='pt-3'>
                        <FaceSheetCard paddingTopClass="p-0" customClassName="p-[12px]">
                            {assessmentData.map((data, index) => (
                                <div className='flex flex-row row-gap-10 col-gap-5' key={"assessmentData-appointment" + index}>
                                    <div>{index + 1}.</div>
                                    <div className='text-gray-500'>{data}</div>
                                </div>

                            ))}
                            <div className='pt-3'>
                                <hr className='' />
                            </div>
                            <div className='pt-3 flex flex-row column-gap-5px items-center'>
                                <div className="h-4 w-4 bg-met-primary rounded-full mr-2"></div>
                                <div className='fs-18'>ICD 10 CODE</div>
                            </div>
                            {icdCodeData.map((icdData, index) => (
                                <div className='flex flex-row row-gap-10 col-gap-5 pt-1' key={index}>
                                    <div>{index + 1}.</div>
                                    <div>{icdData.feildName}</div>
                                    <div className='text-gray-500 pl-2'>{icdData.feildData}</div>
                                </div>
                            ))}
                        </FaceSheetCard>
                        <div className='text-met-primary font-bold pt-3'>PLAN</div>
                        <div className='pt-3'>
                            <FaceSheetCard paddingTopClass="p-0" customClassName="p-[12px]">
                                <div>Symptomatic treatment for pharyngitis:</div>
                                {SymptomaticData.map((data, index) => (
                                    <div className='flex flex-row row-gap-10 col-gap-5' key={"SymptomaticData-appointment" + index}>
                                        <div>{index + 1}.</div>
                                        <div className='text-gray-500'>{data}</div>
                                    </div>

                                ))}
                                {planData.map((plan, index) => (
                                    <div key={index}>
                                        <div className='pt-3'>
                                            <hr className='' />
                                        </div>
                                        <div className='pt-3 flex flex-row column-gap-5px items-center'>
                                            <div className="h-4 w-4 bg-met-primary rounded-full mr-2"></div>
                                            <div className='fs-18'>{plan.planTitle}</div>
                                        </div>
                                        {plan.planSubData?.map((subData, subIndex) => (
                                            <div className='flex flex-row row-gap-10 col-gap-5 pt-1' key={subIndex}>
                                                <div>{subIndex + 1}.</div>
                                                {subData.fieldName && <div>{subData.fieldName}</div>}
                                                <div className='text-gray-500 pl-2'>{subData.fieldData}</div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </FaceSheetCard>
                            <div className='pt-4 pb-4 flex  justify-end'>
                                <div className="border border-solid w-[400px]  border-blue-400 rounded p-2 bg-met-secondary">
                                    <div className='pt-6 fs-20 pl-3 font-mono'>Albert Melbourne</div>
                                    <div className='pt-2 flex  flex-col'>
                                        <div className='flex justify-end fs-14 text-gray-500'>signed by Albert Melbourne</div>
                                        <div className='flex justify-end fs-14 text-gray-500'>October 10,2023 03:29 PM</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalComponent>
    )
}

export default PatientFormModal



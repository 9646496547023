import React, { useEffect } from 'react';
import AddNewPrintConfigurationModal from './AddNewPrintConfigurationModal';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setIsOpenPrintConfigModal, setUpdatePrintConfigDetails, setPrintConfig } from './PrintConfigSlice';
import { deletePrintConfig, getPrintConfig } from './PrintConfigSaga';
import PrintConfigCardItem from './PrintConfigCard';


const PrintConfiguration = () => {
	const { printConfigDetails, isOpenPrintConfigModal, } = useSelector((state) => state[componentKey])
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getPrintConfig())
	}, [dispatch])

	const handleEditPrintConfig = (item) => {
		dispatch(setPrintConfig(item))
		dispatch(setIsOpenPrintConfigModal(true))
		dispatch(setUpdatePrintConfigDetails(true))
	}

	const handleDeletePrintConfig = (uuid) => {
		dispatch(deletePrintConfig({ uuid }))
	}

	return (
		<div className="profile-card-height">
			<div className="mt-5">
				<p className="text-met-dimmed-charcoal text-[18px]">
					This is the list of document headers you can select from when printing from METSL
				</p>
			</div>
			{
				printConfigDetails.map((item) => {
					return <PrintConfigCardItem
						key={item?.printConfigTitle}
						item={item}
						handleEditPrintConfig={handleEditPrintConfig}
						handleDeletePrintConfig={handleDeletePrintConfig}
					/>
				})
			}
			{isOpenPrintConfigModal && (
				<AddNewPrintConfigurationModal
					open={isOpenPrintConfigModal}
					close={() => {
						dispatch(setIsOpenPrintConfigModal(false))
					}}
				/>
			)}
		</div>
	);
};

export default PrintConfiguration;

import React, { forwardRef, useEffect, useState } from 'react'
import './styles.scss'
import SelectDropdown from '../../../components/common-components/selectDropdown'
import DatePicker from '../../../components/common-components/datePicker'
import { Form, Formik } from 'formik'
import { FORM_FIELDS_NAMES } from './Constants'
import { getValidationSchema } from '../../../libs/formsUtils'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey, setCreatePatientData, setIsFormSubmitted } from '../../../pages/Patients/AddPatient/AddPatientSlice'
import IsAccessAllowed from '../../Wrappers/IsAccessAllowed'
import { USER_ROLE_NAMES } from '../../Wrappers/Constants'
import { componentKey as routerComponentKey } from '../../../routes/RoutesSlice'
import { componentKey as dataLoaderComponentKey } from '../../Wrappers/Dataloader/DataloaderSlice'
import { getProviderGroupLocation, updatePatientDetails } from '../../../pages/Patients/AddPatient/AddPatientSaga'
import { useParams } from 'react-router-dom'
const fields = [
    { fieldName: FORM_FIELDS_NAMES.PRIMARY_PROVIDER, isDropdown: true },
    { fieldName: FORM_FIELDS_NAMES.PROVIDER_GROUP_LOCATION, isDropdown: true },
    { fieldName: FORM_FIELDS_NAMES.REGISTRATION_DATE, isRequired: true },
];

const validationSchema = getValidationSchema(fields)

const ProviderInformation = forwardRef(function ProviderInformation(props, formRef) {
    const { patientId } = useParams();
    const dispatch = useDispatch()
    const [isAdminUser, setIsAdminUser] = useState(false)
    const [selectedProvider, setSelectedProvider] = useState({})

    const { loggedInUser, loggedInProviderUser } = useSelector(state => state[routerComponentKey])
    const { providersList } = useSelector(state => state[dataLoaderComponentKey])
    const { providerGroupLocation, fetchedPatientDetails, isFormSubmitted, createPatientData } = useSelector(state => state[componentKey])
    const { newlyAddedPatientId } = useSelector(state => state[componentKey])

    useEffect(() => {
        const allowedRoles = [
            USER_ROLE_NAMES.ADMIN_PROVIDER,
        ]

        const currentRole = loggedInProviderUser?.roleNames || []
        setIsAdminUser(IsAccessAllowed(allowedRoles, currentRole))
    }, [loggedInProviderUser])


    useEffect(() => {
        if (!isAdminUser) {
            setSelectedProvider({ label: loggedInUser.name, value: loggedInUser?.uuid })
        }
    }, [isAdminUser,loggedInUser])

    useEffect(() => {
        if (selectedProvider.value) {
            dispatch(getProviderGroupLocation(selectedProvider.value))
        }
    }, [selectedProvider, dispatch])

    const isFrontDeskUser = loggedInProviderUser.roleNames[0] === "Front Desk"
    const id = loggedInProviderUser?.customAttributes?.frontDeskId

    return (
        <Formik
            innerRef={formRef}
            initialValues={{
                [FORM_FIELDS_NAMES.PRIMARY_PROVIDER]: isAdminUser ? { label: fetchedPatientDetails?.providerId || createPatientData?.providerId, value: fetchedPatientDetails?.providerId || createPatientData?.providerId } : selectedProvider,
                [FORM_FIELDS_NAMES.PROVIDER_GROUP_LOCATION]: { label: fetchedPatientDetails?.providerLocationId || createPatientData?.providerLocationId, value: fetchedPatientDetails?.providerLocationId || createPatientData?.providerLocationId },
                [FORM_FIELDS_NAMES.REGISTRATION_DATE]: fetchedPatientDetails?.registrationDate || createPatientData?.registrationDate || "",
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => {
                if (patientId && patientId === ":id") {
                    values.providerId = values.providerId?.value;
                    values.providerLocationId = values.providerLocationId?.value;
                    dispatch(setCreatePatientData(values))
                    dispatch(setIsFormSubmitted({ providerInformation: false }))
                }
                // else{
                // 	dispatch(updatePatientDetails({ patientId: newlyAddedPatientId, payload: { ...createPatientData, ...values } }))
                // }
            }}
        >
            {({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, ...props }) => {
                return <Form onSubmit={handleSubmit} >
                    <div className='custom-provider-information mt-4 mb-4 bg-opacity-3 rounded-md'>
                        <div className='flex space-x-4'>
                            <div className='provider-container'>
                                <SelectDropdown
                                    label='Primary Provider'
                                    placeholder="Select Primary Provider"
                                    isRequired={true}
                                    name={FORM_FIELDS_NAMES.PRIMARY_PROVIDER}
                                    value={values[FORM_FIELDS_NAMES.PRIMARY_PROVIDER]}
                                    onChangeCb={(val) => {
                                        setFieldValue(FORM_FIELDS_NAMES.PRIMARY_PROVIDER, val)
                                        setSelectedProvider(val)
                                    }}
                                    options={isAdminUser ? providersList : [selectedProvider]}
                                    disabled={!isAdminUser || isFormSubmitted.providerInformation}
                                />
                            </div>
                            <div className='provider-container'>
                                <SelectDropdown
                                    label='Provider Group Location'
                                    placeholder="Select Provider Group Location"
                                    isRequired={true}
                                    name={FORM_FIELDS_NAMES.PROVIDER_GROUP_LOCATION}
                                    value={values[FORM_FIELDS_NAMES.PROVIDER_GROUP_LOCATION]}
                                    onChangeCb={(val) => {
                                        setFieldValue(FORM_FIELDS_NAMES.PROVIDER_GROUP_LOCATION, val)
                                    }}
                                    options={providerGroupLocation}
                                    disabled={isFormSubmitted.providerInformation}
                                />
                            </div>
                            <div className='provider-container'>
                                <DatePicker
                                    isRequired={true}
                                    label="Registration Date"
                                    placeholder='Choose Date'
                                    maxDate={new Date()}
                                    value={values[FORM_FIELDS_NAMES.REGISTRATION_DATE]}
                                    name={FORM_FIELDS_NAMES.REGISTRATION_DATE}
                                    onChangeCb={(date) => {
                                        setFieldValue(FORM_FIELDS_NAMES.REGISTRATION_DATE, date)
                                    }}
                                    disabled={isFormSubmitted.providerInformation}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            }}
        </Formik >
    )
});

export default ProviderInformation
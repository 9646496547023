import React from 'react';
import './styles.scss';
import Label from '../label/Label';

function RadioButton({ label, checked = false, disabled = false, onChangeCb = () => { }, value, customLabelClass = "", name = "flexRadioDefault" }) {

	return (
		<div className='flex items-center column-gap-10px'>
			<input
				className=" cutom-radio relative  h-6 w-6  rounded-full border-2 border-solid border-neutral-300 behtmlFore:pointer-events-none behtmlFore:absolute behtmlFore:h-4 behtmlFore:w-4 behtmlFore:scale-0 behtmlFore:rounded-full behtmlFore:bg-transparent behtmlFore:opacity-0 behtmlFore:shadow-[0px_0px_0px_13px_transparent] behtmlFore:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:behtmlFore:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transhtmlForm:translate(-50%,-50%)]  cursor-pointer"
				type="radio"
				name={name}
				id="radioDefault01"
				checked={checked}
				disabled={disabled}
				onClick={onChangeCb}
				onChange={() => { }}
				value={value}
			/>
			<Label customClass={customLabelClass}> {label} </Label>
		</div>
	);
}

export default RadioButton;

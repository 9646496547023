export const ABHA_FIELDS_NAMES = {
    SELECT_OPTION: "getOption",
    ABHA_NUMBER: "abhaNumber",
    ABHA_ADDRESS: "abhaAddress",
    AADHAR_NUMBER: "aadharNumber",
};

export const ABHA_OPTIONS = {
    GET_OPTIONS: [
        { label: "Aadhar", value: "Aadhar" },
        { label: "ABHA Number", value: "ABHA Number" },
        { label: "ABHA Address", value: "ABHA Address" },
        { label: "Mobile Number", value: "Mobile Number"}
    ],

};


import store from '../../../../../store/store';

export const componentKey = 'PROVIDER_GROUP_LOCATION';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setEditLocationData: (state, action) => {
			state.editLocationData = action.payload;
		},
		setLocationsData: (state, action) => {
			state.locationsData = action.payload;
		},
		setIsLocationModalOpen: (state, action) => {
			state.isLocationModalOpen = action.payload;
		},
		setIsNewLocationAdd: (state, action) => {
			state.isNewLocationAdd = action.payload;
		},
		setProviderSepcialities: (state, action) => {
			state.providerSepcialities = action.payload;
		},
		setAllProviderLocation: (state, action) => {
			state.allproviderLocations = action.payload;
		},
		setProviderInformationData: (state, action) => {
			state.providerProfile = action.payload;
		},
		setCurrentProvidersUUID: (state, action) => {
			state.currentProvidersUUID = action.payload;
		},
		setCurrentProvidersPhysicalAddressUUID: (state, action) => {
			state.currentProvidersPhysicalAddressUUID = action.payload;
		},
		setCurrentProvidersBillingAddressUUID: (state, action) => {
			state.currentProviderBillingAddresssUUID = action.payload;
		},
	
		setAllProviderGroupLocation: (state, action) => {
			state.allProviderGroupLocation = action.payload;
		},
		setProviderGroupDetails: (state, action) => {
			state.providerGroupDetails = action.payload;
		},
		setOpenSideDrawer:(state,action) =>{
			state.openSideDrawer = action.payload
		},
		setPatientsByLocationId:(state,action) =>{
			state.patientsByLocationId = action.payload
		},
		setAllProviders:(state,action) => {
			state.allProviders = action.payload
		}
		
	},
	initialReducerState: {
		editLocationData: '',
		locationsData: [],
		isLocationModalOpen: false,
		isNewLocationAdd: false,
		providerGroupDetails: {},
		allProviderGroupLocation: [],
		currentProvidersUUID: '',
		currentProviderBillingAddresssUUID: '',
		currentProvidersPhysicalAddressUUID: '',
		openSideDrawer: false,
		patientsByLocationId:"",
		allProviders:[]
	},
});

export const {
	setEditLocationData,
	setLocationsData,
	setIsLocationModalOpen,
	setIsNewLocationAdd,
	setProviderSepcialities,
	setAllProviderLocation,
	setProviderInformationData,
	setCurrentProvidersUUID,
	setCurrentProvidersPhysicalAddressUUID,
	setCurrentProvidersBillingAddressUUID,
	setProviderGroupDetails,
	setAllProviderGroupLocation,
	setOpenSideDrawer,
	setPatientsByLocationId,
	setAllProviders
} = actions;

import AppDataService from './AppDataService'

const COMMON_BASE = 'payment'

export default class PaymentDataService {

    static async createPatientPayment(data) {
        return await AppDataService.post(`${COMMON_BASE}`, data)
    }
    static async getPatientsPaymentDetails(params) {
        return await AppDataService.get(`${COMMON_BASE}`, params)
    }
    static async getCollectPayment(uuid) {
        return await AppDataService.get(`${COMMON_BASE}/${uuid}`)
    }
    static async updatePatientPayment(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}`, data)
    }

    static async getAllPatientPaymentList(patientId,params) {
        return await AppDataService.get(`${COMMON_BASE}?patientId=${patientId}`, params)
    }
}
import React from 'react';
import ProfileCardList from '../ProfileCardList';
import Icons from '../../../../../../components/icons/Icons';
import PrivacyConsent from '../../../../../../containers/AddPatient/PrivacyConsent/PrivacyConsent';
import { updateProfiles } from '../../ProfileSaga';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';


const PrivacyCard = ({ patientData }) => {
    const { patientId } = useParams();
    const dispatch = useDispatch()

    const data = [
        {
            editbtnIcon: (<Icons iconName="editIcon" />),
        },
        { cardTitle: 'Privacy', feildName: 'Consent Form', subFeild: ` ${patientData?.isPatientOnboarded == true ? 'Yes' : 'No'}` },
        {
            feildName: 'Consent To Voice Call',
            subFeild: `${patientData?.consentToCall == true ? 'Yes' : 'No'}`,
        },
        {
            feildName: 'Consent To Email',
            subFeild: `${patientData?.consentToEmail == true ? 'Yes' : 'No'}`,
        },
        {
            feildName: 'Consent To Text',
            subFeild: `${patientData?.consentToMessage == true ? 'Yes' : 'No' }`,
        },
    ];
    return <ProfileCardList
        patientUpdateCb={(values, handleSuccess) => {
            dispatch(updateProfiles({ uuid: patientId, updatedData: values, handleSuccess }))

        }}
        customClassName="flex-1 min-w-500 max-w-600 max-h-385"
        data={data}
        paddingTop="pt-0"
        Component={PrivacyConsent}
        modelTitle={data[1].cardTitle}
    />
};

export default PrivacyCard;
export const LAB_ORDER_FORM_FIELDS_NAMES = {
    LAB_TYPE: 'labType',
    LAB_CENTER: 'labCenter',
    INSURANCE: 'Insurance',
    TESTS: 'Tests',
    ICD_10_CODE: 'Icd10Code',
    PATIENT_INSTRUCTIONS: 'patientInstructions',
    LAB_INSTRUCTIONS_TO_TEST_CENTER: 'labInstructionsToTestCenter',
    COLLECTION_DATE_TIME: 'collectionDateTime',
    CHOOSE_TIME: 'chooseTime',
    PRIMARY_PROVIDER: 'primaryProvider',
    ORDERING_PROVIDER: 'orderingProvider',
    CHART_NOTE: 'chartNote',
    BILL_TYPE: 'billType'

}

export const LAB_RESULT_FORM_FIELDS_NAMES = {
    NOTE: 'note',
    LAB_NAME: 'labId',
    REVIEWER: 'reviewer',
    COLLECTION_TIME: 'collectionTime',
    COLLECTION_DATE: 'collectionDate',
    LAB_ORDER: 'labOrder',
    UPLOAD_ACTION: 'uploadAction',
    RESULT_WITH_LAB_ORDER: 'resultWithExistingLaborder ',
    RESULT_WITHOUT_LAB_ORDER: 'resultWithoutExistingLaborder'
}
import React, { useEffect, useState } from 'react';
import Tabs from '../../../../components/common-components/Tabs/index';
import CardContainer from '../../../../components/common-components/Cards/Container/CardContainer';
import { appointmentCardsData } from '../../constant';
import Icons from '../../../../assets/svgs';
import { useNavigate } from 'react-router-dom';
import { MAIN_PATHS, VALIDATION_REGEX } from '../../../../libs/constant';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import ModalComponent from '../../../../components/common-components/modal/ModalComponent';
import AddAvailabilityModal from './AddAvailability';
import './styles.scss';
import useGetCurrenPathHook from '../../../../libs/customHooks/useGetCurrenPathHook';
import { Form, Formik } from 'formik';
import { FORM_FIELDS_NAMES } from './constants';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailability, getProviderLocations, postAvailability, updateAvailability } from './Availability/AvailabilitySaga';
import { componentKey as AvailabilityComponentKey, setIsAvailiablityModalOpen, setIsNewAvailabilityAdd } from "./Availability/AvailabilitySlice"
import moment from 'moment';
import General from '../../../../libs/utility/General';
import { getValidationSchema } from '../../../../libs/formsUtils';
import { componentKey as routerComponentKey } from '../../../../routes/RoutesSlice'
import { USER_PERMISSIONS } from '../../../../libs/UserPermissionsConstants';
import ShowForAllowedPermissionsOnly from '../../../Wrappers/ShowForAllowedPermissionsOnly';


const fields = [
	{ fieldName: FORM_FIELDS_NAMES.PROVIDER_ID, isDropdown: true },
	{ fieldName: FORM_FIELDS_NAMES.BOOKING_WINDOW, isDropdown: true },
	{ fieldName: FORM_FIELDS_NAMES.NEW_APPOINTMENT, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.FOLLOW_UP_APPOINTMENT, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.BUFFER_TIME, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.MINIMUM_SCHEDULING_THRESHOLD, isRequired: true, regexPattern: VALIDATION_REGEX.NUMBER_REGEX },
	{ fieldName: FORM_FIELDS_NAMES.MINIMUM_SCHEDULING_THRESHOLD_DESCRIPTION, isDropdown: true },
];


const validationSchema = Yup.object().shape({
	...getValidationSchema(fields).fields,
	daySlots: Yup.array().of(
		Yup.object().shape({
			day: Yup.object().shape({
				label: Yup.string().required(`${General.camelToPascalWithSpaces('day')} is required`),
				value: Yup.string().required(`${General.camelToPascalWithSpaces('day')} value is required`),
			}),
			slots: Yup.array().of(
				Yup.object().shape({
					virtual: Yup.boolean(),
					startTime: Yup.string().required('Start Time is required'),
					endTime: Yup.string().required('End Time is required'),
					location: Yup.object().required().when('virtual', {
						is: (val) => val === false,
						then: () => Yup.object().shape({
							label: Yup.string().required(`${General.camelToPascalWithSpaces('location')} is required`),
							value: Yup.string().required(`${General.camelToPascalWithSpaces('location')} value is required`),
						}),
						otherwise: (schema) => schema.notRequired(),
					}),
				})
			)
		})
	),
	blockDays: Yup.array().of(
		Yup.object().shape({
			blockDaysDate: Yup.string().required('Date is required'),
			blockDaysStartTime: Yup.string().required().when('fullDayBlock', {
				is: (val) => val === false,
				then: () => Yup.string().required(`${General.camelToPascalWithSpaces('blockDaysStartTime')} is required`),
				otherwise: (schema) => schema.notRequired(),
			}),
			blockDaysEndTime: Yup.string().required().when('fullDayBlock', {
				is: (val) => val === false,
				then: () => Yup.string().required(`${General.camelToPascalWithSpaces('blockDaysEndTime')} is required`),
				otherwise: (schema) => schema.notRequired(),
			}),
			fullDayBlock: Yup.boolean()
		})
	),
})

const mapDaySlots2 = (availabilitySlots) => {
	const daySlots = []
	for (let i = 0; i < availabilitySlots.length; i = i + 1) {
		const index = daySlots.findIndex(daySlot => availabilitySlots[i]?.day === daySlot?.day?.label);
		if (index !== -1) {
			const slotIndex = daySlots[index]?.slots?.findIndex(slot => {
				return moment(slot.startTime).format('hh:mm A') === availabilitySlots[i]?.startTime
			})
			if (slotIndex === -1) {
				daySlots[index]?.slots.push({ startTime: availabilitySlots[i]?.startTime ? moment().set(moment(availabilitySlots[i]?.startTime, 'hh:mm A').toObject()) : '', endTime: availabilitySlots[i]?.endTime ? moment().set(moment(availabilitySlots[i]?.endTime, 'hh:mm A').toObject()) : '', location: { label: availabilitySlots[i]?.availabilityLocation?.name, value: availabilitySlots[i]?.availabilityLocation?.uuid }, virtual: availabilitySlots[i]?.virtual, isSingleDaySlot: availabilitySlots[i].isSingleDaySlot ? availabilitySlots[i].isSingleDaySlot : undefined, singleDaySlotDate: availabilitySlots[i].isSingleDaySlot ? availabilitySlots[i].date : undefined })
			}
		}
		else {
			daySlots.push({ day: { label: availabilitySlots[i].day, value: availabilitySlots[i].day }, slots: [{ startTime: availabilitySlots[i]?.startTime ? moment().set(moment(availabilitySlots[i]?.startTime, 'hh:mm A').toObject()) : '', endTime: availabilitySlots[i]?.endTime ? moment().set(moment(availabilitySlots[i]?.endTime, 'hh:mm A').toObject()) : '', location: { label: availabilitySlots[i]?.availabilityLocation?.name, value: availabilitySlots[i]?.availabilityLocation?.uuid }, virtual: availabilitySlots[i]?.virtual, isSingleDaySlot: availabilitySlots[i].isSingleDaySlot ? availabilitySlots[i].isSingleDaySlot : undefined, singleDaySlotDate: availabilitySlots[i].isSingleDaySlot ? availabilitySlots[i].date : undefined }] })
		}
	}
	return daySlots
}

const mapBlockDays = (blockDays) => {
	return blockDays?.map(blockDay => {
		return {
			blockDaysDate: blockDay.date,
			fullDayBlock: blockDay.fullDayBlock,
			blockDaysStartTime: blockDay.startTime ? moment().set(moment(blockDay.startTime, 'hh:mm A').toObject()) : '',
			blockDaysEndTime: blockDay.endTime ? moment().set(moment(blockDay.endTime, 'hh:mm A').toObject()) : ''
		}
	})
}

const AppointmentTabContainer = () => {

	const navigate = useNavigate();
	const currrentPath = useGetCurrenPathHook(true, 2);
	const dispatch = useDispatch()

	const { isAvailiablityModalOpen, isNewAvailabilityAdd, selectedProvider, availiabilityData } = useSelector(state => state[AvailabilityComponentKey])
	const { isAdminUser } = useSelector(state => state[routerComponentKey])
	const initialValues = {
		providerId: !isNewAvailabilityAdd && selectedProvider ? { label: selectedProvider?.label, value: selectedProvider.value } : { label: selectedProvider?.label, value: selectedProvider.value },
		newAppointmentTime: !isNewAvailabilityAdd && availiabilityData[0] ? availiabilityData[0]?.newAppointmentTime : '',
		followUpAppointmentTime: !isNewAvailabilityAdd && availiabilityData[0] ? availiabilityData[0]?.followUpAppointmentTime : '',
		bufferTime: !isNewAvailabilityAdd && availiabilityData[0] ? availiabilityData[0]?.bufferTime : '',
		daySlots: !isNewAvailabilityAdd && availiabilityData[0] ? mapDaySlots2(availiabilityData[0]?.provider_availability_slots) : [
			{ day: { label: 'Monday', value: 'Monday' }, slots: [{ startTime: '', endTime: '', location: '', virtual: false }] },
		],
		bookingWindow: !isNewAvailabilityAdd && availiabilityData[0] ? { label: `${availiabilityData[0].bookingWindow} ${availiabilityData[0].bookingWindow == 1 ? 'week' : 'weeks'}`, value: availiabilityData[0].bookingWindow } : '',
		timeZone: !isNewAvailabilityAdd && availiabilityData[0] ? { label: availiabilityData[0].timeZone, value: availiabilityData[0].timeZone } : { label: 'IST', value: 'IST' },
		blockDays: !isNewAvailabilityAdd && availiabilityData[0] ? mapBlockDays(availiabilityData[0].availability_block_days) : [],
		minimumSchedulingThresholdDescription: !isNewAvailabilityAdd && availiabilityData[0] ? { label: availiabilityData[0]?.minimumSchedulingThresholdDescription, value: availiabilityData[0]?.minimumSchedulingThresholdDescription } : '',
		minimumSchedulingThreshold: !isNewAvailabilityAdd && availiabilityData[0] ? availiabilityData[0].minimumSchedulingThreshold : '',
	};

	useEffect(() => {
		if (selectedProvider.value)
			dispatch(getProviderLocations({ providerId: selectedProvider.value }))
		if (!isNewAvailabilityAdd && selectedProvider?.value) {
			dispatch(getAvailability({ providerId: selectedProvider.value, locationIds: {} }))
		}
	}, [selectedProvider])

	const addDaySlot = (values, setFieldValue, valuesPrefix) => {
		switch (valuesPrefix) {
			case 'daySlots':
				setFieldValue(`daySlots`, [
					...values[`daySlots`],
					{ day: { label: '', value: '' }, slots: [{ startTime: '', endTime: '', location: '', virtual: false }] }
				]);
				return;
			case 'blockDays':
				setFieldValue(`blockDays`, [
					...values[`blockDays`],
					{ blockDaysDate: '', fullDayBlock: false, blockDaysStartTime: '', blockDaysEndTime: '' }
				]);
				return;
			default:
				return
		}
	};

	const addTimeSlot = (values, setFieldValue, index) => {
		const currentDaySlots = values.daySlots[index];
		// const currentDaySlots = values.daySlots.find((daySlot) => daySlot.day.label === weekIndex);
		const updatedDaySlots = [
			...currentDaySlots.slots,
			{ startTime: '', endTime: '', location: '', virtual: false }
		];
		setFieldValue(`daySlots[${index}].slots`, updatedDaySlots);
	}

	const handleDeleteClick = (values, setFieldValue, index) => {
		values.daySlots.splice(index, 1);
		setFieldValue(`daySlots`, values.daySlots);
	}

	const handleTimeSlotDeleteCLick = (values, setFieldValue, index, weekIndex) => {
		values.daySlots[index].slots.splice(weekIndex, 1);
		setFieldValue(`daySlots[${index}].slots`, values.daySlots[index].slots)
	}

	const handleBlockDaysDeleteCLick = (values, setFieldValue, index) => {
		values.blockDays.splice(index, 1);
		setFieldValue(`blockDays`, values.blockDays)
	}

	const getAdditionalButtons = () => {
		if (currrentPath === '/availability') {
			return (
				<div className="flex justify-between">
					<ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.UPDATE_AVAILABILITY}>
						{availiabilityData && availiabilityData.length ?
							<Button
								onClickCb={() => {
									dispatch(setIsNewAvailabilityAdd(false))
									dispatch(setIsAvailiablityModalOpen(true))
								}

								}
								startIcon={{ icon: <Icons iconName="settingsPencilIcon" /> }}
								variant={BUTTON_VARIANTS.OUTLINED}
								customBtnClass="mr-5"
							>
								Edit Availability
							</Button>
							: null}
					</ShowForAllowedPermissionsOnly>

					<ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.ADD_AVAILABILITY}>
						<Button
							onClickCb={() => {
								dispatch(setIsNewAvailabilityAdd(true))
								dispatch(setIsAvailiablityModalOpen(true))
							}
							}
							variant={BUTTON_VARIANTS.CONTAINED}
							startIcon={{ icon: <Icons iconName="settingsPlusIcon" /> }}
						>
							Add Availability
						</Button>
					</ShowForAllowedPermissionsOnly>
				</div>
			)
		}
		return null;
	}

	return (
		<React.Fragment className='overflow-auto met-scrollbar'>
			<CardContainer customCssClass="calendar-css">
				<div className="justify-between mb-4 flex font-medium text-rgba-1a1a1acc h-[42px]">
					<div className="flex text-2xl">
						<span onClick={() => navigate(MAIN_PATHS.SETTINGS)} className="hover:cursor-pointer pt-[4px]">
							<Icons iconName="settingsBackArrowIcon" />
						</span>
						<span className="pl-[10px] font-bold">Appointment Settings</span>
					</div>
					{getAdditionalButtons()}
				</div>
				<Tabs tabs={appointmentCardsData} customDivClass="h-[92%]" customChildClass="h-[96%]" />
			</CardContainer>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				enableReinitialize
				onSubmit={(formvals) => {
					const data = { ...formvals }
					const availiabilityArray = []
					formvals.daySlots.forEach((daySlot) => {
						daySlot.slots.map(slot => {
							availiabilityArray.push({
								day: daySlot.day.value,
								startTime: moment(slot.startTime).format('hh:mm A'),
								endTime: moment(slot.endTime).format('hh:mm A'),
								virtual: slot.virtual,
								availabilityLocationId: slot?.location?.value,
								isSingleDaySlot: slot?.isSingleDaySlot,
								singleDaySlotDate: slot?.singleDaySlotDate
							})
						})
					})
					data.blockDays = data.blockDays.map((blockDay => {
						return {
							...blockDay,
							blockDaysStartTime: moment(blockDay.blockDaysStartTime).format('hh:mm A'),
							blockDaysEndTime: moment(blockDay.blockDaysEndTime).format('hh:mm A')
						}
					}))
					data.availabilitySlots = availiabilityArray
					data.minimumSchedulingThresholdDescription = formvals.minimumSchedulingThresholdDescription.value
					data.minimumSchedulingThreshold = Number(data.minimumSchedulingThreshold)
					data.providerId = formvals.providerId.uuid || formvals.providerId.value
					data.bookingWindow = formvals.bookingWindow?.value
					data.timeZone = formvals.timeZone?.value
					delete data.daySlots
					if (isNewAvailabilityAdd)
						dispatch(postAvailability({ availabilityData: data, providerId: formvals.providerId }))
					else {
						delete data.providerId;
						dispatch(updateAvailability({ availabilityData: data, availabilityId: availiabilityData[0]?.uuid, providerId: formvals.providerId }))
					}
				}}
			>
				{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
					return <Form onSubmit={handleSubmit} >
						<ModalComponent
							open={isAvailiablityModalOpen}
							title={isNewAvailabilityAdd ? "Add Availability" : "Edit Availability"}
							customClasses="h-[80%] w-[80%]"
							close={() => {
								dispatch(setIsAvailiablityModalOpen(false))
								dispatch(setIsNewAvailabilityAdd(!isNewAvailabilityAdd))
								handleReset()
							}
							}
							customBodyClasses="h-[88%]"
							footerButton={<Button type='submit' variant={BUTTON_VARIANTS.CONTAINED}>Save</Button>}
						>
							<AddAvailabilityModal addSlotCb={(valuesPrefix) => addDaySlot(values, setFieldValue, valuesPrefix)} addTimeSlotCb={(index) => addTimeSlot(values, setFieldValue, index)} handleDeleteCLickCb={(index) => handleDeleteClick(values, setFieldValue, index)} values={values} setFieldValue={setFieldValue} handleTimeSlotDeleteCLickCb={(index, weekIndex) => handleTimeSlotDeleteCLick(values, setFieldValue, index, weekIndex)} handleBlockDaysDeleteCLickCb={(index) => handleBlockDaysDeleteCLick(values, setFieldValue, index)} />
						</ModalComponent>
					</Form>
				}}
			</Formik >
		</React.Fragment>
	);
};

export default AppointmentTabContainer;

import React, { useState } from 'react';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import Icons from '../../../components/icons/Icons';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	componentKey,
	setIsAppointmentDetailsModalOpen,
	setIsCancelledAppointmentModalOpen,
	setIsCheckedInModalOpen,
	setIsDeleteModalOpen,
	setIsNewAppointmentModal,
	setIsNoShowModalOpen,
	setIsRescheduleModalOpen,
	setIsSignOffModalOpen,
	setIsSuccessPopUpModalOpen,
	setStatusOfAppointment,
} from '../ShedulingSlice';
import Avatar from '../../../components/common-components/avatar';
import General from '../../../libs/utility/General';
import moment from 'moment';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import RescheduleAppointment from './RescheduleAppointmentModal';
import CancellingApppointment from './CancellingAppointmentModal';
import { deleteScheduledAppointment } from '../SchedulingSaga';
import CheckInModal from './CheckInModal';
import DeleteAppointmentModal from './DeleteAppointmentModal';
import SucessPopUp from '../../../components/common-components/popup-components/SucessPopUp';
import { USER_PERMISSIONS } from '../../../libs/UserPermissionsConstants';
import { addNotifications } from '../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../components/common-components/toaster/MetToaster';
import { useShowForAllowedPermissionsOnly } from '../../../libs/customHooks/useShowForAllowedPermissionsOnly';
import NoShowAppointment from './NoShowAppointment';
import SignOffAppoinment from './SignOffAppoinment';

const AppointmentDetailsModal = ({ close }) => {
	const {
		isAppointmentDetailsModalOpen,
		appointmentDetails,
		statusOfAppointment,
		isRescheduleAppointmentModalOpen,
		isCancelledAppointmentModalOpen,
		isCheckedInModalOpen,
		isDeleteModalOpen,
		isSuccessPopUpModalOpen,
		isNoShowModalOpen,
		isSignOffModalOpen
	} = useSelector((state) => state[componentKey]);

	const showForAllowPermission = useShowForAllowedPermissionsOnly()

	const dispatch = useDispatch();
	const formattedUUID = appointmentDetails?.uuid.substring(0, 5);
	const UUIDWithUpperCase = formattedUUID?.charAt(0)?.toUpperCase() + formattedUUID?.slice(1);
	const currentDate = moment();
	const ageOfPerson = currentDate.diff(appointmentDetails?.Patient?.dob, 'years');
	return (
		<div>
			<ModalComponent
				open={isAppointmentDetailsModalOpen}
				title={'Appointment Details'}
				hideOverflow={true}
				close={() => {
					close();
				}}
				icon={<Icons iconName="closeIcon" />}
				// footerButton={
					// <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary">
					// 	Start Visit Note
					// </Button>
				// }
				customClasses="w-[30%] overflow-visible"
				enableReinitialize
				showDeleteIcon={true}
				deleteClick={() => {
					dispatch(setIsDeleteModalOpen(true));
					// dispatch(deleteScheduledAppointment({ scheduleId: appointmentDetails.uuid }))
				}}
				deleteIcon={<Icons iconName="trashIcon" color={'#1B5984'} />}
			>
				<div className="">
					<div className="flex">
						<div className="w-[20%]">
							<Avatar
								url={appointmentDetails?.Patient?.profilePicture}
								name={`${appointmentDetails?.Patient?.firstName} ${appointmentDetails?.Patient?.lastName}`}
								width={64}
								height={64}
								customClass="rounded-[5px]"
							/>
						</div>
						<div className="w-[90%] flex flex-col">
							<div className="flex">
								<p className="font-bold">{`${appointmentDetails?.Patient?.firstName} ${appointmentDetails?.Patient?.lastName
									} (${UUIDWithUpperCase || '-'})  `}</p>
							</div>
							<div className="flex">
								<p className="w-[30%]">{General.getformattedDate(appointmentDetails?.Patient?.dob)}</p>
								<p className="w-[10%]">{`${ageOfPerson}yrs`}</p>
								<p className="w-[45%]">
									{General.camelToPascalWithSpaces(appointmentDetails?.Patient?.gender)}
								</p>
							</div>
							<div className="flex">
								<p className="w-[45%] flex">
									<span>
										<Icons iconName="mobileIcon" />
									</span>
									{appointmentDetails?.Patient?.mobileNumber || '-'}
								</p>
								<p className="flex className='w-[45%]'">
									<span>
										<Icons iconName="emailIcon" color={'#1A1A1A99'} />
									</span>
									{appointmentDetails?.Patient?.email || '-'}
								</p>
							</div>
						</div>
					</div>
					<div className="mt-5 flex">
						<div className="w-[10%]">
							<Icons iconName="appointmentClockIcon" />
						</div>
						<div className="">
							<p>{appointmentDetails?.Appointment?.appointmentType}</p>
							<div className="p-5 bg-[#1A1A1A0A] mt-4">
								<p>{`${moment(appointmentDetails?.Appointment?.startDate).format(
									'dddd, DD MMM YYYY'
								)} at ${appointmentDetails?.start}`}</p>
								<p>{`${appointmentDetails?.Provider?.firstName} ${appointmentDetails?.Provider?.lastName}`}</p>
								<p>{appointmentDetails?.Locations?.name}</p>
							</div>
							<div className="mt-5">
								<SelectDropdown
									placeholder="select status"
									label="Status"
									// isRequired={true}
									// name="status"
									value={{ label: appointmentDetails?.status, value: appointmentDetails?.status }}
									onChangeCb={(val) => {

										switch (val.value) {

											case "ReScheduled":
												if (!showForAllowPermission(USER_PERMISSIONS.RESCHEDULE_APPOINTMENT)) {
													return dispatch(addNotifications({ message: "You dont have permission please contact your admin.", variant: TOASTER_VARIANT.ERROR }));
												}
												break;

											case "Cancelled":
												if (!showForAllowPermission(USER_PERMISSIONS.CANCEL_APPOINTMENT)) {
													return dispatch(addNotifications({ message: "You dont have permission please contact your admin.", variant: TOASTER_VARIANT.ERROR }));
												}
												break;
											default:
										}
										dispatch(setStatusOfAppointment(val));
										if (val.value === 'ReScheduled') {
											dispatch(setIsRescheduleModalOpen(true));
										}
										if (val.value === 'Cancelled')
											dispatch(setIsCancelledAppointmentModalOpen(true));
										if (val.value === 'Checked_In') dispatch(setIsCheckedInModalOpen(true));
										if (val.value === 'No_Show') {
											dispatch(setIsNoShowModalOpen(true))
										}
										if (val.value === "Signed_Off") {
											dispatch(setIsSignOffModalOpen(true))
										}
										// setFieldValue(FORM_FIELDS_FOR_SCHEDULE_NEW_APPOINTMENT.PROVIDER, val);
									}}
									options={[
										{ label: 'Scheduled', value: 'Scheduled' },
										{ label: 'Checked In', value: 'Checked_In' },
										{ label: 'In Exam', value: 'In Exam' },
										{ label: 'Rescheduled', value: 'ReScheduled' },
										{ label: 'Cancelled', value: 'Cancelled' },
										{ label: 'No Show', value: 'No_Show' },
										{ label: 'Signed Off', value: 'Signed_Off' },
									]}
								/>
							</div>
						</div>
					</div>
					{(appointmentDetails?.mode === 'Video_Call' || appointmentDetails?.mode === 'Voice_Call') && (
						<div className="mt-5 flex">
							<div className="w-[10%]">
								<Icons iconName="videoIcon" />
							</div>
							<span>Virtual</span>{' '}
						</div>
					)}

					{appointmentDetails?.mode === 'In_Person' && (
						<div className="mt-5 flex">
							<div className="w-[10%]">
								<Icons iconName="locationIcon" />
							</div>
							<span>In Person</span>{' '}
						</div>
					)}
				</div>
				{statusOfAppointment === 'ReScheduled' && isRescheduleAppointmentModalOpen && (
					<RescheduleAppointment
						close={() => dispatch(setIsRescheduleModalOpen(false))}
						appointmentDetails={appointmentDetails}
					/>
				)}
				{statusOfAppointment === 'Cancelled' && isCancelledAppointmentModalOpen && (
					<CancellingApppointment
						close={() => dispatch(setIsCancelledAppointmentModalOpen(false))}
						appointmentDetails={appointmentDetails}
					/>
				)}
				{statusOfAppointment === 'Checked_In' && isCheckedInModalOpen && (
					<CheckInModal close={() => dispatch(setIsCheckedInModalOpen(false))} />
				)}
				{statusOfAppointment === "No_Show" &&
					isNoShowModalOpen && (
						<NoShowAppointment
							open={isNoShowModalOpen}
							appointmentDetails={appointmentDetails}
							close={() => {
								dispatch(setIsNoShowModalOpen(false))
							}} />
					)}
				{statusOfAppointment === "Signed_Off" && isSignOffModalOpen
					&& <SignOffAppoinment
						open={isSignOffModalOpen}
						appointmentDetails={appointmentDetails}
						close={() => {
							dispatch(setIsSignOffModalOpen(false))
						}}
					/>}
				{isDeleteModalOpen && (
					<DeleteAppointmentModal
						close={() => dispatch(setIsDeleteModalOpen(false))}
						appointmentDetails={appointmentDetails}
					/>
				)}
			</ModalComponent>
		</div>
	);
};
export default AppointmentDetailsModal;

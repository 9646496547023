import { useLocation } from 'react-router-dom'

const useUrlPathExists = () => {
    const location = useLocation()
    
    return (path) => {
        return location ? location.pathname.split("/").includes(path) : false
    }
}

export default useUrlPathExists
import React from 'react';
import ProfileCardList from '../ProfileCardList';
import Icons from '../../../../../../components/icons/Icons';
import ContactInformation from '../../../../../../containers/AddPatient/ContactInformation/ContactInformation';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updatePatientAddress } from '../../ProfileSaga';


const AddressCard = ({ patientData }) => {
    const { patientId } = useParams();
    const dispatch = useDispatch()
    const data = [
        {
            editbtnIcon: (<Icons iconName="editIcon" />),
        },
        { cardTitle: 'Address', feildName: 'Address Line 1', subFeild: `${patientData?.Address?.map((item) => item?.addressLine1).join(", ") || "-"}` },
        {
            feildName: 'Address Line 2',
            subFeild: `${patientData?.Address?.map((item) => item?.addressLine2).join(", ") || "-"}`
        },
        {
            feildName: 'State',
            subFeild: `${patientData?.Address?.map((item) => item?.state).join(", ") || "-"}`,
        },
        {
            feildName: 'City',
            subFeild: `${patientData?.Address?.map((item) => item?.city).join(", ") || "-"}`,
        },
        {
            feildName: 'Country',
            subFeild: `${patientData?.Address?.map((item) => item?.country).join(", ") || "-"}`,
        },
        {
            feildName: 'Zip code',
            subFeild: `${patientData?.Address?.map((item) => item?.zip).join(", ") || "-"}`,
        },
        { feildName: 'Contact Number', subFeild: `${patientData?.mobileNumber || "-"} ` },

    ];
    return <ProfileCardList
        patientUpdateCb={(values, handleSuccess) => {
            const { uuid, email, mobileNumber, ...updatedValues } = values;
            const updatedData = {
                ...updatedValues,
                state: values.state?.value,
            };
            dispatch(updatePatientAddress({ uuid: values?.uuid, updatedData: updatedData, handleSuccess, patientId: patientId }))
        }} customClassName="flex-1 min-w-500 max-w-600 max-h-385" data={data} paddingTop="pt-0" Component={ContactInformation}
        modelTitle={data[1].cardTitle}
    > </ProfileCardList>

};

export default AddressCard;

import { all, put, takeLatest } from "redux-saga/effects";
import store from "../store/store";
import { addNotifications } from "../components/common-components/toaster/ToasterSlice";
import { TOASTER_VARIANT } from "../components/common-components/toaster/MetToaster";
import NotificationDataService from "../services/NotificationDataService";
import { componentKey, setNotificationListData } from "./socketSlice";

export const { getAllNotifications, patchMarkNotificationAsRead, patchClearNotifications } = {
    getAllNotifications: (payload) => {
        return {
            type: 'SOCKET/GET_ALL_NOTIFICATIONS',
            payload
        }
    },
    patchMarkNotificationAsRead: (payload) => {
        return {
            type: 'SOCKET/PATCH_MARK_NOTIFICATION_AS_READ',
            payload
        }
    },
    patchClearNotifications: (payload) => {
        return {
            type: 'SOCKET/PATCH_CLEAR_NOTIFICATIONS',
            payload
        }
    },

}


function* getAllNotificationsAsync(action) {
    try {
        const { providerId } = action.payload
        const response = yield NotificationDataService.getAllNotifications(providerId)

        if (response.status) {
            let filteredData = response.data.data.notificationLog.map(item => (
                {
                    uuid: item.uuid,
                    label: item.label,
                    description: item.description,
                    date: new Date(item.created_at),
                    isRead: item.isRead,
                    type: item.type
                }
            )
            )
            yield put(setNotificationListData(filteredData))
        }

    } catch (error) {
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
        console.log('err: ', error)
    }
}

function* patchMarkNotificationAsReadAsync(action) {
    try {
        const { notificationId, providerId } = action.payload
        const response = yield NotificationDataService.patchMarkNotificationAsRead(notificationId)

        if (response.status) {
            yield put(getAllNotifications({ providerId }))
        }

    } catch (error) {
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
        console.log('err: ', error)
    }
}

function* patchClearNotificationsAsync(action) {
    try {
        const { providerId } = action.payload
        const response = yield NotificationDataService.patchMarkClearNotification(providerId)

        if (response.status) {
            yield put(getAllNotifications({ providerId }))
        }

    } catch (error) {
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
        console.log('err: ', error)
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getAllNotifications().type, getAllNotificationsAsync),
        takeLatest(patchMarkNotificationAsRead().type, patchMarkNotificationAsReadAsync),
        takeLatest(patchClearNotifications().type, patchClearNotificationsAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
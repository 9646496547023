import React from 'react';
import ProfileCardList from '../ProfileCardList';
import Icons from '../../../../../../components/icons/Icons';
import PatientDetails from '../../../../../../containers/AddPatient/PatientDetails/PatientDetails';
import { updateProfiles } from '../../ProfileSaga';
import { useDispatch } from 'react-redux';
import { FORM_FIELDS_NAMES } from '../../../../../../containers/AddPatient/PatientDetails/Constants';
import { useParams } from 'react-router-dom';


const DemographicsCard = ({ patientData }) => {
    const { patientId } = useParams();
    const dispatch = useDispatch()

    const data = [
        {
            editbtnIcon: (<Icons iconName="editIcon" />),
        },
        { cardTitle: 'Demographics', feildName: 'languages', subFeild: `${patientData?.language || "-"}` },
        {
            feildName: 'Marital Status',
            subFeild: `${patientData?.maritalStatus || "-"}`,
        },
        {
            feildName: 'Gender',
            subFeild: `${patientData?.gender || "-"}`,
        },
    ];
    return <ProfileCardList
        patientUpdateCb={(values, handleSuccess) => {
            values.gender = values.gender?.value;
            values.maritalStatus = values.maritalStatus?.value;
            values[FORM_FIELDS_NAMES.TIMEZONE] = values[FORM_FIELDS_NAMES.TIMEZONE]?.value;
            values[FORM_FIELDS_NAMES.LANGUAGE] = values[[FORM_FIELDS_NAMES.LANGUAGE]]?.value;
            const adharNum = values[FORM_FIELDS_NAMES.AADHAR_NUMBER]?.replace(/-/g, '')
            values[FORM_FIELDS_NAMES.AADHAR_NUMBER] = Number(adharNum)

            const { profilePicture, ...updatedValues } = values
            const updatedData = {
                ...updatedValues,
                image: profilePicture,
            };

            dispatch(updateProfiles({ uuid: patientId, updatedData: updatedData, handleSuccess }))

        }}
        customClassName="flex-1 min-w-500 max-w-600 max-h-385"
        data={data}
        paddingTop="pt-0"
        Component={PatientDetails}
        modelTitle={data[1].cardTitle}
    />
};

export default DemographicsCard;

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom"
import MetToaster from './MetToaster';
import { componentKey, removeNotification } from './ToasterSlice';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toaster = () => {
    const { notifications } = useSelector((state) => state[componentKey]);
    const dispatch = useDispatch();

    useEffect(() => {
        const [note] = notifications
        if (!note) return
        toast[note.variant](note.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            pauseOnFocusLoss: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            limit: 1,
            onClose: () => dispatch(removeNotification())
        });

        return () => {
            dispatch(removeNotification())
        }
    }, [notifications, dispatch])

    return ReactDOM.createPortal(
        <div>
            <ToastContainer />
        </div>
        ,
        document.getElementById('met-app-toasts')
    );
};

export default Toaster;
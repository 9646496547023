import React, { useState } from 'react'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import SelectDropdown from '../../../../components/common-components/selectDropdown'
import Icons from '../../../../components/icons/Icons'
import Input from '../../../../components/common-components/input/Input'
import DatePicker from '../../../../components/common-components/datePicker'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Table from '../../../../containers/Table/Index'
import InputWithSuffix from '../../../../components/common-components/inputWithSuffix/InputWithSuffix'
import Upload from '../../../../components/common-components/upload/Upload'
import { MET_COLORS } from '../../../../libs/constant'
import General from '../../../../libs/utility/General'


function LabResultTable({ addLabTests, values, setFieldValue, handleBlur }) {

    const [rowData, setRowData] = useState([])

    const coloumns = [
        {
            field: "number",
            label: <Label fontWeight="font-bold">No</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.number}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "name",
            label: <Label fontWeight="font-bold">Name</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.name}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "result",
            label: <Label fontWeight="font-bold">Result</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.result}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "unit",
            label: <Label fontWeight="font-bold">Unit</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.unit}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "reference",
            label: <Label fontWeight="font-bold">Reference</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.reference}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "reportDate",
            label: <Label fontWeight="font-bold">Report Date</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.reportDate}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "reportTime",
            label: <Label fontWeight="font-bold">Report Time</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.reportTime}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "interpretation",
            label: <Label fontWeight="font-bold">Interpretation</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.interpretation}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "uploadResult",
            label: <Label fontWeight="font-bold">Upload Result</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.uploadResult}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "action",
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.action}</Heading>
                        </div>
                        {values.patientLabTest.length > 1 ? <Button variant={BUTTON_VARIANTS.TEXT}
                            customBtnClass="bg-red-50 text-red-500 border-red-500"
                            startIcon={{ icon: <Icons iconName="trashIcon" /> }} onClickCb={() => {

                                values.patientLabTest.splice(row.number - 1, 1)
                                setFieldValue('patientLabTest', values.patientLabTest)
                            }}></Button> : null}


                    </div>
                </>
            },
        },
    ]


    const getRowData = () => {
        return values.patientLabTest?.slice(0, 3).map((test, index) => {
            return {
                number: index + 1,
                name: <Input
                    name={`patientLabTest[${index}].name`}
                    placeholder='Enter'
                    value={test.name}
                    onChangeCb={(e) => setFieldValue(`patientLabTest[${index}].name`, e.target.value)}

                />,
                result: <Input
                    placeholder={"Enter"}
                    name={`patientLabTest[${index}].result`}
                    value={test.result}
                    onChangeCb={(e) => { setFieldValue(`patientLabTest[${index}].result`, e.target.value) }}
                    onBlurCb={handleBlur}
                />,
                unit: <Input
                    placeholder={"Enter"}
                    name={`patientLabTest[${index}].unit`}
                    value={test.unit}
                    onChangeCb={(e) => { setFieldValue(`patientLabTest[${index}].unit`, e.target.value) }}
                    onBlurCb={handleBlur}
                />,
                reference: <div className='flex column-gap-10px'>
                    <div><InputWithSuffix placeholder="min"
                        name={`patientLabTest[${index}].min`}
                        value={test.min}
                        onChangeCb={(e) => {
                            setFieldValue(`patientLabTest[${index}].min`, e.target.value)
                        }}
                    /></div>
                    <div><InputWithSuffix placeholder="max"
                        name={`patientLabTest[${index}].max`}
                        value={test.max}
                        onChangeCb={(e) => { setFieldValue(`patientLabTest[${index}].max`, e.target.value) }} /></div>
                </div>,
                reportDate: <DatePicker placeholder='Select Date'
                minDate={new Date()}
                    value={test.reportDate}
                    name={`patientLabTest[${index}].reportDate`}
                    onChangeCb={(date) => {
                        setFieldValue(`patientLabTest[${index}].reportDate`, date)
                    }}
                />,
                reportTime: <DatePicker
                    name={`patientLabTest[${index}].reportTime`}
                    placeholder='Select Time'
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    value={test.reportTime}
                    onChangeCb={(time) => {
                        setFieldValue(`patientLabTest[${index}].reportTime`, time)
                    }}
                    iconName='scheduleIcon'
                />,
                interpretation: <SelectDropdown
                    placeholder='Select'
                    name={`patientLabTest[${index}].interpretation`}
                    value={test.interpretation}
                    onChangeCb={(e) => setFieldValue(`patientLabTest[${index}].interpretation`, e)}
                    options={[{ label: 'NORMAL', value: 'NORMAL' }, { label: 'ABNORMAL', value: 'ABNORMAL' }]}

                />,
                uploadResult: <Upload
                    customClass="h-[40px] w-[90px] text-met-primary"
                    borderType="border-none"
                    icon={<Icons iconName="uploadOutlinedIcon" color={MET_COLORS.PRIMARY}
                    />}
                    title="Upload"
                    customCotainerClass='flex justify-center items-center gap-3'
                    onChangeCb={async (file) => {
                        const mimeType = file?.type || ""
                        const base64String = await General.fileToBase64(file)
                        const base64 = `data:${mimeType};base64,${base64String}`
                        setFieldValue(`patientLabTest[${index}].uploadResult`, base64)
                    }}
                />
            }
        })
    }
    return (
        <>
            <><div className='flex flex-col items-center justify-between pt-6'>
                <div className='flex items-center justify-between w-full'>
                    <Heading fontSize="sm" fontWeight="bold" type={HEADING.H3}>Tests</Heading>
                    {values.patientLabTest.length < 3 && (
                        <Button
                            variant={BUTTON_VARIANTS.TEXT}
                            startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }}
                            customBtnClass="text-met-primary"
                            onClickCb={addLabTests}
                        >
                            Add New
                        </Button>
                    )}
                </div>
                <Table isPagination={false} coloumns={coloumns}
                    rows={getRowData()}
                    sorting={{
                        isSortable: false,
                        onSortChangeCb: () => { }
                    }}
                />
            </div></>
        </>
    )
}

export default LabResultTable
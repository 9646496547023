import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../components/common-components/button/Constants";
import ButtonDropdown from "../../../components/common-components/buttonDropdown";
import { HEADING } from "../../../components/common-components/heading/Constants";
import Heading from "../../../components/common-components/heading/Heading";
import Label from "../../../components/common-components/label/Label";
import Table from "../../../containers/Table/Index";
import { componentKey as PatientPaymentComponentKey, setEmptyPaymentpaginationState, setIsPatientPaymentFilterOpen, setIsPaymentModalOpen, setIsReciptModalOpen, setPatientReceiptData, setPaymentPaginationState } from "../Payments/PatientPaymentSlice"
import PatientPaymentModal from "./PatientPaymentModal";
import Filters from "./Filters";
import { getPatientsPaymentDetails } from "./PatientPaymentSaga";
import { useEffect } from "react";
import moment from "moment";
import Avatar from "../../../components/common-components/avatar";
import useGarbageCollector from "../../../libs/garbagecollector/garbageCollectorHooks";
import PatientReceiptModal from "./PatientReceiptModal";

const Payments = () => {
    const { isPaymentModalOpen, isReceiptModalOpen, isPatientPaymentFilterOpen, paymentpaginationState, patientPayments } = useSelector(((state) => state[PatientPaymentComponentKey]))
    const dispatch = useDispatch()

    useEffect(() => {
        const params = {
            pageNumber: paymentpaginationState.pageNumber,
            limit: paymentpaginationState.limit,
            name: (paymentpaginationState?.name || "").toLowerCase(),
            receipt: paymentpaginationState.receipt,
            date: paymentpaginationState.date,
        }
        dispatch(getPatientsPaymentDetails(params));
    }, [
        dispatch,
        paymentpaginationState.limit,
        paymentpaginationState.pageNumber,
        paymentpaginationState.name,
        paymentpaginationState.receipt,
        paymentpaginationState.date,
    ]);
    const handlePaymentModal = () => {
        dispatch(setIsPaymentModalOpen(true));
    };

    // const handleEditClick = (row) => {
    //     dispatch(setIsPaymentModalOpen(true));
    //     dispatch(setEditSelectedPayment(row));
    // };

    const handleReceiptView = (row) => {
        dispatch(setIsReciptModalOpen(true))
        dispatch(setPatientReceiptData(row))
    }

    const coloumns = [
        {
            field: 'id',
            label: <Label fontWeight="font-bold">Payment ID</Label>,
            renderLogic: (row) => {
                const uuid = row?.uuid;
                const paymentId = uuid.substring(0, 6);
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {paymentId}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'receipt',
            label: <Label fontWeight="font-bold">Receipt #</Label>,
            renderLogic: (row) => {
                const uuid = row?.uuid;
                const receipt = `RE${uuid.substring(uuid.length - 4)}`;
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="met-primary"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                    onClickCb={() => handleReceiptView(row)}
                                >
                                    {receipt}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'date',
            label: <Label fontWeight="font-bold">Payment Date</Label>,
            renderLogic: (row) => {
                const paymentDate = row.paymentDate ? moment(row?.paymentDate).format('DD-MM-YYYY') : '-';
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {paymentDate}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'name',
            label: <Label fontWeight="font-bold">Patient Name</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <Avatar
                                customClass="w-[40px] h-[40px] rounded-full"
                                url={row?.profilePicture}
                                name={row.patient?.firstName + ' ' + row.patient?.lastName}
                            />
                            <div className="pl-4">
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.patient?.firstName + ' ' + row.patient?.lastName}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'provider',
            label: <Label fontWeight="font-bold">Provider Name</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.provider?.firstName + ' ' + row.provider?.lastName}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'method',
            label: <Label fontWeight="font-bold">Payment Method</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.method}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'card',
            label: <div>
                <Label fontWeight="font-bold">Cheque No/Card Last 4 Digit</Label>
            </div>,
            renderLogic: (row) => {
                const checkNo = row?.checkNo;
                const cheque = checkNo ? checkNo.substring(checkNo.length - 4) : '-';
                return (
                    <>
                        <div className="flex justify-center mr-10">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {cheque}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'paid',
            label: <Label fontWeight="font-bold">Paid Amount</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.amount}
                                </Heading>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'action',
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-center">
                            <div>
                                <Heading
                                    type={HEADING.H1}
                                    color="gray-500"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {row.action}
                                </Heading>
                            </div>
                            <ButtonDropdown
                                customBtnClass="p-0"
                                endIcon="moreVertIcon"
                                options={[
                                    { label: 'View Details', value: 'viewDetails', onChangeCb: () => handleReceiptView(row) },

                                    // {label: 'Edit & Apply Payment', value: null, onChangeCb: () => handleEditClick(row) },
                                ]}
                                selectCb={(option) => {
                                    option?.onChangeCb();
                                }}
                            />
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className='flex items-center justify-end p-2 px-4 column-gap-10px'>
                <div className='flex'>
                    {!isPatientPaymentFilterOpen && (
                        <Button
                            variant={BUTTON_VARIANTS.OUTLINED}
                            onClickCb={() => {
                                dispatch(setIsPatientPaymentFilterOpen(!isPatientPaymentFilterOpen));
                            }}
                        >
                            Show Filters
                        </Button>
                    )}
                    {isPatientPaymentFilterOpen && (
                        <Button
                            variant={BUTTON_VARIANTS.OUTLINED}
                            onClickCb={() => {
                                dispatch(setIsPatientPaymentFilterOpen(!isPatientPaymentFilterOpen));
                                dispatch(getPatientsPaymentDetails())
                                dispatch(setEmptyPaymentpaginationState())
                            }}
                        >
                            Clear Filters
                        </Button>
                    )}
                </div>
                <div className='flex'>
                    <Button onClickCb={handlePaymentModal} variant={BUTTON_VARIANTS.CONTAINED} startIcon={{ icon: "plusVectorIcon", color: "white" }} >Add Payment</Button>
                </div>
            </div>
            {isPatientPaymentFilterOpen && <Filters />}
            <div className="p-4">
                <Table
                    isPagination={false}
                    coloumns={coloumns}
                    rows={patientPayments}
                    tableHeaderCustomclasses=' '
                    paginationProps={{
                        isPagination: true,
                        totalCount: paymentpaginationState.total,
                        limit: paymentpaginationState.limit,
                        onPageChange: (page) => {
                            dispatch(setPaymentPaginationState({ pageNumber: page }))
                        }
                    }}
                />
            </div>
            {isPaymentModalOpen ? (
                <PatientPaymentModal open={isPaymentModalOpen} close={() => { dispatch(setIsPaymentModalOpen(false)); }} />
            ) : null}
            {isReceiptModalOpen ? (
                <PatientReceiptModal open={isReceiptModalOpen} close={() => { dispatch(setIsReciptModalOpen(false)) }} />
            ) : null}

        </>
    );
}

export default Payments;
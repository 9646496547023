import React, { useEffect, useState, useRef } from 'react';
import useBase64Image from '../../../../containers/SettingsTab/MyAccountCard/Tabs/PrintConfiguration/useS3Image';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '../../../../assets/scss/global.scss';

const LinkToBase64 = ({ linkurl }) => {
    const [pdfURL, setPdfURL] = useState("");
    const [error, setError] = useState("");
    const [base64Data, setBase64Data] = useState("")
    const getBase64 = useBase64Image();
    const hasLoaded = useRef(false);

    const [showConditionalMessage, setShowConditionalMessage] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowConditionalMessage(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const fetchAndConvertToBase64 = async () => {
            if (linkurl && !hasLoaded.current) {
                try {
                    const base64Data = await getBase64(linkurl);
                    setBase64Data(base64Data)
                    if (base64Data) {
                        const base64String = base64Data.startsWith('data:')
                            ? base64Data.split(",")[1]
                            : base64Data;
                        const mimeType = 'application/pdf';

                        const byteCharacters = atob(base64String);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: mimeType });
                        const objectURL = URL.createObjectURL(blob);
                        setPdfURL(objectURL);
                        hasLoaded.current = true;
                    }
                } catch (error) {
                    console.error("Error converting URL to base64:", error);
                    setError("Error loading PDF");
                }
            }
        };

        fetchAndConvertToBase64();
    }, []);

    return (
        <div className="h-full w-full overflow-hidden relative">
            {pdfURL ? (
                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                    <div className='break-inside-avoid page-break-before-auto page-break-after-auto'>
                        <Viewer fileUrl={pdfURL} />
                    </div>
                </Worker>
            ) : (
                showConditionalMessage ? (
                    <p>{base64Data ? "Loading..." : "File Not Found"}</p>
                ) : (
                    <p>Loading...</p>
                )
            )}
        </div>
    );
}

export default LinkToBase64;

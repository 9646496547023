import { useLayoutEffect } from 'react';

export function ResizeObserver(callback) {
    this.callback = callback;
}

ResizeObserver.prototype.observe = function (target) {
    this.target = target;
    const self = this;
    const resizeHandler = function () {
        self.callback([{ target: self.target }]);
    };
    this.target.addEventListener('resize', resizeHandler);
};

ResizeObserver.prototype.unobserve = function (target) {
    this.target.removeEventListener('resize', this.callback);
};

ResizeObserver.prototype.disconnect = function () {
    this.target = null;
};

export function useSizeCallback(target, callback) {
    useLayoutEffect(() => {
        if (!target) {
            return () => {
                // Empty function
            };
        }
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                callback({
                    width: entry.target.clientWidth,
                    height: entry.target.clientHeight
                });
            });
        });
        resizeObserver.observe(target);
        return () => {
            resizeObserver.unobserve(target);
            resizeObserver.disconnect();
        };
    }, [target, callback]);
}

import { useEffect, useState } from "react"
import SelectDropdown from "../../../components/common-components/selectDropdown"
import EncounterDiagnosesDataCard from "./EncounterDiagnosesDataCard"
import { getEncounterIdByUUID, getPatientEncounterDetails } from "../EncounterSaga"
import { componentKey as PatientEncounterComponentKey, setSelectedDate } from "../EncounterSlice"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import EncounterDetails from "./EncounterDetails"
import { useParams } from "react-router-dom"

const EncounterData = () => {
    const [showAllEncounterDetails, setShowAllEncounterDetails] = useState(false)
    const [encounterDetails, setEncounterDetails] = useState(false)
    const [selectedEncounterDate, setSelectedEncounterDate] = useState()
    const { patientEncounterData, encounterFormDataById, selectedDate } = useSelector(state => state[PatientEncounterComponentKey])
    const dispatch = useDispatch()
    const { patientId } = useParams();
   
    useEffect(() => {
        dispatch(getPatientEncounterDetails({ patientId }))
    }, [])

    const getPatientEncounterDateLabelValue = () => {
        const encounterDate = [];
        patientEncounterData?.map(encounter => {
            encounterDate.push({ label: moment(encounter.createdAt).format('ddd. MMMM D, YYYY'), value: encounter.uuid })
        })
        return encounterDate
    }

    const handleShowAllEncounterDetails = (encounter) => {
        setShowAllEncounterDetails(true)
        setEncounterDetails(encounter)
    }

    const handleHideAllEncounterDetails = () => {
        setShowAllEncounterDetails(false)
    }

    return <div className="mt-[70px]">
        {!showAllEncounterDetails && <div className="row-gap-10">
            <div className="flex justify-end">
                <div className=" w-[200px]">
                    <SelectDropdown
                        // name={'selectedEnconterDate'}
                        placeholder={'All Encounters'}
                        onChangeCb={(val) => {
                            setSelectedEncounterDate(val)
                            dispatch(setSelectedDate(val?.label))
                        }}
                        options={getPatientEncounterDateLabelValue()}
                        value={selectedEncounterDate}
                    />
                </div>
            </div>
            {patientEncounterData.map((encounter, index) => {
                return (
                <EncounterDiagnosesDataCard
                    key={`encounter ${index}`}
                    title={moment(encounter?.createdAt).format('ddd, MMMM D, YYYY')} encounter={encounter} handleShowAllEncounterDetails={handleShowAllEncounterDetails}></EncounterDiagnosesDataCard>
            )})}
        </div>}
        {showAllEncounterDetails && <EncounterDetails encounterDetails={encounterFormDataById} handleHideAllEncounterDetails={handleHideAllEncounterDetails}></EncounterDetails>}
    </div >
}

export default EncounterData
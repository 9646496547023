import { all, put, takeLatest } from 'redux-saga/effects'
import store from "../../../../../store/store"
import { componentKey, setAllProviderGroupLocation, setProviderGroupDetails } from './ProviderGroupSlice'
import ProviderGroupDataService from '../../../../../services/ProviderGroupDataService'
import ProvidersDataService from '../../../../../services/ProvidersDataService'

export const { getProviderGroupDetails } = {
    getProviderGroupDetails: (payload) => {
        return {
            type: 'USER_PROFILE/GET_PROVIDER_GROUP_DETAILS',
            payload
        }
    }
}

export const { getAllProviderGroupLocations } = {
    getAllProviderGroupLocations: (payload) => {
        return {
            type: 'USER_PROFILE/GET_PROVIDER_GROUP_LOCATION_DETAILS',
            payload
        }
    }
}

function* getProviderGroupDetailsAsync(action) {
    try {
        const response = yield ProviderGroupDataService.getProviderGroupDetails(action.payload)
        const { data: [firstElement] } = response.data
        yield put(setProviderGroupDetails(firstElement))
    } catch (error) {
        console.log('err: ', error)
    }
}

function* getAllProviderGroupLocationsAsync(action) {
    try {
        const response = yield ProvidersDataService.getAllProviderLocation(action.payload)
        const { data, status } = response.data

        if (status) {
            const { locations } = data || []
            yield put(setAllProviderGroupLocation(locations))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getProviderGroupDetails().type, getProviderGroupDetailsAsync),
        takeLatest(getAllProviderGroupLocations().type, getAllProviderGroupLocationsAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Icons from '../../../../components/icons/Icons'
import Label from '../../../../components/common-components/label/Label'
import RadioButton from '../../../../components/common-components/radioButton/RadioButton'
import Input from '../../../../components/common-components/input/Input';
import DatePicker from '../../../../components/common-components/datePicker'
import LabResultTable from './LabResultTable'
import TextArea from '../../../../components/common-components/textArea/TextArea'
import { Form, Formik } from 'formik'
import { LAB_RESULT_FORM_FIELDS_NAMES } from './Constants'
import * as Yup from 'yup';
import { componentKey as PatientLabResultComponentKey } from '../Lab/PatientLabSlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import {
    postPatientLabResult, updatePatientLabResult,
} from '../Lab/PatientLabSaga';
import SelectDropdown from '../../../../components/common-components/selectDropdown';
import General from '../../../../libs/utility/General';
import { VALIDATION_REGEX } from '../../../../libs/constant';
import LabResultPrintModal from './LabResultPrintModal';

const fields = [
    { fieldName: LAB_RESULT_FORM_FIELDS_NAMES.LAB_ORDER },
    { fieldName: LAB_RESULT_FORM_FIELDS_NAMES.COLLECTION_DATE },
    { fieldName: LAB_RESULT_FORM_FIELDS_NAMES.COLLECTION_TIME },
    { fieldName: LAB_RESULT_FORM_FIELDS_NAMES.REVIEWER },
    { fieldName: LAB_RESULT_FORM_FIELDS_NAMES.LAB_NAME },
    { fieldName: LAB_RESULT_FORM_FIELDS_NAMES.NOTE },
    { fieldName: LAB_RESULT_FORM_FIELDS_NAMES.UPLOAD_ACTION, isRequired: true },

]

const validationSchema = Yup.object().shape({
    patientLabTest: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('name is required'),
            result: Yup.string().required('result is required'),
            unit: Yup.string().required('unit is required'),
            min: Yup.string().required("min value is required").matches(VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX, `${General.camelToPascalWithSpaces("min")} is invalid`),
            reportDate: Yup.string().required('reportDate is required'),
            max: Yup.string().required("max value is required").matches(VALIDATION_REGEX.NUMBER_WITH_DECIMAL_REGEX, `${General.camelToPascalWithSpaces("max")} is invalid`),
            interpretation: Yup.object().shape({
                label: Yup.string().required('interpretation is required'),
                value: Yup.string().required('interpretation is required')
            }),
        })
    ),
    labId: Yup.object().shape({
        label: Yup.string().required('labId center is required'),
        value: Yup.string().required('labId center is required')
    }),
    collectionDate: Yup.date().required('collection date is required'),
    reviewer: Yup.string().required('reviewer is required'),
});


function LabResultModal({ open, close }) {

    const dispatch = useDispatch();
    const { isLabResultModalOpen, labResultData, currentLabResultId, isNewLabResultAdd, allLabsData } = useSelector(
        (state) => state[PatientLabResultComponentKey]
    );

    const { currentPatientId, patientData } = useSelector((state) => state[PatientDashboardComponentKey]);

    const [isOpenPrintModal, setIsOpenPrintModal] = useState(false)

    const addLabTests = (values, setFieldValue) => {
        setFieldValue('patientLabTest', [
            ...values.patientLabTest,
            { name: '', result: '', unit: '', min: '', max: '', reportDate: '', reportTime: '', interpretation: '', uploadResult: '' }
        ]);
    };

    return (
        <>

            <Formik
                initialValues={{
                    patientLabTest: labResultData?.patientLabTest ? labResultData?.patientLabTest.map((test) => {
                        const obj = { name: test.name, result: test.result, unit: test.unit, min: test.min, max: test.max, reportDate: test.reportDate, reportTime: test.reportTime, interpretation: test.interpretation ? { label: test.interpretation, value: test.interpretation } : '', uploadResult: '' }
                        return { ...test, ...obj }
                    }) : [{ name: '', result: '', unit: '', min: '', max: '', reportDate: '', reportTime: '', interpretation: '', uploadResult: '' }],
                    [LAB_RESULT_FORM_FIELDS_NAMES.COLLECTION_DATE]: labResultData?.collectionDate,
                    [LAB_RESULT_FORM_FIELDS_NAMES.COLLECTION_TIME]: labResultData?.collectionTime || '',
                    [LAB_RESULT_FORM_FIELDS_NAMES.REVIEWER]: labResultData?.reviewer,
                    [LAB_RESULT_FORM_FIELDS_NAMES.LAB_NAME]: labResultData?.labId ? { label: labResultData?.labId || '', value: labResultData?.labId || '' } : '',
                    [LAB_RESULT_FORM_FIELDS_NAMES.NOTE]: labResultData?.note,
                }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(formVals) => {
                    const data = {
                        ...formVals,
                        labId: formVals.labId?.value,
                        patientLabTest: formVals.patientLabTest.map((test) => ({ ...test, interpretation: test.interpretation?.value }))
                    }
                    try {
                        if (isNewLabResultAdd) {
                            dispatch(postPatientLabResult({ patientId: currentPatientId, labresultData: data }));
                        } else {
                            dispatch(
                                updatePatientLabResult({
                                    patientId: currentPatientId,
                                    labresultId: currentLabResultId,
                                    labresultData: data,
                                })
                            );
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }}
            >
                {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                    return <Form onSubmit={handleSubmit} >
                        <ModalComponent
                            open={isLabResultModalOpen}
                            title={isNewLabResultAdd ? 'Add Lab Result' : 'Update Lab Result'}
                            footerButton={<div className='flex pt-2 column-gap-10px'>
                                <Button variant={BUTTON_VARIANTS.OUTLINED} customBtnClass="text-met-primary" onClickCb={() => {
                                    setIsOpenPrintModal(true)
                                }}>Print</Button>
                                <Button type='submit' variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary">Save Lab Result</Button>
                            </div>}
                            icon={<Icons iconName="closeIcon" />}
                            close={close}
                            customClasses="w-[93%]"
                        >
                            <div className='p-4'>
                                <div className='flex'>
                                    {/* <div className='w-1/4' >
                                    <Label fontSize={"md"} fontWeight='bold' isRequired>Upload Action</Label>
                                    <div className='flex mt-2'>
                                        <div className='flex'>
                                            <RadioButton
                                                label="Associate result with existing lab order"
                                                name={LAB_RESULT_FORM_FIELDS_NAMES.UPLOAD_ACTION}
                                                checked={values[LAB_RESULT_FORM_FIELDS_NAMES.UPLOAD_ACTION] === LAB_RESULT_FORM_FIELDS_NAMES.RESULT_WITH_LAB_ORDER}
                                                value={LAB_RESULT_FORM_FIELDS_NAMES.UPLOAD_ACTION}
                                                onChangeCb={(e) => {
                                                    setFieldValue(LAB_RESULT_FORM_FIELDS_NAMES.UPLOAD_ACTION, e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex pt-2'>
                                        <RadioButton
                                            label="Upload result without lab order"
                                            name={LAB_RESULT_FORM_FIELDS_NAMES.UPLOAD_ACTION}
                                            checked={values[LAB_RESULT_FORM_FIELDS_NAMES.UPLOAD_ACTION] === LAB_RESULT_FORM_FIELDS_NAMES.RESULT_WITHOUT_LAB_ORDER}
                                            value={LAB_RESULT_FORM_FIELDS_NAMES.UPLOAD_ACTION}
                                            onChangeCb={(e) => {
                                                setFieldValue(LAB_RESULT_FORM_FIELDS_NAMES.UPLOAD_ACTION, e.target.value)
                                            }}
                                        />
                                    </div>
                                </div> */}
                                    <div className='flex column-gap-10px'>
                                        {/* <div className='flex column-gap-10px'>
                                        <div className='min-w-[360px]'>
                                            <SelectDropdown
                                                placeholder='Select'
                                                label='Lab Order'
                                                value={values[LAB_RESULT_FORM_FIELDS_NAMES.LAB_ORDER]}
                                                name={LAB_RESULT_FORM_FIELDS_NAMES.LAB_ORDER}
                                                onChangeCb={(val) => { setFieldValue(LAB_RESULT_FORM_FIELDS_NAMES.LAB_ORDER, val.value) }}
                                                options={[{ label: "hello", value: "harris" }]}
                                            />
                                        </div>
                                    </div> */}
                                        <div className='flex column-gap-10px'>
                                            <div className='flex-1'>
                                                <div>
                                                    <DatePicker
                                                        label="Collection Date"
                                                        placeholder='Select Date'
                                                        maxDate={new Date()}
                                                        isRequired={true}
                                                        value={values[LAB_RESULT_FORM_FIELDS_NAMES.COLLECTION_DATE]}
                                                        name={LAB_RESULT_FORM_FIELDS_NAMES.COLLECTION_DATE}
                                                        onChangeCb={(date) => {
                                                            setFieldValue(LAB_RESULT_FORM_FIELDS_NAMES.COLLECTION_DATE, date)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div >
                                                <div>
                                                    <DatePicker
                                                        name={LAB_RESULT_FORM_FIELDS_NAMES.COLLECTION_TIME}
                                                        placeholder='Select Time'
                                                        label='Collection Time'
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={30}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"
                                                        value={values[LAB_RESULT_FORM_FIELDS_NAMES.COLLECTION_TIME]}
                                                        onChangeCb={(time) => {
                                                            setFieldValue(LAB_RESULT_FORM_FIELDS_NAMES.COLLECTION_TIME, time)
                                                        }}
                                                        iconName='scheduleIcon'
                                                    />
                                                </div>
                                            </div>
                                            <div className='min-w-[360px]'>
                                                <Input
                                                    label="Reviewer"
                                                    placeholder="Enter Reviewer"
                                                    isRequired={true}
                                                    name={LAB_RESULT_FORM_FIELDS_NAMES.REVIEWER}
                                                    value={values[LAB_RESULT_FORM_FIELDS_NAMES.REVIEWER]}
                                                    onChangeCb={(e) => {
                                                        setFieldValue(LAB_RESULT_FORM_FIELDS_NAMES.REVIEWER, e.target.value);
                                                    }}
                                                />
                                            </div>
                                            <div className='min-w-[360px]'>
                                                <SelectDropdown
                                                    label='Lab Name'
                                                    placeholder='Select Lab Name'
                                                    isRequired={true}
                                                    name={LAB_RESULT_FORM_FIELDS_NAMES.LAB_NAME}
                                                    value={values[LAB_RESULT_FORM_FIELDS_NAMES.LAB_NAME]}
                                                    onChangeCb={(val) => {
                                                        setFieldValue(LAB_RESULT_FORM_FIELDS_NAMES.LAB_NAME, val)

                                                    }}
                                                    options={General.addLableValuePair(allLabsData, "name", "uuid")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex column-gap-10px pt-6'>




                                </div>
                                <div>
                                    <LabResultTable values={values} setFieldValue={setFieldValue} addLabTests={() => addLabTests(values, setFieldValue)} handleBlur={handleBlur} />
                                </div>
                                <div>
                                    <div className='mt-4'>
                                        <Label>Note</Label>
                                    </div>
                                    <div className='mt-2'>
                                        <TextArea
                                            name={LAB_RESULT_FORM_FIELDS_NAMES.NOTE}
                                            value={values[LAB_RESULT_FORM_FIELDS_NAMES.NOTE]}
                                            onChangeCb={(e) => { setFieldValue(LAB_RESULT_FORM_FIELDS_NAMES.NOTE, e.target.value) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </ModalComponent >
                        {isOpenPrintModal && <LabResultPrintModal open={isOpenPrintModal} close={() => {
                            setIsOpenPrintModal(false)
                        }}
                            data={{ patientInfo: patientData, labInfo: values[LAB_RESULT_FORM_FIELDS_NAMES.LAB_NAME], patientLabTest: values.patientLabTest?.map((labTest, index) => ({ index: index + 1, ...labTest })), labNote: values[LAB_RESULT_FORM_FIELDS_NAMES.NOTE] }}
                        />}
                    </Form>
                }}
            </Formik>


        </>
    )
}

export default LabResultModal
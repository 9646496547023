/* eslint-disable react/no-unknown-property */
export const SettingsPrintConfiguration = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			// xmlns:xlink="http://www.w3.org/1999/xlink"
			width="58.616"
			height="77.039"
			viewBox="0 0 58.616 77.039"
		>
			<defs>
				<clipPath id="clip-path">
					<rect
						id="Rectangle_35083"
						data-name="Rectangle 35083"
						width="58.616"
						height="77.039"
						transform="translate(0 0)"
						fill="none"
					/>
				</clipPath>
			</defs>
			<g id="Group_90569" data-name="Group 90569" transform="translate(0 -0.004)">
				<g id="Group_83527" data-name="Group 83527" transform="translate(0 0.004)" clip-path="url(#clip-path)">
					<g id="Group_90568" data-name="Group 90568" transform="translate(0)">
						<path
							id="Path_205063"
							data-name="Path 205063"
							d="M7.168,303.773A8.679,8.679,0,0,0,0,312.3v8.638H19.991a18.444,18.444,0,0,0-4.083-9.656,15.712,15.712,0,0,0-2.036-2.016Z"
							transform="translate(0 -282.979)"
							fill="#18aab0"
						/>
						<path
							id="Path_205064"
							data-name="Path 205064"
							d="M319.114,12.889V8.652A8.681,8.681,0,0,0,310.465,0h-8.648V10.3A8.271,8.271,0,0,1,313.3,17.373a61.49,61.49,0,0,1,5.81-4.484"
							transform="translate(-281.157 -0.004)"
							fill="#18aab0"
						/>
						<path
							id="Path_205065"
							data-name="Path 205065"
							d="M390.934,339.926h8.648v-20.67H411.6a8.664,8.664,0,0,0,8.638-8.638V301.97H403.326a28.756,28.756,0,0,0-4.854,9.831c-1.789,6.252-1.974,12.937-4.37,19.014a23.37,23.37,0,0,1-5.9,8.669,8.566,8.566,0,0,0,2.735.442"
							transform="translate(-361.627 -281.3)"
							fill="#86c442"
						/>
						<path
							id="Path_205066"
							data-name="Path 205066"
							d="M157.937,153.992c-28.691,18.654-2.746,44.157-40.455,52.96,18.222-8.556,19.035-30.367,7.764-39.581l11.034,3.445a57.118,57.118,0,0,1,21.657-16.824"
							transform="translate(-109.44 -143.451)"
							fill="#18aab0"
						/>
						<path
							id="Path_205067"
							data-name="Path 205067"
							d="M278.325,185.541a5.2,5.2,0,1,1-5.2,5.2,5.2,5.2,0,0,1,5.2-5.2"
							transform="translate(-254.427 -172.841)"
							fill="#86c442"
						/>
					</g>
					<text
						id="Think_EMR"
						data-name="Think EMR"
						transform="translate(9 73.737)"
						fill="#18aab0"
						font-size="9"
						font-family="MyriadPro-Semibold, Myriad Pro"
						font-weight="600"
					>
						<tspan x="0" y="0">
							Think EMR
						</tspan>
					</text>
				</g>
			</g>
		</svg>
	);
};

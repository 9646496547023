import React from 'react'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Table from '../../../../containers/Table/Index'
import Label from '../../../../components/common-components/label/Label'
import { useSelector } from 'react-redux'
import { componentKey as PatientNotesComponentKey } from "./PatientNotesSlice"
import moment from 'moment'

function NoteHistoryModal({ open, close }) {

    const { notesHistoryData } = useSelector(state => state[PatientNotesComponentKey])

    const columns = [
        {
            field: 'user',
            label: <Label fontWeight="normal">User</Label>,
            renderLogic: (row) => {
                return (
                    <div className='w-[350px]'>
                        <Label color="gray-500">{`${row?.provider?.firstName ?? ''} ${row?.provider?.lastName ?? ''}`}</Label>
                    </div>
                );
            },
        },
        {
            field: 'at',
            label: <Label fontWeight="normal">At</Label>,
            renderLogic: (row) => {
                return (
                    <div>
                        <Label color="gray-500">{`${row?.updatedAt ? moment(row?.updatedAt).format('DD-MM-YYYY') : ''}, ${row?.updatedAt ? moment(row?.updatedAt).format('hh:mm A') : ''}`}</Label>
                    </div>
                );
            },
        }
    ]

    return (
        <ModalComponent close={close} customClasses='w-[600px]' open={open} title="Note History">
            <Table coloumns={columns} rows={notesHistoryData} isPagination={false} tableHeaderCustomclasses={"bg-white border-b border-1"} variant='sm' />
        </ModalComponent>
    )
}

export default NoteHistoryModal
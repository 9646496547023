import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import FaceSheetCard from '../../Dashboard/FaceSheet/FaceSheetCard';
import Button from '../../../../../components/common-components/button/Button';
import { useRef, useState } from 'react';
import General from '../../../../../libs/utility/General';
import ShowForAllowedPermissionsOnly from '../../../../../containers/Wrappers/ShowForAllowedPermissionsOnly';
import { USER_PERMISSIONS } from '../../../../../libs/UserPermissionsConstants';


const ProfileCardList = ({ data, customClassName = '', paddingTop = '', patientUpdateCb = () => { }, Component, modelTitle }) => {
    const [isOpenEditPopup, setIsOpenEditPopup] = useState(false)

    const formRef = useRef()

    const handleSuccess = () => {
        setIsOpenEditPopup(false);
    };

    return (
        <FaceSheetCard
            title={<div className='flex justify-start items-center w-1000'>
                {data[1]?.cardTitle}
            </div>
            }
            iconbtn={<> {data[0]?.editbtnIcon && <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.UPDATE_PATIENT} >
                <div onClick={() => setIsOpenEditPopup(true)} className="cursor-pointer flex justify-end flex-start">
                    {data[0]?.editbtnIcon}
                </div>
            </ShowForAllowedPermissionsOnly>
            } </>}
            customClassName={customClassName}
            paddingTopClass={paddingTop}
            textColorClass="text-met-primary"
        >
            {data.map((item, index) => (
                <div key={"face-sheet-card" + data[1]?.cardTitle + index}>
                    <div className="flex pt-2 justify-between">
                        {item?.icon ? (
                            <div className="flex justify-center items-center h-100 w-full">
                                {item?.icon}
                            </div>
                        ) : null}
                    </div>


                    {item?.feildName && (
                        <div className="flex justify-between pt-2">
                            <div className="fs-16 flex-1 font-bold">{item.feildName}</div>
                            <div
                                className={
                                    item.subFeild === 'Active'
                                        ? 'text-green-500 flex-1 flex column-gap-10px'
                                        : 'text-gray-500 flex-1 flex column-gap-10px'
                                }
                            >
                                <span>:</span>
                                <span dangerouslySetInnerHTML={{ __html: General.camelToPascalWithSpaces(item.subFeild) }}>
                                </span>
                            </div>

                            {item.description && <div className="text-gray-500 w-1/2" dangerouslySetInnerHTML={{ __html: General.camelToPascalWithSpaces(item.description) }}></div>}
                        </div>
                    )}
                </div>
            ))
            }

            {
                isOpenEditPopup ?
                    <ModalComponent
                        title={`Edit ${modelTitle}`}
                        customClasses='w-[80%]'
                        open={isOpenEditPopup}
                        close={() => setIsOpenEditPopup(false)}
                        footerButton={<Button onClickCb={() => {
                            formRef.current.submitForm();
                        }} type='submit'>Update</Button>}
                    >
                        <Component
                            onUpdateCb={(values, patientId) => {
                                patientUpdateCb(values, handleSuccess, patientId)

                            }}
                            isUpdate={true}
                            ref={formRef}
                        />
                    </ModalComponent> : null
            }

        </FaceSheetCard >
    );
};

export default ProfileCardList;

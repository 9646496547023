

const Templates = () => {
    return <>
        <div className="mt-[70px] text-gray-500 text-center">
            No data found
        </div>
    </>
}

export default Templates
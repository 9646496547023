import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../../components/common-components/modal/ModalComponent';
import Label from '../../../../components/common-components/label/Label';
import RadioButton from '../../../../components/common-components/radioButton/RadioButton';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import Icons from '../../../../components/icons/Icons';
import ToggleButton from '../../../../components/common-components/toggleButton/ToggleButton';
import SelectDropdown from '../../../../components/common-components/selectDropdown';
import Input from '../../../../components/common-components/input/Input';
import DatePicker from '../../../../components/common-components/datePicker';
import TextArea from '../../../../components/common-components/textArea/TextArea';
import { FORM_FIELDS_NAMES } from './Constants';
import { Form, Formik } from 'formik';
import { getValidationSchema } from '../../../../libs/formsUtils';
import { componentKey as PatientAllergiesComponentKey } from '../Allergies/PatientAllergiesSlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import {
	postPatientAllergies,
	updatePatientAllergy,
} from '../Allergies/PatientAllergiesSaga';

const fields = [
	{ fieldName: FORM_FIELDS_NAMES.ALLERGY_NAME, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.REACTION, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.SEVERITY, isDropdown: true },
	{ fieldName: FORM_FIELDS_NAMES.ONSET_DATE, isRequired: true },
];

const validationSchema = getValidationSchema(fields);

function AllergiesModal({ open, close }) {
	const dispatch = useDispatch();
	const { isAllergyModalOpen, allergyData, currentAllergyId, isNewAllergyAdd } = useSelector(
		(state) => state[PatientAllergiesComponentKey]
	);

	const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);
	return (
		<Formik
			initialValues={{
				[FORM_FIELDS_NAMES.ALLERGY_TYPE]: allergyData?.allergyType || 'drug',
				[FORM_FIELDS_NAMES.ALLERGY_NAME]: allergyData?.name,
				[FORM_FIELDS_NAMES.REACTION]: allergyData?.reaction,
				[FORM_FIELDS_NAMES.SEVERITY]: allergyData?.severity ? { label: allergyData?.severity || "", value: allergyData?.severity || "", } : "",
				[FORM_FIELDS_NAMES.ONSET_DATE]: allergyData?.onsetDate,
				[FORM_FIELDS_NAMES.NOTE]: allergyData?.note || '',
				[FORM_FIELDS_NAMES.STATUS]: allergyData?.status ? allergyData.status : 'Active',
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(formVals) => {
				try {
					const data = {
						...formVals,
						severity: formVals?.severity?.value
					}
					if (isNewAllergyAdd) {
						dispatch(postPatientAllergies({ patientId: currentPatientId, allergyData: data }));
					} else {
						dispatch(
							updatePatientAllergy({
								patientId: currentPatientId,
								allergyId: currentAllergyId,
								allergyData: data,
							})
						);
					}
				} catch (error) {
					console.log(error);
				}
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<div>
							<ModalComponent
								open={isAllergyModalOpen}
								title={isNewAllergyAdd ? 'Add Allergies' : 'Update Allergy'}
								footerButton={
									<Button
										type="submit"
										variant={BUTTON_VARIANTS.CONTAINED}
										customBtnClass="text-met-primary"
									>
										Save
									</Button>
								}
								icon={<Icons iconName="closeIcon" />}
								close={close}
								customClasses="w-[800px]"
							>
								<div className="p-2">
									<div>
										<div className="mt-4">
											<Label isRequired={true} asteriskPosition="end" fontWeight="bold">
												Allergy Type
											</Label>
										</div>
										<div className="flex mt-2">
											<div className="flex">
												<div>
													<RadioButton
														label="Drug"
														name={FORM_FIELDS_NAMES.ALLERGY_TYPE}
														checked={
															values[FORM_FIELDS_NAMES.ALLERGY_TYPE] ===
															FORM_FIELDS_NAMES.DRUG
														}
														value={FORM_FIELDS_NAMES.DRUG}
														onChangeCb={(e) => {
															setFieldValue(
																FORM_FIELDS_NAMES.ALLERGY_TYPE,
																e.target.value
															);
														}}
													/>
												</div>
											</div>
											<div className="flex ml-3">
												<div>
													<RadioButton
														label="Food"
														name={FORM_FIELDS_NAMES.ALLERGY_TYPE}
														checked={
															values[FORM_FIELDS_NAMES.ALLERGY_TYPE] ===
															FORM_FIELDS_NAMES.FOOD
														}
														value={FORM_FIELDS_NAMES.FOOD}
														onChangeCb={(e) => {
															setFieldValue(
																FORM_FIELDS_NAMES.ALLERGY_TYPE,
																e.target.value
															);
														}}
													/>
												</div>
											</div>
											<div className="flex ml-3">
												<div>
													<RadioButton
														label="Environment"
														name={FORM_FIELDS_NAMES.ALLERGY_TYPE}
														checked={
															values[FORM_FIELDS_NAMES.ALLERGY_TYPE] ===
															FORM_FIELDS_NAMES.ENVIRONMENT
														}
														value={FORM_FIELDS_NAMES.ENVIRONMENT}
														onChangeCb={(e) => {
															setFieldValue(
																FORM_FIELDS_NAMES.ALLERGY_TYPE,
																e.target.value
															);
														}}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="pt-6">
										<Input
											label="Allergy Name"
											isRequired={true}
											placeholder="Enter Allergy Name"
											name={FORM_FIELDS_NAMES.ALLERGY_NAME}
											value={values[FORM_FIELDS_NAMES.ALLERGY_NAME]}
											onChangeCb={(e) => {
												setFieldValue(FORM_FIELDS_NAMES.ALLERGY_NAME, e.target.value);
											}}
										/>
									</div>
									<div className="flex column-gap-10px items-center pt-6">
										<div className="flex-1">
											<Input
												label="Reaction"
												isRequired={true}
												placeholder="Enter Reaction"
												name={FORM_FIELDS_NAMES.REACTION}
												value={values[FORM_FIELDS_NAMES.REACTION]}
												onChangeCb={(e) => {
													setFieldValue(FORM_FIELDS_NAMES.REACTION, e.target.value);
												}}
											/>
										</div>
										<div className="flex-1">
											<SelectDropdown
												placeholder="Select Severity"
												label="Severity"
												isRequired={true}
												name={FORM_FIELDS_NAMES.SEVERITY}
												value={values[FORM_FIELDS_NAMES.SEVERITY]}
												onChangeCb={(val) => {
													setFieldValue(FORM_FIELDS_NAMES.SEVERITY, val);
												}}
												options={[
													{ label: 'Low', value: 'low' },
													{ label: 'Medium', value: 'medium' },
													{ label: 'High', value: 'high' },
												]}
											/>
										</div>
									</div>
									<div className="flex column-gap-10px items-center pt-6">
										<div className="flex-1">
											<DatePicker
												label="Onset Date"
												placeholder="Choose Date"
												isRequired={true}
												maxDate={new Date()}
												value={values[FORM_FIELDS_NAMES.ONSET_DATE]}
												name={FORM_FIELDS_NAMES.ONSET_DATE}
												onChangeCb={(date) => {
													setFieldValue(FORM_FIELDS_NAMES.ONSET_DATE, date);
												}}
											/>
										</div>
										<div className="flex-1">
											<div>
												<Label>Status</Label>
											</div>
											<div>
												<ToggleButton
													onChangeCb={(option) => {
														setFieldValue(FORM_FIELDS_NAMES.STATUS, option.value);
													}}
													value={values[FORM_FIELDS_NAMES.STATUS]}
													options={[
														{ label: 'Active', value: 'Active' },
														{ label: 'Inactive', value: 'Inactive' },
													]}
												/>
											</div>
										</div>
									</div>
									<div>
										<div className="mt-4">
											<Label fontWeight="bold">Note</Label>
										</div>
										<div>
											<TextArea
												placeholder="Type here"
												name={FORM_FIELDS_NAMES.NOTE}
												value={values[FORM_FIELDS_NAMES.NOTE]}
												onChangeCb={(e) => {
													setFieldValue(FORM_FIELDS_NAMES.NOTE, e.target.value);
												}}
											/>
										</div>
									</div>
								</div>
							</ModalComponent>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
}

export default AllergiesModal;

import React, { useState } from 'react';
import './styles.scss';
import Label from '../label/Label';

const VARIANTS = {
    SM: "sm",
    MD: "md",
    LG: "lg",
}

function Checkbox({ checked = false, label, name, disabled = false, onChangeCb = () => { }, variant = "md", title = "" }) {

    const getVarient = () => {
        switch (variant) {
            case VARIANTS.SM:
                return "w-4 h-4"
            case VARIANTS.MD:
                return "w-5 h-5"
            case VARIANTS.LG:
                return "w-6 h-6"
            default:
                return "w-4 h-4"
        }
    }
    return (
        <div className='flex column-gap-10px'>
            <input
                className={`cutom-radio relative  h-6 w-6  rounded-full border-2 border-gray-300 behtmlFore:pointer-events-none behtmlFore:absolute behtmlFore:h-4 behtmlFore:w-4 behtmlFore:scale-0 behtmlFore:rounded-full behtmlFore:bg-transparent behtmlFore:opacity-0 behtmlFore:shadow-[0px_0px_0px_13px_transparent] behtmlFore:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:behtmlFore:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-red checked:after:content-[''] checked:after:[transhtmlForm:translate(-50%,-50%)]  cursor-pointer`}
                type="checkbox"
                name="flexRadioDefault"
                id="radioDefault01"
                checked={checked}
                disabled={disabled}
                onChange={(e) => onChangeCb(e.target.checked)}
                title={title}
            />
            <Label fontWeight="bold">{label}</Label>
        </div>
    );
}

export default Checkbox;

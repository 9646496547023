import { all, call, put, takeLatest } from 'redux-saga/effects'

import { componentKey, setIsAddFamilyMemberModalOpen, setIsOpenFamilyMemberDeletePopup, setfamilyMembersData } from './FamilySlice'
import FamilyDataService from '../../../../services/FamilyDataService'
import store from '../../../../store/store'
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice'
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/Constants'


export const getFamilyMembers = (payload) => {
    return {
        type: 'USER_PROFILE/GET_FAMILY_MEMBERS',
        payload
    }
}

export const EditFamilyMembers = (payload) => {
    return {
        type: 'USER_PROFILE/UPDATE_FAMILY_MEMBERS',
        payload
    }
}

export const saveFamilyMembers = (payload) => {
    return {
        type: 'USER_PROFILE/POST_FAMILY_MEMBERS',
        payload
    }
}
export const deleteFamilyMembers = (payload) => {
    return {
        type: 'USER_PROFILE/DELETE_FAMILY_MEMBERS',
        payload,
    };
}



function* getFamilyMembersAsync(action) {
    try {
        const { uuid } = action.payload
        const response = yield FamilyDataService.getFamilyMembers(uuid)
        const { data, status } = response.data
        if (status) {
            yield put(setfamilyMembersData(data))
        }

    } catch (error) {
        console.log('err: ', error)
    }
}


function* updateFamilyMembersAsync(action) {
    try {
        const { uuid, data, patientId = "" } = action.payload
        const response = yield FamilyDataService.updateFamilyMembers(uuid, data, patientId)
    
        const { status, message } = response.data

        if (status) {
            yield put(setIsAddFamilyMemberModalOpen(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getFamilyMembersAsync, getFamilyMembers({ uuid: patientId }));
        }

    } catch (error) {
        console.log('err: ', error)
    }
}


function* saveFamilyMembersAsync(action) {
    try {
        const { data } = action.payload
        const response = yield FamilyDataService.postFamilyMembers(data)
        const { status } = response
        const { message } = response.data
        if (status) {
            yield put(setIsAddFamilyMemberModalOpen(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getFamilyMembersAsync, getFamilyMembers({ uuid: data.patientId }));
        }

    } catch (error) {
        console.log('err: ', error)
    }
}

function* deleteFamilyMemberAsync(action) {

    try {
        const { uuid, patientId = "" } = action.payload;
        const response = yield FamilyDataService.deleteFamilyMember(uuid, patientId);

        const { status, message } = response.data
        if (status) {
            yield put(setIsOpenFamilyMemberDeletePopup(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getFamilyMembersAsync, getFamilyMembers({ uuid: patientId }));
        }

    } catch (error) {
        console.log('err: ', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getFamilyMembers().type, getFamilyMembersAsync),
        takeLatest(EditFamilyMembers().type, updateFamilyMembersAsync),
        takeLatest(saveFamilyMembers().type, saveFamilyMembersAsync),
        takeLatest(deleteFamilyMembers().type, deleteFamilyMemberAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)

import React from 'react';
import { useSelector } from 'react-redux';
import { componentKey as PatientAllergiesComponentKey } from "../../../Patients/PatientDashboard/Allergies/PatientAllergiesSlice";
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import FaceSheetCard from '../../../Patients/PatientDashboard/Dashboard/FaceSheet/FaceSheetCard';
import Button from '../../../../components/common-components/button/Button';
import moment from 'moment';
import Icons from '../../../../components/icons/Icons';
import { MET_COLORS } from '../../../../libs/constant';

const AlleriesCard = () => {
    const { allergiesData } = useSelector((state) => state[PatientAllergiesComponentKey])

    const handleCopyClick = () => {
        let copiedText = '';
        allergiesData?.slice(0, 5).map(allergy => {
            copiedText += `${allergy.name} : ${allergy.allergyType} : ${allergy.reaction} : ${allergy.severity}\nOnset Date: ${moment(allergy.onsetDate).format('DD-MM-YYYY')}\n`
        })
        navigator.clipboard.writeText(copiedText);
    }

    return (
        <FaceSheetCard
            title="ALLERGIES"
            iconbtn={
                allergiesData?.length ? <Button
                    variant={BUTTON_VARIANTS.TEXT}
                    customBtnClass="fs-14 font-bold text-met-primary"
                    style={{ height: '19px' }}
                    onClickCb={() => handleCopyClick()}
                >
                    <Icons iconName={'copyIcon'} color={MET_COLORS.PRIMARY}></Icons>  Copy
                </Button> : ''
            }
            textColorClass={'text-met-primary'}
            customClassName="h-full"
        >
            {allergiesData?.length ? allergiesData?.slice(0, 5).map((allergy, index) => {
                return <div key={"face-sheet-card-" + index}>
                    <div className="mb-4">
                        <div className="flex items-center mb-1">
                            <div className={`fs-14`}>{`${allergy.name} : ${allergy.allergyType} : ${allergy.reaction} : ${allergy.severity}`}</div>
                        </div>
                        <div className="text-gray-500 fs-14">{`Onset Date: ${moment(allergy.onsetDate).format('DD-MM-YYYY')}`}</div>
                    </div>
                </div>

            }) : <div className='fs-14 text-gray-500'>No data found</div>}

        </FaceSheetCard>
    );
};

export default AlleriesCard;
export const SettingsLeftArrowIcon = () => {
	return (
		<svg
			id="chevron_right_black_24dp"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path id="Path_186932" data-name="Path 186932" d="M24,0H0V24H24Z" fill="none" />
			<path
				id="Path_186933"
				data-name="Path 186933"
				d="M14.59,6,16,7.41,11.42,12,16,16.59,14.59,18l-6-6Z"
				transform="translate(-0.59)"
				fill="#1b5984"
			/>
		</svg>
	);
};

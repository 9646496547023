import moment from "moment";

export const CVS_HEADERS = [
	{ label: 'First Name', key: 'firstName' },
	{ label: 'Middle Name', key: 'middleName' },
	{ label: 'Last Name', key: 'lastName' },
	{ label: 'Email', key: 'email' },
	{ label: 'Mobile Number', key: 'mobileNumber' },
	{ label: 'Phone Number', key: 'homeNumber' },
	{ label: 'Date of Birth', key: 'dateOfBirth' },
	{ label: 'Gender', key: 'gender' },
	{ label: 'Marital Status', key: 'maritalStatus' },
	{ label: 'Timezone', key: 'timezone' },
	{ label: 'Language', key: 'language' },
	{ label: 'Aadhar Number', key: 'aadhar' },
	{ label: 'Consent to Email', key: 'consentToEmail' },
	{ label: 'Consent to Call', key: 'consentToCall' },
	{ label: 'Consent to Message', key: 'consentToMessage' },
	{ label: 'Address Line 1', key: 'addressLine1' },
	{ label: 'Address Line 2', key: 'addressLine2' },
	{ label: 'City', key: 'city' },
	{ label: 'State', key: 'state' },
	{ label: 'Country', key: 'country' },
	{ label: 'Pin Code', key: 'zip' },
	{ label: 'Provider First Name', key: 'providerFirstName' },
	{ label: 'Provider Last Name', key: 'providerLastName' },
	{ label: 'Onboarding Date', key: 'registrationDate' },
];

export function bindData(array) {
	const newArray = [];

	array?.forEach((obj) => {
		const newObj = {
			id: obj.uuid,
			firstName: obj.firstName,
			middleName: obj.middleName,
			lastName: obj.lastName,
			email: obj.email,
			mobileNumber: obj.mobileNumber,
			homeNumber: obj.homeNumber,
			dateOfBirth: obj.dob ? new Date(obj.dob).toLocaleDateString() : null,
			aadhar: obj.aadhar,
			gender: obj.gender,
			maritalStatus: obj.maritalStatus,
			timezone: obj.timeZone,
			language: obj.language,
			fax: obj.faxNumber,
			consentToEmail: obj.consentToEmail,
			consentToCall: obj.consentToCall,
			consentToMessage: obj.consentToMessage,
			addressLine1: obj.Address.map((obj) => obj.addressLine1),
			addressLine2: obj.Address.map((obj) => obj.addressLine2),
			city: obj.Address.map((obj) => obj.city),
			state: obj.Address.map((obj) => obj.state),
			country: obj.Address.map((obj) => obj.country),
			zip: obj.Address.map((obj) => obj.zip),
			providerFirstName: obj.provider ? obj.provider.firstName : null,
			providerLastName: obj.provider ? obj.provider.lastName : null,
			registrationDate: obj.registrationDate ? new Date(obj.registrationDate).toLocaleDateString() : null
		};

		newArray.push(newObj);
	});

	return newArray;
}

export const CSV_FILE_HEADERS_FOR_SUPER_BILLS = [
	{ label: 'Bill Date', key: 'bilingDate' },
	{ label: 'Bill ID', key: 'billingId' },
	{ label: 'Date Of Service', key: 'dateofservice' },
	{ label: 'First Name', key: 'firstName' },
	{ label: 'Last Name', key: 'lastName' },
	{ label: 'Aadhar Number', key: 'aadhar' },
	{ label: 'Email', key: 'email' },
	{ label: 'Mobile Number', key: 'mobileNumber' },
	{ label: 'Provider First Name', key: 'providerFirstName' },
	{ label: 'Provider Last Name', key: 'providerLastName' },
	{ label: 'Total Charges', key: 'totalBill' },
	{ label: 'Patient Paid', key: 'totalAmountPaid' },
	{ label: 'GST Number', key: 'gstNumber' },
	{ label: 'GST Type', key: 'gstType' },
	{ label: 'GST Percentage', key: 'gstPercentage' },
];

export function superbillData(array) {
	const newArray = [];

	array?.forEach((obj) => {
		const newObj = {
            bilingDate: moment(obj?.createdAt).format('DD-MM-YY'),
			billingId: obj?.billing_procedural_codes[0]?.billingId,
            dateofservice: moment(obj?.dos).format('DD-MM-YY'),
			firstName: obj?.patient?.firstName,
			lastName: obj?.patient?.lastName,
			email: obj?.patient?.email,
			mobileNumber: obj?.patient?.mobileNumber,
			dateOfBirth: obj?.patient?.dob ? new Date(obj.dob).toLocaleDateString() : null,
			aadhar: obj?.patient?.aadhar,
			gender: obj?.patient?.gender,
			maritalStatus: obj?.patient?.maritalStatus,
			providerFirstName: obj?.renderingProvider?.firstName,
			providerLastName: obj.renderingProvider?.lastName,
			gstPercentage: obj?.gstPercentage,
			gstType: obj?.gstType,
			gstNumber: obj?.gstNumber,
			totalAmountPaid: obj?.totalAmountPaid,
			totalBill: obj?.totalBill,
		};

		newArray.push(newObj);
	});

	return newArray;
}

export const CSV_HEADERS_FOR_PATIENT_PAYMENTS = [
	{ label: 'Bill Date', key: 'bilingDate' },
	{ label: 'Bill ID', key: 'billingId' },
	{ label: 'Date Of Service', key: 'dos' },
	{ label: 'First Name', key: 'firstName' },
	{ label: 'Last Name', key: 'lastName' },
	{ label: 'Aadhar Number', key: 'aadhar' },
	{ label: 'Email', key: 'email' },
	{ label: 'Mobile Number', key: 'mobileNumber' },
	{ label: 'Provider First Name', key: 'providerFirstName' },
	{ label: 'Provider Last Name', key: 'providerLastName' },
	{ label: 'Method', key: 'method' },
	{ label: 'Amount', key: 'amount' },
	{ label: 'GST Number', key: 'gstNumber' },
	{ label: 'GST Type', key: 'gstType' },
	{ label: 'GST Percentage', key: 'gstPercentage' },
];


export function patientPaymentData(array) {
	const newArray = [];

	array?.forEach((obj) => {
		const newObj = {
			billingId: obj?.mappingBillingPayment?.[0]?.billingId,
            bilingDate: moment(obj?.mappingBillingPayment?.[0]?.billing?.createdAt).format('DD-MM-YY'),
            dos: moment(obj?.mappingBillingPayment?.[0]?.billing?.dos).format('DD-MM-YY'),
			firstName: obj.patient.firstName,
			lastName: obj.patient.lastName,
			email: obj.patient.email,
			mobileNumber: obj.patient.mobileNumber,
			dateOfBirth: obj.patient.dob ? new Date(obj.dob).toLocaleDateString() : null,
			aadhar: obj.patient.aadhar,
			gender: obj.patient.gender,
			maritalStatus: obj.patient.maritalStatus,
			providerFirstName: obj?.provider.firstName,
			providerLastName: obj.provider.lastName,
			method: obj.method,
			amount: obj.amount,
			gstNumber: obj?.mappingBillingPayment[0]?.billing?.gstNumber,
			totalAmountPaid: obj.mappingBillingPayment[0]?.billing.totalAmountPaid,
			totalBill: obj.mappingBillingPayment[0]?.billing.totalBill,
            gstPercentage: obj?.mappingBillingPayment[0]?.billing?.gstPercentage,
            gstType: obj?.mappingBillingPayment[0]?.billing?.gstType
		};

		newArray.push(newObj);
	});

	return newArray;
}
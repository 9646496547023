import React from 'react'

function ProvidersIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92">
            <g id="Group_72257" data-name="Group 72257" transform="translate(-636 -132)">
                <g id="Group_72252" data-name="Group 72252" transform="translate(342 -10)">
                    <g id="Group_66269" data-name="Group 66269" transform="translate(-4.332 -68.332)">
                        <circle id="Ellipse_1213" data-name="Ellipse 1213" cx="46" cy="46" r="46" transform="translate(298.332 210.332)" fill="rgba(0,151,240,0.1)" />
                    </g>
                </g>
                <g id="Group_72256" data-name="Group 72256" transform="translate(826 80)">
                    <path id="Path_28497" data-name="Path 28497" d="M0,0H56V56H0Z" transform="translate(-172 70)" fill="#1b5984" opacity="0" />
                    <g id="noun-doctor-1030145" transform="translate(-165.524 74.03)">
                        <path id="Path_28494" data-name="Path 28494" d="M135.666,326.777c.739,0,1.5,0,2.268,0h14.631c.772,0,1.528,0,2.267,0,6.77,0,11.942-.346,11.942-7.424a14.2,14.2,0,0,0-7.938-12.751,10.692,10.692,0,0,1,.78,3.83,3.464,3.464,0,1,1-1.6.014c-.066-1.48-.42-3.731-1.867-4.841a14.23,14.23,0,0,0-2.278-.4c-.058,2.591-8.624,8.987-8.624,8.987s-8.567-6.394-8.625-8.986a14.075,14.075,0,0,0-2.521.465c-1.056.837-1.6,2.394-1.631,4.659a1.753,1.753,0,0,1,.715.795A6.618,6.618,0,0,1,136.1,314.4a1.141,1.141,0,0,1,.051.856,10.319,10.319,0,0,1,.725,3.575c0,1.527,0,2.971-1.665,3.342a1,1,0,0,1-.643.232H133.46a1.013,1.013,0,0,1-1.012-1.011l0-.071a1.019,1.019,0,0,1,1.009-.941h1.112a1.006,1.006,0,0,1,.333.057.322.322,0,0,0,.088-.035,5.856,5.856,0,0,0,.124-1.573,8.475,8.475,0,0,0-.61-2.948,1.136,1.136,0,0,1-.464-.5c-.675-1.412-1.592-2.324-2.338-2.324-.762,0-1.725.986-2.4,2.452a1.146,1.146,0,0,1-.539.55,8.268,8.268,0,0,0-.543,2.766,5.466,5.466,0,0,0,.141,1.577.557.557,0,0,0,.121.041,1.011,1.011,0,0,1,.363-.067h1.114a1.012,1.012,0,0,1,1,.894l.006.011,0,.067a1.022,1.022,0,0,1-1.011,1.052h-1.114a1,1,0,0,1-.611-.208,1.928,1.928,0,0,1-1.37-.857,4.742,4.742,0,0,1-.406-2.509,10.167,10.167,0,0,1,.7-3.51,1.122,1.122,0,0,1,.066-.758,8.441,8.441,0,0,1,1.734-2.553,4.8,4.8,0,0,1,1.166-.839,1.774,1.774,0,0,1,.745-.839,10.225,10.225,0,0,1,.639-3.656,14.206,14.206,0,0,0-7.79,12.679c0,7.077,5.17,7.423,11.94,7.423Zm13.313-7.924a.354.354,0,0,1,.372-.333h2.025V316.5a.354.354,0,0,1,.333-.373h2a.355.355,0,0,1,.333.373v2.024h2.025a.354.354,0,0,1,.372.333v2a.354.354,0,0,1-.372.333H154.04v2.024a.355.355,0,0,1-.333.373h-2a.354.354,0,0,1-.333-.373v-2.024h-2.025a.354.354,0,0,1-.372-.333Z" transform="translate(-123.728 -278.836)" fill="#1b5984" />
                        <path id="Path_28495" data-name="Path 28495" d="M225.51,39.845c0,6.543,5.3,16.382,11.845,16.382S249.2,46.388,249.2,39.845a11.846,11.846,0,0,0-23.693,0Z" transform="translate(-215.828 -28)" fill="#1b5984" />
                        <path id="Path_28496" data-name="Path 28496" d="M479.314,380.427a1.6,1.6,0,1,1-1.6-1.6,1.6,1.6,0,0,1,1.6,1.6" transform="translate(-442.598 -345.458)" fill="#1b5984" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default ProvidersIcon
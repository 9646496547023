import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../components/common-components/button/Button'
import Icons from '../../../../components/icons/Icons'
import Label from '../../../../components/common-components/label/Label'
import DatePicker from '../../../../components/common-components/datePicker'
import Input from '../../../../components/common-components/input/Input'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import TextArea from '../../../../components/common-components/textArea/TextArea'
import { Form, Formik } from 'formik'
import { getValidationSchema } from '../../../../libs/formsUtils'
import { PAST_MEDICAL_FORM_FIELDS_NAMES } from './Constants'
import { componentKey as PatientHistoryComponentKey } from '../History/PatientHistorySlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import {
    postPatientHistory, updatePatientHistory,
} from '../History/PatientHistorySaga';

const fields = [
    { fieldName: PAST_MEDICAL_FORM_FIELDS_NAMES.CONDITION_NAME, isRequired: true },
    { fieldName: PAST_MEDICAL_FORM_FIELDS_NAMES.ONNSET_DATE, isRequired: true },

];

const validationSchema = getValidationSchema(fields)

function PastMedicalHistoryModal({ open, close }) {

    const dispatch = useDispatch();
    const { isMedicalHistoryModalOpen, medicalHistoryData, currentMedicalHistoryId, isNewMedicalHistoryAdd } = useSelector(
        (state) => state[PatientHistoryComponentKey]
    );

    const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);

    return (
        <Formik
            initialValues={{
                [PAST_MEDICAL_FORM_FIELDS_NAMES.CONDITION_NAME]: medicalHistoryData?.conditionName,
                [PAST_MEDICAL_FORM_FIELDS_NAMES.ONNSET_DATE]: medicalHistoryData?.onsetDate,
                [PAST_MEDICAL_FORM_FIELDS_NAMES.NOTE]: medicalHistoryData?.note
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(formVals) => {
                try {
                    if (isNewMedicalHistoryAdd) {
                        dispatch(postPatientHistory({ patientId: currentPatientId, historyData: formVals, historyType: 'pmh' }));
                    } else {
                        dispatch(
                            updatePatientHistory({
                                patientId: currentPatientId,
                                historyId: currentMedicalHistoryId,
                                historyData: formVals,
                                historyType: 'pmh'
                            })
                        );
                    }
                } catch (error) {
                    console.log(error);
                }
            }}
        >
            {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                return <Form onSubmit={handleSubmit} >

                    <ModalComponent
                        open={isMedicalHistoryModalOpen}
                        title={isNewMedicalHistoryAdd ? 'Add Past Medical History' : 'Update Past Medical History'}
                        footerButton={<Button variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary" type='submit'>Save</Button>}
                        icon={<Icons iconName="closeIcon" />}
                        close={close}
                        customClasses="w-[594px]">
                        <div className='p-2'>
                            <div>
                                <div className='pt-6'>
                                    <Label fontWeight='bold' isRequired>Condition Name</Label>
                                </div>
                                <div>
                                    <Input
                                        placeholder={"Enter Condition Name"}
                                        name={PAST_MEDICAL_FORM_FIELDS_NAMES.CONDITION_NAME}
                                        value={values[PAST_MEDICAL_FORM_FIELDS_NAMES.CONDITION_NAME]}
                                        onChangeCb={(e) => { setFieldValue(PAST_MEDICAL_FORM_FIELDS_NAMES.CONDITION_NAME, e.target.value) }}
                                        onBlurCb={handleBlur}
                                    />
                                </div>
                            </div>
                            <div className='flex-1 min-w-[280px] max-w-[280px] pt-6'>
                                <div className='flex-1'>
                                    <DatePicker
                                        label="Onset Date"
                                        placeholder='Choose Date'
                                        name={PAST_MEDICAL_FORM_FIELDS_NAMES.ONNSET_DATE}
                                        maxDate={new Date()}
                                        isRequired={true}
                                        value={values[PAST_MEDICAL_FORM_FIELDS_NAMES.ONNSET_DATE]}
                                        onChangeCb={(date) => {
                                            setFieldValue(PAST_MEDICAL_FORM_FIELDS_NAMES.ONNSET_DATE, date)
                                        }}
                                    />
                                </div>
                            </div>
                            <div >
                                <div className='min-w-[280px] max-w-[280px] pt-6'>
                                    <Label fontWeight='bold'>Note</Label>
                                </div>
                                <div className='mt-2'>
                                    <TextArea
                                        name={PAST_MEDICAL_FORM_FIELDS_NAMES.NOTE}
                                        value={values[PAST_MEDICAL_FORM_FIELDS_NAMES.NOTE]}
                                        onChangeCb={(e) => { setFieldValue(PAST_MEDICAL_FORM_FIELDS_NAMES.NOTE, e.target.value) }} />
                                </div>
                            </div>
                        </div>
                    </ModalComponent>
                </Form>
            }}
        </Formik>

    )
}

export default PastMedicalHistoryModal
import AppDataService from './AppDataService'

const COMMON_BASE = 'department'

export default class DepartmentDataService {

    static async getAllDepartments() {
        return await AppDataService.get(`${COMMON_BASE}`)
    }
    static async postDepartment(data){
        return await AppDataService.post(`${COMMON_BASE}`,data)
    }
    static async patchDepartment(departmentId, data){
        return await AppDataService.patch(`${COMMON_BASE}/${departmentId}`, data)
    }
}



import { all, put, takeLatest } from 'redux-saga/effects'
import store from '../../../store/store'
import { componentKey, setPaginationState, setPatientGridListState } from './PatientGridListSlice'
import PatientDataService from '../../../services/PatientDataServices'

export const { getPatientGridList } = {
    getPatientGridList: (payload) => {
        return {
            type: 'HOME/ACTION_EXAMPLE',
            payload
        }
    },
}

function* getPatientGridListAsync(action) {
    try {
        const paginationState = action.payload
        const params = {
            pageNumber: paginationState.pageNumber,
            limit: paginationState.limit,
            search: paginationState.search,
            status: paginationState.status,
            sortBy: paginationState.sortBy,
            orderBy: paginationState.orderBy,
        }
        const response = yield PatientDataService.getPatientGridList({ params })

        const { data, message, status } = response.data
        if (status) {
            const { patients, totalPages, totalRecords } = data || {}
            yield put(setPatientGridListState(patients))
            yield put(setPaginationState({ total: totalRecords }))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getPatientGridList().type, getPatientGridListAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
import React, { forwardRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createDeviceTest, createVitalsTest, getAllDeviceNames, getDeviceTestList } from '../../../pages/Patients/AddPatient/AddPatientSaga'
import { ErrorMessage, Form, Formik } from 'formik'
import { VITALS_FIELDS_NAMES } from './Constants'
import { getValidationSchema } from '../../../libs/formsUtils'
import SelectDropdown from '../../../components/common-components/selectDropdown'
import { componentKey } from '../../../pages/Patients/AddPatient/AddPatientSlice'
import Label from '../../../components/common-components/label/Label'
import Icons from '../../../components/icons/Icons'
import Button from '../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants'
import { addNotifications } from '../../../components/common-components/toaster/ToasterSlice'
import { TOASTER_VARIANT } from '../../../components/common-components/toaster/MetToaster'
import FaceSheetCard from '../../../pages/Patients/PatientDashboard/Dashboard/FaceSheet/FaceSheetCard'
import { useParams } from 'react-router-dom'
import { getPatientVitalsTest } from './PatientVitalsSaga'
import { componentKey as patientVitalsComponentKey } from './PatientVitalsSlice'
import useGarbageCollector from '../../../libs/garbagecollector/garbageCollectorHooks'
import * as Yup from 'yup'
import { updatePendingVitalTest } from '../../../pages/Patients/PatientDashboard/VitalsTest/VitalsTestSaga'
import General from '../../../libs/utility/General'

const VitalsTest = forwardRef(function VitalsTest({ isAddVitalTest = false, editTestsData = {} }, formRef) {
    useGarbageCollector(patientVitalsComponentKey)
    const { patientId } = useParams();
    const dispatch = useDispatch()
    const [deviceSelected, setDeviceSelected] = useState(false);
    const [selectedDevice, setselectedDevice] = useState({})
    const { deviceNamesList, deviceTestList } = useSelector(state => state[componentKey])
    const { patientvitalsTestDetails } = useSelector(state => state[patientVitalsComponentKey])

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        const isEditModal = editTestsData?.test?.length > 0
        setIsEdit(isEditModal)
    }, [editTestsData])

    useEffect(() => {
        dispatch(getAllDeviceNames())
    }, [dispatch])

    useEffect(() => {
        if (selectedDevice.value) {
            dispatch(getDeviceTestList({ deviceName: (selectedDevice?.label || "").trim() }))
        }
    }, [selectedDevice, dispatch])

    useEffect(() => {
        if (isAddVitalTest) return
        const params = {
            patientId: patientId
        }

        dispatch(getPatientVitalsTest({ params }))
    }, [patientId, dispatch, isAddVitalTest]);

    const initialValues = {
        tests: isEdit && editTestsData?.test?.length ? General.addLableValuePair(editTestsData?.test, "testName", "uuid") : patientvitalsTestDetails ? patientvitalsTestDetails : [],
        [VITALS_FIELDS_NAMES.DEVICE_NAME]: selectedDevice,
        [VITALS_FIELDS_NAMES.TEST_NAME]: "",
    };

    const validationSchema = Yup.object().shape({
        tests: Yup.array()
            .min(1, 'There must be at least one test.'),
    });

    const addNewDevice = (values, setFieldValue, selectVal) => {
        setFieldValue("tests", selectVal);
    };

    const handleRemove = (index, values, setFieldValue, tests) => {
        const clinicalCenterClone = [...values[tests]];
        clinicalCenterClone.splice(index, 1);
        setFieldValue(tests, clinicalCenterClone);
    };

    const message = "Test Already Selected";

    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    if (patientvitalsTestDetails?.length) return
                    const payload = {
                        test: values.tests && Array.isArray(values.tests) ?
                            values.tests.map(test => ({
                                charge: test.charge,
                                uuid: test.uuid,
                                deviceName: test.deviceName,
                                testName: test.testName,
                            }))
                            : [],
                        status: 'Pending'
                    };
                    if (isEdit) {
                        return dispatch(updatePendingVitalTest({ vitalTestId: editTestsData.uuid, payload, patientId }))
                    }
                    dispatch(createVitalsTest({ patientId, data: payload }))
                }}
            >
                {({ handleSubmit, values, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className='custom-provider-information p-4 mt-4 mb-4 bg-opacity-3 rounded-md'>
                            <div className='flex column-gap-10px'>
                                <div className='w-1/4 min-w-410 max-w-450'>
                                    <SelectDropdown
                                        label='Select Device'
                                        placeholder="Select Device"
                                        isRequired={true}
                                        name={VITALS_FIELDS_NAMES.DEVICE_NAME}
                                        value={values[VITALS_FIELDS_NAMES.DEVICE_NAME]}
                                        onChangeCb={(val) => {
                                            setFieldValue(VITALS_FIELDS_NAMES.DEVICE_NAME, val)
                                            setDeviceSelected(val !== '');
                                            setselectedDevice(val)
                                        }}
                                        options={deviceNamesList}
                                        disabled={patientvitalsTestDetails?.length}
                                    />
                                </div>
                                <div className={`w-1/4 min-w-410 max-w-450 ${!deviceSelected && 'hidden'}`}>
                                    <div className='w-1/4 min-w-410 max-w-450'>
                                        <SelectDropdown
                                            label='Select Test'
                                            name={VITALS_FIELDS_NAMES.TEST_NAME}
                                            value={values?.tests?.length ? values?.tests?.map(test => test.value) : []}
                                            placeholder='Select Test'
                                            isRequired={true}
                                            onChangeCb={(selectedOpt) => {
                                                addNewDevice(values, setFieldValue, selectedOpt,)
                                            }}
                                            isMultiSelect={true}
                                            multiSelectAll={true}
                                            options={deviceTestList}
                                            disabled={patientvitalsTestDetails?.length}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="flex pt-3 flex-wrap column-gap-20px overflow-auto max-h-[500px] met-scrollbar">
                                    {values?.tests.length ? values?.tests?.map((test, index) => (
                                        <div className='cursor-pointer pb-3' key={index}>
                                            <FaceSheetCard
                                                paddingTopClass={0}
                                            >
                                                <div key={"VitalTest" + index} className='flex column-gap-20px'>
                                                    <div className='flex items-center column-gap-10px'>
                                                        <Icons iconName={"vitalsIcon"} color={'#1B5984'}></Icons>
                                                        <Label fontSize="md" color={"gray-500"}>{test?.testName}</Label>
                                                    </div>
                                                    <div className='flex'>
                                                        <Button variant={BUTTON_VARIANTS.TEXT}
                                                            customBtnClass="text-red-500 p-0"
                                                            startIcon={{ icon: <Icons iconName="closeIcon" color={'red'} /> }} onClickCb={() => handleRemove(index, values, setFieldValue, 'tests')}
                                                            disabled={patientvitalsTestDetails?.length}
                                                        ></Button>
                                                    </div>
                                                </div>
                                            </FaceSheetCard>
                                        </div>
                                    )) : null}
                                </div>
                            </div>
                            {VITALS_FIELDS_NAMES.TESTS && (
                                <div className="mt-1">
                                    <ErrorMessage name={VITALS_FIELDS_NAMES.TESTS} component="div" className="error-text" />
                                </div>
                            )}
                        </div>
                    </Form>
                )}
            </Formik >
        </>
    )
})

export default VitalsTest
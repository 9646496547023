import store from '../../../store/store';
import { all, put, takeLatest } from 'redux-saga/effects';
import CommmonDataService from '../../../services/CommonDataService';
import { componentKey, setAllProviderGroupLocation, setIndianStates, setProvidersList, setUsersRolesAndResp } from './DataloaderSlice';
import General from '../../../libs/utility/General';
import ProvidersDataService from '../../../services/ProvidersDataService';
import RolesAndResponsibilitiesDataService from '../../../services/RolesAndResponsibilitiesService';

export const { getStateList, rolesAndPermissionOfUser, getProvidersList,getAllProviderGroupLocations } = {
	getStateList: (payload) => {
		return {
			type: 'STATES/ACTION_EXAMPLE',
			payload,
		};
	},
	getProvidersList: (payload) => {
		return {
			type: 'PROVIDERS_LIST',
			payload,
		};
	},
	rolesAndPermissionOfUser: (payload) => {
		return {
			type: 'GET/ROLES_AND_RESP',
			payload,
		};
	},
	getAllProviderGroupLocations: (payload) => {
		return {
			type: 'GET/GET_PROVIDER_GROUP_LOCATIONS',
			payload,
		};
	},
};

function* getStateListAsync(action) {
	try {
		const response = yield CommmonDataService.getStateList(action.payload);

		const { data } = response || [];
		const states = General.addLableValuePair(data, 'name', 'isoCode');
		yield put(setIndianStates(states));
	} catch (error) {
		console.log('err: ', error);
	}
}

function* providersListAsync(action) {
	try {
		const response = yield ProvidersDataService.getProvidersList();
		const { data, status } = response.data;

		if (status) {
			const providerlist = General.addLableValuePair(data, 'firstName', 'uuid', 'lastName');
			yield put(setProvidersList(providerlist));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* rolesAndPermissionOfUserAsync(action) {
	try {
		const response = yield RolesAndResponsibilitiesDataService.rolesAndPermissionOfUser(action.payload);
		const permissions = {};
		response?.data?.data?.rolesPermissionsMapping.forEach((obj) => {
			const { permissionKey } = obj.permissions;
			permissions[permissionKey] = obj.enable;
		});
		yield put(setUsersRolesAndResp(permissions));
	} catch (error) {
		console.log('err', error);
	}
}
function* getAllProviderGroupLocationsAsync(action) {
	try {
		const response = yield ProvidersDataService.getAllProviderLocation(action.payload);
		const { data, status } = response.data;

		if (status) {
			const { locations } = data || [];
			yield put(setAllProviderGroupLocation(locations));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* rootSaga() {
	yield all([
		takeLatest(getStateList().type, getStateListAsync),
		takeLatest(getProvidersList().type, providersListAsync),
		takeLatest(rolesAndPermissionOfUser().type, rolesAndPermissionOfUserAsync),
		takeLatest(getAllProviderGroupLocations().type, getAllProviderGroupLocationsAsync),

	]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

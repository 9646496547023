import { useEffect, useRef, useCallback } from 'react';
export function useParticipantsChange(zmClient, fn = () => { }) {
    const fnRef = useRef(fn);

    fnRef.current = fn;

    const callback = useCallback((data) => {
        const participants = zmClient.getAllUser();
        fnRef.current?.(participants, data);
    }, [zmClient]);

    useEffect(() => {
        zmClient.on('user-added', callback);
        zmClient.on('user-updated', callback);
        zmClient.on('user-removed', callback);
        return () => {
            zmClient.off('user-added', callback);
            zmClient.off('user-updated', callback);
            zmClient.off('user-removed', callback);
        };
    }, [zmClient, callback]);
}

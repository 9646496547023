import store from "../../../store/store"
export const componentKey = 'PATIENT_VITALS'

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setPatientVitalsTestDetails: (state, action) => {
            state.patientvitalsTestDetails = action.payload;
        }
    },
    initialReducerState: {
        patientvitalsTestDetails: []
    }
})

export const { setPatientVitalsTestDetails } = actions
import React from 'react'
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import Input from '../../../../../components/common-components/input/Input';
import Icons from '../../../../../components/icons/Icons';
import { Form, Formik } from 'formik';
import { getValidationSchema } from '../../../../../libs/formsUtils';
import { VISIT_NOTES_FIELDS } from './Constants';
import { postTemplateVisitNotes } from './VisitNotesSaga';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey } from './VisitNotesSlice';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import useGarbageCollector from '../../../../../libs/garbagecollector/garbageCollectorHooks';
import { useNavigate } from 'react-router-dom';

const fields = [
  { fieldName: VISIT_NOTES_FIELDS.FORM_NAME, isRequired: true },
  { fieldName: VISIT_NOTES_FIELDS.TYPE },
  { fieldName: VISIT_NOTES_FIELDS.SPECIALITY },
  { fieldName: VISIT_NOTES_FIELDS.FORM_TYPE, isDropdown: true },

];

const validationSchema = getValidationSchema(fields)

function VisitNoteModal({ close }) {
  useGarbageCollector(componentKey)
  const { visitNoteFormState, isVisitNoteModalOpen } = useSelector(((state) => state[componentKey]))
  const initialValues = {
    [VISIT_NOTES_FIELDS.FORM_NAME]: "",
    [VISIT_NOTES_FIELDS.TYPE]: "",
    [VISIT_NOTES_FIELDS.SPECIALITY]: "",
    [VISIT_NOTES_FIELDS.FORM_TYPE]: {},
  }
  const dispatch = useDispatch()

  const navigate = useNavigate();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        dispatch(postTemplateVisitNotes({ payload: { ...values, data: visitNoteFormState, formType: values.formType.value, }, navigate: navigate }));
        close();
      }}
    >
      {({ submitForm, isSubmitting, handleReset, values, handleSubmit, setFieldValue, handleBlur, ...props }) => {
        return <Form onSubmit={handleSubmit} >
          <div>
            <ModalComponent
              open={isVisitNoteModalOpen}
              title={'Save Visit Note'}
              footerButton={<Button type='submit' variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary">Save</Button>}
              icon={<Icons iconName="closeIcon" />}
              close={close}
              hideOverflow={true}
              customClasses="w-[800px] overflow-visible">
              <div className='p-2'>
                <div className='flex column-gap-10px items-center pt-6'>
                  <div className='flex-1'>
                    <Input
                      label="Form Name"
                      placeholder="Enter Form Name"
                      isRequired={true}
                      name={VISIT_NOTES_FIELDS.FORM_NAME}
                      value={values[VISIT_NOTES_FIELDS.FORM_NAME]}
                      onChangeCb={(e) => { setFieldValue(VISIT_NOTES_FIELDS.FORM_NAME, e.target.value) }}
                      onBlurCb={handleBlur}
                    />
                  </div>
                  <div className='flex-1'>
                    <Input
                      label="Type"
                      placeholder="Enter Type"
                      name={VISIT_NOTES_FIELDS.TYPE}
                      value={values[VISIT_NOTES_FIELDS.TYPE]}
                      onChangeCb={(e) => { setFieldValue(VISIT_NOTES_FIELDS.TYPE, e.target.value) }}
                      onBlurCb={handleBlur}
                    />
                  </div>
                </div>
                <div className='flex column-gap-10px items-center pt-6'>
                  <div className='flex-1'>
                    <div>
                      <Input
                        label='Speciality'
                        placeholder="Enter Speciality"
                        name={VISIT_NOTES_FIELDS.SPECIALITY}
                        value={values[VISIT_NOTES_FIELDS.SPECIALITY]}
                        onChangeCb={(e) => { setFieldValue(VISIT_NOTES_FIELDS.SPECIALITY, e.target.value) }}
                        onBlurCb={handleBlur}
                      />
                    </div>
                  </div>
                  <div className='flex-1'>
                    <div>
                      <SelectDropdown
                        placeholder='Select'
                        label='Form Type'
                        isRequired={true}
                        name={VISIT_NOTES_FIELDS.FORM_TYPE}
                        value={values[VISIT_NOTES_FIELDS.FORM_TYPE]}
                        onChangeCb={(val) => { setFieldValue(VISIT_NOTES_FIELDS.FORM_TYPE, val) }}
                        options={[
                          { label: 'Visit Form', value: 'visitForm' },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ModalComponent>
          </div>
        </Form>
      }}
    </Formik >
  )
}

export default VisitNoteModal
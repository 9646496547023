import React, { useEffect, useRef } from 'react';
import styles from './Popup.styles';
import Heading from '../heading/Heading';
import { HEADING } from '../heading/constants/constants';
import Icons from '../icon/Icon';
import { VEC_ICON_NAME } from '../icon/constants';

export default function Popup({ title, isPopupOpen = false, onCloseCb = () => { }, showClose = true, children, customPopupStyles = {}, childrenStyles = {}, overlayStyles = {}, showHeader = true, customPopupHeaderStyles = {} }) {

    const bodyRef = useRef(document.querySelector('body'));
    useEffect(() => {
        const updatePageScroll = () => {
            if (isPopupOpen) {
                bodyRef.current.style.overflow = 'hidden';
            } else {
                bodyRef.current.style.overflow = '';
            }
        };
        updatePageScroll();
    }, [isPopupOpen]);

    return (
        <div
            style={{
                ...styles.overlay,
                visibility: isPopupOpen ? "visible" : "hidden",
                opacity: isPopupOpen ? "1" : "0",
                ...overlayStyles
            }}
        >
            <div style={{ ...styles.popup, ...customPopupStyles }}>
                {showHeader &&
                    <div style={{ ...styles.popupHeader, ...customPopupHeaderStyles }}>
                        <Heading type={HEADING.H3} style={styles.popupHeaderTitle}>{title}</Heading>
                        {showClose ? <Icons onClickCb={onCloseCb} familyName={'vec'} iconName={VEC_ICON_NAME.CLOSE_ICON} style={styles.icon} /> : null}
                    </div>}
                <div style={{ ...styles.content, ...childrenStyles }}>
                    {children}
                </div>
            </div>
        </div>
    )
}
import React from 'react'
import Button from '../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../components/common-components/button/Constants'
import { MET_COLORS } from '../../libs/constant'
import ButtonDropdown from '../../components/common-components/buttonDropdown'
import { useNavigate } from 'react-router-dom'

const Sidebar = ({ options, onClickCb = () => { }, customBtnClass = "", checkIsActivePath = () => { } }) => {
    const navigate = useNavigate()
    return (
        <>
            {options.map((option, index) => {
                const isActivePath = checkIsActivePath(option) || ""
                return (<React.Fragment key={"sidebar-option-" + index}>
                    {option.children ? (
                        <ButtonDropdown customBtnClass='text-gray-700' label={option.label} options={option.children} selectCb={(selectedOpt) => { navigate(selectedOpt.path) }} />
                    ) : option.label ?
                        (<Button
                            customBtnClass={`${option.suffixIcon ? "justify-between" : ""} ${isActivePath ? "bg-met-secondary py-2 w-75 text-met-primary m-1 " : "py-2 w-75 text-gray-700 m-1"} ${customBtnClass}`}
                            startIcon={{ icon: option.prefixIcon, ...(isActivePath && { color: MET_COLORS.PRIMARY }) }}
                            onClickCb={() => onClickCb(option)}
                            endIcon={option.suffixIcon}
                            variant={BUTTON_VARIANTS.TEXT}
                        >
                            {option.label}
                        </Button>
                        ) : null}
                </React.Fragment>)
            })}
        </>
    )
}

export default Sidebar
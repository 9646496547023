import React, { useEffect, useMemo } from 'react';
import profileDetailsImage from '../../../../../assets/images/profileDetailsImage.png';
import Label from '../../../../../components/common-components/label/Label';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey } from './ProfileSlice';
import { getAllProviderGroupLocations, getProviderGroupDetails } from './ProfileSaga';
import Avatar from '../../../../../components/common-components/avatar';
import moment from 'moment';
import General from '../../../../../libs/utility/General';

const Profile = () => {
	const { providerGroupDetails } = useSelector((state) => state[componentKey]);
	const { providerGroupSpecialities } = providerGroupDetails || {};

	const specialitiesNames = useMemo(() => {
		if (!providerGroupSpecialities) {
			return [];
		}
		return providerGroupSpecialities.map((item) => item.speciality.name);
	}, [providerGroupSpecialities]);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getProviderGroupDetails());
		dispatch(getAllProviderGroupLocations());
	}, [dispatch]);

	const PROFILE_INFO_LABELS = useMemo(() => {
		const { email, phone, mlc, website } = providerGroupDetails;
		const { physicalAddress } = providerGroupDetails;
		const { address_line_1, address_line_2, city, state, country, zip } = physicalAddress || {};
		const address = {
			address_line_1: address_line_1,
			address_line_2: address_line_2,
			city: city,
			zip: zip,
			state: state,
			country: country,
		};
		const formatAddress = (address) => {
			const { address_line_1, address_line_2, city, zip, state, country } = address;
			return [address_line_1, address_line_2, city, zip, state, country].filter(Boolean).join(', ');
		};
		return {
			MLC: mlc,
			Website: website || '',
			'Contact Number': phone,
			Email: email,
			'Physical Address': formatAddress(address),
			// 'Physical Address': `${address_line_1}, ${address_line_2}, ${city}, ${zip}, ${state}, ${country}`,
		};
	}, [providerGroupDetails]);

	const BASIC_INFORMATION_LABELS = useMemo(() => {
		const { fax, description, billingAddress, workingHours } = providerGroupDetails || {};
		const { address_line_1, address_line_2, city, state, country, zip } = billingAddress || {};
		const address = {
			address_line_1: address_line_1,
			address_line_2: address_line_2,
			city: city,
			zip: zip,
			state: state,
			country: country,
		};
		const formatAddress = (address) => {
			const { address_line_1, address_line_2, city, zip, state, country } = address;
			return [address_line_1, address_line_2, city, zip, state, country].filter(Boolean).join(', ');
		};
		return {
			'Fax Number': fax || '',
			// 'Billing Address': `${address_line_1}, ${address_line_2}, ${city}, ${zip}, ${state}, ${country}`,
			'Billing Address': formatAddress(address),
			'Provider Group Office Hours': workingHours?.length ? workingHours : [],
			Information: description || '',
		};
	}, [providerGroupDetails]);

	const getExtraInfo = (key) => {
		if (typeof BASIC_INFORMATION_LABELS[key] === 'object') {
			return (
				<div className="text-rgba-1a1a1acc">
					{BASIC_INFORMATION_LABELS[key].map((item, index) => (
						<ol className="mt-5 flex" key={'basic-information-working-hour-' + index}>
							<div className="w-[30%]">{General.capitalizeFirstLetter(item?.dayOfWeek)}</div>
							<div className="ml-2 text-met-light-grey">{moment(item?.openingTime,"HH:mm").format("hh:mm A")}</div>
							{item?.openingTime && <div className="ml-2  text-met-light-grey">-</div>}
							<div className="ml-2 text-met-light-grey"> {moment(item?.closingTime,"HH:mm").format("hh:mm A")}</div>
						</ol>
					))}
				</div>
			);
		} else {
			return BASIC_INFORMATION_LABELS[key];
		}
	};
	return (
		<div className="profile-card-height">
			<div className="flex justify-between">
				<div
					style={{ borderRadius: '10px', border: '1px solid #1A1A1A33' }}
					className="bg-white w-1/3 h-full p-6"
				>
					<div className="flex flex-col">
						<div className="flex flex-col">
							<Avatar
								customClass="w-[100px] h-[100px] overflow-hidden object-cover rounded-full"
								url={providerGroupDetails?.path}
								// name={`${item.firstName} ${item.lastName}`}
							/>

							<p className="mt-4 text-2xl text-rgba-1A1A1A font-bold">{providerGroupDetails.groupName}</p>
							<div className="pt-4 flex column-gap-16 flex-wrap row-gap-10px">
								{specialitiesNames?.length > 1 ? (
									<>
										<p className="font-semibold p-1 inline-block  bg-rgba-CCECFF4D text-met-primary">
											Multispecialist
										</p>
									</>
								) : specialitiesNames?.length === 1 ? (
									<>
										{specialitiesNames?.map((name, index) => (
											<p
												key={'provider-specialities-index' + index}
												className="inline-block bg-rgba-CCECFF4D px-4 py-2 rounded-md font-semibold text-met-primary"
											>
												{name}
											</p>
										))}
									</>
								) : null}
							</div>
						</div>
						<div className="flex flex-col">
							{Object.keys(PROFILE_INFO_LABELS).map((key) => (
								<div key={key} className="flex mt-7">
									<div className="w-1/3 text-met-light-grey font-bold">{key}</div>
									<div className="w-2/3 text-rgba-1a1a1acc font-bold">{PROFILE_INFO_LABELS[key]}</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="flex-1 ml-3">
					<div style={{ borderRadius: '10px', border: '1px solid #1A1A1A33' }} className="bg-white p-6">
						<p className=" text-[24px] text-met-primary font-bold">Basic Information</p>
						<div className="flex">
							<div className="">
								{Object.keys(BASIC_INFORMATION_LABELS).map((key) => (
									<div key={key} className="flex mt-7 w-full">
										<div className="w-[30%] text-met-light-grey font-bold">{key}</div>
										<div className="w-[70%] text-rgba-1a1a1acc font-bold">{getExtraInfo(key)}</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Profile;

import React from 'react';

const LanguageGlobIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="language_black_24dp_1_"
			data-name="language_black_24dp (1)"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path id="Path_186595" data-name="Path 186595" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_186596"
				data-name="Path 186596"
				d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2Zm6.93,6H15.97a15.649,15.649,0,0,0-1.38-3.56A8.03,8.03,0,0,1,18.92,8ZM12,4.04A14.087,14.087,0,0,1,13.91,8H10.09A14.087,14.087,0,0,1,12,4.04ZM4.26,14a7.822,7.822,0,0,1,0-4H7.64a16.515,16.515,0,0,0-.14,2,16.515,16.515,0,0,0,.14,2Zm.82,2H8.03a15.649,15.649,0,0,0,1.38,3.56A7.987,7.987,0,0,1,5.08,16ZM8.03,8H5.08A7.987,7.987,0,0,1,9.41,4.44,15.649,15.649,0,0,0,8.03,8ZM12,19.96A14.087,14.087,0,0,1,10.09,16h3.82A14.087,14.087,0,0,1,12,19.96ZM14.34,14H9.66a14.713,14.713,0,0,1-.16-2,14.585,14.585,0,0,1,.16-2h4.68a14.585,14.585,0,0,1,.16,2A14.713,14.713,0,0,1,14.34,14Zm.25,5.56A15.649,15.649,0,0,0,15.97,16h2.95A8.03,8.03,0,0,1,14.59,19.56ZM16.36,14a16.515,16.515,0,0,0,.14-2,16.515,16.515,0,0,0-.14-2h3.38a7.822,7.822,0,0,1,0,4Z"
				fill="#1b5984"
			/>
			<g id="Group_88214" data-name="Group 88214" transform="translate(-13618 9177)">
				<circle
					id="Ellipse_1274"
					data-name="Ellipse 1274"
					cx="7"
					cy="7"
					r="7"
					transform="translate(13628 -9167)"
					fill="#fff"
				/>
				<g id="schedule_black_24dp_4_" data-name="schedule_black_24dp (4)" transform="translate(13628 -9167)">
					<path id="Path_205413" data-name="Path 205413" d="M0,0H14V14H0Z" fill="rgba(255,255,255,0)" />
					<path
						id="Path_205414"
						data-name="Path 205414"
						d="M7.848,2a5.854,5.854,0,1,0,5.86,5.854A5.851,5.851,0,0,0,7.848,2Zm.006,10.537a4.683,4.683,0,1,1,4.683-4.683A4.682,4.682,0,0,1,7.854,12.537Zm.293-7.61H7.269V8.44h.878Z"
						transform="translate(14.854 -0.854) rotate(90)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.4"
					/>
				</g>
			</g>
		</svg>
	);
};
export default LanguageGlobIcon;

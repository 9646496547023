import React, { useState } from 'react'
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants'
import Label from '../../../../../components/common-components/label/Label'
import Input from '../../../../../components/common-components/input/Input'
import Checkbox from '../../../../../components/common-components/checkbox/Checkbox'
import SelectDropdown from '../../../../../components/common-components/selectDropdown'
import Upload from '../../../../../components/common-components/upload/Upload'
import Icons from '../../../../../components/icons/Icons'
import MetTextEditor from '../../../../../components/common-components/metTextEditor'
import { Form, Formik } from 'formik'
import General from '../../../../../libs/utility/General'
import { useDispatch, useSelector } from 'react-redux'
import { postPrintConfig, updatePrintConfig } from './PrintConfigSaga'
import { componentKey, setIsOpenPrintConfigModal } from './PrintConfigSlice'
import PrintConfigCardItem from './PrintConfigCard'
import { getValidationSchema } from '../../../../../libs/formsUtils'

const FORM_FIELDS_NAMES = {
    HEADER_NAME: 'headerName',
    HEADER_CONTENT: 'headerContent',
    HTML: 'html',
    ALIGN_IMAGE: 'align',
    RIGHT_ALIGN: 'right',
    LEFT_ALIGN: 'left',
    FIT_TO_PAGE_WIDTH: 'fit',
    LOGO_IMAGE: 'image',
}

const AddNewPrintConfigurationModal = ({ open, close }) => {
    const [isImageLogoChecked, setIsImageLogoChecked] = useState(false);
    const [isLinesChecked, setIsLinesChecked] = useState(false)
    const [isAlignImg, setIsAlignImg] = useState("")
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
    const { updatePrintConfigDetails, printConfig } = useSelector((state) => state[componentKey])

    const [currentConfigInfo, setCurrentConfigInfo] = useState()

    const dispatch = useDispatch()

    const handlePreview = () => {
        setIsPreviewModalOpen(true)
    }

    const fields = [
        { fieldName: FORM_FIELDS_NAMES.HEADER_NAME, isRequired: true }
    ]

    const validationSchema = getValidationSchema(fields)
    return (
        <>
            <Formik
                initialValues={{
                    [FORM_FIELDS_NAMES.HEADER_NAME]: printConfig.headerName,
                    [FORM_FIELDS_NAMES.HTML]: printConfig.html,
                    [FORM_FIELDS_NAMES.ALIGN_IMAGE]: printConfig?.align,
                    [FORM_FIELDS_NAMES.LOGO_IMAGE]: printConfig?.image,
                }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(val) => {
                    if (updatePrintConfigDetails) {
                        const updatedVal = {
                            align: val?.align,
                            headerName: val?.headerName,
                            html: val?.html,
                            updatedImage: val?.image
                        }
                        dispatch(updatePrintConfig({ values: updatedVal, uuid: printConfig.uuid }))
                    }
                    else {
                        dispatch(postPrintConfig({ data: val }))
                    }
                    dispatch(setIsOpenPrintConfigModal(false))
                    close()
                }}
            >
                {({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, handleBlur }) => {
                    return <Form onSubmit={handleSubmit} >

                        <div>
                            <ModalComponent open={open} close={close} title="New Print Configuration" customClasses='w-[60%]' footerButton={<div className='flex'>
                                <div className='pr-2'><Button variant={BUTTON_VARIANTS.OUTLINED} onClickCb={handlePreview}>Priview</Button></div>
                                <div><Button type='submit' >Save</Button></div>
                            </div>}>
                                <div>
                                    <div className='pt-1 '><Input placeholder="Enter Header Name"
                                        label='Header Name'
                                        name={FORM_FIELDS_NAMES.HEADER_NAME}
                                        value={values[FORM_FIELDS_NAMES.HEADER_NAME]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.HEADER_NAME, e.target.value) }}
                                        onBlurCb={handleBlur}
                                        isRequired={true}
                                    /></div>
                                </div>
                                <div className='pt-3'><Label fontSize="lg">Header Content</Label></div>
                                <div className='flex items-center pt-3'>
                                    <div>
                                        <Checkbox onChangeCb={() => setIsLinesChecked(prev => !prev)} checked={isLinesChecked}
                                        />
                                    </div>
                                    <div className='pl-2'><Label>Text (up to 6 lines)</Label></div>
                                </div>
                                {isLinesChecked &&
                                    <div className='pt-3'>
                                        <MetTextEditor
                                            value={values[FORM_FIELDS_NAMES.HTML]}
                                            onChange={(val) => {
                                                setCurrentConfigInfo(prev => ({ ...prev, html: val.value }))
                                                setFieldValue(FORM_FIELDS_NAMES.HTML, val.value)
                                            }}
                                        />
                                    </div>}
                                <div className='flex justify-between items-center pt-2'>
                                    <div className='flex items-center'>
                                        <div>
                                            <Checkbox onChangeCb={() => setIsImageLogoChecked(prev => !prev)} checked={isImageLogoChecked} />
                                        </div>
                                        <div className='pl-2'><Label>Image/Logo (Up To 600Px Tall, 3000Px Wide)</Label></div>
                                    </div>
                                    <div>
                                        {isImageLogoChecked &&
                                            <SelectDropdown placeholder='Align Image'
                                                name={FORM_FIELDS_NAMES.ALIGN_IMAGE}
                                                value={values[FORM_FIELDS_NAMES.ALIGN_IMAGE]}
                                                onChangeCb={(val) => {
                                                    setIsAlignImg(val.value)
                                                    setCurrentConfigInfo(prev => ({ ...prev, align: val.value }))
                                                    setFieldValue(FORM_FIELDS_NAMES.ALIGN_IMAGE, val.value)
                                                }}
                                                options={[
                                                    { label: "Right Align", value: FORM_FIELDS_NAMES.RIGHT_ALIGN },
                                                    { label: 'Left Align', value: FORM_FIELDS_NAMES.LEFT_ALIGN },
                                                    { label: 'Fit To Page Width', value: FORM_FIELDS_NAMES.FIT_TO_PAGE_WIDTH }]}
                                            />
                                        }

                                    </div>
                                </div>
                                <div className='pt-3'>
                                    {isImageLogoChecked && (
                                        <Upload
                                            customClass='h-[250px]'
                                            customImgClass={isAlignImg === "right" ? 'max-h-[180px] max-w-[180px]' : isAlignImg === "left" ? 'max-h-[180px] max-w-[180px]' : isAlignImg === "fit" ? 'h-full w-full' : ''}
                                            customAlignClass={isAlignImg === 'right' ? 'flex justify-end items-center mr-6' : isAlignImg === 'left' ? 'flex justify-start items-center ml-6' : isAlignImg === 'fit' ? 'flex justify-center items-center' : ''}
                                            icon={<Icons iconName="fileUpload" height="48px" width="48px" color="#1A1A1A4D" />}
                                            name={FORM_FIELDS_NAMES.LOGO_IMAGE}
                                            value={values[FORM_FIELDS_NAMES.LOGO_IMAGE] || ''}
                                            onChangeCb={async (file) => {
                                                const mimeType = file?.type || ""
                                                const base64String = await General.fileToBase64(file)
                                                const base64 = `data:${mimeType};base64,${base64String}`
                                                setFieldValue(FORM_FIELDS_NAMES.LOGO_IMAGE, base64)
                                                setCurrentConfigInfo(prev => ({ ...prev, imageURL: base64 }))
                                            }}
                                            title='Drag & Drop files'
                                            content=' Or'
                                            link='Browse Files'
                                            subContent="Supported formats: JPEG, PNG."
                                        />)}
                                </div>
                            </ModalComponent>

                        </div>
                    </Form>
                }}
            </Formik>
            {isPreviewModalOpen &&
                <ModalComponent close={() => { setIsPreviewModalOpen(false) }} open={isPreviewModalOpen} title="Preview Print Configuration" customClasses='h-[84vh] w-[60%]'>
                    <PrintConfigCardItem isHeader={false} item={currentConfigInfo} customClass="h-[660px]" borderLINE={true} />
                </ModalComponent>
            }
        </>
    )
}

export default AddNewPrintConfigurationModal
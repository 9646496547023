
import { all, put, takeLatest } from 'redux-saga/effects'
import store from '../../store/store'
import { componentKey, setUnsignedVisitListState, setUnsignedVisitPaginationState } from './UnsignedVisitSlice'
import EncounterDataService from '../../services/EncounterDataService'

export const { getUnsignedVisitData } = {
    getUnsignedVisitData: (payload) => {
        return {
            type: 'GET_UNSIGNED_VISIT_DATA',
            payload
        }
    },
}

function* getUnsignedVisitDataAsync(action) {
    try {
        const unsignedVisitPaginationState = action.payload
        const params = {
            pageNumber: unsignedVisitPaginationState.pageNumber,
            limit: unsignedVisitPaginationState.limit,
            start: unsignedVisitPaginationState.start,
            end: unsignedVisitPaginationState.end,
            status: unsignedVisitPaginationState.status.value,
            providerId: unsignedVisitPaginationState.providerId
        }

        const response = yield EncounterDataService.getUnsignedVisitData({ params })
        const { data, message, status } = response.data
        if (status) {
            const { encounter, totalPages, totalRecords } = data || {}
            yield put(setUnsignedVisitListState(encounter))
            yield put(setUnsignedVisitPaginationState({ total: totalRecords }))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getUnsignedVisitData().type, getUnsignedVisitDataAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
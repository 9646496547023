import React, { useState } from 'react'

function NavigationCapsuleTabs({ tabs, name, onChangeCb = () => { }, label = "", value = 0, outerBorderClass = "rounded-full", buttonClass = "rounded-full", btnBorderColor = " border-met-primary" ,navigationKey}) {
    const [activeTab, setActiveTab] = useState(value);

    return (
        <>
            <div className='font-bold pb-1'>{label}</div>
            <div className={`flex border shadow-md bg-gray-200 ${outerBorderClass}`}>
                {tabs.map((tab, index) => (
                    <button
                        name={name}
                        key={"capsule-tab-item" + index}
                        className={`px-4 py-1    ${activeTab === index
                            ? `text-met-primary border bg-white ${buttonClass} ${btnBorderColor}`
                            : 'text-gray-600'
                            }`}
                        onClick={() => {
                            if (navigationKey==='custom' || activeTab !== index ) {
                                setActiveTab(index);
                                onChangeCb(tab.label);
                            }
                        }
                        }
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
        </>

    );
}

export default NavigationCapsuleTabs 
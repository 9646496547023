export const SettingsDeleteIcon = () => {
	return (
		<svg
			id="delete_black_24dp_7_"
			data-name="delete_black_24dp (7)"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path id="Path_206792" data-name="Path 206792" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_206793"
				data-name="Path 206793"
				d="M16,9V19H8V9h8M14.5,3h-5l-1,1H5V6H19V4H15.5ZM18,7H6V19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2Z"
				fill="rgba(26,26,26,0.8)"
			/>
		</svg>
	);
};

import React from 'react'

import { componentKey, setMeetingParticipants, setSendInviteModal, setSendInviteType, setShowViewJoinAttendance } from '../../ZoomIntegrationSlice'
import { useParticipantsChange } from '../../hooks/useParticipantsChange'
import { useDispatch, useSelector } from 'react-redux'
import { VEC_ICON_NAME } from '../../components/icon/constants'
import { BUTTON_TYPE } from '../../constants'

import Icons from '../../components/icon/Icon'
import AvatarComponent from '../../components/avatarComponent/AvatarComponent'
import Heading from '../../components/heading/Heading'
import Button from '../../components/button/Button'

const ShowParticipantList = ({ userType }) => {

    const dispatch = useDispatch()

    const { client, meetingParticipants, showViewJoinAttendance } = useSelector(state => state[componentKey])

    useParticipantsChange(client, () => {
        const participants = client.getAllUser()
        dispatch(setMeetingParticipants(participants))
    }, [])

    return (
        <div className='participant-list' style={showViewJoinAttendance ? { display: "block" } : { display: "none" }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                <Heading>People</Heading>
                <div style={{ display: "flex", rowGap: "15px" }} className='cursor'>
                    <Icons iconName={VEC_ICON_NAME.CLOSE_ICON} onClickCb={() => dispatch(setShowViewJoinAttendance(!showViewJoinAttendance))} />
                </div>
            </div>

            {userType == "Provider" &&
                <Button
                    onClickCb={() => {
                        dispatch(setSendInviteModal(true))
                        dispatch(setShowViewJoinAttendance(!showViewJoinAttendance))
                        dispatch(setSendInviteType("Patient"))
                    }}
                    prefixProps={{ icon: VEC_ICON_NAME.ADD_PERSON_ICON }}
                    variant={BUTTON_TYPE.BUTTON_WITH_LIGHT_COLOR_NO_ICON}
                    styles={{ width: "120px", fontSize: "14px" }}
                >Add People</Button>
            }

            <div style={{ minHeight: "800px", overflow: "auto" }}>
                <div className='mt-3'>
                    {meetingParticipants?.map((item, index) => {
                        return (
                            <div key={index} style={{ display: "flex", columnGap: "10px", alignItems: "center", }} className='border p-2 mb-2 rounded'>
                                <AvatarComponent row={{ firstName: item.displayName }} style={{ height: '30px', width: '30px' }} size="11px" />
                                <div style={{ display: "flex", flexDirection: "column" }} className='d-flex flex-column'>
                                    <span>{item.displayName} <span style={{ fontSize: "12px", letterSpacing: "0.8px" }}>{client.getCurrentUserInfo()?.userId == item.userId ? "(You)" : ""}</span></span>
                                    {item?.isHost && <span style={{ fontSize: "11px", letterSpacing: "0.5px", color: "rgb(95, 99, 104)" }}>Meeting host</span>}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ShowParticipantList

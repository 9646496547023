import moment from 'moment';
import store from '../../store/store';
import { FILTERS } from './constant';
export const componentKey = 'SCHEDULING_COMPONENT_SLICE';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setShowSheduleFilters: (state, action) => {
			state.showSheduleFilters = action.payload;
		},
		setActiveScheduleTab: (state, action) => {
			state.activeScheduleTab = action.payload;
		},
		setCalendarViewFormat: (state, action) => {
			state.calendarViewFormat = action.payload;
		},
		setActiveDate: (state, action) => {
			state.activeDate = action.payload;
		},
		setIsNewAppointmentModal: (state, action) => {
			state.isNewAppointModalOpen = action.payload;
		},
		setIsInstantModalOpen: (state, action) => {
			state.isInstantAppointmentModalOpen = action.payload;
		},
		setIsBlockAppointmentOpen: (state, action) => {
			state.isBlockApoointmentModalOpen = action.payload;
		},
		setAddNewPatientData: (state, action) => {
			state.addNewPatientData = action.payload;
		},
		setIsAddNewPatientModalOpen: (state, action) => {
			state.isAddNewPatientModalOpen = action.payload;
		},
		setInstantAppointmentData: (state, action) => {
			state.instantAppointmentData = action.payload;
		},
		setBlockAppointmentData: (state, action) => {
			state.blockAppointmentData = action.payload;
		},
		setPatientList: (state, action) => {
			state.patientList = action.payload;
		},
		setAvailableSlotsOfProvider: (state, action) => {
			state.availableSlotsOfProvider = action.payload;
		},
		setPatientListPaginationState: (state, action) => {
			state.patientListPaginationState = { ...state.patientListPaginationState, ...action.payload };
		},
		setSchedulingAppointmentsPaginationState: (state, action) => {
			state.schedulingAppointmentsPaginationState = {
				...state.schedulingAppointmentsPaginationState,
				...action.payload,
			};
		},
		setColorConfigDetailsData: (state, action) => {
			state.colorConfigDetails = action.payload;
		},
		setProviderListPaginationState: (state, action) => {
			state.providerListPaginationState = { ...state.providerListPaginationState, ...action.payload };
		},
		setProvidersList: (state, action) => {
			state.providerList = action.payload;
		},
		setAllScheduledAppointmentsforVirtualMode: (state, action) => {
			state.allScheduledAppointmentsforVirtualMode = action.payload;
		},
		setAllScheduledAppointmentsforLocationlMode: (state, action) => {
			state.allScheduledAppointmentsforLocationMode = action.payload;
		},
		setIsAppointmentDetailsModalOpen: (state, action) => {
			state.isAppointmentDetailsModalOpen = action.payload;
		},
		setAppointmentDetails: (state, action) => {
			state.appointmentDetails = action.payload;
		},
		setIsRescheduleModalOpen: (state, action) => {
			state.isRescheduleAppointmentModalOpen = action.payload;
		},
		setIsCancelledAppointmentModalOpen: (state, action) => {
			state.isCancelledAppointmentModalOpen = action.payload;
		},
		setStatusOfAppointment: (state, action) => {
			state.statusOfAppointment = action.payload.value;
		},
		setIsCheckedInModalOpen: (state, action) => {
			state.isCheckedInModalOpen = action.payload;
		},
		setIsCheckedInData: (state, action) => {
			state.checkedInData = action.payload;
		},
		setSelectedProvidersLocation: (state, action) => {
			state.selectedProvidersLocation = action.payload;
		},
		setAllAppointmentsOfCalenderView: (state, action) => {
			state.allAppointmentsOfCalenderView = action.payload;
		},
		setIsDeleteModalOpen: (state, action) => {
			state.isDeleteModalOpen = action.payload;
		},
		setResetFilters: (state, action) => {
			state.schedulingAppointmentsPaginationState = action.payload;
		},
		setIsAppointmentData: (state, action) => {
			state.appointmentData = action.payload;
		},
		setIsSuccessPopUpModalOpen: (state, action) => {
			state.isSuccessPopUpModalOpen = action.payload;
		},
		setIsCalenderView: (state, action) => {
			state.isCalenderView = action.payload;
		},
		setIsSuccessPopUpModalOpenForReschedule: (state, action) => {
			state.isSuccessModalOpenForReschedule = action.payload;
		},
		setIsViewAllEventsModalOpen: (state, action) => {
			state.isViewAllEventsModalOpen = action.payload;
		},
		setIsNoShowModalOpen: (state, action) => {
			state.isNoShowModalOpen = action.payload
		},
		setIsSignOffModalOpen: (state, action) => {
			state.isSignOffModalOpen = action.payload
		},
		setIsSuccessPopUpModalOpenForNoShow: (state, action) => {
			state.isSuccessModalOpenForNoShow = action.payload;
		},
		setIsSuccessPopUpModalOpenForSignedOff: (state, action) => {
			state.isSuccessModalOpenForSignedOff = action.payload;
		},
	},
	initialReducerState: {
		showSheduleFilters: true,
		activeScheduleTab: '/list',
		calendarViewFormat: 'month',
		activeDate: moment().format('YYYY-MM-DD'),
		isNewAppointModalOpen: false,
		isInstantAppointmentModalOpen: false,
		isBlockApoointmentModalOpen: false,
		isAddNewPatientModalOpen: false,
		addNewPatientData: {},
		instantAppointmentData: {},
		blockAppointmentData: {},
		patientList: [],
		availableSlotsOfProvider: {},
		patientListPaginationState: {
			pageNumber: 1,
			limit: 20,
			total: 10,
			search: '',
			status: '',
			orderBy: 'desc',
			sortBy: '',
		},
		schedulingAppointmentsPaginationState: FILTERS,
		colorConfigDetails: [],
		providerListPaginationState: { pageNumber: 1, limit: 20, total: 10 },
		providerList: [],
		allScheduledAppointmentsforVirtualMode: [],
		allScheduledAppointmentsforLocationMode: [],
		isAppointmentDetailsModalOpen: false,
		appointmentDetials: {},
		isRescheduleAppointmentModalOpen: false,
		isCancelledAppointmentModalOpen: false,
		statusOfAppointment: { label: 'Scheduled', value: 'Scheduled' },
		isCheckedInModalOpen: false,
		checkedInData: {},
		selectedProvidersLocation: [],
		allAppointmentsOfCalenderView: [],
		isDeleteModalOpen: false,
		appointmentData: {},
		isSuccessPopUpModalOpen: false,
		isCalenderView: false,
		isSuccessModalOpenForReschedule: false,
		isViewAllEventsModalOpen: false,
		isNoShowModalOpen: false,
		isSignOffModalOpen: false,
		isSuccessModalOpenForNoShow: false,
		isSuccessModalOpenForSignedOff:false

	},
});

export const {
	setShowSheduleFilters,
	setActiveScheduleTab,
	setCalendarViewFormat,
	setActiveDate,
	setIsNewAppointmentModal,
	setIsInstantModalOpen,
	setIsBlockAppointmentOpen,
	setAddNewPatientData,
	setIsAddNewPatientModalOpen,
	setInstantAppointmentData,
	setBlockAppointmentData,
	setPatientList,
	setAvailableSlotsOfProvider,
	setPatientListPaginationState,
	setSchedulingAppointmentsPaginationState,
	setColorConfigDetailsData,
	setProviderListPaginationState,
	setProvidersList,
	setAllScheduledAppointmentsforVirtualMode,
	setIsAppointmentDetailsModalOpen,
	setAppointmentDetails,
	setIsRescheduleModalOpen,
	setIsCancelledAppointmentModalOpen,
	setStatusOfAppointment,
	setIsCheckedInModalOpen,
	setIsCheckedInData,
	setSelectedProvidersLocation,
	setAllAppointmentsOfCalenderView,
	setAllScheduledAppointmentsforLocationlMode,
	setIsDeleteModalOpen,
	setResetFilters,
	setIsAppointmentData,
	setIsSuccessPopUpModalOpen,
	setIsCalenderView,
	setIsSuccessPopUpModalOpenForReschedule,
	setIsViewAllEventsModalOpen,
	setIsNoShowModalOpen,
	setIsSignOffModalOpen,
	setIsSuccessPopUpModalOpenForSignedOff,
	setIsSuccessPopUpModalOpenForNoShow

} = actions;

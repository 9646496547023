import React, { useEffect, useRef, useState } from 'react';
import { ErrorMessage } from 'formik';
import Button from '../button/Button';
import { BUTTON_VARIANTS } from '../button/Constants';
import useBase64Image from '../../../containers/SettingsTab/MyAccountCard/Tabs/PrintConfiguration/useS3Image';
import { useDispatch } from 'react-redux';
import { addNotifications } from '../toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../toaster/MetToaster';

function Upload({ customClass = "", title = "", content = '', subTitle = "", icon, name = "", value = "", onChangeCb = () => { }, link = "", borderType = "border-dashed", isHoverSelectOption = false, customCotainerClass = "", customImgClass = "", customAlignClass = "", disabled = false, customSubtitleClass = "", url, subContent = "" }) {
	const fileInputRef = useRef(null);
	const [showUploadCard, setShowUploadCard] = useState(false)
	const [isDraggingOver, setIsDraggingOver] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedImageName, setSelectedImageName] = useState('');
	const getBase64 = useBase64Image();
	const dispatch = useDispatch();

	useEffect(() => {
		if (url) {
			const getBase64Data = async (url) => {
				const base64Data = await getBase64(url);
				if (base64Data !== null) {
					setSelectedImage(base64Data);
				}
			};
			getBase64Data(url);
		}
	}, [url]);

	const handleClick = () => {
		fileInputRef.current.click();
	};

	const handleImageChange = (event) => {
		const file = event.target.files[0];
		const fileType = file.type.split('/')[1];
		let isValidFileType = false;

		switch (fileType) {
			case 'pdf':
			case 'png':
			case 'jpeg':
			case 'jpg':
			case 'doc':
			case 'excel':
			case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			case 'zip':
			case 'mp3':
			case 'csv':
			case 'mpeg':
			case 'msword':
			case 'svg+xml':
				isValidFileType = true;
				break;
			default:
				isValidFileType = false;
				break;
		}


		if (isValidFileType) {
			setSelectedImageName(file.name);
			onChangeCb(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				setSelectedImage(reader.result);
			};
			reader.readAsDataURL(file);
		}
		else {
			dispatch(addNotifications({ message: "Unsupported file type!", variant: TOASTER_VARIANT.ERROR }));
		}

		onChangeCb(file)
	};

	const handleDragEnter = (e) => {
		e.preventDefault();
		setIsDraggingOver(true);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		setIsDraggingOver(true);
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		setIsDraggingOver(false);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		setIsDraggingOver(false);
		const file = e.dataTransfer.files[0];
		onChangeCb(file)
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setSelectedImage(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	return (
		<>
			<div onMouseOver={() => { setShowUploadCard(true) }} onMouseLeave={() => { setShowUploadCard(false) }}
				onDragEnter={handleDragEnter}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				className={`flex items-center justify-center border-2 met-scrollbar ${selectedImage ? "" : borderType} border-gray-400   rounded-md relative ${customClass} ${isHoverSelectOption ? "bg-gray-200" : "bg-white"} ${isHoverSelectOption ? "bg-gray-200" : "bg-white"} ${isDraggingOver ? "bg-gray-200" : "bg-white"}`} onClick={handleClick}>
				{selectedImage && !showUploadCard ? (
					<>
						{selectedImage?.includes("data:image/") ? <div className={`h-full w-full ${customAlignClass} `}>
							<img src={selectedImage} alt="Selected" className={`h-full w-full ${customImgClass}`} />

						</div> :
							<div className={`h-full w-full text-gray-600 font-bold  flex justify-center mt-8 ${customAlignClass}  `} >
								{selectedImageName}
							</div>}
					</>
				) : (
					<div className={customCotainerClass}>
						<div className="flex justify-center cursor-pointer">
							{icon}
						</div>
						<div className='flex flex-col justify-center items-center'>
							<div className="pt-2 font-bold">{title}</div>
							<div className="pt-1 font-bold">{content}</div>
							<div className="pt-1 flex items-center justify-center">
								<span className={`${customSubtitleClass}`}>{subTitle}</span>
								{link && <Button variant={BUTTON_VARIANTS.TEXT} customBtnClass="underline text-met-primary font-bold">
									{link}
								</Button>}
							</div>
						</div>
						<div className='mt-4 items-center flex justify-center text-gray-500 fs-14'>{subContent}</div>
					</div>

				)}
				<input
					type="file"
					name={name}
					ref={fileInputRef}
					className="hidden"
					onChange={(e) => {
						handleImageChange(e)
					}}
					disabled={disabled}
				/>
			</div>
			{name && <div className='mt-1'>
				<ErrorMessage name={name} component="div" className="error-text" />
			</div>}
		</>
	);
}

export default Upload;



import React from 'react'

function PatientsIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92">
            <g id="Group_96340" data-name="Group 96340" transform="translate(-956 -132)">
                <circle id="Ellipse_1213" data-name="Ellipse 1213" cx="46" cy="46" r="46" transform="translate(956 132)" fill="rgba(0,151,240,0.1)" />
                <g id="Group_72294" data-name="Group 72294" transform="translate(1132 43)">
                    <path id="Path_28503" data-name="Path 28503" d="M0,0H55.6V55.6H0Z" transform="translate(-158 107)" fill="#1b5984" opacity="0" />
                    <g id="noun-patient-4126477" transform="translate(-149.389 110.395)">
                        <path id="Path_28499" data-name="Path 28499" d="M227.374,206.91H219.81a8.373,8.373,0,0,1-8.362-8.362v-9.212a18.436,18.436,0,1,0,21.049,29.44,4.312,4.312,0,0,1-.227-.455l-4.9-11.411Zm-9.762,11.829a12.035,12.035,0,0,1-12.019-12.02.616.616,0,0,1,1.231,0,10.8,10.8,0,0,0,10.788,10.788.616.616,0,0,1,0,1.231Z" transform="translate(-200.675 -175.734)" fill="#1b5984" />
                        <path id="Path_28500" data-name="Path 28500" d="M303.208,79.325a4.126,4.126,0,1,0-4.126,4.126A4.135,4.135,0,0,0,303.208,79.325Z" transform="translate(-283.721 -75.199)" fill="#1b5984"  />
                        <path id="Path_28502" data-name="Path 28502" d="M322.475,173.184a7.453,7.453,0,0,0-6.853-4.522H308.55a.619.619,0,0,1-.616-.615v-10.07a3.261,3.261,0,0,0-6.522-.066V168.04a7.139,7.139,0,0,0,7.131,7.131h7.966a.607.607,0,0,1,.564.374l5.05,11.785a2.822,2.822,0,0,0,.366.63l.008.007a2.956,2.956,0,0,0,2.331,1.151,2.95,2.95,0,0,0,2.712-4.112l-5.064-11.821Z" transform="translate(-289.408 -145.241)" fill="#1b5984"  />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default PatientsIcon
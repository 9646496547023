import React from 'react'

const RoundFab = ({ color = "", children, customClasses = "" }) => {

    return (
        <div className={`bg-${color} rounded-full flex justify-center items-center ${customClasses}`}>
            {children}
        </div>

    )
}

export default RoundFab
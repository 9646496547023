import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { MAIN_PATHS, PATIENTS_ROUTES } from '../../libs/constant'
import PatientsGridList from './PatientsGridList'
import AddPatients from './AddPatient'
import CardContainer from '../../components/common-components/Cards/Container/CardContainer'
import PatientDashboard from './PatientDashboard'

const Patients = () => {
    const PATIENTS_SECTION_ROUTES = [
        { path: `${PATIENTS_ROUTES.ADD}/:patientId/*?`, component: <CardContainer customCssClass="flex flex-col justify-between"><AddPatients /></CardContainer> },
        { path: MAIN_PATHS.ROOT, component: <CardContainer customCssClass="flex flex-col row-gap-20"><PatientsGridList /> </CardContainer> },
        { path: PATIENTS_ROUTES.ROOT, component: <PatientDashboard /> },
    ]

    return (
        <>
            <Routes>
                {PATIENTS_SECTION_ROUTES.map((route, index) => (
                    <Route key={'patient-section-routes' + index} path={route.path} element={route.component} />
                ))}
            </Routes>
        </>
    )
}

export default Patients
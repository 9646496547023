import React, { useEffect, useState } from 'react'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Table from '../../../../containers/Table/Index'
import ButtonDropdown from '../../../../components/common-components/buttonDropdown'
import PatientFormModal from './PatientFormModal'
import { useDispatch, useSelector } from 'react-redux'
import { getUpcommingAppointment } from './AppointmentSaga'
import { componentKey } from './AppointmentsSlice'
import moment from 'moment'
import General from '../../../../libs/utility/General'
import { useParams } from 'react-router-dom'

function Appointments() {

    const { allUpcommingAppointmentData, appoinmentpaginationState } = useSelector(state => state[componentKey])
    const [isOpenPatientForm, setIsOpenPatientForm] = useState(false)
    const dispatch = useDispatch()

    const [appointments, setAppointments] = useState({
        upcomingAppointments: [],
        pastAppointments: []
    })

    const { patientId } = useParams();

    useEffect(() => {
        dispatch(getUpcommingAppointment({ patientId }))
    }, [dispatch, patientId])


    useEffect(() => {
        if (allUpcommingAppointmentData) {
            const formattedAppointmentData = allUpcommingAppointmentData.reduce((acc, appointment) => {
                const today = moment().format('YYYY-MM-DD')
                const itemDate = moment(appointment.date).format('YYYY-MM-DD')
                if (moment(itemDate).isBefore(today)) {
                    acc.pastAppointments.push(appointment)
                } else {
                    acc.upcomingAppointments.push(appointment)
                }
                return acc;
            }, { upcomingAppointments: [], pastAppointments: [] })

            setAppointments(formattedAppointmentData);
        }
    }, [allUpcommingAppointmentData])

    const coloumns = [
        {
            field: "visitDateTime",
            label: <Label fontWeight='bold'>Visit Date & Time</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{`${General.getformattedDate(row.date)}, ${General.convertToUppercaseAMPM(row?.start)}`}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "appointmentType",
            label: <Label fontWeight='bold'>Appointment Type</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.appointmentType}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "location",
            label: <Label fontWeight='bold'>Location</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row?.Locations?.name}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "providerName",
            label: <Label fontWeight='bold'>Provider Name</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{`${row.Provider.firstName} ${row.Provider.lastName}`}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "chiefComplaint",
            label: <Label fontWeight='bold'>Chief Complaint</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.complaints}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        // {
        //     field: "action",
        //     label: <Label fontWeight='bold'>Action</Label>,
        //     renderLogic: (row) => {
        //         return <>
        //             <div className='flex items-center'>
        //                 <div onClick={() => { row.action ? (true) : null }}>
        //                     <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.action}</Heading>
        //                 </div>
        //                 <ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[{ label: "View", value: null, }, { label: "Edit", value: null }, { label: "Delete", value: null }]} />
        //             </div>
        //         </>
        //     },
        // },
    ]



    const pastAppointments = [
        {
            field: "visitDateTime",
            label: <Label fontWeight='bold'>Visit Date & Time</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{`${General.getformattedDate(row.date)}, ${General.convertToUppercaseAMPM(row?.start)}`}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "appointmentType",
            label: <Label fontWeight='bold'>Appointment Type</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.appointmentType}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "location",
            label: <Label fontWeight='bold'>Location</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row?.Locations?.name}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "providerName",
            label: <Label fontWeight='bold'>Provider Name</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{`${row.Provider.firstName} ${row.Provider.lastName}`}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "chiefComplaint",
            label: <Label fontWeight='bold'>Chief Complaint</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.complaints}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        // {
        //     field: "action",
        //     label: <Label fontWeight='bold'>Action</Label>,
        //     renderLogic: (row) => {
        //         return <>
        //             <div className='flex items-center'>
        //                 <div>
        //                     <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.action}</Heading>
        //                 </div>
        //                 <ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[{ label: "View", value: null, onClickCb: () => { setIsOpenPatientForm(true) } }]} />
        //             </div>
        //         </>
        //     },
        // },
    ]

    return (
        <>
            <div>
                <div className='flex items-center justify-between p-4'>
                    <Heading fontSize="xl" fontWeight="bold" type={HEADING.H3}>Upcoming Appointments</Heading>
                </div>
                <div className='p-4'>
                    <Table isPagination={true} coloumns={coloumns} rows={appointments?.upcomingAppointments} sorting={
                        {
                            isSortable: false,
                            onSortChangeCb: () => { }
                        }}
                    // paginationProps={{
                    //     isPagination: true,
                    //     totalCount: appoinmentpaginationState.total,
                    //     onPageChange: (page) => {
                    //         dispatch(setAppoinmentpaginationState({ pageNumber: page }))
                    //     }
                    // }}
                    />
                </div>
            </div>
            <div>
                <div className='flex items-center justify-between p-4'>
                    <Heading fontSize="xl" fontWeight="bold" type={HEADING.H3}>Past Appointments</Heading>
                </div>
                <div className='p-4'>
                    <Table isPagination={false} coloumns={pastAppointments} rows={appointments?.pastAppointments} sorting={
                        {
                            isSortable: false,
                            onSortChangeCb: () => { }
                        }}
                    />
                </div>
            </div>
            {isOpenPatientForm && <PatientFormModal isOpen={isOpenPatientForm} close={() => { setIsOpenPatientForm(false) }} />}
        </>
    )
}

export default Appointments
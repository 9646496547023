import React, { useState } from 'react';
import Button from '../button/Button';
import { BUTTON_VARIANTS } from '../button/Constants';
import DropdownModal from '../dropdownModal';

const ButtonDropdown = ({
	label = '',
	options = [],
	selectCb = () => { },
	customBtnClass = '',
	openOnHover = false,
	endIcon = '',
	startIcon = "",
	buttonVariant = BUTTON_VARIANTS.TEXT
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOptionclick = (option) => {
		option?.onClickCb ? option?.onClickCb() : null;
		selectCb(option);
		setIsOpen(false);
	};

	const onClickCb = () => {
		setIsOpen(false);
	};

	return (
		<div className="relative">
			<Button
				startIcon={{ icon: startIcon }}
				onClickCb={() => setIsOpen(!isOpen)}
				endIcon={endIcon}
				onMouseOverCb={() => {
					openOnHover && setIsOpen(true);
				}}
				variant={buttonVariant}
				customBtnClass={customBtnClass}
			>
				{label}
			</Button>
			{isOpen ? (
				<DropdownModal isOpen={isOpen} onCloseCb={onClickCb} options={options} selectCb={handleOptionclick} />
			) : null}
		</div>
	);
};

export default ButtonDropdown;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Table from '../../../../containers/Table/Index'
import ImagingReportModal from './ImagingReportModal'
import { MET_COLORS } from '../../../../libs/constant'
import ButtonDropdown from '../../../../components/common-components/buttonDropdown'
import {
    componentKey as PatientImagingResultComponentKey,
    setIsImagingResultModalOpen,
    setIsNewImagingResultAdd,
    setImagingResultId,
    setImagingResultData,
    setIsOpenImagingDeleteModal,
} from '../Imaging/PatientImagingSlice';
import {
    componentKey as PatientDashboardComponentKey
} from '../PatientDashboardSlice';
import { deletePatientImagingResult, getAllImagingCentreDetails, getAllPatientImagingResultDetails } from '../Imaging/PatientImagingSaga';
import moment from 'moment';
import DeletePopup from '../../../../components/common-components/deletePopup/DeletePopup';
import General from '../../../../libs/utility/General';
import useBase64Image from '../../../../containers/SettingsTab/MyAccountCard/Tabs/PrintConfiguration/useS3Image';

function ImagingReport() {
    const dispatch = useDispatch();
    const { isImagingResultModalOpen, allImagingResultData, isOpenImagingDeleteModal } = useSelector((state) => state[PatientImagingResultComponentKey]);

    const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);
    const [rowData, setRowData] = useState(null)
    const getBase64 = useBase64Image();
    const role = localStorage.getItem('role');

    const handleImagingReportModal = () => {
        dispatch(setIsNewImagingResultAdd(true));
        dispatch(setImagingResultId(''));
        dispatch(setImagingResultData({}));
        dispatch(setIsImagingResultModalOpen(true));
    }

    const handleEditClick = (imagingResult) => {
        dispatch(setIsNewImagingResultAdd(false));
        dispatch(setImagingResultId(imagingResult.uuid));
        dispatch(setIsImagingResultModalOpen(true));
        dispatch(setImagingResultData(imagingResult));
    };

    const handleDeleteClick = (imagingResult) => {
        dispatch(deletePatientImagingResult({ patientId: currentPatientId, imagingresultId: imagingResult.uuid }));
    };

    useEffect(() => {
        if (!currentPatientId) return;
        dispatch(getAllPatientImagingResultDetails({ patientId: currentPatientId, page: 1, limit: 10 }));
    }, [currentPatientId]);

    useEffect(() => {
        dispatch(getAllImagingCentreDetails());
    }, [])


    const handleView = async (attachment) => {
        const base64 = await getBase64(attachment)
        if (base64) {
            General.openBase64FileInNewTab(base64);
        }
    }

    const coloumns = [
        // {
        //     field: "appointment",
        //     label: <Label>Appointment</Label>,
        //     renderLogic: (row) => {
        //         return <>
        //             <div className='flex items-center'>
        //                 <div>
        //                     <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.appointment || '-'}</Heading>
        //                     <Label color='gray-500'>{row.subLabel}</Label>
        //                 </div>
        //             </div>
        //         </>
        //     },
        // },

        {
            field: "imgReferenceID",
            label: <Label fontWeight="font-bold">Image Reference ID</Label>,
            renderLogic: (row) => {
                return <>
                    {
                        row.patientImageStudy?.map((test) => {
                            return <div key={test.uuid} className='flex items-center'>
                                <div>
                                    <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{`#${test?.uuid?.substring(test.uuid.length - 6)}`}</Heading>
                                </div>
                            </div>
                        })
                    }
                </>
            },
        },

        {
            field: "study",
            label: <Label fontWeight="font-bold">Study</Label>,
            renderLogic: (row) => {
                return <>
                    {
                        row.patientImageStudy?.map((test) => {
                            return <div key={test.uuid} className='flex items-center'>
                                <div>
                                    <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{`${test?.name || ''}`}</Heading>
                                </div>
                            </div>
                        })
                    }
                </>
            },
        },

        {
            field: "radiologyCenter",
            label: <Label fontWeight="font-bold">Radiology Center</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.imageCenter?.name}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "reviewer",
            label: <Label fontWeight="font-bold">Reviewer</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.reviewer}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "attachment",
            label: <Label fontWeight="font-bold">Attachment</Label>,
            renderLogic: (row) => {
                return <>
                    {
                        row.patientImageStudy?.map((test, index) => {
                            return (
                                <div key={test.uuid} className='flex items-center'>
                                    <div>
                                        <Heading type={HEADING.H1} color='met-primary' fontSize={"md"} customClasses="cursor-pointer" onClickCb={() => { handleView(test.path) }}>
                                            {test.path ? `Attachment ${index + 1}` : "-"}
                                        </Heading>
                                    </div>
                                </div>
                            );
                        })
                    }

                </>
            },
        },

        {
            field: "receivedDate",
            label: <Label fontWeight="font-bold">Received Date</Label>,
            renderLogic: (row) => {
                return <>
                    {
                        row.patientImageStudy?.map((test) => {
                            return <div key={test.uuid} className='flex items-center'>
                                <div>
                                    <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{test.reportDate ? moment(test.reportDate).format('DD-MM-YYYY') : ''}</Heading>
                                </div>
                            </div>
                        })
                    }
                </>
            },
        },

        {
            field: "action",
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.action}</Heading>
                        </div>
                        <ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[{ label: "Edit Image Report", value: null, onChangeCb: () => handleEditClick(row) }, {
                            label: "Delete", value: null, onChangeCb: () => {
                                dispatch(setIsOpenImagingDeleteModal(true))
                                setRowData(row)
                            }
                        }]}
                            selectCb={(option) => {
                                option?.onChangeCb();
                            }} />
                    </div>
                </>
            },
        },
    ]

    return (
        <>
            <div className='flex items-center justify-end p-2 px-4'>
                <div className='flex'>
                {role !== 'Biller' && <Button onClickCb={handleImagingReportModal} variant={BUTTON_VARIANTS.OUTLINED} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} >Add New Image Report</Button>}
                </div>
            </div>
            <div className='p-4'>
                <Table isPagination={false} coloumns={coloumns} rows={allImagingResultData} sorting={
                    {
                        isSortable: false,
                        onSortChangeCb: () => { }
                    }} />
            </div>
            {isImagingResultModalOpen ? <ImagingReportModal open={isImagingResultModalOpen} close={() => dispatch(setIsImagingResultModalOpen(false))} /> : null}
            {isOpenImagingDeleteModal ? <DeletePopup open={isOpenImagingDeleteModal}
                okButton='Delete'
                cancelButton='Cancel'
                onClickCancel={() => {
                    dispatch(setIsOpenImagingDeleteModal(false))
                }}
                onClickOk={() => {
                    handleDeleteClick(rowData)

                }}
            /> : null}

        </>
    )
}

export default ImagingReport
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey as PatientDashboardComponentKey } from '../../Patients/PatientDashboard/PatientDashboardSlice'
import Label from '../../../components/common-components/label/Label';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import useBase64Image from '../../../containers/SettingsTab/MyAccountCard/Tabs/PrintConfiguration/useS3Image';
import { getFormattedAge, getFormattedPatientAgeDob } from '../../../libs/patientUtils';
import Avatar from '../../../components/common-components/avatar';
import WrapperCard from '../../../components/common-components/Cards/WrapperCard/WrapperCard';
import moment from 'moment';
import { componentKey as PatientAllergiesComponentKey } from "../../Patients/PatientDashboard/Allergies/PatientAllergiesSlice"
import { invitePatient } from '../../Patients/PatientDashboard/PortalAccess/PortalAccessSaga';
import { getPatientDetails } from '../../Patients/PatientDashboard/PatientDashboardSaga';
import { useParams } from 'react-router-dom';

function PatientInfo() {
    const [base64Data, setBase64Data] = useState("")
    const dispatch = useDispatch()

    const { patientId } = useParams();

    const { patientData } = useSelector((state) => state[PatientDashboardComponentKey]);
    const { allergiesData } = useSelector((state) => state[PatientAllergiesComponentKey]);

    const handlePatientInvite = () => {
        dispatch(invitePatient({ patientId }))
        dispatch(getPatientDetails({ patientId }))
    }

    const getPrimaryInsurance = () => {
        return patientData?.Insurance?.find(insurance => {
            return insurance.type === 'Primary'
        })
    }

    const getBase64 = useBase64Image(patientData?.profilePicture);

    useEffect(() => {
        const getBase64Data = async () => {
            const base64Data = await getBase64(patientData?.profilePicture)
            setBase64Data(base64Data)
        }
        getBase64Data()
    }, [patientData?.profilePicture])

    return (
        <>
            <WrapperCard>
                <div className="p-4">
                    <div className="flex column-gap-40px flex-1 min-w-[600px]">
                        <div className='flex column-gap-20px'>
                            <div className='h-[70px] w-[70px]'>
                                <Avatar url={patientData?.profilePicture || ''} name={`${patientData?.firstName || ''} ${patientData?.lastName || ''}`} width={70} height={70} customClass='rounded-none'></Avatar>
                            </div>
                            <div className='flex flex-col row-gap-20px'>
                                <div className='flex flex-wrap flex-1 column-gap-10px items-center'>
                                    <Label fontSize="lg" customClass="text-met-primary font-bold">{`${patientData?.firstName || ''} ${patientData?.lastName || ''}`}</Label>
                                    <div className="flex items-center text-gray-500">
                                        {moment(patientData?.dob).format('MMMM DD, YYYY')}
                                    </div>
                                    <div>
                                        {getFormattedAge(patientData?.dob)}
                                    </div>
                                    <div className="flex items-center">
                                        <Label fontSize="md">{patientData?.gender ? `(${patientData.gender.substring(0, 1).toUpperCase() + patientData.gender.substring(1)})` : ''}</Label>
                                    </div>
                                </div>
                                <div className="">
                                    <span className='text-gray-500'>MOBILE NUMBER : </span> <Label fontSize="md">{patientData?.mobileNumber || '-'}</Label>
                                </div>
                            </div>
                        </div>
                        <div className='flex column-gap-20px items-center'>
                            {allergiesData && allergiesData.length ? <div className="bg-red-100 rounded-lg">
                                <div className="text-met-red px-2 py-0">
                                    {allergiesData && allergiesData.length ? `${allergiesData.length} ${allergiesData.length === 1 ? 'Allergy' : 'Allergies'}` : ''}
                                </div>
                            </div> : ''}
                            {patientData?.patientPortalAccessStatus === 'Disabled' ? <Button
                                variant={BUTTON_VARIANTS.CONTAINED_GRAY}
                                customBtnClass="h-[28px]"
                                onClickCb={() => { handlePatientInvite() }}
                            >
                                Invite Patient
                            </Button> : patientData?.patientPortalAccessStatus === 'Pending' ? <div className="bg-yellow-100 rounded-lg h-[26px]">
                                <div className="text-met-goldenrod px-2 py-0">
                                    Pending
                                </div>
                            </div> : <div className="bg-green-100 rounded-lg h-[26px]">
                                <div className="text-met-success px-2 py-0">
                                    Enrolled
                                </div>
                            </div>}
                        </div>
                        <div className='flex flex-col justify-evenly'>
                            <div className='flex row-gap-20px'>
                                <div className='text-gray-500 w-[200px]'>INSURANCE</div>
                                <div>{getPrimaryInsurance()?.companyName || '-'} <span className='text-gray-500'>{getPrimaryInsurance()?.companyName ? `(Default)` : ''}</span></div>
                            </div>
                            <div className='flex'>
                                <div className='text-gray-500 w-[200px]'>PRIMARY PROVIDER</div>
                                <div>{patientData?.provider?.firstName ? patientData?.provider?.firstName + ' ' + patientData?.provider?.lastName || '' : ''}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </WrapperCard>
        </>
    );
}

export default PatientInfo;

import React from 'react';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setDeleteVitalsTestModalOpen } from './VitalsTestsSlice';
import Icons from '../../../../../components/icons/Icons';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import { deleteVitalsTests } from './VitalsTestsSaga';

function DeleteVitalsTest({ close, deviceName }) {
	const dispatch = useDispatch();
	const { deleteVitalsTestModalOpen, currentUUID } = useSelector((state) => state[componentKey]);
	return (
		<ModalComponent
			open={deleteVitalsTestModalOpen}
			title={'Delete Vitals Test'}
			close={close}
			icon={<Icons iconName={'closeIcon'} />}
			footerButton={
				<div className="flex justify-between w-[15%]">
					<Button
						type="submit"
						variant={BUTTON_VARIANTS.OUTLINED}
						customBtnClass="text-met-primary"
						onClickCb={() => dispatch(setDeleteVitalsTestModalOpen(false))}
					>
						No
					</Button>
					<Button
						type="submit"
						variant={BUTTON_VARIANTS.CONTAINED}
						customBtnClass="text-met-primary"
						onClickCb={() => {
							dispatch(deleteVitalsTests({ uuid: currentUUID, deviceName }));
						}}
					>
						Yes
					</Button>
				</div>
			}
			enableReinitialize
			customClasses="w-[40%]"
		>
			<div>
				<p>Do you want to delete this test?</p>
			</div>
		</ModalComponent>
	);
}

export default DeleteVitalsTest;

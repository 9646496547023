import React, { useEffect, useState } from 'react';
import Label from '../../../../../components/common-components/label/Label';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import Table from '../../../../Table/Index';
import Icons from '../../../../../assets/svgs';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import ColorConfigModal from './ColorConfigModal';
import { useDispatch, useSelector } from 'react-redux';
import { getColorConfigDetails } from './ColorConfigSaga';
import { componentKey, setIsColorSelectorModalOpen } from './ColorConfigSlice';
import moment from 'moment';

const ColorConfig = () => {

	const [color, setColor] = useState();
	const [selectedRow, setSelectedRow] = useState(null)

	const dispatch = useDispatch()
	const { colorConfigDetails, isColorSelectorModalOpen } = useSelector((state) => state[componentKey])


	useEffect(() => {
		dispatch(getColorConfigDetails())
	}, [dispatch])


	const columns = [
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Appointment Name</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row.appointmentType}
						</Heading>
					</>
				);
			},

		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Color</Label>,
			renderLogic: (row) => {
				return (
					<span style={{ backgroundColor: row.colour }} className="flex h-[24px] w-[65px] rounded-md"></span>
				);
			},

		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Updated Date</Label>,
			renderLogic: (row) => {
				const formattedDate = moment(row.updatedAt).format('DD-MM-YYYY');
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{formattedDate}
						</Heading>
					</>
				);
			},

		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Updated By</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row?.provider?.firstName} {row?.provider?.lastName}
						</Heading>
					</>
				);
			},

		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Action</Label>,
			renderLogic: (row) => {
				return (
					<div>
						<span className="hover:cursor-pointer flex w-fit" onClick={() => {
							setSelectedRow(row)
							dispatch(setIsColorSelectorModalOpen(true))
						}}>
							<Icons iconName="greyPencilIcon" />
						</span>
					</div>
				);
			},
		},
	];

	const DefaultAppointmentStatus = colorConfigDetails.find(element => element.default)

	return (
		<div className='met-scrollbar overflow-auto'>

			<div className="met-scrollbar">
				<div>
					<Table coloumns={columns} rows={colorConfigDetails} isPagination={false} />
				</div>
				<div className='mt-6 flex'>
					<SelectDropdown value={DefaultAppointmentStatus} onChangeCb={(val) => {setColor(val.colour)}} label='Default Appointment Status' startIcon={<div className={`min-w-[50px] h-[20px] rounded-md`} style={{ backgroundColor: color ? color : DefaultAppointmentStatus?.colour}}
					></div>}
						options={colorConfigDetails}
					/>
				</div>
			</div>
			{isColorSelectorModalOpen && <ColorConfigModal open={isColorSelectorModalOpen} close={() => dispatch(setIsColorSelectorModalOpen(false))} selectedRow={selectedRow} />}
		</div>
	);
};

export default ColorConfig;

import React, { useEffect, useMemo } from 'react'
import CardContainer from '../../../../components/common-components/Cards/Container/CardContainer'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Icons from '../../../../components/icons/Icons'
import FaceSheetCard from '../../../Patients/PatientDashboard/Dashboard/FaceSheet/FaceSheetCard'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey } from './GenrateSuperBillSlice'
import { getPatientDetails } from './GenrateSuperBillSaga'
import General from '../../../../libs/utility/General'

function BillDetails({ uuid }) {

    const { patientDetails } = useSelector((state) => state[componentKey]);
    const dispatch = useDispatch();
    const role = localStorage.getItem('role')

    useEffect(() => {
        if (!uuid) return;
        dispatch(getPatientDetails({ uuid: uuid }))
    }, [dispatch, uuid])


    const PATIENT_DETAILS_INFO = useMemo(() => {
        const { uuid, firstName, lastName, gender, dob, mobileNumber } = patientDetails || {}
        const { Address } = patientDetails || {};
        const { city, state, addressLine1, addressLine2, country, zip } = Address?.[0] || {};
        const formattedDOB = General.getformattedDate(dob)
        const formattedAddress = [addressLine1, addressLine2, city, state, country, zip].join(", ");
        const formattedUUID = uuid?.substring(0, 5)
        const UUID = General.camelToPascalWithSpaces(formattedUUID)
        const Gender = General.camelToPascalWithSpaces(gender)
        return {
            'Patient ID': UUID,
            Name: `${firstName || ""} ${lastName || ""}`,
            Gender: Gender,
            DOB: formattedDOB,
            'Contact Number': mobileNumber,
            Address: formattedAddress
        }
    }, [patientDetails])

    const INSURANCE_INFORMATION = useMemo(() => {
        const { Insurance } = patientDetails
        const { type, name, planName, startDate, endDate } = Insurance?.[0] || {}
        const formattedStartDate = General.getformattedDate(startDate)
        const formattedEndDate = General.getformattedDate(endDate)
        return {
            'Insurance Type': type,
            'Insurance Name': name,
            'Plan Name': planName,
            'Effective Start & End Date': type ? `${formattedStartDate} - ${formattedEndDate}` : "-"
        }
    }, [patientDetails])



    const PROVIDER_INFO = useMemo(() => {
        const { provider } = patientDetails
        const { firstName, lastName, npiNumber, phoneNumber, email } = provider || {}
        const { Address } = provider || {};
        const { city, state, addressLine1, addressLine2, country, zip } = Address?.[0] || {};
        const formattedAddress = [addressLine1, addressLine2, city, state, country, zip].join(", ");
        return {
            'First Name': firstName || "",
            'Last Name': lastName || "",
            'NMC ID': npiNumber,
            'Contact Number': phoneNumber,
            'Email Id': email || "",
            'Address': formattedAddress || ""
        }
    }, [patientDetails])




    return (
        <CardContainer>
            <div className='font-bold text-met-primary'>BILL DETAILS</div>
            <div className='mt-3 flex justify-between items-center'>
                <div>
                    <Heading fontSize="md" onClickCb={() => {

                    }} fontWeight="bold" type={HEADING.H1} customClasses="flex column-gap-10px items-center cursor-pointer">
                        <Icons iconName={"personIcon"} />  Patient Details
                    </Heading>
                </div>
            </div>
            <div className='mt-2'>
                <FaceSheetCard comingFrom={role === 'Biller' ? true :false} paddingTopClass="p-0" customClassName="border-met-primary">
                    {Object.keys(PATIENT_DETAILS_INFO)?.map((key) => (
                        <div key={key} className="flex mb-1">
                            <div className="flex-1 text-rgba-1a1a1acc fs-14 font-semibold">{key}</div>
                            <div className='text-gray-500 font-semibold'>:</div>
                            <div className="flex-1 ml-2 text-met-light-grey fs-14 font-semibold">
                                {PATIENT_DETAILS_INFO[key] ? PATIENT_DETAILS_INFO[key] : '-'}
                            </div>
                        </div>
                    ))}
                </FaceSheetCard>
            </div>

            <div className='mt-3 flex justify-between items-center'>
                <div>
                    <Heading fontSize="md" onClickCb={() => {

                    }} fontWeight="bold" type={HEADING.H1} customClasses="flex column-gap-10px items-center cursor-pointer">
                        <Icons iconName={"healthInsurance"} />  Insurance Information
                    </Heading>
                </div>
            </div>
            <div className='mt-2'>
                <FaceSheetCard comingFrom={role === 'Biller' ? true :false}  paddingTopClass="p-0" customClassName="border-met-primary">
                    {Object.keys(INSURANCE_INFORMATION)?.map((key) => (
                        <div key={key} className="flex mb-1">
                            <div className="flex-1 text-rgba-1a1a1acc fs-14 font-semibold">{key}</div>
                            <div className='text-gray-500 font-semibold'>:</div>
                            <div className="flex-1 ml-2 text-met-light-grey fs-14 font-semibold">
                                {INSURANCE_INFORMATION[key] ? INSURANCE_INFORMATION[key] : '-'}
                            </div>
                        </div>
                    ))}
                </FaceSheetCard>
            </div>

            <div className='mt-3 flex justify-between items-center'>
                <div>
                    <Heading fontSize="md" onClickCb={() => {

                    }} fontWeight="bold" type={HEADING.H1} customClasses="flex column-gap-10px items-center cursor-pointer">
                        <Icons iconName={"primaryProviderIcon"} />  Primary Provider Details
                    </Heading>
                </div>
            </div>
            <div className='mt-2'>
                <FaceSheetCard comingFrom={role === 'Biller' ? true :false}  paddingTopClass="p-0" customClassName="border-met-primary">
                    {Object.keys(PROVIDER_INFO)?.map((key) => (
                        <div key={key} className="flex mb-1">
                            <div className="flex-1 text-rgba-1a1a1acc fs-14 font-semibold">{key}</div>
                            <div className='text-gray-500 font-semibold'>:</div>
                            <div className="flex-1 ml-2 text-met-light-grey fs-14 font-semibold">
                                {PROVIDER_INFO[key] ? PROVIDER_INFO[key] : '-'}
                            </div>
                        </div>
                    ))}
                </FaceSheetCard>
            </div>
        </CardContainer>
    )
}

export default BillDetails

export default {
    bodyContainer: {
        display: "flex",
        flexDirection: "row",
        columnGap: "12px",
        alignItems: "flex-start",
        padding: "0 60px 10px 40px",
        alignItems: "center"
    },

    bodyText: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "32px",
        color: "#262626",
    },

    separatorLine: {
        margin: "22px 40px"
    },

    button: {
        minWidth: "140px",
        minHeight: "40px",
        fontWeight: "600"
    },

    icon: {
        width: "24px",
        height: "24px"
    },

    footer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        borderRadius: "0px 0px 8px 8px",
        columnGap: "12px",
        padding: "8px 40px 35px",
        justifyContent: "center",
        marginTop: "20px"
    },
    cancelFooterButton: {
        background: "red",
        color: "red",
    },
    innerHtmlContent: {
        margin: "0px"
    }
}

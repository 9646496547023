import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import store from '../../store/store';
import SchedulingService from '../../services/SchedulingService';
import {
    componentKey,
    setAddNewPatientData,
    setAllAppointmentsOfCalenderView,
    setAllScheduledAppointments,
    setAllScheduledAppointmentsforLocationlMode,
    setAllScheduledAppointmentsforVirtualMode,
    setAvailableSlotsOfProvider,
    setColorConfigDetailsData,
    setIsAddNewPatientModalOpen,
    setIsAppointmentData,
    setIsAppointmentDetailsModalOpen,
    setIsBlockAppointmentOpen,
    setIsCancelledAppointmentModalOpen,
    setIsCheckedInData,
    setIsDeleteModalOpen,
    setIsInstantModalOpen,
    setIsNewAppointmentModal,
    setIsNoShowModalOpen,
    setIsRescheduleModalOpen,
    setIsSignOffModalOpen,
    setIsSuccessPopUpModalOpen,
    setIsSuccessPopUpModalOpenForNoShow,
    setIsSuccessPopUpModalOpenForReschedule,
    setIsSuccessPopUpModalOpenForSignedOff,
    setPatientList,
    setPatientListPaginationState,
    setProviderListPaginationState,
    setProvidersList,
    setSelectedProvidersLocation,
} from './ShedulingSlice';
import PatientDataService from '../../services/PatientDataServices';
import General from '../../libs/utility/General';
import { TOASTER_VARIANT } from '../../components/common-components/toaster/MetToaster';
import { addNotifications } from '../../components/common-components/toaster/ToasterSlice';
import ColorConfigDataService from '../../services/ColorConfigDataService';
import ProvidersDataService from '../../services/ProvidersDataService';
import { socket } from '../../socket/socket';
import moment from 'moment';
import EncounterDataService from '../../services/EncounterDataService';

export const {
	postAddNewPatient,
	getAllPatients,
	postAppointmentsSlots,
	postCreateNewAppointment,
	getAllScheduleAppointments,
	getColorConfigDetails,
	postScheduleBlockAppointment,
	getProvidersList,
	deleteScheduledAppointment,
	patchScheduledAppointment,
	// getProvidersLocationByProviderId,
	getScheduleById,
	getProviderLocations,
	postInstantAppointmentBooking,
	getAppointmentById,
	getEncounterIdByScheduleId,
    getProviderLocationsByProviderId
} = {
	postAddNewPatient: (payload) => {
		return {
			type: 'APPOINTMENTS/ADD_NEW_PATIENT',
			payload,
		};
	},
	getAllPatients: (payload) => {
		return {
			type: 'APPOINTMENTS/GET_PATIENT_NAMES',
			payload,
		};
	},
	getScheduleById: (payload) => {
		return {
			type: 'APPOINTMENTS/GET_SCHEDULT_BY_ID',
			payload,
		};
	},
	postAppointmentsSlots: (payload) => {
		return {
			type: 'APPOINTMENTS/POST_APPOINTMENT_SLOTS',
			payload,
		};
	},
	postCreateNewAppointment: (payload) => {
		return {
			type: 'APPOINTMENTS/POST_CREATE_NEW_APPOINTMENT',
			payload,
		};
	},
	getAllScheduleAppointments: (payload) => {
		return {
			type: 'APPOINTMENTS/GET_ALL_APPOINTMENTS',
			payload,
		};
	},
	getColorConfigDetails: (payload) => {
		return {
			type: 'USER_PROFILE/GET_COLOR_CONFIG_DETAILS',
			payload,
		};
	},
	postScheduleBlockAppointment: (payload) => {
		return {
			type: 'USER_PROFILE/POST_BLOCK_APPOINTMENT',
			payload,
		};
	},
	getProvidersList: (payload) => {
		return {
			type: 'PROVIDERS_LIST',
			payload,
		};
	},
	deleteScheduledAppointment: (payload) => {
		return {
			type: 'APPOINTMENTS/DELETE_APPOINTMENT',
			payload,
		};
	},
	patchScheduledAppointment: (payload) => {
		return {
			type: 'APPOINTMENTS/UPDATE_APPOINTMENT',
			payload,
		};
	},
	getProviderLocations: (payload) => {
		return {
			type: 'APPOINTMENTS/GET_LOCATIOND_OF_PROVIDER',
			payload,
		};
	},
	postInstantAppointmentBooking: (payload) => {
		return {
			type: 'APPOINTMENTS/POST_INSTANT_BOOKING',
			payload,
		};
	},
	getAppointmentById: (payload) => {
		return {
			type: 'APPOINTMENTS/GET_APPOINTMENT_BY_ID',
			payload,
		};
	},
	
	getEncounterIdByScheduleId: (payload) => {
		return {
			type: "APPOINTMENTS/GET_ENCOUNTER_ID_SCHEDULE_ID",
			payload
		}
	},
    getProviderLocationsByProviderId: (payload) => {
        return {
            type: "PROVIDER_LOCATION_BY_PROVIDER_ID",
            payload
        }
    }

};

function* postAddNewPatientAsync(action) {
	try {
		const { addNewPatientData } = action.payload;
		yield put(setAddNewPatientData({}));
		const response = yield SchedulingService.postAddNewPatient(addNewPatientData);
		if (response.status === 201) {
			yield put(addNotifications({ message: response?.data?.message, variant: TOASTER_VARIANT.SUCCESS }));
			yield put(setIsAddNewPatientModalOpen(false));
		}
	} catch (error) {
		console.log(error);
	}
}
function* getAllPatientsAsync(action) {
	try {
		const params = action.payload;

		const response = yield PatientDataService.getPatientGridList({ params });
		const { data, status } = response.data;

		if (status) {
			const { totalRecords } = data || {};
			const patientList = General.addLableValuePair(data.patients, 'firstName', 'uuid', 'lastName');
			yield put(setPatientList(patientList));
			yield put(setPatientListPaginationState({ total: totalRecords }));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* getScheduleByIdAsync(action) {
	try {
		const { scheduleId } = action.payload;
		const response = yield SchedulingService.getScheduleById(scheduleId);
		const { data, status } = response?.data || {};

		if (status) {
			yield put(setIsCheckedInData(data));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* postAppointmentsSlotsAsync(action) {
	try {
		const { patientId, appointmentType, locationId, providerId, startDate, mode } = action.payload;
		const data = {
			providerId,
			locationId,
			patientId,
			mode,
			startDate,
			appointmentType,
		};
		const response = yield SchedulingService.postAppointmentsSlots(data);
		yield put(setAvailableSlotsOfProvider(response?.data));
	} catch (error) {
		yield put(addNotifications({ message: error?.response.data.message, variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
	}
}

function* postCreateNewAppointmentAsync(action) {
	try {
		const { createNewAppointment, schedulingAppointmentsPaginationState, selectedPatientData, selectedProvider } =
			action.payload;
		const data = {
			...schedulingAppointmentsPaginationState,

			dateTo: moment(schedulingAppointmentsPaginationState?.dateTo).format('YYYY-MM-DD') || undefined,
			dateFrom: moment(schedulingAppointmentsPaginationState?.dateFrom).format('YYYY-MM-DD') || undefined,
			location: schedulingAppointmentsPaginationState?.location.join(',') || undefined,
			patientId: schedulingAppointmentsPaginationState?.patientId || undefined,
			providerId: schedulingAppointmentsPaginationState?.providerId.join(',') || undefined,
			appointmentType: (schedulingAppointmentsPaginationState?.appointmentType) || undefined,
			status: (schedulingAppointmentsPaginationState?.status || '').trim() || undefined,
		};

		let response = yield SchedulingService.postCreateNewAppointment(createNewAppointment);
		if (response.status === 201) {
			yield put(setIsNewAppointmentModal(false));
			yield put(setAvailableSlotsOfProvider({}));
			yield put(addNotifications({ message: response?.data?.message, variant: TOASTER_VARIANT.SUCCESS }));
			yield call(getAllScheduleAppointmentsAsync, getAllScheduleAppointments({ ...data, mode: 'virtual' }));
			yield call(getAllScheduleAppointmentsAsync, getAllScheduleAppointments({ ...data, mode: 'location' }));

			const notificationObject = {
				uuid: createNewAppointment.patientId,
			};

			const providerNotificationObject = {
				uuid: createNewAppointment.providerId,
			};
			socket.emit('sendNotificationToPatient', notificationObject);
			socket.emit('sendNotificationToProvider', providerNotificationObject);
		}
	} catch (error) {
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }));

		console.log('err', error);
	}
}
function* getAllScheduleAppointmentsAsync(action) {
	try {
		const params = action.payload;
		const response = yield SchedulingService.getAllScheduleAppointments({ params });
		if (response.status === 200) {
			if (params.mode === 'location') {
				yield put(setAllScheduledAppointmentsforLocationlMode(response?.data?.data?.schedule));
			}
			if (params.mode === 'virtual') {
				yield put(setAllScheduledAppointmentsforVirtualMode(response?.data?.data?.schedule));
			}
			yield put(setAllAppointmentsOfCalenderView(response?.data?.schedule));
		}
	} catch (error) {
		console.log('err', error);
	}
}

function* getColorConfigDetailsAsync() {
	try {
		const response = yield ColorConfigDataService.getColorConfig();
		const { data, status } = response.data;
		const colorConfigData = General.addLableValuePair(data, 'appointmentType', 'colour');

		if (status) {
			yield put(setColorConfigDetailsData(colorConfigData));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* postScheduleBlockAppointmentAsync(action) {
	try {
		const { blockAppointmentData } = action.payload;
		const response = yield SchedulingService.postScheduleBlockAppointment(blockAppointmentData);
		if (response.status === 201) {
			yield put(setIsBlockAppointmentOpen(false));
			yield put(
				addNotifications({ message: 'Appointment Blocked Successfully', variant: TOASTER_VARIANT.SUCCESS })
			);
		}
	} catch (error) {
		console.log('err', error);
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }));
	}
}
function* providersListAsync(action) {
	try {
		const params = action.payload;

		const response = yield ProvidersDataService.getProvidersList({ params });
		const { data, status } = response.data;

		if (status) {
			const { totalRecords } = data || {};

			const providerlist = General.addLableValuePair(data, 'firstName', 'uuid', 'lastName');
			yield put(setProvidersList(providerlist));
			yield put(setProviderListPaginationState({ total: totalRecords }));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}
function* deleteScheduledAppointmentAsync(action) {
	const { scheduleId, schedulingAppointmentsPaginationState } = action.payload;
	const data = {
		...schedulingAppointmentsPaginationState,

		dateTo: moment(schedulingAppointmentsPaginationState?.dateTo).format('YYYY-MM-DD') || undefined,
		dateFrom: moment(schedulingAppointmentsPaginationState?.dateFrom).format('YYYY-MM-DD') || undefined,
		location: schedulingAppointmentsPaginationState?.location.join(',') || undefined,
		patientId: schedulingAppointmentsPaginationState?.patientId || undefined,
		providerId: schedulingAppointmentsPaginationState?.providerId.join(',') || undefined,
		appointmentType: (schedulingAppointmentsPaginationState?.appointmentType) || undefined,
		status: (schedulingAppointmentsPaginationState?.status || '').trim() || undefined,
	};
	try {
		const response = yield SchedulingService.deleteScheduledAppointment(scheduleId);
		if (response.status === 200) {
			yield put(setIsAppointmentDetailsModalOpen(false));
			// yield put(getAllScheduleAppointments());
			yield put(addNotifications({ message: response?.data?.message, variant: TOASTER_VARIANT.SUCCESS }));
			yield put(setIsDeleteModalOpen(false));
			yield call(getAllScheduleAppointmentsAsync, getAllScheduleAppointments({ ...data, mode: 'virtual' }));
			yield call(getAllScheduleAppointmentsAsync, getAllScheduleAppointments({ ...data, mode: 'location' }));
		}
	} catch (error) {
		console.log('err', error);
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }));
	}
}

function* patchScheduledAppointmentAsync(action) {
	const {
		scheduleId,
		editScheduledData,
		schedulingAppointmentsPaginationState,
		selectedProvider,
		selectedPatientData,
	} = action.payload;
	const data = {
		...schedulingAppointmentsPaginationState,

		dateTo: moment(schedulingAppointmentsPaginationState?.dateTo).format('YYYY-MM-DD') || undefined,
		dateFrom: moment(schedulingAppointmentsPaginationState?.dateFrom).format('YYYY-MM-DD') || undefined,
		location: schedulingAppointmentsPaginationState?.location.join(',') || undefined,
		patientId: schedulingAppointmentsPaginationState?.patientId || undefined,
		providerId: schedulingAppointmentsPaginationState?.providerId.join(',') || undefined,
		appointmentType: (schedulingAppointmentsPaginationState?.appointmentType) || undefined,
		status: (schedulingAppointmentsPaginationState?.status || '').trim() || undefined,
	};
	try {
		const response = yield SchedulingService.patchScheduledAppointment(scheduleId, editScheduledData);
		if (response.status === 200) {
			yield put(setIsSuccessPopUpModalOpenForReschedule(true));
			yield put(setIsSuccessPopUpModalOpenForSignedOff(true));
			yield put(setIsSuccessPopUpModalOpenForNoShow(true));
			yield put(setIsAppointmentDetailsModalOpen(false));
			yield put(setIsSuccessPopUpModalOpen(true));
			yield put(setIsRescheduleModalOpen(false));
			yield put(setIsCancelledAppointmentModalOpen(false));
			yield put(setIsSignOffModalOpen(false));
			yield put(setIsNoShowModalOpen(false));
			// yield put(addNotifications({ message: response?.data?.message, variant: TOASTER_VARIANT.SUCCESS }));
			yield call(getAllScheduleAppointmentsAsync, getAllScheduleAppointments({ ...data, mode: 'virtual' }));
			yield call(getAllScheduleAppointmentsAsync, getAllScheduleAppointments({ ...data, mode: 'location' }));

			const notificationObject = {
				uuid: selectedPatientData.uuid,
			};

			const providerNotificationObject = {
				uuid: editScheduledData.providerId,
			};
			socket.emit('sendNotificationToPatient', notificationObject);
			socket.emit('sendNotificationToProvider', providerNotificationObject);
		}
	} catch (error) {
		console.log(error);
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }));
	}
}

function* getProviderLocationsAsync(action) {
	const { providerId } = action.payload;
	try {
		const response = yield ProvidersDataService.getProviderLocations(providerId);
		const { data, success } = response.data;
		if (success) {
			yield put(setSelectedProvidersLocation(data?.provider_location_intermediate));
		}
	} catch (err) {
		console.log('err', err);
	}
}

function* postInstantAppointmentBookingAsync(action) {
	const { data, schedulingAppointmentsPaginationState } = action.payload;
	const payload = {
		...schedulingAppointmentsPaginationState,

		dateTo: moment(schedulingAppointmentsPaginationState?.dateTo).format('YYYY-MM-DD') || undefined,
		dateFrom: moment(schedulingAppointmentsPaginationState?.dateFrom).format('YYYY-MM-DD') || undefined,
		location: schedulingAppointmentsPaginationState?.location.join(',') || undefined,
		patientId: schedulingAppointmentsPaginationState?.patientId || undefined,
		providerId: schedulingAppointmentsPaginationState?.providerId.join(',') || undefined,
		appointmentType: (schedulingAppointmentsPaginationState?.appointmentType) || undefined,
		status: (schedulingAppointmentsPaginationState?.status || '').trim() || undefined,
	};
	try {
		const response = yield SchedulingService.postInstantAppointmentBooking(data);

		if (response.status === 201) {
			yield put(setIsInstantModalOpen(false));
			yield put(addNotifications({ message: response?.data?.message, variant: TOASTER_VARIANT.SUCCESS }));
			yield call(getAllScheduleAppointmentsAsync, getAllScheduleAppointments({ ...payload, mode: 'virtual' }));
			yield call(getAllScheduleAppointmentsAsync, getAllScheduleAppointments({ ...payload, mode: 'location' }));
		}
	} catch (error) {
		console.log(error);
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }));
	}
}

function* getAppointmentByIdAsync(action) {
	const { scheduleId } = action.payload;
	try {
		const response = yield SchedulingService.getAppointmentById(scheduleId);
		yield put(setIsAppointmentData(response?.data?.data));
	} catch (error) {
		console.log(error);
	}
}

function* getEncounterIdByScheduleIdAsync(action) {
	try {
		const { data, navigate } = action.payload
		const response = yield EncounterDataService.getEncounterIdByScheduleId(data);
		const { status, encounter } = response.data

		if (status) {
			navigate(`/encounter/${data?.patinetId}/${encounter?.uuid}/clinical-data`)
		}
	} catch (error) {
		console.log(error);
	}
}

function* getProviderLocationsByProviderIdAsync(action) {
    const { providerId } = action.payload;
    try {
        const response = yield ProvidersDataService.getProviderLocationsByProviderId(providerId);
        
        const { data, status } = response.data;
        if (status) {
            yield put(setSelectedProvidersLocation(data));
        }
    } catch (err) {
        console.log('err', err);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(postAddNewPatient().type, postAddNewPatientAsync),
        takeLatest(getAllPatients().type, getAllPatientsAsync),
        takeLatest(postAppointmentsSlots().type, postAppointmentsSlotsAsync),
        takeLatest(postCreateNewAppointment().type, postCreateNewAppointmentAsync),
        takeEvery(getAllScheduleAppointments().type, getAllScheduleAppointmentsAsync),
        takeLatest(getColorConfigDetails().type, getColorConfigDetailsAsync),
        takeLatest(postScheduleBlockAppointment().type, postScheduleBlockAppointmentAsync),
        takeLatest(getProvidersList().type, providersListAsync),
        takeLatest(deleteScheduledAppointment().type, deleteScheduledAppointmentAsync),
        takeLatest(patchScheduledAppointment().type, patchScheduledAppointmentAsync),
        // takeLatest(getProvidersLocationByProviderId().type, getProvidersLocationByProviderIdAsync),
        takeLatest(getScheduleById().type, getScheduleByIdAsync),
        takeLatest(getProviderLocations().type, getProviderLocationsAsync),
        takeLatest(postInstantAppointmentBooking().type, postInstantAppointmentBookingAsync),
        takeLatest(getAppointmentById().type, getAppointmentByIdAsync),
        takeLatest(getEncounterIdByScheduleId().type, getEncounterIdByScheduleIdAsync),
        takeLatest(getProviderLocationsByProviderId().type, getProviderLocationsByProviderIdAsync),

    ]);
}
store.sagaManager.addSaga(componentKey, rootSaga);

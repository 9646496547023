import store from '../../../../store/store';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { componentKey, setAllergiesData, setAllergyData, setAllergyStatus, setIsAllergyModalOpen, setIsOpenAllergiesDeletePopup } from './PatientAllergiesSlice';
import PatientDashboardDataService from '../../../../services/PatientDashboardDataService';
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/MetToaster';


export const {
	getAllPatientAllergiesDetails,
	postPatientAllergies,
	deletePatientAllergies,
	getPatientAllergyDetails,
	updatePatientAllergy,
} = {
	getAllPatientAllergiesDetails: (payload) => {
		return {
			type: 'PATIENTDASHBOARD/ALLERGIES',
			payload,
		};
	},
	postPatientAllergies: (payload) => {
		return {
			type: 'POST/PATIENTDASHBOARD/ALLERGIES',
			payload,
		};
	},
	deletePatientAllergies: (payload) => {
		return {
			type: 'DELETE/PATIENTDASHBOARD/ALLERGIES',
			payload,
		};
	},
	getPatientAllergyDetails: (payload) => {
		return {
			type: 'GET/PATIENTDASHBOARD/ALLERGIES',
			payload,
		};
	},
	updatePatientAllergy: (payload) => {
		return {
			type: 'UPDATE/PATIENTDASHBOARD/ALLERGIES',
			payload,
		};
	},
};

function* getAllPatientAllergiesDetailsAsync(action) {
	try {
		const { patientId = '', page = '1', limit = '10', allergyStatus } = action.payload;
		const params = {
			page: page,
			limit: limit,
			...(allergyStatus === 'All' ? {} : { status: allergyStatus })
		};

		const response = yield PatientDashboardDataService.getAllPatientAllergies(
			'allergies/patient',
			patientId,
			params
		);

		const { message, status, data } = response?.data || {};

		if (status) {
			const allergies = data?.allergies.map((allergy, index) => {
				return {
					...allergy, index
				}
			})
			yield put(setAllergiesData(allergies));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* postPatientAllergiesAsync(action) {
	try {
		const { patientId, allergyData } = action.payload;
		const response = yield PatientDashboardDataService.postPatientAllergies('allergies', patientId, allergyData);
		const { message, status, data } = response?.data || {};
		if (status) {
			yield put(setAllergiesData(data?.allergies));
			yield put(setIsAllergyModalOpen(false));
			yield put(setAllergyStatus({ label: 'All', value: 'All' }));
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			yield call(getAllPatientAllergiesDetailsAsync, {
				type: 'PATIENTDASHBOARD/ALLERGIES',
				payload: { patientId },
			});
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* deletePatientAllergiesDetailsAsync(action) {
	try {
		const { patientId = '', allergyId = '' } = action.payload;

		const response = yield PatientDashboardDataService.deletePatientAllergies('allergies', allergyId, patientId);

		const { message, status, data } = response?.data || {};

		if (status) {
			yield put(setAllergyStatus({ label: 'All', value: 'All' }));
			yield put(setIsOpenAllergiesDeletePopup(false))
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			yield call(getAllPatientAllergiesDetailsAsync, {
				type: 'PATIENTDASHBOARD/ALLERGIES',
				payload: { patientId },
			});
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* getPatientAllergyDetailsAsync(action) {
	try {
		yield put(setAllergyData([]));
		const { patientId = '', allergyId } = action.payload;

		const response = yield PatientDashboardDataService.getPatientAllergy('allergies', allergyId, patientId);

		const { message, status, data } = response?.data || {};

		if (status) {
			yield put(setAllergyData(data));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* updatePatientAllergyDetailsAsync(action) {
	try {
		const { patientId = '', allergyId = '', allergyData = {} } = action.payload;

		const response = yield PatientDashboardDataService.updatePatientAllergy(
			'allergies',
			allergyId,
			patientId,
			allergyData
		);

		const { message, status, data } = response?.data || {};

		if (status) {
			yield put(setIsAllergyModalOpen(false));
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			yield put(setAllergyStatus({ label: 'All', value: 'All' }));
			yield call(getAllPatientAllergiesDetailsAsync, {
				type: 'PATIENTDASHBOARD/ALLERGIES',
				payload: { patientId },
			});
		}
	} catch (error) {
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
		console.log('err: ', error);
	}
}

function* rootSaga() {
	yield all([
		takeLatest(getAllPatientAllergiesDetails().type, getAllPatientAllergiesDetailsAsync),
		takeLatest(postPatientAllergies().type, postPatientAllergiesAsync),
		takeLatest(deletePatientAllergies().type, deletePatientAllergiesDetailsAsync),
		takeLatest(getPatientAllergyDetails().type, getPatientAllergyDetailsAsync),
		takeLatest(updatePatientAllergy().type, updatePatientAllergyDetailsAsync),
	]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

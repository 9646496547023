import store from "../../../store/store"

export const componentKey = 'INSURANCE_DETAILS'

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setIsOpenEditInsuranceModal: (state, action) => {
            state.isOpenEditInsuranceModal = action.payload
        },
    },
    initialReducerState: {
        isOpenEditInsuranceModal: false
    }
})

export const { setIsOpenEditInsuranceModal } = actions
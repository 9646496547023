import store from '../../../../store/store';

export const componentKey = 'PATIENTVACCINES_SECTION';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setAllVaccinesData: (state, action) => {
            state.allVaccinesData = action.payload;
        },
        setIsVaccinesModalOpen: (state, action) => {
            state.isVaccinesModalOpen = action.payload;
        },
        setIsNewVaccineAdd: (state, action) => {
            state.isNewVaccineAdd = action.payload;
        },
        setVaccineData: (state, action) => {
            state.vaccineData = action.payload;
        },
        setVaccineId: (state, action) => {
            state.currentVaccineId = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        setIsOpenVaccinDeletePopup: (state, action) => {
            state.isOpenVaccinDeletePopup = action.payload;
        },
    },
    initialReducerState: {
        allVaccinesData: [],
        isVaccinesModalOpen: false,
        isNewVaccineAdd: false,
        vaccineData: {},
        currentVaccineId: '',
        activeTab: 'ADMINISTERED',
        isOpenVaccinDeletePopup: false
    },
});

export const { setAllVaccinesData, setIsVaccinesModalOpen, setIsNewVaccineAdd, setVaccineData, setVaccineId, setActiveTab, setIsOpenVaccinDeletePopup } = actions;

import React, { forwardRef, useEffect, useState } from 'react';
import '../ProviderInformation/styles.scss';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import DatePicker from '../../../components/common-components/datePicker';
import { Form, Formik } from 'formik';
import { getValidationSchema } from '../../../libs/formsUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
	componentKey,
	setEditDemographicsData,
	setCreatePatientData,
	setIsFormSubmitted,
} from '../../../pages/Patients/AddPatient/AddPatientSlice';
import IsAccessAllowed from '../../Wrappers/IsAccessAllowed';
import { USER_ROLE_NAMES } from '../../Wrappers/Constants';
import { componentKey as routerComponentKey } from '../../../routes/RoutesSlice';
import { componentKey as dataLoaderComponentKey } from '../../Wrappers/Dataloader/DataloaderSlice';
import { getProviderGroupLocation, updatePatientDetails } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import { useParams, useSearchParams } from 'react-router-dom';
import { componentKey as schedulingKey } from '../../../pages/Scheduling/ShedulingSlice';
import Input from '../../../components/common-components/input/Input';
import { getAppointmentById } from '../../../pages/Scheduling/SchedulingSaga';
import moment from 'moment';
import { FORM_FIELDS_FOR_CHECK_IN } from '../ProviderInformation/Constants';
import General from '../../../libs/utility/General';

const fields = [
	// { fieldName: FORM_FIELDS_FOR_CHECK_IN.PRIMARY_PROVIDER, isDropdown: true },
	// { fieldName: FORM_FIELDS_FOR_CHECK_IN.PROVIDER_GROUP_LOCATION, isDropdown: true },
	// { fieldName: FORM_FIELDS_FOR_CHECK_IN.REGISTRATION_DATE, isRequired: true },
];

const validationSchema = getValidationSchema(fields);

const PatientAppointment = forwardRef(function PatientAppointment(props, formRef) {
	const { patientId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const scheduleId = searchParams.get('scheduleId');

	const dispatch = useDispatch();
	const [isAdminUser, setIsAdminUser] = useState(false);
	const [selectedProvider, setSelectedProvider] = useState({});


	const { loggedInUser, loggedInProviderUser } = useSelector((state) => state[routerComponentKey]);
	const { providersList } = useSelector((state) => state[dataLoaderComponentKey]);
	const { providerGroupLocation, fetchedPatientDetails, isFormSubmitted, createPatientData } = useSelector(
		(state) => state[componentKey]
	);

	const { appointmentData } = useSelector((state) => state[schedulingKey]);

	const formattedDate = moment(appointmentData?.date).format('ddd, MMM DD, YYYY');
	useEffect(() => {
		const allowedRoles = [USER_ROLE_NAMES.ADMIN_PROVIDER];

		const currentRole = loggedInProviderUser?.roleNames || [];
		setIsAdminUser(IsAccessAllowed(allowedRoles, currentRole));
	}, [loggedInProviderUser]);

	useEffect(() => {
		if (!isAdminUser) {
			setSelectedProvider({ label: loggedInUser.name, value: loggedInUser?.uuid });
		}
	}, [isAdminUser, loggedInUser]);

	useEffect(() => {
		if (selectedProvider.value) {
			dispatch(getProviderGroupLocation(selectedProvider.value));
		}
	}, [selectedProvider, dispatch]);

	useEffect(() => {
		dispatch(getAppointmentById({ scheduleId: scheduleId }));
	}, []);

	return (
		<Formik
			innerRef={formRef}
			initialValues={{
				[FORM_FIELDS_FOR_CHECK_IN.PROVIDER_ID]: General.getLabelValue(appointmentData?.Provider?.uuid),
				[FORM_FIELDS_FOR_CHECK_IN.LOCATION_ID]: General.getLabelValue(appointmentData?.Locations?.uuid),
				[FORM_FIELDS_FOR_CHECK_IN.APPOINTMENT_TYPE]: General.getLabelValue(appointmentData?.appointmentType),
				[FORM_FIELDS_FOR_CHECK_IN.DATE]: appointmentData?.date,
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				dispatch(setEditDemographicsData({ appointment: values }));
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, ...props }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<div className="flex justify-between">
							<div className="w-[24%]">
								<Input
									label="Appointment Date & Time"
									name={FORM_FIELDS_FOR_CHECK_IN.DATE}
									onChangeCb={() => { }}
									value={formattedDate}
									disabled={true}
								/>
							</div>

							{appointmentData?.Locations && (
								<div className="w-[24%]">
									<Input
										label="Location"
										name={FORM_FIELDS_FOR_CHECK_IN.LOCATION_ID}
										onChangeCb={() => { }}
										value={appointmentData?.Locations?.name}
										disabled={true}
									/>
								</div>
							)}
							<div className="w-[24%]">
							<SelectDropdown
                                label="Appointment Type"
                                placeholder="Select"
                                isRequired={true}
                                name={FORM_FIELDS_FOR_CHECK_IN.APPOINTMENT_TYPE}
                                value={values[FORM_FIELDS_FOR_CHECK_IN.APPOINTMENT_TYPE]}
                                onChangeCb={(val) => {
                                    return setFieldValue(FORM_FIELDS_FOR_CHECK_IN.APPOINTMENT_TYPE, val)}
                                }

                                options={[
                                    { label: 'New Appointment', value: 'New_Appointment' },
                                    { label: 'Follow Up', value: 'Follow_Up' },
                                ]}
                            />
							</div>
							<div className="w-[24%]">
								<SelectDropdown
									label="Rendering Provider"
									placeholder="Select Provider"
									isRequired={true}
									name={FORM_FIELDS_FOR_CHECK_IN.PROVIDER_ID}
									value={General.getLabelValue(appointmentData?.Provider?.uuid)}
									onChangeCb={(val) => {
										setFieldValue(FORM_FIELDS_FOR_CHECK_IN.PROVIDER_ID, val);
										setSelectedProvider(val);
									}}
									options={providersList}
									disabled={true}
								// disabled={!isAdminUser || isFormSubmitted.providerInformation}
								/>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
});


export default PatientAppointment
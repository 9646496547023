import { all, put, takeLatest } from 'redux-saga/effects';
import store from '../../../../../store/store';
import {
	componentKey,
	setAllProviderGroupLocation,
	setCurrentProvidersBillingAddressUUID,
	setCurrentProvidersPhysicalAddressUUID,
	setCurrentProvidersUUID,
	setEditedProfileData,
	setIsEditProfileModalOpen,
	setProviderGroupDetails,
} from './ProfileSlice';
import ProviderGroupDataService from '../../../../../services/ProviderGroupDataService';
import ProvidersDataService from '../../../../../services/ProvidersDataService';
import { addNotifications } from '../../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../../components/common-components/toaster/MetToaster';

export const { getProviderGroupDetails, getAllProviderGroupLocations, updateProfileData } = {
	getProviderGroupDetails: (payload) => {
		return {
			type: 'PROVIDER_GROUP/GET_PROVIDER_GROUP_DETAILS',
			payload,
		};
	},
	getAllProviderGroupLocations: (payload) => {
		return {
			type: 'PROVIDER_GROUP/GET_PROVIDER_GROUP_DETAILS',
			payload,
		};
	},
	updateProfileData: (payload) => {
		return {
			type: 'PROVIDER_GROUP/UPDATE_PROFILE_DATA',
			payload,
		};
	},
};

function* getProviderGroupDetailsAsync(action) {
	try {
		const response = yield ProviderGroupDataService.getProviderGroupDetails(action.payload);
		const {
			data: [firstElement],
		} = response.data;
		yield put(setCurrentProvidersUUID(response?.data?.data[0]?.uuid));
		yield put(setCurrentProvidersPhysicalAddressUUID(response?.data?.data[0]?.physicalAddress?.uuid));
		yield put(setCurrentProvidersBillingAddressUUID(response?.data?.data[0].billingAddress.uuid));
		yield put(setProviderGroupDetails(firstElement));
		yield put(setEditedProfileData(firstElement))
	} catch (error) {
		console.log('err: ', error);
	}
}

function* getAllProviderGroupLocationsAsync(action) {
	try {
		const response = yield ProvidersDataService.getAllProviderLocation(action.payload);
		const { data, status } = response.data;

		if (status) {
			const { locations } = data || [];
			yield put(setAllProviderGroupLocation(locations));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}
function* updateProfileDataAsync(action) {
	try {
		const { uuid, editedProfileData, currentProviderBillingAddresssUUID, currentProvidersPhysicalAddressUUID, isSameAsPhysicalAddress } =
		action.payload;
		const workHours = editedProfileData?.workingHours.map((item, index) => {
			return{
				dayOfWeek: item?.dayOfWeek,
				openingTime: item?.openingTime,
				closingTime: item?.closingTime
			}
		})
			const data = {
			avatar: editedProfileData?.avatar,
			groupName: editedProfileData?.groupName,
			phone: editedProfileData?.phone,
			email: editedProfileData?.email,
			mlc: editedProfileData?.mlc,
			fax: editedProfileData?.fax,
			website: editedProfileData?.website,
			description: editedProfileData?.description,
			physicalAddress: {
				uuid: currentProvidersPhysicalAddressUUID,
				address_line_1: editedProfileData?.physicalAddress1,
				address_line_2: editedProfileData?.physicalAddress2,
				city: editedProfileData?.physicalAddressCity,
				state: editedProfileData?.physicalAddressState.value,
				zip: editedProfileData?.physicalAddressZip,
				country: editedProfileData?.physicalAddressCountry,
			},
			billingAddress: {
				uuid: currentProviderBillingAddresssUUID,
				address_line_1: editedProfileData?.billingAddress1,
				address_line_2: editedProfileData?.billingAddress2,
				city: editedProfileData?.billingAddressCity,
				state: editedProfileData?.billingAddressState.value,
				zip: editedProfileData?.billingAddressZip,
				country: editedProfileData?.billingAddressCountry,
			},
			providerGroupSpecialities: editedProfileData?.speciality,
			workingHours: workHours,
			information: editedProfileData?.information,
			isSame:isSameAsPhysicalAddress
			// path: 'string',
		};

		const response = yield ProvidersDataService.updateProfileData(uuid, data);
		if (response.status) {
			yield put(setIsEditProfileModalOpen(false));
			yield put(getProviderGroupDetails());
			yield put(addNotifications({ message: 'Profile updated succesfully', variant: TOASTER_VARIANT.SUCCESS }));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* rootSaga() {
	yield all([
		takeLatest(getProviderGroupDetails().type, getProviderGroupDetailsAsync),
		takeLatest(getAllProviderGroupLocations().type, getAllProviderGroupLocationsAsync),
		takeLatest(updateProfileData().type, updateProfileDataAsync),
	]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

import React from 'react'

function VisaIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="28" viewBox="0 0 56 28">
  <g id="Group_94795" data-name="Group 94795" transform="translate(-17324 3800)">
    <rect id="Rectangle_37267" data-name="Rectangle 37267" width="56" height="28" rx="5" transform="translate(17324 -3800)" fill="#fff"/>
    <g id="visa_1_" data-name="visa(1)" transform="translate(17329.842 -3792.991)">
      <path id="Path_206905" data-name="Path 206905" d="M184.8,193.059l2.25-12.659h3.516l-2.18,12.659Z" transform="translate(-168.554 -179.978)" fill="#3c58bf"/>
      <path id="Path_206906" data-name="Path 206906" d="M184.8,193.059l2.883-12.659h2.883l-2.18,12.659Z" transform="translate(-168.554 -179.978)" fill="#293688"/>
      <path id="Path_206907" data-name="Path 206907" d="M258.76,176.163a8.919,8.919,0,0,0-3.235-.563c-3.516,0-6.048,1.758-6.048,4.29,0,1.9,1.758,2.883,3.165,3.516s1.829,1.055,1.829,1.618c0,.844-1.125,1.266-2.11,1.266a7.644,7.644,0,0,1-3.376-.7l-.492-.211L248,188.259a11.4,11.4,0,0,0,4.009.7c3.727,0,6.189-1.758,6.189-4.431,0-1.477-.914-2.6-3.024-3.516-1.266-.633-2.039-.985-2.039-1.618,0-.563.633-1.125,2.039-1.125a6.45,6.45,0,0,1,2.672.492l.352.141.563-2.743Z" transform="translate(-226.198 -175.6)" fill="#3c58bf"/>
      <path id="Path_206908" data-name="Path 206908" d="M258.76,176.163a8.919,8.919,0,0,0-3.235-.563c-3.516,0-5.415,1.758-5.415,4.29a3.676,3.676,0,0,0,2.532,3.516c1.407.633,1.829,1.055,1.829,1.618,0,.844-1.125,1.266-2.11,1.266a7.644,7.644,0,0,1-3.376-.7l-.492-.211L248,188.259a11.4,11.4,0,0,0,4.009.7c3.727,0,6.189-1.758,6.189-4.431,0-1.477-.914-2.6-3.024-3.516-1.266-.633-2.039-.985-2.039-1.618,0-.563.633-1.125,2.039-1.125a6.45,6.45,0,0,1,2.672.492l.352.141.563-2.743Z" transform="translate(-226.198 -175.6)" fill="#293688"/>
      <path id="Path_206909" data-name="Path 206909" d="M365.5,180.4c-.844,0-1.477.07-1.828.914L358.4,193.059h3.8l.7-2.11h4.5l.422,2.11H371.2L368.246,180.4Zm-1.618,8.439c.211-.633,1.407-3.727,1.407-3.727s.281-.774.492-1.266l.211,1.2s.7,3.165.844,3.868h-2.954Z" transform="translate(-326.893 -179.978)" fill="#3c58bf"/>
      <path id="Path_206910" data-name="Path 206910" d="M366.347,180.4c-.844,0-1.477.07-1.829.914L358.4,193.059h3.8l.7-2.11h4.5l.422,2.11H371.2L368.246,180.4Zm-2.461,8.439c.281-.7,1.407-3.727,1.407-3.727s.281-.774.492-1.266l.211,1.2s.7,3.165.844,3.868h-2.954Z" transform="translate(-326.893 -179.978)" fill="#293688"/>
      <path id="Path_206911" data-name="Path 206911" d="M56.475,189.991l-.352-1.829a10.2,10.2,0,0,0-4.923-5.556l3.165,11.252h3.8l5.7-12.659h-3.8Z" transform="translate(-46.699 -180.708)" fill="#3c58bf"/>
      <path id="Path_206912" data-name="Path 206912" d="M56.475,189.991l-.352-1.829a10.2,10.2,0,0,0-4.923-5.556l3.165,11.252h3.8l5.7-12.659H60.765Z" transform="translate(-46.699 -180.708)" fill="#293688"/>
      <path id="Path_206913" data-name="Path 206913" d="M0,180.4l.633.141c4.5,1.055,7.6,3.727,8.791,6.892l-1.266-5.978c-.211-.844-.844-1.055-1.618-1.055Z" transform="translate(0 -179.978)" fill="#ffbc00"/>
      <path id="Path_206914" data-name="Path 206914" d="M0,180.4H0c4.5,1.055,8.228,3.8,9.424,6.962l-1.2-4.993a1.717,1.717,0,0,0-1.688-1.336Z" transform="translate(0 -179.978)" fill="#f7981d"/>
      <path id="Path_206915" data-name="Path 206915" d="M0,180.4H0c4.5,1.055,8.228,3.8,9.424,6.962L8.58,184.62A2.48,2.48,0,0,0,7.1,182.58Z" transform="translate(0 -179.978)" fill="#ed7c00"/>
      <g id="Group_94785" data-name="Group 94785" transform="translate(4.571 1.829)">
        <path id="Path_206916" data-name="Path 206916" d="M60.721,203.433l-2.391-2.391L57.2,203.714l-.281-1.758A10.2,10.2,0,0,0,52,196.4l3.165,11.252h3.8Z" transform="translate(-52 -196.4)" fill="#051244"/>
        <path id="Path_206917" data-name="Path 206917" d="M188.387,292.294l-3.024-3.094-.563,3.094Z" transform="translate(-173.126 -281.042)" fill="#051244"/>
        <path id="Path_206918" data-name="Path 206918" d="M254.118,274.8h0c.281.281.422.492.352.774,0,.844-1.125,1.266-2.11,1.266a7.643,7.643,0,0,1-3.376-.7l-.492-.211L248,278.809a11.4,11.4,0,0,0,4.009.7,6.963,6.963,0,0,0,5.134-1.758Z" transform="translate(-230.77 -267.908)" fill="#051244"/>
        <path id="Path_206919" data-name="Path 206919" d="M364,228.813h3.305l.7-2.11h4.5l.422,2.11h3.376l-1.2-5.134-4.22-4.079.211,1.125s.7,3.165.844,3.868h-2.954c.281-.7,1.406-3.727,1.406-3.727s.281-.774.492-1.266" transform="translate(-336.572 -217.561)" fill="#051244"/>
      </g>
    </g>
  </g>
</svg>
  )
}

export default VisaIcon
export const cardContainerData = [
	{ title: 'Day Slot Creation', isButton: true, actionName: 'Add Day Slot', valuesPrefix: "daySlots", namePrefix: "daySlots" },
	{ title: 'Slot Creation Settings', isButton: false, actionName: '', valuesPrefix: "slotCreation", namePrefix: "slotCreation" },
	{ title: 'Block Days', isButton: true, actionName: 'Add Block Day', valuesPrefix: "blockDays", namePrefix: "blockDays" },
];

export const appointmentTime = [
	{ id: 15, label: '15' },
	{ id: 30, label: '30' },
	{ id: 45, label: '45' },
	{ id: 60, label: '60' },
	{ id: 75, label: '75' },
	{ id: 90, label: '90' },
	{ id: 105, label: '105' },
	{ id: 120, label: '120' },
];

export const bufferTimes = [
	{ id: 0, label: '0' },
	{ id: 15, label: '15' },
	{ id: 30, label: '30' },
	{ id: 45, label: '45' },
];

export const appointmentArray = [
	{ label: 'New Appointment Consult Time (minutes)', isAppointment: true },
	{ label: 'Follow Up Appointment Consult Time (minutes)', isAppointment: false },
];

const times = [
	{ value: '12:00', label: '12:00 AM' },
	{ value: '12:30', label: '12:30 AM' },
	{ value: '1:00', label: '1:00 AM' },
	{ value: '1:30', label: '1:30 AM' },
	{ value: '2:00', label: '2:00 AM' },
	{ value: '2:30', label: '2:30 AM' },
];

const locations = [
	{ label: 'Virtual', value: 'virtual' },
	{ label: 'Location 2', value: 'location2' },
	{ label: 'Location 3', value: 'location3' },
	{ label: 'Location 4', value: 'location4' },
	{ label: 'Location 5', value: 'location5' },
	{ label: 'Location 6', value: 'location6' },
	{ label: 'Location 7', value: 'location7' },
]

export const editAvailabilityScheduleAppData = [
	{ time: '11:45 AM', pName: 'Alex Smith', isvirtual: true },
	{ time: '12:30 PM', pName: 'Jenny crew', isvirtual: false },
	{ time: '01:15 PM', pName: 'Alex Smith', isvirtual: true },
	{ time: '02:45 PM', pName: 'Luna Dey', isvirtual: false },
	{ time: '03:30 PM', pName: 'Amy Peralta', isvirtual: true },
]

export const FORM_FIELDS_NAMES = {
	PROVIDER_ID: 'providerId',
	BOOKING_WINDOW: 'bookingWindow',
	MINIMUM_SCHEDULING_THRESHOLD: 'minimumSchedulingThreshold',
	MINIMUM_SCHEDULING_THRESHOLD_DESCRIPTION: 'minimumSchedulingThresholdDescription',
	AVAILABILITY_TYPE: 'availabilityType',
	AVAILABILITY_LOCATION: 'availabilityLocation',
	DAY: 'day',
	START_TIME: 'startTime',
	END_TIME: 'endTime',
	BLOCK_DAY: 'blockDay',
	BLOCK_START_TIME: 'blockStartTime',
	BLOCK_END_TIME: 'blockEndTime',
	SLOT_BOOKING_WINDOW: 'slotBookingWindow',
	SLOT_TIME_zONE: 'slotTimeZone',
	NEW_APPOINTMENT: 'newAppointmentTime',
	FOLLOW_UP_APPOINTMENT: 'followUpAppointmentTime',
	BUFFER_TIME: 'bufferTime',
	AVAILABILITY_LOCATION_ID: 'availabilityLocationId'
}

export const firstRow = [
	{ placeholder: 'Select Provider', label: 'Select Provider', name: FORM_FIELDS_NAMES.PROVIDER_ID },
	// { placeholder: 'Select', label: 'Availability Type', name: FORM_FIELDS_NAMES.AVAILABILITY_TYPE },
	// { placeholder: 'Select Location', label: 'Availability Location', name: FORM_FIELDS_NAMES.AVAILABILITY_LOCATION },
];

import React, { useEffect } from 'react';
import UserBox from './UserBox';
import Input from '../../../../../components/common-components/input/Input';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setFilters, setResetFilters } from './UsersSlice';
import { getAllProviders, getAllProvidersWorkLocations } from './UsersSaga';
import { FORM_FIELDS_NAMES_FOR_FILTERS } from './constant';
import { Form, Formik } from 'formik';
import { getValidationSchema } from '../../../../../libs/formsUtils';
const Users = () => {
	const { isFilterOpen, allProviders, filters } = useSelector((state) => state[componentKey]);
	const dispatch = useDispatch();
	const fields = [];
	const validationSchema = getValidationSchema(fields);

	useEffect(() => {
		dispatch(getAllProviders({ type: "user" }));
		dispatch(getAllProvidersWorkLocations());
	}, [dispatch]);

	return (
		<Formik
			initialValues={{
				[FORM_FIELDS_NAMES_FOR_FILTERS.NAME]: filters?.name || '',
				[FORM_FIELDS_NAMES_FOR_FILTERS.ROLE]: filters?.role || '',
				[FORM_FIELDS_NAMES_FOR_FILTERS.DEPARTMENT]: filters?.department || '',
				[FORM_FIELDS_NAMES_FOR_FILTERS.STATUS]: filters?.status || '',
				type: 'user'
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				dispatch(setFilters(values))
				dispatch(getAllProviders({ filters: values }));
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<div>
							{isFilterOpen && (
								<div className="flex justify-between w-full p-[20px] border-[1px] border-[#1A1A1A33] rounded-[10px] border-opacity-25">
									<div className="w-[25%]">
										<Input
											label="Search By Name"
											// isRequired={true}
											placeholder="Search Name"
											name={FORM_FIELDS_NAMES_FOR_FILTERS.NAME}
											value={values[FORM_FIELDS_NAMES_FOR_FILTERS.NAME]}
											onChangeCb={(e) => {
												setFieldValue(FORM_FIELDS_NAMES_FOR_FILTERS.NAME, e.target.value);
											}}
										/>
									</div>
									<div className="w-[25%]">
										<SelectDropdown
											placeholder="Select"
											label="Search By Role"
											isRequired={false}
											name={FORM_FIELDS_NAMES_FOR_FILTERS.ROLE}
											value={values[FORM_FIELDS_NAMES_FOR_FILTERS.ROLE]}
											onChangeCb={(val) => {
												setFieldValue(FORM_FIELDS_NAMES_FOR_FILTERS.ROLE, val.value);
											}}
											options={[
												{ label: 'Provider Admin', value: 'Provider Admin' },
												{ label: 'Provider', value: 'Provider' },
												{
													label: 'Biller',
													value: 'Biller',
												},
											]}
										/>
									</div>
									{/* <div className="w-[25%]">
										<SelectDropdown
											placeholder="Select"
											label="Search By Department"
											isRequired={false}
											name={FORM_FIELDS_NAMES_FOR_FILTERS.DEPARTMENT}
											value={values[FORM_FIELDS_NAMES_FOR_FILTERS.DEPARTMENT]}
											onChangeCb={(val) => {
												setFieldValue(FORM_FIELDS_NAMES_FOR_FILTERS.DEPARTMENT, val.value);
											}}
											options={[
												{ label: 'All', value: 'all' },
												{ label: 'Ophthalmology', value: 'ophthalmology' },
												{
													label: 'Cardiothoracic Surgery',
													value: 'cardiothoracicSurgery',
												},
											]}
										/>
									</div> */}
									<div className="w-[25%]">
										<SelectDropdown
											placeholder="Select"
											label="Search By Status"
											isRequired={false}
											name={FORM_FIELDS_NAMES_FOR_FILTERS.STATUS}
											value={values[FORM_FIELDS_NAMES_FOR_FILTERS.STATUS]}
											onChangeCb={(val) => {
												setFieldValue(FORM_FIELDS_NAMES_FOR_FILTERS.STATUS, val.value);
											}}
											options={[
												{ label: 'All', value: 'all' },
												{ label: 'Active', value: 'active' },
												{
													label: 'Inactive',
													value: 'inactive',
												},
											]}
										/>
									</div>
									<div className="mt-5">
										<Button
											variant={BUTTON_VARIANTS.OUTLINED}
											customBtnClass="text-met-primary "
											onClickCb={handleSubmit}
										>
											Apply Filter
										</Button>
									</div>
								</div>
							)}
							<div className="flex flex-wrap column-gap-10px">
								<UserBox allProviders={allProviders} />
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
export default Users;

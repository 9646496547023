import store from '../../../../store/store';

export const componentKey = 'PATIENTMEDICATIONS_SECTION';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setMedicationId: (state, action) => {
			state.currentMedicationId = action.payload;
		},
		setAllCurrentMedicationsData: (state, action) => {
			state.allCurrentMedicationsData = action.payload;
		},
		setAllPastMedicationsData: (state, action) => {
			state.allPastMedicationsData = action.payload;
		},
		setIsMedicationsModalOpen: (state, action) => {
			state.isMedicationModalOpen = action.payload;
		},
		setIsNewMedicationAdd: (state, action) => {
			state.isNewMedicationAdd = action.payload;
		},
		setMedicationData: (state, action) => {
			state.medicationData = action.payload;
		},
		setIsOpenMedicationDeletePopup: (state, action) => {
			state.isOpenMedicationDeletePopup = action.payload;
		},
	},
	initialReducerState: {
		currentMedicationId: '',
		allCurrentMedicationsData: [],
		allPastMedicationsData: [],
		isMedicationModalOpen: false,
		isNewMedicationAdd: false,
		medicationData: {},
		isOpenMedicationDeletePopup: false
	},
});

export const { setAllCurrentMedicationsData, setAllPastMedicationsData, setIsMedicationsModalOpen, setIsNewMedicationAdd, setMedicationId, setMedicationData, setIsOpenMedicationDeletePopup } = actions;

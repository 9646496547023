const EmailIcon = ({ color = '#1b5984' }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="email_black_24dp_2_"
			data-name="email_black_24dp (2)"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path id="Path_186599" data-name="Path 186599" d="M0,0H24V24H0Z" fill={'none'} />
			<path
				id="Path_186600"
				data-name="Path 186600"
				d="M22,6a2.006,2.006,0,0,0-2-2H4A2.006,2.006,0,0,0,2,6V18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2ZM20,6l-8,5L4,6Zm0,12H4V8l8,5,8-5Z"
				// fill="#1b5984"
				fill={color}
			/>
		</svg>
	);
};

export default EmailIcon;

import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { MAIN_ROUTES } from '../../routes/Routes'
import Button from '../../components/common-components/button/Button'
import useGetCurrenPathHook from '../../libs/customHooks/useGetCurrenPathHook'
import General from '../../libs/utility/General'
import { BUTTON_VARIANTS } from '../../components/common-components/button/Constants'
import Icons from '../../components/icons/Icons'
import ButtonDropdown from '../../components/common-components/buttonDropdown'
import Drawer from '../../components/common-components/drawer'
import Sidebar from '../Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { setLoggedInProviderUser, setLoggedInUser } from '../../routes/RoutesSlice'
import Avatar from '../../components/common-components/avatar'
import { componentKey as routerComponentKey } from '../../routes/RoutesSlice'
import Logo from '../../components/common-components/logo/Logo'
import { getLogInInfo } from '../../pages/Authentication/LoginSaga'
import { componentKey } from '../../pages/Authentication/LoginSlice'
import AvatarComponent from '../../components/common-components/zoom/components/avatarComponent/AvatarComponent'
import ShowForAllowedPermissionsOnly from '../Wrappers/ShowForAllowedPermissionsOnly'
import NotificationDropdown from '../Notification/NotificationDropdown'
import { socket } from '../../socket/socket'
import { setNotificationList } from '../../socket/socketSlice'
import { getAllNotifications } from '../../socket/socketSaga'

const Navbar = () => {
    const currrentPath = useGetCurrenPathHook()
    const navigate = useNavigate()
    const { loggedInUser } = useSelector(state => state[routerComponentKey])
    const { logInDetails } = useSelector(state => state[componentKey])
    const role = localStorage.getItem('role')

    const [openSideDrawer, setOpenSideDrawer] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const onCloseCb = (isOpen) => {
        setOpenSideDrawer(isOpen)
    }

    useEffect(() => {
        dispatch(getLogInInfo())
    }, [dispatch])

    const logoutUser = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        dispatch(setLoggedInUser({}))
        dispatch(setLoggedInProviderUser({}))
        navigate("/login")
    }

    useEffect(() => {
        if (!logInDetails[0]?.groupName) return
        document.title = logInDetails[0]?.groupName;
    }, [logInDetails])

    useEffect(() => {
        socket.on("getProviderNotifications", (msg) => {
            dispatch(getAllNotifications({ providerId: loggedInUser.uuid }))
            // dispatch(setNotificationList(msg))
        })

        return () => {
            socket.off("getProviderNotifications")
        }
    }, [])

    useEffect(() => {
        if (loggedInUser) {
            dispatch(getAllNotifications({ providerId: loggedInUser.uuid }))
        }
    }, [loggedInUser])

    const logopath = logInDetails[0]?.logopath;

    return (
        <header className='bg-met-primary flex min-h-[60px] max-h-[60px]'>
            <div className="mx-auto flex flex-1 items-center justify-between gap-5">
                <div className='text-2xl font-extrabold text-white block md:hidden cursor-pointer' onClick={() => { setOpenSideDrawer(true) }}><Icons iconName={"threeDotMenu"} /></div>
                <div>{<Avatar customClass='mx-3 px-2 flex items-center max-h-[40px] object-fill w-full' url={logopath} />}</div>
                <nav className="hidden md:flex flex-auto flex-3 gap-1 column-gap-16 items-center text-base align-baseline">
                    {MAIN_ROUTES.map((route, index) => (
                        <React.Fragment key={"navbar-tab-item-" + index}>
                            {route?.children ?
                                (<div className={`hover:border-b-2 hover:border-solid hover:border-white hover:rounded-none ${splitLocation.includes(General.convertStringToURLPath(route.label).slice(1)) ? "border-b-2 border-solid border-white rounded-none" : ""}`} >
                                    <ButtonDropdown openOnHover={true} customBtnClass="text-white p-0" label={route.label} options={route.children} selectCb={(selectedOpt) => { navigate(selectedOpt.path) }} />
                                </div>)
                                : !(role === 'Biller' && route?.label === 'Scheduling') && route.label &&
                                (<Link className={`/${splitLocation[1]}` === General.convertStringToURLPath(route.label) ? "border-b-2 border-solid border-white rounded-none text-white no-underline px-4" : 'text-white no-underline px-4' + " hover:border-b-2 hover:border-solid hover:border-white hover:rounded-none"} to={General.convertStringToURLPath(route.label)}>
                                    <Button variant={BUTTON_VARIANTS.TEXT} customBtnClass="p-0"> {route.label}</Button>
                                </Link>)}
                        </React.Fragment>
                    ))}
                </nav>
                <div className='flex flex-1 justify-end items-center column-gap-10px'>
                    <NotificationDropdown />
                    <div>
                        <ButtonDropdown
                            customBtnClass="p-0"
                            endIcon="expandIcon"
                            label={<Avatar customClass='rounded-full' width={32} height={32} url={loggedInUser?.profilePicture || ""} name={loggedInUser?.name || ""} />}
                            options={[
                                { label: "Profile", value: null, prefixProps: { icon: "userIcon" }, onClickCb: () => navigate("/settings/my-account/my-profile") },
                                {
                                    label: "Log Out", value: null, prefixProps: { icon: "logoutIcon" }, onClickCb: logoutUser
                                }
                            ]}
                            selectCb={(selectedOpt) => { selectedOpt.onClickCb() }} />
                    </div>
                </div>
            </div>
            {openSideDrawer ? <Drawer isOpen={openSideDrawer} onCloseCb={onCloseCb} >
                <Sidebar options={MAIN_ROUTES} customBtnClass='w-full py-2 m-1' checkIsActivePath={(option) => `${splitLocation[1]}` === General.convertStringToURLPath(option.label).slice(1) ? true : false} onClickCb={(item) => { navigate(General.convertStringToURLPath(item.label)); onCloseCb() }} />
            </Drawer> : null}
        </header >
    )
}

export default Navbar
import React from 'react';
import { useSelector } from 'react-redux';
import FaceSheetCard from './FaceSheetCard';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import { useNavigate } from 'react-router-dom';
import { componentKey as PatientHistoryComponentKey } from "../../History/PatientHistorySlice";
import General from '../../../../../libs/utility/General';

const PastMedicalHistoryCard = () => {
	const navigate = useNavigate()
	const { allMedicalHistoryData } = useSelector((state) => state[PatientHistoryComponentKey])

	const pastMedicalHistoryData = allMedicalHistoryData.slice(0, 2).map((history, index) => {
		return {
			id: index + 1,
			title: history.conditionName,
			subtitle: `Onset Date: ${General.getformattedDate(history?.onsetDate)}`
		}
	})

	return (
		<FaceSheetCard
			title="PAST MEDICAL HISTORY"
			comingFrom ={true}
			iconbtn={
				<Button
					variant={BUTTON_VARIANTS.TEXT}
					customBtnClass="fs-14 font-bold text-met-primary"
					style={{ height: '19px' }}
					onClickCb={() => navigate("../history/past-medical")}
				>
					View All
				</Button>
			}
			data={pastMedicalHistoryData}
			customClassName="min-w-515"
		/>
	);
};

export default PastMedicalHistoryCard;

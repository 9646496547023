
const RespirationIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" width="34" height="34" viewBox="0 0 34 34">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_35873" data-name="Rectangle 35873" width="24" height="24" transform="translate(0.066 0.066)" fill="#1b5984" stroke="#707070" strokeWidth="1" opacity="0" />
            </clipPath>
        </defs>
        <g id="Group_87032" data-name="Group 87032" transform="translate(-1483 -756)">
            <g id="Rectangle_35872" data-name="Rectangle 35872" transform="translate(1483 756)" fill="#ccecff" stroke="#0097f0" strokeWidth="1">
                <rect width="34" height="34" rx="3" stroke="none" />
                <rect x="0.5" y="0.5" width="33" height="33" rx="2.5" fill="none" />
            </g>
            <g id="Group_87031" data-name="Group 87031" transform="translate(-80.066 252.934)">
                <g id="Mask_Group_66661" data-name="Mask Group 66661" transform="translate(1568 508)" clipPath="url(#clip-path)">
                    <g id="noun-lungs-1067515" transform="translate(1.203 1.203)">
                        <path id="Path_205298" data-name="Path 205298" d="M30.2,15.868c-1.345-6.353-4.83-8.507-6.838-8.406-1.333.067-1.842,1.689-2.087,3.355a37.4,37.4,0,0,1,.052-7.7.463.463,0,0,0-.46-.513H19.119a.463.463,0,0,0-.46.513,35.058,35.058,0,0,1-.012,8.166c-.22-1.817-.693-3.748-2.151-3.822C14.483,7.364,11,9.516,9.66,15.868c-.918,4.333-.8,7.044.348,8.055a1.7,1.7,0,0,0,1.146.425,2.506,2.506,0,0,0,.828-.153c.413-.144.941-.3,1.5-.472,2.9-.873,5.136-1.643,5.351-2.924a31.815,31.815,0,0,0,.155-5.167,3.408,3.408,0,0,0,1-.964,3.363,3.363,0,0,0,.881.883,32.206,32.206,0,0,0,.151,5.248c.214,1.282,2.45,2.051,5.351,2.924.559.168,1.087.327,1.5.471a1.871,1.871,0,0,0,1.974-.272c1.15-1.012,1.267-3.722.349-8.055Zm-.961,7.359c-.093.082-.376.331-1.057.093-.432-.151-.97-.312-1.539-.484-1.6-.481-4.568-1.375-4.7-2.189a32.284,32.284,0,0,1-.129-5.323.463.463,0,0,0-.231-.421,2.072,2.072,0,0,1-1.124-1.777.463.463,0,1,0-.926,0A2.1,2.1,0,0,1,18.3,14.958a.463.463,0,0,0-.251.432,31.954,31.954,0,0,1-.132,5.257c-.136.815-3.108,1.71-4.7,2.19-.569.171-1.106.333-1.539.484-.681.238-.964-.011-1.057-.093-.4-.351-1.2-1.751-.054-7.168,1.192-5.633,4.136-7.676,5.786-7.676l.1,0c1.046.053,1.294,2.979,1.413,4.385.017.2.033.393.05.562a.463.463,0,0,0,.817.25c1.525-1.845,1.083-8.094.9-10.053h.725c-.162,1.813-.58,7.825.761,9.843a.463.463,0,0,0,.847-.214L22,12.768c.119-1.4.368-4.328,1.411-4.381,1.628-.08,4.669,1.928,5.885,7.673,1.147,5.417.344,6.817-.055,7.168Z" transform="translate(-9.049 -2.605)" fill="#1b5984" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

export default RespirationIcon
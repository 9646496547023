import React from 'react';
import { useSelector } from 'react-redux'
import { componentKey } from '../ZoomIntegrationSlice';

const VideoTimer = () => {

    const { videoDuration } = useSelector(state => state[componentKey])

    const formatTime = (milliseconds) => {
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        const formattedHours = hours > 0 ? pad(hours) : '';
        const formattedMinutes = pad(minutes % 60);
        const formattedSeconds = pad(seconds % 60);

        return `${formattedHours !== '' ? formattedHours + ':' : ''}${formattedMinutes}:${formattedSeconds}`;
    };

    const pad = (num) => {
        return num < 10 ? '0' + num : num;
    };

    return (
        <div className='video-timer'>{formatTime(videoDuration)}</div>
    );
};

export default VideoTimer;

import React from 'react'
import Label from '../../components/common-components/label/Label'
import Button from '../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../components/common-components/button/Constants'
import Input from '../../components/common-components/input/Input'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { getValidationSchema } from '../../libs/formsUtils'
import { FORM_FIELDS_NAMES } from './Constants'
import { useDispatch, useSelector } from 'react-redux'
import { forgotUserPassword } from './LoginSaga'
import Loadable from '../../components/common-components/zoom/components/loadable/Loadable'
import { componentKey } from './LoginSlice'

const fields = [
    { fieldName: FORM_FIELDS_NAMES.EMAIL, isRequired: true, isEmail: true },
];

const validationSchema = getValidationSchema(fields)

function Forgot() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { loginLoadingState } = useSelector(state => state[componentKey])
    return (
        <div className='flex flex-1 items-center justify-center'>
            < Formik
                initialValues={{ [FORM_FIELDS_NAMES.EMAIL]: "" }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    dispatch(forgotUserPassword(values))
                }
                }
            >
                {({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, handleBlur }) => {
                    return <Form onSubmit={handleSubmit} >
                        <div className='flex flex-1 items-center justify-center'>
                            <div className='w-[500px] h-[300px] flex flex-col justify-evenly'>
                                <div className='text-met-primary'>
                                    <Label fontSize={"xl"} fontWeight='bold'>Forgot Password</Label>
                                </div>
                                <div>
                                    <Input placeholder={"Email"}
                                        name={FORM_FIELDS_NAMES.EMAIL}
                                        value={values[FORM_FIELDS_NAMES.EMAIL]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.EMAIL, e.target.value) }}
                                        onBlurCb={handleBlur} />
                                </div>
                                <Loadable loadingStates={loginLoadingState.state} loadingMessage={loginLoadingState.message}>
                                    <div>
                                        <Button type='submit' variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary w-full justify-center">Send Reset Password Link</Button>
                                    </div>
                                </Loadable >
                                <div className='flex justify-center'>
                                    <div className='text-met-primary ml-2'>
                                        <Label onChangeCb={() => { navigate("/login") }}>Back to Sign in</Label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                }}
            </Formik>
        </div >
    )

}

export default Forgot
import React, { useEffect, useMemo } from 'react';
import Label from '../../../../../components/common-components/label/Label';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import { useDispatch, useSelector } from 'react-redux';
import ToggleSwitchWtLabel from '../../../../../components/common-components/toggleSwitch/ToggleswitchWtLabel';
import Icons from '../../../../../assets/svgs';
import Table from '../../../../Table/Index';
import { componentKey } from '../Profile/ProfileSlice';
import profileDetailsImage from '../../../../../assets/images/profileDetailsImage.png';
import {
	componentKey as departmentkey,
	setEditDepartmentData,
	setIsDepartmentModalOpen,
	setIsNewDepartmentAdd,
} from './DepartmentsSlice';
import { getAllDepartments } from './DepartmentsSaga';
import ToggleSwitch from '../../../../../components/common-components/toggleSwitch/ToggleSwitch';
import { getProviderGroupDetails } from '../Profile/ProfileSaga';
import Avatar from '../../../../../components/common-components/avatar';

const Departments = () => {
	const { allDepartments } = useSelector((state) => state[departmentkey]);
	const { providerGroupDetails } = useSelector((state) => state[componentKey]);
	const { providerGroupSpecialities } = providerGroupDetails || {};
	const dispatch = useDispatch();

	const PROFILE_INFO_LABELS = useMemo(() => {
		const { email, phone, mlc, website } = providerGroupDetails;
		const { physicalAddress } = providerGroupDetails;
		const { address_line_1, address_line_2, city, state, country, zip } = physicalAddress || {};
		return {
			MLC: mlc,
			Website: website || '',
			'Contact Number': phone,
			Email: email,
			'Physical Address': `${address_line_1}, ${address_line_2}, ${city}, ${zip}, ${state}, ${country}`,
		};
	}, [providerGroupDetails]);

	const specialitiesNames = useMemo(() => {
		if (!providerGroupSpecialities) {
			return [];
		}
		return providerGroupSpecialities.map((item) => item.speciality.name);
	}, [providerGroupSpecialities]);

	useEffect(() => {
		dispatch(getProviderGroupDetails());
		dispatch(getAllDepartments());
	}, [dispatch]);

	const handleEditClick = (row) => {
		dispatch(setIsDepartmentModalOpen(true));
		dispatch(setIsNewDepartmentAdd(false));
		dispatch(setEditDepartmentData(row));
	};

	const columns = [
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Department ID</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading
							onClickCb={() => {
								// navigate(row.uuid);
							}}
							fontWeight="bold"
							type={HEADING.H1}
							color="met-primary"
							fontSize={'sm'}
							customClasses="cursor-pointer"
						>
							{row?.departmentId}
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Department Name</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row?.departmentName}
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Department Admin</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{/* {row?.departmentAdmin} */}
							{`${row?.Provider?.firstName} ${row?.Provider?.lastName}`}
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Contact Number</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row?.contactNumber}
						</Heading>
					</>
				);
			},
			sort: 'name',
		},

		{
			field: 'pcp',
			label: <Label fontWeight="bold">Status</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{/* <div className="w-[50%] p-1 bg-[#1A1A1A0F] rounded-[50px] flex">
								{/* <ToggleSwitch label={'action'} checked={row.status === true ? true : false} /> */}
							{/* </div> */}
							<span
								className={`${row.status === true
									? 'text-met-success border-b-[1px] border-b-met-success'
									: 'text-met-red border-b-[1px] border-b-met-red'
									}`}
							>
								{row.status === true ? 'Active' : 'Inactive'}
							</span>
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
		{
			field: 'Action',
			label: <Label fontWeight="bold">Action</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							<span className="hover:cursor-pointer flex w-fit" onClick={() => handleEditClick(row)}>
								<Icons iconName="greyPencilIcon" />
							</span>
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
	];

	return (
		<div>
			<div
				className="flex bg-white w-[45%] p-3"
				style={{ borderRadius: '10px', border: '1px solid #1A1A1A33' }}
			>
				<div className="flex flex-col">
					<Avatar
						customClass="w-[100px] h-[100px] overflow-hidden object-cover rounded-full"
						url={providerGroupDetails?.path}
					// name={`${item.firstName} ${item.lastName}`}
					/>
					{/* <img
						src={profileDetailsImage}
						className="w-[100px] h-[100px] overflow-hidden object-cover"
						style={{ borderRadius: '50%' }}
						alt="profileDetailsImage"
					/> */}
					<p className="mt-4 text-2xl text-rgba-1A1A1A font-bold">{providerGroupDetails.groupName}</p>
					<div className="pt-4 flex column-gap-16 flex-wrap row-gap-10px">
						{specialitiesNames?.length > 1 ? (
							<>
								<p className="font-semibold p-1 inline-block  bg-rgba-CCECFF4D text-met-primary">
									Multispecialist
								</p>
							</>
						) : specialitiesNames?.length === 1 ? (
							<>
								{specialitiesNames?.map((name, index) => (
									<p
										key={'provider-specialities-index' + index}
										className="inline-block bg-rgba-CCECFF4D px-4 py-2 rounded-md font-semibold text-met-primary"
									>
										{name}
									</p>
								))}
							</>
						) : null}
					</div>
				</div>
				<div className="flex flex-col ml-[60px] w-[55%]">
					{Object.keys(PROFILE_INFO_LABELS).map((key) => (
						<div key={key} className="flex  mt-7">
							<div className="w-1/2 text-met-light-grey font-bold">{key}</div>
							<div className="w-1/2 text-rgba-1a1a1acc font-bold">{PROFILE_INFO_LABELS[key]}</div>
						</div>
					))}
				</div>
			</div>
			<div className="mt-[10px] overflow-y-scroll met-scrollbar" style={{ height: '35vh' }}>
				<Table coloumns={columns} rows={allDepartments} />
			</div>
		</div>
	);
};
export default Departments;


import React from 'react'
import Button from '../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../components/common-components/button/Constants';
import { useNavigate } from 'react-router-dom';
import Icons from '../../components/icons/Icons';

function PasswordSuccessPage({ children, successMesaage, onClickOk }) {
    const navigate = useNavigate()
    return (
        <div className="w-full h-full flex items-center flex-col  justify-center">
            <div
                style={{
                    height: 332,
                    width: 332,
                }}
                className="rounded-lg p-6 mt-8 flex flex-col items-center justify-center"
            >
                <Icons iconName="successSvg" />
            </div>
            <div className="mt-8 text-xl font-semibold">Password Reset Successfully</div>
            <div className='text-gray-500'>Your password has been successfully changed. </div>
        </div>
    );
}

export default PasswordSuccessPage
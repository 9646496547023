import { Chart } from "chart.js/auto";
import React, { useEffect } from 'react'

export const VitalsLineChart = ({ xValues = [], selectedVital, isBPVital, yAxisValues, yAxisValues2 }) => {

    useEffect(() => {

        const ctx = document.getElementById("myChart").getContext("2d");
        const existingChart = Chart.getChart(ctx);
        if (existingChart) {
            existingChart.destroy();
        }

        const newChart = new Chart(ctx, {
            type: "line",
            data: {
                labels: xValues,
                datasets: [
                    {
                        label: isBPVital ? "sys" : selectedVital?.value,
                        data: yAxisValues
                    },

                    ...(isBPVital ? [{
                        label: isBPVital ? "dai" : selectedVital?.value,
                        data: yAxisValues2


                    }] : []),
                ],
            },
            options: {
                cutout: "75%",
                animation: {
                    animateScale: true,
                },
                plugins: {
                    title: {
                        display: true,
                    },
                    legend: {
                        display: false,
                        position: "right",
                    },
                },
            },
        });
        return () => {
            newChart.destroy();
        };
    }, [yAxisValues, yAxisValues2, xValues]);

    return (
        <div className="item-center flex-wrap flex justify-center w-full">
            <canvas id="myChart" className="max-h-[50vh]"></canvas>
        </div>
    )
}

import { useState, useCallback, useEffect } from 'react';

export function useNetworkQuality(zmClient) {
    const [networkQuality, setNetworkQuality] = useState({});

    const onNetworkChange = useCallback((payload) => {
        const { userId, type, level } = payload;
        setNetworkQuality((prevNetworkQuality) => {
            const updatedNetworkQuality = { ...prevNetworkQuality };
            if (updatedNetworkQuality[userId] !== undefined) {
                if (type === 'uplink') {
                    updatedNetworkQuality[userId].uplink = level;
                } else {
                    updatedNetworkQuality[userId].downlink = level;
                }
            } else {
                updatedNetworkQuality[userId] = {
                    [type]: level
                };
            }
            return updatedNetworkQuality;
        });
    }, []);

    useEffect(() => {
        zmClient.on('network-quality-change', onNetworkChange);
        return () => {
            zmClient.off('network-quality-change', onNetworkChange);
        };
    }, [zmClient, onNetworkChange]);

    return networkQuality;
}

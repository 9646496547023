import React from 'react';
import { componentKey, setIsDeleteModalOpen } from '../ShedulingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { deleteScheduledAppointment } from '../SchedulingSaga';
import DeletePopup from '../../../components/common-components/deletePopup/DeletePopup';
const DeleteAppointmentModal = ({ appointmentDetails }) => {
	const { isDeleteModalOpen, schedulingAppointmentsPaginationState } = useSelector((state) => state[componentKey]);
	const dispatch = useDispatch();
	return (
		<>
			<DeletePopup
				alertMessage={`Are you sure you want to delete the appointment of ${appointmentDetails?.Patient?.firstName} ${appointmentDetails?.Patient?.lastName}`}
				open={isDeleteModalOpen}
				onClickOk={() => { dispatch(deleteScheduledAppointment({ scheduleId: appointmentDetails.uuid, schedulingAppointmentsPaginationState })) }}
				okButton='Delete'
				cancelButton='Cancel'
				onClickCancel={() => {
					dispatch(setIsDeleteModalOpen(false))
				}}
			/>
		</>

	);
};
export default DeleteAppointmentModal;

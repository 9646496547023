export const FORM_FIELDS_NAMES = {
	NAME: 'name',
	LOCATION_ID: 'locationId',
	PLACEOFSERVICE: 'placeOfService',
	SPECIALITY_TYPE: 'specialityType',
	EMAIL: 'email',
	GROUP_NPI_NUMBER: 'groupNpiNumber',
	FAX: 'fax',
	INFORMATION: 'information',
	TIMEZONE: 'timezone',
	CONTACT_NUMBER: 'contactNumber',
	CONTACT_PERSON: 'contactPerson',
	PHYSICAL_ADDRESS_1: 'physicalAddress1',
	PHYSICAL_ADDRESS_2: 'physicalAddress2',
	PHYSICAL_ADDRESS_CITY: 'physicalAddressCity',
	PHYSICAL_ADDRESS_STATE: 'physicalAddressState',
	PHYSICAL_ADDRESS_COUNTRY: 'physicalAddressCountry',
	PHYSICAL_ADDRESS_ZIP: 'physicalAddressZip',
	BILLING_ADDRESS_1: 'billingAddress1',
	BILLING_ADDRESS_2: 'billingAddress2',
	BILLING_ADDRESS_CITY: 'billingAddressCity',
	BILLING_ADDRESS_STATE: 'billingAddressState',
	BILLING_ADDRESS_COUNTRY: 'billingAddressCountry',
	BILLING_ADDRESS_ZIP: 'billingAddressZip',
	NUMBER: 'number',
	SPECIALITY: 'speciality',
	LOCATION_LOGO: 'image',
	LOCATION_PATH: 'imagePath',
	OFFICE_HOURS:"officeHours"

};

import AppDataService from "./AppDataService"

const COMMON_BASE = 'notifications'
const COMMON_BASE_2 = 'notification-log'

export default class NotificationDataService {
    static async getNotificationType() {
        return await AppDataService.get(`${COMMON_BASE}`)
    }
    static async updateNotificationType(data) {
        return await AppDataService.patch(`${COMMON_BASE}`, data)
    }
    static async getAllNotifications(providerId) {
        return await AppDataService.get(`${COMMON_BASE_2}/details?providerId=${providerId}&limit=${75}`)
    }
    static async patchMarkNotificationAsRead(notificationId) {
        return await AppDataService.patch(`${COMMON_BASE_2}/view/${notificationId}`, {})
    }
    static async patchMarkClearNotification(providerId) {
        return await AppDataService.patch(`${COMMON_BASE_2}/clear/${providerId}`, {})
    }
}
import React from 'react'

const BgBlurCard = ({ children, height = 30, width = "full" }) => {
    return (
        <div className={`absolute bottom-0 h-[${height}%] ${width !== "full" ? `w-[${width}%]` : "w-full"} bg-gradient-to-t from-gray-500 to-transparent backdrop-filter backdrop-blur-xl`}>
            <div className="w-full h-full bg-gradient-to-b from-gray-400 to-transparent">
                {children}
            </div>
        </div>
    )
}

export default BgBlurCard
import React, { useEffect } from 'react'
import ZoomIntegration from '../../../components/common-components/zoom/ZoomIntegration'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { getAppointmentScheduleDetails } from '../../../components/common-components/zoom/ZoomIntegrationSaga';
import { componentKey } from '../../../components/common-components/zoom/ZoomIntegrationSlice';

const PatientVideoCall = () => {

    const dispatch = useDispatch()
    const { appointmentDetailsId, type, tenetId } = useParams()
    const { scheduleAppointmentDetails } = useSelector(state => state[componentKey])

    useEffect(() => {
        if (appointmentDetailsId) {
            dispatch(getAppointmentScheduleDetails({ scheduleId: appointmentDetailsId, tenetId }))
        }
    }, [appointmentDetailsId])

    return (
        <>
            <ZoomIntegration
                userType={type || "Patient"}
                appointmentDetails={scheduleAppointmentDetails.data}
                tenetId={tenetId}
            />
        </>
    )
}

export default PatientVideoCall

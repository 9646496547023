import React, { useEffect, useMemo } from 'react'
import FaceSheetCard from '../../Patients/PatientDashboard/Dashboard/FaceSheet/FaceSheetCard'
import { useDispatch, useSelector } from 'react-redux';
import { getCountToDisplayDashboard } from '../DashboardSaga';
import { componentKey as routerComponentKey } from '../../../routes/RoutesSlice';
import { componentKey } from '../DashboardSlice';
import { useNavigate } from 'react-router-dom';


function UncheckNotification() {

    const { loggedInUser } = useSelector(state => state[routerComponentKey])
    const { dashboardCounts } = useSelector(state => state[componentKey])
    const dispatch = useDispatch()

    const data = useMemo(() => {
        const { totalAppointments, totalEncounters, totalPatients, totalTest, totalUnpaidBill, totelDocuments, totalSchedules, totalPendingTest} = dashboardCounts || {}

        return [
            { redirectTo:'/scheduling/list', title: 'Scheduled', number: totalSchedules || 0 },
            {  redirectTo:'/scheduling/list', title: 'Patient Appointment', number: totalAppointments || 0 },
            { redirectTo:"/patients",title: 'Patients', number: totalPatients || 0 },
            { redirectTo: "/billing/superbill", title: 'Unpaid Billing', number: totalUnpaidBill || 0 },
            { title: 'Test Conducted', number: totalTest || 0 },
            { redirectTo:"/unsigned-visit", title: 'Unsigned Encounter', number: totalEncounters || 0 },
            { title: 'Pending Reports', number: totalPendingTest || 0 },
            { title: 'Patient Documents', number: totelDocuments || 0 },
        ];

    }, [dashboardCounts])


    useEffect(() => {
            dispatch(getCountToDisplayDashboard())
    }, [dispatch])

    const navigate = useNavigate()

    return (
        <div>
            <div className="flex col-gap-5 row-gap-10 overflow-x-auto met-scrollbar">
                {data?.map((item, index) => (
                    <div onClick={() => item?.redirectTo && navigate(item?.redirectTo)}   className={`${item?.redirectTo ? 'cursor-pointer' : ''}`}  key={index}>
                        <FaceSheetCard
                            textColorClass="text-gray-500 text-md truncate"
                            paddingTopClass={0}
                            customClassName="min-w-[13rem] max-w-[15rem] rounded-md p-1"
                            title={item.title}
                        >
                            <div className='mt-4 text-met-primary font-semibold'>{item.number}</div>
                        </FaceSheetCard>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default UncheckNotification
import store from "../../../../store/store"


export const componentKey = 'CHARTING/UPCOMMING_APPOINTMENT'

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setAllUpcommingAppointmentData: (state, action) => {
            state.allUpcommingAppointmentData = action.payload
        },
        setAppoinmentpaginationState: (state, action) => {
            state.appoinmentpaginationState = { ...state.appoinmentpaginationState, ...action.payload }
        }
    },
    initialReducerState: {
        allUpcommingAppointmentData: [],
        appoinmentpaginationState: { pageNumber: 1, limit: 10, total: 0, }
    }
})

export const { setAllUpcommingAppointmentData, setAppoinmentpaginationState } = actions
import React, { useEffect } from 'react'
import "./zoomIntegration.scss"

import { useDispatch, useSelector } from 'react-redux'

import { componentKey, setActivePageIndex, setCloseVideoCallModal, setExitFullScreen, setStartZoomMeeting, setVideoDuration } from './ZoomIntegrationSlice'
import { VEC_ICON_NAME } from './components/icon/constants'
import { patchUpdateScheduleDetails } from './ZoomIntegrationSaga'

import Permissions from './permissions/Permissions'
import VideoCall from './videoCall/VideoCall'
import DraggableVideoContainer from './videoCall/DraggableVideoContainer'
import moment from 'moment'
import Icons from './components/icon/Icon';
import Loadable from './components/loadable/Loadable';
import Heading from './components/heading/Heading'
import Button from './components/button/Button'
import { useNavigate } from 'react-router-dom'
import { BUTTON_TYPE } from './constants'
import ConfirmationPopup from './components/popup/confirmationpopup/ConfirmationPopup'

const ZoomIntegration = ({
    userType = "Provider",
    appointmentDetails = {},
    onEndVideoCb = () => { },
    tenetId = ""
}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { activePageIndex, loadingState, exitFullScreen, client, closeVideoCall, startVideoTimer, zoomEncounterId } = useSelector(state => state[componentKey])

    const pages = [
        { component: <Permissions appointmentDetails={appointmentDetails} userType={userType} tenetId={tenetId} /> },
        { component: <VideoCall appointmentDetails={appointmentDetails} userType={userType} /> },
    ]

    useEffect(() => {
        if (startVideoTimer) {
            let timer = setInterval(() => {
                dispatch(setVideoDuration(1000))
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [startVideoTimer]);

    const handleCloseMeeting = () => {
        if (activePageIndex == 0) {
            dispatch(setStartZoomMeeting(false))
        } else {
            dispatch(setCloseVideoCallModal(!closeVideoCall))
        }
    }

    const handleConfirmationClick = async () => {
        try {
            if (userType == "Provider") {
                await client.leave(true);
                dispatch(setActivePageIndex(0))
                dispatch(setStartZoomMeeting(false))
                dispatch(patchUpdateScheduleDetails({ scheduleId: appointmentDetails.uuid, body: { isProviderPresent: false, providerId: appointmentDetails?.providerId } }))
                onEndVideoCb()
            } else {
                await client.leave();
                dispatch(setActivePageIndex(0))
                dispatch(setCloseVideoCallModal(false))
            }
        } catch (error) {
            console.error("Leave meeting error", error)
        }
    }

    return (
        <Loadable loadingStates={loadingState.state} loadingMessage={loadingState.message}>
            {closeVideoCall ?
                <ConfirmationPopup
                    title={"Are you sure?"}
                    bodyText={userType !== "Provider" ? "Are you certain you want to leave this meeting?" : "Are you certain you want to end this encounter? Please fill all encounter details before closing the call"}
                    isOpen={closeVideoCall}
                    confirmationButtonProps={{
                        onConfirmationCb: handleConfirmationClick,
                        confirmationButtonText: "Leave",
                        confirmationButtonStyles: {},
                        confirmationButtonType: BUTTON_TYPE.PRIMARY,
                    }}
                    cancelButtonProps={{
                        onCancelCb: () => dispatch(setCloseVideoCallModal(!closeVideoCall)),
                        cancelButtonText: "Cancel",
                        cancelButtonStyles: {},
                        cancelButtonType: BUTTON_TYPE.PRIMARY_LIGHT_WITH_BORDER,
                    }}
                />
                :
                <>
                    {!exitFullScreen ?
                        <div className='full-screen-page'>
                            <div className='full-page-header'>
                                <div className='full-page-content'>
                                    {userType == "Provider" && <Icons style={{ marginTop: "3px", cursor: "pointer" }} iconName={VEC_ICON_NAME.BACK_ICON} onClickCb={handleCloseMeeting} />}
                                    <div>
                                        <Heading className='mb-0'>{appointmentDetails?.Patient?.firstName} {appointmentDetails?.Patient?.lastName} Appointment With {appointmentDetails?.Provider?.providerType || "Dr"}. {appointmentDetails?.Provider?.firstName} {appointmentDetails?.Provider?.lastName}</Heading>
                                        <span style={{ color: "#1A1A1A", fontWeight: "400" }}>{moment(appointmentDetails?.date).format('MMMM DD, YYYY')} At {appointmentDetails?.start}</span>
                                    </div>
                                </div>

                                {(activePageIndex == 1 && userType == "Provider") &&
                                    <Button
                                        prefixProps={{ icon: VEC_ICON_NAME.START_ENCOUNTER_ICON }}
                                        styles={{ width: "174px" }}
                                        onClickCb={() => {
                                            dispatch(setExitFullScreen(true))
                                            navigate(`/encounter/${appointmentDetails?.patientId}/${zoomEncounterId}`)
                                        }}
                                    >Start Encounter</Button>
                                }

                            </div>
                            <div className='full-page-body'>
                                {pages[activePageIndex].component}
                            </div>
                        </div> :
                        <DraggableVideoContainer />
                    }
                </>
            }
        </Loadable>
    )
}

export default ZoomIntegration

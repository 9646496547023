import React from 'react'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import SelectDropdown from '../../../../components/common-components/selectDropdown'
import Icons from '../../../../components/icons/Icons'
import Input from '../../../../components/common-components/input/Input'
import DatePicker from '../../../../components/common-components/datePicker'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Table from '../../../../containers/Table/Index'
import InputWithSuffix from '../../../../components/common-components/inputWithSuffix/InputWithSuffix'
import { MET_COLORS } from '../../../../libs/constant'
import Upload from '../../../../components/common-components/upload/Upload'
import General from '../../../../libs/utility/General'

function ImagingReportTable({ addImageReport, values, setFieldValue, handleBlur }) {

  const coloumns = [
    {
      field: "number",
      label: <Label>No</Label>,
      renderLogic: (row) => {
        return <>
          <div className='flex items-center'>
            <div>
              <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.number}</Heading>
            </div>
          </div>
        </>
      },
    },

    {
      field: "name",
      label: <Label>Name</Label>,
      renderLogic: (row) => {
        return <>
          <div className='flex items-center'>
            <div>
              <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.name}</Heading>
            </div>
          </div>
        </>
      },
    },

    // {
    //   field: "result",
    //   label: <Label>Result</Label>,
    //   renderLogic: (row) => {
    //     return <>
    //       <div className='flex items-center'>
    //         <div>
    //           <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.result}</Heading>
    //         </div>
    //       </div>
    //     </>
    //   },
    // },
    // {
    //   field: "unit",
    //   label: <Label>Unit</Label>,
    //   renderLogic: (row) => {
    //     return <>
    //       <div className='flex items-center'>
    //         <div>
    //           <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.unit}</Heading>
    //         </div>
    //       </div>
    //     </>
    //   },
    // },

    // {
    //   field: "reference",
    //   label: <Label>Reference</Label>,
    //   renderLogic: (row) => {
    //     return <>
    //       <div className='flex items-center'>
    //         <div>
    //           <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.reference}</Heading>
    //         </div>
    //       </div>
    //     </>
    //   },
    // },
    {
      field: "reportDate",
      label: <Label>Report Date</Label>,
      renderLogic: (row) => {
        return <>
          <div className='flex items-center'>
            <div>
              <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.reportDate}</Heading>
            </div>
          </div>
        </>
      },
    },

    {
      field: "reportTime",
      label: <Label>Report Time</Label>,
      renderLogic: (row) => {
        return <>
          <div className='flex items-center'>
            <div>
              <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.reportTime}</Heading>
            </div>
          </div>
        </>
      },
    },

    {
      field: "interpretation",
      label: <Label>Interpretation</Label>,
      renderLogic: (row) => {
        return <>
          <div className='flex items-center'>
            <div>
              <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.interpretation}</Heading>
            </div>
          </div>
        </>
      },
    },
    {
      field: "uploadResult",
      label: <Label>Upload Result</Label>,
      renderLogic: (row) => {
        return <>
          <div className='flex items-center'>
            <div>
              <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.uploadResult}</Heading>
            </div>
          </div>
        </>
      },
    },
    {
      field: "action",
      label: <Label>Action</Label>,
      renderLogic: (row) => {
        return <>
          <div className='flex items-center'>
            <div>
              <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.action}</Heading>
            </div>
            {values.patientImageStudy.length > 1 ? (
              <Button
                variant={BUTTON_VARIANTS.TEXT}
                customBtnClass="bg-red-50 text-red-500 border-red-500"
                startIcon={{ icon: <Icons iconName="trashIcon" /> }}
                onClickCb={() => {
                  values.patientImageStudy.splice(row.number - 1, 1);
                  setFieldValue('patientImageStudy', values.patientImageStudy);
                }}
              ></Button>
            ) : null}
          </div>
        </>
      },
    },
  ]


  const getRowData = () => {
    return values.patientImageStudy.map((test, index) => {
      return {
        number: index + 1,
        name: <Input
          name={`patientImageStudy[${index}].name`}
          value={test.name}
          onChangeCb={(e) => setFieldValue(`patientImageStudy[${index}].name`, e.target.value)}

        />,

        // result: <Input
        //   placeholder={"Enter"}
        //   name={`ImageTests[${index}].result`}
        //   value={test.result}
        //   onChangeCb={(e) => { setFieldValue(`ImageTests[${index}].result`, e.target.value) }}
        //   onBlurCb={handleBlur}
        // />,
        // unit: <Input
        //   placeholder={"Enter"}
        //   name={`ImageTests[${index}].unit`}
        //   value={test.unit}
        //   onChangeCb={(e) => { setFieldValue(`ImageTests[${index}].unit`, e.target.value) }}
        //   onBlurCb={handleBlur}
        // />,
        // reference: <div className='flex column-gap-10px'>
        //   <div><InputWithSuffix placeholder="min"
        //     name={`ImageTests[${index}].referenceMin`}
        //     value={test.referenceMin}
        //     onChangeCb={(e) => { setFieldValue(`ImageTests[${index}].referenceMin`, e.target.value) }}
        //   /></div>
        //   <div><InputWithSuffix placeholder="max"
        //     name={`ImageTests[${index}].referenceMax`}
        //     value={test.referenceMax}
        //     onChangeCb={(e) => { setFieldValue(`ImageTests[${index}].referenceMax`, e.target.value) }} /></div>
        // </div>,
        reportDate: <DatePicker placeholder='Select Date'
          value={test.reportDate}
          name={`patientImageStudy[${index}].reportDate`}
          onChangeCb={(date) => {
            setFieldValue(`patientImageStudy[${index}].reportDate`, date)
          }}
        />,
        reportTime: <DatePicker
          name={`patientImageStudy[${index}].reportTime`}
          placeholder='Choose Time'
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="h:mm aa"
          value={test.reportTime}
          onChangeCb={(time) => {
            setFieldValue(`patientImageStudy[${index}].reportTime`, time)
          }}
          iconName='scheduleIcon'
        />,
        interpretation: <SelectDropdown
          placeholder='Select'
          name={`patientImageStudy[${index}].interpretation`}
          value={test.interpretation}
          onChangeCb={(e) => setFieldValue(`patientImageStudy[${index}].interpretation`, e)}
          options={[{ label: 'NORMAL', value: 'NORMAL' }, { label: 'ABNORMAL', value: 'ABNORMAL' }]}
        />,
        uploadResult: <Upload
          customClass="h-[40px] w-[90px] text-met-primary"
          borderType="border-none"
          icon={<Icons iconName="uploadOutlinedIcon" color={MET_COLORS.PRIMARY}

          />}
          title="Upload"
          customCotainerClass='flex  justify-center items-center gap-3'
          url={test.uploadResult}
          onChangeCb={async (file) => {
            const mimeType = file?.type || ""
            const base64String = await General.fileToBase64(file)
            const base64 = `data:${mimeType};base64,${base64String}`
            setFieldValue(`patientImageStudy[${index}].uploadResult`, base64)
          }}

        />
      }
    })
  }
  return (
    <>
      <div className='flex flex-col items-center justify-between pt-6'>
        <div className='flex items-center justify-between w-full'>
          <Heading fontSize="sm" fontWeight="bold" type={HEADING.H3}>Study</Heading>
          <Button
            variant={BUTTON_VARIANTS.TEXT}
            startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }}
            customBtnClass="text-met-primary"
            onClickCb={addImageReport}
          >
            Add New
          </Button>

        </div>
        <Table isPagination={false} coloumns={coloumns}
          rows={getRowData()}
          sorting={{
            isSortable: false,
            onSortChangeCb: () => { }
          }}
        />
      </div>
    </>
  )
}

export default ImagingReportTable



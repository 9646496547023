import AppDataService from './AppDataService'

const COMMON_BASE = 'colour-config'

export default class ColorConfigDataService {

    static async getColorConfig() {
        return await AppDataService.get(`${COMMON_BASE}`)
    }
    static async updateColorConfig(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}`, data)
    }

}
import store from '../../../../../store/store';
import { FILTERS } from './constant';

export const componentKey = 'PROVIDER_GROUP_USERS';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setIsFilterOpen: (state, action) => {
			state.isFilterOpen = action.payload;
		},
		setISAddProviderUserModalOpen: (state, action) => {
			state.isProviderUserModalOpen = action.payload;
		},
		setProviderUsersData: (state, action) => {
			state.providerUsersData = action.payload;
		},
		setAllProviders: (state, action) => {
			state.allProviders = action.payload;
		},
		setAllProvidersWorkLocations: (state, action) => {
			state.allProvidersWorkLocations = action.payload;
		},
		setIsNewProviderUserAdd: (state, action) => {
			state.isNewProviderUserAdd = action.payload;
		},
		setCurrentProviderUserUUID: (state, action) => {
			state.currentProviderUserUUID = action.payload;
		},
		setCreateResendInviteLink: (state, action) => {
			state.createResendInviteLink = action.payload;
		},
		setFilters: (state, action) => {
			state.filters = {
				...state.filters, ...action.payload
			}
		},
		setViewUserModalIsOpen: (state, action) => {
			state.viewUserModal = action.payload;
		},
		setUsersData: (state, action) => {
			state.usersData = action.payload
		},
		setResetFilters: (state, action) => {
			state.filters = action.payload?.filters
		},
		setIsAddUSerStaffModalOpen: (state, action) => {
			state.isAddStaffUserModalOpen = action.payload
		},
		setIsNewUserAdd: (state, action) => {
			state.isNewUserAdd = action.payload
		}

	},
	initialReducerState: {
		isFilterOpen: false,
		isProviderUserModalOpen: false,
		providerUsersData: {},
		allProviders: [],
		allProvidersWorkLocations: [],
		isNewProviderUserAdd: false,
		createResendInviteLink: '',
		filters: FILTERS,
		viewUserModal: false,
		usersData: {},
		isAddStaffUserModalOpen: false,
		isNewUserAdd: false,

	},
});

export const {
	setIsFilterOpen,
	setISAddProviderUserModalOpen,
	setProviderUsersData,
	setAllProviders,
	setAllProvidersWorkLocations,
	setIsNewProviderUserAdd,
	setCurrentProviderUserUUID,
	setFilters,
	setViewUserModalIsOpen,
	setResetFilters,
	setIsAddUSerStaffModalOpen,
	setIsNewUserAdd

} = actions;

import React, { useState, useEffect, forwardRef } from 'react';
import { Formik, Form } from 'formik';
import Button from '../../../components/common-components/button/Button';
import Label from '../../../components/common-components/label/Label';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { useParams } from 'react-router-dom';
import { ABHA_FIELDS_NAMES } from './Constants';
import Heading from '../../../components/common-components/zoom/components/heading/Heading';
import { HEADING } from '../../../components/common-components/zoom/constants';
import { useSelector, useDispatch } from 'react-redux';
import ImportFromAadhaar from '../PatientDetails/ImportFromAadhaar';
import { componentKey,setOpenValidateCreateOtpModal } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import ValidateOTPModal from '../PatientDetails/ValidateOTPModal';
import store from '../../../store/store';

const ABHAInformation = forwardRef(function ABHAInformation({ isUpdate = false, onUpdateCb = null }, formRef) {

    const { openValidateCreateOtpModal } = useSelector((state) => state[componentKey]);
    const { patientId } = useParams();

    const [isImportUsingAadhaarModalOpen, setIsImportUsingAadhaarModalOpen] = useState(false)
    const dispatch = useDispatch();
    useEffect(() => {
		return () => {
			if (isUpdate) {
				store.reducerManager.remove(componentKey);
			}
		};
	}, []);

    return (
        <>{patientId === ":id" ? 
            <div>
            <Formik
                innerRef={formRef}
                initialValues={{ [ABHA_FIELDS_NAMES.SELECT_OPTION]: "" }}
                onSubmit={ (values) => {
                }}
            >
                {({ submitForm,	isSubmitting, handleReset, values,handleSubmit, setFieldValue, handleBlur, ...props }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="custom-patient-details bg-opacity-3 rounded p-5 flex flex-col gap-4">
                            <Heading type={HEADING.H2}>Ayushman Bharat Health Account</Heading>
                            <div>
                                <Button 
                                    onClickCb={() => {
                                        setIsImportUsingAadhaarModalOpen(true)
                                    }} 
                                    variant={BUTTON_VARIANTS.CONTAINED} 
                                    customBtnClass={`px-4 `} //{selectType ==="createABHAAccount" ? "border border-4 border-red-500":""}
                                >
                                    
                                    Create Account
                                </Button>
                            </div>
                            
                        </div>
                    </Form>
                )}
            </Formik>
            <div className="pt-4">
                <Label asteriskPosition="start" isRequired={true}>
                    Indicates mandatory fields
                </Label>
            </div>
            {
                <ImportFromAadhaar
                    open={isImportUsingAadhaarModalOpen}
                        close={() => setIsImportUsingAadhaarModalOpen(false)}
                />
            }
            { openValidateCreateOtpModal ? 
				<ValidateOTPModal
					open={openValidateCreateOtpModal}
					close={() =>  {
                        dispatch(setOpenValidateCreateOtpModal(false))}
					}
				/> : null
			}
            </div>
: null}
        </>
    );
});

export default ABHAInformation;

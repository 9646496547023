import React from 'react';

function LockOpenIcon({ color = '' }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" id="lock_open_black_24dp" width="24" height="24" viewBox="0 0 24 24">
			<path id="Path_204336" data-name="Path 204336" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_204337"
				data-name="Path 204337"
				d="M18,8H17V6A5,5,0,0,0,7,6H9a3,3,0,0,1,6,0V8H6a2.006,2.006,0,0,0-2,2V20a2.006,2.006,0,0,0,2,2H18a2.006,2.006,0,0,0,2-2V10A2.006,2.006,0,0,0,18,8Zm0,12H6V10H18Zm-6-3a2,2,0,1,0-2-2A2.006,2.006,0,0,0,12,17Z"
				fill="#1b5984"
			/>
		</svg>
	);
}

export default LockOpenIcon;

import store from '../../../../store/store'

export const componentKey = 'PROFILE_CARD_SLICE'

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {},
    initialReducerState: {}
})

export const { } = actions
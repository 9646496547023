import React from 'react'
import Button from '../button/Button'
import { BUTTON_VARIANTS } from '../button/Constants'

export const MetToggleType1 = ({ options, selected, onChangeCb, customBtnClass, }) => { 
    return (
        <div className='flex column-gap-10px items-center p-1 shadow-md rounded-md'>
            {options.map((item, index) => {
                return <Button customBtnClass={`${customBtnClass} ${item.value === selected ? "text-white" : "text-gray-500"}`} onClickCb={() => onChangeCb(item)} variant={item.value === selected ? BUTTON_VARIANTS.CONTAINED : BUTTON_VARIANTS.TEXT} key={"met-toggle-type-1-" + index} title={item.title}>
                    {item.label}
                </Button>
            })}


        </div>
    )
}
import store from '../../../../store/store';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { componentKey, setAllImagingResultData, setImagingResultData, setIsImagingResultModalOpen, setAllImagingCentresData, setIsOpenImagingDeleteModal } from './PatientImagingSlice';
import PatientDashboardDataService from '../../../../services/PatientDashboardDataService';
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/Constants';

export const {
    getAllPatientImagingResultDetails,
    postPatientImagingResult,
    deletePatientImagingResult,
    updatePatientImagingResult,
    getAllImagingCentreDetails,
} = {
    getAllPatientImagingResultDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/IMAGING',
            payload,
        };
    },
    postPatientImagingResult: (payload) => {
        return {
            type: 'POST/PATIENTDASHBOARD/IMAGING',
            payload,
        };
    },
    deletePatientImagingResult: (payload) => {
        return {
            type: 'DELETE/PATIENTDASHBOARD/IMAGING',
            payload,
        };
    },
    updatePatientImagingResult: (payload) => {
        return {
            type: 'UPDATE/PATIENTDASHBOARD/IMAGING',
            payload,
        };
    },
    getAllImagingCentreDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/ALLIMAGINGCENTRE',
            payload,
        };
    },
};

function* getAllPatientImagingResultDetailsAsync(action) {
    try {
        yield put(setAllImagingResultData([]));
        const { patientId = '', page = '1', limit = '10' } = action.payload;
        const params = {
            page: page,
            limit: limit,
        };

        const response = yield PatientDashboardDataService.getAllPatientImagingResult(
            'imagereport/patient',
            patientId,
            params
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setAllImagingResultData(data?.imageReport));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* postPatientImagingResultAsync(action) {
    try {
        yield put(setAllImagingResultData([]));
        const { patientId, imagingresultData } = action.payload;
        const response = yield PatientDashboardDataService.postPatientImagingResult('imagereport', patientId, imagingresultData);
        const { message, status, data } = response?.data || {};
        if (status) {
            yield put(setIsImagingResultModalOpen(false));
            yield call(getAllPatientImagingResultDetailsAsync,
                getAllPatientImagingResultDetails({ patientId }));
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* deletePatientImagingResultDetailsAsync(action) {
    try {
        const { patientId = '', imagingresultId = '' } = action.payload;

        const response = yield PatientDashboardDataService.deletePatientImagingResult('imagereport', imagingresultId, patientId);

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setIsOpenImagingDeleteModal(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAllPatientImagingResultDetailsAsync,
                getAllPatientImagingResultDetails({ patientId }));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* updatePatientImagingResultDetailsAsync(action) {
    try {
        const { patientId = '', imagingresultId = '', imagingresultData = {} } = action.payload;

        const response = yield PatientDashboardDataService.updatePatientImagingResult(
            'imagereport',
            imagingresultId,
            patientId,
            imagingresultData
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setIsImagingResultModalOpen(false));
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAllPatientImagingResultDetailsAsync,
                getAllPatientImagingResultDetails({ patientId }));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* getAllImagingCentreDetailsAsync(action) {
    try {

        const response = yield PatientDashboardDataService.getAllImagingCentres(
            'radiology',
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setAllImagingCentresData(data?.radiology));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getAllPatientImagingResultDetails().type, getAllPatientImagingResultDetailsAsync),
        takeLatest(postPatientImagingResult().type, postPatientImagingResultAsync),
        takeLatest(deletePatientImagingResult().type, deletePatientImagingResultDetailsAsync),
        takeLatest(updatePatientImagingResult().type, updatePatientImagingResultDetailsAsync),
        takeLatest(getAllImagingCentreDetails().type, getAllImagingCentreDetailsAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

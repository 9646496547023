import BellIcon from './vectors/BellIcon';
import { CloudUploadIcon } from './vectors/CloudUploadIcon';
import FaxIcon from './vectors/FaxIcon';
import ExpandIcon from './vectors/ExpandIcon';
import FileUpload from './vectors/FileUpload';
import LocationIcon from './vectors/LocationIcon';
import PhoneIcon from './vectors/PhoneIcon';
import PlusVectorIcon from './vectors/PlusVectorIcon';
import { SearchIcon } from './vectors/SearchIcon';
import SortingIcon from './vectors/SortingIcon';
import { UploadIcon } from './vectors/UploadIcon';
import MoreVertIcon from './vectors/MoreVertIcon';
import VisaIcon from './vectors/VisaIcon';
import CloseIcon from './vectors/CloseIcon';
import EmailIcon from './vectors/EmailIcon';
import CalenderIcon from './vectors/CalenderIcon';
import LanguageGlobIcon from './vectors/LanguageGlobIcon';
import TranslateIcon from './vectors/TranslateIcon';
import AddWithCircleIcon from './vectors/AddWithCircleIcon';
import AllergiesIcon from './vectors/AllergiesIcon';
import AppointmentsIcon from './vectors/AppointmentsIcon';
import BillingIcon from './vectors/BillingIcon';
import CardDetailsIcon from './vectors/CardDetailsIcon';
import DiagnosesIcon from './vectors/DiagnosesIcon';
import DocumentsIcon from './vectors/DocumentsIcon';
import ImagingIcon from './vectors/ImagingIcon';
import HistoryIcon from './vectors/HistoryIcon';
import InsuranceIcon from './vectors/InsuranceIcon';
import LabIcon from './vectors/LabIcon';
import MedicationsIcon from './vectors/MedicationsIcon';
import PatientDashboardIcon from './vectors/PatientDashboardIcon';
import PortalAccessIcon from './vectors/PortalAccessIcon';
import ProfileIcon from './vectors/ProfileIcon';
import VaccinesIcon from './vectors/VaccinesIcon';
import VitalsIcon from './vectors/VitalsIcon';
import ArrowBackIcon from './vectors/ArrowBackIcon';
import SuccesStepIcon from './vectors/SuccesStepIcon';
import LogoutIcon from './vectors/LogoutIcon';
import UserIcon from './vectors/UserIcon';
import ArrowRightIcon from './vectors/ArrowRightIcon';
import LockOpenIcon from './vectors/LockOpenIcon';
import LockCloseIcon from './vectors/LockCloseIcon';
import ClockWithPendingStatusIcon from './vectors/ClockWithPendingStatusIcon';
import CheckCircleIcon from './vectors/CheckCircleIcon';
import EditIcon from './vectors/EditIcon';
import UploadOutlinedIcon from './vectors/UploadOutlinedIcon';
import TrashIcon from './vectors/TrashIcon';
import ThreeDotMenu from './vectors/ThreeDotMenu';
import ScheduleIcon from './vectors/ScheduleIcon';
import FamilyIcon from './vectors/FamilyIcon';
import SuccessSvg from './vectors/SuccessSvg';
import DeleteIcon from './vectors/DeleteIcon';
import VideoIcon from './vectors/VideoIcon';
import CopyIcon from './vectors/CopyIcon';
import PersonIcon from './vectors/PersonIcon';
import PaymentIcon from './vectors/PaymentIcon';
import ListIcon from './vectors/ListIcon';
import FiltersIcon from './vectors/FiltersIcon';
import CopyUrlIcon from './vectors/CopyUrlIcon';
import HealthInsurance from './vectors/HealthInsurance';
import PrimaryProviderIcon from './vectors/PrimaryProviderIcon';
import ProvidersIcon from './vectors/ProvidersIcon';
import PatientsIcon from './vectors/PatientsIcon';
import TrendingUpIcon from './vectors/TrendingUpIcon';
import TrendingDownIcon from './vectors/TrendingDownIcon';
import DashboardCalenderIcon from './vectors/DashboardCalenderIcon';
import EncounterIcon from './vectors/EncounterIcon';
import BodyMassIndex from './vectors/BodyMassIndexIcon';
import HeartRateIcon from './vectors/HeartRateIcon';
import RespirationIcon from './vectors/RespirationIcon';
import BloodPressureIcon from './vectors/BloodPressureIcon';
import { AppointmentClockIcon } from './vectors/appoitmentClockIcon';
import AddPersonIcon from './vectors/AddPersonIcon';
import { MobileIcon } from './vectors/MobileIcon';
import { PersonWithBorderIcon } from './vectors/PersonWithBorderIcon';
import CalenderWithWatchIcon from './vectors/CalenderWithWatchIcon';
import MetslSecondaryLogo from './vectors/MetslSecondaryLogo';
import MetslPrimaryLogo from './vectors/MetslPrimaryLogo';
import StartButtonIcon from './vectors/StartButtonIcon';
import ChartIcon from './vectors/ChartIcon';
import { VisibilityIcon } from './vectors/VisibilityIcon';
import { RedirectIcon } from './vectors/RedirectIcon';
import DownloadIcon from './vectors/DownloadIcon';
import EyeIcon from './vectors/EyeIcon';
import EyeSlashIcon from './vectors/EyeSlashIcon'
import ViewGraphIcon from './vectors/ViewGraphIcon';
const patientDashBoardIcons = {
	allergiesIcon: (iconProps) => <AllergiesIcon color={iconProps.color} />,
	appointmentsIcon: (iconProps) => <AppointmentsIcon color={iconProps.color} />,
	billingIcon: (iconProps) => <BillingIcon color={iconProps.color} />,
	cardDetailsIcon: (iconProps) => <CardDetailsIcon color={iconProps.color} />,
	diagnosesIcon: (iconProps) => <DiagnosesIcon color={iconProps.color} />,
	documentsIcon: (iconProps) => <DocumentsIcon color={iconProps.color} />,
	historyIcon: (iconProps) => <HistoryIcon color={iconProps.color} />,
	imagingIcon: (iconProps) => <ImagingIcon color={iconProps.color} />,
	insuranceIcon: (iconProps) => <InsuranceIcon color={iconProps.color} />,
	labIcon: (iconProps) => <LabIcon color={iconProps.color} />,
	medicationsIcon: (iconProps) => <MedicationsIcon color={iconProps.color} />,
	patientDashboardIcon: (iconProps) => <PatientDashboardIcon color={iconProps.color} />,
	portalAccessIcon: (iconProps) => <PortalAccessIcon color={iconProps.color} />,
	profileIcon: (iconProps) => <ProfileIcon color={iconProps.color} />,
	vaccinesIcon: (iconProps) => <VaccinesIcon color={iconProps.color} />,
	vitalsIcon: (iconProps) => <VitalsIcon color={iconProps.color} />,
	familyIcon: (iconProps) => <FamilyIcon color={iconProps.color} />,
	providersIcon: (iconProps) => <ProvidersIcon color={iconProps.color} />,
	patientsIcon: (iconProps) => <PatientsIcon color={iconProps.color} />,
	trendingUpIcon: (iconProps) => <TrendingUpIcon color={iconProps.color} />,
	trendingDownIcon: (iconProps) => <TrendingDownIcon color={iconProps.color} />,
	dashboardCalenderIcon: (iconProps) => <DashboardCalenderIcon color={iconProps.color} />,
	encounterIcon: (iconProps) => <EncounterIcon color={iconProps.color} />,
	chartIcon: (iconProps) => <ChartIcon color={iconProps.color} />,
};

const icons = {
	...patientDashBoardIcons,
	uploadOutlinedIcon: (iconProps) => <UploadOutlinedIcon color={iconProps.color} />,
	arrowRightIcon: (iconProps) => <ArrowRightIcon color={iconProps.color} />,
	uploadIcon: (iconProps) => <UploadIcon color={iconProps.color} />,
	searchIcon: (iconProps) => <SearchIcon color={iconProps.color} />,
	cloudUploadIcon: (iconProps) => <CloudUploadIcon color={iconProps.color} />,
	sortingIcon: (iconProps) => <SortingIcon color={iconProps.color} />,
	fileUpload: (iconProps) =>
		<FileUpload
			color={iconProps.color}
			height={iconProps.height}
			width={iconProps.width}
		/>,
	plusVectorIcon: (iconProps) => (
		<PlusVectorIcon
			color={iconProps.color}
			height={iconProps.height}
			width={iconProps.width}
			style={iconProps.style}
		/>
	),
	scheduleIcon: (iconProps) => <ScheduleIcon color={iconProps.color} style={iconProps.style} />,
	trashIcon: (iconProps) => <TrashIcon color={iconProps.color} style={iconProps.style} />,
	locationIcon: (iconProps) => <LocationIcon color={iconProps.color} height={iconProps.height} width={iconProps.width} />,
	phoneIcon: (iconProps) => <PhoneIcon color={iconProps.color} />,
	faxIcon: (iconProps) => <FaxIcon color={iconProps.color} />,
	bellIcon: (iconProps) => <BellIcon color={iconProps.color} />,
	expandIcon: (iconProps) => <ExpandIcon color={iconProps.color} style={iconProps.style} />,
	moreVertIcon: (iconProps) => <MoreVertIcon color={iconProps.color} />,
	visaIcon: (iconProps) => <VisaIcon color={iconProps.color} />,
	closeIcon: (iconProps) => <CloseIcon color={iconProps.color} />,
	emailIcon: (iconProps) => <EmailIcon color={iconProps.color} />,
	calenderIcon: (iconProps) => <CalenderIcon color={iconProps.color} style={iconProps.style} />,
	languageGlobIcon: (iconProps) => <LanguageGlobIcon color={iconProps.color} />,
	translateIcon: (iconProps) => <TranslateIcon color={iconProps.color} />,
	addWithCircleIcon: (iconProps) => <AddWithCircleIcon color={iconProps.color} />,
	arrowBackIcon: (iconProps) => <ArrowBackIcon color={iconProps.color} />,
	succesStepIcon: (iconProps) => <SuccesStepIcon color={iconProps.color} />,
	lockOpenIcon: (iconProps) => <LockOpenIcon color={iconProps.color} />,
	lockCloseIcon: (iconProps) => <LockCloseIcon color={iconProps.color} />,
	clockWithPendingStatusIcon: (iconProps) => <ClockWithPendingStatusIcon color={iconProps.color} />,
	checkCircleIcon: (iconProps) => <CheckCircleIcon color={iconProps.color} />,
	editIcon: (iconProps) => <EditIcon color={iconProps.color} height={iconProps.height} width={iconProps.width} />,
	logoutIcon: (iconProps) => <LogoutIcon color={iconProps.color} />,
	userIcon: (iconProps) => <UserIcon color={iconProps.color} />,
	threeDotMenu: (iconProps) => <ThreeDotMenu color={iconProps.color} />,
	successSvg: (iconProps) => <SuccessSvg color={iconProps.color} />,
	deleteIcon: (iconProps) => <DeleteIcon height={iconProps.height}
		width={iconProps.width} />,
	videoIcon: (iconProps) => <VideoIcon color={iconProps.color} style={iconProps.style} />,
	copyIcon: (iconProps) => <CopyIcon color={iconProps.color} />,
	personIcon: (iconProps) => <PersonIcon color={iconProps.color} />,
	paymentIcon: () => <PaymentIcon />,
	listIcon: (iconProps) => <ListIcon color={iconProps.color} />,
	filtersIcon: (iconProps) => <FiltersIcon color={iconProps.color} />,
	copyUrlIcon: (iconProps) => <CopyUrlIcon color={iconProps.color} />,
	healthInsurance: (iconProps) => <HealthInsurance />,
	primaryProviderIcon: (iconProps) => <PrimaryProviderIcon />,
	bodyMassIndex: () => <BodyMassIndex />,
	heartRateIcon: () => <HeartRateIcon />,
	respirationIcon: () => <RespirationIcon />,
	bloodPressureIcon: (iconProps) => <BloodPressureIcon color={iconProps.color} />,
	appointmentClockIcon: (iconProps) => <AppointmentClockIcon color={iconProps.color} />,
	addPersonIcon: (iconProps) => <AddPersonIcon color={iconProps.color} />,
	mobileIcon: (iconProps) => <MobileIcon color={iconProps.color} />,
	personWithBorder: (iconProps) => <PersonWithBorderIcon color={iconProps.color} />,
	calenderWithWatchIcon: (iconProps) => <CalenderWithWatchIcon color={iconProps.color} />,
	metslPrimaryLogo: (iconProps) => <MetslPrimaryLogo color={iconProps.color} />,
	metslSecondaryLogo: (iconProps) => <MetslSecondaryLogo color={iconProps.color} />,
	startButtonIcon: (iconProps) => <StartButtonIcon color={iconProps.color} />,
	visibilityIcon: (iconProps) => <VisibilityIcon color={iconProps.color} />,
	redirectionIcon: (iconProps) => <RedirectIcon color={iconProps.color} />,
	downloadIcon: (iconProps) => <DownloadIcon color={iconProps.color} />,
	eyeIcon: (iconProps) => <EyeIcon color={iconProps.color} onClick={iconProps.onClick}/>,
	eyeSlashIcon: (iconProps) => <EyeSlashIcon color={iconProps.color}  onClick={iconProps.onClick}/>,
    viewGraphIcon: (iconProps) => <ViewGraphIcon color={iconProps.color} onClick={iconProps.onClick} />

};

const Icons = ({ iconName, color, transformScale = 1, rotateDeg = 0, style, height, width, onClick = () => {} }) => {
	const iconProps = {
		style: {
			...style,
			transform: `scale(${transformScale}) rotate(${rotateDeg}deg)`,
			cursor: 'pointer'
		},
		color: color,
		stroke: color,

		iconName: '',
		height: height,
		width: width,
		onClick: onClick
	};

	const IconComponent = icons[iconName];

	if (!IconComponent) {
		return null;
	}

	return <IconComponent {...iconProps} />;
};

export default Icons;

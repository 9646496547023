import React, { useEffect, useRef, useState } from 'react'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import FaceSheetCard from '../../../Patients/PatientDashboard/Dashboard/FaceSheet/FaceSheetCard'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey as PatientEncounterComponentKey } from '../../../Encounter/EncounterSlice'
import { componentKey as SchedulingComponentKey } from '../../../Scheduling/ShedulingSlice'
import { componentKey as PatientDashboardComponentKey } from '../../../Patients/PatientDashboard/PatientDashboardSlice'
import { componentKey as PrintConfigComponentKey } from '../../../../containers/MetPrintModal/MetPrintModalSlice'


import parse from 'html-react-parser'
import moment from 'moment'
import ReactToPrint from 'react-to-print'
import { getDefaultPrintConfig } from '../../../../containers/MetPrintModal/MetPrintModalSaga'
import useBase64Image from '../../../../containers/SettingsTab/MyAccountCard/Tabs/PrintConfiguration/useS3Image'
import { getEncounterByIdDetails } from '../../../Encounter/EncounterSaga'
import { getScheduleById } from '../../../Scheduling/SchedulingSaga'
import { getPatientDetails } from '../../../Patients/PatientDashboard/PatientDashboardSaga'
import EncounterSummaryCard from '../../../Encounter/EncounterData/EncounterSummary/EncounterSummaryCard'
import { DEVICE_UUID_TEXT } from '../../../Encounter/FormBuilder/constants'
import General from '../../../../libs/utility/General'

const BillingViewSummaryModal = ({ isOpen, close, encounterId = "", scheduleId = "", patientId = "" }) => {

    const { encounterDataById } = useSelector(state => state[PatientEncounterComponentKey])
    const { checkedInData } = useSelector((state) => state[SchedulingComponentKey]);
    const { patientData } = useSelector((state) => state[PatientDashboardComponentKey]);
    const { defaultPrintConfig } = useSelector(state => state[PrintConfigComponentKey])

    const dispatch = useDispatch()
    const printingRef = useRef()

    const [base64Data, setBase64Data] = useState("")

    const getBase64 = useBase64Image(defaultPrintConfig?.image);

    useEffect(() => {
        if (!encounterId) return
        dispatch(getEncounterByIdDetails({ encounterId }))
    }, [encounterId])

    useEffect(() => {
        if (!scheduleId) return
        dispatch(getScheduleById({ scheduleId }))
    }, [scheduleId])


    useEffect(() => {
        if (!patientId) return
        dispatch(getPatientDetails({ patientId }))
    }, [scheduleId])

    useEffect(() => {
        const getBase64Data = async () => {
            const base64Data = await getBase64(defaultPrintConfig?.image)
            setBase64Data(base64Data)
        }
        getBase64Data()
    }, [defaultPrintConfig?.image])

    useEffect(() => {
        dispatch(getDefaultPrintConfig())
    }, [dispatch])

    const getPrimaryInsurance = () => {
        return patientData?.Insurance?.find(insurance => {
            return insurance.type === 'Primary'
        })
    }

    return (
        <ModalComponent customClasses='w-[70%]' customBodyClasses='pt-0' close={close}
            open={isOpen}
            footerButton={
                <ReactToPrint
                    trigger={() => <button className="text-met-primary bg-met-secondary border border-met-primary p-2 mr-8 rounded-md">Print</button>}
                    content={() => printingRef.current}
                />
            }
        >
            <div ref={printingRef} className='px-4'>
                <div className='sticky top-0 bg-white'>
                    <div className='flex flex-wrap flex-col items-center '>
                        <span>
                            <img className="max-h-[100px] max-w-[100px]" src={base64Data} alt="" />
                        </span>
                        <div className='pt-3'>{defaultPrintConfig?.headerName || '-'}</div>
                        <div dangerouslySetInnerHTML={{ __html: defaultPrintConfig?.html }}></div>
                    </div>
                </div>
                <div className='text-met-primary font-bold pt-3'>ENCOUNTER DETAILS</div>
                <div className='pt-3'>
                    <div className={`bg-white bg-no-repeat bg-padding-box border border-gray-300 rounded-lg shadow-sm p-4 p-[12px]`}>

                        <div className='flex flex-row column-gap-20px'>
                            <div>Encounter Date -</div>
                            <div className='text-gray-500'>{checkedInData?.date ? moment(checkedInData.date).format('ddd, MMM D YYYY') : '-'}</div>
                            <div className='text-gray-500'>{`Last amended by ${checkedInData?.Provider?.firstName} ${checkedInData?.Provider?.lastName || ''} on ${encounterDataById?.updatedAt ? moment(encounterDataById?.updatedAt).format('MMM, d YYYY') : '-'} at ${encounterDataById?.updatedAt ? moment(encounterDataById?.updatedAt).format('hh:MM A') : '-'}`}</div>
                        </div>
                        <div className='flex  flex-wrap justify-between'>
                            {/* {encounterData.map((encounter, index) => (
                        <div className='flex w-1/2 flex-row column-gap-20px pt-2' key={"encounterData-appointment" + index}>
                            <div className=''>{encounter.feildName}</div>
                            <div className=' text-gray-500'>{encounter.feildData}</div>
                        </div>
                    ))} */}
                            <div className='flex w-1/2 flex-row column-gap-20px pt-2'>
                                <div className=''>Patient Name -</div>
                                <div className=' text-gray-500'>{`${checkedInData?.Patient?.firstName} ${checkedInData?.Patient?.lastName} (${moment(new Date()).diff(moment(checkedInData?.Patient?.dob), 'years')} yo, ${checkedInData?.Patient?.gender ? checkedInData?.Patient?.gender?.toUpperCase().substring(0, 1) : ''})`}</div>
                            </div>
                            <div className='flex w-1/2 flex-row column-gap-20px pt-2'>
                                <div className=''>Appointment Date & Time -</div>
                                <div className=' text-gray-500'>{`${moment(checkedInData?.Appointment?.startDate).format('ddd, MMM D YYYY')} ${checkedInData?.start || ''}`}</div>
                            </div>
                            <div className='flex w-1/2 flex-row column-gap-20px pt-2'>
                                <div className=''>DOB -</div>
                                <div className=' text-gray-500'>{checkedInData?.Patient?.dob ? moment(checkedInData?.Patient?.dob).format('MMMM D, YYYY') : '-'}</div>
                            </div>
                            {/* <div className='flex w-1/2 flex-row column-gap-20px pt-2'>
                            <div className=''>Service Department -</div>
                            <div className=' text-gray-500'>{`${checkedInData?.Patient?.firstName} ${checkedInData?.Patient?.lastName} (${moment(new Date()).diff(moment(checkedInData?.Patient?.dob), 'years')} yo, ${checkedInData?.Patient?.gender ? checkedInData?.Patient?.gender?.toUpperCase().substring(0, 1) : ''})`}</div>
                        </div> */}
                            <div className='flex w-1/2 flex-row column-gap-20px pt-2'>
                                <div className=''>Provider Name -</div>
                                <div className=' text-gray-500'>{`${checkedInData?.Provider?.firstName} ${checkedInData?.Provider?.lastName || ""}`}</div>
                            </div>
                            <div className='flex w-1/2 flex-row column-gap-20px pt-2'>
                                <div className=''>Insurane Name -</div>
                                <div className=' text-gray-500'>{`${getPrimaryInsurance()?.companyName || '-'}`}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='pt-3'>
                    <div >
                        <div >
                            <div className='pt-3'>
                                {encounterDataById?.encounterForms?.data && Object.keys(encounterDataById?.encounterForms?.data).map((key, index) => {
                                    if (typeof encounterDataById?.encounterForms?.data[key] === 'string' && key !== DEVICE_UUID_TEXT) {
                                        return <EncounterSummaryCard key={`encounter-data-${index}`} title={General
                                            .camelToPascalWithSpaces(key)} description={parse(encounterDataById?.encounterForms?.data[key] || '-')} customLabelClass='text-met-primary' fontSize='lg'></EncounterSummaryCard>
                                    } else {
                                        return <></>
                                    }
                                })}
                                <div className='pt-4 pb-4 flex  justify-end'>
                                    <div className="border border-solid w-[400px]  border-blue-400 rounded p-2 bg-met-secondary">
                                        <div className='pt-6 fs-20 pl-3 font-mono'>{`${checkedInData?.Provider?.firstName || '-'} ${checkedInData?.Provider?.lastName || ''}`}</div>
                                        <div className='pt-2 flex  flex-col'>
                                            <div className='flex justify-end fs-14 text-gray-500'>Signed by {`${checkedInData?.Provider?.firstName || '-'} ${checkedInData?.Provider?.lastName || ''}`}</div>
                                            <div className='flex justify-end fs-14 text-gray-500'>{moment(new Date()).format('MMMM DD, YYYY hh:mm A')}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        </ModalComponent >
    )
}

export default BillingViewSummaryModal



import React, { useEffect, useState } from 'react';
import { componentKey as SchedulingKey, setSelectedProvidersLocation } from '../ShedulingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import Icons from '../../../components/icons/Icons';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import TextArea from '../../../components/common-components/textArea/TextArea';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { getValidationSchema } from '../../../libs/formsUtils';
import DatePicker from '../../../components/common-components/datePicker';
import Checkbox from '../../../components/common-components/checkbox/Checkbox';
import Input from '../../../components/common-components/input/Input';
import { FORM_FIELDS_FOR_BLOCK_APPOINTMENTS } from '../constant';
import Avatar from '../../../components/common-components/avatar';
import General from '../../../libs/utility/General';
import { componentKey } from '../../../containers/Wrappers/Dataloader/DataloaderSlice';
import Label from '../../../components/common-components/label/Label';
import { getProviderLocationsByProviderId, postScheduleBlockAppointment } from '../SchedulingSaga';
import { componentKey as routerComponentKey } from '../../../routes/RoutesSlice'
import moment from 'moment';
import { USER_ROLE_NAMES } from '../../../containers/Wrappers/Constants';
import IsAccessAllowed from '../../../containers/Wrappers/IsAccessAllowed';
const BlockAppointment = ({ close }) => {
    const fields = [
        { fieldName: FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.PROVIDER, isDropdown: true,isRequired: true },
        { fieldName: FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.LOCATION, isDropdown: true,isRequired: true },
        { fieldName: FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START_DATE, isRequired: true },
        { fieldName: FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START, isRequired: true },
        { fieldName: FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.END, isRequired: true },
    ];
    const validationSchema = getValidationSchema(fields)
    const dispatch = useDispatch();
    const { isBlockApoointmentModalOpen, blockAppointmentData, selectedProvidersLocation } = useSelector((state) => state[SchedulingKey]);
    const { loggedInUser, loggedInProviderUser } = useSelector(state => state[routerComponentKey])

    const [isSelectedProvider, setIsSelectedProvider] = useState(false);
    const { providersList, allProviderGroupLocation } = useSelector((state) => state[componentKey]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [isAdminUser, setIsAdminUser] = useState(false)
    const [selectedProvider, setSelectedProvider] = useState({})
    const [selectedProviderData, setSelectedProviderData] = useState({});
    const [isFrontDeskUser, setIsFrontDeskUser] = useState(false)
    const [selectedProviderId, setSelectedProviderId] = useState({})


    useEffect(() => {
        const allowedRoles = [
            USER_ROLE_NAMES.ADMIN_PROVIDER,
        ]
        const currentRole = loggedInProviderUser?.roleNames || []
        setIsAdminUser(IsAccessAllowed(allowedRoles, currentRole))
        setIsFrontDeskUser(currentRole[0] === 'Front Desk' ? true : false)
    }, [loggedInProviderUser])

    useEffect(() => {
        if (!isAdminUser) {
            setSelectedProvider({ label: loggedInUser?.name, value: loggedInUser?.uuid })
        }
        setSelectedProviderId({ label: loggedInUser?.providerId, value: loggedInUser?.providerId })
    }, [isAdminUser, loggedInUser, isFrontDeskUser, loggedInProviderUser])

    useEffect(() => {
        if (!selectedProvider?.value) return;
        dispatch(getProviderLocationsByProviderId({ providerId: selectedProvider.value }));
    }, [selectedProvider, isAdminUser, loggedInProviderUser, isFrontDeskUser]);

    useEffect(() => {
        if (!selectedProviderId?.value) return;
        dispatch(getProviderLocationsByProviderId({ providerId: selectedProviderId?.value }));
    }, [selectedProviderId, isFrontDeskUser]);
    const defaultRenderOption = (option, onChangeCb = () => {}) => {
        const specialities = option.provider_speciality_intermediate.map((item) => {
            return item?.speciality.name;
        });
        return (
            <div
                className={`flex items-center justify-between w-full p-2 ${isSelectedProvider && isSelectedProvider.value === option.value
                    ? 'bg-met-primary text-white'
                    : 'hover:bg-gray-100'
                    }`}
            >
                <div
                    className="flex justify-between w-full"
                    onClick={() => {
                        onChangeCb(option);
                    }}
                >
                    <div className="flex justify-between">
                        <Avatar url={option?.profilePicture} name={`${option?.firstName} ${option?.lastName}`} />
                        <div className='flex flex-col'>
                            <p className="ml-[10px]">{option.label}</p>
                            <p className='ml-[10px]'>{specialities && specialities.length > 0 ? (specialities.length > 1 ? "Multispecialist" : specialities) : "-"}</p>

							{/* <p className='ml-[10px]'>{specialities?.length > 1 ? "Multispecialist" : specialities}</p> */}
						</div>
					</div>
				</div>
			</div>
		);
	};

	const locations = selectedProvidersLocation?.map((item) => {
		return {
			...item.provider_locations,
			label: item.provider_locations.name,
			value: item.provider_locations.uuid,
		};
	});
	// const locations = General.addLableValuePair(allProviderGroupLocation, 'name', 'uuid');
	const toggleDay = (day) => {
		setSelectedDays((prevSelectedDays) => {
			if (prevSelectedDays.includes(day)) {
				return prevSelectedDays.filter((selectedDay) => selectedDay !== day);
			} else {
				return [...prevSelectedDays, day];
			}
		});
	};

	return (
		<Formik
			initialValues={{
				

                [FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.PROVIDER]: isAdminUser ? blockAppointmentData?.providerId : isFrontDeskUser
                                                  ? selectedProviderId: selectedProvider,

				[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.LOCATION]: blockAppointmentData?.locationId,

				[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START]: blockAppointmentData?.start,
				[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.END]: blockAppointmentData?.end,
				[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START_DATE]: blockAppointmentData?.startDate,
				[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.END_DATE]: blockAppointmentData?.endDate,
				[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.EVERY]: blockAppointmentData?.every,
				[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.DAYS]: blockAppointmentData?.days || [],
				[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.REASON]: blockAppointmentData?.reason,
				[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.IS_REPEAT]: blockAppointmentData?.isRepeat,
				[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.REPEAT_TYPE]: blockAppointmentData?.repeatType,
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				const { days, endDate, every, isRepeat, repeatType, ...extractedValues } = values
				const payload = {
					...extractedValues,
					endTime: moment(values?.endTime, 'hh:mm A').format('hh:mm A'),
					startTime: moment(values?.startTime, 'hh:mm A').format('hh:mm A'),
					providerId: values?.providerId?.value,
					locationId: values?.locationId?.uuid,
				};

				dispatch(postScheduleBlockAppointment({ blockAppointmentData: payload }));
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={isBlockApoointmentModalOpen}
							title={'Block Time'}
							close={() => {
								close();
							}}
							icon={<Icons iconName="closeIcon" />}
							footerButton={
								<Button
									type="submit"
									variant={BUTTON_VARIANTS.CONTAINED}
									customBtnClass="text-met-primary"
								>
									Save
								</Button>
							}
							customClasses="w-[40%] overflow-visible"
							enableReinitialize
						>
							<div className="">
								<div className="flex justify-between">
									<div className="w-[45%]">
										<SelectDropdown
                                            placeholder="Select Provider"
                                            label="Block Time For"
                                            isImageInOption={true}
                                            // isRequired={true}
                                            name={FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.PROVIDER}
                                            value={
                                                isAdminUser
                                                    ? values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.PROVIDER]
                                                    : isFrontDeskUser
                                                        ? selectedProviderId
                                                        : selectedProvider
                                            }	
                                            onChangeCb={(val) => {
                                                setFieldValue(FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.PROVIDER, val);
                                                dispatch(
                                                    setSelectedProvidersLocation(val.provider_location_intermediate)
                                                );
                                            }}
                                            disabled={isAdminUser ? false : true}
                                            renderOption={defaultRenderOption}
                                            isAsync={true}
                                            url="providers"
                                            extractKeyFromResponse=""
                                            labelKey={'firstName'}
                                            valueKey={'uuid'}
                                            labelKey2={'lastName'}
                                        />
									</div>
									<div className="w-[45%]">
										<SelectDropdown
											placeholder="Select Location"
											label="Location"
											// isRequired={true}
											name={FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.LOCATION}
											value={values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.LOCATION] || []}
											onChangeCb={(val) => {
												setFieldValue(FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.LOCATION, val);
											}}
											options={locations}
										/>
									</div>
								</div>
								<div className="mt-5 flex justify-between">
									<div className="w-[30%]">
										<DatePicker
											label="Day"
											placeholder="Select"
											name={FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START_DATE}
											value={values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START_DATE]}
											onChangeCb={(date) => {
												setFieldValue(FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START_DATE, date);
											}}
											minDate={new Date()}
										/>
									</div>
									<div className="w-[30%]">
										<DatePicker
											name={FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START}
											placeholder="Select Time"
											label="Start Time"
											isRequired={true}
											showTimeSelect
											showTimeSelectOnly
											timeIntervals={30}
											timeCaption="Time"
											dateFormat="h:mm aa"
											value={values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START]}
											iconName="scheduleIcon"
											minTime={values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START_DATE] && moment(values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START_DATE]).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY') ? new Date() : new Date().setHours(0, 0)}
											maxTime={new Date().setHours(23, 59)}
											onChangeCb={(time) => {
												setFieldValue(FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START, time);
												if (time && values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.END] && new Date(time).toISOString() > new Date(values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.END]).toISOString()) {
													setFieldValue(FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.END, "");
												}
											}}
											// iconName="scheduleIcon"
											// minDate={new Date()}
										/>
									</div>
									<div className="w-[30%]">
										<DatePicker
											name={FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.END}
											placeholder="Select Time"
											label="End Time"
											isRequired={true}
											showTimeSelect
											showTimeSelectOnly
											timeIntervals={30}
											timeCaption="Time"
											dateFormat="h:mm aa"
											value={values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.END]}
											onChangeCb={(time) => {
												setFieldValue(FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.END, time);
											}}
											iconName="scheduleIcon"
											minTime={values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START] ? new Date(values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START]).setMinutes(new Date(values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.START]).getMinutes() + 30) : new Date().setHours(0, 0, 0, 0)}
											maxTime={new Date().setHours(23, 59)}
										/>
									</div>
								</div>
								{/* <div className="mt-5">
									<Checkbox
										label={'Repeat'}
										style={{ color: 'gray-500' }}
										checked={values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.IS_REPEAT]}
										onChangeCb={(e) => {
											// setIsRepeatCheckboxChecked(!isRepeatCheckboxChecked);
											setFieldValue(FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.IS_REPEAT, e);
										}}
									/>
								</div> */}
								{/* {values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.IS_REPEAT] && (
									<div
										className={`mt-4 ${isBlockApoointmentModalOpen === true ? 'w-full' : 'w-[80%]'
											}`}
									>
										<div className="flex  justify-between w-[80%]">
											<p className="mt-4">Every</p>
											<div className="">
												<SelectDropdown
													placeholder="Select"
													name={FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.EVERY}
													value={values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.EVERY] || []}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.EVERY, val);
													}}
													options={[
														{ label: '1', value: '1' },
														{ label: '2', value: '2' },
														{ label: '3', value: '3' },
														{ label: '4', value: '4' },
														{ label: '5', value: '5' },
													]}
												/>
											</div>
											<div className="">
												<SelectDropdown
													placeholder="Select"
													name={FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.REPEAT_TYPE}
													value={values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.REPEAT_TYPE] || []}
													onChangeCb={(val) => {
														setFieldValue(
															FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.REPEAT_TYPE,
															val
														);
													}}
													options={[
														{ label: 'Day', value: 'Day' },
														{ label: 'Week', value: 'Week' },
														{ label: 'Month', value: 'Month' },
														{ label: 'Year', value: 'Year' },
													]}
												/>
											</div>
										</div>
										<div className="w-[30%] mt-5">
											<div className="flex">
												{['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'].map(
													(item, index) => (
														<div
															key={item}
															className={`h-10 p-2 w-10 flex flex-wrap justify-center items-center cursor-pointer border-[1px] rounded-[5px] border-[#1A1A1A33] ${index !==
																['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
																	.length -
																1
																? 'mr-1'
																: ''
																} ${selectedDays.includes(item)
																	? 'bg-met-primary text-white'
																	: 'bg-met-primary-bg text-rgba-1A1A1A66'
																}`}
															onClick={() => {
																toggleDay(item);
																setFieldValue(
																	FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.DAYS,
																	selectedDays
																);
															}}
														>
															{item}
														</div>
													)
												)}
											</div>
										</div>
										<div className="flex justify-between mt-5">
											<p className="mt-4">End On</p>
											<div>
												<DatePicker
													// label="Date & Time"
													// open={false}
													placeholder="Choose Date"
													// onFocusCb={() => setIsDatePickerTouched(!isDatePickerTouched)}
													name={FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.END_DATE}
													value={values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.END_DATE]}
													onChangeCb={(date) => {
														// setIsDatePickerTouched(!isDatePickerTouched);
														setFieldValue(
															FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.END_DATE,
															date
														);
													}}
												/>
											</div>
											<div>
												<Input
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.COUNT,
															General.sanitizeNumbers(e.target.value)
														);
													}}
													placeholder="Enter"
													name={FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.COUNT}
													value={values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.COUNT]}
												/>
											</div>
											<Label customClass="mt-4">Appointments</Label>
										</div>
									</div>
								)} */}
								<div className="w-full mt-5">
									<Input
										label="Reason"
										// isRequired={true}
										placeholder="Enter reason"
										name={FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.REASON}
										value={values[FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.REASON]}
										onChangeCb={(e) => {
											setFieldValue(FORM_FIELDS_FOR_BLOCK_APPOINTMENTS.REASON, e.target.value);
										}}
									/>
								</div>
							</div>
						</ModalComponent>
					</Form>
				);
			}}
		</Formik>
	);
};
export default BlockAppointment;

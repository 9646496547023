import React from 'react';
export const AppointmentClockIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
			<g id="Group_92108" data-name="Group 92108" transform="translate(-1342 -352)">
				<g id="Group_77329" data-name="Group 77329" transform="translate(1342 352)">
					<rect id="Rectangle_34272" data-name="Rectangle 34272" width="34" height="34" fill="none" />
				</g>
				<g id="Group_77331" data-name="Group 77331" transform="translate(1345 355)">
					<g id="Group_77330" data-name="Group 77330" transform="translate(0 0)">
						<path
							id="Path_204106"
							data-name="Path 204106"
							d="M15.444,10.778v7.778l6.611,3.92,1.2-1.991-5.476-3.251V10.778ZM31,13.889V3L26.893,7.107A13.991,13.991,0,1,0,31,17H27.889A10.888,10.888,0,1,1,24.7,9.3l-4.589,4.589Z"
							transform="translate(-3 -3)"
							fill="#1b5984"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

import store from '../../../store/store';

export const componentKey = 'PATIENTDASHBOARD_SECTION';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setPatientId: (state, action) => {
			state.currentPatientId = action.payload;
		},
		setPatientData: (state, action) => {
			state.patientData = action.payload;
		},
		setIsOpenDeletePopup: (state, action) => {
			state.isOpenDeletePopup = action.payload;
		}
	},
	initialReducerState: {
		currentPatientId: '',
		patientData: {},
		isOpenDeletePopup: false
	},
});

export const { setPatientId, setPatientData, setIsOpenDeletePopup } = actions;

import React from 'react'
import DashBoardCards from './DashBoardCards'

const PatientDashboardInfo = () => {

    // const routes = [
    //     { path: "/facesheet", component: <div className='overflow-auto relative met-scrollbar h-[90%]'><DashBoardCards /></div> },
    //     { path: "/questionnaires", component: <QuestionnairesSheet /> },
    //     { path: "/", component: <Navigate to={"facesheet"} /> },
    // ]

    return (
        <>
            <div className='overflow-auto relative met-scrollbar h-[90%]'><DashBoardCards /></div>
            {/* <NavigationBorderedTabs tabs={[
                { label: "Face sheet", onClickCb: () => navigate('facesheet'), path: "/facesheet" },
                { label: "Questionaires", onClickCb: () => navigate('questionnaires'), path: "/questionnaires" }
            ]} />
            <Routes>
                {routes.map((route, index) => {
                    return <Route key={"patient-dashboard-route" + index} path={route.path} element={route.component} />
                })}
            </Routes> */}

        </>
    )
}

export default PatientDashboardInfo
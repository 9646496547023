import React from 'react'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../components/common-components/button/Button'
import Input from '../../../../components/common-components/input/Input'
import SelectDropdown from '../../../../components/common-components/selectDropdown'
import { Form, Formik } from 'formik'
import { FORM_FIELDS_NAMES } from './Constatnts'
import { getValidationSchema } from '../../../../libs/formsUtils'
import { VALIDATION_REGEX } from '../../../../libs/constant'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey } from './FamilySlice'
import { EditFamilyMembers, saveFamilyMembers } from './FamilySaga'
import { componentKey as currentPatientIdkye } from '../PatientDashboardSlice'
import General from '../../../../libs/utility/General'

const fields = [
    { fieldName: FORM_FIELDS_NAMES.FIRST_NAME, isRequired: true, regexPattern: VALIDATION_REGEX.NAME_REGEX },
    { fieldName: FORM_FIELDS_NAMES.LAST_NAME, isRequired: true, regexPattern: VALIDATION_REGEX.NAME_REGEX },
    { fieldName: FORM_FIELDS_NAMES.AGE, regexPattern: VALIDATION_REGEX.AGE_REGEX },
    { fieldName: FORM_FIELDS_NAMES.CONTACT_NUMBER, isRequired: true, regexPattern: VALIDATION_REGEX.MOBILE_NUM_REGEX },
    { fieldName: FORM_FIELDS_NAMES.RELATION, isDropdown: true },
    { fieldName: FORM_FIELDS_NAMES.EMAIL, isEmail: true, },
    { fieldName: FORM_FIELDS_NAMES.ADDRESS }
];

const validationSchema = getValidationSchema(fields)

const AddFamilyMemberModal = ({ open, close, rowData }) => {
    const { isEditFamilyMemberOpen } = useSelector((state) => state[componentKey])
    const { currentPatientId } = useSelector((state) => state[currentPatientIdkye])

    const dispatch = useDispatch()

    return (
        <Formik
            initialValues={{
                [FORM_FIELDS_NAMES.FIRST_NAME]: rowData?.firstName || "",
                [FORM_FIELDS_NAMES.LAST_NAME]: rowData?.lastName || "",
                [FORM_FIELDS_NAMES.AGE]: rowData?.age || undefined,
                [FORM_FIELDS_NAMES.RELATION]: { label: rowData?.relationWithPatient || '', value: rowData?.relationWithPatient || '' },
                [FORM_FIELDS_NAMES.EMAIL]: rowData?.email || undefined,
                [FORM_FIELDS_NAMES.CONTACT_NUMBER]: rowData?.contactNumber || "",
                [FORM_FIELDS_NAMES.ADDRESS]: rowData?.address || undefined
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(val) => {
                val.relationWithPatient = val.relationWithPatient?.value
                if (isEditFamilyMemberOpen) {
                    dispatch(EditFamilyMembers({ uuid: rowData.uuid, data: val, patientId: currentPatientId }))
                }
                else {
                    val.patientId = currentPatientId;
                    dispatch(saveFamilyMembers({ data: val, patientId: currentPatientId }))

                }
            }}
        >
            {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                return <Form onSubmit={handleSubmit} >
                    <div>
                        <ModalComponent open={open} close={close} title={isEditFamilyMemberOpen ? "Edit Family Member" : "Add Family Member"} customClasses='w-[50%]'
                            footerButton={<Button type='submit'>Save</Button>}>
                            <div className='flex justify-between column-gap-16 items-center'>
                                <div className='flex-1'>
                                    <Input label='First Name'
                                        isRequired={true}
                                        placeholder='Enter Name'
                                        name={FORM_FIELDS_NAMES.FIRST_NAME}
                                        value={values[FORM_FIELDS_NAMES.FIRST_NAME]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.FIRST_NAME, e.target.value) }}
                                        onBlurCb={handleBlur}
                                    />
                                </div>
                                <div className='flex-1' >
                                    <Input label='Last Name'
                                        isRequired={true}
                                        placeholder='Enter Name'
                                        name={FORM_FIELDS_NAMES.LAST_NAME}
                                        value={values[FORM_FIELDS_NAMES.LAST_NAME]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.LAST_NAME, e.target.value) }}
                                        onBlurCb={handleBlur} />
                                </div>
                                <div className='flex-1'>
                                    <SelectDropdown label='Relation'
                                        placeholder='Select'
                                        isRequired={true}
                                        name={FORM_FIELDS_NAMES.RELATION}
                                        value={values[FORM_FIELDS_NAMES.RELATION]}
                                        onChangeCb={(val) => {
                                            setFieldValue(FORM_FIELDS_NAMES.RELATION, val)
                                        }}
                                        options={[
                                            { label: 'Mother', value: 'mother' },
                                            { label: 'Sister', value: 'sister' },
                                            { label: 'Brother', value: 'Brother' },
                                            { label: 'Father', value: 'father' },
                                            { label: 'Self', value: 'self' },
                                            { label: 'Spouse', value: 'spouse' },
                                            { label: 'Parent', value: 'parent' },
                                            { label: 'Child', value: 'childs' },
                                            { label: 'Other', value: 'other' },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className='pt-3 flex justify-between column-gap-16 items-center'>
                                <div className='flex-1'>
                                    <Input label='Age'
                                        placeholder='Enter Age'
                                        name={FORM_FIELDS_NAMES.AGE}
                                        value={values[FORM_FIELDS_NAMES.AGE]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.AGE, Number(e.target.value)) }}
                                        onBlurCb={handleBlur} />
                                </div>
                                <div className='flex-1'>
                                    <Input label='Contact Number'
                                        placeholder='Enter Contact Number '
                                        isRequired={true}
                                        name={FORM_FIELDS_NAMES.CONTACT_NUMBER}
                                        value={values[FORM_FIELDS_NAMES.CONTACT_NUMBER]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.CONTACT_NUMBER, General.sanitizeNumbers(e.target.value)) }}
                                        onBlurCb={handleBlur} />
                                </div>
                                <div className='flex-1'>
                                    <Input label='Email'
                                        placeholder='Enter Email Address'
                                        name={FORM_FIELDS_NAMES.EMAIL}
                                        value={values[FORM_FIELDS_NAMES.EMAIL]}
                                        onChangeCb={(e) => {
                                            const val = e.target.value
                                            if (val.trim() !== "") {
                                                setFieldValue(FORM_FIELDS_NAMES.EMAIL, val)
                                            }
                                            else {
                                                setFieldValue(FORM_FIELDS_NAMES.EMAIL, undefined)
                                            }
                                        }}
                                        onBlurCb={handleBlur} />
                                </div>
                            </div>
                            <div className='pt-3'>
                                <Input label='Address '
                                    placeholder='Enter Address'
                                    name={FORM_FIELDS_NAMES.ADDRESS}
                                    value={values[FORM_FIELDS_NAMES.ADDRESS]}
                                    onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.ADDRESS, e.target.value) }}
                                    onBlurCb={handleBlur} />
                            </div>

                        </ModalComponent>
                    </div>
                </Form>
            }}
        </Formik>
    )
}

export default AddFamilyMemberModal
import { useEffect, useState } from "react";
import AppS3DataService from "../../../../../services/AppS3DataService";

const useBase64Image = () => {
    return (imageUrl) => {
        const fetchImage = async () => {
            if (imageUrl) {
                try {
                    const response = await AppS3DataService.get(imageUrl);
                    const { data } = response
                    return data;
                } catch (error) {
                    console.error('Error fetching image:', error);
                    return null
                }
            }
        };

        const base64 = fetchImage();
        return base64

    }
};

export default useBase64Image;
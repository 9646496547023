import { SETTINGS_PATHS } from "../../libs/constant";
import SettingsAvailability from "./AppointmentCard/Tabs/Availability/Availaility";
// import CancellationPolicy from "./AppointmentCard/Tabs/CancellationPolicy/CancellationPolicy";
import ColorConfig from "./AppointmentCard/Tabs/ColorConfig/ColorConfig";
import ProviderProfile from "./MyAccountCard/Tabs/MyProfile/ProviderProfile";
import SettingsNotifications from "./MyAccountCard/Tabs/Notification/Notifications";
import PrintConfiguration from "./MyAccountCard/Tabs/PrintConfiguration/PrintConfiguration";
import ProviderGroup from "./MyAccountCard/Tabs/ProviderGroup/ProviderGroup";
import Departments from "./ProviderGroupCard/Tabs/Departments/Departments";
// import SettingsUsers from "./MyAccountCard/Tabs/Users/Users";
// import Departments from "./ProviderGroupCard/Tabs/Departments/Departments";
import Locations from "./ProviderGroupCard/Tabs/Locations/Locations";
import Profile from "./ProviderGroupCard/Tabs/Profile/Profile";
import RolesAndResponsibilities from "./ProviderGroupCard/Tabs/RolesAndResponsibilities/RolesAndResponsibilities";
import Users from "./ProviderGroupCard/Tabs/Users/Users";
import VisitNotes from "./TemplatesCard/Tabs/VisitNotes/VisitNotes";
import VitalsTests from "./MasterCard/Tabs/VitalsTests/VitalsTests";
import ShowForAllowedPermissionsOnly from "../Wrappers/ShowForAllowedPermissionsOnly";
import { USER_PERMISSIONS } from "../../libs/UserPermissionsConstants";
import AllowAdminOnly from "../Wrappers/AllowAdminOnly";

export const appointmentCardsData = [
    { id: '/availability', name: 'Availability', redirectTo: SETTINGS_PATHS.APPOINTMENT_AVAILABILITY, component: <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.VIEW_AVAILABILITY}> <SettingsAvailability /> </ShowForAllowedPermissionsOnly> },
    { id: '/color-config', name: 'Color Configuration', redirectTo: SETTINGS_PATHS.APPOINTMENT_COLOR_CONFIG, component: <ColorConfig /> },
    // { id: '/cancellation', name: 'Cancellation Policy', redirectTo: SETTINGS_PATHS.APPOINTMENT_CANCELLATION, component: <CancellationPolicy /> },
];

export const myAccountCardsData = [
    {
        id: '/my-profile', name: 'My Profile', accessName: USER_PERMISSIONS.VIEW_PROFILE, redirectTo: SETTINGS_PATHS.MY_ACOCUNT_MY_PROFILE, component:
            <ShowForAllowedPermissionsOnly navigateUrl={"/"} name={USER_PERMISSIONS.VIEW_PROFILE}>
                <ProviderProfile />
            </ShowForAllowedPermissionsOnly>
    },
    {
        id: '/provider-group', accessName: USER_PERMISSIONS.VIEW_PRACTICE_GROUP, name: 'Provider Group', redirectTo: SETTINGS_PATHS.MY_ACOCUNT_PROVIDER_GROUP, component:
            <ShowForAllowedPermissionsOnly navigateUrl={"/"} name={USER_PERMISSIONS.VIEW_PRACTICE_GROUP}>
                <ProviderGroup />
            </ShowForAllowedPermissionsOnly>
    },
    // { id: '/users', name: 'Users', redirectTo: SETTINGS_PATHS.MY_ACOCUNT_USERS, component: <SettingsUsers /> },
    { id: '/notification', name: 'Notification', redirectTo: SETTINGS_PATHS.MY_ACOCUNT_NOTIFICATION, component: <SettingsNotifications /> },
    { id: '/print-configuration', name: 'Print Configuration', redirectTo: SETTINGS_PATHS.MY_ACOCUNT_PRINT_CONFIGURATION, component: <PrintConfiguration /> },
];

export const providerGroupData = [
    { id: '/profile', name: 'Profile', redirectTo: SETTINGS_PATHS.PROVIDER_GROUP_PROFILE, component: <AllowAdminOnly navigateUrl="/"> <Profile /></AllowAdminOnly> },
    { id: '/locations', name: 'Locations', redirectTo: SETTINGS_PATHS.PROVIDER_GROUP_LOCATIONS, component: <AllowAdminOnly navigateUrl="/"> <Locations /></AllowAdminOnly> },
    { id: '/departments', name: 'Departments', redirectTo: SETTINGS_PATHS.PROVIDER_GROUP_DEPARTMENTS, component: <AllowAdminOnly navigateUrl="/"> <Departments /> </AllowAdminOnly> },
    { id: '/users', name: 'Users', redirectTo: SETTINGS_PATHS.PROVIDER_GROUP_USERS, component: <AllowAdminOnly navigateUrl="/"><Users /></AllowAdminOnly> },
    { id: '/roles-responsibilties', name: 'Roles & Responsibility', redirectTo: SETTINGS_PATHS.PROVIDER_GROUP_ROLES_RESPONSIBILITIES, component: <AllowAdminOnly navigateUrl="/"><RolesAndResponsibilities /></AllowAdminOnly> },
];

export const templatesData = [
    { id: '/visitNotes', name: 'Visit Notes', redirectTo: SETTINGS_PATHS.TEMPLATES_VISIT_NOTES, component: <VisitNotes /> },
];

export const masterData = [
    { id: '/vitalsTests', name: 'Vitals Tests', redirectTo: SETTINGS_PATHS.MASTER_VITALS_TESTS, component: <VitalsTests /> }
];
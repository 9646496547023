import React, { useState } from 'react'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../components/common-components/button/Button'
import Icons from '../../../../components/icons/Icons'
import Label from '../../../../components/common-components/label/Label'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Table from '../../../../containers/Table/Index'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Input from '../../../../components/common-components/input/Input'
import { MET_COLORS } from '../../../../libs/constant'
import { Form, Formik } from 'formik'
import { getValidationSchema } from '../../../../libs/formsUtils'
import { FORM_FIELDS_NAMES } from './Constants'
import { componentKey as PatientDocumentsComponentKey, setDocumentsTypeData, setDocumentsTypeId, setIsNewDocumentsTypeAdd } from "./PatientDocumentsSlice"
import { useDispatch, useSelector } from 'react-redux'
import { deletePatientDocumentsType, getAllPatientDocumentsDetails, postPatientDocumentsType, updatePatientDocumentsType } from './PatientDocumentsSaga'
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice'

const fields = [
    { fieldName: FORM_FIELDS_NAMES.TYPE_NAME, isRequired: true },
];

const validationSchema = getValidationSchema(fields)

function DocumentsTypeModal({ open, close }) {
    const [isInputVisible, setIsInputVisible] = useState(false);
    const dispatch = useDispatch()
    const { currentPatientId } = useSelector((state => state[PatientDashboardComponentKey]))

    const { allDocumentsTypeData, isNewDocumentsTypeAdd, documentsTypeData, documentsTypeId } = useSelector(state => state[PatientDocumentsComponentKey])

    const handleAddNewType = () => {
        setIsInputVisible(true);
        dispatch(setIsNewDocumentsTypeAdd(true));
        dispatch(setDocumentsTypeData({}))
    };

    const handleEditClick = (documentTypeData) => {
        setIsInputVisible(true);
        dispatch(setIsNewDocumentsTypeAdd(false));
        dispatch(setDocumentsTypeId(documentTypeData.uuid))
        dispatch(setDocumentsTypeData(documentTypeData))
    }

    const handleDeleteClick = (documentTypeData) => {
        dispatch(deletePatientDocumentsType({ documentsTypeId: documentTypeData.uuid }))
    }

    const coloumns = [
        {
            field: "type",
            label: <Label fontWeight="font-bold">Type</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.typeName}</Heading>
                        </div>
                        <div className='flex items-center'>
                            <div onClick={() => handleEditClick(row)}> <Icons iconName={'editIcon'}></Icons> </div>
                            <div onClick={() => handleDeleteClick(row)}> <Icons iconName={'trashIcon'}></Icons> </div>
                        </div>
                    </div>
                </>
            },
        },

    ]

    return (
        <div>
            <ModalComponent
                open={open}
                title={'Upload'}
                icon={<Icons iconName="closeIcon" />}
                close={close}
                customClasses="w-[635px]"
            >
                <div>
                    <div>
                        <div>
                            <Table isPagination={false} coloumns={coloumns} rows={allDocumentsTypeData} sorting={
                                {
                                    isSortable: false,
                                    onSortChangeCb: () => { }
                                }} />
                        </div>
                        <div className='flex justify-end '>
                            <Button variant={BUTTON_VARIANTS.TEXT} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} customBtnClass="text-met-primary pt-6" onClickCb={handleAddNewType}>Add Type</Button>
                        </div>
                    </div>
                    <div>
                        {isInputVisible && (
                            <>
                                <Formik
                                    initialValues={{
                                        [FORM_FIELDS_NAMES.TYPE_NAME]: documentsTypeData?.typeName,
                                    }}
                                    validationSchema={validationSchema}
                                    enableReinitialize
                                    onSubmit={(formVals) => {
                                        try {
                                            if (isNewDocumentsTypeAdd) {
                                                dispatch(postPatientDocumentsType({ documentTypeData: formVals }))
                                                setIsInputVisible(false)
                                            } else {
                                                dispatch(updatePatientDocumentsType({ documentsTypeId, documentTypeData: formVals }))
                                                dispatch(getAllPatientDocumentsDetails({ patientId: currentPatientId }))
                                                setIsInputVisible(false);
                                            }
                                        } catch (err) {
                                            console.log(err);
                                        }
                                    }}
                                >
                                    {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                                        return <Form onSubmit={handleSubmit} >
                                            <div>
                                                <div>
                                                    <Input
                                                        label='Type Name'
                                                        placeholder='Enter'
                                                        isRequired={true}
                                                        name={FORM_FIELDS_NAMES.TYPE_NAME}
                                                        value={values[FORM_FIELDS_NAMES.TYPE_NAME]}
                                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.TYPE_NAME, e.target.value) }}
                                                        onBlurCb={handleBlur} />
                                                </div>
                                            </div>
                                            <div className='flex justify-end pt-6'>
                                                <Button type='submit' variant={BUTTON_VARIANTS.CONTAINED} >Save</Button>
                                            </div>
                                        </Form>
                                    }}
                                </Formik>
                            </>
                        )}
                    </div>
                </div>
            </ModalComponent >
        </div >
    )
}

export default DocumentsTypeModal
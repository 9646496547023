export const FORM_FIELDS_NAMES = {
    PATIENT_NAME: 'patientId',
    PAYMENT_DATE: 'paymentDate',
    PAYMENT_METHOD: 'method',
    AMOUNT: 'amount',
    CHEQUE_NO_NFT_TRANS_ID: 'checkNo',
    TRANSACTION_ID: 'transactionId',
    PROVIDER_NAME: 'providerId',
    MOBILE: 'mobile',
    NOTE: 'note',
    SUPERBILL: 'superBill',
    SEARCH_BY_DATE: 'searchByDate',
    SEARCH_PATIENT: 'searchPatient',
    SEARCH_OPTIONS: 'searchOptions' 
}
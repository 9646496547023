
import store from '../../../../store/store';

export const componentKey = 'VITALS_TEST_SECTION';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setIsVitalsTestModalOpen: (state, action) => {
            state.isVitalsTestModalOpen = action.payload;
        },
        setVitalsTestDetails: (state, action) => {
            state.vitalsTestDetails = action.payload;
        },
        setPaginationState: (state, action) => {
            state.paginationState = { ...state.paginationState, ...action.payload }
        },
        setEditTestsData: (state, action) => {
            state.editTestsData = action.payload
        },
        setIsEditVitalTest: (state, action) => {
            state.isEditVitalTest = action.payload
        },
        setIsOpenViewReportModal: (state, action) => {
            state.isOpenViewReportModal = action.payload
        },
        setViewTestsData: (state, action) => {
            state.viewTestsData = action.payload
        },
        setIsreviewTestModalOpen: (state, action) => {
            state.isreviewTestModalOpen = action.payload    
        },
        setIsAudioPlayModalOpen: (state, action) => {
           state.isAudioPlayModalOpen = action.payload
        }

    },
    initialReducerState: {
        isVitalsTestModalOpen: false,
        vitalsTestDetails: [],
        paginationState: { pageNumber: 1, limit: 10, total: 0 },
        editTestsData: {},
        isEditVitalTest: false,
        isOpenViewReportModal: false,
        viewTestsData: {},
        isreviewTestModalOpen : false,
        isAudioPlayModalOpen : false


    },
});

export const { setPaginationState, setIsVitalsTestModalOpen, setVitalsTestDetails, setEditTestsData, setIsEditVitalTest, setIsOpenViewReportModal, setViewTestsData, setIsreviewTestModalOpen, setIsAudioPlayModalOpen  } = actions;

import store from "../../../../store/store";

export const componentKey = 'GENRATE_SUPER_BILL'

const initialState = {
    patientDetails: {},
    allLocation: [],
    allDepartmentsData: [],
    icdCodesData: [],
    proceduralCodesData: [],
    isOpenCollectPaymentModal: false,
    collectPaymentData: {},
    paymentData: {},
    superBillData: {},
    SuperBillpaginationState: { pageNumber: 1, limit: 10, total: 0, name: "", status: "", date: "" },
    billData: {},
    providerGrouplist: {},
    isEditSuperBill: false,
    isCreateSuperbillModalOpen: false,
    readyForBillingData: [],
    readyForBillingPaginationState: { pageNumber: 1, limit: 10, total: 0, name: "" },
    billingPrintData: {},
    deviceTestsData: [],
    deviceNameList: [],
    patientVitalTests: [],
    readyForBillingByIdData: {},
    isSuperBillFilterOpen: false,
    isReadyBilingIds: []
};

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setpatientDetails: (state, action) => {
            state.patientDetails = action.payload;
        },
        setAllLocation: (state, action) => {
            state.allLocation = action.payload;
        },
        setAllDepartments: (state, action) => {
            state.allDepartmentsData = action.payload
        },
        setIcdCodesData: (state, action) => {
            state.icdCodesData = action.payload
        },
        setProceduralCodesData: (state, action) => {
            state.proceduralCodesData = action.payload
        },
        setIsopenCollectPaymentModal: (state, action) => {
            state.isOpenCollectPaymentModal = action.payload
        },
        setCollectPaymentData: (state, action) => {
            state.collectPaymentData = action.payload
        },
        setPaymentData: (state, action) => {
            state.paymentData = action.payload
        },
        setSuperBillData: (state, action) => {
            state.superBillData = action.payload
        },
        setSuperBillpaginationState: (state, action) => {
            state.SuperBillpaginationState = { ...state.SuperBillpaginationState, ...action.payload }
        },
        setBillData: (state, action) => {
            state.billData = action.payload
        },
        setproviderGrouplist: (state, action) => {
            state.providerGrouplist = action.payload
        },
        setIsCreateSuperbillModalOpen: (state, action) => {
            state.isCreateSuperbillModalOpen = action.payload;
        },
        setIsEditSuperBill: (state, action) => {
            state.isEditSuperBill = action.payload;
        },
        setReadyForBillingData: (state, action) => {
            state.readyForBillingData = action.payload
        },
        setReadyForBillingPaginationState: (state, action) => {
            state.readyForBillingPaginationState = { ...state.readyForBillingPaginationState, ...action.payload }
        },
        setbillingPrintData: (state, action) => {
            state.billingPrintData = action.payload
        },
        setdeviceTestsData: (state, action) => {
            state.deviceTestsData = action.payload
        },
        setdeviceNameList: (state, action) => {
            state.deviceNameList = action.payload
        },
        setpatientVitalTests: (state, action) => {
            state.patientVitalTests = action.payload
        },
        setReadyForBillingByIdData: (state, action) => {
            state.readyForBillingByIdData = action.payload
        },
        setIsSuperBillFilterOpen: (state, action) => {
            state.isSuperBillFilterOpen = action.payload;
        },
        setEmptySuperBillpaginationState: (state, action) => {
            state.SuperBillpaginationState = {
                pageNumber: 1,
                limit: 10,
                total: 0,
                name: "",
                status: "",
                date: ""
            }
        },
        setIsReadyBilingIds: (state, action) => {
            state.isReadyBilingIds = action.payload
        },
    },
    initialReducerState: initialState
});

export const { setpatientDetails, setAllLocation, setAllDepartments, setIcdCodesData, setProceduralCodesData, setIsopenCollectPaymentModal, setCollectPaymentData, setPaymentData, setSuperBillData, setSuperBillpaginationState, setBillData, setproviderGrouplist, setIsCreateSuperbillModalOpen, setIsEditSuperBill, setReadyForBillingData, setReadyForBillingPaginationState, setbillingPrintData, setdeviceTestsData, setdeviceNameList, setpatientVitalTests, setReadyForBillingByIdData, setIsSuperBillFilterOpen, setEmptySuperBillpaginationState, setIsReadyBilingIds } = actions

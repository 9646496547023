import React from 'react';
import './chat-message-item.scss';
import classNames from 'classnames';
import ChatFileMessageItem from './ChatFileMessageItem';
import moment from 'moment';

const ChatMessageItem = (props) => {
    const { record, currentUserId, resendFile, downloadFile } = props;
    const { message, file, sender, timestamp } = record;
    const isCurrentUser = currentUserId === sender.userId;

    const chatMessage = Array.isArray(message) ? message : [message];

    return (
        <div className={classNames('chat-message-item m-0', { myself: isCurrentUser })}>
            <div className="chat-message-content">
                <div className={classNames('chat-message-info', { myself: isCurrentUser })}>
                    <span className='chat-timestamp'>{moment(timestamp).format("hh:mm a")} <span className='chat-sender'>{isCurrentUser ? 'You' : sender.name}</span></span>
                </div>

                {file ? (
                    <ChatFileMessageItem
                        className={classNames({ myself: isCurrentUser })}
                        file={file}
                        id={record.id}
                        resendFile={resendFile}
                        downloadFile={downloadFile}
                    />
                ) : (
                    <ul className={classNames('chat-message-text-list m-0', { myself: isCurrentUser })}>
                        {chatMessage.map((text, index) => (
                            <li className={classNames('chat-message-text')} key={index}>
                                {text}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default ChatMessageItem;

import React from 'react'

const DeleteIcon = ({ height = "32", width = "32" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
            <g id="Group_96497" data-name="Group 96497" transform="translate(-10257 4470)">
                <circle id="Ellipse_1386" data-name="Ellipse 1386" cx="24" cy="24" r="24" transform="translate(10257 -4470)" fill="rgba(255,57,57,0.2)" />
                <g id="delete_black_24dp_10_" data-name="delete_black_24dp (10)" transform="translate(10266 -4461)">
                    <path id="Path_207143" data-name="Path 207143" d="M0,0H30V30H0Z" fill="none" />
                    <path id="Path_207144" data-name="Path 207144" d="M18.521,10.442v12.4H8.688v-12.4h9.833M16.677,3H10.531L9.3,4.24H5V6.721H22.209V4.24h-4.3Zm4.3,4.961H6.229V22.845a2.477,2.477,0,0,0,2.458,2.481h9.833a2.477,2.477,0,0,0,2.458-2.481Z" transform="translate(1.396 0.837)" fill="rgba(255,57,57,0.8)" />
                </g>
            </g>
        </svg>
    )
}

export default DeleteIcon
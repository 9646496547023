import React from 'react'
import CardContainer from '../../../../components/common-components/Cards/Container/CardContainer'
import { MAIN_PATHS } from '../../../../libs/constant'
import { templatesData } from '../../constant'
import Icons from '../../../../components/icons/Icons'
import { useNavigate } from 'react-router-dom'
import Tabs from '../../../../components/common-components/Tabs'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import useGetCurrenPathHook from '../../../../libs/customHooks/useGetCurrenPathHook'
import Button from '../../../../components/common-components/button/Button'
import { USER_PERMISSIONS } from '../../../../libs/UserPermissionsConstants'
import ShowForAllowedPermissionsOnly from '../../../Wrappers/ShowForAllowedPermissionsOnly'

function TemplatesTabContainer() {
    const navigate = useNavigate();
    const currrentPath = useGetCurrenPathHook(true, 2);
    const getAdditionalButtons = () => {
        switch (currrentPath) {
            case '/visitNotes':
                return (
                    <div className="flex justify-between">
                        <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.CREATE_TEMPLATES}>
                            <Button
                                startIcon={{ icon: <Icons iconName="plusVectorIcon" color='white' /> }}
                                variant={BUTTON_VARIANTS.CONTAINED}
                                customBtnClass="mr-5"
                                onClickCb={() => navigate('/settings/templates/new-visit-notes')}
                            >
                                Create New Visit Note
                            </Button>
                        </ShowForAllowedPermissionsOnly>
                    </div>
                );
            default:
                return null;
        }
    };
    return (
        <>
            <CardContainer>
                <div className="justify-between mb-4 flex font-medium text-rgba-1a1a1acc h-[42px]">
                    <div className="flex text-2xl">
                        <span onClick={() => navigate(MAIN_PATHS.SETTINGS)} className="hover:cursor-pointer pt-[4px]">
                            <Icons iconName="arrowBackIcon" />
                        </span>
                        <span className="pl-[10px] font-bold">Templates</span>
                    </div>
                    {getAdditionalButtons()}
                </div>
                <Tabs tabs={templatesData} />
            </CardContainer>
        </>
    )
}

export default TemplatesTabContainer
import { all, call, put, takeLatest } from 'redux-saga/effects'
import store from '../../../../../store/store'
import { componentKey, setColorConfigDetailsData, setIsColorSelectorModalOpen } from './ColorConfigSlice'
import ColorConfigDataService from '../../../../../services/ColorConfigDataService'
import General from '../../../../../libs/utility/General'
import { addNotifications } from '../../../../../components/common-components/toaster/ToasterSlice'
import { TOASTER_VARIANT } from '../../../../../components/common-components/toaster/MetToaster'

export const getColorConfigDetails = (payload) => {
    return {
        type: 'USER_PROFILE/GET_COLOR_CONFIG_DETAILS',
        payload
    }
}


export const updateColorConfig = (payload) => {
    return {
        type: 'USER_PROFILE/UPDATE_COLOR_CONFIG_DETAILS',
        payload
    }
}


function* getColorConfigDetailsAsync() {
    try {
        const response = yield ColorConfigDataService.getColorConfig()
        const { data, status } = response.data
        const colorConfigData = General.addLableValuePair(data, "appointmentType", "colour")

        if (status) {
            yield put(setColorConfigDetailsData(colorConfigData))
        }

    } catch (error) {
        console.log('err: ', error)
    }
}



function* updatePrintConfigDetailsAsync(action) {
    try {
        const { uuid, values } = action.payload;
        const response = yield ColorConfigDataService.updateColorConfig(uuid, values)
        const { status, message } = response.data

        if (status) {
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield put(setIsColorSelectorModalOpen(false))
            yield call(getColorConfigDetailsAsync, getColorConfigDetails());

        }
    }
    catch (error) {
        console.log('err: ', error)
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getColorConfigDetails().type, getColorConfigDetailsAsync),
        takeLatest(updateColorConfig().type, updatePrintConfigDetailsAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
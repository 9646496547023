export const IMAGE_REPORT_FORM_FIELDS_NAMES = {
    // IMAGE_ORDER: 'imageOrder',
    COLLECTION_TIME: 'collectionTime',
    COLLECTION_DATE: 'collectionDate',
    // UPLOAD_OPTION: 'uploadOption',
    // RESULT_WITH_LAB_ORDER: 'resultWithExistingLaborder ',
    // RESULT_WITHOUT_LAB_ORDER: 'resultWithoutExistingLaborder',
    IMAGING_CENTER: 'imageCenterId',
    NOTE: 'note',
    REVIEWER: 'reviewer',
}
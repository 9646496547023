import React, { useEffect, useState } from 'react'
import Button from '../../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants'
import Icons from '../../../../../assets/svgs';
import useBase64Image from './useS3Image';
import RadioButton from '../../../../../components/common-components/radioButton/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { updatePrintConfig } from './PrintConfigSaga';
import DeletePopup from '../../../../../components/common-components/deletePopup/DeletePopup';
import { componentKey, setIsOpenDeletePopup } from './PrintConfigSlice';

const PrintConfigCardItem = ({ item = {}, isHeader = true, handleEditPrintConfig = () => { }, handleDeletePrintConfig = () => { }, customClass = "", borderLINE = false }) => {

    const { isOpenDeletePopup } = useSelector((state) => state[componentKey])
    const dispatch = useDispatch()
    const [base64Data, setBase64Data] = useState("")

    const getBase64 = useBase64Image(item.image);


    useEffect(() => {
        const getBase64Data = async () => {
            const base64Data = await getBase64(item.image)
            setBase64Data(base64Data)
        }
        getBase64Data()
    }, [item.image])

    const fetchAlignment = (align, isJustify = false) => {
        switch (align) {
            case 'left':
                return isJustify ? 'start' : 'left';
            case 'right':
                return isJustify ? 'end' : '-webkit-right';
            case 'fit':
                return isJustify ? 'center' : '-webkit-center';
            default:
                return 'start';
        }
    };

    return (
        <>
            <div
                key={item?.printConfigTitle}
                className={`mt-5 ${customClass}`}
                // TODO: remove this style tag and use the tailwind
                style={{ border: '1px solid #1A1A1A40', borderRadius: '5px' }}
            >
                {isHeader ? <div className="bg-bg-1A1A1A0D p-4 min-h-[93px]">
                    <div className="flex justify-between">
                        <div>
                            <p className="text-met-primary text-[18px]">{item?.headerName}</p>
                        </div>
                        <div className="flex">
                            <Button
                                variant={BUTTON_VARIANTS.OUTLINED}
                                startIcon={{ icon: <Icons iconName="settingsPencilIcon" /> }}
                                onClickCb={() => handleEditPrintConfig(item)
                                }
                            >
                                Edit
                            </Button>
                            <Button
                                color="met-red"
                                customBtnClass="ml-2 bg-red-50 text-red-500 border-red-500"
                                variant={BUTTON_VARIANTS.OUTLINED}
                                startIcon={{ icon: <Icons iconName="settingsDeleteRedIcon" height={24} width={24} /> }}
                                onClickCb={() => {
                                    dispatch(setIsOpenDeletePopup(true))
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                    <div className="mt-3">
                        <RadioButton
                            label="Set This As My Default Header"
                            customLabelClass="text-[16px] text-met-dimmed-charcoal"
                            checked={item?.default}
                            onChangeCb={() => {
                                dispatch(updatePrintConfig({ values: { default: true }, uuid: item.uuid }))
                            }}
                        />
                    </div>
                </div> : null}
                <div className={`p-4 flex justify-${fetchAlignment(item?.align, true)}`}>
                    <div className="" style={{ textAlign: fetchAlignment(item?.align) }}>
                        <span style={{ textAlign: fetchAlignment(item?.align) }}>
                            <img className="max-h-[100px] max-w-[100px]" src={item?.imageURL ? item.imageURL : base64Data} alt="" />
                        </span>
                        <p className="text-rgba-1a1a1acc text-[18px] mt-4"
                            dangerouslySetInnerHTML={{ __html: item?.html }}
                        />
                    </div>
                    <div style={{ textAlign: fetchAlignment(item?.align) }}>

                    </div>
                </div>
                {borderLINE ? <div className="border-dashed border border-gray-400 m-4"></div> : null}
            </div>
            {
                isOpenDeletePopup && <DeletePopup
                    open={isOpenDeletePopup}
                    onClickOk={() => handleDeletePrintConfig(item.uuid)}
                    okButton='Delete'
                    cancelButton='Cancel'
                    onClickCancel={() => {
                        dispatch(setIsOpenDeletePopup(false))
                    }}
                />
            }
        </>

    );
};


export default PrintConfigCardItem

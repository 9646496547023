import { useEffect, useMemo } from 'react';
import '../../styles.scss';
import ChangePasswordModal from './ChangePasswordModal';
import { useDispatch, useSelector } from 'react-redux'
import { componentKey, setIsOpenChangePassWordModal } from './ProviderSlice';
import { getProvidersDetails } from './ProviderProfileSaga';
import { componentKey as routerSliceKey } from '../../../../../routes/RoutesSlice';
import General from '../../../../../libs/utility/General';
import Avatar from '../../../../../components/common-components/avatar';

const ProviderProfile = () => {
	const { providerProfile, isOpenChangePassWordModal } = useSelector(state => state[componentKey])
	const providerLocationsArray = providerProfile.provider_location_intermediate
	const { loggedInUser } = useSelector(state => state[routerSliceKey])

	const dispatch = useDispatch()

	useEffect(() => {
		if (loggedInUser?.uuid) {
			dispatch(getProvidersDetails(loggedInUser?.uuid))
		}
	}, [dispatch, loggedInUser, loggedInUser?.uuid])


	const CLINICAL_INOFRMATION_LABELS = useMemo(() => {
		const { nmcId, licensedStates, licenceNumber, licenceExpireDate } = providerProfile
		const formattedDate = General.getformattedDate(licenceExpireDate)
		return {
			'NMC ID': nmcId,
			'Licensed State (s)': licensedStates?.join(", ") || [],
			'License Number': licenceNumber,
			'Licence Expiry Date': formattedDate,
		}
	}, [providerProfile])

	const WORK_LOCATION = useMemo(() => {
		return {
			'Work Location': providerLocationsArray?.length ? providerLocationsArray.map((location, index) => {
				return `${index + 1}. ${location.provider_locations.name}`
			}) : []
		}
	}, [providerLocationsArray])



	const BASIC_INFORMATION_LABELS = useMemo(() => {
		const { educationWorkExperience, yearsOfExperience, expertiesIn } = providerProfile
		return {
			'Work Experience': educationWorkExperience ? [
				educationWorkExperience] : [],
			'Year Of Experience': yearsOfExperience ? [yearsOfExperience] : [],
			'Expertise In': expertiesIn ? [expertiesIn] : [],

		}
	}, [providerProfile])



	const specialitY = providerProfile.provider_speciality_intermediate
	const specialitydata = specialitY?.map((item) => item.speciality.name)

	const departments = providerProfile.provider_department_intermediate
	const departmentData = departments?.map((item) => item.Department.departmentName)

	const PROFILE_INFO_LABELS = useMemo(() => {
		const { role, providerType, gender, dob, phoneNumber, email, languagesSpoken, officeFaxNumber, bio } = providerProfile
		const { Address } = providerProfile;
		const { city, state, addressLine1, addressLine2, country, zip } = Address?.[0] || {};
		const formattedDOB = General.getformattedDate(dob)
		const formattedGender = General.camelToPascalWithSpaces(gender)
		const formattedAddress = [addressLine1, addressLine2, city, state, country, zip].join(", ");

		return {
			'Provider Type': providerType,
			Role: role,
			Department: departmentData,
			Gender: formattedGender,
			DOB: formattedDOB,
			'Phone Number': phoneNumber,
			Email: email,
			'Languages Spoken': languagesSpoken,
			'Office Fax Number': officeFaxNumber,
			Address: formattedAddress.startsWith(",") ? "-" : formattedAddress,
			Bio: bio,
		};
	}, [providerProfile, departmentData])

		const capitalizeFirstLetter = (str) => {
			return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
			};

		const formatLanguages = (languages) => {
			return languages.map(lang => capitalizeFirstLetter(lang));
			};


	return (

		<>
			<div className="profile-card-height">
				<div className="flex justify-between">
					<div
						style={{ borderRadius: '10px', border: '1px solid #1A1A1A33' }}
						className="bg-white w-1/4 h-full p-6"
					>
						<div className="flex flex-col">
							<div className="flex flex-col">
								<div>
									<Avatar textCenter="middle"
										customClass="w-[130px] h-[130px] overflow-hidden object-cover rounded-full"
										fontSize={3} url={providerProfile?.profilePicture} name={`${providerProfile.firstName} ${providerProfile.lastName}`} />
								</div>
								<p className="mt-4 text-2xl text-rgba-1A1A1A font-bold">
									{`${(loggedInUser.role !== "Front Desk" && loggedInUser.role !== "Biller") ? "Dr. " : ""}${providerProfile.firstName} ${providerProfile.lastName}`}
								</p>

								<div className='pt-2'>
									{specialitydata?.length > 1 ? (
										<>
											<p className="font-semibold p-1 inline-block  bg-rgba-CCECFF4D text-met-primary" >Multispecialist</p>
										</>
									) : specialitydata?.length === 1 ? (
										<>
											{specialitydata?.map((name, index) => (
												<p
													key={"provider-specialities-index" + index}
													className="inline-block bg-rgba-CCECFF4D px-4 py-2 rounded-md font-semibold text-met-primary"
												>
													{name}
												</p>
											))}
										</>
									) : null}
								</div>
							</div>
							<div className="flex flex-col">
								{Object.keys(PROFILE_INFO_LABELS)?.map((key) => {
									if (key === 'Languages Spoken' || key === "Department") {
										return (
											<div key={key} className="flex mb-4">
												<div className="w-1/3 text-met-light-grey fs-14 font-semibold">{key}</div>
												<div className="w-2/3 text-rgba-1a1a1acc fs-14 font-semibold">
												{PROFILE_INFO_LABELS[key] ? formatLanguages(PROFILE_INFO_LABELS[key]).join(", ") : '-'}
												</div>
											</div>
										);
									}
									else {
										return (
											<div key={key} className="flex mt-[12px]">
												<div className="w-1/3 text-met-light-grey fs-14 font-semibold">{key}</div>
												<div className="w-2/3 text-rgba-1a1a1acc fs-14  font-semibold">
													{PROFILE_INFO_LABELS[key] ? PROFILE_INFO_LABELS[key] : '-'}
												</div>
											</div>
										);
									}
								})}
							</div>
						</div>
					</div>
					<div className="flex-1 w-2/3 ml-3">
						<div style={{ borderRadius: '10px', border: '1px solid #1A1A1A33' }} className="bg-white p-6">
							<p className=" text-[20px] text-met-primary font-semibold">Clinical Information</p>
							<div className="flex">
								<div className="w-2/3">
									{Object.keys(CLINICAL_INOFRMATION_LABELS)?.map((key) => (
										<div key={key} className="flex mt-3">
											<div className="w-1/3 text-met-light-grey fs-14 font-semibold">{key}</div>
											<div className="w-2/3 text-rgba-1a1a1acc fs-14 font-semibold">
												{CLINICAL_INOFRMATION_LABELS[key] ? CLINICAL_INOFRMATION_LABELS[key] : '-'}
											</div>
										</div>
									))}
								</div>
								<div className="w-1/2">
									{Object.keys(WORK_LOCATION)?.map((key) => (
										<div key={key} className="flex mt-7">
											<div className="w-1/3 text-met-light-grey fs-14 font-semibold">{key}</div>
											<div className="w-2/3 text-rgba-1a1a1acc fs-14 font-semibold">
												{WORK_LOCATION[key].map((item) => (
													<ol key={item}>{item}</ol>
												))}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<div style={{ borderRadius: '10px', border: '1px solid #1A1A1A33' }} className="bg-white p-6 mt-3">
							<p className="text-[20px] text-met-primary font-semibold">Basic Information</p>
							<div className="">
								{Object.keys(BASIC_INFORMATION_LABELS)?.map((key) => (
									<div key={key} className="flex mt-3.5">
										<div className="w-1/3 text-met-light-grey fs-14 font-semibold">{key}</div>
										<div className="w-2/3 text-rgba-1a1a1acc fs-14 font-semibold">{BASIC_INFORMATION_LABELS[key]?.length ? BASIC_INFORMATION_LABELS[key] : '-'}</div>
									</div>
								))}
							</div>
						</div>
						<div style={{ borderRadius: '10px', border: '1px solid #1A1A1A33' }} className="bg-white p-6 mt-3">
							<div className='flex justify-between items-center'>
								<div className="text-[20px] text-met-primary font-semibold">
									Security Details
								</div>
								<div className='text-met-primary fs-14 font-semibold underline cursor-pointer ' onClick={() => { dispatch(setIsOpenChangePassWordModal(true)) }}>Change Password</div>
							</div>

						</div>
					</div>
				</div>
			</div >
			{isOpenChangePassWordModal && <ChangePasswordModal open={isOpenChangePassWordModal} close={() => { dispatch(setIsOpenChangePassWordModal(false)) }} />
			}
		</>
	);
};
export default ProviderProfile;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/common-components/button/Button';
import ButtonDropdown from '../../../components/common-components/buttonDropdown';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import Icons from '../../../components/icons/Icons';
import { MET_COLORS } from '../../../libs/constant';
import { MetToggleType1 } from '../../../components/common-components/MetToggles/MetToggleType1';
import {
	componentKey,
	setActiveDate,
	setActiveScheduleTab,
	setCalendarViewFormat,
	setIsBlockAppointmentOpen,
	setIsCalenderView,
	setIsInstantModalOpen,
	setIsNewAppointmentModal,
	setShowSheduleFilters,
} from '../ShedulingSlice';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import General from '../../../libs/utility/General';
import { useNavigate } from 'react-router-dom';
import useGetCurrenPathHook from '../../../libs/customHooks/useGetCurrenPathHook';
import moment from 'moment';
import NewAppointmentModal from '../ScheduleAppointmentModals/NewAppointmentModal';
import InstantAppointment from '../ScheduleAppointmentModals/InstantAppointent';
import BlockAppointment from '../ScheduleAppointmentModals/BlockAppointment';
import { getAllScheduleAppointments } from '../SchedulingSaga';
import ShowForAllowedPermissionsOnly from '../../../containers/Wrappers/ShowForAllowedPermissionsOnly';
import { USER_PERMISSIONS } from '../../../libs/UserPermissionsConstants';

const CALENDAR_VIEW_FORMATS = {
	MONTH: 'month',
	WEEK: 'week',
	DAY: 'day',
};
export const navigateOptions = {
	PREVIOUS: 'PREV',
	NEXT: 'NEXT',
	TODAY: 'TODAY',
	DATE: 'DATE',
};

const SchedulingNavbar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const currentPath = useGetCurrenPathHook();
	const {
		showSheduleFilters,
		activeScheduleTab,
		calendarViewFormat,
		activeDate,
		isNewAppointModalOpen,
		isInstantAppointmentModalOpen,
		isBlockApoointmentModalOpen,
		schedulingAppointmentsPaginationState,
	} = useSelector((state) => state[componentKey]);

	useEffect(() => {
		dispatch(setActiveScheduleTab(currentPath));
	}, [currentPath, dispatch]);

	const navigateAction = (action) => {
		switch (action) {
			case navigateOptions.TODAY:
				return dispatch(setActiveDate(new Date()));
			case navigateOptions.NEXT:
				return dispatch(setActiveDate(moment(activeDate).clone().add(1, calendarViewFormat)));
			case navigateOptions.PREVIOUS:
				return dispatch(setActiveDate(moment(activeDate).clone().subtract(1, calendarViewFormat)));
			default:
				return;
		}
	};

	const headingLableFormat = calendarViewFormat === 'day' ? 'D MMMM YYYY' : 'MMMM YYYY';
	const formattedDate = moment(activeDate).format(headingLableFormat);

	return (
		<div className="flex justify-between items-center my-1">
			<div className="flex items-center column-gap-10px ">
				{!showSheduleFilters && (
					<Button
						onClickCb={() => {
							dispatch(setShowSheduleFilters(true));
						}}
						variant={BUTTON_VARIANTS.TEXT}
						startIcon={{ icon: 'filtersIcon', color: 'white' }}
						customBtnClass="text-met-primary py-0 px-2"
					>
						Filters
					</Button>
				)}
				{activeScheduleTab === '/calendar' ? (
					<>
						<Button
							onClickCb={() => navigateAction(navigateOptions.TODAY)}
							variant={BUTTON_VARIANTS.OUTLINED}
						>
							Today
						</Button>
						<Button
							variant={BUTTON_VARIANTS.TEXT}
							customBtnClass="rounded-full size-8 flex justify-center items-center"
							onClickCb={() => navigateAction(navigateOptions.PREVIOUS)}
						>
							<Icons iconName={'expandIcon'} color={MET_COLORS.PRIMARY} rotateDeg={90} />
						</Button>
						<Button
							variant={BUTTON_VARIANTS.TEXT}
							customBtnClass="rounded-full size-8 flex justify-center items-center"
							onClickCb={() => navigateAction(navigateOptions.NEXT)}
						>
							<Icons iconName={'expandIcon'} color={MET_COLORS.PRIMARY} rotateDeg={270} />
						</Button>
						<div className="text-met-primary">{formattedDate || ''}</div>
					</>
				) : null}
			</div>

			<div className="flex items-center column-gap-10px">
				<div>
					{activeScheduleTab === '/calendar' ? (
						<SelectDropdown
							value={General.getLabelValue(calendarViewFormat)}
							onChangeCb={(val) => {
								dispatch(setCalendarViewFormat(val.value));
								// useEffect(() => {
								let dateTo, dateFrom;

								if (val.value === 'day') {
									dateTo = moment(activeDate).format('YYYY-MM-DD');
									dateFrom = moment(activeDate).format('YYYY-MM-DD');
								} else if (val.value === 'month') {
									dateTo = moment().endOf('month').format('YYYY-MM-DD');
									dateFrom = moment().startOf('month').format('YYYY-MM-DD');
								} else if (val.value === 'week') {
									dateTo = moment(activeDate).endOf('week').format('YYYY-MM-DD');
									dateFrom = moment(activeDate).startOf('week').format('YYYY-MM-DD');
								}
								const params = {
									dateTo: dateTo,
									dateFrom: dateFrom,
									// dateTo: moment(schedulingAppointmentsPaginationState?.dateTo).format('YYYY-MM-DD') || undefined,
									// dateFrom: moment(schedulingAppointmentsPaginationState?.dateFrom).format('YYYY-MM-DD') || undefined,
									location: schedulingAppointmentsPaginationState?.location.join(',') || undefined,
									patientId: schedulingAppointmentsPaginationState?.patientId || undefined,
									providerId:
										schedulingAppointmentsPaginationState?.providerId.join(',') || undefined,
									appointmentType:
										(schedulingAppointmentsPaginationState?.appointmentType) ||
										undefined,
									status: (schedulingAppointmentsPaginationState?.status || '').trim() || undefined,
									responseType: 'calender',
								};
								dispatch(getAllScheduleAppointments(params));
								// }, [schedulingAppointmentsPaginationState]);
							}}
							options={[
								General.getLabelValue(CALENDAR_VIEW_FORMATS.DAY),
								General.getLabelValue(CALENDAR_VIEW_FORMATS.MONTH),
								General.getLabelValue(CALENDAR_VIEW_FORMATS.WEEK),
							]}
						/>
					) : null}
				</div>
				<div>
					<MetToggleType1
						options={[
							{
								label: (
									<Icons
										iconName={'listIcon'}
										color={activeScheduleTab === '/list' ? MET_COLORS.GRAY : MET_COLORS.PRIMARY}
									/>
								),
								id: '1',
								value: '/list',
								navigate: 'list',
                                title:"List View"
							},
							{
								label: (
									<Icons
										iconName={'calenderIcon'}
										color={activeScheduleTab === '/calendar' ? MET_COLORS.GRAY : MET_COLORS.PRIMARY}
									/>
								),
								id: '2',
								value: '/calendar',
								navigate: 'calendar',
                                title: "Calender View"
							},
						]}
						selected={currentPath}
						onChangeCb={(option) => {
							navigate(option.navigate);
							if (option?.value === '/calendar') {
								dispatch(setIsCalenderView(true));
							} else {
								dispatch(setIsCalenderView(false));
							}
						}}
					/>
				</div>
				<div>
					<ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.ADD_APPOINTMENT}>
						<ButtonDropdown
							customBtnClass=""
							startIcon={<Icons iconName={'plusVectorIcon'} color={MET_COLORS.GRAY} />}
							label={'Schedule Appointment'}
							buttonVariant={BUTTON_VARIANTS.CONTAINED}
							options={[
								{
									label: 'New Appointment',
									value: null,
									onClickCb: () => {
										dispatch(setIsNewAppointmentModal(true));
									},
								},
								{
									label: 'Instant Appointment',
									value: null,
									onClickCb: () => {
										dispatch(setIsInstantModalOpen(true));
									},
								},
								{
									label: 'Block Appointment',
									value: null,
									onClickCb: () => {
										dispatch(setIsBlockAppointmentOpen(true));
									},
								},
							]}
							selectCb={(selectedOpt) => {
								selectedOpt.onClickCb();
							}}
						/>
					</ShowForAllowedPermissionsOnly>
				</div>
			</div>
			{isNewAppointModalOpen && (
				<NewAppointmentModal
					open={isNewAppointModalOpen}
					close={() => dispatch(setIsNewAppointmentModal(false))}
				/>
			)}
			{isInstantAppointmentModalOpen && (
				<InstantAppointment
					open={isInstantAppointmentModalOpen}
					close={() => dispatch(setIsInstantModalOpen(false))}
				/>
			)}
			{isBlockApoointmentModalOpen && (
				<BlockAppointment
					open={isBlockApoointmentModalOpen}
					close={() => dispatch(setIsBlockAppointmentOpen(false))}
				/>
			)}
		</div>
	);
};

export default SchedulingNavbar;

import React from 'react';
import ProfileCardList from '../ProfileCardList';
import Icons from '../../../../../../components/icons/Icons';
import EmergencyContact from '../../../../../../containers/AddPatient/EmergencyContact.js/EmergencyContact';
import { updateEmergencyContact } from '../../ProfileSaga';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';


const EmergencycontactInfoCard = ({ patientData }) => {
    const { patientId } = useParams();
    const dispatch = useDispatch()
    const data = [
        {
            editbtnIcon: (<Icons iconName="editIcon" />),
        },
        {
            cardTitle: 'Emergency Contact', feildName: 'Relationship With Patient', subFeild: `${patientData?.EmergencyContacts?.map((item) => item?.relationWithPatient).join(", ") || "-"}`
        },
        {
            feildName: 'Last Name',
            subFeild: `${patientData?.EmergencyContacts?.map((item) => item?.lastName).join(", ") || "-"}`
        },
        {
            feildName: 'First Name',
            subFeild: `${patientData?.EmergencyContacts?.map((item) => item?.firstName).join(", ") || "-"}`,
        },
        {
            feildName: 'Mobile Number',
            subFeild: `${patientData?.EmergencyContacts?.map((item) => item?.contactNumber).join(", ") || "-"}`,
        },
        {
            feildName: 'Email',
            subFeild: `${patientData?.EmergencyContacts?.map((item) => item?.email).join(", ") || "-"}`,
        }
    ];
    return (
        <ProfileCardList
            patientUpdateCb={(values, handleSuccess) => {

                const [payload] = values?.emergencyContacts || []
                payload.relationWithPatient = payload.relationWithPatient?.value
                payload.email = payload?.email ? payload.email : undefined

                dispatch(updateEmergencyContact({ uuid: payload?.uuid, updatedData: payload, patientId: patientId, handleSuccess }))

            }}
            data={data}
            customClassName="flex-1 min-w-500 max-w-600 max-h-385"
            Component={EmergencyContact}
            modelTitle={data[1].cardTitle}
        />
    );
};

export default EmergencycontactInfoCard;

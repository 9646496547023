import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getAllPatientHistoryDetails } from "../../Patients/PatientDashboard/History/PatientHistorySaga"
import PastMedicalHistoryCard from "./HistoryCards/PastMedicalHistoryCard"
import PastSurgicalHistoryCard from "./HistoryCards/PastSurgicalHistoryCard"
import { useParams } from "react-router-dom"


const History = () => {

    const dispatch = useDispatch()
    const { patientId } = useParams();
    useEffect(() => {
        dispatch(getAllPatientHistoryDetails({ patientId, historyType: 'pmh' }))
        dispatch(getAllPatientHistoryDetails({ patientId, historyType: 'psh' }))
    }, [])
    return <div className="row-gap-10">
        <div className='flex flex-col column-gap-10px row-gap-10'>
            <div className="mt-[70px]">
                <PastMedicalHistoryCard></PastMedicalHistoryCard>
            </div>
            <div>
                <PastSurgicalHistoryCard></PastSurgicalHistoryCard>
            </div>
        </div>
    </div>
}

export default History
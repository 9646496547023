import React, { useEffect, useState } from 'react'
import Table from '../../../../containers/Table/Index'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Input from '../../../../components/common-components/input/Input'
import General from '../../../../libs/utility/General'
import { genrateSuperBillFieldNames } from './Constants'

import { useDispatch, useSelector } from 'react-redux'
import { componentKey } from './GenrateSuperBillSlice'

function SummaryChargesTable({ values, setFieldValue, handleBlur }) {

    const [totalAmount, setTotalAmount] = useState(0)
    const dispatch = useDispatch()

    const { patientDetails, billData } = useSelector(state => state[componentKey])

    const { mapping_billing_payment } = billData || {}

    const { Insurance } = patientDetails || {}


    useEffect(() => {
        const totalVitalTestAmount = General.getAllSum(values?.tests, "charge")
        const totalProceduralCodesAmount = General.getAllSum(values?.billingProceduralCodes, 'netAmount')
        const finalTotalBill = totalVitalTestAmount + totalProceduralCodesAmount
        setTotalAmount(finalTotalBill)

    }, [values?.billingProceduralCodes, values?.tests])

    useEffect(() => {
        const discountInPercentage = values.discountInPercentage || 0;
        const netCharges = totalAmount - (totalAmount * (discountInPercentage / 100));

        setFieldValue('netCharges', netCharges);

        const insuranceAmount = parseFloat(values?.insuranceAmount || 0);
        const coPayAmount = parseFloat(values?.coPayAmount || 0);

        let totalBalance = netCharges - (insuranceAmount + coPayAmount);

        if (insuranceAmount + coPayAmount > netCharges) {
            // dispatch(addNotifications({ message: `Insurance amount + Copay-amount should be less than Net charges`, variant: TOASTER_VARIANT.ERROR }))
            return;
        }
        else if (totalBalance < 0) {
            totalBalance = 0
        }
        else {
            setFieldValue('totalBalance', totalBalance);
        }

    }, [totalAmount, values.coPayAmount, values.discountInPercentage, values.insuranceAmount, dispatch]);


    const coloumns = [
        {
            field: "totalBill",
            label: <Label fontWeight='md'>Total(₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.totalBill}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "discountInPercentage",
            label: <Label fontWeight='md'>Disc(-%)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.discountInPercentage}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "netCharges",
            label: <Label fontWeight='md'>Net Charges(₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.netCharges}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "insuranceAmount",
            label: <Label fontWeight='md'>Insurance amount to be submit (-₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.insuranceAmount}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "coPayAmount",
            label: <Label fontWeight='md'>Co-Pay(₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">
                                {row.coPayAmount}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "totalBalance",
            label: <Label fontWeight='md'>Total Balance(₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.totalBalance}</Heading>
                    </div>
                </>
            },
        },

    ]

    const getRowData = () => {

        return [{
            totalBill: <Input
                customClasses="w-[60px]"
                name={genrateSuperBillFieldNames.TOTAL_BILL}
                placeholder='Enter'
                value={General.sanitizeNumbers(totalAmount?.toFixed(2))}
                disabled={mapping_billing_payment?.length ? true : false}
            />,
            discountInPercentage: <Input
                customClasses="max-w-[70px]"
                name={genrateSuperBillFieldNames.DISCOUNT_IN_PERCENTAGE}
                value={values[genrateSuperBillFieldNames.DISCOUNT_IN_PERCENTAGE]}
                placeholder='Enter'
                onChangeCb={(e) => {
                    setFieldValue(`${genrateSuperBillFieldNames.DISCOUNT_IN_PERCENTAGE}`, General.sanitizeNumbers(e.target.value),)
                }}
                onBlurCb={handleBlur}
                disabled={mapping_billing_payment?.length ? true : false}
            />,
            netCharges: <Input
                customClasses="max-w-[100px]"
                placeholder='Enter'
                name={genrateSuperBillFieldNames.NET_CHARGES}
                value={values[genrateSuperBillFieldNames.NET_CHARGES]?.toFixed(2)}
                onChangeCb={(e) => {
                    setFieldValue(`${genrateSuperBillFieldNames.NET_CHARGES}`, e.target.value,)
                }}
                disabled={true}
            />,
            insuranceAmount: <Input
                customClasses="w-[60px]"
                name={genrateSuperBillFieldNames.INSURANCE_AMOUNT}
                value={values[genrateSuperBillFieldNames.INSURANCE_AMOUNT]}
                placeholder='Enter'
                onChangeCb={(e) => {
                    setFieldValue(`${genrateSuperBillFieldNames.INSURANCE_AMOUNT}`, General.sanitizeNumbers(e.target.value),)
                }}
                disabled={mapping_billing_payment?.length ? true : false || Insurance?.length ? false : true}
            />,
            coPayAmount: <Input
                customClasses="max-w-[80px]"
                name={genrateSuperBillFieldNames.CO_PAY_AMOUNT}
                value={values[genrateSuperBillFieldNames.CO_PAY_AMOUNT]}
                placeholder='Enter'
                onChangeCb={(e) => {
                    setFieldValue(`${genrateSuperBillFieldNames.CO_PAY_AMOUNT}`, General.sanitizeNumbers(e.target.value),)
                }}
                disabled={mapping_billing_payment?.length ? true : false}
            />,
            totalBalance: <Input
                placeholder='Enter'
                name={genrateSuperBillFieldNames.TOTAL_BALANCE}
                value={values[genrateSuperBillFieldNames.TOTAL_BALANCE]?.toFixed(2)}
                disabled={true}
            />,
        }];
    };


    return (

        <>
            <div className='font-bold mt-5'>
                Summary Of Charges
            </div>

            <div className='mt-5 bg-white bg-no-repeat bg-padding-box border border-gray-300 rounded-lg shadow-sm p-1'>
                <Table isPagination={false} coloumns={coloumns} rows={getRowData()} />
            </div>
        </>
    )
}

export default SummaryChargesTable
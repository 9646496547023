import store from "../../store/store"

export const componentKey = 'UNSIGNED_LIST'

export const unsignedVisitState = {
    pageNumber: 1,
    limit: 10,
    total: 0,
    start: undefined,
    end: undefined,
    status: { label: 'All', value: 'All' }
}

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setUnsignedVisitListState: (state, action) => {
            state.unsignedVisitListState = action.payload
        },
        setUnsignedVisitPaginationState: (state, action) => {
            state.unsignedVisitPaginationState = { ...state.unsignedVisitPaginationState, ...action.payload }
        },
        setResetFilterUnsignedVisitData: (state, action) => {
            state.unsignedVisitPaginationState = action.payload

        }
    },
    initialReducerState: {
        unsignedVisitListState: [],
        unsignedVisitPaginationState: unsignedVisitState,
    }
})

export const { setUnsignedVisitListState, setUnsignedVisitPaginationState, setResetFilterUnsignedVisitData } = actions
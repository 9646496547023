export const SettingsProviderIcon = () => {
	return (
		<svg id="people_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path id="Path_204360" data-name="Path 204360" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_204361"
				data-name="Path 204361"
				d="M9,13.75c-2.34,0-7,1.17-7,3.5V19H16V17.25C16,14.92,11.34,13.75,9,13.75ZM4.34,17A9.642,9.642,0,0,1,9,15.75,9.642,9.642,0,0,1,13.66,17ZM9,12A3.5,3.5,0,1,0,5.5,8.5,3.5,3.5,0,0,0,9,12ZM9,7A1.5,1.5,0,1,1,7.5,8.5,1.5,1.5,0,0,1,9,7Zm7.04,6.81A4.193,4.193,0,0,1,18,17.25V19h4V17.25C22,15.23,18.5,14.08,16.04,13.81ZM15,12a3.5,3.5,0,0,0,0-7,3.446,3.446,0,0,0-1.5.35,5.461,5.461,0,0,1,0,6.3A3.446,3.446,0,0,0,15,12Z"
				fill="rgba(26,26,26,0.5)"
			/>
		</svg>
	);
};

import { useNavigate } from 'react-router-dom';
import RoundFab from '../../../components/common-components/Fab/RoundFab';
import ButtonDropdown from '../../../components/common-components/buttonDropdown';
import { HEADING } from '../../../components/common-components/heading/Constants';
import Heading from '../../../components/common-components/heading/Heading';
import Label from '../../../components/common-components/label/Label';
import Icons from '../../../components/icons/Icons';
import Table from '../../../containers/Table/Index';
import useGetNameInitials from '../../../libs/customHooks/useGetNameInitials';
import BillingViewSummaryModal from './viewSummary/BillingViewSummaryModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	componentKey,
	setIsEditSuperBill,
	setReadyForBillingPaginationState,
} from './genrateSuperBill/GenrateSuperBillSlice';
import { getReadyForBillingList } from './genrateSuperBill/GenrateSuperBillSaga';
import General from '../../../libs/utility/General';
import Input from '../../../components/common-components/input/Input';
import { FORM_FIELDS_NAMES } from '../Payments/Constants';
import SearchBar from '../../../components/common-components/searchInput/SearchBar';
import useDebounced from '../../../libs/customHooks/useDebounced';

const ReadyForBilling = () => {
	const getNameInitials = useGetNameInitials();

	const { readyForBillingData, readyForBillingPaginationState } = useSelector((state) => state[componentKey]);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isOpenBillingPrintForm, setIsOpenBillingPrintForm] = useState(false);
	const [rowData, setRowData] = useState({});
	const [patientName, setPatientName] = useState('');
    const debounced = useDebounced(1000);

	useEffect(() => {
		const params = {
			pageNumber: readyForBillingPaginationState.pageNumber,
			limit: readyForBillingPaginationState.limit,
			name: readyForBillingPaginationState.name
		};
		dispatch(getReadyForBillingList(params));
	}, [dispatch, readyForBillingPaginationState.pageNumber, readyForBillingPaginationState.limit, readyForBillingPaginationState.name]);

	const { readyBilling } = readyForBillingData || {};

	const coloumns = [
		{
			field: 'number',
			label: <Label fontWeight="md">Service ID</Label>,
			renderLogic: (row) => {
				const formattedUUID = row?.uuid.substring(0, 5);
				const UUIDWithUpperCase = formattedUUID.charAt(0).toUpperCase() + formattedUUID.slice(1);
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{UUIDWithUpperCase}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'type',
			label: <Label fontWeight="md">Date Of Service</Label>,
			renderLogic: (row) => {
				const formattedDate = General.getformattedDate(row.createdAt);
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{formattedDate}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'allergy',
			label: <Label fontWeight="md">Patient Name</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<RoundFab customClasses="w-[30px] h-[30px]" color="gray-400">
								<h1 className="fs-12">{getNameInitials(`${'S'} ${'S'}`)}</h1>
							</RoundFab>
							<div className="pl-4">
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{`${row?.patient?.firstName} ${row.patient.lastName} `}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'reaction',
			label: <Label fontWeight="md">Visit Types</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							{row?.schedules?.Locations === null && (
								<div className="pr-2">
									<Icons iconName="videoIcon" color={'#1A1A1A99'} />
								</div>
							)}
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row?.schedules?.Appointment?.appointmentType}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'onsetDate',
			label: <Label fontWeight="md">Chief Complaint</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row?.schedules?.complaints || '-'}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'recordedDate',
			label: <Label fontWeight="md">Rendering Provider</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{`${row.provider.firstName} ${row.provider.lastName} `}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'recordedBy',
			label: <Label fontWeight="md">Place Of Service</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row?.schedules?.Locations?.placeOfService || '-'}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'action',
			label: <Label fontWeight="md">Action</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<ButtonDropdown
								customBtnClass="p-0"
								endIcon="moreVertIcon"
								options={[
									{
										label: 'Generate SuperBill',
										value: null,
										onChangeCb: () => {
											navigate(
												`/billing/genrate-super-bill/${row.patientId}?encounterid=${row.uuid}`
											);
											dispatch(setIsEditSuperBill(false));
										},
									},
									{
										label: 'View Summary',
										value: null,
										onChangeCb: () => {
											setRowData(row);
											setIsOpenBillingPrintForm(true);
										},
									},
								]}
								selectCb={(option) => {
									option.onChangeCb();
								}}
							/>
						</div>
					</>
				);
			},
		},
	];

	return (
		<>
			<div className="flex items-center justify-end p-2 px-4 column-gap-10px">
				<SearchBar
					onChangeCb={(e) => {
						debounced(() => {
							dispatch(setReadyForBillingPaginationState({ name: e.target.value, pageNumber: 1 }));
						});
					}}
					placeholder="Search by patient name,"
				/>
			</div>
			<div className="p-4 mt-10">
				<Table
					isPagination={false}
					coloumns={coloumns}
					rows={readyBilling}
					paginationProps={{
						isPagination: true,
						totalCount: readyForBillingPaginationState.total,
						limit: readyForBillingPaginationState.limit,
						onPageChange: (page) => {
							dispatch(setReadyForBillingPaginationState({ pageNumber: page }));
						},
					}}
				/>
			</div>
			{isOpenBillingPrintForm ? (
				<BillingViewSummaryModal
					patientId={rowData?.encounterForms?.patientId}
					encounterId={rowData?.encounterForms?.uuid}
					scheduleId={rowData?.encounterForms?.schduleId}
					isOpen={isOpenBillingPrintForm}
					close={() => {
						setIsOpenBillingPrintForm(false);
					}}
				/>
			) : null}
		</>
	);
};

export default ReadyForBilling;

import store from "../../store/store"

export const componentKey = 'PRINT_MODAL_SLICE'

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setDefaultPrintConfig: (state, action) => {
            state.defaultPrintConfig = action.payload
        }
    },
    initialReducerState: {
        defaultPrintConfig: {}
    }
})

export const { setDefaultPrintConfig } = actions
import { all, put, takeLatest } from 'redux-saga/effects';
import ProvidersDataService from '../../../../../services/ProvidersDataService';
import {
	componentKey,
	setAllProviders,
	setAllProvidersWorkLocations,
	setCurrentProviderUserUUID,
	setISAddProviderUserModalOpen,
	setIsAddUSerStaffModalOpen,
	setProviderUsersData,
} from './UsersSlice';
import store from '../../../../../store/store';
import General from '../../../../../libs/utility/General';
import { addNotifications } from '../../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../../components/common-components/toaster/MetToaster';

export const {
	postAddProviderUser,
	updateLocations,
	getAllProviders,
	getAllProvidersWorkLocations,
	patchAddProviderUser,
	postCreateInviteLink,
	updateProvidersStatus,
	postAddUser,
	patchAddUser
} = {
	postAddProviderUser: (payload) => {
		return {
			type: 'POST/ADD_PROVIDER_USER',
			payload,
		};
	},
	updateLocations: (payload) => {
		return {
			type: 'PATCH/LOCATION_DATA',
			payload,
		};
	},
	getAllProviders: (filters) => {
		return {
			type: 'GET/ALL_PROVIDERS',
			payload: { filters },
		};
	},
	getAllProvidersWorkLocations: (payload) => {
		return {
			type: 'GET/ALL_PRVIDERS_WORK_LOCATIONS',
			payload,
		};
	},
	patchAddProviderUser: (payload) => {
		return {
			type: 'PATCH/PROVIDER_USER',
			payload,
		};
	},
	postCreateInviteLink: (payload) => {
		return {
			type: 'POST/CREATE_INVITE_LINK',
			payload,
		};
	},
	updateProvidersStatus: (payload) => {
		return {
			type: 'PATCH/PROVIDERS_STATUS',
			payload,
		};
	},
	postAddUser: (payload) => {
		return {

			type: 'POST/ADD_USER',
			payload
		}
	},
	patchAddUser: (payload) => {
		return {
			type: "PATCH/EDIT_USER",
			payload
		}
	}

};

function* postAddProviderUserAsync(action) {
	try {
		const { newProviderUsersData } = action.payload;

		const response = yield ProvidersDataService.postAddProviderUser(newProviderUsersData);
		if (response.status === 201) {
			yield put(setISAddProviderUserModalOpen(false));
			yield put(getAllProviders({ type: 'user' }));
			yield put(addNotifications({ message: 'Provider created succesfully', variant: TOASTER_VARIANT.SUCCESS }));
			yield put(setProviderUsersData({}))
		}
	} catch (error) {
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
		console.log('err: ', error);
	}
}

function* getAllProvidersAsync(action) {
	const { filters } = action.payload;
	const status =
		filters?.filters?.status === 'active'
			? true
			: filters?.filters?.status === 'inactive'
				? false
				: filters?.filters?.status === 'all'
					? ''
					: '';

	const params = {
		name: filters?.filters?.name,
		role: filters?.filters?.role,
		locationId: '',
		departmentId: '',
		type: filters?.filters?.type || filters?.type,
		status: status,
	};
	try {
		const response = yield ProvidersDataService.getAllProviders(params);
		if (response.status) {
			yield put(setAllProviders(response.data.data));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* getAllProvidersWorkLocationsAsync(action) {
	try {
		const response = yield ProvidersDataService.getAllProvidersWorkLocations(action.payload);
		const { data, status } = response;

		const workLocations = General.addLableValuePair(data?.data?.locations, 'name', 'uuid');

		if (status) {
			yield put(setAllProvidersWorkLocations(workLocations));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* patchAddProviderUserAsync(action) {
	try {
		const { uuid, newProviderUsersData } = action.payload;

		const resp = yield ProvidersDataService.patchAddProviderUser(uuid, newProviderUsersData);
		if (resp.status === 200) {
			yield put(setISAddProviderUserModalOpen(false));
			yield put(getAllProviders({ type: 'user' }));
			yield put(setProviderUsersData({}))
			yield put(addNotifications({ message: 'Provider updated succesfully', variant: TOASTER_VARIANT.SUCCESS }));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}
function* postCreateInviteLinkAsync(action) {
	try {
		const { uuid } = action.payload;
		const response = yield ProvidersDataService.postCreateInviteLink(uuid);
		const { message } = response.data;
		yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
	} catch (error) {
		console.log('err: ', error);
	}
}

function* updateProvidersStatusAsync(action) {
	try {
		const { uuid, status } = action.payload;
		const data = {
			status: status,
		};
		const response = yield ProvidersDataService.updateProvidersStatus(uuid, data);
		if (response.status === 200) {
			yield put(setProviderUsersData({}))
			yield put(setCurrentProviderUserUUID(""))
			yield put(getAllProviders({ type: 'user' }));
		}

		const { message } = response.data;

		yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
	} catch (error) {
		console.log(error);
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }));

	}
}

function* postAddUserAsync(action) {
	try {
		const { newUsersData } = action.payload

		const response = yield ProvidersDataService.postAddUser(newUsersData)
		const { message } = response.data;
		if (response.status === 201) {
			yield put(setIsAddUSerStaffModalOpen(false))
			yield put(setProviderUsersData({}))

			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
		}
	} catch (error) {
		console.log(error);
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }));
	}

}
function* patchAddUserAsync(action) {
	try {
		const { id, newUsersData } = action.payload

		const response = yield ProvidersDataService.patchAddUser(id, newUsersData)
		const { message } = response.data;

		if (response.status === 200) {
			yield put(setIsAddUSerStaffModalOpen(false))
			yield put(setProviderUsersData({}))
			yield put(getAllProviders({ type: 'user' }));

			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
		}
	} catch (error) {
		console.log(error);
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }));

	}

}

function* rootSaga() {
	yield all([
		takeLatest(postAddProviderUser().type, postAddProviderUserAsync),
		takeLatest(getAllProvidersWorkLocations().type, getAllProvidersWorkLocationsAsync),
		takeLatest(getAllProviders().type, getAllProvidersAsync),
		takeLatest(patchAddProviderUser().type, patchAddProviderUserAsync),
		takeLatest(postCreateInviteLink().type, postCreateInviteLinkAsync),
		takeLatest(updateProvidersStatus().type, updateProvidersStatusAsync),
		takeLatest(postAddUser().type, postAddUserAsync),
		takeLatest(patchAddUser().type, patchAddUserAsync)
	]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

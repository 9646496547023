import React from 'react';
export const PersonWithBorderIcon = () => {
	return (
		<svg id="account_box_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g id="Group_87656" data-name="Group 87656">
				<rect id="Rectangle_36020" data-name="Rectangle 36020" width="24" height="24" fill="none" />
			</g>
			<g id="Group_87657" data-name="Group 87657">
				<path
					id="Path_205370"
					data-name="Path 205370"
					d="M19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM17,19H7v-.24a7.982,7.982,0,0,1,10,0Zm2-1.14a10,10,0,0,0-14,0V5H19ZM12,13A3.5,3.5,0,1,0,8.5,9.5,3.5,3.5,0,0,0,12,13Zm0-5a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,12,8Z"
					fill="rgba(26,26,26,0.6)"
				/>
			</g>
		</svg>
	);
};


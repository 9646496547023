import React, { useEffect, useState } from 'react';
import Table from '../../../../../Table/Index';
import Label from '../../../../../../components/common-components/label/Label';
import Avatar from '../../../../../../components/common-components/avatar';
import Heading from '../../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../../components/common-components/heading/Constants';
import MetPopOver from '../../../../../../components/common-components/popoverComponent/MetPopOver';
import { useNavigate } from 'react-router-dom';
import { getFormattedPatientAgeDob } from '../../../../../../libs/patientUtils';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProviders, getPatientsByLocationId } from '../LocationSaga';
import { componentKey as locationComponentKey } from '../LocationSlice';
import SearchBar from '../../../../../../components/common-components/searchInput/SearchBar';
import useDebounced from '../../../../../../libs/customHooks/useDebounced';

const Users = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const debounced = useDebounced(1000);
	const [searchUser, setSerachUser] = useState('');
	const { editLocationData, allProviders } = useSelector((state) => state[locationComponentKey]);

	const columns = [
		{
			field: 'pcp',
			label: <Label fontWeight="bold">User Id</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row?.uuid?.substring(row?.uuid?.length - 3)}
						</Heading>
					</>
				);
			},
			sort: 'firstName',
		},
		{
			field: 'PatientName',
			label: <Label fontWeight="bold">Provider Name</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-start column-gap-10px">
							<Avatar
								customClass="w-[40px] h-[40px] rounded-full"
								url={row?.profilePicture}
								name={`${row.firstName} ${row.lastName}`}
							/>
							<div>
								<Heading
									// onClickCb={() => {
									// 	navigate(row.uuid);
									// }}
									fontWeight="bold"
									type={HEADING.H1}
									color="met-primary mt-2"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{`${row.firstName} ${row.lastName}`}
								</Heading>
								{/* <Label color="gray-500">{row.subLabel}</Label> */}
							</div>
						</div>
					</>
				);
			},
			sort: 'firstName',
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Role</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row?.role}
						</Heading>
					</>
				);
			},
			sort: 'firstName',
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Department</Label>,
			renderLogic: (row) => {
				return (
					<>
						{row?.provider_department_intermediate.map((item, index) => (
							<Heading type={HEADING.H1} color="gray-500" fontSize={'md'} key={index}>
								{item?.Department?.departmentName}
							</Heading>
						))}
					</>
				);
			},
			sort: 'firstName',
		},

		{
			field: 'contactDetails',
			label: <Label fontWeight="bold">Contact Details</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div>
							<Label color="gray-500">{row?.phoneNumber}</Label>
							<div>
								<Label color="met-primary">{row.email}</Label>
							</div>
						</div>
					</>
				);
			},
			sort: 'mobileNumber',
		},

		{
			field: 'lastVisit',
			label: <Label fontWeight="bold">Last Visit</Label>,
			renderLogic: (row) => {
				const formattedDate = row.lastLogin ? moment(row.lastLogin).format('ddd MMM DD YYYY') : '-';
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{formattedDate}
						</Heading>
					</>
				);
			},
			sort: 'lastVisit',
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Status</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							<span
								className={`${row.status === true
									? 'text-met-success border-b-[1px] border-b-met-success'
									: 'text-met-red border-b-[1px] border-b-met-red'
									}`}
							>
								{row.status === true ? 'Active' : 'Inactive'}
							</span>
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
	];
	useEffect(() => {
		dispatch(getAllProviders({ locationId: editLocationData?.uuid, name: searchUser, type: 'user' }));
	}, [editLocationData?.uuid, searchUser, dispatch]);
	return (
		<div className="w-full">
			<div className="flex justify-end">
				<SearchBar
					onChangeCb={(e) => {
						debounced(() => {
							setSerachUser(e.target.value);
						});
					}}
					placeholder="Search By Name"
					customclasses="w-[35%]"
				/>
			</div>
			<div className=" h-[65vh] met-scrollbar overflow-auto mt-4">
				<Table rows={allProviders} coloumns={columns} />
			</div>
		</div>
	);
};
export default Users;

import React from 'react'
import SelectDropdown from '../../../../components/common-components/selectDropdown'
import Label from '../../../../components/common-components/label/Label'
import TextArea from '../../../../components/common-components/textArea/TextArea'
import Input from '../../../../components/common-components/input/Input'
import DatePicker from '../../../../components/common-components/datePicker'
import Icons from '../../../../components/icons/Icons'
import { ADMINISTERED_FORM_FIELDS_NAMES } from './Constants'

function VaccineAdministeredModal({ values = {}, setFieldValue = () => { } }, handleBlurCb = () => { }) {
    return (
        <div className='max-w-[1200px] min-w-[1200px]'>
            <div>
                <div className='pt-5'>
                    <Input
                        label='Vaccine Name'
                        isRequired={true}
                        placeholder='Enter Vaccine Name'
                        name={ADMINISTERED_FORM_FIELDS_NAMES.VACCINE_NAME}
                        value={values[ADMINISTERED_FORM_FIELDS_NAMES.VACCINE_NAME]}
                        onChangeCb={(e) => { setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.VACCINE_NAME, e.target.value) }}
                    />
                </div>
            </div>
            <div className='flex column-gap-10px items-center pt-6'>
                <div className='flex-1'>
                    <DatePicker
                        label="Administer Date"
                        placeholder='Choose Date'
                        isRequired={true}
                        maxDate={new Date()}
                        value={values[ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_DATE]}
                        name={ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_DATE}
                        onChangeCb={(date) => {
                            setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_DATE, date)
                        }} />
                </div>
                <div className='flex-1'>
                    <DatePicker
                        name={ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_TIME}
                        placeholder='Choose Time'
                        label='Administer Time'
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        value={values[ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_TIME]}
                        onChangeCb={(time) => {
                            setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_TIME, time)
                        }}
                        iconName='scheduleIcon'
                    />
                </div>
                <div className='flex-1'>
                    <Input
                        label="Ordered By"
                        isRequired={true}
                        placeholder="Enter Ordered By"
                        name={ADMINISTERED_FORM_FIELDS_NAMES.OREDERD_BY}
                        value={values[ADMINISTERED_FORM_FIELDS_NAMES.OREDERD_BY]}
                        onChangeCb={(e) => {
                            setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.OREDERD_BY, e.target.value);
                        }}
                    />
                </div>
                <div className='flex-1'>
                    <Input
                        label="Administered By"
                        isRequired={true}
                        placeholder="Enter Administered By"
                        name={ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_BY}
                        value={values[ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_BY]}
                        onChangeCb={(e) => {
                            setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.ADMINISTERED_BY, e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className='flex column-gap-10px items-center pt-6'>
                <div className='flex-1'>
                    <div>
                        <Input
                            label='Manufacturer'
                            placeholder={"Enter Manufacturer"}
                            name={ADMINISTERED_FORM_FIELDS_NAMES.MANUFACTURER}
                            value={values[ADMINISTERED_FORM_FIELDS_NAMES.MANUFACTURER]}
                            onChangeCb={(e) => { setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.MANUFACTURER, e.target.value) }}
                        />
                    </div>
                </div>
                <div className='flex-1'>
                    <div>
                        <Input
                            label='NDC Code'
                            placeholder={"Enter NDC Code"}
                            name={ADMINISTERED_FORM_FIELDS_NAMES.NDC_CODE}
                            value={values[ADMINISTERED_FORM_FIELDS_NAMES.NDC_CODE]}
                            onChangeCb={(e) => { setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.NDC_CODE, e.target.value) }}
                        />
                    </div>
                </div>
            </div>
            <div className='flex column-gap-10px items-center pt-6'>
                <div className='flex-1'>
                    <div>
                        <Input
                            label='LOT'
                            placeholder={"Enter"}
                            name={ADMINISTERED_FORM_FIELDS_NAMES.LOT}
                            value={values[ADMINISTERED_FORM_FIELDS_NAMES.LOT]}
                            onChangeCb={(e) => { setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.LOT, e.target.value) }}
                        />
                    </div>
                </div>
                <div className='flex-1' >
                    <div>
                        <Input
                            label='Dose'
                            placeholder={"Enter"}
                            name={ADMINISTERED_FORM_FIELDS_NAMES.DOSE}
                            value={values[ADMINISTERED_FORM_FIELDS_NAMES.DOSE]}
                            onChangeCb={(e) => { setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.DOSE, e.target.value) }}
                        />
                    </div>
                </div>
                <div className='flex-1'>
                    <SelectDropdown
                        placeholder='Unit'
                        label='Units'
                        isRequired={true}
                        name={ADMINISTERED_FORM_FIELDS_NAMES.UNITS}
                        value={values[ADMINISTERED_FORM_FIELDS_NAMES.UNITS]}
                        onChangeCb={(val) => {
                            setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.UNITS, val)
                        }}
                        options={[{ label: "Mg", value: "mg" }, { label: "Tablet", value: "Tablet" }]} />
                </div>
                <div className='flex-1'>
                    <SelectDropdown
                        placeholder='Route'
                        label='Route'
                        isRequired={true}
                        name={ADMINISTERED_FORM_FIELDS_NAMES.ROUTE}
                        value={values[ADMINISTERED_FORM_FIELDS_NAMES.ROUTE]}
                        onChangeCb={(val) => { setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.ROUTE, val) }}
                        options={[{ label: "Buccal", value: "Buccal" }, { label: "Dental", value: "Dental" }, { label: "Epidural", value: "Epidural" }, { label: "Injection", value: "Injection" }]} />
                </div>
                <div className='flex-1'>
                    <SelectDropdown
                        placeholder='Site'
                        label='Body Site'
                        isRequired={true}
                        name={ADMINISTERED_FORM_FIELDS_NAMES.BODY_SITE}
                        value={values[ADMINISTERED_FORM_FIELDS_NAMES.BODY_SITE]}
                        onChangeCb={(val) => { setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.BODY_SITE, val) }}
                        options={[{ label: "Ankle, left", value: "Ankle_left" }, { label: "Ankle, right", value: "Ankle_right" }, { label: "Bladder", value: "Bladder" }, { label: "Arm, left upper", value: "Arm_left_upper" }]} />
                </div>
                <div className='flex-1'>
                    <DatePicker
                        label="Expiry Date"
                        placeholder='Choose Date'
                        isRequired={true}
                        minDate={new Date()}
                        value={values[ADMINISTERED_FORM_FIELDS_NAMES.EXPIRY_DATE]}
                        name={ADMINISTERED_FORM_FIELDS_NAMES.EXPIRY_DATE}
                        onChangeCb={(date) => {
                            setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.EXPIRY_DATE, date)
                        }} />
                </div>
            </div>
            <div className='flex column-gap-10px items-center pt-6'>
                <div className='flex-1'>
                    <div>
                        <Input
                            label='Age'
                            placeholder={"Enter"}
                            name={ADMINISTERED_FORM_FIELDS_NAMES.AGE}
                            value={values[ADMINISTERED_FORM_FIELDS_NAMES.AGE]}
                            onChangeCb={(e) => { setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.AGE, e.target.value) }}
                        />
                    </div>
                </div>
                <div className='flex-1' >
                    <SelectDropdown
                        placeholder='Select Reaction'
                        label='Reaction'
                        isRequired={true}
                        name={ADMINISTERED_FORM_FIELDS_NAMES.REACTION}
                        value={values[ADMINISTERED_FORM_FIELDS_NAMES.REACTION]}
                        onChangeCb={(val) => { setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.REACTION, val) }}
                        options={[{ label: "Anaphylaxis", value: "Anaphylaxis" }, { label: "Encephalopathy", value: "Encephalopathy" }, { label: "Collapse or shock-like state", value: "Collapse_or_shock_like_state" }, { label: "Convulsions within 72 hours Of dose", value: "convulsions_within_72_hours_of_dose" }]} />
                </div>
                <div className='width-50'>
                    <div>
                        <Input
                            label='Reason'
                            placeholder={"Reason"}
                            name={ADMINISTERED_FORM_FIELDS_NAMES.REASON}
                            value={values[ADMINISTERED_FORM_FIELDS_NAMES.REASON]}
                            onChangeCb={(e) => { setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.REASON, e.target.value) }}
                        />
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <TextArea
                    label='Note'
                    labelfontWeight='bold'
                    name={ADMINISTERED_FORM_FIELDS_NAMES.NOTE}
                    value={values[ADMINISTERED_FORM_FIELDS_NAMES.NOTE]}
                    onChangeCb={(e) => { setFieldValue(ADMINISTERED_FORM_FIELDS_NAMES.NOTE, e.target.value) }}
                />
            </div>
        </div >
    )
}

export default VaccineAdministeredModal
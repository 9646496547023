export const SettingsVideoIcon = () => {
	return (
		<svg id="video_call_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path id="Path_205551" data-name="Path 205551" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_205552"
				data-name="Path 205552"
				d="M17,10.5V7a1,1,0,0,0-1-1H4A1,1,0,0,0,3,7V17a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1V13.5l4,4V6.5ZM15,16H5V8H15Zm-2-3v0Z"
				fill="rgba(26,26,26,0.8)"
			/>
		</svg>
	);
};

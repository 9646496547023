
export const formDefinition = {
    components: [
        {
            "label": "CHIEF COMPLAINT (CC)",
            "labelPosition": "top",
            "placeholder": "Chief Complaint (CC)",
            "description": "",
            "tooltip": "",
            "prefix": "",
            "suffix": "",
            "widget": {
                "type": "input"
            },
            "inputMask": "",
            "displayMask": "",
            "applyMaskOn": "change",
            "allowMultipleMasks": false,
            "customClass": "",
            "tabindex": "",
            "autocomplete": "",
            "hidden": false,
            "hideLabel": false,
            "showWordCount": false,
            "showCharCount": false,
            "mask": false,
            "autofocus": false,
            "spellcheck": true,
            "disabled": false,
            "tableView": true,
            "modalEdit": false,
            "multiple": false,
            "persistent": true,
            "inputFormat": "plain",
            "protected": false,
            "dbIndex": false,
            "case": "",
            "truncateMultipleSpaces": false,
            "encrypted": false,
            "redrawOn": "",
            "clearOnHide": true,
            "customDefaultValue": "",
            "calculateValue": "",
            "calculateServer": false,
            "allowCalculateOverride": false,
            "validateOn": "change",
            "validate": {
                "required": false,
                "pattern": "",
                "customMessage": "",
                "custom": "",
                "customPrivate": false,
                "json": "",
                "minLength": "",
                "maxLength": "",
                "strictDateValidation": false,
                "multiple": false,
                "unique": false
            },
            "unique": false,
            "errorLabel": "",
            "errors": "",
            "key": "chiefComplaintCc",
            "tags": [],
            "properties": {},
            "conditional": {
                "show": null,
                "when": null,
                "eq": "",
                "json": ""
            },
            "customConditional": "",
            "logic": [],
            "attributes": {},
            "overlay": {
                "style": "",
                "page": "",
                "left": "",
                "top": "",
                "width": "",
                "height": ""
            },
            "type": "textfield",
            "input": true,
            "refreshOn": "",
            "dataGridLabel": false,
            "addons": [],
            "inputType": "text",
            "id": "esm275o",
            "defaultValue": ""
        },
        {
            "label": "SUBJECTIVE",
            "labelPosition": "top",
            "placeholder": "",
            "description": "",
            "tooltip": "",
            "prefix": "",
            "suffix": "",
            "widget": {
                "type": "input"
            },
            "displayMask": "",
            "applyMaskOn": "change",
            "editor": "ckeditor",
            "customClass": "",
            "tabindex": "",
            "autocomplete": "",
            "hidden": false,
            "hideLabel": false,
            "showWordCount": false,
            "showCharCount": false,
            "autofocus": false,
            "spellcheck": true,
            "disabled": false,
            "tableView": true,
            "modalEdit": false,
            "multiple": false,
            "persistent": true,
            "inputFormat": "html",
            "protected": false,
            "dbIndex": false,
            "case": "",
            "truncateMultipleSpaces": false,
            "encrypted": false,
            "redrawOn": "",
            "clearOnHide": true,
            "customDefaultValue": "",
            "calculateValue": "",
            "calculateServer": false,
            "allowCalculateOverride": false,
            "validateOn": "change",
            "validate": {
                "required": false,
                "pattern": "",
                "customMessage": "",
                "custom": "",
                "customPrivate": false,
                "json": "",
                "minLength": "",
                "maxLength": "",
                "minWords": "",
                "maxWords": "",
                "strictDateValidation": false,
                "multiple": false,
                "unique": false
            },
            "unique": false,
            "errorLabel": "",
            "errors": "",
            "key": "subjective",
            "tags": [],
            "properties": {},
            "conditional": {
                "show": null,
                "when": null,
                "eq": "",
                "json": ""
            },
            "customConditional": "",
            "logic": [],
            "attributes": {},
            "overlay": {
                "style": "",
                "page": "",
                "left": "",
                "top": "",
                "width": "",
                "height": ""
            },
            "type": "textarea",
            "rows": 3,
            "wysiwyg": false,
            "input": true,
            "refreshOn": "",
            "dataGridLabel": false,
            "allowMultipleMasks": false,
            "addons": [],
            "mask": false,
            "inputType": "text",
            "inputMask": "",
            "fixedSize": true,
            "id": "enbsx8c",
            "defaultValue": "",
            "isUploadEnabled": false
        },
        {
            "label": "History Of Present Illness (HPI)",
            "labelPosition": "top",
            "placeholder": "",
            "description": "",
            "tooltip": "",
            "prefix": "",
            "suffix": "",
            "widget": {
                "type": "input"
            },
            "displayMask": "",
            "applyMaskOn": "change",
            "editor": "ckeditor",
            "customClass": "",
            "tabindex": "",
            "autocomplete": "",
            "hidden": false,
            "hideLabel": false,
            "showWordCount": false,
            "showCharCount": false,
            "autofocus": false,
            "spellcheck": true,
            "disabled": false,
            "tableView": true,
            "modalEdit": false,
            "multiple": false,
            "persistent": true,
            "inputFormat": "html",
            "protected": false,
            "dbIndex": false,
            "case": "",
            "truncateMultipleSpaces": false,
            "encrypted": false,
            "redrawOn": "",
            "clearOnHide": true,
            "customDefaultValue": "",
            "calculateValue": "",
            "calculateServer": false,
            "allowCalculateOverride": false,
            "validateOn": "change",
            "validate": {
                "required": false,
                "pattern": "",
                "customMessage": "",
                "custom": "",
                "customPrivate": false,
                "json": "",
                "minLength": "",
                "maxLength": "",
                "minWords": "",
                "maxWords": "",
                "strictDateValidation": false,
                "multiple": false,
                "unique": false
            },
            "unique": false,
            "errorLabel": "",
            "errors": "",
            "key": "historyOfPresentIllnessHpi",
            "tags": [],
            "properties": {},
            "conditional": {
                "show": null,
                "when": null,
                "eq": "",
                "json": ""
            },
            "customConditional": "",
            "logic": [],
            "attributes": {},
            "overlay": {
                "style": "",
                "page": "",
                "left": "",
                "top": "",
                "width": "",
                "height": ""
            },
            "type": "textarea",
            "rows": 3,
            "wysiwyg": false,
            "input": true,
            "refreshOn": "",
            "dataGridLabel": false,
            "allowMultipleMasks": false,
            "addons": [],
            "mask": false,
            "inputType": "text",
            "inputMask": "",
            "fixedSize": true,
            "id": "eu4maai",
            "defaultValue": "",
            "isUploadEnabled": false
        },
        {
            "label": "OBJECTIVE",
            "labelPosition": "top",
            "placeholder": "",
            "description": "",
            "tooltip": "",
            "prefix": "",
            "suffix": "",
            "widget": {
                "type": "input"
            },
            "displayMask": "",
            "applyMaskOn": "change",
            "editor": "ckeditor",
            "customClass": "",
            "tabindex": "",
            "autocomplete": "",
            "hidden": false,
            "hideLabel": false,
            "showWordCount": false,
            "showCharCount": false,
            "autofocus": false,
            "spellcheck": true,
            "disabled": false,
            "tableView": true,
            "modalEdit": false,
            "multiple": false,
            "persistent": true,
            "inputFormat": "html",
            "protected": false,
            "dbIndex": false,
            "case": "",
            "truncateMultipleSpaces": false,
            "encrypted": false,
            "redrawOn": "",
            "clearOnHide": true,
            "customDefaultValue": "",
            "calculateValue": "",
            "calculateServer": false,
            "allowCalculateOverride": false,
            "validateOn": "change",
            "validate": {
                "required": false,
                "pattern": "",
                "customMessage": "",
                "custom": "",
                "customPrivate": false,
                "json": "",
                "minLength": "",
                "maxLength": "",
                "minWords": "",
                "maxWords": "",
                "strictDateValidation": false,
                "multiple": false,
                "unique": false
            },
            "unique": false,
            "errorLabel": "",
            "errors": "",
            "key": "objective",
            "tags": [],
            "properties": {},
            "conditional": {
                "show": null,
                "when": null,
                "eq": "",
                "json": ""
            },
            "customConditional": "",
            "logic": [],
            "attributes": {},
            "overlay": {
                "style": "",
                "page": "",
                "left": "",
                "top": "",
                "width": "",
                "height": ""
            },
            "type": "textarea",
            "rows": 3,
            "wysiwyg": false,
            "input": true,
            "refreshOn": "",
            "dataGridLabel": false,
            "allowMultipleMasks": false,
            "addons": [],
            "mask": false,
            "inputType": "text",
            "inputMask": "",
            "fixedSize": true,
            "id": "euljd0n",
            "defaultValue": "",
            "isUploadEnabled": false
        },
        {
            "label": "ASSESSMENT",
            "labelPosition": "top",
            "placeholder": "",
            "description": "",
            "tooltip": "",
            "prefix": "",
            "suffix": "",
            "widget": {
                "type": "input"
            },
            "displayMask": "",
            "applyMaskOn": "change",
            "editor": "ckeditor",
            "customClass": "",
            "tabindex": "",
            "autocomplete": "",
            "hidden": false,
            "hideLabel": false,
            "showWordCount": false,
            "showCharCount": false,
            "autofocus": false,
            "spellcheck": true,
            "disabled": false,
            "tableView": true,
            "modalEdit": false,
            "multiple": false,
            "persistent": true,
            "inputFormat": "html",
            "protected": false,
            "dbIndex": false,
            "case": "",
            "truncateMultipleSpaces": false,
            "encrypted": false,
            "redrawOn": "",
            "clearOnHide": true,
            "customDefaultValue": "",
            "calculateValue": "",
            "calculateServer": false,
            "allowCalculateOverride": false,
            "validateOn": "change",
            "validate": {
                "required": false,
                "pattern": "",
                "customMessage": "",
                "custom": "",
                "customPrivate": false,
                "json": "",
                "minLength": "",
                "maxLength": "",
                "minWords": "",
                "maxWords": "",
                "strictDateValidation": false,
                "multiple": false,
                "unique": false
            },
            "unique": false,
            "errorLabel": "",
            "errors": "",
            "key": "assessment",
            "tags": [],
            "properties": {},
            "conditional": {
                "show": null,
                "when": null,
                "eq": "",
                "json": ""
            },
            "customConditional": "",
            "logic": [],
            "attributes": {},
            "overlay": {
                "style": "",
                "page": "",
                "left": "",
                "top": "",
                "width": "",
                "height": ""
            },
            "type": "textarea",
            "rows": 3,
            "wysiwyg": false,
            "input": true,
            "refreshOn": "",
            "dataGridLabel": false,
            "allowMultipleMasks": false,
            "addons": [],
            "mask": false,
            "inputType": "text",
            "inputMask": "",
            "fixedSize": true,
            "id": "e58b39",
            "defaultValue": "",
            "isUploadEnabled": false
        },
        {
            "label": "PLAN",
            "labelPosition": "top",
            "placeholder": "",
            "description": "",
            "tooltip": "",
            "prefix": "",
            "suffix": "",
            "widget": {
                "type": "input"
            },
            "displayMask": "",
            "applyMaskOn": "change",
            "editor": "ckeditor",
            "customClass": "",
            "tabindex": "",
            "autocomplete": "",
            "hidden": false,
            "hideLabel": false,
            "showWordCount": false,
            "showCharCount": false,
            "autofocus": false,
            "spellcheck": true,
            "disabled": false,
            "tableView": true,
            "modalEdit": false,
            "multiple": false,
            "persistent": true,
            "inputFormat": "html",
            "protected": false,
            "dbIndex": false,
            "case": "",
            "truncateMultipleSpaces": false,
            "encrypted": false,
            "redrawOn": "",
            "clearOnHide": true,
            "customDefaultValue": "",
            "calculateValue": "",
            "calculateServer": false,
            "allowCalculateOverride": false,
            "validateOn": "change",
            "validate": {
                "required": false,
                "pattern": "",
                "customMessage": "",
                "custom": "",
                "customPrivate": false,
                "json": "",
                "minLength": "",
                "maxLength": "",
                "minWords": "",
                "maxWords": "",
                "strictDateValidation": false,
                "multiple": false,
                "unique": false
            },
            "unique": false,
            "errorLabel": "",
            "errors": "",
            "key": "plan",
            "tags": [],
            "properties": {},
            "conditional": {
                "show": null,
                "when": null,
                "eq": "",
                "json": ""
            },
            "customConditional": "",
            "logic": [],
            "attributes": {},
            "overlay": {
                "style": "",
                "page": "",
                "left": "",
                "top": "",
                "width": "",
                "height": ""
            },
            "type": "textarea",
            "rows": 3,
            "wysiwyg": false,
            "input": true,
            "refreshOn": "",
            "dataGridLabel": false,
            "allowMultipleMasks": false,
            "addons": [],
            "mask": false,
            "inputType": "text",
            "inputMask": "",
            "fixedSize": true,
            "id": "eqryfii",
            "defaultValue": "",
            "isUploadEnabled": false
        },
        {
            "label": "Follow Up & Instruction Note",
            "labelPosition": "top",
            "placeholder": "",
            "description": "",
            "tooltip": "",
            "prefix": "",
            "suffix": "",
            "widget": {
                "type": "input"
            },
            "displayMask": "",
            "applyMaskOn": "change",
            "editor": "ckeditor",
            "customClass": "",
            "tabindex": "",
            "autocomplete": "",
            "hidden": false,
            "hideLabel": false,
            "showWordCount": false,
            "showCharCount": false,
            "autofocus": false,
            "spellcheck": true,
            "disabled": false,
            "tableView": true,
            "modalEdit": false,
            "multiple": false,
            "persistent": true,
            "inputFormat": "html",
            "protected": false,
            "dbIndex": false,
            "case": "",
            "truncateMultipleSpaces": false,
            "encrypted": false,
            "redrawOn": "",
            "clearOnHide": true,
            "customDefaultValue": "",
            "calculateValue": "",
            "calculateServer": false,
            "allowCalculateOverride": false,
            "validateOn": "change",
            "validate": {
                "required": false,
                "pattern": "",
                "customMessage": "",
                "custom": "",
                "customPrivate": false,
                "json": "",
                "minLength": "",
                "maxLength": "",
                "minWords": "",
                "maxWords": "",
                "strictDateValidation": false,
                "multiple": false,
                "unique": false
            },
            "unique": false,
            "errorLabel": "",
            "errors": "",
            "key": "followUpInstructionNote",
            "tags": [],
            "properties": {},
            "conditional": {
                "show": null,
                "when": null,
                "eq": "",
                "json": ""
            },
            "customConditional": "",
            "logic": [],
            "attributes": {},
            "overlay": {
                "style": "",
                "page": "",
                "left": "",
                "top": "",
                "width": "",
                "height": ""
            },
            "type": "textarea",
            "rows": 3,
            "wysiwyg": false,
            "input": true,
            "refreshOn": "",
            "dataGridLabel": false,
            "allowMultipleMasks": false,
            "addons": [],
            "mask": false,
            "inputType": "text",
            "inputMask": "",
            "fixedSize": true,
            "id": "eiouldh",
            "defaultValue": "",
            "isUploadEnabled": false
        },
        {
            "label": "Additional Note",
            "labelPosition": "top",
            "placeholder": "",
            "description": "",
            "tooltip": "",
            "prefix": "",
            "suffix": "",
            "widget": {
                "type": "input"
            },
            "displayMask": "",
            "applyMaskOn": "change",
            "editor": "ckeditor",
            "customClass": "",
            "tabindex": "",
            "autocomplete": "",
            "hidden": false,
            "hideLabel": false,
            "showWordCount": false,
            "showCharCount": false,
            "autofocus": false,
            "spellcheck": true,
            "disabled": false,
            "tableView": true,
            "modalEdit": false,
            "multiple": false,
            "persistent": true,
            "inputFormat": "html",
            "protected": false,
            "dbIndex": false,
            "case": "",
            "truncateMultipleSpaces": false,
            "encrypted": false,
            "redrawOn": "",
            "clearOnHide": true,
            "customDefaultValue": "",
            "calculateValue": "",
            "calculateServer": false,
            "allowCalculateOverride": false,
            "validateOn": "change",
            "validate": {
                "required": false,
                "pattern": "",
                "customMessage": "",
                "custom": "",
                "customPrivate": false,
                "json": "",
                "minLength": "",
                "maxLength": "",
                "minWords": "",
                "maxWords": "",
                "strictDateValidation": false,
                "multiple": false,
                "unique": false
            },
            "unique": false,
            "errorLabel": "",
            "errors": "",
            "key": "additionalNote",
            "tags": [],
            "properties": {},
            "conditional": {
                "show": null,
                "when": null,
                "eq": "",
                "json": ""
            },
            "customConditional": "",
            "logic": [],
            "attributes": {},
            "overlay": {
                "style": "",
                "page": "",
                "left": "",
                "top": "",
                "width": "",
                "height": ""
            },
            "type": "textarea",
            "rows": 3,
            "wysiwyg": false,
            "input": true,
            "refreshOn": "",
            "dataGridLabel": false,
            "allowMultipleMasks": false,
            "addons": [],
            "mask": false,
            "inputType": "text",
            "inputMask": "",
            "fixedSize": true,
            "id": "exikil",
            "defaultValue": "",
            "isUploadEnabled": false
        },
        {
            "label": "Columns",
            "columns": [
                {
                    "components": [
                        {
                            "label": "Cancel",
                            "action": "event",
                            "showValidations": false,
                            "size": "sm",
                            "customClass": "bg-primary bg-light text-primary mx-2",
                            "tableView": false,
                            "key": "cancel",
                            "type": "button",
                            "event": "cancel",
                            "input": true,
                            "id": "ey0cmw9",
                            "placeholder": "",
                            "prefix": "",
                            "suffix": "",
                            "multiple": false,
                            "defaultValue": null,
                            "protected": false,
                            "unique": false,
                            "persistent": false,
                            "hidden": false,
                            "clearOnHide": true,
                            "refreshOn": "",
                            "redrawOn": "",
                            "modalEdit": false,
                            "dataGridLabel": true,
                            "labelPosition": "top",
                            "description": "",
                            "errorLabel": "",
                            "tooltip": "",
                            "hideLabel": false,
                            "tabindex": "",
                            "disabled": false,
                            "autofocus": false,
                            "dbIndex": false,
                            "customDefaultValue": "",
                            "calculateValue": "",
                            "calculateServer": false,
                            "widget": {
                                "type": "input"
                            },
                            "attributes": {},
                            "validateOn": "change",
                            "validate": {
                                "required": false,
                                "custom": "",
                                "customPrivate": false,
                                "strictDateValidation": false,
                                "multiple": false,
                                "unique": false
                            },
                            "conditional": {
                                "show": null,
                                "when": null,
                                "eq": ""
                            },
                            "overlay": {
                                "style": "",
                                "left": "",
                                "top": "",
                                "width": "",
                                "height": ""
                            },
                            "allowCalculateOverride": false,
                            "encrypted": false,
                            "showCharCount": false,
                            "showWordCount": false,
                            "properties": {},
                            "allowMultipleMasks": false,
                            "addons": [],
                            "leftIcon": "",
                            "rightIcon": "",
                            "block": false,
                            "disableOnInvalid": false,
                            "theme": "primary"
                        }
                    ],
                    "offset": 0,
                    "push": 0,
                    "pull": 0,
                    "size": "xs",
                    "currentWidth": 3,
                    "width": 3
                },
                {
                    components: [
                        {
                            "label": "Save as draft",
                            "action": "event",
                            "showValidations": false,
                            "size": "sm",
                            "customClass": "bg-primary bg-light text-primary mx-2",
                            "tableView": false,
                            "key": "saveAsDraft",
                            "type": "button",
                            "event": "saveAsDraft",
                            "input": true,
                            "id": "ew6spe9",
                            "placeholder": "",
                            "prefix": "",
                            "suffix": "",
                            "multiple": false,
                            "defaultValue": null,
                            "protected": false,
                            "unique": false,
                            "persistent": false,
                            "hidden": false,
                            "clearOnHide": true,
                            "refreshOn": "",
                            "redrawOn": "",
                            "modalEdit": false,
                            "dataGridLabel": true,
                            "labelPosition": "top",
                            "description": "",
                            "errorLabel": "",
                            "tooltip": "",
                            "hideLabel": false,
                            "tabindex": "",
                            "disabled": false,
                            "autofocus": false,
                            "dbIndex": false,
                            "customDefaultValue": "",
                            "calculateValue": "",
                            "calculateServer": false,
                            "widget": {
                                "type": "input"
                            },
                            "attributes": {},
                            "validateOn": "change",
                            "validate": {
                                "required": false,
                                "custom": "",
                                "customPrivate": false,
                                "strictDateValidation": false,
                                "multiple": false,
                                "unique": false
                            },
                            "conditional": {
                                "show": null,
                                "when": null,
                                "eq": ""
                            },
                            "overlay": {
                                "style": "",
                                "left": "",
                                "top": "",
                                "width": "",
                                "height": ""
                            },
                            "allowCalculateOverride": false,
                            "encrypted": false,
                            "showCharCount": false,
                            "showWordCount": false,
                            "properties": {},
                            "allowMultipleMasks": false,
                            "addons": [],
                            "leftIcon": "",
                            "rightIcon": "",
                            "block": false,
                            "disableOnInvalid": false,
                            "theme": "primary"
                        }
                    ],
                    "offset": 0,
                    "push": 0,
                    "pull": 0,
                    "size": "xs",
                    "currentWidth": 3,
                    "width": 3
                },
                {
                    "components": [
                        {
                            "label": "Save",
                            "showValidations": false,
                            "size": "sm",
                            "customClass": "mx-2",
                            "tableView": false,
                            "key": "save",
                            "type": "button",
                            "saveOnEnter": false,
                            "input": true,
                            "id": "e1iuf3j",
                            "placeholder": "",
                            "prefix": "",
                            "suffix": "",
                            "multiple": false,
                            "defaultValue": null,
                            "protected": false,
                            "unique": false,
                            "persistent": false,
                            "hidden": false,
                            "clearOnHide": true,
                            "refreshOn": "",
                            "redrawOn": "",
                            "modalEdit": false,
                            "dataGridLabel": true,
                            "labelPosition": "top",
                            "description": "",
                            "errorLabel": "",
                            "tooltip": "",
                            "hideLabel": false,
                            "tabindex": "",
                            "disabled": false,
                            "autofocus": false,
                            "dbIndex": false,
                            "customDefaultValue": "",
                            "calculateValue": "",
                            "calculateServer": false,
                            "widget": {
                                "type": "input"
                            },
                            "attributes": {},
                            "validateOn": "change",
                            "validate": {
                                "required": false,
                                "custom": "",
                                "customPrivate": false,
                                "strictDateValidation": false,
                                "multiple": false,
                                "unique": false
                            },
                            "conditional": {
                                "show": null,
                                "when": null,
                                "eq": ""
                            },
                            "overlay": {
                                "style": "",
                                "left": "",
                                "top": "",
                                "width": "",
                                "height": ""
                            },
                            "allowCalculateOverride": false,
                            "encrypted": false,
                            "showCharCount": false,
                            "showWordCount": false,
                            "properties": {},
                            "allowMultipleMasks": false,
                            "addons": [],
                            "leftIcon": "",
                            "rightIcon": "",
                            "block": false,
                            "action": "submit",
                            "disableOnInvalid": false,
                            "theme": "primary"
                        }
                    ],
                    "size": "xs",
                    "width": 3,
                    "currentWidth": 3
                }
            ],
            "autoAdjust": false,
            "customClass": "float-right",
            "hidden": false,
            "hideLabel": false,
            "modalEdit": false,
            "key": "columns2",
            "tags": [],
            "properties": {},
            "conditional": {
                "show": null,
                "when": null,
                "eq": "",
                "json": ""
            },
            "customConditional": "",
            "logic": [],
            "attributes": {},
            "overlay": {
                "style": "",
                "page": "",
                "left": "",
                "top": "",
                "width": "",
                "height": ""
            },
            "type": "columns",
            "input": false,
            "tableView": false,
            "placeholder": "",
            "prefix": "",
            "suffix": "",
            "multiple": false,
            "defaultValue": null,
            "protected": false,
            "unique": false,
            "persistent": false,
            "clearOnHide": false,
            "refreshOn": "",
            "redrawOn": "",
            "dataGridLabel": false,
            "labelPosition": "top",
            "description": "",
            "errorLabel": "",
            "tooltip": "",
            "tabindex": "",
            "disabled": false,
            "autofocus": false,
            "dbIndex": false,
            "customDefaultValue": "",
            "calculateValue": "",
            "calculateServer": false,
            "widget": null,
            "validateOn": "change",
            "validate": {
                "required": false,
                "custom": "",
                "customPrivate": false,
                "strictDateValidation": false,
                "multiple": false,
                "unique": false
            },
            "allowCalculateOverride": false,
            "encrypted": false,
            "showCharCount": false,
            "showWordCount": false,
            "allowMultipleMasks": false,
            "addons": [],
            "tree": false,
            "lazyLoad": false,
            "id": "eymkr3"
        }
    ]
};

export const DEVICE_UUID_TEXT = "deviceUuid"
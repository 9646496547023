import { useRef } from "react";
import Label from "../../../../components/common-components/label/Label";
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Icons from "../../../../components/icons/Icons";
import VitalsTest from "../../../../containers/AddPatient/VitalsTest/VitalsTest";
import Button from "../../../../components/common-components/button/Button";
import { addNotifications } from "../../../../components/common-components/toaster/ToasterSlice";
import { TOASTER_VARIANT } from "../../../../components/common-components/toaster/MetToaster";
import { useDispatch, useSelector } from "react-redux";
import { componentKey } from "./VitalsTestSlice";

function AddVitalsTestModal({ open, close , editTestsData}) {
    const formRef = useRef();
    const dispatch = useDispatch();
    const {isEditVitalTest} = useSelector((state) => state[componentKey])
    return (
        <div>
            <ModalComponent
                open={open}
                title={isEditVitalTest ? "Edit Vitals Test" :'Add Vitals Test'}
                icon={<Icons iconName="closeIcon" />}
                close={close}
                footerButton={
                    <Button
                        onClickCb={() => {
                            if (formRef.current.values.tests?.length) {
                                formRef.current.submitForm();
                            } else {
                                dispatch(addNotifications({ message: "Please Select Test!", variant: TOASTER_VARIANT.ERROR }));
                            }
                        }}
                        type='submit'
                    >
                        Save
                    </Button>
                }
                customClasses="w-[1100px] overflow-visible"
                hideOverflow={true}
            >
                <VitalsTest editTestsData={editTestsData} isAddVitalTest={true} ref={formRef} />
            </ModalComponent>
        </div>
    );
}

export default AddVitalsTestModal;
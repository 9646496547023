import store from '../../../../store/store';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { componentKey, setAllDocumentsData, setAllDocumentsTypeData, setIsDocumentsModalOpen, setIsOpenDeletePopUp, } from './PatientDocumentsSlice';
import PatientDashboardDataService from '../../../../services/PatientDashboardDataService';
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/MetToaster';

export const {
    getAllPatientDocumentsDetails,
    postPatientDocuments,
    deletePatientDocuments,
    getPatientDocumentsDetails,
    updatePatientDocuments,
    getAllPatientDocumentsTypeDetails,
    postPatientDocumentsType,
    updatePatientDocumentsType,
    deletePatientDocumentsType,
} = {
    getAllPatientDocumentsDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/DOCUMENTS',
            payload,
        };
    },
    postPatientDocuments: (payload) => {
        return {
            type: 'POST/PATIENTDASHBOARD/DOCUMENTS',
            payload,
        };
    },
    deletePatientDocuments: (payload) => {
        return {
            type: 'DELETE/PATIENTDASHBOARD/DOCUMENTS',
            payload,
        };
    },
    getPatientDocumentsDetails: (payload) => {
        return {
            type: 'GET/PATIENTDASHBOARD/DOCUMENTS',
            payload,
        };
    },
    updatePatientDocuments: (payload) => {
        return {
            type: 'UPDATE/PATIENTDASHBOARD/DOCUMENTS',
            payload,
        };
    },
    getAllPatientDocumentsTypeDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/DOCUMENTSTYPE',
            payload,
        };
    },
    postPatientDocumentsType: (payload) => {
        return {
            type: 'POST/PATIENTDASHBOARD/DOCUMENTSTYPE',
            payload,
        };
    },
    updatePatientDocumentsType: (payload) => {
        return {
            type: 'UPDATE/PATIENTDASHBOARD/DOCUMENTSTYPE',
            payload,
        };
    },
    deletePatientDocumentsType: (payload) => {
        return {
            type: 'DELETE/PATIENTDASHBOARD/DOCUMENTSTYPE',
            payload,
        };
    },
};

function* getAllPatientDocumentsDetailsAsync(action) {
    try {
        const { patientId = '', page = '1', limit = '10' } = action.payload;
        const params = {
            page: page,
            limit: limit,
        };

        const response = yield PatientDashboardDataService.getAllPatientDocuments(
            'documents/patient',
            patientId,
            params
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setAllDocumentsData(data?.patientDocuments));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* postPatientDocumentsAsync(action) {
    try {
        const { patientId = '', documentsData } = action.payload;
        const response = yield PatientDashboardDataService.postPatientDocuments('documents', documentsData);
        const { message, status, data } = response?.data || {};
        if (status) {
            yield put(setIsDocumentsModalOpen(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
            yield call(getAllPatientDocumentsDetailsAsync, getAllPatientDocumentsDetails({ patientId }));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* deletePatientDocumentsDetailsAsync(action) {
    try {
        const { patientId = '', documentId = '' } = action.payload;

        const response = yield PatientDashboardDataService.deletePatientDocuments('documents', documentId);

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setIsOpenDeletePopUp(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
            yield call(getAllPatientDocumentsDetailsAsync, getAllPatientDocumentsDetails({ patientId }));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* getAllPatientDocumentsTypeDetailsAsync(action) {
    try {
        const response = yield PatientDashboardDataService.getAllPatientDocumentsType(
            'documents/type/get',
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setAllDocumentsTypeData(data));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* postPatientDocumentsTypeAsync(action) {
    try {
        const { documentTypeData } = action.payload;
        const response = yield PatientDashboardDataService.postPatientDocumentsType('documents/type', documentTypeData);
        const { message, status, data } = response?.data || {};
        if (status) {
            yield call(getAllPatientDocumentsTypeDetailsAsync, getAllPatientDocumentsTypeDetails());
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* updatePatientDocumentsTypeDetailsAsync(action) {
    try {
        const { documentsTypeId = '', documentTypeData = {} } = action.payload;

        const response = yield PatientDashboardDataService.updatePatientDocumentsType(
            'documents/type',
            documentsTypeId,
            documentTypeData
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            yield call(getAllPatientDocumentsTypeDetailsAsync, getAllPatientDocumentsTypeDetails());

        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* deletePatientDocumentsTypeDetailsAsync(action) {
    try {
        const { documentsTypeId = '' } = action.payload;

        const response = yield PatientDashboardDataService.deletePatientDocumentsType('documents/type', documentsTypeId);


        const { message, status, data } = response?.data || {};

        if (status) {

            yield call(getAllPatientDocumentsTypeDetailsAsync, getAllPatientDocumentsTypeDetails());
        }
    } catch (error) {
        console.log('err: ', error);
    }
}


function* rootSaga() {
    yield all([
        takeLatest(getAllPatientDocumentsDetails().type, getAllPatientDocumentsDetailsAsync),
        takeLatest(postPatientDocuments().type, postPatientDocumentsAsync),
        takeLatest(deletePatientDocuments().type, deletePatientDocumentsDetailsAsync),
        takeLatest(getAllPatientDocumentsTypeDetails().type, getAllPatientDocumentsTypeDetailsAsync),
        takeLatest(postPatientDocumentsType().type, postPatientDocumentsTypeAsync),
        takeLatest(updatePatientDocumentsType().type, updatePatientDocumentsTypeDetailsAsync),
        takeLatest(deletePatientDocumentsType().type, deletePatientDocumentsTypeDetailsAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

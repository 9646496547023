
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import store from '../../../../../store/store'
import ProvidersDataService from '../../../../../services/ProvidersDataService'
import { componentKey, setAvailiabilityData, setIsAddSingleDayAvailabilityModalOpen, setIsAvailiablityModalOpen, setIsEditSingleDayAvailabilityModalOpen, setIsNewAvailabilityAdd, setProviderLocationsData, setSelectedProvider } from './AvailabilitySlice'
import { addNotifications } from '../../../../../components/common-components/toaster/ToasterSlice'
import { TOASTER_VARIANT } from '../../../../../components/common-components/toaster/MetToaster'

export const { getAvailability, getProviderLocations, postAvailability, postAvailabilityForADay, updateAvailability, } = {
    getAvailability: (payload) => {
        return {
            type: 'ADD_AVAILIABILITY/GET_AVAILABILITY',
            payload
        }
    },
    getProviderLocations: (payload) => {
        return {
            type: 'ADD_AVAILIABILITY/GET_PROVIDER_LOCATIONS',
            payload
        }
    },
    postAvailability: (payload) => {
        return {
            type: 'ADD_AVAILIABILITY/POST_AVAILABILITY',
            payload
        }
    },
    updateAvailability: (payload) => {
        return {
            type: 'ADD_AVAILIABILITY/UPDATE_AVAILABILITY',
            payload
        }
    },
    postAvailabilityForADay: (payload) => {
        return {
            type: 'ADD_AVAILIABILITY/POST_AVAILABILITY_FOR_A_DAY',
            payload
        }
    },
}

function* getAvailabilityAsync(action) {
    try {
        const { providerId, locationIds } = action.payload

        const locationData = locationIds && locationIds.locationIds ? locationIds : {}

        const response = yield ProvidersDataService.getAvailability(providerId, locationData)
        const { data, success } = response.data
        if (success) {
            yield put(setAvailiabilityData(data))
        }

    } catch (error) {
        console.log('err: ', error)
    }
}

function* getProviderLocationsAsync(action) {
    try {
        const { providerId } = action.payload
        const response = yield ProvidersDataService.getProviderLocations(providerId)
        const { data, success } = response.data
        if (success) {
            yield put(setProviderLocationsData(data?.provider_location_intermediate))
        }

    } catch (error) {
        console.log('err: ', error)
    }
}

function* postAvailabilityAsync(action) {
    try {
        const { availabilityData, providerId } = action.payload
        const response = yield ProvidersDataService.postAvailability(availabilityData)
        const { data, success, message } = response.data
        if (success) {
            yield put(setIsAvailiablityModalOpen(false))
            yield put(setIsNewAvailabilityAdd(false))
            yield put(setSelectedProvider(providerId))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAvailabilityAsync, getAvailability({ providerId: providerId.uuid || providerId.value, locationIds: {} }))
        }

    } catch (error) {
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
        console.log('err: ', error)
    }
}

function* updateAvailabilityAsync(action) {
    try {
        const { availabilityData, availabilityId, providerId } = action.payload
        const response = yield ProvidersDataService.updateAvailability(availabilityData, availabilityId)
        const { data, success, message } = response.data
        if (success) {
            yield put(setIsAvailiablityModalOpen(false))
            yield put(setIsNewAvailabilityAdd(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAvailabilityAsync, getAvailability({ providerId: providerId.uuid || providerId.value, locationIds: {} }))
        }

    } catch (error) {
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
        console.log('err: ', error)
    }
}

function* postAvailabilityForADayAsync(action) {
    try {
        const { availabilityData } = action.payload
        const response = yield ProvidersDataService.postAvailabilityForADay(availabilityData)
        const { data, success, message } = response.data
        if (success) {
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield put(setIsEditSingleDayAvailabilityModalOpen(false))
            yield put(setIsAddSingleDayAvailabilityModalOpen(false))
            yield call(getAvailabilityAsync, getAvailability({ providerId: availabilityData.providerId, locationIds: {} }))
        }

    } catch (error) {
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
        console.log('err: ', error)
    }
}


function* rootSaga() {
    yield all([
        takeLatest(getAvailability().type, getAvailabilityAsync),
        takeLatest(getProviderLocations().type, getProviderLocationsAsync),
        takeLatest(postAvailability().type, postAvailabilityAsync),
        takeLatest(updateAvailability().type, updateAvailabilityAsync),
        takeLatest(postAvailabilityForADay().type, postAvailabilityForADayAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)

import { all, put, takeLatest } from 'redux-saga/effects'
import store from '../../../../store/store'
import { componentKey, setAllUpcommingAppointmentData, setAppoinmentpaginationState } from './AppointmentsSlice'
import SchedulingService from '../../../../services/SchedulingService'


export const { getUpcommingAppointment } = {
    getUpcommingAppointment: (payload) => {
        return {
            type: 'CHARTING/UPCOMMING_APPOINMENTS',
            payload
        }
    },
}

function* getUpcommingAppointmentAsync(action) {
    try {
        const { patientId } = action.payload
        const params = {
            patientId: patientId
        }
        const response = yield SchedulingService.getAllScheduleAppointments({ params })
        const { data, status } = response.data

        if (status) {
            const { schedule, totalRecords } = data || {}
            yield put(setAllUpcommingAppointmentData(schedule))
            yield put(setAppoinmentpaginationState({ total: totalRecords }))
        }
    } catch (error) {
        console.log('err: ', error)
    }

}

function* rootSaga() {
    yield all([
        takeLatest(getUpcommingAppointment().type, getUpcommingAppointmentAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
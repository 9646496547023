import store from '../../../../store/store';

export const componentKey = 'PATIENTDIAGNOSES_SECTION';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setDiagnosesId: (state, action) => {
			state.currentDiagnosesId = action.payload;
		},
		setAllDiagnosesData: (state, action) => {
			state.allDiagnosesData = action.payload;
		},
		setDiagnosesData: (state, action) => {
			state.diagnosesData = action.payload;
		},
		setIsDiagnosesModalOpen: (state, action) => {
			state.isDiagnosesModalOpen = action.payload;
		},
		setIsNewDiagnosesAdd: (state, action) => {
			state.isNewDiagnosesAdd = action.payload;
		},
		setDiagnosesStatus: (state, action) => {
			state.diagnosesStatus = action.payload;
		},
	},
	initialReducerState: {
		currentDiagnosesId: '',
		allDiagnosesData: [],
		diagnosesData: {},
		isDiagnosesModalOpen: false,
		isNewDiagnosesAdd: false,
		diagnosesStatus: { label: 'All', value: 'All' },
	},
});

export const { setDiagnosesId, setAllDiagnosesData, setDiagnosesData, setIsDiagnosesModalOpen, setIsNewDiagnosesAdd, setDiagnosesStatus } =
	actions;

import { useEffect } from "react"
import AlleriesCard from "./ClinicalDataCards/AllergiesCard"
import { useDispatch } from "react-redux"
import { getAllPatientAllergiesDetails } from "../../Patients/PatientDashboard/Allergies/PatientAllergiesSaga"
import { getAllPatientDiagnosesDetails } from "../../Patients/PatientDashboard/Diagnoses/PatientDiagnosesSaga"
import DiagonosesCard from "./ClinicalDataCards/DiagnosesCard"
import MedicationsCard from "./ClinicalDataCards/MedicationCard"
import { getAllPatientMedicationsDetails } from "../../Patients/PatientDashboard/Medications/PatientMedicationsSaga"
import VitalsCard from "./ClinicalDataCards/VitalsCard"
import { getAllPatientVitalsDetails } from "../../Patients/PatientDashboard/Vitals/PatientVitalsSaga"
import { getAllPatientVaccinesDetails } from "../../Patients/PatientDashboard/Vaccines/PatientVaccinesSaga"
import VaccineCard from "./ClinicalDataCards/VaccineCard"
import { useParams } from "react-router-dom"

const ClinicalData = () => {

    const dispatch = useDispatch()
    const { patientId } = useParams();
    useEffect(() => {
        dispatch(getAllPatientAllergiesDetails({ patientId }))
        dispatch(getAllPatientDiagnosesDetails({ patientId }))
        dispatch(getAllPatientMedicationsDetails({ patientId }))
        dispatch(getAllPatientVitalsDetails({ patientId }))
        dispatch(getAllPatientVaccinesDetails({ patientId }))
    }, [])
    return <div className="row-gap-10">
        <div className='flex flex-col column-gap-10px row-gap-10'>
            <div className="mt-[70px]">
                <AlleriesCard></AlleriesCard>
            </div>
            <div>
                <DiagonosesCard></DiagonosesCard>
            </div>
            <div>
                <MedicationsCard></MedicationsCard>
            </div>
            <div>
                <VitalsCard></VitalsCard>
            </div>
            <div>
                <VaccineCard></VaccineCard>
            </div>
        </div>
    </div>
}

export default ClinicalData
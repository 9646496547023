export const GreyPencilIcon = () => {
	return (
		<svg
			id="Group_96333"
			data-name="Group 96333"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path id="Path_187021" data-name="Path 187021" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_187022"
				data-name="Path 187022"
				d="M14.06,9.02l.92.92L5.92,19H5v-.92l9.06-9.06M17.66,3a1,1,0,0,0-.7.29L15.13,5.12l3.75,3.75,1.83-1.83a1,1,0,0,0,0-1.41L18.37,3.29A.982.982,0,0,0,17.66,3Zm-3.6,3.19L3,17.25V21H6.75L17.81,9.94,14.06,6.19Z"
				fill="rgba(26,26,26,0.5)"
			/>
		</svg>
	);
};

import React from 'react';
import FaceSheetCard from './FaceSheetCard';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import { useNavigate } from 'react-router-dom';
import General from '../../../../../libs/utility/General';

const encounterData = [
	{
		id: 1,
		date: 'Fri, Apr 16, 2022 : Infection Disease',
		diagnosis: 'Diagnosis :',

		details: [
			{
				number: '1.',
				uniqueId: 'F 33.2 -',
				diseasesInfo: 'Major Depressive Disorder, Recurrent episode',
			},
			{
				number: '2.',
				uniqueId: 'F 41.1 -',
				diseasesInfo: ' Generalized Anxiety Disorder',
			},
		],
	},
];

const repetedData = Array(6).fill(...encounterData);
const EncountersCard = () => {
	const navigate = useNavigate()

	return (
		<FaceSheetCard
			title="ENCOUNTERS"
			iconbtn={
				<Button
					variant={BUTTON_VARIANTS.TEXT}
					customBtnClass="fs-14 font-bold text-met-primary"
					style={{ height: '19px' }}
					onClickCb={() => navigate("../appointments")}
				>
					View All
				</Button>
			}
			customClassName="min-w-515"
		>
			<div>
				<ul>
					{repetedData?.map((item, index) => (
						<li key={"face-sheet-card-ENCOUNTERS-" + index} className="mb-4">
							<div className="flex justify-between ">
								<div className="flex items-center mb-1">
									<div className="h-2 w-2 bg-met-primary rounded-full mr-2"></div>
									<div className="fs-14 text-met-primary ml-1">{General.getformattedDate(item?.date)}</div>
								</div>
							</div>
							<div className="flex">
								<div className="fs-14 text-gray-500 ml-2">{item?.diagnosis}</div>

								<div className="item-center">
									<ol>
										{item.details.map((detail, index) => (
											<li key={index.id} className="flex">
												<div className="fs-14 ml-1">{detail?.number}</div>
												<div className="fs-14 ml-2">{detail?.uniqueId}</div>
												<div className="fs-14 ml-2  text-gray-500 ">
													{detail?.diseasesInfo}
												</div>
											</li>
										))}
									</ol>
								</div>
							</div>
						</li>
					))}
				</ul>
			</div>
		</FaceSheetCard>
	);
};

export default EncountersCard;

import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import Button from '../../../components/common-components/button/Button';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../components/icons/Icons';
import Input from '../../../components/common-components/input/Input';
// import General from '../../../libs/utility/General';
// import { getValidationSchema } from '../../../libs/formsUtils';
// import { VALIDATION_REGEX } from '../../../libs/constant';
import { FORM_FIELDS_NAMES, ABHA_FIELDS_NAMES, ABHA_OPTIONS, ABHA_VERIFICATION_OPTIONS } from './Constants';
import {  getExistingABHADetails } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import ValidateOTPModal from './ValidateOTPModal';
import RadioButton from '../../../components/common-components/radioButton/RadioButton';
import { componentKey, setCreatePatientFromAadhaar, setOpenValidateGetOtpModal, setDownloadedCard } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import Label from '../../../components/common-components/label/Label';
import PatientDetailsReviewModal from './PatientDetailsReviewModal';
import { useLocation, useNavigate } from 'react-router-dom';

// const fields = [
// 	{
// 		fieldName: FORM_FIELDS_NAMES.AADHAR_NUMBER,
// 		isRequired: true,
// 		regexPattern: VALIDATION_REGEX.AADHAR_REGEX,
// 	},
// ];
// const validationSchema = getValidationSchema(fields);

const ImportFromABHA = ({ open, close }) => {
	const {openValidateGetOtpModal, createPatientFromAadhaar } = useSelector((state) => state[componentKey])
    const [selectedABHAOption, setSelectedABHAOption] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [selectedOtpOption, setSelectedOtpOption] = useState("");
    const [showPatientDetailsPopup, setShowPatientDetailsPopup] = useState(false);
	const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate()

    const handleSelectedABHAChange = (option) => {
        setSelectedABHAOption(option);
        setSelectedOtpOption("");
        setSearchValue("")
    };

    const handleSelectedOtpChange = (option) => {
        setSelectedOtpOption(option);
    }

    const closePatientDetailsModal = () => {
        setShowPatientDetailsPopup(false);
        dispatch(setCreatePatientFromAadhaar(""))
        dispatch(setDownloadedCard(null));
    }

    useEffect(() => {
		if (createPatientFromAadhaar !== "") {
			setShowPatientDetailsPopup(true);
		}
	}, [createPatientFromAadhaar]);

    const field = selectedABHAOption.toLowerCase().split(' ').map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)).join('');
	return (
		<>
			<Formik
				initialValues={{
					[FORM_FIELDS_NAMES.AADHAR_NUMBER]: '',
				}}
				//validationSchema={validationSchema}
				enableReinitialize
				onSubmit={async () => {
                    let payload;
                    if(selectedOtpOption){
                        payload = {
                            key: field,
                            [field]: searchValue,
                            sentTo: selectedOtpOption
                        }
                    }else{
                        payload = {
                            key: field,
                            [field]: searchValue,
                        };
                    }
					dispatch(getExistingABHADetails({getExistingABHADetailsData:payload}));
                    close()
				}}
			>	
				{({
					submitForm,
					isSubmitting,
					handleReset,
					values,
					handleSubmit,
					setFieldValue,
					handleBlur,
					...props
				}) => {
					return (
                        <>
						<Form onSubmit={handleSubmit}>
							<div>
								<ModalComponent
									open={open}
									title={'Import Data From ABHA'}
									footerButton={
										<Button
											type="submit"
											variant={BUTTON_VARIANTS.CONTAINED}
											customBtnClass="text-met-primary"
										>
											Generate OTP
										</Button>
									}
									icon={<Icons iconName="closeIcon" />}
									close={close}
									customClasses="w-[800px]"
								>
									<div className="p-2">
                                        <div className="flex flex-col gap-4 items-start pt-6">
                                            <div className="flex flex-col gap-5">
                                                <div className="flex flex-wrap gap-4 mt-2">
                                                    {ABHA_OPTIONS.GET_OPTIONS.map((option) => (
                                                        <div key={option.value} className={`border ${selectedABHAOption === option.value ? "border-blue-500 border-2":"border-gray-400"} p-2 rounded-lg `}>
                                                            <RadioButton
                                                                label={option.label}
                                                                name="abhaOptions"
                                                                checked={selectedABHAOption === option.value}
                                                                onChangeCb={() => handleSelectedABHAChange(option.value)}
                                                            />
                                                        </div>
                                                        
                                                    ))}
                                                </div>
                                                {selectedABHAOption && (
                                                    <>
                                                        <Input
                                                            label={`Enter ${selectedABHAOption}`}
                                                            placeholder={`Enter ${selectedABHAOption}`}
                                                            isRequired={true}
                                                            name={FORM_FIELDS_NAMES.AADHAR_NUMBER}
                                                            value={searchValue}
                                                            onChangeCb={(e) => {
                                                                setSearchValue(e.target.value);
                                                            }}
                                                            onBlurCb={handleBlur}
                                                        />
                                                        {(selectedABHAOption === "ABHA Number" || selectedABHAOption === "ABHA Address") &&
                                                        <div>
                                                            <Label fontSize="sm" fontWeight='bold' isRequired={true}>Send OTP To</Label>
                                                            <div className="flex flex-wrap gap-4 mt-2">
                                                                {ABHA_VERIFICATION_OPTIONS.map((option) => (
                                                                    <div key={option.value} className={`border ${selectedOtpOption === option.value ? "border-blue-500 border-2":"border-gray-400"} p-2 rounded-lg `}>
                                                                        <RadioButton
                                                                            label={option.label}
                                                                            name='otpOptions'
                                                                            checked={selectedOtpOption === option.value}
                                                                            onChangeCb={() => handleSelectedOtpChange(option.value)}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
								</ModalComponent>
							</div>
						</Form>
                        { openValidateGetOtpModal &&
                            <ValidateOTPModal
                                open={openValidateGetOtpModal}
                                getVia={field}
                                otpOption = {selectedOtpOption}
                                searchValue = {searchValue}
                                close={() => dispatch(setOpenValidateGetOtpModal(false))}
                                modalType="getABHADetails"
                            />
                            
                        }
                        {showPatientDetailsPopup && createPatientFromAadhaar && (
										<PatientDetailsReviewModal
											open={showPatientDetailsPopup}
											onSubmit={() => {
												const currentLoc = location.pathname
												const pathWtPatientID = currentLoc.toString().replace(":id", createPatientFromAadhaar?.patient?.uuid);
												navigate(pathWtPatientID);
												closePatientDetailsModal();
											}}
											close={() => closePatientDetailsModal()}
                                            modalType="getABHADetails"
										/>
									)}
                        </>
					);
				}}
			</Formik>
			
		</>
	);
};

export default ImportFromABHA;

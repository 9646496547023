export const FORM_FIELDS_NAMES = {
    INSURANCE_TYPE: "type",
    INSURANCE_NAME: "name",
    POLICY_NUMBER: "policyNumber",
    PLAN_NAME: "planName",
    COMPANY_NAME: "companyName",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    EFFECTIVE_START_DATE: "startDate",
    EFFECTIVE_END_DATE: "endDate",
    PATIENT_RELATIONSHIP_TO_BE_INSURED: "patientRelationshipToInsured",
    SELF: "Self",
    SPOUSE: "Spouse",
    CHILD: "Child",
    OTHER: "Other",
    DATE_OF_BIRTH: "dob",
    GENDER: "gender",
    ADDRESS_LINE_1: "addressLine1",
    ADDRESS_LINE_2: "addressLine2",
    CITY: "city",
    STATE: "state",
    COUNTRY: "country",
    ZIP_CODE: "zip",
    INSUREANCE_CARD_FRONT: "insuranceCardFrontImage",
    INSUREANCE_CARD_BACK: "insuranceCardBackImage",
}
import store from "../store/store";

export const componentKey = 'SOCKET_IO_SLICE';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setNotificationList: (state, action) => {
            state.notifications = [action.payload, ...state.notifications];
        },
        setNotificationListData: (state, action) => {
            state.notifications = action.payload;
        },
    },
    initialReducerState: {
        notifications: []
    }
});

export const { setNotificationList, setNotificationListData } = actions;

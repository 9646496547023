import React from 'react'
import { FORM_FIELDS_FOR_SIGN_OFF_APPOINTMENTS } from '../constant';
import { getValidationSchema } from '../../../libs/formsUtils';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey } from '../ShedulingSlice';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { patchScheduledAppointment } from '../SchedulingSaga';
import Icons from '../../../components/icons/Icons';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import TextArea from '../../../components/common-components/textArea/TextArea';

function SignOffAppoinment({ open, close, appointmentDetails }) {

    const fields = [{ fieldName: FORM_FIELDS_FOR_SIGN_OFF_APPOINTMENTS.REASON, isRequired: true }];
    const validationSchema = getValidationSchema(fields);
    const { statusOfAppointment, schedulingAppointmentsPaginationState } = useSelector(
        (state) => state[componentKey]
    );

    const dispatch = useDispatch()
    return (
        <Formik
            initialValues={{
                [FORM_FIELDS_FOR_SIGN_OFF_APPOINTMENTS.REASON]: "",
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => {
                const payload = {
                    status: statusOfAppointment,
                    cancellationReason: values.reason,
                };
                
                dispatch(
                    patchScheduledAppointment({
                        scheduleId: appointmentDetails?.uuid,
                        editScheduledData: payload,
                        schedulingAppointmentsPaginationState,
                    })
                );

            }}
        >
            {({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <ModalComponent
                            open={open}
                            title={'Sign Off Appointment'}
                            close={close}
                            icon={<Icons iconName="closeIcon" />}
                            footerButton={
                                <Button
                                    type="submit"
                                    variant={BUTTON_VARIANTS.CONTAINED}
                                    customBtnClass="text-met-primary"
                                    onClickCb={() => {
                                    }}
                                >
                                    Confirm
                                </Button>
                            }
                            customClasses="w-[30%] overflow-visible"
                            enableReinitialize
                        >
                            <div className="">
                                <div className="flex justify-center">
                                    <Icons iconName="calenderWithWatchIcon" />
                                </div>
                                <TextArea
                                    isRequired={true}
                                    label="Enter the reason for Sign off appointment"
                                    placeholder="Type here..."
                                    customClasses="mt-2"
                                    name={FORM_FIELDS_FOR_SIGN_OFF_APPOINTMENTS.REASON}
                                    value={values[FORM_FIELDS_FOR_SIGN_OFF_APPOINTMENTS.REASON]}
                                    onChangeCb={(e) => {
                                        setFieldValue(FORM_FIELDS_FOR_SIGN_OFF_APPOINTMENTS.REASON, e.target.value);
                                    }}
                                />
                            </div>
                        </ModalComponent>
                    </Form>
                );
            }}
        </Formik>
    )
}

export default SignOffAppoinment
import React from "react";

function BackIcon({ style, onClickCb = () => { } }) {
    return (
        <svg
            style={style}
            onClick={onClickCb}
            id="arrow_back_black_24dp_2_"
            data-name="arrow_back_black_24dp (2)"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path id="Path_185601" data-name="Path 185601" d="M0,0H24V24H0Z" fill="none" />
            <path id="Path_185602" data-name="Path 185602" d="M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z" />
        </svg>
    );
}

export default BackIcon;

import React, { useCallback } from 'react';

import './chat-file-message-item.scss';
import { Progress } from 'reactstrap';
import { ChatFileDownloadStatus, ChatFileUploadStatus } from '@zoom/videosdk';
import { isImageFile } from '../../helper/chat-utils';

import classNames from 'classnames';
import ChatImageMessageItem from './ChatImageMessageItem';
import documentIcon from "../../helper/folder.png"
import Icons from '../../components/icon/Icon';
import { VEC_ICON_NAME } from '../../components/icon/constants';

const getFileSize = (size) => {
    if (size < 1024) {
        return `${size} bytes`;
    }
    if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(1)} KB`;
    }
    return `${(size / 1024 / 1024).toFixed(1)} MB`;
};

const getFileInProgress = (file, id) => {
    let isInProgress = false;
    let progress = 0;
    if (!id && file.upload && [ChatFileUploadStatus.InProgress, ChatFileUploadStatus.Init].includes(file.upload?.status)) {
        isInProgress = true;
        progress = file.upload.progress;
    } else if (file.download?.status === ChatFileDownloadStatus.InProgress) {
        isInProgress = true;
        progress = file.download.progress;
    }
    return [isInProgress, progress];
};

const getFileTip = (file, id) => {
    if (file.upload) {
        const { status } = file.upload;
        if (!id) {
            if (status === ChatFileUploadStatus.Fail) {
                return '- Click to resend';
            } else if (status === ChatFileUploadStatus.Cancel) {
                return '- Canceled, click to resend';
            }
        } else if (id && status === ChatFileUploadStatus.Complete) {
            return '- Click to download';
        }
    } else if (file.download) {
        const { status } = file.download;
        if (status !== ChatFileDownloadStatus.InProgress) {
            return '- Click to download';
        }
    }
};

const ChatFileMessageItem = (props) => {
    const { file, id, className, resendFile, downloadFile } = props;
    const { name, size } = file;

    const [isInProgress, progress] = getFileInProgress(file, id);

    const onCancelButtonClick = useCallback((event) => {
        event.stopPropagation();
        if (!id && file.upload && file.upload.status === ChatFileUploadStatus.InProgress) {
            const cancelFunc = file.upload.cancelFunc;
            if (cancelFunc) {
                cancelFunc();
            }
        } else if (file.download && file.download.status === ChatFileDownloadStatus.InProgress) {
            const cancelFunc = file.download.cancelFunc;
            if (cancelFunc) {
                cancelFunc();
            }
        }
    }, [file, id]);

    const onFileItemClick = useCallback(() => {
        if (!isInProgress) {
            if (!id && file.upload && (file.upload.status === ChatFileUploadStatus.Fail || file.upload.status === ChatFileUploadStatus.Cancel)) {
                const uuid = file.uuid;
                const retryToken = file.upload.retryToken;
                if (retryToken && uuid) {
                    resendFile(retryToken, uuid);
                }
            } else if (id) {
                downloadFile(id);
            }
        }
    }, [isInProgress, id, file, resendFile, downloadFile]);

    return (
        <div className={classNames('chat-file-message-wrap', className)}>
            {isImageFile(name) ? (
                <ChatImageMessageItem file={file} id={id} resendFile={resendFile} downloadFile={downloadFile} />
            ) : (
                <div>
                    <div className='chat-file-message-item'>
                        <div className='chat-items' onClick={onFileItemClick}>
                            <div className="chat-file-preview">
                                <img style={{ width: "25px" }} src={documentIcon} />
                            </div>
                            <div className="chat-file-desc">
                                <h4 className="chat-file-name">{name}</h4>
                                <div className="chat-file-subline">
                                    <span className="chat-file-size">{getFileSize(size)}</span>
                                    <div className="chat-file-action-tip">{getFileTip(file, id)}</div>
                                </div>
                            </div>
                            <div>
                                {isInProgress &&
                                    <Icons style={{ marginLeft: "40px" }} onClickCb={onCancelButtonClick} iconName={VEC_ICON_NAME.CLOSE_ICON} />
                                }
                            </div>
                        </div>
                        {isInProgress &&
                            <Progress
                                style={{ height: "3px" }}
                                color="success"
                                value={progress}
                            />
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatFileMessageItem;

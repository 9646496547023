import store from "../../../store/store";
import { PAGE_STATE } from "../../../libs/constant";

export const componentKey = "ZOOM_INTEGRATION";

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setLoadingState: (state, action) => {
            state.loadingState = action.payload;
        },
        setActivePageIndex: (state, action) => {
            state.activePageIndex = action.payload
        },
        setZoomClient: (state, action) => {
            state.client = action.payload
        },
        setMediaStream: (state, action) => {
            state.mediaStream = action.payload
        },
        setMeetingArguments: (state, action) => {
            state.meetingArgs = action.payload
        },
        setVideoStarted: (state, action) => {
            state.videoStarted = action.payload
        },
        setAudioStarted: (state, action) => {
            state.audioStarted = action.payload
        },
        setIsMuted: (state, action) => {
            state.isMuted = action.payload
        },
        setIsShareScreen: (state, action) => {
            state.isShareScreen = action.payload
        },
        setIsSAB: (state, action) => {
            state.isSAB = action.payload
        },
        setMeetingParticipants: (state, action) => {
            state.meetingParticipants = action.payload
        },
        setIsVideoMuted: (state, action) => {
            state.isVideoMuted = action.payload
        },
        setExitFullScreen: (state, action) => {
            state.exitFullScreen = action.payload
        },
        setStartZoomMeeting: (state, action) => {
            state.startZoomMeeting = action.payload
        },
        setIsFullScreen: (state, action) => {
            state.isFullScreen = action.payload
        },
        setShowViewJoinAttendance: (state, action) => {
            state.inCallMessages = false
            state.showViewJoinAttendance = action.payload
        },
        setInCallMessages: (state, action) => {
            state.showViewJoinAttendance = false
            state.inCallMessages = action.payload
        },
        setScheduleAppointmentDetails: (state, action) => {
            state.scheduleAppointmentDetails = action.payload
        },
        setCloseVideoCallModal: (state, action) => {
            state.closeVideoCall = action.payload
        },
        setSendInviteType: (state, action) => {
            state.sendInviteType = action.payload
        },
        setPatientListData: (state, action) => {
            state.patientListData = [...state.patientListData, ...action.payload]
        },
        setExactPatientList: (state, action) => {
            state.patientListData = action.payload
        },
        setClearUserList: (state, action) => {
            state.patientListData = []
        },
        setPatientListPaginationStates: (state, action) => {
            state.patientListPaginationStates = { ...state.patientListPaginationStates, ...action.payload }
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload
        },
        setGuestEmailState: (state, action) => {
            let guestEmailState = { ...state.guestEmailState };

            Object.entries(action.payload).map(([key, value]) => {
                guestEmailState = {
                    ...state.guestEmailState,
                    [key]: {
                        ...state.guestEmailState[key],
                        ...value,
                    },
                };
                state.guestEmailState = guestEmailState;
            });
        },
        setSendInviteModal: (state, action) => {
            state.sendInviteModal = action.payload
        },
        setVideoDuration: (state, action) => {
            state.videoDuration = state.videoDuration + action.payload
        },
        setStartVideoTimer: (state, action) => {
            state.startVideoTimer = action.payload
        },
        setZoomEncounterId: (state, action) => {
            state.zoomEncounterId = action.payload
        },
    },
    initialReducerState: {
        loadingState: { state: PAGE_STATE.PAGE_READY, message: "" },
        activePageIndex: 0,
        meetingArgs: {},
        meetingParticipants: [],

        videoDuration: 0,
        startVideoTimer: false,

        scheduleAppointmentDetails: {},

        patientListData: [],
        patientListPaginationStates: {
            PageNumber: 1,
            PageSize: 50,
            FilterText: ""
        },
        guestEmailState: {
            guestEmail: { value: "", errors: {}, rules: { required: true, regex: { pattern: /^(?!.*[\s.@]{2,})(?!.*\s)[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/, message: "Please enter a valid email address" } } }
        },

        selectedUser: {},

        client: null,
        mediaStream: null,

        startZoomMeeting: false,
        isFullScreen: false,
        showViewJoinAttendance: false,
        inCallMessages: false,
        closeVideoCall: false,
        sendInviteType: "Patient",

        videoStarted: true,
        isVideoMuted: false,
        isMuted: true,
        audioStarted: true,
        isShareScreen: false,
        isSAB: false,
        zoomEncounterId:""
    },
});

export const { setLoadingState, setActivePageIndex, setZoomClient, setMediaStream, setMeetingArguments, setVideoStarted, setAudioStarted, setIsMuted, setIsShareScreen, setIsSAB, setMeetingParticipants, setIsVideoMuted, setExitFullScreen, setStartZoomMeeting, setIsFullScreen, setShowViewJoinAttendance, setInCallMessages, setScheduleAppointmentDetails, setCloseVideoCallModal, setSendInviteType, setPatientListPaginationStates, setPatientListData, setSelectedUser, setClearUserList, setGuestEmailState, setSendInviteModal, setVideoDuration, setStartVideoTimer, setZoomEncounterId, setExactPatientList } = actions;

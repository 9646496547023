import { all, put, takeLatest } from 'redux-saga/effects';
import store from '../../../../../store/store';
import { componentKey, setAllProviderGroupLocation, setAllProviders, setCurrentProvidersBillingAddressUUID, setCurrentProvidersPhysicalAddressUUID, setCurrentProvidersUUID, setIsLocationModalOpen, setLocationsData, setPatientsByLocationId, setProviderGroupDetails, setProviderSepcialities } from './LocationSlice';
import ProvidersDataService from '../../../../../services/ProvidersDataService';
import General from '../../../../../libs/utility/General';
import ProviderGroupDataService from '../../../../../services/ProviderGroupDataService';
// import { getAllProviderGroupLocations } from '../Profile/ProfileSaga';
// import { getAllProviderLocation } from '../../../MyAccountCard/Tabs/MyProfile/ProviderProfileSaga';
import { addNotifications } from '../../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../../components/common-components/toaster/MetToaster';
import PatientDataService from '../../../../../services/PatientDataServices';
export const { postLocationData, updateLocations, getProviderSepcialities,getProviderGroupDetails,getAllProviderGroupLocations,getPatientsByLocationId,getAllProviders } = {
	postLocationData: (payload) => {
		return {
			type: 'POST/LOCATON_DATA',
			payload,
		};
	},
	updateLocations: (payload) => {
		return {
			type: 'PATCH/LOCATION_DATA',
			payload,
		};
	},
	getProviderSepcialities: (payload) => {
		return {
			type: 'GET/PROVIDER_SPECIALITIES',
			payload,
		};
	},
	getProviderGroupDetails: (payload) => {
		return {
			type: 'GET/GET_PROVIDER_GROUP_DETAILS',
			payload,
		};
	},
	getAllProviderGroupLocations: (payload) => {
		return {
			type: 'GET/GET_PROVIDER_GROUP_DETAILS',
			payload,
		};
	},
	getPatientsByLocationId:(payload) =>{
		return{
			type: 'GET/PATIENTS_BY_ID',
			payload
		}
	},
	getAllProviders: (payload) => {
		return {
			type: 'GET/PROVIDERS',
			payload,
		};
	},
};
function* getAllProviderGroupLocationsAsync(action) {
	try {
		const response = yield ProvidersDataService.getAllProviderLocation(action.payload);
		const { data, status } = response.data;

		if (status) {
			const { locations } = data || [];
			yield put(setAllProviderGroupLocation(locations));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* postLocationDataAsync(action) {
	try {
		yield put(setLocationsData([]));
		const { allLocationData } = action.payload;

		const office_hours = allLocationData.officeHours.reduce((acc, { day, active, from, to, openingTime, closingTime, dayOfWeek }) => {
			acc[day.toLowerCase()] = { active, from, to, openingTime, closingTime, dayOfWeek };
			return acc;
		}, {});

		const data = {
			image: allLocationData?.image,
			name: allLocationData?.name,
			locationId: allLocationData?.locationId,
			placeOfService: allLocationData?.placeOfService.value,
			specialityType: allLocationData?.specialityType,
			email: allLocationData?.email,
			groupNpiNumber: Number(allLocationData?.groupNpiNumber),
			fax: allLocationData?.fax,
			information: allLocationData?.information,
			// timezone: allLocationData?.timezone,
			contactNumber: Number(allLocationData?.contactNumber),
			contactPerson: allLocationData?.contactPerson,
			physicalAddress1: allLocationData?.physicalAddress1,
			physicalAddress2: allLocationData?.physicalAddress2,
			physicalAddressCity: allLocationData?.physicalAddressCity,
			physicalAddressState: allLocationData?.physicalAddressState.value,
			physicalAddressCountry: allLocationData?.physicalAddressCountry,
			physicalAddressZip: allLocationData?.physicalAddressZip,
			billingAddress1: allLocationData?.billingAddress1,
			billingAddress2: allLocationData?.billingAddress2,
			billingAddressCity: allLocationData?.billingAddressCity,
			billingAddressState: allLocationData?.billingAddressState.value,
			billingAddressCountry: allLocationData?.billingAddressCountry,
			billingAddressZip: allLocationData?.billingAddressZip,
			officeHours: office_hours,
			number: Number(allLocationData?.number),
			speciality: allLocationData?.speciality || [],
		};
		const response = yield ProvidersDataService.postLocationData(data);
		if (response.status === 201) {
			yield put(setIsLocationModalOpen(false));
			yield put(getAllProviderGroupLocations());
			yield put(getAllProviderGroupLocations());
			yield put(addNotifications({ message: 'Location created succesfully', variant: TOASTER_VARIANT.SUCCESS }));

		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* updateLocationsAsync(action) {
	try {
		// const { uuid = '', updatedData = {} } = action.payload;
		const { uuid, allLocationData } = action.payload;

		const office_hours = allLocationData.officeHours.reduce((acc, { day, active, from, to, openingTime, closingTime, dayOfWeek }) => {
			acc[day.toLowerCase()] = { active, from, to, openingTime, closingTime, dayOfWeek };
			return acc;
		}, {});

		const data = {
			image: allLocationData?.image,
			name: allLocationData?.name,
			locationId: allLocationData?.locationId,
			placeOfService: allLocationData?.placeOfService.value,
			specialityType: allLocationData?.specialityType,
			email: allLocationData?.email,
			groupNpiNumber: Number(allLocationData?.groupNpiNumber),
			fax: allLocationData?.fax,
			information: allLocationData?.information,
			// timezone: allLocationData?.timezone,
			contactNumber: Number(allLocationData?.contactNumber),
			contactPerson: allLocationData?.contactPerson,
			physicalAddress1: allLocationData?.physicalAddress1,
			physicalAddress2: allLocationData?.physicalAddress2,
			physicalAddressCity: allLocationData?.physicalAddressCity,
			physicalAddressState: allLocationData?.physicalAddressState?.value,
			physicalAddressCountry: allLocationData?.physicalAddressCountry,
			physicalAddressZip: allLocationData?.physicalAddressZip,
			billingAddress1: allLocationData?.billingAddress1,
			billingAddress2: allLocationData?.billingAddress2,
			billingAddressCity: allLocationData?.billingAddressCity,
			billingAddressState: allLocationData?.billingAddressState?.value,
			billingAddressCountry: allLocationData?.billingAddressCountry,
			billingAddressZip: allLocationData?.billingAddressZip,
			officeHours: office_hours,
			number: Number(allLocationData?.number),
			speciality: allLocationData?.speciality || [],
		};
		const resp = yield ProvidersDataService.updateLocations(uuid, data);
		if (resp.status === 200) {
			yield put(setIsLocationModalOpen(false));
			yield put(getAllProviderGroupLocations());
			yield put(addNotifications({ message: 'Location updated succesfully', variant: TOASTER_VARIANT.SUCCESS }));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}
function* getProviderSpecialitiesAsync(action) {
	try {
		const response = yield ProviderGroupDataService.getProviderSepcialities(action.payload);
		const { data } = response.data;
		const specialities = General.addLableValuePair(data, 'name', 'uuid');
		yield put(setProviderSepcialities(specialities));
	} catch (error) {
		console.log('err: ', error);
	}
}
function* getProviderGroupDetailsAsync(action) {
	try {
		const response = yield ProviderGroupDataService.getProviderGroupDetails(action.payload);

		const {
			data: [firstElement],
		} = response.data;
		yield put(setCurrentProvidersUUID(response?.data?.data[0]?.uuid));
		yield put(setCurrentProvidersPhysicalAddressUUID(response?.data?.data[0]?.physicalAddress?.uuid));
		yield put(setCurrentProvidersBillingAddressUUID(response?.data?.data[0].billingAddress.uuid));
		yield put(setProviderGroupDetails(firstElement));
		// yield put(setEditedProfileData(firstElement))
	} catch (error) {
		console.log('err: ', error);
	}
}

function* getPatientsByLocationIdAsync(action){
	try{
		const {locationId} = action.payload
		const params = {
            locationId: locationId
        }
		const resp = yield PatientDataService.getPatientGridList({params})
		yield put(setPatientsByLocationId(resp?.data?.data?.patients))
	}catch(error) {
		console.log(error);
	}
}
function* getAllProvidersAsync(action) {

	try {
		const {name, locationId, type} = action.payload
		const params = {
			name: name,
			role: "",
			locationId: locationId,
			departmentId: '',
			type: type,
			status: "",
		};
		const response = yield ProvidersDataService.getAllProviders(params);
		if (response.status) {
			yield put(setAllProviders(response.data.data));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}


function* rootSaga() {
	yield all([
		takeLatest(postLocationData().type, postLocationDataAsync),
		takeLatest(updateLocations().type, updateLocationsAsync),
		takeLatest(getProviderSepcialities().type, getProviderSpecialitiesAsync),
		takeLatest(getProviderGroupDetails().type, getProviderGroupDetailsAsync),
		takeLatest(getAllProviderGroupLocations().type, getAllProviderGroupLocationsAsync),
		takeLatest(getPatientsByLocationId().type, getPatientsByLocationIdAsync),
		takeLatest(getAllProviders().type, getAllProvidersAsync)

	]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

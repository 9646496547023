import store from "../../../../../store/store";
export const componentKey = 'VISIT_NOTE';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setVisitNoteState: (state, action) => {
            state.visitNoteState = action.payload
        },
        setVisitNoteFormState: (state, action) => {
            state.visitNoteFormState = action.payload
        },
        setVisitNotePaginationState: (state, action) => {
            state.visitNotePaginationState = action.payload
        },
        setIsVisitNoteModalOpen: (state, action) => {
            state.isVisitNoteModalOpen = action.payload
        },
        setVisitNoteById: (state, action) => {
            state.visitNoteById = action.payload
        },
        setIsDeleteVisitNotePopUpOpen: (state, action) => {
            state.isDeleteVisitNotePopUpOpen = action.payload
        },
        setVisitNoteId: (state, action) => {
            state.visitNoteId = action.payload
        },
    },
    initialReducerState: {
        visitNotePaginationState: { pageNumber: 1, limit: 10, total: 0, },
        visitNoteState: [],
        visitNoteFormState: {},
        isVisitNoteModalOpen: false,
        visitNoteById: {},
        isDeleteVisitNotePopUpOpen: false,
        visitNoteId: ''
    },
});

export const { setVisitNotePaginationState, setVisitNoteState, setVisitNoteFormState, setIsVisitNoteModalOpen, setVisitNoteById, setIsDeleteVisitNotePopUpOpen, setVisitNoteId } = actions;

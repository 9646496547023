import store from "../../../../store/store"

export const componentKey = 'SETTING_TABS'

const initialState = {
    updatedNotifications: []
}

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setupdatedNotifications: (state, action) => {
            state.updatedNotifications = action.payload
        },
    },
    initialReducerState: initialState
})

export const { setupdatedNotifications } = actions
import store from '../../../../store/store';
import { all, put, takeLatest } from 'redux-saga/effects';
import { componentKey } from './ProfileCardSlice';
import PatientDashboardDataService from '../../../../services/PatientDashboardDataService';
import { getPatientDetails } from '../PatientDashboardSaga';
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/MetToaster';

export const { updateProfiles, updateEmergencyContact, updatePreference, updatePatientAddress } = {
	updateProfiles: (payload) => {
		return {
			type: 'UPDATE/PATIENTDASHBOARD/PROFILE',
			payload,
		};
	},
	updateEmergencyContact: (payload) => {
		return {
			type: 'UPDATE/PATIENTDASHBOARD/EMERGENCY_CONTACT',
			payload,
		};
	},
	updatePreference: (payload) => {
		return {
			type: 'UPDATE/PATIENTDASHBOARD/PREFERENCE',
			payload,
		};
	},
	updatePatientAddress: (payload) => {
		return {
			type: 'UPDATE/PATIENTDASHBOARD/ADDRESS',
			payload,
		};
	},
};

function* updateProfilesAsync(action) {
	try {
		const { uuid = '', updatedData = {}, handleSuccess } = action.payload;

		const response = yield PatientDashboardDataService.updatePatientProfiles('patients', uuid, updatedData);

		const { message, status, data } = response?.data || {};

		if (status) {
			handleSuccess();
			if (uuid) {
				yield put(getPatientDetails({ patientId: uuid }));
			}
			yield put(
				addNotifications({ message: 'Patient updated successfully', variant: TOASTER_VARIANT.SUCCESS })
			);
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* updateEmergencyContactAsync(action) {
	try {
		const { uuid = '', updatedData = {}, patientId, handleSuccess } = action.payload;

		const response = yield PatientDashboardDataService.updatePatientProfiles(
			'emergency-contacts',
			uuid,
			updatedData
		);

		const { message, status, data } = response?.data || {};

		if (status) {
			handleSuccess();
			if (patientId) {
				console.log(patientId);
				yield put(getPatientDetails({ patientId: patientId }));
			}
			yield put(
				addNotifications({ message: 'Emergency contact updated successfully', variant: TOASTER_VARIANT.SUCCESS })
			);
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* updatePreferenceAsync(action) {
	try {
		const { uuid = '', updatedData = {}, handleSuccess } = action.payload;

		const response = yield PatientDashboardDataService.updatePreference('patients', uuid, updatedData);

		const { message, success, data } = response.data || {};

		if (success) {
			handleSuccess();
			if (uuid) {
				yield put(getPatientDetails({ patientId: uuid }));
			}
			yield put(
				addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS })
			);
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* updatePatientAddressAsync(action) {
	try {
		const { uuid = '', updatedData = {}, handleSuccess, patientId } = action.payload;
		const response = yield PatientDashboardDataService.updatePatientProfiles('address', uuid, updatedData);

		const { message, status, data } = response || {};

		if (status) {
			handleSuccess();
			if (uuid) {
				yield put(getPatientDetails({ patientId: patientId }));
			}
			yield put(
				addNotifications({ message: 'Address updated successfully', variant: TOASTER_VARIANT.SUCCESS })
			);
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* rootSaga() {
	yield all([
		takeLatest(updateProfiles().type, updateProfilesAsync),
		takeLatest(updateEmergencyContact().type, updateEmergencyContactAsync),
		takeLatest(updatePreference().type, updatePreferenceAsync),
		takeLatest(updatePatientAddress().type, updatePatientAddressAsync),
	]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

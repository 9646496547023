import React from 'react';
import { SettingsAppointmentIcon } from './icons/settingAppointments';
import { RightArrowIcon } from './icons/rightArrowIcon';
import { SettingsMyAccountIcon } from './icons/settingMyAccount';
import { SettingsBackArrowIcon } from './icons/settingsBackArrow';
import { GreyPencilIcon } from './icons/greyPencil';
import { SettingsLeftArrowIcon } from './icons/settingsLeftArrowIcon';
import { SettingsRightArrowIcon } from './icons/settingsRightArrowIcon';
import { SettingsLocationIcon } from './icons/settingsLocationIcon';
import { SettingsProviderIcon } from './icons/settingsProviderIcon';
import { SettingsPlusIcon } from './icons/settingsPlusIcons';
import { SettingsPencilIcon } from './icons/settingsPencilIcon';
import { SettingsWatchIcon } from './icons/settingsWatchIcon';
import { SettingsDeleteIcon } from './icons/settingsDeleteIcon';
import { SettingsPlusPrimaryIcon } from './icons/settingsPlusPrimaryIcon';
import { SettingsVideoIcon } from './icons/settingsVideoIcon';
import { SettingsPrintConfiguration } from './icons/settingsPrintConfiguration';
import { SettingsDeleteRedIcon } from './icons/settingsDeleteRedIcon';
import { SettingProviderGroupIcon } from './icons/settingProviderGroup';
import { SettingTemplatesIcon } from './icons/SettingTemplatesIcon';
import { SettingMasterIcon } from './icons/SettingMasterIcon';

const icons = {
	settingsAppointmentIcon: (iconProps = {}) => <SettingsAppointmentIcon color={iconProps.color} />,
	settingsMyAccountIcon: (iconProps = {}) => <SettingsMyAccountIcon color={iconProps.color} />,
	rightArrowIcon: (iconProps = {}) => <RightArrowIcon color={iconProps.color} />,
	settingsBackArrowIcon: (iconProps = {}) => <SettingsBackArrowIcon color={iconProps.color} />,
	greyPencilIcon: (iconProps = {}) => <GreyPencilIcon color={iconProps.color} />,
	settingsLeftArrowIcon: (iconProps = {}) => <SettingsLeftArrowIcon color={iconProps.color} />,
	settingsRightArrowIcon: (iconProps = {}) => <SettingsRightArrowIcon color={iconProps.color} />,
	settingsLocationIcon: (iconProps = {}) => <SettingsLocationIcon color={iconProps.color} />,
	settingsProviderIcon: (iconProps = {}) => <SettingsProviderIcon color={iconProps.color} />,
	settingsPlusIcon: (iconProps = {}) => <SettingsPlusIcon color={iconProps.color} />,
	settingsPencilIcon: (iconProps = {}) => <SettingsPencilIcon color={iconProps.color} />,
	settingsWatchIcon: (iconProps = {}) => <SettingsWatchIcon color={iconProps.color} />,
	settingsDeleteIcon: (iconProps = {}) => <SettingsDeleteIcon color={iconProps.color} />,
	settingsPlusPrimaryIcon: (iconProps = {}) => <SettingsPlusPrimaryIcon color={iconProps.color} />,
	settingsVideoIcon: (iconProps = {}) => <SettingsVideoIcon color={iconProps.color} />,
	settingsPrintConfiguration: (iconProps = {}) => <SettingsPrintConfiguration color={iconProps.color} />,
	settingsDeleteRedIcon: (iconProps = {}) => <SettingsDeleteRedIcon color={iconProps.color} height={iconProps.height} width={iconProps.width
	} />,
	settingProviderGroupIcon: (iconProps = {}) => <SettingProviderGroupIcon color={iconProps.color} />,
	settingTemplatesIcon: (iconProps = {}) => <SettingTemplatesIcon color={iconProps.color} />,
	settingMasterIcon : (iconProps = {}) => <SettingMasterIcon color={iconProps.color}/>,
};
const Icons = ({ iconName, color, transformScale = 1, rotateDeg = 0, style, height, width }) => {
	const iconProps = {
		style: {
			...style,
			transform: `scale(${transformScale}) rotate(${rotateDeg}deg)`,
		},
		color: color,
		stroke: color,
		iconName: '',
		height: height,
		width: width,
	};
	const icon = icons[iconName];

	if (!icon) {
		console.error(`Icon with name "${iconName}" not found.`);
		return null;
	}
	return React.cloneElement(icon(iconProps), iconProps);
};
export default Icons;

import store from '../../../../store/store';
import { all, put, takeLatest } from 'redux-saga/effects';
import { componentKey, setEditTestsData, setIsOpenViewReportModal, setIsVitalsTestModalOpen, setIsreviewTestModalOpen, setPaginationState, setVitalsTestDetails } from './VitalsTestSlice';
import VitalsTestDataService from '../../../../services/VitalsTestDataService';
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/MetToaster';
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice';

export const { fetchAllVitalsTest, patchVitalsTest, updatePendingVitalTest } = {
    fetchAllVitalsTest: (payload) => {
        return {
            type: 'FETCH_PATIENT_VITALS_TEST',
            payload,
        };
    },
    patchVitalsTest: (payload) => {
        return {
            type: 'PATCH_VITALS_TEST',
            payload,
        };
    },
    updatePendingVitalTest: (payload) => {
        return {
            type: 'UPDATE_PENDING_VITALS_TEST',
            payload,
        };
    },
};

function* fetchAllVitalsTestAsync(action) {
    try {
        const { paginationState, patientId } = action.payload
        const params =
        {
            pageNumber: paginationState?.pageNumber,
            limit: paginationState?.limit,
            patientId
        }
        const response = yield VitalsTestDataService.getAllVitalsTestDetails(params);
        const { data, status } = response.data;
        if (status) {
            yield put(setVitalsTestDetails(data));
            yield put(setPaginationState({ total: data.totalRecords }))

        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* patchVitalsTestAsync(action) {
    try {
        const { body, paginationState, patientId } = action.payload
        const { data } = yield VitalsTestDataService.patchVitalsTest({ body });
        const { success, message } = data
        if (success) {
            yield put(fetchAllVitalsTest({ paginationState, patientId }))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
        }

    } catch (error) {
        console.log('Error patching vitals test:', error);
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))

    } finally {
        yield put(setIsreviewTestModalOpen(false))
        yield put(setIsOpenViewReportModal(false))
    }
}


function* updatePendingVitalTestAsync(action) {
    try {
        const { payload, vitalTestId, patientId } = action.payload

        const response = yield VitalsTestDataService.updatePendingVitalTest(vitalTestId, payload);
        const { data, message, status } = response.data
        if (status) {
            yield put(setEditTestsData({}))
            yield put(setIsVitalsTestModalOpen(false))
            yield put(fetchAllVitalsTest({ paginationState: { pageNumber: 1, limit: 10, total: 0 }, patientId }))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
        }

    } catch (error) {
        console.log('Error patching vitals test:', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(fetchAllVitalsTest().type, fetchAllVitalsTestAsync),
        takeLatest(patchVitalsTest().type, patchVitalsTestAsync),
        takeLatest(updatePendingVitalTest().type, updatePendingVitalTestAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

export const serviceDetailsLabelNames = {
    SERVICE_LOCATION: 'Service Location',
    PLACE_OF_SERVICE: 'Place of Service',
    DOS: 'Date of Service (DOS)',
    SERVICE_STATE: 'Service State',
    RENDERING_PROVIDER: 'Rendering Provider',
    DEPARTMENT_NAME: 'Department Name',
    BILLER_NAME: 'Biller Name'
}

export const serviceDetailsFieldNames = {
    SERVICE_LOCATION: 'serviceLocationId',
    PLACE_OF_SERVICE: 'pos',
    DOS: 'dos',
    SERVICE_STATE: 'serviceState',
    RENDERING_PROVIDER: 'renderingProviderId',
    DEPARTMENT_NAME: 'departmentName'
}

export const BillingFieldsArray = [
    {
        fieldName: serviceDetailsFieldNames.SERVICE_LOCATION,
        fieldLabel: serviceDetailsLabelNames.SERVICE_LOCATION,
        isRequired: true,
        placeholder: serviceDetailsLabelNames.SERVICE_LOCATION,
        type: 'dropdown',
        isDropdown: true
    },
    {
        fieldName: serviceDetailsFieldNames.PLACE_OF_SERVICE,
        fieldLabel: serviceDetailsLabelNames.PLACE_OF_SERVICE,
        isRequired: true,
        placeholder: serviceDetailsLabelNames.PLACE_OF_SERVICE,
        type: 'dropdown',
        isDropdown: true
    },
    {
        fieldName: serviceDetailsFieldNames.DOS,
        fieldLabel: serviceDetailsLabelNames.DOS,
        isRequired: true,
        placeholder: serviceDetailsLabelNames.DOS,
        type: 'date',
        isDropdown: true
    },
    {
        fieldName: serviceDetailsFieldNames.SERVICE_STATE,
        fieldLabel: serviceDetailsLabelNames.SERVICE_STATE,
        isRequired: true,
        placeholder: serviceDetailsLabelNames.SERVICE_STATE,
        type: 'dropdown',
        isDropdown: true
    },
    {
        fieldName: serviceDetailsFieldNames.RENDERING_PROVIDER,
        fieldLabel: serviceDetailsLabelNames.RENDERING_PROVIDER,
        isRequired: true,
        placeholder: serviceDetailsLabelNames.RENDERING_PROVIDER,
        type: 'dropdown',
        isDropdown: true
    },
    {
        fieldName: serviceDetailsFieldNames.DEPARTMENT_NAME,
        fieldLabel: serviceDetailsLabelNames.DEPARTMENT_NAME,
        isRequired: true,
        placeholder: serviceDetailsLabelNames.DEPARTMENT_NAME,
        type: 'dropdown',
        isDropdown: true
    },
]

export const genrateSuperBillFieldNames = {
    NOTE: 'note',
    TOTAL_BILL: 'totalBill',
    DISCOUNT_IN_PERCENTAGE: 'discountInPercentage',
    NET_CHARGES: 'netCharges',
    INSURANCE_AMOUNT: 'insuranceAmount',
    CO_PAY_AMOUNT: 'coPayAmount',
    TOTAL_BALANCE: 'totalBalance',
    TOTAL_AMOUNT_PAID: 'totalAmountPaid'
}

export const proceduralCodesFieldNames = {
    PROCEDURAL_CODE: 'proceduralCode',
    PROCEDURAL_CODE_DESCRIPTION: 'proceduralCodeDescription',
    CHARGES: 'charges',
    QUANTITY: 'quantity',
    DISCOUNT_IN_PERCENTAGE: 'discountInPercentage',
    DISCOUNT_IN_AMOUNT: 'discountInAmount',
    NET_AMOUNT: 'netAmount',
    DIAGNOSIS_CODE: ""

}

export const COLLECT_PAYMENT_FIELDS_NAMES = {
    PATIENT_ID: 'patientId',
    PROVIDER_ID: 'providerId',
    PAYMENT_DATE: 'paymentDate',
    METHOD: 'method',
    AMOUNT: "amount",
    CHECK_NO: "checkNo",
    TRANSECTIONID: 'transactionId',
    MOBILE_NUMBER: 'mobile',
    NOTE: 'note',
    CARD_HOLDER_NAME: 'cardHolderName'
}

export const deviceTestsFieldNames = {
    DEVICE_NAME: "deviceName",
    TEST_NAME: 'testName',
    CHARGE: 'charge',
    STATUS: 'status',
}

export const vitalsTestFieldNames = {
    GST_TYPE: 'gstType',
    GST_IN_PERCENTAGE: 'gstPercentage',
    GST_NUMBER: 'gstNumber',
    GST_AMOUNT: 'gstAmount',
    GST_TOTAL_AMOUNT: 'gstTotalAmount',
    IS_GST_APPLICABLE:'isGstApplicable'
}
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../../../../components/common-components/label/Label';
import Heading from '../../../../../components/common-components/heading/Heading';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import Icons from '../../../../../components/icons/Icons';
import Button from '../../../../../components/common-components/button/Button';
import { FORM_FIELDS_NAMES } from './constant';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import Table from '../../../../Table/Index';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import Input from '../../../../../components/common-components/input/Input';
import { getValidationSchema } from '../../../../../libs/formsUtils';
import { getAllRoles, getAllRolesAndResponsibilities, getRoleByID } from './RolesAndResponsibilitiesSaga';
import { componentKey, setIsEditedRoleData, setIsNewAddRoleModalOpen, setIsNewRoleAdd } from './RolesAndRespomsibilitiiesSlice';

const ViewRoleAndRespModal = ({ close }) => {
	const dispatch = useDispatch();
	const {
		isViewRoleModalOpen,
		currentUUID,
		allRolesAndResp,
		editedRoleData,
		roleByID,
	} = useSelector((state) => state[componentKey]);
	const [groupedData, setGroupedData] = useState({});

	const fields = [];
	const validationSchema = getValidationSchema(fields);


	const columns = [
		{
			field: 'pcp',
			label: <Label>Permission</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row?.permissions?.permissionName}
						</Heading>
					</>
				);
			},
		},
		{
			field: 'pcp',
			label: <Label>Description</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row?.permissions?.description}
						</Heading>
					</>
				);
			},
		},

		{
			field: 'pcp',
			label: <Label>Status</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{/* <div className="w-[50%] p-1 bg-[#1A1A1A0F] rounded-[50px] flex"> */}
							{/* <ToggleSwitch
									label={'action'}
									checked={row.permissionEnabled}
									onChangeCb={(status) => handleToggleClick(row, 'permissionEnabled', status)}
								/> */}
							<span
								className={`${
									row.enable === true
										? 'text-met-success border-b-[1px] border-b-met-success'
										: 'text-met-red border-b-[1px] border-b-met-red'
								}`}
							>
								{row.enable === true ? 'Enable' : 'Disable'}
							</span>
							{/* </div> */}
						</Heading>
					</>
				);
			},
		},
	];

	useEffect(() => {
		dispatch(getAllRolesAndResponsibilities());
		dispatch(getAllRoles());
		dispatch(getRoleByID({ uuid: currentUUID }));
	}, []);

	useEffect(() => {
		const groupedData =
			allRolesAndResp.reduce((acc, item) => {
				if (!acc[item.module]) {
					acc[item.module] = [];
				}
				acc[item.module].push({ ...item });
				return acc;
			}, {}) || {};

		setGroupedData(groupedData);
	}, [allRolesAndResp]);

	const rows = roleByID?.rolesPermissionsMapping?.map((item, index) => {
		return item;
	});
	
	return (
		<Formik
			initialValues={{
				[FORM_FIELDS_NAMES.ROLE]: editedRoleData?.role,
				[FORM_FIELDS_NAMES.DESCRIPTION]: editedRoleData?.description,
				[FORM_FIELDS_NAMES.PERMISSION]: editedRoleData?.permission,
				// ...editedRoleData,
				...groupedData,
			}}
			validationSchema={validationSchema}
			enableReinitialize
			
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={isViewRoleModalOpen}
							validationSchema={validationSchema}
							title={'View Role'}
							close={() => {
								close();
								// dispatch(setIsEditedRoleData({}));
							}}
							icon={<Icons iconName="closeIcon" />}
							showEditIcon={true}
							edit={() => {
								dispatch(setIsNewAddRoleModalOpen(true));
								dispatch(setIsNewRoleAdd(false));
								dispatch(setIsEditedRoleData(roleByID))
							}}
							editIcon={<Icons iconName="editIcon" />}
							customClasses="w-[80%]"
							enableReinitialize
						>
							<div className="flex flex-col">
								<div className="flex justify-between">
									<div className="w-[40%]">
										<Input
											label="Role Name"
											isRequired={false}
											placeholder="Role"
											name={FORM_FIELDS_NAMES.DESCRIPTION}
											value={roleByID?.name}
											// value={values[FORM_FIELDS_NAMES.DESCRIPTION]}
											// onChangeCb={(e) => {
											// 	setFieldValue(FORM_FIELDS_NAMES.DESCRIPTION, e.target.value);
											// }}
										/>
									</div>
									<div className="w-[55%]">
										<Input
											label="Description"
											isRequired={false}
											placeholder="Enter  Description"
											name={FORM_FIELDS_NAMES.DESCRIPTION}
											value={roleByID?.description}
											// onChangeCb={(e) => {
											// 	setFieldValue(FORM_FIELDS_NAMES.DESCRIPTION, e.target.value);
											// }}
										/>
									</div>
								</div>
								<div className="mt-4">
									{Object.keys(groupedData || {}).map((key, index) => {
										return (
											<div key={index} className="mt-5">
												<Heading
													type={HEADING.H1}
													color="met-primary"
													fontWeight="bold"
													fontSize={'lg'}
												>
													{key}
												</Heading>
												<div className="mt-4">
													<Table coloumns={columns} rows={rows} />
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</ModalComponent>
					</Form>
				);
			}}
		</Formik>
	);
};
export default ViewRoleAndRespModal;

import React from 'react';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import ProfileWrapper from './ProfileCards/ProfileContainer/ProfileWrapper';

const Profile = () => {
	return (
		<div>
			<div className="flex justify-between items-center p-2 px-4">
				<div className="text-xl font-bold">Profile</div>
			</div>
			<div>
				<ProfileWrapper />
			</div>
		</div>
	);
};

export default Profile;

export const SettingsDeleteRedIcon = ({ width = '18', height = "18" }) => {
	return (
		<svg id="delete_black_24dp" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18">
			<path id="Path_204310" data-name="Path 204310" d="M0,0H18V18H0Z" fill="none" />
			<path
				id="Path_204311"
				data-name="Path 204311"
				d="M11.286,7v6.667H6.714V7h4.571m-.857-4H7.571L7,3.667H5V5h8V3.667H11Zm2,2.667H5.571v8A1.253,1.253,0,0,0,6.714,15h4.571a1.253,1.253,0,0,0,1.143-1.333Z"
				fill="#ff3939"
			/>
		</svg>
	);
};

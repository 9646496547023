import React, { useEffect, useState } from 'react'
import Table from '../../../../containers/Table/Index'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Label from '../../../../components/common-components/label/Label'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey } from './GenrateSuperBillSlice'
import { getCollectPaymentDetails } from './GenrateSuperBillSaga'
import General from '../../../../libs/utility/General'
import { useParams } from 'react-router-dom'

function CollectPaymentTable() {
    const { billData } = useSelector(state => state[componentKey])
    const dispatch = useDispatch()


    const { uuid: patientUuid } = useParams()
    const [paymentTableData, setPaymentTableData] = useState()


    const { mapping_billing_payment } = billData || {}
    const totalAmount = mapping_billing_payment?.length ? mapping_billing_payment[0].patientPayment.amount : 0

    useEffect(() => {
        if (mapping_billing_payment?.length) {

            const paymentTableData = mapping_billing_payment.map((item, index) => {
                return {
                    ...item,
                    index,
                }
            })
            setPaymentTableData(paymentTableData)
        }
    }, [mapping_billing_payment])

    const coloumns = [
        {
            field: "srNo",
            label: <Label fontWeight='md'>Sr No</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">
                                {row.index + 1}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "method",
            label: <Label fontWeight='md'>Payment Mode</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">
                                {row?.patientPayment?.method}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "paymentDate",
            label: <Label fontWeight='md' >Date</Label>,
            renderLogic: (row) => {
                const formattedDate = General.getformattedDate(row?.patientPayment?.paymentDate)
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">
                                {formattedDate}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "amount",
            label: <Label fontWeight='md' >Amount (₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">
                                {row?.patientPayment?.amount}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "transactionId",
            label: <Label fontWeight='md'>TransectionID</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">
                            {row?.patientPayment?.transactionId ? row?.patientPayment?.transactionId : "-"}
                        </Heading>
                    </div>
                </>
            },
        },

    ]

    return (

        <>
            <div className='mt-5'>
                <Table isPagination={false} coloumns={coloumns} rows={paymentTableData} />
            </div>
            <div className='h-[45px] items-center pl-4 bg-met-secondary flex font-bold fs-14'>
                <div>Total Paid - {totalAmount}</div>
            </div>
        </>
    )
}


export default CollectPaymentTable
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { componentKey as routerComponentKey } from '../../routes/RoutesSlice'
import { USER_ROLE_NAMES } from './Constants'
import IsAccessAllowed from './IsAccessAllowed'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const AllowAdminOnly = ({ fallback, children, navigateUrl = null }) => {
    const [isAdminUser, setIsAdminUser] = useState(true)
    const { loggedInProviderUser } = useSelector(state => state[routerComponentKey])
    const navigate = useNavigate()

    useEffect(() => {
        const allowedRoles = [
            USER_ROLE_NAMES.ADMIN_PROVIDER,
        ]

        const currentRole = loggedInProviderUser?.roleNames || []
        const status = IsAccessAllowed(allowedRoles, currentRole)
        setIsAdminUser(status)
    }, [loggedInProviderUser?.roleNames])

    if (!isAdminUser) {
        if (fallback) {
            return fallback()
        } else if (navigateUrl) {
            return navigate(navigateUrl)
        }
        return <></>
    }

    return (
        <>{children}</>
    )
}

export default AllowAdminOnly
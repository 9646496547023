import React, { useEffect, useRef, useState } from 'react';
import Checkbox from '../checkbox/Checkbox';
import Button from '../button/Button';
import { BUTTON_VARIANTS } from '../button/Constants';
import General from '../../../libs/utility/General';

const DropdownModal = ({
	options = [],
	isOpen = false,
	onCloseCb = () => { },
	selectCb = () => { },
	selectedOption = {},
	isMultiSelect = false,
	onMouseLeaveCb = () => { },
	renderOption = undefined,
	onDropdownTouchBottomCb = () => { },
	isAsync = false,
	disabled = false,
	openDirection = "bottom",
	multiSelectAll = false,
}) => {
	const [multiSelectValues, setMultiSelectValues] = useState([]);
	const modalRef = useRef();
	const lastItemRef = useRef()

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				return onCloseCb();
			}
		};

		if (isOpen) {
			document.addEventListener('mousedown', handleOutsideClick);
		} else {
			document.removeEventListener('mousedown', handleOutsideClick);
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [isOpen]);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry?.isIntersecting) {
					onDropdownTouchBottomCb(entry?.isIntersecting)
				}
			},
		);

		if (lastItemRef.current) {
			observer.observe(lastItemRef.current);
		}

		return () => {
			if (lastItemRef.current) {
				observer.unobserve(lastItemRef.current);
			}
		};
	}, []);



	const handleOptionRendering = (option, selectCb) => {
		if (renderOption) {
			return (renderOption(option, selectCb))
		}
		return (
			<div className={`${selectedOption.value === option.value ? 'bg-met-primary text-white' : 'text-gray-500'}`}>
				<Button
					startIcon={option.prefixProps}
					variant={BUTTON_VARIANTS.TEXT}
					customBtnClass={`w-full ${selectedOption.value === option.value ? '' : 'hover:bg-gray-100'
						} rounded-md ${selectedOption.value === option.value ? 'bg-met-primary text-white' : 'text-gray-500'}`}
					onClickCb={() => selectCb(option)}
					disabled={disabled}
				>
					{option.colour ? (
						<div
							style={{ backgroundColor: option.colour }}
							className={`min-w-[50px] h-[20px] rounded-md`}
						></div>
					) : null}
					{option.label}
				</Button>
				{option?.additionalHtml ? <>{option.additionalHtml}</> : null}
			</div>
		);
	};

	useEffect(() => {
		Array.isArray(selectedOption) ? setMultiSelectValues(selectedOption) : null;
	}, [selectedOption]);

	const handleMultiSelectChange = (value) => {
		const updatedArray = General.toggleObjectInArray(multiSelectValues, value, 'value');
		setMultiSelectValues(updatedArray);
	};
	if (!isOpen) {
		return null;
	}

	return (
		<div
			ref={modalRef}
			onMouseLeave={onMouseLeaveCb}
			className={`absolute z-[100] mt-1 w-full bg-white rounded-md shadow-lg right-0 min-w-[200px] max-h-[250px] overflow-hidden ${openDirection === 'top' && "bottom-[50px]"}`}
		>
			<div className="max-h-[200px] overflow-y-auto  met-scrollbar">
				{options.length ? (
					<>
						{multiSelectAll ? <>
							<Button
								variant={BUTTON_VARIANTS.TEXT}
								customBtnClass={"w-full bg-gray-300 rounded-md text-gray-500"}
								onClickCb={(e) => {
									if (multiSelectValues?.length && multiSelectValues?.length === options?.length) {
										setMultiSelectValues([])
									} else {
										setMultiSelectValues(options)
									}
								}}
							>
								<Checkbox
									checked={multiSelectValues.length === options.length}
								/>
								Select All
							</Button>
						</> : ""}
						{options.map((option, index) => (
							<React.Fragment key={'select-dropdown-modal-option' + index}>
								{isMultiSelect ? (
									// multi-select-start
									<Button
										startIcon={option.prefixProps}
										variant={BUTTON_VARIANTS.TEXT}
										customBtnClass={`w-full ${selectedOption.value === option.value ? '' : 'hover:bg-gray-100'
											} rounded-md ${selectedOption.value === option.value
												? 'bg-met-primary text-white'
												: 'text-gray-500'
											}`}
										onClickCb={(e) => {
											handleMultiSelectChange(option);
										}}
									>
										<Checkbox
											checked={multiSelectValues.some((val) => val.value === option.value)}
										/>{' '}
										{option.label}
										{option.colour ? (
											<div
												style={{ backgroundColor: option.colour }}
												className={`min-w-[50px] h-[20px] rounded-md`}
											></div>
										) : null}
									</Button>
									// multi-select-end
								) : (
									handleOptionRendering(option, selectCb)
								)}
							</React.Fragment>
						))}
						{isAsync ? (
							<option className="text-gray-500 pl-2" ref={lastItemRef}>
								Loading...
							</option>
						) : null}
					</>
				) : (
					<div className="cursor-pointer py-2 px-4 hover:bg-gray-100">No Result Found</div>
				)}
			</div>
			{isMultiSelect ? (
				<div className="flex justify-end sticky bottom-0 bg-white">
					<Button
						variant={BUTTON_VARIANTS.TEXT}
						customBtnClass="text-met-primary font-bold"
						onClickCb={() => selectCb(multiSelectValues)}
					>
						Done
					</Button>
				</div>
			) : null}
		</div>
	);
};

export default DropdownModal;

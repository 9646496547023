import React, { useEffect, useMemo } from 'react';
import Avatar from '../../../../../../components/common-components/avatar';
import { componentKey as locationComponentKey } from '../LocationSlice';
import { useSelector } from 'react-redux';
import Label from '../../../../../../components/common-components/label/Label';
import Heading from '../../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../../components/common-components/heading/Constants';
import moment from 'moment';

const Details = () => {
	const { editLocationData } = useSelector((state) => state[locationComponentKey]);
	const LOCATION_DATA = useMemo(() => {
		const {
			locationId,
			name,
			contactNumber,
			email,
			fax,
			groupNpiNumber,
			physicalAddress1,
			physicalAddress2,
			physicalAddressCity,
			physicalAddressCountry,
			physicalAddressState,
			physicalAddressZip,
			billingAddress1,
			billingAddress2,
			billingAddressCity,
			billingAddressCountry,
			billingAddressState,
			billingAddressZip,
		} = editLocationData;
		const { provider_locations_speciality_intermediate } = editLocationData;
		const { officeHours } = editLocationData;

		const specialitydata = provider_locations_speciality_intermediate?.map((item) => item.speciality.name)
		return {
			info: { 'Location Id': locationId, 'Location Name': name, 'Work Phone': contactNumber, Email: email },
			'Basic Information': {
				"Specility": specialitydata.length > 2 ? "Multispecialist" : specialitydata,
				'Fax Number': fax ? fax : "-",
				MLC: groupNpiNumber ? groupNpiNumber : "-",
			},
			'Office Hours': officeHours ? officeHours : [],
			'Practice Address': {
				'Address Line 1': physicalAddress1,
				'Address Line 2': physicalAddress2,
				"City": physicalAddressCity,
				"Country": physicalAddressCountry,
				"State": physicalAddressState,
				"Zip Code": physicalAddressZip,
			},
			'Pay to (Billng) Address': {
				'Address Line 1': billingAddress1,
				'Address Line 2': billingAddress2,
				"City": billingAddressCity,
				"Country": billingAddressCountry,
				"State": billingAddressState,
				"Zip Code": billingAddressZip,
			},
		};
	}, [editLocationData]);

	return (
		<div className="flex flex-col h-[75vh] met-scrollbar overflow-auto">
			<div className="flex justify-between">
				<div className="flex flex-col p-[25px] w-[35%] border-[1px] rounded-[10px] border-opacity-25  border-[#1A1A1A33] mt-2">
					<div>
						<Avatar
							customClass="w-[100px] h-[100px] overflow-hidden object-cover rounded-full"
							url={editLocationData?.imagePath}
						// name={`${item.firstName} ${item.lastName}`}
						/>
					</div>
					<div className="flex mt-4 flex-wrap w-full">
						{Object.keys(LOCATION_DATA.info).map((key) => (
							<div className="flex justify-between w-full mt-4" key={key}>
								<div className="w-2/3 text-met-light-grey font-bold">{key}</div>
								<div className="w-2/3 text-rgba-1a1a1acc font-bold">{LOCATION_DATA.info[key]}</div>
							</div>
						))}
					</div>
				</div>
				<div className="flex flex-col p-[25px] w-[62%] border-[1px] rounded-[10px] border-opacity-25  border-[#1A1A1A33] mt-2">
					<Heading type={HEADING.H1} color="met-primary" fontSize={'lg'} fontWeight="bold">
						Basic Information
					</Heading>
					<div className="flex justify-between w-full">
						<div className="flex w-[35%]  flex-col mt-2">
							{Object.keys(LOCATION_DATA['Basic Information']).map((key) => (
								<div key={key} className="flex justify-between mt-4">
									<div className="w-1/2 text-met-light-grey font-bold">{key}</div>
									<div className="flex-1 text-rgba-1a1a1acc font-bold">{LOCATION_DATA['Basic Information'][key] || '-'}</div>
								</div>
							))}

						</div>
						<div className="w-[60%] ">
							<div className="flex justify-between">
								<div className="w-[25%] mt-4 text-met-light-grey font-bold">Office Hours</div>
								<div className="ml-3  flex-1">
									{Object.keys(LOCATION_DATA['Office Hours']).map((day) => {
										const hours = LOCATION_DATA['Office Hours'][day];
										return (
											<div key={day} className="flex justify-between mt-4">
												<p className="text-rgba-1a1a1acc font-bold w-[20%]">{day.charAt(0).toUpperCase() + day.slice(1)}</p>
												<p className="text-rgba-1a1a1acc font-bold">{hours.from ? moment(hours.from).format('hh:mm A') : "-"}</p>
												<p className="text-rgba-1a1a1acc font-bold">{hours.to ? moment(hours.to).format('hh:mm A') : "-"}</p>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col p-[25px] w-[100%] border-[1px] rounded-[10px] border-opacity-25  border-[#1A1A1A33] mt-4">
				<Heading type={HEADING.H1} color="met-primary" fontSize={'lg'} fontWeight="bold">
					Practice Address
				</Heading>
				<div className="flex justify-between w-full">
					<div className=" w-[60%]">
						{Object.entries(LOCATION_DATA['Practice Address']).map(
							([key, value]) =>
								['Address Line 1', 'Address Line 2', 'Country'].includes(key) && (
									<div key={key} className="flex  mt-4">
										<div className='w-[20%] text-met-light-grey font-bold'>{key}</div>
										<div className='text-rgba-1a1a1acc ml-2 font-bold'>{value || '-'}</div>
									</div>
								)
						)}
					</div>
					<div className="w-[30%]">
						{Object.entries(LOCATION_DATA['Practice Address']).map(
							([key, value]) =>
								!['Address Line 1', 'Address Line 2', 'Country'].includes(key) && (
									<div key={key} className="flex mt-4">
										<div className='w-[20%] text-met-light-grey font-bold'>{key}</div>
										<div className='text-rgba-1a1a1acc ml-3 font-bold'>{value}</div>
									</div>
								)
						)}
					</div>
				</div>
			</div>
			<div className="flex flex-col p-[25px] w-[100%] border-[1px] rounded-[10px] border-opacity-25  border-[#1A1A1A33] mt-4">
				<Heading type={HEADING.H1} color="met-primary" fontSize={'lg'} fontWeight="bold">
					Pay to (Billng) Address
				</Heading>
				<div className="flex justify-between w-full">
					<div className=" w-[60%]">
						{Object.entries(LOCATION_DATA['Pay to (Billng) Address']).map(
							([key, value]) =>
								['Address Line 1', 'Address Line 2', 'Country'].includes(key) && (
									<div key={key} className="flex mt-4">
										<div className='w-[20%] text-met-light-grey font-bold'>{key}</div>
										<div className='text-rgba-1a1a1acc ml-2 font-bold'>{value || '-'}</div>
									</div>
								)
						)}
					</div>
					<div className="w-[30%]">
						{Object.entries(LOCATION_DATA['Pay to (Billng) Address']).map(
							([key, value]) =>
								!['Address Line 1', 'Address Line 2', 'Country'].includes(key) && (
									<div key={key} className="flex mt-4">
										<div className='w-[20%]  text-met-light-grey font-bold'>{key}</div>
										<div className='text-rgba-1a1a1acc ml-3 font-bold'>{value}</div>
									</div>
								)
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Details;

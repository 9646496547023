import React from 'react'
import Icons from '../../../../components/icons/Icons'
import SelectDropdown from '../../../../components/common-components/selectDropdown'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import { Form, Formik } from 'formik'
import { FLAGS_FORM_FIELDS_NAMES } from './Constants'
import { getValidationSchema } from '../../../../libs/formsUtils'


const fields = [
    { fieldName: FLAGS_FORM_FIELDS_NAMES.SEARCH_PATIENT_FLAG, isRequired: true }
]

const validationSchema = getValidationSchema(fields)

const initialValues = { [FLAGS_FORM_FIELDS_NAMES.SEARCH_PATIENT_FLAG]: '' }

const FlagsModal = ({ isOpen, close }) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={() => { }}
        >
            {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                return <Form onSubmit={handleSubmit} >
                    <div>
                        <ModalComponent
                            open={isOpen}
                            title="Add Patient Flags"
                            icon={<Icons iconName="closeIcon" />}
                            customClass="min-w-[638px] min-h-[355px] "
                            close={close}
                            customClasses='w-[30%]'
                            footerButton={
                                <Button type='submit' variant={BUTTON_VARIANTS.CONTAINED}>Add Flags</Button>
                            }
                        >
                            <div className="pt-7">
                                <SelectDropdown
                                    label='Search Patient Flag'
                                    placeholder="Search Patient Flag"
                                    isRequired={true}
                                    name={FLAGS_FORM_FIELDS_NAMES.SEARCH_PATIENT_FLAG}
                                    value={values[FLAGS_FORM_FIELDS_NAMES.SEARCH_PATIENT_FLAG]}
                                    onChangeCb={(val) => { setFieldValue(FLAGS_FORM_FIELDS_NAMES.SEARCH_PATIENT_FLAG, val.value) }}
                                    options={[{ label: "Diabetic", value: "Diabetic" }]}
                                />
                            </div>
                        </ModalComponent>

                    </div>
                </Form>
            }}
        </Formik>

    )
}

export default FlagsModal
import AppDataService from './AppDataService'

const COMMON_BASE = 'patients/family-member'

export default class FamilyDataService {
    static async getFamilyMembers(uuid) {
        return await AppDataService.get(`${COMMON_BASE}/${uuid}`)
    }
    static async updateFamilyMembers(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}`, data);
    }
    static async postFamilyMembers(data) {
        return await AppDataService.post(`${COMMON_BASE}`, data)
    }
    static async deleteFamilyMember(uuid) {
        return await AppDataService.delete(`${COMMON_BASE}/${uuid}`)
    }
}

import React from "react";
import { VEC_ICON_NAME } from "./constants";
import BackIcon from "./vectors/BackIcon";
import UnMuteMicIcon from "./vectors/UnMuteMicIcon";
import MuteMicIcon from "./vectors/MuteMicIcon";
import OffCamera from "./vectors/OffCamera";
import OnCamera from "./vectors/OnCamera";
import VideoBackgroundProfile from "./vectors/VideoBackgroundProfile";
import MicroPhoneIcon from "./vectors/MicroPhoneIcon";
import UpArrow from "./vectors/UpArrow";
import SpeakerIcon from "./vectors/SpeakerIcon";
import VideoCamIcon from "./vectors/VideoCamIcon";
import ExitFullScreen from "./vectors/ExitFullScreen";
import CallRecordingIcon from "./vectors/CallRecordingIcon";
import LeaveMeetingIcon from "./vectors/LeaveMeetingIcon";
import AddGuestIcon from "./vectors/AddGuestIcon";
import ZoomChatIcon from "./vectors/ZoomChatIcon";
import ZoomSettingIcon from "./vectors/ZoomSettingIcon";
import TriggerPatientIcon from "./vectors/TriggerPatientIcon";
import StartEncounterIcon from "./vectors/StartEncounterIcon";
import CloseIcon from "./vectors/CloseIcon";
import ShareDocumentIcon from "./vectors/ShareDocumentIcon";
import SendMessageIcon from "./vectors/SendMessageIcon";
import SendEmojiIcon from "./vectors/SendEmojiIcon";
import ScreenShareIcon from "./vectors/ScreenShareIcon";
import RadioButtonIcon from "./vectors/RadioButtonIcon";

export default function LocalIcons({ iconName, color, style = {}, transformScale, rotateDeg = 0, onClickCb = () => { } }) {
    const iconProps = {
        style: {
            ...style,
            transform: `scale(${transformScale}) rotate(${rotateDeg}deg)`,
            fill: color,
        },
        fill: color,
        stroke: color,
        onClick: onClickCb,
    };

    /* eslint-disable */
    switch (iconName) {
        case VEC_ICON_NAME.BACK_ICON:
            return <BackIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.UNMUTE_MIC_ICON:
            return <UnMuteMicIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.MUTE_MIC_ICON:
            return <MuteMicIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.OFF_CAMERA:
            return <OffCamera {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.ON_CAMERA:
            return <OnCamera {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.VIDEO_BACKGROUND_PROFILE:
            return <VideoBackgroundProfile {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.MICROPHONE_ICON:
            return <MicroPhoneIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.UP_ARROW:
            return <UpArrow {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.SPEAKER_ICON:
            return <SpeakerIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.VIDEO_CAM_ICON:
            return <VideoCamIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.EXIT_FULL_SCREEN:
            return <ExitFullScreen {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.EXIT_FULL_SCREEN:
            return <ExitFullScreen {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.CALL_RECORDING_ICON:
            return <CallRecordingIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.LEAVE_MEETING_ICON:
            return <LeaveMeetingIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.ADD_GUEST_ICON:
            return <AddGuestIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.ZOOM_CHAT_ICON:
            return <ZoomChatIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.ZOOM_SETTING_ICON:
            return <ZoomSettingIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.TRIGGER_PATIENT_ICON:
            return <TriggerPatientIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.START_ENCOUNTER_ICON:
            return <StartEncounterIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.CLOSE_ICON:
            return <CloseIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.SHARE_DOCUMENT_ICON:
            return <ShareDocumentIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.SEND_MESSAGE_ICON:
            return <SendMessageIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.SEND_EMOJI_ICON:
            return <SendEmojiIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.SCREEN_SHARE_ICON:
            return <ScreenShareIcon {...iconProps} onClickCb={onClickCb} />;
        case VEC_ICON_NAME.RADIO_BUTTON_ICON:
            return <RadioButtonIcon {...iconProps} onClickCb={onClickCb} />;
        default:
            return;
    }
    /* eslint-enable */
}

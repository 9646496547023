import React from 'react';
import { VITALS_TESTS_FIELDS } from './Constants';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { componentKey, setIsCreateNewVitalsTests } from './VitalsTestsSlice';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import Icons from '../../../../../components/icons/Icons';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import Input from '../../../../../components/common-components/input/Input';
import General from '../../../../../libs/utility/General';
import { createDeviceTest, patchVitalsTests } from './VitalsTestsSaga';
import { getValidationSchema } from '../../../../../libs/formsUtils';
import { VALIDATION_REGEX } from '../../../../../libs/constant';

function VitalsTestsModal({ open, close, }) {
	const dispatch = useDispatch();

	const { vitalsTestsData, currentUUID, isCreateNewVitalsTests, deviceNamesList, deviceName } = useSelector(
		(state) => state[componentKey]
	);

	const fields = [
		{
			fieldName: VITALS_TESTS_FIELDS.TEST_NAME,
			isRequired: true,
		},
		{ fieldName: VITALS_TESTS_FIELDS.CHARGE, isRequired: true, regexPattern: VALIDATION_REGEX.NUMBER_REGEX },
	];

	const validationSchema = getValidationSchema(fields)

	return (
		<Formik
			initialValues={
				{
					[VITALS_TESTS_FIELDS.TEST_NAME]: vitalsTestsData?.testName,
					[VITALS_TESTS_FIELDS.CHARGE]: vitalsTestsData?.charge
				}
			}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				const data = {
					...values,
					deviceName: deviceName.deviceName
				};
				try {
					if (isCreateNewVitalsTests) {
						dispatch(createDeviceTest({ vitalsTestsData: data }));

					} else {
						dispatch(patchVitalsTests({ vitalsTestId: currentUUID, vitalsTestsData: data }))
					}
				} catch (err) {
					console.log(err);
				}
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={open}
							title={isCreateNewVitalsTests ? 'Add New Vitals Test' : 'Edit Vitals Tests'}
							close={() => {
								close();
							}}
							icon={<Icons iconName="closeIcon" />}
							footerButton={
								<Button
									type="submit"
									variant={BUTTON_VARIANTS.CONTAINED}
									customBtnClass="text-met-primary"
								>
									Save
								</Button>
							}
							customClasses="w-[60%] overflow-visible"
							enableReinitialize
							hideOverflow={true}
						>
							<div className="flex flex-col">
								<div className="w-full flex justify-between">
									<div className="w-[45%]">
										<Input
											label="Test Name"
											isRequired={true}
											placeholder="Enter Test Name"
											name={VITALS_TESTS_FIELDS.TEST_NAME}
											value={values[VITALS_TESTS_FIELDS.TEST_NAME]}
											onChangeCb={(e) => {
												setFieldValue(VITALS_TESTS_FIELDS.TEST_NAME, e.target.value);
											}}
                                            disabled={true}  
										/>
									</div>
								</div>
								<div className="w-full flex justify-between mt-[20px]">
									<div className="w-[45%]">
										<Input
											label="Charge"
											isRequired={true}
											placeholder="Enter Charge"
											name={VITALS_TESTS_FIELDS.CHARGE}
											value={values[VITALS_TESTS_FIELDS.CHARGE]}
											onChangeCb={(e) => {
												setFieldValue(
													VITALS_TESTS_FIELDS.CHARGE,
													General.sanitizeNumbers(e.target.value)
												);
											}}
										/>
									</div>
								</div>
							</div>
						</ModalComponent>
					</Form>
				);
			}}
		</Formik>
	);
}

export default VitalsTestsModal;

import React from 'react'

function PrimaryProviderIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <clipPath id="clip-path">
                    <path id="path2928" d="M0-682.665H18.791v18.791H0Z" transform="translate(0 682.665)" fill="#6a6a6a" />
                </clipPath>
            </defs>
            <g id="Group_87205" data-name="Group 87205" transform="translate(-13281 7948)">
                <rect id="Rectangle_35917" data-name="Rectangle 35917" width="24" height="24" transform="translate(13281 -7948)" fill="#fff" opacity="0" />
                <g id="g2922" transform="translate(13283.604 -7262.73)">
                    <g id="g2924" transform="translate(0 -682.665)">
                        <g id="g2926" clipPath="url(#clip-path)">
                            <g id="g2932" transform="translate(5.268 0.551)">
                                <path id="path2934" d="M-277.406-64.331a4.552,4.552,0,0,0-3.642-1.821h-.165a4.552,4.552,0,0,0-3.642,1.821h0a2.016,2.016,0,0,0-.4,1.21v2.276a4.128,4.128,0,0,0,4.128,4.128A4.128,4.128,0,0,0-277-60.845v-2.276a2.016,2.016,0,0,0-.4-1.21Z" transform="translate(285.258 66.152)" fill="none" stroke="#696a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                            </g>
                            <g id="g2936" transform="translate(5.268 4.089)">
                                <path id="path2938" d="M0-41.665H0a1.424,1.424,0,0,0,1.007-.417l.762-.762h0a5.274,5.274,0,0,0,4.717,0h0l.762.762a1.424,1.424,0,0,0,1.007.417h0" transform="translate(0 42.844)" fill="none" stroke="#696a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                            </g>
                            <g id="g2940" transform="translate(0.551 11.754)">
                                <path id="path2942" d="M-624.975-229.157l-1.241-3.723a2.949,2.949,0,0,0-2.157-1.946l-3.677-.817-1.769,2.948-1.769-2.948-3.677.817a2.948,2.948,0,0,0-2.158,1.946l-1.241,3.723" transform="translate(642.665 235.643)" fill="none" stroke="#696a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                            </g>
                            <g id="g2944" transform="translate(7.627 9.679)">
                                <path id="path2946" d="M0-73.318v-2.075" transform="translate(0 75.393)" fill="none" stroke="#696a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                            </g>
                            <g id="g2948" transform="translate(11.165 9.679)">
                                <path id="path2950" d="M0-73.318v-2.075" transform="translate(0 75.393)" fill="none" stroke="#696a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                            </g>
                            <g id="g2952" transform="translate(11.754 14.703)">
                                <path id="path2954" d="M-20.833-125h-.59v-1.769a1.769,1.769,0,0,1,.518-1.251,1.769,1.769,0,0,1,1.251-.518,1.769,1.769,0,0,1,1.769,1.769V-125h-.59" transform="translate(21.423 128.533)" fill="none" stroke="#696a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                            </g>
                            <g id="g2956" transform="translate(13.523 12.278)">
                                <path id="path2958" d="M0-64.812v-1.835" transform="translate(0 66.646)" fill="none" stroke="#696a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                            </g>
                            <g id="g2960" transform="translate(4.089 15.882)">
                                <path id="path2962" d="M-41.665,0a1.18,1.18,0,0,0-1.179,1.179,1.18,1.18,0,0,0,1.179,1.179,1.18,1.18,0,0,0,1.179-1.179A1.18,1.18,0,0,0-41.665,0Z" transform="translate(42.844)" fill="none" stroke="#696a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                            </g>
                            <g id="g2964" transform="translate(5.268 12.278)">
                                <path id="path2966" d="M0-127.311v-3.6" transform="translate(0 130.914)" fill="none" stroke="#696a6a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default PrimaryProviderIcon
import { Form, Formik } from 'formik';
import React from 'react';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import { useSelector } from 'react-redux';

import { componentKey as usersComponentKey } from './UsersSlice';

import { componentKey } from '../../../../Wrappers/Dataloader/DataloaderSlice';
import { FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER } from './constant';
import TextArea from '../../../../../components/common-components/textArea/TextArea';
import DatePicker from '../../../../../components/common-components/datePicker';
import { getValidationSchema } from '../../../../../libs/formsUtils';
import General from '../../../../../libs/utility/General';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import Icons from '../../../../../components/icons/Icons';
import Upload from '../../../../../components/common-components/upload/Upload';
import Input from '../../../../../components/common-components/input/Input';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import Heading from '../../../../../components/common-components/heading/Heading';
import { MET_COLORS } from '../../../../../libs/constant';
const ViewProfileModal = ({ close, providerUsersData }) => {
	const fields = [
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.FIRSTNAME, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LASTNAME, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EMAIL, isRequired: true, isEmail: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ROLE, isDropdown: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.GENDER, isDropdown: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_1, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.CITY, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.STATE, isDropdown: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.WORK_LOCATION, isMultiSelect: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENSED_STATES, isMultiSelect: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LANGUAGES_SPOKEN, isMultiSelect: true },
		{ fieldName: FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.SPECIALITY, isMultiSelect: true },
	];
	const {
		allProvidersWorkLocations,

		viewUserModal,
	} = useSelector((state) => state[usersComponentKey]);

	const { indianStates,providersList } = useSelector((state) => state[componentKey]);
	const validationSchema = getValidationSchema(fields);

	const specialityData = providerUsersData?.provider_speciality_intermediate?.map((item, idx) => item?.speciality);
	const specialities = specialityData?.map((item) => item?.name);
	const dept = providerUsersData?.provider_department_intermediate?.map((item) => item?.Department);
	const departments = dept?.map((item) => item?.departmentName);
	const languagesSpoken = providerUsersData?.languagesSpoken.map((item) => {
		return item;
	});
	const licensedStates = providerUsersData?.licensedStates.map((item) => item);
	const work_locations = providerUsersData?.provider_location_intermediate?.map(
		(item, index) => item?.providerLocationId
	);
	return (
		<Formik
			initialValues={{
				// ...providerproviderUsersData,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.FIRSTNAME]: providerUsersData?.firstName,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LASTNAME]: providerUsersData?.lastName,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EMAIL]: providerUsersData?.email,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ROLE]: General.getLabelValue(providerUsersData?.role),
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_TYPE]: General.getLabelValue(
					providerUsersData?.providerType
				),
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.SPECIALITY]: providerUsersData?.speciality || [],
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.GENDER]: General.getLabelValue(providerUsersData?.gender),
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DOB]: providerUsersData?.dob,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PHONE_NUMBER]: providerUsersData?.phoneNumber,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.OFFICE_FAX_NUMBER]: providerUsersData?.officeFaxNumber,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LANGUAGES_SPOKEN]: providerUsersData?.languagesSpoken || [],
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.YEARS_OF_EXPERIENCE]: providerUsersData?.yearsOfExperience,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DEPARTMENT]: dept || [],
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENSED_STATES]: providerUsersData?.licensedStates || [],
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_NUMBER]: providerUsersData?.licenceNumber,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_EXPIRE_DATE]: providerUsersData?.licenceExpireDate,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.BIO]: providerUsersData?.bio,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EXPERTIES_IN]: providerUsersData?.expertiesIn,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EDUCATION_WORK_EXPERIENCE]:
					providerUsersData?.educationWorkExperience,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.NMC_ID]: providerUsersData?.nmcId,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_1]: providerUsersData?.Address[0]?.addressLine1,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_2]: providerUsersData?.Address[0]?.addressLine2,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.CITY]: providerUsersData?.Address[0]?.city,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.STATE]: General.getLabelValue(
					providerUsersData?.Address[0]?.state
				),
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.COUNTTRY]: providerUsersData?.Address[0]?.country,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ZIP]: providerUsersData?.Address[0]?.zip,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.WORK_LOCATION]: work_locations || [],
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_PROFILE_PIC]: providerUsersData?.profile_pic,
				[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_ID] : General.getLabelValue(providerUsersData?.assigneeProviders?.uuid)
			}}
			validationSchema={validationSchema}
			enableReinitialize
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={viewUserModal}
							validationSchema={validationSchema}
							title={'User Information'}
							close={close}
							icon={<Icons iconName="closeIcon" />}
							customClasses="w-[80%]"
							enableReinitialize
						>
							<div className="flex-column">
								<div className="flex justify-between flex-wrap">
									<div className="w-[10%]">
										<Upload
											subTitle="Upload"
											customClass="w-[157px] h-[157px]"
											url={providerUsersData?.profilePicture}
											icon={<Icons iconName="fileUpload" color={MET_COLORS.DARK_GRAY} />}
											name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_PROFILE_PATH}
											value={
												values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_PROFILE_PATH] ||
												''
											}
											disabled
										/>
									</div>
									<div className="w-[85%]">
										<div
											className=" flex column-gap-10px flex-wrap  p-4"
											style={{ rowGap: '10px' }}
										>
											{providerUsersData?.role !== 'Biller' &&  providerUsersData?.role !== "Front Desk" &&(
												<>
													<div className="w-[32.5%]">
														<Input
															label="Provider Type"
															placeholder=""
															name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_TYPE}
															value={
																values[
																	FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER
																		.PROVIDER_TYPE
																].label
															}
															disabled
														/>
													</div>
													<div className="w-[32.5%]">
														<Input
															label=" Speciality"
															placeholder=""
															name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.SPECIALITY}
															value={specialities}
															disabled
														/>
													</div>
												</>
											)}
											<div className="w-[32.5%]">
												<Input
													label="Role"
													placeholder="Enter first name"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ROLE}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ROLE].label}
													disabled
												/>
											</div>
											
											{ providerUsersData?.role === "Front Desk" &&<div className="w-[32.5%]">
												<SelectDropdown
													label="Provider"
													options={providersList}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_ID}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PROVIDER_ID]}
													disabled													
												/>
											</div>}
											<div className="w-[32.5%]">
												<Input
													label="First Name"
													placeholder="Enter first name"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.FIRSTNAME}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.FIRSTNAME]}
													// onChangeCb={(e) => {
													// 	setFieldValue(
													// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.FIRSTNAME,
													// 		e.target.value
													// 	);
													// }}
													disabled
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Last Name"
													placeholder="Enter Last Name"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LASTNAME}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LASTNAME]}
													disabled
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Gender"
													placeholder="Select"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.GENDER}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.GENDER].label}
													disabled
												/>
											</div>
											<div className="w-[32.5%]">
												<DatePicker
													label="Date of Birth"
													placeholder="Select"
													isRequired={false}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DOB]}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DOB}
													// onChangeCb={(date) => {
													// 	setFieldValue(
													// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DOB,
													// 		date
													// 	);
													// }}
													disabled
												/>
											</div>
											{ providerUsersData?.role !== "Front Desk" && providerUsersData?.role !== "Biller" &&  (
											<div className="w-[32.5%]">
												<Input
													label="NMC ID"
													isRequired={false}
													placeholder="Enter Number"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.NMC_ID}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.NMC_ID]}
													disabled
												/>
											</div>)}
											{	providerUsersData?.role !== "Front Desk" && (
											<div className="w-[32.5%]">
												<Input
													label="Contact Number"
													isRequired={false}
													placeholder="Enter Phone Number"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PHONE_NUMBER}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PHONE_NUMBER]}
													disabled
												/>
											</div>)}
											{ providerUsersData?.role !== "Front Desk" &&   providerUsersData?.role !== "Biller" &&(
											<div className="w-[32.5%]">
												<Input
													label="Office Fax Number"
													isRequired={false}
													placeholder="Enter Fax Number"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.OFFICE_FAX_NUMBER}
													value={
														values[
														FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.OFFICE_FAX_NUMBER
														]
													}
													disabled
												/>
											</div>)}
											{ providerUsersData?.role == "Front Desk" && (<div className="w-[32.5%]">
												<Input
													label="Contact Number"
													placeholder=""
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DEPARTMENT}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.PHONE_NUMBER]}
													disabled
												/>
											</div>)}
											<div className="w-[32.5%]">
												<Input
													label="Email"
													placeholder="Enter email"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EMAIL}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EMAIL]}
													disabled
												/>
											</div>
											{providerUsersData?.role !== 'Biller' &&  providerUsersData?.role !== "Front Desk" &&(
												<>
													<div className="w-[32.5%]">
														<Input
															label="Language Spoken"
															placeholder="Language Spoken"
															name={
																FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LANGUAGES_SPOKEN
															}
															value={languagesSpoken}
															disabled
														/>
													</div>
													<div className="w-[32.5%]">
														<Input
															label="Year of Experienc"
															isRequired={false}
															placeholder="Enter "
															name={
																FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.YEARS_OF_EXPERIENCE
															}
															value={
																values[
																	FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER
																		.YEARS_OF_EXPERIENCE
																]
															}
															// onChangeCb={(e) => {
															// 	setFieldValue(
															// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.YEARS_OF_EXPERIENCE,
															// 		General.sanitizeNumbers(e.target.value)
															// 	);
															// }}
															disabled
														/>
													</div>
												</>
											)}
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Work Locations"
													isRequired={false}
													isMultiSelect={true}
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.WORK_LOCATION}
													value={
														values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.WORK_LOCATION] ||
														[]
													}
													onChangeCb={(val) => {
														const values = val.map((item) => item?.value);
														setFieldValue(
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.WORK_LOCATION,
															values
														);
													}}
													options={allProvidersWorkLocations}
													disabled
												/>
											</div>
											{ providerUsersData?.role !== "Front Desk" && providerUsersData?.role !== 'Biller' &&   (<div className="w-[32.5%]">
												<Input
													label="Department"
													placeholder=""
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.DEPARTMENT}
													value={departments}
													disabled
												/>
											</div>)}
											<div className="w-[32.5%]">
												<Input
													label="Address 1"
													placeholder="Address 1"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_1}
													value={
														values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_1]
													}
													// onChangeCb={(e) => {
													// 	setFieldValue(
													// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_1,
													// 		e.target.value
													// 	);
													// }}
													disabled
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Address 2"
													isRequired={false}
													placeholder="Address 2"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_2}
													value={
														values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_2]
													}
													// onChangeCb={(e) => {
													// 	setFieldValue(
													// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ADDRESS_LINE_2,
													// 		e.target.value
													// 	);
													// }}
													disabled
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="City"
													placeholder="City"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.CITY}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.CITY]}
													// onChangeCb={(e) => {
													// 	setFieldValue(
													// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.CITY,
													// 		e.target.value
													// 	);
													// }}
													disabled
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="State"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.STATE}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.STATE]}
													// onChangeCb={(val) => {
													// 	setFieldValue(
													// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.STATE,
													// 		val
													// 	);
													// }}
													options={indianStates}
													disabled
												/>
											</div>{' '}
											<div className="w-[32.5%]">
												<Input
													label="Country"
													isRequired={false}
													placeholder="Country"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.COUNTTRY}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.COUNTTRY]}
													// onChangeCb={(e) => {
													// 	setFieldValue(
													// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.COUNTTRY,
													// 		e.target.value
													// 	);
													// }}
													disabled
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Zip Code"
													placeholder="Zip Code"
													name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ZIP}
													value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ZIP]}
													// onChangeCb={(e) => {
													// 	setFieldValue(
													// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.ZIP,
													// 		e.target.value
													// 	);
													// }}
													disabled
												/>
											</div>
											{providerUsersData?.role !== 'Biller' &&  providerUsersData?.role !== "Front Desk" &&(
												<>
													<div className="w-[32.5%]">
														<Input
															label="Licence State"
															value={licensedStates}
															name={
																FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENSED_STATES
															}
															disabled
														/>
													</div>
													<div className="w-[32.5%]">
														<Input
															label="Licence Number"
															isRequired={false}
															placeholder="Enter  Number"
															name={
																FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_NUMBER
															}
															value={
																values[
																	FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER
																		.LICENCE_NUMBER
																]
															}
															// onChangeCb={(e) => {
															// 	setFieldValue(
															// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_NUMBER,
															// 		e.target.value
															// 	);
															// }}
															disabled
														/>
													</div>
													<div className="w-[32.5%]">
														<DatePicker
															label="Licence Expiry Date"
															placeholder="Enter Date"
															isRequired={false}
															name={
																FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_EXPIRE_DATE
															}
															value={
																values[
																	FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER
																		.LICENCE_EXPIRE_DATE
																]
															}
															// onChangeCb={(date) => {
															// 	setFieldValue(
															// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.LICENCE_EXPIRE_DATE,
															// 		date
															// 	);
															// }}
															disabled
														/>
													</div>
												</>
											)}
										</div>
									</div>
									{providerUsersData?.role !== 'Biller' &&  providerUsersData?.role !== "Front Desk" &&(
										<div className="w-full">
											<Heading type={HEADING.H1} color="met-primary" fontSize={'md'}>
												Basic Account Profile Data
											</Heading>
											<div className="flex flex-col w-full p-6 mt-2 ">
												<div>
													<TextArea
														label="Provider Bio"
														placeholder="Enter Bio..."
														customClasses="mt-2"
														name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.BIO}
														value={values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.BIO]}
														// onChangeCb={(e) => {
														// 	setFieldValue(
														// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.BIO,
														// 		e.target.value
														// 	);
														// }}
														disabled
													/>
												</div>
												<div className="mt-6">
													<TextArea
														label="Expertise In"
														placeholder="Enter Expertise"
														customClasses="mt-2"
														name={FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EXPERTIES_IN}
														value={
															values[FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EXPERTIES_IN]
														}
														// onChangeCb={(e) => {
														// 	setFieldValue(
														// 		FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EXPERTIES_IN,
														// 		e.target.value
														// 	);
														// }}
														disabled
													/>
												</div>
												<div className="mt-6">
													<TextArea
														label="Education, Work Experience"
														placeholder="Enter Education, Work Experience"
														customClasses="mt-2"
														name={
															FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER.EDUCATION_WORK_EXPERIENCE
														}
														value={
															values[
																FORM_FIELDS_NAMES_FOR_ADD_PROVIDER_USER
																	.EDUCATION_WORK_EXPERIENCE
															]
														}
														disabled
													/>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</ModalComponent>
					</Form>
				);
			}}
		</Formik>
	);
};
export default ViewProfileModal;

import React from 'react';
import PatientBillingTable from './PatientBillingTable';
import Heading from '../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../components/common-components/heading/Constants';

const Billing = () => {
	return (
		<>
			<div className='flex items-center justify-between p-2 px-4'>
				<Heading fontSize="xl" fontWeight="bold" type={HEADING.H3}>
					Billing
				</Heading>
			</div>
			{/* <div className="flex pt-7 column-gap-20px flex-wrap">
				<div className="flex-1 flex-wrap">
					<BillingCard />
				</div>
				<div className="flex-1 flex-wrap">{<BillingTable />}</div>
			</div> */}
			<div className="flex-1 flex-wrap">{<PatientBillingTable />}</div>
		</>
	);
};

export default Billing;

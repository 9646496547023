import React from 'react';

const TranslateIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" id="translate_black_24dp" width="24" height="24" viewBox="0 0 24 24">
			<path id="Path_205444" data-name="Path 205444" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_205445"
				data-name="Path 205445"
				d="M12.87,15.07l-2.54-2.51.03-.03A17.519,17.519,0,0,0,14.07,6H17V4H10V2H8V4H1V5.99H12.17A15.713,15.713,0,0,1,9,11.35,15.645,15.645,0,0,1,6.69,8h-2a17.562,17.562,0,0,0,2.98,4.56L2.58,17.58,4,19l5-5,3.11,3.11.76-2.04ZM18.5,10h-2L12,22h2l1.12-3h4.75L21,22h2Zm-2.62,7,1.62-4.33L19.12,17H15.88Z"
				fill="#1b5984"
			/>
		</svg>
	);
};

export default TranslateIcon;

import React from 'react'

const LogoutIcon = () => {
    return (
        <svg id="settings_power_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="Path_204224" data-name="Path 204224" d="M0,0H24V24H0Z" fill="none" />
            <path id="Path_204225" data-name="Path 204225" d="M13,2H11V12h2Zm3.56,2.44L15.11,5.89a5.987,5.987,0,1,1-6.23-.01L7.44,4.44a8,8,0,1,0,9.12,0Z" transform="translate(0 2)" fill="rgba(26,26,26,0.5)" />
        </svg>
    )
}

export default LogoutIcon
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../../components/common-components/modal/ModalComponent';
import Icons from '../../../../components/icons/Icons';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import Label from '../../../../components/common-components/label/Label';
import ToggleButton from '../../../../components/common-components/toggleButton/ToggleButton';
import TextArea from '../../../../components/common-components/textArea/TextArea';
import Input from '../../../../components/common-components/input/Input';
import DatePicker from '../../../../components/common-components/datePicker';
import { Form, Formik } from 'formik';
import { getValidationSchema } from '../../../../libs/formsUtils';
import { FORM_FIELDS_NAMES } from './Constants';
import { componentKey as PatientDiagnosesComponentKey } from '../Diagnoses/PatientDiagnosesSlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import {
	postPatientDiagnoses,
	updatePatientDiagnoses,
} from '../Diagnoses/PatientDiagnosesSaga';

const fields = [
	{ fieldName: FORM_FIELDS_NAMES.DIAGNOSES_NAME, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.ONSET_DATE, isRequired: true },
];

const validationSchema = getValidationSchema(fields);

function DiagnosesModal({ open, close }) {
	const dispatch = useDispatch();
	const { isDiagnosesModalOpen, diagnosesData, currentDiagnosesId, isNewDiagnosesAdd } = useSelector(
		(state) => state[PatientDiagnosesComponentKey]
	);

	const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);
	return (
		<Formik
			initialValues={{
				[FORM_FIELDS_NAMES.DIAGNOSES_NAME]: diagnosesData?.diagnosesName,
				[FORM_FIELDS_NAMES.ONSET_DATE]: diagnosesData?.onsetDate,
				[FORM_FIELDS_NAMES.STATUS]: diagnosesData?.status ? diagnosesData?.status : 'Active',
				[FORM_FIELDS_NAMES.TYPE]: diagnosesData?.type ? diagnosesData?.type : 'Acute',
				[FORM_FIELDS_NAMES.NOTE]: diagnosesData?.note || '',
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(formVals) => {
				try {
					if (isNewDiagnosesAdd) {
						dispatch(postPatientDiagnoses({ patientId: currentPatientId, diagnosesData: formVals }));
					} else {
						dispatch(
							updatePatientDiagnoses({
								patientId: currentPatientId,
								diagnosesId: currentDiagnosesId,
								diagnosesData: formVals,
							})
						);
					}
				} catch (error) {
					console.log(error);
				}
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<div>
							<ModalComponent
								open={isDiagnosesModalOpen}
								title={isNewDiagnosesAdd ? 'Add Diagnoses' : 'Update Diagnoses'}
								footerButton={
									<Button
										variant={BUTTON_VARIANTS.CONTAINED}
										customBtnClass="text-met-primary"
										type="submit"
									>
										Save
									</Button>
								}
								icon={<Icons iconName="closeIcon" />}
								close={close}
								customClasses="w-[869px]"
							>
								<div className="p-2">
									<div className="pt-6">
										<Input
											label="Diagnoses Name"
											isRequired={true}
											placeholder="Enter Diagnoses Name"
											name={FORM_FIELDS_NAMES.DIAGNOSES_NAME}
											value={values[FORM_FIELDS_NAMES.DIAGNOSES_NAME]}
											onChangeCb={(e) => {
												setFieldValue(FORM_FIELDS_NAMES.DIAGNOSES_NAME, e.target.value);
											}}
										/>
									</div>
									<div className="flex pt-6">
										<div className="flex-1">
											<div>
												<Label>Status</Label>
											</div>
											<div>
												<ToggleButton
													onChangeCb={(option) => {
														setFieldValue(FORM_FIELDS_NAMES.STATUS, option.value);
													}}
													value={values[FORM_FIELDS_NAMES.STATUS]}
													options={[
														{ label: 'Active', value: 'Active' },
														{ label: 'Historical', value: 'Historical' },
													]}
												/>
											</div>
										</div>
										<div className="flex-1">
											<div>
												<Label>Type</Label>
											</div>
											<div>
												<ToggleButton
													onChangeCb={(option) => {
														setFieldValue(FORM_FIELDS_NAMES.TYPE, option.label);
													}}
													value={values[FORM_FIELDS_NAMES.TYPE]}
													options={[
														{ label: 'Acute', value: 'Acute' },
														{ label: 'Chronic', value: 'Chronic' },
													]}
												/>
											</div>
										</div>
									</div>
									<div className="flex justify-between">
										<div className="width-40">
											<div className="mt-4">
												<DatePicker
													label="Onset Date"
													placeholder="Choose Date"
													isRequired={true}
													maxDate={new Date()}
													value={values[FORM_FIELDS_NAMES.ONSET_DATE]}
													name={FORM_FIELDS_NAMES.ONSET_DATE}
													onChangeCb={(date) => {
														setFieldValue(FORM_FIELDS_NAMES.ONSET_DATE, date);
													}}
												/>
											</div>
										</div>
									</div>
									<div>
										<div className="mt-4">
											<Label fontWeight="bold">Note</Label>
										</div>
										<div className="mt-2">
											<TextArea
												placeholder="Type here"
												name={FORM_FIELDS_NAMES.NOTE}
												value={values[FORM_FIELDS_NAMES.NOTE]}
												onChangeCb={(e) => {
													setFieldValue(FORM_FIELDS_NAMES.NOTE, e.target.value);
												}}
											/>
										</div>
									</div>
								</div>
							</ModalComponent>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
}

export default DiagnosesModal;

import store from '../../../../store/store';
import { all, put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import { componentKey, setAllCurrentMedicationsData, setAllPastMedicationsData, setDiagnosesData, setIsMedicationsModalOpen, setIsOpenMedicationDeletePopup } from './PatientMedicationsSlice';
import PatientDashboardDataService from '../../../../services/PatientDashboardDataService';
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/Constants';

export const {
	getAllPatientMedicationsDetails,
	postPatientMedications,
	updatePatientMedications,
	deletePatientMedications
} = {
	getAllPatientMedicationsDetails: (payload) => {
		return {
			type: 'PATIENTDASHBOARD/MEDICATIONS',
			payload,
		};
	},
	postPatientMedications: (payload) => {
		return {
			type: 'POST/PATIENTDASHBOARD/MEDICATIONS',
			payload,
		};
	},
	updatePatientMedications: (payload) => {
		return {
			type: 'UPDATE/PATIENTDASHBOARD/MEDICATIONS',
			payload,
		};
	},
	deletePatientMedications: (payload) => {
		return {
			type: 'DELETE/PATIENTDASHBOARD/MEDICATIONS',
			payload,
		};
	},
};

function* getAllPatientMedicationsDetailsAsync(action) {
	try {
		const { patientId = '', type = 'current', page = '1', limit = '10' } = action.payload;
		const params = {
			pageNumber: page,
			limit: limit,
			type,
		};

		const response = yield PatientDashboardDataService.getAllPatientMedications(
			'medication/patient',
			patientId,
			params
		);

		const { message, status, data } = response?.data || {};

		if (status) {
			if (type === 'current') {
				const currentMedication = data?.medications.map((medication, index) => {
					return {
						...medication, index
					}
				})
				yield put(setAllCurrentMedicationsData(currentMedication));
			}
			else {
				const pastMedication = data?.medications.map((medication, index) => {
					return {
						...medication, index
					}
				})
				yield put(setAllPastMedicationsData(pastMedication));
			}

		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* postPatientMedicationsAsync(action) {
	try {
		const { patientId, medicationData } = action.payload;
		const response = yield PatientDashboardDataService.postPatientMedications('medication', patientId, medicationData);
		const { message, status, data } = response?.data || {};
		if (status) {
			yield put(setIsMedicationsModalOpen(false));
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			yield call(getAllPatientMedicationsDetailsAsync,
				getAllPatientMedicationsDetails({ patientId }));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* updatePatientMedicationsDetailsAsync(action) {
	try {
		const { patientId = '', medicationId = '', medicationData = {} } = action.payload;

		const response = yield PatientDashboardDataService.updatePatientMedications(
			'medication',
			medicationId,
			patientId,
			medicationData
		);

		const { message, status, data } = response?.data || {};

		if (status) {
			yield put(setIsMedicationsModalOpen(false));
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			yield call(getAllPatientMedicationsDetailsAsync,
				getAllPatientMedicationsDetails({ patientId })
			);
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* deletePatientMedicationsDetailsAsync(action) {
	try {
		const { patientId = '', medicationId = '' } = action.payload;

		const response = yield PatientDashboardDataService.deletePatientMedications('medication', medicationId, patientId);

		const { message, status, data } = response?.data || {};

		if (status) {
			yield put(setIsOpenMedicationDeletePopup(false))
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			yield call(getAllPatientMedicationsDetailsAsync,
				getAllPatientMedicationsDetails({ patientId }));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}


function* rootSaga() {
	yield all([
		takeEvery(getAllPatientMedicationsDetails().type, getAllPatientMedicationsDetailsAsync),
		takeLatest(postPatientMedications().type, postPatientMedicationsAsync),
		takeLatest(updatePatientMedications().type, updatePatientMedicationsDetailsAsync),
		takeLatest(deletePatientMedications().type, deletePatientMedicationsDetailsAsync)
	]);
}

store.sagaManager.addSaga(componentKey, rootSaga);
import store from "../../../../../store/store";
export const componentKey = 'PROVIDER_GROUP_PROFILE'

const initialState = {
    providerGroupDetails: {},
    allProviderGroupLocation: []
};

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setProviderGroupDetails: (state, action) => {
            state.providerGroupDetails = action.payload;
        },
        setAllProviderGroupLocation: (state, action) => {
            state.allProviderGroupLocation = action.payload;
        }
    },
    initialReducerState: initialState
});

export const { setProviderGroupDetails, setAllProviderGroupLocation } = actions
import React from 'react'
import General from '../../../../libs/utility/General'

const GST_TYPES = {
    IGST: "IGST",
    SGST: "SGST",
    CGST: 'CGST',
}

function GstTypeAndValues({ gstType, gstRate, amount }) {
    if (!amount && !gstRate) return null
    
    return (
        <>
            {gstType === GST_TYPES.IGST ? <div className='flex mt-2 mr-3'>
                <div className='flex-1 2 text-rgba-1a1a1acc  fs-18 font-semibold'>{gstType} {gstRate} %</div>
                <div className='text-met-primary fs-18 ml-6 font-semibold'>{General.calculateGst(amount, gstRate)}</div>
            </div> :
                (
                    <>
                        <div className='flex mt-2 mr-3'>
                            <div className='flex-1 2 text-rgba-1a1a1acc  fs-18 font-semibold'>{GST_TYPES.SGST} {Number(gstRate) / 2}%</div>
                            <div className='text-met-primary fs-18 ml-6 font-semibold'>{General.calculateGst(amount, (gstRate / 2))}</div>
                        </div>
                        <div className='flex mt-2 mr-3'>
                            <div className='flex-1 2 text-rgba-1a1a1acc  fs-18 font-semibold'>{GST_TYPES.CGST} {Number(gstRate) / 2}%</div>
                            <div className='text-met-primary fs-18 ml-6 font-semibold'>{General.calculateGst(amount, (gstRate / 2))}</div>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default GstTypeAndValues
import store from "../../../store/store"

export const componentKey = 'PATIENT_GRID_LIST'

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setPatientGridListState: (state, action) => {
            state.patientGridListState = action.payload
        },
        setPaginationState: (state, action) => {
            state.paginationState = { ...state.paginationState, ...action.payload }
        },
        setSelectedPatientList: (state, action) => {
            state.selectedPatientList = action.payload
        }
    },
    initialReducerState: {
        patientGridListState: [],
        paginationState: { pageNumber: 1, limit: 10, total: 0, search: "", status: "All", orderBy: "desc", sortBy: "" },
        selectedPatientList: []
    }
})

export const { setPatientGridListState, setPaginationState, setSelectedPatientList } = actions
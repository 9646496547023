import store from '../../../store/store';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { componentKey, setPatientData } from './PatientDashboardSlice';
import PatientDataService from '../../../services/PatientDataServices';

export const {
    getPatientDetails,
} = {
    getPatientDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/PATIENTDETAILS',
            payload,
        };
    },
};

function* getPatientDetailsAsync(action) {
    try {
        const { patientId = '', page = '1', limit = '10' } = action.payload;
        const params = {
            page: page,
            limit: limit,
        };

        const response = yield PatientDataService.getPatientDetails(
            patientId,
            params
        );

        const { message, status, data } = response?.data || {};
        if (status) {
            yield put(setPatientData(data));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getPatientDetails().type, getPatientDetailsAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

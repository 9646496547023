import React from 'react';
function CheckCircleIcon({ color = '' }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="check_circle_black_24dp_1_"
			data-name="check_circle_black_24dp (1)"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path id="Path_206967" data-name="Path 206967" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_206968"
				data-name="Path 206968"
				d="M9.449,2A7.449,7.449,0,1,0,16.9,9.449,7.451,7.451,0,0,0,9.449,2Zm0,13.407a5.959,5.959,0,1,1,5.959-5.959A5.967,5.967,0,0,1,9.449,15.407Zm3.419-9.251L7.959,11.065,6.03,9.143l-1.05,1.05,2.979,2.979,5.959-5.959Z"
				transform="translate(2.551 2.551)"
				fill="#00b917"
			/>
		</svg>
	);
}

export default CheckCircleIcon;

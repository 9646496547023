
import { all, put, takeLatest } from 'redux-saga/effects';
import store from '../../store/store';
import { componentKey, setDefaultPrintConfig } from './MetPrintModalSlice';
import PrintConfigurationsDataService from '../../services/PrintConfigurationsDataService';

export const { getDefaultPrintConfig } = {
    getDefaultPrintConfig: (payload) => {
        return {
            type: 'MET_PRINT_CONFIG/GET_DEFAULT_PRINT_CONFIG',
            payload
        }
    }
};

function* getPrintConfigDetailsAsync(action) {
    try {
        const response = yield PrintConfigurationsDataService.getPrintConfigDetails(action.payload)
        const { data, status } = response.data
        if (status) {
            const defaultPrintConfig = data.find(config => config.default)
            yield put(setDefaultPrintConfig(defaultPrintConfig))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}


function* rootSaga() {
    yield all([
        takeLatest(getDefaultPrintConfig().type, getPrintConfigDetailsAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

import { useDispatch, useSelector } from "react-redux"
import Label from "../../../components/common-components/label/Label"
import { componentKey as PatientEncounterComponentKey, setIsEditAppointmentModalOpen } from "../EncounterSlice"
import WrapperCard from "../../../components/common-components/Cards/WrapperCard/WrapperCard"
import moment from "moment"
import Icons from "../../../components/icons/Icons"
import EditAppointmentModal from "./EditAppointmentModal"
import { componentKey as SchedulingComponentKey } from '../../Scheduling/ShedulingSlice';
import { useEffect } from "react"
import { getProviderLocations } from "../EncounterSaga"
import { getFormattedAge } from "../../../libs/patientUtils"

const AppointmentDetailsCard = () => {
    const { isEditAppointmentModalOpen } = useSelector(state => state[PatientEncounterComponentKey])
    const { checkedInData } = useSelector((state) => state[SchedulingComponentKey]);
    const dispatch = useDispatch()

    const dob = checkedInData?.Patient?.dob || ""

    useEffect(() => {
        if (checkedInData?.Provider?.uuid) {
            dispatch(getProviderLocations({ providerId: checkedInData?.Provider?.uuid }))
        }
    }, [checkedInData?.Provider?.uuid,dispatch])

    return <WrapperCard>
        <div className="flex flex-col p-4 row-gap-10">
            <div className="flex justify-between">
                <Label fontSize="lg" fontWeight='bold'>APPOINTMENT DETAILS</Label>
                <div className="flex bg-blue-100 cursor-pointer rounded-lg h-[26px] px-2" onClick={() => dispatch(setIsEditAppointmentModalOpen(true))}>
                    <Icons iconName='editIcon'></Icons>
                    <div className="text-met-primary px-2 py-0">
                        Edit
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="flex flex-col mr-[40px] row-gap-10">
                    <div className="flex">
                        <div className="text-gray-500">Appointment Date & Time</div>
                        <div className="text-gray-500 mx-2">:</div>
                        <div>{checkedInData && checkedInData.date ? `${moment(checkedInData.date).format('ddd, MMMM D, YYYY')} & ${checkedInData.start}` : '-'}</div>
                    </div>
                    <div className="flex">
                        <div className="text-gray-500">Age at Encounter</div>
                        <div className="text-gray-500 mx-2">:</div>
                        <div>
                            {dob ? getFormattedAge(dob) : "-"}</div>
                    </div>
                </div>
                <div className="flex flex-col mr-[40px] row-gap-10">
                    <div className="flex">
                        <div className="text-gray-500">Appointment Type</div>
                        <div className="text-gray-500 mx-2">:</div>
                        <div>{checkedInData && checkedInData.Appointment ? checkedInData.Appointment.appointmentType : '-'}</div>
                    </div>
                    <div className="flex">
                        <div className="text-gray-500">Location</div>
                        <div className="text-gray-500 mx-2">:</div>
                        <div>{checkedInData && checkedInData.Locations ? checkedInData.Locations.name : '-'}</div>
                    </div>
                </div>
                <div className="flex flex-col row-gap-10">
                    <div className="flex">
                        <div className="text-gray-500">Rendering Provider</div>
                        <div className="text-gray-500 mx-2">:</div>
                        <div>{checkedInData && checkedInData.Provider ? checkedInData.Provider.firstName + checkedInData.Provider.lastName : '-'}</div>
                    </div>
                    <div className="flex">
                        <div className="text-gray-500">POS</div>
                        <div className="text-gray-500 mx-2">:</div>
                        <div>{checkedInData && checkedInData.placeOfService ? checkedInData.placeOfService : '-'}</div>
                    </div>
                </div>
            </div>
        </div>
        {isEditAppointmentModalOpen ? <EditAppointmentModal close={() => dispatch(setIsEditAppointmentModalOpen(false))} /> : null}
    </WrapperCard>
}

export default AppointmentDetailsCard
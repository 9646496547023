import React from 'react';
import DiagonosesCard from './FaceSheet/DiagonosesCard';
import MedicationsCard from './FaceSheet/MedicationsCard';
import FamilyHistoryCard from './FaceSheet/FamilyHistoryCard';
import PastMedicalHistoryCard from './FaceSheet/PastMedicalHistoryCard';
import AlleriesCard from './FaceSheet/AlleriesCard';
import PastSurgicalHistorycard from './FaceSheet/PastSurgicalHistorycard';
import SocialHistoryCard from './FaceSheet/SocialHistoryCard';
import UpcomingAppointmentCard from './FaceSheet/UpcomingAppointmentCard';
import EncountersCard from './FaceSheet/EncountersCard';

function DashBoardCards() {
	return (
		<div className='absolute inset-0 p-4'>
			<div className="flex flex-wrap column-gap-10px row-gap-10">
				<div className='flex justify-between w-full column-gap-10px flex-wrap row-gap-10'>
					<div className='flex-1'>
						<DiagonosesCard />
					</div>
					<div className='flex-1'>
						<AlleriesCard />
					</div>
					{/* <div className='flex-1'>
						<UpcomingAppointmentCard />
					</div> */}
					<div className='h-auto flex-1 flex flex-wrap row-gap-10 column-gap-10px'>
						<div className='flex-1'>
							<MedicationsCard />
						</div>
						{/* TODO: we are not showing the family history */}
						{/* <div className='flex-1'>
							<FamilyHistoryCard />
						</div> */}
						<div className='flex-1'>
							<PastMedicalHistoryCard />
						</div>
						<div className='flex-1'>
							<PastSurgicalHistorycard />
						</div>
					</div>
					{/* TODO: we are not showing the social history */}
					{/* <div className='flex-1'>
						<SocialHistoryCard />
					</div> */}
					{/* <div className='flex-1'>
						<EncountersCard />
					</div> */}
				</div>
			</div>
		</div>
	);
}

export default DashBoardCards;

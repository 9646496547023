import React from 'react';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import Input from '../../../../../components/common-components/input/Input';
import Button from '../../../../../components/common-components/button/Button';
import { Form, Formik } from 'formik'
import { getValidationSchema } from '../../../../../libs/formsUtils';
import { CHANGE_PASSWORD_FIELDS_NAMES } from './Constants';
import * as Yup from 'yup';
import { VALIDATION_REGEX } from '../../../../../libs/constant';
import { useDispatch } from 'react-redux';
import { saveUpdatedPassword } from './ProviderProfileSaga';

const fields = [
	{ label: "Current Password", placeholder: "Enter Password", fieldName: CHANGE_PASSWORD_FIELDS_NAMES.CURRENT_PASSWORD, isRequired: true },
	{ label: "New Password", placeholder: "Enter Password", fieldName: CHANGE_PASSWORD_FIELDS_NAMES.NEW_PASSWORD, isRequired: true, isPassword: true, regexPattern: VALIDATION_REGEX.PASSWORD_REGEX },
	{ label: "Confirm New Password", placeholder: "Enter Password", fieldName: CHANGE_PASSWORD_FIELDS_NAMES.CONFIRM_NEW_PASSWORD, isRequired: true }
];

const validationSchema = getValidationSchema(fields)
validationSchema.fields[CHANGE_PASSWORD_FIELDS_NAMES.CONFIRM_NEW_PASSWORD] = Yup.string()
	.oneOf([Yup.ref(CHANGE_PASSWORD_FIELDS_NAMES.NEW_PASSWORD), null], 'Passwords must match')
	.required('Confirm Password is required')


const ChangePasswordModal = ({ open, close }) => {
	
	const dispatch = useDispatch()

	return <Formik
		initialValues={{
			[CHANGE_PASSWORD_FIELDS_NAMES.CURRENT_PASSWORD]: "",
			[CHANGE_PASSWORD_FIELDS_NAMES.NEW_PASSWORD]: "",
			[CHANGE_PASSWORD_FIELDS_NAMES.CONFIRM_NEW_PASSWORD]: "",
		}}
		validationSchema={validationSchema}
		enableReinitialize
		onSubmit={(values) => {
			dispatch(saveUpdatedPassword(values))
		}}
	>
		{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, ...props }) => {
			return <Form onSubmit={handleSubmit} >
				<ModalComponent customClasses='w-[35%]' customBodyClasses="h-[300px]" title="Change Password" close={close} open={open} footerButton={<Button type='submit'>Save</Button>}>
					<div className='flex flex-col justify-between h-full'>
						{fields.map((field, index) => (
							<div key={"change-password-field" + index}>
								<Input
									label={field.label}
									isRequired={field.isRequired}
									placeholder={field.placeholder}
									name={field.fieldName}
									type='password'
									value={values[field.fieldName]}
									onChangeCb={(e) => { setFieldValue(field.fieldName, e.target.value) }}
								/>
							</div>
						))}
					</div>
				</ModalComponent>
			</Form>
		}}
	</Formik >
}

export default ChangePasswordModal;

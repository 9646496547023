import store from '../../../store/store';
import { all, put, takeLatest } from 'redux-saga/effects';
import VitalsTestDataService from '../../../services/VitalsTestDataService';
import { componentKey, setPatientVitalsTestDetails } from './PatientVitalsSlice';

export const { getPatientVitalsTest } = {
    getPatientVitalsTest: (payload) => {
        return {
            type: 'GET_PATIENT_VITALS_TEST',
            payload,
        };
    },
};

function* getPatientVitalsTestAsync(action) {
    try {
        const { params } = action.payload
        const response = yield VitalsTestDataService.getAllVitalsTestDetails(params);
        const { data, status } = response.data;
        
        if (status) {
            yield put(setPatientVitalsTestDetails(data?.vitalTest[0]?.test));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getPatientVitalsTest().type, getPatientVitalsTestAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);
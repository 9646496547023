import React from 'react';
import { useSelector } from 'react-redux';
import { componentKey as PatientVitalsComponentKey } from "../../../Patients/PatientDashboard/Vitals/PatientVitalsSlice";
import FaceSheetCard from '../../../Patients/PatientDashboard/Dashboard/FaceSheet/FaceSheetCard';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import moment from 'moment';
import Icons from '../../../../components/icons/Icons';
import { MET_COLORS } from '../../../../libs/constant';

const VitalsCard = () => {
    const { allVitalsData } = useSelector((state) => state[PatientVitalsComponentKey])

    const handleCopyClick = () => {
        let copiedText = `Blood Pressure - ${allVitalsData[0]?.systolic}/${allVitalsData[0]?.daistolic} (mmhg) (${moment(allVitalsData[0]?.date).format('DD-MM-YYYY')})\nRespiration Rate - ${allVitalsData[0]?.respiratoryRate} (bpm) (${moment(allVitalsData[0]?.date).format('DD-MM-YYYY')})\nHeart Rate - ${allVitalsData[0]?.heartRate} (bpm) (${moment(allVitalsData[0]?.date).format('DD-MM-YYYY')})\nBody Mass Index - ${allVitalsData[0]?.BMI} (lbs) (${moment(allVitalsData[0]?.date).format('DD-MM-YYYY')})`
        navigator.clipboard.writeText(copiedText);
    }

    return (
        <FaceSheetCard
            title="VITALS"
            textColorClass={'text-met-primary'}
        >
            <div>
                {allVitalsData?.length ? <div className='flex flex-col gap-y-2'>
                    <div className="flex items-center mb-1">
                        <div className={`fs-14 mr-2`}> <Icons iconName={'bloodPressureIcon'} /></div>
                        <p className='w-[120px] fs-14'>{`Blood Pressure`}</p>
                        <p className='mr-4'>{'-'}</p>
                        {allVitalsData[0]?.systolic ? <p className="fs-14 text-gray-500">{`${allVitalsData[0]?.systolic}/${allVitalsData[0]?.daistolic} (mmhg)`}</p> : ''}
                        {allVitalsData[0]?.systolic ? <p className="text-gray-500 fs-14 ml-4">{`(${moment(allVitalsData[0]?.date).format('DD-MM-YYYY')})`}</p> : ''}
                    </div>
                    <div className="flex items-center mb-1">
                        <div className={`fs-14 mr-2`}> <Icons iconName={'respirationIcon'} /></div>
                        <p className='w-[120px] fs-14'>{`Respiration Rate`}</p>
                        <p className='mr-4'>{'-'}</p>
                        {allVitalsData[0]?.respiratoryRate ? <p className="fs-14 text-gray-500">{`${allVitalsData[0]?.respiratoryRate} (bpm)`}</p> : ''}
                        {allVitalsData[0]?.respiratoryRate ? <p className="text-gray-500 fs-14 ml-4">{`(${moment(allVitalsData[0]?.date).format('DD-MM-YYYY')})`}</p> : ''}
                    </div>
                    <div className="flex items-center mb-1">
                        <div className={`fs-14 mr-2`}> <Icons iconName={'heartRateIcon'} /></div>
                        <p className='w-[120px] fs-14'>{`Heart Rate`}</p>
                        <p className='mr-4'>{'-'}</p>
                        {allVitalsData[0]?.heartRate ? <p className="fs-14 text-gray-500">{`${allVitalsData[0]?.heartRate} (bpm)`}</p> : ''}
                        {allVitalsData[0]?.heartRate ? <p className="text-gray-500 fs-14 ml-4">{`(${moment(allVitalsData[0]?.date).format('DD-MM-YYYY')})`}</p> : ''}
                    </div>
                    <div className="flex items-center mb-1">
                        <div className={`fs-14 mr-2`}> <Icons iconName={'bodyMassIndex'} /></div>
                        <p className='w-[120px] fs-14'>{`Body Mass Index`}</p>
                        <p className='mr-4'>{'-'}</p>
                        {allVitalsData[0]?.BMI ? <p className="fs-14 text-gray-500">{`${allVitalsData[0]?.BMI} (lbs)`}</p> : ''}
                        {allVitalsData[0]?.BMI ? <p className="text-gray-500 fs-14 ml-4">{`(${moment(allVitalsData[0]?.date).format('DD-MM-YYYY')})`}</p> : ''}
                    </div>
                </div> : <div className='fs-14 text-gray-500'>No data found</div>}
            </div>
        </FaceSheetCard>
    );
};

export default VitalsCard;

import React from 'react'

function MoreVertIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="more_vert_black_24dp_1_" data-name="more_vert_black_24dp (1)" width="24" height="24" viewBox="0 0 24 24">
            <path id="Path_186519" data-name="Path 186519" d="M0,0H24V24H0Z" fill="none" />
            <path id="Path_186520" data-name="Path 186520" d="M12,8a2,2,0,1,0-2-2A2.006,2.006,0,0,0,12,8Zm0,2a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,10Zm0,6a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,16Z" fill="rgba(26,26,26,0.5)" />
        </svg>
    )
}

export default MoreVertIcon
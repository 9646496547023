import React from 'react';
import Icons from '../../icons/Icons';

function ModalComponent({
	title,
	footerButton,
	showIcon = true,
	icon = <Icons iconName="closeIcon" />,
	children,
	customClasses = '',
	close,
	open,
	customBodyClasses = '',
	subtitle,
	onClickCb,
	cutomFooterBtnClass = '',
	showEditIcon = false,
	editIcon,
	edit,
	hideOverflow = false,
	showDeleteIcon = false,
	deleteIcon,
	deleteClick,
}) {
	return (
		<>
			{open && (
				<div className="fixed flex-wrap top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-65 bg-no-repeat">
					<div className={`max-h-[95vh] ${customClasses}`}>
						<div className="bg-white shadow-md rounded-lg p-4 h-[100%] flex flex-col">
							<div className="flex justify-between items-center m-2">
								<div className="flex flex-col">
									<div className="fs-20 font-semibold">{title}</div>
									{subtitle && <div className="text-gray-500 fs-14">{subtitle}</div>}
								</div>
								{deleteIcon && (
									<div className="flex w-[30%] justify-between">
										{showEditIcon && (
											<div className="cursor-pointer print-hidden flex" onClick={edit}>
												{editIcon}
												<span className="text-met-primary ml-2">Edit</span>
											</div>
										)}
										{showDeleteIcon && (
											<>
												<div className="cursor-pointer print-hidden flex" onClick={deleteClick}>
													{deleteIcon}
													<span className="text-met-primary ml-2">Delete</span>
												</div>
											</>
										)}
										{showIcon && (
											<div className="cursor-pointer print-hidden" onClick={close}>
												{icon}
											</div>
										)}
									</div>
								)}
								{editIcon && !deleteIcon && (
									<div className="flex w-[10%] justify-between">
										{showEditIcon && (
											<div className="cursor-pointer print-hidden flex" onClick={edit}>
												{editIcon}
												<span className="text-met-primary ml-2">Edit</span>
											</div>
										)}

										{showIcon && (
											<div className="cursor-pointer print-hidden" onClick={close}>
												{icon}
											</div>
										)}
									</div>
								)}
								{showIcon && !editIcon && !deleteIcon && (
									<div className="cursor-pointer print-hidden" onClick={close}>
										{icon}
									</div>
								)}
							</div>

							{/* <div className={`overflow-auto met-scrollbar max-h-[80vh] p-2 ${customBodyClasses}`}>
								{children}
							</div> */}
							<div
								className={`overflow-${
									hideOverflow ? 'visible' : 'auto'
								} met-scrollbar max-h-[80vh] p-2 ${customBodyClasses}`}
							>
								{children}
							</div>

							<div className={`flex justify-end ${cutomFooterBtnClass}`} onClick={onClickCb}>
								{footerButton}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default ModalComponent;

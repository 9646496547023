import React, { useEffect, useRef, useState } from 'react'

import { toast } from 'react-toastify'
import { componentKey, setAudioStarted, setIsVideoMuted, setVideoStarted, setZoomClient } from '../ZoomIntegrationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getZoomAuthJwtToken, getZoomAuthJwtTokenForGuest } from '../ZoomIntegrationSaga'
import { VEC_ICON_NAME } from '../components/icon/constants'
import { BUTTON_TYPE, HEADING } from '../constants'
import { useMount } from '../hooks/useMount'

import styles from "../ZoomIntegration.style"
import ZoomVideo from '@zoom/videosdk'
import Icons from '../components/icon/Icon'
import Button from '../components/button/Button'
import Heading from '../components/heading/Heading'


const Permissions = ({ userType, appointmentDetails, tenetId }) => {

    const zmClient = ZoomVideo.createClient();
    const dispatch = useDispatch()

    let localAudio = ZoomVideo.createLocalAudioTrack();

    const [microphoneRange, setMicrophoneRange] = useState(0);
    const [isMicrophoneAllowed, setIsMicrophoneAllowed] = useState(false)
    const [isVideoAllowed, setIsVideoAllowed] = useState(false)
    const [guestName, setGuestName] = useState("")

    const [micList, setMicList] = useState([]);
    const [speakerList, setSpeakerList] = useState([]);
    const [cameraList, setCameraList] = useState([]);

    const [activeCamera, setActiveCamera] = useState('')
    const [activeMicrophone, setActiveMicrophone] = useState('');
    const [activeSpeaker, setActiveSpeaker] = useState('');

    const [showMicroPhoneList, setShowMicroPhoneList] = useState(false)
    const [showSpeakerList, setShowSpeakerList] = useState(false)
    const [showCameraList, setShowCameraList] = useState(false)
    const [isPlayingAudio, setIsPlayingAudio] = useState(false);
    const [outputLevel, setOutputLevel] = useState(0);

    const [isPlayingRecording, setIsPlayingRecording] = useState(false);
    const [isRecordingVoice, setIsRecordingVoice] = useState(false);
    const [inputLevel, setInputLevel] = useState(0);

    const microphoneTesterRef = useRef();
    const speakerTesterRef = useRef();

    const { client, videoStarted, audioStarted, activePageIndex, isVideoMuted } = useSelector(state => state[componentKey])

    const mountDevices = async () => {
        const allDevices = await ZoomVideo.getDevices();

        const cameraDevices = allDevices.filter((device) => {
            return device.kind === 'videoinput';
        });

        const micDevices = allDevices.filter((device) => {
            return device.kind === 'audioinput';
        });

        const speakerDevices = allDevices.filter((device) => {
            return device.kind === 'audiooutput';
        });

        return {
            mics: micDevices.map((item) => {
                return {
                    label: item.label,
                    value: item.label,
                    deviceId: item.deviceId,
                    additionalHtml: <div className='flex px-[10px] column-gap-10px'>
                        <Icons iconName={VEC_ICON_NAME.MICROPHONE_ICON} />
                        <input type='range' className='w-75' value={activeMicrophone} onChange={(e) => setActiveMicrophone(e.target.value)} />
                    </div>
                };
            }),
            speakers: speakerDevices.map((item) => {
                return { label: item.label, deviceId: item.deviceId };
            }),
            cameras: cameraDevices.map((item) => {
                return { label: item.label, deviceId: item.deviceId };
            })
        };
    };

    useEffect(() => {
        dispatch(setAudioStarted(true))
        dispatch(setVideoStarted(true))
        dispatch(setZoomClient(zmClient))
    }, [zmClient])

    useEffect(() => {
        try {
            const init = async () => {
                await zmClient.init('en-US', 'Global', `${window.location.origin}/lib`, {
                    enforceMultipleVideos: true,
                    stayAwake: true,
                    patchJsMedia: true,
                    leaveOnPageUnload: true
                });
            };

            init();
        } catch (error) {
            console.log(">>>>> Client init error 57", error)
        }
    }, [zmClient]);

    const onStartAppointmentHandler = async () => {
        if ((userType == "Guest") && tenetId) {
            if (guestName.length == 0) {
                toast.error("Please enter your name to join the meeting")
                return
            }

            if (tenetId) {
                dispatch(getZoomAuthJwtTokenForGuest({ type: "Patient", scheduleId: appointmentDetails.uuid, userIdentity: guestName, client, tenetId }))
            }
        } else {
            if (userType == "Patient") {
                dispatch(getZoomAuthJwtTokenForGuest({ type: "Patient", scheduleId: appointmentDetails.uuid, userIdentity: guestName, client, tenetId }))
            } else {
                dispatch(getZoomAuthJwtToken({ type: userType !== "Provider" ? "Patient" : "Provider", scheduleId: appointmentDetails.uuid, client }))
            }
        }
    }

    const videoRef = useRef(null);

    useEffect(() => {
        // Access microphone stream
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .then(stream => {
                // Set microphone and video permissions as allowed
                setIsMicrophoneAllowed(true);
                setIsVideoAllowed(true);

                // Create audio context
                const audioContext = new AudioContext();
                const source = audioContext.createMediaStreamSource(stream);
                const analyser = audioContext.createAnalyser();

                // Connect microphone stream to analyzer
                source.connect(analyser);

                // Set up analyzer properties
                analyser.fftSize = 2048;
                const bufferLength = analyser.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);

                // Update microphone range
                const updateMicrophoneRange = () => {
                    analyser.getByteFrequencyData(dataArray);
                    const maxFrequency = Math.max(...dataArray);
                    setMicrophoneRange(maxFrequency);
                };

                // Start updating microphone range
                const intervalId = setInterval(updateMicrophoneRange, 100);

                // Cleanup function
                return () => {
                    clearInterval(intervalId);
                    audioContext.close();
                    stream.getTracks().forEach(track => track.stop());
                };
            })
            .catch(error => console.error('Error accessing microphone:', error));
    }, [activePageIndex]); // Only run once on component mount


    const startMicrophoneAndCamera = async () => {
        try {
            // Reset permission
            await navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                stream.getTracks().forEach(track => track.stop());
            });

            await navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
                stream.getTracks().forEach(track => track.stop());
            });

            // Request access to the microphone
            await navigator.mediaDevices.getUserMedia({ audio: true }).then(resp => {
                setIsMicrophoneAllowed(resp.active)
            })
            await navigator.mediaDevices.getUserMedia({ video: true }).then(resp => {
                setIsVideoAllowed(resp.active)
            })

        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    useEffect(() => {
        const startCamera = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (error) {
                console.error('Error accessing camera:', error);
            }
        };

        if (videoStarted) {
            startCamera();
        }

        return () => {
            if (videoRef.current) {
                const stream = videoRef.current.srcObject;
                if (stream) {
                    const tracks = stream.getTracks();
                    tracks.forEach(track => track.stop());
                }
            }
        };
    }, [isMicrophoneAllowed, isVideoAllowed, videoStarted]);

    const toggleCamera = () => {
        dispatch(setVideoStarted(!videoStarted))
        dispatch(setIsVideoMuted(videoStarted))

        if (videoRef.current) {
            const stream = videoRef.current.srcObject;
            if (stream) {
                const videoTracks = stream.getVideoTracks();
                if (videoTracks.length > 0) {
                    videoTracks[0].enabled = videoStarted;
                }
            }
        }
    };

    const toggleMute = () => {
        dispatch(setAudioStarted(!audioStarted))
    };

    useMount(() => {
        mountDevices().then((devices) => {
            setMicList(devices.mics);
            setCameraList(devices.cameras);
            setSpeakerList(devices.speakers);

            if (devices.speakers.length > 0) {
                setActiveSpeaker(devices.speakers[0].deviceId);
            }

            if (devices.mics.length > 0) {
                setActiveMicrophone(devices.mics[0].deviceId);
            }

            if (devices.cameras.length > 0) {
                setActiveCamera(devices.cameras[0].deviceId)
            }
        });
    });

    const toggleDeviceList = (type) => {
        if (type === "Microphone") {
            setShowMicroPhoneList(!showMicroPhoneList)
            setShowCameraList(false)
            setShowSpeakerList(false)
        } else if (type === "Speakers") {
            setShowSpeakerList(!showSpeakerList)
            setShowMicroPhoneList(false)
            setShowCameraList(false)
        } else if (type === "Camera") {
            setShowCameraList(!showCameraList)
            setShowSpeakerList(false)
            setShowMicroPhoneList(false)
        }
    }

    const onTestSpeakerClick = () => {
        if (microphoneTesterRef.current) {
            microphoneTesterRef.current.destroy();
            microphoneTesterRef.current = undefined;
        }
        if (isPlayingAudio) {
            speakerTesterRef.current?.stop();
            setIsPlayingAudio(false);
            setOutputLevel(0);
        } else {
            speakerTesterRef.current = localAudio.testSpeaker({
                speakerId: activeSpeaker,
                onAnalyseFrequency: (value) => {
                    setOutputLevel(Math.min(100, value));
                }
            });
            setIsPlayingAudio(true);
        }
    };

    const onTestMicrophoneClick = () => {
        if (speakerTesterRef.current) {
            speakerTesterRef.current.destroy();
            speakerTesterRef.current = undefined;
        }
        if (!isPlayingRecording && !isRecordingVoice) {
            microphoneTesterRef.current = localAudio.testMicrophone({
                microphoneId: activeMicrophone,
                speakerId: activeSpeaker,
                recordAndPlay: true,
                onAnalyseFrequency: (value) => {
                    setInputLevel(Math.min(100, value));
                },
                onStartRecording: () => {
                    setIsRecordingVoice(true);
                },
                onStartPlayRecording: () => {
                    setIsRecordingVoice(false);
                    setIsPlayingRecording(true);
                },
                onStopPlayRecording: () => {
                    setIsPlayingRecording(false);
                }
            });
        } else if (isRecordingVoice) {
            microphoneTesterRef.current?.stopRecording();
            setIsRecordingVoice(false);
        } else if (isPlayingRecording) {
            microphoneTesterRef.current?.stop();
            setIsPlayingRecording(false);
        }
    };

    let microphoneBtn = 'Test Microphone';
    if (isRecordingVoice) {
        microphoneBtn = 'Recording';
    } else if (isPlayingRecording) {
        microphoneBtn = 'Playing';
    }

    return (
        <React.Fragment>
            <div style={styles.permissionContainer}>
                <div style={styles.mainView}>
                    <div style={{ ...styles.permissionButtonContainer, ...((isMicrophoneAllowed && isVideoAllowed) && { rowGap: "0" }) }}>
                        {(isMicrophoneAllowed && isVideoAllowed) ?
                            <>
                                <video style={styles.readyToJoinVideo} ref={videoRef} autoPlay playsInline muted />
                                <div style={styles.videoButtons}>
                                    <div className='video-setting-icons'>
                                        <div onClick={toggleMute}>
                                            {audioStarted ?
                                                <Icons iconName={VEC_ICON_NAME.MUTE_MIC_ICON} style={styles.videoButtonsIcon} /> :
                                                <Icons iconName={VEC_ICON_NAME.UNMUTE_MIC_ICON} style={styles.videoButtonsIcon} />
                                            }
                                        </div>

                                        <div onClick={toggleCamera}>
                                            {!videoStarted ?
                                                <Icons iconName={VEC_ICON_NAME.OFF_CAMERA} style={styles.videoButtonsIcon} /> :
                                                <Icons iconName={VEC_ICON_NAME.ON_CAMERA} style={styles.videoButtonsIcon} />
                                            }
                                        </div>
                                    </div>

                                </div>
                            </>
                            :
                            <div style={styles.permission}>
                                <Icons iconName={VEC_ICON_NAME.VIDEO_BACKGROUND_PROFILE} />
                            </div>
                        }
                        <div style={styles.buttonMainContainer}>
                            <div style={styles.buttonContainer}>
                                <div style={styles.select} onClick={() => toggleDeviceList("Microphone")}>
                                    <Icons iconName={VEC_ICON_NAME.MICROPHONE_ICON} />
                                    <span>Microphone (Built-in)</span>
                                    <Icons iconName={VEC_ICON_NAME.UP_ARROW} />
                                </div>
                                {showMicroPhoneList &&
                                    <div style={{ ...styles.listContainer, left: "10px" }}>
                                        {micList.map((item, index) => {
                                            return (
                                                <div onClick={() => setActiveMicrophone(item.deviceId)} key={index} style={activeMicrophone == item.deviceId ? styles.activeDeviceList : styles.deviceList}>
                                                    <Icons iconName={VEC_ICON_NAME.MICROPHONE_ICON} />
                                                    <option key={index} value={item.label}>{item.label}</option>
                                                </div>
                                            )
                                        })}
                                        <Button
                                            variant={BUTTON_TYPE.BUTTON_WITH_LIGHT_COLOR_BG}
                                            styles={{ padding: "0", marginTop: "10px" }}
                                            onClickCb={onTestMicrophoneClick}
                                        >
                                            {microphoneBtn}
                                        </Button>

                                        {isPlayingRecording &&
                                            <div style={styles.testOutput}>
                                                <span className="speaker-label">Input level</span>
                                                <input type='range' value={inputLevel} />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div style={styles.buttonContainer}>
                                <div style={styles.select} onClick={() => toggleDeviceList("Speakers")}>
                                    <Icons iconName={VEC_ICON_NAME.SPEAKER_ICON} />
                                    <span>Speakers (Built-in)</span>
                                    <Icons iconName={VEC_ICON_NAME.UP_ARROW} />
                                </div>
                                {showSpeakerList &&
                                    <div style={styles.listContainer}>
                                        {speakerList.map((item, index) => {
                                            return (
                                                <div onClick={() => setActiveSpeaker(item.deviceId)} key={index} style={activeSpeaker == item.deviceId ? styles.activeDeviceList : styles.deviceList}>
                                                    <Icons iconName={VEC_ICON_NAME.SPEAKER_ICON} />
                                                    <option key={index} value={item.label}>{item.label}</option>
                                                </div>
                                            )
                                        })}
                                        <Button
                                            variant={BUTTON_TYPE.BUTTON_WITH_LIGHT_COLOR_BG}
                                            styles={{ padding: "0", marginTop: "10px" }}
                                            onClickCb={onTestSpeakerClick}
                                        >
                                            {isPlayingAudio ? "Stop" : "Test Speaker"}
                                        </Button>

                                        {isPlayingAudio &&
                                            <div style={styles.testOutput}>
                                                <span className="speaker-label">Output level</span>
                                                <input type='range' value={outputLevel} />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div style={styles.buttonContainer}>
                                <div style={styles.select} onClick={() => toggleDeviceList("Camera")}>
                                    <Icons iconName={VEC_ICON_NAME.VIDEO_CAM_ICON} />
                                    <span>Camera (Built-in)</span>
                                    <Icons iconName={VEC_ICON_NAME.UP_ARROW} />
                                </div>
                                {showCameraList &&
                                    <div style={styles.listContainer}>
                                        {cameraList.map((item, index) => {
                                            return (
                                                <div key={index} style={activeCamera == item.deviceId ? styles.activeDeviceList : styles.deviceList}>
                                                    <Icons iconName={VEC_ICON_NAME.VIDEO_CAM_ICON} />
                                                    <option key={index} value={item.label}>{item.label}</option>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div style={styles.permissionContent}>
                        {(!isMicrophoneAllowed && !isVideoAllowed) ?
                            <>
                                <Heading>Camera & Microphone Needed</Heading>
                                <Heading>Get ready for your close up</Heading>
                            </> :
                            <>
                                <Heading customStyle={{ color: "#1A1A1ACC", fontWeight: "500", fontSize: "30px", marginBottom: "15px" }} type={HEADING.H1}>Ready to join?</Heading>
                                {userType !== "Guest" &&
                                    <Heading customStyle={{ color: "#1A1A1A99", fontWeight: "500" }}>Scheduled at : Today, {appointmentDetails.start} - {appointmentDetails.end}</Heading>
                                }
                            </>
                        }
                        {(!isMicrophoneAllowed && !isVideoAllowed) ?
                            <Button styles={styles.enableButtonStyle} onClickCb={startMicrophoneAndCamera}>Enable Mic & Camera</Button> :
                            <>
                                {(userType == "Guest") &&
                                    <input
                                        placeholder='Enter your name here'
                                        className='guest-name'
                                        onChange={(e) => setGuestName(e.target.value)}
                                        value={guestName}
                                    />
                                }
                                <Button styles={styles.enableButtonStyle} onClickCb={onStartAppointmentHandler}>Start appointment</Button>
                            </>
                        }
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Permissions

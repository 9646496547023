import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SuperBillWrapper from './ReadyForBilling/genrateSuperBill/SuperBillWrapper'
import BillingWrapper from './BillingWrapper'

const Billing = () => {
    const fullPageRoutes = [{
        path: `/genrate-super-bill/:uuid`, component: <SuperBillWrapper />
    }, { path: "/*", component: <BillingWrapper /> }]

    return (
        <>
            <Routes>
                {fullPageRoutes?.map((route, index) => {
                    return <Route key={"patient-history-route" + index} path={route.path} element={route.component} />
                })}
            </Routes>
        </>
    )
}

export default Billing
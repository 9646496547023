import React from 'react';
import ProfileCardList from '../ProfileCardList';
import RoundFab from '../../../../../../components/common-components/Fab/RoundFab';
import Icons from '../../../../../../components/icons/Icons';
import PatientDetails from '../../../../../../containers/AddPatient/PatientDetails/PatientDetails';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { FORM_FIELDS_NAMES } from '../../../../../../containers/AddPatient/PatientDetails/Constants';
import { useDispatch } from 'react-redux';
import { updateProfiles } from '../../ProfileSaga';
import Avatar from '../../../../../../components/common-components/avatar';


const PatientProfileCard = ({ patientData }) => {
    const { patientId } = useParams();
    const dispatch = useDispatch()
    const capitalizedFirstName = patientData?.firstName ? patientData?.firstName.charAt(0).toUpperCase() + patientData?.firstName.slice(1) : '-';
    const capitalizedLastName = patientData?.lastName ? patientData?.lastName.charAt(0).toUpperCase() + patientData?.lastName.slice(1) : '-';

    const valueis = `${moment(patientData?.dob).format('DD-MM-YYYY') || "-"}`;
    const age = `${patientData?.dob?.length ? `${(moment().diff(patientData?.dob, 'years'))}` : "-"}`
    const formattedAge = `(${age} Yr)`

    const data = [
        {
            icon: (
                <RoundFab customClasses="w-[100px] h-[100px]" color="gray-400">
                    <Avatar customClass="w-[100px] h-[100px] overflow-hidden object-cover rounded-full"
                        url={patientData?.profilePicture} name={`${patientData?.firstName} ${patientData?.lastName}`} />
                </RoundFab>
            ),
            editbtnIcon: (<Icons iconName="editIcon" />),
            feildName: 'Name',

            subFeild: `${capitalizedFirstName} ${capitalizedLastName}`,
        },
        { feildName: 'Status', subFeild: `${patientData?.status == true ? 'Active' : "Inactive" || "-"}` },
        { feildName: 'Patient ID', subFeild: patientId?.slice(32, 36) },
        { feildName: 'DOB', subFeild: `${valueis} ${formattedAge}` },
        { feildName: 'Aadhar ID', subFeild: `${patientData?.aadhar || "-"}` },
        { feildName: 'Email ID', subFeild: `${patientData?.email || "-"} ` },
        { feildName: 'Patient registered provider group location', subFeild: 'Location 1' },
    ];
    return <ProfileCardList
        patientUpdateCb={(values, handleSuccess) => {
            values.gender = values.gender?.value;
            values.maritalStatus = values.maritalStatus?.value;
            values[FORM_FIELDS_NAMES.TIMEZONE] = values[FORM_FIELDS_NAMES.TIMEZONE]?.value;
            values[FORM_FIELDS_NAMES.LANGUAGE] = values[[FORM_FIELDS_NAMES.LANGUAGE]]?.value;
            const adharNum = values[FORM_FIELDS_NAMES.AADHAR_NUMBER]?.replace(/-/g, '')
            values[FORM_FIELDS_NAMES.AADHAR_NUMBER] = Number(adharNum)

            const { profilePicture, ...updatedValues } = values
            const updatedData = {
                ...updatedValues,
                image: profilePicture,
            };
            dispatch(updateProfiles({ uuid: patientId, updatedData: updatedData, handleSuccess }))
        }}
        data={data}
        paddingTop="pt-0"
        customClassName="min-w-400"
        Component={PatientDetails}
        modelTitle={'Patient Details'}
    />
};

export default PatientProfileCard;

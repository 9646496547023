export const SettingsBackArrowIcon = () => {
	return (
		<svg id="arrow_back_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path id="Path_204520" data-name="Path 204520" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_204521"
				data-name="Path 204521"
				d="M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z"
				fill="rgba(26,26,26,0.8)"
			/>
		</svg>
	);
};

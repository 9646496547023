import { useDispatch, useSelector } from "react-redux"
import SelectDropdown from "../../components/common-components/selectDropdown"
import { componentKey } from "../../containers/SettingsTab/TemplatesCard/Tabs/VisitNotes/VisitNotesSlice"
import General from "../../libs/utility/General"
import { useEffect, useState } from "react"
import { getNoteById } from "../../containers/SettingsTab/TemplatesCard/Tabs/VisitNotes/VisitNotesSaga"
import { setSelectedForm } from "./EncounterSlice"
import { componentKey as encounterComponentKey } from "./EncounterSlice"

const NotesDropdown = () => {
    const { visitNoteState, visitNoteById } = useSelector(state => state[componentKey])
    const { selectedForm, encounterDataById } = useSelector(state => state[encounterComponentKey])

    const dispatch = useDispatch()

    useEffect(() => {
        if (!selectedForm?.value) {
            const deviceUuid = encounterDataById?.encounterForms?.data?.deviceUuid
            if (deviceUuid) {
                dispatch(setSelectedForm({ label: deviceUuid, uuid: deviceUuid, value: deviceUuid }))
                dispatch(getNoteById({ noteId: deviceUuid}))
            }
        }
    }, [dispatch, selectedForm?.value, encounterDataById?.encounterForms?.data?.deviceUuid])

    return <div className="flex p-4">
        <div className="w-[300px]">
            <SelectDropdown
                onChangeCb={(val) => {
                    dispatch(setSelectedForm(val))
                    dispatch(getNoteById({ noteId: val.uuid }))
                }}
                options={General.addLableValuePair(visitNoteState?.templateDetails, 'name', 'uuid')}
                value={{ label: selectedForm?.name, value: selectedForm?.uuid }}
            />
        </div>
    </div>
}

export default NotesDropdown
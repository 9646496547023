import store from "../../../../../store/store";
export const componentKey = 'ADD_AVAILABILITY'

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setAvailiabilityData: (state, action) => {
            state.availiabilityData = action.payload;
        },
        setAvailiabilityResponseData: (state, action) => {
            state.availiabilityResponseData = action.payload;
        },
        setProviderLocationsData: (state, action) => {
            state.providerLocationsData = action.payload;
        },
        setIsAvailiablityModalOpen: (state, action) => {
            state.isAvailiablityModalOpen = action.payload;
        },
        setIsNewAvailabilityAdd: (state, action) => {
            state.isNewAvailabilityAdd = action.payload;
        },
        setAvailability: (state, action) => {
            state.availiability = action.payload;
        },
        setSelectedProvider: (state, action) => {
            state.selectedProvider = action.payload;
        },
        setIsNewAvailabilityAdd: (state, action) => {
            state.isNewAvailabilityAdd = action.payload;
        },
        setIsEditSingleDayAvailabilityModalOpen: (state, action) => {
            state.isEditSingleDayAvailabilityModalOpen = action.payload;
        },
        setIsAddSingleDayAvailabilityModalOpen: (state, action) => {
            state.isAddSingleDayAvailabilityModalOpen = action.payload;
        },
        setSelectedLocations: (state, action) => {
            state.selectedLocations = action.payload;
        },
        setIsViewAllEventsModalOpen: (state, action) => {
            state.isViewAllEventsModalOpen = action.payload;
        },
        setViewAllModalSelectedSlotData: (state, action) => {
            state.viewAllModalSelectedSlotData = action.payload;
        },
    },
    initialReducerState: {
        availiabilityData: [],
        availiabilityResponseData: [],
        providerLocationsData: [],
        isAvailiablityModalOpen: false,
        isNewAvailabilityAdd: false,
        availiability: {},
        selectedProvider: {},
        isEditSingleDayAvailabilityModalOpen: false,
        isAddSingleDayAvailabilityModalOpen: false,
        selectedLocations: [],
        isViewAllEventsModalOpen: false,
        viewAllModalSelectedSlotData: {}
    }
})

export const { setAvailiabilityData,
    setAvailiabilityResponseData,
    setProviderLocationsData,
    setIsAvailiablityModalOpen,
    setIsNewAvailabilityAdd,
    setAvailability,
    setSelectedProvider,
    setIsEditSingleDayAvailabilityModalOpen,
    setIsAddSingleDayAvailabilityModalOpen,
    setSelectedLocations,
    setIsViewAllEventsModalOpen,
    setViewAllModalSelectedSlotData,
} = actions
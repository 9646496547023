import React, { forwardRef, useEffect } from 'react';
import Input from '../../../components/common-components/input/Input';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import { Form, Formik } from 'formik';
import { FORM_FIELDS_NAMES } from './Constants';
import { getValidationSchema } from '../../../libs/formsUtils';
import { VALIDATION_REGEX } from '../../../libs/constant';
import { createNewPatient, getPatientDetails, updatePatientDetails } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import { useDispatch, useSelector } from 'react-redux';
import {
	componentKey,
	setCreatePatientData,
	setEditDemographicsData,
	setIsFormSubmitted,
} from '../../../pages/Patients/AddPatient/AddPatientSlice';
import { componentKey as dataLoaderKey } from '../../Wrappers/Dataloader/DataloaderSlice';
import General from '../../../libs/utility/General';
import Label from '../../../components/common-components/label/Label';
import { useParams, useSearchParams } from 'react-router-dom';
import store from '../../../store/store';

const fields = [
	{ fieldName: FORM_FIELDS_NAMES.MOBILE_NUMBER, isRequired: true, regexPattern: VALIDATION_REGEX.MOBILE_NUM_REGEX },
	{ fieldName: FORM_FIELDS_NAMES.HOME_NUMBER, regexPattern: VALIDATION_REGEX.MOBILE_NUM_REGEX },
	{ fieldName: FORM_FIELDS_NAMES.EMAIL, isEmail: true },
	{ fieldName: FORM_FIELDS_NAMES.ADDRESS_LINE_1, isRequired: true, isMaxLength: 255 },
	{ fieldName: FORM_FIELDS_NAMES.CITY, isRequired: true, regexPattern: VALIDATION_REGEX.CITY_REGEX },
	{ fieldName: FORM_FIELDS_NAMES.STATE, isDropdown: true },
	{ fieldName: FORM_FIELDS_NAMES.COUNTRY, regexPattern: VALIDATION_REGEX.NAME_REGEX },
	{ fieldName: FORM_FIELDS_NAMES.ZIPCODE, isRequired: true, regexPattern: VALIDATION_REGEX.ZIP_CODE_REGEX },
];

const validationSchema = getValidationSchema(fields);

const ContactInformation = forwardRef(function ContactInformation({ isUpdate = false, onUpdateCb = null }, formRef) {
	const { patientId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const redirectFromDemographics = searchParams.get('redirectFromDemographics');
	const dispatch = useDispatch();
	const { createPatientData, fetchedPatientDetails, isFormSubmitted } = useSelector((state) => state[componentKey]);
	const { indianStates } = useSelector((state) => state[dataLoaderKey]);

	const { Address } = fetchedPatientDetails;
	const { addressLine1, addressLine2, city, country, state, zip, uuid } = Address?.[0] || {};

	const { newlyAddedPatientId } = useSelector((state) => state[componentKey]);

	useEffect(() => {
		return () => {
			if (isUpdate) {
				store.reducerManager.remove(componentKey);
			}
		}
	}, [])

	useEffect(() => {
		if (patientId && patientId !== ":id" && isUpdate) {
			dispatch(getPatientDetails({ patientId }))
		}
	}, [dispatch, patientId, isUpdate])

	return (
		<Formik
			innerRef={formRef}
			initialValues={{
				[FORM_FIELDS_NAMES.MOBILE_NUMBER]:
					fetchedPatientDetails?.mobileNumber || createPatientData?.mobileNumber,
				[FORM_FIELDS_NAMES.HOME_NUMBER]: fetchedPatientDetails?.homeNumber || createPatientData?.homeNumber,
				[FORM_FIELDS_NAMES.EMAIL]: fetchedPatientDetails?.email || createPatientData?.email,
				[FORM_FIELDS_NAMES.FAX_NUMBER]: fetchedPatientDetails?.faxNumber || createPatientData?.faxNumber,
				[FORM_FIELDS_NAMES.ADDRESS_LINE_1]: addressLine1 || createPatientData?.addressLine1,
				[FORM_FIELDS_NAMES.ADDRESS_LINE_2]: addressLine2 || createPatientData?.addressLine2,
				[FORM_FIELDS_NAMES.CITY]: city || createPatientData?.city,
				[FORM_FIELDS_NAMES.STATE]: {
					label: state || createPatientData?.state,
					value: state || createPatientData?.state,
				},
				[FORM_FIELDS_NAMES.COUNTRY]: country || createPatientData?.country,
				[FORM_FIELDS_NAMES.ZIPCODE]: zip || createPatientData?.zip,
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				if (onUpdateCb) {
					return onUpdateCb({ ...values, uuid: uuid, })
				}
				if (patientId && patientId === ':id') {
					values.state = values.state?.value;
					dispatch(createNewPatient({ ...createPatientData, ...values }));
					dispatch(setIsFormSubmitted({ contactInformation: false }));
					dispatch(setCreatePatientData(values));
				} else {
					//EDIT demographics
					dispatch(setEditDemographicsData({address:values}))
				}
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, handleBlur, ...props }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<div className="custom-provider-information mt-4 mb-4 bg-opacity-3 rounded-md">
							<div className="flex column-gap-20px row-gap-15  flex-wrap p-4">
								<div className="flex-1 min-w-410">
									<Input
										label="Mobile Number"
										placeholder="Enter Number"
										isRequired={true}
										asteriskPosition="end"
										name={FORM_FIELDS_NAMES.MOBILE_NUMBER}
										value={values[FORM_FIELDS_NAMES.MOBILE_NUMBER]}
										onChangeCb={(e) => {
											setFieldValue(
												FORM_FIELDS_NAMES.MOBILE_NUMBER,
												General.sanitizeNumbers(e.target.value)
											);
										}}
										onBlurCb={handleBlur}
										disabled={isFormSubmitted.contactInformation || isUpdate ? true : false }
									/>
								</div>
								<div className="flex-1 min-w-410">
									<Input
										label="Phone Number"
										placeholder="Enter Number"
										name={FORM_FIELDS_NAMES.HOME_NUMBER}
										value={values[FORM_FIELDS_NAMES.HOME_NUMBER]}
										onChangeCb={(e) => {
											setFieldValue(
												FORM_FIELDS_NAMES.HOME_NUMBER,
												General.sanitizeNumbers(e.target.value)
											);
										}}
										onBlurCb={handleBlur}
										disabled={isFormSubmitted.contactInformation}
									/>
								</div>
								<div className="flex-1 min-w-410">
									<Input
										label="Email"
										placeholder="Enter Email"
										asteriskPosition="end"
										name={FORM_FIELDS_NAMES.EMAIL}
										value={values[FORM_FIELDS_NAMES.EMAIL]}
										onChangeCb={(e) => {
											setFieldValue(FORM_FIELDS_NAMES.EMAIL, e.target.value);
										}}
										onBlurCb={handleBlur}
										disabled={isFormSubmitted.contactInformation || isUpdate ? true : false}
									/>
								</div>
								<div className="flex-1 min-w-410">
									<Input
										label="Fax Number"
										placeholder="Enter Fax Number"
										name={FORM_FIELDS_NAMES.FAX_NUMBER}
										value={values[FORM_FIELDS_NAMES.FAX_NUMBER]}
										onChangeCb={(e) => {
											setFieldValue(FORM_FIELDS_NAMES.FAX_NUMBER, e.target.value);
										}}
										onBlurCb={handleBlur}
										disabled={isFormSubmitted.contactInformation}
									/>
								</div>
								<div className="flex-basis-50-10">
									<Input
										label="Address Line 1"
										placeholder="Enter Address Line 1"
										isRequired={true}
										asteriskPosition="end"
										name={FORM_FIELDS_NAMES.ADDRESS_LINE_1}
										value={values[FORM_FIELDS_NAMES.ADDRESS_LINE_1]}
										onChangeCb={(e) => {
											setFieldValue(FORM_FIELDS_NAMES.ADDRESS_LINE_1, e.target.value);
										}}
										onBlurCb={handleBlur}
										disabled={isFormSubmitted.contactInformation}
									/>
								</div>
								<div className="flex-basis-50-10">
									<Input
										label="Address Line 2"
										placeholder="Enter Address Line 2"
										asteriskPosition="end"
										name={FORM_FIELDS_NAMES.ADDRESS_LINE_2}
										value={values[FORM_FIELDS_NAMES.ADDRESS_LINE_2]}
										onChangeCb={(e) => {
											setFieldValue(FORM_FIELDS_NAMES.ADDRESS_LINE_2, e.target.value);
										}}
										onBlurCb={handleBlur}
										disabled={isFormSubmitted.contactInformation}
									/>
								</div>
								<div className="flex-1 min-w-410">
									<Input
										label="City"
										isRequired={true}
										placeholder="Enter City"
										name={FORM_FIELDS_NAMES.CITY}
										value={values[FORM_FIELDS_NAMES.CITY]}
										onChangeCb={(e) => {
											setFieldValue(FORM_FIELDS_NAMES.CITY, e.target.value);
										}}
										onBlurCb={handleBlur}
										disabled={isFormSubmitted.contactInformation}
									/>
								</div>
								<div className="flex-1 min-w-410">
									<SelectDropdown
										isRequired={true}
										placeholder="Select State"
										label="State"
										name={FORM_FIELDS_NAMES.STATE}
										value={values[FORM_FIELDS_NAMES.STATE]}
										onChangeCb={(val) => {
											setFieldValue(FORM_FIELDS_NAMES.STATE, val);
										}}
										options={indianStates}
										onBlurCb={handleBlur}
										disabled={isFormSubmitted.contactInformation}
									/>
								</div>
								<div className="flex-1 min-w-410">
									<Input
										placeholder="Enter Country"
										label="Country"
										name={FORM_FIELDS_NAMES.COUNTRY}
										value={values[FORM_FIELDS_NAMES.COUNTRY]}
										onChangeCb={(e) => {
											setFieldValue(FORM_FIELDS_NAMES.COUNTRY, e.target.value);
										}}
										onBlurCb={handleBlur}
										disabled={isFormSubmitted.contactInformation || isUpdate ? true : false}
									/>
								</div>
								<div className="flex-1 min-w-410">
									<Input
										label="Zip Code"
										isRequired={true}
										placeholder="Enter Zip Code"
										name={FORM_FIELDS_NAMES.ZIPCODE}
										value={values[FORM_FIELDS_NAMES.ZIPCODE]}
										onChangeCb={(e) => {
											setFieldValue(FORM_FIELDS_NAMES.ZIPCODE, e.target.value);
										}}
										onBlurCb={handleBlur}
										disabled={isFormSubmitted.contactInformation}
									/>
								</div>
							</div>
						</div>

						<div className="pt-2">
							<Label asteriskPosition="start" isRequired={true}>
								Indicates mandatory fields
							</Label>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
});

export default ContactInformation;

import React from 'react'

function EncounterIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92">
            <g id="Group_96342" data-name="Group 96342" transform="translate(-1604 -132)">
                <circle id="Ellipse_1213" data-name="Ellipse 1213" cx="46" cy="46" r="46" transform="translate(1604 132)" fill="rgba(0,151,240,0.1)" />
                <g id="Group_72298" data-name="Group 72298" transform="translate(1618 150)">
                    <g id="Group_72296" data-name="Group 72296">
                        <g id="Group_72295" data-name="Group 72295">
                            <path id="Path_28497" data-name="Path 28497" d="M0,0H43.066V43.066H0Z" fill="#1b5984" opacity="0" />
                            <g id="noun-doctor-1030145" transform="translate(4.98 3.1)">
                                <path id="Path_28494" data-name="Path 28494" d="M132.909,321.795c.568,0,1.15,0,1.744,0h11.252c.594,0,1.175,0,1.743,0,5.206,0,9.184-.266,9.184-5.709a10.923,10.923,0,0,0-6.1-9.806,8.223,8.223,0,0,1,.6,2.946,2.664,2.664,0,1,1-1.228.01c-.051-1.138-.323-2.869-1.436-3.723a10.943,10.943,0,0,0-1.752-.309c-.045,1.993-6.632,6.911-6.632,6.911s-6.589-4.917-6.633-6.91a10.822,10.822,0,0,0-1.939.358c-.812.644-1.233,1.841-1.254,3.583a1.348,1.348,0,0,1,.55.611,5.089,5.089,0,0,1,2.24,2.52.877.877,0,0,1,.039.658,7.936,7.936,0,0,1,.557,2.749c0,1.174,0,2.285-1.28,2.57a.769.769,0,0,1-.494.179h-.855a.779.779,0,0,1-.778-.778l0-.055a.783.783,0,0,1,.776-.724h.855a.774.774,0,0,1,.256.044.247.247,0,0,0,.068-.027,4.5,4.5,0,0,0,.1-1.21,6.518,6.518,0,0,0-.469-2.267.874.874,0,0,1-.357-.382c-.519-1.086-1.224-1.787-1.8-1.787s-1.326.758-1.843,1.886a.881.881,0,0,1-.415.423,6.358,6.358,0,0,0-.418,2.127,4.2,4.2,0,0,0,.109,1.212.429.429,0,0,0,.093.031.778.778,0,0,1,.279-.052h.857a.778.778,0,0,1,.772.688l0,.009,0,.052a.786.786,0,0,1-.778.809h-.857a.77.77,0,0,1-.47-.16,1.482,1.482,0,0,1-1.053-.659,3.647,3.647,0,0,1-.313-1.929,7.819,7.819,0,0,1,.54-2.7.863.863,0,0,1,.051-.583,6.492,6.492,0,0,1,1.334-1.963,3.692,3.692,0,0,1,.9-.645,1.364,1.364,0,0,1,.573-.646,7.863,7.863,0,0,1,.491-2.811,10.925,10.925,0,0,0-5.991,9.75c0,5.443,3.976,5.709,9.182,5.709Zm10.238-6.094a.273.273,0,0,1,.286-.256h1.557v-1.557a.272.272,0,0,1,.256-.287h1.536a.273.273,0,0,1,.256.287v1.557H148.6a.272.272,0,0,1,.286.256v1.536a.272.272,0,0,1-.286.256h-1.557v1.557a.273.273,0,0,1-.256.287h-1.536a.273.273,0,0,1-.256-.287v-1.557h-1.557a.272.272,0,0,1-.286-.256Z" transform="translate(-123.728 -284.926)" fill="#1b5984" />
                                <path id="Path_28495" data-name="Path 28495" d="M225.51,37.11c0,5.032,4.079,12.6,9.11,12.6s9.111-7.567,9.111-12.6a9.11,9.11,0,0,0-18.221,0Z" transform="translate(-218.064 -28)" fill="#1b5984" />
                                <path id="Path_28496" data-name="Path 28496" d="M478.576,380.058a1.229,1.229,0,1,1-1.229-1.229,1.229,1.229,0,0,1,1.229,1.229" transform="translate(-450.34 -353.165)" fill="#1b5984" />
                            </g>
                        </g>
                    </g>
                    <rect id="Rectangle_34148" data-name="Rectangle 34148" width="23" height="20" transform="translate(36 30)" fill="#e5f4fe" />
                    <g id="event_black_24dp_4_" data-name="event_black_24dp (4)" transform="translate(30.467 21.035)">
                        <path id="Path_28492" data-name="Path 28492" d="M0,0H34.066V34.066H0Z" fill="none" />
                        <path id="Path_28493" data-name="Path 28493" d="M22.872,16.614h-7.1v7.1h7.1ZM21.453,1V3.839H10.1V1H7.258V3.839H5.839A2.826,2.826,0,0,0,3.014,6.678L3,26.55a2.838,2.838,0,0,0,2.839,2.839H25.711A2.847,2.847,0,0,0,28.55,26.55V6.678a2.847,2.847,0,0,0-2.839-2.839H24.292V1Zm4.258,25.55H5.839V10.936H25.711Z" transform="translate(1.258 0.419)" fill="#1b5984" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default EncounterIcon
import React from 'react';
import Button from '../button/Button';
import { BUTTON_VARIANTS } from '../button/Constants';

function SucessPopUp({ isOpen, children, successMesaage, onClickOk }) {
	return isOpen ? (
		<div className="fixed top-0 left-0 w-full h-full flex items-center flex-col  justify-center z-50 bg-black bg-opacity-65">
			<div
				style={{
					minHeight: '400px',
					minWidth: '600px',
				}}
				className="bg-white shadow-md rounded-lg p-6 flex items-center justify-center flex-col"
			>
				<div
					style={{
						height: 250,
						width: 250,
					}}
					className="rounded-lg p-6 mt-8 flex flex-col items-center justify-center"
				>
					{children}
				</div>
				<div className="mt-8 text-xl font-semibold">{successMesaage}</div>
				<div style={{ marginTop: '24px' }}>
					<Button variant={BUTTON_VARIANTS.CONTAINED} onClickCb={onClickOk}>
						Okay
					</Button>
				</div>
			</div>
		</div>
	) : null;
}

export default SucessPopUp;

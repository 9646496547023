import React, { useState, useEffect } from 'react';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setABHASuggestionsOpen } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import Input from '../../../components/common-components/input/Input';
import Avatar from '../../../components/common-components/avatar';
import TextArea from '../../../components/common-components/textArea/TextArea';
import { abhaAddressSuggetions, abhaAddressUpdate, downloadCard } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import UpdateABHAAddressPopup from './updateABHAAddressPopup';
import General from '../../../libs/utility/General';

function PatientDetailsReviewModal({ open, onSubmit, close, modalType }) {
    const { createPatientFromAadhaar,transactionIdAndAadhaar, abhaSuggestedAddress, abhaSuggestionsOpen, selectedABHAAddress, downloadedCardBuffer } = useSelector(state => state[componentKey]);
    const dispatch = useDispatch();
    const {
        firstName,
        middleName,
        lastName,
        profilePicture,
        dob,
        gender,
        mobileNumber,
        aadhar,
        abhaNumber

    } = createPatientFromAadhaar?.patient || {};
    const address = createPatientFromAadhaar?.address || "";
    const [abhaAddressValue, setABHAAddressValue] = useState("");
    const [updateABHAAddressOpen, setUpdateABHAAddressOpen] = useState(false);
    const [abhaAddressToDisplay, setAbhaAddressToDisplay] = useState("");

    useEffect(() => {
        if (createPatientFromAadhaar?.patient?.abhaAddress) {
            setAbhaAddressToDisplay(createPatientFromAadhaar.patient.abhaAddress);
        }
    }, [createPatientFromAadhaar]);
    
    useEffect(() => {
        if (selectedABHAAddress?.preferredAbhaAddress) {
            setAbhaAddressToDisplay(selectedABHAAddress.preferredAbhaAddress);
        }
    }, [selectedABHAAddress]);

    const handleUpdateABHAAddress = async() => {
        try{
            const payload = {
                txnId: transactionIdAndAadhaar?.transactionId || '',
                abhaAddress: abhaAddressValue

            }
            await dispatch(abhaAddressUpdate({abhaAddressUpdateData:payload}))

        } catch (error){
            console.log("Error in suggested address", error)
        } finally {
            dispatch(setABHASuggestionsOpen(false));
            setUpdateABHAAddressOpen(false)
        }
    }
    
    const handleSelectAddress = (abhaAddress) => {
        setUpdateABHAAddressOpen(true);
        setABHAAddressValue(abhaAddress)
    };
    
    const handleSuggestedABHAAddress = async() => {
        const makeAPICall = !abhaSuggestionsOpen;
        
        if(makeAPICall){
            try{
                const payload = {
                    txnId: transactionIdAndAadhaar?.transactionId || '',
                }
                await dispatch(abhaAddressSuggetions({abhaAddressSuggetionData:payload}))
                
            } catch (error){
                console.log("Error in suggested address", error)
            }
        }else{
            dispatch(setABHASuggestionsOpen(false));
        }
    }

    const handleDownloadCard = async () => {
        try {
            const payload = {
                xtoken : createPatientFromAadhaar?.patient?.xtoken || '',
            };
            await dispatch(downloadCard({downloadCardData: payload}));
        } catch (error) {
            console.error("Error downloading card:", error);
        }
    };
    useEffect(() => {
        const handleDownloadCard = async (bufferData) => {
            await General.downloadFile(bufferData, 'Patient_Card');
            
        };

        if (downloadedCardBuffer !== null) {
            handleDownloadCard(downloadedCardBuffer.data);
        }
    }, [downloadedCardBuffer]);
    
    function formatDOB(dob) {
        if (typeof dob !== "string") {
          return dob;
        }
      
        const isoDate = Date.parse(dob);
        if (!isNaN(isoDate)) {
          return new Date(isoDate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
        }

        const [day, month, year] = dob.split('-').map(Number);
        if (day && month && year) {
          const parsedDate = new Date(year, month - 1, day);
          return parsedDate.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
        }
        return dob;
      }

    if (!open) {
        return null;
    }

    return (
        <>
            <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
                <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-5xl">
                    <div className="flex justify-between items-center border-b pb-3">
                        <h2 className="text-lg font-semibold">Ayushman Bharat Health Authority Details</h2>
                        <button onClick={close} className="text-gray-600 hover:text-black text-xl">
                            ✕
                        </button>
                    </div>

                    <div className="mt-4 space-y-4">
                        <div className="flex flex-col gap-4">
                            <div className='flex justify-between'>
                                <div className="col-span-1 flex flex-col items-left">
                                    {profilePicture ? (
                                        <Avatar textCenter="middle"
                                        customClass="w-[130px] h-[130px] overflow-hidden object-cover rounded-full"
                                        fontSize={3} url={profilePicture} name={`${firstName} ${lastName}`} />
                                    ) : (
                                        <div className="w-32 h-32 border-dashed border-2 border-gray-300 rounded flex items-center justify-center">
                                            <span className="text-gray-500">No Profile Picture</span>
                                        </div>
                                    )}
                                </div>
                                
                                <div className='flex gap-5 items-start'>
                                {modalType !== "getABHADetails" && 
                                    <div className='relative'>
                                        <Button
                                            variant={BUTTON_VARIANTS.CONTAINED}
                                            className="text-blue-600 border-blue-600"
                                            onClickCb = {() => handleSuggestedABHAAddress()}
                                        >
                                            Update ABHA Address
                                        </Button>
                                        {abhaSuggestionsOpen && (
                                            <div className="mt-4 bg-white absolute border border-gray-300 z-10 top-7 left-0 rounded-lg overflow-hidden">
                                                <ul className="border rounded p-2 space-y-2">
                                                    {abhaSuggestedAddress?.abhaAddressList?.length > 0 && (
                                                        abhaSuggestedAddress.abhaAddressList.map((address, index) => (
                                                            <li
                                                                key={index}
                                                                className="cursor-pointer p-2 rounded hover:bg-met-primary hover:text-white"
                                                                onClick={() => handleSelectAddress(address)}
                                                            >
                                                                {address}@sbx
                                                            </li>
                                                        ))
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                }
                                    <Button
                                        variant={BUTTON_VARIANTS.CONTAINED}
                                        onClickCb={handleDownloadCard}
                                        className="text-blue-600 border-blue-600"
                                    >
                                        Download Card
                                    </Button>
                                </div>
                            </div>
                            
                            <div className="col-span-2 grid md:grid-cols-3 gap-4">
                                <Input 
                                    label={<>First Name <span className="text-red-500">*</span></>} 
                                    value={firstName || ''} 
                                    disabled
                                />
                                <Input 
                                    label="Middle Name" 
                                    value={middleName || ''} 
                                    readOnly 
                                />
                                <Input 
                                    label={<>Last Name <span className="text-red-500">*</span></>} 
                                    value={lastName || ''} 
                                    disabled 
                                />
                                <Input 
                                    label={<>Date of Birth <span className="text-red-500">*</span></>} 
                                    value={dob ? formatDOB(dob) : ''} 
                                    disabled 
                                />
                                <Input
                                    label={<>Gender <span className="text-red-500">*</span></>}
                                    value={gender?.charAt(0).toUpperCase() + gender?.slice(1) || ''}
                                    disabled
                                    
                                />
                                <Input 
                                    label={<>Mobile <span className="text-red-500">*</span></>} 
                                    value={mobileNumber || ''} 
                                    
                                    disabled 
                                />
                                <Input 
                                    label={<>ABHA Number <span className="text-red-500">*</span></>} 
                                    value={abhaNumber || ''} 
                                    disabled 
                                />
                                <Input
                                    label={<>ABHA Address <span className="text-red-500">*</span></>} 
                                    value={abhaAddressToDisplay || ''}
                                    disabled
                                />
                            </div>
                            <div className='w-full'>
                                <TextArea
                                    label={<>Address <span className="text-red-500">*</span></>} 
                                    value={
                                        address
                                            ? `${address.addressLine1 || ''}, ${address.addressLine2 || ''}, ${address.city || ''}, ${address.state || ''}, ${address.country || ''}, ${address.zip || ''}`
                                            : 'No Address Available'
                                    }
                                    disabled
                                />
                            </div>
                            

                        </div>

                        <p className="text-xs text-red-500">* Indicates mandatory fields</p>
                    </div>


                    <div className="flex justify-end space-x-4 mt-6">
                        <Button
                            variant={BUTTON_VARIANTS.CONTAINED}
                            onClickCb={onSubmit}
                            className="text-blue-600 border-blue-600"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
            {updateABHAAddressOpen && 
                <UpdateABHAAddressPopup 
                    open={updateABHAAddressOpen} 
                    close={() => setUpdateABHAAddressOpen(false)}
                    onConfirm={handleUpdateABHAAddress}
                />}
        </>
    );
}

export default PatientDetailsReviewModal;

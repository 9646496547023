import store from '../../../../store/store';

export const componentKey = 'PATIENTALLERGIES_SECTION';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setAllergyId: (state, action) => {
			state.currentAllergyId = action.payload;
		},
		setAllergiesData: (state, action) => {
			state.allergiesData = action.payload;
		},
		setAllergyData: (state, action) => {
			state.allergyData = action.payload;
		},
		setIsAllergyModalOpen: (state, action) => {
			state.isAllergyModalOpen = action.payload;
		},
		setIsNewAllergyAdd: (state, action) => {
			state.isNewAllergyAdd = action.payload;
		},
		setAllergyStatus: (state, action) => {
			state.allergyStatus = action.payload;
		},
		setIsOpenAllergiesDeletePopup: (state, action) => {
			state.isOpenAllergiesDeletePopup = action.payload;
		},
	},
	initialReducerState: {
		currentAllergyId: '',
		allergiesData: [],
		allergyData: {},
		isAllergyModalOpen: false,
		isNewAllergyAdd: false,
		allergyStatus: { label: 'All', value: 'All' },
		isOpenAllergiesDeletePopup: false
	},
});

export const { setAllergyId, setAllergiesData, setAllergyData, setIsAllergyModalOpen, setIsNewAllergyAdd, setAllergyStatus, setIsOpenAllergiesDeletePopup } = actions;

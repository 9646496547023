import React, { useState } from 'react'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Table from '../../../../containers/Table/Index'
import FamilyHistoryModal from './FamilyHistoryModal'
import { MET_COLORS } from '../../../../libs/constant'
import ButtonDropdown from '../../../../components/common-components/buttonDropdown'
import General from '../../../../libs/utility/General'

function FamilyHistory() {
    const [isFamilyHistoryModalOpen, setIsFamilyHistoryModalOpen] = useState(false)

    const handleFamilyHistoryModal = () => {
        setIsFamilyHistoryModalOpen(true)
    }

    const handleFamilyHistoryModalClose = () => {
        setIsFamilyHistoryModalOpen(false)
    }
    const coloumns = [
        {
            field: "problem",
            label: <Label>Problem</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.problem}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "relative",
            label: <Label>Relative</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.relative}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "onsetAge",
            label: <Label>Onset Age</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.onsetAge}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "recordedDate",
            label: <Label>Recorded Date</Label>,
            renderLogic: (row) => {
                const formattedRecordedDate = General.getformattedDate(row.recordedDate)
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{formattedRecordedDate}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "note",
            label: <Label>Note</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.note}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "action",
            label: <Label>Action</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.action}</Heading>
                        </div>
                        <ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[{ label: "Edit", value: null }, { label: "Delete", value: null }]} />
                    </div>
                </>
            },
        },
    ]

    const rowData = [
        {
            problem: 'Asthma',
            relative: 'Brother',
            onsetAge: '27',
            recordedDate: '26-10-2022',
            note: '-',
        },
        {
            problem: 'Breathing',
            relative: 'Father',
            onsetAge: '56',
            recordedDate: '10-11-2022',
            note: '-',
        },
    ]
    return (
        <>
            <div className='flex items-center justify-end p-2 px-4'>
                <div className='flex'>
                    <Button onClickCb={handleFamilyHistoryModal} variant={BUTTON_VARIANTS.OUTLINED} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} >Add Family History</Button>
                </div>
            </div>
            <div className='p-4'>
                <Table isPagination={false} coloumns={coloumns} rows={rowData} sorting={
                    {
                        isSortable: false,
                        onSortChangeCb: () => { }
                    }} />
            </div>
            {isFamilyHistoryModalOpen ? <FamilyHistoryModal open={isFamilyHistoryModalOpen} close={handleFamilyHistoryModalClose} /> : null}
        </>
    )
}

export default FamilyHistory
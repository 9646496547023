import React from 'react'

function PhoneIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="call_black_24dp_1_" data-name="call_black_24dp (1)" width="24" height="24" viewBox="0 0 24 24">
            <path id="Path_206094" data-name="Path 206094" d="M0,0H24V24H0Z" fill="none" />
            <path id="Path_206095" data-name="Path 206095" d="M6.832,5.165a13.329,13.329,0,0,0,.487,2.8l-1.3,1.3a16.049,16.049,0,0,1-.823-4.1H6.832M17.506,18.177a13.805,13.805,0,0,0,2.815.487v1.613a16.7,16.7,0,0,1-4.114-.812l1.3-1.288M7.871,3H4.083A1.086,1.086,0,0,0,3,4.083a18.4,18.4,0,0,0,18.4,18.4A1.086,1.086,0,0,0,22.486,21.4V17.625A1.086,1.086,0,0,0,21.4,16.543a12.349,12.349,0,0,1-3.865-.617.909.909,0,0,0-.336-.054,1.109,1.109,0,0,0-.769.314l-2.382,2.382a16.4,16.4,0,0,1-7.134-7.134L9.3,9.051a1.086,1.086,0,0,0,.271-1.1,12.3,12.3,0,0,1-.617-3.865A1.086,1.086,0,0,0,7.871,3Z" transform="translate(-0.743 -0.743)" fill="#1b5984" />
        </svg>
    )
}

export default PhoneIcon
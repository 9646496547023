import React from 'react';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import Icons from '../../../../../components/icons/Icons';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRole } from './RolesAndResponsibilitiesSaga';
import { componentKey, setDeleteRoleModalOpen } from './RolesAndRespomsibilitiiesSlice';
const DeleteRoleModal = ({ close }) => {
	const dispatch = useDispatch();
	const { editedData, deleteRoleModalOpen,currentUUID } = useSelector((state) => state[componentKey]);
	return (
		<ModalComponent
			open={deleteRoleModalOpen}
			title={'Delete Role'}
			close={() => {
				close();
			}}
			icon={<Icons iconName={'closeIcon'} />}
			footerButton={
				<div className='flex justify-between w-[15%]'>
					<Button type="submit" variant={BUTTON_VARIANTS.OUTLINED} customBtnClass="text-met-primary" onClickCb={() => dispatch(setDeleteRoleModalOpen(false))}>
						No
					</Button>
					<Button
						type="submit"
						variant={BUTTON_VARIANTS.CONTAINED}
						customBtnClass="text-met-primary"
						onClickCb={() => {

							dispatch(deleteRole({ uuid: currentUUID }));
						}}
					>
						Yes
					</Button>
				</div>
			}
			enableReinitialize
			customClasses="w-[40%]"
		>
			<div>
				<p>Do you want to delete this role?</p>
			</div>
		</ModalComponent>
	);
};
export default DeleteRoleModal
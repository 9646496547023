import React, { useEffect, useRef, useState } from 'react';
import Tabs from '../../../../components/common-components/Tabs/index';
import CardContainer from '../../../../components/common-components/Cards/Container/CardContainer';
import { myAccountCardsData } from '../../constant';
import Icons from '../../../../assets/svgs';
import { useNavigate } from 'react-router-dom';
import { MAIN_PATHS } from '../../../../libs/constant';
import useGetCurrenPathHook from '../../../../libs/customHooks/useGetCurrenPathHook';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import AddNewPrintConfigurationModal from './PrintConfiguration/AddNewPrintConfigurationModal';
import EditProfileModal from './MyProfile/EditProfileModal';
import ModalComponent from '../../../../components/common-components/modal/ModalComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdatePrintConfigDetails } from './PrintConfiguration/PrintConfigSlice';
import { componentKey } from './SettingTabsSlice';
import { updateNotifications } from './Notification/NotificationsSaga';
import { componentKey as loggedInUserkey } from '../../../../routes/RoutesSlice';
import ShowForAllowedPermissionsOnly from '../../../Wrappers/ShowForAllowedPermissionsOnly';
import { USER_PERMISSIONS } from '../../../../libs/UserPermissionsConstants';

const AppointmentTabContainer = () => {
	const navigate = useNavigate();
	const formRef = useRef();
	const dispatch = useDispatch();
	const { updatedNotifications } = useSelector((state) => state[componentKey])
	const { loggedInUser } = useSelector(state => state[loggedInUserkey])

	const currrentPath = useGetCurrenPathHook(true, 2);
	const [isOpenAddNewPrint, setIsOpenAddNewPrint] = useState(false);
	const [isEditProfile, setIsEditProfile] = useState(false);

	const editProfileHandler = () => {
		setIsEditProfile(!isEditProfile);
	};

	const handleEditProfile = async () => {
		if (formRef.current) {
			await formRef.current.submitForm();

			const isValid = formRef.current.isValid;
			if (!isValid) {
				return;
			}
			editProfileHandler();
		}
	};

	const addAvailabilityStateHandler = () => {
		dispatch(setUpdatePrintConfigDetails(false))
		setIsOpenAddNewPrint(true);
	};

	const getAdditionalButtons = () => {
		switch (currrentPath) {
			case '/my-profile':
				if (loggedInUser?.uuid) {
					return (
						<div className="flex justify-between">
							<ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.EDIT_PROFILE}>
								<Button
									startIcon={{ icon: <Icons iconName="settingsPencilIcon" /> }}
									variant={BUTTON_VARIANTS.OUTLINED}
									customBtnClass="mr-5"
									onClickCb={editProfileHandler}
								>
									Edit Profile
								</Button>
							</ShowForAllowedPermissionsOnly>
						</div>
					);

				}
				else {
					return null
				}
			case '/notification':
				return (
					<div className="flex justify-between">
						<Button
							onClickCb={() => { dispatch(updateNotifications({ payload: updatedNotifications })) }}
							variant={BUTTON_VARIANTS.CONTAINED}
						>
							Save
						</Button>
					</div>
				);
			case '/print-configuration':
				return (
					<div className="flex justify-between">
						<Button
							onClickCb={addAvailabilityStateHandler}
							variant={BUTTON_VARIANTS.CONTAINED}
							startIcon={{ icon: <Icons iconName="settingsPlusIcon" /> }}
						>
							Add New Print Configuration
						</Button>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<>

			<CardContainer>
				<div className="justify-between mb-4 flex font-medium text-rgba-1a1a1acc h-[42px]">
					<div className="flex text-2xl">
						<span onClick={() => navigate(MAIN_PATHS.SETTINGS)} className="hover:cursor-pointer pt-[4px]">
							<Icons iconName="settingsBackArrowIcon" />
						</span>
						<span className="pl-[10px] font-bold">Account Settings</span>
					</div>
					{getAdditionalButtons()}
				</div>
				<Tabs tabs={myAccountCardsData} />
			</CardContainer>
			{isOpenAddNewPrint && (
				<AddNewPrintConfigurationModal
					open={isOpenAddNewPrint}
					close={() => {
						setIsOpenAddNewPrint(false);
					}}
				/>
			)}

			<ModalComponent
				open={isEditProfile}
				title="Edit Profile"
				customClasses="h-[87%] w-[80%]"
				close={editProfileHandler}
				customBodyClasses="h-[87%]"
				footerButton={
					<Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} onClickCb={handleEditProfile}>
						Save
					</Button>
				}
			>
				<EditProfileModal ref={formRef} close={editProfileHandler} />
			</ModalComponent>
		</>
	);
};

export default AppointmentTabContainer;

import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import CardContainer from '../../../../components/common-components/Cards/Container/CardContainer';
import { MAIN_PATHS, MET_COLORS } from '../../../../libs/constant';
import { providerGroupData } from '../../constant';
import Tabs from '../../../../components/common-components/Tabs';
import useGetCurrenPathHook from '../../../../libs/customHooks/useGetCurrenPathHook';
import { useNavigate } from 'react-router-dom';
import LocationModal from './Locations/LocationModal';

import {
	componentKey as locationComponentKey,
	setEditLocationData,
	setIsLocationModalOpen,
	setIsNewLocationAdd,
} from './Locations/LocationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey as profileComponentKey, setIsEditProfileModalOpen } from './Profile/ProfileSlice';
import ProfileModal from './Profile/ProfileModal';
import Icons from '../../../../components/icons/Icons';
import {
	componentKey as usersComponentKey,
	setIsFilterOpen,
	setISAddProviderUserModalOpen,
	setIsNewProviderUserAdd,
	setProviderUsersData,
	setResetFilters,
	setIsAddUSerStaffModalOpen,
	setIsNewUserAdd,
} from './Users/UsersSlice';
import AddProviderUserModal from './Users/AddProviderUserModal';
import { getAllProviders } from './Users/UsersSaga';
import {
	componentKey as departmentKey,
	setEditDepartmentData,
	setIsDepartmentModalOpen,
	setIsNewDepartmentAdd,
} from './Departments/DepartmentsSlice';
import DepartmentsModal from './Departments/DepartmentsModal';
import {
	componentKey as RolesAndRespKey,
	setIsNewAddRoleModalOpen,
	setIsNewRoleAdd,
} from './RolesAndResponsibilities/RolesAndRespomsibilitiiesSlice';
import AddNewRole from './RolesAndResponsibilities/AddNewRole';
import { componentKey } from '../../../Wrappers/Dataloader/DataloaderSlice';
import { getProviderGroupDetails } from './Profile/ProfileSaga';
import AddUserStaffModal from './Users/AddUserStaffModal';
import { FILTERS } from './Users/constant';
import ShowForAllowedPermissionsOnly from '../../../Wrappers/ShowForAllowedPermissionsOnly';
import { USER_PERMISSIONS } from '../../../../libs/UserPermissionsConstants';

const ProviderGroupTabContainer = () => {
	const [hospitalName, setHospitalName] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const currrentPath = useGetCurrenPathHook(true, 2);
	const { isLocationModalOpen, allProviderGroupLocation } = useSelector((state) => state[locationComponentKey]);
	const { isEditProfileModalOpen, providerGroupDetails } = useSelector((state) => state[profileComponentKey]);
	const { isFilterOpen, isProviderUserModalOpen, providerUsersData, isAddStaffUserModalOpen } = useSelector(
		(state) => state[usersComponentKey]
	);
	const { isDepartmentModalOpen, editDepartmentData } = useSelector((state) => state[departmentKey]);
	const { isNewAddRoleModalOpen, editedRoleData } = useSelector((state) => state[RolesAndRespKey]);
	const { usersRolesAndResp } = useSelector((state) => state[componentKey]);
	const getAdditionalButtons = () => {
		switch (currrentPath) {
			case '/profile':
				return (
					<div className="flex justify-between">
						<ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.EDIT_PRACTICE_GROUP}>
							<Button
								startIcon={{ icon: <Icons iconName="editIcon" /> }}
								variant={BUTTON_VARIANTS.OUTLINED}
								customBtnClass="mr-5"
								onClickCb={() => {
									dispatch(setIsEditProfileModalOpen(true));
								}}
							>
								Edit Profile
							</Button>
						</ShowForAllowedPermissionsOnly>
					</div>
				);
			case '/locations':
				return (
					<div className="flex justify-between">
						<Button
							variant={BUTTON_VARIANTS.CONTAINED}
							customBtnClass="mr-5"
							startIcon={{ icon: <Icons iconName="plusVectorIcon" color={MET_COLORS.GRAY} /> }}
							onClickCb={() => {
								dispatch(setEditLocationData(''))
								dispatch(setIsNewLocationAdd(true));
								dispatch(setIsLocationModalOpen(true));
							}}
						>
							Add Location
						</Button>
					</div>
				);
			case '/departments':
				return (
					<div className="flex justify-between">
						<Button
							onClickCb={() => {
								dispatch(setEditDepartmentData({}));
								dispatch(setIsNewDepartmentAdd(true));
								dispatch(setIsDepartmentModalOpen(!isDepartmentModalOpen));
							}}
							variant={BUTTON_VARIANTS.CONTAINED}
							startIcon={{ icon: <Icons iconName="plusVectorIcon" color={MET_COLORS.GRAY} /> }}
						>
							Add Department
						</Button>
					</div>
				);
			case '/users':
				return (
					<div className="flex justify-between w-[25%]">
						{!isFilterOpen && (
							<Button
								variant={BUTTON_VARIANTS.OUTLINED}
								// startIcon={{ icon: <Icons iconName="plusVectorIcon" /> }}
								onClickCb={() => {
									dispatch(setIsFilterOpen(!isFilterOpen));
								}}
							>
								Filters
							</Button>
						)}
						{isFilterOpen && (
							<Button
								variant={BUTTON_VARIANTS.OUTLINED}
								onClickCb={() => {
									dispatch(setIsFilterOpen(!isFilterOpen));
									dispatch(setResetFilters(FILTERS))

									dispatch(getAllProviders({ type: 'user' }));
								}}
							>
								Clear Filters
							</Button>
						)}
						<ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.CREATE_USER}>
							<Button
								onClickCb={() => {
									dispatch(setProviderUsersData({}));
									dispatch(setIsNewProviderUserAdd(true));
									dispatch(setISAddProviderUserModalOpen(!isProviderUserModalOpen));
								}}
								variant={BUTTON_VARIANTS.OUTLINED}
								startIcon={{ icon: <Icons iconName="plusVectorIcon" color={MET_COLORS.PRIMARY} /> }}
							>
								Add User
							</Button>{' '}
						</ShowForAllowedPermissionsOnly>
						<Button
							onClickCb={() => {
								dispatch(setIsAddUSerStaffModalOpen(true))
								dispatch(setIsNewUserAdd(true))
							}}
							variant={BUTTON_VARIANTS.OUTLINED}
							startIcon={{ icon: <Icons iconName="plusVectorIcon" color={MET_COLORS.PRIMARY} /> }}
						>
							Add Staff User
						</Button>
					</div>
				);
			case '/roles-responsibilties':
				return (
					<div className="flex justify-between">
						{/* <Button
							onClickCb={() => {
								dispatch(setIsNewAddRoleModalOpen(true));
								dispatch(setIsNewRoleAdd(true));
							}}
							variant={BUTTON_VARIANTS.CONTAINED}
							startIcon={{ icon: <Icons iconName="plusVectorIcon" color={MET_COLORS.GRAY} /> }}
						>
							Add New Role
						</Button> */}
					</div>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		dispatch(getProviderGroupDetails());
	}, []);
	useEffect(() => {
		setHospitalName(providerGroupDetails?.groupName);
	}, [providerGroupDetails?.groupName]);

	return (
		<>
			<CardContainer>
				<div className="justify-between mb-4 flex font-medium text-rgba-1a1a1acc h-[42px]">
					<div className="flex text-2xl">
						<span onClick={() => navigate(MAIN_PATHS.SETTINGS)} className="hover:cursor-pointer pt-[4px]">
							<Icons iconName="arrowBackIcon" />
						</span>
						<span className="pl-[10px] font-bold">Provider Group</span>
					</div>
					{getAdditionalButtons()}
				</div>
				<Tabs tabs={providerGroupData} />
			</CardContainer>

			{isLocationModalOpen && (
				<LocationModal
					open={isLocationModalOpen}
					close={() => {
						dispatch(setIsLocationModalOpen(false));
					}}
					hospitalName={hospitalName}
				/>
			)}
			{isEditProfileModalOpen && (
				<ProfileModal
					open={isEditProfileModalOpen}
					close={() => dispatch(setIsEditProfileModalOpen(false))}
					profileData={providerGroupDetails}
				/>
			)}
			{isProviderUserModalOpen && (
				<AddProviderUserModal
					open={isProviderUserModalOpen}
					close={() => dispatch(setISAddProviderUserModalOpen(false))}
					providerUserData={providerUsersData}
				/>
			)}
			{isDepartmentModalOpen && (
				<DepartmentsModal
					open={isDepartmentModalOpen}
					close={() => dispatch(setIsDepartmentModalOpen(false))}
					editDepartmentData={editDepartmentData}
				/>
			)}
			{isNewAddRoleModalOpen && (
				<AddNewRole
					open={isNewAddRoleModalOpen}
					close={() => dispatch(setIsNewAddRoleModalOpen(false))}
					editRoleData={editedRoleData}
				/>
			)}
			{
				isAddStaffUserModalOpen && (
					<AddUserStaffModal
						open={isAddStaffUserModalOpen}
						close={() => {dispatch(setIsAddUSerStaffModalOpen(false))
							dispatch(setProviderUsersData({}))

						}}
						providerUserData={providerUsersData}
					/>
				)
			}
		</>
	);
};

export default ProviderGroupTabContainer;

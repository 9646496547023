 const EyeSlashIcon = ({onClick = () => {}}) => {
	return (
		<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		onClick= {onClick}

	>
		<path d="M12 4.5C7.305 4.5 3.291 7.366 1.5 12c1.791 4.634 5.805 7.5 10.5 7.5s8.709-2.866 10.5-7.5c-1.791-4.634-5.805-7.5-10.5-7.5zM12 16.5c-2.485 0-4.5-2.015-4.5-4.5s2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5-2.015 4.5-4.5 4.5z"/>
		<path d="M12 10.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z"/>
		<path d="M2 2l20 20" stroke="black" strokeWidth="2" />
	</svg>
	);
};
export default EyeSlashIcon
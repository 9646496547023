import React, { useEffect, useState } from 'react';
import useBase64Image from '../../../containers/SettingsTab/MyAccountCard/Tabs/PrintConfiguration/useS3Image';

const colours = ["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"];
const Avatar = ({ url, name, customClass = "", width = 32, height = 32, fontSize = 1, textCenter = "" }) => {

    const [imgSrc, setImgSrc] = useState("");

    const getBase64 = useBase64Image();

    useEffect(() => {
        const getBase64Data = async (url) => {
            const base64Data = await getBase64(url);
            if (base64Data !== null) {
                setImgSrc(base64Data);
            }
        };
        getBase64Data(url);
    }, [url]);

    const handleImageError = () => {
        if (!name) return
        const nameSplit = name.split(" ");
        const initials = nameSplit.map((name) => name.charAt(0).toUpperCase()).join("")
        var charIndex = initials.charCodeAt(0) - 65;
        const colourIndex = charIndex % 19;
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext('2d');
        context.beginPath();
        context.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2, 0, Math.PI * 2);
        context.clip();

        context.fillStyle = colours[colourIndex];
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.font = `${fontSize}rem 'Roboto'`;
        context.textAlign = "center";
        context.textBaseline = `${textCenter}`;
        context.fillStyle = "white";
        const nameArr = name.split(' ');
        const initialsText = nameArr[0]?.charAt(0) + nameArr[1]?.charAt(0);
        const x = canvas.width / 2;
        const y = (canvas.height / 2) + 4;
        context.fillText(initialsText, x, y);
        const dataUrl = canvas.toDataURL();

        setImgSrc(dataUrl);
    };

    useEffect(() => {
        if (name !== undefined && !url?.length) {
            handleImageError();
        }
    }, [name, imgSrc]);

    return (
        <div>
            <img width={width} height={height} className={customClass} src={imgSrc} alt="" onError={handleImageError} />
        </div>
    );
};

export default Avatar;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useGetCurrenPathHook = (isConsiderLevel = false, level = 0) => {
	const location = useLocation();
	const [path, setPath] = useState('');
	useEffect(() => {
		const pathname = location.pathname;
		const segments = pathname.split('/').filter(Boolean);
		const lastSegment = segments.length > 0 ? segments[isConsiderLevel ? level : segments.length - 1] : '/';
		setPath('/' + lastSegment);
	}, [location.pathname]);

	return path;
};

export default useGetCurrenPathHook;

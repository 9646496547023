import { Form, Formik } from 'formik';
import React, { useEffect } from 'react'
import { FORM_FIELDS_NAMES } from './Constants';
import { getValidationSchema } from '../../../libs/formsUtils';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setPaymentPaginationState } from './PatientPaymentSlice';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import DatePicker from '../../../components/common-components/datePicker';
import Icons from '../../../components/icons/Icons';
import Input from '../../../components/common-components/input/Input';
import useGarbageCollector from '../../../libs/garbagecollector/garbageCollectorHooks';

function Filters() {
    const dispatch = useDispatch()
    const { isPatientPaymentFilterOpen } = useSelector((state) => state[componentKey]);

    const fields = [];
    const validationSchema = getValidationSchema(fields);

    return (
        <Formik
            initialValues={{
                [FORM_FIELDS_NAMES.SEARCH_BY_DATE]: '',
                [FORM_FIELDS_NAMES.SEARCH_PATIENT]: '',
                [FORM_FIELDS_NAMES.PATIENT_NAME]: '',
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => {
                dispatch(setPaymentPaginationState({
                    date: values[FORM_FIELDS_NAMES.SEARCH_BY_DATE],
                    receipt: values[FORM_FIELDS_NAMES.SEARCH_OPTIONS],
                    name: values[FORM_FIELDS_NAMES.SEARCH_PATIENT]
                }));

            }}
        >
            {({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, handleBlur }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <div className='p-4'>
                            {isPatientPaymentFilterOpen && (
                                <div className="flex justify-between p-[20px] items-end border-[1px] border-[#1A1A1A33] rounded-[10px] border-opacity-25 ">
                                    <div className='flex column-gap-20px '>
                                        <div className='flex-1 min-w-410 max-w-440 '>
                                            <DatePicker
                                                label="Search By Date"
                                                placeholder="Choose Date"
                                                value={values[FORM_FIELDS_NAMES.SEARCH_BY_DATE]}
                                                name={FORM_FIELDS_NAMES.SEARCH_BY_DATE}
                                                onChangeCb={(date) => {
                                                    setFieldValue(FORM_FIELDS_NAMES.SEARCH_BY_DATE, date);
                                                }}
                                            />
                                        </div>
                                        <div className='flex-1 min-w-410 max-w-440'>
                                            <Input
                                                // endIcon={<Icons iconName={'searchIcon'} />}
                                                placeholder="Patient"
                                                label="Search Patient"
                                                name={FORM_FIELDS_NAMES.SEARCH_PATIENT}
                                                value={values[FORM_FIELDS_NAMES.SEARCH_PATIENT]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(FORM_FIELDS_NAMES.SEARCH_PATIENT, e.target.value);
                                                }}
                                                onBlurCb={handleBlur}
                                            />
                                        </div>
                                        <div className='flex-1 min-w-410 max-w-440'>
                                            <Input
                                                // endIcon={<Icons iconName={'searchIcon'} />}
                                                placeholder="Receipt #"
                                                label="Search Options"
                                                name={FORM_FIELDS_NAMES.SEARCH_OPTIONS}
                                                value={values[FORM_FIELDS_NAMES.SEARCH_OPTIONS]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(FORM_FIELDS_NAMES.SEARCH_OPTIONS, e.target.value);
                                                }}
                                                onBlurCb={handleBlur}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            type='submit'
                                            variant={BUTTON_VARIANTS.OUTLINED}
                                            customBtnClass="text-met-primary "
                                        >
                                            Apply Filter
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    )
}

export default Filters
import AppDataService from './AppDataService';

export default class PatientDashboardDataService {
	static async getAllPatientAllergies(url, patientId, params) {
		return await AppDataService.get(`${url}/${patientId}`, { params });
	}

	static async postPatientAllergies(url, patientId, data) {
		return await AppDataService.post(`${url}/${patientId}`, data);
	}

	static async deletePatientAllergies(url, allergyId, patientId) {
		return await AppDataService.delete(`${url}/${allergyId}/${patientId}`);
	}

	static async getPatientAllergy(url, allergyId, patientId) {
		return await AppDataService.get(`${url}/${allergyId}/${patientId}`);
	}

	static async updatePatientAllergy(url, allergyId, patientId, data) {
		return await AppDataService.patch(`${url}/${allergyId}/${patientId}`, data);
	}

	static async getAllPatientDiagnoses(url, patientId, params) {
		return await AppDataService.get(`${url}/${patientId}`, { params });
	}

	static async postPatientDiagnoses(url, patientId, data) {
		return await AppDataService.post(`${url}/${patientId}`, data);
	}

	static async deletePatientDiagnoses(url, diagnosesId, patientId) {
		return await AppDataService.delete(`${url}/${diagnosesId}/${patientId}`);
	}

	static async getPatientDiagnoses(url, diagnosesId, patientId) {
		return await AppDataService.get(`${url}/${diagnosesId}/${patientId}`);
	}

	static async updatePatientDiagnoses(url, diagnosesId, patientId, data) {
		return await AppDataService.patch(`${url}/${diagnosesId}/${patientId}`, data);
	}

	static async getAllPatientMedications(url, patientId, params) {
		return await AppDataService.get(`${url}/${patientId}`, { params });
	}

	static async postPatientMedications(url, patientId, data) {
		return await AppDataService.post(`${url}/${patientId}`, data);
	}

	static async updatePatientMedications(url, medicationId, patientId, data) {
		return await AppDataService.patch(`${url}/${medicationId}/${patientId}`, data);
	}

	static async deletePatientMedications(url, medicationId, patientId) {
		return await AppDataService.delete(`${url}/${medicationId}/${patientId}`);
	}

	static async getAllPatientVaccines(url, patientId, params) {
		return await AppDataService.get(`${url}/${patientId}`, { params });
	}

	static async postPatientVaccines(url, patientId, data) {
		return await AppDataService.post(`${url}/${patientId}`, data);
	}

	static async updatePatientVaccines(url, vaccineId, patientId, data) {
		return await AppDataService.patch(`${url}/${vaccineId}/${patientId}`, data);
	}

	static async deletePatientVaccines(url, vaccineId, patientId) {
		return await AppDataService.delete(`${url}/${vaccineId}/${patientId}`);
	}

	static async getAllPatientVitals(url, patientId, params) {
		return await AppDataService.get(`${url}/${patientId}`, { params });
	}

	static async postPatientVitals(url, patientId, data) {
		return await AppDataService.post(`${url}/${patientId}`, data);
	}

	static async getAllPatientLabResult(url, patientId, params) {
		return await AppDataService.get(`${url}/${patientId}`, { params });
	}

	static async postPatientLabResult(url, patientId, data) {
		return await AppDataService.post(`${url}/${patientId}`, data);
	}

	static async updatePatientLabResult(url, labresultId, patientId, data) {
		return await AppDataService.patch(`${url}/${labresultId}/${patientId}`, data);
	}

	static async deletePatientLabResult(url, labresultId, patientId) {
		return await AppDataService.delete(`${url}/${labresultId}/${patientId}`);
	}

	static async getAllLabs(url) {
		return await AppDataService.get(url);
	}

	static async getAllPatientHistory(url, patientId, params) {
		return await AppDataService.get(`${url}/${patientId}`, { params });
	}

	static async postPatientHistory(url, patientId, data) {
		return await AppDataService.post(`${url}/${patientId}`, data);
	}

	static async updatePatientHistory(url, historyId, patientId, data) {
		return await AppDataService.patch(`${url}/${historyId}/${patientId}`, data);
	}

	static async deletePatientHistory(url, historyId, patientId) {
		return await AppDataService.delete(`${url}/${historyId}/${patientId}`);
	}

	static async getAllPatientNotes(url, params) {
		return await AppDataService.get(`${url}`, { params });
	}

	static async postPatientNotes(url, data) {
		return await AppDataService.post(`${url}`, data);
	}

	static async deletePatientNotes(url, noteId) {
		return await AppDataService.delete(`${url}/${noteId}`);
	}

	static async getAllPatientDocuments(url, patientId, params) {
		return await AppDataService.get(`${url}/${patientId}`, { params });
	}

	static async postPatientDocuments(url, data) {
		return await AppDataService.post(`${url}`, data);
	}

	static async updatePatientDocuments(url, historyId, patientId, data) {
		return await AppDataService.patch(`${url}/${historyId}/${patientId}`, data);
	}

	static async deletePatientDocuments(url, documentId) {
		return await AppDataService.delete(`${url}/${documentId}`);
	}

	static async getAllPatientDocumentsType(url) {
		return await AppDataService.get(url);
	}

	static async postPatientDocumentsType(url, data) {
		return await AppDataService.post(url, data);
	}

	static async updatePatientDocumentsType(url, documentTypeId, data) {
		return await AppDataService.patch(`${url}/${documentTypeId}`, data);
	}

	static async deletePatientDocumentsType(url, documentTypeId) {
		return await AppDataService.delete(`${url}/${documentTypeId}`);
	}

	static async getAllPatientImagingResult(url, patientId, params) {
		return await AppDataService.get(`${url}/${patientId}`, { params });
	}

	static async postPatientImagingResult(url, patientId, data) {
		return await AppDataService.post(`${url}/${patientId}`, data);
	}

	static async updatePatientImagingResult(url, imagingresultId, patientId, data) {
		return await AppDataService.patch(`${url}/${imagingresultId}/${patientId}`, data);
	}

	static async deletePatientImagingResult(url, imagingresultId, patientId) {
		return await AppDataService.delete(`${url}/${imagingresultId}/${patientId}`);
	}

	static async getAllImagingCentres(url) {
		return await AppDataService.get(url);
	}

	static async updatePatientProfiles(url, uuid, data) {
		return await AppDataService.patch(`${url}/${uuid}`, data);
	}

	static async updatePreference(url, uuid, data) {
		return await AppDataService.patch(`${url}/${uuid}/preferences`, data)
	}
	static async getAllPatientVitalsForFilter(url, patientId, params) {
		return await AppDataService.get(`${url}/${patientId}`, { params });
	}
}
import React from 'react';
import { providerGroupData } from '../constant';
import CardComponent from '../../../components/common-components/SettingsCard/Card';

function Index() {
    return (
        <CardComponent
            cardName="Provider Group"
            cardLogoName="settingProviderGroupIcon"
            cardoptions={providerGroupData}
            extraClass='ml-[20px]'
        />
    );
}

export default Index;

import React, { useState } from 'react';
import { getValidationSchema } from '../../../libs/formsUtils';
import { componentKey } from '../ShedulingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import Icons from '../../../components/icons/Icons';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import DatePicker from '../../../components/common-components/datePicker';
import Input from '../../../components/common-components/input/Input';
import Label from '../../../components/common-components/label/Label';
import { FORM_FIELDS_FOR_ADD_NEW_PATIENT } from '../constant';
import { postAddNewPatient } from '../SchedulingSaga';
import Checkbox from '../../../components/common-components/checkbox/Checkbox';
import General from '../../../libs/utility/General';
import { VALIDATION_REGEX } from '../../../libs/constant';
const AddNewPatient = ({ close }) => {
	const dispatch = useDispatch();
	const {  addNewPatientData, isAddNewPatientModalOpen } = useSelector(
		(state) => state[componentKey]
	);

	const fields = [
		{ fieldName: FORM_FIELDS_FOR_ADD_NEW_PATIENT.FIRSTNAME, isRequired: true, regexPattern:VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_FOR_ADD_NEW_PATIENT.LASTNAME, isRequired: true, regexPattern:VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_FOR_ADD_NEW_PATIENT.MIDDLENAME, isRequired: false },
		{ fieldName: FORM_FIELDS_FOR_ADD_NEW_PATIENT.GENDER, isRequired: true, isDropdown: true },
		{ fieldName: FORM_FIELDS_FOR_ADD_NEW_PATIENT.EMAIL, isRequired: true, isEmail:true },
		{
			fieldName: FORM_FIELDS_FOR_ADD_NEW_PATIENT.CONTACT_NUMBER,
			isRequired: true,
			regexPattern: VALIDATION_REGEX.MOBILE_NUM_REGEX,
		},
		{ fieldName: FORM_FIELDS_FOR_ADD_NEW_PATIENT.DOB, isRequired: true },
		{
			fieldName: FORM_FIELDS_FOR_ADD_NEW_PATIENT.AADHAR_NUMBER,
			isRequired: false,
			regexPattern: VALIDATION_REGEX.AADHAR_REGEX,
		},
	];
	const validationSchema = getValidationSchema(fields);

	return (
		<Formik
			initialValues={{
				// ...addNewPatientData,
				[FORM_FIELDS_FOR_ADD_NEW_PATIENT.FIRSTNAME]: addNewPatientData?.firstName,
				[FORM_FIELDS_FOR_ADD_NEW_PATIENT.LASTNAME]: addNewPatientData?.lastName,
				[FORM_FIELDS_FOR_ADD_NEW_PATIENT.MIDDLENAME]: addNewPatientData?.middleName,
				[FORM_FIELDS_FOR_ADD_NEW_PATIENT.GENDER]: addNewPatientData?.gender,
				[FORM_FIELDS_FOR_ADD_NEW_PATIENT.EMAIL]: addNewPatientData?.email,
				[FORM_FIELDS_FOR_ADD_NEW_PATIENT.CONTACT_NUMBER]: addNewPatientData?.mobileNumber,
				[FORM_FIELDS_FOR_ADD_NEW_PATIENT.DOB]: addNewPatientData?.dob,
				// [FORM_FIELDS_FOR_ADD_NEW_PATIENT.AADHAR_NUMBER]: General.formateAadhar((
				// 	addNewPatientData?.aadhar || ''
				// ).toString()),
				[FORM_FIELDS_FOR_ADD_NEW_PATIENT.AADHAR_NUMBER]: addNewPatientData?.aadhar || '',
				[FORM_FIELDS_FOR_ADD_NEW_PATIENT.IS_PATIENT_AADHAR]: addNewPatientData?.isPatientsAadhar,
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				const adharNum = values.aadhar?.replace(/-/g, '');
				const payload = {
					...values,
					gender: values.gender.value,
					aadhar: Number(adharNum) ,
					registrationDate: new Date(),
					isPatientsAadhar:  values?.isPatientsAadhar === true ? false : true
				};
				dispatch(postAddNewPatient({ addNewPatientData: payload }));
			}}
		>
			{({ submitForm, isSubmitting, handleBlur, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={isAddNewPatientModalOpen}
							validationSchema={validationSchema}
							title={'New Patient'}
							close={() => {
								close();
							}}
							icon={<Icons iconName="closeIcon" />}
							footerButton={
								<Button
									type="submit"
									variant={BUTTON_VARIANTS.CONTAINED}
									customBtnClass="text-met-primary"
								>
									Save
								</Button>
							}
							customClasses="w-[50%] overflow-visible"
							enableReinitialize
							// hideOverflow={true}
						>
							<div className="flex flex-wrap justify-between">
								<div className="w-[32.5%] mt-5">
									<Input
										label="First Name"
										isRequired={true}
										placeholder="Enter First Name"
										name={FORM_FIELDS_FOR_ADD_NEW_PATIENT.FIRSTNAME}
										value={values[FORM_FIELDS_FOR_ADD_NEW_PATIENT.FIRSTNAME]}
										onChangeCb={(e) => {
											setFieldValue(FORM_FIELDS_FOR_ADD_NEW_PATIENT.FIRSTNAME, e.target.value);
										}}
									/>
								</div>
								<div className="w-[32.5%] mt-5">
									<Input
										label="Middle Name"
										// isRequired={true}
										placeholder="Enter Middle Name"
										name={FORM_FIELDS_FOR_ADD_NEW_PATIENT.MIDDLENAME}
										value={values[FORM_FIELDS_FOR_ADD_NEW_PATIENT.MIDDLENAME]}
										onChangeCb={(e) => {
											setFieldValue(FORM_FIELDS_FOR_ADD_NEW_PATIENT.MIDDLENAME, e.target.value);
										}}
									/>
								</div>{' '}
								<div className="w-[32.5%] mt-5">
									<Input
										label="Last Name"
										isRequired={true}
										placeholder="Enter Last Name"
										name={FORM_FIELDS_FOR_ADD_NEW_PATIENT.LASTNAME}
										value={values[FORM_FIELDS_FOR_ADD_NEW_PATIENT.LASTNAME]}
										onChangeCb={(e) => {
											setFieldValue(FORM_FIELDS_FOR_ADD_NEW_PATIENT.LASTNAME, e.target.value);
										}}
									/>
								</div>
								<div className="w-[32.5%] mt-5">
									<DatePicker
										isRequired={true}
										label="Date Of Birth"
										placeholder="Choose Date"
										name={FORM_FIELDS_FOR_ADD_NEW_PATIENT.DOB}
										value={values[FORM_FIELDS_FOR_ADD_NEW_PATIENT.DOB]}
										onChangeCb={(date) => {
											setFieldValue(FORM_FIELDS_FOR_ADD_NEW_PATIENT.DOB, date);
										}}
										maxDate={new Date()}
									/>
								</div>
								<div className="w-[32.5%] mt-5">
									<SelectDropdown
										placeholder="Select"
										label="Gender"
										isRequired={true}
										name={FORM_FIELDS_FOR_ADD_NEW_PATIENT.GENDER}
										value={values[FORM_FIELDS_FOR_ADD_NEW_PATIENT.GENDER] || []}
										onChangeCb={(val) => {
											setFieldValue(FORM_FIELDS_FOR_ADD_NEW_PATIENT.GENDER, val);
										}}
										options={[
											{ label: 'Male', value: 'male' },
											{ label: 'Female', value: 'female' },
											{ label: 'Other', value: 'other' },
										]}
									/>
								</div>
								<div className="w-[32.5%] mt-5">
									<Input
										label="Email"
										isRequired={true}
										placeholder="Enter Email"
										name={FORM_FIELDS_FOR_ADD_NEW_PATIENT.EMAIL}
										value={values[FORM_FIELDS_FOR_ADD_NEW_PATIENT.EMAIL]}
										onChangeCb={(e) => {
											setFieldValue(FORM_FIELDS_FOR_ADD_NEW_PATIENT.EMAIL, e.target.value);
										}}
									/>
								</div>
								<div className="w-[32.5%] mt-5">
									<Input
										label="Mobile Number"
										isRequired={true}
										placeholder="Enter Mobile Number"
										name={FORM_FIELDS_FOR_ADD_NEW_PATIENT.CONTACT_NUMBER}
										value={values[FORM_FIELDS_FOR_ADD_NEW_PATIENT.CONTACT_NUMBER]}
										onChangeCb={(e) => {
											setFieldValue(
												FORM_FIELDS_FOR_ADD_NEW_PATIENT.CONTACT_NUMBER,
												General?.sanitizeNumbers(e.target.value)
											);
										}}
									/>
								</div>
								<div className="w-[32.5%] mt-5 ">
									<div className="flex items-center">
										<Label fontWeight="bold" asteriskPosition="end">
											Aadhar Number
										</Label>
										<div className="flex pl-2">
											<Checkbox
												name={FORM_FIELDS_FOR_ADD_NEW_PATIENT.IS_PATIENT_AADHAR}
												checked={
													values[FORM_FIELDS_FOR_ADD_NEW_PATIENT.IS_PATIENT_AADHAR]
												}
												onChangeCb={(e) => {
													// setIsPatientsAadhar(!isPatientsAadhar);
													setFieldValue(
														FORM_FIELDS_FOR_ADD_NEW_PATIENT.IS_PATIENT_AADHAR,
													(e)
													);
												}}
											/>
											<div className="pl-2 text-gray-500">
												<Label fontWeight="normal">Aadhar Not Provided</Label>
											</div>
										</div>
									</div>
									<div className="">
										<Input
											placeholder="1234-5678-9874"
											label=""
											asteriskPosition="end"
											name={FORM_FIELDS_FOR_ADD_NEW_PATIENT.AADHAR_NUMBER}
											value={values[FORM_FIELDS_FOR_ADD_NEW_PATIENT.AADHAR_NUMBER]}
											onChangeCb={(e) => {
												const formattedValue = General.formateAadhar(e.target.value);

												setFieldValue(
													FORM_FIELDS_FOR_ADD_NEW_PATIENT.AADHAR_NUMBER,
													formattedValue
												);
											}}
											onBlurCb={handleBlur}
											// disabled={isFormSubmitted.patientDetails}
										/>
									</div>
								</div>
								<div className="w-[32.5%] mt-5"></div>
							</div>
							<div className="pt-2">
								<Label asteriskPosition="start" isRequired={true}>
									Indicates mandatory fields
								</Label>
							</div>
						</ModalComponent>
					</Form>
				);
			}}
		</Formik>
	);
};
export default AddNewPatient;

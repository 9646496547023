import React, { useState } from 'react';
import Icons from '../../../components/icons/Icons';
import { MET_COLORS } from '../../../libs/constant';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import Button from '../../../components/common-components/button/Button';
import FaceSheetCard from '../../Patients/PatientDashboard/Dashboard/FaceSheet/FaceSheetCard';
import General from '../../../libs/utility/General';

const HeaderAndDescriptionCard = ({ id, keyName, encounterDetails }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = async () => {
        if (id) {
            const textArea = document.getElementById(id);
            if (textArea) {
                const description = textArea.textContent || '';
                try {
                    await navigator.clipboard.writeText(description);
                    setIsCopied(true);
                    setTimeout(() => setIsCopied(false), 2000);
                } catch (err) {
                    console.error('Failed to copy text: ', err);
                }
            }
        }
    }

    const title = keyName;
    const description = encounterDetails[keyName] || '-';

    return (
        <FaceSheetCard
            title={General.capitalizeFirstLetter(
                title?.replace(/([A-Z])/g, ' $1').trim()
            )}
            iconbtn={
                <Button
                    variant={BUTTON_VARIANTS.TEXT}
                    customBtnClass="fs-14 font-bold text-met-primary"
                    style={{
                        height: '19px',
                        backgroundColor: isCopied ? '#CCECFF80' : 'inherit',
                        padding: isCopied ? '9px' : '0px',
                        borderRadius: isCopied ? '15px' : '0px',
                    }}
                    onClickCb={handleCopyClick}
                >
                    <Icons iconName={'copyIcon'} color={MET_COLORS.PRIMARY}></Icons> {isCopied ? 'Copied' : 'Copy'}
                </Button>
            }
            textColorClass={'text-met-primary'}
            customClassName="h-full"
        >
            <div className={`mb-4`}>
                <div className="flex items-center mb-1">
                    <div id={id} className={`fs-14`}>{description}</div>
                </div>
            </div>


        </FaceSheetCard>
    );
};

export default HeaderAndDescriptionCard;
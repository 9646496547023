/* eslint-disable react/no-unknown-property */
export const SettingsMyAccountIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
			<g id="Group_96323" data-name="Group 96323" transform="translate(-797 -132)">
				<rect
					id="Rectangle_36519"
					data-name="Rectangle 36519"
					width="70"
					height="70"
					rx="5"
					transform="translate(797 132)"
					fill="rgba(204,236,255,0.5)"
				/>
				<g id="Icon" transform="translate(806.75 141.75)">
					<path
						id="Path_205934"
						data-name="Path 205934"
						d="M25.25,1.25a24,24,0,1,0,24,24A24.012,24.012,0,0,0,25.25,1.25Zm0,3.349A20.651,20.651,0,1,1,4.6,25.25,20.662,20.662,0,0,1,25.25,4.6Z"
						transform="translate(0 0)"
						fill="#1b5984"
						fillRule="evenodd"
					/>
					<path
						id="Path_205935"
						data-name="Path 205935"
						d="M16.979,4.25a9.229,9.229,0,1,0,9.229,9.229A9.233,9.233,0,0,0,16.979,4.25Zm0,3.257a5.972,5.972,0,1,1-5.972,5.972A5.974,5.974,0,0,1,16.979,7.507Z"
						transform="translate(8.271 3.515)"
						fill="#1b5984"
						fillRule="evenodd"
					/>
					<path
						id="Path_205936"
						data-name="Path 205936"
						d="M6.29,24.525a21.208,21.208,0,0,1,33.213,0,1.725,1.725,0,0,0,2.361.319,1.594,1.594,0,0,0,.33-2.28A24.405,24.405,0,0,0,22.9,13.25,24.405,24.405,0,0,0,3.6,22.564a1.594,1.594,0,0,0,.33,2.28A1.725,1.725,0,0,0,6.29,24.525Z"
						transform="translate(2.354 15.042)"
						fill="#1b5984"
						fillRule="evenodd"
					/>
				</g>
			</g>
		</svg>
	);
};

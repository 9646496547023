import React, { useState } from 'react'
import ModalComponent from '../../../components/common-components/modal/ModalComponent'
import Button from '../../../components/common-components/button/Button'
import Checkbox from '../../../components/common-components/checkbox/Checkbox'
import { useDispatch, useSelector } from 'react-redux'
import { updatePatientDetails } from '../../../pages/Patients/AddPatient/AddPatientSaga'
import { componentKey } from '../../../pages/Patients/AddPatient/AddPatientSlice'

const contentData = [" I hereby authorize Health Care Services to use the telehealth practice platform for telecommunication for evaluating, testing and diagnosing my medical condition.",
    "I understand that technical difficulties may occur before or during the telehealth sessions and my appointment cannot be started or ended as intended.",
    " I accept that the professionals can contact interactive sessions with video call; however, I am informed that the sessions can be conducted via regular voice communication if the technical requirements such as Internet speed cannot be met.",
    " I understand that my current insurance may not cover the additional fees of the telehealth practices and I may be responsible for any fee that my insurance company does not cover.",
    "I agree that my medical records on telehealth can be kept for further evaluation, analysis and documentation, and in all of these, my information will be kept private."]

const PrivacyConsentFormModal = ({ open, close, onClickCb, onTermsAcceptCb = () => { } }) => {
    const [isChecked, setIsChecked] = useState(false);

    const dispatch = useDispatch()
    const { newlyAddedPatientId } = useSelector(state => state[componentKey])

    const handleSubmitClick = () => {
        dispatch(updatePatientDetails({ patientId: newlyAddedPatientId, payload: { isPatientOnboarded: isChecked } }))
    }

    return (
        <ModalComponent customClasses='w-[60%]' title="Patient Consent form" subtitle="Please completely review the terms and conditon below." open={open} close={close} footerButton={<Button onClickCb={handleSubmitClick} customBtnClass={!isChecked ? 'cursor-not-allowed' : ''}>Submit</Button>} onClickCb={isChecked ? onClickCb : null}>
            {contentData.map((content, index) => (
                <div key={"patient-consent-item-" + index} className='p-4 flex col-gap-5'>
                    <div className='font-bold'>{index + 1}.</div>
                    <div className='text-gray-500'>{content}</div>
                </div>

            ))}
            <div className='flex pt-10  column-gap-10px items-center'>
                <div>
                    <Checkbox onChangeCb={() => setIsChecked(prev => !prev)} checked={isChecked} />
                </div>
                <div><span>I agree to </span><span  /* className='pl-1 fs-16 underline  text-met-primary' */ onClick={onTermsAcceptCb}>terms & conditons</span></div>
            </div>
        </ModalComponent>
    )
}

export default PrivacyConsentFormModal
import store from '../../../../store/store';

export const componentKey = 'PATIENTHISTORY_SECTION';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setMedicalHistoryId: (state, action) => {
            state.currentMedicalHistoryId = action.payload;
        },
        setAllMedicalHistoryData: (state, action) => {
            state.allMedicalHistoryData = action.payload;
        },
        setMedicalHistoryData: (state, action) => {
            state.medicalHistoryData = action.payload;
        },
        setIsMedicalHistoryModalOpen: (state, action) => {
            state.isMedicalHistoryModalOpen = action.payload;
        },
        setIsNewMedicalHistoryAdd: (state, action) => {
            state.isNewMedicalHistoryAdd = action.payload;
        },
        setSurgicalHistoryId: (state, action) => {
            state.currentSurgicalHistoryId = action.payload;
        },
        setAllSurgicalHistoryData: (state, action) => {
            state.allSurgicalHistoryData = action.payload;
        },
        setSurgicalHistoryData: (state, action) => {
            state.surgicalHistoryData = action.payload;
        },
        setIsSurgicalHistoryModalOpen: (state, action) => {
            state.isSurgicalHistoryModalOpen = action.payload;
        },
        setIsNewSurgicalHistoryAdd: (state, action) => {
            state.isNewSurgicalHistoryAdd = action.payload;
        },
        setIsPastSurgicalHistoryDeletePopUp: (state, action) => {
            state.isPastSurgicalHistoryDeletePopUp = action.payload
        }
    },
    initialReducerState: {
        currentMedicalHistoryId: '',
        allMedicalHistoryData: [],
        medicalHistoryData: {},
        isMedicalHistoryModalOpen: false,
        isNewMedicalHistoryAdd: false,
        currentSurgicalHistoryId: '',
        allSurgicalHistoryData: [],
        surgicalHistoryData: {},
        isSurgicalHistoryModalOpen: false,
        isNewSurgicalHistoryAdd: false,
        isPastSurgicalHistoryDeletePopUp: false
    },
});

export const { setMedicalHistoryId, setAllMedicalHistoryData, setMedicalHistoryData, setIsMedicalHistoryModalOpen, setIsNewMedicalHistoryAdd,
    setSurgicalHistoryId, setAllSurgicalHistoryData, setSurgicalHistoryData, setIsSurgicalHistoryModalOpen, setIsNewSurgicalHistoryAdd, setIsPastSurgicalHistoryDeletePopUp
} =
    actions;

export const SettingProviderGroupIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
			<g id="Group_96324" data-name="Group 96324" transform="translate(-16 -16)">
				<rect
					id="Rectangle_36519"
					data-name="Rectangle 36519"
					width="70"
					height="70"
					rx="5"
					transform="translate(16 16)"
					fill="rgba(204,236,255,0.5)"
				/>
				<g id="hospital" transform="translate(30.793 30.791)">
					<path
						id="Path_205875"
						data-name="Path 205875"
						d="M41.745,14.126H32.987V2.674A.673.673,0,0,0,32.314,2H12.1a.673.673,0,0,0-.674.674V14.126H2.674A.673.673,0,0,0,2,14.8V41.745a.673.673,0,0,0,.674.674H41.745a.673.673,0,0,0,.674-.674V14.8A.673.673,0,0,0,41.745,14.126Zm-38.4,1.347h8.084v25.6H3.347ZM24.23,34.335v6.736H20.188V34.335Zm7.41,6.736H25.577V34.335h1.347V32.987H17.494v1.347h1.347v6.736H12.778V3.347H31.64Zm9.431,0H32.987v-25.6h8.084Z"
						transform="translate(-2 -2)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205876"
						data-name="Path 205876"
						d="M50.674,29.389h4.042a.673.673,0,0,0,.674-.674V24.674A.673.673,0,0,0,54.715,24H50.674a.673.673,0,0,0-.674.674v4.042A.673.673,0,0,0,50.674,29.389Zm.674-4.042h2.695v2.695H51.347Z"
						transform="translate(-17.665 -9.18)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205877"
						data-name="Path 205877"
						d="M50.674,39.389h4.042a.673.673,0,0,0,.674-.674V34.674A.673.673,0,0,0,54.715,34H50.674a.673.673,0,0,0-.674.674v4.042A.673.673,0,0,0,50.674,39.389Zm.674-4.042h2.695v2.695H51.347Z"
						transform="translate(-17.665 -12.443)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205878"
						data-name="Path 205878"
						d="M50.674,49.389h4.042a.673.673,0,0,0,.674-.674V44.674A.673.673,0,0,0,54.715,44H50.674a.673.673,0,0,0-.674.674v4.042A.673.673,0,0,0,50.674,49.389Zm.674-4.042h2.695v2.695H51.347Z"
						transform="translate(-17.665 -15.707)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205879"
						data-name="Path 205879"
						d="M10.715,24H6.674A.673.673,0,0,0,6,24.674v4.042a.673.673,0,0,0,.674.674h4.042a.673.673,0,0,0,.674-.674V24.674A.673.673,0,0,0,10.715,24Zm-.674,4.042H7.347V25.347h2.695Z"
						transform="translate(-3.305 -9.18)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205880"
						data-name="Path 205880"
						d="M20.674,26.042h2.695a.673.673,0,0,0,.674-.674V22.674A.673.673,0,0,0,23.368,22H20.674a.673.673,0,0,0-.674.674v2.695A.673.673,0,0,0,20.674,26.042Zm.674-2.695h1.347v1.347H21.347Z"
						transform="translate(-7.874 -8.527)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205881"
						data-name="Path 205881"
						d="M32.368,22H29.674a.673.673,0,0,0-.674.674v2.695a.673.673,0,0,0,.674.674h2.695a.673.673,0,0,0,.674-.674V22.674A.673.673,0,0,0,32.368,22Zm-.674,2.695H30.347V23.347h1.347Z"
						transform="translate(-10.812 -8.527)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205882"
						data-name="Path 205882"
						d="M38,22.674v2.695a.673.673,0,0,0,.674.674h2.695a.673.673,0,0,0,.674-.674V22.674A.673.673,0,0,0,41.368,22H38.674A.673.673,0,0,0,38,22.674Zm1.347.674h1.347v1.347H39.347Z"
						transform="translate(-13.749 -8.527)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205883"
						data-name="Path 205883"
						d="M20.674,34.042h2.695a.673.673,0,0,0,.674-.674V30.674A.673.673,0,0,0,23.368,30H20.674a.673.673,0,0,0-.674.674v2.695A.673.673,0,0,0,20.674,34.042Zm.674-2.695h1.347v1.347H21.347Z"
						transform="translate(-7.874 -11.138)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205884"
						data-name="Path 205884"
						d="M32.368,30H29.674a.673.673,0,0,0-.674.674v2.695a.673.673,0,0,0,.674.674h2.695a.673.673,0,0,0,.674-.674V30.674A.673.673,0,0,0,32.368,30Zm-.674,2.695H30.347V31.347h1.347Z"
						transform="translate(-10.812 -11.138)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205885"
						data-name="Path 205885"
						d="M41.368,30H38.674a.673.673,0,0,0-.674.674v2.695a.673.673,0,0,0,.674.674h2.695a.673.673,0,0,0,.674-.674V30.674A.673.673,0,0,0,41.368,30Zm-.674,2.695H39.347V31.347h1.347Z"
						transform="translate(-13.749 -11.138)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205886"
						data-name="Path 205886"
						d="M20.674,42.042h2.695a.673.673,0,0,0,.674-.674V38.674A.673.673,0,0,0,23.368,38H20.674a.673.673,0,0,0-.674.674v2.695A.673.673,0,0,0,20.674,42.042Zm.674-2.695h1.347v1.347H21.347Z"
						transform="translate(-7.874 -13.749)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205887"
						data-name="Path 205887"
						d="M32.368,38H29.674a.673.673,0,0,0-.674.674v2.695a.673.673,0,0,0,.674.674h2.695a.673.673,0,0,0,.674-.674V38.674A.673.673,0,0,0,32.368,38Zm-.674,2.695H30.347V39.347h1.347Z"
						transform="translate(-10.812 -13.749)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205888"
						data-name="Path 205888"
						d="M41.368,38H38.674a.673.673,0,0,0-.674.674v2.695a.673.673,0,0,0,.674.674h2.695a.673.673,0,0,0,.674-.674V38.674A.673.673,0,0,0,41.368,38Zm-.674,2.695H39.347V39.347h1.347Z"
						transform="translate(-13.749 -13.749)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205889"
						data-name="Path 205889"
						d="M10.715,34H6.674A.673.673,0,0,0,6,34.674v4.042a.673.673,0,0,0,.674.674h4.042a.673.673,0,0,0,.674-.674V34.674A.673.673,0,0,0,10.715,34Zm-.674,4.042H7.347V35.347h2.695Z"
						transform="translate(-3.305 -12.443)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205890"
						data-name="Path 205890"
						d="M10.715,44H6.674A.673.673,0,0,0,6,44.674v4.042a.673.673,0,0,0,.674.674h4.042a.673.673,0,0,0,.674-.674V44.674A.673.673,0,0,0,10.715,44Zm-.674,4.042H7.347V45.347h2.695Z"
						transform="translate(-3.305 -15.707)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205891"
						data-name="Path 205891"
						d="M25.674,12.736h2.021v2.021a.673.673,0,0,0,.674.674h2.695a.673.673,0,0,0,.674-.674V12.736h2.021a.673.673,0,0,0,.674-.674V9.368a.673.673,0,0,0-.674-.674H31.736V6.674A.673.673,0,0,0,31.063,6H28.368a.673.673,0,0,0-.674.674V8.695H25.674A.673.673,0,0,0,25,9.368v2.695A.673.673,0,0,0,25.674,12.736Zm.674-2.695h2.021a.673.673,0,0,0,.674-.674V7.347h1.347V9.368a.673.673,0,0,0,.674.674h2.021v1.347H31.063a.673.673,0,0,0-.674.674v2.021H29.042V12.063a.673.673,0,0,0-.674-.674H26.347Z"
						transform="translate(-9.506 -3.305)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205892"
						data-name="Path 205892"
						d="M42,56h1.347v1.347H42Z"
						transform="translate(-15.054 -19.623)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205893"
						data-name="Path 205893"
						d="M42,52h1.347v1.347H42Z"
						transform="translate(-15.054 -18.318)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
					<path
						id="Path_205894"
						data-name="Path 205894"
						d="M42,48h1.347v1.347H42Z"
						transform="translate(-15.054 -17.013)"
						fill="#1b5984"
						stroke="#1b5984"
						strokeWidth="0.5"
					/>
				</g>
			</g>
		</svg>
	);
};

import React, { useEffect, useMemo } from 'react'
import MetPrintModal from '../../../containers/MetPrintModal'
import FaceSheetCard from '../../Patients/PatientDashboard/Dashboard/FaceSheet/FaceSheetCard'
import Table from '../../../containers/Table/Index'
import Label from '../../../components/common-components/label/Label'
import Icons from '../../../components/icons/Icons'
import Heading from '../../../components/common-components/heading/Heading'
import { HEADING } from '../../../components/common-components/heading/Constants'
import moment from 'moment'
import { getPatientsReceiptDetails } from './PatientPaymentSaga'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey } from './PatientPaymentSlice'
import useGarbageCollector from '../../../libs/garbagecollector/garbageCollectorHooks'

function PatientReceiptModal({ open, close }) {
  const { patientReceiptData } = useSelector(((state) => state[componentKey]))
  const patientId = patientReceiptData?.patientId;
  const address = patientReceiptData?.patient?.Address[0];
  const paymentId = patientReceiptData?.mappingBillingPayment[0]?.paymentId

  const PATIENT_DETAILS_INFO = {
    'Patient Name': patientReceiptData?.patient?.firstName + ' ' + patientReceiptData?.patient?.lastName,
    DOB: moment(patientReceiptData?.patient?.dob).format('DD MMM YYYY') || '-',
    Email: patientReceiptData?.patient?.email,
    'Phone Number': patientReceiptData?.patient?.mobileNumber,
    Address: address ? `${address.addressLine1 ? address.addressLine1 + "," : ""} ${address.addressLine2 ? address.addressLine2 + "," : ""} ${address.city ? address.city + "," : ""} ${address.state ? address.state + "," : ""} ${address.country ? address.country + "," : ""} ${address.zip ? address.zip + "," : ""}` : '-',
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (paymentId) {
      dispatch(getPatientsReceiptDetails({ paymentId }));
    }
  }, [dispatch, paymentId]);

  const rowdata = useMemo(() => {
    return [
      { label: 'Amount Paid', value: patientReceiptData?.amount },
      { label: 'Payment Method', value: patientReceiptData?.method },
      { label: 'Posted Date', value: moment(patientReceiptData?.paymentDate).format('DD-MM-YYYY') || '-' },
    ]
  })
  const coloumns = [
    {
      field: 'superBillNo',
      label: <Label fontWeight="font-bold">Super Bill No</Label>,
      renderLogic: (row) => {
        const uuid = row?.uuid;
        const superBill = uuid.substring(0, 6);
        return (
          <>
            <div className="flex items-center">
              <div>
                <Heading
                  type={HEADING.H1}
                  color="gray-500"
                  fontSize={'md'}
                  customClasses="cursor-pointer"
                >
                  {superBill}
                </Heading>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: 'dateOfService',
      label: <Label fontWeight="font-bold">Date Of Service</Label>,
      renderLogic: (row) => {
        const dateOfService = row?.billing?.dos ? moment(row?.billing?.dos).format('MMM DD YYYY') : '-';
        return (
          <>
            <div className="flex items-center">
              <div>
                <Heading
                  type={HEADING.H1}
                  color="gray-500"
                  fontSize={'md'}
                  customClasses="cursor-pointer"
                >
                  {dateOfService}
                </Heading>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: 'totalCharges',
      label: <Label fontWeight="font-bold">Total Charges</Label>,
      renderLogic: (row) => {
        return (
          <>
            <div className="flex items-center">
              <div>
                <Heading
                  type={HEADING.H1}
                  color="gray-500"
                  fontSize={'md'}
                  customClasses="cursor-pointer"
                >
                  {row.billing.totalBill}
                </Heading>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: 'insuranceBalance',
      label: <Label fontWeight="font-bold">Insurance Balance</Label>,
      renderLogic: (row) => {
        return (
          <>
            <div className="flex items-center">
              <div>
                <Heading
                  type={HEADING.H1}
                  color="gray-500"
                  fontSize={'md'}
                  customClasses="cursor-pointer"
                >
                  {row.billing.insuranceAmount}
                </Heading>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: 'totalBalance',
      label: <Label fontWeight="font-bold">Total Amount</Label>,
      renderLogic: (row) => {
        return (
          <>
            <div className="flex items-center">
              <div>
                <Heading
                  type={HEADING.H1}
                  color={"gray-500"}
                  fontSize={'md'}
                  customClasses="cursor-pointer"
                >
                  {row.billing.totalBalance}
                </Heading>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  return (
    <div>
      <MetPrintModal open={open} close={close} title='Patient Receipt'>
        <div className='mt-4'>
          <FaceSheetCard paddingTopClass={0} customClassName="">
            <div className='text-met-primary font-bold'>PATIENT INFORMATION</div>
            <div className="flex flex-wrap row-gap-10 column-gap-10px">
              {Object.keys(PATIENT_DETAILS_INFO)?.map((key, index) => (
                <div key={key} className="flex column-gap-10px">
                  <div className="text-met-light-grey  font-semibold">{key}</div>
                  <div className="text-rgba-1a1a1acc  font-semibold">
                    {PATIENT_DETAILS_INFO[key] ? PATIENT_DETAILS_INFO[key] : '-'}
                  </div>
                </div>
              ))}
            </div>
          </FaceSheetCard>
        </div>
        <div className='flex mt-4 items-center'>
          <div>
            <Icons iconName="checkCircleIcon"></Icons>
          </div>
          <Label fontWeight='bold' fontSize={''} color={"green-500"}> Payment Posted Successfully </Label>
        </div>

        <div className='flex justify-start mt-4'>
          <div className='flex flex-col w-1/3  bg-met-secondary p-4'>
            {rowdata.map((item, index) => (
              <div key={index} className='flex mt-2'>
                <div className='flex-1 fs-18 2 text-rgba-1a1a1acc font-semibold'>{item.label}</div>
                <div className='text-met-primary fs-18 font-semibold'>{item.value}</div>
              </div>
            ))}
          </div>
        </div>

        <div className='mt-4'>
          <div className='mt-3'>
            <Table isPagination={false} coloumns={coloumns} rows={patientReceiptData.mappingBillingPayment} />
          </div>
        </div>

      </MetPrintModal>
    </div>
  )
}

export default PatientReceiptModal
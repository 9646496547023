import React, { useEffect, useState } from 'react';
import Table from '../../../../Table/Index';
import Label from '../../../../../components/common-components/label/Label';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import Checkbox from '../../../../../components/common-components/checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from './NotificationsSaga';
import { componentKey, setNotificationData } from './NotificationsSlice';
import { setupdatedNotifications } from '../SettingTabsSlice';
import General from '../../../../../libs/utility/General';
import { componentKey as settingComponentKey } from '../SettingTabsSlice';

const SettingsNotifications = () => {
	const { notificationData } = useSelector((state) => state[componentKey])
	const { updatedNotifications } = useSelector(state => state[settingComponentKey])
	const dispatch = useDispatch()


	const [notifications, setNotifications] = useState({})

	useEffect(() => {
		const appointmentsNotifications = notificationData.filter(notification => notification.notificationType.type === "appointments");
		const formNotifications = notificationData.filter(notification => notification.notificationType.type === "form");
		const noteNotifications = notificationData.filter(notification => notification.notificationType.type === "note");

		const notifications = {
			appointments: appointmentsNotifications,
			form: formNotifications,
			note: noteNotifications,
		}

		setNotifications(notifications)
	}, [notificationData])

	useEffect(() => {
		dispatch(getNotifications())
	}, [dispatch])

	const handleCheckBoxClick = (row, key, status) => {
		const notificationDataClone = [...notificationData]
		const updatedNotification = {
			...row,
			[key]: status,
			notificationId: row.uuid,
		}

		const updatedNotificationData = General.updateObjectInArray(notificationDataClone, row, "uuid", updatedNotification)
		dispatch(setNotificationData(updatedNotificationData))
		const notificationsToUpdate = General.updateObjectInArray(updatedNotifications, updatedNotification, "notificationId", updatedNotification)
		dispatch(setupdatedNotifications(notificationsToUpdate))
	}

	const columns = [
		{
			field: 'pcp',
			label: <Label>Title</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row.notificationType.title}
						</Heading>
					</>
				);
			},
		},
		{
			field: 'pcp',
			label: <Label>Push</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Checkbox
							checked={row.push}
							onChangeCb={(val) => {
								handleCheckBoxClick(row, "push", val)
							}}
						/>
					</>
				);
			},
		},
		{
			field: 'pcp',
			label: <Label>Text</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Checkbox checked={row.text} onChangeCb={(val) => { handleCheckBoxClick(row, "text", val) }} />
					</>
				);
			},
		},
		{
			field: 'pcp',
			label: <Label>Email</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Checkbox checked={row.email} onChangeCb={(val) => { handleCheckBoxClick(row, "email", val) }} />
					</>
				);
			},
		},
	];

	return (
		<>
			<div className="profile-card-height">
				<p className="text-[24px] mt-8">Appointments</p>
				<div className="mt-4">
					<Table coloumns={columns} rows={notifications?.appointments} isPagination={false} />
				</div>
				<p className="text-[24px] mt-8">Form</p>
				<div className="mt-4">
					<Table coloumns={columns} rows={notifications.form} isPagination={false} />
				</div>
				<p className="text-[24px] mt-8">Note</p>
				<div className="mt-4">
					<Table coloumns={columns} rows={notifications.note} isPagination={false} />
				</div>
			</div>
		</>
	);
};

export default SettingsNotifications;

import React, { useEffect, useMemo, useState } from 'react';
import profileDetailsImage from '../../../../../assets/images/profileDetailsImage.png';
// import { componentKey } from '../Profile/ProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../../Table/Index';
import Label from '../../../../../components/common-components/label/Label';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import ToggleSwitchWtLabel from '../../../../../components/common-components/toggleSwitch/ToggleswitchWtLabel';
import Icons from '../../../../../assets/svgs';
import {
	componentKey,
	setEditLocationData,
	setIsLocationModalOpen,
	setIsNewLocationAdd,
	setOpenSideDrawer,
} from './LocationSlice';
import ToggleSwitch from '../../../../../components/common-components/toggleSwitch/ToggleSwitch';
// import { getAllProviderGroupLocations, getProviderGroupDetails } from '../Profile/ProfileSaga';
import { getAllProviderGroupLocations, getProviderGroupDetails, getProviderSepcialities } from './LocationSaga';
import Avatar from '../../../../../components/common-components/avatar';
import Drawer from '../../../../../components/common-components/drawer';
import { SETTINGS_PATHS } from '../../../../../libs/constant';
import Details from './Tabs/Details';
import Tabs from '../../../../../components/common-components/Tabs';
import Patients from './Tabs/Patients';
import Users from './Tabs/Users';
import LocationsTabsContainer from './Tabs/LocationsTabsContainer';
import { useNavigate } from 'react-router-dom';
import General from '../../../../../libs/utility/General';

export const locationsTab = [
	{
		id: '/details',
		name: 'Details',
		redirectTo: SETTINGS_PATHS.PROVIDER_GROUP_LOCATIONS_DETAILS,
		component: <Details />,
	},
	{
		id: '/patients',
		name: 'Patients',
		redirectTo: SETTINGS_PATHS.PROVIDER_GROUP_LOCATIONS_PATIENTS,
		component: <Patients />,
	},
	{ id: '/users', name: 'Users', redirectTo: SETTINGS_PATHS.PROVIDER_GROUP_LOCATIONS_USERS, component: <Users /> },
];
const Locations = () => {
	const { allProviderGroupLocation, providerGroupDetails, openSideDrawer } = useSelector(
		(state) => state[componentKey]
	);
	const { providerGroupSpecialities } = providerGroupDetails || {};
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getProviderGroupDetails());
		dispatch(getAllProviderGroupLocations());
		dispatch(getProviderSepcialities());
	}, [dispatch]);
	const PROFILE_INFO_LABELS = useMemo(() => {
		const { email, phone, mlc, website } = providerGroupDetails;
		const { physicalAddress } = providerGroupDetails;
		const { address_line_1, address_line_2, city, state, country, zip } = physicalAddress || {};
		return {
			MLC: mlc,
			Website: website || '',
			'Contact Number': phone,
			Email: email,
			'Physical Address': `${address_line_1}, ${address_line_2}, ${city}, ${zip}, ${state}, ${country}`,
		};
	}, [providerGroupDetails]);
	const specialitiesNames = useMemo(() => {
		if (!providerGroupSpecialities) {
			return [];
		}
		return providerGroupSpecialities.map((item) => item.speciality.name);
	}, [providerGroupSpecialities]);

	const handleEditClick = (row) => {
		dispatch(setIsNewLocationAdd(false));
		dispatch(setIsLocationModalOpen(true));
		dispatch(setEditLocationData(row));
	};

	const columns = [
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Location ID</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading
							onClickCb={() => {
								dispatch(setOpenSideDrawer(true));
								dispatch(setEditLocationData(row));
								navigate(SETTINGS_PATHS.PROVIDER_GROUP_LOCATIONS_DETAILS);
							}}
							fontWeight="bold"
							type={HEADING.H1}
							color="met-primary"
							fontSize={'sm'}
							customClasses="cursor-pointer"
						>
							{row.locationId}
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Location Name</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{`${General.camelToPascalWithSpaces(row.name)}`}
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Specialties</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row?.provider_locations_speciality_intermediate.map((item, index) => item.speciality.name).join(', ')}
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
		// {
		// 	field: 'pcp',
		// 	label: <Label fontWeight="bold">POS</Label>,
		// 	renderLogic: (row) => {
		// 		return (
		// 			<>
		// 				<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
		// 					{row.placeOfService}
		// 				</Heading>
		// 			</>
		// 		);
		// 	},
		// 	sort: 'name',
		// },
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Address</Label>,
			renderLogic: (row) => {
				const { physicalAddress1, physicalAddress2, physicalAddressCity, physicalAddressState, physicalAddressCountry, physicalAddressZip } = row || {}
				const addressParts = [physicalAddress1, physicalAddress2, physicalAddressCity, physicalAddressState, physicalAddressCountry, physicalAddressZip]
				const formattedAddress = addressParts.filter(part => part).join(", ");
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{formattedAddress}
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Contact Number</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{row.contactNumber}
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
		{
			field: 'pcp',
			label: <Label fontWeight="bold">Status</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							{/* <ToggleSwitch label={'action'} checked={row.status === true ? true : false} /> */}
							<span
								className={`${row.status === true
									? 'text-met-success border-b-[1px] border-b-met-success'
									: 'text-met-red border-b-[1px] border-b-met-red'
									}`}
							>
								{row.status === true ? 'Active' : 'Inactive'}
							</span>
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
		{
			field: 'Action',
			label: <Label fontWeight="bold">Action</Label>,
			renderLogic: (row) => {
				return (
					<>
						<Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
							<span className="hover:cursor-pointer flex w-fit" onClick={() => handleEditClick(row)}>
								<Icons iconName="greyPencilIcon" />
							</span>
						</Heading>
					</>
				);
			},
			sort: 'name',
		},
	];

	return (
		<div>
			<div
				className="flex justify-between bg-white w-[45%]  p-3"
				style={{ borderRadius: '10px', border: '1px solid #1A1A1A33' }}
			>
				<div className="flex flex-col w-[25%]">
					<Avatar
						customClass="w-[100px] h-[100px] overflow-hidden object-cover rounded-full"
						url={providerGroupDetails?.path}
					// name={`${item.firstName} ${item.lastName}`}
					/>
					<p className="mt-4 text-2xl text-rgba-1A1A1A font-bold">{providerGroupDetails.groupName}</p>
					<div className="pt-4 flex column-gap-16 flex-wrap row-gap-10px ">
						{/* {specialitiesNames.map((name, index) => (
							<p
								key={'provider-specialities-index' + index}
								style={{ borderRadius: '15px' }}
								className="inline-block bg-rgba-CCECFF4D  text-met-primary"
							>
								{name}
							</p>
						))} */}
						<div className="pt-2">
							{specialitiesNames?.length > 1 ? (
								<>
									<p className="font-semibold p-1 inline-block  bg-rgba-CCECFF4D text-met-primary">
										Multispecialist
									</p>
								</>
							) : specialitiesNames?.length === 1 ? (
								<>
									{specialitiesNames?.map((name, index) => (
										<p
											key={'provider-specialities-index' + index}
											className="inline-block bg-rgba-CCECFF4D px-4 py-2 rounded-md font-semibold text-met-primary"
										>
											{name}
										</p>
									))}
								</>
							) : null}
						</div>
					</div>
				</div>
				<div className="flex flex-col w-[70%]">
					{Object.keys(PROFILE_INFO_LABELS).map((key) => (
						<div key={key} className="flex mt-7">
							<div className="w-1/3 text-met-light-grey font-bold" fontWeight="bold">
								{key}
							</div>
							<div className="flex-1 text-rgba-1a1a1acc font-bold">{PROFILE_INFO_LABELS[key]}</div>
						</div>
					))}
				</div>
			</div>
			<div className="mt-[10px] overflow-y-scroll met-scrollbar" style={{ height: '35vh' }}>
				<Table coloumns={columns} rows={allProviderGroupLocation} />
			</div>

			{openSideDrawer ? (
				<Drawer
					width={'70%'}
					isOpen={openSideDrawer}
					onCloseCb={() => dispatch(setOpenSideDrawer(false))}
					direction="right"
				// heading="location"
				>
					<LocationsTabsContainer />
				</Drawer>
			) : null}
		</div>
	);
};
export default Locations;

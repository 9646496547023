import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FORM_FIELDS_NAMES } from './constant';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import Button from '../../../../../components/common-components/button/Button';
import Label from '../../../../../components/common-components/label/Label';
import Upload from '../../../../../components/common-components/upload/Upload';
import Input from '../../../../../components/common-components/input/Input';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import { getValidationSchema } from '../../../../../libs/formsUtils';
import { MET_COLORS, VALIDATION_REGEX } from '../../../../../libs/constant';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import Checkbox from '../../../../../components/common-components/checkbox/Checkbox';
import ToggleSwitch from '../../../../../components/common-components/toggleSwitch/ToggleSwitch';
import { componentKey as ProfileKey } from './ProfileSlice';
import { updateProfileData } from './ProfileSaga';
import General from '../../../../../libs/utility/General';
import Icons from '../../../../../components/icons/Icons';
import { componentKey as locationComponentKey } from '../Locations/LocationSlice';
import { getProviderSepcialities } from '../Locations/LocationSaga';
import { componentKey } from '../../../../Wrappers/Dataloader/DataloaderSlice';
import DatePicker from '../../../../../components/common-components/datePicker';
import moment from 'moment';

const ProfileModal = ({ close }) => {
	const [isSameAsPhysicalAddress, setISSameAsPhysicialAddress] = useState(false);
	const dispatch = useDispatch();
	const fields = [
		{ fieldName: FORM_FIELDS_NAMES.GROUP_NAME, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.EMAIL, isRequired: true, isEmail: true },
		{ fieldName: FORM_FIELDS_NAMES.MLC, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_1, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_CITY, isRequired: true, regexPattern: VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_COUNTRY,regexPattern: VALIDATION_REGEX.NAME_REGEX},
		{ fieldName: FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_STATE, isRequired: true, isDropdown: true },
		{
			fieldName: FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_ZIP,
			isRequired: true,
			regexPattern: VALIDATION_REGEX.ZIP_CODE_REGEX,
		},
		{ fieldName: FORM_FIELDS_NAMES.BILLING_ADDRESS_1, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY, isRequired: true, regexPattern: VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY, regexPattern: VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE, isRequired: true, isDropdown: true },
		{
			fieldName: FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP,
			isRequired: true,
			regexPattern: VALIDATION_REGEX.ZIP_CODE_REGEX,
		},

		{
			fieldName: FORM_FIELDS_NAMES.CONTACT_NUMBER,
			isRequired: false,
			regexPattern: VALIDATION_REGEX.MOBILE_NUM_REGEX,
		},
		{
			fieldName: FORM_FIELDS_NAMES.WEBSITE,
			isRequired: false,
			regexPattern: VALIDATION_REGEX.WEBSITE_REGEX,
		},
	];
	const {
		isEditProfileModalOpen,
		editedProfileData,
		currentProvidersUUID,
		currentProviderBillingAddresssUUID,
		currentProvidersPhysicalAddressUUID,
	} = useSelector((state) => state[ProfileKey]);
	const { indianStates, usersRolesAndResp } = useSelector((state) => state[componentKey]);

	const { providerSepcialities } = useSelector((state) => state[locationComponentKey]);
	const validationSchema = getValidationSchema(fields);
	const OFFICE_HOURS = Object.keys(editedProfileData?.workingHours || {}).map((key, index) => {
		return {
			day: key,
			...editedProfileData.workingHours[key],
		};
	});

	const editedData = {
		...editedProfileData,
		[FORM_FIELDS_NAMES.OFFICE_HOURS]: OFFICE_HOURS,
	};

	const defaultOfficeHours = [
		{
			dayOfWeek: 'Monday',
			// active: false,
			openingTime: '00:00',
			closingTime: '00:00',
		},
		{
			dayOfWeek: 'Tuesday',

			// active: false,
			openingTime: '00:00',
			closingTime: '00:00',
		},
		{
			dayOfWeek: 'Wednesday',
			// active: false,
			openingTime: '00:00',
			closingTime: '00:00',
		},
		{
			dayOfWeek: 'Thursday',

			// active: false,
			openingTime: '00:00',
			closingTime: '00:00',
		},
		{
			dayOfWeek: 'Friday',
			// active: false,
			openingTime: '00:00',
			closingTime: '00:00',
		},
		{
			dayOfWeek: 'Saturday',
			// active: false,
			openingTime: '00:00',
			closingTime: '00:00',
		},
		{
			dayOfWeek: 'Sunday',
			// active: false,
			openingTime: '00:00',
			closingTime: '00:00',
		},
	];
	useEffect(() => {
		dispatch(getProviderSepcialities());
	}, []);
	const specialityData = editedData?.providerGroupSpecialities?.map((item, idx) => item?.specialityId);

	const { physicalAddress, billingAddress } = editedData;

	const {
		address_line_1: physical_address_line_1,
		address_line_2: physical_address_line_2,
		city: physical_city,
		state: physical_state,
		country: physical_country,
		zip: physical_zip,
	} = physicalAddress || {};

	const {
		address_line_1: billing_address_line_1,
		address_line_2: billing_address_line_2,
		city: billing_city,
		state: billing_state,
		country: billing_country,
		zip: billing_zip,
	} = billingAddress || {};

	return (
		<Formik
			initialValues={{
				[FORM_FIELDS_NAMES.GROUP_NAME]: editedData?.groupName,
				[FORM_FIELDS_NAMES.WEBSITE]: editedData?.website,
				[FORM_FIELDS_NAMES.PLACEOFSERVICE]: General.getLabelValue(editedData?.placeOfService),
				[FORM_FIELDS_NAMES.EMAIL]: editedData?.email,
				[FORM_FIELDS_NAMES.MLC]: editedData?.mlc,
				[FORM_FIELDS_NAMES.FAX]: editedData?.fax,
				[FORM_FIELDS_NAMES.INFORMATION]: editedData?.description,
				[FORM_FIELDS_NAMES.TIMEZONE]: General.getLabelValue(editedData?.timezone),
				[FORM_FIELDS_NAMES.CONTACT_NUMBER]: editedData?.phone,
				[FORM_FIELDS_NAMES.CONTACT_PERSON]: editedData?.contactPerson,
				[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_1]: editedData?.physicalAddress1 || physical_address_line_1,
				[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_2]: editedData?.physicalAddress2 || physical_address_line_2,
				[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_CITY]: editedData?.physicalAddressCity || physical_city,
				[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_STATE]:
					General.getLabelValue(editedData?.physicalAddressState) || physical_state
						? { label: physical_state, value: physical_state }
						: '',
				[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_COUNTRY]: editedData?.physicalAddressCountry || physical_country,
				[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_ZIP]: editedData?.physicalAddressZip || physical_zip,
				[FORM_FIELDS_NAMES.BILLING_ADDRESS_1]: editedData?.billingAddress1 || billing_address_line_1,
				[FORM_FIELDS_NAMES.BILLING_ADDRESS_2]: editedData?.billingAddress2 || billing_address_line_2,
				[FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY]: editedData?.billingAddressCity || billing_city,
				[FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE]:
					General.getLabelValue(editedData?.billingAddressState) || billing_state
						? { label: billing_state, value: billing_state }
						: '',
				[FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY]: editedData?.billingAddressCountry || billing_country,
				[FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP]: editedData?.billingAddressZip || billing_zip,
				[FORM_FIELDS_NAMES.NUMBER]: editedData?.number,
				[FORM_FIELDS_NAMES.SPECIALITY]: specialityData || [],
				[FORM_FIELDS_NAMES.AVTAR]: editedData?.avtar,
				[FORM_FIELDS_NAMES.PATH]: editedData?.path || editedData?.avtar,
				[FORM_FIELDS_NAMES.OFFICE_HOURS]: editedData[FORM_FIELDS_NAMES.OFFICE_HOURS].length
					? editedData[FORM_FIELDS_NAMES.OFFICE_HOURS]
					: defaultOfficeHours,
				// ...profileData,
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				try {
					dispatch(
						updateProfileData({
							uuid: currentProvidersUUID,
							editedProfileData: values,
							currentProviderBillingAddresssUUID: currentProviderBillingAddresssUUID,
							currentProvidersPhysicalAddressUUID: currentProvidersPhysicalAddressUUID,
							// isSameAsPhysicalAddress: isSameAsPhysicalAddress,
						})
					);
				} catch (error) {
					console.log(error);
				}
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={isEditProfileModalOpen}
							validationSchema={validationSchema}
							title={'Edit Provider Group Profile'}
							close={close}
							icon={<Icons iconName="closeIcon" />}
							footerButton={
								<Button
									type="submit"
									variant={BUTTON_VARIANTS.CONTAINED}
									customBtnClass="text-met-primary"
								>
									Save
								</Button>
							}
							customClasses="w-[80%]"
							enableReinitialize
						>
							<div className="flex-column">
								<div className="flex justify-between flex-wrap">
									<div className="w-[10%]">
										<div className="mb-3">
											<Label fontWeight="bold">Provider Group Logo</Label>
										</div>
										<Upload
											url={editedData?.path}
											subTitle="Upload"
											customClass="w-[157px] h-[157px]"
											icon={<Icons iconName="fileUpload" color={MET_COLORS.DARK_GRAY} />}
											name={FORM_FIELDS_NAMES.AVTAR}
											value={values[FORM_FIELDS_NAMES.AVTAR] || values[FORM_FIELDS_NAMES.PATH]}
											onChangeCb={async (file) => {
												const mimeType = file?.type || '';
												const base64String = await General.fileToBase64(file);
												const base64 = `data:${mimeType};base64,${base64String}`;
												setFieldValue(FORM_FIELDS_NAMES.AVTAR, base64);
											}}
										/>
									</div>
									<div className="w-[85%]">
										<div
											className=" flex column-gap-10px flex-wrap border-b-[1px] border-b-[#00000029] p-4"
											style={{ rowGap: '10px' }}
										>
											<div className="w-full">
												<Input
													label="Provider Group Name"
													isRequired={true}
													placeholder="Enter Name"
													name={FORM_FIELDS_NAMES.GROUP_NAME}
													value={values[FORM_FIELDS_NAMES.GROUP_NAME]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.GROUP_NAME, e.target.value);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Contact Number"
													isRequired={false}
													placeholder="Enter Number"
													name={FORM_FIELDS_NAMES.CONTACT_NUMBER}
													value={values[FORM_FIELDS_NAMES.CONTACT_NUMBER]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.CONTACT_NUMBER, e.target.value);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Contact Person"
													isRequired={false}
													placeholder="Enter"
													name={FORM_FIELDS_NAMES.CONTACT_PERSON}
													value={values[FORM_FIELDS_NAMES.CONTACT_PERSON]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.CONTACT_PERSON, e.target.value);
													}}
												/>
											</div>
											{/* <div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Place of Service"
													isRequired={false}
													name={FORM_FIELDS_NAMES.PLACEOFSERVICE}
													value={values[FORM_FIELDS_NAMES.PLACEOFSERVICE]}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_NAMES.PLACEOFSERVICE, val);
													}}
													options={[
														{ label: '11-Office', value: 'Office' },
														{ label: '12-Home', value: 'Home' },
														{
															label: '13-Assisted Lining Facility',
															value: 'assisteLiningFacility',
														},
													]}
												/>
											</div> */}
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Specility Type"
													isRequired={false}
													isMultiSelect={true}
													name={FORM_FIELDS_NAMES.SPECIALITY}
													value={values[FORM_FIELDS_NAMES.SPECIALITY] || []}
													onChangeCb={(selectedItems) => {
														const selectedValues = selectedItems.map(
															(item) => item.value
															// name: item.label,
														);
														setFieldValue(FORM_FIELDS_NAMES.SPECIALITY, selectedValues);
													}}
													options={providerSepcialities}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Email"
													isRequired={true}
													placeholder="Enter Email"
													name={FORM_FIELDS_NAMES.EMAIL}
													value={values[FORM_FIELDS_NAMES.EMAIL]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.EMAIL, e.target.value);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Website"
													isRequired={false}
													placeholder="Enter"
													name={FORM_FIELDS_NAMES.WEBSITE}
													value={values[FORM_FIELDS_NAMES.WEBSITE]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.WEBSITE, e.target.value);
													}}
												/>
											</div>
											{/* <div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="Time Zone"
													isRequired={false}
													name={FORM_FIELDS_NAMES.TIMEZONE}
													value={values[FORM_FIELDS_NAMES.TIMEZONE]}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_NAMES.TIMEZONE, val);
													}}
													options={[
														{ label: 'Eastern Standard Time(EST)', value: 'est' },
														{ label: 'Indian Standard Time(IST)', value: 'ist' },
														{ label: 'Central Standard Time(CST)', value: 'cst' },
														{ label: 'Pacific Standard Time(PST)', value: 'pst' },
														{ label: 'Coordinated Standard Time(UST)', value: 'ust' },
													]}
												/>
											</div> */}
											<div className="w-[32.5%]">
												<Input
													label="MLC"
													isRequired={true}
													placeholder="Enter MLC number"
													name={FORM_FIELDS_NAMES.MLC}
													value={values[FORM_FIELDS_NAMES.MLC]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.MLC, e.target.value);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Fax ID"
													isRequired={false}
													placeholder="Enter Fax"
													name={FORM_FIELDS_NAMES.FAX}
													value={values[FORM_FIELDS_NAMES.FAX]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.FAX, e.target.value);
													}}
												/>
											</div>
											<div className="w-full">
												<Input
													label="Information"
													isRequired={false}
													placeholder="Enter information"
													name={FORM_FIELDS_NAMES.INFORMATION}
													value={values[FORM_FIELDS_NAMES.INFORMATION]}
													onChangeCb={(e) => {
														setFieldValue(FORM_FIELDS_NAMES.INFORMATION, e.target.value);
													}}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="">
									<Heading type={HEADING.H1} color="met-primary" fontSize={'md'} fontWeight="bold">
										Address
									</Heading>
									<div className="flex flex-col w-full border-[1px] p-6 mt-2 rounded-[5px] shadow-[0px_0px_6px_#00000029]">
										<Heading type={HEADING.H1} fontSize={'md'}>
											Physical Address
										</Heading>
										<div
											className="w-full flex column-gap-10px flex-wrap mt-2"
											style={{ rowGap: '10px' }}
										>
											<div className="w-[32.5%]">
												<Input
													label="Address 1"
													isRequired={true}
													placeholder="Address 1"
													name={FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_1}
													value={values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_1]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_1,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Address 2"
													isRequired={false}
													placeholder="Address 2"
													name={FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_2}
													value={values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_2]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_2,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="City"
													isRequired={true}
													placeholder="City"
													name={FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_CITY}
													value={values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_CITY]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_CITY,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="State"
													isRequired={true}
													name={FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_STATE}
													value={values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_STATE]}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_STATE, val);
													}}
													options={indianStates}
												/>
											</div>

											<div className="w-[32.5%]">
												<Input
													label="Country"
													isRequired={false}
													placeholder="Country"
													name={FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_COUNTRY}
													value={values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_COUNTRY]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_COUNTRY,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Zip Code"
													isRequired={true}
													placeholder="Zip Code"
													name={FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_ZIP}
													value={values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_ZIP]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_ZIP,
															e.target.value
														);
													}}
												/>
											</div>
										</div>
									</div>
									<div className="flex flex-col w-full border-[1px] p-6 mt-4 rounded-[5px] shadow-[0px_0px_6px_#00000029]">
										<div className="flex justify-between w-1/4">
											<Heading type={HEADING.H1} fontSize={'md'}>
												Billing Address
											</Heading>
											{/* <Checkbox
												label={'same as physical address'}
												style={{ color: 'gray-500' }}
												checked={isSameAsPhysicalAddress === true ? true : false}
												onChangeCb={(e) => {
													setISSameAsPhysicialAddress(!isSameAsPhysicalAddress);
													if (e) {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_1,
															values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_1]
														);
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_2,
															values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_2]
														);
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY,
															values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_CITY]
														);
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE,
															values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_STATE]
														);
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY,
															values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_COUNTRY]
														);
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP,
															values[FORM_FIELDS_NAMES.PHYSICAL_ADDRESS_ZIP]
														);
													} else {
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_1, '');
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_2, '');
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY, '');
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE, '');
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY, '');
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP, '');
													}
												}}
											/> */}
										</div>
										<div
											className="w-full flex column-gap-10px flex-wrap mt-2"
											style={{ rowGap: '10px' }}
										>
											<div className="w-[32.5%]">
												<Input
													label="Address 1"
													isRequired={true}
													placeholder="Address 1"
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_1}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_1]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_1,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Address 2"
													isRequired={false}
													placeholder="Address 2"
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_2}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_2]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_2,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="City"
													isRequired={true}
													placeholder="City"
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_CITY,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<SelectDropdown
													placeholder="Select"
													label="State"
													isRequired={true}
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE]}
													onChangeCb={(val) => {
														setFieldValue(FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE, val);
													}}
													options={indianStates}
												/>
											</div>
											{/* <div className="w-[32.5%]">
												<Input
													label="State"
													isRequired={false}
													placeholder="State"
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_STATE,
															e.target.value
														);
													}}
												/>
											</div> */}
											<div className="w-[32.5%]">
												<Input
													label="Country"
													isRequired={false}
													placeholder="Country"
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_COUNTRY,
															e.target.value
														);
													}}
												/>
											</div>
											<div className="w-[32.5%]">
												<Input
													label="Zip Code"
													isRequired={true}
													placeholder="Zip Code"
													name={FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP}
													value={values[FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.BILLING_ADDRESS_ZIP,
															e.target.value
														);
													}}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="mt-4">
									<Heading type={HEADING.H1} color="met-primary" fontSize={'md'} fontWeight="bold">
										Provider Group Office Hours
									</Heading>

									<div className="w-full flex-wrap border-[1px] p-4 mt-2 rounded-[5px] shadow-[0px_0px_6px_#00000029] ">
										{values[FORM_FIELDS_NAMES.OFFICE_HOURS]?.map((item, index) => (
											<div
												className="w-[45%]  flex column-gap-10px p-[10px]"
												key={index}
												style={{ rowGap: '10px' }}
											>
												{/* <div className="mt-3 w-[12%]">
													<ToggleSwitch
														name={`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].active`}
														checked={item['active'] === true ? true : false}
														onChangeCb={(val) => {
															setFieldValue(
																`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].active`,
																val
															);
														}}
													/>
												</div> */}
												<div className="mt-3 w-[25%]">
													<span>{item?.dayOfWeek}</span>
												</div>
												<div className="w-[30%]">
													{/* <DatePicker
														iconName="scheduleIcon"
														
														placeholder="Choose Time"
														name={`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].openingTime`}
														// value={item['openingTime']}
														onChangeCb={(time) => {
															setFieldValue(
																`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].openingTime`,
																time
															);
														}}
														timeCaption="Time"
														dateFormat='h:mm aa'
														showTimeSelect
														showTimeSelectOnly
													/> */}
													<Input
														placeholder="openingTime"
														endIcon={values[item?.openingTime]}
														name={`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].openingTime`}
														value={item['openingTime']}
														onChangeCb={(e) => {
															setFieldValue(
																`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].openingTime`,
																e.target.value
															);
														}}
													/>
												</div>
												<div className="w-[30%]">
													<Input
														placeholder="closingTime"
														endIcon={values[item?.closingTime]}
														name={`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].closingTime`}
														value={item['closingTime']}
														onChangeCb={(e) => {
															setFieldValue(
																`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].closingTime`,
																e.target.value
															);
														}}
													/>
												</div>
												{/* < div className="w-[30%]" >
													<DatePicker
														placeholder="Opening Time"
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={30}
														timeCaption="Time"
														dateFormat="h:mm aa"
														name={`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].openingTime`}
														value={item['openingTime']}
														onChangeCb={(time) => {
															setFieldValue(
																`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].openingTime`,
																time
															);
														}}
														iconName="scheduleIcon"
													/>
												</div>
												<div className="w-[30%]">
													<DatePicker
														placeholder="Closing Time"
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={30}
														timeCaption="Time"
														dateFormat="h:mm aa"
														name={`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].closingTime`}
														value={item['closingTime']}
														onChangeCb={(time) => {
															setFieldValue(
																`${FORM_FIELDS_NAMES.OFFICE_HOURS}[${index}].closingTime`,
																time
															);
														}}
														iconName="scheduleIcon"
													/>
												</div> */}
											</div>
										))}
									</div>
								</div>
							</div>
						</ModalComponent>
					</Form >
				);
			}}
		</Formik >
	);
};
export default ProfileModal;

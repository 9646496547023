export default {
    h1Body: {
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '29px',
        color: '#1A1A1A'
    },

    h2Body: {
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '29px',
        color: '#1A1A1A',
    },

    h3Body: {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '29px',
        color: '#1A1A1A',
    },

    h4Body: {
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '29px',
        color: '#1A1A1A',
    },
}
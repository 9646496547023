import React, { forwardRef, useEffect } from 'react'
import EmergencyContactCard from './EmergencyContactCard';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { Form, Formik, FieldArray } from 'formik'
import * as Yup from 'yup';
import { EMERGENCY_CONTACT_FIELDS } from './Constants';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientDetails, saveEmergencyContact } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import { componentKey, setCreatePatientData, setEditDemographicsData, setIsFormSubmitted } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import { useParams } from 'react-router-dom';
import { getValidationSchema } from '../../../libs/formsUtils';
import { MET_COLORS, VALIDATION_REGEX } from '../../../libs/constant';
import store from '../../../store/store';

const fields = [
    { fieldName: EMERGENCY_CONTACT_FIELDS.RELATIONSHIP_WITH_PATIENT, isDropdown: true },
    { fieldName: EMERGENCY_CONTACT_FIELDS.LASTNAME, isRequired: true, regexPattern: VALIDATION_REGEX.NAME_REGEX },
    { fieldName: EMERGENCY_CONTACT_FIELDS.FIRSTNAME, isRequired: true, regexPattern: VALIDATION_REGEX.NAME_REGEX },
    { fieldName: EMERGENCY_CONTACT_FIELDS.CONTACT_NUMBER, isRequired: true, regexPattern: VALIDATION_REGEX.MOBILE_NUM_REGEX },
    { fieldName: EMERGENCY_CONTACT_FIELDS.EMAIL, isEmail: true },
];

const validationSchema = Yup.object().shape({
    emergencyContacts: Yup.array().of(
        getValidationSchema(fields)
    )
});

const EmergencyContact = forwardRef(function EmergencyContact({ isUpdate = false, onUpdateCb = null }, formRef) {
    const { patientId } = useParams();
    const { newlyAddedPatientId, fetchedPatientDetails, createPatientData } = useSelector(state => state[componentKey])
    const dispatch = useDispatch()

    const { EmergencyContacts } = fetchedPatientDetails

    const initialValues = {
        emergencyContacts: EmergencyContacts?.length || createPatientData?.emergencyContacts?.length ? (EmergencyContacts?.length ? EmergencyContacts : createPatientData.emergencyContacts)?.map((contact) => {
            const { contactNumber, email, firstName, lastName, relationWithPatient, uuid } = contact
            return {
                uuid: uuid, //TODO: check if this is not causing any issue if yes please remove and find any altenative way to do this functionality
                [EMERGENCY_CONTACT_FIELDS.RELATIONSHIP_WITH_PATIENT]: { label: relationWithPatient, value: relationWithPatient },
                [EMERGENCY_CONTACT_FIELDS.LASTNAME]: lastName,
                [EMERGENCY_CONTACT_FIELDS.FIRSTNAME]: firstName,
                [EMERGENCY_CONTACT_FIELDS.CONTACT_NUMBER]: contactNumber,
                [EMERGENCY_CONTACT_FIELDS.EMAIL]: email ? email : undefined
            }
        }) : [{
            [EMERGENCY_CONTACT_FIELDS.RELATIONSHIP_WITH_PATIENT]: '',
            [EMERGENCY_CONTACT_FIELDS.LASTNAME]: '',
            [EMERGENCY_CONTACT_FIELDS.FIRSTNAME]: '',
            [EMERGENCY_CONTACT_FIELDS.CONTACT_NUMBER]: '',
            [EMERGENCY_CONTACT_FIELDS.EMAIL]: undefined
        }]
    };

    useEffect(() => {
        return () => {
            if (isUpdate) {
                store.reducerManager.remove(componentKey);
            }
        }
    }, [])

    useEffect(() => {
        if (patientId && patientId !== ":id" && isUpdate) {
            dispatch(getPatientDetails({ patientId }))
        }
    }, [dispatch, patientId, isUpdate])

    const addEmergencyContact = (values, setFieldValue) => {
        setFieldValue('emergencyContacts', [
            ...values.emergencyContacts,
            { [EMERGENCY_CONTACT_FIELDS.RELATIONSHIP_WITH_PATIENT]: { label: '', value: "" }, [EMERGENCY_CONTACT_FIELDS.LASTNAME]: '', [EMERGENCY_CONTACT_FIELDS.FIRSTNAME]: '', [EMERGENCY_CONTACT_FIELDS.CONTACT_NUMBER]: '', [EMERGENCY_CONTACT_FIELDS.EMAIL]: '' }
        ]);
    };

    const handleRemove = (index, values, setFieldValue) => {
        const updatedContacts = [...values.emergencyContacts];
        updatedContacts.splice(index, 1);
        setFieldValue('emergencyContacts', updatedContacts);
    };

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => {
                if (onUpdateCb) {
                    return onUpdateCb(values)
                }
                const { emergencyContacts } = values
                const contacts = emergencyContacts.map((item, index) => {
                    return { ...item, relationWithPatient: item?.relationWithPatient?.value, patientId: newlyAddedPatientId }
                })

                dispatch(saveEmergencyContact(contacts))
                dispatch(setCreatePatientData({ emergencyContacts: contacts }))
                dispatch(setIsFormSubmitted({ emergencyContact: false }))
                dispatch(setEditDemographicsData({ emergencyContact: values }));
            }}
        >
            {({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleBlur, handleSubmit, ...props }) => {
                return <Form onSubmit={handleSubmit} >
                    <FieldArray name="emergencyContacts">
                        {() => (
                            <>
                                {
                                    values.emergencyContacts.map((contact, index) => (
                                        <EmergencyContactCard handleBlur={handleBlur} key={index} enableDelete={values.emergencyContacts?.length > 1} handleRemoveCb={() => handleRemove(index, values, setFieldValue)} setFieldValue={setFieldValue} contact={contact} index={index} />
                                    ))
                                }
                                {values.emergencyContacts?.length < 4 ? <Button
                                    onClickCb={() => addEmergencyContact(values, setFieldValue)}
                                    variant={BUTTON_VARIANTS.TEXT}
                                    startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }}
                                    customBtnClass='text-met-primary'>
                                    Add Emergency Contact
                                </Button> : null}
                            </>
                        )}
                    </FieldArray>
                </Form>
            }}
        </Formik >
    )
});


export default EmergencyContact
import { useEffect } from "react"
import EncounterWrapper from "./EncounterWrapper"
import PatientInfo from "./PatientInfo/PatientInfo"
import { useDispatch, useSelector } from "react-redux"
import { getPatientDetails } from "../Patients/PatientDashboard/PatientDashboardSaga"
import { getAllPatientAllergiesDetails } from "../Patients/PatientDashboard/Allergies/PatientAllergiesSaga"
import FormioForm from "./FormBuilder"
import AppointmentDetailsCard from "./AppointmentDetails"
import EncounterSummary from "./EncounterData/EncounterSummary"
import { componentKey as PatientEncounterComponentKey, setIsEncounterSummaryOpen, setSelectedForm } from "./EncounterSlice"
import { useParams } from "react-router-dom"
import { getEncounterByIdDetails } from "./EncounterSaga"
import { getScheduleById } from "../Scheduling/SchedulingSaga"
import NotesDropdown from "./NotesDropdown"
import { getAllNotes } from "../../containers/SettingsTab/TemplatesCard/Tabs/VisitNotes/VisitNotesSaga"
import WrapperCard from "../../components/common-components/Cards/WrapperCard/WrapperCard"

const PatientEncounterDetails = () => {
    // const patientId = '8c5b8e4a-b64b-4100-9bbf-51a5727c3acd'
    const { patientId, encounterId } = useParams();
    const dispatch = useDispatch()
    const { isEncounterSummaryOpen, encounterDataById } = useSelector(state => state[PatientEncounterComponentKey])
    useEffect(() => {
        dispatch(getPatientDetails({ patientId }))
        dispatch(getEncounterByIdDetails({ encounterId }))
        dispatch(getAllPatientAllergiesDetails({ patientId }))
        dispatch(getAllNotes())
        dispatch(setSelectedForm({}))
        dispatch(setIsEncounterSummaryOpen(false))
    }, [patientId, encounterId, dispatch])

    useEffect(() => {
        if (encounterDataById && encounterDataById.scheduleId)
            dispatch(getScheduleById({ scheduleId: encounterDataById.scheduleId }))
    }, [encounterDataById, dispatch])
    
    return <div className="flex p-3 column-gap-20px">
        <div className="w-3/4">
            <div className="flex flex-col row-gap-15">
                <PatientInfo />
                <AppointmentDetailsCard />
                <WrapperCard>
                    <NotesDropdown></NotesDropdown>
                </WrapperCard>
            </div>
            <div className="mt-[20px]">
                {isEncounterSummaryOpen && <EncounterSummary />}
                {!isEncounterSummaryOpen && <FormioForm />}
            </div>
        </div>
        <div>
            <EncounterWrapper></EncounterWrapper>
        </div>
    </div>
}

export default PatientEncounterDetails
import store from '../../../../../store/store';

export const componentKey = 'PROVIDER_PROFILE_GROUP';

const initialState = {
	providerGroupDetails: {},
	allProviderGroupLocation: [],
	editedProfileData: {},
	isEditProfileModalOpen: false,
	currentProvidersUUID: '',
	currentProviderBillingAddresssUUID: '',
	currentProvidersPhysicalAddressUUID: '',
};
const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setProviderGroupDetails: (state, action) => {
			state.providerGroupDetails = action.payload;
		},
		setAllProviderGroupLocation: (state, action) => {
			state.allProviderGroupLocation = action.payload;
		},

		setEditedProfileData: (state, action) => {
			state.editedProfileData = action.payload;
		},
		setIsEditProfileModalOpen: (state, action) => {
			state.isEditProfileModalOpen = action.payload;
		},
		setCurrentProvidersUUID: (state, action) => {
			state.currentProvidersUUID = action.payload;
		},
		setCurrentProvidersPhysicalAddressUUID: (state, action) => {
			state.currentProvidersPhysicalAddressUUID = action.payload;
		},
		setCurrentProvidersBillingAddressUUID: (state, action) => {
			state.currentProviderBillingAddresssUUID = action.payload;
		},
	},
	initialReducerState: initialState,
});

export const {
	setProviderGroupDetails,
	setAllProviderGroupLocation,
	setEditedProfileData,
	setIsEditProfileModalOpen,
	setCurrentProvidersUUID,
	setCurrentProvidersPhysicalAddressUUID,
	setCurrentProvidersBillingAddressUUID,
} = actions;

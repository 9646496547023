export const VEC_ICON_NAME = {
    BACK_ICON: "BackIcon",
    UNMUTE_MIC_ICON: "UnMuteMicIcon",
    MUTE_MIC_ICON: "MuteMicIcon",
    OFF_CAMERA: "OffCamera",
    ON_CAMERA: "OnCamera",
    VIDEO_BACKGROUND_PROFILE: "VideoBackgroundProfile",
    MICROPHONE_ICON: "MicroPhoneIcon",
    UP_ARROW: "UpArrow",
    SPEAKER_ICON: "SpeakerIcon",
    VIDEO_CAM_ICON: "VideoCamIcon",
    EXIT_FULL_SCREEN: "ExitFullScreen",
    SCREEN_SHARE_ICON: "ScreenShareIcon",
    CALL_RECORDING_ICON: "CallRecordingIcon",
    LEAVE_MEETING_ICON: "LeaveMeetingIcon",
    ADD_GUEST_ICON: "AddGuestIcon",
    ZOOM_CHAT_ICON: "ZoomChatIcon",
    ZOOM_SETTING_ICON: "ZoomSettingIcon",
    TRIGGER_PATIENT_ICON: "TriggerPatientIcon",
    START_ENCOUNTER_ICON: "StartEncounterIcon",
    CLOSE_ICON: "CloseIcon",
    SHARE_DOCUMENT_ICON: "ShareDocumentIcon",
    SEND_MESSAGE_ICON: "SendMessageIcon",
    SEND_EMOJI_ICON: "SendEmojiIcon",
    RADIO_BUTTON_ICON: "RadioButtonIcon",
};

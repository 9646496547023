import store from '../../../../store/store';

export const componentKey = 'PATIENTDOCUMENTS_SECTION';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setDocumentsId: (state, action) => {
            state.currentDocumentsId = action.payload;
        },
        setAllDocumentsData: (state, action) => {
            state.allDocumentsData = action.payload;
        },
        setDocumentsData: (state, action) => {
            state.documentsData = action.payload;
        },
        setIsDocumentsModalOpen: (state, action) => {
            state.isDocumentsModalOpen = action.payload;
        },
        setIsNewDocumentsAdd: (state, action) => {
            state.isNewDocumentsAdd = action.payload;
        },
        setAllDocumentsTypeData: (state, action) => {
            state.allDocumentsTypeData = action.payload;
        },
        setIsDocumentsTypeModalOpen: (state, action) => {
            state.isDocumentsTypeModalOpen = action.payload;
        },
        setIsNewDocumentsTypeAdd: (state, action) => {
            state.isNewDocumentsTypeAdd = action.payload;
        },
        setDocumentsTypeData: (state, action) => {
            state.documentsTypeData = action.payload;
        },
        setDocumentsTypeId: (state, action) => {
            state.documentsTypeId = action.payload;
        },
        setFileImageUrl: (state, action) => {
            state.fileImageUrl = action.payload;
        },
        setIsOpenDeletePopUp: (state, action) => {
            state.isOpenDeletePopUp = action.payload
        }
    },
    initialReducerState: {
        currentDocumentsId: '',
        allDocumentsData: [],
        documentsData: {},
        isDocumentsModalOpen: false,
        isNewDocumentsAdd: false,
        allDocumentsTypeData: [],
        isDocumentsTypeModalOpen: false,
        isNewDocumentsTypeAdd: false,
        documentsTypeData: {},
        documentsTypeId: '',
        fileImageUrl: '',
        isOpenDeletePopUp: false
    },
});

export const { setDocumentsId, setAllDocumentsData, setDocumentsData, setIsDocumentsModalOpen, setIsNewDocumentsAdd, setAllDocumentsTypeData, setIsDocumentsTypeModalOpen, setIsNewDocumentsTypeAdd, setDocumentsTypeData, setDocumentsTypeId, setFileImageUrl, setIsOpenDeletePopUp } =
    actions;

const IsAccessAllowed = (allowedRoles = [], currentRole = []) => {
    if (typeof currentRole === "string") {
        return allowedRoles.includes(currentRole);
    } else {
        for (let role of currentRole) {
            if (allowedRoles.includes(role)) {
                return true;
            }
        }
        return false;
    }
}

export default IsAccessAllowed
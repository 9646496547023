import React from 'react'

const CloseIcon = ({ style, onClickCb }) => {
    return (
        <svg style={style} onClick={onClickCb} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_81720" data-name="Group 81720" transform="translate(-1071 -123)">
                <path id="Path_28552" data-name="Path 28552" d="M0,0H24V24H0Z" transform="translate(1071 123)" fill="none" />
                <path id="Path_28553" data-name="Path 28553" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(1071 123)" fill={style?.fill} />
            </g>
        </svg>

    )
}

export default CloseIcon

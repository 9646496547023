import React from 'react'

const HealthInsurance = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="health_and_safety_black_24dp" width="24" height="24" viewBox="0 0 24 24">
            <rect id="Rectangle_27001" data-name="Rectangle 27001" width="24" height="24" fill="none" />
            <path id="Path_186927" data-name="Path 186927" d="M10.5,13H8V10h2.5V7.5h3V10H16v3H13.5v2.5h-3ZM12,2,4,5v6.09C4,16.14,7.41,20.85,12,22c4.59-1.15,8-5.86,8-10.91V5Zm6,9.09a9.336,9.336,0,0,1-6,8.83,9.326,9.326,0,0,1-6-8.83V6.39l6-2.25,6,2.25Z" fill="rgba(26,26,26,0.6)" />
        </svg>
    )
}
export default HealthInsurance
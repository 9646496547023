import React, { forwardRef, useEffect, useState } from 'react';
import Input from '../../../components/common-components/input/Input';
import Label from '../../../components/common-components/label/Label';
import RadioButton from '../../../components/common-components/radioButton/RadioButton';
import Icons from '../../../components/icons/Icons';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import DatePicker from '../../../components/common-components/datePicker';
import Upload from '../../../components/common-components/upload/Upload';
import { FORM_FIELDS_NAMES } from './Constants';
import General from '../../../libs/utility/General';
import { componentKey } from '../../Wrappers/Dataloader/DataloaderSlice';
import { useSelector } from 'react-redux';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';

function InsuranceCard({ insurance = {}, isSelfChecked, setFieldValue = () => { }, handleBlur = () => { }, index, handleRemoveCb, enableDelete = false }) {
	const { indianStates } = useSelector(state => state[componentKey])

	// TYPE, NAME, PLAN NAME (OPTIONAL), COMPANY NAME, STARTDDATE, ENDDATE, FIRSTNAME (OPTIONAL), LASTNAME (OPTIONAL),policy num,  INSURANCE IMAGES FRONT AND BACK

	return (
		<>
			<div className="custom-provider-information mt-4 mb-4 bg-opacity-3 rounded-md">
				<div className="flex column-gap-16 row-gap-16  flex-wrap pr-4 pt-4 pb-4 pl-4">
					<div className="flex-1 min-w-410">
						<SelectDropdown placeholder='Select'
							label='Insurance Type'
							isRequired={true}
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.INSURANCE_TYPE}`}
							value={insurance[FORM_FIELDS_NAMES.INSURANCE_TYPE]}
							onChangeCb={(val) => { setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.INSURANCE_TYPE}`, val) }}
							options={[
								{ label: "Primary Insurance", value: "Primary" },
								{ label: "Secondary Insurance", value: "Secondary" },
								{ label: "Other", value: "Other" },
							]}
						/>
					</div>
					<div className="flex-1 min-w-410">
						<SelectDropdown placeholder='Select'
							label='Insurance Name'
							isRequired={true}
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.INSURANCE_NAME}`}
							value={insurance[FORM_FIELDS_NAMES.INSURANCE_NAME]}
							onChangeCb={(val) => { setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.INSURANCE_NAME}`, val) }}
							options={[
								{ label: "Other", value: "other" },
							]} />
					</div>
					<div className="flex-1 min-w-410">
						<Input
							label="Policy Number"
							placeholder="Enter Policy Number"
							isRequired={true}
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.POLICY_NUMBER}`}
							value={insurance[FORM_FIELDS_NAMES.POLICY_NUMBER]}
							onChangeCb={(e) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.POLICY_NUMBER}`, e.target.value)
							}}
							onBlurCb={handleBlur} />
					</div>
					<div className="flex-1 min-w-410">
						<Input
							label="Plan Name"
							placeholder="Enter Plan Name"
							labelfontWeight="bold"
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.PLAN_NAME}`}
							value={insurance[FORM_FIELDS_NAMES.PLAN_NAME]}
							onChangeCb={(e) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.PLAN_NAME}`, e.target.value)
							}}
							onBlurCb={handleBlur}
						/>
					</div>
					<div className="flex-1 min-w-410">
						<Input
							label="Company Name"
							placeholder="Enter Company Name"
							isRequired={true}
							abelfontWeight="bold"
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.COMPANY_NAME}`}
							value={insurance[FORM_FIELDS_NAMES.COMPANY_NAME]}
							onChangeCb={(e) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.COMPANY_NAME}`, e.target.value)
							}}
							onBlurCb={handleBlur}
						/>
					</div>
					<div className="flex-1 min-w-410">
						<DatePicker
							label="Effective Start Date"
							placeholder='Select'
							isRequired={true}
							value={insurance[FORM_FIELDS_NAMES.EFFECTIVE_START_DATE]}
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.EFFECTIVE_START_DATE}`}
							onChangeCb={(date) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.EFFECTIVE_START_DATE}`, date)
							}} />
					</div>
					<div className="flex-1 min-w-410 pr-2">
						<DatePicker
							label="Effective End Date"
							placeholder='Select'
							isRequired={true}
							minDate={insurance[FORM_FIELDS_NAMES.EFFECTIVE_START_DATE] ? new Date(insurance[FORM_FIELDS_NAMES.EFFECTIVE_START_DATE]).setDate(new Date(insurance[FORM_FIELDS_NAMES.EFFECTIVE_START_DATE]).getDate() + 1) : new Date()}
							value={insurance[FORM_FIELDS_NAMES.EFFECTIVE_END_DATE]}
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.EFFECTIVE_END_DATE}`}
							onChangeCb={(date) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.EFFECTIVE_END_DATE}`, date)
							}} />
					</div>
				</div>
				<div className="flex column-gap-16 row-gap-16  flex-wrap pr-4 pt-2 pb-2 pl-4">
					<div>
						<Label fontWeight="bold">Patient Relationship To Insured</Label>
					</div>
				</div>
				<div className="flex column-gap-16 row-gap-16  flex-wrap pr-4 pt-2 pb-2 pl-4">
					<div className="flex flex-row  gap-6">
						<div>
							<RadioButton
								name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED}`}
								checked={insurance[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED] === FORM_FIELDS_NAMES.SELF}
								onChangeCb={(e) => {
									setFieldValue(
										`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED}`,
										FORM_FIELDS_NAMES.SELF
									);
								}}
								label={"Self"}
							/>
						</div>
						<div>
							<RadioButton
								name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED}`}
								checked={insurance[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED] === FORM_FIELDS_NAMES.SPOUSE}
								onChangeCb={(e) => {
									setFieldValue(
										`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED}`,
										FORM_FIELDS_NAMES.SPOUSE
									);
								}}
								label={"Spouse"}
							/>
						</div>
						<div>
							<RadioButton
								name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED}`}
								checked={insurance[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED] === FORM_FIELDS_NAMES.CHILD}
								onChangeCb={(e) => {
									setFieldValue(
										`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED}`,
										FORM_FIELDS_NAMES.CHILD
									);
								}}
								label={"Child"}
							/>
						</div>
						<div>
							<RadioButton
								name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED}`}
								checked={insurance[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED] === FORM_FIELDS_NAMES.OTHER}
								onChangeCb={(e) => {
									setFieldValue(
										`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED}`,
										FORM_FIELDS_NAMES.OTHER
									);
								}}
								label={"Other"}
							/>
						</div>
					</div>
				</div>
				{insurance[FORM_FIELDS_NAMES.PATIENT_RELATIONSHIP_TO_BE_INSURED] !== FORM_FIELDS_NAMES.SELF ? <div className="flex column-gap-16 row-gap-16  flex-wrap  pr-4 pt-2 pb-2 pl-4">
					<div className="flex-1 min-w-410">
						<Input
							label="First Name"
							placeholder="Enter First Name"
							isRequired={true}
							labelfontWeight="bold"
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.FIRST_NAME}`}
							value={insurance[FORM_FIELDS_NAMES.FIRST_NAME]}
							onChangeCb={(e) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.FIRST_NAME}`, e.target.value)
							}}
							onBlurCb={handleBlur} />
					</div>
					<div className="flex-1 min-w-410">
						<Input
							label="Last Name"
							placeholder="Enter Last Name"
							isRequired={true}
							labelfontWeight="bold"
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.LAST_NAME}`}
							value={insurance[FORM_FIELDS_NAMES.LAST_NAME]}
							onChangeCb={(e) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.LAST_NAME}`, e.target.value)
							}}
							onBlurCb={handleBlur}
						/>
					</div>
					<div className="flex-1 min-w-410">
						<DatePicker
							label="Date Of Birth"
							placeholder='Choose Date'
							maxDate={new Date()}
							value={insurance[FORM_FIELDS_NAMES.DATE_OF_BIRTH]}
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.DATE_OF_BIRTH}`}
							onChangeCb={(date) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.DATE_OF_BIRTH}`, date)
							}}
						/>
					</div>
					<div className="flex-1 min-w-410">
						<SelectDropdown
							placeholder='Select'
							label='Gender'
							isRequired={true}
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.GENDER}`}
							value={insurance[FORM_FIELDS_NAMES.GENDER]}
							onChangeCb={(val) => { setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.GENDER}`, val) }}
							options={[
								{ label: "Male", value: "Male" },
								{ label: "Female", value: "Female" },
								{ label: "Other", value: "other" },
							]}
						/>
					</div>
					<div className="flex-basis-50-10">
						<Input
							label="Address Line 1"
							placeholder="Enter Address Line 1"
							isRequired={true}
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.ADDRESS_LINE_1}`}
							value={insurance[FORM_FIELDS_NAMES.ADDRESS_LINE_1]}
							onChangeCb={(e) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.ADDRESS_LINE_1}`, e.target.value)
							}}
							onBlurCb={handleBlur}
						/>
					</div>
					<div className="flex-basis-50-10">
						<Input
							label="Address Line 2"
							placeholder="Enter Address Line 1"
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.ADDRESS_LINE_2}`}
							value={insurance[FORM_FIELDS_NAMES.ADDRESS_LINE_2]}
							onChangeCb={(e) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.ADDRESS_LINE_2}`, e.target.value)
							}}
							onBlurCb={handleBlur}
						/>
					</div>
					<div className="flex-1 min-w-410">
						<Input
							label="City"
							placeholder="Enter City"
							isRequired={true}
							labelfontWeight="bold"
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.CITY}`}
							value={insurance[FORM_FIELDS_NAMES.CITY]}
							onChangeCb={(e) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.CITY}`, e.target.value)
							}}
							onBlurCb={handleBlur}
						/>
					</div>
					<div className="flex-1 min-w-410">
						<SelectDropdown
							placeholder='Select State'
							isRequired={true}
							label='State'
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.STATE}`}
							value={insurance[FORM_FIELDS_NAMES.STATE]}
							onChangeCb={(val) => { setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.STATE}`, val) }}
							options={indianStates}
							onBlurCb={handleBlur}
						/>
					</div>
					<div className="flex-1 min-w-410">
						<Input
							placeholder='Enter Country'
							label='Country'
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.COUNTRY}`}
							value={insurance[FORM_FIELDS_NAMES.COUNTRY]}
							onChangeCb={(e) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.COUNTRY}`, e.target.value)
							}}
							onBlurCb={handleBlur}
						/>
					</div>
					<div className="flex-1 min-w-410">
						<Input
							label="Zip Code"
							placeholder="Enter Zip Code"
							isRequired={true}
							labelfontWeight="bold"
							name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.ZIP_CODE}`}
							value={insurance[FORM_FIELDS_NAMES.ZIP_CODE]}
							onChangeCb={(e) => {
								setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.ZIP_CODE}`, e.target.value)
							}}
							onBlurCb={handleBlur}
						/>
					</div>
				</div> : null}
				<div className="flex column-gap-16 row-gap-16  flex-wrap  pr-4 pt-2 pb-2 pl-4">
					<div>
						<Label isRequired="true" fontWeight="bold">Upload Insurance Card</Label>
					</div>
				</div>
				<div className="flex column-gap-16 row-gap-16  flex-wrap pl-4  pb-4">
					<>
						<div>
							<Upload
								icon={<Icons iconName="uploadIcon" color="#2879C9" />}
								title="Click here to upload Front Side"
								subTitle="Drag & drop your files or,"
								link="Browse"
								customClass='h-[170px] w-[306px] rounded-xl'
								borderType={null}
								isHoverSelectOption={true}
								isRequired={true}
								url={!General.isMETBase64(insurance[FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT]) ? insurance[FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT] : ""}
								name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT}`}
								// value={insurance[FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT] || ''}
								onChangeCb={async (file) => {
									const mimeType = file?.type || ""
									const base64String = await General.fileToBase64(file)
									const base64 = `data:${mimeType};base64,${base64String}`
									setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.INSUREANCE_CARD_FRONT}`, base64)
								}}
							/>
						</div>
						<div>
							<Upload
								icon={<Icons iconName="uploadIcon" color="#2879C9" />}
								title="Click here to upload Back Side"
								subTitle="Drag & drop your files or,"
								link="Browse"
								customClass='h-[170px] w-[306px] rounded-xl'
								borderType={null}
								isHoverSelectOption={true}
								isRequired={true}
								name={`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK}`}
								value={insurance[FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK] || ''}
								url={!General.isMETBase64(insurance[FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK]) ? insurance[FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK] : ""}
								onChangeCb={async (file) => {
									const mimeType = file?.type || ""
									const base64String = await General.fileToBase64(file)
									const base64 = `data:${mimeType};base64,${base64String}`
									setFieldValue(`addPatientInsurance[${index}].${FORM_FIELDS_NAMES.INSUREANCE_CARD_BACK}`, base64)
								}}
							/>
						</div>
					</>

				</div>
				{enableDelete ? <div className='p-2 flex justify-end'>
					<Button variant={BUTTON_VARIANTS.OUTLINED}
						customBtnClass="bg-red-50 text-red-500 border-red-500"
						startIcon={{ icon: <Icons iconName="trashIcon" /> }} onClickCb={handleRemoveCb}>Delete</Button>
				</div> : null}
			</div >
		</>
	);
}
export default InsuranceCard
import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import ProviderAdminWrapper from './providerAdmin/ProviderAdminWrapper'
import ProviderDashboardWrapper from './provider/ProviderDashboardWrapper'
import { MetToggleType1 } from '../../components/common-components/MetToggles/MetToggleType1'
import { useDispatch, useSelector } from 'react-redux'
import useGetCurrenPathHook from '../../libs/customHooks/useGetCurrenPathHook'
import { componentKey, setActiveDashboardTab, setIsOpenCalenderRange } from './DashboardSlice'
import { MET_COLORS } from '../../libs/constant'
import Button from '../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../components/common-components/button/Constants'
import Icons from '../../components/icons/Icons'
import CalenderRangeModal from './providerAdmin/CalenderRangeModal'
import { Navigate } from 'react-router-dom'
import AllowAdminOnly from '../../containers/Wrappers/AllowAdminOnly'
import IsAccessAllowed from '../../containers/Wrappers/IsAccessAllowed'
import { USER_ROLE_NAMES } from '../../containers/Wrappers/Constants'
import { componentKey as routerComponentKey } from '../../routes/RoutesSlice'

const HomeSection = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const currentPath = useGetCurrenPathHook()

    const { activeDashboardTab, isOpenCalenderRange } = useSelector(state => state[componentKey])
    const { loggedInProviderUser } = useSelector(state => state[routerComponentKey])

    useEffect(() => {
        dispatch(setActiveDashboardTab(currentPath))
    }, [currentPath, dispatch])

    const routes = [
        { path: "/provider", component: <ProviderDashboardWrapper /> },
        { path: "/provider-admin", component: <AllowAdminOnly fallback={() => { navigate('provider') }}><ProviderAdminWrapper /> </AllowAdminOnly> },
        { path: "/", component: <Navigate to={"provider"} /> },

    ]
    return (
        <>
            <div className='flex flex-col rounded m-0 h-[calc(100vh-150px)]'>
                <div className='flex  justify-between'>
                    <div className="font-bold text-2xl ml-4 mt-4">Dashboard</div>
                    <div className='flex items-center column-gap-20px mr-4 mt-4'>
                        <div>
                            {IsAccessAllowed([
                                USER_ROLE_NAMES.ADMIN_PROVIDER,
                            ], loggedInProviderUser?.roleNames) ? <MetToggleType1
                                options={[
                                    {
                                        label: <div className={activeDashboardTab === "/provider" ? MET_COLORS.GRAY : MET_COLORS.PRIMARY}>Provider</div>,
                                        id: "1",
                                        value: "/provider",
                                        navigate: "/dashboard/provider",
                                    },
                                    {
                                        label: <div className={activeDashboardTab === "/provider-admin" ? MET_COLORS.GRAY : MET_COLORS.PRIMARY}>Provider Admin</div>,
                                        id: "2",
                                        value: '/provider-admin',
                                        navigate: "/dashboard/provider-admin"
                                    },
                                ]}
                                selected={currentPath}
                                onChangeCb={(option) => {
                                    navigate(option.navigate)
                                }}
                                customBtnClass={`w-[180px] justify-center font-bold`}
                            />
                                : null}
                        </div>
                        {activeDashboardTab === '/provider-admin' && (
                            <div>
                                <Button onClickCb={() => { dispatch(setIsOpenCalenderRange(true)) }} startIcon={{ icon: <Icons iconName="calenderIcon" /> }} customBtnClass='h-[50px] font-bold bg-white shadow-md rounded-md fs-16' variant={BUTTON_VARIANTS.CONTAINED_GRAY}>Calendar Range</Button>
                            </div>
                        )}

                    </div>
                </div>

                <div className='relative'>
                    <Routes>
                        {routes.map((route, index) => {
                            return <Route key={"patient-history-route" + index} path={route.path} element={route.component} />
                        })}
                    </Routes>
                </div>
            </div>
            {isOpenCalenderRange ? <CalenderRangeModal open={isOpenCalenderRange} close={() => { dispatch(setIsOpenCalenderRange(false)) }} /> : null}

        </>

    )
}

export default HomeSection
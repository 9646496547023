import React from 'react';

const PlusVectorIcon = ({ color = 'gray', width = '24px', height = '24px', style }) => {
	return (
		<svg
			id="add_black_24dp"
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={style}
			viewBox="0 0 24 24"
		>
			<path id="Path_204259" data-name="Path 204259" d="M0,0H24V24H0Z" fill="none" />
			<path id="Path_204260" data-name="Path 204260" d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z" fill={color} />
		</svg>
	);
};

export default PlusVectorIcon;

import React, { useEffect } from 'react'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Icons from '../../../../components/icons/Icons'
import Input from '../../../../components/common-components/input/Input'
import Table from '../../../../containers/Table/Index'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProceduralodes } from './GenrateSuperBillSaga'
import { componentKey } from './GenrateSuperBillSlice'
import SelectDropdown from '../../../../components/common-components/selectDropdown'
import { proceduralCodesFieldNames } from './Constants'
import General from '../../../../libs/utility/General'
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice'
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/MetToaster'

function ProceduralCodeTable({ addProceduralCodes, values, setFieldValue, handleBlur, handleRemoveCb }) {
    const { proceduralCodesData, billData } = useSelector(state => state[componentKey])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllProceduralodes())
    }, [dispatch])

    const handleCopyRow = (row) => {
        const { charges, quantity, discountInPercentage, discountInAmount, netAmount } = row
        const copiedText = `${row.number} ${row.proceduralCode} ${row.proceduralCodeDescription} ${charges.props.value} ${quantity.props.value} ${discountInPercentage.props.value} ${discountInAmount.props.value} ${netAmount.props.value}`;
        navigator.clipboard.writeText(copiedText);
    };
    const { mapping_billing_payment } = billData || {}

    const coloumns = [
        {
            field: "number",
            label: <Label fontWeight='md'>Sr No</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center w-[85px]'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.number}.</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "proceduralCode",
            label: <Label fontWeight='md'>Procedural Charges</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{`${row.proceduralCode} ${row.proceduralCodeDescription}`}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "charges",
            label: <Label fontWeight='md'>Charges (₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.charges}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "quantity",
            label: <Label fontWeight='md'>Quantity</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.quantity}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "disc",
            label: <Label fontWeight='md'>Disc(-%)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.discountInPercentage}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "discINPercentage",
            label: <Label fontWeight='md'>Disc(-₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.discountInAmount}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "netAmount",
            label: <Label fontWeight='md'>Net(₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.netAmount}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "action",
            label: <Label fontWeight='md'>Action</Label>,
            renderLogic: (row) => {
                const isDisabled = mapping_billing_payment?.length > 0;
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.action}</Heading>
                        </div>
                        <div className={isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}
                            onClick={!isDisabled ? () => handleRemoveCb(row.uuid) : null}><Icons iconName="trashIcon" /></div>
                        <div
                            onClick={() => { handleCopyRow(row) }}
                            className='cursor-pointer'
                        >
                            <Icons iconName="copyIcon" />
                        </div>
                    </div >
                </>
            },
        },
    ]


    const getRowData = () => {
        return values.billingProceduralCodes?.map((item, index) => {
            return {
                number: index + 1,
                uuid: item.uuid,
                proceduralCode: item[proceduralCodesFieldNames.PROCEDURAL_CODE],
                proceduralCodeDescription: item[proceduralCodesFieldNames.PROCEDURAL_CODE_DESCRIPTION],
                charges: <Input
                    customClasses="w-[70px]"
                    name={`billingProceduralCodes[${index}].${proceduralCodesFieldNames.CHARGES}`}
                    placeholder='Enter'
                    value={item[proceduralCodesFieldNames.CHARGES]}
                    onChangeCb={(e) => {
                        setFieldValue(`billingProceduralCodes[${index}].${proceduralCodesFieldNames.CHARGES}`, General.sanitizeNumbers(e.target.value),)
                        setFieldValue(`billingProceduralCodes[${index}].${proceduralCodesFieldNames.DISCOUNT_IN_AMOUNT}`, (values.billingProceduralCodes[index][proceduralCodesFieldNames.DISCOUNT_IN_PERCENTAGE] / 100) * (e.target.value * values.billingProceduralCodes[index].quantity));
                        setFieldValue(`billingProceduralCodes[${index}].${proceduralCodesFieldNames.NET_AMOUNT}`, ((e.target.value * values.billingProceduralCodes[index].quantity) - (values.billingProceduralCodes[index][proceduralCodesFieldNames.DISCOUNT_IN_PERCENTAGE] / 100) * (e.target.value * values.billingProceduralCodes[index].quantity)))
                    }
                    }
                    disabled={mapping_billing_payment?.length ? true : false}

                />,
                quantity: <Input
                    customClasses="w-[70px]"
                    placeholder={"Enter"}
                    name={`billingProceduralCodes[${index}].quantity`}
                    value={item[proceduralCodesFieldNames.QUANTITY]}
                    onChangeCb={(e) => {
                        setFieldValue(`billingProceduralCodes[${index}].${proceduralCodesFieldNames.QUANTITY}`, General.sanitizeNumbers(e.target.value))
                        setFieldValue(`billingProceduralCodes[${index}].${proceduralCodesFieldNames.DISCOUNT_IN_AMOUNT}`, (values.billingProceduralCodes[index][proceduralCodesFieldNames.DISCOUNT_IN_PERCENTAGE] / 100) * (values.billingProceduralCodes[index].charges * e.target.value));
                        setFieldValue(`billingProceduralCodes[${index}].${proceduralCodesFieldNames.NET_AMOUNT}`, ((values.billingProceduralCodes[index].charges * e.target.value) - (values.billingProceduralCodes[index][proceduralCodesFieldNames.DISCOUNT_IN_PERCENTAGE] / 100) * (values.billingProceduralCodes[index].charges * e.target.value)))
                    }}
                    onBlurCb={handleBlur}
                    disabled={mapping_billing_payment?.length ? true : false}
                />,
                discountInPercentage: <Input
                    customClasses="w-[70px]"
                    placeholder={"Enter"}
                    name={`billingProceduralCodes[${index}].${proceduralCodesFieldNames.DISCOUNT_IN_PERCENTAGE}`}
                    value={item[proceduralCodesFieldNames.DISCOUNT_IN_PERCENTAGE]}
                    onChangeCb={(e) => {
                        setFieldValue(`billingProceduralCodes[${index}].${proceduralCodesFieldNames.DISCOUNT_IN_PERCENTAGE}`, General.sanitizeNumbers(e.target.value))
                        setFieldValue(`billingProceduralCodes[${index}].${proceduralCodesFieldNames.DISCOUNT_IN_AMOUNT}`, (e.target.value / 100) * (values.billingProceduralCodes[index].charges * values.billingProceduralCodes[index].quantity));
                        setFieldValue(
                            `billingProceduralCodes[${index}].${proceduralCodesFieldNames.NET_AMOUNT}`,
                            ((values.billingProceduralCodes[index].charges * values.billingProceduralCodes[index].quantity) * (1 - parseFloat(e?.target?.value || 0) / 100))
                        );

                    }}
                    onBlurCb={handleBlur}
                    disabled={mapping_billing_payment?.length ? true : false}
                />,
                discountInAmount: <Input
                    customClasses="w-[70px]"
                    name={`billingProceduralCodes[${index}].${proceduralCodesFieldNames.DISCOUNT_IN_AMOUNT}`}
                    placeholder='Enter'
                    value={item[proceduralCodesFieldNames.DISCOUNT_IN_AMOUNT]}
                    onChangeCb={(e) => {
                        setFieldValue(`billingProceduralCodes[${index}].${proceduralCodesFieldNames.DISCOUNT_IN_AMOUNT}`, General.sanitizeNumbers(e.target.value))
                        setFieldValue(`billingProceduralCodes[${index}].${proceduralCodesFieldNames.DISCOUNT_IN_PERCENTAGE}`, (General.sanitizeNumbers(e.target.value) / (values.billingProceduralCodes[index].charges * values.billingProceduralCodes[index].quantity)) * 100)
                        setFieldValue(`billingProceduralCodes[${index}].${proceduralCodesFieldNames.NET_AMOUNT}`, ((values.billingProceduralCodes[index].charges * values.billingProceduralCodes[index].quantity) - e.target.value))
                    }}
                    disabled={mapping_billing_payment?.length ? true : false}

                />,
                netAmount: <Input
                    customClasses="w-[70px]"
                    name={`billingProceduralCodes[${index}].${proceduralCodesFieldNames.NET_AMOUNT}`}
                    placeholder='Enter'
                    value={item[proceduralCodesFieldNames.NET_AMOUNT]?.toFixed(2)}
                    disabled={true}
                />,
            }

        })
    }

    const message = "Already Exists";

    return (
        <>

            <div className='w-full'>

                <div className='h-[45px] items-center pl-4 bg-met-secondary flex font-semibold'>
                    Procedural Code
                </div>
                <div className='mt-[20px] w-[50%]'>
                    <SelectDropdown
                        placeholder="Search Procedural Code"
                        options={proceduralCodesData}
                        disabled={mapping_billing_payment?.length ? true : false}
                        onChangeCb={(selectedOpt) => {
                            if (selectedOpt && selectedOpt !== null && Object.keys(selectedOpt).length > 0) {
                                const similarOpt = values?.billingProceduralCodes?.find(item => item.uuid === selectedOpt.uuid)
                                if (similarOpt) {
                                    dispatch(addNotifications({ message: `${selectedOpt.proceduralCodeDescription} ${message}`, variant: TOASTER_VARIANT.ERROR }))
                                }
                                else {
                                    addProceduralCodes(selectedOpt)
                                }
                            }
                        }}
                    />
                </div>
                <div className='pt-[20px]'>
                    <Table isPagination={false} coloumns={coloumns} rows={getRowData()} />
                </div>
            </div>
        </>
    )
}

export default ProceduralCodeTable
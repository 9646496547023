import moment from "moment";

export const getFormattedPatientAgeDob = (dob) => {
    if (dob) {
        const formattedDob = moment(dob).format("DD/MM/YYYY");
        const age = moment(new Date()).diff(moment(dob), 'years');
        return `${formattedDob} (${age} Yrs)`;
    } else {
        return '';
    }
}
export const getFormattedAge = (dob) => {
    if (dob) {
        const age = moment(new Date()).diff(moment(dob), 'years');
        return `(${age} Yrs)`;
    } else {
        return '';
    }
}
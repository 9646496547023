import React from 'react';
import Popup from '../Popup';
import styles from './ConfirmationPopup.styles';
import Button from '../../button/Button';
import { BUTTON_TYPE } from '../../../constants';
import Icons from '../../icon/Icon';

export default function ConfirmationPopup({
    title,
    bodyText,
    iconName = '',
    isOpen,
    confirmationButtonProps = {
        onConfirmationCb: () => { },
        confirmationButtonText: "Submit",
        confirmationButtonStyles: {},
        confirmationButtonType: BUTTON_TYPE.PRIMARY,
    },
    cancelButtonProps = {
        onCancelCb: () => { },
        onCloseCb: () => { },
        cancelButtonText: "Cancel",
        cancelButtonStyles: {},
        cancelButtonType: BUTTON_TYPE.PRIMARY_LIGHT_WITH_BORDER,
    },
    customStyle = {},
    isClose = true,
    showClose = true,
}) {
    return (

        <Popup title={title} onCloseCb={isClose ? cancelButtonProps?.onCancelCb : cancelButtonProps?.onCloseCb} showClose={showClose} isPopupOpen={isOpen} customPopupSyles={customStyle}>
            <div style={styles.bodyContainer}>

                {iconName && <div>
                    <Icons familyName='vec' iconName={iconName} />
                </div>}

                {bodyText && <span style={styles.bodyText}>{<p style={styles.innerHtmlContent}>{bodyText}</p>}</span>}

            </div>

            <div style={styles.footer}>

                {confirmationButtonProps.confirmationButtonText && <div onClick={confirmationButtonProps.onConfirmationCb}>
                    <Button
                        type={confirmationButtonProps.confirmationButtonType}
                        style={{ ...confirmationButtonProps.confirmationButtonStyles, ...styles.button }}
                    >
                        {confirmationButtonProps.confirmationButtonText}
                    </Button>
                </div>}

                {
                    cancelButtonProps !== null && <div onClick={cancelButtonProps.onCancelCb}>
                        <Button
                            type={cancelButtonProps.cancelButtonType}
                            style={{ ...cancelButtonProps.cancelButtonStyles, ...styles.button }}
                        >
                            {cancelButtonProps.cancelButtonText}
                        </Button>
                    </div>
                }

            </div>
        </Popup>
    )
}
export const USER_PERMISSIONS = {
    // VIEW_DOCUMENT_INTAKE: "VIEW_DOCUMENT_INTAKE",
    VIEW_PATIENTS_DOCUMENTS: "VIEW_PATIENTS_DOCUMENTS",  // Used
    DOWNLOAD_PATIENTS_DOCUMENTS: "DOWNLOAD_PATIENTS_DOCUMENTS", // UnUsed
    UPLOAD_PATIENTS_DOCUMENTS: "UPLOAD_PATIENTS_DOCUMENTS", // Used
    CHECK_IN_PATIENT: "CHECK_IN_PATIENT", // Unused
    UPDATE_CHECK_IN: "UPDATE_CHECK_IN", // Unused
    COLLECT_PAYMENTS: "COLLECT_PAYMENTS", // Used
    IMPORT_INTAKE_FORM: "IMPORT_INTAKE_FORM", // Unused
    COLLECT_PAST_HISTORY: "COLLECT_PAST_HISTORY", // Unused
    CHECK_VITALS: "CHECK_VITALS", // Unused
    EXAM: "EXAM", // Unused
    VIEW_CLINICAL_NOTES: "VIEW_CLINICAL_NOTES", // Used
    INTERACT_CLINICAL_NOTES: "INTERACT_CLINICAL_NOTES", // Unused
    SIGN_CLINICAL_NOTES: "SIGN_CLINICAL_NOTES", // Unused 
    LOCK_CLINICAL_NOTES: "LOCK_CLINICAL_NOTES", // Unused 
    ACCESS_REPORTS: "ACCESS_REPORTS", // Unued
    INTERACT_REPORTS: "INTERACT_REPORTS", // Unused
    UPLOAD_RADIOLOGY_REPORT: "UPLOAD_RADIOLOGY_REPORT", // Unused
    UPLOAD_LAB_REPORT: "UPLOAD_LAB_REPORT", // Used
    DELETE_RADIOLOGY_REPORT: "DELETE_RADIOLOGY_REPORT", // Unused
    DELETE_LAB_REPORT: "DELETE_LAB_REPORT", // Used
    CREATE_SUPER_BILL: "CREATE_SUPER_BILL", // Used
    UPDATE_SUPER_BILL: "UPDATE_SUPER_BILL", // Used
    DELETE_SUPER_BILL: "DELETE_SUPER_BILL", // Unused
    CREATE_CLAIM: "CREATE_CLAIM", // Unused
    UPDATE_CLAIM: "UPDATE_CLAIM", // Unused
    DELETE_CLAIM: "DELETE_CLAIM", // Unused
    VIEW_SUPER_BILL: "VIEW_SUPER_BILL", // used
    INTERACT_SUPER_BILL: "INTERACT_SUPER_BILL", // Unused
    VIEW_CLAIM: "VIEW_CLAIM", // Unused
    INTERACT_CLAIM: "INTERACT_CLAIM", // Unused
    SUBMIT_CLAIM: "SUBMIT_CLAIM", // Unused
    CREATE_FEE_SCHEDULE: "CREATE_FEE_SCHEDULE", // Unused
    UPDATE_FEE_SCHEDULE: "UPDATE_FEE_SCHEDULE", // Unused
    DELETE_FEE_SCHEDULE: "DELETE_FEE_SCHEDULE", // Unused
    VIEW_FEE_SCHEDULE: "VIEW_FEE_SCHEDULE", // Unused
    ACCESS_MESSAGE: "ACCESS_MESSAGE", // Unused
    ACCESS_AUDIO_CALL: "ACCESS_AUDIO_CALL", // Unused
    ACCESS_FAX: "ACCESS_FAX", // Unused
    ACCESS_TASKS: "ACCESS_TASKS", // Unused
    EDIT_PROFILE: "EDIT_PROFILE", // Used 
    VIEW_PROFILE: "VIEW_PROFILE", // used
    VIEW_SETTINGS: "VIEW_SETTINGS", // Used
    EDIT_PRACTICE_GROUP: "EDIT_PRACTICE_GROUP", // Used
    VIEW_PRACTICE_GROUP: "VIEW_PRACTICE_GROUP", // Used
    CREATE_TEMPLATES: "CREATE_TEMPLATES", // Used
    UPDATE_TEMPLATES: "UPDATE_TEMPLATES", // Unused
    DELETE_TEMPLATES: "DELETE_TEMPLATES", // Used
    VIEW_TEMPLATES: "VIEW_TEMPLATES", // Unused
    CREATE_FORM_BUILDER: "CREATE_FORM_BUILDER", // Unused
    UPDATE_FORM_BUILDER: "UPDATE_FORM_BUILDER", // Unused
    DELETE_FORM_BUILDER: "DELETE_FORM_BUILDER", // Unsed
    VIEW_FORM_BUILDER: "VIEW_FORM_BUILDER", // Unused
    CREATE_USER: "CREATE_USER", // used
    VIEW_USER: "VIEW_USER", // used
    MANAGE_PERMISSIONS: "MANAGE_PERMISSIONS", // Unused
    MANAGE_PROVER_ACCOUNT: "MANAGE_PROVER_ACCOUNT", // Unused
    ACCOUNT: "ACCOUNT", // Used
    ACCESS_ERX: "ACCESS_ERX", // Unused
    ADD_PATIENT: "ADD_PATIENT", // Used
    UPDATE_PATIENT: "UPDATE_PATIENT", // Used
    DELETE_PATIENT: "DELETE_PATIENT", // Unused
    VIEW_PATIENTS: "VIEW_PATIENTS", // Unused
    EXPORT_PATIENTS: "EXPORT_PATIENTS", // Used
    SHARE_PATIENTS: "SHARE_PATIENTS", // Unused
    ADD_APPOINTMENT: "ADD_APPOINTMENT", // Used
    UPDATE_APPOINTMENT: "UPDATE_APPOINTMENT", // Unused
    DELETE_APPOINTMENT: "DELETE_APPOINTMENT", // Unused
    CANCEL_APPOINTMENT: "CANCEL_APPOINTMENT", // Used
    RESCHEDULE_APPOINTMENT: "RESCHEDULE_APPOINTMENT", // Used
    VIEW_APPOINTMENT: "VIEW_APPOINTMENT", // Unused
    CONFIRM_APPOINTMENT: "CONFIRM_APPOINTMENT", // Unused
    ADD_AVAILABILITY: "ADD_AVAILABILITY", // Used
    UPDATE_AVAILABILITY: "UPDATE_AVAILABILITY", // Used
    DELETE_AVAILABILITY: "DELETE_AVAILABILITY", // Unused
    VIEW_AVAILABILITY: "VIEW_AVAILABILITY", // Used
    HAVE_AVAILABILITY: "HAVE_AVAILABILITY", // Unused
    UPDATE_DOCUMENT_INTAKE: "UPDATE_DOCUMENT_INTAKE", // Unused
    FALSE_KEY: "ACCOUNT" // Used
}

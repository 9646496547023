import AppDataService from './AppDataService'

const COMMON_BASE = 'print-config'

export default class PrintConfigurationsDataService {

    static async postPrintConfigDetails(data) {
        return await AppDataService.post(`${COMMON_BASE}`, data)
    }
    static async updatePrintConfigDetails(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}`, data)
    }
    static async deletePrintConfigDetails(uuid) {
        return await AppDataService.delete(`${COMMON_BASE}/${uuid}`);
    }
    static async getPrintConfigDetails() {
        return await AppDataService.get(`${COMMON_BASE}`)
    }
}
import React, { useState } from 'react';
import Button from '../../../../../components/common-components/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
	componentKey,
	setCurrentProviderUserUUID,
	setISAddProviderUserModalOpen,
	setIsAddUSerStaffModalOpen,
	setIsNewProviderUserAdd,
	setIsNewUserAdd,
	setProviderUsersData,
	setViewUserModalIsOpen,
} from './UsersSlice';
import { postCreateInviteLink, updateProvidersStatus } from './UsersSaga';

import Avatar from '../../../../../components/common-components/avatar';
import ViewProfileModal from './ViewProfileModal';
import ShowForAllowedPermissionsOnly from '../../../../Wrappers/ShowForAllowedPermissionsOnly';
import { USER_PERMISSIONS } from '../../../../../libs/UserPermissionsConstants';
import MetPopOver from '../../../../../components/common-components/popoverComponent/MetPopOver';

const UserBox = ({ allProviders }) => {
	const { currentProviderUserUUID, viewUserModal, providerUsersData } = useSelector((state) => state[componentKey]);
	const dispatch = useDispatch();

	return (
		<>
			{allProviders?.map((item, index) => (
				
				<div
					key={index}
					className="p-[14px] w-[24.5%] border-[1px] rounded-[10px] border-opacity-25  border-[#1A1A1A33] mt-2"
				>
					<div className=" p-[10px]">
						<div className="flex justify-between w-full">
							<p className="text-xl text-rgba-1A1A1A font-bold">{`${item?.firstName} ${item?.lastName}`}</p>
							<div className=" inline-block  bg-[#1A1A1A0F] rounded-md p-1 cursor-pointer">
								<p
									className={`font-semi-bold text-[16px] ${
										item?.status === true ? 'text-[#00B917]' : 'text-[#1A1A1A]'
									}`}
									onClick={() => {

										if (item?.uuid) {
											dispatch(
												updateProvidersStatus({
													uuid: item?.uuid,
													status: item?.status === false ? 'Active' : 'Inactive',
												})
											);
										}
									}}
								>
									{item?.status === true ? 'Active' : 'Inactive' || '-'}
								</p>
							</div>
						</div>
						<div className="flex justify-between w-full mt-2">
							<p className="inline-block bg-rgba-CCECFF4D  text-met-primary">{item?.role}</p>
							{item?.status === false && (
								<p
									className="  text-met-primary cursor-pointer border-b-[1px] border-met-primary font-bold text-[16px]"
									onClick={() => {
										dispatch(setCurrentProviderUserUUID(item?.uuid));
										if (item?.uuid) {
											dispatch(postCreateInviteLink({ uuid: item?.uuid }));
										}
									}}
								>
									{'Resend Link'}
								</p>
							)}
						</div>
					</div>
					<div className="flex flex-col p-[10px]  border-y-2 border-[#1A1A1A1A] border-opacity-25">
						<div className="flex  justify-center">
							<Avatar
								customClass="w-[100px] h-[100px] overflow-hidden object-cover rounded-full"
								url={item?.profilePicture}
								name={`${item.firstName} ${item.lastName}`}
							/>
						</div>

						<div className="mt-4 flex flex-wrap">
							<div className="w-[40%]">
								<p className="text-[16px] text-met-light-grey font-bold">Last Login Time:</p>
								<p className="text-[16px] text-met-light-grey font-bold">Contact Number:</p>
								<p className="text-[16px] text-met-light-grey font-bold">Email:</p>
								<p className="text-[16px] text-met-light-grey font-bold">Department:</p>
							</div>
							<div className="w-[50%]">
								<p className="text-rgba-1a1a1acc font-bold">
									{item.lastLogin
										? new Date(item.lastLogin).toLocaleDateString('en-GB', {
											day: '2-digit',
											month: '2-digit',
											year: 'numeric',
										}) + ' at ' + new Date(item.lastLogin).toLocaleTimeString('en-US', { hour12: false })
										: '-'}
								</p>
								<p className="text-rgba-1a1a1acc font-bold">{item.phoneNumber || '-'}</p>
								<MetPopOver> <p className="text-rgba-1a1a1acc font-bold ">{item.email || '-'}</p></MetPopOver>
								<p className="text-rgba-1a1a1acc font-bold">
									{item?.provider_department_intermediate[0]?.Department?.departmentName || '-'}
								</p>
							</div>
						</div>
					</div>
					<div className="flex mt-4 w-full justify-between">
						{/* <Button className="border-r-2 border-[#1A1A1A1A]" onClickCb={() => navigate(SETTINGS_PATHS.PROVIDER_GROUP_PROFILE)}>View Profile</Button> */}
						<ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.VIEW_USER}>
							<Button
								className="border-r-2 border-[#1A1A1A1A]"
								onClickCb={() => {
									dispatch(setViewUserModalIsOpen(true));
									dispatch(setCurrentProviderUserUUID(item?.uuid));
									dispatch(setProviderUsersData(item));
								}}
							>
								View Profile
							</Button>
						</ShowForAllowedPermissionsOnly>
						<Button
							onClickCb={() => {
								if (item.role === "Biller" || item.role === "Front Desk") {
									dispatch(setIsAddUSerStaffModalOpen(true))
									dispatch(setProviderUsersData(item));
									dispatch(setCurrentProviderUserUUID(item?.uuid));
									dispatch(setIsNewUserAdd(false))


								} else {

									dispatch(setIsNewProviderUserAdd(false));
									dispatch(setISAddProviderUserModalOpen(true));
									dispatch(setCurrentProviderUserUUID(item?.uuid));
									dispatch(setProviderUsersData(item));
								}
							}}
						>
							Edit Profile
						</Button>
					</div>
				</div>
			))}

			{viewUserModal && (
				<ViewProfileModal
					open={viewUserModal}
					close={() => {dispatch(setViewUserModalIsOpen(false))
								dispatch(setProviderUsersData({}))
					}}
					providerUsersData={providerUsersData}
				/>
			)}
		</>
	);
};
export default UserBox;

import store from '../../../../store/store';

export const componentKey = 'PATIENTVITALS_SECTION';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setAllVitalsData: (state, action) => {
            state.allVitalsData = action.payload;
        },
        setIsVitalsModalOpen: (state, action) => {
            state.isVitalsModalOpen = action.payload;
        },
        setContentToFilterArray: (state, action) => {
            state.contentToFilterArray = action.payload
        },
        setPatientDeviceVitals: (state, action) => {
            state.patientDeviceVitals = action.payload
        },
        setDeviceNamesOptionList: (state, action) => {
            state.deviceNamesOptionList = action.payload
        },
        setSelectedDevice: (state, action) => {
            state.selectedDevice = action.payload
        },
        setVitalTestsOptions: (state, action) => {
            state.vitalTestsOptions = action.payload
        },
        setSelectedTestName: (state, action) => {
            state.selectedTestName = action.payload
        },
        setVitalTestsOptionsWithoutType: (state, action) => {
            state.vitalTestsOptionsWithoutType = action.payload
        },
    },
    initialReducerState: {
        allVitalsData: [],
        isVitalsModalOpen: false,
        contentToFilterArray: [],
        patientDeviceVitals: [],
        deviceNamesOptionList: [],
        selectedDevice: "",
        vitalTestsOptions: [],
        selectedTestName: "",
        vitalTestsOptionsWithoutType: [],

    },
});

export const { setAllVitalsData, setIsVitalsModalOpen, setContentToFilterArray, setPatientDeviceVitals, setDeviceNamesOptionList, setSelectedDevice, setVitalTestsOptions, setSelectedTestName, setVitalTestsOptionsWithoutType } = actions;

import { useDispatch } from "react-redux";
import { getEncounterIdByUUID } from "../EncounterSaga";

const EncounterDiagnosesDataCard = ({ title, encounter, handleShowAllEncounterDetails }) => {
    const dispatch = useDispatch()

    const diagnosisList = Array.isArray(encounter?.encounterForms?.data?.diagnosis) ? encounter?.encounterForms?.data?.diagnosis : [];
    return <div
        onClick={() => {
            handleShowAllEncounterDetails(encounter);
            dispatch(getEncounterIdByUUID({ encounterId: encounter?.uuid }));
        }}
        className='border-b-2 p-4 cursor-pointer'>
        <div className="font-bold fs-14 mb-2">
            <p>
                {title != "Invalid date" ? `${title}` : ""}
                {title?.length ? " : " : ""}
                {encounter?.schedules?.Appointment?.appointmentType?.split("_").join(" ") || '-'}
            </p>
        </div>

        {encounter && diagnosisList?.length ? <div className="flex fs-14">
            <div>
                <p>Diagnoses <span className="mx-[5px] text-gray-500">:</span> </p>
            </div>
            <div className="flex-1">
                {diagnosisList?.map((item, index) => {
                    return <div key={`diagnoses-${index}`} className="mb-1"><span className="text-gray-500">{`${index + 1}.`} </span><span className="text-gray-500">{item.name}</span></div>
                })}
            </div>
        </div> : ''}
    </div>
}

export default EncounterDiagnosesDataCard
import React from 'react';
import CardContainer from '../../../../components/common-components/Cards/Container/CardContainer';
import { MAIN_PATHS } from '../../../../libs/constant';
import Icons from '../../../../components/icons/Icons';
import Tabs from '../../../../components/common-components/Tabs';
import { masterData } from '../../constant';
import { useNavigate } from 'react-router-dom';
import useGetCurrenPathHook from '../../../../libs/customHooks/useGetCurrenPathHook';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import { useDispatch, useSelector } from 'react-redux';
import {
	setIsCreateNewVitalsTests,
	setIsVitalsTestsModalOpen,
	componentKey,
	setVitalsTestsData,
} from './VitalsTests/VitalsTestsSlice';
import VitalsTestsModal from './VitalsTests/VitalsTestsModal';
import useGarbageCollector from '../../../../libs/garbagecollector/garbageCollectorHooks';

function MasterTabContainer() {

	useGarbageCollector(componentKey);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const currrentPath = useGetCurrenPathHook(true, 2);
	const { isVitalsTestsModalOpen, deviceName } = useSelector((state) => state[componentKey]);
	const getAdditionalButtons = () => {
		switch (currrentPath) {
			case '/vitalsTests':
				return (
					<div className="flex justify-between">
						{/* <Button
							onClickCb={() => {
								dispatch(setVitalsTestsData({}))
								dispatch(setIsVitalsTestsModalOpen(true));
								dispatch(setIsCreateNewVitalsTests(true));
							}}
							disabled={!deviceName?.value}
							startIcon={{ icon: <Icons iconName="plusVectorIcon" color="white" /> }}
							variant={BUTTON_VARIANTS.CONTAINED}
							customBtnClass="mr-5"
						>
							Add Vitals Test
						</Button> */}
					</div>
				);
			default:
				return null;
		}
	};
	return (
		<>
			<CardContainer>
				<div className="justify-between mb-4 flex font-medium text-rgba-1a1a1acc h-[42px]">
					<div className="flex text-2xl">
						<span onClick={() => navigate(MAIN_PATHS.SETTINGS)} className="hover:cursor-pointer pt-[4px]">
							<Icons iconName="arrowBackIcon" />
						</span>
						<span className="pl-[10px] font-bold">Master</span>
					</div>
					{getAdditionalButtons()}
				</div>
				<Tabs tabs={masterData} />
				{isVitalsTestsModalOpen && (
					<VitalsTestsModal
						open={isVitalsTestsModalOpen}
						close={() => dispatch(setIsVitalsTestsModalOpen(false))}
					/>
				)}
			</CardContainer>
		</>
	);
}

export default MasterTabContainer;

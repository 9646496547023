
import React from 'react';
import { useSelector } from 'react-redux';
import { componentKey as PatientHistoryComponentKey } from "../../../Patients/PatientDashboard/History/PatientHistorySlice";
import FaceSheetCard from '../../../Patients/PatientDashboard/Dashboard/FaceSheet/FaceSheetCard';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import moment from 'moment';
import Icons from '../../../../components/icons/Icons';
import { MET_COLORS } from '../../../../libs/constant';

const PastMedicalHistoryCard = () => {
    const { allMedicalHistoryData } = useSelector((state) => state[PatientHistoryComponentKey])

    const handleCopyClick = () => {
        let copiedText = '';
        allMedicalHistoryData?.slice(0, 5).map(history => {
            copiedText += `${history.conditionName} : ${moment(history.onsetDate).format('MMMM D, YYYY')}\n`
        })
        navigator.clipboard.writeText(copiedText);
    }

    return (
        <FaceSheetCard
            title="PAST MEDICAL HISTORY"
            iconbtn={
                allMedicalHistoryData?.length ? <Button
                    variant={BUTTON_VARIANTS.TEXT}
                    customBtnClass="fs-14 font-bold text-met-primary"
                    style={{ height: '19px' }}
                    onClickCb={() => handleCopyClick()}
                >
                    <Icons iconName={'copyIcon'} color={MET_COLORS.PRIMARY}></Icons> Copy
                </Button> : ''
            }
            textColorClass={'text-met-primary'}
        >
            {allMedicalHistoryData?.length ? allMedicalHistoryData?.slice(0, 5).map((history, index) => {
                return <div key={"face-sheet-card-" + index}>
                    <div className="mb-4">
                        <div className="flex items-center mb-1">
                            <div className={`fs-14`}>{`${history.conditionName} : ${moment(history.onsetDate).format('MMMM D, YYYY')}`}</div>
                        </div>
                    </div>
                </div>

            }) : <div className='fs-14 text-gray-500'>No data found</div>}
        </FaceSheetCard>
    );
};

export default PastMedicalHistoryCard;

import React, { useState } from 'react';
import Label from '../../../../components/common-components/label/Label';
import Heading from '../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../components/common-components/heading/Constants';
import Icons from '../../../../components/icons/Icons';
import Table from '../../../../containers/Table/Index';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import AddCardModal from './AddCardModal';
import { MET_COLORS } from '../../../../libs/constant';
import ButtonDropdown from '../../../../components/common-components/buttonDropdown';

function CardDetails() {
	const [openAddCard, setOpenAddCard] = useState(false);
	const handleOpenAddCardModal = () => {
		setOpenAddCard(true);
	};
	const handleCloseAddCardModal = () => {
		setOpenAddCard(false);
	};
	const coloumns = [
		{
			field: 'cardNumber',
			label: <Label>Card Number</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div className="mr-2">
								<Icons iconName="visaIcon" />
							</div>
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.cardNumber}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'expirationDate',
			label: <Label>Expiration Date</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.expirationDate}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'primary',
			label: <Label>Primary</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.primary}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},

		{
			field: 'status',
			label: <Label>Status</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.status}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},

		{
			field: 'action',
			label: <Label>Action</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.action}
								</Heading>
							</div>
							<ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[{ label: "Set Primary", value: null }, { label: "Edit", value: null }, { label: "Delete", value: null }]} />
						</div>
					</>
				);
			},
		},
	];

	const rowData = [
		{
			cardNumber: '**** ***** **** 9090',
			expirationDate: '02/12',
			primary: 'Yes',
			status: 'Active',
		},
		{
			cardNumber: '**** ***** **** 2678',
			expirationDate: '13/12',
			primary: 'No',
			status: 'Inactive',
		},
		{
			cardNumber: '**** ***** **** 7966',
			expirationDate: '30/12',
			primary: 'No',
			status: 'Inactive',
		},
	];
	return (
		<>
			<div>
				<div className="flex items-center justify-between p-2 px-4">
					<Heading fontSize="xl" fontWeight="bold" type={HEADING.H3}>
						Cards Details
					</Heading>
					<div className="flex">
						<Button
							variant={BUTTON_VARIANTS.OUTLINED}
							startIcon={{ icon: 'plusVectorIcon', color: MET_COLORS.PRIMARY }}
							customBtnClass="text-met-primary"
							onClickCb={handleOpenAddCardModal}
						>
							Add Card
						</Button>
					</div>
				</div>
				<div className="p-4">
					<Table
						isPagination={false}
						coloumns={coloumns}
						rows={rowData}
						sorting={{
							isSortable: false,
							onSortChangeCb: () => { },
						}}
					/>
				</div>
			</div>
			{openAddCard && <AddCardModal isOpen={openAddCard} isClose={handleCloseAddCardModal} />}
		</>
	);
}

export default CardDetails;

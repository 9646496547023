import React, { useEffect } from 'react'
import Label from '../../../../../components/common-components/label/Label';
import Heading from '../../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../../components/common-components/heading/Constants';
import ButtonDropdown from '../../../../../components/common-components/buttonDropdown';
import moment from 'moment';
import Table from '../../../../Table/Index';
import { componentKey, setIsDeleteVisitNotePopUpOpen, setVisitNoteId, setVisitNotePaginationState } from './VisitNotesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTemplateVisitNotes, getAllNotes } from './VisitNotesSaga';
import DeletePopup from '../../../../../components/common-components/deletePopup/DeletePopup';
import { USER_PERMISSIONS } from '../../../../../libs/UserPermissionsConstants';
import ShowForAllowedPermissionsOnly from '../../../../Wrappers/ShowForAllowedPermissionsOnly';

function VisitNotes() {
  const dispatch = useDispatch()
  const { visitNotePaginationState, visitNoteState, isDeleteVisitNotePopUpOpen, visitNoteId } = useSelector(((state) => state[componentKey]))

  useEffect(() => {
    const params = {
      pageNumber: visitNotePaginationState.pageNumber,
      limit: visitNotePaginationState.limit,
    }
    dispatch(getAllNotes(params));
  }, [
    dispatch,
    visitNotePaginationState.limit,
    visitNotePaginationState.pageNumber,
  ]);

  const handleDeleteClick = () => {
    dispatch(deleteTemplateVisitNotes({ uuid: visitNoteId }));
  };

  const coloumns = [
    {
      field: 'name',
      label: <Label fontWeight="font-bold">Visit Note Name</Label>,
      renderLogic: (row) => {
        return (
          <>
            <div className="flex items-center">
              <div>
                <Heading
                  type={HEADING.H1}
                  color="gray-500"
                  fontSize={'md'}
                  customClasses="cursor-pointer"
                >
                  {row.name}
                </Heading>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: 'formType',
      label: <Label fontWeight="font-bold">Visit Note Type</Label>,
      renderLogic: (row) => {
        return (
          <>
            <div className="flex items-center">
              <div>
                <Heading
                  type={HEADING.H1}
                  color="gray-500"
                  fontSize={'md'}
                  customClasses="cursor-pointer"
                >
                  {row.formType}
                </Heading>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: 'specialties',
      label: <Label fontWeight="font-bold">Specialties</Label>,
      renderLogic: (row) => {
        return (
          <>
            <div className="flex items-center">
              <div className="pl-4">
                <Heading
                  type={HEADING.H1}
                  color="gray-500"
                  fontSize={'md'}
                  customClasses="cursor-pointer"
                >
                  {row.specialties}
                </Heading>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: 'createdBy',
      label: <Label fontWeight="font-bold">Created By</Label>,
      renderLogic: (row) => {
        return (
          <>
            <div className="flex items-center">
              <div>
                <Heading
                  type={HEADING.H1}
                  color="gray-500"
                  fontSize={'md'}
                  customClasses="cursor-pointer"
                >
                  {(row.providers?.firstName && row.providers?.lastName) ?
                    `${row.providers.firstName} ${row.providers.lastName}` :
                    '-'
                  }
                </Heading>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: 'updatedAt',
      label: <Label fontWeight="font-bold">Updated Date</Label>,
      renderLogic: (row) => {
        const updatedAt = row.updatedAt ? moment(row?.updatedAt).format('DD-MM-YYYY') : '-';
        return (
          <>
            <div className="flex items-center">
              <div>
                <Heading
                  type={HEADING.H1}
                  color="gray-500"
                  fontSize={'md'}
                  customClasses="cursor-pointer"
                >
                  {updatedAt}
                </Heading>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: 'action',
      label: <Label fontWeight="font-bold">Action</Label>,
      renderLogic: (row) => {
        return (
          <>

            <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.DELETE_TEMPLATES}>

              <div className="flex items-center">
                <div>
                  <Heading
                    type={HEADING.H1}
                    color="gray-500"
                    fontSize={'md'}
                    customClasses="cursor-pointer"
                  >
                    {row.action}
                  </Heading>
                </div>
                <ButtonDropdown
                  customBtnClass="p-0"
                  endIcon="moreVertIcon"
                  options={[
                    {
                      label: 'Delete', value: null, onChangeCb: () => {
                        dispatch(setIsDeleteVisitNotePopUpOpen(true))
                        dispatch(setVisitNoteId(row.uuid));
                      }
                    },
                  ]}
                  selectCb={(option) => {
                    option?.onChangeCb();
                  }}
                />
              </div>
            </ShowForAllowedPermissionsOnly>
          </>
        );
      },
    },
  ];
  return (
    <div>
      <div className="p-4">
        <Table
          isPagination={false}
          coloumns={coloumns}
          rows={visitNoteState.templateDetails}
          tableHeaderCustomclasses=' '
          paginationProps={{
            isPagination: true,
            totalCount: visitNotePaginationState.total,
            limit: visitNotePaginationState.limit,
            onPageChange: (page) => {
              dispatch(setVisitNotePaginationState({ pageNumber: page }))
            }
          }}
        />
      </div>
      {isDeleteVisitNotePopUpOpen ? <DeletePopup
        open={isDeleteVisitNotePopUpOpen}
        okButton="Delete"
        cancelButton='Cancel'
        confirmationMessage="Delete"
        onClickCancel={() => { dispatch(setIsDeleteVisitNotePopUpOpen(false)) }}
        onClickOk={() => handleDeleteClick()}
      /> : null}
    </div>
  )
}

export default VisitNotes
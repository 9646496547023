import { all, call, put, takeLatest } from "redux-saga/effects";
import store from "../../../../../store/store";
import TemplatesDataService from "../../../../../services/TemplatesDataService";
import { componentKey, setIsDeleteVisitNotePopUpOpen, setVisitNoteById, setVisitNotePaginationState, setVisitNoteState } from "./VisitNotesSlice";
import { addNotifications } from "../../../../../components/common-components/toaster/ToasterSlice";
import { TOASTER_VARIANT } from "../../../../../components/common-components/toaster/MetToaster";

export const { getAllNotes, postTemplateVisitNotes, getNoteById, deleteTemplateVisitNotes } = {
    getAllNotes: (payload) => {
        return {
            type: 'VISIT_NOTE/GET_ALL_NOTES',
            payload
        }
    },
    getNoteById: (payload) => {
        return {
            type: 'VISIT_NOTE/NOTE_BY_ID',
            payload
        }
    },
    postTemplateVisitNotes: (payload) => {
        return {
            type: 'VISIT_NOTE/POST_TEMPLATE_VISIT_NOTES',
            payload
        }
    },
    deleteTemplateVisitNotes: (payload) => {
        return {
            type: 'DELETE/VISIT_NOTE',
            payload,
        };
    },
}

function* getAllNotesAsync(action) {
    try {
        const params = action.payload
        const response = yield TemplatesDataService.getTemplateNotes({ params })
        const { data, status } = response.data

        if (status) {
            const { totalRecords } = data || {}
            yield put(setVisitNoteState(data))
            yield put(setVisitNotePaginationState({ total: totalRecords }))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* getNoteByIdAsync(action) {
    try {
        const { noteId } = action.payload
        const response = yield TemplatesDataService.getTemplateNoteById(noteId)
        const { data, status } = response.data

        if (status) {
            yield put(setVisitNoteById(data))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* postTemplateVisitNotesAsync(action) {
    try {
        const { payload, navigate} = action.payload
        const response = yield TemplatesDataService.createTemplateVisitNotes(payload)
        const { data, status } = response.data
        if(response.status === 201){
            navigate("/settings/templates/visitNotes")
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* deleteTemplateVisitNotesAsync(action) {
    try {
        const { uuid } = action.payload;

        const response = yield TemplatesDataService.deleteTemplateVisitNotes(uuid);

        const { message, status, data } = response?.data || {};
        if (status) {
            yield call(getAllNotesAsync, getAllNotes())
            yield put(setIsDeleteVisitNotePopUpOpen(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getAllNotes().type, getAllNotesAsync),
        takeLatest(getNoteById().type, getNoteByIdAsync),
        takeLatest(postTemplateVisitNotes().type, postTemplateVisitNotesAsync),
        takeLatest(deleteTemplateVisitNotes().type, deleteTemplateVisitNotesAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
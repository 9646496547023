
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'
import store from '../../store/store'
import { componentKey, setIsOpenCalenderRange, setIsOpenCustomDatePickerModal, setProviderAdminMetaDataState, setProviderAdminPaginationState, setProvidersListState, setUpcommingAppointmentData, setProviderInformation, setBirthdays, setCalenderviewUpcommingData, setDashboardCounts, setBilingCountStored, setVitalTestCount, setNoDataMessage, setNoDataMessageForBilling } from './DashboardSlice'
import ProvidersDataService from '../../services/ProvidersDataService'
import SchedulingService from '../../services/SchedulingService'
import PatientDataService from '../../services/PatientDataServices'
import { getProvidersList as providersList } from '../../containers/Wrappers/Dataloader/DataloaderSaga'

export const { getProvidersList, fetchProviderAdminMetaData, getUpcommingAppointments, updateProviderAdminStatus, getProvidersDetails, getUpCommingBirthdays, getCountToDisplayDashboard, bilingCount, vitalTestResultCount } = {
    getProvidersList: (payload) => {
        return {
            type: 'DASHBOARD/PROVIDER_ADMIN',
            payload
        }
    },

    fetchProviderAdminMetaData: (payload) => {
        return {
            type: 'DASHBOARD/PROVIDER_ADMIN_META_DATA',
            payload
        }
    },

    getUpcommingAppointments: (payload) => {
        return {
            type: 'DASHBOARD/PROVIDER_UPCOMMING_APPOINTMENT',
            payload
        }
    },
    updateProviderAdminStatus: (payload) => {
        return {
            type: 'PATCH/PROVIDER_ADMIN_STATUS',
            payload,
        };
    },
    getProvidersDetails: (payload) => {
        return {
            type: 'DASHBOARD/GET_PROVIDERS',
            payload
        }
    },
    getUpCommingBirthdays: (payload) => {
        return {
            type: 'DASHBOARD/GET_UPCOMMING_BIRTHDAYS',
            payload
        }
    },
    getCountToDisplayDashboard: (payload) => {
        return {
            type: 'DASHBOARD/COUNT',
            payload
        }
    },
    bilingCount: (payload) => {
        return {
            type: 'BILING_COUNT',
            payload
        }
    },
    vitalTestResultCount: (payload) => {
        return {
            type: 'VITAL_TEST_RESULT_COUNT',
            payload
        }
    }
}

function* getProvidersListAsync(action) {
    try {
        const { params } = action.payload
        const response = yield ProvidersDataService.getAdminProvidersList({ params })

        const { data, message, status } = response.data
        if (status) {
            const { providers, totalPages, totalRecords } = data || {}
            yield put(setProvidersListState(providers))
            yield put(setProviderAdminPaginationState({ total: totalRecords }))
            yield put(setIsOpenCalenderRange(false))

        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* fetchProviderAdminMetaDataAsync(action) {
    try {
        const { params } = action.payload;
        const response = yield ProvidersDataService.getProviderAdminMetaData(params);  
        const { data, status } = response.data;
        if (status) {
            yield put(setProviderAdminMetaDataState(data))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}


function* getUpcommingAppointmentsAsync(action) {
    try {
        const { params } = action.payload

        const response = yield SchedulingService.getAllScheduleAppointments(action.payload)

        const { data, status } = response

        if (status) {
            if (params.responseType) {
                yield put(setCalenderviewUpcommingData(data))
            } else {
                yield put(setUpcommingAppointmentData(data))
            }
            yield put(setIsOpenCustomDatePickerModal(false))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}


function* updateProviderAdminStatusAsync(action) {
    try {
        const { uuid, status, providerAdminPaginationState } = action.payload;
        const data = { status }
        const response = yield ProvidersDataService.updateProvidersStatus(uuid, data);

        if (response.status === 200) {
            yield put(getProvidersList(providerAdminPaginationState))
            yield put(providersList())
        }
        const { message } = response.data;
    } catch (error) {
        console.log(error);
    }
}

function* getProvidersDetailsAsync(action) {
    try {
        const response = yield ProvidersDataService.getProvidersDetails(action.payload)
        const { status } = response
        const { data } = response.data
        const { _count } = data
        if (status) {
            yield put(setProviderInformation(_count))
        }

    } catch (error) {
        console.log('err: ', error)
    }
}


function* getUpcommingBirthDaysAsync(action) {
    try {
        const response = yield PatientDataService.upcomingbirthdays(action.payload)
        const { upcomingBirthdays, status } = response.data
        if (status) {
            yield put(setBirthdays(upcomingBirthdays))

        }

    } catch (error) {
        console.log('err: ', error)
    }
}

function* getCountToDisplayDashboardAsync(action) {
    try {
        const response = yield ProvidersDataService.getCountToDisplayDashboard(action.payload)
        const { status, count } = response.data
        if (status) {
            yield put(setDashboardCounts(count))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}


function* bilingCountAsync(action) {
    try {
        const response = yield ProvidersDataService.bilingCount(action.payload)
        const { data, status } = response

        if (status) {
            if (data?.length === 0) {
                yield put(setNoDataMessageForBilling(true))
            } else {
                yield put(setNoDataMessageForBilling(false))
                yield put(setBilingCountStored(data?.response))
            }
        }
    } catch (error) {
        console.log('err: ', error)
    }
}


function* vitalTestResultCountAsync(action) {
    try {
        const response = yield ProvidersDataService.vitalTestResultCount(action.payload)
        const { data, status } = response

        if (status) {
            if (data?.monthlyCount?.length === 0) {
                yield put(setNoDataMessage(true))
            } else {
                yield put(setNoDataMessage(false))
                yield put(setVitalTestCount(data?.monthlyCount))
            }
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getProvidersList().type, getProvidersListAsync),
        takeLatest(fetchProviderAdminMetaData().type, fetchProviderAdminMetaDataAsync),
        takeEvery(getUpcommingAppointments().type, getUpcommingAppointmentsAsync),
        takeLatest(updateProviderAdminStatus().type, updateProviderAdminStatusAsync),
        takeLatest(getProvidersDetails().type, getProvidersDetailsAsync),
        takeLatest(getUpCommingBirthdays().type, getUpcommingBirthDaysAsync),
        takeLatest(getCountToDisplayDashboard().type, getCountToDisplayDashboardAsync),
        takeLatest(bilingCount().type, bilingCountAsync),
        takeLatest(vitalTestResultCount().type, vitalTestResultCountAsync)

    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
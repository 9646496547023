import React from 'react';
export const MobileIcon = () => {
	return (
		<svg id="phone_iphone_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path id="Path_205371" data-name="Path 205371" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_205372"
				data-name="Path 205372"
				d="M15.5,1h-8A2.5,2.5,0,0,0,5,3.5v17A2.5,2.5,0,0,0,7.5,23h8A2.5,2.5,0,0,0,18,20.5V3.5A2.5,2.5,0,0,0,15.5,1Zm-4,21A1.5,1.5,0,1,1,13,20.5,1.5,1.5,0,0,1,11.5,22ZM16,18H7V4h9Z"
				fill="rgba(26,26,26,0.6)"
			/>
		</svg>
	);
};

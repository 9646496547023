import { all, call, put, takeLatest } from 'redux-saga/effects'
import { componentKey, setIsOpenEditInsuranceModal } from './InsuranceSlice';
import InsuranceDataService from '../../../services/InsuranceDataService';
import store from '../../../store/store';
import { getPatientDetails } from '../../../pages/Patients/PatientDashboard/PatientDashboardSaga';
import { addNotifications } from '../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../components/common-components/toaster/MetToaster';
import General from '../../../libs/utility/General';

export const updateInsuranceDetails = (payload) => {
    return {
        type: 'INSURANCE/UPDATE_INSURANCE_DETAILS',
        payload
    }
}

export const toggleInsuranceActivation = (insuranceId) => ({
    type: 'INSURANCE/TOGGLE_INSURANCE_ACTIVATION',
    payload: { insuranceId },
});

function* updateInsuranceDetailsAsync(action) {
    try {
        const { uuid, values } = action.payload
        const updatedVal = values?.map((item) => {
            const updatedInsuranceCardFrontImage = General.isMETBase64(item.insuranceCardFrontImage) ? item.insuranceCardFrontImage : undefined;
            const updatedInsuranceCardBackImage = General.isMETBase64(item.insuranceCardBackImage) ? item.insuranceCardBackImage : undefined;
            delete item.insuranceCardFrontImage
            delete item.insuranceCardBackImage
            return {
                ...item,
                updatedInsuranceCardFrontImage,
                updatedInsuranceCardBackImage,
            }
        });
        const data = updatedVal[0]

        const response = yield InsuranceDataService.updateInsuranceDetails(uuid, data)
        const { success, message, } = response.data
        if (success) {
            const patientId = response.data.data.patientId || ""

            yield put(setIsOpenEditInsuranceModal(false))
            if (patientId) {
                yield put(getPatientDetails({ patientId }))
            }

            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
        }

    }
    catch (error) {
        console.log('err: ', error)
    }
}


function* toggleInsuranceActivationAsync(action) {
    try {
        const { insuranceId } = action.payload;
        const response = yield InsuranceDataService.toggleInsuranceActivation(insuranceId)
        const { success, message, } = response.data
        if (success) {
            const patientId = response?.data?.data?.patientId || ""

            if (patientId) {
                yield put(getPatientDetails({ patientId }))
            }
        }
    } catch (error) {
        console.error('Error updating insurance activation status:', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(updateInsuranceDetails().type, updateInsuranceDetailsAsync),
        takeLatest(toggleInsuranceActivation().type, toggleInsuranceActivationAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
import store from '../../../../store/store';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { componentKey, setAllDiagnosesData, setDiagnosesData, setDiagnosesStatus, setIsDiagnosesModalOpen } from './PatientDiagnosesSlice';
import PatientDashboardDataService from '../../../../services/PatientDashboardDataService';
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/MetToaster';
import { setIsOpenDeletePopup } from '../PatientDashboardSlice';

export const {
	getAllPatientDiagnosesDetails,
	postPatientDiagnoses,
	deletePatientDiagnoses,
	getPatientDiagnosesDetails,
	updatePatientDiagnoses,
} = {
	getAllPatientDiagnosesDetails: (payload) => {
		return {
			type: 'PATIENTDASHBOARD/Diagnoses',
			payload,
		};
	},
	postPatientDiagnoses: (payload) => {
		return {
			type: 'POST/PATIENTDASHBOARD/Diagnoses',
			payload,
		};
	},
	deletePatientDiagnoses: (payload) => {
		return {
			type: 'DELETE/PATIENTDASHBOARD/Diagnoses',
			payload,
		};
	},
	getPatientDiagnosesDetails: (payload) => {
		return {
			type: 'GET/PATIENTDASHBOARD/Diagnoses',
			payload,
		};
	},
	updatePatientDiagnoses: (payload) => {
		return {
			type: 'UPDATE/PATIENTDASHBOARD/Diagnoses',
			payload,
		};
	},
};

function* getAllPatientDiagnosesDetailsAsync(action) {
	try {
		yield put(setAllDiagnosesData([]));
		const { patientId = '', page = '1', limit = '10', diagnosesStatus = 'All' } = action.payload;
		const params = {
			page: page,
			limit: limit,
			...(diagnosesStatus === 'All' ? {} : { status: diagnosesStatus })
		};

		const response = yield PatientDashboardDataService.getAllPatientDiagnoses(
			'diagnoses/patient',
			patientId,
			params
		);

		const { message, status, data } = response?.data || {};

		if (status) {
			const diagnoses = data?.diagnoses.map((diagnose, index) => {
				return {
					...diagnose, index
				}
			})
			yield put(setAllDiagnosesData(diagnoses));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* postPatientDiagnosesAsync(action) {
	try {
		yield put(setAllDiagnosesData([]));
		const { patientId, diagnosesData } = action.payload;
		const response = yield PatientDashboardDataService.postPatientDiagnoses('diagnoses', patientId, diagnosesData);
		const { message, status, data } = response?.data || {};
		if (status) {
			yield put(setAllDiagnosesData(data?.diagnoses));
			yield put(setIsDiagnosesModalOpen(false));
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			yield put(setDiagnosesStatus({ label: 'All', value: 'All' }));
			yield call(getAllPatientDiagnosesDetailsAsync, {
				type: 'PATIENTDASHBOARD/Diagnoses',
				payload: { patientId },
			});
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* deletePatientDiagnosesDetailsAsync(action) {
	try {
		yield put(setAllDiagnosesData([]));
		const { patientId = '', diagnosesId = '' } = action.payload;

		const response = yield PatientDashboardDataService.deletePatientDiagnoses('diagnoses', diagnosesId, patientId);

		const { message, status, data } = response?.data || {};

		if (status) {
			yield put(setDiagnosesStatus({ label: 'All', value: 'All' }));
			yield put(setIsOpenDeletePopup(false))
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			yield call(getAllPatientDiagnosesDetailsAsync, {
				type: 'PATIENTDASHBOARD/Diagnoses',
				payload: { patientId },
			});
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* getPatientDiagnosesDetailsAsync(action) {
	try {
		yield put(setDiagnosesData([]));
		const { patientId = '', diagnosesId } = action.payload;

		const response = yield PatientDashboardDataService.getPatientDiagnoses('diagnoses', diagnosesId, patientId);

		const { message, status, data } = response?.data || {};

		if (status) {
			yield put(setDiagnosesData(data));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* updatePatientDiagnosesDetailsAsync(action) {
	try {
		yield put(setDiagnosesData([]));
		const { patientId = '', diagnosesId = '', diagnosesData = {} } = action.payload;

		const response = yield PatientDashboardDataService.updatePatientDiagnoses(
			'diagnoses',
			diagnosesId,
			patientId,
			diagnosesData
		);

		const { message, status, data } = response?.data || {};

		if (status) {
			yield put(setIsDiagnosesModalOpen(false));
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			yield put(setDiagnosesStatus({ label: 'All', value: 'All' }));
			yield call(getAllPatientDiagnosesDetailsAsync, {
				type: 'PATIENTDASHBOARD/Diagnoses',
				payload: { patientId },
			});
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* rootSaga() {
	yield all([
		takeLatest(getAllPatientDiagnosesDetails().type, getAllPatientDiagnosesDetailsAsync),
		takeLatest(postPatientDiagnoses().type, postPatientDiagnosesAsync),
		takeLatest(deletePatientDiagnoses().type, deletePatientDiagnosesDetailsAsync),
		takeLatest(getPatientDiagnosesDetails().type, getPatientDiagnosesDetailsAsync),
		takeLatest(updatePatientDiagnoses().type, updatePatientDiagnosesDetailsAsync),
	]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

import React from 'react';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import Icons from '../../../../../assets/svgs';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import { componentKey } from './AvailabilitySlice';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey as AvailabilityComponentKey } from '../../Tabs/Availability/AvailabilitySlice'
import DatePicker from '../../../../../components/common-components/datePicker';
import moment from 'moment';
import { postAvailabilityForADay } from './AvailabilitySaga';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import { FORM_FIELDS_NAMES } from '../constants';
import { getValidationSchema } from '../../../../../libs/formsUtils';

const fields = [
	{ fieldName: FORM_FIELDS_NAMES.AVAILABILITY_LOCATION_ID, isDropdown: true },
	{ fieldName: FORM_FIELDS_NAMES.START_TIME, isRequired: true },
	{ fieldName: FORM_FIELDS_NAMES.END_TIME, isRequired: true }
];

const initialValues = {
	date: '',
	startTime: '',
	endTime: '',
	availabilityLocationId: '',
	day: '',
	providerId: ''
};

const AddNewAvailabilityModal = ({ isFromEdit = false, open, title, customClasses, customBodyClasses, close, footerButton, selectedSlotDate }) => {

	const validationSchema = getValidationSchema(fields)

	const { providerLocationsData } = useSelector(state => state[AvailabilityComponentKey])
	const { selectedProvider } = useSelector(state => state[componentKey])
	const dispatch = useDispatch();

	return <Formik
		initialValues={initialValues}
		validationSchema={validationSchema}
		enableReinitialize
		onSubmit={(formvals) => {
			const data = {
				...formvals,
				availabilityLocationId: formvals.availabilityLocationId?.value,
				startTime: formvals.startTime ? moment(formvals.startTime).format('hh:mm A') : '',
				endTime: formvals.endTime ? moment(formvals.endTime).format('hh:mm A') : '',
				providerId: selectedProvider.value,
				date: moment(selectedSlotDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
				day: moment(selectedSlotDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('dddd')
			}
			dispatch(postAvailabilityForADay({ availabilityData: data }))
		}}
	>
		{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
			return <Form onSubmit={handleSubmit} >
				<ModalComponent
					open={open}
					title={title}
					customClasses={customClasses}
					close={close}
					customBodyClasses={customBodyClasses}
					footerButton={<Button type='submit' variant={BUTTON_VARIANTS.CONTAINED}>Add</Button>}
				>
					<div
						className={`${isFromEdit ? '' : "max-h-[100%] overflow-y-auto scrollbar-h-2 px-2 pb-[10px] text-rgba-1a1a1acc"}`}
					>
						<div className="mt-6">
							<SelectDropdown
								placeholder="Select Locations"
								label="Locations"
								toggleIcon={<Icons iconName="settingsLocationIcon" />}
								name={`availabilityLocationId`}
								value={values.availabilityLocationId}
								isRequired={true}
								onChangeCb={(val) => { setFieldValue(`availabilityLocationId`, val) }}
								options={providerLocationsData?.map(location => ({ 'label': location.provider_locations.name, 'value': location.provider_locations.uuid }))}
							/>
						</div>
						<div className="flex justify-between mt-6">
							<div className="flex-1 mr-5">
								<DatePicker
									name={`startTime`}
									placeholder='Start Time'
									label='Start Time'
									isRequired={true}
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={30}
									timeCaption="Time"
									dateFormat="h:mm aa"
									value={values.startTime}
									minTime={new Date().getTime() > new Date(selectedSlotDate).getTime() ? new Date() : new Date(selectedSlotDate)}
									maxTime={new Date().setHours(23, 59)}
									onChangeCb={(time) => {
										setFieldValue(`startTime`, time)
										if (time && values.endTime && new Date(time).toISOString() > new Date(values.endTime).toISOString()) {
											setFieldValue(`endTime`, '')
										}
									}}
									iconName='scheduleIcon'
								/>
							</div>
							<div className="flex-1">
								<DatePicker
									name={`endTime`}
									placeholder='End Time'
									label='End Time'
									isRequired={true}
									showTimeSelect
									showTimeSelectOnly
									minTime={new Date().getTime() > new Date(selectedSlotDate).getTime() ? new Date() : new Date(selectedSlotDate)}
									maxTime={new Date().setHours(23, 59)}
									timeIntervals={30}
									timeCaption="Time"
									dateFormat="h:mm aa"
									value={values.endTime}
									onChangeCb={(time) => {
										setFieldValue(`endTime`, time)
									}}
									iconName='scheduleIcon'
								/>
							</div>
						</div>
					</div>
				</ModalComponent>
			</Form>
		}}
	</Formik >
};

export default AddNewAvailabilityModal;

import React from 'react';

const Label = ({ color, fontSize, fontWeight = 'normal', isRequired = false, asteriskPosition = "end", children, customClass = "", onChangeCb = () => { } }) => {
	const labelColor = color || '#1A1A1A';
	const labelFontSize = fontSize || 'sm' || 'md' || 'lg';
	const labelFontWeight = fontWeight || 'bold';
	const asterisk = isRequired ? <span className="text-red-500 m-1">*</span> : null;

	return (
		<label className={`relative text-${labelColor} text-${labelFontSize} font-${labelFontWeight} ${customClass}`} onClick={onChangeCb}>
			{asteriskPosition === 'start' && asterisk}
			{children}
			{asteriskPosition === 'end' && asterisk}
		</label>
	);
};

export default Label;

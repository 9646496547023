
import { all, put, takeLatest } from 'redux-saga/effects'
import store from '../../store/store'
import { componentKey, setLoginDetails, setLoginLoadingState } from './LoginSlice'
import EncodeDecodeUtils from '../../libs/utility/EncodeDecodeUtils'
import { setLoggedInProviderUser, setLoggedInUser } from '../../routes/RoutesSlice'
import AuthDataService from '../../services/AuthDataService'
import { addNotifications } from '../../components/common-components/toaster/ToasterSlice'
import { TOASTER_VARIANT } from '../../components/common-components/toaster/MetToaster'
import { PAGE_STATE } from '../../libs/constant'

export const { login, forgotUserPassword, resetUserPassword, getLogInInfo } = {
    login: (payload) => {
        return {
            type: 'USER/LOG_IN',
            payload
        }
    },
    forgotUserPassword: (payload) => {
        return {
            type: 'USER/FORGOT_PASSWORD',
            payload
        }
    },
    resetUserPassword: (payload) => {
        return {
            type: 'USER/RESET_PASSWORD',
            payload
        }
    },
    getLogInInfo: (payload) => {
        return {
            type: 'USER/LOGIN_INFO',
            payload
        }  
    }
}

function* loginAsync(action) {

    yield put(setLoginLoadingState({ state: PAGE_STATE.LOADING, message: "Loading..." }));

    try {
        const userData = {
            ...action.payload,
            password: EncodeDecodeUtils.encode(action.payload.password)
        }
        const response = yield AuthDataService.login(userData)
        const { status } = response
        if (status) {
            const { data } = response
            localStorage.setItem('user', JSON.stringify(data.proivder))
            localStorage.setItem('providerUser', JSON.stringify(data.user))
            localStorage.setItem('role', data?.proivder.role)

            yield put(setLoggedInUser(data.proivder))
            yield put(setLoggedInProviderUser(data.user))

            const sessionJwt = data.sessionJwt;
            localStorage.setItem('token', sessionJwt);

        }
    } catch (error) {
        console.log('err: ', error)
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
    } finally {
        yield put(setLoginLoadingState({ state: PAGE_STATE.PAGE_READY }));

    }
}
function* forgotUserPasswordAsync(action) {

    yield put(setLoginLoadingState({ state: PAGE_STATE.LOADING, message: "Loading..." }));

    try {

        const data = action.payload
        const response = yield AuthDataService.forgotPassword(data)

    } catch (error) {
        console.log('err: ', error)
    } finally {
        yield put(setLoginLoadingState({ state: PAGE_STATE.PAGE_READY }));

    }
}
function* resetUserPasswordAsync(action) {

    yield put(setLoginLoadingState({ state: PAGE_STATE.LOADING, message: "Loading..." }));

    try {
        const { navigate, payload } = action.payload
        const { newPassword, confirmNewPassword, magicLinkToken } = payload
        const payloadData = {
            newPassword: EncodeDecodeUtils.encode(newPassword),
            confirmNewPassword: EncodeDecodeUtils.encode(confirmNewPassword),
            magicLinkToken: magicLinkToken
        }
        const response = yield AuthDataService.resetPassword(payloadData)

        const { response: status } = response.data
        if (status) {
            navigate("/resetpassword/success")
        }

    } catch (error) {
        console.log('err: ', error)
    } finally {
        yield put(setLoginLoadingState({ state: PAGE_STATE.PAGE_READY }));

    }
}


function* getLogInInfoAsync() {
    try {
        const response = yield AuthDataService.getLogInDetails()
        const { data, status } = response.data
        if (status) {
            yield put(setLoginDetails(data))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}


function* rootSaga() {
    yield all([
        takeLatest(login().type, loginAsync),
        takeLatest(forgotUserPassword().type, forgotUserPasswordAsync),
        takeLatest(resetUserPassword().type, resetUserPasswordAsync),
        takeLatest(getLogInInfo().type, getLogInInfoAsync),

    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
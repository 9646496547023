import { all, put, takeLatest } from 'redux-saga/effects';
import DepartmentDataService from '../../../../../services/DepartmentDataService';
import store from '../../../../../store/store';
import { componentKey, setAllDepartments, setIsDepartmentModalOpen } from './DepartmentsSlice';
import { addNotifications } from '../../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../../components/common-components/toaster/MetToaster';

export const { getAllDepartments, postDepartment, patchDepartment } = {
	getAllDepartments: (payload) => {
		return {
			type: 'DEPARTMENTS/GET_ALL_DEPARTMETNS',
			payload,
		};
	},
	postDepartment: (payload) => {
		return {
			type: 'DEPARTMENTS/POST_DEPARTMETNS',
			payload,
		};
	},
	patchDepartment: (payload) => {
		return {
			type: 'DEPARTMENTS/PATCH_DEPARTMETNS',
			payload,
		};
	},
};
function* getAllDepartmentsAsync(action) {
	try {
		const response = yield DepartmentDataService.getAllDepartments();
		if (response.status === 200) {
			yield put(setAllDepartments(response?.data?.data));
		}
	} catch (error) {
		console.log('err:', error);
	}
}

function* postDepartmentAsync(action) {
	const { editDepartmentData } = action.payload;
	try {
		const response =yield DepartmentDataService.postDepartment(editDepartmentData);
        if(response.status === 201){
            yield put(setIsDepartmentModalOpen(false))
            yield put(getAllDepartments())
			yield put(addNotifications({ message: 'Department added succesfully', variant: TOASTER_VARIANT.SUCCESS }));
        }
	} catch (error) {
			yield put(addNotifications({ message: error.response.data.message, variant: TOASTER_VARIANT.ERROR }));
			console.log(error);
	}
}

function* patchDepartmentAsync(action) {
	try {
		const { departmentId,editDepartmentData } = action.payload;
		const response =yield DepartmentDataService.patchDepartment(departmentId,editDepartmentData);
        if(response.status === 200){
            yield put(setIsDepartmentModalOpen(false))
            yield put(getAllDepartments())
			yield put(addNotifications({ message: 'Department updated succesfully', variant: TOASTER_VARIANT.SUCCESS }));
        }
	} catch (error) {
		console.log(error);
	}
}
function* rootSaga() {
	yield all([takeLatest(getAllDepartments().type, getAllDepartmentsAsync)]);
	yield all([takeLatest(postDepartment().type, postDepartmentAsync)]);
	yield all([takeLatest(patchDepartment().type, patchDepartmentAsync)]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { getValidationSchema } from '../../../libs/formsUtils';
import General from '../../../libs/utility/General';
import Icons from '../../../components/icons/Icons';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { componentKey, setIsInstantModalOpen, setSchedulingAppointmentsPaginationState } from '../ShedulingSlice';
import TextArea from '../../../components/common-components/textArea/TextArea';
import { FORM_FIELDS_FOR_BLOCK_APPOINTMENTS, FORM_FIELDS_FOR_INSTANT_BOOKING } from '../constant';
import { getAllPatients, postInstantAppointmentBooking } from '../SchedulingSaga';
import Checkbox from '../../../components/common-components/checkbox/Checkbox';
const InstantAppointment = ({ close }) => {
	const dispatch = useDispatch();
	const {
		isInstantAppointmentModalOpen,
		instantAppointmentData,
		patientList,
		schedulingAppointmentsPaginationState,
	} = useSelector((state) => state[componentKey]);
	const [patientListPageNum, setPatientListPageNum] = useState(1);
	useEffect(() => {
		const params = {
			pageNumber: patientListPageNum,
			limit: 20,
		};
		dispatch(getAllPatients(params));
	}, [dispatch, patientListPageNum]);
	const fields = [{ fieldName: FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT, isRequired: true, isDropdown: true }];
	const validationSchema = getValidationSchema(fields);

	return (
		<Formik
			initialValues={{
				[FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT]: General.getLabelValue(instantAppointmentData?.patientId) || {label:"",value:""},
				[FORM_FIELDS_FOR_INSTANT_BOOKING.NOTE]: instantAppointmentData?.note,
				[FORM_FIELDS_FOR_INSTANT_BOOKING.SEND_INVITE]: instantAppointmentData?.sendInvite,
			}}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				// const payload = {
				// 	patientId: values.patientId.uuid,
				// 	note: values.note,
				// 	sendInvite: values.sendInvite == true ? true : false,
				// };

				// dispatch(postInstantAppointmentBooking({ data: payload, schedulingAppointmentsPaginationState }));
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<ModalComponent
							open={isInstantAppointmentModalOpen}
							validationSchema={validationSchema}
							title={'Instant Appointment'}
							close={() => {
								close();
							}}
							icon={<Icons iconName="closeIcon" />}
							footerButton={
								<div className="flex justify-between w-full mt-5">
									<div className="flex justify-between mt-4 w-[50%]">
										<span className="flex text-met-primary font-bold">
											<Checkbox
												label="invite by email   "
												name={FORM_FIELDS_FOR_INSTANT_BOOKING.SEND_INVITE}
												checked={values[FORM_FIELDS_FOR_INSTANT_BOOKING.SEND_INVITE]}
												onChangeCb={(e) => {
													// setIsPatientsAadhar(!isPatientsAadhar);
													setFieldValue(FORM_FIELDS_FOR_INSTANT_BOOKING.SEND_INVITE, e);
												}}
											/>
										</span>
									</div>
									<div className="flex justify-between column-gap-20px">
										<Button
											type="submit"
											variant={BUTTON_VARIANTS.OUTLINED}
											customBtnClass="text-met-primary"
											onClickCb={() => {
												dispatch(setIsInstantModalOpen(false));
											}}
										>
											Cancel
										</Button>
										<Button
											type="submit"
											variant={BUTTON_VARIANTS.CONTAINED}
											customBtnClass="text-met-primary"
											onClickCb={() => {
												const payload = {
													patientId: values?.patientId?.value,
													note: values.note,
													sendInvite: values.sendInvite == true ? true : false,
												};						
												dispatch(postInstantAppointmentBooking({ data: payload, schedulingAppointmentsPaginationState }));
											}}
										>
											Create Appointment
										</Button>
									</div>
								</div>
							}
							customClasses="w-[40%] overflow-visible"
							enableReinitialize
							// hideOverflow={true}
						>
							<div className="">
								<div className="w-full  justify-between">
									<div className="">
										{/* <SelectDropdown
											name={FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT}
											value={values[FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT]}
											onChangeCb={(e) => {
												setFieldValue(FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT, e);
											}}
											toggleIcon={<Icons iconName={'searchIcon'}></Icons>}
											placeholder="Search Patient"
											label="Patient Name"
											options={patientList}
											isAsync={true}
											onDropdownTouchBottomCb={() => {
												setPatientListPageNum((prev) => prev + 1);
											}}
											isRequired={true}
										/> */}
										{/* {console.log(">>>>>>>>>>>>>",General.getLabelValue(values[FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT]))} */}
										<SelectDropdown
											name={FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT}
											value={values[FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT]}
											onChangeCb={(e) => {
												
												setFieldValue(FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT,
													General.getLabelValue(e.value))
												return dispatch(
													setSchedulingAppointmentsPaginationState({ patientId: e.value })
												);
											}}
											toggleIcon={<Icons iconName={'searchIcon'}></Icons>}
											placeholder="Search Patient"
											label="Patient Name"
											isAsync={true}
											url="patients"
											extractKeyFromResponse="patients"
											labelKey={'firstName'}
											valueKey={'uuid'}
											labelKey2={'lastName'}
										/>
									</div>
									<div className="">
										<TextArea
											label="Note"
											placeholder="Type here"
											customClasses="mt-2"
											name={FORM_FIELDS_FOR_INSTANT_BOOKING.NOTE}
											value={values[FORM_FIELDS_FOR_INSTANT_BOOKING.NOTE]}
											onChangeCb={(e) => {
												setFieldValue(FORM_FIELDS_FOR_INSTANT_BOOKING.NOTE, e.target.value);
											}}
										/>
									</div>
								</div>
							</div>
						</ModalComponent>
					</Form>
				);
			}}
		</Formik>
	);
};
export default InstantAppointment;

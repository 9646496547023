import React, { useState, useEffect } from 'react';

const Carousel = ({ content, customClasses, interval = 2000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
        }, interval);

        return () => clearInterval(timer);
    }, [content.length, interval]);

    const changeImage = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className={`relative ${customClasses}`}>
            {content[currentIndex]}
            <div className="absolute bottom-5 right-10 flex justify-center column-gap-16">
                {content.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => changeImage(index)}
                        className={`h-3 w-3 rounded-full border border-gray-400 ${currentIndex === index ? 'bg-white' : 'bg-gray-400'}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default Carousel;

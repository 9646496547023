import React from 'react'
import NavigationBorderedTabs from '../../components/common-components/navigationTabBorderVariant'
import { ENCOUNTER_ROUTES } from '../../libs/constant'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import CardContainer from '../../components/common-components/Cards/Container/CardContainer'
import ClinicalData from './ClinicalData'
import EncounterData from './EncounterData'
import History from './History'
import Templates from './Templates'
import WrapperCard from '../../components/common-components/Cards/WrapperCard/WrapperCard'

const EncounterWrapper = () => {
    const navigate = useNavigate()
    const routes = [
        { path: "/clinical-data", component: <ClinicalData /> },
        { path: "/patient-encounter", component: <EncounterData /> },
        { path: "/history", component: <History /> },
        { path: "/templates", component: <Templates /> },
        { path: "/*", component: <Navigate to={"clinical-data"} /> },

    ]
    return (
        <WrapperCard customClasses='w-[489px] px-[15px] pb-[15px] max-h-[90vh] min-h-[94vh] '>
            <NavigationBorderedTabs tabs={[
                { label: "Clinical Data", onClickCb: () => navigate('clinical-data'), path: ENCOUNTER_ROUTES.CLINICAL_DATA },
                { label: "Encounter", onClickCb: () => navigate('patient-encounter'), path: ENCOUNTER_ROUTES.ENCOUNTER },
                { label: "History", onClickCb: () => navigate('history'), path: ENCOUNTER_ROUTES.HISTORY },
                // { label: "Templates", onClickCb: () => navigate('templates'), path: ENCOUNTER_ROUTES.TEMPLATES } NOTE: Hide this as we are not showing anything on this tab
            ]} customClasses="bg-white py-[15px]" />

            <div className=' max-h-[85vh] overflow-y-scroll met-scrollbar'>
                <Routes>
                    {routes.map((route, index) => {
                        return <Route key={"ENCOUNTER-route" + index} path={route.path} element={route.component} />
                    })}
                </Routes>
            </div>
        </WrapperCard >
    )
}

export default EncounterWrapper
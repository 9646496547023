
const EncounterSummaryCard = ({ title, description, customLabelClass, fontSize }) => {
    return <div>
        <div className="my-4">
            <div className={`font-semibold ${customLabelClass} text-${fontSize}`}>{title}</div>
        </div>
        <div className="bg-padding-box border border-gray-300 rounded-lg shadow-sm p-2">
            <div>
                {description || '-'}
            </div>
        </div>
    </div>
}

export default EncounterSummaryCard
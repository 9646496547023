import React, { useEffect } from 'react'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../components/common-components/button/Button'
import Icons from '../../../../components/icons/Icons'
import Label from '../../../../components/common-components/label/Label'
import DatePicker from '../../../../components/common-components/datePicker'
import Input from '../../../../components/common-components/input/Input'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import SelectDropdown from '../../../../components/common-components/selectDropdown'
import Upload from '../../../../components/common-components/upload/Upload'
import { Form, Formik } from 'formik'
import { FORM_FIELDS_NAMES } from './Constants'
import { getValidationSchema } from '../../../../libs/formsUtils'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPatientDocumentsTypeDetails, postPatientDocuments } from './PatientDocumentsSaga'
import { componentKey as PatientDocumentsComponentKey } from "./PatientDocumentsSlice"
import { componentKey as PatientDashboardComponentKey } from "../PatientDashboardSlice"
import General from '../../../../libs/utility/General'

const fields = [
    { fieldName: FORM_FIELDS_NAMES.SELECT_DOCUMENT_TYPE, isRequired: true },
    { fieldName: FORM_FIELDS_NAMES.DATE, isRequired: true },
    { fieldName: FORM_FIELDS_NAMES.DOCUMENT_NAME, isRequired: true },
    { fieldName: FORM_FIELDS_NAMES.UPLOAD_FILE, isRequired: true }

];

const validationSchema = getValidationSchema(fields)

const initialValues = {
    [FORM_FIELDS_NAMES.SELECT_DOCUMENT_TYPE]: "",
    [FORM_FIELDS_NAMES.DATE]: '',
    [FORM_FIELDS_NAMES.DOCUMENT_NAME]: '',
    [FORM_FIELDS_NAMES.UPLOAD_FILE]: ''
}

function DocumentsModal({ open, close }) {
    const dispatch = useDispatch();
    const { allDocumentsTypeData, isDocumentsModalOpen } = useSelector(state => state[PatientDocumentsComponentKey])
    const { currentPatientId } = useSelector(state => state[PatientDashboardComponentKey])

    useEffect(() => {
        dispatch(getAllPatientDocumentsTypeDetails())
    }, [])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(formVals) => {
                try {
                    formVals.patientId = currentPatientId
                    dispatch(postPatientDocuments({ patientId: currentPatientId, documentsData: formVals }))
                }
                catch (err) {
                    console.log(err)
                }
            }}
        >
            {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
                return <Form onSubmit={handleSubmit} >
                    <ModalComponent
                        open={isDocumentsModalOpen}
                        title={'Upload'}
                        footerButton={<Button variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary" type='submit'>Save</Button>}
                        icon={<Icons iconName="closeIcon" />}
                        close={close}
                    >
                        <div className='p-2'>
                            <div className='flex column-gap-10px pt-6'>
                                <div className='flex-1'>
                                    <SelectDropdown
                                        label='Select Document Type'
                                        placeholder='Select Type'
                                        isRequired={true}
                                        name={FORM_FIELDS_NAMES.SELECT_DOCUMENT_TYPE}
                                        value={values[FORM_FIELDS_NAMES.SELECT_DOCUMENT_TYPE]}
                                        onChangeCb={(val) => { setFieldValue(FORM_FIELDS_NAMES.SELECT_DOCUMENT_TYPE, val.value) }}
                                        options={General.addLableValuePair(allDocumentsTypeData, "typeName", "uuid")} />
                                </div>
                                <div className='flex-1'>
                                    <DatePicker label="Date" placeholder='Choose Date'
                                        name={FORM_FIELDS_NAMES.DATE}
                                        isRequired={true}
                                        value={values[FORM_FIELDS_NAMES.DATE]}
                                        onChangeCb={(date) => {
                                            setFieldValue(FORM_FIELDS_NAMES.DATE, date)
                                        }} />
                                </div>
                            </div>
                            <div className='pt-6'>
                                <div>
                                    <Input
                                        label='Document Name'
                                        placeholder={"Enter"}
                                        isRequired={true}
                                        name={FORM_FIELDS_NAMES.DOCUMENT_NAME}
                                        value={values[FORM_FIELDS_NAMES.DOCUMENT_NAME]}
                                        onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.DOCUMENT_NAME, e.target.value) }}
                                        onBlurCb={handleBlur}
                                    />
                                </div>
                            </div>
                            <div className='pt-6'>
                                <div>
                                    <Upload
                                        icon={<Icons height='40px' width='40px' iconName={"fileUpload"}></Icons>}
                                        title='Drag & Drop files'
                                        content='Or'
                                        subTitle=' Browse Files'
                                        customClass='w-[599px] h-[243px]'
                                        name={FORM_FIELDS_NAMES.UPLOAD_FILE}
                                        value={values[FORM_FIELDS_NAMES.UPLOAD_FILE]}
                                        onChangeCb={async (file) => {
                                            const mimeType = file?.type || ""
                                            const base64String = await General.fileToBase64(file)
                                            const base64 = `data:${mimeType};base64,${base64String}`
                                            setFieldValue(FORM_FIELDS_NAMES.UPLOAD_FILE, base64)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='text-met-light-grey pt-2'>
                                <Label fontSize={"sm"}>Supported formats: JPEG, PNG, PDF,</Label>
                            </div>
                        </div>
                    </ModalComponent>

                </Form>
            }}
        </Formik>

    )
}

export default DocumentsModal
import React, { useEffect, useState } from 'react'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Icons from '../../../../components/icons/Icons';
import useBase64Image from '../../../../containers/SettingsTab/MyAccountCard/Tabs/PrintConfiguration/useS3Image';

const AudioPlayModal = ({ open, close,linkurl, viewTestsData }) => {
    const [audioSuccess, setAudioSuccess] = useState(false);
    const getBase64 = useBase64Image();
    const [base64String, setbase64String] = useState("")


    useEffect(() => {
        const fetchAndConvertToBase64 = async () => {
            if (linkurl) {
                try {
                    const base64Data = await getBase64(linkurl);
                    setbase64String(base64Data)

                } catch (error) {
                    console.error("Error converting URL to base64:", error);
                }
            }
        };

        fetchAndConvertToBase64();
    }, []);

    return (
        <>
            <ModalComponent
                open={open}
                title={"Play Audio Here"}
                icon={<Icons iconName="closeIcon" />}
                close={close}
                customClasses="w-[594px]">
                <div className='p-2'>
                    <div >
                        <audio
                            className="listing-audio"
                            id={"audio-player"}
                            src={`data:audio/mp3;base64,${base64String}`}
                            onCanPlayThrough={() => {
                                setAudioSuccess(true);
                            }}
                            onError={(e) => {
                                setAudioSuccess(false);
                            }}
                            controls
                        ></audio>
                    </div>
                </div>
            </ModalComponent>
        </>
    )
}

export default AudioPlayModal
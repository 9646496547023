import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Table from '../../../../containers/Table/Index'
import PastSurgicalHistoryModal from './PastSurgicalHistoryModal'
import { MET_COLORS } from '../../../../libs/constant'
import ButtonDropdown from '../../../../components/common-components/buttonDropdown'
import {
    componentKey as PatientHistoryComponentKey, setIsNewSurgicalHistoryAdd, setIsPastSurgicalHistoryDeletePopUp, setIsSurgicalHistoryModalOpen, setSurgicalHistoryData, setSurgicalHistoryId,
} from '../History/PatientHistorySlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import { deletePatientHistory, getAllPatientHistoryDetails } from '../History/PatientHistorySaga';
import General from '../../../../libs/utility/General';
import DeletePopup from '../../../../components/common-components/deletePopup/DeletePopup';

function PastSurgicalHistory() {

    const dispatch = useDispatch();
    const { isSurgicalHistoryModalOpen, allSurgicalHistoryData, isPastSurgicalHistoryDeletePopUp, currentSurgicalHistoryId } = useSelector((state) => state[PatientHistoryComponentKey]);

    const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);

    const handlePastSurgicalHistoryModal = () => {
        dispatch(setIsNewSurgicalHistoryAdd(true));
        dispatch(setSurgicalHistoryId(''));
        dispatch(setSurgicalHistoryData({}));
        dispatch(setIsSurgicalHistoryModalOpen(true));
    }

    const handleEditClick = (history) => {
        dispatch(setIsNewSurgicalHistoryAdd(false));
        dispatch(setSurgicalHistoryId(history.uuid));
        dispatch(setIsSurgicalHistoryModalOpen(true));
        dispatch(setSurgicalHistoryData(history));
    };

    const handleDeleteClick = (historyId) => {
        dispatch(deletePatientHistory({ patientId: currentPatientId, historyId, historyType: 'psh' }));
    };

    useEffect(() => {
        if (!currentPatientId) return;
        dispatch(getAllPatientHistoryDetails({ patientId: currentPatientId, historyType: 'psh', page: 1, limit: 10 }));
    }, [currentPatientId]);

    const coloumns = [
        {
            field: "surgery",
            label: <Label fontWeight="font-bold">Surgery</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.surgeryName}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "surgeryDate",
            label: <Label fontWeight="font-bold">Surgery Date</Label>,
            renderLogic: (row) => {
                const formattedSurgeryDate = General.getformattedDate(row.surgeryDate)
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{formattedSurgeryDate}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "recordedDate",
            label: <Label fontWeight="font-bold">Recorded Date</Label>,
            renderLogic: (row) => {
                const formattedRecordedDate = General.getformattedDate(row.createdAt)
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{formattedRecordedDate}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "note",
            label: <Label fontWeight="font-bold">Note</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.note || '-'}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "action",
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.action}</Heading>
                        </div>
                        <ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[{ label: "Edit", value: null, onChangeCb: () => handleEditClick(row) }, {
                            label: "Delete", value: null, onChangeCb: () => {
                                dispatch(setIsPastSurgicalHistoryDeletePopUp(true))
                                dispatch(setSurgicalHistoryId(row.uuid));
                            }
                        }]}
                            selectCb={(option) => {
                                option?.onChangeCb();
                            }} />
                    </div>
                </>
            },
        },
    ]

    return (
        <>
            <div className='flex items-center justify-end p-2 px-4'>
                <div className='flex'>
                    <Button onClickCb={handlePastSurgicalHistoryModal} variant={BUTTON_VARIANTS.OUTLINED} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} >Add PSH</Button>
                </div>
            </div>
            <div className='p-4'>
                <Table isPagination={false} coloumns={coloumns} rows={allSurgicalHistoryData} sorting={
                    {
                        isSortable: false,
                        onSortChangeCb: () => { }
                    }} />
            </div>
            {isSurgicalHistoryModalOpen ? <PastSurgicalHistoryModal open={isSurgicalHistoryModalOpen} close={() => dispatch(setIsSurgicalHistoryModalOpen(false))} /> : null}
            {isPastSurgicalHistoryDeletePopUp ?
                <DeletePopup
                    open={isPastSurgicalHistoryDeletePopUp}
                    okButton="Delete"
                    cancelButton='Cancel'
                    confirmationMessage="Delete"
                    onClickCancel={() => { dispatch(setIsPastSurgicalHistoryDeletePopUp(false)) }}
                    onClickOk={() => handleDeleteClick(currentSurgicalHistoryId)}
                /> : null}
        </>
    )
}

export default PastSurgicalHistory
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useGetCurrenPathHook from '../../../../../../libs/customHooks/useGetCurrenPathHook';
import CardContainer from '../../../../../../components/common-components/Cards/Container/CardContainer';
import Icons from '../../../../../../components/icons/Icons';
import Tabs from '../../../../../../components/common-components/Tabs';
import { MAIN_PATHS, SETTINGS_PATHS } from '../../../../../../libs/constant';
import Details from './Details';
import Patients from './Patients';
import Users from './Users';
import Button from '../../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../../components/common-components/button/Constants';
import { setOpenSideDrawer } from '../LocationSlice';

export const locationsTab = [
	{
		id: '/details',
		name: 'Details',
		redirectTo: SETTINGS_PATHS.PROVIDER_GROUP_LOCATIONS_DETAILS,
		component: <Details />,
	},
	{
		id: '/patients',
		name: 'Patients',
		redirectTo: SETTINGS_PATHS.PROVIDER_GROUP_LOCATIONS_PATIENTS,
		component: <Patients />,
	},
	{ id: '/users', name: 'Users', redirectTo: SETTINGS_PATHS.PROVIDER_GROUP_LOCATIONS_USERS, component: <Users /> },
];
const LocationsTabsContainer = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const currrentPath = useGetCurrenPathHook(true, 2);

	return (
		<>
			<CardContainer>
				<div className="justify-between mb-4 flex font-medium text-rgba-1a1a1acc h-[42px]">
					<div className="flex text-2xl">
						<span
							onClick={() => {
								dispatch(setOpenSideDrawer(false));
								navigate(SETTINGS_PATHS.PROVIDER_GROUP_LOCATIONS);
							}}
							className="hover:cursor-pointer pt-[4px]"
						>
							<Icons iconName="arrowBackIcon" />
						</span>
						<span className="pl-[10px] font-bold text-met-primary">Location</span>
					</div>
				</div>
				<Tabs tabs={locationsTab} pathLevel={3} />
			</CardContainer>
		</>
	);
};

export default LocationsTabsContainer;

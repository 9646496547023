

import { all, put, takeLatest } from "redux-saga/effects";
import { componentKey, setActivePageIndex, setExactPatientList, setLoadingState, setMediaStream, setMeetingArguments, setPatientListData, setScheduleAppointmentDetails, setSendInviteModal, setStartVideoTimer } from "./ZoomIntegrationSlice";

import { toast } from 'react-toastify'
import General from "../../../libs/utility/General";
import { PAGE_STATE } from "../../../libs/constant";
import store from "../../../store/store";
import SchedulingService from "../../../services/SchedulingService";

export const { getZoomAuthJwtToken, getAppointmentScheduleDetails, patchUpdateScheduleDetails, getPatientList, getProviderList, postSendInviteToGuest, postSendReminder, getZoomAuthJwtTokenForGuest } = {
    getZoomAuthJwtToken: (payload) => {
        return {
            type: "ZOOM_INTEGRATE/GENERATE_TOKEN",
            payload,
        };
    },
    getZoomAuthJwtTokenForGuest: (payload) => {
        return {
            type: "ZOOM_INTEGRATE/GENERATE_TOKEN_FOR_GUEST",
            payload,
        };
    },
    getAppointmentScheduleDetails: (payload) => {
        return {
            type: "ZOOM_INTEGRATE/GET_APPOINTMENT_DETAILS",
            payload,
        }
    },
    patchUpdateScheduleDetails: (payload) => {
        return {
            type: "ZOOM_INTEGRATE/PATCH_UPDATE_SCHEDULE_DETAILS",
            payload,
        }
    },
    getPatientList: (payload) => {
        return {
            type: "ZOOM_INTEGRATE/GET_PATIENT_LIST",
            payload,
        }
    },
    getProviderList: (payload) => {
        return {
            type: "ZOOM_INTEGRATE/GET_PROVIDER_LIST",
            payload,
        }
    },
    postSendInviteToGuest: (payload) => {
        return {
            type: "ZOOM_INTEGRATE/SEND_INVITE_TO_USER",
            payload,
        }
    },
    postSendReminder: (payload) => {
        return {
            type: "ZOOM_INTEGRATE/SEND_REMINDER_USER",
            payload,
        }
    },
};

function* getZoomAuthJwtTokenAsync(action) {
    try {
        const { scheduleId, type, client, userIdentity } = action.payload;
        yield put(setLoadingState({ state: PAGE_STATE.LOADING, message: "Loading..." }));

        const response = yield SchedulingService.getAuthenticationToken(scheduleId, type);

        if (response.status === 200) {
            yield put(setMeetingArguments(response.data))

            const data = General.tokenDecode(response.data)

            yield put(setLoadingState({ state: PAGE_STATE.LOADING, message: "Joining the session..." }))

            let topic = data.topic
            let signature = data.signature
            let name = userIdentity ? userIdentity : data.user_identity
            let password = ""

            yield client.join(topic, signature, name, password)
            yield put(setActivePageIndex(1))
            const stream = client.getMediaStream();
            yield put(setMediaStream(stream))
            yield put(setStartVideoTimer(true))
        }

    } catch (error) {
        toast.error(error?.response?.data ? error?.response?.data?.message : error?.reason )
        console.log(error);
    } finally {
        yield put(setLoadingState({ state: PAGE_STATE.PAGE_READY }));
    }
}

function* getZoomAuthJwtTokenForGuestAsync(action) {
    try {
        const { scheduleId, tenetId, type, client, userIdentity } = action.payload;
        yield put(setLoadingState({ state: PAGE_STATE.LOADING, message: "Loading..." }));

        const response = yield SchedulingService.getZoomAuthJwtTokenForGuest(scheduleId, tenetId, type);

        if (response.status === 200) {
            yield put(setMeetingArguments(response.data))

            const data = General.tokenDecode(response.data)

            yield put(setLoadingState({ state: PAGE_STATE.LOADING, message: "Joining the session..." }))

            let topic = data.topic
            let signature = data.signature
            let name = userIdentity ? userIdentity : data.user_identity
            let password = ""

            yield client.join(topic, signature, name, password)
            yield put(setActivePageIndex(1))
            const stream = client.getMediaStream();
            yield put(setMediaStream(stream))
            yield put(setStartVideoTimer(true))
        }

    } catch (error) {
        toast.error(error?.response?.data ? error?.response?.data?.message : error?.reason )
        console.log(error);
    } finally {
        yield put(setLoadingState({ state: PAGE_STATE.PAGE_READY }));
    }
}


function* getAppointmentScheduleDetailsAsync(action) {
    try {
        const { scheduleId, tenetId } = action.payload;
        yield put(setLoadingState({ state: PAGE_STATE.LOADING, message: "Loading..." }));

        const response = yield SchedulingService.getAppointmentScheduleDetails({ scheduleId, tenetId });

        if (response.status == 200) {
            yield put(setScheduleAppointmentDetails(response.data))
        }

    } catch (error) {
        console.log(error);
    } finally {
        yield put(setLoadingState({ state: PAGE_STATE.PAGE_READY }));
    }
}

function* patchUpdateScheduleDetailsAsync(action) {
    try {
        const { scheduleId, body } = action.payload;
        yield put(setLoadingState({ state: PAGE_STATE.LOADING, message: "Loading..." }));

        yield SchedulingService.patchUpdateScheduleDetails({ scheduleId, body });

    } catch (error) {
        console.log(error);
    } finally {
        window.location.reload()
        yield put(setLoadingState({ state: PAGE_STATE.PAGE_READY }));
    }
}

function* getPatientListAsync(action) {
    try {
        const { params, token } = action.payload;
        yield put(setLoadingState({ state: PAGE_STATE.LOADING, message: "Loading..." }));

        const response = yield SchedulingService.getPatientListSelect(params, token);

        if (response.status === 200) {
            let filteredData = response.data.data.patients.map(item => ({ ...item, label: `${item.firstName} ${item.lastName}`, value: `${item.id}` }))
            if(params.search.lenght !== 0){
                yield put(setExactPatientList(filteredData))
            }else{
                yield put(setPatientListData(filteredData))
            }
        }

    } catch (error) {
        toast.error(error?.response?.data ? error?.response?.data?.message : error?.reason )
        console.log(error);
    } finally {
        yield put(setLoadingState({ state: PAGE_STATE.PAGE_READY }));
    }
}

function* getProviderListAsync(action) {
    try {
        const { params, token } = action.payload;
        yield put(setLoadingState({ state: PAGE_STATE.LOADING, message: "Loading..." }));

        const response = yield SchedulingService.getProvidersListSelect(params, token);

        if (response.status === 200) {
            let filteredData = response.data.data.map(item => ({ ...item, label: `${item.firstName} ${item.lastName}`, value: `${item.id}` }))
            if(params.search.lenght !== 0){
                yield put(setExactPatientList(filteredData))
            }else{
                yield put(setPatientListData(filteredData))
            }
        }

    } catch (error) {
        toast.error(error?.response?.data ? error?.response?.data?.message : error?.reason )
        console.log(error);
    } finally {
        yield put(setLoadingState({ state: PAGE_STATE.PAGE_READY }));
    }
}

function* postSendInviteToGuestAsync(action) {
    try {
        const { body, token } = action.payload;
        yield put(setLoadingState({ state: PAGE_STATE.LOADING, message: "Loading..." }));

        const response = yield SchedulingService.postSendInviteToGuest({ body, token });

        if (response.status === 201) {
            toast.success("Invitation sent successfully.")
        }

    } catch (error) {
        toast.error(error?.response?.data?.message)
        console.log(error);
    } finally {
        yield put(setSendInviteModal(false))
        yield put(setLoadingState({ state: PAGE_STATE.PAGE_READY }));
    }
}

function* postSendReminderAsync(action) {
    try {
        const { scheduleId, token } = action.payload;
        yield put(setLoadingState({ state: PAGE_STATE.LOADING, message: "Loading..." }));

        const response = yield SchedulingService.postSendReminder({ scheduleId, token });

        if (response.status === 201) {
            toast.success("Notified patient successfully.")
        }

    } catch (error) {
        toast.error(error?.response?.data?.message)
        console.log(error);
    } finally {
        yield put(setLoadingState({ state: PAGE_STATE.PAGE_READY }));
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getZoomAuthJwtToken().type, getZoomAuthJwtTokenAsync),
        takeLatest(getZoomAuthJwtTokenForGuest().type, getZoomAuthJwtTokenForGuestAsync),
        takeLatest(getAppointmentScheduleDetails().type, getAppointmentScheduleDetailsAsync),
        takeLatest(patchUpdateScheduleDetails().type, patchUpdateScheduleDetailsAsync),
        takeLatest(getPatientList().type, getPatientListAsync),
        takeLatest(getProviderList().type, getProviderListAsync),
        takeLatest(postSendInviteToGuest().type, postSendInviteToGuestAsync),
        takeLatest(postSendReminder().type, postSendReminderAsync),
    ]);
}
store.sagaManager.addSaga(componentKey, rootSaga);

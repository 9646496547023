export default {

    permissionContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        height: "100%",
        width: "100%",
    },
    mainView: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        height: "100%",
        width: "100%"
    },
    permission: {
        background: "#3A3A3A 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 10px #00000029",
        borderRadius: "10px",
        width: "100%",
        height: '65%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    permissionContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    enableButtonStyle: {
        marginTop: "40px",
        fontWeight: "400",
        fontSize: "14px",
        width: "180px"
    },
    permissionButtonContainer: {
        width: "45%",
        height: '100%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        rowGap: "30px"
    },
    dropdownMenu: {
        width: "270px",
        marginLeft: "10px",
    },

    readyToJoinVideo: {
        position: "relative",
        width: "100%",
        maxWidth: '100%',
        boxShadow: "0px 0px 10px #00000029",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: 'hidden',
    },

    videoButtons: {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        bottom: '100px',
        width: '100%',
        padding: '5px',
        margin: "0 auto",
    },

    videoButtonsIcon: {
        margin: '0 5px',
        cursor: 'pointer',
    },

    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        flex: "wrap",
        gap: "10px"
    },

    buttonMainContainer: {
        display: "flex",
        justifyContent: "center",
        gap: "10px"
    },

    select: {
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        width: "280px",
        padding: "10px 10px 10px 15px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 8px #00000029",
        borderRadius: "5px",
        cursor: "pointer"
    },

    listContainer: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 8px #00000029",
        padding: "10px",
        position: "absolute",
        bottom: "130px"
    },

    deviceList: {
        display: "flex",
        gap: "10px",
        padding: "5px",
        cursor: "pointer"
    },

    activeDeviceList: {
        display: "flex",
        gap: "10px",
        padding: "5px",
        cursor: "pointer",
        background: "#abcbf1",
        borderRadius: "5px",
    },

    testOutput: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
        width: "100%",
        marginTop: "10px"
    }
}
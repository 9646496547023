import React, { useState } from 'react'
import Label from '../../components/common-components/label/Label'
import Input from '../../components/common-components/input/Input'
import Checkbox from '../../components/common-components/checkbox/Checkbox'
import Button from '../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../components/common-components/button/Constants'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login } from './LoginSaga'
import { FORM_FIELDS_NAMES } from './Constants'
import { getValidationSchema } from '../../libs/formsUtils'
import { Form, Formik } from 'formik'
import { VALIDATION_REGEX } from '../../libs/constant'
import { rolesAndPermissionOfUser } from '../../containers/Wrappers/Dataloader/DataloaderSaga'
import { componentKey } from './LoginSlice'
import Loadable from '../../components/common-components/zoom/components/loadable/Loadable'
import Icons from '../../components/icons/Icons'

const fields = [
    { fieldName: FORM_FIELDS_NAMES.EMAIL, isRequired: true, isEmail: true },
    { fieldName: FORM_FIELDS_NAMES.PASSWORD, isRequired: true, isPassword: true, regexPattern: VALIDATION_REGEX.PASSWORD_REGEX },
];

const validationSchema = getValidationSchema(fields)

function LoginCard() {
    const [isCheckedRememberMe, setIsCheckedRememberMe] = useState(false)
    const { loginLoadingState } = useSelector(state => state[componentKey])
	const [showPassword, setShowPassword] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handlePasswordToggle = () => {
        console.log();
        setShowPassword(!showPassword);
    };
    return (
        <div className='flex flex-1 items-center justify-center'>
            <Formik
                initialValues={{
                    [FORM_FIELDS_NAMES.EMAIL]: "",
                    [FORM_FIELDS_NAMES.PASSWORD]: "",
                }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    dispatch(login(values))
                }}
            >
                {({ submitForm, isSubmitting, handleReset, values, setFieldValue, handleSubmit, handleBlur }) => {
                    return <Form onSubmit={handleSubmit} >
                        <div className='w-[500px] h-[400px] flex flex-col justify-evenly'>
                            <div className='text-met-primary'>
                                <Label fontSize={"2xl"} fontWeight='bold'>Hey, good to see you</Label>
                            </div>
                            <div className='text-met-primary'>
                                <Label fontSize={"xl"} fontWeight='bold'>Lets Sign in you</Label>
                            </div>
                            <div>
                                <Input placeholder={"Email"}
                                    name={FORM_FIELDS_NAMES.EMAIL}
                                    value={values[FORM_FIELDS_NAMES.EMAIL]}
                                    onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.EMAIL, e.target.value) }}
                                    onBlurCb={handleBlur} />
                            </div>
                            <div>
                                <Input placeholder={"Password"}
                                    type={showPassword ? "text" : "password"}
                                    name={FORM_FIELDS_NAMES.PASSWORD}
                                    value={values[FORM_FIELDS_NAMES.PASSWORD]}
                                    onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.PASSWORD, e.target.value) }}
                                    onBlurCb={handleBlur}
                                    endIcon={
                                        showPassword ? 
                                        <Icons iconName="eyeIcon" style={{cursor:"pointer", }}  onClick={handlePasswordToggle} /> : 
                                        <Icons iconName="eyeSlashIcon"  style={{cursor:"pointer" }} onClick={handlePasswordToggle} />
                                    }
                                    />
                            </div>
                            <div>
                                <div className='flex justify-between items-center '>
                                    <div className='flex items-center'>
                                        <div>
                                            <Checkbox
                                                checked={isCheckedRememberMe}
                                                onChangeCb={(e) => {
                                                    setIsCheckedRememberMe(!isCheckedRememberMe)
                                                }}

                                            />
                                        </div>
                                        <div className='text-met-primary ml-2'>
                                            <Label>Remember Me</Label>
                                        </div>
                                    </div>
                                    <div className='text-met-primary'>
                                        <Label onChangeCb={() => { navigate("recovery") }}>Forgot Password?</Label>
                                    </div>
                                </div>
                            </div>
                            <Loadable loadingStates={loginLoadingState.state} loadingMessage={loginLoadingState.message}>
                                <div>
                                    <Button type='submit' variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary w-full justify-center">Lets get started </Button>
                                </div>
                            </Loadable >

                        </div>
                    </Form>
                }}
            </Formik>
        </div>
    )
}

export default LoginCard
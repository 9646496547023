import React, { useEffect, useState } from 'react';
import Label from '../../../components/common-components/label/Label';
import Table from '../../../containers/Table/Index';
import {
	componentKey,
	setIsAppointmentDetailsModalOpen,
	setAppointmentDetails,
	setIsCheckedInModalOpen,
	setIsCheckedInData,
	setActiveDate,
	setSchedulingAppointmentsPaginationState,
	setIsSuccessPopUpModalOpen,
	setIsSuccessPopUpModalOpenForReschedule,
	setIsSuccessPopUpModalOpenForSignedOff,
	setIsSuccessPopUpModalOpenForNoShow
} from '../ShedulingSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Heading from '../../../components/common-components/heading/Heading';
import { HEADING } from '../../../components/common-components/heading/Constants';
import AppointmentDetailsModal from '../ScheduleAppointmentModals/AppointmentDetailsModal';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import CheckInModal from '../ScheduleAppointmentModals/CheckInModal';
import Icons from '../../../components/icons/Icons';
import { useNavigate } from 'react-router-dom';
import Avatar from '../../../components/common-components/avatar';
import SucessPopUp from '../../../components/common-components/popup-components/SucessPopUp';

const SchedulingGridLists = () => {
	const {
		allScheduledAppointmentsforVirtualMode,
		allScheduledAppointmentsforLocationMode,
		isSuccessPopUpModalOpen,
		isSuccessModalOpenForReschedule,
		statusOfAppointment,
		activeDate,
		isSuccessModalOpenForSignedOff,
		isSuccessModalOpenForNoShow

	} = useSelector((state) => state[componentKey]);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setSchedulingAppointmentsPaginationState({ dateTo: new Date(), dateFrom: new Date() }));
	}, []);
	const isButtonDisabled = !moment().isSame(activeDate, 'day');
	const coloumns = [
		{
			field: 'time',
			label: <Label fontWeight="bold">Time</Label>,
			renderLogic: (row) => {
				const startTime = moment(row.start, 'hh:mm A');
				const endtime = moment(row.end, 'hh:mm A');

				let totalAppointmentTime;
				if (endtime.isBefore(startTime)) {
					const nextDayEndTime = endtime.add(1, 'day');
					totalAppointmentTime = moment.duration(nextDayEndTime.diff(startTime)).asMinutes();
				} else {
					totalAppointmentTime = moment.duration(endtime.diff(startTime)).asMinutes();
				}
				return (
					<>
						<p>{`${row?.start} (${totalAppointmentTime} mins)`}</p>
					</>
				);
			},
		},
		{
			field: 'appointmentType',
			label: <Label fontWeight="bold">Appointment Type</Label>,
			renderLogic: (row) => {
				return <p>{row?.appointmentType}</p>;
			},
		},
		{
			field: 'patientName',
			label: <Label fontWeight="bold">Patient Name</Label>,
			renderLogic: (row) => {
				const gender = row?.Patient?.gender.slice(0, 1).toUpperCase();
				return (
					<div className="flex  column-gap-10px">
						<Avatar
							customClass="w-[40px] h-[40px] rounded-full"
							url={row?.Patient?.profilePicture}
							name={`${row?.Patient?.firstName} ${row?.Patient?.lastName}`}
						/>
						<Heading
							onClickCb={() => {
								if (row.uuid) {
									navigate(`/patients/${row.Patient?.uuid}`);
								}
							}}
							fontWeight="bold"
							type={HEADING.H1}
							color="met-primary"
							fontSize={'md'}
							customClasses="cursor-pointer mt-2"
						>
							{`${row?.Patient.firstName} ${row?.Patient.lastName} (${gender})`}
						</Heading>
					</div>
				);
			},
			sort: 'mobileNumber',
		},
		{
			field: 'dob',
			label: <Label fontWeight="bold">Date of Birth</Label>,
			renderLogic: (row) => {
				return <p>{moment(row?.Patient.dob).format('DD-MM-YYYY')}</p>;
			},
		},
		{
			field: 'contactNumber',
			label: <Label fontWeight="bold">Contact Number</Label>,
			renderLogic: (row) => {
				return <p>{row?.Patient?.mobileNumber}</p>;
			},
			sort: 'lastVisit',
		},
		{
			field: 'providerName',
			label: <Label fontWeight="bold">Provider Name</Label>,
			renderLogic: (row) => {
				return <p>{`${row?.Provider.firstName} ${row?.Provider.lastName}`}</p>;
			},
		},
		{
			field: 'chiefComplaint',
			label: <Label fontWeight="bold">Chief Complaint</Label>,
			renderLogic: (row) => {
				return <p>{row?.complaints}</p>;
			},
		},
		{
			field: 'status',
			label: <Label fontWeight="bold">Status</Label>,
			renderLogic: (row) => {
				const color = row?.color;
				return (
					<p
						className={`${
							row.status !== 'Signed_Off' || row.status !== 'Unsigned' ? `cursor-pointer` : ''
						}`}
						style={{ color: color, textDecoration: 'underline' }}
						onClick={() => {
							if (row.status !== 'Signed_Off' && row.status !== 'Unsigned' && row.status !== "No_Show" && row.status !== "Cancelled") {
								dispatch(setIsCheckedInData(row));
								dispatch(setIsAppointmentDetailsModalOpen(true));
								dispatch(setAppointmentDetails(row));
							}
						}}
					>
						{row?.status}
					</p>
				);
			},
		},
		{
			field: 'status',
			label: <></>,
			renderLogic: (row) => {
				return (
					<Button
						type="submit"
						variant={BUTTON_VARIANTS.OUTLINED}
						customBtnClass="text-met-primary"
						startIcon={{ icon: <Icons iconName="startButtonIcon" /> }}
						onClickCb={() => {
							dispatch(setIsCheckedInData(row));
							// if (row.status === 'Checked_In' || row?.status === "Scheduled" || row?.status === "ReScheduled") {
							// 	dispatch(setIsCheckedInModalOpen(true));
							// }
							if (row.status !== 'Signed_Off' && row.status !== 'Unsigned' && row.status !== "No_Show" && row.status !== "Cancelled") {
								dispatch(setIsCheckedInModalOpen(true));
							}
						}}
						disabled={isButtonDisabled}
					>
						Start
					</Button>
				);
			},
		},
	];
	return (
		<div className="p-2">
			<div>
				<Label fontWeight="bold" fontSize={'lg'} color="met-primary">
					Virtual
				</Label>
				<Table coloumns={coloumns} rows={allScheduledAppointmentsforVirtualMode} />
			</div>
			<div className="mt-5">
				<Label fontWeight="bold" fontSize={'lg'} color="met-primary">
					In Person
				</Label>
				<Table coloumns={coloumns} rows={allScheduledAppointmentsforLocationMode} />
			</div>
			{statusOfAppointment === 'Cancelled' && isSuccessPopUpModalOpen ? (
				<SucessPopUp
					isOpen={isSuccessPopUpModalOpen}
					successMesaage="Appointment Cancelled Successfully"
					onClickOk={() => {
						dispatch(setIsSuccessPopUpModalOpen(false));
						// navigate('/scheduling/list');
					}}
				>
					<Icons iconName="successSvg"></Icons>
				</SucessPopUp>
			) : null}
			{statusOfAppointment === 'ReScheduled' && isSuccessModalOpenForReschedule ? (
				<SucessPopUp
					isOpen={isSuccessModalOpenForReschedule}
					successMesaage="Appointment Rescheduled Successfully"
					onClickOk={() => {
						dispatch(setIsSuccessPopUpModalOpenForReschedule(false));
						// navigate('/scheduling/list');
					}}
				>
					<Icons iconName="successSvg"></Icons>
				</SucessPopUp>
			) : null}
			{statusOfAppointment === 'Signed_Off' && isSuccessModalOpenForSignedOff ? (
				<SucessPopUp
					isOpen={isSuccessModalOpenForSignedOff}
					successMesaage="Appointment status has been updated successfully"
					onClickOk={() => {
						dispatch(setIsSuccessPopUpModalOpenForSignedOff(false));
						// navigate('/scheduling/list');
					}}
				>
					<Icons iconName="successSvg"></Icons>
				</SucessPopUp>
			) : null}{' '}
			{statusOfAppointment === 'No_Show' && isSuccessModalOpenForNoShow ? (
				<SucessPopUp
					isOpen={isSuccessModalOpenForNoShow}
					successMesaage="Appointment status has been updated successfully"
					onClickOk={() => {
						dispatch(setIsSuccessPopUpModalOpenForNoShow(false));
						// navigate('/scheduling/list');
					}}
				>
					<Icons iconName="successSvg"></Icons>
				</SucessPopUp>
			) : null}
		</div>
	);
};

export default SchedulingGridLists;

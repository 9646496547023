import React, { useState } from 'react'
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../../components/common-components/button/Button'
import Label from '../../../../../components/common-components/label/Label'
import Input from '../../../../../components/common-components/input/Input'
import ColorSelector from './ColorSelector'
import { useDispatch } from 'react-redux'
import { updateColorConfig } from './ColorConfigSaga'

const ColorConfigModal = ({ open, close, selectedRow }) => {

    const [selectedColor, setSelectedColor] = useState(selectedRow ? selectedRow.color : '');
    const [appointmentType, setAppointmentType] = useState(selectedRow ? selectedRow.appointmentType : '')

    const dispatch = useDispatch()

    const handleColorChange = (color) => {
        setSelectedColor(color);
    };

    const handleAppointmentTypeChange = (e) => {
        setAppointmentType(e.target.value);
    };

    return (
        <ModalComponent customClasses='w-[600px]' customBodyClasses='h-[450px]' close={close} open={open} title="Edit Color Configuration" footerButton={<Button onClickCb={() => {
            dispatch(updateColorConfig({ uuid: selectedRow.uuid, values: { appointmentType: appointmentType, colour: selectedColor } }));
        }}>Save</Button>}>
            <div>
                <div>
                    <Label>Appointment Name</Label>
                </div>
                <div className='pt-2'>
                    <Input value={appointmentType} onChangeCb={handleAppointmentTypeChange} bgInputColor="bg-met-primary" />
                </div>
                <div className='flex pt-5'>
                    <div>
                        <Label fontSize="lg">Color</Label>
                    </div>
                    <div>
                        <ColorSelector selectedColor={selectedColor} handleColorChange={handleColorChange} selectedRow={selectedRow} />
                    </div>
                </div>
            </div>
        </ModalComponent>
    );
};

export default ColorConfigModal;
import store from '../../../../store/store';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { componentKey, setAllLabResultData, setLabResultData, setIsLabResultModalOpen, setAllLabsData, setIsOpenDeleteLabResultPopup } from './PatientLabSlice';
import PatientDashboardDataService from '../../../../services/PatientDashboardDataService';
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/Constants';

export const {
    getAllPatientLabResultDetails,
    postPatientLabResult,
    deletePatientLabResult,
    updatePatientLabResult,
    getAllLabsDetails,
} = {
    getAllPatientLabResultDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/LAB',
            payload,
        };
    },
    postPatientLabResult: (payload) => {
        return {
            type: 'POST/PATIENTDASHBOARD/LAB',
            payload,
        };
    },
    deletePatientLabResult: (payload) => {
        return {
            type: 'DELETE/PATIENTDASHBOARD/LAB',
            payload,
        };
    },
    updatePatientLabResult: (payload) => {
        return {
            type: 'UPDATE/PATIENTDASHBOARD/LAB',
            payload,
        };
    },
    getAllLabsDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/ALLLABS',
            payload,
        };
    },
};

function* getAllPatientLabResultDetailsAsync(action) {
    try {
        yield put(setAllLabResultData([]));
        const { patientId = '', page = '1', limit = '10' } = action.payload;
        const params = {
            page: page,
            limit: limit,
        };

        const response = yield PatientDashboardDataService.getAllPatientLabResult(
            'labresult/patient',
            patientId,
            params
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            const labResult = data?.labresults.map((result, index) => {
                return {
                    ...result, index
                }
            })
            yield put(setAllLabResultData(labResult));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* postPatientLabResultAsync(action) {
    try {
        yield put(setAllLabResultData([]));
        const { patientId, labresultData } = action.payload;
        const response = yield PatientDashboardDataService.postPatientLabResult('labresult', patientId, labresultData);
        const { message, status, data } = response?.data || {};
        if (status) {
            yield put(setAllLabResultData(data?.labresults));
            yield put(setIsLabResultModalOpen(false));
            yield call(getAllPatientLabResultDetailsAsync,
                getAllPatientLabResultDetails({ patientId }));
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* deletePatientLabResultDetailsAsync(action) {
    try {
        const { patientId = '', labresultId = '' } = action.payload;

        const response = yield PatientDashboardDataService.deletePatientLabResult('labresult', labresultId, patientId);

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setIsOpenDeleteLabResultPopup(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAllPatientLabResultDetailsAsync,
                getAllPatientLabResultDetails({ patientId }));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* updatePatientLabResultDetailsAsync(action) {
    try {
        const { patientId = '', labresultId = '', labresultData = {} } = action.payload;

        const response = yield PatientDashboardDataService.updatePatientLabResult(
            'labresult',
            labresultId,
            patientId,
            labresultData
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setIsLabResultModalOpen(false));
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAllPatientLabResultDetailsAsync,
                getAllPatientLabResultDetails({ patientId }));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* getAllLabsDetailsAsync(action) {
    try {

        const response = yield PatientDashboardDataService.getAllLabs(
            'lab',
        );

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setAllLabsData(data?.labs));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getAllPatientLabResultDetails().type, getAllPatientLabResultDetailsAsync),
        takeLatest(postPatientLabResult().type, postPatientLabResultAsync),
        takeLatest(deletePatientLabResult().type, deletePatientLabResultDetailsAsync),
        takeLatest(updatePatientLabResult().type, updatePatientLabResultDetailsAsync),
        takeLatest(getAllLabsDetails().type, getAllLabsDetailsAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

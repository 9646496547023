import React from 'react'
import './styles.scss'
import Input from '../../../components/common-components/input/Input'
import SelectDropdown from '../../../components/common-components/selectDropdown';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { EMERGENCY_CONTACT_FIELDS } from './Constants';
import { componentKey } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import { useSelector } from 'react-redux';
import General from '../../../libs/utility/General';
import Icons from '../../../components/icons/Icons';

function EmergencyContactCard({ contact, index, handleBlur, setFieldValue, handleRemoveCb, enableDelete = false }) {
    const { isFormSubmitted } = useSelector(state => state[componentKey])
    return (
        <>
            <div className='custom-patient-details bg-opacity-3 rounded m-4 p-5'>
                <div className='emergency-contact-wrapper justify-start flex column-gap-20px row-gap-15'>
                    <div className='flex-1 w-1/4 min-w-410 max-w-450'>
                        <SelectDropdown
                            name={`emergencyContacts[${index}].${EMERGENCY_CONTACT_FIELDS.RELATIONSHIP_WITH_PATIENT}`}
                            isRequired={true}
                            placeholder='Select'
                            label='Relationship With Patient'
                            value={contact[EMERGENCY_CONTACT_FIELDS.RELATIONSHIP_WITH_PATIENT]}
                            onChangeCb={(val) => setFieldValue(`emergencyContacts[${index}].${EMERGENCY_CONTACT_FIELDS.RELATIONSHIP_WITH_PATIENT}`, val)}
                            options={[
                                // { label: 'Self', value: 'self' },
                                { label: 'Spouse', value: 'spouse' },
                                { label: 'Parent', value: 'parent' },
                                { label: 'Child', value: 'childs' },
                                { label: 'Other', value: 'other' },
                            ]}
                            disabled={isFormSubmitted.emergencyContact}
                        />
                    </div>

                    <div className='flex-1 w-1/4 min-w-410 max-w-450'>
                        <Input
                            placeholder="Enter Last Name"
                            label="Last Name"
                            isRequired={true}
                            name={`emergencyContacts[${index}].${EMERGENCY_CONTACT_FIELDS.LASTNAME}`}
                            value={contact[EMERGENCY_CONTACT_FIELDS.LASTNAME]}
                            onChangeCb={(e) => setFieldValue(`emergencyContacts[${index}].${EMERGENCY_CONTACT_FIELDS.LASTNAME}`, e.target.value)}
                            onBlurCb={handleBlur}
                            disabled={isFormSubmitted.emergencyContact}
                        />
                    </div>

                    <div className='flex-1 w-1/4 min-w-410 max-w-450'>
                        <Input
                            placeholder="Enter First Name"
                            label="First Name"
                            isRequired={true}
                            name={`emergencyContacts[${index}].${EMERGENCY_CONTACT_FIELDS.FIRSTNAME}`}
                            value={contact[EMERGENCY_CONTACT_FIELDS.FIRSTNAME]}
                            onChangeCb={(e) => setFieldValue(`emergencyContacts[${index}].${EMERGENCY_CONTACT_FIELDS.FIRSTNAME}`, e.target.value)}
                            onBlurCb={handleBlur}
                            disabled={isFormSubmitted.emergencyContact}
                        />
                    </div>

                    <div className='flex-1 w-1/4 min-w-410 max-w-450'>
                        <Input
                            type='text'
                            placeholder="Enter Number"
                            label="Mobile Number"
                            isRequired={true}
                            name={`emergencyContacts[${index}].${EMERGENCY_CONTACT_FIELDS.CONTACT_NUMBER}`}
                            value={contact[EMERGENCY_CONTACT_FIELDS.CONTACT_NUMBER]}
                            onChangeCb={(e) => setFieldValue(`emergencyContacts[${index}].${EMERGENCY_CONTACT_FIELDS.CONTACT_NUMBER}`, General.sanitizeNumbers(e.target.value))}
                            onBlurCb={handleBlur}
                            disabled={isFormSubmitted.emergencyContact}
                        />
                    </div>

                    <div className='flex-1 w-1/4 min-w-410 max-w-440'>
                        <Input
                            placeholder="Enter Email"
                            label="Email"
                            name={`emergencyContacts[${index}].${EMERGENCY_CONTACT_FIELDS.EMAIL}`}
                            value={contact[EMERGENCY_CONTACT_FIELDS.EMAIL]}
                            onChangeCb={(e) => setFieldValue(`emergencyContacts[${index}].${EMERGENCY_CONTACT_FIELDS.EMAIL}`, e.target.value)}
                            onBlurCb={handleBlur}
                            disabled={isFormSubmitted.emergencyContact}
                        />
                    </div>
                </div>
                {enableDelete ? <div className='flex justify-end'>
                    <Button variant={BUTTON_VARIANTS.OUTLINED}
                        customBtnClass="bg-red-50 text-red-500 border-red-500"
                        startIcon={{ icon: <Icons iconName="trashIcon" /> }} onClickCb={handleRemoveCb}>Delete</Button>
                </div> : null}
            </div>
        </>
    )
}

export default EmergencyContactCard
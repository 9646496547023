export const FORM_FIELDS_NAMES = {
	GROUP_NAME: 'groupName',
	WEBSITE: 'website',
	PLACEOFSERVICE: 'placeOfService',
	SPECIALITY_TYPE: 'specialityType',
	EMAIL: 'email',
	MLC: 'mlc',
	FAX: 'fax',
	INFORMATION: 'description',
	TIMEZONE: 'timezone',
	CONTACT_NUMBER: 'phone',
	CONTACT_PERSON: 'contactPerson',
	PHYSICAL_ADDRESS_1: 'physicalAddress1',
	PHYSICAL_ADDRESS_2: 'physicalAddress2',
	PHYSICAL_ADDRESS_CITY: 'physicalAddressCity',
	PHYSICAL_ADDRESS_STATE: 'physicalAddressState',
	PHYSICAL_ADDRESS_COUNTRY: 'physicalAddressCountry',
	PHYSICAL_ADDRESS_ZIP: 'physicalAddressZip',
	BILLING_ADDRESS_1: 'billingAddress1',
	BILLING_ADDRESS_2: 'billingAddress2',
	BILLING_ADDRESS_CITY: 'billingAddressCity',
	BILLING_ADDRESS_STATE: 'billingAddressState',
	BILLING_ADDRESS_COUNTRY: 'billingAddressCountry',
	BILLING_ADDRESS_ZIP: 'billingAddressZip',
	NUMBER: 'number',
	SPECIALITY: 'speciality',
	LOCATION_LOGO: 'logo',
	OFFICE_HOURS: 'workingHours',
	AVTAR:'avatar',
	PATH:"path"
};

import store from '../../store/store';

export const componentKey = 'ENCOUNTER_SECTION';

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setPatientEncounterData: (state, action) => {
            state.patientEncounterData = action.payload;
        },
        setPostEncounterData: (state, action) => {
            state.postEncounterData = action.payload;
        },
        setIsEditAppointmentModalOpen: (state, action) => {
            state.isEditAppointmentModalOpen = action.payload;
        },
        setIsEncounterSummaryOpen: (state, action) => {
            state.isEncounterSummaryOpen = action.payload;
        },
        setFormData: (state, action) => {
            state.formData = action.payload;
        },
        setEncounterDataById: (state, action) => {
            state.encounterDataById = action.payload;
        },
        setIsSignatureModalOpen: (state, action) => {
            state.isSignatureModalOpen = action.payload;
        },
        setIsSuccessPopUpModalOpen: (state, action) => {
            state.isSuccessPopUpModalOpen = action.payload;
        },
        setProviderLocations: (state, action) => {
            state.providerLocations = action.payload;
        },
        setIsPrintEncounterModalOpen: (state, action) => {
            state.isPrintEncounterModalOpen = action.payload;
        },
        setSelectedForm: (state, action) => {
            state.selectedForm = action.payload;
        },
        setEncounterFormDataById: (state, action) => {
           state.encounterFormDataById = {...state.encounterFormDataById, ...action.payload}
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload
        }

    },
    initialReducerState: {
        patientEncounterData: [],
        postEncounterData: {},
        isEditAppointmentModalOpen: false,
        isEncounterSummaryOpen: false,
        formData: {},
        encounterDataById: {},
        isSignatureModalOpen: false,
        isSuccessPopUpModalOpen: false,
        providerLocations: [],
        isPrintEncounterModalOpen: false,
        selectedForm: {},
        encounterFormDataById : {},
        selectedDate: ""  
    },
});

export const { setPatientEncounterData, setPostEncounterData, setIsEditAppointmentModalOpen, setIsEncounterSummaryOpen, setFormData, setEncounterDataById, setIsSignatureModalOpen, setIsSuccessPopUpModalOpen, setProviderLocations, setIsPrintEncounterModalOpen, setSelectedForm, setEncounterFormDataById, setSelectedDate } = actions;

import * as Yup from 'yup';
import General from '../utility/General';


export const getValidationSchema = (fields) => {
    const schemaFields = {};

    fields.forEach(({ fieldName, isRequired = false, isMinLength, isMaxLength, regexPattern, isEmail, isPassword, isDropdown = false, isMultiSelect = false, isStateValid = false }) => {
        let fieldSchema = Yup.string();

        if (isDropdown) {
            if(isStateValid){
                fieldSchema = Yup.object().shape({
                    label: Yup.string().when([], {
                        is: () => isRequired,
                        then: (schema) =>
                            schema.required(`${General.camelToPascalWithSpaces(fieldName)} is required`),
                        otherwise: (schema) => schema.notRequired(),
                    }),
                    value: Yup.string().when([], {
                        is: () => isRequired,
                        then: (schema) =>
                            schema.required(`${General.camelToPascalWithSpaces(fieldName)} value is required`),
                        otherwise: (schema) => schema.notRequired(),
                    }),
                });
            }else{
                fieldSchema = Yup.object().shape({
                    label: Yup.string().required(`${General.camelToPascalWithSpaces(fieldName)} is required`),
                    value: Yup.string().required(`${General.camelToPascalWithSpaces(fieldName)} value is required`),
                }); 
            }    
        } else if (isMultiSelect) {
            fieldSchema = Yup.array().of(Yup.string()).required(`${General.camelToPascalWithSpaces(fieldName)} is required`)
        } else if (isRequired) {
            fieldSchema = fieldSchema.required(`${General.camelToPascalWithSpaces(fieldName)} is required`);
        }

        if (isMinLength) {
            fieldSchema = fieldSchema.min(3, `${General.camelToPascalWithSpaces(fieldName)} must be at least 3 characters`);
        }

        if (isMaxLength) {
            fieldSchema = fieldSchema.max(isMaxLength, `${General.camelToPascalWithSpaces(fieldName)} must be at most ${isMaxLength} characters`);
        }

        if (isPassword) {
            fieldSchema = fieldSchema.matches(regexPattern, `${General.camelToPascalWithSpaces(fieldName)} must contain at least one uppercase letter, one lowercase letter, one number, and one special character`);
        }

        if (regexPattern) {
            fieldSchema = fieldSchema.matches(regexPattern, `${General.camelToPascalWithSpaces(fieldName)} is invalid`);
        }

        if (isEmail) {
			fieldSchema = fieldSchema
				.email(`${General.camelToPascalWithSpaces(fieldName)} must be a valid email`)
				.matches(
					/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
					`${General.camelToPascalWithSpaces(fieldName)} must be a valid email`
				);
		}

        schemaFields[fieldName] = fieldSchema;
    });

    return Yup.object().shape(schemaFields);
};
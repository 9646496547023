import React, { useEffect, useState } from 'react';
import SelectDropdown from '../../../../components/common-components/selectDropdown';
import SubCard from './SubCard';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import { FORM_FIELDS_NAMES, appointmentArray, appointmentTime, bufferTimes, cardContainerData, firstRow } from './constants';
import Input from '../../../../components/common-components/input/Input';
import DatePicker from '../../../../components/common-components/datePicker';
import { ErrorMessage, FieldArray } from 'formik'
import Label from '../../../../components/common-components/label/Label';
import Icons from '../../../../components/icons/Icons';
import IsAccessAllowed from '../../../Wrappers/IsAccessAllowed'
import Checkbox from '../../../../components/common-components/checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey as routerComponentKey } from '../../../../routes/RoutesSlice'
import { componentKey as dataLoaderComponentKey } from '../../../Wrappers/Dataloader/DataloaderSlice'
import { USER_ROLE_NAMES } from '../../../Wrappers/Constants';
import General from '../../../../libs/utility/General';
import { getAvailability, getProviderLocations } from './Availability/AvailabilitySaga';
import { componentKey as AvailabilityComponentKey, setSelectedProvider } from '../../AppointmentCard/Tabs/Availability/AvailabilitySlice'
import { MET_COLORS } from '../../../../libs/constant';

const AddAvailabilityModal = ({ values, setFieldValue, addSlotCb, addTimeSlotCb, handleDeleteCLickCb, handleTimeSlotDeleteCLickCb, handleBlockDaysDeleteCLickCb }) => {
	const [isDaySlotOpen, setIsDaySlotOpen] = useState([])
	const { isAdminUser, loggedInUser, loggedInProviderUser } = useSelector(state => state[routerComponentKey])
	const { providersList } = useSelector(state => state[dataLoaderComponentKey])
	const { providerLocationsData, isNewAvailabilityAdd, selectedProvider } = useSelector(state => state[AvailabilityComponentKey])
	const dispatch = useDispatch();

	useEffect(() => {
		if (!isAdminUser) {
			dispatch(setSelectedProvider({ label: loggedInUser.name, value: loggedInUser?.uuid }))
		}
	}, [isAdminUser, loggedInUser, dispatch])

	const handleClick = (index) => {
		setIsDaySlotOpen(prevState => {
			const newState = [...prevState]
			newState[index] = !newState[index];
			return newState;
		})
	}

	const getTimeSlotTabs = (array = [], selectedState, setSelectedVal) => {
		return array.map((item, index) => {
			return (
				<div
					key={item.id}
					className={`h-10 w-10 flex flex-wrap justify-center items-center cursor-pointer ${index !== array.length - 1 ? 'mr-1' : ''
						} ${selectedState === item.id ? 'bg-met-primary text-white' : 'bg-met-primary-bg text-rgba-1A1A1A66'
						}`}
					onClick={() => {
						setSelectedVal(item.id);
					}}
				>
					{item.label}
				</div>
			);
		});
	};

	const getDaySlots = (valuesPrefix = '', values, setFieldValue, index, weekIndex) => {
		return (
			<div className="pt-2 flex justify-center">
				<div className="flex-1 mr-2">
					<div className='p-4 my-2 border'>
						<div className='flex items-center justify-between'>
							<div className='flex items-center'>
								<div className='mr-4' onClick={() => handleClick(index)}>
									<Icons
										iconName="expandIcon"
										color="gray-500"
										rotateDeg={isDaySlotOpen[index] ? 180 : 0}
									/>
								</div>
								<SelectDropdown placeholder="Select"
									name={`daySlots[${index}].day`}
									value={values.daySlots[index].day}
									onChangeCb={(val) => { setFieldValue(`daySlots[${index}].day`, val) }}
									options={[{ label: 'Sunday', value: 'Sunday' }, { label: 'Monday', value: 'Monday' }, { label: 'Tuesday', value: 'Tuesday' }, { label: 'Wednesday', value: 'Wednesday' }, { label: 'Thursday', value: 'Thursday' }, { label: 'Friday', value: 'Friday' }, { label: 'Saturday', value: 'Saturday' }]}
								/>
							</div>
							{values.daySlots.length > 1 && <div onClick={() => handleDeleteCLickCb(index)}>
								<Icons iconName='closeIcon'></Icons>
							</div>}
						</div>
						{isDaySlotOpen[index] && <div className='mt-4 border-b-2'></div>}
						<div>
							{isDaySlotOpen[index] && getTimeSlotAndBlockDays('Monday', values, setFieldValue, index)}
						</div>
						<div>

						</div>
					</div>
				</div>
			</div>
		);
	};

	const getBlockDays = (valuesPrefix = '', values, setFieldValue, index) => {
		return (
			<div className="flex justify-between items-center my-4">
				<div className="flex-1 mr-4 min-w-[250px] max-w-[250px]">
					<DatePicker
						label='Day'
						placeholder='Select Date'
						isRequired={true}
						name={`blockDays[${index}].blockDaysDate`}
						value={values.blockDays[index].blockDaysDate}
						onChangeCb={(date) => {
							setFieldValue(`blockDays[${index}].blockDaysDate`, date)
						}} />
				</div>
				<div className='flex-1 min-w-[150px]'>
					<Checkbox
						checked={values.blockDays[index].fullDayBlock}
						onChangeCb={(val) => {
							setFieldValue(`blockDays[${index}].fullDayBlock`, val)
						}}
						label='Full Day Block'
						variant='sm'
					/>
				</div>
				{!values.blockDays[index].fullDayBlock ? <div className='flex items-center'>
					<div className="flex-1 mr-4">
						<DatePicker
							name={`blockDays[${index}].blockDaysStartTime`}
							placeholder='Select Time'
							label='Start Time'
							showTimeSelect
							showTimeSelectOnly
							isRequired={true}
							timeIntervals={30}
							timeCaption="Time"
							dateFormat="h:mm aa"
							value={values.blockDays[index].blockDaysStartTime}
							onChangeCb={(time) => {
								setFieldValue(`blockDays[${index}].blockDaysStartTime`, time)
								if (time && values.blockDays[index].blockDaysEndTime && new Date(time).toISOString() >= new Date(values.blockDays[index].blockDaysEndTime).toISOString()) {
									setFieldValue(`blockDays[${index}].blockDaysEndTime`, '')
								}
							}}
							iconName='scheduleIcon'
						/>
					</div>
					<div className="flex-1 mr-4">
						<DatePicker
							name={`blockDays[${index}].blockDaysEndTime`}
							placeholder='Choose Time'
							label='End Time'
							showTimeSelect
							showTimeSelectOnly
							timeIntervals={30}
							isRequired={true}
							minTime={values.blockDays[index].blockDaysStartTime ? new Date(values.blockDays[index].blockDaysStartTime).setMinutes(new Date(values.blockDays[index].blockDaysStartTime).getMinutes() + 30) : new Date().setHours(0, 0, 0, 0)}
							maxTime={new Date().setHours(23, 59)}
							timeCaption="Time"
							dateFormat="h:mm aa"
							value={values.blockDays[index].blockDaysEndTime}
							onChangeCb={(time) => {
								setFieldValue(`blockDays[${index}].blockDaysEndTime`, time)
							}}
							iconName='scheduleIcon'
						/>
					</div>
				</div> : null}
				<div onClick={() => handleBlockDaysDeleteCLickCb(index)}>
					<Icons iconName='trashIcon'></Icons>
				</div>

			</div>
		);
	};

	const getTimeSlotAndBlockDays = (valuesPrefix = '', values, setFieldValue, index) => {
		return (
			<div className="pt-2">
				{values.daySlots[index].slots.map((weekSlot, weekIndex) => (
					<div key={`slot ${index}`} className="flex column-gap-10px items-end p-2 relative">
						<div className="flex-1 mr-4 max-w-[200px]">
							<DatePicker
								name={`${'daySlots'}[${index}].slots[${weekIndex}].startTime`}
								placeholder='Select Time'
								label='Start Time'
								showTimeSelect
								showTimeSelectOnly
								isRequired={true}
								timeIntervals={30}
								timeCaption="Time"
								dateFormat="h:mm aa"
								value={values.daySlots[index].slots[weekIndex].startTime}
								onChangeCb={(time) => {
									setFieldValue(`daySlots[${index}].slots[${weekIndex}].startTime`, time)
									if (time && values.daySlots[index].slots[weekIndex].endTime && new Date(time).toISOString() >= new Date(values.daySlots[index].slots[weekIndex].endTime).toISOString()) {
										setFieldValue(`${'daySlots'}[${index}].slots[${weekIndex}].endTime`, '')
									}
								}}
								iconName='scheduleIcon'
							/>
						</div>
						<div className="flex-1 mr-4 max-w-[200px]">
							<DatePicker
								name={`${'daySlots'}[${index}].slots[${weekIndex}].endTime`}
								placeholder='Select Time'
								label='End Time'
								showTimeSelect
								showTimeSelectOnly
								timeIntervals={30}
								isRequired={true}
								timeCaption="Time"
								minTime={values.daySlots[index].slots[weekIndex].startTime ? new Date(values.daySlots[index].slots[weekIndex].startTime).setMinutes(new Date(values.daySlots[index].slots[weekIndex].startTime).getMinutes() + 30) : new Date().setHours(0, 0, 0, 0)}
								maxTime={new Date().setHours(23, 59)}
								dateFormat="h:mm aa"
								value={values.daySlots[index].slots[weekIndex].endTime}
								onChangeCb={(time) => {
									setFieldValue(`${'daySlots'}[${index}].slots[${weekIndex}].endTime`, time)
								}}
								iconName='scheduleIcon'
							/>
						</div>
						<div>
							<div className='flex-1 mr-4 mb-2'>
								<Checkbox
									checked={values.daySlots[index].slots[weekIndex].virtual}
									onChangeCb={(val) => {
										setFieldValue(`${'daySlots'}[${index}].slots[${weekIndex}].virtual`, val)
									}}
									label='Virtual'
									variant='sm'
								/>
							</div>
						</div>
						{!values.daySlots[index].slots[weekIndex].virtual ? <div className="flex-1 mr-4 min-w-[200px] max-w-[200px]">
							<SelectDropdown
								placeholder="Select Location"
								label="Location"
								toggleIcon={<Icons iconName="settingsWatchIcon" />}
								isRequired={true}
								name={`${'daySlots'}[${index}].slots[${weekIndex}].location`}
								value={values.daySlots[index].slots[weekIndex].location}
								onChangeCb={(val) => { setFieldValue(`${'daySlots'}[${index}].slots[${weekIndex}].location`, val) }}
								options={providerLocationsData?.map(location => ({ 'label': location.provider_locations.name, 'value': location.provider_locations.uuid }))}
							/>
						</div> : ''}
						{values.daySlots[index].slots.length > 1 && <div style={{ position: 'absolute', right: 0, marginRight: '10px' }} onClick={() => handleTimeSlotDeleteCLickCb(index, weekIndex)}>
							<Icons iconName='trashIcon' color={MET_COLORS.DARK_GRAY}> </Icons>
						</div>}
					</div>
				))}
				<div>
					<Button
						onClickCb={() => addTimeSlotCb(index)}
						variant={BUTTON_VARIANTS.TEXT}
						startIcon={{ icon: <Icons iconName="plusVectorIcon" color={MET_COLORS.PRIMARY} /> }}
						customBtnClass="text-met-primary bg-cyan-50 w-full m-2"
					>
						Add Time Slots</Button>
				</div>
			</div>
		);
	};

	const slotBookingComponent = (values, setFieldValue, index) => {
		return (
			<div className="flex border p-8 my-4 min-w-[900px]">
				<div className="flex-1 mr-[60px] max-w-[300px]">
					<SelectDropdown
						placeholder="Select"
						label="Booking Window"
						name={`bookingWindow`}
						isRequired={true}
						value={values[`bookingWindow`]}
						onChangeCb={(val) => { setFieldValue(`bookingWindow`, val) }}
						options={[{ label: '1 week', value: 1 }, { label: '2 weeks', value: 2 }, { label: '3 weeks', value: 3 }, { label: '4 weeks', value: 4 }, { label: '5 weeks', value: 5 }, { label: '6 weeks', value: 6 }]}
					/>
				</div>
				<div className="flex-1 max-w-[300px]">
					<SelectDropdown
						placeholder="Select "
						label="Time Zone"
						name={`timeZone`}
						value={values[`timeZone`]}
						onChangeCb={(val) => { setFieldValue(`timeZone`, val) }}
						options={[{ label: 'IST', value: 'IST' },
						{ label: 'PST', value: 'PST' },
						{ label: 'EST', value: 'EST' },
						{ label: 'CST', value: 'CST' },
						{ label: 'MST', value: 'MST' },
						{ label: 'AST', value: 'AST' },
						{ label: 'HST', value: 'HST' }]}
					/>
				</div>
			</div>
		)
	}

	const getCardIconsContainer = () => {
		return cardContainerData.map((item, index) => {
			return (
				<>
					{/* <FieldArray name="daySlots" key={"day-slot-cards-" + index}>
						{() => ( */}
					<div className="pt-6" key={item?.title}>
						<SubCard
							title={item.title}
							cardAction={
								item?.isButton ? (
									<Button
										onClickCb={() => addSlotCb(item.valuesPrefix)}
										variant={BUTTON_VARIANTS.TEXT}
										startIcon={{ icon: <Icons iconName="plusVectorIcon" color={MET_COLORS.PRIMARY} /> }}
										customBtnClass="text-met-primary"
									>
										{item?.actionName}
									</Button>
								) : null
							}
						>

							{item.valuesPrefix === "slotCreation" ? slotBookingComponent(values, setFieldValue, index) : null}
							{item.valuesPrefix === "daySlots" ?
								values.daySlots?.map((slot, index) => (
									<React.Fragment key={"day-slot-item-" + index}>
										{getDaySlots(item.valuesPrefix, values, setFieldValue, index)}
									</React.Fragment>
								))
								: null}
							{item.valuesPrefix === "blockDays" ?
								values?.blockDays && values.blockDays?.length ? <div className='border p-8'>
									{values.blockDays.map((slot, index) => (
										<React.Fragment key={"block-days-item-" + index}>
											{getBlockDays(item.valuesPrefix, values, setFieldValue, index)}
										</React.Fragment>
									))}
								</div> : ''

								: null}
						</SubCard>
					</div>
					{/* )}
					</FieldArray> */}
				</>
			);
		});
	};

	const getFirstRow = (values, setFieldValue) => {
		return firstRow.map((item, index) => {
			return (
				<div key={item.label} className={`${index !== firstRow.length - 1 ? 'mr-2' : ''} w-[345px]`}>
					<SelectDropdown name={item.name}
						placeholder={item.placeholder}
						label={item.label}
						value={isAdminUser ? (isNewAvailabilityAdd ? { label: selectedProvider?.label, value: selectedProvider.value } : values.providerId) : { label: selectedProvider?.label, value: selectedProvider?.value }}
						onChangeCb={(val) => {
							setFieldValue(item.name, val)
							dispatch(setSelectedProvider(val))
							if (val?.value) {
								dispatch(getProviderLocations({ providerId: val.value }))
								dispatch(getAvailability({ providerId: val.value, locationIds: {} }))
							}
						}}
						isRequired={true}
						options={isAdminUser ? General.addLableValuePair(providersList, 'firstName', 'uuid', 'lastName') : [selectedProvider]}
						disabled={!isAdminUser}
					/>
				</div>
			);
		});
	};

	const getAppointmentRow = (appointmentArray = [], values, setFieldValue) => {
		return appointmentArray.map((item) => {
			return (
				<>
					<div className="pt-6">
						<Label fontWeight='bold' fontSize={'md'} isRequired='true'>{item.label}</Label>
						<div className="flex">
							<div
								className="flex bg-white mt-4"
								style={{ boxShadow: '0px 0px 4px #00000029', borderRadius: '5px' }}
							>
								{getTimeSlotTabs(
									appointmentTime,
									item.isAppointment ? values[FORM_FIELDS_NAMES.NEW_APPOINTMENT] : values[FORM_FIELDS_NAMES.FOLLOW_UP_APPOINTMENT],
									item.isAppointment ? (val) => { setFieldValue(FORM_FIELDS_NAMES.NEW_APPOINTMENT, val) } : (val) => { setFieldValue(FORM_FIELDS_NAMES.FOLLOW_UP_APPOINTMENT, val) })
								}
							</div>
						</div>
						<div className='pt-2'>
							{item.isAppointment ? (
								<ErrorMessage name={FORM_FIELDS_NAMES.NEW_APPOINTMENT} component="div" className="error-text" />
							) : (
								<ErrorMessage name={FORM_FIELDS_NAMES.FOLLOW_UP_APPOINTMENT} component="div" className="error-text" />
							)}
						</div>
					</div>
				</>
			);
		});
	};

	return (
		<div className="max-h-[100%] overflow-y-auto scrollbar-h-2 px-2 pb-[10px] text-rgba-1a1a1acc">
			<div>
				<h1 className="text-met-primary pt-6">
					Please Note: Updating availability settings may take several minutes to update.
				</h1>
				<div className="pt-6 flex justify-between flex-wrap">{getFirstRow(values, setFieldValue)}</div>
			</div>
			<div className='flex flex-wrap'>
				<div className='pr-8'>
					{getCardIconsContainer()}
				</div>
				<div className='pl-8 pt-6'>
					<div className='pt-6'>
						<Label fontWeight='bold' fontSize={'lg'}>Availability Settings</Label>
					</div>
					{getAppointmentRow(appointmentArray, values, setFieldValue)}
					<div className="pt-6">
						<Label fontWeight='bold' fontSize={'md'} isRequired='true'>Minimum Scheduling Notice</Label>
						<div className='w-full pt-3'>
							<div className="flex flex-wrap">
								<div className="flex-1 mr-2">
									<Input
										placeholder="Type here"
										name={'minimumSchedulingThreshold'}
										value={values['minimumSchedulingThreshold']}
										onChangeCb={(e) => {
											setFieldValue('minimumSchedulingThreshold', e.target.value);
										}}
									/>
								</div>
								<div className="flex-2 mr-2">
									<SelectDropdown
										placeholder="Hours Away"
										name={`minimumSchedulingThresholdDescription`}
										value={values[`minimumSchedulingThresholdDescription`]}
										onChangeCb={(val) => { setFieldValue(`minimumSchedulingThresholdDescription`, val) }}
										options={[{ label: 'Hours Away', value: 'Hours Away' }, { label: 'Minute Away', value: 'Minute Away' }, { label: 'Day Away', value: 'Day Away' }]}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="pt-6">
						<Label fontWeight='bold' fontSize={'md'} isRequired='true'>{"Event Buffer (minutes)"}</Label>
						<div>
							<span className="text-xs text-met-light-grey">Ensure time between appointments</span>
						</div>
					</div>
					<div className="pt-2">
						<div className="flex">
							<div className="flex bg-white" style={{ boxShadow: '0px 0px 4px #00000029', borderRadius: '5px' }}>
								{getTimeSlotTabs(bufferTimes, values[FORM_FIELDS_NAMES.BUFFER_TIME], (val) => { setFieldValue(FORM_FIELDS_NAMES.BUFFER_TIME, val) })}
							</div>
						</div>
						<div>
							<ErrorMessage name={FORM_FIELDS_NAMES.BUFFER_TIME} component="div" className="error-text" />
						</div>
					</div>
				</div>
			</div>

		</div>

	);
};

export default AddAvailabilityModal;

import store from "../store/store"
export const componentKey = "APP_ROUTES"

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setLoggedInUser: (state, action) => {
            state.loggedInUser = action.payload
        },
        setLoggedInProviderUser: (state, action) => {
            state.loggedInProviderUser = action.payload
        },
        setIsAdminUser: (state, action) => {
            state.isAdminUser = action.payload
        },
    },
    initialReducerState: {
        loggedInUser: {},
        loggedInProviderUser: [],
        isAdminUser: false
    }
})

export const { setLoggedInUser, setLoggedInProviderUser, setIsAdminUser } = actions
export const SettingsPencilIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g id="Group_78661" data-name="Group 78661" transform="translate(1298 -13939)">
				<path
					id="Path_187021"
					data-name="Path 187021"
					d="M0,0H24V24H0Z"
					transform="translate(-1298 13939)"
					fill="none"
				/>
				<path
					id="Path_187022"
					data-name="Path 187022"
					d="M14.055,9.017l.92.92L5.919,18.993H5v-.92l9.056-9.056M17.653,3a1,1,0,0,0-.7.29L15.125,5.119l3.748,3.748L20.7,7.038a1,1,0,0,0,0-1.409L18.363,3.29a.982.982,0,0,0-.71-.29Zm-3.6,3.189L3,17.244v3.748H6.748L17.8,9.937,14.055,6.189Z"
					transform="translate(-1297.996 13939.004)"
					fill="#1b5984"
				/>
			</g>
		</svg>
	);
};

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { componentKey } from '../../containers/Wrappers/Dataloader/DataloaderSlice';


export const useShowForAllowedPermissionsOnly = () => {
    const { usersRolesAndResp } = useSelector((state) => state[componentKey]);

    return (name) => {
        if ((name in usersRolesAndResp === true && usersRolesAndResp[name]) || name in usersRolesAndResp === false) {
            return true;
        } else {
            return false
        }
    }
};

import store from '../../../../store/store';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { componentKey, setAllNotesData, setNotesData, setIsNotesModalOpen, setIsOpenDeleteStickyNotePopup } from './PatientNotesSlice';
import PatientDashboardDataService from '../../../../services/PatientDashboardDataService';
import { addNotifications } from '../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../components/common-components/toaster/MetToaster';

export const {
    getAllPatientNotesDetails,
    postPatientNotes,
    deletePatientNotes,
} = {
    getAllPatientNotesDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/NOTES',
            payload,
        };
    },
    postPatientNotes: (payload) => {
        return {
            type: 'POST/PATIENTDASHBOARD/NOTES',
            payload,
        };
    },
    deletePatientNotes: (payload) => {
        return {
            type: 'DELETE/PATIENTDASHBOARD/NOTES',
            payload,
        };
    },
};

function* getAllPatientNotesDetailsAsync(action) {
    try {
        const { patientId = '', page = '1', limit = '10' } = action.payload;
        const params = {
            patientId,
            page: page,
            limit: limit,
        };

        const response = yield PatientDashboardDataService.getAllPatientNotes(
            'notes',
            params
        );

        const { message, success, data } = response?.data || {};

        if (success) {
            yield put(setAllNotesData(data.allNotes));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* postPatientNotesAsync(action) {
    try {
        const { notesData } = action.payload;
        const response = yield PatientDashboardDataService.postPatientNotes('notes', notesData);
        const { message, status, data } = response?.data || {};
        if (status) {
            yield put(setIsNotesModalOpen(false));
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAllPatientNotesDetailsAsync, getAllPatientNotesDetails({ patientId: notesData.patientId }));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* deletePatientNotesDetailsAsync(action) {
    try {
        const { patientId = '', noteId = '' } = action.payload;

        const response = yield PatientDashboardDataService.deletePatientNotes('notes', noteId);

        const { message, status, data } = response?.data || {};

        if (status) {
            yield put(setIsOpenDeleteStickyNotePopup(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getAllPatientNotesDetailsAsync, getAllPatientNotesDetails({ patientId }));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getAllPatientNotesDetails().type, getAllPatientNotesDetailsAsync),
        takeLatest(postPatientNotes().type, postPatientNotesAsync),
        takeLatest(deletePatientNotes().type, deletePatientNotesDetailsAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);

import { all, call, put, takeLatest } from 'redux-saga/effects'
import { componentKey, setAllDepartmentsData, setAllProviderLocation, setIsOpenChangePassWordModal, setProviderInformationData, setProviderSepcialities } from './ProviderSlice'
import ProvidersDataService from '../../../../../services/ProvidersDataService'
import store from "../../../../../store/store"
import ProviderGroupDataService from '../../../../../services/ProviderGroupDataService'
import General from '../../../../../libs/utility/General'
import AuthDataService from '../../../../../services/AuthDataService'
import EncodeDecodeUtils from '../../../../../libs/utility/EncodeDecodeUtils'
import DepartmentDataService from '../../../../../services/DepartmentDataService'
import { addNotifications } from '../../../../../components/common-components/toaster/ToasterSlice'
import { TOASTER_VARIANT } from '../../../../../components/common-components/toaster/MetToaster'

export const getProvidersDetails = (payload) => {
    return {
        type: 'USER_PROFILE/GET_PROVIDER_DETAILS',
        payload
    }
}

export const saveProvidersDetails = (payload) => {
    return {
        type: 'USER_PROFILE/SAVE_PROVIDER_DETAILS',
        payload
    }
}

export const getAllProviderLocation = (payload) => {
    return {
        type: "USER_PROFILE/GET_PROVIDER_LOCATION",
        payload

    }
}

export const getProviderSepcialities = (payload) => {
    return {
        type: 'USER_PROFILE/PROVIDER_SPECIALITIES',
        payload
    }
}

export const saveUpdatedPassword = (payload) => {
    return {
        type: 'USER_PROFILE/CHANGE_PASSWORD',
        payload
    }
}

export const getAllDepartments = (payload) => {
    return {
        type: 'USER_PROFILE/GET_ALL_DEPARTMENTS',
        payload
    }
}

function* getProvidersDetailsAsync(action) {
    try {
        const response = yield ProvidersDataService.getProvidersDetails(action.payload)
        const { data } = response.data
        yield put(setProviderInformationData(data))
    } catch (error) {
        console.log('err: ', error)
    }
}

function* saveProviderDetailsAsync(action) {
    try {
        const { uuid, values } = action.payload;
        const response = yield ProvidersDataService.saveProvidersDetails(uuid, values)
        const { data, message, success } = response.data
        if (success === true) {
            yield put(setProviderInformationData(data));
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
            yield call(getProvidersDetailsAsync, saveProvidersDetails(uuid))

        }

    } catch (error) {
        console.log('err: ', error)
    }
}

function* getAllProviderLocationAsync(action) {
    try {
        const response = yield ProvidersDataService.getAllProviderLocation(action.payload)
        const { data } = response.data
        const { locations } = data || []
        const workLocations = General.addLableValuePair(locations, "name", "uuid")
        yield put(setAllProviderLocation(workLocations))
    } catch (error) {
        console.log('err: ', error)
    }
}

function* getProviderSpecialitiesAsync(action) {
    try {
        const response = yield ProviderGroupDataService.getProviderSepcialities(action.payload)
        const { data } = response.data
        const specialities = General.addLableValuePair(data, "name", "uuid")
        yield put(setProviderSepcialities(specialities))
    } catch (error) {
        console.log('err: ', error)
    }
}

function* saveUpdatedPasswordAsync(action) {
    try {
        const { oldPassword, newPassword, confirmNewPassword } = action.payload
        const payload = {
            oldPassword: EncodeDecodeUtils.encode(oldPassword),
            newPassword: EncodeDecodeUtils.encode(newPassword),
            confirmNewPassword: EncodeDecodeUtils.encode(confirmNewPassword),
        }

        const response = yield AuthDataService.changePassword(payload)
        const { status, message } = response.data

        if (status) {
            yield put(setIsOpenChangePassWordModal(false))
            yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
        }

    } catch (error) {
        console.log('err: ', error)
    }
}

function* getAllDepartmentsAsync() {

    try {
        const response = yield DepartmentDataService.getAllDepartments()
        const { data, status } = response.data
        if (status) {
            const department = General.addLableValuePair(data, "departmentName", "uuid")
            yield put(setAllDepartmentsData(department))
        }
    } catch (error) {
        console.log('err: ', error)
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getProvidersDetails().type, getProvidersDetailsAsync),
        takeLatest(saveProvidersDetails().type, saveProviderDetailsAsync),
        takeLatest(getAllProviderLocation().type, getAllProviderLocationAsync),
        takeLatest(getProviderSepcialities().type, getProviderSpecialitiesAsync),
        takeLatest(saveUpdatedPassword().type, saveUpdatedPasswordAsync),
        takeLatest(getAllDepartments().type, getAllDepartmentsAsync),
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function MetTextEditor({ placeholder, value, onChange }) {
    const handleChange = (html) => {
        onChange({ value: html });
    };

    return (
        <div className='met-text-editor box-shadow-1'>
            <ReactQuill
                onChange={handleChange}
                value={value}
                modules={MetTextEditor.modules}
                formats={MetTextEditor.formats}
                placeholder={placeholder}
                style={{}}
            />
        </div>
    );
}

MetTextEditor.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        matchVisual: false,
    }
};

MetTextEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];

export default MetTextEditor;

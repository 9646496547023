import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import NavigationBorderedTabs from '../../../../components/common-components/navigationTabBorderVariant'
import Vitals from './Vitals'
import VitalsGraph from './VitalsGraph'
import VitalsDevice from './VitalsDevice'

const PatientVitals = () => {
    const navigate = useNavigate()
    const routes = [
        { path: "/", component: <Vitals /> },
        { path: "/vital-graph", component: <VitalsGraph /> },
        { path: "/vital-device", component: <VitalsDevice /> }
    ]

    return (
        <>
            <NavigationBorderedTabs
                tabs={[
                    { label: "Vitals", onClickCb: () => navigate(''), path: "/vitals" },
                    { label: "Vitals Graph", onClickCb: () => navigate('vital-graph'), path: "/vital-graph" },
                    { label: "Device Vitals", onClickCb: () => navigate('vital-device'), path: "/vital-device" },
                ]}
                customClasses="absolute z-10"
            />
            <div className='overflow-auto relative met-scrollbar h-[90%]'>
                <Routes>
                    {routes.map((route, index) => {
                        return <Route key={"patient-documents-route" + index} path={route.path} element={route.component} />
                    })}
                </Routes>
            </div>
        </>
    )
}

export default PatientVitals
import AppDataService from './AppDataService';

const COMMON_BASE = 'encounter';

export default class EncounterDataService {
	static async getReadyForBilling(params) {
		return await AppDataService.get(`${COMMON_BASE}/readybill`, { params });
	}

	static async getPatientEncounter(patientId, params) {
		return await AppDataService.get(`${COMMON_BASE}/patient/${patientId}`, { params });
	}

	static async getEncounterById(encounterId) {
		return await AppDataService.get(`${COMMON_BASE}/${encounterId}`);
	}

	static async postPatientEncounter(data) {
		return await AppDataService.post(`${COMMON_BASE}`, data);
	}

	static async updatePatientDemographics(scheduleId, data) {
		return await AppDataService.patch(`${COMMON_BASE}/demographic/${scheduleId}`, data);
	}

	static async getUnsignedVisitData(params) {
		return await AppDataService.get(`${COMMON_BASE}`, params);
	}

	static async fetchReadyForBillingByID(encounterId) {
		return await AppDataService.get(`${COMMON_BASE}/readybill/${encounterId}`,);
	}

	static async getEncounterIdByScheduleId(payload) {
		return await AppDataService.post(`${COMMON_BASE}/encounterid`, payload);
	}
    static async getEncounterIdByUUID(encounterId) {
		return await AppDataService.get(`${COMMON_BASE}/${encounterId}`,);
	}
}

import React, { useEffect, useMemo, useRef } from 'react'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import ReactToPrint from 'react-to-print'
import FaceSheetCard from '../Dashboard/FaceSheet/FaceSheetCard'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Table from '../../../../containers/Table/Index'
import General from '../../../../libs/utility/General'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey } from '../../../../routes/RoutesSlice'
import { getPrintConfig } from '../../../../containers/SettingsTab/MyAccountCard/Tabs/PrintConfiguration/PrintConfigSaga'
import Logo from '../../../../components/common-components/logo/Logo'
import { componentKey as PatientLabResultComponentKey } from '../Lab/PatientLabSlice';

const columns = [
    {
        field: 'number',
        label: <Label>No</Label>,
        renderLogic: (row) => {
            return row.name ? (
                <Heading type={HEADING.H1} customClasses='text-met-primary' fontSize={'md'}>
                    {row.index}
                </Heading>
            ) : null;

        },
    },
    {
        field: 'name',
        label: <Label>Name</Label>,
        renderLogic: (row) => {
            return (
                <>
                    <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                        {row.name}
                    </Heading>
                </>
            );
        },

    },
    {
        field: 'result',
        label: <Label>Result</Label>,
        renderLogic: (row) => {
            return (
                <>
                    <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                        {row.result}
                    </Heading>
                </>
            );
        },

    },
    {
        field: 'reference',
        label: <Label>Reference</Label>,
        renderLogic: (row) => {
            return (
                <>
                    <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                        {row.max}
                    </Heading>
                </>
            );
        },

    },
    {
        field: 'interpretation',
        label: <Label>Interpretation</Label>,
        renderLogic: (row) => {
            return (
                <>
                    <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                        {row.interpretation.label}
                    </Heading>
                </>
            );
        },

    },
]






const LabResultPrintModal = ({ open, close, data }) => {


    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPrintConfig())

    }, [dispatch])


    const { loggedInUser } = useSelector(state => state[componentKey])
    const { selectedRowData } = useSelector(state => state[PatientLabResultComponentKey])

    const { labs } = selectedRowData || {}


    const patientInfoData = useMemo(() => {
        const { patientInfo } = data
        const { firstName, lastName, uuid, aadhar, dob, gender } = patientInfo
        const { Address } = patientInfo
        const { addressLine1, addressLine2, city, state, country, zip } = Address?.[0] || {};
        const formattedAddress = [addressLine1, addressLine2, city, state, country, zip].join(", ");
        const formattedDate = General.getformattedDate(dob)

        return {
            NAME: `${firstName} ${lastName}`,
            'PATIENT ID': `#${uuid.substring(0, 5)}`,
            Aadhar: aadhar,
            DOB: formattedDate,
            GENDER: gender,
            ADDRESS: formattedAddress,
        }
    }, [data])


    const providerInfoData = useMemo(() => {
        const { firstName, lastName,
        } = loggedInUser

        return {
            REVIEWER: `${firstName} ${lastName}`,

        }
    }, [loggedInUser])

    const labInfoData = useMemo(() => {
        const labInfo = data?.labInfo ?? {};
        const { labs } = selectedRowData || {}

        return {
            'REFERENCE ID': labInfo.uuid ? `#${labInfo.uuid.substring(0, 5)}` : (labs?.uuid ? `#${labs.uuid.substring(0, 5)}` : 'No Data'),
            'LAB NAME': labInfo.name ? labInfo?.name : (labs?.name ? `${labs?.name}` : 'No Data'),
        };
    }, [data, selectedRowData]);

    const labNoteData = useMemo(() => {
        const { labNote } = data
        return {
            'Patient Instructions': `${labNote ?? 'No Data'}`,
        };
    }, [data]);

    const printingRef = useRef()
    return (
        <>

            <ModalComponent close={close} open={open} customClasses='w-[65%]' footerButton={
                <ReactToPrint
                    trigger={() => <button className="text-met-primary bg-met-secondary border border-met-primary p-2 px-4 rounded-md">Print</button>}
                    content={() => printingRef.current}
                />
            }>
                <div ref={printingRef} className='p-4'>
                    <div className='pt-1 pb-4 flex justify-between' >
                        <div className='flex justify-center' >
                            <div className='text-met-primary text-3xl font-extrabold'>
                                <Logo customLogoClassess="max-h-[60px]" color="met-primary" />
                            </div>
                        </div>
                        {data?.labInfo?.name || labs?.name ? (
                            <div className='flex flex-col text-right'>
                                <div>{Object.keys(selectedRowData).length ? labs?.name : data.labInfo.name}</div>
                                <div className='fs-14 text-gray-500'>
                                    {Object.keys(selectedRowData).length ? `${labs?.address} ${labs?.city} ${labs?.state} ${labs?.country} ${labs?.zip}` : `${data?.labInfo?.address} ${data?.labInfo?.city} ${data?.labInfo?.state} ${data?.labInfo?.country} ${data?.labInfo?.zip}`}
                                </div>
                                <div className='fs-14 text-gray-500'>{Object.keys(selectedRowData).length ? labs?.fax : data?.labInfo?.fax}</div>
                                <div className='fs-14 text-gray-500'> {Object.keys(selectedRowData).length ? labs?.contactNumber : data?.labInfo?.contactNumber}</div>
                            </div>

                        ) : ("")}

                    </div>
                    <div className='h-[32px] bg-met-secondary flex justify-center items-center text-met-primary font-semibold'>
                        LAB RESULT
                    </div>
                    <div className='flex column-gap-16 mt-4'>
                        <FaceSheetCard paddingTopClass="p-0" customClassName="w-1/3 min-w-[250px]">
                            <div>LAB INFORMATION</div>
                            <div className='flex flex-wrap column-gap-10px'>
                                {
                                    Object.keys(labInfoData)?.map((key) => (
                                        <div className='flex flex-row column-gap-20px pt-2' key={key} >
                                            <div className='fs-14'>{key}</div>
                                            <div className='fs-14 text-gray-500'>{labInfoData[key]}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </FaceSheetCard>
                        <FaceSheetCard paddingTopClass="p-0" customClassName="w-1/3 min-w-[250px]">
                            <div>PROVIDER INFORMATION</div>
                            <div className='flex flex-wrap column-gap-10px'>
                                {
                                    Object.keys(providerInfoData)?.map((key) => (
                                        <div className='flex flex-row column-gap-20px pt-2' key={key} >
                                            <div className='fs-14'>{key}</div>
                                            <div className='fs-14 text-gray-500'>{providerInfoData[key]}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </FaceSheetCard>
                        <FaceSheetCard paddingTopClass="p-0" customClassName="p-4 w-1/3 min-w-[250px]">
                            <div>PATIENT INFORMATION</div>
                            <div className='flex flex-wrap column-gap-10px'>
                                {
                                    Object.keys(patientInfoData)?.map((key) => (
                                        <div className='flex flex-row column-gap-20px pt-2' key={key} >
                                            <div className='fs-14'>{key}</div>
                                            <div className='fs-14 text-gray-500'>{patientInfoData[key]}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </FaceSheetCard>

                    </div>

                    <div className='pt-3'>
                        <div>
                            TEST RESULT
                        </div>
                        <div className='pt-2'>
                            <Table coloumns={columns} rows={data.patientLabTest} isPagination={false} />
                        </div>

                    </div>

                    <div className='pt-3 pb-3'>
                        <FaceSheetCard paddingTopClass="p-0" customClassName="p-[12px]">
                            <div className='underline'>ADDITIONAL NOTE</div>
                            {
                                Object.keys(labNoteData)?.map((key) => (
                                    <div className='flex flex-row column-gap-20px pt-2' key={key} >
                                        <div className='fs-14'>{key}</div>
                                        <div className='fs-14 text-gray-500'>{labNoteData[key]}</div>
                                    </div>
                                ))
                            }

                        </FaceSheetCard>
                    </div>
                </div>
            </ModalComponent></>
    )
}

export default LabResultPrintModal
import React from 'react'
import ModalComponent from '../../../components/common-components/modal/ModalComponent'
import Button from '../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants'
import Icons from '../../../components/icons/Icons'
import SelectDropdown from '../../../components/common-components/selectDropdown'
import DatePicker from '../../../components/common-components/datePicker'
import Input from '../../../components/common-components/input/Input'
import { getValidationSchema } from '../../../libs/formsUtils'
import { FORM_FIELDS_NAMES, GARDIAN_FIELDS_NAMES } from './Constants'
import { VALIDATION_REGEX } from '../../../libs/constant'
import { Form, Formik } from 'formik'
import { componentKey, setCreatePatientData, setIsAadharProvided } from '../../../pages/Patients/AddPatient/AddPatientSlice'
import { useDispatch, useSelector } from 'react-redux'
import General from '../../../libs/utility/General'

const fields = [
    { fieldName: GARDIAN_FIELDS_NAMES.GUARDIAN_NAME, isRequired: true,isMinLength: true, isMaxLength: 50, regexPattern: VALIDATION_REGEX.NAME_REGEX },
    { fieldName: GARDIAN_FIELDS_NAMES.RELATIONSHIP, isDropdown: true, },
    { fieldName: GARDIAN_FIELDS_NAMES.GUARDIAN_AADHAR_NUMBER, isRequired: true, regexPattern: VALIDATION_REGEX.AADHAR_REGEX },
    { fieldName: GARDIAN_FIELDS_NAMES.PATIENT_DOB, isRequired: false },

];

const validationSchema = getValidationSchema(fields)

function GuardianDetailsModal({ open, close }) {
    const { fetchedPatientDetails, createPatientData } = useSelector(state => state[componentKey])

    const initialValues = {
        [GARDIAN_FIELDS_NAMES.GUARDIAN_NAME]: fetchedPatientDetails?.guardianName || createPatientData?.guardianName,
        [GARDIAN_FIELDS_NAMES.RELATIONSHIP]: { label: fetchedPatientDetails?.relationWithPatient || createPatientData?.relationWithPatient, value: fetchedPatientDetails?.relationWithPatient || createPatientData?.relationWithPatient },
        [GARDIAN_FIELDS_NAMES.GUARDIAN_AADHAR_NUMBER]: General.formateAadhar((fetchedPatientDetails?.guardianAadhar || createPatientData?.guardianAadhar || "").toString()),
        [GARDIAN_FIELDS_NAMES.PATIENT_DOB]: fetchedPatientDetails?.patientsBirthTimestamp || createPatientData?.patientsBirthTimestamp,
        [FORM_FIELDS_NAMES.IS_PATIENT_AADHAR]: fetchedPatientDetails?.isPatientsAadhar || createPatientData?.isPatientsAadhar,
        // ...fetchedPatientDetails
    }

    const dispatch = useDispatch()

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => {
                values.relationWithPatient = values.relationWithPatient?.value;
                const adharNum = values[GARDIAN_FIELDS_NAMES.GUARDIAN_AADHAR_NUMBER].replace(/-/g, '')
                dispatch(setCreatePatientData({ ...values, [GARDIAN_FIELDS_NAMES.GUARDIAN_AADHAR_NUMBER]: Number(adharNum), [FORM_FIELDS_NAMES.IS_PATIENT_AADHAR]: false }))
                dispatch(setIsAadharProvided(false))
                close();
            }}
        >
            {({ submitForm, isSubmitting, handleReset, values, handleSubmit, setFieldValue, handleBlur, ...props }) => {
                return <Form onSubmit={handleSubmit} >
                    <div>
                        <ModalComponent
                            open={open}
                            title={'Guardian Details'}
                            footerButton={<Button type='submit' onClickCb={() => {
                                setFieldValue(FORM_FIELDS_NAMES.IS_PATIENT_AADHAR, values[FORM_FIELDS_NAMES.IS_PATIENT_AADHAR] === true ? false : true);
                            }} variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary" >Save</Button>}
                            icon={<Icons iconName="closeIcon" />}
                            close={close}
                            customClasses="w-[800px]">
                            <div className='p-2'>
                                <div className='flex column-gap-10px items-center pt-6'>
                                    <div className='flex-1'>
                                        <Input
                                            label="Guardian Name"
                                            placeholder="Enter Name"
                                            isRequired={true}
                                            name={GARDIAN_FIELDS_NAMES.GUARDIAN_NAME}
                                            value={values[GARDIAN_FIELDS_NAMES.GUARDIAN_NAME]}
                                            onChangeCb={(e) => { setFieldValue(GARDIAN_FIELDS_NAMES.GUARDIAN_NAME, e.target.value) }}
                                            onBlurCb={handleBlur}
                                        />
                                    </div>
                                    <div className='flex-1'>
                                        <SelectDropdown
                                            placeholder='Select'
                                            label='Relationship with Patient'
                                            isRequired={true}
                                            name={GARDIAN_FIELDS_NAMES.RELATIONSHIP}
                                            value={values[GARDIAN_FIELDS_NAMES.RELATIONSHIP]}
                                            onChangeCb={(val) => { setFieldValue(GARDIAN_FIELDS_NAMES.RELATIONSHIP, val) }}
                                            options={[
                                                { label: 'Spouse', value: 'spouse' },
                                                { label: 'Parent', value: 'parent' },
                                                { label: 'Child', value: 'childs' },
                                                { label: 'Other', value: 'other' },
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div className='flex column-gap-10px items-center pt-6'>
                                    <div className='flex-1'>
                                        <div>
                                            <Input
                                                label='Guardian Aadhar Number'
                                                placeholder="Enter Number"
                                                isRequired={true}
                                                name={GARDIAN_FIELDS_NAMES.GUARDIAN_AADHAR_NUMBER}
                                                value={values[GARDIAN_FIELDS_NAMES.GUARDIAN_AADHAR_NUMBER]}
                                                onChangeCb={(e) => {
                                                    const formattedValue = General.formateAadhar(e.target.value)
                                                    setFieldValue(GARDIAN_FIELDS_NAMES.GUARDIAN_AADHAR_NUMBER, formattedValue)
                                                }}
                                                onBlurCb={handleBlur}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex-1'>
                                        <DatePicker
                                            iconName='scheduleIcon'
                                            label="Patient Birth Time"
                                            placeholder='Choose Time'
                                            value={values[GARDIAN_FIELDS_NAMES.PATIENT_DOB]}
                                            name={GARDIAN_FIELDS_NAMES.PATIENT_DOB}
                                            onChangeCb={(date) => {
                                                setFieldValue(GARDIAN_FIELDS_NAMES.PATIENT_DOB, date)
                                            }}
                                            showTimeSelect
                                            showTimeSelectOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </ModalComponent>
                    </div>
                </Form>
            }}
        </Formik >
    )
}

export default GuardianDetailsModal
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'

import ChatMessageItem from './ChatMessageItem'
import EmojiPicker from 'emoji-picker-react'

import { componentKey, setInCallMessages } from '../../ZoomIntegrationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useChat } from '../../hooks/useChat'
import Icons from '../../components/icon/Icon'
import { VEC_ICON_NAME } from '../../components/icon/constants'
import Heading from '../../components/heading/Heading'

const InCallMessages = () => {

    const dispatch = useDispatch()
    const [chatDraft, setChatDraft] = useState(null);
    const [emojiPicker, setEmojiPicker] = useState(false)

    const { inCallMessages, client } = useSelector(state => state[componentKey])

    const chatClient = client.getChatClient();

    const chatWrapRef = useRef();
    const fileInputRef = useRef()

    const {
        records,
        receivers,
        privilege,
        chatUser,
        isHostOrManager,
        setChatUserId,
        sendMessage,
        sendFile,
        resendFile,
        downloadFile
    } = useChat(client, chatClient);

    useLayoutEffect(() => {
        if (chatWrapRef.current) {
            chatWrapRef.current.scrollTo(0, chatWrapRef.current.scrollHeight);
        }
    }, [records]);

    const onChatPressEnter = useCallback((event) => {
        if (event.key == "Enter") {
            event.preventDefault();
            if (chatUser && chatDraft) {
                sendMessage(chatDraft);
                setChatDraft('');
            }
        }
    }, [sendMessage, chatUser, chatDraft]);

    const onChatInput = useCallback((event) => {
        setChatDraft(event.target.value);
    }, []);


    const onFileChange = useCallback((event) => {
        const target = event.target;
        const { files } = target;
        if (files && files?.length > 0) {
            sendFile(files[0]);
        }
        target.value = '';
    }, [sendFile]);

    const onSendFileClick = useCallback(() => {
        fileInputRef.current?.click();
    }, []);

    const handleSendMessage = useCallback(() => {
        if (chatUser && chatDraft) {
            sendMessage(chatDraft);
            setChatDraft('');
        }
    }, [sendMessage, chatUser, chatDraft]);

    return (
        <div className='in-call-messages' style={inCallMessages ? { display: "block" } : { display: "none" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }} className='border-bottom'>
                <Heading>In Call Messages</Heading>
                <div style={{ display: "flex", columnGap: "10px" }} className='cursor'>
                    <Icons iconName={VEC_ICON_NAME.CLOSE_ICON} onClickCb={() => dispatch(setInCallMessages(!inCallMessages))} />
                </div>
            </div>
            <div style={{ marginTop: "15px", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <div className='message-container'>
                    <div className="chat-message-wrap" ref={chatWrapRef}>
                        <div className='message-info'>
                            Messages can be seen only people in the call and are deleted when the call ends
                        </div>
                        {records.map((record) => (
                            <ChatMessageItem
                                record={record}
                                currentUserId={client.getSessionInfo().userId}
                                setChatUser={setChatUserId}
                                key={record.timestamp}
                                resendFile={resendFile}
                                downloadFile={downloadFile}
                            />
                        ))}
                    </div>
                </div>
                <div className='emoji-picker'>
                    {emojiPicker && <EmojiPicker searchDisabled={true} onEmojiClick={(event) => setChatDraft((preMsg) => `${preMsg || ""}${event.emoji}`)} />}
                </div>
                <div style={{ marginBottom: "40px", display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: "20px" }} className='cursor'>
                    {chatClient.isFileTransferEnabled() &&
                        <Icons style={{ width: "30px" }} onClickCb={onSendFileClick} iconName={VEC_ICON_NAME.SHARE_DOCUMENT_ICON} />
                    }
                    <input
                        type="file"
                        ref={fileInputRef}
                        className="chat-send-file-input"
                        onChange={onFileChange}
                        accept={chatClient.getFileTransferSetting().typeLimit}
                    />
                    <div>
                        <Icons iconName={VEC_ICON_NAME.SEND_EMOJI_ICON} onClickCb={() => setEmojiPicker(!emojiPicker)} />
                    </div>
                    <input
                        className='zoom-send-message-input'
                        onChange={onChatInput}
                        onKeyPress={onChatPressEnter}
                        value={chatDraft || ""}
                        style={{ borderRadius: "20px", background: "#1A1A1A05", padding: "5px 8px", width: "100%", border: "1px solid #d8d0d0" }}
                        placeholder='Type your message…'
                    />
                    <div>
                        <Icons style={{ width: "30px" }} iconName={VEC_ICON_NAME.SEND_MESSAGE_ICON} onClickCb={handleSendMessage} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InCallMessages

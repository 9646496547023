import React from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import PastMedicalHistory from './PastMedicalHistory'
import PastSurgicalHistory from './PastSurgicalHistory'
import FamilyHistory from './FamilyHistory'
import NavigationBorderedTabs from '../../../../components/common-components/navigationTabBorderVariant'

const PatientHistory = () => {
    const navigate = useNavigate()
    const routes = [
        { path: "/past-medical", component: <PastMedicalHistory /> },
        { path: "/past-surgical", component: <PastSurgicalHistory /> },
        // { path: "/family", component: <FamilyHistory /> },
        { path: "/*", component: <Navigate to={"past-medical"} /> },
    ]
    return (
        <>
            <NavigationBorderedTabs tabs={[
                { label: "Past Medical History", onClickCb: () => navigate('past-medical'), path: "/past-medical" },
                { label: "Past Surgical History", onClickCb: () => navigate('past-surgical'), path: "/past-surgical" },
                // { label: "Family History", onClickCb: () => navigate('family'), path: "/family" }
            ]} customClasses="absolute z-10" />
            <div className='overflow-auto relative met-scrollbar h-[90%]'>
                <Routes>
                    {routes.map((route, index) => {
                        return <Route key={"patient-history-route" + index} path={route.path} element={route.component} />
                    })}
                </Routes>
            </div>
        </>
    )
}

export default PatientHistory
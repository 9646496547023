import React from 'react';
import Icons from '../../icons/Icons';

function Logo({ variant, color = "", customClass = "" }) {
    return (
        <div className={`${color} ${customClass}`}>
            {variant === 'primary' ? (
                <Icons iconName="metslPrimaryLogo" />
            ) : (
                <Icons iconName="metslSecondaryLogo" />
            )}
        </div>
    );
}

export default Logo;
import React from 'react';
import Button from '../button/Button';
import { BUTTON_VARIANTS } from '../button/Constants';
import ModalComponent from '../modal/ModalComponent';

function AlertPopUp({ cancelBtnProps = {}, confirmationButtonProps = {}, open, close, title = "Do you want to leave without saving your changes?" }) {
	return (
		<div>
			<ModalComponent
				open={open}
				title={title}
				footerButton={
					<div className='flex column-gap-10px'>
						<Button variant={BUTTON_VARIANTS.OUTLINED} onClickCb={cancelBtnProps.onCancelCb} customBtnClass="text-met-primary" type='submit'>Cancel</Button>
						<Button variant={BUTTON_VARIANTS.CONTAINED} onClickCb={confirmationButtonProps.onConfirmCb} customBtnClass="text-met-primary min-w-[80px] justify-center" type='submit'>Yes</Button>
					</div>}
				close={close}
				customClasses="w-[600px]"
				showIcon={false}
			>
			</ModalComponent>
		</div>
	);
}

export default AlertPopUp;

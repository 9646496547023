import React from "react";
import ModalComponent from "../modal/ModalComponent";
import { BUTTON_VARIANTS } from "../button/Constants";
import Button from "../button/Button";
import Icons from "../../icons/Icons";

const DeletePopup = ({
    open,
    close,
    okButton = "",
    cancelButton = "",
    onClickOk,
    onClickCancel,
    alertMessage = "Are you sure you want to delete this? This action cannot be undone.",
    icon = <Icons iconName="deleteIcon" width={48} height={48} />,
    confirmationMessage = "Delete",
    showcloseIcon = false
}) => (
    <ModalComponent customBodyClasses="w-[550px]" close={close} cutomFooterBtnClass="flex" showIcon={showcloseIcon} open={open}
        footerButton={
            <div className="flex column-gap-10px">
                {cancelButton &&
                    <Button onClickCb={onClickCancel} variant={BUTTON_VARIANTS.OUTLINED}>
                        {cancelButton}
                    </Button>
                }
                {okButton &&
                    <Button onClickCb={onClickOk}>
                        {okButton}
                    </Button>
                }

            </div>

        }>
        <div className="flex">
            <div >
                <div>
                    {icon}
                </div>
            </div>
            <div className="pl-2">
                {confirmationMessage && (
                    <div className="fs-20">{confirmationMessage}</div>

                )}
                <div className=" text-gray-500  mt-2 fs-18">{alertMessage}</div>

            </div>

        </div>


    </ModalComponent>
);

export default DeletePopup;

import React, { useEffect } from 'react'
import ModalComponent from '../../../components/common-components/modal/ModalComponent'
import Button from '../../../components/common-components/button/Button'
import SearchBar from '../../../components/common-components/searchInput/SearchBar';
import { componentKey as CreateSuperbillComponentKey, setIsCreateSuperbillModalOpen } from '../ReadyForBilling/genrateSuperBill/GenrateSuperBillSlice';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../components/icons/Icons';
import Label from '../../../components/common-components/label/Label';
import { getFormattedPatientAgeDob } from '../../../libs/patientUtils';
import { HEADING } from '../../../components/common-components/heading/Constants';
import Heading from '../../../components/common-components/heading/Heading';
import Avatar from '../../../components/common-components/avatar';
import MetPopOver from '../../../components/common-components/popoverComponent/MetPopOver';
import Table from '../../../containers/Table/Index';
import { componentKey, setPaginationState } from '../../Patients/PatientsGridList/PatientGridListSlice';
import useDebounced from '../../../libs/customHooks/useDebounced';
import { useNavigate } from 'react-router-dom';
import { getPatientGridList } from '../../Patients/PatientsGridList/PatientGridListSaga';
import General from '../../../libs/utility/General';

function CreateSuperbillModal({ open, close }) {
    const dispatch = useDispatch()
    const debounced = useDebounced(1000);
    const navigate = useNavigate();
    const { isCreateSuperbillModalOpen } = useSelector((state) => state[CreateSuperbillComponentKey]);
    const { patientGridListState, paginationState } = useSelector((state) => state[componentKey]);

    useEffect(() => {
        dispatch(getPatientGridList(paginationState));
    }, [
        dispatch,
        paginationState.limit,
        paginationState.pageNumber,
        paginationState.search,
        paginationState.status,
        paginationState.sortBy,
        paginationState.orderBy,
    ]);
    const coloumns = [
        {
            field: 'PatientName',
            label: <Label fontWeight="bold">Patient Name</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <div className="flex items-start column-gap-10px">
                            <Avatar
                                customClass="w-[40px] h-[40px] rounded-full"
                                url={row?.profilePicture}
                                name={`${row.firstName} ${row.lastName}`}
                            />
                            <div>
                                <Heading
                                    onClickCb={() => {
                                        navigate(`/billing/genrate-super-bill/${row.uuid}`)
                                        dispatch(setIsCreateSuperbillModalOpen(false))

                                    }}
                                    fontWeight="bold"
                                    type={HEADING.H1}
                                    color="met-primary"
                                    fontSize={'md'}
                                    customClasses="cursor-pointer"
                                >
                                    {`${row.firstName} ${row.lastName}`}
                                </Heading>
                                {/* <Label color="gray-500">{row.subLabel}</Label> */}
                            </div>
                        </div>
                    </>
                );
            },
            sort: 'firstName',
        },
        {
            field: 'dob',
            label: <Label fontWeight="bold">Date of Birth</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                            {getFormattedPatientAgeDob(row.dob)}
                        </Heading>
                        <Label color="gray-500">{`${General.camelToPascalWithSpaces(row.gender)}`}</Label>
                    </>
                );
            },
            sort: 'dob',
        },
        {
            field: 'Address',
            label: <Label fontWeight="bold">Address</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        {row.Address.map((address, index) => (
                            <div key={index}>
                                <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                                    <MetPopOver>
                                        {`${address?.addressLine1 ? address?.addressLine1 + ', ' : ''}${address?.addressLine2 ? address?.addressLine2 + ', ' : ''
                                            }${address?.city ? address?.city + ', ' : ''}${address?.state ? address?.state + ', ' : ''
                                            } ${address?.country ? address?.country + ', ' : ''} ${address.zip}`}
                                    </MetPopOver>
                                </Heading>
                            </div>
                        ))}
                    </>
                );
            },
        },
        {
            field: 'contactDetails',
            label: <Label fontWeight="bold">Contact Details</Label>,
            renderLogic: (row) => {
                return (
                    <>
                        {row.Address.map((address, index) => (
                            <div key={index}>
                                <Heading type={HEADING.H1} color="gray-500" fontSize={'md'}>
                                    <Label color="gray-500">{row.mobileNumber}</Label>
                                    <div>
                                        <Label color="met-primary">{row.email}</Label>
                                    </div>
                                </Heading>
                            </div>
                        ))}
                    </>
                );
            },
            sort: 'mobileNumber',
        },
    ];
    return (
        <div>
            <ModalComponent
                open={isCreateSuperbillModalOpen}
                title={"Patient Search"}
                icon={<Icons iconName="closeIcon" />}
                close={close}
                customClasses="w-[1177px]"
            >
                <div className='p-2'>

                    <div className='flex column-gap-10px items-center pt-6 '>
                        <div className='flex-1'>
                            <div className='flex'><Label fontWeight='bold'>Search By</Label></div>
                            <div className='pt-2'>
                                <SearchBar
                                    placeholder="Search By Patient Name, DOB (DD/MM/YYYY)"
                                    onChangeCb={(e) => {
                                        debounced(() => {
                                            dispatch(setPaginationState({ search: e.target.value }));
                                        });
                                    }}
                                />
                            </div>
                            <div className='pt-2'>
                                <Table
                                    coloumns={coloumns}
                                    rows={patientGridListState}
                                    paginationProps={{
                                        isPagination: true,
                                        totalCount: paginationState.total,
                                        limit: paginationState.limit,
                                        onPageChange: (page) => {
                                            dispatch(setPaginationState({ pageNumber: page }));
                                        },
                                    }}
                                    sorting={{
                                        isSortable: true,
                                        onSortChangeCb: (sortKey) => {
                                            dispatch(setPaginationState({ orderBy: paginationState.orderBy === 'asc' ? 'desc' : 'asc' }));
                                            dispatch(setPaginationState({ sortBy: sortKey }));
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalComponent>
        </div>
    )
}

export default CreateSuperbillModal
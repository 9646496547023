import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Documents from './Documents'
import StickyNotes from './StickyNotes'
import NavigationBorderedTabs from '../../../../components/common-components/navigationTabBorderVariant'
import ShowForAllowedPermissionsOnly from '../../../../containers/Wrappers/ShowForAllowedPermissionsOnly'
import { USER_PERMISSIONS } from '../../../../libs/UserPermissionsConstants'
import { useShowForAllowedPermissionsOnly } from '../../../../libs/customHooks/useShowForAllowedPermissionsOnly'

const PatientDocuments = () => {
    const navigate = useNavigate()
    const routes = [
        { path: "/", component: <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.VIEW_PATIENTS_DOCUMENTS}><Documents /></ShowForAllowedPermissionsOnly> },
        { path: "/notes", component: <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.VIEW_CLINICAL_NOTES}> <StickyNotes /> </ShowForAllowedPermissionsOnly> },
    ]

    const showForAllowPermission = useShowForAllowedPermissionsOnly()

    return (
        <>
            <NavigationBorderedTabs
                tabs={[
                    ...(showForAllowPermission(USER_PERMISSIONS.VIEW_PATIENTS_DOCUMENTS) ? [{ label: "Documents", onClickCb: () => navigate(''), path: "/documents" }] : []),
                    ...(showForAllowPermission(USER_PERMISSIONS.VIEW_CLINICAL_NOTES) ? [{ label: "Sticky Notes", onClickCb: () => navigate('notes'), path: "/notes" }] : []),
                ]}
                customClasses="absolute z-10"
            />

            <div className='overflow-auto relative met-scrollbar h-[90%]'>
                <Routes>
                    {routes.map((route, index) => {
                        return <Route key={"patient-documents-route" + index} path={route.path} element={route.component} />
                    })}
                </Routes>
            </div>
        </>
    )
}

export default PatientDocuments
export const FORM_FIELDS_NAMES = {
	ALLERGY_TYPE: 'allergyType',
	DRUG: 'drug',
	FOOD: 'food',
	ENVIRONMENT: 'environmental',
	ALLERGY_NAME: 'name',
	REACTION: 'reaction',
	SEVERITY: 'severity',
	ONSET_DATE: 'onsetDate',
	NOTE: 'note',
	STATUS: 'status',
};

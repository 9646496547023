import React from 'react'
import ProviderAdminTable from './ProviderAdminTable'
import ProviderAdminCards from './ProviderAdminCards'
import CardContainer from '../../../components/common-components/Cards/Container/CardContainer'

function ProviderAdminWrapper() {
    return (
        <div>
            <div className="container-card-body rounded overflow-hidden m-0">
                <ProviderAdminCards />
            </div>
            <CardContainer>
                <ProviderAdminTable />
            </CardContainer>
        </div>
    )
}

export default ProviderAdminWrapper

export const SettingTemplatesIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
      <g id="Group_96325" data-name="Group 96325" transform="translate(-48 -132)">
        <rect id="Rectangle_36519" data-name="Rectangle 36519" width="70" height="70" rx="5" transform="translate(48 132)" fill="rgba(204,236,255,0.5)" />
        <g id="Layer_x0020_1" transform="translate(3.713 144.188)">
          <g id="_274121576" transform="translate(62)">
            <g id="Group_90526" data-name="Group 90526">
              <g id="_216583016" transform="translate(2.673 2.584)">
                <path id="Path_205920" data-name="Path 205920" d="M123.368,72.041H92.535A.514.514,0,0,1,92,71.507V29.535A.514.514,0,0,1,92.535,29h23.437a.575.575,0,0,1,.535.535v6.594h6.862a.575.575,0,0,1,.535.535V71.507A.575.575,0,0,1,123.368,72.041Zm-30.3-1.069h29.764V37.2h-6.862a.575.575,0,0,1-.535-.535V30.069H93.069Z" transform="translate(-92 -29)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216585824" transform="translate(26.11 2.615)">
                <path id="Path_205921" data-name="Path 205921" d="M362.931,37.511h-7.4a.575.575,0,0,1-.535-.535V29.847a.592.592,0,0,1,.356-.446.42.42,0,0,1,.535.089l7.4,7.129c.178.089.178.356.089.535A.592.592,0,0,1,362.931,37.511Zm-6.862-1.069h5.525l-5.525-5.258Z" transform="translate(-355 -29.343)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216585800" transform="translate(7.218 12.832)">
                <path id="Path_205922" data-name="Path 205922" d="M165.189,148.277H143.535a.514.514,0,0,1-.535-.535v-3.208a.514.514,0,0,1,.535-.535h21.654a.473.473,0,0,1,.535.535v3.208A.473.473,0,0,1,165.189,148.277Zm-21.12-1.069h20.585v-2.139H144.069Z" transform="translate(-143 -144)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216585656" transform="translate(7.307 18.892)">
                <path id="Path_205923" data-name="Path 205923" d="M149.614,216.277h-5.079a.575.575,0,0,1-.535-.535v-3.208a.575.575,0,0,1,.535-.535h5.079a.575.575,0,0,1,.535.535v3.208A.575.575,0,0,1,149.614,216.277Zm-4.545-1.069h4.01v-2.139h-4.01Z" transform="translate(-144 -212)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216585848" transform="translate(7.307 25.397)">
                <path id="Path_205924" data-name="Path 205924" d="M149.614,289.188h-5.079a.514.514,0,0,1-.535-.535v-3.119a.514.514,0,0,1,.535-.535h5.079a.514.514,0,0,1,.535.535v3.119A.514.514,0,0,1,149.614,289.188Zm-4.545-1.069h4.01v-2.05h-4.01Z" transform="translate(-144 -285)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216585872" transform="translate(7.307 31.813)">
                <path id="Path_205925" data-name="Path 205925" d="M149.614,361.277h-5.079a.575.575,0,0,1-.535-.535v-3.208a.575.575,0,0,1,.535-.535h5.079a.575.575,0,0,1,.535.535v3.208A.575.575,0,0,1,149.614,361.277Zm-4.545-1.069h4.01v-2.139h-4.01Z" transform="translate(-144 -357)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216586136" transform="translate(7.307 38.318)">
                <path id="Path_205926" data-name="Path 205926" d="M149.614,434.188h-5.079a.575.575,0,0,1-.535-.535v-3.119a.514.514,0,0,1,.535-.535h5.079a.514.514,0,0,1,.535.535v3.119A.575.575,0,0,1,149.614,434.188Zm-4.545-1.069h4.01v-2.05h-4.01Z" transform="translate(-144 -430)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216586040" transform="translate(16.218 20.496)">
                <path id="Path_205927" data-name="Path 205927" d="M257.1,231.069H244.535a.535.535,0,0,1,0-1.069H257.1a.535.535,0,0,1,0,1.069Z" transform="translate(-244 -230)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216586712" transform="translate(16.218 26.912)">
                <path id="Path_205928" data-name="Path 205928" d="M257.1,303.069H244.535a.473.473,0,0,1-.535-.535.514.514,0,0,1,.535-.535H257.1a.514.514,0,0,1,.535.535A.473.473,0,0,1,257.1,303.069Z" transform="translate(-244 -302)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216586424" transform="translate(16.218 33.417)">
                <path id="Path_205929" data-name="Path 205929" d="M257.1,376.069H244.535a.535.535,0,0,1,0-1.069H257.1a.535.535,0,0,1,0,1.069Z" transform="translate(-244 -375)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216586856" transform="translate(16.218 39.833)">
                <path id="Path_205930" data-name="Path 205930" d="M257.1,448.069H244.535a.473.473,0,0,1-.535-.535.514.514,0,0,1,.535-.535H257.1a.514.514,0,0,1,.535.535A.473.473,0,0,1,257.1,448.069Z" transform="translate(-244 -447)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216586808" transform="translate(8.911 5.169)">
                <path id="Path_205931" data-name="Path 205931" d="M173.5,59.069H162.535a.514.514,0,0,1-.535-.535.575.575,0,0,1,.535-.535H173.5a.575.575,0,0,1,.535.535A.514.514,0,0,1,173.5,59.069Z" transform="translate(-162 -58)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216586664" transform="translate(8.911 7.04)">
                <path id="Path_205932" data-name="Path 205932" d="M173.5,80.069H162.535a.514.514,0,0,1-.535-.535.575.575,0,0,1,.535-.535H173.5a.575.575,0,0,1,.535.535A.514.514,0,0,1,173.5,80.069Z" transform="translate(-162 -79)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
              <g id="_216586016">
                <path id="Path_205933" data-name="Path 205933" d="M65.208,43.041H62.535A.514.514,0,0,1,62,42.507V.535A.575.575,0,0,1,62.535,0H86.06A.575.575,0,0,1,86.6.535V3.119a.514.514,0,0,1-.535.535H65.743V42.507A.514.514,0,0,1,65.208,43.041Zm-2.139-1.069h1.6V3.119a.514.514,0,0,1,.535-.535H85.526V1.069H63.069Z" transform="translate(-62)" fill="#1b5984" stroke="#1b5984" strokeWidth="0.6" fillRule="evenodd" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
import { all, call, put, takeLatest } from 'redux-saga/effects';
import DeviceTestDataService from '../../../../../services/DeviceTestDataService';
import store from '../../../../../store/store';
import { componentKey, setDeviceNamesList, setDeviceTestList, setDeleteVitalsTestModalOpen, setIsVitalsTestsModalOpen } from './VitalsTestsSlice';
import General from '../../../../../libs/utility/General';
import { addNotifications } from '../../../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../../../components/common-components/toaster/MetToaster';

export const { getDeviceName, getDeviceTestList, deleteVitalsTests,createDeviceTest,patchVitalsTests } = {
	getDeviceName: (payload) => {
		return {
			type: 'VITALS_TESTS/GET_DEVICE_LIST',
			payload,
		};
	},
	getDeviceTestList: (payload) => {
		return {
			type: 'VITALS_TESTS/GET_DEVICE_TEST',
			payload,
		};
	},
	deleteVitalsTests: (payload) => {
		return {
			type: 'VITALS_TESTS/DELETE_DEVICE_TEST',
			payload,
		};
	},
	createDeviceTest: (payload) => {
        return {
            type: 'VITALS_TESTS/POST_DEVICE_TEST',
            payload
        }
    },
	patchVitalsTests: (payload) => {
        return {
            type: 'VITALS_TESTS/PATCH_DEVICE_TEST',
            payload
        }
    },
};

function* getDeviceNameAsync(action) {
	try {
		const response = yield DeviceTestDataService.getDeviceNameList(action.payload);
		const { device, status } = response.data;
		if (status) {
			const deviceList = General.addLableValuePair(device, 'deviceName', 'uuid');
			yield put(setDeviceNamesList(deviceList));
		}
	} catch (error) {
		console.log('error', error);
	}
}

function* getDeviceTestListAsync(action) {
	try {
		const response = yield DeviceTestDataService.getDeviceTest(action.payload);
		const { data, status, message } = response.data;

		if (status) {
			const deviceTestList = General.addLableValuePair(data, 'testName', 'uuid');
			yield put(setDeviceTestList(deviceTestList));
		}
	} catch (error) {
		console.log('error: ', error);
	}
}

function* createDeviceTestAsync(action) {
    try {
        const { vitalsTestsData } = action.payload
        const response = yield DeviceTestDataService.addDeviceTest(vitalsTestsData)

        const { status, message } = response.data
		if(status){
		yield put(setIsVitalsTestsModalOpen(false));
		yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
			yield put(getDeviceTestList({ deviceName: vitalsTestsData?.deviceName}));
		}
    } catch (error) {
        yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
        console.log('err: ', error)
    }
}

function* deleteVitalsTestsAsync(action) {
	const { deviceName, uuid } = action.payload;

	try {
		const response = yield DeviceTestDataService.deleteDeviceTest(uuid);
		const { message, status } = response?.data || {};

		if (status ) {
			yield put(setDeleteVitalsTestModalOpen(false));
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
			yield put(getDeviceTestList({deviceName:deviceName}));
			
		}
	} catch (error) {
		console.log('error', error);
	}
}

function* patchVitalsTestsAsync(action){
try {
	
	const {vitalsTestId, vitalsTestsData } = action.payload;
	
	const response = yield DeviceTestDataService.editDeviceTest(vitalsTestId,vitalsTestsData)
	const { message, status } = response?.data || {};
	
	if(status){
		yield put(setIsVitalsTestsModalOpen(false));
		yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
		yield put(getDeviceTestList({ deviceName: vitalsTestsData?.deviceName }));
	}
} catch (error) {
	console.log(error)
}
}

function* rootSaga() {
	yield all([
		takeLatest(getDeviceName().type, getDeviceNameAsync),
		takeLatest(getDeviceTestList().type, getDeviceTestListAsync),
		takeLatest(deleteVitalsTests().type, deleteVitalsTestsAsync),
		takeLatest(createDeviceTest().type, createDeviceTestAsync),
		takeLatest(patchVitalsTests().type, patchVitalsTestsAsync),
	]);
}

store.sagaManager.addSaga(componentKey, rootSaga, deleteVitalsTests, createDeviceTest);

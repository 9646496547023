import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../../../components/common-components/label/Label'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Heading from '../../../../components/common-components/heading/Heading'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Table from '../../../../containers/Table/Index'
import MedicationModal from './MedicationModal'
import { MET_COLORS } from '../../../../libs/constant'
import ButtonDropdown from '../../../../components/common-components/buttonDropdown'
import { componentKey as PatientMedicationsComponentKey, setIsNewMedicationAdd, setMedicationId, setMedicationData, setIsMedicationsModalOpen, setIsOpenMedicationDeletePopup } from './PatientMedicationsSlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import { deletePatientMedications, getAllPatientMedicationsDetails } from './PatientMedicationsSaga';
import General from '../../../../libs/utility/General';
import MetPopOver from '../../../../components/common-components/popoverComponent/MetPopOver';
import DeletePopup from '../../../../components/common-components/deletePopup/DeletePopup';

function Medications() {
    const dispatch = useDispatch();
    const { allCurrentMedicationsData, allPastMedicationsData, isMedicationModalOpen, isOpenMedicationDeletePopup, currentMedicationId } = useSelector((state) => state[PatientMedicationsComponentKey]);

    const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);
    const role = localStorage.getItem('role');

    const handleMedicationModal = () => {
        dispatch(setIsNewMedicationAdd(true));
        dispatch(setMedicationId(''));
        dispatch(setMedicationData({}));
        dispatch(setIsMedicationsModalOpen(true));
    }

    const handleEditClick = (medication) => {
        dispatch(setIsNewMedicationAdd(false));
        dispatch(setIsMedicationsModalOpen(true));
        dispatch(setMedicationId(medication.uuid));
        dispatch(setMedicationData(medication))
    };

    const handleDeleteClick = (medicationId) => {
        dispatch((deletePatientMedications({ patientId: currentPatientId, medicationId })));
    };

    const handleAddToCurrentMedication = (medication) => {
        dispatch(setIsNewMedicationAdd(true));
        dispatch(setMedicationId(''));
        dispatch(setMedicationData({
            medicineName: medication.medicineName,
            sig: medication.sig,
            duration: medication.duration,
        }));
        dispatch(setIsMedicationsModalOpen(true));
    }

    useEffect(() => {
        if (!currentPatientId) return;
        dispatch(getAllPatientMedicationsDetails({ patientId: currentPatientId, type: 'current', page: 1, limit: 10 }));
        dispatch(getAllPatientMedicationsDetails({ patientId: currentPatientId, type: 'past', page: 1, limit: 10 }));
    }, [currentPatientId]);

    const coloumns = [
        {
            field: "number",
            label: <Label fontWeight="font-bold">No</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.index + 1}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "medication",
            label: <Label fontWeight="font-bold">Medication</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.medicineName}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "sig",
            label: <Label fontWeight="font-bold">Sig</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.sig || '-'}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "startDate",
            label: <Label fontWeight="font-bold">Start Date</Label>,
            renderLogic: (row) => {
                const formattedstartDate = General.getformattedDate(row.startDate)
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{formattedstartDate}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "duration",
            label: <Label fontWeight="font-bold">Duration</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.duration || '-'}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "note",
            label: <Label fontWeight="font-bold">Note</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <MetPopOver >{row.note}</MetPopOver>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "endDate",
            label: <Label fontWeight="font-bold">Est. End Date</Label>,
            renderLogic: (row) => {
                const formattedEndDate = General.getformattedDate(row.endDate)
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{formattedEndDate}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "action",
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.action}</Heading>
                        </div>
                        <ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[{ label: "Edit", value: null, onChangeCb: () => handleEditClick(row) }, {
                            label: "Delete", value: null, onChangeCb: () => {
                                dispatch(setIsOpenMedicationDeletePopup(true))
                                dispatch(setMedicationId(row.uuid))
                            }
                        }]} selectCb={(option) => {
                            option?.onChangeCb();
                        }} />
                    </div>
                </>
            },
        },
    ]

    const pastMedicationTable = [
        {
            field: "number",
            label: <Label fontWeight="font-bold">No</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.index + 1}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "medication",
            label: <Label fontWeight="font-bold">Medication</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.medicineName}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "sig",
            label: <Label fontWeight="font-bold">Sig</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.sig}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "completedDate",
            label: <Label fontWeight="font-bold">Completed Date</Label>,
            renderLogic: (row) => {
                const formattedstartDate = General.getformattedDate(row.endDate)
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{formattedstartDate}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "duration",
            label: <Label fontWeight="font-bold">Duration</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.duration}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "note",
            label: <Label fontWeight="font-bold">Note</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <MetPopOver>{row.note}</MetPopOver>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "action",
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.action}</Heading>
                        </div>
                        <ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[{ label: "Add to current medication", value: null, onChangeCb: () => handleAddToCurrentMedication(row) }]} selectCb={(option) => {
                            option?.onChangeCb();
                        }} />
                    </div>
                </>
            },
        },
    ]

    return (
        <>
            <div>
                <div className='flex items-center justify-between p-2 px-4'>
                    <Heading fontSize="xl" fontWeight="bold" type={HEADING.H3}>Current Medications</Heading>
                    {role !=="Biller" && <div className='flex'>
                        <Button onClickCb={handleMedicationModal} variant={BUTTON_VARIANTS.OUTLINED} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} >Add Medication</Button>
                    </div>
                    }
                </div>
                <div className='p-4'>
                    <Table isPagination={false} coloumns={coloumns} rows={allCurrentMedicationsData} sorting={
                        {
                            isSortable: false,
                            onSortChangeCb: () => { }
                        }} />
                </div>
            </div>
            <div>
                <div className='flex items-center justify-between p-4'>
                    <Heading fontSize="xl" fontWeight="bold" type={HEADING.H3}>Past Medications</Heading>
                </div>
                <div className='p-4'>
                    <Table isPagination={false} coloumns={pastMedicationTable} rows={allPastMedicationsData} sorting={
                        {
                            isSortable: false,
                            onSortChangeCb: () => { }
                        }} />
                </div>
            </div>
            {isMedicationModalOpen ? <MedicationModal open={isMedicationModalOpen} close={() => dispatch(setIsMedicationsModalOpen(false))} /> : null}
            {isOpenMedicationDeletePopup ? <DeletePopup
                open={isOpenMedicationDeletePopup}
                okButton="Delete"
                cancelButton='Cancel'
                confirmationMessage="Delete"
                onClickCancel={() => { dispatch(setIsOpenMedicationDeletePopup(false)) }}
                onClickOk={() => handleDeleteClick(currentMedicationId)}
            /> : null}
        </>
    )
}

export default Medications
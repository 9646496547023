import { all, put, takeLatest } from "redux-saga/effects"
import PaymentDataService from "../../../../services/PaymentDataService"
import store from "../../../../store/store"
import { componentKey, setPatientBillingList } from "./PatientBillingslice"
import { setPaymentPaginationState } from "../../../Billing/Payments/PatientPaymentSlice"

export const { getAllPatientPayment } = {
    getAllPatientPayment : (payload) => {
        return {
            type : 'BILLING/GET_PATIENT_PAYMENT',
            payload
        }
    }
}

function* getAllPatientPaymentAsync(action) {
    try{
        const {patientId,params} = action.payload
        const response = yield PaymentDataService.getAllPatientPaymentList(patientId,params)
        const { data, message, status } = response.data
        if (status) {
          const {totalPages, totalRecords } = data || {}
            yield put(setPatientBillingList(data))
            yield put(setPaymentPaginationState({ total: totalRecords }))
        }
    }
    catch (error) {
        console.log('err: ', error)
      }
}

function* rootSaga() {
    yield all([
        takeLatest(getAllPatientPayment().type,getAllPatientPaymentAsync)
    ])
}

store.sagaManager.addSaga(componentKey, rootSaga)
import store from '../../../../../store/store';

export const componentKey = 'PROVIDERS_GROUP_ROLES_RESPONSIBILITIES';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setPaginationState: (state, action) => {
			state.paginationState = { ...state.paginationState, ...action.payload };
		},
		setAllRolesAndResp: (state, action) => {
			state.allRolesAndResp = action.payload;
		},
		setAllRoles: (state, action) => {
			state.roles = action.payload;
		},
		setIsNewAddRoleModalOpen: (state, action) => {
			state.isNewAddRoleModalOpen = action.payload;
		},
		setIsNewRoleAdd: (state, action) => {
			state.isNewRoleAdd = action.payload;
		},
        setIsEditedRoleData: (state, action) =>{
            state.editedRoleData = action.payload
        },
		setDeleteRoleModalOpen:(state,action) =>{
			state.deleteRoleModalOpen = action.payload
		},
		setCurrentUUID:(state, action) => {
			state.currentUUID = action.payload
		},
		setIsViewRoleModalOpen: (state,action) => {
			state.isViewRoleModalOpen = action.payload
		},
		setGetRoleById: (state,action) =>{
			state.roleByID = action.payload
		},
		setIsRoleDuplicate:(state,action) => {
			state.roleDuplicate =action.payload
		}
	},
	initialReducerState: {
		paginationState: {
			pageNumber: 1,
			limit: 10,
			totalRecords: 0,
		},
        editedRoleData:{},
		allRolesAndResp: [],
		roles: [],
		isNewAddRoleModalOpen: false,
		isNewRoleAdd: false,
		deleteRoleModalOpen: false,
		currentUUID:"",
		isViewRoleModalOpen: false,
		roleByID:{},
		roleDuplicate: false
	},
});

export const { setPaginationState, setAllRolesAndResp, setAllRoles, setIsNewAddRoleModalOpen, setIsNewRoleAdd,setIsEditedRoleData, setDeleteRoleModalOpen,setCurrentUUID, setIsViewRoleModalOpen,setGetRoleById,setIsRoleDuplicate } = actions;

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Icons from '../../../../components/icons/Icons'
import Label from '../../../../components/common-components/label/Label'
import RadioButton from '../../../../components/common-components/radioButton/RadioButton'
import DatePicker from '../../../../components/common-components/datePicker'
import TextArea from '../../../../components/common-components/textArea/TextArea'
import ImagingReportTable from './ImagingReportTable'
import { IMAGE_REPORT_FORM_FIELDS_NAMES } from './Constants'
import { Form, Formik } from 'formik'
import * as Yup from 'yup';
import { componentKey as PatientImagingResultComponentKey } from '../Imaging/PatientImagingSlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import {
  getAllImagingCentreDetails,
  postPatientImagingResult, updatePatientImagingResult,
} from '../Imaging/PatientImagingSaga';
import moment from 'moment';
import SelectDropdown from '../../../../components/common-components/selectDropdown';
import General from '../../../../libs/utility/General';
import Input from '../../../../components/common-components/input/Input';

const fields = [
  // { fieldName: IMAGE_REPORT_FORM_FIELDS_NAMES.IMAGE_ORDER },
  { fieldName: IMAGE_REPORT_FORM_FIELDS_NAMES.COLLECTION_DATE },
  { fieldName: IMAGE_REPORT_FORM_FIELDS_NAMES.COLLECTION_TIME },
  { fieldName: IMAGE_REPORT_FORM_FIELDS_NAMES.REVIEWER },
  { fieldName: IMAGE_REPORT_FORM_FIELDS_NAMES.IMAGING_CENTER },
  { fieldName: IMAGE_REPORT_FORM_FIELDS_NAMES.NOTE },
  // { fieldName: IMAGE_REPORT_FORM_FIELDS_NAMES.UPLOAD_OPTION, isRequired: true }
]

const validationSchema = Yup.object().shape({
  patientImageStudy: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('name is required'),
      reportDate: Yup.string().required('report date is required'),
      interpretation: Yup.object().shape({
        label: Yup.string().required('interpretation is required'),
        value: Yup.string().required('interpretation is required')
      }),
    })
  ),
  collectionDate: Yup.date().required('collection date is required'),
  reviewer: Yup.string().required('reviewer is required'),
  collectionTime: Yup.string().required('collection time is required'),
  imageCenterId: Yup.object().shape({
    label: Yup.string().required('imaging center is required'),
    value: Yup.string().required('imaging center is required')
  }),
});


function ImagingReportModal({ open, close }) {

  const dispatch = useDispatch();
  const { isImagingResultModalOpen, imagingResultData, currentImagingResultId, isNewImagingResultAdd, allImagingCentreData } = useSelector(
    (state) => state[PatientImagingResultComponentKey]
  );

  const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);

  const addImageReport = (values, setFieldValue) => {
    setFieldValue('patientImageStudy', [
      ...values.patientImageStudy,
      { name: '', reportDate: '', reportTime: '', interpretation: '', uploadResult: '' }
    ]);
  };

  return (
    <Formik
      initialValues={{
        patientImageStudy: imagingResultData?.patientImageStudy ? imagingResultData?.patientImageStudy.map((test) => {
          const obj = { name: test.name, reportDate: test.reportDate, reportTime: test.reportTime, interpretation: test.interpretation ? { label: test.interpretation, value: test.interpretation } : '', uploadResult: test.uploadResult }
          return { ...test, ...obj }
        }) : [{ name: '', reportDate: '', reportTime: '', interpretation: '', uploadResult: '' }],
        // [IMAGE_REPORT_FORM_FIELDS_NAMES.IMAGE_ORDER]: '',
        [IMAGE_REPORT_FORM_FIELDS_NAMES.COLLECTION_DATE]: imagingResultData?.collectionDate,
        [IMAGE_REPORT_FORM_FIELDS_NAMES.COLLECTION_TIME]: imagingResultData?.collectionTime,
        [IMAGE_REPORT_FORM_FIELDS_NAMES.REVIEWER]: imagingResultData?.reviewer,
        [IMAGE_REPORT_FORM_FIELDS_NAMES.IMAGING_CENTER]: imagingResultData?.imageCenterId ? { label: imagingResultData?.imageCenterId || '', value: imagingResultData?.imageCenterId || '' } : '',
        [IMAGE_REPORT_FORM_FIELDS_NAMES.NOTE]: imagingResultData?.note,
        // [IMAGE_REPORT_FORM_FIELDS_NAMES.UPLOAD_OPTION]: ''
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(formVals) => {
        const data = {
          ...formVals,
          imageCenterId: formVals.imageCenterId?.value,
          patientImageStudy: formVals.patientImageStudy.map((test) => ({ ...test, interpretation: test.interpretation?.value }))
        }
        try {
          if (isNewImagingResultAdd) {
            dispatch(postPatientImagingResult({ patientId: currentPatientId, imagingresultData: data }));
          } else {
            dispatch(
              updatePatientImagingResult({
                patientId: currentPatientId,
                imagingresultId: currentImagingResultId,
                imagingresultData: data,
              })
            );
          }
        } catch (error) {
          console.log(error);
        }
      }}
    >
      {({ submitForm, isSubmitting, handleSubmit, values, setFieldValue, handleBlur }) => {
        return <Form onSubmit={handleSubmit} >
          <ModalComponent
            open={open}
            title={isNewImagingResultAdd ? 'Add Image Report' : 'Update Image Report'}
            footerButton={<div className='flex pt-2 column-gap-10px'>
              <Button type='submit' variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="text-met-primary">Save Image Report</Button>
            </div>}
            icon={<Icons iconName="closeIcon" />}
            close={close}
            customClasses="w-[85%]"
          >
            <div className='p-4'>
              <div className='flex'>
                {/* <div className='w-1/4' > */}
                {/* <Label fontSize={"md"} fontWeight='bold' isRequired>Upload Option</Label>
                  <div className='flex mt-2'>
                    <div className='flex'>
                      <RadioButton
                        label="Associate result with existing lab order"
                        name={IMAGE_REPORT_FORM_FIELDS_NAMES.UPLOAD_OPTION}
                        checked={values[IMAGE_REPORT_FORM_FIELDS_NAMES.UPLOAD_OPTION] === IMAGE_REPORT_FORM_FIELDS_NAMES.RESULT_WITH_LAB_ORDER}
                        value={IMAGE_REPORT_FORM_FIELDS_NAMES.UPLOAD_OPTION}
                        onChangeCb={(e) => {
                          setFieldValue(IMAGE_REPORT_FORM_FIELDS_NAMES.UPLOAD_OPTION, e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex pt-2'>
                    <RadioButton
                      label="Upload result without lab order"
                      name={IMAGE_REPORT_FORM_FIELDS_NAMES.UPLOAD_OPTION}
                      checked={values[IMAGE_REPORT_FORM_FIELDS_NAMES.UPLOAD_OPTION] === IMAGE_REPORT_FORM_FIELDS_NAMES.RESULT_WITHOUT_LAB_ORDER}
                      value={IMAGE_REPORT_FORM_FIELDS_NAMES.UPLOAD_OPTION}
                      onChangeCb={(e) => {
                        setFieldValue(IMAGE_REPORT_FORM_FIELDS_NAMES.UPLOAD_OPTION, e.target.value)
                      }}
                    />
                  </div> */}
                {/* </div> */}
                {/* <div className='flex column-gap-10px'> */}
                {/* <div className='flex column-gap-10px'>
                    <div className='min-w-[360px]'>
                      <SelectDropdown
                        placeholder='Select'
                        label='Image Order'
                        value={values[IMAGE_REPORT_FORM_FIELDS_NAMES.IMAGE_ORDER]}
                        name={IMAGE_REPORT_FORM_FIELDS_NAMES.IMAGE_ORDER}
                        onChangeCb={(val) => { setFieldValue(IMAGE_REPORT_FORM_FIELDS_NAMES.IMAGE_ORDER, val.value) }}
                        options={[{ label: "hello", value: "harris" }]}
                      />
                    </div>
                  </div> */}
                <div className='flex column-gap-10px'>
                  <div className='flex-1 p-2'>
                    <div>
                      <DatePicker
                        label="Collection Date"
                        placeholder='Choose Date'
                        isRequired={true}
                        value={values[IMAGE_REPORT_FORM_FIELDS_NAMES.COLLECTION_DATE]}
                        name={IMAGE_REPORT_FORM_FIELDS_NAMES.COLLECTION_DATE}
                        onChangeCb={(date) => {
                          setFieldValue(IMAGE_REPORT_FORM_FIELDS_NAMES.COLLECTION_DATE, date)
                        }}
                      />
                    </div>
                  </div>
                  <div className='p-2'>
                    <div>
                      <DatePicker
                        name={IMAGE_REPORT_FORM_FIELDS_NAMES.COLLECTION_TIME}
                        placeholder='Choose Time'
                        label='Collection Time'
                        showTimeSelect
                        isRequired={true}
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        value={values[IMAGE_REPORT_FORM_FIELDS_NAMES.COLLECTION_TIME]}
                        onChangeCb={(time) => {
                          setFieldValue(IMAGE_REPORT_FORM_FIELDS_NAMES.COLLECTION_TIME, time)
                        }}
                        iconName='scheduleIcon'
                      />
                    </div>
                  </div>
                </div>
                {/* </div> */}
                {/* </div> */}
                {/* <div className='flex column-gap-10px pt-6'> */}
                <div className='min-w-[360px] p-2'>
                  <SelectDropdown
                    placeholder='Select'
                    label='Imaging Center'
                    isRequired={true}
                    name={IMAGE_REPORT_FORM_FIELDS_NAMES.IMAGING_CENTER}
                    value={values[IMAGE_REPORT_FORM_FIELDS_NAMES.IMAGING_CENTER]}
                    onChangeCb={(val) => { setFieldValue(IMAGE_REPORT_FORM_FIELDS_NAMES.IMAGING_CENTER, val) }}
                    options={General.addLableValuePair(allImagingCentreData, "name", "uuid")}
                  />
                </div>
                <div className='min-w-[360px] p-2'>
                  <Input
                    label="Reviewer"
                    placeholder="Enter Reviewer"
                    isRequired={true}
                    name={IMAGE_REPORT_FORM_FIELDS_NAMES.REVIEWER}
                    value={values[IMAGE_REPORT_FORM_FIELDS_NAMES.REVIEWER]}
                    onChangeCb={(e) => {
                      setFieldValue(IMAGE_REPORT_FORM_FIELDS_NAMES.REVIEWER, e.target.value);
                    }}
                  />
                </div>
              </div>
              <div>
                <ImagingReportTable values={values} setFieldValue={setFieldValue} addImageReport={() => addImageReport(values, setFieldValue)} handleBlur={handleBlur} />
              </div>
              <div>
                <div className='mt-4'>
                  <Label>Note</Label>
                </div>
                <div className='mt-2'>
                  <TextArea
                    name={IMAGE_REPORT_FORM_FIELDS_NAMES.NOTE}
                    value={values[IMAGE_REPORT_FORM_FIELDS_NAMES.NOTE]}
                    onChangeCb={(e) => { setFieldValue(IMAGE_REPORT_FORM_FIELDS_NAMES.NOTE, e.target.value) }}
                  />
                </div>
              </div>
            </div>
          </ModalComponent >
        </Form>
      }}
    </Formik >
  )
}

export default ImagingReportModal
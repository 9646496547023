import React, { useCallback, useEffect, useState } from 'react';
import WrapperCard from '../../../components/common-components/Cards/WrapperCard/WrapperCard';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey as PatientEncounterComponentKey, setFormData, setIsEncounterSummaryOpen } from '../EncounterSlice'
import { postEncounterDetails } from '../EncounterSaga';
import { useNavigate } from 'react-router-dom';
import { componentKey } from '../../../containers/SettingsTab/TemplatesCard/Tabs/VisitNotes/VisitNotesSlice';
import AlertPopUp from '../../../components/common-components/popup-components/AlertPopUp';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';

const FormioForm = () => {
    const dispatch = useDispatch()
    const [srcDoc, setSrcDoc] = useState(null)
    const [isEndEncounterModalOpen, setIsEndEncounterModalOpen] = useState()
    const { formData, encounterDataById, selectedForm } = useSelector(state => state[PatientEncounterComponentKey])
    const [changeformData, setChangeFormData] = useState({})
    const { visitNoteById } = useSelector(state => state[componentKey])

    const navigate = useNavigate()

    const handleSubmit = useCallback((e) => {
        const data = {
            patinetId: encounterDataById?.patientId,
            providerId: encounterDataById?.providerId,
            scheduleId: encounterDataById?.scheduleId,
            data: e?.data ? { ...e?.data, deviceUuid: selectedForm.value } : {},
            isCreateEncounter: false,
            encounterId: encounterDataById?.uuid,
            encounterFormsId: encounterDataById?.encounterFormsId,
            status: 'Unsigned'
        }
        dispatch(setFormData(e?.data?.data))
        dispatch(postEncounterDetails({ encounterData: data, encounterId: encounterDataById?.uuid }))
    }, [encounterDataById, dispatch, selectedForm])

    const handleOnChange = useCallback((formdata) => {
        const data = {
            patinetId: encounterDataById?.patientId,
            providerId: encounterDataById?.providerId,
            scheduleId: encounterDataById?.scheduleId,
            data: formdata,
            isCreateEncounter: false,
            encounterId: encounterDataById?.uuid,
            encounterFormsId: encounterDataById?.encounterFormsId,
            status: 'Unsigned'
        }
        dispatch(setFormData(formdata))
        dispatch(postEncounterDetails({ encounterData: data }))
    }, [encounterDataById, dispatch])

    useEffect(() => {
        const onloadFunction = `
        window.onload = function() {
            const iframeWindow = window.parent;
                iframeWindow.postMessage({ type: 'iframeLoaded' }, '*');
            Formio.createForm(document.getElementById('formio'), {
                components: [
                    ${selectedForm?.value && visitNoteById?.data?.components?.length ? visitNoteById?.data?.components?.map(component => JSON.stringify(component)).join(',') : visitNoteById?.data?.length && visitNoteById?.data?.map(component => JSON.stringify(component)).join(',')}
                ]
            }).then(function(form) {
                form.on('submit', (submission) => {
                    iframeWindow.postMessage({ type: 'formSubmit', data: submission }, '*');
                });
    
                form.on('change', (submission) => {
                    iframeWindow.postMessage({ type: 'formChange', data: submission.data }, '*');
                });
    
                form.on('cancel', (submission) => {
                    iframeWindow.postMessage({ type: 'cancel'}, '*');
                });

                form.on('saveAsDraft', (submission) => {
                    iframeWindow.postMessage({ type: 'saveAsDraft', data: submission }, '*');
                });
                form.setSubmission({
                    data: ${JSON.stringify(selectedForm?.value === encounterDataById?.encounterForms?.data?.deviceUuid ? encounterDataById?.encounterForms?.data : {})}
                });
                
                const formElements = document.querySelectorAll('.formio-component');
                formElements.forEach(element => {
                    element.style.fontWeight = 'bold';
                });
              });
        };
    `;


        const dynamicContent = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Dynamic HTML inside Iframe</title>
                <script type='text/javascript'>
                ${onloadFunction}
            </script >
            </head >
    <body>
        <div>
        <link crossorigin="anonymous"  rel='stylesheet' href='https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css' />
        <link crossorigin="anonymous"  rel='stylesheet' href='https://cdn.form.io/formiojs/formio.full.min.css' />
        <script crossorigin="anonymous"  src='https://cdn.form.io/formiojs/formio.full.min.js'></script>

        <div id='formio'></div>
    </div>
    </body>
            </html >
    `;

        setSrcDoc(dynamicContent);
    }, [encounterDataById?.encounterForms?.data, visitNoteById, selectedForm.value]);

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'formSubmit') {
                handleSubmit(event.data);
            } else if (event.data.type === "formChange") {
                setChangeFormData(event?.data || {});
            } else if (event.data.type === "cancel") {
                setIsEndEncounterModalOpen(true)
            }
            else if (event.data.type === "saveAsDraft") {
                handleOnChange(event?.data?.data)
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [handleSubmit, handleOnChange]);

    return <>

        <WrapperCard customClasses='p-4 h-[58vh] min-h-[400px]'>
            {/* <Form form={formDefinition} submission={{
                data: encounterDataById?.encounterForms?.data || {}
            }} onSubmit={handleSubmit} onCustomEvent={(customEvent) => {
                myCustomFunction(customEvent);
            }}
                onChange={handleOnChange}
            >

            </Form> */}
            <iframe id="dynamicIframe" className='w-full' title='unique title' srcDoc={srcDoc} width="400" height="100%"></iframe>
            {Object.keys(selectedForm)?.length ?
                <div className="flex mt-4 col-gap-5 float-right">
                    <div className="mx-2">
                        <Button variant={BUTTON_VARIANTS.OUTLINED} onClickCb={() => setIsEndEncounterModalOpen(true)} customBtnClass="text-met-primary" type='submit'>Cancel</Button>
                    </div>
                    <div className="mx-2">
                        <Button variant={BUTTON_VARIANTS.OUTLINED} onClickCb={() => { handleOnChange(changeformData?.data || {}) }} customBtnClass="text-met-primary" type='submit'>Save as Draft</Button>
                    </div>
                    <div className="mx-2">
                        <Button variant={BUTTON_VARIANTS.CONTAINED} onClickCb={() => { handleSubmit(changeformData || {}); }} customBtnClass="text-met-primary" type='submit'>Save</Button>
                    </div>
                </div> : null}
        </WrapperCard>
        {isEndEncounterModalOpen ? <AlertPopUp
            title="Are you sure you want to end this encounter?"
            open={isEndEncounterModalOpen}
            cancelBtnProps={{
                onCancelCb: () => setIsEndEncounterModalOpen(false)
            }}
            confirmationButtonProps={{
                onConfirmCb: () => {
                    navigate('/scheduling/list')
                }
            }}
        /> : null}
    </>

};

export default FormioForm;
import store from "../../../../../store/store";

export const componentKey = 'COLOR_CONFIGURATION'

const initialState = {
    colorConfigDetails: [],
    isColorSelectorModalOpen: false
};

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setColorConfigDetailsData: (state, action) => {
            state.colorConfigDetails = action.payload;
        },
        setIsColorSelectorModalOpen: (state, action) => {
            state.isColorSelectorModalOpen = action.payload;
        },
    },
    initialReducerState: initialState
});

export const { setColorConfigDetailsData, setIsColorSelectorModalOpen } = actions